import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Spinner from "../campaigns/Spinner"
import QuestionsList from "./form/questions/QuestionsList"
import { serialize } from 'object-to-formdata'
import axios from '../../configAxios'
import AddElementButton from "./AddElementButton"
import FormDetails from "./FormDetails"

class Create extends Component {
  timeout = 0;
  state = {
    createForm: {
      formName: {
        name: "formName",
        elementType: "input",
        elementConfig: {
          name: "name",
          type: "text",
          required: true,
        },
        value: "",
        label: "Form Name",
      },
      formDescription: {
        name: "formDescription",
        elementType: "textarea",
        elementConfig: {
          name: "description",
          type: "text",
          required: true,
        },
        value: "",
        label: "Form Description"
      },
      questions: []
    },
    grant_campaign_id: this.props.match.params.campaign_id,
    form_id: this.props.match.params.form_id,
    enteredUserCharacters: "",
    disabled: false,
    isLoading: this.props.match.params.form_id ? true : false,
    edited: false
  };

  handleRetrievedQuestions = (questions) => {
    return questions.map(question => {
      question["question_id"] = question.id

      if (question.type === "ApplicantDataQuestion") {
        return { ...question, applicant_fields: question.applicant_fields.fields }
      }
      return { ...question }
    })
  }

  setFormState = (form) => {
    let createForm = this.state.createForm
    this.setState({
      createForm: {
        ...createForm,
        formName: { ...createForm.formName, value: form.name },
        formDescription: { ...createForm.formDescription, value: form.description },
        questions: this.handleRetrievedQuestions(form.questions)
      },
      isLoading: false
    })
  }

  getForm = () => {
    axios.get(`/forms/${this.state.form_id}`)
      .then(res => {
        this.setFormState(res.data.json.data.form)
      })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.state.form_id && this.getForm()
  }

  inputChangedHandler = (event, key, formName) => {
    let inputValue = {};
    inputValue.value = event.target.value;

    const updatedControls = {
      ...this.state[formName],
      [key]: {
        ...this.state[formName][key],
        ...inputValue,
      },
    };
    this.setState({ [formName]: updatedControls });
    this.setState({ edited: true })
  };

  isSubmitDisabled = () => {
    let form = this.state.createForm
    return this.state.disabled || !form.formName.value
  }

  checkQuestionsSaved = () => {
    let buttons = document.querySelectorAll(".questionSaveButton")
    for (let button of buttons.values()) {
      if (button.firstChild.data === "Save") {
        return false
      }
    }
    return true
  }

  submitHandler = (event) => {
    event.preventDefault()

    this.setState({ disabled: true });

    let formState = this.state.createForm
    const form_id = this.state.form_id

    let formData = {
      grant_campaign_id: this.state.grant_campaign_id,
      name: formState.formName.value,
      description: formState.formDescription.value,
    }

    axios.put(`/forms/${form_id}`, formData).then((res) => {
      toast.success("Form data updated.")
      this.setState({edited: false})
    }).catch(err => toast.error("Form data could not be updated."))
  };

  setQuestions = (questionData) => {
    this.setState({
      createForm: { ...this.state.createForm, questions: questionData }
    })
  }

  submitNewQuestion = (question) => {
    const questionCopy = {...question}

    if (question.applicant_fields) {
      questionCopy.applicant_fields = { fields: question.applicant_fields }
    }

    const serializedQuestion = serialize(questionCopy)

    return axios.post(`forms/${this.state.form_id}/questions`, serializedQuestion).then((res) => {

      if (res.data.json.success) {
        const savedQuestion = res.data.json.data.question

        const questionIndex = this.state.createForm.questions.findIndex( question => !question.id && (question.order === savedQuestion.order) )
        const questionsCopy = [...this.state.createForm.questions]
        questionsCopy.splice(questionIndex, 1, savedQuestion)

        this.setState({
          createForm: {
            ...this.state.createForm,
            questions: questionsCopy
          },
          isLoading: false
        })

        toast.success("Question saved successfully!")

      } else {
        toast.error("Error saving question!")
      }
    })
  }

  updateQuestion = (question) => {
    const questionCopy = {...question}

    if (question.applicant_fields) {
      questionCopy.applicant_fields = { fields: question.applicant_fields }
    }

    const serializedQuestion = serialize(questionCopy)

    return axios.put(`forms/${this.state.form_id}/questions/${question.id}`, serializedQuestion)
  }

  updateQuestionState = (question) => {
    let questionsCopy = [...this.state.createForm.questions]

    questionsCopy[question.order - 1] = question

    this.setState({
      createForm: {
        ...this.state.createForm,
        questions: questionsCopy
      }
    })
  }

  createQuestion = () => {
    let questions = this.state.createForm.questions
    this.setQuestions(
      [...questions,
        {
          dragId: questions.length + 1,
          type: null,
          question_text: "",
          question_options: [],
          order: questions.length + 1,
          file_attachment: null,
          mandatory: true,
          applicant_fields: null,
        }
      ])
  }

  deleteUnsavedQuestionFromState(question) {
    let questionsCopy = [...this.state.createForm.questions]
    const questionIndex = question.order - 1
    questionsCopy.splice(questionIndex, 1)
    questionsCopy.map((question, index) => question.order = index + 1)
    this.setQuestions(questionsCopy)
  }

  deleteSavedQuestion(question) {
    return axios.delete(`/forms/${this.state.form_id}/questions/${question.id}`).then(res => {
      const questions = this.handleRetrievedQuestions(res.data.json.questions)
      this.setQuestions([...questions])
      toast.success("Questions Deleted Successfully")
    }).catch(err => {
      toast.error("Could Not Delete Question!")
    })
  }

  deleteQuestion = (question) => {
    if (question.id && window.confirm("Are you sure you want to delete this question?")) {
      return this.deleteSavedQuestion(question)
    }

    if (!question.id) {
      this.deleteUnsavedQuestionFromState(question)
    }
  }

  render() {
    let { formName, formDescription } = this.state.createForm;

    return (
      <div className="container w-100">
        {this.state.isLoading && <Spinner/>}

        {!this.state.isLoading &&
        <form onSubmit={this.submitHandler} className="px-4 pb-4 pt-5 card">
          <FormDetails formName={formName} formDescription={formDescription}
                       inputChangedHandler={this.inputChangedHandler} setState={this.setState}
                       campaignID={this.state.grant_campaign_id} backUrl={this.backUrl} edited={this.state.edited}/>

          <div className="d-flex flex-row justify-content-between mt-3">
            <h3 className="mb-3">Questions</h3>
          </div>

          <QuestionsList
            questions={this.state.createForm.questions}
            setQuestions={this.setQuestions}
            submitNewQuestion={this.submitNewQuestion}
            updateQuestion={this.updateQuestion}
            updateQuestionState={this.updateQuestionState}
            deleteQuestion={this.deleteQuestion}
          />
          <AddElementButton createQuestion={this.createQuestion}/>
        </form>
        }
      </div>
    );
  }
}

export default withRouter(Create);
