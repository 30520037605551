import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import axios from "../../configAxios";
import langUtils from "../../utils/langUtils";

import "./index.css";

class Reports extends Component {
  csvLink = React.createRef();
  signal = axios.CancelToken.source();
  state = {
    reportData: "",
    policyRes: null
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getPolicy();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getPolicy = () => {
    axios
      .get("/reports/report_builders/policy", {
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({ policyRes: res.data.json });
      });
  }

  reportDownloadHandler = () => {
    axios
      .get("/reports/mailing_list/mailing_list", {
        params: {
          organization_id: this.props.auth.organizationId
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({ reportData: res.data.json.data }, () => {
          this.csvLink.current.link.click();
        });
      });
  };

  render() {
    return (
      <div className="container reports w-100">
        <div className="row mb-4">
          <div className="col-12">
            <h3>Reports</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="card-text">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/general_membership_report">
                        <p className="font-weight-bold">General Membership Report</p>
                      </Link>
                      <span className="text-justify">
                        This report lists all active Members along with their
                        current contact info, Member Type, and Volunteer Hours for the
                        year.
                      </span>
                    </div>
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/users_accessing_programs_and_services_report">
                        <p className="font-weight-bold">Users Accessing Programs and Services</p>
                      </Link>
                      <span className="text-justify">
                        This report summarizes the Programs and Services that
                        have been accessed for a defined period. Note that the
                        Registrations Listing displays a record for each Program
                        Enrollment only if the first scheduled program date falls within
                        the report bounds.
                      </span>
                    </div>
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/partnership_organizations_report">
                        <p className="font-weight-bold">Partnership Organizations Report</p>
                      </Link>
                      <span className="text-justify">
                        This report lists all Partnership Organizations that the system
                        knows about, as well as the Members and Non-Members that belong
                        to those organizations.
                      </span>
                    </div>
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/services_in_kind_report">
                        <p className="font-weight-bold">Services in Kind Report</p>
                      </Link>
                      <span className="text-justify">
                        This report summarizes and lists all Service-in-Kind payments
                        for a defined period.
                      </span>
                    </div>
                    {this.state.policyRes && this.state.policyRes.asset && (<div className="list-group-item">
                      <Link className="text-info" to="/reports/asset_revenue_report">
                        <p className="font-weight-bold">{langUtils("txt_global_stuff", "Asset")} Revenue Report</p>
                      </Link>
                      <span className="text-justify">
                        This report summarizes revenue collected from Asset rentals for
                        a defined period. Bookings are selected by Invoice Date
                        priority. These amounts do not include manually created Invoices
                        and Invoice Line Items.
                      </span>
                    </div>)}
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/general_revenue_report">
                        <p className="font-weight-bold">General Revenue Report</p>
                      </Link>
                      <span className="text-justify">
                        This report summarizes all revenue collected as
                        reported through this system. This includes Asset Booking
                        revenue, Program Registration revenue, and other General Invoice
                        items.
                      </span>
                    </div>
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/rentals_report">
                        <p className="font-weight-bold">Rentals Report</p>
                      </Link>
                      <span className="text-justify">
                        This report summarizes all rental activity tracked by the system.
                      </span>
                    </div>
                    {this.state.policyRes && this.state.policyRes.asset && (<div className="list-group-item">
                      <Link className="text-info" to="/reports/asset_servicing_report">
                        <p className="font-weight-bold">{langUtils("txt_global_stuff", "Asset")} Servicing Report</p>
                      </Link>
                      <span className="text-justify">
                        This report summarizes Asset Servicing Activity for the
                        specified period. The data is culled from Flags that are
                        automatically generated when an Asset is removed from the Asset
                        pool for Servicing.
                      </span>
                    </div>)}
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/grant_application_report">
                        <p className="font-weight-bold">Grant Application Report</p>
                      </Link>
                      <span className="text-justify">
                        This report summarizes information relevant to the writing of
                        Grant Applications. This includes total number of volunteers and
                        volunteer hours, total number of active Members with percentage
                        of those Members from Edmonton and Alberta, total FAVA Bucks
                        used, and total Employee Grants used.
                      </span>
                    </div>
                    <div className="list-group-item">
                      <p className="mailing-list font-weight-bold" onClick={this.reportDownloadHandler}>
                        Mailing List
                      </p>
                      <span className="text-justify">
                        This link simply generates a CSV file for download that contains
                        a comma separated list of e-mail addresses for Friends that have
                        opted in to the mailing list. Blank e-mail addresses are
                        automatically removed from the list. This list can be copied and
                        pasted directly into an e-mail client in order to do mass
                        mailings.
                      </span>
                    </div>
                    {this.state.policyRes && this.state.policyRes.asset && (<div className="list-group-item">
                      <Link className="text-info" to="/reports/equipment_report">
                        <p className="font-weight-bold">Equipment Report</p>
                      </Link>
                      <span>A list of all equipment assets.</span>
                    </div>)}
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/account_report">
                        <p className="font-weight-bold">{langUtils("txt_account", "Account")} Report</p>
                      </Link>
                      <span>Account Summary Report</span>
                    </div>
                    {this.state.policyRes && this.state.policyRes.project && (<div className="list-group-item">
                      <Link className="text-info" to="/reports/project_report">
                        <p className="font-weight-bold">{langUtils("projects", "Project")} Report</p>
                      </Link>
                      <span>Total number of unique projects and list of all projects.</span>
                    </div>)}
                    {this.state.policyRes && this.state.policyRes.program && (<div className="list-group-item">
                      <Link className="text-info" to="/reports/program_report">
                        <p className="font-weight-bold">{langUtils("programs", "Program")} Report</p>
                      </Link>
                      <span className="text-justify">
                        List of programs and people who have registered in a specific time frame.
                      </span>
                    </div>)}
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/education_and_association_report">
                        <p className="font-weight-bold">Education and Association</p>
                      </Link>
                      <span className="text-justify">
                        List of members according to their Education And Association.
                      </span>
                    </div>
                    
                    { localStorage.organizationName === "FAVA" && (
                    <div className="list-group-item">
                      <Link className="text-info" to="/reports/fava_stats_report">
                        <p className="font-weight-bold">FAVA TV Stats Report</p>
                      </Link>
                      <span className="text-justify">
                        List of all films on FAVA TV and the number of views on each film
                      </span>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-5">
            <div className="card report-types">
              <p className="font-weight-bold">Recent Reports</p>
            </div>
          </div> */}
        </div>
        <CSVLink
          data={this.state.reportData}
          filename={`mailing_list.csv`}
          className="hidden"
          ref={this.csvLink}
          target="_self"
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(Reports);
