import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";

import FormButton from "../../UI/button/FormButton";
import Input from "../../UI/input/Input";
import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";

import './Invoice.css';

class NewTerm extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    orgnId: null,
    floating: {
      elementType: "input",
      elementConfig: {
        name: "floating",
        type: "text",
      },
      value: "",
    },
    general: {
      elementType: "input",
      elementConfig: {
        name: "general",
        type: "text",
      },
      value: ""
    },
    programRegistration: {
      elementType: "input",
      elementConfig: {
        name: "programRegistration",
        type: "text",
      },
      value: ""
    },
    programCostTracking: {
      elementType: "input",
      elementConfig: {
        name: "programCostTracking",
        type: "text",
      },
      value: ""
    },
    rental: {
      elementType: "input",
      elementConfig: {
        name: "rental",
        type: "text",
      },
      value: ""
    },
    disabled: false
  }

  componentDidMount() {
    axios.get("/organizations/formdata", { cancelToken: this.signal.token })
      .then(res => {
        const orgnId = res.data.json.current_organization.id;
        this.setState({
          orgnId: orgnId
        }, () => {
          axios.get(`/organizations/${this.state.orgnId}`, { cancelToken: this.signal.token })
            .then(res => {
              const { data } = res.data.organization;
              this.setState({
                loading: false,
                floating: {
                  ...this.state.floating,
                  value: data.floating_invoice_type
                },
                general: {
                  ...this.state.general,
                  value: data.general_invoice_type
                },
                programRegistration: {
                  ...this.state.programRegistration,
                  value: data.program_registration_invoice_type
                },
                programCostTracking: {
                  ...this.state.programCostTracking,
                  value: data.program_cost_tracking_invoice_type
                },
                rental: {
                  ...this.state.rental,
                  value: data.rental_invoice_type
                },
              })
            })
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  toggleCheckbox = (event, key) => {
    this.setState({
      [key]: {
        ...this.state[key],
        elementConfig: {
          ...this.state[key].elementConfig,
          checked: !this.state[key].elementConfig.checked
        }
      }
    });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let attrData = {};
    const { floating, general, programRegistration, programCostTracking, rental } = this.state;
    attrData["floating_invoice_type"] = floating.value;
    attrData["general_invoice_type"] = general.value;
    attrData["program_registration_invoice_type"] = programRegistration.value;
    attrData["program_cost_tracking_invoice_type"] = programCostTracking.value;
    attrData["rental_invoice_type"] = rental.value;
    axios
      .put(`/organizations/${this.state.orgnId}`, attrData)
      .then(res => {
        if (res.data.json.success) {
          toast.success("New Term successfully updated");
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
        this.setState({ disabled: false })
      })
  };

  render() {
    return (
      this.state.loading ? (
        <div className="card-text">
          <div className="row">
            <div className="col-12">
              <Spinner className="h-60vh" />
            </div>
          </div>
        </div>
      ) : (
          <div className="p-3">
            <form onSubmit={this.submitHandler} className="mt-3">
              <div className="row">
                <div className="col-lg-6">
                  <p>Floating</p>
                  <Input
                    {...this.state.floating}
                    changed={event => this.inputChangedHandler(event, "floating")}
                  />
                </div>
                <div className="col-lg-6">
                  <p>General</p>
                  <Input
                    {...this.state.general}
                    changed={event => this.inputChangedHandler(event, "general")}
                  />
                </div>
                <div className="col-lg-6">
                  <p>Program Registration</p>
                  <Input
                    {...this.state.programRegistration}
                    changed={event => this.inputChangedHandler(event, "programRegistration")}
                  />
                </div>
                <div className="col-lg-6">
                  <p>Program Cost Tracking</p>
                  <Input
                    {...this.state.programCostTracking}
                    changed={event => this.inputChangedHandler(event, "programCostTracking")}
                  />
                </div>
                <div className="col-lg-6">
                  <p>Rental</p>
                  <Input
                    {...this.state.rental}
                    changed={event => this.inputChangedHandler(event, "rental")}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-center py-3">
                    <FormButton disabled={this.state.disabled}>Save</FormButton>
                  </div>
                </div>
              </div>

            </form>
          </div>
        )
    );
  }
}
export default NewTerm;
