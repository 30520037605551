import React, { Component } from "react";

import axios from "../../../../configAxios";

import CardHeader from "../../CardHeader";
import ProjectsGraph from "./ProjectsGraph";

import Spinner from "../../../../assests/images/spinner.gif";

class Projects extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    data: [
      {
        name: "",
        activeProject: null,
        newThisYear: null,
        newThisMonth: null,
        newThisWeek: null
      }
    ]
  }

  componentDidMount() {
    axios.get('/projects/stats', { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          data: [
            {
              name: "",
              activeProject: res.data.json.total_active_projects,
              newThisYear: res.data.json.new_this_year,
              newThisMonth: res.data.json.new_this_month,
              newThisWeek: res.data.json.new_this_week
            }
          ],
          loading: false
        })
        this.props.updateGrid();
      })
      .catch(error => error)
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  render() {
    const spinner = (
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    );
    return (
      <div className="dashboard">
        <div className="card">
          {this.state.loading ? (
            <div className="card-body p-0">
              <div className="card-text">
                <div className="spinner">{spinner}</div>
              </div>
            </div>
          ) : (
              <React.Fragment>
                <CardHeader
                  title="Projects"
                  icon="fa-folder-open"
                  collapse="projectsgraph"
                  updateGrid={this.props.updateGrid}
                  removeModule={this.props.removeModule}
                  name={this.props.name} />
                <div className="dashboard-card collapse show" id="projectsgraph">
                  <div className="card-body">
                    <div className="card-text" style={{ height: '300px' }}>
                      <ProjectsGraph data={this.state.data} />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
        </div>
      </div>
    );
  }
}

export default Projects;
