import React, { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { OverlayTrigger, Popover } from "react-bootstrap";

import axios from "../../../../configAxios"

import "./ReelsItem.css"

class ReelsItem extends Component {
  deleteReelHandler = () => {
    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      axios
        .delete("/reels/" + this.props.reel.id)
        .then((res) => {
          if (res.data.json.success) {
            toast.success("Reel successfully deleted.")
            this.props.getReels()
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(error + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
          }
        })
        .catch(() => toast.error("Something went wrong"))
    }
  }

  render() {
    const { reel, userId, disabledIds, featuredCheckboxHandler } = this.props
    const showIcon = (
      <div className="row">
        <div className="col-1">
          <Link to={"/reels/" + this.props.reel.id} className="text-muted">
            <i className="fas fa-pencil-alt cursor" />
          </Link>
        </div>
        {reel.can_delete && (
          <div className="col-1">
            <Link
              to="/reels"
              className="text-muted"
              onClick={this.deleteReelHandler}
            >
              <i className="fas fa-trash cursor" />
            </Link>
          </div>
        )}
      </div>
    )

    return (
      <li className="list-group-item">
        <div className="row">
          <div className="col-1">
          { <OverlayTrigger key={"top"} placement={"top"}
              overlay={
                <Popover>
                  <div>
                    Reel Id: <span className="font-weight-bold">{this.props.reel.id}</span>
                  </div>
                </Popover>
              }
            >       
              <i className="fa fa-info-circle text-muted cursor" aria-hidden="true"/>
            </OverlayTrigger>
          }  
          </div>
          <div className="col-3">
            <Link to={"/reels/" + this.props.reel.id} className="text-info">
              {this.props.reel.name}
            </Link>
          </div>
          <div className="col-2">{this.props.reel.project_count}</div>
          <div className="col-2">
            {this.props.reel.sharing.label === "me"
              ? "Private"
              : this.props.reel.sharing.label === "staff"
              ? "Staff Only"
              : this.props.reel.sharing.label === "everyone"
              ? "Public"
              : this.props.reel.sharing.label}
          </div>
          <div className="col-2">
            <input
              type="checkbox"
              checked={reel.featured}
              disabled={disabledIds.includes(reel.id)}
              onChange={(e) => featuredCheckboxHandler(this.props.reel)}
              className="cursor"
            />
          </div>
          <div className="col-1 text-center">{showIcon}</div>
        </div>
      </li>
    )
  }
}

export default ReelsItem
