import React, { Component } from "react"
import { toast } from "react-toastify"
import { title } from "change-case"

import Input from "../../UI/input/Input"
import Button from "../../UI/button/Button"
import FormButton from "../../UI/button/FormButton"
import axios from "../../../configAxios"
import ModalSpinner from "../../UI/ModalSpinner"
import ToggleSwitch from "../../common/toggleSwitch"

import EditThirdPartyDetails from "./EditThirdPartyDetails"

class EditRental extends Component {
  signal = axios.CancelToken.source();
  state = {
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true
      },
      label: "Name",
      value: ""
    },
    shortName: {
      elementType: "input",
      elementConfig: {
        name: "shortName",
        type: "text"
      },
      label: "Short Name",
      value: ""
    },
    locationName: {
      elementType: "input",
      elementConfig: {
        name: "locationName",
        type: "text",
        required: true
      },
      label: "Location Name",
      value: "",
    },
    loading: true,
    disabled: false,
    bookableToggle: false,
    isPublicToggle: false,
  };

  componentDidMount() {
    if (this.props.type === "category") {
      axios
        .get(`/categories/${this.props.category.id}`, {
          cancelToken: this.signal.token
        })
        .then(res => {
          this.setState({
            name: { ...this.state.name, value: res.data.category.name },
            shortName: {
              ...this.state.shortName,
              value: res.data.category.short_name
            },
            bookableToggle: res.data.category.bookable_by_admin_only,
            isPublicToggle: res.data.category.is_public,
            loading: false
          });
        })
        .catch(error => error);
    } else if (this.props.type === "location") {
      axios
        .get(`/locations/${this.props.location.id}`, {
          cancelToken: this.signal.token
        })
        .then(res => {
          this.setState({
            locationName: {
              ...this.state.locationName,
              value: res.data.location.name
            },
            loading: false
          });
        })
        .catch(error => error);
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  closeModal = () => {
    this.props.closeModal();
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let updatedData = {};
    const { name, shortName, bookableToggle, isPublicToggle } = this.state;
    updatedData["id"] = this.props.category.id;
    updatedData["name"] = name.value;
    updatedData["short_name"] = shortName.value;
    updatedData["bookable_by_admin_only"] = bookableToggle;
    updatedData["is_public"] = isPublicToggle;
    axios
      .put(`/categories/${this.props.category.id}`, updatedData)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Categories has successfully updated");
          this.props.closeModal();
          this.props.getCategories();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
        this.setState({ disabled: false })
      })
      .catch(err => toast.error(err));
  };

  submitLocationHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let updatedData = {};
    const { locationName } = this.state;
    updatedData["id"] = this.props.location.id;
    updatedData["name"] = locationName.value;
    axios
      .put(`/locations/${this.props.location.id}`, updatedData)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Location has successfully updated");
          this.props.closeModal();
          this.props.getLocations();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
        this.setState({ disabled: false })
      })
      .catch(err => toast.error(err));
  };

  render() {
    const { name, shortName, locationName, bookableToggle, isPublicToggle } = this.state;
    if (this.props.type === "location") {
      return (
        <div>
          {
            this.state.loading ? <ModalSpinner /> : (
              <form className="mt-3" onSubmit={this.submitLocationHandler}>
                <Input
                  {...locationName}
                  changed={event => this.inputChangedHandler(event, "locationName")}
                />
                <div className="text-center">
                  <Button type="secondary" clicked={this.props.closeModal}>
                    Cancel
                  </Button>
                  <FormButton className="ml-2" disabled={this.state.disabled}>Update Location</FormButton>
                </div>
              </form>
            )
          }
        </div>
      );
    } else if (this.props.type === "category") {
      return (
        <div>
          {
            this.state.loading ? <ModalSpinner /> : (
              <form className="mt-3" onSubmit={this.submitHandler}>
                <Input
                  {...name}
                  changed={event => this.inputChangedHandler(event, "name")}
                />
                <Input
                  {...shortName}
                  changed={event => this.inputChangedHandler(event, "shortName")}
                />
                <div className="d-flex mt-4 mb-3">
                  <div className="mr-3">
                    <ToggleSwitch
                      id = "toggle"
                      checked={bookableToggle}
                      onChange={() => {
                        this.setState((prevState) => ({ ...prevState, bookableToggle: !prevState.bookableToggle }))
                      }}
                      customCheckContent="YES"
                      customUncheckContent="NO"
                    />
                  </div>
                  <label htmlFor="toggle" className="w-100 word-wrap-break">
                  Only available for booking by Staff Members
                  </label>
                </div>
                <div className="d-flex mt-4 mb-3">
                  <div className="mr-3">
                    <ToggleSwitch
                      id = "toggle"
                      checked={isPublicToggle}
                      onChange={() => {
                        this.setState((prevState) => ({ ...prevState, isPublicToggle: !prevState.isPublicToggle }))
                      }}
                      customCheckContent="YES"
                      customUncheckContent="NO"
                    />
                  </div>
                  <label htmlFor="toggle" className="w-100 word-wrap-break">
                    Make this category public (API)
                  </label>
                </div>
                <div className="text-center">
                  <Button type="secondary" clicked={this.props.closeModal}>
                    Cancel
                  </Button>
                  <FormButton className="ml-2" disabled={this.state.disabled}>Update Category</FormButton>
                </div>
              </form>
            )
          }
        </div>
      );
    } else if (this.props.type === "thirdPartyOwner") {
      return (
        <EditThirdPartyDetails
          editThirdPartyHandler={this.editThirdPartyHandler}
          editThirdPartyOwner={this.props.editThirdPartyOwner}
        />
      );
    }
  }
}

export default EditRental;
