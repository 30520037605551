import { toast } from "react-toastify";
import { title } from "change-case";

export const responseErrorHandler = (response) => {
  if (response.data.json.hasOwnProperty("errors")) {
    Object.keys(response.data.json.errors).forEach(error => {
      toast.error(title(error) + " " + response.data.json.errors[error]);
    });
  }
  if (response.data.json.hasOwnProperty("error")) {
    toast.error(response.data.json.error);
  }
}