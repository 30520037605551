import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { title } from "change-case"

import DatePicker from "../../UI/datePicker"
import FormButton from "../../UI/button/FormButton"
import Button from "../../UI/button/Button"
import axios from "../../../configAxios"

class EditPaymentDate extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      paymentDate: this.props.paymentDate
        ? new Date(this.props.paymentDate) 
        : null,
      disabled: false
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being cancelled");
  }

  submitHandler = e => {
    e.preventDefault();
    this.setState({ disabled: true });
    const formData = new FormData();
    formData.append("payment_date", this.state.paymentDate);
    axios.put(`/payments/${this.props.paymentId}`, formData)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Successfully updated payment");
          this.props.updateInvoice()
          this.props.closeModal()
        } else {
          if (Object.keys(res.data.json).includes("errors")) {
            Object.keys(res.data.json.errors).forEach(key => {
              toast.error(`${title(key)} ${res.data.json.errors[key]}`)
            })
          }
          if (Object.keys(res.data.json).includes("error")) {
            toast.error(res.data.json.error)
          }
          this.setState({ disabled: false })
        }
      })
      .catch(err => this.setState({ disabled: false }))
  }

  render() {
    const { paymentDate, disabled } = this.state;
    return (
      <div className="my-3">
        <form onSubmit={this.submitHandler}>
          <label>Payment Date</label>
          <DatePicker
            dateFormat="yyyy.MM.dd"
            selected={paymentDate}
            changed={e => this.setState({ paymentDate: e })}
          />
          <div className="mt-2 text-center">
            <FormButton className="mr-2" disabled={disabled}>
              Update
            </FormButton>
            <Button type="secondary" clicked={this.props.closeModal}>Cancel</Button>
          </div>
        </form>
      </div>
    )
  }
}

export default EditPaymentDate
