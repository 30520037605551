import React, { Component } from "react";
import Input from "../../UI/input/Input";

class InvoiceItem extends Component {
  state = {
    ...this.props.invoiceRowData,
    accountingCode: {
      elementType: "select",
      elementConfig: {
        name: "accountingCode",
        placeholder: "Select",
        defaultValue: this.props.invoiceRowData.accountingCode
          ? this.props.invoiceRowData.accountingCode.selectedOption
          : null,
        options: this.props.accountingCodes,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        selectedOption: null
      }
    },
    showError: false,
  };

  componentDidMount() {
    const { accountingCode } = this.state;
    this.setState({
      accountingCode: {
        ...accountingCode,
        className: "",
        elementConfig: {
          ...accountingCode.elementConfig,
          onBlur: this.blurHandler,
          onMenuOpen: this.menuOpenHandler,
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.invalidContent !== prevProps.invalidContent) {
      this.blurHandler();
    }
  }

  blurHandler = () => {
    const { index } = this.props;
    const { accountingCode } = this.state;
    let validation = true;
    if (accountingCode.selectedOption)
      validation = false;
    this.setState({
      accountingCode: {
        ...accountingCode,
        className: 
          validation
            ? "border border-danger"
            : ""
      },
      showError: validation
    }, () => this.props.updateRowValidation(index, validation));
  }

  menuOpenHandler = () => {
    this.setState({
      accountingCode: {
        ...this.state.accountingCode,
        className: "",
      },
      showError: false,
    });
  }

  inputChangedHandler = (e, key, type) => {
    const { index } = this.props;
    if (type === "select") {
      this.setState(
        {
          accountingCode: {
            ...this.state.accountingCode,
            selectedOption: e
          }
        },
        () => {
          this.props.updateRow(index, this.state);
          this.blurHandler();
        }
      );
    } else {
      this.setState({ [key]: e.target.value }, () =>
        this.props.updateRow(index, this.state)
      );
    }
  };

  render() {
    return (
      <tr>
        <td className="p-2">
          <Input
            {...this.state.accountingCode}
            changed={e =>
              this.inputChangedHandler(e, "accountingCode", "select")
            }
          />
          {this.state.showError && (
            <div className="text-danger code-select-error">
              <small>Select an Accounting Code</small>
            </div>
          )}
        </td>
        <td className="p-2">
          <Input
            elementType={"input"}
            value={this.state.description}
            changed={e => this.inputChangedHandler(e, "description", "text")}
          />
        </td>
        <td className="p-2">
          <Input
            elementType={"input"}
            elementConfig={{ type: "number", step: ".01" }}
            value={this.state.amount}
            changed={e => this.inputChangedHandler(e, "amount", "text")}
          />
        </td>
        <td className="p-2 pt-3">
          <div className="d-flex justify-content-center">
            <i
              className="fas fa-trash trash-color cursor delete-icon"
              aria-hidden="true"
              onClick={() => this.props.deleteRow(this.props.index)}
            />
          </div>
        </td>
        {this.props.rowCount && this.props.rowCount !== 0 ? <td className="p-2 pt-3" >
          <div className="d-flex justify-content-center">
            <i className="fas fa-pen" style={{ cursor: "not-allowed", color: "#e9ecef" }} />
          </div>
        </td> : null}
      </tr>
    );
  }
}

export default InvoiceItem;
