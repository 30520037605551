import React, { useState, useEffect } from 'react'
import Spinner from "../../../Spinner";
import { useSelector, useDispatch } from 'react-redux'
import axios from '../../../../../configAxios'
import ApplicationFormsTable from './ApplicationFormsTable'
import ApplicationReview from './ApplicationReview'
import ApplicationReviews from './applicationReviews/ApplicationReviews'
import _ from "lodash";
import { updateObject } from '../../../../../store/actions/index'
import { toast } from "react-toastify";
import BackLink from "../../../../UI/button/BackLink"

export default function ApplicationOverview({ grantCampaign, isAdmin, match, history }) {

  const [isLoading, setIsLoading] = useState(false)
  const [grantApplication, setGrantApplication] = useState(null)
  const [formsList, setFormsList] = useState([])
  const [grantApplicationReview, setGrantApplicationReview] = useState(null)
  
  const userId = Number(useSelector(state => state.auth.userId))
  const backUrl = `/campaigns/${match.params.id}/applications`

  const response = useSelector(state => state.response)
  const isProgramOfficer = grantCampaign.program_officer.id === userId
  const isJuryMember = grantCampaign.grant_campaigns_jury_members.find(member => member.jury_member_id === userId) ? true : false

  const dispatch = useDispatch()

  const getGrantApplication = () => {
    setIsLoading(true)
    axios.get(`/grant_applications/${match.params.application_id}`).then(res => {
      setGrantApplication(res.data.json.data.grant_application)
      setIsLoading(false)
    })
  }

  const getGrantApplicationReview = () => {
    setIsLoading(true)
    axios.get(`/grant_application_reviews/search?grant_application_id=${grantApplication.id}`).then(res => {
      let review_data = res.data.json.data.grant_application_review
      setGrantApplicationReview(review_data || {})
      setIsLoading(false)
    })
  }

  const getForms = () => {
    setIsLoading(true)
    axios.get(`/grant_campaigns/${grantApplication.grant_campaign_id}/forms`)
    .then(res => {
      setFormsList(res.data.json.data)
      setIsLoading(false)
    })
  }

  const awardGrant = () => {
    const reviewParams = {
      route: `/grant_applications/${grantApplication.id}/award`,
      data: {},
      object: "grant_application",
      callback: setGrantApplication,
      message: "Grant awarded successfully!"
    }

    dispatch(updateObject(reviewParams))
  }

  useEffect(() => {
    !grantApplication && getGrantApplication()
  }, [])

  useEffect(() => {
    grantApplication && formsList.length === 0 && getForms()
  }, [grantApplication])

  useEffect(() => {
    grantApplication && getGrantApplicationReview()
  }, [grantApplication])

  useEffect(() => {
    if (!_.isEmpty(response) && response.success !== null && response.message) {
      const { message, success } = response;
        
      success ? toast.success(message) : toast.error(message);
    }
  },[response])

  return (
    <div className="px-4 pt-4 pb-3 mb-3">
      { isLoading
        ?
        <Spinner />
        :
        <>
          { formsList.length > 0 && grantApplication &&
            <>
              <div className="d-flex flex-row justify-content-between mb-3">
                <h3 className="mb-0">{grantApplication?.applicant.full_name}</h3>
                <BackLink history={history} backUrl={backUrl}>
                  Back to Applications
                </BackLink>
              </div>
              <ApplicationFormsTable formsList={formsList} grantApplication={grantApplication} />
              <div className="d-flex flex-row justify-content-between mb-0">
                <h3 className="mt-3 mb-3">Jury Reviews</h3>
              </div>
              { (isProgramOfficer || isAdmin()) &&
                  <ApplicationReviews grantCampaign={grantCampaign} match={match}/>
              }
              { (!isProgramOfficer && isAdmin() || isJuryMember) &&
                <ApplicationReview grantApplication={grantApplication} grantApplicationReview={grantApplicationReview} getGrantApplicationReview={getGrantApplicationReview} awardGrant={awardGrant} isJuryMember={isJuryMember} response={response}/>
              }
            </>
          }
        </>
      }
    </div>
  )
}
