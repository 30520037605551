import React, { Component } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

class MembersGraph extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={this.props.data}
          margin={{
            top: 10,
            right: 30,
            left: -10,
            bottom: 0
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend
            align="left"
            wrapperStyle={{ marginLeft: "50px" }}
            payload={[
              {
                value: "Newly Registered Users",
                type: "square",
                color: "#FF6C60"
              },
              {
                value: "Active Members",
                type: "square",
                color: "#7DB6ED"
              }
            ]}
          />
          <Area
            dataKey="newly_registered_users"
            stackId="1"
            stroke="#FF6C60"
            fill="#FFC4BF"
            dot={true}
          />
          <Area
            dataKey="active_members"
            stackId="1"
            stroke="#7DB6ED"
            fill="#DDF1FF"
            dot={true}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default MembersGraph;
