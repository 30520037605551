import React from "react"
import Pagination from "../UI/Pagination"

const Footer = ({ pageItems, setPage, labelsCount, page }) => {
  const paginationHandler = (page) => {
    setPage(page["selected"] + 1)
  }

  let totalItems = labelsCount < pageItems ? labelsCount : pageItems

  let pageTotal =
    labelsCount < page * totalItems ? labelsCount : page * totalItems

  return (
    <div className="d-flex">
      <div className="flex-fill d-flex justify-content-start align-items-center ml-2 pt-2">
        {`Showing ${
          (page - 1) * pageItems + 1
        } to ${pageTotal} of ${labelsCount}`}
      </div>
      <div>
        <Pagination
          pageCount={labelsCount / pageItems}
          handlePageClick={paginationHandler}
        />
      </div>
    </div>
  )
}

export default Footer
