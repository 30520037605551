import React, { Component } from "react";
import dateFns from "date-fns";
import { toast } from "react-toastify";
import Pagination from "../UI/Pagination";
import Spinner from "../UI/Spinner";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import DatePicker from "../UI/datePicker/index";
import axios from "../../configAxios";
import "./index.css";

class ServicesInKind extends Component {
  constructor() {
    super();
    const currentMonthDate = new Date();
    this.signal = axios.CancelToken.source();
    this.state = {
      startDate: new Date().setMonth(currentMonthDate.getMonth() - 1),
      endDate: new Date(),
      servicesInKind: [],
      selectedPage: 1,
      metaData: {},
      forcePage: "",
      reportOptions: null,
      loading: true,
      disabled: true
    };
  }

  componentDidMount() {
    const queryParams = {
      id: "ServicesInKindReport",
      page: this.state.selectedPage,
      per_page: 30
    };
    this.getServicesInKind(queryParams);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getServicesInKind = queryParams => {
    axios
      .get("/reports/services_in_kind", {
        params: queryParams,
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          servicesInKind: res.data["reports/services_in_kind"],
          metaData: res.data.meta,
          loading: false,
          disabled: false
        });
      });
  };

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      forcePage: page.selected,
      loading: true
    });
    const queryParams = {
      id: "ServicesInKindReport",
      report_options: this.state.reportOptions,
      page: page.selected + 1,
      per_page: 30
    };
    this.getServicesInKind(queryParams);
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ loading: true, selectedPage: 1, disabled: true });
    const { startDate, endDate } = this.state;
    const report_options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD")
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD")
      }
    };
    this.setState({ reportOptions: report_options });
    const queryParams = {
      id: "ServicesInKindReport",
      report_options,
      page: 1,
      per_page: 30
    };
    this.getServicesInKind(queryParams);
  };

  downloadReport = async () => {
    try {
      if (this.state.downloading) return;
      this.setState({ downloading: true });
      const { startDate, endDate } = this.state;
      const report_options = {
        start: {
          year: dateFns.format(startDate, "YYYY"),
          month: dateFns.format(startDate, "MM"),
          day: dateFns.format(startDate, "DD")
        },
        end: {
          year: dateFns.format(endDate, "YYYY"),
          month: dateFns.format(endDate, "MM"),
          day: dateFns.format(endDate, "DD")
        }
      };
      let report = await axios.get("/reports/services_in_kind/download_csv", {
        params: {
          id: "ServicesInKindReport",
          report_options
        },
        cancelToken: this.signal.token
      });
      if (report.data.json.generating) {
        toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
        let timerId = setInterval(
          () => this.checkDownloadProgress(report.data.json.id),
          5000
        );
        this.setState({ timerId });
      }
    } catch (err) {
      this.setState({ downloading: false });
      console.log(err);
    }
  };

  checkDownloadProgress = async id => {
    try {
      let progress = await axios.get(
        "/reports/services_in_kind/check_generating",
        {
          params: { id },
          cancelToken: this.signal.token
        }
      );
      if (typeof progress.data.json === "string") {
        clearInterval(this.state.timerId);
        this.setState({ downloading: false });
        toast.success("File successfully downloaded");
        window.open(progress.data.json, "_blank");
        return;
      }
    } catch (e) {
      toast.error("Downloading failed");
      clearInterval(this.state.timerId);
    }
  };

  render() {
    const { servicesInKind } = this.state;

    let servicesInKindList;
    servicesInKindList = servicesInKind.map(service => (
      <tr key={service.id}>
        <td>
          {service.name_for_reference}
          <div>{`Invoice #${service.reference}`}</div>
        </td>
        <td>{dateFns.format(service.invoice_date, "YYYY-MM-DD")}</td>
        <td>
          <b>Invoice Notes: </b>
          {service.notes}
          <div>
            <b>
              {service.ticket_details
                ? "Ticket Details: "
                : "Registration Detail: "}
            </b>
            {service.ticket_details
              ? service.ticket_details
              : service.registration_details}
          </div>
        </td>
        <td>${service.subtotal_due}</td>
        <td>${service.credit_amount}</td>
      </tr>
    ));

    return (
      <div className="container service-kind w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Services in Kind Report</h3>
          </div>
          <div className="col-6 text-right">
            <Button
              type="success"
              clicked={this.downloadReport}
            >
              Generate CSV
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <form onSubmit={this.submitHandler}>
                    <div className="row">
                      <div className="col-3">
                        <label>From</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.startDate}
                          changed={date => this.setState({ startDate: date })}
                        />
                      </div>
                      <div className="col-3">
                        <label>To</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.endDate}
                          changed={date => this.setState({ endDate: date })}
                        />
                      </div>
                      <div className="col-3">
                        <FormButton className="build-report" disabled={this.state.disabled}>Build Report</FormButton>
                      </div>
                    </div>
                  </form>
                  <div className="row mt-5">
                    <div className="col-12">
                      <h5 className="font-weight-bold">
                        Statistics
                      </h5>
                      {
                        this.state.loading ? <Spinner className="h-30vh" /> : (
                          <React.Fragment>
                            <div className="row">
                              <div className="col-4">
                                Total Num. of Service In Kind Offsets:
                              </div>
                              <div className="col-8">
                                {this.state.loading ? 0 : this.state.metaData.total_num_of_service_in_kind_offsets}
                              </div>
                              <div className="col-4">
                                Total Num. of Service In Kind Offsets:
                              </div>
                              <div className="col-8">
                                {this.state.loading ? 0 : this.state.metaData.total_val_of_service_in_kind_offsets}
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      }
                    </div>
                  </div>
                  <div className="list mt-5">
                    <h5 className="font-weight-bold">
                      Invoices with Service In Kind Offsets
                    </h5>
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        style={{ tableLayout: this.state.loading ? "fixed" : "" }}
                      >
                        <thead className="border-top">
                          <tr>
                            <th className="w-20">Invoices For</th>
                            <th className="w-20">Invoices Date</th>
                            <th className="w-20">Invoices Details</th>
                            <th className="w-20">Invoice Subtotal</th>
                            <th className="w-20">Service in Kind Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!this.state.loading &&
                            servicesInKindList
                          }
                        </tbody>
                      </table>
                    </div>
                    {this.state.loading && <Spinner className="h-30vh" />}
                  </div>
                  <Pagination
                    forcePage={this.state.selectedPage - 1}
                    pageCount={this.state.metaData.total_count / 30}
                    handlePageClick={this.paginationHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesInKind;
