import React, { Component } from "react";
import Button from "../../../UI/button/Button";
import FormButton from "../../../UI/button/FormButton";
import { Link } from "react-router-dom"
import dateFns from "date-fns";


class UserList extends Component {

  submitHandler = (event, verify) => {
    event.preventDefault();
    this.props.verifyExpireHandler(event, verify);
  };

  render() {
    const userList = this.props.userData && this.props.userData.map(user => {
      return <div className="row cursor" key={user[0]}>
        <p className="col-6" ><Link to={`/users/${user[0]}/summary`}>{user[1]}{user[2]}</Link></p>
        <p className="col-6" >{user[3] ? dateFns.format(new Date(user[3]), "DD-MM-YYYY") : "--"}</p>
      </div>

    });


    return (
      <React.Fragment>
        <form className="mt-3" onSubmit={(event) => this.submitHandler(event, true)}>
          <div className="pl-3"
            style={{ overflowY: "scroll", height: "330px", width: "100%" }}
          >
            <div className="row cursor font-weight-bold">
              <p className="col-6" >FullName</p>
              <p className="col-6" >Membership Expires</p>
            </div>
            <div>{userList}</div>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <Button title="Reject if you donot want to change"
              className="ml-2"
              type="danger"
              clicked={(event) => this.submitHandler(event, false)}
            >Reject</Button>
            <FormButton
              title="Changes all the listed users to lifetime membership"
              className="ml-2"
            >Confirm </FormButton>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default UserList;
