import React, { useState, useEffect } from 'react'

export default function FileUploadQuestion({ question, response, setResponseData, completed }) {

  const [uploadedFile, setUploadedFile] = useState(response && response.file_attachment || null)

  useEffect(() => {
    if (!response || response && !response.file_attachment && !uploadedFile) {
      return
    }
    setResponseData(question.id, {file_attachment: uploadedFile})
  }, [uploadedFile])

  const clearUploadedFile = () => {
    let fileInput = document.querySelector("#file-input")
    if (fileInput) return fileInput.value = ""
  }
  
  const uploadedFileName = () => {
    const fileNameMatch = uploadedFile.file.url.match(/.*\/(.*)\?/)
    return fileNameMatch ? fileNameMatch[1] : uploadedFile.file.url.split("/").pop()
  }

  const setFileString = () => {
    if (uploadedFile) {
      return uploadedFile.file ? uploadedFileName() : uploadedFile.name
    }
  }
  
  const setDownloadLink = () => {
    if (uploadedFile) {
      return uploadedFile.file ? uploadedFile.file.url : URL.createObjectURL(uploadedFile)
    }
  }

  const clearValidationError = () => {
    const element = document.getElementById("file-input")
    element.validity.customError && !element.validity.valueMissing && element.setCustomValidity('')
  }

  useEffect(() => {
    uploadedFile && clearValidationError()
  }, [uploadedFile])

  return (
   <div className="mb-4">
      <p className="mb-2 font-weight-bold">{question.question_text}</p>
      <div className="mb-3 ml-3 d-flex flex-row justify-content-start align-items-center">
        <input
          hidden={uploadedFile}
          id="file-input"
          type="file"
          accept=".doc,.docx,application/msword,application/pdf"
          onChange={e => setUploadedFile(e.target.files[0] || null)}
          required={question.mandatory && !uploadedFile}
          onInvalid={e => e.target.setCustomValidity("Please upload a file")}
          disabled={completed}
        />
        <a target="_blank" href={setDownloadLink()}>{setFileString()}</a>
        { completed ||
          <span
            hidden={!uploadedFile}
            className="ml-3 cursor fas fa-sm fa-times"  
            data-toggle="tooltip" 
            data-placement="top" 
            title="Remove File" 
            onClick={() => {
              setUploadedFile(null)
              clearUploadedFile()
            }}
          />
        }
      </div>
    </div>
  )
}
