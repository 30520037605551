import React, { Component } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { title } from "change-case";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Button from "../UI/button/Button";
import Modal from "../UI/Modal";
import langUtils from "../../utils/langUtils";

import CollectionListItem from "./CollectionListItem";
import CreateCollection from "./CreateCollection";

class Collections extends Component {
  signal = axios.CancelToken.source();
  state = {
    collectionsList: [],
    loading: true,
    createModal: false,
    editModal: false,
    showCollectionModal: false,
    collection: {},
    id: null,
    showReels: null,
    showCollections: null
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getCollections();
    this.getPolicy();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getCollections = () => {
    let fetchedList = [];
    axios
      .get("/collections", { cancelToken: this.signal.token })
      .then(res => {
        for (let key in res.data.collections) {
          fetchedList.push({
            ...res.data.collections[key],
            id: res.data.collections[key].id
          });
        }
        this.setState({ loading: false, collectionsList: fetchedList });
      })
      .catch(error => this.setState({ loading: false }));
  };

  getPolicy = () => {
    axios.get("/projects/policy").then((res) => {
      this.setState({
        showReels: res.data.json.reels,
        showCollections: res.data.json.collections,
      })
    })
  }

  deleteCollectionHandler = id => {
    let response = window.confirm("Permanently delete collection?");
    if (response) {
      axios.delete(`/collections/${id}`).then(res => {
        if (res.data.json.success) {
          toast.success("Collection successfully deleted.");
          this.getCollections();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      });
    }
  };

  getCollectionData = async (key, id) => {
    await axios
      .get(`/collections/${id}`, { cancelToken: this.signal.token })
      .then(res => {
        let collection = {};
        collection = res.data.json.data;
        this.setState({ collection, [key]: true });
      });
  };

  render() {
    const { collection } = this.state;
    let collectionsItem;
    if (this.state.loading) {
      collectionsItem = <Spinner />;
    } else {
      collectionsItem = this.state.collectionsList.map(listItem => (
        <CollectionListItem
          key={listItem.id}
          collection={listItem}
          deleteCollection={this.deleteCollectionHandler}
          editModal={() => this.getCollectionData("editModal", listItem.id)}
          showCollectionModal={() =>
            this.getCollectionData("showCollectionModal", listItem.id)
          }
        />
      ));
    }

    const showCollectionModal = (
      <Modal
        show={this.state.showCollectionModal}
        closeModal={() => this.setState({ showCollectionModal: false })}
        hide={() => this.setState({ showCollectionModal: false })}
        closeButton
        title="Collection Details"
      >
        <div className="col-md-12 mt-2">
          <h4>
            <b>Name</b>
          </h4>
          <h5>{collection.name}</h5>
          {collection.projects && collection.projects.length ? (
            <div>
              <h4>
                <b>Projects</b>
              </h4>
              <h5>
                {collection.projects.map(project => (
                  <div>{project.name}</div>
                ))}
              </h5>
            </div>
          ) : (
              ""
            )}
          {collection.reels && collection.reels.length ? (
            <div>
              <h4>
                <b>Reels</b>
              </h4>
              <h5>
                {collection.reels.map(reel => (
                  <div>{reel.name}</div>
                ))}
              </h5>
            </div>
          ) : (
              ""
            )}
        </div>
      </Modal>
    );
    return (
      <div className="container collections w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Collections</h3>
          </div>
          <div className="col-6 text-right">
            <Button
              type="success"
              clicked={() => { window.scrollTo(0, 0); this.setState({ createModal: true }) }}
            >
              {langUtils("txt_global_add_new", "Add New")}
            </Button>
          </div>
        </div>

        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs font-weight-bold">
                <li className="nav-item px-4 inactive-tab">
                  <NavLink className="text-dark decoration-none" to="/projects">
                    Projects
                  </NavLink>
                </li>
                {this.state.showReels && (
                  <li className="nav-item px-4 inactive-tab">
                    <NavLink className="text-dark decoration-none" to="/reels">
                      Reels
                    </NavLink>
                  </li>
                )}
                {this.state.showCollections && (
                  <li className="nav-item px-4 active-tab">
                    <NavLink className="decoration-none" to="/collections">
                      Collections
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <ul className="list-group list-group-flush">
            <li className="list-group-item border-top-0">
              <div className="row">
                <div className="col-1">
                  <span className="font-weight-bold">ID</span>
                </div>
                <div className="col-1">
                  <span className="font-weight-bold">Name</span>
                </div>
              </div>
            </li>
            {collectionsItem}
          </ul>
        </div>

        <Modal
          show={this.state.createModal}
          title="New Collection"
          hide={() => this.setState({ createModal: false })}
        >
          <CreateCollection
            isEdit={false}
            closeModal={() => this.setState({ createModal: false })}
            getCollections={this.getCollections}
          />
        </Modal>
        <Modal
          show={this.state.editModal}
          title="Edit Collection"
          flag={true}
          hide={() => this.setState({ editModal: false })}
        >
          <CreateCollection
            isEdit={true}
            collection={this.state.collection}
            closeModal={() => this.setState({ editModal: false })}
            getCollections={this.getCollections}
          />
        </Modal>
        {showCollectionModal}
      </div>
    );
  }
}

export default Collections;
