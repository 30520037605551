import React, { useRef, useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"

import Input from "../../UI/input/Input"
import FormButton from "../../UI/button/FormButton"
import Button from "../../UI/button/Button"
import onError from "../../../utils/onError"

const CreatePromoForm = ({ programId, setPromos }) => {
  const formRef = useRef(null)
  const initialForm = { code: null, discountPercent: null, name: null }
  const [form, setForm] = useState(initialForm)

  const onChangeHandler = (e, key) => {
    e.persist()

    setForm((prev) => {
      return { ...prev, [key]: e.target.value }
    })
  }

  const onClearHandler = () => {
    const formValues = formRef.current

    formValues['code'].value = initialForm.code
    formValues['discount'].value = initialForm.discountPercent
    formValues['name'].value = initialForm.name

    setForm(initialForm)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()

    axios
      .post(`/programs/${programId}/promos`, {
        code: form.code,
        discount_percent: form.discountPercent,
        name: form.name
      })
      .then((res) => {
        const { data, promos: allPromos } = res.data.json
        setPromos(allPromos)

        toast.dismiss()
        toast.success(`Promo ${data.code} successfully created`)
      })
      .catch((err) => {
        const { errors: allErrors } = err.response.data.json
        allErrors.forEach((error) => onError(error))
      })
      .finally(() => {
        onClearHandler()
      })
  }

  return (
    <form ref={formRef} onSubmit={onSubmitHandler}>
      <div className="mt-2 d-flex justify-content-between">
        <Input
          elementType="input"
          elementConfig={{ type: "text", id: "promo-code", name: "code", placeholder: "(Max 15, A-Z, 0-9)", required: true }}
          label="Code"
          value={form.code}
          changed={(e) => onChangeHandler(e, 'code')}
        />
        <Input
          elementType="number"
          elementConfig={{ type: "number", id: "promo-discount", min: 0, max: 100, name: "discount", placeholder: 0, required: true }}
          label="Discount Percent"
          value={form.discountPercent}
          changed={(e) => onChangeHandler(e, 'discountPercent')}
        />
        <Input
          elementType="input"
          elementConfig={{ type: "text", id: "promo-name", name: "name", placeholder: "Enter a name", required: true }}
          label="Name"
          value={form.name}
          changed={(e) => onChangeHandler(e, 'name')}
        />
      </div>
      <div className="d-flex flex-row justify-content-between">
        <Button type="success" className="float-none" clicked={onClearHandler}>
          Clear Form
        </Button>
        <FormButton type="success" className="float-none">
          Create Promo
        </FormButton>
      </div>
    </form>
  )
}

export default CreatePromoForm
