import Input from "../../UI/input/Input";
import React from "react";

const ActiveProjectsCheckbox = ({ changed, activeProjectFilter }) => {
  return <Input
    key="activeProjectFilter"
    elementType="input"
    elementConfig={{
      name: "inactive",
      type: "checkbox",
      checked: activeProjectFilter
    }}
    label="Search only Active Projects"
    checkboxRequired={true}
    changed={changed}
  />;
}

export default ActiveProjectsCheckbox
