import React from "react";
import dateFns from "date-fns";

const BookingActivityFacility = props => {
  let assets = props.booking.assets.sort((a, b) =>
    new Date(a.details.start_time) - new Date(b.details.start_time));
  let assetsList = assets.map(asset => {
    let title;
    if (asset.userOrProgram === "User") {
      title =
        asset.userOrProgramDetail.organization_name ||
        `${asset.userOrProgramDetail.first_name} ${
        asset.userOrProgramDetail.last_name
        }`;
    } else {
      title = asset.userOrProgramDetail.name;
    }
    return (<div className="mb-1" key={`${asset.details.start_time}-${asset.details.end_time}`}>
      {` ${title} - ${asset.ticket.project_name ? asset.ticket.project_name : "No Project Name"}
           - ${dateFns.format(new Date(asset.details.start_time), "hh:mmA")} / 
           ${dateFns.format(new Date(asset.details.end_time), "hh:mmA")}`}</div>
    )
  })
  return (
    <li className="list-group-item px-3 pt-3">
      <div className="row">
        <div className="col-12">
          <div className="text-primary pb-2">
            {props.booking.info}
          </div>
          <div>
            {assetsList}
          </div>
        </div>
      </div>
    </li>
  );
};

export default BookingActivityFacility;