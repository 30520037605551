import React, { Component } from "react";

import axios from "../../../../configAxios";
import Button from "../../../UI/button/Button";
import Modal from "../../../UI/Modal";
import Spinner from "../../../UI/Spinner";
import langUtils from "../../../../utils/langUtils";

import AddOrEditAccreditation from "./AddOrEditAccreditation";

class Accreditatons extends Component {
  signal = axios.CancelToken.source();
  state = {
    cred: [],
    credModal: false,
    editCred: false,
    credId: null,
    credLevels: [],
    loader: true
  }

  async componentDidMount() {
    const res = await axios.get("/creds/formdata", { cancelToken: this.signal.token });
    const credLevels = res.data.json.cred_levels;
    this.setState({ credLevels }, () => this.getCreds());
  }

  getCreds = () => {
    axios.get("/creds", { cancelToken: this.signal.token })
      .then(res => {
        const creds = res.data.creds;
        this.setState({ creds, loader: false })
      })
  }

  render() {
    const { creds, credLevels } = this.state;
    const tableBody = creds && creds.map(cred => {
      const level = credLevels && credLevels.filter(level =>
        level[0] === cred.cred_level_id ? true : false
      )[0][1];
      return (
        <div className="list-group-item" key={cred.id}>
          <div className="row">
            <div className="col-6">
              {cred.name}
            </div>
            <div className="col-4 text-center">
              {level}
            </div>
            <div className="col-2 text-center">
              <i
                class="fas fa-pencil-alt cursor"
                onClick={() => { window.scrollTo(0, 0); this.setState({ credModal: true, editCred: true, credId: cred.id }) }}
              />
            </div>
          </div>
        </div>
      )
    })
    return (
      <div className="accreditations">
        <div className="row">
          <div className="col-12 py-3 px-5 text-lg-right text-center">
            <Button type="success" clicked={() => { window.scrollTo(0, 0); this.setState({ credModal: true, editCred: false }) }}>
              {langUtils("txt_global_add_new", "Add New")}
            </Button>
          </div>
        </div>
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="row">
              <div className="col-6">
                <span className="font-weight-bold">Accreditation Name</span>
              </div>
              <div className="col-4 text-center">
                <span className="font-weight-bold">Accreditation Level</span>
              </div>
              <div className="col-2"></div>
            </div>
          </div>
          {!this.state.loader && tableBody}
        </div>
        {this.state.loader && <Spinner />}
        <Modal
          title={this.state.editCred ? "Edit Accreditation" : "Add New Accreditation"}
          show={this.state.credModal}
          hide={() => this.setState({ credModal: false })}
          closeModal={() => this.setState({ credModal: false })}
          closeButton
        >
          <AddOrEditAccreditation closeModal={() => this.setState({ credModal: false })}
            getCreds={this.getCreds} editCred={this.state.editCred} credId={this.state.credId}
            credLevels={this.state.credLevels} />
        </Modal>
      </div>
    )
  }
}

export default Accreditatons;