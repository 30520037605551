import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from "lodash";
import Input from "../../../../UI/input/Input";
import { createObject, updateObject } from '../../../../../store/actions/index'
import FormButton from "../../../../UI/button/FormButton";
import Button from "../../../../UI/button/Button";
import { toast } from "react-toastify"

function ApplicationReview({ grantApplication, grantApplicationReview, getGrantApplicationReview, isJuryMember }) {

  const [comments, setComments] = useState(grantApplicationReview && grantApplicationReview.comments || "")
  const [rating, setRating] = useState(grantApplicationReview && grantApplicationReview.rating || "")
  const [isEditing, setIsEditing] = useState(false)

  const dispatch = useDispatch()
  
  const RatingSelect = () => {
    return (
      <div className="d-flex flex-column justify-content-start mb-3">
        <span className="mb-2 font-weight-bold">Rating</span>
        <span>
          <select className="w-5 text-center font-weight-bold text-muted" onChange={e => setRating(e.target.value)} disabled={!isEditing} required defaultValue="" value={rating} onInvalid={e => e.target.setCustomValidity("Please select a rating")}>
            <option disabled hidden value={""} />
            {
              new Array(10).fill("").map((x, i) => {
                return <option key={i} value={i + 1}>{i + 1}</option>
              })
            }
          </select>
        </span>
      </div>
    )
  }

  const createGrantApplicationReview = () => {
    const reviewParams = {
      route: "/grant_application_reviews",
      data: { grant_application_id: grantApplication.id, status: "Saved", comments, rating },
      object: "grant_application_review",
      callback: getGrantApplicationReview,
      message: "Review saved successfully"
    }

    dispatch(createObject(reviewParams))
  }

  const updateGrantApplicationReview = () => {
    const reviewParams = {
      route: `/grant_application_reviews/${grantApplicationReview.id}`,
      data: { status: "Saved", comments, rating },
      object: "grant_application_review",
      callback: getGrantApplicationReview,
      message: "Review saved successfully"
    }

    dispatch(updateObject(reviewParams))
  }
  
  const Buttons = () => {
    return (
      <div className="d-flex justify-content-end">
        <div>
          <Button className="mr-2" type="success" clicked={() => setIsEditing(prev => !prev)}>
            Edit
          </Button>
          <FormButton disabled={!isEditing} >
            Save
          </FormButton>
        </div>
      </div>
    )
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!isJuryMember) return toast.error('You must be a jury member')
    return grantApplicationReview && grantApplicationReview.id ? updateGrantApplicationReview() : createGrantApplicationReview()
  }

  return (
    <form className="d-flex flex-column justify-content-between" onSubmit={handleSubmit}>
      <RatingSelect />
      <div className="mb-2 w-100">
        <p className="mb-2 font-weight-bold">Comments/Notes</p>
        <Input
          elementType="textarea"
          elementConfig={{
            name: 'comments',
            rows: 3,
          }}
          changed={e => setComments(e.target.value)}
          value={comments}
          disabled={!isEditing}
        />
      </div>
      <Buttons />
    </form>
  )
}

export default ApplicationReview
