import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import Search from "./Search";
import Items from "./Items";
import Button from "../UI/button/Button";
import "./index.css";
import Modal from "../UI/Modal"
import Input from "../UI/input/Input"
import FormButton from "../UI/button/FormButton"
import { connect } from "react-redux"
import axios from "../../configAxios"
import { toast } from "react-toastify"


class Forms extends Component {
  state = {
    refreshList: false,
    selectedForm: null,
    grant_campaign_id: this.props.grantCampaign.id,
    createForm: {
      formName: {
        name: "formName",
        elementType: "input",
        elementConfig: {
          name: "name",
          type: "text",
          required: true,
        },
        value: "",
        label: "Form Name",
      },
      formDescription: {
        name: "formDescription",
        elementType: "textarea",
        elementConfig: {
          name: "description",
          type: "text",
          required: true,
        },
        value: "",
        label: "Form Description"
      }
    },
    enteredUserCharacters: "",
    disabled: false,
  };

  componentDidUpdate() {
    this.state.selectedForm && !this.state.showModal && this.openModal()
  }

  openModal = () => {
    window.scrollTo(0, 0);
    this.setState({
      showModal: true,
      refreshList: false,
    });
  };

  closeModal() {
    this.setState({ showModal: false })
  }

  handleNameChange(event) {
    this.setState({ ...this.state, createForm: { ...this.state.createForm, formName: { ...this.state.createForm.formName, value: event.target.value } } })
  }

  handleDescriptionChange(event) {
    this.setState({ ...this.state, createForm: { ...this.state.createForm, formDescription: { ...this.state.createForm.formDescription, value: event.target.value } } })
  }

  submitHandler = (event) => {
    event.preventDefault()

    this.setState({ disabled: true });

    let formState = this.state.createForm

    let payload = {
      grant_campaign_id: this.state.grant_campaign_id,
      name: formState.formName.value,
      description: formState.formDescription.value,
    }

    axios.post("/forms", payload).then(() => {
      toast.success("Form Created!")
      this.closeModal()
      this.setState({ disabled: false })
      this.props.history.go(0)
    }).catch(() => {
      toast.error("Could not create form.")
    })
  }

  render() {
    let { formName, formDescription } = this.state.createForm;

    return (
      <div className="container w-100 p-0">
        <div className="mx-4 mt-3 mb-5">
          <div className="d-flex flex-row justify-content-between">
            <h3 className="">Forms</h3>
            <Button type='success' clicked={this.openModal}>
              Add New Form
            </Button>
          </div>
        </div>

        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs font-weight-bold">
                <li className="nav-item px-4 active-tab">
                  <NavLink className="decoration-none" to="/forms">
                    Forms
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body p-0">
            <div className="card-text">
              <div className="row">
                <div className="col-lg-5">
                  <Search refreshList={this.state.refreshList} />
                </div>
                <div className="col-lg-7">
                  <Items 
                    grantCampaign={this.props.grantCampaign}
                    setSelectedForm={(formItem) => this.setState({selectedForm: formItem})}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Create a Form"
          show={this.state.showModal}
          flag={true}
          hide={() => this.closeModal()}
        >
          <form onSubmit={(e) => this.submitHandler(e)} className="mt-4 p-4 card">
            <Input
              key={formName.name}
              {...formName}
              changed={(event) =>
                this.handleNameChange(event)
              }
              onInputChange={(event) =>
                this.setState({ enteredUserCharacters: event })
              }
            />
            <Input
              key={formDescription.name}
              {...formDescription}
              changed={(event) =>
                this.handleDescriptionChange(event)
              }
              onInputChange={(event) =>
                this.setState({ enteredUserCharacters: event })
              }
            />
            <div className="text-center mt-3">
              <Button type="secondary" clicked={() => this.closeModal()}>
                Cancel
              </Button>
              <FormButton className="ml-2" disabled={this.state.disabled}>
                Create Form
              </FormButton>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.response,
  };
};

export default connect(mapStateToProps)(withRouter(Forms));
