import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Modal } from "react-bootstrap";

import MediaCard from "./MediaCard";

import MediaListView from "../project/MediaListView";

import "react-html5video/dist/styles.css"
import "react-html5video/dist/styles.css";

const baseStyle = {
  borderWidth: 1,
  borderColor: "#e3e3e3",
  borderStyle: "solid"
};
const activeStyle = {
  borderStyle: "solid",
  borderColor: "#6c6",
  backgroundColor: "#eee"
};
const rejectStyle = {
  borderStyle: "solid",
  borderColor: "#c66",
  backgroundColor: "#eee"
};

class Media extends Component {
  state = {
    options: [],
    files: [],
    fileLink: null,
    attributes: [],
    showPreview: false,
    showListModal: false
  };

  onDrop = (files) => {
    this.setState({
      files,
      fileLink: URL.createObjectURL(files[0])
    }, () => this.props.addMediaHandler(files[0]));
  }

  removeFileHandler = () => {
    this.setState({
      files: [],
      fileLink: null,
      showPreview: false
    }, () => this.props.addMediaHandler(null))
  }

  render() {
    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));
    
    let mediaCard = null;
    if(this.props.editUser && this.props.fileAttachments.length > 0) {
      mediaCard = this.props.fileAttachments.map(fileAttached => {
        const filenameWithExt = fileAttached.file.url.split("?")[0].split("/").pop();
        const filenameWithoutExt = filenameWithExt && filenameWithExt.substring(0, filenameWithExt.lastIndexOf("."));
        const filename = filenameWithoutExt && 
          filenameWithoutExt.substring(filenameWithoutExt.length -15, filenameWithoutExt.length);

        return (
          <MediaCard
            file={filename}
            fileLink={fileAttached.file.url}
            removeFile={this.removeFileHandler}
            alreadyUploaded={false}
            editUser={true}
          />
        )
      })
    } else mediaCard = null

    return (
      <div className="mb-2">
        <button
          className="btn header-btn"
          onClick={() => this.props.tabHandler("media")}
          type="button"
          data-toggle="collapse"
          data-target=".Media"
          aria-expanded="false"
          aria-controls="multiCollapse"
        >
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Media</p>
            <i
              className={
                this.props.selectedTabs.includes("media")
                  ? "fas fa-minus-circle text-primary fa-2x"
                  : "fas fa-plus-circle text-primary fa-2x"
              }
            />
          </div>
        </button>
        <div className="row">
          <div className="col-12">
            <div className="collapse Media" id="multiCollapse">
              <div className="row">
                <div className="col-md-5">
                  <h5 className="ml-2 mt-3">
                    <b>Choose file via computer</b>
                  </h5>
                  <Dropzone
                    onDrop={this.onDrop}
                    accept="image/*, application/pdf"
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject
                    }) => {
                      let styles = { ...baseStyle };
                      styles = isDragActive
                        ? { ...styles, ...activeStyle }
                        : styles;
                      styles = isDragReject
                        ? { ...styles, ...rejectStyle }
                        : styles;

                      return (
                        <div {...getRootProps()} style={styles} className="ml-2 mb-2 cursor">
                          <input {...getInputProps()} />
                          <div className="text-center">
                            <div style={{ marginTop: 30, color: "gray" }}>
                              <span className="text-center fas fa-folder-open mb-2" />
                              <div className="text-center">
                                Choose a file or drag it here
                              </div>
                              <div className="col-md-12 mb-3 text-center">
                                {files.length ? files : "(No file selected)"}
                              </div>
                            </div>
                          </div>
                          {isDragReject && <div className="text-center">Unsupported file type...</div>}
                        </div>
                      );
                    }}
                  </Dropzone>
                  <p className="ml-2 text-center font-weight-bold text-dark">
                    <small>
                      <em>
                        <span className="text-danger">*</span> Images and PDF files only allowed
                        </em>
                    </small>
                  </p>
                </div>
              </div>
              {this.props.editUser && this.props.fileAttachments.length > 0 &&
                <div className="row">
                  <div className="col-12 text-right">
                    <span
                      className="mr-4 mt-4 cursor bg-primary py-1 px-2"
                      onClick={() => this.setState({ showListModal: true })}
                    >
                      <i className="fas fa-download text-light" />
                    </span>
                  </div>
                </div>
              }
              <div className="row">
                {this.state.fileLink ? 
                  <MediaCard
                    file={this.state.files}
                    fileLink={this.state.fileLink}
                    removeFile={this.removeFileHandler}
                    alreadyUploaded={false}
                    editUser={false}
                  />
                :null
                }
                {mediaCard}
              </div>
              <Modal
                className="project-media-modal"
                show={this.state.showListModal}
                onHide={() => this.setState({
                  showListModal: false
                })}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Select to download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <MediaListView
                    attributes={this.props.fileAttachments}
                    section="User"
                    id={this.props.userId}
                    name={this.props.username}
                    closeListView={() => this.setState({
                      showListModal: false
                    })}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default Media;