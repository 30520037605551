import React from "react"
import { format } from "date-fns"
import { Link } from "react-router-dom"

const ShowDonationInfo = ({ donationDetails }) => {
  return (
    <div className="container-fluid">
      <div className="row my-2">
        <div className="col-lg-6">
          <div className="font-weight-bold">Campaign</div>
          <div>{donationDetails.donation_campaign.name}</div>
        </div>
        <div className="col-lg-6">
          <div className="font-weight-bold">Amount</div>
          <div>{"$"}{donationDetails.amount}</div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-6">
          <div className="font-weight-bold">Donation Date</div>
          <div>{format(donationDetails.donation_date, "YYYY.MM.DD")}</div>
        </div>
        <div className="col-lg-6">
          <div className="font-weight-bold">Recognition</div>
          <div>{donationDetails.recognition}</div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-6">
          <div className="font-weight-bold">Last Contact</div>
          <div>{donationDetails.donor.last_contact
            ? format(new Date(donationDetails.donor.last_contact), "YYYY.MM.DD")
            : "---"
          }
          </div>
        </div>
        <div className="col-lg-6">
          <div className="font-weight-bold">Donor</div>
          <div>
            <Link
              className="text-secondary"
              to={`/users/${donationDetails.donor.id}/summary`}
            >
              <span>
                {donationDetails.donor.first_name}
              </span>
              {" "}
              <span>
                {donationDetails.donor.last_name}
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-6">
          <div className="font-weight-bold">Tax Receipt</div>
          <div>{donationDetails.requires_tax_receipt ? "Yes" : "No"}</div>
        </div>
        <div className="col-lg-6">
          <div className="font-weight-bold">Thanked</div>
          <div>{donationDetails.has_thanked ? "Yes" : "No"}</div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-6">
          <div className="font-weight-bold">Notes</div>
          <div>{donationDetails.notes ? donationDetails.notes : "---"}</div>
        </div>
      </div>
    </div>
  )
}

export default ShowDonationInfo
