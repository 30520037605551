import React, { Component } from "react";
import _ from "lodash"
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";
import axios from '../../../../configAxios';
import Button from "../../../UI/button/Button";
import Spinner from "../../../UI/Spinner";
import langUtils from "../../../../utils/langUtils";

import UserList from "./UserList";

import "./../index.css";

class Users extends Component {
  signal = axios.CancelToken.source();
  state = {
    tabNames: [],
    activeTab: "",
    loading: true,
    newModal: false,
    forcePage: 0
  };

  componentDidMount() {
    axios.get("/roles", {
      params: {
        organization_id: localStorage.organizationId
      },
      cancelToken: this.signal.token
    }).then(res => {
      let tabNames = [];
      const roles = { "super_admin": 1, "staff": 2, "accounting": 3, "member": 4, "non_member": 5 };
      if (!_.isEmpty(res.data.json.role_information)) {
        res.data.json.role_information.forEach(role => {
          tabNames.push({ role: role.name, level: role.level });
        });
        tabNames = tabNames.sort((tabName1, tabName2) => {
          return roles[tabName1.level] - roles[tabName2.level];
        });
      }
      tabNames.push({role: "Undefined", level: "undefined"})
      this.setState(
        {
          tabNames,
          loading: false,
        },
        () => {
          this.handleTabFromProp();
          window.addEventListener("popstate", this.handleTabFromProp);
        }
      );
    })
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  handleTabFromProp = () => {
    let { innertab } = this.props.match.params;
    let tab = innertab && this.state.tabNames.find(tab => tab.level === innertab);
    this.setState(
      {
        ...this.state,
        activeTab: tab
          ? tab.role
          : title(this.state.tabNames[0].role),
        userType: tab ? tab.level : this.state.tabNames[0].level
      });
  };

  tabChangeHandler = activeTab => {
    this.setState({ activeTab: activeTab.role, userType: activeTab.level, forcePage: 0 });
    this.props.history.push(
      `/system_settings/users/users/${snakeCase(activeTab.level)}`
    );
  };

  render() {
    const tabs = this.state.tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={
          this.state.activeTab === tab.role
            ? "breadcrumb-item active-tab innertab p-1 pr-2"
            : "breadcrumb-item inactive-tab innertab p-1 pr-2"
        }
      >
        <b>{tab.role}</b>
      </li>
    ));
    let activeTab;
    switch (this.state.userType) {
      case "super_admin":
        activeTab = (
          <UserList
            userType={this.state.userType}
            editForm={this.props.editForm}
            getUsers={this.props.getUsers}
            forcePage={this.state.forcePage}
            tabChangeHandler={this.props.tabChangeHandler}
          />
        );
        break;
      case "staff":
        activeTab = (
          <UserList
            userType={this.state.userType}
            editForm={this.props.editForm}
            forcePage={this.state.forcePage}
            tabChangeHandler={this.props.tabChangeHandler}
          />
        );
        break;
      case "accounting":
        activeTab = (
          <UserList
            userType={this.state.userType}
            editForm={this.props.editForm}
            forcePage={this.state.forcePage}
            tabChangeHandler={this.props.tabChangeHandler}
          />
        );
        break;
      case "member":
        activeTab = (
          <UserList
            userType={this.state.userType}
            editForm={this.props.editForm}
            forcePage={this.state.forcePage}
            tabChangeHandler={this.props.tabChangeHandler}
          />
        );
        break;
      case "non_member":
        activeTab = (
          <UserList
            userType={this.state.userType}
            editForm={this.props.editForm}
            forcePage={this.state.forcePage}
            tabChangeHandler={this.props.tabChangeHandler}
          />
        );
        break;
      case "undefined":
        activeTab = (
          <UserList
            userType={this.state.userType}
            forcePage={this.state.forcePage}
          />
        );
        break;  
      default:
        activeTab = null;
    }

    if (this.state.loading) {
      return <Spinner />
    } else {
      return (
        <div
          className="system-setting-users"
          key={this.state.activeTab}
        >
          <div className="p-3">
            <div className="row">
              <div className="col-lg-9">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb ml-3 mt-1">{tabs}</ol>
                </nav>
              </div>
              <div className="col-lg-3 mt-2 text-right">
                <Button type="success" clicked={() => this.props.tabChangeHandler("Add Or Edit Form", "new")}>
                  {langUtils("txt_global_add_new", "Add New")}
                </Button>
              </div>
            </div>
          </div>

          {activeTab}
        </div>
      );
    }
  }
}

export default withRouter(Users);
