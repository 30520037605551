import React, { useEffect } from 'react'

export default function DateSelectQuestion({ question, response, setResponseData, completed }) {

  const clearValidationError = () => {
    const element = document.getElementById("response-date")
    element.validity.customError && !element.validity.valueMissing && element.setCustomValidity('')
  }

  useEffect(() => {
    response?.response_date && clearValidationError()
  }, [response])

  return (
    <div className="mb-4">
      <p className="mb-2 font-weight-bold">{question.question_text}</p>
      <div className="mx-3">
        <input
          id="response-date"
          type="date"
          style={{boxShadow: "none"}}
          className={"form-control w-20"}
          required={question.mandatory}
          onInvalid={e => e.target.setCustomValidity("Please select a date")}
          onChange={e => setResponseData(question.id, {response_date: e.target.value})}
          value={response?.response_date || ""}
          disabled={completed}
        />
      </div>
    </div>
  )
}
