import React, { Component } from "react";
import { toast } from "react-toastify";
import { title, snakeCase } from "change-case";
import ReactTable from 'react-table-6';
import _ from "lodash";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import Input from "../UI/input/Input";
import Modal from "../UI/Modal";
import langUtils from "../../utils/langUtils";

import 'react-table-6/react-table.css';
import "./Constants.css"

class Languages extends Component {
  signal = axios.CancelToken.source();
  state = {
    languages: [],
    loading: true,
    disabled: true,
    search: {
      elementType: "input",
      elementConfig: {
        name: "search",
        type: "text",
        required: true
      },
      value: ""
    },
    columnData: [],
    localeData: null
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getLocaleList();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getLocaleList = () => {
    axios.get("/base_languages", { cancelToken: this.signal.token })
      .then(res => {
        let uniqueKeys = [], localeFields = [], columnData = ["constant"], inputField = ["constant"];
        res.data.base_languages.forEach(language => {
          columnData.push(snakeCase(language.name))
          inputField.push(snakeCase(language.name))
        })
        columnData.push("actions")
        res.data.base_languages.forEach(language => (
          language.locale && Object.keys(language.locale).forEach(localeKey => {
            let index = uniqueKeys.indexOf(localeKey)
            if (index === -1)
              uniqueKeys.push(localeKey)
          })
        ))

        res.data.base_languages.forEach(language => (
          language.locale && Object.keys(language.locale).forEach(localeKey => {
            let index = uniqueKeys.indexOf(localeKey)
            localeFields[index] = {
              ...localeFields[index],
              constant: localeKey,
              [snakeCase(language.name)]: language.locale[localeKey]
            }
          })
        ))
        this.setState({
          ...this.state,
          languages: res.data.base_languages,
          localeFields: localeFields,
          filteredLocaleFields: localeFields,
          columnData: columnData,
          inputField: inputField,
          disabled: false,
          loading: false
        });
      })
  }

  inputChangeHandler = (event, key) => {
    if (this.state.editLocale) {
      this.setState({
        localeValue: {
          ...this.state.localeValue,
          [key]: event.target.value
        }
      })
    }
    else {
      this.setState({
        localeData: {
          ...this.state.localeData,
          [title(key)]: event.target.value
        }
      })
    }
  }

  searchHandler = (event) => {
    this.setState({
      search: {
        ...this.state.search,
        value: event.target.value
      }
    }, () => this.globalSearch())
  }

  globalSearch = () => {
    if (this.state.search.value) {
      let filteredData = this.state.localeFields.filter(localField => {
        let result = Object.values(localField).map(localValue => localValue.toLowerCase()).join("")
        return (result.includes(this.state.search.value.toLowerCase()));
      });
      this.setState({ filteredLocaleFields: filteredData });
    }
    else {
      this.setState({ filteredLocaleFields: this.state.localeFields });
    }
  };

  deleteLocaleHandler = localeItem => {
    let response = window.confirm("Are you sure you want to delete the Constant?");
    if (response) {
      let promises = [];
      this.state.languages.forEach(language => {
        let locale = {};
        language.locale && Object.keys(language.locale).forEach(word => {
          if (word !== localeItem.constant)
            locale[word] = language.locale[word]
        })
        let data = {
          name: language.name,
          locale
        }
        promises.push(axios.put(`/base_languages/${language.id}`, data)
          .then(res => {
            if (res.data.json.success) {
              this.setState({
                loading: true,
                search: {
                  ...this.state.search,
                  value: ""
                },
              })
            } else {
              if (res.data.json.hasOwnProperty("errors")) {
                Object.keys(res.data.json.errors).forEach(error => {
                  toast.error(title(error) + " " + res.data.json.errors[error]);
                });
              }
              if (res.data.json.hasOwnProperty("error")) {
                toast.error(res.data.json.error);
              }
            }
          }))
      })
      axios.all(promises).then(() => {
        toast.success(`Constant deleted from all Languages successfully`)
        this.getLocaleList()
      });
    }
  }

  submitHandler = (event) => {
    event.preventDefault();
    let promises = [];
    this.state.languages.forEach(language => {
      let locale = {}, constantValue;
      language.locale && Object.keys(language.locale).forEach(word => {
        locale[word] = language.locale[word]
      })
      if (this.state.editLocale) {
        Object.keys(this.state.localeValue).forEach(key => {
          if (key === "constant")
            constantValue = this.state.localeValue[key]
          if (key === snakeCase(language.name))
            locale = {
              ...locale,
              [constantValue]: this.state.localeValue[key]
            }
        })
      }
      else {
        Object.keys(this.state.localeData).forEach(key => {
          if (key === "Constant")
            constantValue = this.state.localeData[key]
          if (snakeCase(key) === snakeCase(language.name))
            locale = {
              ...locale,
              [constantValue]: this.state.localeData[key]
            }
        })
      }
      let data = {
        name: language.name,
        locale
      }
      promises.push(axios.put(`/base_languages/${language.id}`, data)
        .then(res => {
          if (res.data.json.success) {
            this.setState({
              showLocaleModal: false,
              loading: true,
              disabled: true
            })
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        }))
    })
    axios.all(promises).then(() => {
      if (this.state.editLocale)
        toast.success(`Constant updated in all Languages successfully`)
      else
        toast.success(`Constant added to all Languages successfully`)
      this.getLocaleList()
    });
  }

  editLocaleHandler = (locale) => {
    this.setState({
      editLocale: true,
      localeValue: locale,
      search: {
        ...this.state.search,
        value: ""
      },
      showLocaleModal: true
    })
  }

  render() {
    let columns;
    if (!this.state.loading) {
      columns = this.state.columnData.map(columnName => {
        if (columnName === "actions")
          return {
            Header: title(columnName),
            accessor: columnName,
            Cell: props => {
              return (
                <div className="d-flex justify-content-center">
                  <i
                    className="fas fa-pencil-alt cursor mr-5"
                    onClick={() => this.editLocaleHandler(props.original)}
                  />
                  <i
                    className="fas fa-trash cursor"
                    onClick={() => this.deleteLocaleHandler(props.original)}
                  />
                </div>
              )
            },
            sortable: false
          }
        else
          return {
            Header: title(columnName),
            accessor: columnName
          }
      })
    }

    let removeIcon;
    if (this.state.search.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times remove-icon cursor"
          onClick={() => this.setState({
            search: {
              ...this.state.search,
              value: ""
            },
            filteredLocaleFields: this.state.localeFields
          })}
        />
      );
    }

    const form = this.state.inputField && Object.keys(this.state.inputField).map((inputField) => (
      <div key={inputField}>
        {title(this.state.inputField[inputField])}
        <Input
          elementType={"input"}
          value={this.state.editLocale ? this.state.localeValue[this.state.inputField[inputField]] : null}
          changed={event => this.inputChangeHandler(event, this.state.inputField[inputField])}
        />
      </div>))

    return (
      <div className="container constant w-100">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-2">
                <div className="mt-5 pl-2">
                  Search:
                </div>
              </div>
              <div className="col-md-6">
                <div className="mt-4 p-0">
                  <i className="fas fa-search search-icon" />
                  {removeIcon}
                  <Input
                    {...this.state.search}
                    style={{ padding: "2%", paddingRight: "55px" }}
                    changed={event => this.searchHandler(event, "search")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 text-lg-right">
            <div className="pr-2 mt-5">
              <Button
                type="success"
                disabled={this.state.disabled}
                clicked={() => this.setState({ editLocale: false, showLocaleModal: true })}
              >{langUtils("txt_global_add_new", "Add New")}</Button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body p-2 mt-4">
            <div className="card-text">
              {this.state.loading ? <Spinner /> : (
                <ReactTable
                  columns={columns}
                  data={this.state.filteredLocaleFields}
                  noDataText="Constant is not found in any Language"
                  defaultPageSize={10}
                >
                </ReactTable>
              )}
              <div className="row">
                <div className="col-12 text-lg-left font-weight-bold text-center">
                  <h5 className="pl-2 mt-4 pb-4">
                    Total Words: {this.state.localeFields && this.state.localeFields.length}
                  </h5>
                </div>
              </div>
              <Modal
                show={this.state.showLocaleModal}
                title={this.state.editLocale ? "Edit Locale" : "Add Locale"}
                hide={() => this.setState({ showLocaleModal: false })}
              >

                <div className="p-2">
                  <form onSubmit={this.submitHandler}>
                    {form}
                    <div className="col-12 text-center">
                      <Button
                        type="secondary"
                        clicked={() => this.setState({ showLocaleModal: false })}
                      >
                        Cancel
                      </Button>
                      <FormButton
                        className="ml-2"
                      >
                        {this.state.editLocale ? "Update" : "Create"}
                      </FormButton>
                    </div>
                  </form>
                </div>
              </Modal>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Languages;
