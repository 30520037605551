import React from 'react';
import dateFns from "date-fns";
import { Link } from "react-router-dom";

import "./superAdminItem.scss";

const SuperAdminItem = props => {
  return (
    <tr className="super-admin-item">
      <td>{props.user.email}</td>
      <td>{props.user.organization_or_name}</td>
      <td>
        {props.user.last_login 
          ? dateFns.format(new Date(props.user.last_login), "YYYY-MM-DD")
          : "---"}
      </td>
      <td>{props.user.data.last_login_url}</td>
      <td>{props.user.role.name}</td>
      <td className="p-0 text-center">
        <div className="d-flex">
          <Link to={`/organizations/${props.organizationId}/super_admins/${props.user.id}/edit`}>
            <button title="edit"
              className="btn btn-sm btn-link m-0 super-admin-pen-icon"
            >
              <i className="fas fa-2x fa-pen" />
            </button>
          </Link>
          <button
            title="Reset Password"
            className="btn btn-sm btn-link m-0 pl-1 super-admin-icon super-admin-reset-icon"
            onClick={event => props.resetPassword(props.user.id)}
          >
            <i
              className="fas fa-xs fa-sync text-light position-relative reset-password-icon"
              title="Reset Password"
            />
            <i className="fas fa-2x fa-lock" title="Reset Password" />
          </button>
          <button
            title="delete"
            className="btn btn-sm btn-link m-0 super-admin-icon"
            onClick={() => props.deleteSuperAdminHandler(props.user.id, props.user.organization_or_name)}
          >
            <i className="fas fa-2x fa-trash-alt cursor"></i>
          </button>
        </div>
      </td>
    </tr>
  )
}

export default SuperAdminItem;
