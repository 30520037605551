import React, { Component } from "react"
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import is from "is_js"

import axios from "../../configAxios"
import langUtils from "../../utils/langUtils"
import Spinner from "../UI/Spinner"

import Tabs from "./Tabs"
import Sidebar from "./Sidebar"
import Bio from './summaryComponents/Bio'

import "./Account.css"

class User extends Component {
  signal = axios.CancelToken.source()
  state = {
    accountId: "",
    userId: this.props.match.params.id,
    policyRes: {},
    userData: null,
    expiryDate: null,
    loading: true,
    customFields: null,
    isAdmin: null,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getUserPolicy()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          loading: true,
          userId: this.props.match.params.id,
        },
        this.getUserPolicy
      )
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getUserPolicy = () => {
    axios
      .get("/users/policy", { params: { id: this.state.userId } })
      .then((res) => {
        this.setState({ policyRes: res.data.json }, () =>
          axios
            .get(`users/${this.state.userId}`, {
              cancelToken: this.signal.token,
            })
            .then((res) => {
              this.setState(
                {
                  accountId: res.data.user.account_id,
                  customFields: res.data.user.custom_fields,
                },
                () => this.getUserData()
              )
            })
        )
      })
  }

  getUserData = () => {
    axios
      .get(`users/${this.props.match.params.id}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const expiryDate = res.data.user.membership_expires
        this.setState({
          userData: res.data.user,
          expiryDate,
          loading: false,
          isAdmin: res.data && res.data.meta && res.data.meta.is_admin,
        })
      })
      .catch((error) => error)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.id !== state.userId) {
      return { userId: props.match.params.id }
    }
    return null
  }

  render() {
    const { userId } = this.state
    return (
      <div className="container friends_account w-100" key={userId}>
        <div className="row mb-4">
          <div className="col-8">
            <h3>{langUtils("txt_account", "Account")}</h3>
          </div>
          <div className="col-4 text-right d-flex justify-content-end align-items-center">
            {is.firefox() ? (
              <p
                className="cursor"
                onClick={() => this.props.history.push(`/users/members/`)}
              >
                {"<< Back"}
              </p>
            ) : (
              <p className="cursor" onClick={() => this.props.history.goBack()}>
                {"<< Back"}
              </p>
            )}
          </div>
        </div>
        {!this.state.loading ? (
          <div className="row">
            <div className="col-md-3">
              <Sidebar
                policyRes={this.state.policyRes}
                userId={userId}
                userData={this.state.userData}
                expiryDate={this.state.expiryDate}
                getUserData={this.getUserData}
                customFields={this.state.customFields}
                isAdmin={this.state.isAdmin}
              />
            </div>
            <div className="col-md-9">
              <Bio text={this.state.userData.bio_link}/>
              <Tabs
                policyRes={this.state.policyRes}
                userId={userId}
                userData={this.state.userData}
                accountId={this.state.accountId}
                roleLevel={this.props.roleLevel}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {roleLevel: state.auth.roleLevel}
}
export default connect(mapStateToProps)(withRouter(User))
