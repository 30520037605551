import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { title } from "change-case"
import { toast } from "react-toastify"

import axios from "../../configAxios"
import Input from "../UI/input/Input"
import langUtil from "../../utils/langUtils"
import { SUCCESS } from "../UI/Colors"
import Modal from "../UI/Modal"
import { responseErrorHandler } from "../../utils/errorHandler"
import Button from "../UI/button/Button"
import ToggleSwitch from "../common/toggleSwitch"

import { getProjects } from "../../store/actions/index"
import MergeProject from "./MergeProject"
import ProjectUpdate from "./ProjectUpdate"
import EditImage from "./EditImage"
import errorStatusHandler from "../errorPages/UserNotAuthorized"
import tagName from "../shared/tagName"

import "./Project.css"
import "./Sidebar.css"
import NoImage from "../../assests/images/no-image.png"

class Sidebar extends Component {
  signal = axios.CancelToken.source()
  timeout = 0
  state = {
    isProjectOwner: false,
    canViewAddProjects: false,
    canEditPublicSetting: null,
    canEditLabels: null,
    tags: {
      elementType: "asyncSelect",
      elementConfig: {
        name: "tags",
        placeholder: "Enter labels here",
        controlShouldRenderValue: false,
        inputLength: 1,
        loadOptions: (inputValue, callback) => {
          if (inputValue.length > 0) this.searchTagsByName(inputValue, callback)
        },
        components: {
          DropdownIndicator: () => {
            return null
          },
          IndicatorSeparator: () => {
            return null
          },
          ClearIndicator: null,
        },
        styles: {
          multiValueLabel: (base) => ({
            ...base,
            backgroundColor: "#f0e9e9",
            color: "#666666",
          }),
          multiValueRemove: (base) => ({
            ...base,
            backgroundColor: "#f0e9e9",
            color: "#666666",
          }),
        },
      },
      selectedOption: null,
    },
    showModal: false,
    showUpdateModal: false,
    name: null,
    creator: null,
    thumbnail: null,
    share: false,
    isActive: null,
    details: {},
    tagged: [],
    project_id: null,
    favaTv: "",
    gmff: "",
    tagCharacters: "",
    projectName: null,
    openModal: false,
    projects: [],
    userId: null,
    project: null,
    loading: true,
    canUpdate: false,
  }

  componentDidMount() {
    axios
      .get(`/projects/${this.props.match.params.id}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const { project } = res.data
        project &&
          this.setState(
            {
              project_id: project.slug ? project.slug : project.id,
              projectName: project.name,
              userId: project.user_id,
              thumbnail: project.thumbnail,
              creator: project.creator,
              id: project.id,
              share: project.share,
              canUpdate: project.can_update,
              project
            },
            () => {
              this.getTags()
              this.getSidebarDetails()
              this.getProjectName()
            }
          )
      })
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios
      .get("/projects/policy", {
        params: { id: this.props.match.params.id },
      })
      .then((res) => {
        this.setState({
          isProjectOwner: res.data.json.is_project_owner,
          canViewAddProjects: res.data.json.can_view_add_projects,
          canEditPublicSetting: res.data.json.can_edit_public_setting,
          canEditLabels: res.data.json.can_edit_labels,
        })
        if (!this.state.canEditLabels) {
          this.setState({
            ...this.state.tags.elementConfig,
            tags: { elementConfig: { readOnly: true } },
          })
        }
      })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.project !== this.props.project &&
      !this.props.location.pathname.includes("media")
    ) {
      this.getSidebarDetails()
    }
    if (
      prevProps.project !== this.props.project &&
      this.props.location.pathname.includes("media")
    ) {
      this.updateThumbnail()
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  updateThumbnail = () => {
    axios
      .get(`/projects/${this.props.match.params.id}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const { project } = res.data
        this.setState({
          project_id: project.slug,
          projectName: project.name,
          userId: project.user_id,
          thumbnail: project.thumbnail,
          project,
        })
      })
  }

  getSidebarDetails = () => {
    axios
      .get("/projects/project_detail_side_bar", {
        params: { id: this.props.match.params.id },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const details = res.data.json
        details.short_attributes.forEach((attr) => {
          const key = attr.name
            .toLowerCase()
            .trim()
            .split(/[.\-_\s]/g)
            .reduce(
              (string, word) => string + word[0].toUpperCase() + word.slice(1)
            )
          details[key] = attr.value
        })
        delete details.short_attributes
        this.setState({
          details: res.data.json,
          isActive: res.data.json.status,
          favaTv: res.data.json.favatv,
          gmff: res.data.json.gmff,
          loading: false,
        })
      })
  }

  updatedName = (formData) => {
    this.setState({ projectName: formData.name })
  }

  getProjectName = () => {
    const queryParams = {
      params: {
        user_id: this.state.userId,
        id: this.state.project_id,
      },
      cancelToken: this.signal.token,
    }
    axios
      .get("/projects/autocomplete", queryParams)
      .then((res) => {
        const projects = []
        for (const key in res.data.projects) {
          projects.push({ ...res.data.projects[key] })
        }

        this.setState({ projects })
      })
      .catch((err) => err)
  }

  getTags = () => {
    const user_id = this.state.userId
    const { project_id } = this.state
    axios
      .get("/tags", {
        params: { project_id, user_id },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        if (res.data.tags[0] !== "") {
          this.setState({
            tagged: res.data.tags[0].split(","),
          })
        } else {
          this.setState({ tagged: [] })
        }
      })
      .catch((err) => err)
  }

  searchTagsByName = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue,
        type: "Project",
      },
    }
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.getTagsByName(queryParams, callback)
    }, 300)
  }

  getTagsByName = (queryParams, callback) => {
    axios
      .get("/tags/search", { ...queryParams, cancelToken: this.signal.token })
      .then((res) => {
        if (res.status === 202) {
          errorStatusHandler(res)
          callback(null)
        } else {
          const options = res.data.json.filter(
            (el) => !this.state.tagged.includes(el.value)
          )
          callback(
            options.map((tag) => {
              return {
                value: tag.value,
                label: tag.name,
              }
            })
          )
        }
      })
      .catch((err) => err)
  }

  mergeProjectHandler = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true })
  }

  projectUpdateHandler = () => {
    window.scrollTo(0, 0)
    this.setState({ showUpdateModal: true })
  }

  updateProjectShareSettings = (params) => {
    const { id } = this.state
    axios
      .put(`/projects/${id}`, params)
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Projects's setting updated successfully.")
        } else {
          responseErrorHandler(res)
        }
      })
      .catch((err) => err)
  }

  inputChangedHandler = (option, actionMeta) => {
    const user_id = this.state.userId
    const { project_id } = this.state
    if (option && option.value) {
      axios
        .post("/tags", { query: option.value, project_id, user_id })
        .then((res) => {
          if (res.data.json.success) {
            this.getTags()
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
          }
        })
    }
  }

  deleteTagHandler = (tagValue) => {
    const user_id = this.state.userId
    const { project_id } = this.state
    const response = window.confirm(
      "Are you sure you want to delete the label?"
    )
    if (response) {
      axios
        .delete("/tags", {
          params: { query: tagValue, project_id, user_id },
        })
        .then((res) => {
          const { project_id } = this.state
          axios
            .get("/tags", {
              params: { project_id },
              cancelToken: this.signal.token,
            })
            .then((res) => {
              if (res.data.tags[0] !== "") {
                this.setState({
                  tagged: res.data.tags[0].split(","),
                })
              }
              if (res.data.tags[0] === "") {
                this.setState({
                  tagged: [],
                })
              }
            })
        })
    }
  }

  toggleShareProjectChangeHandler = () => {
    const { share, id, projectName } = this.state
    this.setState(
      {
        share: !share,
      },
      () => {
        this.updateProjectShareSettings({
          share: this.state.share,
          id: id,
          name: projectName,
        })
      }
    )
  }

  projectDeleteHandler = () => {
    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      axios.delete(`/projects/${this.state.project_id}`).then((res) => {
        if (res.data.json.success) {
          toast.success("Project successfully deleted.")
          this.props.history.push("/projects")
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
    }
  }

  render() {
    const { details, project } = this.state
    const { thumbnail } = this.state
    const { share } = this.state
    const tags = this.state.tagged.map((tagValue, index) => (
      <div
        className="tag word-wrap-break"
        key={index}
        style={{ maxWidth: "90%" }}
      >
        <div className="tag-name">{tagName(tagValue)}</div>
        {this.state.canEditLabels && (
          <button
            className="tag-delete"
            onClick={() => this.deleteTagHandler(tagValue)}
          >
            x
          </button>
        )}
      </div>
    ))

    const image = thumbnail || NoImage
    return (
      <div className="project-sidebar">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="image-preview">
                <a href={thumbnail} target="_blank" rel="noopener noreferrer">
                  <div
                    className="rounded-circle bg-cover"
                    style={{
                      width: 150,
                      height: 150,
                      backgroundImage: `url(${image})`,
                    }}
                  />
                </a>
                {this.adminOrProjectOwner() && (
                  <i
                    className="fas fa-pencil-alt select-image-button"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.editImageModalHandler()}
                  />
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12 text-center text-primary font-weight-bold">
                {this.state.projectName}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12 text-center font-weight-bold">
                <span>Creator:</span>&nbsp;
                <span>
                  <a
                    href={`/users/${this.state.userId}/summary`}
                    className="text-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.state.creator}
                  </a>
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="buttons">
                  {this.adminOrProjectOwner() && (
                    <Button
                      type="warning"
                      clicked={this.projectUpdateHandler}
                      style={{ cursor: "pointer", width: "100%" }}
                    >
                      Edit Project Name
                    </Button>
                  )}
                  {this.adminOrProjectOwner() && (
                    <Button
                      type="primary"
                      className="mt-2"
                      style={{ cursor: "pointer", width: "100%" }}
                      clicked={this.mergeProjectHandler}
                    >
                      Merge Project
                    </Button>
                  )}
                  {project?.can_delete && (
                    <Button
                      className="mt-2"
                      type="danger"
                      style={{ width: "100%" }}
                      clicked={this.projectDeleteHandler}
                    >
                      Delete Project
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ul
            className="list-group list-group-flush"
            style={{ marginBottom: "0px" }}
          >
            {!this.state.loading && (
              <React.Fragment>
                {this.state.canUpdate && (
                  <li className="list-group-item rounded-0">
                    <div style={{ paddingBottom: "10px" }}>
                      {" "}
                      {langUtil("txt_share_project", "Share Project")}
                    </div>
                    <ToggleSwitch
                      disabled={!this.state.canEditPublicSetting}
                      checked={share}
                      onChange={this.toggleShareProjectChangeHandler}
                    />
                  </li>
                )}
                {this.state.favaTv !== null && (
                  <li className="list-group-item">
                    Please consider the project for FAVATV
                    <div style={{ color: SUCCESS }}>
                      {this.state.favaTv ? "On" : "Off"}
                    </div>
                  </li>
                )}
                {this.state.gmff !== null && (
                  <li className="list-group-item">
                    Please consider the project for Gotta Minute Film Festival
                    <div
                      style={{
                        color: SUCCESS,
                      }}
                    >
                      {this.state.gmff ? "On" : "Off"}
                    </div>
                  </li>
                )}
              </React.Fragment>
            )}
          </ul>
        </div>

        <div className="card mt-3">
          <ul className="list-group list-group-flush mb-0">
            <li className="list-group-item font-weight-bold">
              <span>Info</span>
              <span className="float-right">ID {this.state.id}</span>
            </li>
            <li className="list-group-item">
              <div className="text-left">{details.info}</div>
            </li>
          </ul>
        </div>

        <div className="card mt-3">
          <ul className="list-group list-group-flush mb-0">
            <li className="list-group-item font-weight-bold">Labels</li>
            <li className="list-group-item">
              <Input
                {...this.state.tags}
                changed={this.inputChangedHandler}
                tags={true}
                chart={this.state.tagCharacters}
                onInputChange={(event) =>
                  this.setState({ tagCharacters: event })
                }
              />
              <div className="row pl-3">{tags}</div>
            </li>
          </ul>
        </div>
        <Modal
          title="Merge Project"
          flag={true}
          show={this.state.showModal}
          hide={() => this.setState({ showModal: false })}
          style={{ top: "15%" }}
        >
          <MergeProject
            projectNames={this.state.projects}
            user_id={this.state.userId}
            project_id={this.state.project_id}
            getProjectName={this.getProjectName}
            closeModal={() => this.setState({ showModal: false })}
          />
        </Modal>
        <Modal
          title="Project Update"
          show={this.state.showUpdateModal}
          hide={() => this.setState({ showUpdateModal: false })}
          style={{ top: "15%" }}
        >
          <ProjectUpdate
            updatedName={this.updatedName}
            projectName={this.state.projectName}
            closeModal={() => this.setState({ showUpdateModal: false })}
          />
        </Modal>
        <Modal
          title="Select Image"
          show={this.state.openModal}
          closeModal={() => this.setState({ openModal: false })}
          hide={() => this.setState({ openModal: false })}
          closeButton
        >
          <EditImage
            projectId={this.state.project_id}
            closeModal={() => this.setState({ openModal: false })}
            updateThumbnail={this.updateThumbnail}
          />
        </Modal>
      </div>
    )
  }

  editImageModalHandler() {
    if (this.adminOrProjectOwner()) {
      window.scrollTo(0, 0)
      this.setState({ openModal: true })
    }
  }

  adminOrProjectOwner = () => {
    return this.state.canViewAddProjects || this.state.isProjectOwner
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.projects.project,
    response: state.response,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjects: (params) => dispatch(getProjects(params)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
