import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import dateFns from "date-fns";
import Pagination from "../UI/Pagination";
import { isEmpty } from "lodash";
import CenteredContainer from "../UI/container/CenteredContainer";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";

import './index.css';

class History extends Component {
  signal = axios.CancelToken.source();
  state = {
    history: [],
    totalCount: null,
    selectedPage: 1,
    loading: true
  };

  componentDidMount() {
    this.getHistoryList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { formattedDates } = this.props
    if ((prevProps.formattedDates.formattedStart !== formattedDates.formattedStart) || (prevProps.formattedDates.formattedEnd !== formattedDates.formattedEnd)) {
      this.getHistoryList()
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  getHistoryList = () => {
    const { formattedStart, formattedEnd } = this.props.formattedDates
    const params = {
      filter: 'history',
      page: this.state.selectedPage,
      per_page: 15,
    }

    if (formattedStart) params['start_date'] = formattedStart
    if (formattedEnd) params['end_date'] = formattedEnd

    axios
      .get("/volunteer_tickets/", { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          history: res.data.volunteer_tickets,
          totalCount: res.data.meta.total_count,
          loading: false,
        })
      })
  }

  deleteHandler = id => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      axios
        .delete(`/volunteer_tickets/${id}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Successfully deleted");
            this.getHistoryList();
          } else {
            toast.error("Delete unsuccessful");
          }
        });
    }
  }

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      loading: true
    }, () => this.getHistoryList());
  };

  render() {
    const history = this.state.history;
    let historyList;
    if (this.state.loading) {
      historyList = <Spinner />;
    } else {
      historyList = !isEmpty(history)
        ? history.map(item => (
          <li className="list-group-item border-top border-bottom rounded-0 py-2 px-3">
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-12 mt-1">
                    <Link to={`/users/${item.user.id}/volunteer`} className="text-info">
                      <h5>{item.user.login ? item.user.login : "no one"}/{item.user.full_name}</h5>
                    </Link>
                  </div>
                  <div className="col-lg-12">
                    {item.description}
                  </div>

                  <div className="col-lg-12 mt-1">
                    {dateFns.format(item.date_completed, "MMMM DD YYYY")}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 d-flex justify-content-center align-items-center">
                {
                  item.status === 1 ?
                    <div className="badge bg-success text-light py-2 px-3">Approved</div> :
                    item.status === 2 ?
                      <div className="badge bg-danger text-light py-2 px-3">Not Approved</div> :
                      <p className="text-danger">Pending Approval</p>
                }
              </div>

              <div className="col-lg-2 d-flex justify-content-center align-items-center">
                <div>
                  <span className="badge bg-warning py-2 px-3 mr-4">{item.volunteer_hours} hours</span>
                  <i onClick={() => this.deleteHandler(item.id)} className="fas fa-trash cursor text-danger" />
                </div>
              </div>
            </div>

          </li>

        ))
        : <CenteredContainer>
          <h3 className="text-lg-center">No Volunteer Found.</h3>
        </CenteredContainer>;
    }
    return (
      <React.Fragment>
        <div>
          <ul className="list-group list-group-flush">
            {historyList}
          </ul>
        </div>
        <div style={{ marginTop: "14px" }}>
          <Pagination
            pageCount={this.state.totalCount / 15}
            handlePageClick={this.paginationHandler}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default History;
