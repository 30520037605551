import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Input from "../../UI/input/Input";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import { getForm, updateForm } from "../../../store/slices/form";
import { clearResponse } from "../../../store/actions";
import { connect } from "react-redux";

class Update extends Component {
  state = {
    updateCampaignName: {
      elementType: "input",
      elementConfig: {
        name: "updateCampaignName",
        required: true,
      },
    },
    name: this.props.name,
    description: this.props.description,
    disabled: false,
  };

  inputChangedHandler = (event) => {
    this.setState({ name: event.target.value });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.response.hasOwnProperty("success") &&
      nextProps.response.success !== null
    ) {
      const { message, success } = nextProps.response;
      console.log(nextProps);
      this.props.clearResponse();
      if (success) {
        this.props.closeModal(true);
        this.props.getForm(nextProps.id);
        toast.success(message);
      } else {
        toast.error(message);
      }
    }
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ disabled: true });
    const formData = {
      id: this.props.id,
      name: this.state.name,
      description: this.state.description,
    };
    this.props.updateForm(formData, this.props.history);
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <p>Edit Form Name</p>
        <Input
          {...this.state.updateCampaignName}
          changed={(event) => this.inputChangedHandler(event)}
          value={this.state.name}
        />
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            className="mr-2"
            clicked={this.props.closeModal}
          >
            Cancel
          </Button>
          <FormButton disabled={this.state.disabled}>Update</FormButton>
        </div>
      </form>
    );
  }
}

Update.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  getForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  clearResponse: PropTypes.func.isRequired,
  response: PropTypes.shape({
    loading: PropTypes.bool,
    message: PropTypes.string,
    success: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    response: state.response,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForm: (id) => dispatch(getForm(id)),
    updateForm: (formData, history) => dispatch(updateForm(formData, history)),
    clearResponse: () => dispatch(clearResponse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Update);
