import { map, sortBy, startCase } from "lodash"

export const orderedTagOptions = (options) => {
  const categorized = options.filter(o => o.value.includes('program_global') && o.category)
  const dynamicCategories = {}

  categorized.forEach(o => {
    if (!Object.keys(dynamicCategories).includes(String(o.category.order))) {
      return dynamicCategories[o.category.order] = { label: startCase(o.category.name), order: o.category.order, options: [o] }
    } else {
      dynamicCategories[o.category.order].options = sortBy([...dynamicCategories[o.category.order].options, o], 'order')
    }
    sortBy(dynamicCategories[o.category.order].options, 'order')
  })

  const mapped = sortBy(map(dynamicCategories, e => e), 'order')

  return [
    ...mapped,
    {
      label: "Default Labels",
      options: options.filter((option) =>
        option.value.includes("program_global") && !option.category
      ),
    },
    {
      label: "Custom Labels",
      options: options.filter(
        (option) => !option.value.includes("program_global")
      ),
    },
  ]
}

const groupTagOptions = (options) => {
  return [
    {
      label: "Default Labels",
      options: options.filter((option) =>
        option.value.includes("program_global")
      ),
    },
    {
      label: "Custom Labels",
      options: options.filter(
        (option) => !option.value.includes("program_global")
      ),
    },
  ]
}

export default groupTagOptions
