import React, { Component } from "react";
import { Link } from "react-router-dom";
import dateFnsFormat from "date-fns/format";

import Spinner from "../../UI/Spinner";
import Button from "../../UI/button/Button";
import axios from "../../../configAxios";

class Contest extends Component {
  signal = axios.CancelToken.source();
  state = {
    winners: [],
    loading: true,
    contestInfo: [],
    winnersList: false
  };

  componentDidMount() {
    axios
      .get(`/contests/${this.props.id}`, { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          contestInfo: res.data.json.data,
          loading: false
        });
        if (res.data.json.data.winners) {
          this.setState({
            winners: res.data.json.data.winners,
            winnersList: true
          });
        } else {
          return null;
        }
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  render() {
    const info = this.state.winners;
    let winners;
    if (this.state.loading) {
      winners = <Spinner />;
    } else {
      winners = info.map(item => (
        <li className="list-group-item rounded-0 border-top">
          <div className="row">
            <div className="col-md-4">{item.name}</div>
            <div className="col-md-4">
              <Link to={`/users/${item.user_id}/summary`}>{item.creator}</Link>
            </div>
            <div className="col-md-4">{item.votes_count}</div>
          </div>
        </li>
      ));
    }
    return (
      <div className="project-contests-show">
        <div className="row contest-name m-0 mt-3">
          <h4 className="col">{this.state.contestInfo.name}</h4>
        </div>
        <div className="row contest-detail m-0">
          <div className="col-md-2">
            <b>Name</b>
          </div>
          <div className="col-md-5">{this.state.contestInfo.name}</div>
        </div>
        <div className="row contest-detail m-0 mt-2">
          <div className="col-md-2">
            <b>Start Date</b>
          </div>
          <div className="col-md-5">
            { 
              this.state.contestInfo.start_date
                ? dateFnsFormat(
                    this.state.contestInfo.start_date, 
                    "YYYY-MM-DD hh:mm A"
                  )
                : "---"
            }
          </div>
        </div>
        <div className="row contest-detail m-0 mt-2">
          <div className="col-md-2">
            <b>End Date</b>
          </div>
          <div className="col-md-5">
            {
              this.state.contestInfo.end_date
                ? dateFnsFormat(
                    this.state.contestInfo.end_date, 
                    "YYYY-MM-DD hh:mm A"
                  )
                : "---"
            }
          </div>
        </div>
        <br/>
        <div className="row contest-detail m-0 ">
          <h5 className="col">Winners</h5>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="row">
              <div className="col-lg-4">
                <span className="font-weight-bold">Name</span>
              </div>
              <div className="col-lg-4">
                <span className="font-weight-bold">Creator</span>
              </div>
              <div className="col-lg-4">
                <span className="font-weight-bold">Votes count</span>
              </div>
            </div>
          </li>
          {winners}
        </ul>
        <div className="row m-0 my-4 ml-4">
          <Button type="secondary" clicked={() => this.props.showContests()}>
            Back
          </Button>
        </div>
      </div>
    );
  }
}

export default Contest;
