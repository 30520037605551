import React, { Component } from "react";
import Input from "../UI/input/Input";
import axios from "../../configAxios";

class CustomFields extends Component {
  state = {
    customFields: [],
    loading: true
  };

  componentDidMount() {
    axios.get("/custom_fields").then(res => {
      let customFields = res.data.custom_fields
        .filter(cf => cf.klass_name === "User")
        .map(cf => ({ id: cf.id, label: cf.label, key: cf.column_name, value: "" }))
      this.setState({ customFields, loading: false }, () => {
        if (this.props.editUser) {
          const { formData: { customFields: customFieldData } } = this.props;
          let customFieldsCp = [...this.state.customFields];
          customFieldsCp.forEach(cf => {
            if (customFieldData[cf.key]) {
              cf["value"] = customFieldData[cf.key];
            }
          });
          this.setState({ customFields: customFieldsCp });
        }
      });
    });
  }

  inputChangeHandler = (event, key) => {
    this.props.inputChangeHandler(event, "customFields", key);
    const customFields = this.state.customFields.map(
      customField => {
        if (customField.key === key)
          customField.value = event.target.value
        return customField
      })
    this.setState({ customFields });
  };

  renderCustomFields = () => {
    const { customFields } = this.state;
    const sortedCustomFields = customFields.sort((cF1, cF2) => cF1.label > cF2.label ? 1 : -1)
    const form = sortedCustomFields.map(cf => {
      return (
        <div key={cf.id} className="col-md-3">
          <p>{cf["label"]}</p>
          <Input
            elementType={"input"}
            value={cf.value}
            changed={event =>
              this.inputChangeHandler(event, cf["key"])
            }
          />
        </div>
      );
    });
    return form;
  };

  render() {
    return (
      <div className="mb-2">
        <button
          className="btn"
          onClick={() => this.props.tabHandler("customFields")}
          type="button"
          data-toggle="collapse"
          data-target=".customFields"
          aria-expanded="false"
          aria-controls="multiCollapseExample1 multiCollapseExample2"
          style={{
            width: "100%",
            backgroundColor: "#f5efef",
            outline: "none",
            boxShadow: "none"
          }}
        >
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Custom Fields</p>
            <i
              className={
                this.props.selectedTabs.includes("customFields")
                  ? "fas fa-minus-circle text-primary fa-2x"
                  : "fas fa-plus-circle text-primary fa-2x"
              }
            />
          </div>
        </button>
        <div className="row">
          <div className="col">
            <div className="collapse customFields" id="multiCollapseExample1">
              {!this.state.loading && (
                <div className="row mt-2">{this.renderCustomFields()}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomFields;
