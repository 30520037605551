import React from "react"

const SocialMediaIcon = (props) => {
  const url = props.url
  return (
    <span className="col text-center">
      <a
        href={url}
        target="_blank"
        className="text-primary"
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    </span>
  )
}

export default SocialMediaIcon
