import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { toast } from "react-toastify";
import { title } from "change-case";
import _ from "lodash";
import classnames from "classnames";

import AddNewMember from "./AddNewMember";

import axios from "../../../../configAxios";
import Button from "../../../UI/button/Button";
import Modal from "../../../UI/Modal";
import Spinner from "../../../UI/Spinner";
import langUtils from "../../../../utils/langUtils";

import "./../index.css";

class Members extends Component {
  signal = axios.CancelToken.source();
  state = {
    membersList: [],
    newModal: false,
    member: {},
    loading: true,
    edit: false,
    id: null
  };

  componentDidMount() {
    this.getMembers();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getMembers = () => {
    axios.get("/member_types", { cancelToken: this.signal.token }).then(res => {
      this.setState({ membersList: res.data.member_types, loading: false });
    });
  };

  newModal = () => {
    window.scrollTo(0, 0);
    this.setState({ newModal: true, edit: false });
  };

  editModal = id => {
    window.scrollTo(0, 0);
    this.setState({ edit: true, newModal: true, id: id });
  };

  deleteHandler = id => {
    let response = window.confirm("Are you sure?");
    if (response) {
      axios.delete(`/member_types/${id}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Member type successfully deleted");
            this.getMembers();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              if (_.isEmpty(res.data.json.errors))
                toast.error("Cannot delete the member type");
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  };

  render() {
    const members = this.state.membersList;
    let membersList;
    if (this.state.loading) {
      membersList = <Spinner />;
    } else {
      membersList = members.map((member, index) => {
        const tooltip =
          <OverlayTrigger key={"top"} placement={"top"}
            overlay={
              <Popover>
                <div>
                  Member Type Id: <span className="font-weight-bold">{member.id}</span>
                </div>
              </Popover>
            }
          >
            <i
              className="fa fa-info-circle text-muted cursor"
              aria-hidden="true"
            />
          </OverlayTrigger>

        return (
          <div className="list-group-item" key={index}>
            <div className="row align-items-center">
              <div className="col-lg-1">{tooltip}</div>
              <div className="col-lg-2">
                <div>{member.name}</div>
                <div className="text-dark">
                  {member.description}
                </div>
              </div>
              <div className="col-lg-2">
                <i className={classnames(
                    "fas fa-check-circle",
                    {
                      "text-info": member.is_public,
                      "text-secondary member-list-item-disabled": !member.is_public,
                    }
                  )}
                />
              </div>
              <div className="col-lg-2">
                <i className={classnames(
                    "fas fa-check-circle",
                    {
                      "text-info": member.require_basic_info,
                      "text-secondary member-list-item-disabled": !member.require_basic_info,
                    }
                  )}
                />
              </div>
              <div className="col-lg-2">
                <i className={classnames(
                    "fas fa-check-circle",
                    { 
                      "text-info": member.lifetime,
                      "text-secondary element-disabled": !member.lifetime, 
                    }
                  )}
                />
              </div>
              <div className="col-lg-2">
                  {!!member.membership_price && (`$${member.membership_price}`)}
              </div>
              <div className="col-lg-1">
                <div className="d-flex justify-content-around align-items-center px-2">
                  <i
                    className="fas fa-pencil-alt cursor mr-2"
                    onClick={() => this.editModal(member.id)}
                  />
                  <i
                    className={classnames(
                      "fas fa-trash ml-3",
                      {
                        "cursor": member.can_delete,
                        "cursor-not-allowed element-disabled": !member.can_delete
                      }
                    )}
                    {...member.can_delete && {
                      onClick: () => this.deleteHandler(member.id) 
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )

      });
    }
    return (
      <div className="row users-and-members-member">
        <div className="col-lg-12 text-lg-right text-center py-3">
          <div className="px-3">
            <Button type="success" clicked={this.newModal}>
              {langUtils("txt_global_add_new", "Add New")}
            </Button>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="row">
                <div className="col-lg-1 font-weight-bold">Id</div>
                <div className="col-lg-2 font-weight-bold">Membership detail</div>
                <div className="col-lg-2 font-weight-bold">Public (API)</div>
                <div className="col-lg-2 font-weight-bold">Require Basic Info?</div>
                <div className="col-lg-2 font-weight-bold">Lifetime Member?</div>
                <div className="col-lg-2 font-weight-bold">Membership Dues / Year</div>
              </div>
            </div>
            {membersList}
          </div>      
        </div>
        <Modal
          show={this.state.newModal}
          title={this.state.edit ? "Edit Member Type" : "New Member Type"}
          hide={() => this.setState({ newModal: false })}
          flag={true}
        >
          <AddNewMember
            type="category"
            id={this.state.id}
            getMembers={this.getMembers}
            closeModal={() => this.setState({ newModal: false })}
            edit={this.state.edit}
          />
        </Modal>
      </div>
    );
  }
}

export default Members;
