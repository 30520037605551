import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case"
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";

class AddNewFlag extends Component {
  signal = axios.CancelToken.source();
  state = {
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        placeholder: "",
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        required: true
      },
      enteredName: ""
    },
    flagtype: {
      elementType: "select",
      elementConfig: {
        name: "type",
        placeholder: "Please Select",
        options: [],
        required: true,
        hide: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    description: {
      elementType: "textarea",
      elementConfig: {
        name: "description",
        rows: "6"
      },
      value: ""
    },
    showModal: true,
    disabled: false
  };

  closeModal = () => {
    this.props.closeModal();
  };

  componentWillMount() {
    this.setState({ flagData: null });
    axios
      .get("/flags/formdata", { cancelToken: this.signal.token })
      .then(res => {
        const types = res.data.json;
        this.setState({
          flagtype: {
            ...this.state.flagtype,
            elementConfig: {
              ...this.state.flagtype.elementConfig,
              options: types.map(type => {
                return { value: type.name, id: type.id, label: type.name };
              })
            }
          }
        });
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, elementType) => {
    if (elementType === "select") {
      let selectedOption = event;
      this.setState({
        flagtype: {
          ...this.state.flagtype,
          selectedOption: selectedOption
        }
      });
    } else if (elementType === "input") {
      let name = event.target.value;
      this.setState({
        name: {
          ...this.state.name,
          enteredName: name
        }
      });
    } else {
      if (elementType === "textArea") {
        let description = event.target.value;
        this.setState({
          description: {
            ...this.state.description,
            value: description
          }
        });
      }
    }
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const queryParams = {
      name: this.state.name.enteredName,
      flag_type_id: this.state.flagtype.selectedOption.id,
      description: this.state.description.value,
      user_id: this.props.userId
    };
    if (
      this.state.name.enteredName !== "" &&
      this.state.flagtype.selectedOption.id !== undefined
    ) {
      axios
        .post("/flags", queryParams)
        .then(res => {
          if (res.data.json.success) {
            let message = "Flag created successfully";
            this.props.closeModal();
            this.props.updateFlags(res);
            toast.success(message);
          } else {
            if ('errors' in res.data.json) {
              Object.keys(res.data.json.errors).forEach(key => {
                toast.error(title(key) + " " + res.data.json.errors[key])
              })
            }
            if ('error' in res.data.json) {
              toast.error(res.data.json.error)
            }
            this.setState({ disabled: false })
          }
        })
        .catch(error => error);
    }
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <p>Name</p>
        <Input
          {...this.state.name}
          changed={event => this.inputChangedHandler(event, "input")}
        />
        <p>Flag Type</p>
        <Input
          {...this.state.flagtype}
          changed={event => this.inputChangedHandler(event, "select")}
        />
        <p>Description</p>
        <Input
          {...this.state.description}
          changed={event => this.inputChangedHandler(event, "textArea")}
        />
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            className="float-left mr-3"
            clicked={this.props.closeModal}
          >
            Cancel
          </Button>
          <FormButton disabled={this.state.disabled}>Submit</FormButton>
        </div>
      </form>
    );
  }
}
export default AddNewFlag;
