import {
  AUTHENTICATE_USER,
  GET_SIDE_BAR,
  GET_INVOICES_POLICY,
  IS_ADMIN,
  RESET_ADMIN_STATUS,
} from "../actions/actionTypes";

const initialState = {
  isAdmin: false,
  isAuthenticated: false,
  userId: null,
  organizationId: null,
  roleLevel: null,
  sideBar: {},
  policyRes: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        userId: action.userId,
        organizationId: action.organizationId,
        roleLevel: action.roleLevel,
      };
    case GET_SIDE_BAR:
      return {
        ...state,
        sideBar: { forms: true, ...action.sideBar },
      };
    case GET_INVOICES_POLICY:
      return {
        ...state,
        policyRes: action.policyRes,
      };
    case IS_ADMIN:
      return { ...state, isAdmin: action.isAdmin }
    case RESET_ADMIN_STATUS:
      return { ...state, isAdmin: false }
    default:
      return state;
  }
};

export default reducer;
