import React, { Component } from "react"
import { Link } from "react-router-dom"
import Calendar from "react-calendar"
import dateFns, { addDays, startOfMonth, endOfMonth } from "date-fns"
import axios from "../../configAxios"
import Spinner from "../UI/Spinner"
import "./index.css"

class CalendarView extends Component {
  signal = axios.CancelToken.source()
  state = {
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    bookedDates: null,
    facilityBookedDates: null,
    bulkCount: [],
    loading: false,
  }

  componentDidMount() {
    this.getBookedDates()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getBookedDates = () => {
    this.setState({ loading: true })
    const { startOfMonth } = this.state
    let bulkCount = []
    if (this.props.isBulkAsset) {
      axios
        .get("/assets/get_bulk_count", {
          params: {
            id: this.props.assetId,
            year: dateFns.getYear(startOfMonth),
            month: dateFns.getMonth(startOfMonth) + 1,
            calendar_type: this.props.calendarType,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          bulkCount = res.data.json.bulk_count_total
          this.setState({
            bulkCount,
          })
        })
    }
    axios
      .get("/assets/calendar", {
        params: {
          id: this.props.assetId,
          month: dateFns.getMonth(startOfMonth) + 1,
          year: dateFns.getYear(startOfMonth),
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const { assets } = res.data
        let bookedDates = []
        const facilityBookedDates = {}
        assets.forEach((asset) => {
          let title
          if (asset.booking.user_or_program === "User") {
            title =
              asset.booking.user_or_program_detail.organization_name ||
              `${asset.booking.user_or_program_detail.first_name} ${asset.booking.user_or_program_detail.last_name}`
          } else {
            title = asset.booking.user_or_program_detail.name
          }

          const bookingId = asset.booking.id
          const id = asset.booking.user_or_program_detail.id
          const userOrProgram = asset.booking.user_or_program
          const ticketId = asset.booking.ticket.id
          if (asset.booking.asset.type !== "Facility") {
            if (!this.props.isBulkAsset) {
              let startDate = new Date(asset.booking.details.start_time) //YYYY-MM-DD
              let endDate = new Date(asset.booking.details.end_time) //YYYY-MM-DD
              let currentMonth = dateFns.getMonth(startOfMonth)
              let date = new Date(startDate)
              while (date <= endDate) {
                const day = dateFns.getDate(date)
                if (dateFns.getMonth(date) === currentMonth) {
                  bookedDates.push({
                    id,
                    day,
                    title,
                    userOrProgram,
                    ticketId,
                    month: "current",
                  })
                } else if (dateFns.getMonth(date) === currentMonth - 1) {
                  bookedDates.push({
                    id,
                    day,
                    title,
                    userOrProgram,
                    ticketId,
                    month: "prev",
                  })
                } else if (dateFns.getMonth(date) === currentMonth + 1) {
                  bookedDates.push({
                    id,
                    day,
                    title,
                    userOrProgram,
                    ticketId,
                    month: "next",
                  })
                }
                date.setDate(date.getDate() + 1)
              }
            }
          } else {
            let date = dateFns.getDate(asset.booking.details.day)
            const month = dateFns.getMonth(asset.booking.details.day)
            const startTime = dateFns.format(asset.booking.details.start_time, "hh:mma")
            const endTime = dateFns.format(asset.booking.details.end_time, "hh:mma")
            if (
              facilityBookedDates[month] &&
              facilityBookedDates[month][date] &&
              facilityBookedDates[month][date].length
            ) {
              facilityBookedDates[month][date].push({
                time: `${startTime}-${endTime}`,
                id,
                userOrProgram,
                ticketId,
                title,
                bookingId,
                day: asset.booking.details.day,
              })
            } else {
              if (facilityBookedDates[month]) {
                facilityBookedDates[month][date] = [
                  {
                    time: `${startTime}-${endTime}`,
                    id,
                    userOrProgram,
                    ticketId,
                    title,
                    bookingId,
                    day: asset.booking.details.day,
                  },
                ]
              } else {
                facilityBookedDates[month] = {}
                facilityBookedDates[month][date] = [
                  {
                    time: `${startTime}-${endTime}`,
                    id,
                    userOrProgram,
                    ticketId,
                    title,
                    bookingId,
                    day: asset.booking.details.day,
                  },
                ]
              }
            }
          }
        })
        this.setState({ bookedDates, facilityBookedDates, loading: false })
      })
  }

  monthChangeHandler = (date) => {
    this.setState(
      {
        startOfMonth: startOfMonth(addDays(date, 1)),
        endOfMonth: endOfMonth(date),
      },
      () => this.getBookedDates()
    )
  }

  render() {
    const {
      bookedDates,
      startOfMonth,
      bulkCount,
      facilityBookedDates,
    } = this.state

    const renderTileContent = ({ date, view }) => {
      let content
      bulkCount &&
        bulkCount.map((count) => {
          if (view === "month" && dateFns.getMonth(startOfMonth) === dateFns.getMonth(date) &&
            this.props.isBulkAsset && parseInt(count.date) === dateFns.getDate(date))
          { content = <div>{`${count.bulk_count_left} left`}</div> }
          return content
        })
      bookedDates &&
        bookedDates.forEach((obj) => {
          const bookedDate = obj.day
          if (view === "month" && parseInt(bookedDate) === dateFns.getDate(date)) {
            if (obj.month === "prev") {
              if (dateFns.getMonth(startOfMonth) - 1 === dateFns.getMonth(date)) {
                content = (
                  <div
                    className="tile-content-container justify-content-center"
                    style={{ opacity: 0.8 }}
                  >
                    <div className="row my-2">
                      {" "}
                      <div className="col-md-12">
                        <Link
                          to={
                            obj.userOrProgram.toLowerCase() === "user"
                              ? `/users/${obj.id}/bookings?ticketId=${obj.ticketId}`
                              : `/programs/${obj.id}/bookings`
                          }
                          className="decoration-none"
                        >
                          <p className="tile-content-text mb-0">{obj.title}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              }
            } else if (obj.month === "current") {
              if (dateFns.getMonth(startOfMonth) === dateFns.getMonth(date)) {
                content = (
                  <div className="tile-content-container justify-content-center">
                    <div className="row my-2">
                      {" "}
                      <div className="col-md-12">
                        <Link
                          to={
                            obj.userOrProgram.toLowerCase() === "user"
                              ? `/users/${obj.id}/bookings?ticketId=${obj.ticketId}`
                              : `/programs/${obj.id}/bookings`
                          }
                          className="decoration-none"
                        >
                          <p className="tile-content-text mb-0">{obj.title}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              }
            } else if (obj.month === "next") {
              if (dateFns.getMonth(startOfMonth) + 1 === dateFns.getMonth(date)) {
                content = (
                  <div
                    className="tile-content-container justify-content-center"
                    style={{ opacity: 0.8 }}
                  >
                    <div className="row my-2">
                      {" "}
                      <div className="col-md-12">
                        <Link
                          to={
                            obj.userOrProgram.toLowerCase() === "user"
                              ? `/users/${obj.id}/bookings?ticketId=${obj.ticketId}`
                              : `/programs/${obj.id}/bookings`
                          }
                          className="decoration-none"
                        >
                          <p className="tile-content-text mb-0">{obj.title}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              }
            }
          }
        })
      return content
    }

    const facilityTileContent = ({ date, view }) => {
      let content
      const month = dateFns.getMonth(date)
      if (view === "month") {
        facilityBookedDates && facilityBookedDates[month] &&
          Object.keys(facilityBookedDates[month]).forEach((bookedDate) => {
            if (parseInt(bookedDate) === dateFns.getDate(date)) {
              const tooltip = (
                <div className="custom-tooltip tooltip-right">
                  {facilityBookedDates[month][bookedDate].map(
                    (detail, index) => {
                      if (index === 0) {
                        return null
                      } else {
                        return (
                          <div
                            key={detail.bookingId}
                            className="font-15 font-weight-bold my-2"
                          >
                            <Link
                              to={
                                detail.userOrProgram.toLowerCase() === "user"
                                  ? `/users/${detail.id}/bookings?ticketId=${detail.ticketId}`
                                  : `/programs/${detail.id}/bookings`
                              }
                              key={detail.bookingId}
                            >
                              <p className="tooltip-link mb-2">
                                {detail.title}
                              </p>
                              <p className="tooltip-link mb-0">{detail.time}</p>
                            </Link>
                          </div>
                        )
                      }
                    }
                  )}
                </div>
              )
              const detail = facilityBookedDates[month][bookedDate][0]
              content = (
                <div
                  className="tile-content-container justify-content-center tile-content"
                  key={detail.bookingId}
                >
                  <div className="row text-container my-2">
                    {" "}
                    <div
                      className={`${
                        facilityBookedDates[month][bookedDate].length > 1 
                          ? "col-md-9" : "col-md-12"
                      }`}
                    >
                      <Link
                        to={
                          detail.userOrProgram.toLowerCase() === "user"
                            ? `/users/${detail.id}/bookings?ticketId=${detail.ticketId}`
                            : `/programs/${detail.id}/bookings`
                        }
                        key={detail.bookingId}
                        className="tile-content-link"
                      >
                        <p className="tile-content-text mb-0">{detail.title}</p>
                        <p className="tile-content-text mb-0">{detail.time}</p>
                      </Link>
                    </div>
                    {facilityBookedDates[month][bookedDate].length > 1 && (
                      <div className="col-md-3">
                        <p className="badge mt-3 calendar-badge">
                          {facilityBookedDates[month][bookedDate].length > 1 ?
                            `${facilityBookedDates[month][bookedDate].length - 1}+` : ""}
                          {tooltip}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )
            }
          })
        return content
      }
    }

    return (
      <div className="row m-0 calender-view">
        {this.state.loading && <Spinner />}
        <div className={!this.state.loading ? "w-100 d-block" : "w-100 d-none"}>
          <Calendar
            calendarType="US"
            tileContent={
              this.props.calendarType && this.props.calendarType !== "Facility"
                ? renderTileContent : facilityTileContent
            }
            onActiveDateChange={(payload) =>
              this.monthChangeHandler(payload.activeStartDate)
            }
            onClickMonth={(date) => this.monthChangeHandler(date)}
          />
        </div>
      </div>
    )
  }
}

export default CalendarView
