import AsyncSelect from "react-select/lib/Async"
import { customStylesFlag } from "./customStyles"
import React from "react"

const AsyncSelectInput = ({ props }) => {
  return (
    <div>
      <AsyncSelect
        value={props.value}
        isClearable
        isSearchable
        onChange={props.changed}
        noOptionsMessage={() => {
          if (props.chart !== undefined && props.chart.length > 0) {
            return "No Option"
          } else {
            return props.elementConfig.inputLength
              ? `Type ${props.elementConfig.inputLength} characters..`
              : "Type 3 characters.."
          }
        }}
        placeholder={
          props.elementConfig &&
          props.elementConfig.hasOwnProperty("placeholder")
            ? props.elementConfig.placeholder
            : "Select Option"
        }
        onInputChange={props.onInputChange}
        {...props.elementConfig}
        styles={{ ...customStylesFlag, ...props.elementConfig.styles }}
      />
      <input
        value={
          props.selectedOption && props.selectedOption !== undefined
            ? props.selectedOption.value
            : props.value
            ? props.value
            : ""
        }
        required={props.elementConfig.required}
        className="form-control"
        style={{
          opacity: 0,
          height: 0,
          position: "absolute",
        }}
      />
    </div>
  )
}

export default AsyncSelectInput
