import { setResponse, clearResponse, setLoading } from "./index";
import axios from "../../configAxios";
import { capitalize } from 'lodash'

export const createObject = ({ route, data, object, callback, message }) => {
  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      dispatch(setLoading(true));
      const res = await axios.post(route, data);
      const { success } = res.data.json;
      if (success) {
        callback && callback(res.data.json.data[object])
        dispatch(setResponse(true, `${message}`));
      } else {
        const { errors } = res.data.json;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${capitalize(key)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};

export const createObjects = ({ route, dataArray, callback, message }) => {

  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      dispatch(setLoading(true));
      const responseArray = await Promise.all(dataArray.map(item => axios.post(route, item)))
      const response = responseArray.slice(-1)[0].data.json;
                                
      if (response.success) {
        callback && callback()
        dispatch(setResponse(true, message));
      } else {
        const errors = response.errors;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${capitalize(key)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};

export const updateObject = ({ route, data, object, callback, message }) => {
  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      dispatch(setLoading(true));
      const res = await axios.put(route, data);
      const { success } = res.data.json;
      if (success) {
        callback && callback(res.data.json.data[object])
        dispatch(setResponse(true, `${message}`));
      } else {
        const { errors } = res.data.json;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${capitalize(key)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};


export const deleteObject = ({ route, data, callback, message }) => {
  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      dispatch(setLoading(true));
      const res = await axios.delete(route, data);
      const { success } = res.data.json;
      if (success) {
        callback && callback()
        dispatch(setResponse(true, `${message}`));
      } else {
        const { errors } = res.data.json;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${capitalize(key)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};

export const deleteObjects = ({ route, dataArray, message, callback }) => {
  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      dispatch(setLoading(true));
      const responseArray = await Promise.all(dataArray.map(item => axios.delete(route, { params :item })))
      const response = responseArray.slice(-1)[0].data.json;
                                
      if (response.success) {
        callback && callback()
        dispatch(setResponse(true, `${message}`));
      } else {
        const errors = response.errors;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${capitalize(key)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};
