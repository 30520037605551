import React, { Component } from "react"

import Program from "./Program"
import CurrentRegistration from "./CurrentRegistration"
import PastRegistration from "./PastRegistration"

import axios from "../../configAxios"
import Pagination from "../UI/Pagination"
import Spinner from "../UI/Spinner"
import Modal from "../UI/Modal"
import Input from "../UI/input/Input"
import FormButton from "../UI/button/FormButton"

class Programs extends Component {
  signal = axios.CancelToken.source()
  state = {
    programs: [],
    registeredPrograms: [],
    registeredId: [],
    forcePage: 0,
    totalCount: 0,
    showModal: false,
    disableSearchButton: true,
    search: {
      elementType: "input",
      elementConfig: {
        name: "search",
        placeholder: "Search",
      },
      value: "",
    },
    programStatus: {
      elementType: "select",
      elementConfig: {
        name: "status",
        placeholder: "Select Status",
        options: [],
        isSearchable: false,
        isLoading: true,
        components: {
          IndicatorSeparator: () => null,
        },
      },
      customStyle: {
        menu: (style) => ({ ...style, zIndex: "3" }),
      },
      selectedOption: null,
      managePrograms: null,
    },
  }

  componentDidMount() {
    this.setState({ programs: null })
    axios
      .get("/programs/filters", { cancelToken: this.signal.token })
      .then((res) => {
        const defaultValue = res.data.json.status
          .filter((status) => status.value === "Active")
          .map((status) => ({ label: status.value, value: status.key }))[0]
        this.setState(
          {
            programStatus: {
              ...this.state.programStatus,
              elementConfig: {
                ...this.state.programStatus.elementConfig,
                options: res.data.json.status.map((status) => ({
                  label: status.value,
                  value: status.key,
                })),
                defaultValue,
                isLoading: false,
              },
              selectedOption: defaultValue,
            },
          },
          () => {
            this.getAllPrograms()
            this.registeredPrograms()
          }
        )
      })
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios
      .get("/users/policy", { params: { id: this.props.userId } })
      .then((res) => {
        this.setState({
          managePrograms: res.data.json.manage_programs,
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getAllPrograms = (page) => {
    const {
      search: { value },
      programStatus: { selectedOption },
    } = this.state
    const forPageChange = page && page.hasOwnProperty("selected")
    if (forPageChange) window.scrollTo(0, 0)
    this.setState({
      programs: null,
      forcePage: forPageChange ? page.selected : 0,
      disableSearchButton: true,
    })
    axios
      .get("/programs", {
        params: {
          type: "All",
          ...(value && typeof value === "string" && { query: value }),
          ...(selectedOption && { status: selectedOption.value }),
          ...(forPageChange && { page: page.selected + 1 }),
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          programs: res.data.programs,
          totalCount: res.data.meta.total_count,
          disableSearchButton: false,
        })
      })
      .catch(() => this.setState({ disableSearchButton: false }))
  }

  registeredPrograms = () => {
    axios
      .get("/programs", {
        params: {
          user_id: parseInt(this.props.userId),
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const registeredId = []
        for (var i = 0; i < res.data.programs.length; i++) {
          registeredId.push(res.data.programs[i].id)
        }
        this.setState({ registeredId: registeredId })
      })
      .catch((error) => error)
  }

  registerUpdate = (id) => {
    const a = this.state.registeredId.slice(0)
    a.push(id)
    this.setState({
      ...this.state,
      registeredId: a,
      programs: this.state.programs.map((program) => {
        if (program.id === id) {
          program.number_of_enrolled += 1
          return program
        } else {
          return program
        }
      }),
    })
  }

  openModal = (modal) => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true, modal: modal })
  }

  closeModal = () => {
    this.setState({ showModal: false, modal: null })
  }

  render() {
    const registeredId = []
    for (var i = 0; i < this.state.registeredPrograms.length; i++) {
      registeredId.push(this.state.registeredPrograms[i].id)
    }
    let pagination
    let programList
    if (this.state.programs == null) {
      pagination = null
      programList = <Spinner />
    } else if (this.state.programs.length === 0) {
      pagination = null
      programList = (
        <div className="d-flex justify-content-center">No data found</div>
      )
    } else {
      programList = this.state.programs.map((program, index) => (
        <Program
          managePrograms={this.state.managePrograms}
          program={program}
          userId={this.props.userId}
          key={index}
          registeredId={this.state.registeredId}
          registerUpdate={this.registerUpdate}
        />
      ))
      pagination = (
        <Pagination
          forcePage={this.state.forcePage}
          pageCount={this.state.totalCount / 10}
          handlePageClick={this.getAllPrograms}
        />
      )
    }

    return (
      <div className="user-programs">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            this.getAllPrograms()
          }}
        >
          <div className="row pt-4">
            <div className="col-lg-5">
              <div className="pl-4 pr-md-c-4">
                <div className="position-relative">
                  {this.state.search && this.state.search.value !== "" && (
                    <i
                      className="fas fa-times cursor position-absolute remove-icon"
                      onClick={(e) =>
                        this.setState(
                          {
                            search: {
                              ...this.state.search,
                              value: "",
                            },
                          },
                          this.getAllPrograms
                        )
                      }
                    />
                  )}
                  <Input
                    {...this.state.search}
                    className="pr-4 program-search-form"
                    changed={(e) =>
                      this.setState({
                        search: {
                          ...this.state.search,
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="px-md-c-4">
                <Input
                  {...this.state.programStatus}
                  changed={(e) =>
                    this.setState(
                      {
                        programStatus: {
                          ...this.state.programStatus,
                          selectedOption: e,
                        },
                      },
                      () => {
                        if (!e) this.getAllPrograms()
                      }
                    )
                  }
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="px-md-c-4">
                <FormButton
                  className={`program-search-form ${
                    this.state.disableSearchButton
                      ? "cursor-not-allowed"
                      : "cursor"
                  }`}
                  disabled={this.state.disableSearchButton}
                >
                  Search
                </FormButton>
              </div>
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-12 text-right">
            <div className="p-4">
              <p>
                <span
                  className="cursor side-text-color"
                  onClick={
                    this.state.managePrograms
                      ? () => this.openModal("currentRegistration")
                      : null
                  }
                  style={{
                    cursor: this.state.managePrograms ? "pointer" : "no-drop",
                  }}
                >
                  Current Registrations
                </span>{" "}
                |{" "}
                <span
                  className="cursor side-text-color"
                  onClick={
                    this.state.managePrograms
                      ? () => this.openModal("pastRegistration")
                      : null
                  }
                  style={{
                    cursor: this.state.managePrograms ? "pointer" : "no-drop",
                  }}
                >
                  Past Registrations
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="list-group list-group-flush">{programList}</div>
        {pagination}
        <Modal
          closeButton
          closeModal={() => this.closeModal()}
          hide={() => this.setState({ showModal: false })}
          show={this.state.showModal}
          title={
            this.state.modal === "currentRegistration"
              ? "Current Registrations"
              : "Past Registrations"
          }
          style={{ top: "15%" }}
          program={true}
        >
          {this.state.modal === "currentRegistration" ? (
            <CurrentRegistration
              userId={this.props.userId}
              registeredPrograms={this.registeredPrograms}
            />
          ) : (
            <PastRegistration userId={this.props.userId} />
          )}
        </Modal>
      </div>
    )
  }
}
export default Programs
