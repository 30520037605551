import React from "react";
import dateFns from "date-fns";

const RentalItem = props => {
  return (
    <li className="list-group-item p-3">
      <div className="row">
        <div className="col-9">
          <span className="text-primary">{props.ticket.project_name}</span><br />
          by {props.ticket.full_name_cached},{" "}
          {dateFns.format(new Date(props.ticket.created_at), "ddd MMM DD YYYY")}
        </div>
        <div className="col-3 pl-0 text-center d-flex flex-column justify-content-center">
          <span className={`p-1 text-light rounded font-weight-bold ${props.ticket.status_string !== 'Pending' ? 'bg-success' : 'bg-danger'}`}><small>{props.ticket.status_string}</small></span>
        </div>
      </div>
    </li>
  );
};

export default RentalItem;