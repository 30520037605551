import React, { Component } from "react";
import dateFns from "date-fns";
import { toast } from "react-toastify";
import classNames from "classnames";
import { Link } from "react-router-dom";

import axios from "../../../../configAxios";
import { responseErrorHandler } from "../../../../utils/errorHandler";
import Input from "../../../UI/input/Input";
import Spinner from "../../../UI/Spinner";
import Pagination from "../../../UI/Pagination";

import "../index.css";

class UserList extends Component {
  signal = axios.CancelToken.source();
  timeout = 0;
  state = {
    usersList: [],
    user: {},
    loading: true,
    totalCount: null,
    selectedPage: 1,
    forcePage: this.props.forcePage,
    query: "",
    type: {
      text: {
        elementType: "input",
        elementConfig: {
          name: "search",
          type: "text"
        },
        placeholder: "Search"
      }
    }
  };

  componentDidMount() {
    if (this.props.userType === "undefined") {
      this.getInActiveUsers();
    } else {
      this.getUsers({ role_level: this.props.userType });
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.query !== this.state.query) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getUsers({
          role_level: this.props.userType,
          page: 1
        });
      }, 300);
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  componentWillReceiveProps(props) {
    this.setState({ forcePage: props.forcePage });
    this.getUsers({ role_level: props.userType });
  }

  getUsers = params => {
    this.setState({ loading: true });
    axios
      .get("/users", {
        params: {
          type: "tab",
          query: this.state.query,
          ...params
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          usersList: res.data.users,
          loading: false,
          totalCount: res.data.meta.total_count
        });
      });
  };

  getInActiveUsers = (params = {}) => {
    this.setState({ loading: true });
    axios.get("/users/undefined", { params })
      .then(res => {
        this.setState({
          usersList: res.data.users,
          loading: false,
          totalCount: res.data.meta.total_count
        });
      })
  }

  deleteHandler = user => {
    let response = window.confirm(`Are you sure you want to delete ${user.full_name || ""}?`);
    if (response) {
      axios
        .delete(`/users/${user.id}`, {
          cancelToken: this.signal.token
        })
        .then(res => {
          const { userType } = this.props;
          if (res.data.json.success) {
            toast.success("Successfully deleted");
            if ( userType === "undefined" )
              this.getInActiveUsers();
            else
              this.getUsers({
                role_level: this.props.userType,
                page: this.state.selectedPage
              });
          } else {
            responseErrorHandler(res);
          }
        });
    }
  };


  inputChangeHandler = event => {
    this.setState({
      ...this.state,
      query: event.target.value
    });
  };

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1
    });
    if (this.props.userType === "undefined") {
      this.getInActiveUsers({ page: page.selected + 1 });
    } else {
      this.getUsers({
        role_level: this.props.userType,
        page: page.selected + 1
      });
    }
  };

  clearQueryString = () => {
    this.setState({ query: "" });
  };

  render() {
    const users = this.state.usersList;
    let removeIcon;
    if (this.state.query !== "") {
      removeIcon = (
        <i
          className="fas fa-times remove-icon cursor"
          onClick={() => this.clearQueryString()}
        />
      );
    }
    let usersList;
    if (this.state.loading) {
      usersList = <Spinner />;
    } else {
      usersList = users.map(user => (
        <li className="list-group-item" key={user.id}>
          <div className="row align-items-center">
            <div className="col-md-10">
              <div>
                <Link className="text-dark" to={`/users/${user.id}/summary`}>
                  {
                    (typeof user.full_name === "string" &&
                    user.full_name.trim()) ||
                    user.job_position
                      ? (
                        <span>
                          {`${user.full_name}${user.job_position ? ` / ${user.job_position}` : ""}`}
                        </span>
                      ) : (
                        <span>
                          {`ID ${user.id}`}
                        </span>
                      )
                  }
                </Link>
              </div>
              <div className="font-weight-bold">{`Username: ${user.email ? user.email : "--"}`}</div>
              <div className="font-weight-bold">Last Login: {user.last_login ? dateFns.format(new Date(user.last_login), "YYYY-MM-DD") : "--"}</div>
            </div>
            <div className="col-md-2 text-center">
              <div className="d-flex justify-content-md-end justify-content-center my-md-0 my-3">
                {this.props.userType !== "undefined" && (
                  <i
                    className="fas fa-pencil-alt user-edit cursor"
                    onClick={() => this.props.tabChangeHandler("Add Or Edit Form", "edit", user.id)}
                  />
                )}
                {
                  this.props.userType !== "super_admin" &&
                  this.props.userType !== "staff" && (
                    <i
                      className={classNames(
                        "fas fa-trash user-edit ml-4",
                        {
                          "cursor": user.can_delete,
                          "cursor-not-allowed element-disabled": !user.can_delete,
                        }
                      )}
                      onClick={() => {
                        if (user.can_delete)
                        this.deleteHandler(user)
                      }}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </li>
      ));
    }

    let paginationContent = (
      <div className="mt-4" key={this.props.userType}>
        <Pagination
          pageCount={this.state.totalCount / 10}
          forcePage={this.state.forcePage}
          handlePageClick={this.paginationHandler}
        />
      </div>
    );
    return (
      <div className="users-and-members-users">
        {
          this.props.userType === "member" || this.props.userType === "non_member" ? (
            <div className="text-center px-5">
              <i className="fas fa-search member-search" />
              {removeIcon}
              <Input
                {...this.state.type.text}
                value={this.state.query}
                changed={event => this.inputChangeHandler(event)}
                style={{ maxWidth: "100%", padding: "2%", paddingRight: "65px" }}
              />
            </div>
          ) : ("")
        }
        {!this.state.loading && this.state.totalCount === 0 ?
          <div className="text-center font-weight-bold my-5">No User</div>
          : (
            <ul className="list-group list-group-flush">
              {usersList}
            </ul>
          )
        }
        <div>{paginationContent}</div>
        <div style={{ height: 40 }} />
      </div>
    );
  }
}

export default UserList;
