import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./index.css";
class FlagListing extends Component {
  render() {
    let assetId,
      userId = null;
    if (this.props.data.user_or_asset.hasOwnProperty("asset")) {
      assetId = this.props.data.user_or_asset.asset.id;
    }

    if (this.props.data.user_or_asset.hasOwnProperty("user")) {
      userId = this.props.data.user_or_asset.user.id;
    }
    if (this.props.data !== undefined) {
      return (
        <tr>
          <td className="w-35 word-wrap-break">
            {
              typeof this.props.data.user_or_asset === "string" ? (
                ""
              ) : this.props.data.user_or_asset.user !== undefined ? (
                <p>
                  User:{" "}
                  <span>
                    <Link to={`/users/${userId}/summary`} className="text-info">
                      {this.props.data.user_or_asset.user.name}
                    </Link>
                  </span>
                </p>
              ) : (
                    <p>
                      Asset:{" "}
                      <span>
                        <Link to={`/assets/${assetId}`} className="text-info">
                          {this.props.data.user_or_asset.asset.name}
                        </Link>
                      </span>
                    </p>
                  )
            }
            <p>
              Type: <span>{this.props.data.flag_type}</span>
            </p>
          </td>
          <td className="w-35 word-wrap-break">
            <p>
              {this.props.data.name != null ? (
                <span className="font-weight-bold">
                  {this.props.data.name}
                </span>
              ) : null}
            </p>
            <span>
              {this.props.data.description !== "" &&
                this.props.data.description !== null ? (
                  <p>
                    <span>{this.props.data.description}</span>
                  </p>
                ) : (
                  "No Description Available."
                )}
            </span>
          </td>
          <td className="w-20 word-wrap-break">
            <span>
              {this.props.data.created_by === "" ||
                this.props.data.created_by === null ? (
                  ""
                ) : (
                  <p>
                    By: <span>{this.props.data.created_by}</span>
                  </p>
                )}
            </span>
            <p>{new Date(this.props.data.created_at).toDateString()} </p>
          </td>
          <td className="w-15 text-center flag-listing ">
            <button
              className="fas fa-trash text-danger bg-transparent delete-icon "
              flag={this.props.searchCondition}
              onClick={() => {
                this.props.deleteHandler(
                  this.props.searchCondition
                    ? this.props.data.id
                    : this.props.data.id
                );
              }}
              style={{
                cursor: this.props.disable ? "no-drop" : "pointer",
                opacity: this.props.disable ? 0.7 : 1
              }}
              disabled={this.props.disable}
            />
          </td>
        </tr>
      );
    } else {
      return null;
    }
  }
}
export default FlagListing;
