import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { title } from "change-case"
import { formatDate } from "../../utils/formatDate"

import axios from "../../configAxios"
import Button from "../UI/button/Button"
import langUtil from "../../utils/langUtils"
import Pagination from "../UI/Pagination"
import Spinner from "../UI/Spinner"

import Flags from "./Flags"
import "./Summary.css"
import errorStatusHandler from "../errorPages/UserNotAuthorized"
import GrantApplications from "./grantApplications/index";

class Summary extends Component {
  signal = axios.CancelToken.source()
  state = {
    userData: "",
    flags: null,
    tickets: null,
    programs: null,
    showModal: false,
    selectedTickets: [],
    scheduledDates: [],
    ticketsTotalCount: 0,
    flagCount: 0,
    ticketQueryParams: {
      user_id: parseInt(this.props.userId),
      per_page: 5,
      page: 1,
    },
    loading: true,
  }

  componentDidMount() {
    this.getUserDetail()
    this.getTickets()
    this.getWarningMessage()
    axios
      .get("/programs", {
        params: {
          user_id: parseInt(this.props.userId),
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({ programs: res.data.programs })
      })
      .catch((error) => error)
    this.getProgramScheduledDates()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getUserDetail = () => {
    axios
      .get(`users/${this.props.userId}`, { cancelToken: this.signal.token })
      .then((res) => {
        this.setState({
          userData: res.data.user,
          flagCount: res.data.user.flags_count,
        })
      })
      .catch((error) => error)
  }

  getTickets = () => {
    axios
      .get("/tickets", {
        params: this.state.ticketQueryParams,
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          tickets: res.data.tickets,
          ticketsTotalCount: res.data.meta.total_count,
        })
      })
      .catch((error) => error)
  }

  getProgramScheduledDates = () => {
    axios
      .get("/users/scheduled_program_dates", {
        params: {
          id: parseInt(this.props.userId),
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const scheduled_dates = []
        const fetchedScheduledDates = res.data.users
        fetchedScheduledDates.map((schedule) => {
          const scheduledDates = {}
          const startDate = new Date(schedule.start)
          const endDate = new Date(schedule.end)
          const date = `${formatDate(
            startDate,
            "YYYY/MM/DD: "
          )} ${formatDate(startDate, "hh:mmA")} - ${formatDate(
            endDate,
            "hh:mmA"
          )}`
          scheduledDates.id = schedule.id
          scheduledDates.date = date
          scheduledDates.programId = schedule.program.id
          return scheduled_dates.push(scheduledDates)
        })
        this.setState({ scheduledDates: scheduled_dates })
      })
  }

  updateFlags = (res) => {
    const flags = this.state.flags.slice(0)
    flags.splice(0, 0, res.data.json.data)
    this.setState({ flags: flags })
  }

  addNewFlagHandler = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true })
  }

  deleteHandler = (index) => {
    const flagId = this.state.flags[index].id
    const queryParams = {
      params: {
        id: flagId,
      },
    }

    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      axios.delete("/flags", queryParams).then((res) => {
        if (res.data.json.success) {
          toast.success("Deleted Successfully")
          const flags = this.state.flags.slice(0)
          flags.splice(index, 1)
          this.setState({ flags: flags })
          if (this.state.flags == null) {
            this.setState({ flags: [] })
          }
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
    }
  }

  downloadPackingInvoice = (tickedId) => {
    axios
      .get("/tickets/packing_list", {
        params: {
          id: tickedId,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        if (res.status === 202) {
          errorStatusHandler(res)
        } else {
          window.open(res.data.json.file_path, "_blank")
        }
      })
      .catch((err) => {
        const { data } = err.response
        if (data && data.hasOwnProperty("error")) {
          toast.error(data.error)
        }
      })
  }

  selectTicketHandler = (tickedId) => {
    const { selectedTickets } = this.state
    if (selectedTickets.indexOf(tickedId) === -1) {
      this.setState({ selectedTickets: [...selectedTickets, tickedId] })
    } else {
      this.setState({
        selectedTickets: selectedTickets.filter((id) => id !== tickedId),
      })
    }
  }

  mergeTicketHandler = () => {
    axios
      .post("/tickets/merge", {
        ticket_ids: { ids: [...this.state.selectedTickets] },
      })
      .then((res) => {
        toast.success("Tickets merged successfully")
        this.setState(
          {
            selectedTickets: [],
            ticketQueryParams: {
              ...this.state.ticketQueryParams,
              page: 1,
            },
          },
          () => this.getTickets()
        )
      })
      .catch(() => toast.error("Failed to merge tickets"))
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState(
      {
        tickets: null,
        ticketQueryParams: {
          ...this.state.ticketQueryParams,
          page: page.selected + 1,
        },
      },
      () => this.getTickets()
    )
  }

  getProgramsScheduledDates(programs, scheduledDates) {
    const scheduledDatesArray = []
    programs.forEach((program) => {
      scheduledDates.forEach((schedule) => {
        if (program.id === schedule.programId) {
          scheduledDatesArray.push({
            scheduleId: schedule.id,
            programId: program.id,
            programName: program.name,
            scheduleDate: schedule.date,
          })
        }
      })
    })
    return scheduledDatesArray
  }

  getWarningMessage = () => {
    axios
      .get("users/warning_messages", {
        params: { id: this.props.match.params.id },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({ warningMessages: res.data.json, loading: false })
      })
      .catch((error) => error)
  }

  render() {
    const { policyRes } = this.props
    const {
      selectedTickets,
      programs,
      scheduledDates,
      tickets,
      userData,
      warningMessages,
    } = this.state

    const distinctPrograms = Array.from(
      new Set(programs && programs.map((el) => el.id))
    ).map((id) => {
      return {
        id: id,
        name: programs.find((el) => el.id === id).name,
      }
    })

    const scheduledProgramsDates =
      programs &&
      scheduledDates &&
      this.getProgramsScheduledDates(distinctPrograms, scheduledDates)

    let programList, ticketList
    if (programs === null) {
      programList = <Spinner className="w-100 h-20vh" />
    } else if (!(programs.length && scheduledDates.length)) {
      programList = (
        <div className="d-flex justify-content-center align-items-center list-container">
          <h4 className="text-dark">No {langUtil("programs", "Programs")}</h4>
        </div>
      )
    } else {
      programList =
        scheduledProgramsDates &&
        scheduledProgramsDates.map((programDates) => (
          <div
            key={programDates.scheduleId}
            className="d-flex justify-content-between border-top"
            style={{
              padding: 10,
              paddingLeft: "20px",
            }}
          >
            <p>Program Session</p>
            <p>{programDates.scheduleDate}</p>
            <div className="col-md-4">
              <p className="text-right">
                <Link
                  to={"/programs/" + programDates.programId}
                  className="text-info font-weight-bold"
                >
                  {programDates.programName}
                </Link>
              </p>
            </div>
          </div>
        ))
    }

    if (tickets === null) {
      ticketList = <Spinner className="w-100 h-20vh" />
    } else if (tickets.length === 0) {
      ticketList = (
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center h-30vh">
            <h4 className="text-dark">No Tickets</h4>
          </div>
        </div>
      )
    } else {
      ticketList = tickets.map((ticket, index) => {
        const formattedTicketEquipmentStartTimeDate = formatDate(
          new Date(ticket.equipment_items?.start_time),
          "YYYY/MM/DD"
        )
        const formattedTicketEquipmentEndTimeDate = formatDate(
          new Date(ticket.equipment_items?.end_time),
          "YYYY/MM/DD"
        )
        const equipmentItem = ticket.equipment_items && (
          <span className="summary">
            {`#${ticket.id} - `}
            <span className="label other">{ticket.status_string}</span> -
            {` ${ticket.equipment_items.count} Equipment ${
              ticket.equipment_items.count > 1 ? "bookings" : "booking"
            }
           between
        ${formattedTicketEquipmentStartTimeDate} and  ${formattedTicketEquipmentEndTimeDate}`}
          </span>
        )
        const formattedTicketFacilityStartTimeDate = formatDate(
          new Date(ticket?.facilities?.start_time),
          "YYYY/MM/DD h:mm A"
        )
        const formattedTicketFacilityEndTimeDate = formatDate(
          new Date(ticket?.facilities?.end_time),
          "YYYY/MM/DD h:mm A"
        )
        const facilityItem = ticket.facilities && (
          <span className="summary">
            {`#${ticket.id} - `}
            <span className="label other">{ticket.status_string}</span> -
            {` ${ticket.facilities.count} Facility ${
              ticket.facilities.count > 1 ? "bookings" : "booking"
            }
          between
        ${formattedTicketFacilityStartTimeDate} and  ${formattedTicketFacilityEndTimeDate}`}
          </span>
        )
        return (
          <div className="list-group-item" key={index}>
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div
                    className="col-12 mt-1"
                    onClick={() => this.selectTicketHandler(ticket.id)}
                  >
                    <div className="cursor">
                      <div
                        className="row"
                        onClick={() => this.selectTicketHandler(ticket.id)}
                      >
                        <div className="col-1">
                          {policyRes.can_merge && (
                            <input
                              type="checkbox"
                              checked={
                                selectedTickets.indexOf(ticket.id) !== -1
                              }
                              className="mx-3"
                            />
                          )}
                        </div>
                        <div className="col-10">
                          <p>
                            {ticket.equipment_items && equipmentItem}
                            {ticket.equipment_items && ", "}
                            {ticket.facilities && facilityItem}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 pl-3">
                    <div className="row no-gutters">
                      {ticket.can_edit ? (
                        <div className="col-lg-4 text-center my-1">
                          <Button
                            type="primary"
                            clicked={() =>
                              this.downloadPackingInvoice(ticket.id)
                            }
                          >
                            Packing List
                          </Button>
                        </div>
                      ) : null}
                      <div
                        className={
                          ticket.can_edit
                            ? "col-lg-4 text-center pl-2 my-1"
                            : "col-lg-4 text-center button-margin my-1"
                        }
                      >
                        <Button
                          type="danger"
                          disabled={ticket.is_ticket_locked || !ticket.can_edit}
                          style={{
                            cursor:
                              ticket.is_ticket_locked || !ticket.can_edit
                                ? "no-drop"
                                : "pointer",
                            opacity:
                              ticket.is_ticket_locked || !ticket.can_edit
                                ? 0.7
                                : 1,
                          }}
                          clicked={() =>
                            this.props.tabChangeHandler("Bookings", ticket.id)
                          }
                        >
                          {!ticket.is_ticket_locked && ticket.can_edit
                            ? "Edit Ticket"
                            : "Ticket is Locked"}
                        </Button>
                      </div>
                      {ticket.invoice_id && ticket.can_view_invoice && (
                        <div className="col-lg-4 text-center pl-3 my-1">
                          <Link to={`/invoices/${ticket.invoice_id}`}>
                            <Button type="primary" className="cursor">
                              View Invoice
                            </Button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  {ticket.notes ? (
                    <div
                      className={
                        ticket.can_edit
                          ? "row mt-3 ml-2 pl-2"
                          : "row mt-3 ml-4 pl-3"
                      }
                    >
                      <div className="col-12">
                        <div>
                          <span>Notes: {ticket.notes}</span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mr-4 mt-3 text-lg-right">
                  <p className="font-weight-bold">{ticket.project_name}</p>
                  <p>Asset Booking</p>
                  <p className="font-weight-bold">{ticket.total}</p>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }
    const user = userData

    const formattedLatestLoginDate = formatDate(
      new Date(user?.last_login).toDateString().substring(4),
      "YYYY/MM/DD"
    )

    const formattedLatestCheckoutDate = formatDate(
      new Date(user?.latest_checkout_or_facility_use).toDateString().substring(4),
      "YYYY/MM/DD"
    )

    return (
      <div className="summary webkit-fill-available">
        <div className="row p-3">
          <div className="col-6 ">
            Latest Checkout:
            <span className="font-weight-bold ml-2">
              {user.latest_checkout_or_facility_use
                ? formattedLatestCheckoutDate
                : "--"}
            </span>
          </div>
          <div className="col-6 text-right">
            Latest Login:
            <span className="font-weight-bold ml-2">
              {user.last_login
                ? formattedLatestLoginDate
                : "--"}
            </span>
          </div>
        </div>

        <div className="row bg-light">
          <div className="col">
            <div className="pl-3 py-2">
              <h5 className="text-dark font-weight-bold m-0">Alerts</h5>
            </div>
          </div>
        </div>
        <div className="row">
          {this.state.loading ? (
            <Spinner className="w-100 h-20vh" />
          ) : (
            <div className="w-100 mt-3 px-4">
              {warningMessages &&
                warningMessages.has_membership_renewal_invoice_due && (
                  <div className="alert alert-danger">
                    This user has past due membership renewal invoice
                  </div>
                )}
              {warningMessages &&
                warningMessages.has_program_registration_invoice_due && (
                  <div className="alert alert-danger">
                    This user has past due program registration invoices
                  </div>
                )}
              {warningMessages && warningMessages.has_rental_invoice_due && (
                <div className="alert alert-danger">
                  This user has past due rental invoices
                </div>
              )}
              {warningMessages && warningMessages.membership_expired && (
                <div className="alert alert-danger">
                  This user&apos;s Membership has expired
                </div>
              )}
              {warningMessages && warningMessages.can_rent_equipment && (
                <div className="alert alert-danger">
                  This user does not have sufficient privileges to rent
                  Equipment
                </div>
              )}
              {warningMessages && warningMessages.can_book_facilities && (
                <div className="alert alert-danger">
                  This user does not have sufficient privileges to book
                  Facilities
                </div>
              )}
              {warningMessages && warningMessages.can_register_in_programs && (
                <div className="alert alert-danger">
                  This user does not have sufficient privileges to register in
                  Programs
                </div>
              )}
            </div>
          )}
        </div>

        <Flags
          userId={this.props.userId}
          user={user.id}
          policyRes={this.props.policyRes}
        />
        <hr />
        <div className="row bg-light">
          <div className="col-6">
            <div className="pl-3 py-2">
              <h5 className="text-dark font-weight-bold m-0 mt-1">
                Tickets |{" "}
                <small className="font-weight-bold">
                  Total Tickets: {this.state.ticketsTotalCount}
                </small>
              </h5>
            </div>
          </div>
          <div className="col-6 text-right">
            <div>
              {policyRes.can_merge && (
                <Button
                  type="warning"
                  clicked={this.mergeTicketHandler}
                  disabled={this.state.selectedTickets.length < 2}
                  style={{
                    cursor:
                      this.state.selectedTickets.length > 1
                        ? "pointer"
                        : "no-drop",
                  }}
                  className="m-1"
                >
                  Merge Tickets
                </Button>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="tickets-list-container">
          <div className="list-group list-group-flush">{ticketList}</div>
          <div>
            {this.state.ticketsTotalCount !== 0 && (
              <Pagination
                pageCount={this.state.ticketsTotalCount / 5}
                handlePageClick={this.paginationHandler}
              />
            )}
          </div>
        </div>
        <hr />
        <div className="row bg-light">
          <div className="col-12">
            <div className="pl-3 py-2">
              <h5 className="text-dark font-weight-bold m-0">
                {langUtil("programs", "Programs")}
              </h5>
            </div>
          </div>
        </div>
        <div className="list-container">{programList}</div>
        {user && this.props.policyRes.grant_applications && <>
          <GrantApplications user={user} />
        </>}
      </div>
    )
  }
}

export default withRouter(Summary)
