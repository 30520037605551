import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom"

import Button from "../UI/button/Button"

class EmailVerification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      verfied: false
    }
  }

  componentDidMount() {
    const statuses = ["confirmed", "failed"];

    if (this.props.match.params && this.props.match.params.hasOwnProperty("status")) {
      if (!statuses.includes(this.props.match.params.status))
        this.props.history.push("/error/404")
      else
        this.setState({ verfied: this.props.match.params.status === "confirmed" })
    } else {
      this.props.history.push("/error/404")
    }
  }

  render() {
    const subdomain = window.location.hostname.split('.').reverse().pop()

    return (
      <div className="mt-4 pt-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <div className="h3 font-weight-bold">
                  <span className="text-uppercase">{subdomain}</span> | AMS Network
              </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="jumbotron bg-light">
                <div className="text-center">
                  <div className="h5 my-5">
                    Your email is {!this.state.verfied && "not"} confirmed
                  </div>
                  <Link to="/system_settings/communication/email_adresses">
                    <Button type="primary">
                      Continue
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col my-5 py-5 text-center">
            {`Powered By FAVA © ${new Date().getFullYear()} All rights reserved`}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EmailVerification)
