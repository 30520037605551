import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";
import Categories from "./Categories";
import Locations from "./Locations";
import Library from "./Library";
import Settings from "./Settings";
import ThirdPartyOwners from "./ThirdPartyOwners";

import { connect } from "react-redux"
import {
  getSideBar,
} from "../../../store/actions/index"

import "./index.css";

class Rentals extends Component {
  state = {
    activeTab: "Categories"
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : "Categories"
    });
  };

  tabChangeHandler = activeTab => {
    this.setState({ activeTab });
    this.props.history.push(`/system_settings/rentals/${snakeCase(activeTab)}`);
  };

  render() {
    const tabNames = [
      "Categories",
      "Location",
      "Third Party Owners",
      "Library",
      "Settings"
    ];
    const sideBarState = this.props.sideBar
    const tabs = tabNames.map((tab, index) => {
      if (tab === "Library" && !sideBarState.library) return null
      return <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${this.state.activeTab === tab ? "active-tab " : "inactive-tab"}`}
      >
        {tab}
      </li>
    });
    let activeTab;
    switch (this.state.activeTab) {
      case "Categories":
        activeTab = <Categories />;
        break;
      case "Location":
        activeTab = <Locations tabChangeHandler={this.tabChangeHandler} />;
        break;
      case "Third Party Owners":
        activeTab = <ThirdPartyOwners tabChangeHandler={this.tabChangeHandler} />;
        break;
      case "Library":
        activeTab = <Library tabChangeHandler={this.tabChangeHandler} />;
        break;
        case "Settings":
          activeTab = <Settings tabChangeHandler={this.tabChangeHandler} />;
          break;
      default:
        activeTab = null;
    }
    return (
      <div className="card rentals">
        <ul className="nav nav-tabs ml-0">
          {tabs}
        </ul>
        {activeTab}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSideBar: (params) => dispatch(getSideBar()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rentals))

