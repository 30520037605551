import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"

import Spinner from "../../UI/Spinner"
import axios from "../../../configAxios"
import ToggleSwitch from "../../common/toggleSwitch"
import errorHandler from "../../errorPages/UserNotAuthorized"
import onError from "../../../utils/onError"

class Setting extends Component {
  signal = axios.CancelToken.source()
  state = {
    allowUserToDeleteProjectFiles: false,
    allowUserToShareMedia: false,
    loading: true,
  }

  componentDidMount() {
    this.getOrganizationInfo()
  }

  componentWillUnmount() {
    this.signal.cancel("Requests being Cancelled")
  }

  getOrganizationInfo = () => {
    this.setState({ loading: true })
    axios
      .get(`/organizations/${localStorage.organizationId}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        if (res.data.organization) {
          this.setState({
            allowUserToDeleteProjectFiles:
              res.data.organization.allow_users_to_delete_project_files,
            allowUserToShareMedia:
              res.data.organization.allow_users_to_share_media,
            loading: false,
          })
        }
      })
  }

  allowUserToDeleteProjectFilesChangeHandler = (checked) => {
    axios
      .put(
        `/organizations/${localStorage.organizationId}`,
        {
          id: localStorage.organizationId,
          allow_users_to_delete_project_files: checked,
        },
        { cancelToken: this.signal.token }
      )
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Project Settings updated successfully")
          this.setState({
            allowUserToDeleteProjectFiles: checked,
          })
        } else {
          errorHandler(res)
        }
      })
      .catch((err) => {})
  }

  allowUserToShareMediaChangeHandler = (toggle) => {

    axios.put(`/organizations/${localStorage.organizationId}/share_media_setting`,
      {
        id: localStorage.organizationId,
        allow_users_to_share_media: toggle,
      },
      { cancelToken: this.signal.token }
    )
      .then((res) => {
        const { allow_users_to_share_media: allowMediaShare } = res.data.json.data

        toast.success("Project Settings updated successfully!")
        this.setState({ allowUserToShareMedia: allowMediaShare })
      })
      .catch(err => {
        onError(err)
      })
  }

  render() {
    const { allowUserToDeleteProjectFiles, allowUserToShareMedia, disabled, loading } = this.state

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <div className="my-3 d-flex align-items-center">
                  <ToggleSwitch
                    checked={allowUserToDeleteProjectFiles}
                    onChange={this.allowUserToDeleteProjectFilesChangeHandler}
                    disabled={disabled}
                  />
                  <label className="ml-2 my-0">
                    Allow users to delete their own projects & associated files?
                  </label>
                </div>
                <div className="my-3 d-flex align-items-center">
                  <ToggleSwitch
                    checked={allowUserToShareMedia}
                    onChange={this.allowUserToShareMediaChangeHandler}
                    disabled={disabled}
                  />
                  <label className="ml-2 my-0">
                    Allow users to share media?
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Setting)
