import React, { Component } from "react";
import _ from "lodash"

class ShowLibrary extends Component {
  renderStringElement = (label, value) => {
    if(!value) return;
    return (
      <div className="card">
        <div className="info-heading font-weight-bold">{label}</div>
        <div className="info-text">{value}</div>
      </div>
    );
  };

  renderArrayElement = (label, value) => {
    if(!(value && _.isArray(value) && !_.isEmpty(value))) return;
    return (
      <div className="card">
        <div className="info-heading font-weight-bold">{label}</div>
        <div className="info-text">
          {value.map((element, index) => <div key={index}>{element}</div>)}
        </div>
      </div>
    );
  };
  render() {
    const { asset } = this.props;
    let libraryItem = null;
    if (asset.type === "Print") {
      libraryItem = (
        <>
          {this.renderStringElement("Author(s)", asset.parameters.authors)}
          {this.renderStringElement(
            "Type of print",
            asset.parameters.type_of_print
          )}
          {this.renderStringElement("ISBN", asset.parameters.isbn_number)}
          {this.renderStringElement("Oversize", asset.parameters.oversize)}
          {this.renderStringElement("Anthology", asset.parameters.anthology)}
          {this.renderStringElement("Pages", asset.parameters.pages)}
          {this.renderStringElement("Volume", asset.parameters.volume)}
          {this.renderStringElement("Publisher", asset.parameters.publisher)}
          {this.renderStringElement("City", asset.parameters.city_published)}
          {this.renderStringElement(
            "Year Published",
            asset.parameters.year_of_publishing
          )}
          {this.renderStringElement("Edition", asset.parameters.edition)}
          {this.renderStringElement(
            "Binding type",
            asset.parameters.binding_type
          )}
          {this.renderStringElement("Series", asset.parameters.series)}
          {this.renderStringElement("Genre", asset.parameters.genre)}
          {this.renderStringElement("Tags", asset.parameters.tags)}
          {this.renderStringElement("Languages", asset.parameters.languages)}
        </>
      );
    } else if (asset.type === "Video") {
      libraryItem = (
        <>
          {this.renderStringElement("Director", asset.parameters.director)}
          {this.renderStringElement(
            "Video format",
            asset.parameters.video_format
          )}
          {this.renderStringElement("ISBN", asset.parameters.isbn)}
          {this.renderStringElement(
            "Release date",
            asset.parameters.release_date
          )}
          {this.renderStringElement("Video type", asset.parameters.video_type)}
          {this.renderStringElement(
            "Feature type",
            asset.parameters.feature_type
          )}
          {this.renderArrayElement("Genre", asset.parameters.video_genre)}
          {this.renderArrayElement("Studio", asset.parameters.studio)}
          {this.renderStringElement("Runtime", asset.parameters.running_time)}
          {this.renderStringElement(
            "Original video or disk",
            asset.parameters.original_video_or_disk_location
          )}
          {this.renderStringElement(
            "Complete Collection?",
            asset.parameters.complete_collection
          )}
          {this.renderArrayElement("Publisher", asset.parameters.publisher)}
          {this.renderStringElement("# of Films", asset.parameters.no_of_films)}
          {this.renderStringElement(
            "Aspect ratio",
            asset.parameters.aspect_ratio
          )}
          {this.renderStringElement(
            "NTSC or PAL",
            asset.parameters.ntsc_or_pal
          )}
          {this.renderStringElement("Resolution", asset.parameters.resolution)}
          {this.renderStringElement("Subtitle", asset.parameters.subtitle)}
          {this.renderStringElement("Audio type", asset.parameters.audio_type)}
          {this.renderStringElement(
            "Record label",
            asset.parameters.record_label
          )}
          {this.renderStringElement(
            "Distributor",
            asset.parameters.distributor
          )}
          {this.renderStringElement("Starring", asset.parameters.starring)}
          {this.renderStringElement("Edition", asset.parameters.edition)}
          {this.renderArrayElement("Tag", asset.parameters.tag)}
          {this.renderArrayElement(
            "Animation technique",
            asset.parameters.animation_technique
          )}
          {this.renderStringElement("Platform", asset.parameters.platform)}
          {this.renderStringElement("Languages", asset.parameters.languages)}
        </>
      );
    } else if (asset.type === "Audio" || asset.type === "Film") {
      libraryItem = (
        <>
          {this.renderStringElement("Subtitle", asset.parameters.subtitle)}
          {this.renderStringElement(
            "Country of origin",
            asset.parameters.country_origin
          )}
          {this.renderStringElement("City", asset.parameters.city_published)}
          {this.renderStringElement(
            "Date released",
            asset.parameters.date_released
          )}
          {this.renderStringElement("Date added", asset.parameters.date_added)}
          {this.renderStringElement("ISBN", asset.parameters.isbn)}
          {this.renderArrayElement(
            "Territory of origin",
            asset.parameters.territory_origin
          )}
          {this.renderArrayElement(
            "Original language",
            asset.parameters.original_language
          )}
          {this.renderArrayElement("Flags", asset.parameters.library_flags)}
          {this.renderStringElement("Tags", asset.parameters.tags)}
          {this.renderArrayElement(
            "First nation",
            asset.parameters.first_nation
          )}
          {this.renderStringElement("CPU", asset.parameters.cpu)}
          {this.renderStringElement("Creator", asset.parameters.creator)}
          {this.renderStringElement(
            "Bookmark type",
            asset.parameters.bookmark_type
          )}
          {this.renderStringElement("Bookmark", asset.parameters.bookmark)}
          {this.renderStringElement(
            "Runtime time",
            asset.parameters.running_time
          )}
          {this.renderStringElement("Publisher", asset.parameters.publisher)}
          {this.renderStringElement(
            "Theatrical release date",
            asset.parameters.theatrical_release_date
          )}
          {this.renderStringElement("Genre", asset.parameters.genre)}
          {this.renderStringElement("Pages", asset.parameters.pages)}
          {this.renderStringElement("Audio type", asset.parameters.audio_type)}
          {this.renderStringElement(
            "Record label",
            asset.parameters.record_label
          )}
          {this.renderStringElement(
            "Distributor",
            asset.parameters.distributor
          )}
          {this.renderStringElement("Starring", asset.parameters.starring)}
          {this.renderStringElement("Platform", asset.parameters.platform)}
        </>
      );
    }
    return (
      <div>
        {this.renderStringElement(
          "Call Number",
          asset.parameters.call_number
        )}
        {this.renderStringElement("Title", asset.name)}
        {this.renderStringElement(
          "Bulk Count",
          asset.bulk_asset.bulk_count_total
        )}
        {this.renderStringElement("Condition", asset.parameters.condition)}
        {this.renderStringElement("Barcode", asset.barcode)}
        {libraryItem}
        {this.renderStringElement("Rating", asset.parameters.rating)}
        {this.renderStringElement("My rating", asset.parameters.my_rating)}
        {this.renderStringElement(
          "Lending due date",
          asset.parameters.lending_due_date
        )}
        {this.renderStringElement("QAS Member?", asset.parameters.qas_member)}
        {this.renderStringElement("Reference?", asset.parameters.reference)}
        {this.renderStringElement(
          "Mature Content?",
          asset.parameters.mature_content
        )}
      </div>
    );
  }
}

export default ShowLibrary;
