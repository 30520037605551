import React from "react"

const DetailTable = ({ children, descriptionTitle }) => {
  return (
    <table className="table table-bordered">
      <thead className="thead-light">
        <tr>
          <th className="text-center">Code</th>
          <th className="text-center">
            {descriptionTitle}
          </th>
          <th className="text-center">Price</th>
        </tr>
      </thead>
      {children}
    </table>
  )
}

export default DetailTable
