import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import Select from "react-select"
import { toast } from "react-toastify"
import Button from "../UI/button/Button"
import Modal from "../UI/Modal"

const TagModal = ({ label, showModal, setShowModal, clicked, modalTitle, labelCategories }) => {
  const [tagName, setTagName] = useState('')
  const [tagDef, setTagDef] = useState('')
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)

  useEffect(() => {
    if (labelCategories) {
      setOptions(labelCategories.map(category => {
        return { value: category.id, label: category.name }
      }))
    }
  }, [labelCategories])

  useEffect(() => {
    if (label) {
      setTagName(label.name)
      setTagDef(label.definition)
    }
  }, [label])

  useEffect(() => {
    if (!isEmpty(options) && label) {
      const initialOption = options.find(o => o.value === label.label_category_id) ?? null
      setSelectedOption(initialOption)
    }
  }, [label, options])

  const doClicked = () => {
    if (!tagName) return toast.error("Tag Name Required")

    clicked(tagName, selectedOption?.value, tagDef)
    setShowModal(false)
    setSelectedOption(null)
    setTagName('')
    setTagDef('')
  }

  return (
    <Modal
      show={showModal}
      title={`${modalTitle} Label`}
      hide={() => setShowModal(false)}
    >
      <div className="tag-form mt-3">
        <label htmlFor="label-name">
          <span className="text-capitalize">Label Name</span>
        </label>
        <div className="tag-form-input position-relative">
          <input
            id="label-name"
            name="label-name"
            placeholder="Label Name"
            className="form-control"
            value={tagName}
            onChange={(event) => setTagName(event.target.value)}
          />
        </div>
        <label htmlFor="label-category" className="pt-3">
          <span className="text-capitalize">Label Category</span>
        </label>
        <div className="tag-form-input position-relative">
          <Select
            name="label-category"
            options={options}
            value={selectedOption}
            onChange={(selected) => setSelectedOption(selected)}
          />
        </div>
        <label htmlFor="label-definition" className="pt-3">
          <span className="text-capitalize">Label Definition</span>
        </label>
        <div className="tag-form-input position-relative">
          <textarea
            id="label-definition"
            name="label-definition"
            placeholder="Enter a definition (optional)"
            rows="15"
            className="form-control"
            value={tagDef}
            onChange={(event) => setTagDef(event.target.value)}
          />
        </div>
        <div className="text-center mt-3">
          <Button type="secondary" className="mr-2" clicked={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="primary" clicked={() => doClicked()}>
            {modalTitle}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default TagModal
