import React, { Component } from 'react'
import { toast } from "react-toastify";
import { title } from "change-case";
import { last } from "lodash"

import axios from '../../../../configAxios'
import Button from '../../../UI/button/Button'
import Input from '../../../UI/input/Input'
import ModalSpinner from '../../../UI/ModalSpinner';
import FormButton from '../../../UI/button/FormButton';

class MailingListForm extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: this.props.edit,
    disableSubmit: false,
    tags: [],
    formFields: {
      name: {
        elementType: "input",
        elementConfig: {
          name: "name",
          type: "text",
          required: true
        },
        label: "Name",
        value: ""
      },
      tags: {
        elementType: "select",
        elementConfig: {
          name: "tags",
          placeholder: "Enter Labels",
          components: {
            IndicatorSeparator: () => {
              return null;
            },
            DropdownIndicator: () => {
              return null;
            }
          },
          isSearchable: true,
          isClearable: true,
          isLoading: true,
          isMulti: true,
          closeMenuOnSelect: false
        },
        selectedOption: [],
        label: "Labels"
      }
    }
  }

  componentDidMount() {
    axios.get(`/mailing_lists/formdata`, { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          formFields: {
            ...this.state.formFields,
            tags: {
              ...this.state.formFields.tags,
              elementConfig: {
                ...this.state.formFields.tags.elementConfig,
                options: res.data.json.tags.map(tag => ({ value: tag, label: last(tag.split('user:')) })),
                isLoading: false
              }
            }
          }
        })
      })
    if (this.props.edit) {
      axios.get(`/mailing_lists/${this.props.mailingListId}`, { cancelToken: this.signal.token })
        .then(res => {
          this.setState({
            ...this.state,
            formFields: {
              ...this.state.formFields,
              name: {
                ...this.state.formFields.name,
                value: res.data.json.data.name
              },
              tags: {
                ...this.state.formFields.tags,
                selectedOption: res.data.json.data.tags.map(tag => ({ value: tag, label: last(tag.split('user:')) }))
              }
            },
            loading: false
          })
        })
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key) => {
    if (key === "tags") {
      this.setState({
        ...this.state,
        formFields: {
          ...this.state.formFields,
          tags: {
            ...this.state.formFields.tags,
            selectedOption: event
          }
        }
      })
    } else {
      let formFields = this.state.formFields
      formFields[key].value = event.target.value

      this.setState({ ...this.state, formFields });
    }
  }

  submitHandler = e => {
    e.preventDefault()

    if (this.state.formFields.tags.selectedOption.length === 0) {
      toast.error("You must select tags for this Mailing List")
      return
    }

    this.setState({ ...this.state, disableSubmit: true })
    let data = {
      name: this.state.formFields.name.value,
      tags: this.state.formFields.tags.selectedOption.map(tag => tag.value).join()
    }
    if (this.props.edit) {
      axios.put(`/mailing_lists/${this.props.mailingListId}`, data)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Mailing List Updated Successfully")
            this.props.updateData(res.data.json.data)
            this.props.closeModal()
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    } else {
      axios.post('/mailing_lists', data)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Mailing List Created Successfully")
            this.props.updateData(res.data.json.data)
            this.props.closeModal()
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <ModalSpinner />
      )
    }
    return (
      <div className="mt-3 email-campaign">
        <form onSubmit={this.submitHandler}>
          <Input
            {...this.state.formFields.name}
            changed={event => this.inputChangedHandler(event, "name")}
          />
          <Input
            {...this.state.formFields.tags}
            changed={event => this.inputChangedHandler(event, "tags")}
          />
          <div className="text-center">
            <Button
              type="secondary"
              clicked={this.props.closeModal}
              className="mr-2"
            >
              Cancel
            </Button>
            <FormButton disabled={this.state.disableSubmit}>
              {this.props.edit ? "Update" : "Create"}
            </FormButton>
          </div>
        </form>
      </div>
    )
  }
}

export default MailingListForm;
