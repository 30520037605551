import React from 'react'
import Toolbar from "./Toolbar";

const ToolbarContainer = ({ dateChangeHandler, date, view }) => props => {
  return (
    <Toolbar event={props} date={date} dateChangeHandler={dateChangeHandler} view={view} />
  )
}

export default ToolbarContainer
