import "./index.css"
import React from "react";
import dateFns from "date-fns";
import { compact } from "lodash";

const BookingsTable = ({ bookings, organization }) => {

  const bookingTableBody = bookings?.map(({ booking, price, quantity, tax_amount: taxAmount }, index) => {
      const { object: assetObj } = booking.asset
      const startDate = dateFns.format(
        new Date(booking.details.start_time),
        "ddd MMM DD"
      );
      const startTime = dateFns.format(
        new Date(booking.details.start_time),
        "hh:mm A"
      );
      const endDate = dateFns.format(
        new Date(booking.details.end_time),
        "ddd MMM DD"
      );
      const endTime = dateFns.format(
        new Date(booking.details.end_time),
        "hh:mm A"
      );

      const dateRange =
        booking.asset.type === "Facility"
          ? `${startDate}`
          : `${startDate} - ${endDate}`

      const startEndTime = assetObj.is_bookable_in_hours
        ? `: ${startTime} - ${endTime}`
        : ""

      let accountingCode
      if (assetObj.accounting_code && assetObj.accounting_code.code) {
        accountingCode = assetObj.accounting_code.code
      } else if (
        assetObj.registration_code &&
        assetObj.registration_code.code
      ) {
        accountingCode = assetObj.registration_code.code
      }

      const AppliedTaxes = () => {
        const displayGst = organization.is_gst_used && assetObj.is_gst_used ? 'GST' : null
        const displayPst = organization.is_pst_used && assetObj.is_pst_used ? 'PST' : null
        const displayHst = organization.is_hst_used && assetObj.is_hst_used ? 'HST' : null

        if (!(displayGst || displayPst || displayHst)) return 'N/A'

        return compact([displayGst, displayPst, displayHst]).join(', ')
      }

      return (
        <tr key={index}>
          <td className="column-10 text-center">{accountingCode}</td>
          <td className="colum-30 text-center">
            <a className="btn-link" href={`/assets/${booking.asset.id}`} target="_blank" rel="noopener noreferrer">
              {booking.asset.name}
            </a>
          </td>
          <td className="text-center">{`${dateRange}${startEndTime} (${
            booking.loan_period
          } x ${booking.price_to_charge} per ${assetObj.duration})`}</td>
          <td className="text-center">{quantity}</td>
          <td className="text-center text-danger"><AppliedTaxes /></td>
          <td className="text-center">{taxAmount}</td>
          <td className="text-center">{price}</td>
        </tr>
      );
    });

  return (
    <table className="table table-bordered">
      <thead className="thead-light">
        <tr>
          <th className="text-center">Code</th>
          <th className="text-center">Asset Name</th>
          <th className="text-center">Rental</th>
          <th className="text-center">Qty</th>
          <th className="text-center">Tax</th>
          <th className="text-center">Tax Amt</th>
          <th className="text-center">Price</th>
        </tr>
      </thead>
      <tbody>{bookingTableBody}</tbody>
    </table>
  );
};

export default BookingsTable;
