import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { title } from "change-case"
import dateFns from "date-fns";
import Input from "../UI/input/Input";
import DatePicker from "../UI/datePicker/index";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import axios from "../../configAxios";
import { toast } from "react-toastify";
import queryString from "query-string";
import "./NewOrEdit.css";
import Spinner from "../UI/Spinner";

class AddOrEditVideo extends Component {
  signal = axios.CancelToken.source();
  state = {
    call: null,
    title: null,
    bulkCount: null,
    isbn: null,
    barcode: null,
    edition: null,
    description: null,
    rating: null,
    myRating: null,
    languages: null,
    director: null,
    runtime: null,
    originalVideo: null,
    films: null,
    aspectRatio: null,
    audioType: null,
    recordLabel: null,
    distributor: null,
    starring: null,
    platform: null,
    contributersName: {
      elementType: "input",
      elementConfig: {
        name: "contributersName",
        type: "text",
        placeholder: "Contributor Name"
      }
    },
    condition: {
      elementType: "select",
      elementConfig: {
        name: "condition",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    videoFormat: {
      elementType: "select",
      elementConfig: {
        name: "videoFormat",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Video format"
    },
    videoType: {
      elementType: "select",
      elementConfig: {
        name: "videoType",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Video type"
    },
    featureType: {
      elementType: "select",
      elementConfig: {
        name: "featureType",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Feature type"
    },
    genre: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "genre",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Genre"
    },
    studio: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "studio",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Studio"
    },
    completeCollection: {
      elementType: "select",
      elementConfig: {
        name: "completeCollection",
        options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Complete Collection?"
    },
    ntsc: {
      elementType: "select",
      elementConfig: {
        name: "ntsc",
        options: [
          { label: "NTSC", value: "NTSC" },
          { label: "PAL", value: "PAL" }
        ],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "NTSC or PAL"
    },
    resolution: {
      elementType: "select",
      elementConfig: {
        name: "resolution",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Resolution"
    },
    subtitle: {
      elementType: "select",
      elementConfig: {
        name: "subtitle",
        options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Subtitle"
    },
    publisher: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "publisher",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Publisher"
    },
    tag: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "tag",
        options: [],
        isMulti: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Tag"
    },
    animationTechnique: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "animationTechnique",
        options: [],
        isMulti: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Animation Technique"
    },
    QASMember: {
      elementType: "select",
      elementConfig: {
        name: "QASMember",
        options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    reference: {
      elementType: "select",
      elementConfig: {
        name: "reference",
        options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    matureContent: {
      elementType: "select",
      elementConfig: {
        name: "matureContent",
        options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    contributersType: {
      elementType: "select",
      elementConfig: {
        name: "contributersType",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      label: "Contributers"
    },
    releaseDate: null,
    lendingDueDate: null,
    artwork: null,
    contributers: [{ contributerType: null, name: "", id: "" }],
    loading: true,
    disabled: false
  };

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    let formatType = null;
    if (this.props.location.search.includes("format_type")) {
      formatType = { label: values.format_type, value: values.format_type };
    }
    const res = await axios.get("/assets/formdata", { cancelToken: this.signal.token });
        const videoGenre = res.data.json.video_genres;
        const studios = res.data.json.studios;
        const resolutions = res.data.json.resolutions;
        const publishers = res.data.json.publishers;
        const video_tags = res.data.json.video_tags;
        const animation_techniques = res.data.json.animation_techniques;
        const contributer_types = res.data.json.contributer_types;
        const conditions = res.data.json.conditions;
        const feature_types = res.data.json.feature_types;
        const video_types = res.data.json.video_types;
        const video_formats = res.data.json.video_formats;

        this.setState({
          genre: {
            ...this.state.genre,
            elementConfig: {
              ...this.state.genre.elementConfig,
              options: videoGenre.map(genre => {
                return { label: genre, value: genre };
              })
            }
          },
          studio: {
            ...this.state.studio,
            elementConfig: {
              ...this.state.studio.elementConfig,
              options: studios.map(studio => {
                return { label: studio, value: studio };
              })
            }
          },
          resolution: {
            ...this.state.resolution,
            elementConfig: {
              ...this.state.resolution.elementConfig,
              options: resolutions.map(resolution => {
                return { label: resolution, value: resolution };
              })
            }
          },
          publisher: {
            ...this.state.publisher,
            elementConfig: {
              ...this.state.publisher.elementConfig,
              options: publishers.map(publisher => {
                return { label: publisher, value: publisher };
              })
            }
          },
          tag: {
            ...this.state.tag,
            elementConfig: {
              ...this.state.tag.elementConfig,
              options: video_tags.map(tag => {
                return { label: tag, value: tag };
              })
            }
          },
          animationTechnique: {
            ...this.state.animationTechnique,
            elementConfig: {
              ...this.state.animationTechnique.elementConfig,
              options: animation_techniques.map(animationTechnique => {
                return { label: animationTechnique, value: animationTechnique };
              })
            }
          },
          contributersType: {
            ...this.state.contributersType,
            elementConfig: {
              ...this.state.contributersType.elementConfig,
              options: contributer_types.map(type => {
                return { label: type, value: type };
              })
            }
          },
          condition: {
            ...this.state.condition,
            elementConfig: {
              ...this.state.condition.elementConfig,
              options: conditions.map(condition => {
                return { label: condition, value: condition };
              })
            }
          },
          featureType: {
            ...this.state.featureType,
            elementConfig: {
              ...this.state.featureType.elementConfig,
              options: feature_types.map(type => {
                return { label: type, value: type };
              })
            }
          },
          videoType: {
            ...this.state.videoType,
            elementConfig: {
              ...this.state.videoType.elementConfig,
              options: video_types.map(type => {
                return { label: type, value: type };
              })
            }
          },
          videoFormat: {
            ...this.state.videoFormat,
            elementConfig: {
              ...this.state.videoFormat.elementConfig,
              options: video_formats.map(format => {
                return { label: format, value: format };
              }),
              defaultValue: formatType !== null ? formatType : "Select Option"
            },
            selectedOption: formatType
          },
          loading: this.props.location.pathname.includes("edit") ? true : false
        });

    if (this.props.location.pathname.includes("edit")) {
      const id = this.props.match.params.id;
      const res = await axios.get(`/assets/${id}`, { cancelToken: this.signal.token });
          const asset = res.data.asset_detail;
          let contributers = [{ contributerType: null, name: "", id: "" }];
          if (asset.asset_contributers.length) {
            contributers = asset.asset_contributers.map(contributer => {
              return {
                contributerType: {
                  label: contributer.contributer_type,
                  value: contributer.contributer_type
                },
                name: contributer.name,
                id: contributer.id
              };
            });
          }
          this.setState({
            call: asset.parameters.call_number,
            title: asset.name,
            director: asset.parameters.director,
            videoFormat: {
              ...this.state.videoFormat,
              selectedOption: asset.parameters.video_format && {
                label: asset.parameters.video_format,
                value: asset.parameters.video_format
              }
            },
            bulkCount: asset.bulk_asset.bulk_count_total,
            releaseDate: asset.parameters.release_date ? 
              new Date(asset.parameters.release_date) : asset.parameters.releaseDate,
            isbn: asset.parameters.isbn,
            videoType: {
              ...this.state.videoType,
              selectedOption: asset.parameters.video_type && {
                label: asset.parameters.video_type,
                value: asset.parameters.video_type
              }
            },
            featureType: {
              ...this.state.featureType,
              selectedOption: asset.parameters.feature_type && {
                label: asset.parameters.feature_type,
                value: asset.parameters.feature_type
              }
            },
            genre: {
              ...this.state.genre,
              selectedOption:
                asset.parameters.video_genre &&
                asset.parameters.video_genre.map(genre => {
                  return { label: genre, value: genre };
                })
            },
            studio: {
              ...this.state.studio,
              selectedOption:
                asset.parameters.studio &&
                asset.parameters.studio.map(studio => {
                  return { label: studio, value: studio };
                })
            },
            runtime: asset.parameters.running_time,
            originalVideo: asset.parameters.original_video_or_disk_location,
            condition: {
              ...this.state.condition,
              selectedOption: {
                label: asset.parameters.condition,
                value: asset.parameters.condition
              }
            },
            completeCollection: {
              ...this.state.completeCollection,
              selectedOption: asset.parameters.complete_collection && {
                label: asset.parameters.complete_collection,
                value: asset.parameters.complete_collection
              }
            },
            films: asset.parameters.no_of_films,
            aspectRatio: asset.parameters.aspect_ratio,
            ntsc: {
              ...this.state.ntsc,
              selectedOption: asset.parameters.ntsc_or_pal && {
                label: asset.parameters.ntsc_or_pal,
                value: asset.parameters.ntsc_or_pal
              }
            },
            resolution: {
              ...this.state.resolution,
              selectedOption: asset.parameters.resolution && {
                label: asset.parameters.resolution,
                value: asset.parameters.resolution
              }
            },
            subtitle: {
              ...this.state.subtitle,
              selectedOption: asset.parameters.subtitle && {
                label: asset.parameters.subtitle,
                value: asset.parameters.subtitle
              }
            },
            languages: asset.parameters.languages,
            audioType: asset.parameters.audio_type,
            recordLabel: asset.parameters.record_label,
            publisher: {
              ...this.state.publisher,
              selectedOption:
                asset.parameters.publisher &&
                asset.parameters.publisher.map(publisher => {
                  return { label: publisher, value: publisher };
                })
            },
            distributor: asset.parameters.distributor,
            starring: asset.parameters.starring,
            edition: asset.parameters.edition,
            tag: {
              ...this.state.tag,
              selectedOption:
                asset.parameters.tag &&
                asset.parameters.tag.map(tag => {
                  return { label: tag, value: tag };
                })
            },
            rating: asset.parameters.rating,
            myRating: asset.parameters.my_rating,
            animationTechnique: {
              ...this.state.animationTechnique,
              selectedOption:
                asset.parameters.animation_technique &&
                asset.parameters.animation_technique.map(animationTechnique => {
                  return {
                    label: animationTechnique,
                    value: animationTechnique
                  };
                })
            },
            description: asset.description,
            platform: asset.parameters.platform,
            lendingDueDate: asset.parameters.lendingDueDate ? 
              new Date(asset.parameters.lending_due_date) : asset.parameters.lendingDueDate,
            barcode: asset.barcode,
            QASMember: {
              ...this.state.QASMember,
              selectedOption: asset.parameters.qas_member && {
                label: asset.parameters.qas_member,
                value: asset.parameters.qas_member
              }
            },
            reference: {
              ...this.state.reference,
              selectedOption: {
                label: asset.parameters.reference,
                value: asset.parameters.reference
              }
            },
            matureContent: {
              ...this.state.matureContent,
              selectedOption: {
                label: asset.parameters.mature_content,
                value: asset.parameters.mature_content
              }
            },
            contributers,
            loading: false
          });
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  addContributerFields = () => {
    this.setState(prevState => ({
      contributers: [
        ...prevState.contributers,
        { contributerType: null, name: "" }
      ]
    }));
  };

  deleteContributerFields = (index, id) => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      const { contributers } = { ...this.state };
      if (id) {
        axios
          .delete("/asset_contributers", {
            params: {
              id: id
            }
          })
          .then(res => {
            if (res.data.json.success) {
              toast.success("Contributer deleted.");
            } else {
              if (res.data.json.hasOwnProperty("errors")) {
                Object.keys(res.data.json.errors).forEach(error => {
                  toast.error(title(error) + " " + res.data.json.errors[error]);
                });
              }
              if (res.data.json.hasOwnProperty("error")) {
                toast.error(res.data.json.error);
              }
            }
          });
      }
      contributers.splice(index, 1);
      this.setState({ contributers });
    }
  };

  changeHandler = (event, i, key) => {
    let inputValue = {};
    let { contributers } = this.state;
    if (key === "contributersType") {
      inputValue["selectedOption"] = event;
      contributers[i].contributerType = event;
    } else {
      inputValue["value"] = event.target.value;
      contributers[i].name = event.target.value;
    }
    const updatedControls = {
      ...this.state[key],
      ...inputValue
    };

    this.setState({ [key]: updatedControls, contributers });
  };

  inputChangeHandler = (event, key, type) => {
    if (type === "file") {
      this.setState({ artwork: event.target.files[0] });
    } else if (type === "select") {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event
        }
      });
    } else if (type === "creatableSelect") {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event
        }
      });
    }
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let formData = new FormData();
    const {
      contributers,
      genre,
      studio,
      publisher,
      tag,
      animationTechnique
    } = this.state;
    const lendingDueDate = this.state.lendingDueDate
      ? dateFns.format(new Date(this.state.lendingDueDate), "YYYY-MM-DD")
      : "";

    const releaseDate = this.state.releaseDate
      ? dateFns.format(new Date(this.state.releaseDate), "YYYY-MM-DD")
      : "";

    const asset_contributers_attributes = contributers
      .map(contributer => {
        if (contributer.contributerType !== null && contributer.name !== "") {
          const contributerData = {
            contributer_type:
              (contributer.contributerType !== null ||
                contributer.contributerType !== "") &&
                contributer.name !== ""
                ? contributer.contributerType.label
                : "",
            name:
              contributer.contributerType === null ||
                contributer.contributerType === ""
                ? ""
                : contributer.name
          };
          if (contributer.id !== "") {
            contributerData["id"] = contributer.id;
          }
          return JSON.stringify(contributerData);
        } else {
          return null;
        }
      })
      .filter(item => item !== null);

    const selectedGenres =
      genre.selectedOption && genre.selectedOption.map(genre => genre.label);
    const selectedStudios =
      studio.selectedOption &&
      studio.selectedOption.map(studio => studio.label);
    const selectedPublishers =
      publisher.selectedOption &&
      publisher.selectedOption.map(publisher => publisher.label);
    const selectedTags =
      tag.selectedOption && tag.selectedOption.map(tag => tag.label);
    const selectedAnimationTechniques =
      animationTechnique.selectedOption &&
      animationTechnique.selectedOption.map(
        animationTechnique => animationTechnique.label
      );
    formData.append(["status"], 0);
    formData.append(["name"], this.state.title);
    this.state.call && formData.append(["call_number"], this.state.call);
    this.state.director && formData.append(["director"], this.state.director);
    this.state.videoFormat.selectedOption &&
      formData.append(
        ["video_format"],
        this.state.videoFormat.selectedOption.label
      );
    formData.append(
      ["bulk_count_total"],
      this.state.bulkCount ? parseInt(this.state.bulkCount) : ""
    );
    formData.append(["release_date"], releaseDate);
    this.state.isbn && formData.append(["isbn"], this.state.isbn);
    this.state.videoType.selectedOption &&
      formData.append(
        ["video_type"],
        this.state.videoType.selectedOption.label
      );
    this.state.featureType.selectedOption &&
      formData.append(
        ["feature_type"],
        this.state.featureType.selectedOption.label
      );
    selectedGenres &&
      formData.append(["video_genre"], JSON.stringify(selectedGenres));
    selectedStudios &&
      formData.append(["studio"], JSON.stringify(selectedStudios));
    this.state.runtime && formData.append(["running_time"], this.state.runtime);
    this.state.originalVideo &&
      formData.append(
        ["original_video_or_disk_location"],
        this.state.originalVideo
      );
    this.state.condition.selectedOption &&
      formData.append(["condition"], this.state.condition.selectedOption.label);
    this.state.completeCollection.selectedOption &&
      formData.append(
        ["complete_collection"],
        this.state.completeCollection.selectedOption.label
      );
    this.state.films && formData.append(["no_of_films"], this.state.films);
    this.state.aspectRatio &&
      formData.append(["aspect_ratio"], this.state.aspectRatio);
    this.state.ntsc.selectedOption &&
      formData.append(["ntsc_or_pal"], this.state.ntsc.selectedOption.label);
    this.state.resolution.selectedOption &&
      formData.append(
        ["resolution"],
        this.state.resolution.selectedOption.label
      );
    this.state.artwork && formData.append(["photo"], this.state.artwork);
    this.state.subtitle.selectedOption &&
      formData.append(["subtitle"], this.state.subtitle.selectedOption.label);
    this.state.languages &&
      formData.append(["languages"], this.state.languages);
    this.state.audioType &&
      formData.append(["audio_type"], this.state.audioType);
    this.state.recordLabel &&
      formData.append(["record_label"], this.state.recordLabel);
    selectedPublishers &&
      formData.append(["publisher"], JSON.stringify(selectedPublishers));
    this.state.distributor &&
      formData.append(["distributor"], this.state.distributor);
    this.state.starring && formData.append(["starring"], this.state.starring);
    this.state.edition && formData.append(["edition"], this.state.edition);
    selectedTags && formData.append(["tag"], JSON.stringify(selectedTags));
    this.state.rating && formData.append(["rating"], this.state.rating);
    this.state.myRating && formData.append(["my_rating"], this.state.myRating);
    selectedAnimationTechniques &&
      formData.append(
        ["animation_technique"],
        JSON.stringify(selectedAnimationTechniques)
      );
    this.state.description &&
      formData.append(["description"], this.state.description);
    this.state.platform && formData.append(["platform"], this.state.platform);
    formData.append(["lending_due_date"], lendingDueDate);
    this.state.barcode && formData.append(["barcode"], this.state.barcode);
    this.state.QASMember.selectedOption &&
      formData.append(
        ["qas_member"],
        this.state.QASMember.selectedOption.label
      );
    this.state.reference.selectedOption &&
      formData.append(["reference"], this.state.reference.selectedOption.label);
    this.state.matureContent.selectedOption &&
      formData.append(
        ["mature_content"],

        this.state.matureContent.selectedOption.label
      );
    asset_contributers_attributes.length &&
      formData.append(
        ["asset_contributers_attributes"],
        `[${asset_contributers_attributes}]`
      );
    formData.append(["type_used"], "Video");

    if (this.props.location.pathname.includes("edit")) {
      axios.put(`/assets/${this.props.match.params.id}`, formData).then(res => {
        if (res.data.json.success) {
          toast.success("Video successfully updated");
          this.props.history.push(`/assets/${this.props.match.params.id}`);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    } else {
      axios.post("/assets", formData).then(res => {
        if (res.data.json.success) {
          toast.success("Video successfully created");
          this.props.history.push(`/libraries/all_media`);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    }
  };

  renderTextbox = (label, name, placeholder) => {
    return (
      <>
        <p
          className={
            name === "title" ? "new-type-label required text-nowrap" : "new-type-label text-nowrap"
          }
        >{label}
        </p>
        <Input
          elementType={"input"}
          elementConfig={{
            required: name === "title" ? true : false,
            type:
              name === "bulkCount" || name === "runtime"
                ? "number"
                : "text",
            placeholder: placeholder
          }}
          value={this.state[name]}
          changed={event => this.setState({ [name]: event.target.value })}
        />
      </>
    );
  };

  render() {
    return (
      <div className="card mt-4 mb-4 new-library-type">
        {this.state.loading ? (
          <Spinner />
        ) : (
            <form onSubmit={this.submitHandler}>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Call #", "call")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Title", "title")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Director", "director")}
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.videoFormat}
                    changed={event =>
                      this.inputChangeHandler(event, "videoFormat", "select")
                    }
                  />
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("# of Copies (if more than one)", "bulkCount")}
                </div>
                <div className="col-md w-98">
                  <p className="mb-2">Release date</p>
                  <DatePicker
                    selected={this.state.releaseDate}
                    changed={date => this.setState({ releaseDate: date })}
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("ISBN", "isbn")}
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.videoType}
                    changed={event =>
                      this.inputChangeHandler(event, "videoType", "select")
                    }
                  />
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <Input
                    {...this.state.featureType}
                    changed={event =>
                      this.inputChangeHandler(event, "featureType", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.genre}
                    changed={event =>
                      this.inputChangeHandler(event, "genre", "creatableSelect")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.studio}
                    changed={event =>
                      this.inputChangeHandler(event, "studio", "creatableSelect")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Runtime(minutes)", "runtime", "MM")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Original video or disk location", "originalVideo")}
                </div>
                <div className="col-md input-fields">
                  <label className="required">Condition </label>
                  <Input
                    {...this.state.condition}
                    changed={event =>
                      this.inputChangeHandler(event, "condition", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.completeCollection}
                    changed={event =>
                      this.inputChangeHandler(
                        event,
                        "completeCollection",
                        "select"
                      )
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("# of Films", "films")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Aspect ratio", "aspectRatio")}
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.ntsc}
                    changed={event =>
                      this.inputChangeHandler(event, "ntsc", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.resolution}
                    changed={event =>
                      this.inputChangeHandler(event, "resolution", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields text-nowrap">
                  <label>Upload a Thumbnail/Artwork</label>
                  <Input
                    elementType={"file"}
                    value={this.state.artwork}
                    changed={event =>
                      this.inputChangeHandler(event, "artwork", "file")
                    }
                  />
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <Input
                    {...this.state.subtitle}
                    changed={event =>
                      this.inputChangeHandler(event, "subtitle", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Languages", "languages")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Audio type", "audioType")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Record label", "recordLabel")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <Input
                    {...this.state.publisher}
                    changed={event =>
                      this.inputChangeHandler(
                        event,
                        "publisher",
                        "creatableSelect"
                      )
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Distributor", "distributor")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Starring", "starring")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Edition", "edition")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <Input
                    {...this.state.tag}
                    changed={event =>
                      this.inputChangeHandler(event, "tag", "creatableSelect")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Rating", "rating")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("My rating", "myRating")}
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.animationTechnique}
                    changed={event =>
                      this.inputChangeHandler(
                        event,
                        "animationTechnique",
                        "creatableSelect"
                      )
                    }
                  />
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Description/Notes", "description")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Platform", "platform")}
                </div>
                <div className="col-md w-98">
                  <p className="mb-2">Lending due date
                  </p>
                  <DatePicker
                    selected={this.state.lendingDueDate}
                    changed={date =>
                      this.setState({
                        lendingDueDate: date
                      })
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Barcode", "barcode")}
                </div>
              </div>
              <div className="row m-0 mt-4">
                <div className="col-md-3 input-fields">
                  <label>QAS Member? </label>
                  <Input
                    {...this.state.QASMember}
                    changed={event =>
                      this.inputChangeHandler(event, "QASMember", "select")
                    }
                  />
                </div>
                <div className="col-md-3 input-fields">
                  <label className="required">Reference? </label>
                  <Input
                    {...this.state.reference}
                    changed={event =>
                      this.inputChangeHandler(event, "reference", "select")
                    }
                  />
                </div>
                <div className="col-md-3 input-fields">
                  <label className="required">Mature Content? </label>
                  <Input
                    {...this.state.matureContent}
                    changed={event =>
                      this.inputChangeHandler(event, "matureContent", "select")
                    }
                  />
                </div>
              </div>
              <div className="contributers  mt-4" id="contributer">
                {this.state.contributers.map((val, index) => {
                  return (
                    <li className="row m-0" key={index}>
                      <div className="col-md-3 input-fields">
                        <Input
                          {...this.state.contributersType}
                          selectedOption={val.contributerType || null}
                          changed={event =>
                            this.changeHandler(event, index, "contributersType")
                          }
                        />
                      </div>
                      <div className="col-md-3 input-fields contributer-name">
                        <Input
                          {...this.state.contributersName}
                          value={val.name || ""}
                          changed={event =>
                            this.changeHandler(event, index, "contributersName")
                          }
                        />
                      </div>
                      <input type="hidden" name="contributerId" value={val.id} />
                      <i
                        className="fas fa-trash-alt library-delete"
                        onClick={() =>
                          this.deleteContributerFields(index, val.id)
                        }
                      />
                    </li>
                  );
                })}
              </div>
              <div className="row m-0 mt-4 ml-4 mb-3 add-button">
                <Button type="success" clicked={this.addContributerFields}>
                  Add
              </Button>
              </div>
              <div className="row m-0 mt-0 mb-5 justify-content-center">
                <Link to={`/libraries/${this.props.type}?type=${this.props.type}`}>
                  <Button type="secondary" className="mr-3">
                    Cancel
                </Button>
                </Link>
                <FormButton className="submit" disabled={this.state.disabled}>
                  {this.props.location.pathname.includes("edit")
                    ? "Update"
                    : "Submit"}
                </FormButton>
              </div>
            </form>
          )}
      </div>
    );
  }
}

export default withRouter(AddOrEditVideo);
