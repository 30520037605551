import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { title, snakeCase } from "change-case";
import axios from "../../../configAxios";
import DatePicker from "../../UI/datePicker";
import EquipmentActivityDetail from "./EquipmentActivityDetail"
import FacilityActivityDetail from "./FacilityActivityDetail"
import translateModuleName from "../../../utils/translateModuleName"
import "./index.css";

const active = {
  color: "#337ab7"
};

const inActive = {
  color: "#666666"
};
class BookingActivity extends Component {
  signal = axios.CancelToken.source();
  state = {
    activeTab: "EquipmentItem",
    date: new Date(),
    key: "EquipmentItem"
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
    this.signal.cancel("Request is being Cancelled");
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.innertab !== "undefined"
          ? this.props.match.params.innertab === "equipment_item" ? "EquipmentItem" : title(this.props.match.params.innertab)
          : "EquipmentItem"
    });
  };

  toggleTab = activeTab => {
    this.setState(
      {
        activeTab,
        date: new Date(),
        key: activeTab
      }
    );
    this.props.history.push(`/assets/booking_activity/${snakeCase(activeTab)}`);
  };

  downloadPackingInvoice = tickedId => {
    axios
      .get("/tickets/packing_list", {
        params: {
          id: tickedId
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        window.open(res.data.json.file_path, "_blank");
      })
      .catch(err => {
        const { data } = err.response;
        if (data && data.hasOwnProperty("errors")) {
          Object.keys(data.errors).forEach(error => {
            toast.error(`${title(error)} ${data.errors[error]}`);
          })
        }
        if (data && data.hasOwnProperty("error")) {
          toast.error(data.error);
        }
      });
  };

  render() {
    const tabNames = ["EquipmentItem", "Facility", "Library"];

    const tabs = tabNames.map((tab, index) => (
      <li
        className="breadcrumb-item cursor"
        key={index}
        onClick={() => this.toggleTab(tab)}
        style={this.state.activeTab === tab ? active : inActive}
      >
        {translateModuleName(tab)}
      </li>
    ))
    return (
      <div className="booking-activity">
        <div className="row my-3 py-4">
          <div className="col">
            <nav aria-label="breadcrumb" className="px-4">
              <ol className="breadcrumb mb-0 pl-2 bg-white">
                {tabs}
              </ol>
            </nav>
          </div>
        </div>
        {this.state.activeTab !== "Facility" &&
          <div className="col-12">
            <div className="pl-4 pr-2 mx-1">
              <DatePicker
                selected={this.state.date}
                changed={date =>
                  this.setState({ date, key: date })
                }
              />
            </div>
          </div>}
        <div key={this.state.key}>
          {this.state.activeTab !== "Facility" ?
            <EquipmentActivityDetail activeTab={this.state.activeTab} date={this.state.date}
              downloadPackingInvoice={this.downloadPackingInvoice} />
            :
            <FacilityActivityDetail activeTab={this.state.activeTab}
              downloadPackingInvoice={this.downloadPackingInvoice} />
          }
        </div>
      </div>
    );
  }
}

export default withRouter(BookingActivity);
