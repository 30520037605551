import React from "react";
import dateFns from "date-fns";

const VolunteerItem = props => {
  return (
    <li className="list-group-item p-3">
      <div className="row">
        <div className="col-9">
          <span className="text-primary">{
            props.volunteer.description
              ? props.volunteer.description.substring(0, 15)
              : <span> -- </span>
          }
            , <strong>{props.volunteer.volunteer_hours}</strong> hrs</span><br />
          by {props.volunteer.user.login}/{props.volunteer.user.full_name}, {" "}
          {dateFns.format(new Date(props.volunteer.date_completed), "ddd MMM DD, YYYY")}
        </div>
        <div className="col-3 d-flex flex-row justify-content-end align-items-center">
          <div className="dashboard action-buttons d-flex flex-row justify-content-end align-items-center">
            <button className="btn btn-success rounded-circle" title="Approve" onClick={event => props.changeStatus(props.volunteer.id, 'approve')}>
              <i className="fas fa-check fa-sm"></i>
            </button>
            <button className="btn btn-danger rounded-circle" title="Reject" onClick={event => props.changeStatus(props.volunteer.id, 'reject')}>
              <i className="fas fa-times fa-sm"></i>
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default VolunteerItem;