import React from "react"
import Select from "react-select"

const SelectPageItems = ({ setPageItems }) => {
  const pageOptions = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "30", value: 30 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ]

  return (
    <div className="row">
      <div className="offset-md-6 col-md-6">
        <div className="row">
          <div className="col-6 text-right">
            <div className="py-2">Show entries:</div>
          </div>
          <div className="col-6">
            <div className="pr-4">
              <div className="form-group">
                <div className="select-dropdown">
                  <Select
                    isClearable={false}
                    isSearchable={false}
                    options={pageOptions}
                    defaultValue={pageOptions[0]}
                    onChange={(event) => setPageItems(event.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectPageItems
