import React, { Component } from "react"
import Input from "../../UI/input/Input"
import { title } from "change-case"
import FormButton from "../../UI/button/FormButton"
import Button from "../../UI/button/Button"
import axios from "../../../configAxios"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"

class AddToReel extends Component {
  signal = axios.CancelToken.source()
  state = {
    existingReel: {
      elementType: "select",
      elementConfig: {
        name: "existingReel",
        options: [],
        placeholder: "Select Option",
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
    },
    otherReels: {
      elementType: "select",
      elementConfig: {
        name: "otherReels",
        options: [],
        required: true,
        placeholder: "Select Option",
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
    },
    createReel: {
      reelName: {
        index: 1,
        elementType: "input",
        elementConfig: {
          name: "reelName",
          type: "text",
          required: true,
        },
        label: "Reel name",
        value: "",
      },
      reelSharing: {
        index: 2,
        elementType: "select",
        elementConfig: {
          name: "reelSharing",
          placeholder: "Select Option",
          options: [],
          required: true,
          components: {
            IndicatorSeparator: () => {
              return null
            },
          },
        },
        selectedOption: null,
        label: "Reel Sharing",
      },
    },
    userFields: false,
    reelFields: false,
    otherReelsField: false,
    disabled: false,
  }

  componentDidMount() {
    let allReels, currentUserReelsArray, sharingArray
    axios
      .get("/reel_project_attribute/formdata", {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        allReels = res.data.json.all_reels
        currentUserReelsArray = res.data.json.current_user_reels
        sharingArray = res.data.json.sharings
        currentUserReelsArray.push(["123", "Others"])
        const currentUserReels = currentUserReelsArray.map((item) => {
          const obj = {
            label: item[1],
            value: item[0],
          }
          return obj
        })
        const sharings = sharingArray.map((item) => {
          let displayName = ""
          if (item[0] === "me") {
            displayName = "Private"
          } else if (item[0] === "staff") {
            displayName = "Staff Only"
          } else if (item[0] === "everyone") {
            displayName = "Public"
          }
          var obj = {
            label: displayName,
            value: item[1],
            name: item[0],
          }
          return obj
        })
        let allReelsName = allReels.map((reel) => {
          var obj = {
            label: reel.name,
            value: reel.id,
          }
          return obj
        })

        this.setState({
          existingReel: {
            ...this.state.existingReel,
            elementConfig: {
              ...this.state.existingReel.elementConfig,
              options: currentUserReels,
            },
          },
          otherReels: {
            ...this.state.otherReels,
            elementConfig: {
              ...this.state.otherReels.elementConfig,
              options: allReelsName,
            },
          },
          createReel: {
            ...this.state.createReel,
            reelSharing: {
              ...this.state.createReel.reelSharing,
              elementConfig: {
                ...this.state.createReel.reelSharing.elementConfig,
                options: sharings,
              },
            },
          },
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  inputChangedHandler = (event, key, formName) => {
    const inputValue = {}
    if (key === "reelName") {
      inputValue["value"] = event.target.value
    } else {
      inputValue["selectedOption"] = event
    }
    if (key === "existingReel") {
      if (
        inputValue["selectedOption"] &&
        inputValue["selectedOption"].label === "Others"
      ) {
        this.showOtherReelsHandler(true)
      } else {
        this.showOtherReelsHandler(false)
      }
    }

    let updatedState

    if (key === "reelName" || key === "reelSharing") {
      updatedState = {
        ...this.state[formName],
        [key]: {
          ...this.state[formName][key],
          ...inputValue,
        },
      }
    } else {
      updatedState = {
        ...this.state[formName],
        ...inputValue,
      }
    }
    this.setState({ [formName]: updatedState })
  }

  showOtherReelsHandler = (show) => {
    this.setState({ otherReelsField: show })
  }

  onClickHandler = () => {
    this.setState({
      existingReel: {
        ...this.state.existingReel,
        elementConfig: {
          ...this.state.existingReel.elementConfig,
          defaultValue:
            this.state.existingReel.selectedOption &&
            this.state.existingReel.selectedOption.hasOwnProperty("label")
              ? this.state.existingReel.selectedOption
              : {},
        },
      },
      otherReels: {
        ...this.state.otherReels,
        elementConfig: {
          ...this.state.otherReels.elementConfig,
          defaultValue:
            this.state.otherReels.selectedOption &&
            this.state.otherReels.selectedOption.hasOwnProperty("label")
              ? this.state.otherReels.selectedOption
              : {},
        },
      },
      createReel: {
        ...this.state.createReel,
        reelSharing: {
          ...this.state.createReel.reelSharing,
          elementConfig: {
            ...this.state.createReel.reelSharing.elementConfig,
            defaultValue:
              this.state.createReel.reelSharing.selectedOption &&
              this.state.createReel.reelSharing.selectedOption.hasOwnProperty(
                "label"
              )
                ? this.state.createReel.reelSharing.selectedOption
                : {},
          },
        },
      },
      userFields: !this.state.userFields,
      reelFields: !this.state.reelFields,
    })
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const selectedReels = this.props.selectedReels.join(",")
    const reelData = { project_ids: selectedReels }
    const { existingReel, otherReels, createReel, userFields } = this.state
    if (!userFields) {
      reelData["reel_id"] =
        existingReel.selectedOption.label === "Others"
          ? otherReels.selectedOption.value
          : existingReel.selectedOption.value
    } else {
      reelData["reel_name"] = createReel.reelName.value
      reelData["reel_sharing"] = createReel.reelSharing.selectedOption.value
    }

    axios.post("/reel_project_attribute", reelData).then((res) => {
      if (res.data.json.success) {
        toast.success("Reel created successfully")
        this.props.closeModal()
        this.checkboxHandler()
        this.props.history.push("/reels")
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach((error) => {
            toast.error(title(error) + " " + res.data.json.errors[error])
          })
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error)
        }
        this.setState({ disabled: false })
      }
    })
  }

  checkboxHandler = (event) => {
    this.props.clearCheckbox()
  }
  render() {
    let createReelFormElement = []

    for (let key in this.state.createReel) {
      createReelFormElement.push({
        index: this.state.createReel[key].index,
        id: key,
        config: this.state.createReel[key],
      })
    }

    const existingReelForm = (
      <Input
        elementType={this.state.existingReel.elementType}
        elementConfig={this.state.existingReel.elementConfig}
        changed={(event) =>
          this.inputChangedHandler(event, "existingReel", "existingReel")
        }
        selectedOption={this.state.existingReel.selectedOption}
      />
    )

    const otherReelsForm = (
      <Input
        elementType={this.state.otherReels.elementType}
        elementConfig={this.state.otherReels.elementConfig}
        changed={(event) =>
          this.inputChangedHandler(event, "otherReels", "otherReels")
        }
        selectedOption={this.state.otherReels.selectedOption}
      />
    )
    createReelFormElement = createReelFormElement.sort(
      (val1, val2) => val1.index > val2.index
    )
    const createReelForm = createReelFormElement.map((formElement) => (
      <Input
        key={formElement.id}
        {...formElement.config}
        changed={(event) =>
          this.inputChangedHandler(event, formElement.id, "createReel")
        }
        chart={this.state.enteredUserChar}
      />
    ))
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        {this.state.reelFields ? (
          ""
        ) : (
          <div>
            <label>Select from existing Reels</label>
            {existingReelForm}
          </div>
        )}
        {this.state.reelFields
          ? ""
          : this.state.otherReelsField
          ? otherReelsForm
          : ""}
        {this.state.userFields ? createReelForm : ""}
        <div className="mb-1">
          <span className="text-primary cursor" onClick={this.onClickHandler}>
            {this.state.userFields
              ? "Select from existing Reels"
              : "Create New Reel"}
          </span>
        </div>

        <div className="text-center">
          <Button type="secondary" clicked={this.props.closeModal}>
            Cancel
          </Button>
          <FormButton className="ml-2" disabled={this.state.disabled}>
            Add to Reel
          </FormButton>
        </div>
      </form>
    )
  }
}

export default withRouter(AddToReel)
