import React, { Component } from "react";
import PropTypes from "prop-types";
import Spinner from "../../UI/Spinner";
import { connect } from "react-redux";
import {
  getSubmissions,
  selectSubmissionById,
  selectSubmissionsLoading,
} from "../../../store/slices/submission";
import { Link } from "react-router-dom";
import "./index.css";
import {
  getForms,
  selectFormById,
  selectFormsLoading,
} from "../../../store/slices/form";
import {
  getSubmissionAnswers,
  selectSubmissionAnswersBySubmissionId,
  selectSubmissionAnswersLoading,
} from "../../../store/slices/submissionAnswer";
import {
  getFormAttributes,
  selectFormAttributesById,
  selectFormAttributesLoading,
} from "../../../store/slices/formAttributes";

class Submission extends Component {
  componentDidMount() {
    this.props.getSubmissions({ campaignId: 3 });
    this.props.getForms({ campaignId: 3 });
    this.props.getSubmissionAnswers();
    this.props.getFormAttributes();
  }

  render() {
    const { submission, form, submissionAnswers, loading } = this.props;
    console.log({ props: this.props });
    let content;
    if (loading) {
      content = <Spinner />;
    } else {
      const answerData = Object.values(submissionAnswers).map((answer) => (
        <div className="card" key={answer.id}>
          <div className="info-heading font-weight-bold">
            {answer.form.name}:
          </div>
          <div className="info-text">{answer.value}</div>
        </div>
      ));
      content = (
        <div className="detail container w-100">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-text">
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="row my-3">
                          <div className="col-md-6 heading">
                            <div>
                              {form.name} - {submission.creator}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-12">
                        <Link
                          to={`/campaigns/${this.props.match.params.campaignId}/submissions/${this.props.match.params.id}/edit`}
                          className="text-info decoration-none cursor mx-2"
                        >
                          Edit this Submission
                        </Link>
                      </div>
                    </div>

                    <div className="row m-4">
                      <div className="card-columns">{answerData}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div>{content}</div>;
  }
}

Submission.propTypes = {
  submission: PropTypes.shape({
    id: PropTypes.number.isRequired,
    creator: PropTypes.string.isRequired,
    formId: PropTypes.number.isRequired,
  }),
  form: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  submissionAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      formAttributeId: PropTypes.number.isRequired,
      submissionId: PropTypes.number.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  getForms: PropTypes.func.isRequired,
  getSubmissions: PropTypes.func.isRequired,
  getSubmissionAnswers: PropTypes.func.isRequired,
  getFormAttributes: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const currentItemId = ownProps.match.params.id;
  const submission = selectSubmissionById(state, currentItemId) || {};
  const form = selectFormById(state, submission.formId);
  const submissionAnswers = selectSubmissionAnswersBySubmissionId(
    state,
    submission.id
  ).map((answer) => {
    return {
      ...answer,
      form: selectFormAttributesById(state, answer.formAttributeId),
    };
  });

  const loading =
    selectSubmissionsLoading(state) ||
    selectFormsLoading(state) ||
    selectSubmissionsLoading(state) ||
    selectSubmissionAnswersLoading(state) ||
    selectFormAttributesLoading(state);

  const currentItem = {
    submission,
    form,
    submissionAnswers,
  };

  return {
    loading,
    response: state.response,
    ...currentItem,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubmissions: (params) => dispatch(getSubmissions(params)),
    getForms: (params) => dispatch(getForms(params)),
    getSubmissionAnswers: (params) => dispatch(getSubmissionAnswers(params)),
    getFormAttributes: (params) => dispatch(getFormAttributes(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Submission);
