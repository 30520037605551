import React from 'react'

export default function ReviewsSummary({ juryMembers, reviews }) {

  const averageRating = reviews?.length > 0 ? reviews.reduce((acc, current) => acc += current.rating, 0)/reviews.length : "N/A"
  const reviewedPercent = ( reviews?.length / juryMembers.length ) * 100

  return (
    <div className="d-flex flex-row justify-content-between mb-2">
      <p className="m-0"><span className="font-weight-bold">Completed: </span>{`${Number(reviewedPercent.toFixed(0))}%`}</p>
      <p className="m-0"><span className="font-weight-bold">Average Rating: </span>{averageRating}</p>
    </div>
  )
}
