import React from "react"
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable"
import Slider from "rc-slider"
import SelectDropdown from "./selectDropdown"
import AsyncSelectInput from "./asyncSelectInput"
import CreatableSelectInput from "./creatableSelectInput"
import CheckboxInput from "./checkboxInput"
import GenericInput from "./genericInput"
import CurrencyInput from "./currencyInput"

const InputElement = ({ props }) => {
  const createSliderWithTooltip = Slider.createSliderWithTooltip
  const Range = createSliderWithTooltip(Slider.Range)

  switch (props.elementType) {
    case "input":
      return <GenericInput props={props} />
    case "currency":
      return <CurrencyInput props={props} />
    case "textarea":
      return (
        <textarea
          className="form-control"
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          style={{ borderRadius: 0, boxShadow: "none", ...props.style }}
          disabled={props.disabled}
        />
      )
    case "select":
      return <SelectDropdown props={props} />
    case "range":
      return <Range {...props.elementConfig} onChange={props.changed} />
    case "asyncSelect":
      return <AsyncSelectInput props={props} />
    case "asyncCreatableSelect":
      return <AsyncCreatableSelect {...props.elementConfig} isClearable isSearchable onChange={props.changed} loadOptions={props.elementConfig.loadOptions}/>
    case "creatableSelect":
      return <CreatableSelectInput props={props} />
    case "file":
      return (
        <input
          type="file"
          className={`file-input ${props.className}`}
          onChange={props.changed}
        />
      )

    case "checkbox":
      return <CheckboxInput props={props} />
    default:
      return (
        <input
          className="form-control"
          required={true}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          style={{
            borderRadius: 0,
            width: props.media ? "108%" : "100%",
            height: 38,
            boxShadow: "none",
          }}
          disabled={props.disabled}
        />
      )
  }
}

export default InputElement
