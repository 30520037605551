import React, { useEffect, useMemo, useRef, useState } from "react"
import { isEmpty, sortBy } from "lodash"
import { toast } from "react-toastify"
import axios from "../../../configAxios"
import Button from "../../UI/button/Button"
import Modal from "../../UI/Modal"
import StatItem from "./StatItem"
import NotesItem from "./NotesItem"
import ModalSpinner from "../../UI/ModalSpinner"

const ProgramStats = ({ programId, showModal, setShowModal }) => {
  const [loading, setLoading] = useState(false)
  const [programStats, setProgramStats] = useState([])
  const signal = useMemo(() => axios.CancelToken.source(),[])
  const statsForUpdate = useRef()

  useEffect(() => {
    if (programId) getProgramStats()
  }, [])

  const getProgramStats = () => {
    setLoading(true)
    axios
      .get("/program_stats", { params: { program_id: programId }, cancelToken: signal.token })
      .then(res => {
        const { stats } = res.data.json
        setLoading(false)
        setProgramStats(sortBy(stats, 'id'))
        statsForUpdate.current = []
      })
      .catch(() => setLoading(false))
  }

  const onSubmitUpdates = (stagedStats) => {
    if (!isEmpty(stagedStats)) {
      Promise.all(stagedStats.map(stat => {
          return axios.put(`/program_stats/${stat.id}`, { stat_value: stat.stat_value, stat_notes: stat.stat_notes })
        })
      ).then(() => {
        setShowModal(false)
        toast.success("Successfully updated program stats")
      }).catch(() => {
        toast.error("Could not update program stats")
      })
    }
  }

  const onInputChange = (statObj) => {
    const matchedStat = programStats.find(s => s.id === statObj.id)

    if (matchedStat?.stat_value === statObj.stat_value && matchedStat?.stat_notes === statObj.stat_notes) return

    const removeMatched = statsForUpdate.current.filter(s => s.id !== statObj.id)

    statsForUpdate.current = sortBy([...removeMatched, statObj], 'id')
  }

  return (
    <Modal
      show={showModal}
      title='Program Stats'
      hide={() => setShowModal(false)}
    >
      <div className="tag-form mt-3 w-100">
        {isEmpty(programStats) && loading && <ModalSpinner/>}
        {isEmpty(programStats) && !loading && (
          <div className="w-100 text-center my-5">No stats found</div>
        )}
        {!isEmpty(programStats) && (
          <>
            <table className="w-100">
              <tbody>
              {programStats.map(stat =>
                <StatItem key={stat.id} stat={stat} onChange={onInputChange} />
              )}
              </tbody>
            </table>
            {programStats.filter(stat => stat.stat_name === 'Notes').map(stat =>
              <NotesItem key={stat.id} stat={stat} onChange={onInputChange} />
            )}
          </>
        )}
        <div className="text-center mt-3">
          <Button type="secondary" className="mr-2" clicked={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="primary" clicked={() => onSubmitUpdates(statsForUpdate.current)}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ProgramStats
