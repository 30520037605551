import React, { Component } from "react";
import { toast } from "react-toastify";
import { sortBy } from "lodash"

import NewProjectAttribute from "./NewProjectAttribute.js";

import Spinner from "../../UI/Spinner";
import Button from "../../UI/button/Button";
import Modal from "../../UI/Modal";
import axios from "../../../configAxios";
import langUtils from "../../../utils/langUtils.js";
import AttributeListItem from './AtributeListComponents/AttributeListItem'

import "./Projects.css";

class ProjectAttributeList extends Component {
  signal = axios.CancelToken.source();
  state = {
    edit: false,
    newModal: false,
    id: null,
    uiTypeIndex: null,
    loading: true,
    flag: true,
    list: this.props.typeList
  };

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      return {
        loading: props.loading,
        list: props.typeList
      };
    }
    return null;
  }

  deleteHandler = (id, attributeName) => {
    let response = window.confirm(`Are you sure you want to delete ${attributeName}?`);
    if (response) {
      axios
        .delete("/project_attribute_types/" + id)
        .then((res) => {
          if (res.data.json.success) {
            toast.success(`${attributeName} successfully deleted`);
          }
          this.props.attrTypeList();
        })
        .catch((error) => error("Something went wrong"));
    }
  };

  editModal = (id) => {
    window.scrollTo(0, 0);
    this.setState({ edit: true, id: id, newModal: true });
  };

  shouldShowId = () => {
    const { type } = this.props
    return type === 'Short Attributes' || type === 'Long Attributes'
  }

  render() {
    let attrTypeList
    const unsortedList = [...this.state.list]
    
    if (this.props.loading) return attrTypeList = <Spinner />

    const sortedList = sortBy(unsortedList, (item) => item.name.toLowerCase() )

    attrTypeList = sortedList.map((item) => (
      <li className="list-group-item rounded-0 border-top projectAttr" key={item.id}>
        <AttributeListItem item={item} shouldShowId={this.shouldShowId} editModal={this.editModal} deleteHandler={this.deleteHandler}/>
      </li>
    ))

    return (
      <div className="row">
        <div className="col-lg-12">
          <ul className="list-group list-group-flush mb-0">
            <div className="list-group-item">
              <div className="row align-items-center">
                {
                  this.shouldShowId()
                    ?
                    <>
                      <div className="col-lg-1">
                        <span className="font-weight-bold">Id</span>
                      </div>
                      <div className="col-lg-5">
                        <span className="font-weight-bold">Type Name</span>
                      </div>
                    </>
                    :
                    <>
                      <div className="col-lg-6">
                        <span className="font-weight-bold">Type Name</span>
                      </div>
                    </>
                }

                <div className="col-lg-3">
                  <span className="font-weight-bold">Visible?</span>
                </div>
                <div className="col-lg-3 text-right">
                  <Button
                    type="success"
                    clicked={() => {
                      window.scrollTo(0, 0);
                      this.setState({ newModal: true, edit: false });
                    }}
                  >
                    {langUtils("txt_global_add_new", "Add New")}
                  </Button>
                </div>
              </div>
            </div>
            {attrTypeList}
          </ul>
        </div>
        <Modal
          show={this.state.newModal}
          title={this.state.edit ? "Edit Attribute Type" : "New Attribute Type"}
          hide={() => this.setState({ newModal: false })}
          flag={this.state.flag}
        >
          <NewProjectAttribute
            closeModal={() => this.setState({ newModal: false })}
            attrTypeList={this.props.attrTypeList}
            edit={this.state.edit}
            id={this.state.id}
            tabChangeHandler={this.props.tabChangeHandler}
          />
        </Modal>
      </div>
    );
  }
}

export default ProjectAttributeList;
