import React, { Component } from "react"
import { Link } from "react-router-dom"
import { formatDate } from "../../utils/formatDate"

import axios from "../../configAxios"
import Pagination from "../UI/Pagination"
import Spinner from "../UI/Spinner"

import NoImage from "../../assests/images/no-image.png"

class Drafts extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      totalCount: 0,
      drafts: [],
      loading: true,
      selectedPage: 1
    };
  }

  componentDidMount() {
    axios.get("/programs", {
      params: { type: "Drafts" },
      cancelToken: this.signal.token
    })
      .then(res => {
        let drafts = [];
        const totalCount = res.data.meta.total_count;
        for (let key in res.data.programs) {
          drafts.push({ ...res.data.programs[key] });
        }
        this.setState({ drafts, loading: false, totalCount });
      });
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      drafts: null,
      loading: true
    });
    axios.get("/programs", {
      params: { type: "Drafts", page: page.selected + 1 },
      cancelToken: this.signal.token
    })
      .then(res => {
        let drafts = [];
        const totalCount = res.data.meta.total_count;
        for (let key in res.data.programs) {
          drafts.push({ ...res.data.programs[key] });
        }
        this.setState({ drafts, loading: false, totalCount });
      })
      .catch(err => err);
  }; 

  render() {
    let draftsList = null;
    if (this.state.loading) {
      draftsList = <Spinner />;
    } else {
      draftsList = this.state.drafts.map(draft => {
        const url = draft.photo.photo.thumb.url ? draft.photo.photo.thumb.url : NoImage;
        
        return (
          <li className="list-group-item" key={draft.id}>
            <div className="row">
              <div className="col-md-1 text-center">
                <a href={draft.photo.photo.thumb.url} target="_blank" rel="noopener noreferrer">
                  <div className="rounded-circle bg-cover m-auto" style={{
                    width: "100%",
                    paddingTop: "100%",
                    backgroundImage: `url(${url})`
                  }} />
                </a>
              </div>
              <div className="col-md-11">
                <Link
                  to={"/programs/" + draft.id}
                  className="text-info font-weight-normal"
                >
                  <p className="mb-1">{draft.name}</p>
                </Link>
                <span className="font-weight-bold">
                  Draft Date:
                  <span>
                    {" "}
                    {
                      draft.earliest_scheduled_program_date === null
                        ? "No date available"
                        : formatDate(draft.earliest_scheduled_program_date, 'YYYY/MM/DD')
                    }
                  </span>
                </span>
                <br/>
                <span>
                  {
                    draft.description === null || draft.description === ""
                      ? "No description available"
                      : draft.description
                  }
                </span>
              </div>
            </div>
          </li>
        );
      });
    }
    return (
      <div className="row">
        <div className="col-12">
          <ul className="list-group list-group-flush border-bottom webkit-fill-available">
            {draftsList}
          </ul>
        </div>

        <div className="col-md pl-0">
          {
            this.state.totalCount !== 0 && (
              <Pagination
                pageCount={this.state.totalCount / 10}
                forcePage={this.state.selectedPage - 1}
                handlePageClick={page => this.paginationHandler(page)}
              />
            )
          }
        </div>
      </div>
    );
  }
}

export default Drafts;
