import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getProject } from "../../store/actions/index";
import Spinner from "../UI/Spinner";
import Sidebar from "./Sidebar";
import Attributes from "./Attributes";
import { clearResponse } from "../../store/actions/index";
import _ from "lodash";

class Project extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getProject(this.props.match.params.id);
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEmpty(props.response) && props.response.success === false) {
      toast.error(props.response.message);
      props.clearResponse();
      props.history.push(`/users/${localStorage.userId}/summary`);
    }
    return null;
  }

  render() {
    const { project, loading } = this.props.projects;
    let projectContent;
    if (project === null || loading) {
      projectContent = <Spinner />;
    } else {
      projectContent = (
        <div className="container w-100">
          <div className="row mb-4">
            <div className="col-12">
              <h3>Project</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <Attributes id={this.props.match.params.id} />
            </div>
          </div>
        </div>
      );
    }
    return <div>{projectContent}</div>;
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects,
    response: state.response
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProject: id => dispatch(getProject(id)),
    clearResponse: () => dispatch(clearResponse())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
