import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { DefaultPlayer as Video } from "react-html5video";
import ReactJWPlayer from "react-jw-player";

import "./VideoPlayer.css";
import "react-html5video/dist/styles.css";

class VideoPlayer extends Component {
  videoRef = React.createRef()
  playPromise = null

  onPlayPauseClick = () => {
    if (this.videoRef.current) {
      const { videoEl } = this.videoRef.current;

      if (this.playPromise) {
        this.playPromise
          .then(_ => {
            if (!videoEl.paused)
              videoEl.pause()
          })
          .catch(err => null)
          .finally(_ => {
            this.playPromise = null
          })
      } else {
        if (videoEl.paused) {
          this.playPromise = videoEl.play();
        } else {
          videoEl.pause();
        }
      }
    }
  }

  renderVideoPlayer = () => {
    const { player: playerType, src } = this.props
    const isHtmlPlayer = (playerType === "htmlPlayer")

    return (
      <>
        {isHtmlPlayer && (
          <Video
            ref={this.videoRef}
            onPlayPauseClick={this.onPlayPauseClick}
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
          >
            <source src={src} />
          </Video>
        )}
        {!isHtmlPlayer && (
          <ReactJWPlayer
            playerId="my-unique-id"
            licenseKey="KfEj20iL73YJlQBSm+6bqoSc148Cf5eSvIn2BWoo/Zg="
            playerScript="https://d2nvlqutlc7e9k.cloudfront.net/jwplayer8.js"
            file={src}
          />
        )}
      </>
    )
  }

  render() {
    return (
      <Modal
        backdrop={true}
        show={this.props.show}
        style={{ position: "absolute", minHeight: "100vh", display: "flex", alignItems: "center" }}
        onHide={this.props.togglePlayer}
      >
        <button className="close" onClick={this.props.togglePlayer}>
          <span aria-hidden="true">&times;</span>
        </button>
        {this.renderVideoPlayer()}
      </Modal>
    );
  }
}

export default VideoPlayer
