import React from 'react'
import ApplicationItem from './ApplicationItem'

export default function ApplicationsList({ applicationsList, grantCampaign }) {
  const appListEmpty = () => !!(applicationsList?.length < 1)
  return (
    <div className="mt-2 pb-3">
      {appListEmpty() &&
      <p className="ml-4">No applications have been received yet</p>
      }

      {
        applicationsList.length > 0 &&
        <>
          <div className="table-responsive w-95 mx-auto">
            <table className="table table-bordered table-hover">
              <thead className="text muted border-top">
              <tr>
                <th scope="col" className="w-25">Name</th>
                <th scope="col" className="w-25">Email</th>
                <th scope="col" className="w-25">Status</th>
                <th scope="col" className="w-auto">Submitted</th>
                <th scope="col" className="w-auto">View</th>
              </tr>
              </thead>
              <tbody>
              {applicationsList.map(item => <ApplicationItem key={item.id} applicationItem={item} grantCampaign={grantCampaign} />)}
              </tbody>
            </table>
          </div>
        </>
      }
    </div>
  )
}
