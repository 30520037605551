import React, { Component } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { title } from "change-case"
import _ from "lodash"

import axios from "../../configAxios"
import Button from "../UI/button/Button"
import dateFns from "date-fns"
import Input from "../UI/input/Input"
import Modal from "../UI/Modal"
import { responseErrorHandler } from "../../utils/errorHandler"
import ToggleSwitch from "../common/toggleSwitch"
import langUtil from "../../utils/langUtils"
import tagName from "../shared/tagName"

import EditPhoto from "./EditPhoto"
import UpdateExpiryDate from "./UpdateExpiryDate"
import Invitation from "./Invitation"

import NoUser from "../../assests/images/user-icon.jpg"
import "./Sidebar.css"
import MembershipInfo from "./sidebar/MembershipInfo"
import NameOrOrgDisplay from "./sidebar/NameOrOrgDisplay"
import UserInfo from "./sidebar/UserInfo"
import AccountInfo from "./sidebar/AccountInfo";

class Sidebar extends Component {
  signal = axios.CancelToken.source()
  timeout = 0
  state = {
    tagsInput: {
      elementType: "asyncSelect",
      elementConfig: {
        name: "tags",
        placeholder: "Enter labels here",
        controlShouldRenderValue: false,
        inputLength: 1,
        loadOptions: (inputValue, callback) => {
          if (inputValue.length > 0) this.searchTagsByName(inputValue, callback)
        },
        components: {
          DropdownIndicator: () => {
            return null
          },
          IndicatorSeparator: () => {
            return null
          },
          ClearIndicator: null,
        },
        styles: {
          multiValueLabel: (base) => ({
            ...base,
            backgroundColor: "#f0e9e9",
            color: "#666666",
          }),
          multiValueRemove: (base) => ({
            ...base,
            backgroundColor: "#f0e9e9",
            color: "#666666",
          }),
        },
      },
      selectedOption: null,
    },
    userData: "",
    expiryDate: "",
    showInvitationModal: false,
    editPhotoModal: false,
    showModal: false,
    tags: [],
    tagged: [],
    loading: true,
    width: window.innerWidth,
    imageLoaded: false,
    tagCharacters: "",
    notesModal: false,
    editThisUser: null,
    allowProfileAccessToMembers: null,
    canUpdateUser: null,
    canEditPublicSetting: null,
  }

  componentDidMount() {
    const { userData, expiryDate } = this.props
    this.setState({
      userData,
      expiryDate,
      loading: false,
    })
    this.getTags()
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios
      .get("/users/policy", { params: { id: this.props.userId } })
      .then((res) => {
        this.setState({
          allowProfileAccessToMembers:
            res.data.json.allow_profile_access_to_members,
          editThisUser: res.data.json.edit_this_user,
          canUpdateUser: res.data.json.can_update_user,
          canEditPublicSetting: res.data.json.can_edit_public_setting,
        })
      })
  }

  componentWillReceiveProps = (nextProps) => {
    const { userData } = nextProps
    this.setState({
      userData,
    })
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange)
    this.signal.cancel("Request is being Cancelled")
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  flagHandler = () => {
    this.componentDidMount()
  }

  editPhotoHandler = () => {
    window.scrollTo(0, 0)
    this.setState({ editPhotoModal: true })
  }

  notesHandler = () => {
    window.scrollTo(0, 0)
    this.setState({ notesModal: true })
  }

  updatePhoto = (photo) => {
    this.props.getUserData()
    this.setState({ imageLoaded: true })
  }

  getTags = () => {
    const params = {
      user_id: this.props.userId,
    }
    axios
      .get(`tags/`, { params, cancelToken: this.signal.token })
      .then((res) => {
        if (res.data.tags[0] !== "") {
          this.setState({
            ...this.state,
            tagged: res.data.tags[0].split(","),
          })
        } else {
          this.setState({ tagged: [] })
        }
      })
      .catch((error) => error)
  }

  inputChangedHandler = (option) => {
    const user_id = this.props.userId
    if (option && option.value) {
      axios.post("/tags", { query: option.value, user_id }).then((res) => {
        if (res.data.json.success) {
          this.getTags()
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
    }
  }

  searchTagsByName = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue,
        type: "User",
      },
    }
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.getTagsByName(queryParams, callback)
    }, 300)
  }

  getTagsByName = (queryParams, callback) => {
    axios
      .get("/tags/search", { ...queryParams, cancelToken: this.signal.token })
      .then((res) => {
        const options = res.data.json.filter(
          (el) => !this.state.tagged.includes(el.value)
        )
        callback(
          options.map((tag) => {
            return {
              value: tag.value,
              label: tag.name,
            }
          })
        )
      })
      .catch((err) => err)
  }

  deleteTagHandler = (tagValue) => {
    const user_id = this.props.userId
    const response = window.confirm(
      "Are you sure you want to delete the label?"
    )
    if (response) {
      axios
        .delete("/tags", {
          params: { query: tagValue, user_id },
        })
        .then((res) => {
          if (res.data.json.success) {
            this.getTags()
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
          }
        })
    }
  }

  countWords = (str) => {
    str = str.replace(/(^\s*)|(\s*$)/gi, "")
    str = str.replace(/[ ]{2,}/gi, " ")
    str = str.replace(/\n /, "\n")
    return str.split(" ").length
  }

  updateName = (value) => {
    const userData = this.state.userData
    userData.full_name = value
    this.setState({ userData: userData })
  }

  renewHandler = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true })
  }

  updateUser = (params) => {
    axios
      .put(`/users/update_profile_setting/${this.props.userId}`, params)
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Profile's public visibility updated successfully.")
        } else {
          responseErrorHandler(res)
        }
      })
      .catch((err) => err)
  }

  togglePublicProfileChangeHandler = () => {
    const userData = this.state.userData
    userData.public_profile = !userData.public_profile
    this.setState(
      {
        userData: userData,
      },
      () => {
        this.updateUser({
          id: this.props.userId,
          public_profile: userData.public_profile,
        })
      }
    )
  }

  renew = (value) => {
    this.setState({ expiryDate: value })
    this.props.getUserData()
  }

  inviteHandler = () => {
    window.scrollTo(0, 0)
    this.setState({ showInvitationModal: true })
  }

  render() {
    const user = { ...this.state.userData }
    let customFields = this.props.customFields
    if (customFields) {
      customFields = Object.keys(customFields)
        .filter(
          (customField) =>
            Number.isInteger(customFields[customField]) ||
            !_.isEmpty(customFields[customField])
        )
        .map((customField, index, arr) => (
          <div
            className={`pl-3 pt-2 ${arr.length - 1 === index ? "pb-2" : ""}`}
            key={index}
          >
            {customField} : {customFields[customField]}
          </div>
        ))
    }

    const { width } = this.state
    const isMobile = width <= 768

    let tags
    if (this.state.tagged.length > 0) {
      tags = this.state.tagged.map((tagValue, index) => (
        <div className="tag word-wrap-break" key={index}>
          <div className="tag-name">
            {this.props.policyRes.can_add_or_delete_label ? (
              <Link
                to={`/users/members?tag_name=${tagValue}`}
                className="text-primary"
              >
                {tagName(tagValue)}
              </Link>
            ) : (
              tagName(tagValue)
            )}
          </div>
          {this.props.policyRes.can_add_or_delete_label && (
            <button
              className="tag-delete"
              onClick={() => this.deleteTagHandler(tagValue)}
            >
              x
            </button>
          )}
        </div>
      ))
    } else {
      tags = <div className="font-weight-bold">No Labels</div>
    }

    const responsibilitiesPopover = (
      <Popover style={{ width: 400 }}>
        <div>
          <div className="cursor-context-menu">
            <div className="tooltip-header font-16 font-weight-bold mb-2">
              {"Rights & Responsibilities"}
            </div>
          </div>
          <div className="border" />
          <div className="d-flex justify-content-between font-15">
            <div>
              <p className="text-muted">Membership Dues / Year</p>
            </div>
            <div className="font-weight-bold">
              {user.membership_dues != null ? (
                <p>${parseFloat(user.membership_dues).toFixed(2)}</p>
              ) : (
                <p className="mr-2">--</p>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between font-15 mt-2">
            <div>
              <p className="text-muted">Volunteer Hours Required</p>
            </div>
            <div className="font-weight-bold">
              {user.volunteer_hours_required_per_year != null ? (
                <p>
                  {user.volunteer_hours_required_per_year} <span>Hrs</span>
                </p>
              ) : (
                <p className="mr-2">--</p>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between font-15 mt-2">
            <div>
              <p className="text-muted">Rent Equipment</p>
            </div>
            <div className="font-weight-bold">
              {user.can_rent_equipment ? "Yes" : "No"}
            </div>
          </div>
          <div className="d-flex justify-content-between font-15 mt-2">
            <div>
              <p className="text-muted">Book Facilities</p>
            </div>
            <div className="font-weight-bold">
              {user.can_book_facilities ? "Yes" : "No"}
            </div>
          </div>
          <div className="d-flex justify-content-between font-15 mt-2">
            <div>
              <p className="text-muted">Register in Programs</p>
            </div>
            <div className="font-weight-bold">
              {user.can_register_in_programs ? "Yes" : "No"}
            </div>
          </div>
          <div className="d-flex justify-content-between font-15 mt-2">
            <div>
              <p className="text-muted">Preferred Rates</p>
            </div>
            <div className="font-weight-bold">
              {user.gets_member_rates ? "Yes" : "No"}
            </div>
          </div>
          <div className="d-flex justify-content-between font-15 mt-2">
            <div>
              <p className="text-muted">Mailing List</p>
            </div>
            <div className="font-weight-bold">
              {user.is_on_mailing_list ? "Yes" : "No"}
            </div>
          </div>
          <div className="d-flex justify-content-between font-15 mt-2">
            <div>
              <p className="text-muted">Insurance Coverage</p>
            </div>
            <div className="font-weight-bold">
              <p>{user.gets_insurance_coverage ? "Yes" : "No"}</p>
            </div>
          </div>
        </div>
      </Popover>
    )

    const creds = []

    user.creds &&
      user.creds.map((cred) => {
        if (cred.name) {
          creds.push(cred)
        }
        return creds
      })
    const credUsers = []
    user.cred_users &&
      user.cred_users.forEach((cred) => {
        const { cred_id, expiry_date } = cred
        credUsers[cred_id] = expiry_date
      })

    const credPopover = (
      <Popover style={{ width: 275 }}>
        <div>
          <p className="font-16 font-weight-bold mb-0">{"Accreditations"}</p>
          <div className="border mt-2" />
          {creds && creds.length ? (
            <div className="row">
              {creds.map((cred, index) => {
                const expiryStatus =
                  credUsers[cred.id] &&
                  new Date() > new Date(credUsers[cred.id])
                return (
                  <div className="col-12" key={index}>
                    <div className="d-flex ml-2 font-14 mt-2">
                      <div
                        className={`py-2 word-break ${
                          credUsers[cred.id]
                            ? expiryStatus
                              ? "expires"
                              : "current"
                            : ""
                        }`}
                        key={index}
                      >
                        {cred.name}
                      </div>
                      <div className="text-muted py-2 ml-2">
                        {credUsers[cred.id] && (
                          <button
                            type="text"
                            className={`text-center 
                      ${expiryStatus ? "expires-btn" : "current-btn"}`}
                          >
                            {expiryStatus
                              ? `Expired ${dateFns.format(
                                  credUsers[cred.id],
                                  "YYYY.MM.DD"
                                )}`
                              : "current"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="py-1 font-weight-bold">None</div>
          )}
        </div>
      </Popover>
    )

    const educationPopover = (
      <Popover style={{ width: 275 }}>
        <div>
          <p className="font-16 font-weight-bold mb-0">
            {"Education & Associations"}
          </p>
          <div className="border mt-2" />
          {user.education_and_associations &&
          user.education_and_associations.length ? (
            <div className="row">
              {user.education_and_associations.map((edu, index) => (
                <div className="col-md-6 col-6" key={index}>
                  <div
                    className="font-16 text-muted py-2"
                    style={{ wordBreak: "break-word" }}
                  >
                    {edu.name}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="py-1 font-weight-bold">None</div>
          )}
        </div>
      </Popover>
    )

    let notes, modalNotes
    const brExp = /<\/?br\s*\/?>/
    if (!_.isEmpty(user.notes)) {
      const notesLength = this.countWords(user.notes)
      const limitNotes = user.notes.split(/\s+/).slice(0, 50).join(" ")

      notes = (
        <div className="p-3">
          {limitNotes.split(brExp).map((note, index, arr) => {
            if (arr.length - 1 === index) return <span>{note}</span>
            else
              return (
                <span>
                  {note}
                  <br />
                  <br />
                </span>
              )
          })}
          {notesLength > 50 && (
            <span
              className=" font-weight-bold cursor"
              onClick={this.notesHandler}
            >
              {" "}
              ... (see more)
            </span>
          )}
        </div>
      )

      modalNotes = (
        <div>
          {user.notes.split(brExp).map((note, index, arr) => {
            if (arr.length - 1 === index) return <span>{note}</span>
            else
              return (
                <span>
                  {note}
                  <br />
                  <br />
                </span>
              )
          })}
        </div>
      )
    }

    const image = user.photo ? user.photo : NoUser
    const { policyRes, isAdmin } = this.props
    const { canEditPublicSetting } = this.state

    return (
      <React.Fragment>
        <div className="card friends-sidebar">
          <div className="card-body p-0">
            <div className="card-text">
              <div className="row">
                <div className="col-6">
                  <i
                    className={`fas fa-flag p-3 ${
                      this.state.userData === ""
                        ? "text-light"
                        : this.state.userData.flags_count === 0
                        ? "text-light"
                        : "text-muted"
                    }`}
                  />
                </div>
                <div className="col-6 text-right">
                  {this.state.editThisUser && (
                    <Link
                      to={{
                        pathname: `/users/edit/${user.id}`,
                        state: { isAdmin },
                      }}
                    >
                      <i className="fas fa-pencil-alt cursor text-muted p-3" />
                    </Link>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="px-5">
                    <div
                      className="rounded-circle bg-cover"
                      style={{
                        width: "100%",
                        paddingTop: "100%",
                        backgroundImage: `url(${image})`,
                      }}
                    />
                    {this.state.editThisUser && 
                      <i
                        className="fas fa-upload upload-button"
                        onClick={this.editPhotoHandler}
                        style={{ cursor: "pointer", opacity: 1 }}
                      />
                    }
                  </div>
                </div>
              </div>
              
              <NameOrOrgDisplay user={user} />

              {policyRes.can_invite && (
                <div className="row py-3">
                  <div className="col-12 text-center">
                    <Button
                      type="warning"
                      style={{ padding: "5px 25px" }}
                      clicked={this.inviteHandler}
                    >
                      Send Invitation
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div className="row no-gutters border-bottom py-2"/>

            <UserInfo user={user} />

            {this.state.canEditPublicSetting && 
              <div className="row no-gutters py-2">
                <div className="col-8 pl-2">
                  {langUtil("txt_share_profile", "Share Profile")}
                </div>
                <div className="col-4 font-weight-bold text-right pr-2">
                  <ToggleSwitch
                    checked={user.public_profile}
                    onChange={this.togglePublicProfileChangeHandler}
                    style={{
                      cursor: this.state.canUpdateUser ? "pointer" : "no-drop",
                      opacity: this.state.canUpdateUser ? 1 : 0.7,
                    }}
                  />
                </div>
              </div>
            }
          </div>
        </div>
        {!(typeof user.member_type_name === "undefined" || this.state.allowProfileAccessToMembers) &&
          <MembershipInfo
            user={user}
            policyRes={this.props.policyRes}
            expiryDate={this.props.expiryDate}
            renewHandler={this.renewHandler}
          />
        }

        {canEditPublicSetting && (
          <AccountInfo user={user} />
        )}

        {this.props.policyRes.can_add_or_delete_label && (
          <div className="card mt-3 friends-sidebar">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="font-weight-bold tags-margin">Labels</div>
                </div>
                <hr />
                <div className="pl-3 pr-4">
                  <Input
                    {...this.state.tagsInput}
                    changed={this.inputChangedHandler}
                    chart={this.state.tagCharacters}
                    onInputChange={(event) =>
                      this.setState({ tagCharacters: event })
                    }
                  />
                  <div className="pb-3">
                    <div className="row">
                      <div className="col-12">{tags}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!this.state.allowProfileAccessToMembers && (
          <div className="card mt-3">
            <div className="border-bottom pl-3 mt-3">
              <p className="font-weight-bold">Qualifications</p>
            </div>
            <div className="px-3 py-3">
              <OverlayTrigger
                key={isMobile ? "top" : "right"}
                placement={isMobile ? "top" : "right"}
                overlay={educationPopover}
              >
                <div className="row no-gutters cursor">
                  <div className="col-11">Education & Associations</div>
                  <div className="col-1 text-gray-200">
                    <i className="fas fa-info-circle" />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
            <div className="px-3 py-3">
              <OverlayTrigger
                key={isMobile ? "top" : "right"}
                placement={isMobile ? "top" : "right"}
                overlay={credPopover}
              >
                <div className="row no-gutters cursor">
                  <div className="col-11">Accreditation status</div>
                  <div className="col-1 text-gray-200">
                    <i className="fas fa-info-circle" />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
            <div className="px-3 py-3">
              <OverlayTrigger
                key={isMobile ? "top" : "right"}
                placement={isMobile ? "top" : "right"}
                overlay={responsibilitiesPopover}
              >
                <div className="row no-gutters cursor">
                  <div className="col-11">Right & Responsibilities</div>
                  <div className="col-1 text-gray-200">
                    <i className="fas fa-info-circle" />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        )}
        {isAdmin && notes && (
          <div className="card mt-3">
            <div className="mt-3">
              <div className="border-bottom pl-3">
                <p className="font-weight-bold">Notes</p>
              </div>
              {notes}
            </div>
          </div>
        )}
        {!this.state.allowProfileAccessToMembers && (
          <div className="card mt-3">
            <div className="mt-3">
              <div className="border-bottom pl-3">
                <p className="font-weight-bold">Custom Fields</p>
              </div>
              {customFields}
            </div>
          </div>
        )}
        <Modal
          title="New Expiration Date"
          hide={() => this.setState({ showModal: false })}
          show={this.state.showModal}
          style={{ top: "15%" }}
          flag={true}
        >
          <UpdateExpiryDate
            renew={this.renew}
            user={user}
            id={this.props.userId}
            expiryDate={this.state.expiryDate}
            closeModal={() => this.setState({ showModal: false })}
          />
        </Modal>
        <Modal
          title="Invitation"
          show={this.state.showInvitationModal}
          hide={() => this.setState({ showInvitationModal: false })}
          style={{ top: "15%" }}
          flag={true}
        >
          <Invitation
            userId={user.id}
            name={user.email}
            updateInvitation={() => this.componentDidMount()}
            closeModal={() => this.setState({ showInvitationModal: false })}
          />
        </Modal>
        <Modal
          title="Edit Photo"
          show={this.state.editPhotoModal}
          flag={true}
          hide={() => this.setState({ editPhotoModal: false })}
        >
          <EditPhoto
            updatePhoto={this.updatePhoto}
            login={user.login}
            userId={user.id}
            closeModal={() => this.setState({ editPhotoModal: false })}
            disableRemovePhoto={!user.photo}
          />
        </Modal>
        <Modal
          title="Notes"
          show={this.state.notesModal}
          closeModal={() => this.setState({ notesModal: false })}
          flag={true}
          hide={() => this.setState({ notesModal: false })}
          closeButton
        >
          <div
            className="mt-4 text-left"
            style={{
              overflowY: "scroll",
              maxHeight: "250px",
              scrollbarWidth: "thin",
            }}
          >
            <span>{modalNotes}</span>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}
export default Sidebar
