import React, { Component } from "react";
import dateFns from "date-fns";
import { toast } from "react-toastify";
import { title } from "change-case";

import DatePicker from "../UI/datePicker";
import ModalSpinner from "../UI/ModalSpinner";
import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import Button from "../UI/button/Button";
import axios from "../../configAxios";

import "./Donor.css"

class CreateorEditDonation extends Component {
  signal = axios.CancelToken.source();
  timeOut = 0;
  state = {
    newDonation: {
      elementType: "select",
      elementConfig: {
        name: "newDonation",
        isLoading: true,
        placeholder: "Select Campaign",
        options: [],
        required: true,
        hide: false,
        components: {
          IndicatorSeparator: () => null
        }
      },
      selectedOption: null
    },
    paymountAmount: {
      elementType: "input",
      elementConfig: {
        name: "paymountAmount",
        required: true,
        type: "number",
        min: 0,
        max: 99999999,
        step: "0.01"
      },
      value: ""
    },
    recognition: {
      elementType: "select",
      elementConfig: {
        name: "Recognition",
        isLoading: true,
        placeholder: "Select Option",
        options: [],
        hide: false,
        required: true,
        components: {
          IndicatorSeparator: () => null
        }
      }
    },
    notes: {
      elementType: "textarea",
      elementConfig: {
        name: "notes",
        rows: "6"
      },
      value: ""
    },
    donorId: {
      elementType: "asyncSelect",
      elementConfig: {
        name: "donorId",
        placeholder: "Select Donor",
        inputLength: 2,
        required: true,
        loadOptions: (inputValue, callback) => {
          if (inputValue.length >= 2) this.searchDonor(inputValue, callback);
        },
        components: {
          IndicatorSeparator: () => null
        }
      },
      selectedOption: null
    },
    date: new Date(),
    lastContact: new Date(),
    taxStatus: null,
    thankedStatus: null,
    loading: null,
    disabled: false,
    userEnteredCharacter: ""
  };


  componentDidMount() {
    this.setState({
      newDonation: {
        ...this.state.newDonation,
        elementConfig: {
          ...this.state.newDonation.elementConfig,
          options: this.props.donationCampaignOptions,
          isLoading: false
        }
      },
      recognition: {
        ...this.state.recognition,
        elementConfig: {
          ...this.state.recognition.elementConfig,
          options: this.props.recognitionOptions,
          isLoading: false
        }
      }
    });
    if (this.props.editDonation)
      this.getDonationDetails();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getDonationDetails = () => {
    this.setState({ loading: true });
    axios
      .get(`/donations/${this.props.donorId}`, {
        cancelToken: this.signal.token
      })
      .then(res => {
        let donation = res.data.json.data;
        let selectedOption = this.props.donationCampaignOptions
          .filter(campaign => campaign.value === donation.donation_campaign_id)[0]
        let findRecognition = this.props.recognitionOptions
          .map(option => option.label)
          .includes(donation.recognition)

        this.setState({
          newDonation: {
            ...this.state.newDonation,
            selectedOption
          },
          recognition: {
            ...this.state.recognition,
            selectedOption: donation.recognition && findRecognition
              ? { label: donation.recognition, value: donation.recognition }
              : null
          },
          paymountAmount: {
            ...this.state.paymountAmount,
            value: donation.amount
          },
          notes: {
            ...this.state.notes,
            value: donation.notes ? donation.notes : ""
          },
          taxStatus: donation.requires_tax_receipt,
          thankedStatus: donation.has_thanked,
          date: donation.donation_date ? new Date(donation.donation_date) : new Date(),
          lastContact: donation.donor.last_contact
            ? new Date(donation.donor.last_contact)
            : new Date(),
          loading: false
        });
      })
  }

  searchDonor = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue,
      },
      cancelToken: this.signal.token
    }
    if (this.timeOut) clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.getDonors(queryParams, callback);
    }, 300)
  }

  getDonors = (queryParams, callback) => {
    axios.get("/users/autocomplete", queryParams)
      .then(res => {
        callback(
          res.data.users.map(user => ({
            label: user.full_name,
            value: user.id
          }))
        )
      })
  }

  inputChangeHandler = (event, type, key, value) => {
    const inputValue = {};
    if (type === "radio") {
      this.setState({ [key]: value })
    }
    else {
      if (type === "select") {
        inputValue["selectedOption"] = event;
      } else {
        inputValue["value"] = event.target.value;
      }
      this.setState({
        [key]: {
          ...this.state[key],
          ...inputValue
        }
      });
    }
  }

  submitHandler = event => {
    event.preventDefault();
    let formData = new FormData();
    this.setState({ disabled: true });
    const donorData = {
      user_id: this.props.fromDonationPage
        ? this.state.donorId.selectedOption.value
        : this.props.userId,
      donation_campaign_id: this.state.newDonation.selectedOption.value,
      recognition: this.state.recognition.selectedOption && this.state.recognition.selectedOption.value
        ? this.state.recognition.selectedOption.value
        : null,
      requires_tax_receipt: this.state.taxStatus,
      has_thanked: this.state.thankedStatus,
      amount: this.state.paymountAmount.value,
      donation_date: dateFns.format(new Date(this.state.date), "DD-MM-YYYY"),
      last_contact: dateFns.format(new Date(this.state.lastContact), "DD-MM-YYYY"),
      notes: this.state.notes.value
    };

    for (let key in donorData) {
      if ((donorData[key] !== null && donorData[key] !== undefined)
        || (this.props.editDonation && key === "recognition"))
        formData.append(key, donorData[key]);
    }

    if (this.props.editDonation) {
      axios.put(`/donations/${this.props.donorId}`, formData).then(res => {
        if (res.data.json.success) {
          toast.success("Donor details updated successfully");
          this.props.getDonationsList();
          this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(`${title(error)} ${res.data.json.errors[error]}`);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    } else {
      if (!this.props.fromDonationPage)
        this.props.setListLoading();
      axios.post("/donations", formData).then(res => {
        if (res.data.json.success) {
          toast.success("Donor details added successfully");
          this.resetFormHelper();
          this.props.getDonationsList();
          if (this.props.fromDonationPage)
            this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(`${title(error)} ${res.data.json.errors[error]}`);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    }
  };

  resetFormHelper = () => {
    this.setState({
      newDonation: {
        ...this.state.newDonation,
        selectedOption: null
      },
      paymountAmount: {
        ...this.state.paymountAmount,
        value: ""
      },
      recognition: {
        ...this.state.recognition,
        selectedOption: null
      },
      notes: {
        ...this.state.notes,
        value: ""
      },
      date: new Date(),
      lastContact: new Date(),
      taxStatus: null,
      thankedStatus: null,
      disabled: false
    });
  }

  render() {
    return (
      <div className="donor">
        {this.state.loading ? (
          <ModalSpinner />
        ) : (
            <form onSubmit={this.submitHandler}>
              <div className="row mt-4">
                <div className="col-lg-6">
                  <label>New Donation</label>
                  <Input
                    {...this.state.newDonation}
                    className="input-style"
                    changed={event => this.inputChangeHandler(event, "select", "newDonation")}
                  />
                </div>
                <div className="col-lg-6">
                  <label>Donation Amount</label>
                  <Input
                    {...this.state.paymountAmount}
                    changed={event =>
                      this.inputChangeHandler(event, "text", "paymountAmount")
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label>Date</label>
                    <i className="fa fa-calendar calender-icon" />
                    <DatePicker
                      selected={this.state.date}
                      changed={date => this.setState({ date })}
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label>Recognition</label>
                  <Input
                    {...this.state.recognition}
                    changed={event => this.inputChangeHandler(event, "select", "recognition")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <div>
                    <label>Last Contact</label>
                    <i className="fa fa-calendar calender-icon" />
                    <DatePicker
                      selected={this.state.lastContact}
                      changed={date => this.setState({ lastContact: date })}
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                </div>
                {this.props.fromDonationPage && (
                  <div className="col-lg-6">
                    <label>Donor</label>
                    <Input
                      {...this.state.donorId}
                      chart={this.state.userEnteredCharacter}
                      onInputChange={e => this.setState({
                        userEnteredCharacter: e
                      })}
                      changed={event => this.inputChangeHandler(event, "select", "donorId")}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <p className="mb-2">Tax Receipt</p>
                  <input
                    className="cursor"
                    type="radio"
                    checked={this.state.taxStatus !== null && this.state.taxStatus}
                    name="taxStatus"
                    onClick={event => this.inputChangeHandler(event, "radio", "taxStatus", true)}
                  />
                  <label
                    onClick={event => this.inputChangeHandler(event, "radio", "taxStatus", true)}
                    className='ml-2 cursor'
                  >
                    Yes
                  </label>
                  <input
                    className="cursor ml-2"
                    type="radio"
                    checked={this.state.taxStatus !== null && !this.state.taxStatus}
                    name="taxStatus"
                    onClick={event => this.inputChangeHandler(event, "radio", "taxStatus", false)}
                  />
                  <label
                    className="ml-2 cursor"
                    onClick={event => this.inputChangeHandler(event, "radio", "taxStatus", false)}
                  >
                    No
                  </label>
                </div>
                <div className="col-lg-6">
                  <p className="mb-2">Thanked</p>
                  <input
                    className="cursor"
                    type="radio"
                    checked={this.state.thankedStatus !== null && this.state.thankedStatus}
                    name="thankedStatus"
                    onClick={event => this.inputChangeHandler(event, "radio", "thankedStatus", true)}
                  />
                  <label
                    onClick={event => this.inputChangeHandler(event, "radio", "thankedStatus", true)}
                    className='ml-2 cursor'
                  >
                    Yes
                  </label>
                  <input
                    className="cursor ml-2"
                    type="radio"
                    checked={this.state.thankedStatus !== null && !this.state.thankedStatus}
                    name="thankedStatus"
                    onClick={event => this.inputChangeHandler(event, "radio", "thankedStatus", false)}
                  />
                  <label
                    onClick={event => this.inputChangeHandler(event, "radio", "thankedStatus", false)}
                    className="ml-2 cursor"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="mt-2">Notes: </label>
                  <Input
                    {...this.state.notes}
                    changed={event => this.inputChangeHandler(event, "text", "notes")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <Button
                    type="secondary"
                    clicked={
                      this.props.editDonation || this.props.fromDonationPage
                        ? this.props.closeModal
                        : this.resetFormHelper
                    }
                  >
                    Cancel
                  </Button>
                </div>
                <div className="ml-3">
                  <FormButton
                    type="primary"
                    disabled={this.state.disabled}
                    style={{ cursor: this.state.disabled ? "no-drop" : "pointer" }}
                  >
                    {this.props.editDonation ? "Save" : "Submit"}
                  </FormButton>
                </div>
              </div>
            </form>)}
      </div>
    )
  }
}

export default CreateorEditDonation;
