import React, { Component } from "react";
import Tab from "../../formTabs";
import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";
import General from "./General";
import Details from "./Details";
import Financial from "./Financial";
import "../createAsset/index.css";

class EditAsset extends Component {
  signal = axios.CancelToken.source();
  state = {
    tabs: ["General", "Details", "Financial"],
    activeTab: "General",
    generalFormData: null,
    accountingCodes: null,
    assetPriceTypes: null,
    assetDetail: null,
    thirdPartyOwners: null,
    loading: true,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    let assetRes = await axios
      .get(`/assets/${this.props.match.params.id}`, {
        cancelToken: this.signal.token,
      })
      .catch((err) =>
        this.setState({
          loading: false,
          assetDetail: null,
        })
      );
    let formDataRes = await axios.get("/assets/formdata", {
      cancelToken: this.signal.token,
    });
    this.setState({
      assetDetail: assetRes && assetRes.data.asset_detail,
      generalFormData: {
        status: formDataRes.data.json.status,
        categories: formDataRes.data.json.categories,
        locations: formDataRes.data.json.locations,
        creds: formDataRes.data.json.creds,
      },
      thirdPartyOwners: formDataRes.data.json.third_party_owners,
      accountingCodes: formDataRes.data.json.accounting_codes,
      assetPriceTypes: formDataRes.data.json.asset_price_types,
      loading: false,
    });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  tabChangedHandler = (activeTab) => {
    this.setState({ activeTab });
  };

  render() {
    const { assetDetail } = this.state;
    return (
      <div
        className="container mt-3 create-asset w-100"
        style={{
          paddingBottom: 60,
        }}
        key={this.state.activeTab}
      >
        <div className="row mb-4">
          <div className="col-6">
            <h4 className="word-wrap-break">
              {!this.state.loading && assetDetail && assetDetail.name}
            </h4>
          </div>
          <div className="col-6 text-right">
            <span
              className="text-right mt-3 cursor"
              onClick={() => this.props.history.goBack()}
            >
              {"<< Back"}
            </span>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div>
                {!this.state.loading && assetDetail && (
                  <Tab
                    tabs={this.state.tabs}
                    activeTab={this.state.activeTab}
                    tabChangedHandler={this.tabChangedHandler}
                    hrMargin={249}
                  />
                )}
              </div>
              {this.state.loading ? (
                <Spinner />
              ) : (
                <div>
                  {this.state.assetDetail && (
                    <div className="col-md-12 py-4">
                      {this.state.activeTab === "General" && (
                        <General
                          formData={this.state.generalFormData}
                          asset={assetDetail}
                          thirdPartyOwners={this.state.thirdPartyOwners}
                        />
                      )}
                      {this.state.activeTab === "Details" && (
                        <Details asset={assetDetail} />
                      )}
                      {this.state.activeTab === "Financial" && (
                        <Financial
                          asset={assetDetail}
                          accountingCodes={this.state.accountingCodes}
                          assetPriceTypes={this.state.assetPriceTypes}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAsset;
