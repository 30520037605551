import React from "react";
import CenteredContainer from "../UI/container/CenteredContainer";
import Spinner from "../../assests/images/spinner.gif";

const spinner = props => (
  <CenteredContainer style={props.style} className={props.className}>
    <img
      src={Spinner}
      style={{ width: "200px", margin: "auto", display: "block" }}
      alt="Loading..."
    />
  </CenteredContainer>
);

export default spinner;
