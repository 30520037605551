import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { toast } from "react-toastify";
import { title } from "change-case";
import _ from "lodash";

import Spinner from "../../UI/Spinner";
import Modal from "../../UI/Modal";
import axios from "../../../configAxios";

import NewFlagType from "./NewFlagType.js";

import "./FlagTypes.css";

class FlagTypes extends Component {
  signal = axios.CancelToken.source();
  state = {
    flagTypeList: [],
    newModal: false,
    viewModal: false,
    editModal: false,
    loading: true,
    edit: false,
    id: null
  };

  componentDidMount() {
    this.getFlagTypes();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  newModal = () => {
    window.scrollTo(0, 0);
    this.setState({ newModal: true, edit: false });
  };

  getFlagTypes = () => {
    axios.get("/flag_types", { cancelToken: this.signal.token }).then(res => {
      this.setState({ flagTypeList: res.data.flag_types, loading: false });
    });
  };

  deleteHandler = (name, id) => {
    let response = window.confirm(`Are you sure you want to delete ${name}?`);
    if (response) {
      axios.delete(`/flag_types/${id}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success(`${name} successfully deleted.`);
            this.getFlagTypes();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              if (_.isEmpty(res.data.json.errors))
                toast.error(`Failed to delete ${name}`);
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  };

  editModal = id => {
    window.scrollTo(0, 0);
    this.setState({ edit: true, newModal: true, id: id });
  };

  render() {
    const flagTypes = this.state.flagTypeList;
    let flagsList;
    if (this.state.loading) {
      flagsList = <Spinner />;
    } else {
      flagsList = flagTypes.map(flagType => (
        <React.Fragment key={flagType.id}>
          <li className="list-group-item rounded-0 border-top">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div>
                  <Link
                    to={`/flags?flag_type_to_list=${flagType.id}&flagTypeName=${flagType.name}`}
                    className="font-weight-bold text-info"
                  >
                    {flagType.name}
                  </Link>
                </div>
                <div>
                  {flagType.flags_count || 0} flags with this Flag Type
                  </div>
              </div>
              <div className="col-lg-2">
                <i className={classnames(
                    "fas fa-check-circle",
                    {
                      "text-info": flagType.visible,
                      "text-secondary element-disabled": !flagType.visible,
                    }
                  )}
                />
              </div>
              <div className="col-lg-2">
                <div className="d-flex justify-content-around align-items-center px-3">
                  <i
                    className={classnames(
                      "fas fa-pencil-alt cursor mr-2",
                      {
                        "cursor": flagType.can_delete,
                        "cursor-not-allowed element-disabled": !flagType.can_delete
                      }
                    )}
                    {...flagType.can_delete && {
                      onClick: () => this.editModal(flagType.id)
                    }}
                  />
                  <i
                    className={classnames(
                      "fas fa-trash ml-3",
                      {
                        "cursor": flagType.can_delete,
                        "cursor-not-allowed element-disabled": !flagType.can_delete
                      }
                    )}
                    {...flagType.can_delete && {
                      onClick: () => this.deleteHandler(flagType.name, flagType.id)
                    }}
                  />
                </div>
              </div>
            </div>
          </li>
        </React.Fragment>
      ));
    }
    return (
      <div className="flagtypes">
        <div className="p-3">
          <div className="row border-bottom">
            <div className="col-lg-6">
              <h5 className="font-weight-bold">Flag Types</h5>
            </div>
            <div className="col-lg-6 text-right">
              <p className="text-primary cursor" onClick={this.newModal}>
                Create New Flag Type
              </p>
            </div>
          </div>

          <div className="row my-3">
            <div className="col-lg-12">
              Note: There are System Flags that the system will not
              allow you to delete. You also cannot delete any Flag Types that
              currently have Flags associated with them. The Visibility settings
              refer to whether the Flags associated with the Flag Type should be
              visible to the member profile or not.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <ul className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="row">
                  <div className="col-lg-8">
                    <span className="font-weight-bold text-dark">Flag Type Name</span>
                  </div>
                  <div className="col-lg-2">
                    <span className="font-weight-bold text-dark">Visible?</span>
                  </div>
                </div>
              </div>
              {flagsList}
            </ul>
          </div>
        </div>

        <Modal
          show={this.state.newModal}
          title={this.state.edit ? "Edit Flag Type" : "New Flag Type"}
          hide={() => this.setState({ newModal: false })}
          flag={true}
        >
          <NewFlagType
            closeModal={() => this.setState({ newModal: false })}
            getFlagTypes={this.getFlagTypes}
            edit={this.state.edit}
            editModal={this.editModal}
            id={this.state.id}
          />
        </Modal>
      </div>
    );
  }
}

export default FlagTypes;
