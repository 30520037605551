import React, { Component } from "react";
import Spinner from "../UI/Spinner";
import "./EducationAndAccreditations.css";

class EducationAndAccreditations extends Component {
  render() {
    return (
      <div className="mb-2 edu-accr">
        <button
          className="btn header-btn"
          onClick={() => this.props.tabHandler("educationAndAccreditations")}
          type="button"
          data-toggle="collapse"
          data-target=".educationAndAccreditations"
          aria-expanded="false"
          aria-controls="multiCollapseExample1 multiCollapseExample2"
        >
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Education And Associations</p>
            <i
              className={
                this.props.selectedTabs.includes("educationAndAccreditations")
                  ? "fas fa-minus-circle fa-2x bg-muted text-primary"
                  : "fas fa-plus-circle fa-2x bg-muted text-primary"
              }
            />
          </div>
        </button>
        <div className="row">
          <div className="col-12">
            <div
              className="collapse educationAndAccreditations mx-2 mt-3"
              id="multiCollapseExample1"
            >

              <div className="row ml-3">
                {
                  this.props.loading ? (
                    <Spinner
                      style={{
                        minHeight: "5vh"
                      }}
                    />
                  ) : this.props.educationAndAccreditationsArray
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EducationAndAccreditations;
