import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";
import axios from "../../../configAxios";

import Users from "./users/index";
import Members from "./members/index";
import Accreditations from "./accreditations/index";
import AddOrEditUser from "./users/AddOrEditUser";
import Alert from "./users/Alert";
import Settings from "./users/Settings";

class UsersAndMembers extends Component {
  signal = axios.CancelToken.source();
  state = {
    activeTab: "Users",
    edit: false,
    id: null
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
    this.signal.cancel("Request is being Cancelled");
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : "Users"
    });
  };

  tabChangeHandler = (activeTab, action, id) => {
    this.setState({
      ...this.state,
      activeTab,
      edit: action === "edit",
      id
    });
    this.props.history.push(
      `/system_settings/users/${snakeCase(activeTab)}`
    );
  };

  getUsers = () => {
    axios
      .get("/users", {
        params: {
          type: "tab",
          role_level: this.props.userType
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({ usersList: res.data.users, loading: false });
      });
  };

  render() {
    const tabNames = ["Users", "Member Types", "Accreditations", "Alerts", "Settings"];
    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${this.state.activeTab === tab ? "active-tab " : "inactive-tab"}`}
      >
        {tab}
      </li>
    ));
    let activeTab;
    switch (this.state.activeTab) {
      case "Users":
        activeTab = (
          <Users
            addOrEditForm={this.addOrEditForm}
            editForm={this.editForm}
            id={this.state.id}
            getUsers={this.getUsers}
            tabChangeHandler={this.tabChangeHandler}
          />
        );
        break;
      case "Member Types":
        activeTab = <Members />;
        break;
      case "Accreditations":
        activeTab = <Accreditations />
        break;
      case "Add Or Edit Form":
        activeTab = (
          <AddOrEditUser
            edit={this.state.edit}
            id={this.state.id}
            toggleForm={this.toggleForm}
            tabChangeHandler={this.tabChangeHandler}
          />
        )
        break;
      case "Alerts":
        activeTab = <Alert />
        break;
      case "Settings":
        activeTab = <Settings />
        break;
      default:
        activeTab = null;
    }
    return (
      <div className="card users-and-members">
        {
          this.state.activeTab !== "Add Or Edit Form" ? (
            <ul className="nav nav-tabs font-weight-bold ml-0">
              {tabs}
            </ul>
          ) : ''
        }
        {activeTab}
      </div>
    );
  }
}

export default withRouter(UsersAndMembers);
