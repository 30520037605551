import React, { Component } from "react"
import { title } from "change-case"
import { toast } from "react-toastify"
import axios from "../../configAxios"
import Input from "../UI/input/Input"
import Button from "../UI/button/Button"

class FavaBucks extends Component {
  state = {
    newBalance: {
      elementType: "input",
      elementConfig: {
        name: "newBalance",
        placeholder: "",
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      value: this.props.favaBalance,
    },
    disabled: false,
  }

  closeModal = () => {
    this.props.closeModal()
  }

  inputChangedHandler = (event) => {
    const newBalance = { ...this.state.newBalance }
    newBalance.value = event.target.value
    this.setState({ newBalance: newBalance })
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const queryParams = {
      id: this.props.accountId,
      available_credits: parseFloat(this.state.newBalance.value),
    }
    axios
      .put("/accounts/update_filmreel_buck_balance", queryParams)
      .then((res) => {
        if (res.data.json.success) {
          this.props.closeModal()
          const organization = localStorage.getItem("organizationSubDomain")
          toast.success(`${title(organization)} Bucks Adjusted Successfully`)
          this.props.updateFavaBucks()
        } else {
          if ("errors" in res.data.json) {
            Object.keys(res.data.json.errors).forEach((key) => {
              toast.error(title(key) + " " + res.data.json.errors[key])
            })
          }
          if ("error" in res.data.json) {
            toast.error(res.data.json.error)
          }
          this.setState({ disabled: false })
        }
      })
      .catch((err) => err)
  }

  render() {
    return (
      <div className="mt-4">
        <p>New Balance</p>
        <Input
          {...this.state.newBalance}
          changed={(event) => this.inputChangedHandler(event)}
        />
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            className="float-left mr-3"
            clicked={this.closeModal}
          >
            Cancel
          </Button>
          <Button
            type="warning"
            className="float-left mr-3"
            clicked={this.submitHandler}
            disabled={this.state.disabled}
          >
            Submit
          </Button>
        </div>
      </div>
    )
  }
}
export default FavaBucks
