import React, { useEffect, useState } from "react"
import axios from "../../../configAxios"
import Select from "react-select"
import { sortBy } from "lodash"

const LabelCategoryDropdown = ({ categoryId, labelCategorySelectHandler }) => {
  const [labelCategoryOptions, setLabelCategoryOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)

  useEffect(() => {
    axios.get("label_categories")
      .then((res) => {
        const labelCategoriesList = res.data.json.label_category.map(category => {
          return { label: category.name, value: category.id, order: category.order }
        })
        const orderedCategories = sortBy(labelCategoriesList, 'order')
        setLabelCategoryOptions(orderedCategories)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (!categoryId) setSelectedOption(null)
  }, [categoryId])

  return (
    <>
      <Select
        options={labelCategoryOptions}
        value={selectedOption}
        onChange={(selected) => {
          setSelectedOption(selected)
          labelCategorySelectHandler(selected.value)
        }}
        placeholder="Select Category"
      />
    </>
  )
}

export default LabelCategoryDropdown
