import React from "react"
import Email from "./Email"
import FullName from "./FullName"
import PhoneNumber from "./PhoneNumber"
import Pronouns from "./Pronouns"
import SocialMedia from "./SocialMedia"
import Website from "./Website"

const UserInfo = (props) => {
  const user = props.user
  const socials = props.user.data

  return (
    <>
      <FullName user={user} />
      <Pronouns user={user} />
      <PhoneNumber user={user} />
      <Email user={user} />
      <Website user={user} />
      <SocialMedia socials={socials} />
    </>
  )
}

export default UserInfo
