import React, { useState, useEffect } from 'react'
import axios from '../../../../configAxios'
import ApplicationOverview from './applicationOverview/ApplicationOverview'
import { Route, Switch, withRouter} from 'react-router-dom'
import ApplicationsIndexBody from './ApplicationsIndexBody'

function ApplicationsIndex({ grantCampaign, isAdmin, match }) {
  
  const [applicationsList, setApplicationsList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getApplications = () => {
    setIsLoading(true)
    axios.get(`/grant_campaigns/${grantCampaign.id}/grant_applications`)
    .then(res => {
      setIsLoading(false)
      setApplicationsList(res.data.json.data.grant_applications)
    })
  }

  useEffect(() => {
    grantCampaign && applicationsList.length === 0 && getApplications()
  }, [])

  return (
    <div className="container w-100 p-0">
      {grantCampaign && applicationsList &&
      <Switch>
        <Route
          path={match.path + "/show/:application_id"} 
          exact
          render={routeProps => 
            <ApplicationOverview
              {...routeProps}
              grantCampaign={grantCampaign}
              isAdmin={isAdmin}
            />
          }
        /> 
        {/* TODO: Reorganize the routes so /show above isn't required */}
        <Route
          path="/campaigns/:campaign_id/applications"
          render={routeProps => {
            return <ApplicationsIndexBody grantCampaign={grantCampaign} applicationsList={applicationsList} isLoading={isLoading} {...routeProps}/>
            }}
        />
      </Switch>
}
    </div>
  )
}

export default withRouter(ApplicationsIndex)
