import * as actionTypes from "../actions/actionTypes";

const initialState = {
  localize: false
};

const organizationReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.GET_ORGANIZATION_SETTINGS:
      return {
        ...state,
        localize: action.localize
      }
    case actionTypes.UPDATE_ORGANIZATION_SETTINGS:
      return {
        ...state,
        localize: action.localize
      }
    default:
      return state
  }
}

export default organizationReducer
