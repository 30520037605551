import React, { Component } from "react"
import "./index.css"

class HelcimForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: "",
      cardExpiryMonth: "",
      cardExpiryYear: "",
      cardCVV: "",
      cardHolderName: "",
      cardHolderAddress: "",
      cardHolderPostalCode: "",
      amount: this.props.totalOutStanding ? this.props.totalOutStanding : ""
    };
  }

  inputChangedHandler = e => {
    const key = e.target.id;
    this.setState({ [key]: e.target.value });
  };

  renderInputField = (key, label, modifyTotalAmount= false) => {
    return (
      <>
        {label}
        <input
          type="text"
          id={key}
          className="form-control"
          value={this.state[key]}
          onChange={this.inputChangedHandler}
          disabled={modifyTotalAmount}
        />
      </>
    );
  };

  useTestMode = () => {
    /*
    To enable testing mode on Helcim, one step includes adding the test mode HTML tag. This method lives here to enable
    test mode on the demo instance allowing us to test transactions on production easily. Security around "test mode" is
    still functioning - tests are only possible on the demo subdomain with it's associated credentials, and the back end
    relies on strong message types. If a 'test mode' request is made with any other organization, it will fail on the
    Helcim side, and the AMS API will respond accordingly
    */
    const orgId = localStorage.getItem("organizationId");
    const demoId = "242"
    return orgId === demoId
  }

  render() {
    return (
      <div className="helcim-payment d-flex justify-content-center">
        <div className="payment-container">
          <div className="amount-due">{`Amount Due ${
            this.props.totalOutStanding
          }`}</div>
          <form name="helcimForm" id="helcimForm">
            <div className="col-md-12">
              {" "}
              <div id="helcimResults" className="text-danger" />
            </div>
            <input type="hidden" id="token" value={this.props.helcimToken} />
            <input type="hidden" id="orderNumber" value={this.props.reference} />
            <input type="hidden" id="language" value="en" />

            { this.useTestMode() &&
            <input type="hidden" id="test" value="1" />
            }

            <div className="col-md-12">
              {this.renderInputField("cardNumber", "Credit Card Number")}
            </div>
            <div className="row px-3 mt-3">
              <div className="col-md-4">
                {this.renderInputField("cardExpiryMonth", "Expiry Month")}
              </div>
              <div className="col-md-4">
                {this.renderInputField("cardExpiryYear", "Expiry Year")}
              </div>
              <div className="col-md-4">
                {this.renderInputField("cardCVV", "CVV")}
              </div>
            </div>
            <br />
            <div className="col-md-12">
              {this.renderInputField("cardHolderName", "Card Holder Name")}
            </div>
            <div className="col-md-12 mt-3">
              {this.renderInputField(
                "cardHolderAddress",
                "Card Holder Address"
              )}
            </div>
            <div className="col-md-12 mt-3">
              {this.renderInputField(
                "cardHolderPostalCode",
                " Card Holder Postal Code"
              )}
            </div>
            <div className="col-md-12 mt-3 mb-5">
              {this.renderInputField("amount", "Total Amount", !this.props.canModifyTotalAmount)}
            </div>
            <div className="col-md-12 mt-4 d-flex justify-content-center">
              <input
                type="button"
                className="btn btn-primary col-md-12"
                id="buttonProcess"
                value="Process Payment"
                onClick={() => window.helcimProcess()}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default HelcimForm;
