import React, { useState } from "react";
import Input from "../../UI/input/Input";

const InvoiceDetailEditor = (props) => {
  const [detail, setDetail] = useState(props.detail);
  const config = { elementConfig: { type: "number", step: ".01" } };
  return (
    <Input elementType="input" value={detail} changed={e => { setDetail(e.target.value); props.editDetailHandler(e.target.value) }} {...props.type === "amount" && config} />
  )
}

export default InvoiceDetailEditor;