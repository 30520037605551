import React, { Component } from "react";
import axios from "../../../../configAxios";
import Button from "../../../UI/button/Button";
import { OverlayTrigger, Popover } from "react-bootstrap";
import FormButton from "../../../UI/button/FormButton";
import { title } from "change-case";
import { toast } from "react-toastify";
import Input from "../../../UI/input/Input";
import ModalSpinner from "../../../UI/ModalSpinner";
import Modal from "../../../UI/Modal";
import UserList from "./UserList";
import ToggleSwitch from "../../../common/toggleSwitch"
import _ from "lodash";


class AddNewMember extends Component {
  signal = axios.CancelToken.source();
  state = {
    categoryNames: [],
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true
      },
      label: "Name",
      value: ""
    },
    description: {
      elementType: "textarea",
      elementConfig: {
        name: "description",
        row: "4",
        required: true
      },
      label: "Description",
      value: ""
    },
    membershipPrice: {
      elementType: "input",
      elementConfig: {
        name: "membershipPrice",
        type: "number",
        min: 0,
        step: 0.01,
      },
      label: "Membership Dues / Year",
      value: ""
    },
    userData: null,
    basicInfo: true,
    doesNotExpire: false,
    disabled: false,
    updateMembershipPrice: false,
    loading: true,
    openModal: false,
    verify: false,
    isPublicToggle: false,
  };

  componentDidMount() {
    if (this.props.edit) {
      axios
        .get("/member_types/" + this.props.id, {
          cancelToken: this.signal.token
        })
        .then(res => {
          const { member_type } = res.data;
          this.setState({
            name: {
              ...this.state.name,
              value: member_type.name
            },
            description: {
              ...this.state.description,
              value: member_type.description
            },
            membershipPrice: {
              ...this.state.membershipPrice,
              value: member_type.membership_price
            },
            basicInfo: member_type.require_basic_info,
            doesNotExpire: member_type.lifetime,
            isPublicToggle: member_type.is_public,
          })
        })
        .catch(err => console.log("Something went wrong"));
    }
    this.getUsers();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getUsers = () => {
    if (this.props.edit && this.props.id) {
      axios
        .get(`/users/member_types/`, {
          params: { id: this.props.id },
          cancelToken: this.signal.token
        })
        .then(res => {
          this.setState({
            userData: res.data.users,
            loading: false
          })
        });
    }
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;

    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = (event, type) => {
    event.preventDefault()
    this.setState({ disabled: true })

    const {
      name, description, basicInfo, doesNotExpire,
      membershipPrice, updateMembershipPrice, isPublicToggle,
    } = this.state

    let memberTypeData = {}
    memberTypeData["name"] = name.value.trim()
    memberTypeData["description"] = description.value
    memberTypeData["require_basic_info"] = basicInfo
    memberTypeData["lifetime"] = doesNotExpire
    memberTypeData["membership_price"] = membershipPrice.value
    memberTypeData["update_membership_price"] = updateMembershipPrice
    memberTypeData["is_public"] = isPublicToggle

    if (this.props.edit) {
      axios
        .put(`/member_types/${this.props.id}`, memberTypeData)
        .then(res => {
          if (res.data.json.success) {
            if (type) {
              toast.success("Member Type updated successfully");
            }
            else {
              if (this.state.doesNotExpire)
                toast.success(`All users under ${name.value} Member Type are lifetime members now`);
              else
                toast.success(`No changes effected on the users of ${name.value} Member Type`);
            }
            this.setState({ openModal: false })
            this.props.closeModal();
            this.props.getMembers();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disabled: false })
        })
        .catch(err => toast.error(err));
    } else {
      axios
        .post("/member_types", memberTypeData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Member has successfully created");
            this.props.closeModal();
            this.props.getMembers();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disabled: false })
        })
        .catch(err => toast.error(err));
    }
  };

  verifyExpireHandler = (event, verify) => {
    this.setState({
      doesNotExpire: verify
    }, () => this.submitHandler(event))
  }

  expireHandler = () => {
    if (this.props.edit) {
      if (!this.state.doesNotExpire && !_.isEmpty(this.state.userData))
        this.setState({ openModal: true })
      else
        this.setState({ doesNotExpire: !this.state.doesNotExpire })
    }
    else {
      this.setState({ doesNotExpire: !this.state.doesNotExpire })
    }
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.openModal ? <form className="mt-3" onSubmit={event => this.submitHandler(event, "update")}>
          {this.props.edit && this.state.loading ? <ModalSpinner />
            : <div>
              <Input
                {...this.state.name}
                changed={event => this.inputChangedHandler(event, "name")}
              />
              <Input
                {...this.state.description}
                style={{ height: 50 }}
                changed={event => this.inputChangedHandler(event, "description")}
              />
              <Input
                {...this.state.membershipPrice}
                changed={event => this.inputChangedHandler(event, "membershipPrice")}
              />
              <div className="d-flex justify-content-between">
                <div>
                  <input
                    type="checkbox"
                    id="requireBasicInfo"
                    checked={this.state.basicInfo}
                    onChange={() =>
                      this.setState({ basicInfo: !this.state.basicInfo })
                    }
                  />
                  {" "}
                  <label htmlFor="requireBasicInfo" className="cursor">Require Basic Info?</label>
                </div>
                <div>
                  {_.isEmpty(this.state.userData) && this.props.edit ?
                    <OverlayTrigger
                      key={"top"}
                      placement={"top"}
                      overlay={<Popover><div className="font-weight-bold">This member type has no user</div></Popover>}>
                      <input
                        type="checkbox"
                        id="doesNotExpire"
                        checked={this.state.doesNotExpire}
                        onClick={this.expireHandler}
                      />
                    </OverlayTrigger>
                    : <input
                      type="checkbox"
                      id="doesNotExpire"
                      checked={this.state.doesNotExpire}
                      onClick={this.expireHandler}
                    />}
                  {" "}
                  <label htmlFor="doesNotExpire" className="cursor">Does not expires</label>
                </div>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="membershipPrice"
                  checked={this.state.updateMembershipPrice}
                  onChange={event => { this.setState({ updateMembershipPrice: !this.state.updateMembershipPrice }) }}
                />
                {" "}
                <label htmlFor="membershipPrice" className="cursor">Update Yearly Membership Dues?</label>
              </div>
              <div className="d-flex mt-3">
                <div className="mr-3">
                  <ToggleSwitch
                    id="toggle"
                    checked={this.state.isPublicToggle}
                    onChange={() => {
                      this.setState((prevState) =>( { ...prevState, isPublicToggle: !prevState.isPublicToggle }))
                    }}
                    customCheckContent="YES"
                    customUncheckContent="NO"
                  />
                </div>
                <label htmlFor="toggle" className="w-100 word-wrap-break">
                  Make this member type public (API)
                </label>
              </div>
              <div className="mt-3 d-flex justify-content-center">
                <Button type="secondary" clicked={this.props.closeModal}>
                  Cancel
                </Button>
                <FormButton className="ml-2" disabled={this.state.disabled}>
                  {this.props.edit ? "Update Member Type" : "Create Member Type"}
                </FormButton>
              </div>
            </div>
          }
        </form>
          : <Modal
            show={this.state.openModal}
            title={"Lifetime Membership Setting"}
            hide={() => {
              this.props.closeModal()
              this.setState({ openModal: false })
            }}
          >
            <UserList
              userData={this.state.userData}
              verifyExpireHandler={this.verifyExpireHandler}
              submitHandler={this.submitHandler}
              closeModal={() => this.setState({ openModal: false })}
            />
          </Modal>}
      </React.Fragment>
    );
  }
}

export default AddNewMember;
