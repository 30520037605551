import React from "react";
import AddOrEditPrint from "./AddOrEditPrint";
import AddOrEditVideo from "./AddOrEditVideo";
import AddOrEditFilmOrAudio from "./AddOrEditFilmOrAudio";

const addNewItem = props => {
  const type = props.type;
  let newItem;
  switch (type) {
    case "print":
      newItem = <AddOrEditPrint type={type} />;
      break;
    case "audio":
      newItem = <AddOrEditFilmOrAudio type={type} />;
      break;
    case "video":
      newItem = <AddOrEditVideo type={type} />;
      break;
    case "film":
      newItem = <AddOrEditFilmOrAudio type={type} />;
      break;
    default:
      newItem = null;
  }
  return newItem;
};

export default addNewItem;
