import React from "react"

const CharacterCounter = ({ data, className, range }) => {
  let counterColor = ""

  if (data.length > range.max || data.length < range.min) counterColor = "text-danger"

  return <span className={`${className || ""} ${counterColor}`}>{data.length}</span>
}

export default CharacterCounter
