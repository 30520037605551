import React, { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import Button from "../UI/button/Button"
import TagModal from "./TagModal"

const CreateLabel = ({ labelCategories, onComplete }) => {
  const [showModal, setShowModal] = useState(false)

  const createLabel = (name, tagCategoryId, definition) => {
    axios
      .post(`/labels`, { name: name, label_category_id: tagCategoryId, tag_type: "program_global", definition: definition })
      .then((res) => {
        toast.success(res.data.json.message)
        onComplete()
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  return (
    <>
    <Button
      type="primary"
      clicked={() => {
        setShowModal(true)
      }}
    >
      Add Label
    </Button>
    <TagModal
      showModal={showModal}
      setShowModal={setShowModal}
      labelCategories={labelCategories}
      clicked={createLabel}
      modalTitle={'Create'}
    />
  </>
  )
}

export default CreateLabel
