import React, { Component } from "react"
import { toast } from "react-toastify"

import Input from "../../UI/input/Input"
import Button from "../../UI/button/Button"
import FormButton from "../../UI/button/FormButton"
import axios from "../../../configAxios"
import errorHandler from "../../errorPages/UserNotAuthorized"
import ToggleSwitch from "../../common/toggleSwitch"
import langUtils from "../../../utils/langUtils"

import AddNewThirdPartyOwner from "./AddNewThirdPartyOwner"

class AddNew extends Component {
  signal = axios.CancelToken.source()
  state = {
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true,
      },
      label: "Name",
      value: "",
    },
    shortName: {
      elementType: "input",
      elementConfig: {
        name: "shortName",
        type: "text",
      },
      label: "Short Name",
      value: "",
    },
    locationName: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true,
      },
      label: "Location name",
      value: "",
    },
    thirdPartyDetails: {},
    disabled: false,
    bookableToggle: false,
    isPublicToggle: false,
  }

  closeModal = () => {
    this.props.closeModal()
  }

  componentDidMount() {
    if (this.props.edit) {
      this.setState({ loading: true })
      axios
        .get(`third_party_owners/${this.props.thirdPartyOwnerId}`, {
          cancelToken: this.signal.token,
        })
        .then((res) => {
          this.setState({
            thirdPartyDetails: res.data.third_party_owner,
            loading: false,
          })
        })
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {}
    inputValue.value = event.target.value

    const updatedcontrols = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState({ [key]: updatedcontrols })
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const categoryData = {}
    const { name, shortName, bookableToggle, isPublicToggle } = this.state
    categoryData.name = name.value
    categoryData.short_name = shortName.value
    categoryData.bookable_by_admin_only = bookableToggle
    categoryData.is_public = isPublicToggle
    axios
      .post("/categories", categoryData)
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Category successfully created")
          this.props.closeModal()
          this.props.getCategories()
        } else {
          errorHandler(res)
        }
        this.setState({ disabled: false })
      })
      .catch((err) => toast.error(err))
  }

  submitLocationHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const locationData = {}
    const { locationName } = this.state
    locationData.name = locationName.value
    axios
      .post("/locations", locationData)
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Location successfully created.")
          this.props.closeModal()
          this.props.getLocations()
        } else {
          errorHandler(res)
        }
        this.setState({ disabled: false })
      })
      .catch((err) => toast.error(err))
  }

  render() {
    const { name, shortName, bookableToggle, isPublicToggle } = this.state
    return (
      <form
        className="mt-3"
        onSubmit={
          this.props.type === "location"
            ? this.submitLocationHandler
            : this.props.type === "category"
            ? this.submitHandler
            : null
        }
      >
        {this.props.type === "location" ? (
          <div>
            <Input
              elementType={name.elementType}
              elementConfig={name.elementConfig}
              label={name.label}
              changed={(event) =>
                this.inputChangedHandler(event, "locationName")
              }
            />
            <div className="text-center">
              <Button type="secondary" clicked={this.props.closeModal}>
                Cancel
              </Button>
              <FormButton className="ml-2" disabled={this.state.disabled}>
                {langUtils("txt_global_add_new", "Add New")}
              </FormButton>
            </div>
          </div>
        ) : this.props.type === "category" ? (
          <div>
            <Input
              elementType={name.elementType}
              elementConfig={name.elementConfig}
              label={name.label}
              changed={(event) => this.inputChangedHandler(event, "name")}
            />
            <Input
              elementType={shortName.elementType}
              elementConfig={shortName.elementConfig}
              label={shortName.label}
              changed={(event) => this.inputChangedHandler(event, "shortName")}
            />
            <div className="d-flex mt-4 mb-3">
              <div className="mr-3">
                <ToggleSwitch
                  id="toggle"
                  checked={bookableToggle}
                  onChange={() => {
                    this.setState((prevState) => ({ ...prevState, bookableToggle: !prevState.bookableToggle}))
                  }}
                  customCheckContent="YES"
                  customUncheckContent="NO"
                />
              </div>
              <label htmlFor="toggle" className="w-100 word-wrap-break">
                Only available for booking by Staff Members
              </label>
            </div>
            <div className="d-flex mt-4 mb-3">
              <div className="mr-3">
                <ToggleSwitch
                  id="toggle"
                  checked={isPublicToggle}
                  onChange={() => {
                    this.setState((prevState) =>( { ...prevState, isPublicToggle: !prevState.isPublicToggle }))
                  }}
                  customCheckContent="YES"
                  customUncheckContent="NO"
                />
              </div>
              <label htmlFor="toggle" className="w-100 word-wrap-break">
                Make this category public (API)
              </label>
            </div>
            <div className="text-center">
              <Button type="secondary" clicked={this.props.closeModal}>
                Cancel
              </Button>
              <FormButton className="ml-2" disabled={this.state.disabled}>
                {langUtils("txt_global_add_new", "Add New")}
              </FormButton>
            </div>
          </div>
        ) : this.props.type === "thirdPartyOwner" ? (
          <AddNewThirdPartyOwner
            inputChangedHandler={this.inputChangedHandler}
            getThirdPartyOwners={this.props.getThirdPartyOwners}
            closeModal={this.closeModal}
            edit={this.props.edit}
            thirdPartyDetails={this.state.thirdPartyDetails}
            loading={this.state.loading}
            migrate={this.props.migrate}
          />
        ) : null}
      </form>
    )
  }
}

export default AddNew
