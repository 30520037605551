import React, { Component } from "react";
import Input from "../UI/input/Input";

class PrimaryEquipmentOperator extends Component {
  state = {
    primaryEquipmentFirstName: "",
    primaryEquipmentLastName: "",
    primaryEquipmentContactInfo: ""
  };

  componentDidMount() {
    if (this.props.editUser) {
      const { formData } = this.props;
      this.setState({
        primaryEquipmentFirstName: formData.primaryEquipmentFirstName,
        primaryEquipmentLastName: formData.primaryEquipmentLastName,
        primaryEquipmentContactInfo: formData.primaryEquipmentContactInfo
      });
    }
  }

  inputChangeHandler = (event, type, key) => {
    this.setState({ [key]: event.target.value });
    this.props.inputChangeHandler(event, type, key);
  };

  render() {
    const firstName = (
      <div className="mt-3">
        <p>First Name</p>
        <Input
          elementType="input"
          value={this.state.primaryEquipmentFirstName}
          changed={event =>
            this.inputChangeHandler(event, "text", "primaryEquipmentFirstName")
          }
        />
      </div>
    );

    const lastName = (
      <div className="mt-3">
        <p>Last Name</p>
        <Input
          elementType="input"
          value={this.state.primaryEquipmentLastName}
          changed={event =>
            this.inputChangeHandler(event, "text", "primaryEquipmentLastName")
          }
        />
      </div>
    );

    const contactInfo = (
      <div className="mt-3">
        <p>Contact Info</p>
        <Input
          elementType="input"
          value={this.state.primaryEquipmentContactInfo}
          changed={event =>
            this.inputChangeHandler(
              event,
              "text",
              "primaryEquipmentContactInfo"
            )
          }
        />
      </div>
    );
    return (
      <div className="mb-2">
        <button
          className="btn"
          onClick={() => this.props.tabHandler("primaryEquipmentOperator")}
          type="button"
          data-toggle="collapse"
          data-target=".primaryEquipmentOperator"
          aria-expanded="false"
          aria-controls="multiCollapseExample1 multiCollapseExample2"
          style={{
            width: "100%",
            backgroundColor: "#f5efef",
            outline: "none",
            boxShadow: "none"
          }}
        >
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Primary Equipment Operator</p>
            <i
              className={
                this.props.selectedTabs.includes("primaryEquipmentOperator")
                  ? "fas fa-minus-circle text-primary fa-2x"
                  : "fas fa-plus-circle text-primary fa-2x"
              }
            />
          </div>
        </button>
        <div className="row">
          <div className="col">
            <div
              className="collapse primaryEquipmentOperator"
              id="multiCollapseExample1"
            >
              <div className="row">
                <div className="col-lg-4">{firstName}</div>
                <div className="col-lg-4">{lastName}</div>
                <div className="col-lg-4">{contactInfo}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PrimaryEquipmentOperator;
