import React, { useEffect }  from 'react'

export default function DateInput({ stateObject, onChangeFunction, invalidMessage }) {
  let today = new Date().toISOString().slice(0, 10)
  
  const clearValidationError = () => {
    const element = document.getElementById("campaign-deadline")
    element.validity.customError && !element.validity.valueMissing && element.setCustomValidity('')
  }

  useEffect(() => {
    stateObject && stateObject.value && clearValidationError()
  }, [stateObject])

  return (
    <>
      <label
        htmlFor={stateObject.name}
      >
        {stateObject.label}
      </label>
      <input
        id={"campaign-deadline"}
        name={stateObject.name}
        type="date"
        className={"form-control"}
        value={stateObject && stateObject.value || ""}
        style={{boxShadow: "none"}}
        onChange={onChangeFunction}
        required={true}
        onInvalid={e => e.target.setCustomValidity(invalidMessage)}
      />
    </>
  )
}
