import React, { Component } from "react";
import axios from "../../../configAxios";
import Modal from "../../UI/Modal";
import Spinner from "../../UI/Spinner";
import EditLibrary from "./EditLibrary";
import errorStatusHandler from "../../errorPages/UserNotAuthorized";

class Library extends Component {
  signal = axios.CancelToken.source();
  state = {
    libraryList: [],
    id: null,
    editModal: false,
    loading: true
  };

  componentDidMount() {
    this.getLibraryList();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getLibraryList = () => {
    axios
      .get("/form_options", { cancelToken: this.signal.token })
      .then(res => {
        if(res.status === 202) {
          errorStatusHandler(res);
          this.props.tabChangeHandler("Categories");
        } else {
          this.setState({ libraryList: res.data.form_options, loading: false });
        }
      })
      .catch(err => console.log("Something went wrong"));
  };

  render() {
    let library;
    if (this.state.loading) {
      library = <Spinner />;
    } else {
      library = this.state.libraryList.map(item => (
        <li className="list-group-item thirdParty rounded-0">
          <div className="row">
            <div className="col-md-2">
              {item.klass_name}
            </div>
            <div className="col-md-4">
              {`${item.field_name}`}
            </div>
            <div className="col-md-3">
              {`${item.options === null ? "--" : item.options}`}
            </div>
            <div className="col-md-3 text-muted text-center">
              <i
                className="fas fa-pencil-alt cursor location-edit"
                onClick={() => { window.scrollTo(0, 0); this.setState({ id: item.id, editModal: true }) }}
              />
            </div>
          </div>
        </li>
      ));
    }

    return (
      <React.Fragment>
        <ul className="list-group list-group-flush mt-4">
          <li className="list-group-item border-top-0">
            <div className="row">
              <div className="col-md-2">
                <span className="font-weight-bold">Form</span>
              </div>
              <div className="col-md-4">
                <span className="font-weight-bold">Field</span>
              </div>
              <div className="col-md-3">
                <span className="font-weight-bold">Options</span>
              </div>
              <div className="col-md-3" />
            </div>
          </li>
          {library}
        </ul>

        <Modal
          show={this.state.editModal}
          title="Edit options"
          hide={() => this.setState({ editModal: false })}
        >
          <EditLibrary
            id={this.state.id}
            getLibraryList={this.getLibraryList}
            closeModal={() => this.setState({ editModal: false })}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default Library;
