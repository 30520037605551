import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from "../../../configAxios";

class TicketItem extends Component {
  deleteHandler = id => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      axios.delete(`/tickets/${id}`).then(res => {
        const { success } = res.data.json;
        if (success) {
          toast.success("Ticket deleted successfully.");
          this.props.getTickets(false);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      });
    }
  };

  render() {
    const { ticket } = this.props;
    return (
      <li className="list-group-item rounded-0 hover-effect">
        <div className="row">
          <div className="col-lg-6">
            <Link
              className="text-info decoration-none"
              to={`/users/${ticket.ticketable.details.id}/summary`}
            >
              {ticket.full_name_cached}
            </Link>
          </div>
          <div className="col-lg-4">{ticket.created_at_time_ago_in_words} ago</div>
          <div className="col-lg-2">
            <i
              className="fa fa-trash cursor"
              aria-hidden="true"
              onClick={() => this.deleteHandler(ticket.id)}
            />
          </div>
        </div>
      </li>
    );
  }
}

export default TicketItem;
