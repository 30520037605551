import "./Rentals.css"
import React, { Component } from "react";
import axios from "../../configAxios";
import dateFns from "date-fns";
import FormButton from "../UI/button/FormButton";
import DatePicker from "../UI/datePicker/index";
import Input from "../UI/input/Input"
import SelectRentalReport from "./SelectRentalReport"
import AssetRentalsTable from "./rentals/AssetRentalsTable"
import RentalStatistics from "./rentals/RentalStatistics"
import SelectThirdPartyOwner from "./SelectThirdPartyOwner"

class Rentals extends Component {
  constructor(props) {
    super(props);
    const currentMonthDate = new Date();
    this.signal = axios.CancelToken.source();
    this.state = {
      startDate: new Date().setMonth(currentMonthDate.getMonth() - 1),
      endDate: new Date(),
      rentals: null,
      metaData: {},
      loading: false,
      disabled: false,
      selectedRentalReport: null,
      owners: [],
      selectedOwner: null,
      queryString: "",
    };
    this.timeoutId = 0
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
    clearTimeout(this.timeoutId)
  }

  getRentals(rentalReport, queryParams) {
    axios
      .get(`/reports/rentals/${rentalReport}`, {
        params: queryParams,
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          rentals: res.data.json.data,
          metaData: res.data.json.meta,
          loading: false,
          disabled: false
        });
      });
  }

  submitHandler = event => {
    event.persist()

    event.preventDefault()
    this.setState({ loading: true, disabled: true });
    const { startDate, endDate, selectedOwner, queryString } = this.state;
    let report_options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD")
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD")
      },
      query_string: queryString,
    };
    if (selectedOwner) {
      report_options = {
        ...report_options,
        owner_id: selectedOwner.thirdPartyOwnerId
      }
    }
    const queryParams = {
      report_options
    };
    this.getRentals(this.state.selectedRentalReport.value, queryParams);
  };

  renderSelectThirdPartyOwner = () => {
    const { selectedRentalReport } = this.state
    if (selectedRentalReport?.value === 'third_party_rentals') {
      return (
        <SelectThirdPartyOwner
          owners={this.state.owners}
          selectedOwner={this.state.selectedOwner}
          setSelectedOwner={selectedOwner => this.setState({selectedOwner})}
        />
      )
    }
  }
  
  renderRentalsReport = () => {
    const { rentals, metaData, loading } = this.state

    if (rentals || loading) {
      return (
        <>
          <RentalStatistics
            metaData={metaData}
            rentals={rentals}
            loading={loading}
          />
          <AssetRentalsTable
            organizationName={metaData.current_organization_name}
            loading={loading}
            rentals={rentals}
          />
        </>
      )
    }
  }

  renderSearchClearIcon = () => {
    if (this.state.queryString) {
      return (
        <i
          className="fas fa-times cursor search-field-clear-icon"
          onClick={(e) =>
            this.setState({ queryString: "" }, () => this.submitHandler(e))
          }
        />
      )
    }
  }

  onSearchHandler = e => {
    const queryString = e.target.value

    e.persist()

    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    this.setState({
      queryString,
      loading: true,
    }, () => {
      this.timeoutId = setTimeout(() => {
        this.submitHandler(e)
      }, 1000)
    })
  }

  render() {
    return (
      <div className="container service-kind w-100">
        <div className="row mb-4">
          <div className="col-12">
            <h3>Rentals Report</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <form onSubmit={this.submitHandler}>
                    <div className="d-flex flex-row">
                      <SelectRentalReport
                        selectedRentalReport={this.state.selectedRentalReport}
                        setRentalReport={selectedRentalReport => this.setState({selectedRentalReport})}
                        setOwners={owners => this.setState({...this.state, owners})}
                      />
                      <div className="ml-4">
                        {this.renderSelectThirdPartyOwner()}
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <div className="d-flex flex-row">
                        <div className="d-flex flex-column mr-4">
                          <label>From</label>
                          <DatePicker
                            dateFormat={"yyyy-MM-dd"}
                            selected={this.state.startDate}
                            changed={date => this.setState({ startDate: date })}
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <label>To</label>
                          <DatePicker
                            dateFormat={"yyyy-MM-dd"}
                            selected={this.state.endDate}
                            changed={date => this.setState({ endDate: date })}
                          />
                        </div>
                      </div>
                      {this.state.rentals && (
                        <div className="d-flex flex-column w-25 search-field-container">
                          <Input
                            className={"search-field-input"}
                            elementType={"input"}
                            elementConfig={{ placeholder: "Begin typing to search results" }}
                            value={this.state.queryString}
                            changed={this.onSearchHandler}
                          />
                          {this.renderSearchClearIcon()}
                        </div>
                      )}
                    </div>
                    <div>
                      <FormButton className="build-report" disabled={this.state.disabled}>Build Report</FormButton>
                    </div>
                  </form>
                  {this.renderRentalsReport()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rentals;
