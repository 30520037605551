import React, { Component } from "react"

import Button from "../../UI/button/Button"
import axios from "../../../configAxios"
import Spinner from "../../UI/Spinner"
import Modal from "../../UI/Modal"
import langUtils from "../../../utils/langUtils"
import onError from "../../../utils/onError"
import classnames from "classnames"
import { toast } from "react-toastify"

import NewAssetPriceType from "./NewAssetPriceType.js"
import ViewAssetPriceType from "./ViewAssetPriceType.js"

class AssetPriceType extends Component {
  signal = axios.CancelToken.source();
  state = {
    assetPriceTypeList: [],
    newModal: false,
    viewModal: false,
    editModal: false,
    loading: true,
    edit: false,
    assetPrice: {},
    id: null,
    flag: true
  };

  componentDidMount() {
    this.getAssetPriceTypes();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  newModal = () => {
    window.scrollTo(0, 0);
    this.setState({ newModal: true, edit: false });
  };

  getAssetPriceTypes = () => {
    axios
      .get("/asset_price_types", { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          assetPriceTypeList: res.data.asset_price_types,
          loading: false
        });
      });
  };

  editModal = id => {
    window.scrollTo(0, 0);
    this.setState({ edit: true, newModal: true, id: id });
  };

  deleteAssetPriceType = (assetPriceTypeId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Asset Price Type?")

    if (confirmDelete) {
      axios
        .delete(`/asset_price_types/${assetPriceTypeId}`, { cancelToken: this.signal.token })
        .then(() => {
          toast.success(`Asset Price Type successfully deleted.`)
          this.getAssetPriceTypes()
        })
        .catch((err) => {
          const { errors } = err.response.data.json
          errors.forEach(e => onError(e))
        })
    }
  }

  render() {
    const assetPriceList = this.state.assetPriceTypeList;
    let assetPrice;
    if (this.state.loading) {
      assetPrice = <Spinner />;
    } else {
      assetPrice = assetPriceList.map(assetPrice => (
        <li className="list-group-item rounded-0 border-top">
          <div className="row">
            <div className="col-lg-5">
              {assetPrice.name}
            </div>
            <div className="col-lg-5 text-center">
              {assetPrice.level}
            </div>
            <div className="col-lg-2">
              <div className="d-flex justify-content-around text-muted">
                <i
                  className="fas fa-eye assetPrice-view cursor"
                  onClick={() => { window.scrollTo(0, 0); this.setState({ assetPrice, viewModal: true }) }}
                />
                <i
                  className="fas fa-pencil-alt assetPrice-edit cursor"
                  onClick={() => this.editModal(assetPrice.id)}
                />
                <i
                  className={classnames("fas fa-trash", {
                      "cursor": assetPrice.can_modify,
                      "cursor-not-allowed element-disabled": !assetPrice.can_modify
                    }
                  )}
                  onClick={() => assetPrice?.can_modify && this.deleteAssetPriceType(assetPrice.id)}
                />
              </div>
            </div>
          </div>
        </li>
      ));
    }
    return (
      <div className="assetPriceType">
        <div className="p-3">
          <div className="row border-bottom">
            <div className="col-12">
              <h5 className="font-weight-bold">Listing Asset Price Types</h5>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6">
              Note: Higher number has higher priority.
            </div>
            <div className="col-lg-6 text-right">
              <Button type="success" clicked={this.newModal}>
                {langUtils("txt_global_add_new", "Add New")}
              </Button>
            </div>
          </div>
        </div>

        <ul className="list-group list-group-flush mt-3">
          <li className="list-group-item border-top-0">
            <div className="row">
              <div className="col-lg-5">
                <span className="font-weight-bold">Type</span>
              </div>
              <div className="col-lg-5 text-center">
                <span className="font-weight-bold">Priority</span>
              </div>
            </div>
          </li>
          {assetPrice}
        </ul>
        <Modal
          show={this.state.newModal}
          title={
            this.state.edit ? "Edit Asset Price Type" : "New Asset Price Type"
          }
          hide={() => this.setState({ newModal: false })}
          flag={this.state.flag}
        >
          <NewAssetPriceType
            closeModal={() => this.setState({ newModal: false })}
            getAssetPriceTypes={this.getAssetPriceTypes}
            edit={this.state.edit}
            id={this.state.id}
          />
        </Modal>
        <Modal
          show={this.state.viewModal}
          title="View Asset Price Type"
          closeButton
          closeModal={() => this.setState({ viewModal: false })}
          hide={() => this.setState({ viewModal: false })}
        >
          <ViewAssetPriceType
            assetPrice={this.state.assetPrice}
            getAssetPriceTypes={this.getAssetPriceTypes}
          />
        </Modal>
      </div>
    );
  }
}

export default AssetPriceType;
