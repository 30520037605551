import React, { Component } from "react"
import { toast } from "react-toastify"

import Button from "../UI/button/Button"
import axios from "../../configAxios";

const acceptableFormats = ["jpg", "jpeg", "png", "bmp"];

class MediaListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadList: [],
      disabled: false
    }
  }

  addOrRemoveFiles = id => {
    const { downloadList } = this.state;
    let fileIndex = downloadList.indexOf(id);
    this.setState({
      downloadList: fileIndex === -1
        ? [...downloadList, id]
        : downloadList.filter(attrId => attrId !== id)
    });
  }

  fileDownloadHelper = () => {
    this.setState({ disabled: true });
    const toastId = toast("Preparing files for donwload...", {
      type: toast.TYPE.INFO,
      autoClose: false
    });

    this.props.closeListView();
    const downloadConfig = {
      headers: {
        "Accept": "application/zip",
        'Content-Type': 'multipart/form-data',
      }, responseType: 'arraybuffer'
    }

    if (this.props.section === "User") {
      const params = {
        id: this.props.id,
        file_attachment_ids: JSON.stringify(this.state.downloadList)
      }

      axios.get('/users/zipped_media_files', { params, responseType: 'blob' })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.props.name}.zip`);
          document.body.appendChild(link);
          link.click();
          toast.dismiss(toastId)
        })
        .catch(err => toast.dismiss(toastId))
    } else {
      const params = {
        id: this.props.id,
        project_attribute_ids: JSON.stringify(this.state.downloadList)
      }

      axios.get("/projects/zipped_media_files", { params, responseType: 'blob' })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.props.name}.zip`);
          document.body.appendChild(link);
          link.click();
          toast.dismiss(toastId)
        })
        .catch(err => toast.dismiss(toastId))
    }
  }

  renderListItem(id, extension, description) {
    return (
      <div className="form-check" key={id} >
        <input
          type="checkbox"
          value={id}
          onClick={e => this.addOrRemoveFiles(id)}
          className="form-check-input"
          id={id}
        />
        <label htmlFor={id}>{extension} - {description}</label>
      </div>
    )
  }

  getFileUrl(fileLink, separator) {
    return fileLink.split("?")[0].split(separator).pop();
  }

  getFilename(filenameWithExt) {
    return filenameWithExt && filenameWithExt.substring(0, filenameWithExt.lastIndexOf("."));
  }

  render() {
    const { attributes, closeListView, section } = this.props;
    let extension, filenameWithExt, description, filename;
    let mediaList = [];

    if (section === "User") {
      mediaList = attributes && attributes.map(attr => {
        extension = this.getFileUrl(attr.file.url, ".");

        filenameWithExt = this.getFileUrl(attr.file.url, "/");

        filename = this.getFilename(filenameWithExt);

        description = filename &&
          filename.substring(filename.length - 15, filename.length);

        return this.renderListItem(attr.id, extension, description);
      })
    } else {
      mediaList = attributes && attributes.map(attr => {
        extension = attr.file_attachment
          ? this.getFileUrl(attr.file_attachment, ".")
          : attr.value_2
            ? this.getFileUrl(attr.value_2, ".")
            : null;

        filenameWithExt = attr.file_attachment
          ? this.getFileUrl(attr.file_attachment, "/")
          : attr.value_2 && this.getFileUrl(attr.value_2, ".");

        filename = this.getFilename(filenameWithExt);

        description = attr.value ?
          attr.value.length <= 15 ? attr.value
            : attr.value.substring(attr.value.length - 15, attr.value.length)
          : filename && filename.substring(filename.length - 15, filename.length)

        if (acceptableFormats.includes(extension)) {
          return this.renderListItem(attr.id, extension, description);
        }
      })
    }

    return (
      <>
        <div className="my-3">
          {mediaList}
        </div>
        <div className="text-center">
          <Button
            type="secondary"
            clicked={() =>
              this.setState({
                downloadDisabled: false,
                downloadList: []
              }, closeListView)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="ml-4"
            disabled={this.state.disabled || this.state.downloadList.length === 0}
            clicked={this.fileDownloadHelper}
          >
            Download
          </Button>
        </div>
      </>
    )
  }
}

export default MediaListView
