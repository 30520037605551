import React, { Component } from "react";
import Button from "../../UI/button/Button";
import Input from "../../UI/input/Input";
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from "../../../configAxios";

class EditChargedLoan extends Component {
  state = {
    chargedLoanPeriod: {
      elementType: "input",
      elementConfig: {
        name: "quantity",
        type: "text",
        required: true
      },
      value: ""
    },
    memberType: {
      elementType: "select",
      elementConfig: {
        name: "memberType",
        placeholder: "Select Option",
        required: true,
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    }
  };

  componentDidMount() {
    const memberType = { ...this.state.memberType };
    this.setState({
      memberType: {
        ...memberType,
        elementConfig: {
          ...memberType.elementConfig,
          options: this.props.memberTypes.map(type => {
            return { value: type[0], label: type[1] };
          })
        }
      }
    });
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    if (key === "memberType") {
      inputValue["selectedOption"] = event;
    } else {
      inputValue["value"] = event.target.value;
    }
    const updateControls = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updateControls });
  };

  updateChargedLoanPeriod = (event, update) => {
    event.preventDefault();
    const { chargedLoanPeriod, memberType } = this.state;
    const formData = {
      ticket_id: this.props.ticketId,
      booking_ids: `[${this.props.selectedAssets}]`,
      new_charged_loan_period: update
        ? parseFloat(chargedLoanPeriod.value)
        : null,
      asset_price_type_id: update && memberType.selectedOption ? memberType.selectedOption.value : null
    };
    axios
      .put("/bookings/update_charged_loan_periods_for", formData)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Charged loan period updated successfully");
          this.props.getTicket();
          this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      });
  };

  render() {
    return (
      <div className="mt-4">
        <div>
          <label>Charged Loan Period</label>
          <Input
            {...this.state.chargedLoanPeriod}
            changed={e => this.inputChangedHandler(e, "chargedLoanPeriod")}
          />
        </div>
        <div>
          <label>Member Type</label>
          <Input
            {...this.state.memberType}
            changed={e => this.inputChangedHandler(e, "memberType")}
          />
        </div>
        <div className="d-flex justify-content-center my-2 col-md-12">
          <Button
            type="primary"
            style={{ width: "80px" }}
            clicked={event => this.updateChargedLoanPeriod(event, true)}
            disabled={
              this.state.chargedLoanPeriod.value === "" &&
              this.state.memberType.selectedOption === null
            }
          >
            Save
          </Button>
          <Button
            type="delete"
            style={{ width: "80px", marginLeft: "10px" }}
            clicked={() => this.props.closeModal()}
          >
            Remove
          </Button>
        </div>
      </div>
    );
  }
}

export default EditChargedLoan;
