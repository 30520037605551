import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import Input from "../../UI/input/Input";
import axios from "../../../configAxios";

import "./TagInput.css";

class AssociationInput extends Component {
  constructor(props) {
    super(props);
    this.cancelSearch = null;
    this.inputRef = React.createRef();
    this.containerRef = React.createRef();
    this.timeout = null;
    this.state = {
      associationName: "",
      associationList: [],
      associationNameList: [],
      isLoading: false,
      isFocused: false,
      hoveredIndex: -1,
    }
  }

  componentDidMount() {
    const { edit, associationName } = this.props;
    if (edit) {
      this.setState({ associationName });
    }
    if (window.PointerEvent) {
      window.addEventListener("pointerdown", this.handleOutsideClicks);
    } else {
      window.addEventListener("mousedown", this.handleOutsideClicks);
      window.addEventListener("touchstart", this.handleOutsideClicks);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.associationList !== this.state.associationList) {
      if (this.state.associationList.length === 0) {
        this.setState({ hoveredIndex: -1 });
      }
      this.props.getAssociationList(
        this.state.associationList.map(association => association.name)
      );
    }
    if (prevState.isLoading !== this.state.isLoading) {
      this.props.setInputLoading(this.state.isLoading);
      this.setState({ hoveredIndex: -1 });
    }
    if (prevState.isFocused !== this.state.isFocused) {
      this.setState({ hoveredIndex: -1 });
    }
  }

  componentWillUnmount() {
    if (this.cancelSearch !== null)
      this.cancelSearch("Request is being cancelled");
    if (this.timeout)
      clearTimeout(this.timeout);
    if (window.PointerEvent) {
      window.removeEventListener("pointerdown", this.handleOutsideClicks);
    } else {
      window.removeEventListener("mousedown", this.handleOutsideClicks);
      window.removeEventListener("touchstart", this.handleOutsideClicks);
    }
  }

  handleOutsideClicks = (e) => {
    if (this.containerRef.current) {
      const clickedOutside = this.containerRef.current.contains(e.target);
      if (!clickedOutside) {
        this.setState({ isFocused: false });
      }
    }
  }

  inputChangeHandler = e => {
    const associationName = e.target.value;
    this.setState({
      associationName,
    }, () => {
      this.props.associationNameChanged(associationName);
      if ( associationName.length > 0 ) {
        this.getAssociationsByName(associationName);
      } else {
        if (this.cancelSearch !== null) {
            this.cancelSearch();
        }
        this.setState({ 
            associationList: [],
            isLoading: false,
        })
      }
    })
  }

  getAssociationsByName = (associationName) => {
    if (this.timeout) {
      if (this.cancelSearch !== null)
        this.cancelSearch();
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(
      () => this.searchAssociation(associationName),
      300
    );
  }

  searchAssociation = (associationName) => {
    const { CancelToken } = axios;
    this.setState({ isLoading: true });
    axios.get("/education_and_associations", {
      params: {
        query: associationName,
      },
      cancelToken: new CancelToken(cancel => {
        this.cancelSearch = cancel;
      }),
    }).then(res => {
      const associations = res.data.education_and_associations;
      this.setState({
        associationList: Array.isArray(associations)
          ? associations
          : [],
        isLoading: false,
      })
    }).catch(err => {
      if (axios.isCancel(err));
    });
  }

  keyDownHandler = e => {
    const { formRef } = this.props;
    if (
      e.key === "Enter" &&
      e.keyCode !== 299 &&
      this.inputRef.current &&  
      typeof this.inputRef.current.blur === "function"
    ) {
      this.inputRef.current.blur();
      this.inputRef.current.reportValidity();
      this.setState({ isFocused: false });
      if (
        !this.state.isLoading &&
        this.state.associationName &&
        formRef && formRef.current &&
        typeof formRef.current.dispatchEvent === "function"
      ) {
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true })
        )
      }
    }
  }

  itemSelectHandler = (association) => {
    const { associationList } = this.state;
    const { associationNameChanged } = this.props;
    if (associationList.length > 1) {
      this.inputChangeHandler({ 
        target: { value: association.name }
      })
    } else {
      this.setState({ 
        isFocused: false,
        associationName: association.name, 
      }, () => associationNameChanged(association.name));
    }
  }

  associationHoverHandler = hoveredIndex => {
    this.setState({ hoveredIndex });
  }

  renderLoadingIndicator = () => {
    const { isLoading } = this.state;
    
    if (!isLoading) return null;

    return (
      <div className="loading-icons position-absolute">
        <span className="loading-icon d-inline-block" />
        <span className="loading-icon d-inline-block" />
        <span className="loading-icon d-inline-block" />
      </div>
    )
  }

  renderAssociationList = () => {
    const {
      associationList,
      isFocused,
      isLoading,
      hoveredIndex,
    } = this.state;
    
    if (isFocused && isLoading) {
      return (
        <div className=" tag-list w-100 border rounded position-absolute bg-white shadow">
          <div className="p-3 text-center">
            Loading...
          </div>
        </div>
      )
    }
    
    if (associationList.length === 0 || !isFocused) {
      return;
    }
    
    return (
      <div className=" tag-list w-100 border rounded position-absolute bg-white shadow">
        {associationList.map((association, index) => (
          <div
            key={association.id}
            className={classNames(
              "tag-item cursor",
              { "border-top": index !== 0 }
            )}
            onClick={() => this.itemSelectHandler(association)}
            onMouseOver={() => this.associationHoverHandler(index)}
            onMouseLeave={() => this.associationHoverHandler(-1)}
            onTouchStart={() => this.associationHoverHandler(index)}
          >
            <span
              className={classNames(
                "d-block tag-text px-2 py-3",
                { "tag-text-hover": hoveredIndex === index }
              )}
            >
              {association.name}
            </span>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { associationName, isLoading, isFocused } = this.state;
    const label = "association";

    return (
      <>
        <label htmlFor={`${label}-name`}>
          <span className="text-capitalize">{`Name`}</span>
        </label>
        <div
          className="tag-form-input position-relative"
          ref={this.containerRef}
        >
          <Input
            elementType="input"
            forwardRef={this.inputRef}
            elementConfig={{
              name: "name",
              id: `${label}-name`,
              type: "text",
              required: true,
              onFocus: () => this.setState({ isFocused: true }),
              onKeyDown: this.keyDownHandler,
              autoComplete: "off"
            }}
            className={classNames({ "tag-form-input-loading": isLoading && isFocused })}
            value={associationName}
            changed={this.inputChangeHandler}
          />
          {this.renderLoadingIndicator()}
          {this.renderAssociationList()}
        </div>
      </>
    )
  }
}

AssociationInput.propTypes = {
    associationName: PropTypes.string.isRequired,
    associationNameChanged: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
}

AssociationInput.defaultProps = {
    associationName: "",
    associationNameChanged: () => null,
    edit: false,
    formRef: null,
}

export default AssociationInput;
