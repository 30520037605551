import React, { Component } from "react"
import countryList from "react-select-country-list"

import DatePicker from "../UI/datePicker"
import Input from "../UI/input/Input"
import langUtil from "../../utils/langUtils"

import "./contactinfo.css"

class ContactInformation extends Component {
  constructor(props) {
    super(props)
    this.countryList = countryList().getData()

    let defaultValue
    if (this.props.editUser) {
      defaultValue = this.countryList.filter(
        (country) => country.label === this.props.formData.country
      )[0]
    }

    this.state = {
      address1: "",
      address2: "",
      city: "",
      province: "",
      country: "",
      postCode: "",
      phone: "",
      mobile: "",
      alternateEmail: "",
      website: "",
      jobDescription: "",
      bio: "",
      twitter: "",
      instagram: "",
      facebook: "",
      linkedin: "",
      youtube: "",
      specializations: "",
      pronouns: "",
      createdAt: new Date(),
      dateOfBirth: null,
      countryList: {
        elementType: "select",
        elementConfig: {
          name: "countryList",
          placeholder: "Select",
          options: this.countryList,
          defaultValue: defaultValue,
          components: {
            IndicatorSeparator: () => {
              return null
            },
          },
          selectedOption: null,
        },
      },
    }
  }

  componentDidMount() {
    if (this.props.editUser) {
      this.setState({
        ...this.state,
        ...this.props.formData,
      })
    }
  }

  inputChangeHandler = (event, key) => {
    let selectedOption = event
    if (event) {
      selectedOption = { label: event.label, value: event.label }
    }
    if (key === "country") {
      this.setState({
        countryList: {
          ...this.state.countryList,
          selectedOption,
        },
      })
      this.props.inputChangeHandler(selectedOption, "select", key)
    } else {
      this.props.inputChangeHandler(event, "text", key)
      this.setState({ [key]: event.target.value })
    }
  }

  renderRow1 = () => {
    const rowObj = {
      address1: "Address Line 1",
      address2: "Address Line 2",
    }
    Object.keys(rowObj).map((key, index) => (
      <div key={index} className="col-md-3">
        <p>{rowObj[key]}</p>
        <Input
          elementType={"input"}
          value={this.state[key]}
          changed={(event) => this.inputChangeHandler(event, key)}
        />
      </div>
    ))
  }

  renderRow2 = () => {
    const rowObj = {
      city: "City/Town",
      province: "Province",
      country: "Country",
      postCode: "Post Code",
    }
    const form = Object.keys(rowObj).map((key, index) => {
      if (key !== "country") {
        return (
          <div key={index} className="col-md-3">
            <p>{rowObj[key]}</p>
            <Input
              elementType={"input"}
              value={this.state[key]}
              changed={(event) => this.inputChangeHandler(event, key)}
            />
          </div>
        )
      } else {
        return (
          <div key={index} className="col-md-3">
            <p>{rowObj[key]}</p>
            <Input
              {...this.state.countryList}
              changed={(e) => this.inputChangeHandler(e, key)}
            />
          </div>
        )
      }
    })

    return form
  }

  renderRow3 = () => {
    const rowObj = {
      phone: "Land Phone",
      mobile: "Mobile Phone",
      alternateEmail: "Alternate Email",
      website: "Website",
    }
    const form = Object.keys(rowObj).map((key, index) => (
      <div key={index} className="col-md-3">
        <p>{rowObj[key]} </p>
        <Input
          elementType={"input"}
          elementConfig={{
            type: key === "alternateEmail" ? "email" : "text",
          }}
          value={this.state[key]}
          changed={(event) => this.inputChangeHandler(event, key)}
        />
      </div>
    ))

    return form
  }

  renderRow4 = () => {
    const rowObj = {
      jobDescription: "Job Title",
    }
    const form = Object.keys(rowObj).map((key, index) => (
      <div key={index} className="col-md-6">
        <p>{rowObj[key]}</p>
        <Input
          elementType={"input"}
          value={this.state[key]}
          changed={(event) => this.inputChangeHandler(event, key)}
        />
      </div>
    ))

    form.push(
      <div className="col-md-6">
        <p>Bio</p>
        <Input
          elementType={"textarea"}
          value={this.state.bio}
          changed={(event) => this.inputChangeHandler(event, "bio")}
        />
      </div>
    )

    return form
  }

  renderRow5 = () => {
    const rowObj = {
      specializations: "Specializations",
    }
    const form = Object.keys(rowObj).map((key, index) => (
      <div key={index} className="col-md-6">
        <p>{rowObj[key]} </p>
        <Input
          elementType={"input"}
          value={this.state[key]}
          changed={(event) => this.inputChangeHandler(event, key)}
        />
      </div>
    ))

    return form
  }

  renderPronouns = () => {
    const rowObj = {
      pronouns: "Pronouns",
    }
    const form = Object.keys(rowObj).map((key, index) => (
      <div key={index} className="col-md-6">
        <p>{rowObj[key]} </p>
        <Input
          elementType={"input"}
          value={this.state[key]}
          changed={(event) => this.inputChangeHandler(event, key)}
        />
      </div>
    ))

    return form
  }

  render() {
    const shouldShowSpecification = () => {
      if (localStorage.getItem("roleLevel") === "member") {
        if (!this.props.allowUserEditContactInformation) return false
      }
      return true
    }

    const shouldShowPronouns = () => {
      if (localStorage.getItem("roleLevel") === "member") {
        if (this.props.allowUserEditContactInformation) return true
      }
      return false
    }

    const FormInputs = (
      <>
        <div className="row mt-3">
          <div className="col-md-6">
            <p>Address Line 1</p>
            <Input
              elementType={"input"}
              value={this.state.address1}
              changed={(event) => this.inputChangeHandler(event, "address1")}
            />
          </div>
          <div className="col-md-6">
            <p>Address Line 2</p>
            <Input
              elementType={"input"}
              value={this.state.address2}
              changed={(event) => this.inputChangeHandler(event, "address2")}
            />
          </div>
        </div>
        <div className="row">{this.renderRow1()}</div>
        <div className="row">{this.renderRow2()}</div>
        <div className="row">{this.renderRow3()}</div>
        <div className="row">{this.renderRow4()}</div>
        <div className="row">
          <div className="col-md-6">
            <p>Twitter</p>
            <Input
              elementType={"input"}
              value={this.state.twitter}
              changed={(event) => this.inputChangeHandler(event, "twitter")}
            />
          </div>
          <div className="col-md-6">
            <p>Instagram</p>
            <Input
              elementType={"input"}
              value={this.state.instagram}
              changed={(event) => this.inputChangeHandler(event, "instagram")}
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p>Facebook</p>
            <Input
              elementType={"input"}
              value={this.state.facebook}
              changed={(event) => this.inputChangeHandler(event, "facebook")}
            />{" "}
          </div>
          <div className="col-md-6">
            <p>Youtube / Vimeo</p>
            <Input
              elementType={"input"}
              value={this.state.youtube}
              changed={(event) => this.inputChangeHandler(event, "youtube")}
            />{" "}
          </div>
          <div className="col-md-6">
            <p>Linkedin</p>
            <Input
              elementType={"input"}
              value={this.state.linkedin}
              changed={(event) => this.inputChangeHandler(event, "linkedin")}
            />{" "}
          </div>
          {!this.props.editUser ? (
            <div className="col-md-6">
              <p>{langUtil("txt_global_friends", "Friend")} Since</p>
              <DatePicker
                required={true}
                selected={this.state.createdAt}
                changed={(date) => {
                  this.setState({ createdAt: date }, () =>
                    this.props.inputChangeHandler(date, "date", "createdAt")
                  )
                }}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col-md-6">
            <p>Birth Date</p>
            <DatePicker
              maxDate={new Date()}
              selected={this.state.dateOfBirth}
              changed={(date) => {
                this.setState({ dateOfBirth: date }, () =>
                  this.props.inputChangeHandler(date, "date", "dateOfBirth")
                )
              }}
            />
          </div>
          {shouldShowSpecification() && this.renderRow5()}
          {shouldShowPronouns() && this.renderPronouns()}
        </div>
      </>
    )

    const baseHeadingStyles = {
      width: "100%",
      backgroundColor: "#f5efef",
    }

    return (
      <div className="mb-2 contact-info">
        {this.props.editUser ? (
          <>
            <div className="p-3 font-weight-bold" style={baseHeadingStyles}>
              Contact Information
            </div>
            <div className="row">
              <div className="col-12">{FormInputs}</div>
            </div>
          </>
        ) : (
          <>
            <button
              className="btn"
              onClick={() => this.props.tabHandler("contactInformation")}
              type="button"
              data-toggle="collapse"
              data-target="#contactInformation"
              aria-expanded="false"
              aria-controls="collapse"
              style={{
                ...baseHeadingStyles,
                outline: "none",
                boxShadow: "none",
              }}
            >
              <div className="d-flex justify-content-between">
                <p className="font-weight-bold">Contact Information</p>
                <i
                  className={
                    this.props.selectedTabs.includes("contactInformation")
                      ? "fas fa-plus-circle text-primary fa-2x"
                      : "fas fa-minus-circle text-primary fa-2x"
                  }
                />
              </div>
            </button>
            <div className="row">
              <div className="col">
                <div className="collapse" id="contactInformation">
                  {FormInputs}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}
export default ContactInformation
