import React from "react"
import UserInfoPanel from "./UserInfoPanel"

const Email = (props) => {
  const user = props.user
  return (
    <>
      {user.email && (
        <UserInfoPanel>
          <a href={`mailto:${user.email}`} className="text-primary">
            {user.email}
          </a>
        </UserInfoPanel>
      )}
    </>
  )
}

export default Email
