import React from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";

const modal = props => {
  let modalStyle;

  if (props.center) {
    modalStyle = {
      top: "45%",
      left: "53%",
      height: "100%",
      width: "100%",
      transform: "translate(-50%, -50%)",
      padding: "15%",
      ...props.style
    };
  } else {
    modalStyle = {
      position: "absolute",
      width: "100%",
      height: "100%",
      ...props.style
    };
  }

  let closeButton = (
    <button
      type="button"
      className="close"
      onClick={event => props.closeModal(event)}
    >
      &times;
    </button>
  );

  let onHideProperty
  if (props.hide) {
    onHideProperty = { onHide: props.hide }
  } else {
    onHideProperty = {}
  }
  return (
    <Modal
      className={`custom-modal ${props.className || ""}`}
      backdrop={true}
      show={props.show}
      style={modalStyle}
      size={props.size}
      {...onHideProperty}
    >
      {props.closeButton && closeButton}
      {props.title && (
        <Modal.Header
          style={{
            ...props.headerStyle,
            backgroundColor: props.flag ? "#f2f2f2" : "white",
            color: props.program ? "brown" : "",
            borderBottom: props.program ? "1px solid white" : "",
          }}
        >
          <Modal.Title style={{ ...props.titleStyle, fontWeight: props.flag ? "bold" : "normal" }}>
            {props.title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body style={props.bodyStyle}>{props.children}</Modal.Body>
    </Modal>
  );
};

export default modal;
