import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { format as dateFormat } from "date-fns";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ToggleSwitch from "../../common/toggleSwitch";
import axios from "../../../configAxios";
import errorStatusHandler from "../../errorPages/UserNotAuthorized";

export class ListItem extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      status: null,
      toggleDisabled: false,
    }
  };

  componentDidMount() {
    const { status } = this.props;
    this.setState({ status: !status });
  }

  componentWillUnmount() {
    this.signal.cancel("Requests are being cancelled");
  }

  toggleHandler = checked => {
    const { status } = this.state;
    const { id } = this.props;
    this.setState({ toggleDisabled: true });
    const params = {
      status: checked ? 0 : 1
    };
    axios.put(`/users/${id}`, params, {
      cancelToken: this.signal.token
    })
      .then(res => {
        if (res.data.json.success) {
          toast.success("AMS Super Admin status has been successfully updated");
          this.setState({ 
            status: checked,
            toggleDisabled: false, 
          });
        } else {
          errorStatusHandler(res);
          this.setState({ 
            toggleDisabled: false,
            status 
          });
        }
      })
      .catch(err => this.setState({
        toggleDisabled: false,
        status
      }))
  }

  resetPasswordHandler = () => {
    const { id } = this.props;
    axios.post("/users/reset_password", { id }, {
      cancelToken: this.signal.token
    })
      .then(res => {
        if (res.data.json.success) {
          toast.success(res.data.json.message);
        } else {
          errorStatusHandler(res);
        }
      })
      .catch(err => err)
  }

  render() {
    const { email, name, lastLogin, loginUrl, id, auth } = this.props;
    const { toggleDisabled, status } = this.state;

    return (
      <tr>
        <td>{email}</td>
        <td>{name}</td>
        <td>{lastLogin ? dateFormat(lastLogin, "YYYY.MM.DD") : "--"}</td>
        <td>
          { loginUrl || "---" }
        </td>
        <td className="text-center">
          <div className="d-flex">
            <Link 
              className="btn-link pen-icon-scale-down" 
              to={`/ams_super_admins/${id}/edit`}
            >
              <i className="fas fa-2x fa-pen cursor" />
            </Link>
            <div 
              className="btn-link ml-3 icon-scale-down cursor"
              onClick={this.resetPasswordHandler}
            >
              <i
                className="fas fa-xs fa-sync text-light position-absolute reset-password-sync-icon"
                title="Reset Password"
              />
              <i 
                className="fas fa-2x fa-lock" 
                title="Reset Password" 
              />
            </div>
          </div>
        </td>
        <td>
          <div className="super-admin-toggle-icon pt-2 icon-scale-down">
            <ToggleSwitch 
              checked={status}
              disabled={toggleDisabled || auth.userId === `${id}`}
              onChange={(checked) => {
                if (auth.userId === `${id}`) return null;
                this.toggleHandler(checked)
              }}
            />
          </div>
        </td>
      </tr>
    )
  }
}

ListItem.propTypes = {
  loginUrl: PropTypes.string.isRequired,
  lastLogin: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

ListItem.defaultProps = {
  loginUrl: "---",
  lastLogin: "---",
  name: "---",
  email: "---",
  status: 1,
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(
  mapStateToProps
)(ListItem);
