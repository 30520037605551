import React, { Component } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { withRouter } from "react-router-dom"
import { title } from "change-case"
import classnames from "classnames"

import axios from "../../configAxios"
import Input from "../UI/input/Input"
import FormButton from "../UI/button/FormButton"
import Spinner from "../UI/Spinner"
import Modal from "../UI/Modal"

import { getProject } from "../../store/actions/index"
import EditAttribute from "./EditAttribute"

import "./ShortAttributes.css"

class LongAttributes extends Component {
  signal = axios.CancelToken.source()
  state = {
    attrType: {
      elementType: "select",
      elementConfig: {
        name: "attrType",
        placeholder: "Select Option",
        options: [],
        required: true,
        isLoading: true,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
      label: "Select Long Attribute",
    },
    elementType: "",
    attrValue: "",
    attributesData: [],
    duration: { hours: "", minutes: "", seconds: "" },
    loading: true,
    showModal: false,
    updateAttributeData: {},
    disabled: true,
    manageAttributesForm: null,
  }

  componentDidMount() {
    const type = "Long Attributes"
    axios
      .get("/project_attributes/formdata", {
        params: { type },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState(
          {
            attrType: {
              ...this.state.attrType,
              elementConfig: {
                ...this.state.attrType.elementConfig,
                options: res.data.project_attributes.map((attr) => {
                  return {
                    label: attr.name,
                    value: attr.id,
                  }
                }),
                isLoading: false,
              },
            },
          },
          () => this.getAttributes()
        )
      })
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios
      .get("/projects/policy", {
        params: { id: this.props.projects.project.id },
      })
      .then((res) => {
        this.setState({
          manageAttributesForm: res.data.json.manage_attributes_form,
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getAttributes = () => {
    const type = "Long Attributes"
    const project_id = this.props.match.params.id
    axios
      .get("/project_attributes", {
        params: { type, project_id },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          attributesData: res.data.project_attributes,
          loading: false,
          disabled: false,
        })
      })
  }

  inputChangedHandler(event, key) {
    const inputValue = {}
    if (key === "attrValue") {
      this.setState({ attrValue: event.target.value })
    } else {
      inputValue.selectedOption = event
      const updatedControls = {
        ...this.state[key],
        ...inputValue,
      }

      this.setState({
        [key]: updatedControls,
      })
    }
  }

  addAttributeHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const id = this.props.projects.project.slug
      ? this.props.projects.project.slug
      : this.props.projects.project.id
    const attrData = {
      project_id: this.props.projects.project.id,
      project_attribute_type_id: this.state.attrType.selectedOption.value,
    }
    attrData.value = this.state.attrValue

    axios.post("/project_attributes", attrData).then((res) => {
      if (res.data.json.success) {
        this.setState({
          attrType: {
            ...this.state.attrType,
            selectedOption: null,
          },
          attrValue: "",
          duration: { hours: "", minutes: "", seconds: "" },
          disabled: false,
        })
        toast.success("Long attribute successfully created.")
        this.props.getProject(id)
        this.getAttributes()
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach((error) => {
            toast.error(title(error) + " " + res.data.json.errors[error])
          })
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error)
        }
        this.setState({ disabled: false })
      }
    })
  }

  deleteAttributeHandler = (e, index) => {
    const params = {
      project_id: this.props.match.params.id,
      id: index,
    }
    const id = this.props.projects.project.slug
      ? this.props.projects.project.slug
      : this.props.projects.project.id
    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      axios
        .delete(`/project_attributes/${index}`, { data: params })
        .then((res) => {
          if (res.data.json.success) {
            toast.success("Long attribute successfully deleted.")
            this.props.getProject(id)
            this.getAttributes()
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
          }
        })
    }
  }

  openModal = (updateAttributeData) => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true, updateAttributeData })
  }

  clearLongAttribute = () => {
    this.setState({ attrValue: "" })
  }

  render() {
    const { projects } = this.props
    let list = ""
    let removeIcon
    if (this.state.attrValue !== "") {
      removeIcon = (
        <i
          className="fas fa-times remove-icon cursor"
          onClick={() => this.clearLongAttribute()}
        />
      )
    }
    if (this.state.loading) {
      list = <Spinner />
    } else {
      list = (
        <ul className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="row">
              <div className="col-md-5">
                <span className="font-weight-bold">Role</span>
              </div>
              <div className="col-md-5">
                <span className="font-weight-bold">Value</span>
              </div>
            </div>
          </div>
          {this.state.attributesData.map((data, index) => (
            <li className="list-group-item" key={index}>
              <div className="row">
                <div className="col-md-5">
                  {data.project_attribute_type_name}
                </div>
                <div className="col-md-5 text-justify">{data.value}</div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-around">
                    <i
                      className="fas fa-pencil-alt cursor"
                      onClick={(event) => this.openModal(data)}
                    />
                    <i
                      className={classnames("fas fa-trash", {
                        cursor:
                          projects &&
                          projects.project &&
                          projects.project.can_delete,
                        "cursor-not-allowed element-disabled":
                          projects &&
                          projects.project &&
                          !projects.project.can_delete,
                      })}
                      onClick={(e) => {
                        if (
                          projects &&
                          projects.project &&
                          projects.project.can_delete
                        )
                          this.deleteAttributeHandler(e, data.id)
                      }}
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )
    }

    let showList
    if (!this.state.loading && this.state.attributesData.length === 0) {
      showList = (
        <div className="col no-long-attributes-container my-5 py-5">
          <h5 className="no-long-attributes-heading text-center font-weight-bold">
            No Long Attributes
          </h5>
        </div>
      )
    } else {
      showList = <>{list}</>
    }

    return (
      <div className="long-attr">
        <form onSubmit={this.addAttributeHandler} className="mt-4 ">
          <div className="row m-0">
            <div className="col-md-5">
              <Input
                className="select-attr"
                {...this.state.attrType}
                changed={(event) => this.inputChangedHandler(event, "attrType")}
              />
            </div>
            <div className="col-md-5 form-group short-attr">
              {removeIcon}
              <Input
                elementType={"textarea"}
                elementConfig={{ required: true, rows: "4" }}
                label={"Add Long Attribute"}
                value={this.state.attrValue}
                changed={(event) =>
                  this.inputChangedHandler(event, "attrValue")
                }
              />
            </div>
            <div className="col-md-2 attr-add-button">
              <FormButton
                disabled={
                  this.state.disabled || !this.state.manageAttributesForm
                }
                className="btn-lg"
                style={{
                  cursor: this.state.manageAttributesForm
                    ? "pointer"
                    : "no-drop",
                  height: 40,
                  fontSize: 15,
                }}
              >
                Add
              </FormButton>
            </div>
          </div>
        </form>
        {showList}
        <Modal
          show={this.state.showModal}
          title="Edit Long Attribute"
          hide={() => this.setState({ showModal: false })}
        >
          <EditAttribute
            closeModal={() => this.setState({ showModal: false })}
            attributesData={this.state.updateAttributeData}
            getAttributes={this.getAttributes}
            longAttribute={true}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProject: (id) => dispatch(getProject(id)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LongAttributes)
)
