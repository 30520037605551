import React from "react";
import "./CenteredContainer.css";

const centeredContainer = props => (
  <div className={`container vertical-center-container ${props.className}`} style={props.style}>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mx-auto">
              <div>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default centeredContainer;
