import { createSlice } from "@reduxjs/toolkit";
import axios from "../../configAxios";

export const grantApplicationSlice = createSlice({
  name: 'grantApplications',
  initialState: {
    loading: false,
    grantApplicationsList: [],
    error: ''
  },
  reducers: {
    getGrantApplicationRequest: state => {
      state.loading = true
    },
    getGrantApplicationSuccess: (state, action) => {
      return {
        loading: false,
        grantApplicationsList: action.payload
      }
    },
    getGrantApplicationFailure: (state, action) => {
      return {
        loading: false,
        error: action.payload
      }
    }
  }
})


export default grantApplicationSlice.reducer

export const getGrantApplications = () => {
  return dispatch => {
    dispatch(grantApplicationSlice.actions.getGrantApplicationRequest())
    axios.get(`/grant_applications`)
    .then(res => dispatch(grantApplicationSlice.actions.getGrantApplicationSuccess(res.data.json.data)))
    .catch(err => dispatch(grantApplicationSlice.actions.getGrantApplicationFailure(err.message)))
  }
}
