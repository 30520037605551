import React, { Component } from "react";
import { toast } from "react-toastify"
import { title } from "change-case";

import ProgramDetail from "./ProgramDetail";
import Spinner from "../UI/Spinner";
import axios from "../../configAxios";
import Sidebar from "./Sidebar";

import "./Program.css";

class Program extends Component {
  signal = axios.CancelToken.source();
  state = {
    content: null,
    loading: true,
    showComponent: false,
    reservedAssets: [],
    createdAt: null,
    ticketId: null,
    totalCount: null,
    notes: null,
    bookings: [],
    invoiceId: null,
    policyRes: null,
    total: null
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getProgramAndPolicy();
  }

  getProgramAndPolicy = () => {
    this.setState({ loading: true });
    axios
      .get("/programs/" + this.props.match.params.id, {
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState(
          {
            content: res.data.program,
            invoiceId: res.data.program.invoice_id,
            loading: false
          }
        );
      });
    axios.get("/programs/policy", {
      params: {
        id: this.props.match.params.id
      },
      cancelToken: this.signal.token
    })
      .then(res => {
        this.setState({ policyRes: res.data.json });
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  createTicket = () => {
    let formData = {};
    formData["program_id"] = this.props.match.params.id;
    axios.post("/tickets", formData).then(res => {
      if (res.data.json.success) {
        const createdAt = res.data.json.data.created_at;
        const ticketId = res.data.json.data.id;
        const bookings = res.data.json.data.bookings;
        this.setState({ createdAt, ticketId, bookings });
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(title(error) + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
      }
    });
  };

  toggleComponentHandler = () => {
    this.setState(prevState => ({
      showComponent: !prevState.showComponent
    }));
  };

  render() {
    let programDetail;
    if (this.state.loading) {
      programDetail = <Spinner />;
    } else {
      programDetail = (
        <ProgramDetail
          content={this.state.content}
          programId={this.props.match.params.id}
          policyRes={this.state.policyRes}
        />
      );
    }
    return (
      <div className="program container w-95">
        <div
          className={
            this.state.showComponent
              ? "hide"
              : "mt-3 summary-program pb-5"
          }
        >
          <div className="row mb-4">
            <div className="col-9">
              <h3>Program</h3>
            </div>
            <div className="col-3 text-right mt-3">
              <span
                className="cursor"
                onClick={() => this.props.history.goBack()}
              >
                {"<< Back"}
              </span>
            </div>
          </div>
          <div className="row">
            <div className={this.state.policyRes && this.state.policyRes.can_update ? 'col-lg-8' : 'col-lg-12'}>
              <div className="card">
                <div className="card-body">
                  <div className="card-text">
                    {programDetail}
                  </div>
                </div>
              </div>
            </div>
            {this.state.policyRes && this.state.policyRes.can_update && (
              <div className="col-lg-4 sidebar">
                <Sidebar
                  invoiceId={this.state.invoiceId}
                  programId={this.props.match.params.id}
                  program={this.state.content}
                  getProgramAndPolicy={this.getProgramAndPolicy}
                  loadSidebar={this.state.loading}
                  policyRes={this.state.policyRes}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Program;
