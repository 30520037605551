import React, { Component } from "react"
import { toast } from "react-toastify"
import { connect } from "react-redux"

import axios from "../../configAxios"
import errorHandler from "../errorPages/UserNotAuthorized"
import Button from "../UI/button/Button"
import FormButton from "../UI/button/FormButton"
import Input from "../UI/input/Input"

import AuthContainer from "../auth/AuthContainer"
import Logo from "../auth/formComponents/Logo"

class SingUp extends Component {
  state = {
    showMessage: false,
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "email",
          required: false,
        },
        disabled: true,
        label: "Email",
        value: "",
      },
      firstName: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "firstName",
          required: true,
        },
        label: "First Name",
        value: "",
      },
      lastName: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "lastName",
          required: true,
        },
        label: "Last Name",
        value: "",
      },
    },
    password: "",
  }

  componentDidMount() {
    this.setState({
      controls: {
        ...this.state.controls,
        email: {
          ...this.state.controls.email,
          value: localStorage.getItem("unconfirmed_user_email"),
        },
      },
    })
  }

  submitHandler = (event) => {
    event.preventDefault()
    const formData = {
      token: this.props.location.state.unconfirmed_user_token,
      first_name: this.state.controls.firstName.value,
      last_name: this.state.controls.lastName.value,
      email: this.state.controls.email.value,
    }
    axios.put(`/sign_up`, formData).then((res) => {
      if (res.data.json.success) {
        toast.success("Registered Successfully")
        this.setState({
          showMessage: true,
          password: res.data.json.password,
        })
      } else {
        errorHandler(res)
      }
    })
  }

  inputChangedHandler = (event) => {
    const updatecontrols = {
      ...this.state.controls,
      [event.target.name]: {
        ...this.state.controls[event.target.name],
        value: event.target.value,
      },
    }
    this.setState({ controls: updatecontrols })
  }

  onLoginClick = () => {
    const { history, loginUrlOverride } = this.props

    if (loginUrlOverride) {
      return window.location.href = loginUrlOverride
    }

    history.push("/")
  }

  onCopyHandler = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success("Copied to Clipboard!")
      })
      .catch((err) => {
        console.log(err)
        toast.error(err)
      })
  }

  render() {
    const { loginButtonStatus } = this.props
    const textElementsArray = []
    for (let key in this.state.controls) {
      textElementsArray.push({
        id: key,
        config: this.state.controls[key],
      })
    }
    const textElements = textElementsArray
      .filter((element) => element.config !== null)
      .map((formElement) => {
        return (
          <Input
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            label={formElement.config.label}
            value={formElement.config.value}
            changed={(event) => this.inputChangedHandler(event)}
            disabled={formElement.config.disabled}
            block
          />
        )
      })

    return (
      <AuthContainer>
        <div className="card-body">
          <div className="card-text px-3 pb-3">
            <Logo/>
            {!this.state.showMessage && (
              <form onSubmit={this.submitHandler} className="mt-4">
                {textElements}
                <FormButton block={true}>Create Account</FormButton>
              </form>
            )}
            {this.state.showMessage && (
              <div className="text-center text-dark">
                <div className="text-left font-14 text-muted my-3">
                  {this.state.controls.firstName.value}{" "}
                  {this.state.controls.lastName.value}
                  <br />
                  <br />
                  Your account was successfully created. <br />
                  This is your login information. <br />
                  <br />
                  Email: {this.state.controls.email.value} <br />
                  <div className="d-flex flex-row align-items-baseline">
                    <p>Password: {this.state.password}</p>
                    <i
                      className="fas fa-clipboard fa-lg ml-3 cursor"
                      onClick={() => this.onCopyHandler(this.state.password)}
                    />
                  </div>
                  <br />
                  As a precaution, we also sent this access credential to your
                  email address.
                </div>
                {loginButtonStatus && (
                  <Button
                    type="primary"
                    className="mr-3 w-100"
                    clicked={this.onLoginClick}
                  >
                    Login
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </AuthContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loginUrlOverride: state.loginAssets.loginUrlOverride,
    loginButtonStatus: state.loginAssets.loginButtonStatus,
  }
}

export default connect(mapStateToProps)(SingUp)
