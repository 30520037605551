import React from 'react'
import Editor from "rich-markdown-editor"

export default function TextContent({ question }) {
  return (
    <div className="p-3 mb-3">
      <Editor
        readOnly={true}
        defaultValue={question.question_text || ""}
        theme={{color: "#6c757d"}}
      />
    </div>
  )
}
