let ckConfig = {
  toolbar: [
    [
      "Undo",
      "Redo",
      "Scayt",
      "NumberedList",
      "BulletedList",
      "Bold",
      "Italic",
      "Link",
      "Underline",
      "Subscript",
      "Superscript",
      "Strike",
      "Blockquote",
      "Anchor",
      "Table",
      "HorizontalRule",
      "SpecialChar",
      "Outdent",
      "Indent",
      "TextColor",
      "BGColor",
      "JustifyLeft",
      "JustifyRight",
      "JustifyCenter",
      "JustifyBlock"
    ],
    "/",
    ["Styles", "Format", "Font", "FontSize"]
  ],
  versionCheck: false,
};

export default ckConfig;
