import React from "react"
import UserInfoPanel from "./UserInfoPanel"

const FullName = (props) => {
  const user = props.user
  return (
    <>
      {user.organization_or_name !== user.full_name && (
        <UserInfoPanel>{user.full_name}</UserInfoPanel>
      )}
    </>
  )
}

export default FullName
