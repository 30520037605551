import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const items = [
  {
    id: 91,
    name: "Demographics",
    attributes: {
      items: [],
    },
  },
  {
    id: 92,
    name: "Film Submission",
    attributes: { items: [] },
  },
];

const initialState = () => {
  return {
    items: [],
    totalCount: 0,
    loading: true,
  };
};

// export const getForms = () => {
//   return (dispatch) => {
//     dispatch({ type: actionTypes.GET_FORMS });
//   };
// };

// export const getForm = () => {
//   return (dispatch) => {
//     dispatch({ type: actionTypes.GET_FORM });
//   };
// };

// export const createForm = (params = {}) => {
//   return (dispatch) => {
//     dispatch({ type: actionTypes.CREATE_FORM, name: params.name });
//   };
// };

// export const updateForm = () => {
//   return (dispatch) => {
//     dispatch({ type: actionTypes.UPDATE_FORM });
//   };
// };

const formsAdapter = createEntityAdapter({});

const slice = createSlice({
  name: "form",
  initialState: formsAdapter.getInitialState(initialState()),
  reducers: {
    getForm(state, action) {
      state.loading = false;
      formsAdapter.addMany(state, items);
    },
    getForms(state, action) {
      state.loading = false;
      formsAdapter.addMany(state, items);
    },
    updateForm(state, action) {},
    createForm(state, action) {
      const id = Math.floor(Math.random() * Math.floor(100000000));
      const { name } = action.payload;
      return formsAdapter.addOne(state, {
        id,
        name,
      });
    },
  },
});

export const selectFormsLoading = (state) => state.forms.loading;

export const {
  selectById: selectFormById,
  selectIds: selectFormIds,
  selectEntities: selectFormEntities,
  selectAll: selectAllForms,
  selectTotal: selectTotalForms,
} = formsAdapter.getSelectors((state) => state.forms);

const { actions, reducer } = slice;
export const { createForm, getForm, getForms, updateForm } = actions;

export default reducer;
