import React, { Component } from "react";
import { connect } from 'react-redux'
import { NavLink } from "react-router-dom";
import CampaignItems from "./Items";
import Button from "../UI/button/Button";
import "./index.css";
import CreateCampaign from "./CreateCampaign";
import Modal from "../UI/Modal";

import axios from "../../configAxios"
import _ from "lodash";

class Campaigns extends Component {
  state = {
    showModal: false,
    refreshList: false,
    campaigns: null,
    campaignLoading: false,
  };

  componentDidMount() {
    if (this.props.location.pathname.includes("new")) {
      this.openModal();
    }
  }

  isAdmin = () => {
    return ["super_admin", "site_admin", "staff"].includes(this.props.roleLevel)
  }

  componentWillReceiveProps(props) {
    if (props.location.pathname.includes("new")) {
      this.openModal();
    }
  }

  openModal = () => {
    window.scrollTo(0, 0);
    this.setState({
      showModal: true,
      refreshList: false,
    });
  };

  getGrantCampaigns = (search_params=null) => {
    this.setState({campaignLoading: true})
    axios.get('/grant_campaigns', {params: { search_params: search_params }})
    .then(res => {
      const { grant_campaigns } = res.data
      if (!this.isAdmin() && _.isEmpty(grant_campaigns)) {
        window.location.href = "/"
      }
      this.setState({ campaigns: grant_campaigns, campaignLoading: false })
    })
  }

  render() {
    return (
      <div className="container projects w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Campaigns</h3>
          </div>
          <div className="col-6 text-right">
            { this.isAdmin() &&
              <Button type="success" clicked={this.openModal}>
                Add New Campaign
              </Button>
            }
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs font-weight-bold">
                <li className="nav-item px-4 active-tab">
                  <NavLink className="decoration-none" to="/campaigns">
                    Campaigns
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card">
          <CampaignItems
            isAdmin={ this.isAdmin }
            userId={this.props.userId}
            campaigns={this.state.campaigns}
            getGrantCampaigns={this.getGrantCampaigns}
          />
        </div>
        <Modal
          title="Create a Campaign"
          show={this.state.showModal}
          flag={true}
          hide={() => this.setState({ showModal: false })}
        >
          <CreateCampaign
            closeModal={refreshList =>
              this.setState({ showModal: false }, () => {
                this.props.history.push("/campaigns");
                this.setState({ refreshList: Boolean(refreshList) });
              })
            }
            getGrantCampaigns={this.getGrantCampaigns}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ roleLevel: state.auth.roleLevel, userId: Number(state.auth.userId)} )

export default connect(mapStateToProps)(Campaigns);
