import React, { Component } from "react";
import changeCase from "change-case";
import { Link } from "react-router-dom"
import dateFormat from "date-fns/format"
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

import axios from "../../../configAxios";
import Spinner from "../../UI/Spinner";
import Button from "../../UI/button/Button";
import MonthlyCalendar from "../../publicSection/publicCalendar/monthlyCalendar";
import WeeklyCalendar from "../../publicSection/publicCalendar/weeklyCalendar";

import "./index.css";
import "./FaciltyDetailCalendar.css";

class FacilityActivityDetail extends Component {
  signal = axios.CancelToken.source();
  state = {
    assetsOut: null,
    eventData: null,
    loading: true,
    date: new Date(),
    views: {
      options: ["week", "month", "day", "agenda"],
      selected: "week"
    },
    bookingData: "",
  };

  componentDidMount() {
    this.getAssets();
    this.interval = setInterval(this.getAssets, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.signal.cancel("Request is being Cancelled");
  }


  getAssets = async () => {
    const params = {
      type_used: this.props.activeTab,
      asset_summary_day: dateFormat(this.state.date, "YYYY-M-D")
    };
    this.setState({ loading: true });
    axios.get(`/assets/booking_activity`, { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          assetsOut: res.data.json.out
        }, () => this.formatEventData());
      })
  };

  formatEventData = () => {
    const { assetsOut } = this.state;
    let eventData = assetsOut.map(asset => {
      return {
        name: asset.asset.name,
        start: asset.start,
        end: asset.end,
        userOrProgram: asset.user_or_program,
        userOrProgramId: asset.user_or_program_detail.id,
        userOrProgramName: 
          asset.user_or_program === "User" ? 
          asset.user_or_program_detail.first_name + " " +asset.user_or_program_detail.last_name : 
          asset.user_or_program_detail.name 
      }
    });
    this.setState({ eventData, loading: false });
  }

  viewSwitchHandler = (e, view) => {
    e.preventDefault();
    this.setState({
      views: {
        ...this.state.views,
        selected: view
      }
    })
  }

  dateChangeHook = date => {
    this.setState({ date }, () => this.getAssets())
  }

  getCsv = (generate_csv) => {
    if (generate_csv) {
      toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
    }
    const assetSummaryDay = dateFormat(this.state.date, "YYYY-M-D")
    const selected_view = this.state.views.selected
    axios.get("/assets/facility_csv", {
      params: {
        asset_summary_day: assetSummaryDay,
        selected_view: selected_view
      },
      cancelToken: this.signal.token
      })
      .then(res => {
        if (generate_csv) {
          this.setState(
            {
              bookingData: res.data.json.data,
            },
            () => this.refs.csv.link.click()
          );
        }
      });
  }

  render() {
    const { loading, views, date } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          {loading ? (
            <Spinner />
          ) : (
              <>
              <div className="row">
                    <div className="col-md-2">
                      <div className="p-2 px-4 mt-1">
                        <Button
                          type="success"
                          clicked={() => this.getCsv({ generate_csv: true })}
                        >
                          Generate CSV
                        </Button>
                      </div>
                    </div>
                  <div className='col-10'>
                    <div className="p-2 px-4">
                      <div className="btn-toolbar justify-content-end" role="toolbar" aria-label="Toolbar with button groups">
                        <div className="btn-group" role="group" aria-label="view button group">
                          {views.options.map((option, index) => (
                            <button type="button" onClick={(event) => this.viewSwitchHandler(event, option)} id={option} key={`${option}-${index}`} className="btn border-dark" style={{ backgroundColor: `${option === this.state.views.selected ? "#3174ad" : "#fff"}`, color: `${option === this.state.views.selected ? "#fff" : "#999"}` }}>{changeCase.upperCaseFirst(option)}</button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {
                      this.state.views.selected === "month" ?
                        <div className="px-4">
                          <MonthlyCalendar 
                            private={true} 
                            date={date} 
                            dateChangeHook={this.dateChangeHook} 
                            assetsOut={this.state.eventData} 
                          />
                        </div>
                        : (
                          <div className="private-weekly-calendar px-4">
                            <WeeklyCalendar 
                              view={this.state.views.selected} 
                              private={true} 
                              date={date} 
                              dateChangeHook={this.dateChangeHook} 
                              eventData={this.state.eventData} 
                            />
                          </div>
                        )
                    }
                  </div>
                </div>
                <CSVLink
                  data={this.state.bookingData}
                  filename={"asset_booking_activity.csv"}
                  target="_blank"
                  ref="csv"
                />
              </>
            )}
          <Link className="btn m-4 float-right text-white" style={{ backgroundColor: "#337AB7" }} to="/public/assets/booking_activity">Public Version <i className="fas fa-external-link-alt"></i></Link>
        </div>
      </div>
    );
  }
}


export default FacilityActivityDetail;
