import React, { Component } from "react"
import changeCase from "change-case"
import _ from "lodash"
import dateFns from "date-fns"
import { toast } from "react-toastify"

import Spinner from "../UI/ModalSpinner"
import Input from "../UI/input/Input"
import Button from "../UI/button/Button"
import FormButton from "../UI/button/FormButton"
import DatePicker from "../UI/datePicker"
import axios from "../../configAxios"

import "./AddDonationCampaign.css"

class AddorEditDonationCampaign extends Component {
  constructor(props) {
    super(props)
    this.signal = axios.CancelToken.source();
    this.state = {
      loading: true,
      name: {
        elementType: "input",
        elementConfig: {
          name: "name",
          placeholder: "Enter Name",
          required: true
        },
        value: ""
      },
      goalAmount: {
        elementType: "input",
        elementConfig: {
          name: "goalAmount",
          type: "number",
          step: 0.01,
          placeholder: "Enter Amount",
          required: true
        },
        value: ""
      },
      startDate: null,
      endDate: null,
      campaignStatus: null,
      selectedCampaignStatus: null,
      disabled: false
    }
  }

  componentDidMount = () => {
    axios.get(
      "/donation_campaigns/formdata",
      { cancelToken: this.signal.token }
    )
      .then(res => {
        const { status } = res.data.json;
        let selectedStatusKey = null,
          selectedStatus = null;
        if (!_.isEmpty(status)) {
          selectedStatusKey = Object.keys(status)[0];
          selectedStatus = status[selectedStatusKey];
        }
        this.setState({
          loading: false,
          campaignStatus: status,
          selectedCampaignStatus: selectedStatus
        }, () => {
          if (this.props.editCampaign) {
            this.setState({ loading: true });
            this.getCampaignInfo();
          }
        })
      })
  }

  getCampaignInfo = () => {
    axios.get(
      `/donation_campaigns/${this.props.campaignId}`,
      { cancelToken: this.signal.token }
    ).then(res => {
      const { success } = res.data.json;
      if (success) {
        const { data } = res.data.json;
        this.setState({
          loading: false,
          name: {
            ...this.state.name,
            value: data.name
          },
          goalAmount: {
            ...this.state.goalAmount,
            value: data.target_amount
          },
          startDate: new Date(data.start_date),
          endDate: new Date(data.end_date),
          selectedCampaignStatus: this.getStatusId(data.status)
        })
      }
    })
  }

  getStatusId = (status) => {
    const { campaignStatus } = this.state;
    let statusId = 0;
    Object.keys(campaignStatus).forEach(
      statusName => {
        if (statusName === status)
          statusId = campaignStatus[statusName]
      }
    )
    return statusId;
  }

  componentWillUnmount = () => {
    this.signal.cancel("Request is being cancelled");
  }

  checkDates = () => {
    const {
      startDate,
      endDate
    } = this.state;

    let flag = true,
      errorMessage = "";

    if (!startDate) {
      flag = false
      errorMessage = "Start date can't be empty"
    } else if (!endDate) {
      flag = false
      errorMessage = "End date can't be empty";
    } else if (dateFns.differenceInDays(new Date(endDate), new Date(startDate)) < 0) {
      flag = false
      errorMessage = "Start date can't be after End date"
    } else;

    if (!flag)
      toast.error(errorMessage);

    return flag;
  }

  submitHandler = e => {
    e.preventDefault();
    if (this.checkDates()) {
      const {
        name: { value: nameValue },
        goalAmount: { value: goalAmountValue },
        startDate,
        endDate,
        selectedCampaignStatus
      } = this.state;

      this.setState({ disabled: true })

      let formData = new FormData;
      formData.append("name", nameValue);
      formData.append("status", selectedCampaignStatus);
      formData.append("target_amount", goalAmountValue);
      formData.append("start_date", dateFns.format(new Date(startDate), 'DD-MM-YYYY'));
      formData.append("end_date", dateFns.format(new Date(endDate), 'DD-MM-YYYY'));

      if (!this.props.editCampaign) {
        axios.post("/donation_campaigns", formData)
          .then(res => {
            const { success } = res.data.json;
            if (success) {
              toast.success("Successfully added a new campaign");
              this.props.getUpdatedCampaigns();
              this.props.modalCloseHandler(e);
            } else {
              if (res.data.json.hasOwnProperty("errors")) {
                Object.keys(res.data.json.errors).forEach(error => {
                  toast.error(`${changeCase.title(error)} ${res.data.json.errors[error]}`);
                });
              }
              if (res.data.json.hasOwnProperty("error")) {
                toast.error(res.data.json.error);
              }
              this.setState({ disabled: false });
            }
          })
      } else {
        axios.put(`/donation_campaigns/${this.props.campaignId}`, formData)
          .then(res => {
            const { success } = res.data.json;
            if (success) {
              toast.success("Successfully updated campaign");
              this.props.getUpdatedCampaigns();
              this.props.modalCloseHandler(e);
            } else {
              if (res.data.json.hasOwnProperty("errors")) {
                Object.keys(res.data.json.errors).forEach(error => {
                  toast.error(`${changeCase.title(error)} ${res.data.json.errors[error]}`);
                });
              }
              if (res.data.json.hasOwnProperty("error")) {
                toast.error(res.data.json.error);
              }
              this.setState({ disabled: false });
            }
          })
      }
    }
  }

  inputChangeHandler = (event, key) => {
    if (key === "selectedCampaignStatus")
      this.setState({ [key]: event })
    else
      this.setState({
        [key]: {
          ...this.state[key],
          value: event.target.value
        }
      })
  }

  render() {
    const {
      loading,
      campaignStatus,
      selectedCampaignStatus,
      endDate,
      startDate,
      disabled
    } = this.state;

    return (
      <>
        {loading
          ? <Spinner /> :
          (<form
            className="custom-campaign-form"
            onSubmit={this.submitHandler}
          >
            <div className="mt-3 mb-2">Status</div>
            {Object.keys(campaignStatus).map(status => (
              <div
                key={campaignStatus[status]}
                className="form-check form-check-inline cursor"
                onClick={e => this.inputChangeHandler(campaignStatus[status], "selectedCampaignStatus")}
              >
                <input
                  className="form-check-input custom-radio"
                  type="radio"
                  name={status}
                  id={status}
                  value={status}
                  checked={campaignStatus[status] === selectedCampaignStatus}
                />
                <span className="custom-radio-button"></span>
                <label className="form-check-label ml-2 cursor" htmlFor={status}>
                  {changeCase.upperCaseFirst(status)}
                </label>
              </div>
            ))}
            <div className="mt-3">
              <label>Name</label>
              <Input
                {...this.state.name}
                changed={e => this.inputChangeHandler(e, "name")}
              />
            </div>
            <div>
              <label>Goal Amount</label>
              <Input
                {...this.state.goalAmount}
                changed={e => this.inputChangeHandler(e, "goalAmount")}
              />
            </div>
            <div className="form-row">
              <div className="col">
                <label>Start Date</label>
                <div className="custom-calendar">
                  <DatePicker
                    placeholder="YYYY.MM.DD"
                    dateFormat="yyyy.MM.dd"
                    selected={startDate}
                    changed={date => this.setState({ startDate: date })}
                  />
                  <i className="fas fa-calendar-alt custom-calendar-icon"></i>
                </div>
              </div>
              <div className="col">
                <label>End Date</label>
                <div className="custom-calendar">
                  <DatePicker
                    placeholder="YYYY.MM.DD"
                    dateFormat="yyyy.MM.dd"
                    selected={endDate}
                    changed={date => this.setState({ endDate: date })}
                  />
                  <i className="fas fa-calendar-alt custom-calendar-icon" />
                </div>
              </div>
            </div>
            <div className="text-center mt-3">
              <Button
                type="secondary"
                clicked={this.props.modalCloseHandler}
              >
                Cancel
              </Button>
              <FormButton
                disabled={disabled}
                className="ml-3"
              >
                {this.props.editCampaign ? "Update" : "Create"}
              </FormButton>
            </div>
          </form>)
        }
      </>
    )
  }
}

export default AddorEditDonationCampaign
