import React, { Component } from "react";
import dateFns from "date-fns";
import _ from "lodash"
import axios from "../../../configAxios";
import Spinner from "../../UI/Spinner";
import { Link } from "react-router-dom"
import Pagination from "../../UI/Pagination";

class History extends Component {
  signal = axios.CancelToken.source();
  state = {
    historyData: null,
    totalCount: null,
    selectedPage: 1
  };

  async componentDidMount() {
    const { historyData, historyLoading } = this.props;
    this.setState({
      historyData,
      totalCount: historyData && historyData.length,
      historyLoading
    });
  }

  componentWillReceiveProps = nextProps => {
    const { historyData, historyLoading } = nextProps;
    this.setState({
      historyData,
      totalCount: historyData.length,
      historyLoading,
      selectedPage: 1
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  createdAtHandler = date => {
    let createdAtDays = dateFns.differenceInDays(new Date(), new Date(date));
    let createdAtMonths = dateFns.differenceInMonths(
      new Date(),
      new Date(date)
    );
    let createdAtYears = dateFns.differenceInYears(new Date(), new Date(date));
    const createdAtHours = dateFns.differenceInHours(new Date(), new Date(date))
    const createdAtMinutes = dateFns.differenceInMinutes(new Date(), new Date(date));
    const createdAtSeconds = dateFns.differenceInSeconds(new Date(), new Date(date))
    if (createdAtYears !== 0) {
      return `${createdAtYears} ${createdAtYears === 1 ? "year" : "years"}`;
    }

    if (createdAtMonths !== 0) {
      return `${createdAtMonths} ${createdAtMonths === 1 ? "month" : "months"}`;
    }

    if (createdAtDays !== 0) {
      return `${createdAtDays} ${createdAtDays === 1 ? "day" : "days"}`;
    }
    if (createdAtHours !== 0) {
      return `${createdAtHours} ${createdAtHours === 1 ? "hour" : "hours"}`;
    }
    if (createdAtMinutes !== 0) {
      return `${createdAtMinutes} ${createdAtMinutes === 1 ? "mintue" : "minutes"}`;
    }
    if (createdAtSeconds !== 0) {
      return `${createdAtSeconds} ${createdAtSeconds === 1 ? "second" : "seconds"}`;
    }

  };

  render() {
    const { totalCount, selectedPage } = this.state;
    const { historyData, historyLoading: loading, historyType } = this.props;
    let historyContent = <Spinner />;
    if (!loading) {
      const start = selectedPage === 1 ? 0 : (selectedPage - 1) * 10;
      const end = selectedPage === 1 ? 9 : (selectedPage - 1) * 10 + 9;
      historyContent = historyData.slice(start, end).map(data => {
        let createdAt = this.createdAtHandler(data.created_at);
        if (data.hasOwnProperty("flag_type")) {
          return (
            <li className="list-group-item">
              <div className="row">
                <div className="col-md-3">{`flagged ${createdAt} ago`}</div>
                <div className="col-md-3">
                  <div>
                    <div>{`${data.name} / Flag Type: ${data.flag_type}`}</div>
                    <div>{data.description}</div>
                  </div>
                </div>
                <div className="col-md-3" />
                <div className="col-md-3">{`Flagged By: ${data.created_by}`}</div>
              </div>
            </li>
          );
        } else {
          let title;
          if (!_.isEmpty(data.user_or_program)) {
            if (data.user_or_program === "User") {
              title =
                data.user_or_program_detail.organization_name ||
                `${data.user_or_program_detail.first_name} ${
                data.user_or_program_detail.last_name
                }`;
            } else {
              title = data.user_or_program_detail.name;
            }
            return (
              <li className="list-group-item">
                <div className="row">
                  <div className="col-md-3">{`booking about ${createdAt} ago`}</div>
                  <div className="col-md-3">
                    <div>{`${dateFns.format(
                      data.details.start_time,
                      "ddd MMM DD"
                    )} to ${dateFns.format(
                      data.details.end_time,
                      "ddd MMM DD"
                    )}`}</div>
                    {data.checked_in_at && (
                      <div>{`Checked In on ${dateFns.format(
                        new Date(data.checked_in_at),
                        "ddd MMM DD YYYY hh:mmA"
                      )}`}</div>
                    )}
                  </div>
                  <div className="col-md-3">{`${
                    data.ticket.project_name
                      ? data.ticket.project_name
                      : "no project name"
                    }`}</div>
                  <div className="col-md-3">
                    {data.user_or_program === "User"
                      ? <Link to={`/users/${data.user_or_program_detail.id}/summary`} className="text-info">
                        {title}
                      </Link>
                      : <Link to={`/programs/${data.user_or_program_detail.id}`} className="text-info">
                        {title}
                      </Link>
                    }
                  </div>
                </div>
              </li>
            );
          } else {
            return null;
          }
        }
      });
    }
    return (
      <div className="container col-md-12">
        {!loading && totalCount === 0 
        ? <h4 className="text-center my-4">
          No {historyType} found
        </h4>
        :
        <>
          <ul className="list-group">{historyContent}</ul>
          <Pagination
            pageCount={totalCount / 10}
            forcePage={selectedPage - 1}
            handlePageClick={page => {
              window.scrollTo(0, 0)
              this.setState({
                ...this.state,
                selectedPage: page.selected + 1
              })
            }}
          />
        </>
        }
      </div>
    );
  }
}

export default History;
