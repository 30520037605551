import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { title } from "change-case"
import { toast } from "react-toastify"
import queryString from "query-string"
import AssetItems from "./AssetItems"
import axios from "../../../configAxios"
import BookedAssets from "./BookedAssets"
import Spinner from "../../UI/Spinner"

class Bookings extends Component {
  signal = axios.CancelToken.source()
  state = {
    ticketId: null,
    ticketNotes: null,
    loading: true,
    bookedAssets: {},
    manageBookings: null,
  }

  componentDidMount() {
    let values
    if (this.props.history) {
      values = queryString.parse(this.props.history.location.search)
    } else {
      values = queryString.parse(this.props.location.search)
    }
    if (values.ticketId) {
      this.setState(
        {
          ticketId: values.ticketId,
        },
        () => this.getTicket()
      )
    } else {
      this.createTicket()
    }
    this.getUserPolicy()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getUserPolicy = () => {
    axios
      .get("/users/policy", { params: { id: this.props.userId } })
      .then((res) => {
        this.setState({
          manageBookings: res.data.json.manage_bookings,
        })
      })
  }

  getTicket = () => {
    axios
      .get(`/tickets/${this.state.ticketId}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          bookedAssets: res.data.ticket,
          ticketNotes: res.data.ticket.notes,
          loading: false,
        })
      })
  }

  createTicket = () => {
    axios
      .post("/tickets", {
        type: "User",
        user_id: this.props.userId,
      })
      .then((res) => {
        if (res.data.json.success) {
          this.setState(
            {
              ticketId: res.data.json.data.id,
            },
            () => this.getTicket()
          )
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
      .catch((err) => console.log(err))
  }

  render() {
    const { ticketId, bookedAssets } = this.state
    let content
    if (this.state.loading) {
      content = <Spinner />
    } else {
      if (ticketId) {
        content = (
          <div className="row booking">
            <div className="col-lg-6 mb-4">
              <BookedAssets
                manageBookings={this.state.manageBookings}
                ticketId={ticketId}
                ticketNotes={this.state.ticketNotes}
                userId={this.props.userId}
                userData={this.props.userData}
                bookedAssets={bookedAssets}
                getTicket={() => this.getTicket()}
                disabled={!bookedAssets.bookings}
                createTicket={() => this.createTicket()}
                tabChangeHandler={this.props.tabChangeHandler}
              />
            </div>
            <div className="col-lg-6">
              <AssetItems
                manageBookings={this.state.manageBookings}
                ticketId={ticketId}
                userId={this.props.userId}
                getTicket={() => this.getTicket()}
                tabChangeHandler={this.props.tabChangeHandler}
              />
            </div>
          </div>
        )
      } else {
        content = (
          <div>
            <Spinner />
          </div>
        )
      }
    }
    return <div className="p-3 webkit-fill-available">{content}</div>
  }
}

export default withRouter(Bookings)
