import React from 'react'
import Input from "../../../../UI/input/Input";

export default function MandatoryCheckbox({ isMandatory, setIsMandatory, isEditing }) {
  return (
    <div>
      <Input
        elementType="checkbox"
        questionText={"Mandatory"}
        elementConfig={{
          type: 'checkbox',
          checked: isMandatory,
        }}
        changed={setIsMandatory}
        disabled={!isEditing}
      />
    </div>
  )
}
