import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";
import { withRouter } from "react-router-dom";

import axios from "../../../configAxios";

import Button from "../../UI/button/Button";
import Input from "../../UI/input/Input";
import FormButton from "../../UI/button/FormButton";

import translateModuleName from "../../../utils/translateModuleName"

class NewProjectAttribute extends Component {
  signal = axios.CancelToken.source();
  state = {
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true
      },
      label: "Attribute Name",
      value: ""
    },
    category: {
      elementType: "select",
      elementConfig: {
        name: "category",
        type: "text",
        placeholder: "Select Attribute Type",
        options: [{ value: "Short Attributes", label: "Short Attributes" }, { value: "Long Attributes", label: "Long Attributes" }, { value: "Crew Role", label: translateModuleName("Crew Role") }],
        isLoading: this.props.edit,
        required: true
      },
      label: "Category",
      selectedOption: null
    },
    uiType: {
      elementType: "select",
      elementConfig: {
        name: "uiType",
        type: "text",
        placeholder: "Select UI Type",
        options: [{ label: "Text Field", value: 0 }, { label: "Text Area", value: 1 }, {label: "File", value: 2}, {label: "Duration Picker", value: 3}],
        isLoading: this.props.edit
      },
      label: "UI type",
      selectedOption: null
    },
    shortAttributesUiTypes: [
      {label: "Text Field", value: 0},
      {label: "Duration Picker", value: 3}
    ],
    longAttributesUiTypes: [{ label: "Text Area", value: 1 }],
    crewUiTypes: [{ label: "Text Field", value: 0 }],
    isChecked: false,
    disabled: false
  };

  componentDidMount() {
    if (this.props.edit) {
      axios
        .get("/project_attribute_types/" + this.props.id, {
          cancelToken: this.signal.token
        })
        .then(res => {
          const { project_attribute_type } = res.data;
          let options = [];
          switch(project_attribute_type.category) {
            case "Short Attributes":
              options = this.state.shortAttributesUiTypes;
              break;
            case "Long Attributes":
              options = this.state.longAttributesUiTypes;
              break;
            case "Crew Role":
              options = this.state.crewUiTypes;
              break;
            default:
              options = this.state.defaultOptions;
          }
          this.setState({
            category: {
              ...this.state.category,
              elementConfig: {
                ...this.state.category.elementConfig,
                isLoading: false
              },
              selectedOption: {
                label: translateModuleName(project_attribute_type.category),
                value: project_attribute_type.category
              }
            },
            name: {
              ...this.state.name,
              value: project_attribute_type.name
            },
            uiType: {
              ...this.state.uiType,
              elementConfig: {
                ...this.state.uiType.elementConfig,
                options,
                isLoading: false
              },
              selectedOption: project_attribute_type.ui_type && {
                label: title(project_attribute_type.ui_type),
                value: project_attribute_type.ui_type_value
              }
            },
            isChecked: project_attribute_type.visible
          });
        });
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  closeModal = () => {
    this.props.closeModal();
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    if (key === "name") {
      inputValue["value"] = event.target.value;
    } else {
      inputValue["selectedOption"] = event;
      let options = [];
      if(key === "category" && event) {
        switch(event.value) {
          case "Short Attributes":
              options = this.state.shortAttributesUiTypes;
              break;
            case "Long Attributes":
              options = this.state.longAttributesUiTypes;
              break;
            case "Crew Role":
              options = this.state.crewUiTypes;
              break;
            default:
              options = this.state.defaultOptions;
        }
      } else options = this.state.defaultOptions
      this.setState({
        uiType: {
          ...this.state.uiType,
          elementConfig: {
            ...this.state.uiType.elementConfig,
            options
          },
          selectedOption: null
        }
      })
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let attrData = {};
    const { name, category, uiType } = this.state;
    attrData["name"] = name.value;
    if (category.selectedOption) {
      attrData["category"] = category.selectedOption.value;
    }
    if (uiType.selectedOption) {
      attrData["ui_type"] = uiType.selectedOption.value;
    }

    attrData["visible"] = this.state.isChecked;

    if (this.props.edit) {
      axios
        .put(`/project_attribute_types/${this.props.id}`, attrData)
        .then(res => {
          if (res.data.json.success) {
            toast.success(`${translateModuleName(category.selectedOption.value)} Successfully updated`);
            this.props.closeModal();
            this.props.attrTypeList();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disabled: false })
        });
    } else {
      axios.post("/project_attribute_types", attrData).then(res => {
        if (res.data.json.success) {
          toast.success(`${translateModuleName(category.selectedOption.value)} Successfully created`);
          this.props.closeModal();
          this.props.tabChangeHandler(category.selectedOption.value);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(error + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
        this.setState({ disabled: false })
      });
    }
  };

  render() {
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        <div>
          <Input
            {...this.state.category}
            disabled={this.props.edit ? true : false}
            className="project-attr-category"
            changed={event => this.inputChangedHandler(event, "category")}
          />
          <Input
            {...this.state.name}
            changed={event => this.inputChangedHandler(event, "name")}
          />
          <Input
            {...this.state.uiType}
            changed={event => this.inputChangedHandler(event, "uiType")}
          />
          <div className="use-checkbox">
            <input
              type="checkbox"
              id="visible-checkbox"
              checked={this.state.isChecked}
              onClick={() =>
                this.setState({ isChecked: !this.state.isChecked })
              }
            />{" "}
            <label for="visible-checkbox" className="cursor">Visible</label>
          </div>
          <div className="text-center">
            <Button type="secondary" clicked={this.props.closeModal}>
              Cancel
            </Button>
            <FormButton className="ml-2" disabled={this.state.disabled}>
              {this.props.edit ? "Update Attribute Type" : "Create Attribute Type"}
            </FormButton>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(NewProjectAttribute);
