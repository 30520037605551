import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getGrantCampaign,
  selectGrantCampaignById,
  selectTotalGrantCampaigns,
} from "../../../store/slices/grantCampaign";
import Spinner from "../../UI/Spinner";
import Tabs from "./Tabs";

export const RefreshGrantCampaignContext = React.createContext()

class Campaign extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getGrantCampaign(this.props.match.params.id);
  }

  isAdmin = () => {
    return ["super_admin", "site_admin", "staff"].includes(this.props.roleLevel)
  }

  render() {
    const { grantCampaign, loading } = this.props;
    if (!grantCampaign || loading) {
      return <Spinner/>;
    }

    return (
      <div className="container w-100 p-0">
        <div className="row mb-4">
          <div className="col-12">
            <h3>{grantCampaign.name}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.props.grantCampaign && this.props.userId &&
            <RefreshGrantCampaignContext.Provider value={() => this.props.getGrantCampaign(this.props.match.params.id)}>
              <Tabs grantCampaign={grantCampaign} roleLevel={this.props.roleLevel}
                    isAdmin={() => this.isAdmin()}/>
            </RefreshGrantCampaignContext.Provider>
            }
          </div>
        </div>
      </div>
    );
  }
}

Campaign.propTypes = {
  grantCampaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  totalCount: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const roleLevel = state.auth.roleLevel
  const totalCount = selectTotalGrantCampaigns(state);

  return {
    userId: Number(state.auth.userId),
    grantCampaign: selectGrantCampaignById(state, id),
    roleLevel,
    totalCount,
    loading: state.grantCampaigns.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGrantCampaign: (id) => dispatch(getGrantCampaign(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
