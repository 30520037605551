import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { title, snakeCase } from "change-case";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Button from "../UI/button/Button";
import langUtils from "../../utils/langUtils";

class Languages extends Component {
  signal = axios.CancelToken.source();
  state = {
    languages: [],
    loading: true,
    disabled: false
  };

  componentDidMount() {
    window.scrollTo(0, 0)

    axios.get("/base_languages", { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          languages: res.data.base_languages,
          disabled: false,
          loading: false
        });
      })
  }

  deleteLanguage = (event, languageId) => {
    let response = window.confirm("Are you sure you want to delete the Language?");
    if (response) {
      axios.delete(`/base_languages/${languageId}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Language deleted successfully")
            this.setState({
              ...this.state,
              languages: this.state.languages.filter(language => language.id !== languageId)
            })
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  render() {
    return (
      <div className="container language w-100">
        <div className="row">
          <div className="col-6 text-lg-right text-center offset-6">
            <div className="mr-2 mt-4">
              <Link to="/languages/new/">
                <Button type="success">
                  {langUtils("txt_global_add_new", "Add New")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body mt-4 p-0">
            <div className="card-text">
              {
                this.state.loading ? <Spinner /> : (
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col-5">
                          <span className="font-weight-bold">Name</span>
                        </div>
                        <div className="col-4 text-center">
                          <span className="font-weight-bold">Total Words</span>
                        </div>
                      </div>
                    </div>
                    {
                      this.state.languages.map(language => (
                        <div className="list-group-item">
                          <div className="row">
                            <div className="col-5">{language.name}</div>
                            <div className="col-4 text-center">
                              {language.locale ? Object.keys(language.locale).length : 0}
                            </div>
                            <div className="col-3 d-flex justify-content-center">
                              <Link to={`/languages/${language.id}/edit`} className="text-muted">
                                <i className="fas fa-pencil-alt cursor pr-5" />
                              </Link>
                              <i
                                className="fas fa-trash cursor"
                                onClick={event => language.can_delete && this.deleteLanguage(event, language.id)}
                                style={{ cursor: language.can_delete ? "pointer" : "no-drop", opacity: language.can_delete ? 1 : 0.7 }}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Languages;
