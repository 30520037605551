import React from 'react'
import Input from "../../../../UI/input/Input";

export default function MultiQuestionOption( {option, setOptionText, removeOption, isEditing }) {

  return (
    <div className="d-flex flex-row">
      <div className="w-100 d-flex flex-column">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <span>Option {option.option_order + 1}</span>
          { isEditing &&
            <span className="cursor fas fa-sm fa-times mb-1" onClick={() => removeOption(option)}/>
          }
        </div>
        <Input 
          elementType="textarea"
          elementConfig={{
            name: "option_text",
            required: true,
            rows: 1,
            readOnly: !isEditing,
          }}
          changed={event => setOptionText(option, event.target.value)}
          value={option.option_text}
          label="Question Text"
        />
      </div>
    </div>
  )
}
