import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import AuthContainer from "./AuthContainer"
import Button from "../UI/button/Button"
import Logo from "./formComponents/Logo"

const ConfirmPasswordUpdate = ({ loginButtonStatus, history }) => {
  return (
    <AuthContainer>
      <div className="card-body">
        <div className="card-text p-3">
          <Logo />
          <div className="text-dark text-center">
            Your password has successfully been updated.
          </div>
          {loginButtonStatus && (
            <div className="d-flex mt-4 justify-content-center">
              <Button type="success" clicked={() => history.push('/login')}>
                Login
              </Button>
            </div>
          )}
        </div>
      </div>
    </AuthContainer>
  )
}

const mapStateToProps = (state) => {
  return { loginButtonStatus: state.loginAssets.loginButtonStatus }
}

export default withRouter(connect(mapStateToProps)(ConfirmPasswordUpdate))
