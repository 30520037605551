import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import apiResponse from "./reducers/apiResponse";
import projectsReducer from "./reducers/project";
import loginAssetsReducer from "./reducers/loginAssetsReducer";
import grantCampaignsReducer from "./slices/grantCampaign";
import formsReducer from "./slices/formSlice";
import formAttributesReducer from "./slices/formAttributes";
import submissionAnswersReducer from "./slices/submissionAnswer";
import submissionsReducer from "./slices/submission";
import grantApplicationsReducer from "./slices/grantApplicationSlice"
import organizationReducer from "./reducers/organizationReducer";

const storeConfig = (preloadedState) => {
  return configureStore({
    reducer: {
      auth: authReducer,
      response: apiResponse,
      projects: projectsReducer,
      loginAssets: loginAssetsReducer,
      organization: organizationReducer,
      grantCampaigns: grantCampaignsReducer,
      submissions: submissionsReducer,
      forms: formsReducer,
      formAttributes: formAttributesReducer,
      submissionAnswers: submissionAnswersReducer,
      grantApplications: grantApplicationsReducer,
    },
    preloadedState,
  });
};

export default storeConfig;
