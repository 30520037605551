import React from "react"
import Spinner from "../../UI/Spinner"

const RentalStatistics = ({ metaData, rentals, loading }) => {
  return (
    <div className="row mt-5">
      <div className="col-12">
        <h5 className="font-weight-bold">
          Statistics
        </h5>
        {loading && <Spinner className="h-30vh"/>}

        {!loading &&
        <>
          <div className="row">
            <div className="col-4">
              Total Bookings for the Period:
            </div>
            <div className="col-8">
              {metaData.total_bookings}
            </div>
            <div className="col-4">
              Total Revenue from Bookings:
            </div>
            <div className="col-8">
              {metaData.total_revenue_from_bookings}
            </div>
            <div className="col-4">
              {`${metaData.current_organization_name}'s Cut: `}
            </div>
            <div className="col-8">
              {metaData.organizations_cut}
            </div>
            <div className="col-4">
              Asset Owner's Cut:
            </div>
            <div className="col-8">
              {metaData.asset_owner_cut}
            </div>
          </div>
        </>
        }
      </div>
    </div>
  )
}

export default RentalStatistics
