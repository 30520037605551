import React from "react"
import Button from "../../UI/button/Button"
import Modal from "../../UI/Modal"

const LabelCategoryModal = ({
                              showLabelCategoryModal, setLabelCategoryModal, labelCategoryName,
                              setLabelCategoryName, clicked, modalTitle
                            }) => {

  return (
    <Modal
      show={showLabelCategoryModal}
      title={`${modalTitle} Label Category`}
      hide={() => setLabelCategoryModal(false)}
    >
      <div className="tag-form mt-3">
        <label htmlFor={`label-name`}>
          <span className="text-capitalize">Label Category Name</span>
        </label>
        <div className="tag-form-input position-relative">
          <input
            placeholder="Enter Label Category Name"
            className="form-control"
            value={labelCategoryName}
            onChange={(event) => setLabelCategoryName(event.target.value)}
          />
        </div>
        <div className="text-center mt-3">
          <Button
            type="secondary"
            clicked={() => {
              setLabelCategoryModal(false)
            }}
            className="mr-2"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            clicked={() => {
              clicked()
              setLabelCategoryModal(false)
            }}
          >
            {modalTitle}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default LabelCategoryModal
