import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { title } from "change-case"

import axios from "../../configAxios"
import Input from "../UI/input/Input"
import Button from "../UI/button/Button"
import FormButton from "../UI/button/FormButton"
import { createProject, clearResponse, getAdminStatus } from "../../store/actions/index"

class CreateProject extends Component {
  signal = axios.CancelToken.source();
  timeout = 0;
  state = {
    createProject: {
      projectName: {
        elementType: "input",
        elementConfig: {
          name: "project_name",
          type: "text",
          required: true
        },
        value: "",
        label: "Project Name"
      },
      user: {
        elementType: "asyncSelect",
        elementConfig: {
          name: "user_id",
          placeholder: "Select User",
          required: true,
          defaultValue: this.props.isUserBooking && { value: this.props.userData.id, label: this.props.userData.full_name },
          loadOptions: (inputValue, callback) => {
            if (inputValue.length > 2) this.searchUsers(inputValue, callback);
          },
          hidden: true,
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          }
        },
        label: "Assign User",
        selectedOption: this.props.isUserBooking && { value: this.props.userData.id, label: this.props.userData.full_name },
        hide: false
      }
    },
    createUser: {
      firstName: {
        elementType: "input",
        elementConfig: {
          name: "user_first_name",
          type: "text"
        },
        label: "First Name",
        value: ""
      },
      lastName: {
        elementType: "input",
        elementConfig: {
          name: "user_last_name",
          type: "text"
        },
        label: "Last Name",
        value: ""
      },
      userName: {
        elementType: "input",
        elementConfig: {
          name: "user_login",
          type: "text",
          required: true
        },
        label: "User Name",
        value: ""
      },
      mobile: {
        elementType: "input",
        elementConfig: {
          name: "user_mobile_phone",
          type: "text"
        },
        label: "Mobile",
        value: ""
      },
      email: {
        elementType: "input",
        elementConfig: {
          name: "user_email",
          type: "email"
        },
        label: "Email",
        value: ""
      }
    },
    projectModal: true,
    userFields: false,
    showModal: true,
    enteredUserCharacters: "",
    disabled: false
  };

  componentDidMount() {
    const { getAdminStatus, auth: { userId } } = this.props

    getAdminStatus(userId)
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.response.hasOwnProperty("success") &&
      nextProps.response.success !== null
    ) {
      const { message, success } = nextProps.response;
      this.props.clearResponse();
      if (success) {
        this.props.closeModal(true);
        this.props.history.push("/projects");
        toast.success(message);
      } else {
        toast.error(message);
      }
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  closeModal = () => {
    this.props.closeModal();
  };

  searchUsers = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue
      },
      cancelToken: this.signal.token
    };
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getUsers(queryParams, callback);
    }, 500);
  };

  getUsers = (queryParams, callback) => {
    axios.get("/users/autocomplete", queryParams)
      .then(res => {
        callback(
          res.data.users.map(user => {
            return {
              value: user.id,
              label: user.full_name
            };
          })
        );
      })
      .catch(err => err);
  }

  inputChangedHandler = (event, key, formName) => {
    let inputValue = {};
    if (key !== "user") {
      inputValue["value"] = event.target.value;
    } else {
      inputValue["selectedOption"] = event;
    }

    const updatedcontrols = {
      ...this.state[formName],
      [key]: {
        ...this.state[formName][key],
        ...inputValue
      }
    };
    this.setState({ [formName]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const projectData = {};
    const { userFields, createProject, createUser } = this.state;
    projectData["project_name"] = createProject.projectName.value;
    if (userFields) {
      Object.keys(createUser).forEach(key => {
        const keyValue = createUser[key].elementConfig.name;
        projectData[keyValue] = createUser[key].value;
      });
    } else {
      projectData["user_id"] = createProject.user.selectedOption.value;
    }
    if (this.props.isUserBooking) {
      axios.post("/projects", projectData).then(res => {
        if (res.data.json.success) {
          toast.success("Project successfully created.");
          this.props.setProjectName({ label: res.data.json.data.name, value: res.data.json.data.id }, createProject.user.selectedOption.value);
          this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      })
    } else {
      this.props.createProject(projectData, this.props.history);
    }
  };

  render() {
    let createProjectFormElements = [];
    let createUserFormElements = [];
    const { isAdmin } = this.props.auth

    for (let key in this.state.createProject) {
      createProjectFormElements.push({
        index: key === "projectName" ? 0 : 1,
        id: key,
        config: this.state.createProject[key]
      });
    }
    for (let key in this.state.createUser) {
      let index;
      switch (key) {
        case "firstName":
          index = 3
          break
        case "lastName":
          index = 4
          break
        case "userName":
          index = 5
          break
        case "mobile":
          index = 6
          break
        case "email":
          index = 7
          break
        default:
          index = 9
      }
      createUserFormElements.push({
        index: index,
        id: key,
        config: this.state.createUser[key]
      });
    }

    createProjectFormElements = createProjectFormElements.sort((val1, val2) => val1.index > val2.index)
    const createProjectForm = createProjectFormElements.map(formElement => {
      if (formElement.id === 'projectName' || (formElement.id === 'user' && isAdmin)) {
        return (
          <Input
            key={formElement.id}
            {...formElement.config}
            changed={event =>
              this.inputChangedHandler(event, formElement.id, "createProject")
            }
            onInputChange={event => this.setState({ enteredUserCharacters: event })}
          />
        )
      }
    });

    createUserFormElements = createUserFormElements.sort((val1, val2) => val1.index > val2.index)
    const createUserForm = createUserFormElements.map(formElement => (
      <Input
        key={formElement.id}
        {...formElement.config}
        changed={event =>
          this.inputChangedHandler(event, formElement.id, "createUser")
        }
      />
    ));

    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        {createProjectForm}
        {isAdmin && (
          <div className="text-right mb-1">
            {this.state.userFields ? "User already exist, " : "User doesn't exist, "}
            <span
              className="text-primary cursor"
              onClick={() =>
                this.setState({
                  userFields: !this.state.userFields,
                  createProject: {
                    ...this.state.createProject,
                    user: {
                      ...this.state.createProject.user,
                      hide: !this.state.createProject.user.hide
                    }
                  }
                })
              }
            >
              {this.state.userFields ? "Assign user" : "Please create it"}
            </span>
          </div>
        )}
        {this.state.userFields ? createUserForm : ""}
        <div className="text-center mt-3">
          <Button type="secondary" clicked={() => this.props.closeModal()}>
            Cancel
          </Button>
          <FormButton className="ml-2" disabled={this.state.disabled}>Create Project</FormButton>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    response: state.response
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createProject: (projectData, history) =>
      dispatch(createProject(projectData, history)),
    clearResponse: () => dispatch(clearResponse()),
    getAdminStatus: (id) => dispatch(getAdminStatus(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CreateProject));
