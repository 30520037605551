import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "../../../configAxios";
import AddNew from "./AddNew";
import EditRental from "./EditRental";
import ViewRental from "./ViewRental";
import Button from "../../UI/button/Button";
import Modal from "../../UI/Modal";
import Spinner from "../../UI/Spinner";
import errorStatusHandler from "../../errorPages/UserNotAuthorized";
import langUtils from "../../../utils/langUtils";

class Locations extends Component {
  signal = axios.CancelToken.source();
  state = {
    locationsList: [],
    newModal: false,
    viewModal: false,
    editModal: false,
    location: {},
    loading: true,
    editLocation: false
  };

  componentDidMount() {
    this.getLocations();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getLocations = () => {
    axios.get("/locations", { cancelToken: this.signal.token }).then(res => {
      if(res.status === 202) {
        errorStatusHandler(res);
        this.props.tabChangeHandler("Categories");
      } else {
        this.setState({ locationsList: res.data.locations, loading: false });
      }
    })
  };

  deleteLocation = (locationId) => {
    let response = window.confirm("Are you sure you want to delete the location?");
    if (response) {
      axios
        .delete(`/locations/${locationId}`, { cancelToken: this.signal.token })
        .then(res => {
          if (res.data.json.success){
            let locationsList = this.state.locationsList;
            locationsList = locationsList.filter((location) => {
              return location.id !== locationId;
            });
            this.setState({locationsList})
          }
        });
    }
  }

  newModal = () => {
    window.scrollTo(0, 0);
    this.setState({ newModal: true });
  };

  closeModal = () => {
    this.setState({ viewModal: false });
  };

  editModal = location => {
    window.scrollTo(0, 0);
    this.setState({ location, editModal: true, editLocation: true });
  };

  viewModal = location => {
    window.scrollTo(0, 0);
    this.setState({ location, viewModal: true });
  };

  render() {
    const locations = this.state.locationsList;
    let locationsList;
    if (this.state.loading) {
      locationsList = <Spinner />;
    } else {
      locationsList = locations.map(location => (
        <li className="list-group-item location rounded-0">
          <div className="row">
            <div className="col-md-9">
              <Link to={{
                pathname: "/assets",
                search: `?locationValue=${location.id}&locationLabel=${location.name}`
              }}>
                {location.name}
              </Link>
              <div>
                {location.asset_count || 0} assets with this location
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex justify-content-between text-muted px-5">
                <i
                  className="fas fa-eye cursor location-view"
                  onClick={() => this.viewModal(location)}
                />
                <i
                  className="fas fa-pencil-alt cursor location-edit"
                  onClick={() => this.editModal(location)}
                />
                <i
                  className={`fas fa-trash ${location.can_destroy ? "cursor" : ""}`}
                  style={{ cursor: location.can_destroy ? "pointer" : "no-drop", opacity: location.can_destroy ? 1 : 0.7 }}
                  onClick={() => {location.can_destroy && this.deleteLocation(location.id)}}
                />
              </div>
            </div>
          </div>
        </li>
      ));
    }
    return (
      <div className="locations">
        <ul className="list-group list-group-flush mt-4">
          <li className="list-group-item">
            <div className="row">
              <div className="col-md-9">
                <span className="font-weight-bold">Name</span>
              </div>
              <div className="col-md-3 text-center">
                <Button type="success" clicked={this.newModal}>
                  {langUtils("txt_global_add_new", "Add New")}
                </Button>
              </div>
            </div>
          </li>
          {locationsList}
        </ul>
        <Modal
          show={this.state.newModal}
          title="New Location"
          hide={() => this.setState({ newModal: false })}
        >
          <AddNew
            type="location"
            editLocation={this.state.editLocation}
            getLocations={this.getLocations}
            closeModal={() => this.setState({ newModal: false })}
          />
        </Modal>
        <Modal
          show={this.state.editModal}
          title="Edit Location"
          hide={() => this.setState({ editModal: false })}
        >
          <EditRental
            type="location"
            location={this.state.location}
            getLocations={this.getLocations}
            closeModal={() => this.setState({ editModal: false })}
          />
        </Modal>
        <Modal
          show={this.state.viewModal}
          title="View Location"
          closeButton
          closeModal={() => this.closeModal()}
          hide={() => this.setState({ viewModal: false })}
        >
          <ViewRental
            type="location"
            location={this.state.location}
            getLocations={this.getLocations}
          />
        </Modal>
      </div>
    );
  }
}

export default Locations;
