import React from "react";
import { Link } from "react-router-dom";
import dateFns from "date-fns";

const ProgramItem = props => {
  let percent = (props.program.number_of_enrolled / props.program.max_participants) * 100;

  let bgColor = '';
  let color = ''
  if (percent > 60) {
    bgColor = 'bg-success'
    color = 'text-success'
  } else if (percent >= 40) {
    bgColor = 'bg-warning'
    color = 'text-warning'
  } else {
    bgColor = 'bg-danger'
    color = 'text-danger'
  }

  return (
    <li className="list-group-item p-3">
      <div className="row">
        <div className="col-7">
          <Link to={`/programs/${props.program.id}`}>
            <span className="text-info">{props.program.name}</span>
          </Link>
          <br />
          {dateFns.format(new Date(props.program.earliest_scheduled_program_date), "ddd, MMM DD")}
        </div>
        <div className="col-5 px-0 d-flex flex-column justify-content-center align-items-center">
          <p>
            Occupied: <span className={`font-weight-bold ${color}`}>{percent.toFixed(1)}%</span>
          </p>
          <div className="progress border-radius-20 w-80 h-15 mb-0">
            <div
              className={`progress-bar border-radius-20 ${bgColor}`}
              style={{ width: `${percent}%`, borderRadius: '20px' }}
              role="progressbar"
              aria-valuenow={percent}
              aria-valuemin="0"
              aria-valuemax="100">
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ProgramItem;