import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const collectionListItem = props => (
  <li className="list-group-item">
    <div className="row">
      <div className="col-1">
        {<OverlayTrigger key={"top"} placement={"top"}
            overlay={
          <Popover>
            <div>
              Collection ID: <span className="font-weight-bold">{props.collection.id}</span>
            </div>
          </Popover>
            }
          >
            <i className="fa fa-info-circle text-muted cursor" aria-hidden="true"/>
        </OverlayTrigger>}
      </div>
      <div className="col-9" onClick={() => props.showCollectionModal()}>
        {props.collection.name}
      </div>
      <div className="col-2">
        <div className="d-flex justify-content-around">
          <i
            className="fas fa-pencil-alt cursor"
            onClick={() => { window.scrollTo(0, 0); props.editModal() }}
          />
          <i
            className="fas fa-trash cursor"
            onClick={() => props.deleteCollection(props.collection.id)}
          />
        </div>
      </div>
    </div>
  </li>
);

export default collectionListItem;
