import React, { Component } from "react";
import Input from "../../UI/input/Input";

class SearchFilter extends Component {
  state = {
    itemsPerPage: {
      elementType: "select",
      elementConfig: {
        name: "type",
        options: [
          { label: "10", value: 10 },
          { label: "20", value: 20 },
          { label: "30", value: 30 },
          { label: "50", value: 50 },
          { label: "100", value: 100 }
        ],
        isSearchable: false,
        defaultValue: this.props.perPage
          ? { label: `${this.props.perPage}`, value: this.props.perPage }
          : { label: "10", value: 10 },
        isClearable: false
      },
      customStyle: {
        menu: styles => ({ ...styles, zIndex: "3" })
      }
    },
    perPage: this.props.perPage
  };

  inputChangeHandler = event => {
    this.setState(
      {
        ["itemsPerPage"]: {
          ...this.state["itemsPerPage"],
          selectedOption: event
        }
      },
      () => this.props.inputChangeHandler(event.value)
    );
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-6 text-right">
            <div className="py-2">Show entries:</div>
          </div>
          <div className="col-6">
            <div className="pr-4">
              <Input
                id="search-entry-filter"
                {...this.state.itemsPerPage}
                changed={this.inputChangeHandler}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SearchFilter;
