import React from "react"

const LabelHeader = () => {
  return (
    <div className="list-group-item">
      <div className="row">
        <div className="col-6">
          <span className="font-weight-bold">Label</span>
        </div>
        <div className="col-4 text-center">
          <span className="font-weight-bold">Label Count</span>
        </div>
        <div className="col-2" />
      </div>
    </div>
  )
}

export default LabelHeader
