import React, { useContext } from 'react'
import Button from "../../UI/button/Button";
import CsvExport from "./CsvExport";
import { useDispatch } from 'react-redux'
import { deleteObject } from "../../../store/actions/index"
import { getGrantApplications } from "../../../store/slices/grantApplicationSlice";

export default function ApplicationFormItem({form, applicationFormItem, grantApplication}) {

  const formUrl = `/grant_applications/${grantApplication?.grant_campaign_id}/form/${form?.id}/application/${grantApplication.id}/apply`
  const viewCompletedForm = `/grant_applications/${grantApplication?.id}/application_form/${applicationFormItem?.id}/show`
  const goToForm = (form) => window.location.href=`${form}`

  const dispatch = useDispatch()

  const deleteApplicationForm = () => {
    const applicationParams = {
      route: `/application_forms/${applicationFormItem.id}`,
      data: {},
      callback: getGrantApplications,
      message: "Application form deleted successfully"
      }

    window.confirm("Permanently delete this application form?") && dispatch(deleteObject(applicationParams)).then(() => {
      dispatch(getGrantApplications())
    })
  }

  const ActionIcons = () => {
    return (
      <>
        { applicationFormItem.status !== "Complete" && (
          <>
            <span className="mr-1 fas fa-pen cursor" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => goToForm(formUrl)} />
            {" "}
            <span className="ml-1 fas fa-trash cursor" data-toggle="tooltip" data-placement="top" title="Delete" onClick={deleteApplicationForm} />
          </>
        )}
        { applicationFormItem.status === "Complete" && (
          <CsvExport
            formId={applicationFormItem?.id}
            goToForm={goToForm}
            viewCompletedForm={viewCompletedForm}
          />
        )}
      </>
    )
  }

  const Actions = () => {
    return (
      <>
        { applicationFormItem 
          ?
            <ActionIcons />
          :
            <Button className="py-0" type="success" clicked={() => goToForm(formUrl)}>Start</Button>
        }
      </>
    )
  }

  return (
      <tr>
        <td>{form.name}</td>
        <td>{applicationFormItem && applicationFormItem.updated_when || "N/A"}</td>
        <td>{applicationFormItem && applicationFormItem.status || "Not Started"}</td>
        <td className="py-0 align-items-center">
          <Actions />
        </td>
      </tr>
  )
}
