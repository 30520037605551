import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import { getProjects } from "../../store/actions/index";
import FormButton from "../UI/button/FormButton";

class CampaignSearch extends Component {
  constructor() {
    super();
    this.signal = axios.CancelToken.source();
    this.timeout = 0;
    this.state = {
      controls: {
        queryString: {
          elementType: "input",
          elementConfig: {
            name: "query_string",
            placeholder: "Enter form name",
          },
          value: "",
        },
      },
    };
    this.textInput = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get("/projects/filters", { cancelToken: this.signal.token })
      .then(res => {
        const { projects: types } = res.data;
        this.setState({
          controls: {
            ...this.state.controls,
            type: {
              ...this.state.controls.type,
              elementConfig: {
                ...this.state.controls.type.elementConfig,
                options: types.map(type => {
                  return { value: type, label: type };
                }),
              },
            },
          },
        });
      })
      .catch(error => error);
    this.textInput.current.focus();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.refreshList !== this.props.refreshList &&
      this.props.refreshList
    ) {
      this.resetSearchHandler();
    }
  };

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    if (key === "queryString") {
      inputValue["value"] = event.target.value;
    } else {
      inputValue["selectedOption"] = event;
      inputValue["value"] = event;
    }
    const updatedcontrols = {
      ...this.state.controls,
      [key]: {
        ...this.state.controls[key],
        ...{
          ...((key === "length" || key === "year") && {
            elementConfig: {
              ...this.state.controls[key].elementConfig,
              ...inputValue,
            },
          }),
        },
        ...inputValue,
      },
    };
    this.setState({ controls: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    const { controls } = this.state;
    if (
      controls["queryString"].value === "" &&
      (controls["year"].value === "" ||
        (controls["year"].value[0] === 2000 &&
          controls["year"].value[1] === new Date().getFullYear())) &&
      (controls["length"].value === "" ||
        (controls["length"].value[0] === 0 &&
          controls["length"].value[1] === 50)) &&
      controls["type"].selectedOption === null &&
      controls["userId"].selectedOption === null
    ) {
      let searchParams = { page: "1" };
      if (!controls["inActive"].value) {
        searchParams["inactive"] = true;
      }
      this.props.getProjects(searchParams);
    } else {
      let searchParams = {
        query_string: controls["queryString"].value,
        "year[start]": controls["year"].value[0],
        "year[end]": controls["year"].value[1],
        "length[start]": controls["length"].value[0],
        "length[end]": controls["length"].value[1],
        page: "1",
        type: controls["type"].selectedOption
          ? controls["type"].selectedOption.value
          : null,
        user_id: controls["userId"].selectedOption
          ? controls["userId"].selectedOption.value
          : null,
      };
      if (!controls["inActive"].value) {
        searchParams["inactive"] = true;
      }
      this.props.getProjects(searchParams);
    }
  };

  clearTextHandler = () => {
    const controls = { ...this.state.controls };
    const queryString = { ...controls["queryString"] };
    queryString.value = "";
    controls["queryString"] = queryString;
    this.setState({ controls: controls });
    this.textInput.current.focus();
  };

  resetSearchHandler = () => {
    this.setState(
      {
        controls: {
          ...this.state.controls,
          queryString: {
            ...this.state.controls.queryString,
            value: "",
          },
        },
      }
      // () => this.submitHandler({ preventDefault: () => null })
    );
  };

  render() {
    const { controls } = this.state;
    const formElementsArray = [];
    for (let key in controls) {
      if (key === "queryString") {
        formElementsArray.push({
          key: key === "queryString" ? 0 : key === "year" ? 1 : 2,
          id: key,
          config: controls[key],
        });
      }
    }

    const formElementsArraySorted = formElementsArray.sort(
      (val1, val2) => val1.key > val2.key
    );

    const queryString = { ...controls["queryString"] };
    let removeIcon = "";
    if (queryString.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times cursor"
          style={{
            position: "relative",
            top: 24,
            color: "#b3b3b3",
            paddingRight: 10,
            float: "right",
          }}
          onClick={this.clearTextHandler}
        />
      );
    }
    return (
      <div className="card">
        <div className="mt-lg-4 ml-lg-4 m-lg-0 m-4" style={{ paddingLeft: "" }}>
          <h4 className="font-weight-bold text-dark">Search</h4>
          <form onSubmit={this.submitHandler}>
            <i
              className="fas fa-search"
              style={{
                position: "relative",
                float: "right",
                top: 24,
                color: "#b3b3b3",
                paddingRight: 10,
              }}
            />
            {removeIcon}
            {formElementsArraySorted.map(formElement => (
              <div
                className={
                  formElement.config.elementType === "range" ? "px-3" : ""
                }
                key={formElement.id}
              >
                <Input
                  key={formElement.id}
                  {...formElement.config}
                  changed={event =>
                    this.inputChangedHandler(event, formElement.id)
                  }
                  needPadding={true}
                  forwardRef={this.textInput}
                  style={
                    formElement.config.elementConfig.name === "query_string"
                      ? { paddingRight: "55px" }
                      : {}
                  }
                />
              </div>
            ))}
            <FormButton
              block
              className="mt-4"
              disabled={this.props.response.loading}
            >
              Search
            </FormButton>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    response: state.response,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjects: searchParams => dispatch(getProjects(searchParams)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSearch);
