import React, { useState, useEffect } from "react"
import axios from "axios"
import { sortBy } from "lodash"
import LabelCategoryDropdown from "../LabelSection/LabelCategoryDropdown"
import NewOrEditLabelDropdown from "./NewOrEditLabelDropdown"
import NewOrEditLabelList from "./NewOrEditLabelList"

const NewOrEditProgramLabels = ({ programId, programEditPage, tagsForSubmission }) => {
  const [categoryId, setCategoryId] = useState(null)
  const [labelOptions, setLabelOptions] = useState([])
  const [tags, setTags] = useState([])

  const labelCategorySelectHandler = (categoryId) => { setCategoryId(categoryId) }

  useEffect(() => {
    tagsForSubmission(tags)
  }, [tags])

  useEffect(() => {
    if (categoryId) {
      axios.get("/labels", { params: { label_category_id: categoryId, tag_type: "program_global" } })
        .then((res) => {
          const formatLabelsInCategory = res.data.json.data.map(label => {
            return { label: label.name, value: `${label.tag_type}:${label.name}`, order: label.order, definition: label.definition }
          })
          const orderedLabels = sortBy(formatLabelsInCategory, 'order')
          setLabelOptions(orderedLabels)
        })
        .catch((err) => err)
    }
  }, [categoryId])

  useEffect(() => {
    if (!programEditPage) setTags([])
  }, [programEditPage])

  return (
    <>
      <div className="pb-3">
        <LabelCategoryDropdown
          categoryId={categoryId}
          labelCategorySelectHandler={labelCategorySelectHandler}
        />
      </div>
      <NewOrEditLabelDropdown
        programEditPage={programEditPage}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        labelOptions={labelOptions}
        programId={programId}
        setTags={setTags}
        tags={tags}
      />
      <NewOrEditLabelList
        programId={programId}
        tags={tags}
        setTags={setTags}
        setCategoryId={setCategoryId}
        programEditPage={programEditPage}
      />
    </>
  )
}

export default NewOrEditProgramLabels
