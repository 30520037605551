import axios from "axios"
import { toast } from "react-toastify"
import { GET_LOGIN_ASSETS, SET_ASSET_LOADING, SET_LOGIN_URL, TOGGLE_LOGIN_BUTTON } from "./actionTypes"
import onError from "../../utils/onError"

export const getLoginAssets = (subdomain) => async dispatch => {
  try {
    dispatch(setAssetLoading(true))

    const res = await axios.get('/organizations/is_present', { params: { subdomain }})
    const { meta, login_logo, login_url_override } = res.data.json

    dispatch(setAssetLoading(false))

    dispatch({
      type: GET_LOGIN_ASSETS,
      payload: {
        loginButtonStatus: meta.allow_registration_login,
        loginLogo: login_logo.login_logo.url,
        loginUrlOverride: login_url_override,
      }
    })
  }
  catch (err) {
    const { error: resError } = err.response.data.json

    dispatch(setAssetLoading(false))
    onError(resError)
  }
}

export const setLoginUrl = (loginUrl) => {
  return {
    type: SET_LOGIN_URL,
    payload: loginUrl
  }
}

export const toggleLoginButton = (id, buttonStatus) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/organizations/${id}`,
      { allow_registration_login: !buttonStatus }
    )
    const { allow_registration_login } = res.data.json.data
    const statusString = allow_registration_login ? 'enabled' : 'disabled'

    toast.dismiss()
    toast.success(`Successfully ${statusString} registration login button`)

    dispatch({
      type: TOGGLE_LOGIN_BUTTON,
      payload: allow_registration_login
    })
  }
  catch (e) {
    console.log(e)
    toast.error(`Error: ${e.message}`)
  }
}

export const setAssetLoading = (loading) => {
  return { type: SET_ASSET_LOADING, payload: loading }
}
