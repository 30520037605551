import React, { Component } from "react";
import axios from "../../configAxios";
import dateFns from "date-fns";
import Spinner from "../UI/Spinner";
import Button from "../UI/button/Button";
import { toast } from "react-toastify";
import FormButton from "../UI/button/FormButton";
import DatePicker from "../UI/datePicker/index";

class GeneralRevenue extends Component {
  constructor() {
    super();
    const currentMonthDate = new Date();
    this.signal = axios.CancelToken.source();
    this.state = {
      startDate: new Date().setMonth(currentMonthDate.getMonth() - 1),
      endDate: new Date(),
      generalRevenue: [],
      metaData: {},
      loading: true,
      disabled: true,
      downloading: false,
    };
  }

  componentDidMount() {
    const queryParams = {
      id: "GeneralRevenueReport"
    };
    this.getGenralRevenue(queryParams);
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  getGenralRevenue(queryParams) {
    axios.get("/reports/general_revenue", { params: queryParams, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          generalRevenue: res.data.json.data,
          metaData: res.data.json.meta,
          loading: false,
          disabled: false
        });
      });
  }

  submitHandler = event => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const { startDate, endDate } = this.state;
    const report_options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD")
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD")
      }
    };
    const queryParams = {
      id: "GeneralRevenueReport",
      report_options
    };
    this.getGenralRevenue(queryParams);
  };

  downloadReport = () => {
    if (this.state.downloading) return;
    this.setState({ downloading: true });
    const { startDate, endDate } = this.state;
    const report_options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD")
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD")
      }
    };
    axios.get("/reports/general_revenue/download_csv", {
      params: {
        id: "GeneralRevenueReport",
        report_options
      },
      cancelToken: this.signal.token
    }).then(res => {
      if (res.data.json.generating) {
        toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
        let timerId = setInterval(
          () => this.checkDownloadProgress(res.data.json.id),
          5000
        );
        this.setState({ timerId });
      }
    }).catch(err => {
      this.setState({ downloading: false });
      console.log(err);
    })
  };

  checkDownloadProgress = id => {
    axios.get(
      "/reports/general_revenue/check_generating",
      {
        params: { id },
        cancelToken: this.signal.token
      }
    ).then(res => {
      if (typeof res.data.json === "string") {
        clearInterval(this.state.timerId);
        this.setState({ downloading: false });
        toast.success("File successfully downloaded");
        window.open(res.data.json, "_blank");
        return;
      }
    }).catch(e => {
      toast.error("Downloading failed");
      clearInterval(this.state.timerId);
    })
  };

  render() {
    const { generalRevenue } = this.state;

    const generalRevenueList = generalRevenue.map((revenue, index) => (
      <tr key={index}>
        <td>{dateFns.format(revenue.week_of, "YYYY-MM-DD")}</td>
        <td>{revenue.asset_bookings}</td>
        <td>{revenue.program_registrations}</td>
        <td>{revenue.invoices}</td>
      </tr>
    ));

    return (
      <div className="container service-kind w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>General Revenue Report</h3>
          </div>
          <div className="col-6 text-right">
            <Button
              type="success"
              className="ml-3"
              clicked={this.downloadReport}
            >
              Generate CSV
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <div className="row">
                    <div className="col-md-12">
                      <form onSubmit={this.submitHandler}>
                        <div className="row">
                          <div className="col-3">
                            <label>From</label>
                            <DatePicker
                              dateFormat={"yyyy-MM-dd"}
                              selected={this.state.startDate}
                              changed={date => this.setState({ startDate: date })}
                            />
                          </div>
                          <div className="col-3">
                            <label>To</label>
                            <DatePicker
                              dateFormat={"yyyy-MM-dd"}
                              selected={this.state.endDate}
                              changed={date => this.setState({ endDate: date })}
                            />
                          </div>
                          <div className="col-3">
                            <FormButton className="build-report" disabled={this.state.disabled}>Build Report</FormButton>
                          </div>
                        </div>
                      </form>
                      <div className="row mt-5">
                        <div className="col-12">
                          <h5 className="font-weight-bold">
                            Statistics
                        </h5>
                        </div>
                        {
                          this.state.loading ? (
                            <div className="col-12">
                              <Spinner className="h-30vh" />
                            </div>
                          ) : (
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-4">
                                    Total Revenue for the Period:
                                  </div>
                                  <div className="col-8">
                                    {this.state.loading ? 0 : this.state.metaData.total_revenue}
                                  </div>
                                  <div className="col-4">
                                    Revenue from Bookings:
                                  </div>
                                  <div className="col-8">
                                    {this.state.loading ? 0 : this.state.metaData.revenue_from_bookings}
                                  </div>
                                  <div className="col-4">
                                    Revenue from Program Registrations:
                                  </div>
                                  <div className="col-8">
                                    {this.state.loading ? 0 : this.state.metaData.revenue_from_program_registrations}
                                  </div>
                                  <div className="col-4">
                                    Revenue from General and Floating Invoices:
                                  </div>
                                  <div className="col-8">
                                    {this.state.loading ? 0 : this.state.metaData.revenue_from_invoices}
                                  </div>
                                </div>
                              </div>
                            )
                        }

                      </div>
                      <div className="list mt-5">
                        <h5 className="font-weight-bold">
                          Week by Week Revenue
                      </h5>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead className="border-top">
                              <tr>
                                <th className="w-25">Week of</th>
                                <th className="w-25">Asset Bookings</th>
                                <th className="w-25">Program Registrations</th>
                                <th className="w-25">General & Floating Invoices</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!this.state.loading &&
                                generalRevenueList
                              }
                            </tbody>
                          </table>
                        </div>
                        {this.state.loading && <Spinner className="h-30vh" />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralRevenue;
