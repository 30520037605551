import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import axios from "../../../configAxios";

import ProgramItem from "./ProgramItem";
import CardHeader from "../CardHeader";

import Spinner from "../../../assests/images/spinner.gif";

class Programs extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    programs: []
  };

  componentDidMount() {
    axios.get('/programs/dashboard', { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          programs: res.data.programs,
          loading: false
        })
        this.props.updateGrid();
      })
      .catch(error => error)
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  render() {
    const spinner = (
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    );
    return (
      <div className="dashboard">
        <div className="card">
          {this.state.loading ? (
            <div className="card-body p-0">
              <div className="card-text">
                <div className="spinner">{spinner}</div>
              </div>
            </div>
          ) : (
              <div>
                <CardHeader
                  title={"Upcoming Programs"}
                  icon="fa-calendar"
                  collapse="programs"
                  updateGrid={this.props.updateGrid}
                  removeModule={this.props.removeModule}
                  name={this.props.name} />
                <div className="dashboard-card collapse show" id="programs">
                  <div className="card-body p-0">
                    <div className="card-text">
                      <ul className="list-group list-group-flush">
                        {this.state.programs.map(program => (
                          <ProgramItem key={program.id} program={program} />
                        ))}
                      </ul>
                      <div className="p-2 text-center cursor card-text" style={{ height: "40px" }}>
                        <Link to="/programs" className="text-info">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(Programs);