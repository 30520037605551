import "./index.css"
import React from "react";

const RegistrationTable = ({ registrationLineItem }) => {
  return (
    <tbody>
      <tr>
        <td className="column-15 text-center">
          {registrationLineItem.registration_code || "N/A"}
        </td>
        <td className="column-70 text-center">
          {registrationLineItem.status && <strong>({registrationLineItem.status}) </strong>}
          {registrationLineItem.program_name} x {registrationLineItem.count}
        </td>
        <td className="column-15 text-center">
          {registrationLineItem.total_amount}
        </td>
      </tr>
    </tbody>
  );
};

export default RegistrationTable;
