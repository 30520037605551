import React from 'react'
import JuryMemberReviewItem from "./JuryMemberReviewItem"

export default function JuryMemberReviewItems({juryMembers, reviews}) {
  const findReviewForJuryMember = (juryMember) => {
    return reviews?.find(review => review.jury_member_id === juryMember.jury_member_id)
  }

  return (
    <table className="w-100 mb-4 border-left border-right">
      <tbody className="text-center">
        { juryMembers.map(juryMember => <JuryMemberReviewItem juryMember={juryMember.jury_member} review={findReviewForJuryMember(juryMember)} />)}
      </tbody>
      <tfoot className="border-bottom">
        <tf />
      </tfoot>
    </table>
  )
}

