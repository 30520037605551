import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import NoImage from "../../assests/images/no-image.png";
import dateFns from "date-fns";

class ListItem extends Component {
  state = {
    width: window.innerWidth
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 768;
    const { listItem, type } = this.props;
    const image = listItem.photo ? listItem.photo : NoImage;
    let year;
    if (
      listItem.parameters.date_released &&
      (type === "All Media" || type === "Audio" || type === "Film")
    ) {
      const date = new Date(listItem.parameters.date_released);
      year = dateFns.getYear(date);
    }

    const fullDate = new Date(listItem.created_at);
    const date = `${dateFns.getDate(fullDate)}-${dateFns.format(
      fullDate,
      "MMM"
    )}-${dateFns.getYear(fullDate)}`;
    const popover = (
      <Popover>
        <div className="text-center" key={listItem.id}>
          <div className="img-rounded img-fluid mx-auto my-auto bg-cover" style={{
            width: 200,
            height: 150,
            backgroundImage: `url(${image})`
          }} />
          <p className="text-left mb-0 mt-1 word-wrap-break"
            style={{ color: type === "Video" ? "#750000" : 'transparent' }}>
            {listItem.name}</p>
          <p className="text-left mb-0 word-wrap-break">
            <span className="font-weight-bold">Added on: </span>
            {date}
          </p>
        </div>
      </Popover>
    );

    let tableData;
    switch (this.props.type) {
      case "All Media":
        tableData = (
          <>
            <td>{listItem.parameters.director}</td>
            <td>
              {listItem.asset_contributers.length
                ? listItem.asset_contributers.map(contributer => (
                  <div key={contributer.id}>{contributer.name}</div>
                ))
                : ""}
            </td>
            <td>{listItem.type}</td>
            <td className="text-center">
              {listItem.type === "Print"
                ? listItem.parameters.year_of_publishing
                : listItem.type === "Audio" || listItem.type === "Film"
                  ? year
                  : ""}
            </td>
            <td className="text-center">
              {listItem.bulk_asset === 1
                ? listItem.bulk_asset
                : listItem.bulk_asset.bulk_count_total}
            </td>
          </>
        );
        break;
      case "Video":
        tableData = (
          <>
            <td>{listItem.parameters.director}</td>
            <td>
              {listItem.parameters.hasOwnProperty("video_format") &&
                listItem.parameters.video_format !== null &&
                listItem.parameters.video_format !== ""
                ? listItem.parameters.video_format
                : listItem.type}
            </td>
            <td>{listItem.parameters.release_date}</td>
            <td className="text-center">
              {listItem.bulk_asset === 1
                ? listItem.bulk_asset
                : listItem.bulk_asset.bulk_count_total}
            </td>
            <td>{listItem.parameters.running_time}</td>
            <td>{listItem.parameters.feature_type}</td>
            <td>
              {listItem.parameters.hasOwnProperty("video_genre") &&
                !_.isEmpty(listItem.parameters.video_genre) && _.isArray(listItem.parameters.video_genre) &&
                listItem.parameters.video_genre.map((genre, index) => (
                  <div key={index}>{genre}</div>))}
            </td>
            <td>{listItem.parameters.edition}</td>
          </>
        );
        break;
      case "Print":
        tableData = (
          <>
            <td>{listItem.parameters.authors}</td>
            <td>{listItem.parameters.type_of_print}</td>
            <td className="text-center">
              {listItem.bulk_asset === 1
                ? listItem.bulk_asset
                : listItem.bulk_asset.bulk_count_total}
            </td>
            <td>{listItem.parameters.pages}</td>
            <td>{listItem.parameters.publisher}</td>
            <td>{listItem.parameters.year_of_publishing}</td>
          </>
        );
        break;
      case "Audio":
      case "Film":
        tableData = (
          <>
            <td>
              {listItem.asset_contributers.length
                ? listItem.asset_contributers.map(contributer => (
                  <div key={contributer.id}>{contributer.name}</div>
                ))
                : ""}
            </td>
            <td>{listItem.type}</td>
            <td className="text-center">
              {listItem.parameters.hasOwnProperty("date_released") &&
                listItem.parameters.date_released !== "" &&
                year}
            </td>
            <td className="text-center">
              {listItem.bulk_asset === 1
                ? listItem.bulk_asset
                : listItem.bulk_asset.bulk_count_total}
            </td>
          </>
        );
        break;
      default:
        tableData = null;
    }
    return (
      <tr>
        <td>{listItem.parameters.call_number}</td>
        <td>
            <OverlayTrigger key={"right"} placement={"right"} overlay={popover}>
              <Link to={`/assets/${listItem.id}`} className="text-info">
                {listItem.name 
                  ? (
                    <>
                      {listItem.name.substring(0, 25)}
                      {listItem.name.length < 25 ? "" : "..."}
                    </>
                  ) : (
                    "---"
                )}
              </Link>
            </OverlayTrigger>
        </td>
        {isMobile ? "" : tableData}
        <td className="text-center">
          {listItem.availability === true ? "Yes" : "No"}
        </td>
        <td className="text-center">
          <Link
            to={`/libraries/${listItem.type.toLowerCase()}/${
              listItem.id
              }/edit?type=${listItem.type.toLowerCase()}`}
          >
            <i className="fas fa-pencil-alt cursor text-dark" />
          </Link>
        </td>
      </tr>
    );
  }
}

export default ListItem;
