import React, { Component } from "react";

import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";

import AssetItem from "./AssetItem";

class AssetItems extends Component {
  signal = axios.CancelToken.source();
  state = {
    assets: [],
    loading: true
  };

  componentDidMount() {
    this.getCurrentlyOutAssets();
  }

  getCurrentlyOutAssets = async () => {
    let assets;
    this.setState({ loading: true });
    const currentlyOutRes = await axios.get("/assets/current_out", {
      params: {
        why_out: this.props.activeTab
      },
      cancelToken: this.signal.token
    });
    if (this.props.activeTab === "checked_out") {
      assets = currentlyOutRes.data.assets;
    } else {
      assets = currentlyOutRes.data.json.assets;
    }
    this.setState({ assets, loading: false });
  };

  render() {
    const { assets } = this.state;

    let assetItems = assets.map(asset => (
      <AssetItem
        key={asset.id}
        asset={asset}
        activeTab={this.props.activeTab}
      />
    ));

    const rentedHeader = (
      <li className="list-group-item rounded-0">
        <div className="row">
          <div className="col-md-4 font-weight-bold">Asset Name</div>
          <div className="col-md-2 font-weight-bold text-center">Quantity</div>
          <div className="col-md-4 font-weight-bold text-center">Booking Range</div>
          <div className="col-md-2 font-weight-bold">Who has it</div>
        </div>
      </li>
    );

    const servicedHeader = (
      <li className="list-group-item rounded-0">
        <div className="row">
          <div className="col-md-6 font-weight-bold">Asset Name</div>
          <div className="col-md-6 font-weight-bold">Last Flagged By</div>
        </div>
      </li>
    );

    return (
      <div>
        {this.state.loading ? (
          <div style={{ background: "white" }}>
            <Spinner />
          </div>
        ) : (
            <ul className="list-group list-group-flush">
              {this.props.activeTab === "checked_out"
                ? rentedHeader
                : servicedHeader}
              {assetItems}
            </ul>
          )}
      </div>
    );
  }
}

export default AssetItems;
