import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "../../configAxios";
import SearchnBrowse from "./SearchnBrowse";
import Button from "../UI/button/Button";
import langUtils from "../../utils/langUtils";

import "./index.css";

class Library extends Component {
  signal = axios.CancelToken.source();
  state = {
    showModal: false,
    libraryTypes: ['Video', 'Film', 'Print', 'Audio'],
    hideDropdown: true,
    activeTabType: "All Media",
    formatType: null,
    counts: {}
  };

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  tabChangeHandler = (activeTabType, formatType) => {
    if (formatType === "All") {
      this.setState({ activeTabType, formatType: null });
    } else this.setState({ activeTabType, formatType });
  };

  openModal = () => {
    this.setState({ showModal: true });
  };
  render() {
    const allMedia = (
      <div className="nav-item dropdown">
        <button
          className="nav-link dropbtn dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {langUtils("txt_global_add_new", "Add New")}
        </button>
        <div className="dropdown-menu">
          {this.state.libraryTypes.map((type, index) => {
            const linkType = type.toLowerCase();
            return (
              <Link
                to={`/libraries/${linkType}/new?type=${linkType}`}
                className="type-link"
                key={index}
              >
                <p className="type-name">{type}</p>
              </Link>
            );
          })}
        </div>
      </div>
    );

    const print = (
      <Link to={`/libraries/print/new?type=print`}>
        <Button type="success">Add New Print</Button>
      </Link>
    );
    const video = (
      <Link
        to={`/libraries/video/new?${
          this.state.formatType === null || this.state.formatType === ""
            ? ""
            : `format_type=${this.state.formatType}&`
          }type=video`}
      >
        <Button type="success">
          Add New{" "}
          {this.state.formatType === null || this.state.formatType === ""
            ? "Video"
            : this.state.formatType}
        </Button>
      </Link>
    );
    const audio = (
      <Link to={`/libraries/audio/new?type=audio`}>
        <Button type="success">Add New Audio</Button>
      </Link>
    );
    const film = (
      <Link to={`/libraries/film/new?type=film`}>
        <Button type="success">Add New Film</Button>
      </Link>
    );

    const { activeTabType } = this.state;
    let libraryType;
    switch (activeTabType) {
      case "All Media":
        libraryType = allMedia;
        break;
      case "Video":
        libraryType = video;
        break;
      case "Audio":
        libraryType = audio;
        break;
      case "Print":
        libraryType = print;
        break;
      case "Film":
        libraryType = film;
        break;
      default:
        libraryType = null;
    }
    return (
      <div className="container library w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>
              {activeTabType === "All Media" ? "Library" : activeTabType} Media
              List
            </h3>
          </div>
          <div className="col-6 text-right">
            <div className="float-right">
              {libraryType}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <SearchnBrowse tabChanged={this.tabChangeHandler} />
          </div>
        </div>
      </div>
    );
  }
}

export default Library;
