import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { title, snakeCase } from 'change-case'

import langUtil from "../../../utils/langUtils"

import Tags from './tags'
import Labels from './labels'
import ProgramLabels from './programLabels'
import AssociationsAndEducation from './associationsAndEducation'

class LabelsAndAssociations extends Component {
  topTab = this.props.match.params.toptab;
  state = {
    activeTab:
      typeof this.topTab !== "undefined"
        ?
        (
        title(this.topTab) === "Associations And Education" ? "Associations & Education" : title(this.topTab)
        )
        : "Labels"
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
      typeof this.topTab !== "undefined"
      ?
      (
      title(this.topTab) === "Associations And Education" ? "Associations & Education" : title(this.topTab)
      )
      : "Friends Labels"
    });
  };

  tabChangeHandler = activeTab => {
    this.setState({ activeTab });
    if (activeTab === "Associations & Education") activeTab = "Associations and Education"
    this.props.history.push(
      `/system_settings/labels_and_associations/${snakeCase(activeTab)}`
    );
  };

  render() {
    const tabNames = {
      "Friends Labels": ["txt_global_friends", "Labels"],
      "Project Labels": ["projects", "Labels"],
      "Program Labels": ["programs", "Labels"],
      "Associations & Education" : ["Associations & Education", ""]
    }
    const tabs = Object.keys(tabNames).map((tab , index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${this.state.activeTab === tab ? "active-tab" : "inactive-tab"}`}
      >
        {`${langUtil(tabNames[tab][0], tab)} ${tabNames[tab][1]}`}
      </li>
    ));

    let activeTab;
    switch (this.state.activeTab) {
      case "Friends Labels":
        activeTab = <Labels activeTab={this.state.activeTab} />;
        break;
      case "Project Labels":
        activeTab = <Tags activeTab={this.state.activeTab} />;
        break;
      case "Program Labels":
        activeTab = <ProgramLabels activeTab={this.state.activeTab} />;
        break;
      case "Associations & Education":
        activeTab = <AssociationsAndEducation activeTab={this.state.activeTab} />;
        break;
      default:
        activeTab = null;
    }

    return (
      <div className="card email-campaign">
        <ul className="nav nav-tabs font-weight-bold ml-0">
          {tabs}
        </ul>
        {activeTab}
      </div>
    );
  }
}

export default withRouter(LabelsAndAssociations);