import React, { Component } from 'react';
import { toast } from "react-toastify";

import axios from "../../configAxios";
import Button from "../UI/button/Button";

import AuthContainer from "./AuthContainer";
import Logo from "./formComponents/Logo"

class Unsubscribe extends Component {
  state = {
    disableSubmitButton: false
  }

  componentDidMount() {
      const { token } = this.props.match.params; 
      localStorage.setItem("forwardURL", `/unsubscribe/${token}`);
  }

  unsubscribeUser = () => {
    axios.post("/users/unsubscribe_from_mailing_list", {
      unsubscribe_from_mailing_list_token: this.props.match.params.token
  })
  .then(res => {
    toast.success("Unsubscribed successfully.");
    localStorage.removeItem("forwardURL");
    this.props.history.push("/dashboard");
  })
  }

  render() {
    return (
      <AuthContainer>
        <div className="card-body">
          <div className="card-text p-3">
            <Logo/>
            <div className="text-dark text-center">
              If you don’t want to receive emails from us unsubscribe
            </div>
            <form onSubmit={this.submitHandler} className="mt-4">
              <Button
                className="btn-block"
                type="primary"
                disabled={this.state.disableSubmitButton}
                clicked={this.unsubscribeUser}
              >
                Unsubscribe
              </Button>
            </form>
          </div>
        </div>
      </AuthContainer>
    )
  }
}

export default Unsubscribe;
