import React from "react"
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { formatDate } from "../../../utils/formatDate"

const MembershipInfo = function ({user, policyRes, expiryDate, renewHandler, roleLevel}) {

  const isAdmin = () => {
    return ["super_admin", "site_admin", "staff"].includes(roleLevel)
  }

  return (
    <div className="card mt-3">
      <div className="card-body p-3">
        <div className="card-text">
          <div className="row border-bottom">
            <div className="col-12">
              <p className="font-weight-bold">
                {user.member_type_id !== null && user.member_type_name !== null
                  ? `${user.member_type_name} Member`
                  : "Non Member"}
                {user.partner_organization_name && ` / ${user.partner_organization_name}`}
              </p>
            </div>
          </div>
          {user.member_type_id &&
          <>
            {user.lifetime &&
            <div className="row border-bottom">
              <div className="col-12 d-flex justify-content-between py-3">
                    <span className="font-weight-bold">
                      No Expiration Date
                    </span>
              </div>
            </div>
            }
            {!user.lifetime && user.membership_expires !== null &&
              <div className="row border-bottom">
                <div className="col-12 d-flex justify-content-between py-3">
                  <span className="font-weight-bold">
                    {user.membership_expires >
                    formatDate(new Date(), "hh:mm")
                      ? "Expires"
                      : "Membership Expired"}
                  </span>
                  <span>{formatDate(expiryDate, 'YYYY/MM/DD')}</span>
                  {isAdmin() && policyRes.edit_this_user && (
                    <span className="text-primary cursor" onClick={renewHandler}>Renew</span>
                  )}
                </div>
              </div>
            }
            {(!user.lifetime && user.membership_expires === null) &&
            <div className="row border-bottom">
              <div className="col-12 d-flex justify-content-between py-3">
                <span className="font-weight-bold">
                  No Expiration Date
                </span>
                <span
                  className="text-primary cursor ml-2"
                  onClick={renewHandler}
                >
                  Record one now
                </span>
              </div>
            </div>
            }
          </>
          }
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-between pt-3">
            <span className="font-weight-bold">Friend Since</span>
            <span>
              {formatDate(user.created_at, 'YYYY/MM/DD')}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { roleLevel: state.auth.roleLevel }
}
export default connect(mapStateToProps)(withRouter(MembershipInfo))
