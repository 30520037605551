import CreatableSelect from "react-select/lib/Creatable"
import { customStylesFlag } from "./customStyles"
import React from "react"

const CreatableSelectInput = ({ props }) => {
  return (
    <div>
      <CreatableSelect
        {...props.elementConfig}
        value={props.selectedOption}
        isClearable
        isSearchable
        isMulti
        onChange={props.changed}
        styles={customStylesFlag}
        placeholder={
          props.elementConfig &&
          props.elementConfig.hasOwnProperty("placeholder")
            ? props.elementConfig.placeholder
            : "Select Option"
        }
      />
      <input
        value={
          (props.selectedOption && props.selectedOption.length === 0) ||
          props.selectedOption === null
            ? ""
            : props.selectedOption
        }
        required={props.elementConfig.required}
        style={{
          opacity: 0,
          height: 0,
          position: "absolute",
        }}
      />
    </div>
  )
}

export default CreatableSelectInput
