import React, { Component } from "react"
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"
import { lowerCase } from "change-case"

import axios from "../../configAxios"
import errorHandler from "../errorPages/UserNotAuthorized"
import FormButton from "../UI/button/FormButton"
import Input from "../UI/input/Input"
import Logo from "./formComponents/Logo"

import AuthContainer from "./AuthContainer"

class SignUpEmail extends Component {
  state = {
    controls: {
      username: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "username",
          required: true,
        },
        label: "Email Address",
        value: "",
      },
      otp: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "otp",
          required: true,
        },
        label: "Token",
        value: "",
      },
    },
    showForm: true,
    showError: false,
    validated: false,
    renderUserForm: false
  }

  inputChangedHandler = (event) => {
    const updatecontrols = {
      ...this.state.controls,
      [event.target.name]: {
        ...this.state.controls[event.target.name],
        value: event.target.value,
      },
    }
    this.setState({ controls: updatecontrols })
  }

  checkEmailHandler = (event) => {
    event.preventDefault()
    const email = lowerCase(this.state.controls.username.value)
    const params = {
      email: email,
    }
    localStorage.setItem("unconfirmed_user_email", email);
    axios.get("sign_up/verify_email", { params }).then((res) => {
      if (!res.data.json.success) {
        this.setState({
          showForm: !this.state.showForm,
          showError: !this.state.showError,
        })
      } else {
        this.setState({
          validated: !this.state.validated,
        })
      }
    })
  }

  validateOtpHandler = (event) => {
    event.preventDefault()
    const params = {
      email: this.state.controls.username.value,
      otp: this.state.controls.otp.value,
    }
    axios.get("sign_up/validate_token", { params }).then((res) => {
      if (res.data.json.success) {
        this.props.history.push({
          pathname: "/users/signup",
          state: {
            fromLoginPage: true,
            unconfirmed_user_token: res.data.json.unconfirmed_user_token
          }
        });
      } else {
        errorHandler(res)
      }
    })
  }

  render() {
    const formElementsArray = []
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      })
    }
    const formElement = formElementsArray[0]
    const otpElement = formElementsArray[1]
    return (
      <AuthContainer>
        <div className="card-body">
          <div className="card-text px-3 pb-3">
            <Logo />
            {this.state.showForm && !this.state.validated && (
              <form onSubmit={this.checkEmailHandler} className="mt-4">
                <Input
                  key={formElement.id}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  label={formElement.config.label}
                  value={formElement.config.value}
                  changed={(event) => this.inputChangedHandler(event)}
                />
                <div className="my-3 font-14 text-muted">
                  Enter a valid email address. A verification Token
                  will be sent before you can proceed with the registration process.
                </div>
                <FormButton block={true}>
                  Send Token
                </FormButton>
              </form>
            )}
            {this.state.showError && (
              <div className="text-center text-dark">
                Email is already taken. <br />
                <Link to="reset_password" className="text-info">
                  Need a password?
                </Link>
              </div>
            )}
            {this.state.validated && !this.state.showError && (
              <form onSubmit={this.validateOtpHandler} className="mt-4">
                <Input
                  key={otpElement.id}
                  elementType={otpElement.config.elementType}
                  elementConfig={otpElement.config.elementConfig}
                  label={otpElement.config.label}
                  value={otpElement.config.value}
                  changed={(event) => this.inputChangedHandler(event)}
                />
                <div className="my-3 font-14 text-muted">
                  Enter the verification Token that was sent to {lowerCase(formElement.config.value)}.
                </div>
                <FormButton block={true}>
                  Validate Token
                </FormButton>
              </form>
            )}
          </div>
        </div>
      </AuthContainer>
    )
  }
}

export default withRouter(SignUpEmail)
