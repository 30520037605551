import { GET_LOGIN_ASSETS, SET_ASSET_LOADING, SET_LOGIN_URL, TOGGLE_LOGIN_BUTTON } from "../actions/actionTypes"

const INITIAL_STATE = {
  assetLoading: false,
  loginLogo: null,
  loginUrlOverride: null,
  loginButtonStatus: true,
}

const loginAssetsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOGIN_ASSETS:
      return {
        ...state,
        loginButtonStatus: action.payload.loginButtonStatus,
        loginLogo: action.payload.loginLogo,
        loginUrlOverride: action.payload.loginUrlOverride
      }
    case SET_LOGIN_URL:
      return { ...state, loginUrlOverride: action.payload }
    case TOGGLE_LOGIN_BUTTON:
      return { ...state, loginButtonStatus: action.payload }
    case SET_ASSET_LOADING:
      return { ...state, assetLoading: action.payload }
    default:
      return state
  }
}

export default loginAssetsReducer
