import React from "react"

const NameOrOrgDisplay = (props) => {
  const user = props.user
  return (
    <div className="row">
      <div className="col-12 px-4 text-center">
        <span className="text-primary font-weight-bold">
          {user.organization_or_name}
        </span>
      </div>
      {user.organization_or_name === user.full_name && user.pronouns && (
        <div className="col-12 text-center">{user.pronouns}</div>
      )}
      {user.organization_or_name !== user.full_name && user.address && (
        <div className="col-12 text-center">{user.address}</div>
      )}
    </div>
  )
}

export default NameOrOrgDisplay
