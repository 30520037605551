import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";

class Invitation extends Component {
  state = {
    userName: {
      elementType: "input",
      elementConfig: {
        name: "userName",
        placeholder: "",
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        required: true
      },
      value: this.props.name
    },
    disabled: false
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      userName: {
        ...this.state.userName,
        value: nextProps.name
      }
    });
  }

  inputChangedHandler = event => {
    let userName = { ...this.state.userName };
    userName["value"] = event.target.value;
    this.setState({ userName: userName });
  };

  submitHandler = event => {
    this.setState({ disabled: true })
    event.preventDefault();
    const queryParams = {
      login: this.state.userName.value,
      id: this.props.userId
    };
    axios
      .post("/users/send_invite", queryParams)
      .then(res => {
        const { error, errors, success, } = res.data.json

        if (success) {
          toast.success("Invitation successfully sent");
          this.props.updateInvitation();
          this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(errors).forEach(error => {
              toast.error(title(error) + " " + errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(error);
          }
          this.setState({ disabled: false })
        }
      })
      .catch(err => err);
  };
  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <p>Email of the user</p>
        <Input
          {...this.state.userName}
          changed={event => this.inputChangedHandler(event)}
        />
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            className="float-left mr-3"
            clicked={this.props.closeModal}
          >
            Cancel
          </Button>
          <FormButton disabled={this.state.disabled}>Send</FormButton>
        </div>
      </form>
    );
  }
}
export default Invitation;
