import React from "react";
import classnames from "classnames";

const FormButton = props => {
  return (
    <input
      type="submit"
      id={props.id}
      value={props.children}
      disabled={props.disabled}
      title={props.title}
      className={classnames(
        `btn`,
        {
          "btn-block": props.block
        },
        `${props.className}`
      )}
      style={{
        color: "white",
        backgroundColor: "var(--color-success)",
        ...props.style
      }}
    />
  );
};

export default FormButton;
