import React, { useState } from 'react'
import ListItem from './questionsListItem/ListItem'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from "react-toastify";

export default React.memo(function DragDropWrapper({ questions, setQuestions, submitNewQuestion, updateQuestion, updateQuestionState, deleteQuestion }) {

  let [dragging, setDragging] = useState(false)
  
  const submitQuestionOrder = (questionsForSubmission) => {
    if (questionsForSubmission.length > 0) {
      Promise.all(questionsForSubmission.map(question => {
          const payload = { id: question.id, order: question.order }
          return updateQuestion(payload)
        })
      ).then(() => {
        toast.success("Successfully reordered questions.")
      }).catch(() => {
        setQuestions(questions)
        toast.error("Could not update question order.")
      })
    }
  }

  function parseQuestionsOrderData(questionsCopy, questionsForSubmission) {
    return questionsCopy.map((question, index) => {
      const newOrder = index + 1
      const updatedQuestion = { ...question, order: newOrder }

      if (question.order !== newOrder) {
        questionsForSubmission.push(updatedQuestion)
      }

      return updatedQuestion
    })
  }

  function updateQuestionsOrder(result) {
    let questionsCopy = [...questions]
    let [movedQuestion] = questionsCopy.splice(result.source.index, 1)
    questionsCopy.splice(result.destination.index, 0, movedQuestion)

    const questionsForSubmission = []
    const formattedQuestions = parseQuestionsOrderData(questionsCopy, questionsForSubmission)

    setQuestions(formattedQuestions)
    submitQuestionOrder(questionsForSubmission)
  }

  const handleDragStart = () => {
    setDragging(true)
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return

    updateQuestionsOrder(result)
    setDragging(false)
  }

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="questions">
        {provided => (
          <ul className="d-flex flex-column list-group" {...provided.droppableProps} ref={provided.innerRef}
              style={{ "list-style": "none" }}>
            {questions.length > 0 && (
              questions.map((question, index) => {
                return (
                  <Draggable key={question.id || question.dragId} draggableId={`${question.id || question.dragId}`}
                             index={index}>
                    {provided => (
                      <>
                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <ListItem question={question}
                                    dragging={dragging} submitNewQuestion={submitNewQuestion}
                                    updateQuestion={updateQuestion} updateQuestionState={updateQuestionState}
                                    deleteQuestion={deleteQuestion} dragging={dragging}/>
                        </li>
                      </>
                    )}
                  </Draggable>
                )
              })
            )
            }
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}, (prevProps, nextProps) => prevProps.questions === nextProps.questions)
