import React, { Component } from 'react';
import { toast } from "react-toastify";

import axios from "../../configAxios";
import Spinner from '../UI/Spinner';
import Input from '../UI/input/Input';
import FormButton from '../UI/button/FormButton';

class AddFileForm extends Component {
  state = {
    formData: {
      id: 0,
      attachment: '',
      type_used: ''
    },
    input: {
      attachment: {
        elementType: "input",
        elementConfig: { name: "logo", type: "file", accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
        label: "Add File"
      }
    },
    loading: false
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        id: this.props.match.params.id,
        type_used: this.props.type
      }
    })
  }

  submitHandler = (event) => {
    event.preventDefault();

    this.setState({ ...this.state, loading: true })

    let formData = new FormData()
    formData.append("id", this.state.formData.id)
    formData.append("attachment", this.state.formData.attachment)
    formData.append("type_used", this.state.formData.type_used)

    axios.post(`/organizations/add_data`, formData)
      .then(res => {
        this.setState({ ...this.state, loading: false })

        if (this.props.type === 'member') {
          toast.success("Members Added");
        } else if (this.props.type === 'equipment_item') {
          toast.success("Equipments Added");
        } else if (this.props.type === 'library') {
          toast.success("Library Added");
        }

      })
      .catch(error => error)
  }

  inputEventHandler = (event, field) => {
    if (field === 'file') {
      this.setState({
        ...this.state,
        formData: {
          ...this.state.formData,
          attachment: event.target.files[0]
        }
      })
    }
  }

  render() {
    let title = '';
    if (this.props.type === 'member') {
      title = "New Members"
    } else if (this.props.type === 'equipment_item') {
      title = "New Equipment Items"
    } else if (this.props.type === 'library') {
      title = "New Library Items"
    }

    return (
      <div className="container w-100">
        <div className="row mb-4">
          <div className="col-12">
            <h3>{title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body p-4">
                {
                  this.state.loading ? <Spinner className="h-60vh" /> :
                    <div className="card-text">
                      <form action="" onSubmit={event => this.submitHandler(event)}>
                        <Input
                          {...this.state.input.attachment}
                          changed={event => this.inputEventHandler(event, "file")}
                          style={{ paddingTop: "3px" }}
                        />
                        <div className="text-center m-4">
                          <FormButton>Submit</FormButton>
                        </div>
                      </form>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddFileForm;