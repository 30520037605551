import React from 'react';
import classnames from "classnames"
import dateFns from "date-fns"
import { Link } from "react-router-dom"

import NoUser from "../../assests/images/user-icon.jpg"
import "./DonorItem.css";

const DonorItem = ({
  image,
  lastContact,
  lastGift,
  thanked,
  donorName,
  donorId
}) => {
  return (
    <div className="donor-item row border-bottom py-3">
      <div className="col-sm-8">
        <div className="d-flex justify-content-start align-items-center">
          <div className="mr-2">
            <div
              className="donor-thumbnail bg-cover"
              style={{ backgroundImage: `url(${image ? image : NoUser})` }}
            />
          </div>
          <div>
            <div>
              <span className="mr-2">Donor</span>
              <span className="font-weight-bold">
                {donorName ?
                  <Link
                    to={`/users/${donorId}/summary`}
                    className="text-secondary"
                  >
                    {donorName}
                  </Link>
                  : "---"
                }
              </span>
            </div>
            <div>
              <span className="mr-2">Last Contact</span>
              <span className="font-weight-bold">{lastContact ? dateFns.format(lastContact, "YYYY.MM.DD") : "---"}</span>
            </div>
            <div>
              <span className="pr-2">Last Gift</span>
              <span className="font-weight-bold">{lastGift ? dateFns.format(lastGift, "YYYY.MM.DD") : "---"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-4">
        <div className="float-right text-center donor-thanked-group">
          <span>Thanked</span>
          <div>
            <span className={classnames("badge p-1 px-2", { "badge-success": thanked }, { "badge-danger": !thanked })}>
              {thanked ? "Yes" : "No"}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DonorItem
