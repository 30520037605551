import React, { Component } from "react";
import dateFns from "date-fns";
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from "../../configAxios";
import Pagination from "../UI/Pagination";
import Spinner from "../UI/Spinner";
import Modal from "../UI/Modal";

import UpdateThirdPartyPayments from "./UpdateThirdPartyPayment";
import "./index.css";

class ThirdPartyPayments extends Component {
  signal = axios.CancelToken.source();
  state = {
    payments: [],
    activeTab: typeof this.props.match.params.tab !== "undefined"
      ? this.props.match.params.tab
      : "unpaid",
    loading: true,
    updateModal: false,
    selectedPage: 1,
    perPage: 10,
    totalCount: 0,
    paymentId: null
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
    this.getPayments();
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
    this.signal.cancel("Request is being Cancelled");
  }

  getPayments = () => {
    this.setState({ loading: true });
    const { activeTab, selectedPage, perPage } = this.state;
    const params = {
      all_paid: activeTab === "paid" ? true : false,
      page: selectedPage,
      per_page: perPage
    };
    axios
      .get("/third_party_payments", { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          payments: res.data.third_party_payments,
          totalCount: res.data.meta.total,
          loading: false
        });
      });
  };

  toggleTab = activeTab => {
    this.setState({ activeTab, totalCount: 0, payments: [], selectedPage: 1 }, () =>
      this.getPayments()
    );
    this.props.history.push(`/invoices/third_party_payments/${activeTab}`);
  };

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState(
      {
        selectedPage: page.selected + 1
      },
      () => this.getPayments()
    );
  };

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.tab !== "undefined"
          ? this.props.match.params.tab
          : "unpaid"
    });
  };

  deletePayment = id => {
    let response = window.confirm("Are you sure you want to mark unpaid?");
    if (response) {
      axios.delete(`/third_party_payments/${id}`).then(res => {
        const { success } = res.data.json;
        if (success) {
          this.getPayments();
          toast.success("Third party payment successfully marked unpaid.");
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      });
    }
  };

  render() {
    const { payments, activeTab } = this.state;
    const tableHeaderFields = [
      "Booking Date",
      "Asset Owner",
      "Notes",
      "Amount",
      "Status"
    ];

    const tableHeader = tableHeaderFields.map(field => <th>{field}</th>);

    const tableBody = payments.map((payment, index) => {
      return (
        <tr>
          <td>
            {dateFns.format(new Date(payment.booking.start), "MMM DD/YY")}
          </td>
          <td>{payment.asset_owner}</td>
          <td>{payment.notes}</td>
          <td>{payment.amount}</td>
          <td>
            {activeTab === "unpaid" ? (
              <div
                className="text-primary cursor"
                onClick={() =>{
                  window.scrollTo(0, 0);
                  this.setState({ updateModal: true, payment: payment })
                }}
              >
                Update Payment
              </div>
            ) : (
                <div>
                  <span>Paid</span>
                  <span className="mx-3">/</span>
                  <span
                    className="text-primary cursor"
                    onClick={() => this.deletePayment(payment.id)}
                  >
                    Mark Unpaid
                </span>
                </div>
              )}
          </td>
        </tr>
      );
    });

    const updateModal = (
      <Modal show={this.state.updateModal} title={"Third Party Payment"} hide={() => this.setState({ updateModal: false })}>
        <UpdateThirdPartyPayments
          closeModal={() =>
            this.setState({ updateModal: false, payment: null })
          }
          payment={this.state.payment}
          getPayments={this.getPayments}
        />
      </Modal>
    );

    return (
      <div className="container billing w-100">
        <div className="row mb-4">
          <div className="col-12">
            <h3>Third Party Rental Payments</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body p-3">
                <div className="card-text">
                  <div className="mx-3 my-4">
                    <span
                      className={`cursor font-weight-bold ${activeTab === "unpaid" &&
                        "text-primary"}`}
                      onClick={() => this.toggleTab("unpaid")}
                    >
                      Unpaid
                    </span>
                    <span className="mx-3">/</span>
                    <span
                      className={`cursor font-weight-bold ${activeTab === "paid" &&
                        "text-primary"}`}
                      onClick={() => this.toggleTab("paid")}
                    >
                      Paid
                    </span>
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-striped"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead>{tableHeader}</thead>
                      <tbody>{!this.state.loading && tableBody}</tbody>
                    </table>
                  </div>

                  {this.state.loading && <Spinner />}
                  {
                    !this.state.loading && this.state.totalCount === 0 && (
                      <div className="d-flex justify-content-center mt-5 font-weight-bold">
                        No Third Party Payments Found.
                      </div>
                    )
                  }
                  {updateModal}
                  <Pagination
                    handlePageClick={this.paginationHandler}
                    pageCount={this.state.totalCount / this.state.perPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ThirdPartyPayments;
