import React, { useState, useEffect } from 'react'
import axios from '../../../../../../configAxios'
import Spinner from '../../../../Spinner'
import ReviewsSummary from './ReviewsSummary'
import JuryMemberReviewItems from './JuryMemberReviewItems'

export default function ApplicationReviews({ grantCampaign, match }) {
  
  const [reviews, setReviews] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const getReviews = () => {
    setIsLoading(true)
    axios.get(`/grant_applications/${match.params.application_id}/reviews`).then(res => {
      setReviews(res.data.json.data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    !reviews && getReviews()
  }, [])

  const ReviewData = () => {
    return (
      <>
        { juryMembers && juryMembers.length > 0
          ?
          <>
            <ReviewsSummary juryMembers={juryMembers} reviews={reviews}/>
            <JuryMemberReviewItems juryMembers={juryMembers} reviews={reviews}/>
          </>
          :
          <p>No jury members have been selected.</p>
        }
      </>
    )
  }

  const juryMembers = grantCampaign.grant_campaigns_jury_members

  return (
    <div>
      { isLoading && !reviews
        ?
        <Spinner />
        :
        <ReviewData />
      }
    </div>
  )
}
