import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";

import Button from "../../UI/button/Button";
import axios from "../../../configAxios";
import Spinner from "../../UI/Spinner";
import langUtils from "../../../utils/langUtils";

import AddOrEditSetting from "./AddOrEditSetting";

import "./Invoice.css";

class PackingList extends Component {
  state = {
    pdfSettingsList: [],
    newModal: false,
    viewModal: false,
    editModal: false,
    loading: true,
    edit: false,
    id: null,
    setting_key: null,
    setting_value: null,
    uiValue: null,
    defaultValues: {}
  };

  componentDidMount() {
    axios.get("/pdf_settings/formdata")
      .then(res => {
        const settingTypes = res.data.json.setting_types;
        let defaultValues = {};
        settingTypes.forEach(type => {
          Object.keys(type).forEach(key => {
            defaultValues[key] = false
          })
        })
        this.setState({ defaultValues }, () => this.getPdfSettings())
      })
  }

  newModal = () => {
    this.setState({ newModal: true, edit: false });
  };

  getPdfSettings = () => {
    const { defaultValues } = this.state;
    axios.get("/pdf_settings").then(res => {
      const pdfSettingsList = res.data.pdf_settings;
      const distinctTypes = [...new Set(pdfSettingsList.map(x => x.setting_type))]
      distinctTypes.forEach(type => {
        Object.keys(defaultValues).forEach(value => {
          if (type === value) {
            this.setState({
              defaultValues: {
                ...this.state.defaultValues,
                [value]: true
              }
            })
          }
        })
      })
      this.setState({ pdfSettingsList, loading: false });
    });
  };

  deleteHandler = id => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      axios.delete("/pdf_settings/" + id).then(res => {
        if (res.data.json.success) {
          toast.success("Successfully deleted");
        }
        this.getPdfSettings();
      });
    }
  };

  editSetting = setting => {
    this.setState({
      edit: true,
      id: setting.id,
      setting_key: setting.setting_type,
      setting_value: setting.content,
      uiValue: setting.setting_type_humanized
    });
  };

  render() {
    const settingsList = this.state.pdfSettingsList;
    const { defaultValues } = this.state;
    let pdfSettings;
    let settingValue;
    if (this.state.edit) {
      settingValue = (
        <AddOrEditSetting
          id={this.state.id}
          setting_key={this.state.setting_key}
          setting_value={this.state.setting_value}
          uiValue={this.state.uiValue}
          getPdfSettings={this.getPdfSettings}
          isEdit={true}
          closeModal={() => this.setState({ edit: false })}
        />
      );
    }
    if (this.state.loading) {
      pdfSettings = <Spinner />;
    } else {
      pdfSettings = settingsList.map(setting => {
        return (
          <li
            className="list-group-item pdf-settings"
            id="setting"
            key={setting.id}
          >
            {this.state.edit && this.state.id === setting.id ? (
              ""
            ) : (
                <div className="row p-0 mb-2">
                  <div className="col-lg-11 list-heading">
                    {setting.setting_type_humanized}
                  </div>
                  <div className="col-lg-1 col-md-2 list-icons d-flex justify-content-between row p-0">
                    <div className="col-lg-6 d-flex justify-content-end">
                      <i
                        className="fas fa-pencil-alt cursor pdf-edit"
                        onClick={() => this.editSetting(setting)}
                      />
                    </div>
                    <div className="col-lg-6 d-flex justify-content-end">
                      <i
                        className="fas fa-trash cursor pdf-delete"
                        onClick={() => this.deleteHandler(setting.id)}
                      />
                  </div>
                  </div>
                </div>
              )}
            <div className="col-lg-12 col-md-7 name p-0 row">
              <div className="col-lg-12">
                {this.state.edit && setting.id === this.state.id
                  ? settingValue
                  : setting.content ? <p>{setting.content.split("\n").map(content => <p>{content}</p>) }</p> : "-empty, to update please create a new PDF Setting-"
                    .trim()
                    .split("\r\n\r\n")
                    .map((setting, index) => (
                      <p className="text-justify mb-0" key={index}>
                        {setting}
                      </p>
                    ))
                }
              </div>
            </div>
          </li>
        );
      });
    }

    let defaultSettings = null;
    if (!this.state.loading) {
      defaultSettings = Object.keys(defaultValues).filter(key => defaultValues[key] === false).map((key, index) =>
        (
          <li className="list-group-item pdf-settings" key={index}>
            <div className="row">
              <div className="col-md-12">
                <h5 className="list-heading">{title(key)}</h5>
                <span>-empty, to update please create a new PDF Setting-</span>
              </div>
            </div>
          </li>
        )
      )
    }

    const newPdf = (
      <div className="col-lg-12 mb-3">
        <AddOrEditSetting
          closeModal={() => this.setState({ newModal: false })}
          getPdfSettings={this.getPdfSettings}
          edit={this.state.edit}
          id={this.state.id}
        />
      </div>
    );
    return (
      <div className="assetPriceType">
        <div className="p-3">
          <div className="row">
            <div className="col-lg-6">
              <h5 className="font-weight-bold mt-2">Invoice PDF</h5>
            </div>
            <div className="col-lg-6 text-right">
              <Button type="success" clicked={this.newModal}>
                {langUtils("txt_global_add_new", "Add New")}
              </Button>
            </div>
          </div>
        </div>
        {this.state.newModal ? newPdf : ""}
        <ul className="list-group list-group-flush mt-3">{pdfSettings}{defaultSettings}</ul>
      </div>
    );
  }
}
export default PackingList;
