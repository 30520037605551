import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"

import axios from "../../../configAxios"
import errorHandler from "../../errorPages/UserNotAuthorized"

import FormButton from "../../UI/button/FormButton"
import Button from "../../UI/button/Button"
import Input from "../../UI/input/Input"

import "./AddNewReel.css"

class AddToReel extends Component {
  signal = axios.CancelToken.source()
  state = {
    reelName: {
      elementType: "input",
      elementConfig: {
        name: "reelName",
        type: "text",
        required: true,
      },
      label: "Reel name",
      value: "",
    },
    reelSharing: {
      elementType: "select",
      elementConfig: {
        name: "reelSharing",
        placeholder: "Select Option",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
      label: "Reel Sharing",
    },
    disabled: false,
  }

  componentDidMount() {
    axios
      .get("/reel_project_attribute/formdata", {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const sharingArray = res.data.json.sharings
        const sharings = sharingArray.map((item) => {
          let label
          if (item[0] === "me") {
            label = "Private"
          } else if (item[0] === "staff") {
            label = "Staff Only"
          } else if (item[0] === "everyone") {
            label = "Public"
          } else {
            label = item[0]
          }
          const obj = {
            label: label,
            value: item[1],
          }
          return obj
        })
        this.setState({
          reelSharing: {
            ...this.state.reelSharing,
            elementConfig: {
              ...this.state.reelSharing.elementConfig,
              options: sharings,
            },
          },
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {}
    if (key === "reelName") {
      inputValue.value = event.target.value
    } else if (key === "reelSharing") {
      inputValue.selectedOption = event
    }
    this.setState({
      [key]: {
        ...this.state[key],
        ...inputValue,
      },
    })
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const { reelName, reelSharing } = this.state
    const reelData = {
      reel_name: reelName.value,
      reel_sharing: reelSharing.selectedOption.value,
    }
    axios.post("/reel_project_attribute", reelData).then((res) => {
      if (res.data.json.success) {
        toast.success("Reel created successfully")
        this.props.closeModal()
        this.props.getReels()
        this.props.history.push("/reels")
      } else {
        errorHandler(res)
        this.setState({ disabled: false })
      }
    })
  }

  render() {
    const { reelName, reelSharing } = this.state
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        <Input
          {...reelName}
          changed={(event) => this.inputChangedHandler(event, "reelName")}
        />
        <Input
          {...reelSharing}
          changed={(event) => this.inputChangedHandler(event, "reelSharing")}
        />
        <div className="text-center">
          <Button type="secondary" clicked={this.props.closeModal}>
            Cancel
          </Button>
          <FormButton className="ml-2" disabled={this.state.disabled}>
            Create Reel
          </FormButton>
        </div>
      </form>
    )
  }
}

export default withRouter(AddToReel)
