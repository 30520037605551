import React, { Component } from "react";
import { connect } from "react-redux";
import { title } from "change-case";
import _ from "lodash";
import { getProject } from "../../../store/actions/index";
import Input from "../../UI/input/Input";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import axios from "../../../configAxios";
import { toast } from "react-toastify";
import { FORM_ATTRIBUTE_TYPES } from "./Constants";

class EditAttribute extends Component {
  signal = axios.CancelToken.source();
  state = {
    attrName: {
      elementType: "input",
      elementConfig: {
        type: "text",
      },
      label: "Attribute Type",
      value: "",
    },
    attrType: {
      elementType: "select",
      elementConfig: {
        name: "attrType",
        placeholder: "Select Option",
        options: FORM_ATTRIBUTE_TYPES,
        required: true,
        isLoading: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          },
        },
      },
      selectedOption: { label: "Long Answer", value: "long" },
      label: "Select Form Attribute",
    },
  };

  componentDidMount() {
    this.setState(
      {
        attrName: {
          ...this.state.attributeName,
          value: this.props.attribute.name,
        },
      }
      // () => this.getattributeValue()
    );
  }

  // getattributeValue = () => {
  //   let updatedControls;
  //   if (!this.props.longAttribute) {
  //     updatedControls = {
  //       ...this.state.attributeValue,
  //       value: {
  //         label: this.props.attributesData.value,
  //         value: `${this.props.attributesData.value_2}`,
  //       },
  //       selectedOption: {
  //         label: this.props.attributesData.value,
  //         value: `${this.props.attributesData.value_2}`,
  //       },
  //     };
  //   } else {
  //     const { required, name } = this.state.attributeValue.elementConfig;
  //     const { label } = this.state.attributeValue;
  //     updatedControls = {
  //       ...this.state.attributeValue,
  //       elementType: "textarea",
  //       label,
  //       value: this.props.attributesData.value,
  //       elementConfig: {
  //         name,
  //         required,
  //         type: "textarea",
  //         rows: 4,
  //       },
  //     };
  //   }

  //   this.setState({ attributeValue: updatedControls, disabled: false });
  // };

  inputChangedHandler(event, key) {
    let inputValue = {};
    inputValue["value"] = this.props.longAttribute
      ? event.target.value
      : _.isEmpty(event)
      ? null
      : event;
    if (!this.props.longAttribute)
      inputValue["selectedOption"] = _.isEmpty(event) ? null : event;

    const updatedControls = {
      ...this.state[key],
      ...inputValue,
    };

    this.setState({ [key]: updatedControls });
  }

  // updateAttributeHandler = event => {
  //   this.setState({ disabled: true });
  //   event.preventDefault();
  //   if (
  //     !this.props.longAttribute &&
  //     _.isEmpty(this.state.attributeValue.value)
  //   ) {
  //     toast.error("Value field is empty");
  //     this.setState({ disabled: false });
  //     return;
  //   }
  //   const { attributesData } = this.props;
  //   const attrData = {
  //     id: attributesData.id,
  //     project_id: attributesData.project_id,
  //     project_attribute_type_id: attributesData.project_attribute_type_id,
  //     value: this.props.longAttribute
  //       ? this.state.attributeValue.value
  //       : this.state.attributeValue.value.label,
  //   };
  //   const id = this.props.projects.project.slug
  //     ? this.props.projects.project.slug
  //     : this.props.projects.project.id;
  //   if (this.state.updatedValue !== "") {
  //     axios
  //       .put(`/project_attributes/${attributesData.id}`, attrData)
  //       .then(res => {
  //         if (res.data.json.success) {
  //           toast.success("Updated Successfully");
  //           this.props.getProject(id);
  //           this.props.getAttributes();
  //           this.props.closeModal();
  //         } else {
  //           if (res.data.json.hasOwnProperty("errors")) {
  //             Object.keys(res.data.json.errors).forEach(error => {
  //               toast.error(title(error) + " " + res.data.json.errors[error]);
  //             });
  //           }
  //           if (res.data.json.hasOwnProperty("error")) {
  //             toast.error(res.data.json.error);
  //           }
  //           this.setState({ disabled: false });
  //         }
  //       })
  //       .catch(err => console.log(err));
  //   }
  // };

  // searchUserHandler = (inputValue, callback) => {
  //   const queryParams = {
  //     params: { query: inputValue },
  //     cancelToken: this.signal.token,
  //   };
  //   axios.get("/users/autocomplete", queryParams).then(res => {
  //     callback(
  //       res.data.users.map(user => ({
  //         label: user.full_name,
  //         value: `${user.id}`,
  //       }))
  //     );
  //   });
  // };

  render() {
    return (
      <form
        className="mt-3"
        onSubmit={this.updateAttributeHandler}
        key={this.state.attrType.elementConfig.type}
      >
        <Input {...this.state.attrType} disabled={false} />
        <Input
          {...this.state.attrName}
          changed={event => this.inputChangedHandler(event, "attributeValue")}
        />
        <div className="text-center">
          <Button type="secondary" clicked={this.props.closeModal}>
            Cancel
          </Button>
          <FormButton className="ml-2" disabled={this.state.disabled}>
            Update
          </FormButton>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    // projects: state.projects,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProject: id => dispatch(getProject(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAttribute);
