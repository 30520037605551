import React, { useEffect, useState } from 'react'
import axios from "../../../configAxios";
import ApplicationFormTable from "./ApplicationFormTable";

const GrantApplicationItem = ({ grantApplication }) => {
  const [formsList, setFormsList] = useState([])

  useEffect(() => {
    if (grantApplication) {
      axios.get(`/grant_campaigns/${grantApplication.grant_campaign_id}/forms`)
        .then(res => setFormsList(res.data.json.data))
    }
  }, [grantApplication])

  return <>
    <h3 className="mt-3 pt-3">{grantApplication.grant_campaign_name}</h3>
    <div className="mb-3 text-center">
      <table className="w-100 mb-3">
        <colgroup>
          <col className="w-25" />
          <col className="w-25" />
          <col className="w-25" />
        </colgroup>
        <thead>
        <tr className="">
          <th>Application Name</th>
          <th>Updated</th>
          <th>Status</th>
        </tr>
        </thead>
        <thead className="border border-bottom-0">
        <tr>
          <td>{grantApplication.name}</td>
          <td>{grantApplication.updated_when}</td>
          <td>{grantApplication.status}</td>
        </tr>
        </thead>
        <tbody className="border border-bottom-0">
        <tr>
          <td colSpan="4" className="px-0">
            <ApplicationFormTable key={grantApplication.id} forms={formsList} grantApplication={grantApplication}/>
          </td>
        </tr>
        </tbody>
        <tfoot className="border-bottom">
          <tr />
        </tfoot>
      </table>
    </div>
  </>
}

export default GrantApplicationItem
