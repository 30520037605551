import React, { Component } from "react"

class FormTab extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const tabNames = this.props.tabs
    const tabs = tabNames.map((tab, index) =>
      this.props.removeExpertsTab ? (
        tab !== "Experts" && (
          <li
            key={index}
            onClick={() => this.props.tabChangedHandler(tab)}
            className={`nav-item font-weight-bold px-4 ${
              this.props.activeTab === tab ? "active-tab " : "inactive-tab"
            }`}
          >
            {tab}
          </li>
        )
      ) : (
        <li
          key={index}
          onClick={() => this.props.tabChangedHandler(tab)}
          className={`nav-item font-weight-bold px-4 ${
            this.props.activeTab === tab ? "active-tab " : "inactive-tab"
          }`}
        >
          {tab}
        </li>
      )
    )

    return (
      <React.Fragment>
        <ul className="nav nav-tabs ml-0">{tabs}</ul>
      </React.Fragment>
    )
  }
}

export default FormTab
