import React, { Component } from "react"
import { Link } from "react-router-dom"
import dateFns from "date-fns"
import _ from "lodash"
import axios from "../../../configAxios"
import Spinner from "../../UI/Spinner"
import AssetCalendar from "../../calendar"
import Modal from "../../UI/Modal"
import EditPhoto from "./EditPhoto"
import AddFlag from "../../Flag/AddNewFlag"
import History from "../history"
import ShowLibrary from "./ShowLibrary"
import NoImage from "../../../assests/images/no-image.png"
import "./index.css"

class AssetDetail extends Component {
  signal = axios.CancelToken.source()
  state = {
    asset: {},
    loading: true,
    photoModal: false,
    addFlagModal: false,
    includedAccTruncuate: true,
    detailsTruncuate: true,
    accountingCodes: [],
    locations: [],
    thirdPartyOwners: [],
    activeTab: "calendar",
    historyDataAll: [],
    width: window.innerWidth,
    showImageModal: false,
    historyLoading: true,
  }

  async componentDidMount() {
    await this.getAssetDetail()
    this.getAssetFlags()
    const formdata = await axios.get("/assets/formdata", {
      cancelToken: this.signal.token,
    })
    this.setState({
      accountingCodes: formdata.data.json.accounting_codes,
      locations: formdata.data.json.locations,
      thirdPartyOwners: formdata.data.json.third_party_owners,
      loading: false,
    })
  }

  getAssetFlags = async () => {
    const params = {
      asset_id: this.props.match.params.id,
    }
    const flagRes = await axios.get("/flags", {
      params,
      cancelToken: this.signal.token,
    })
    const bookingsRes = await axios.get("/bookings", {
      params,
      cancelToken: this.signal.token,
    })
    const flagHistory = flagRes.data.flags.map((flag) => {
      return {
        ...flag,
        history_type: "flag",
      }
    })
    const bookingsHistory = bookingsRes.data.bookings.map((booking) => {
      return {
        ...booking,
        history_type: "booking",
      }
    })
    const historyDataAll = [...flagHistory, ...bookingsHistory]
    historyDataAll.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    )
    const { activeTab } = this.state
    let historyData = [...historyDataAll]
    if (activeTab === "booking" || activeTab === "flag") {
      historyData = historyDataAll.filter(
        (historyValue) => historyValue.history_type === activeTab
      )
    }
    this.setState({
      historyDataAll,
      historyData,
      historyLoading: false,
    })
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange)
    this.signal.cancel("Request is being Cancelled")
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  getAssetDetail = async () => {
    const asset = await axios.get(`/assets/${this.props.match.params.id}`, {
      cancelToken: this.signal.token,
    })
    this.setState({
      asset: asset.data.asset_detail,
    })
  }

  renderInfo = (key, info = "", length = 0) => {
    if (!this.state.loading) {
      return (
        <div className="text-muted">
          {" "}
          {info === null
            ? ""
            : this.state[key] && length < info.length
            ? info.substring(0, length) + "..."
            : info}
          <span
            className="text-primary cursor"
            onClick={() =>
              this.setState({
                [key]: !this.state[key],
              })
            }
          >
            {info === null
              ? ""
              : length < info.length
              ? this.state[key]
                ? " See More"
                : " See Less"
              : ""}
          </span>
        </div>
      )
    }
  }

  getAssetDescription(description) {
    const descriptionArray = description.split(". ")
    let desc
    if (descriptionArray[0].length < 25 && descriptionArray[1]) {
      const description = descriptionArray[0] + ". "
      desc = description.concat(descriptionArray[1]) + ". "
    } else {
      desc = descriptionArray[0] + ". "
    }
    return desc
  }

  handleTabChange = (key) => {
    let historyData, activeTab
    if (key === "all") {
      historyData = this.state.historyDataAll
      activeTab = "history"
    } else {
      historyData = this.state.historyDataAll.filter(
        (historyValue) => historyValue.history_type === key
      )
      activeTab = key
    }
    this.setState({
      historyData,
      activeTab,
    })
  }

  render() {
    const {
      asset,
      loading,
      accountingCodes,
      locations,
      width,
      thirdPartyOwners,
    } = this.state
    const isMobile = width < 768
    const basicContentObj = {
      created_at: "Added to the database on",
      category_name: "Category",
      price_types:
        asset.type === "EquipmentItem" ? "Prices(per day)" : "Prices(per hour)",
      barcode: "Barcode/ISBN",
      accounting_code: "Accounting Code",
      serial_number: "Serial",
      insurance_value: "Insurance Value",
      location: "Location",
      bulk_asset: "Bulk Asset",
    }
    
    let basicContent
    if (!loading) {
      basicContent = Object.keys(basicContentObj).map((key) => {
        let text

        const accountCode = accountingCodes.filter(
          (code) => code[0] === asset.accounting_code_id
        )[0]

        const location = locations.filter(
          (location) => location[0] === asset.location_id
        )[0]

        switch (key) {
          case "created_at":
            text = dateFns.format(asset.created_at, "MMM DD YYYY")
            break
          case "accounting_code":
            text = accountCode && accountCode[1]
            break
          case "location":
            text = location && location[2]
            break
          case "price_types":
            text =
              asset.hasOwnProperty(key) &&
              asset[key].map((type) => <div key={type[0]}>{type[0]}</div>)
            break
          case "bulk_asset":
            text = asset.is_bulk_asset
              ? `${asset[key].bulk_count_available} out of ${asset[key].bulk_count_total} currently available`
              : ""
            break
          default:
            text = asset[key]
        }

        if (!text) return null
        return (
          <div className="card" key={key}>
            <div className="info-heading font-weight-bold">
              {basicContentObj[key]}
            </div>
            <div className="info-text">{text}</div>
          </div>
        )
      })
    }

    const photoModal = (
      <Modal
        title="Edit Photo"
        show={this.state.photoModal}
        hide={() => this.setState({ photoModal: false })}
      >
        <EditPhoto
          assetId={this.props.match.params.id}
          updatePhoto={() => this.getAssetDetail()}
          closeModal={() => this.setState({ photoModal: false })}
        />
      </Modal>
    )

    const addFlagModal = (
      <Modal
        show={this.state.addFlagModal}
        hide={() => this.setState({ addFlagModal: false })}
      >
        <AddFlag
          assetId={this.props.match.params.id}
          closeModal={() => this.setState({ addFlagModal: false })}
          getFlags={this.getAssetFlags}
          historyLoading={() => this.setState({ historyLoading: true })}
          assetFlag={true}
        />
      </Modal>
    )

    const viewPhotoModal = (
      <Modal
        show={this.state.showImageModal}
        hide={() => this.setState({ showImageModal: false })}
        closeButton
        closeModal={() => this.setState({ showImageModal: false })}
        size="lg"
      >
        <div className="mt-4 text-center">
          <img
            className="mt-1 img-fluid align-center"
            src={asset.photo_medium || NoImage}
            onError={(e) => {
              e.target.onerror = null
              e.target.src = asset.photo ? asset.photo : NoImage
            }}
            alt="asset"
          />
        </div>
      </Modal>
    )

    let owner
    if (asset.third_party_rental_agreement) {
      owner = thirdPartyOwners.filter((owner) => {
        return (
          owner.id === asset.third_party_rental_agreement.third_party_owner_id
        )
      })[0]
    }

    let renderDetail = null
    if (asset.type === "EquipmentItem" || asset.type === "Facility") {
      renderDetail = (
        <React.Fragment>
          {asset.included_accessories && (
            <div className="card">
              <div className="text-dark">Included Accessories</div>
              <div className="white-space">
                {this.renderInfo(
                  "includedAccTruncuate",
                  asset.included_accessories,
                  450
                )}
              </div>
            </div>
          )}
          {asset.warranty_info && (
            <div className="card">
              <div className="text-dark">Warranty Info</div>
              <div className="text-muted white-space">
                {asset.warranty_info}
              </div>
            </div>
          )}
          {asset.third_party_rental_agreement && !_.isEmpty(owner) && (
            <div className="card">
              <div className="text-dark">
                This is a Third Party Rental Asset
              </div>
              <div className="text-muted">
                <div>{`Owner: ${
                  owner && owner.first_name + " " + owner.last_name
                }`}</div>
                <div>{`${localStorage.organizationName}'s %: ${asset.third_party_rental_agreement.middleman_percentage}`}</div>
                <div>{`Notes: ${asset.third_party_rental_agreement.notes}`}</div>
              </div>
            </div>
          )}
        </React.Fragment>
      )
    } else {
      renderDetail = !this.state.loading && (
        <ShowLibrary asset={this.state.asset} />
      )
    }

    const image = asset.photo ? asset.photo : NoImage
    const description =
      asset.description && this.getAssetDescription(asset.description)

    return (
      <div className="asset-detail">
        <div className="card">
          {loading ? (
            <Spinner />
          ) : (
            <div className="card-body">
              <div className="card-text">
                <div className="row">
                  <div
                    className="col-lg-3 text-center cursor"
                    onClick={() => {
                      window.scrollTo(0, 0)
                      this.setState({ showImageModal: true })
                    }}
                  >
                    <div
                      className="rounded-circle mx-auto mt-4 bg-cover"
                      style={{
                        width: 140,
                        height: 140,
                        backgroundImage: `url(${image})`,
                      }}
                    />
                  </div>
                  <div className="col-lg-9">
                    <div className="row my-3">
                      <div className="col-md-6 heading">
                        <div>{asset.name}</div>
                      </div>
                      <div className="col-md-6 font-weight-bold my-2">
                        <div className="row justify-content-between">
                          <div className="ml-3 status-text">
                            {asset.status_text}
                          </div>
                          {asset.external_url_resources && (
                            <div className="mr-3">
                              <a
                                className="text-primary decoration-none"
                                href={asset.external_url_resources}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {!isMobile && <span>Resources</span>} &nbsp;
                                <i className="fas fa-external-link-alt" />
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <span
                          className="text-primary cursor mr-2"
                          onClick={() => {
                            window.scrollTo(0, 0)
                            this.setState({ photoModal: true })
                          }}
                        >
                          Add/Change Photo
                        </span>
                        |
                        <Link
                          to={
                            asset.type === "EquipmentItem" ||
                            asset.type === "Facility"
                              ? `/assets/edit/${this.props.match.params.id}`
                              : `/libraries/${asset.type.toLowerCase()}/${
                                  this.props.match.params.id
                                }/edit?type=${asset.type.toLowerCase()}`
                          }
                          className="text-info decoration-none cursor mx-2"
                        >
                          Edit this Asset
                        </Link>
                        |
                        <span
                          className="text-primary cursor mx-2"
                          onClick={() => {
                            window.scrollTo(0, 0)
                            this.setState({ addFlagModal: true })
                          }}
                        >
                          Add a Flag to this Asset
                        </span>
                        |
                        <span
                          className="text-primary cursor mx-2"
                          onClick={() =>
                            this.props.history.push(
                              `/assets/${this.props.match.params.id}/report`
                            )
                          }
                        >
                          View Asset Report
                        </span>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-12 text-muted">
                        <p>{description}</p>
                      </div>
                    </div>

                    <div className="card-columns">
                      {basicContent}
                      {renderDetail}
                    </div>

                    <div className="row">
                      {asset.type === "EquipmentItem" ||
                      asset.type === "Facility" ? (
                        <>
                          {asset.description && (
                            <div className="col-md-12">
                              <div className="text-dark">Details</div>
                              <div className="white-space">
                                {this.renderInfo(
                                  "detailsTruncuate",
                                  asset.description,
                                  1300
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {asset.asset_contributers &&
                            asset.asset_contributers.length > 0 && (
                              <div className="col-md-8">
                                <div className="info-heading font-weight-bold">
                                  Contributers
                                </div>
                                <div className="row mt-1">
                                  <div className="col-md info-heading font-weight-bold">
                                    Type
                                  </div>
                                  <div className="col-md info-heading font-weight-bold">
                                    Name
                                  </div>
                                </div>
                                {asset.asset_contributers.map((contributer) => (
                                  <div
                                    className="row info-text"
                                    key={contributer.id}
                                  >
                                    <div className="col-md">
                                      {contributer.contributer_type}
                                    </div>
                                    <div className="col-md">
                                      {contributer.name}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* col-lg-10 ends here */}
                </div>
              </div>
              <div className="mx-2">
                <div className="text-center my-4">
                  <span
                    className={`${
                      this.state.activeTab === "calendar" ? "text-primary " : ""
                    } cursor mx-3`}
                    onClick={() => this.setState({ activeTab: "calendar" })}
                  >
                    {" "}
                    Asset calendar{" "}
                  </span>
                  |
                  <span
                    className={`${
                      this.state.activeTab === "history" ? "text-primary " : ""
                    } cursor mx-3`}
                    onClick={() => this.handleTabChange("all")}
                  >
                    {" "}
                    See History{" "}
                  </span>
                  (
                  <span
                    className={`${
                      this.state.activeTab === "booking" ? "text-primary " : ""
                    } cursor`}
                    onClick={() => this.handleTabChange("booking")}
                  >
                    {" "}
                    Booking{" "}
                  </span>
                  /
                  <span
                    className={`${
                      this.state.activeTab === "flag" ? "text-primary " : ""
                    } cursor`}
                    onClick={() => this.handleTabChange("flag")}
                  >
                    {" "}
                    Flags{" "}
                  </span>
                  )
                </div>
                {this.state.activeTab === "calendar" ? (
                  <AssetCalendar
                    assetId={this.props.match.params.id}
                    calendarType={asset.type}
                    isBulkAsset={asset.is_bulk_asset}
                  />
                ) : (
                  <History
                    historyData={this.state.historyData}
                    historyLoading={this.state.historyLoading}
                    historyType={this.state.activeTab}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {photoModal}
        {addFlagModal}
        {viewPhotoModal}
      </div>
    )
  }
}

export default AssetDetail
