export default {
  textInput: {
    name: "assetName",
    photo: "photo",
    purchase_date: "purchaseDate",
    description: "description",
    included_accessories: "includedAccessories",
    warranty_info: "warrantyInfo",
    serial_number: "serialNumber",
    purchase_amount: "purchaseAmount",
    insurance_value: "insuranceValue",
    change_notes: "notes",
    barcode: "barcode",
    external_url_resources: "externalUrl",
    unique_name: "uniqueName",
    bulk_count_total: "bulkTotalCount",
    primary_operator_first_name: "primaryEquipmentFirstName",
    primary_operator_last_name: "primaryEquipmentLastName",
    primary_operator_contact_info: "primaryEquipmentContactInfo"
  },
  dropdown: {
    category_id: "category",
    location_id: "location",
    accounting_code_id: "accountingCode"
  },
  checkbox: {
    is_gst_used: "gst",
    is_pst_used: "pst",
    is_hst_used: "hst",
    asset_for_sale: "assetForSale",
  }
};
