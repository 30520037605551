import React, { useEffect, useReducer } from "react"
import axios from "axios"
import { flatten, isEmpty, sortBy } from "lodash"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { toast } from "react-toastify"
import LabelHeader from "./LabelCategory/LabelHeader"
import LabelCategory from "./LabelCategory"
import Spinner from "../UI/Spinner"

const LabelCategoriesTab = () => {
  const [{ labelCategories, loading, pendingSubmission }, dispatch] = useReducer(dndReducer, initialDnDState)

  const getAllLabelCategories = () => {
    axios
      .get("label_categories")
      .then((res) => {
        const sortedCategories = sortBy(res.data.json.label_category, 'order')
        dispatch({ type: INIT_DND_STATE, labelCategories: sortedCategories, })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const updateCategoryOrder = ({ source, destination }) => {
    const reorderedCategories = [...labelCategories]
    const [removedCategory] = reorderedCategories.splice(source.index, 1)
    reorderedCategories.splice(destination.index, 0, removedCategory)

    dispatch({
      type: SET_CATEGORIES,
      labelCategories: reorderedCategories,
    })
  }

  const getUpdatedCategories = () => {
    return flatten(
      labelCategories.filter(category => category?.isUpdated) // returns only updated categories
    )
  }

  const submitCategoriesOrder = () => {
    if (!isEmpty(labelCategories)) {
      const updatedCategories = getUpdatedCategories()
      Promise
        .all(
          updatedCategories.map(category => {
            axios.put(`label_categories/${category.id}`, { name: category.name, order: category.order })
          })
        )
        .then(() => toast.success("Successfully reordered label categories"))
        .catch(() => toast.error("Could not update the label category order"))
        .finally(() => dispatch({ type: SET_PENDING_SUBMISSION, value: false }))
    }
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) return
    if (source.index === destination.index) return

    updateCategoryOrder(result)
  }

  useEffect(() => {
    getAllLabelCategories()
  }, [])

  useEffect(() => {
    if (pendingSubmission) {
      submitCategoriesOrder()
    }
  }, [labelCategories])

  const labelList =
    labelCategories && (
      <Droppable droppableId='labelCategoryList'>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {labelCategories.map((labelCategory, index) =>
              <Draggable key={labelCategory.id} draggableId={`${labelCategory.id}`} index={index}>
                {provided => (
                  <div className='list-group-item' {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                    <LabelCategory key={labelCategory.id} labelCategory={labelCategory} onUpdate={getAllLabelCategories} />
                  </div>
                )}
              </Draggable>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="list-group list-group-flush">
          <LabelHeader onCategoryUpdate={getAllLabelCategories} />
          {loading ? <Spinner /> : labelList}
        </div>
      </DragDropContext>
    </>
  )
}

const initialDnDState = {
  labelCategories: [],
  loading: true,
  pendingSubmission: false,
}

const INIT_DND_STATE = 'INIT_DND_STATE'
const SET_CATEGORIES = 'SET_CATEGORIES'
const SET_LOADING = 'SET_LOADING'
const SET_PENDING_SUBMISSION = 'SET_PENDING_SUBMISSION'

const dndReducer = (state, action) => {
  switch (action.type) {
    case INIT_DND_STATE:
      const sortedCategories = sortBy(action.labelCategories, 'order')
      return { ...state, labelCategories: sortedCategories, loading: false }

    case SET_CATEGORIES:
      const orderedCollection = action.labelCategories.map((category, index) => {
        const newOrder = index + 1
        const isUpdated = !(category.order === newOrder)

        return { ...category, order: newOrder, isUpdated }
      })

      return {
        ...state,
        pendingSubmission: true,
        labelCategories: sortBy(orderedCollection, 'order'),
      }

    case SET_LOADING:
      return { ...state, loading: action.value }

    case SET_PENDING_SUBMISSION:
      return { ...state, pendingSubmission: action.value }

    default:
      return state
  }
}

export default LabelCategoriesTab
