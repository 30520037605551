import React from "react";

function Bio({ text }) {
  return (
    <div>
      {text && (
        <div>
          <div className="row bg-light">
            <div className="col">
              <div className="pl-3 py-2">
                <h5 className="text-dark font-weight-bold m-0">Bio</h5>
              </div>
            </div>
          </div>
          <div className="ml-0 pb-3 row w-100">
            <div className="p-3 bg-white">
              {text}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Bio
