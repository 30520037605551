import { CLEAR_RESPONSE, LOADING, RESET_RESPONSE, SET_RESPONSE } from "./actionTypes";

export const setLoading = loading => {
  return {
    type: LOADING,
    loading: loading
  };
};

export const setResponse = (success, message) => {
  return {
    type: SET_RESPONSE,
    success: success,
    message: message
  };
};

export const clearResponse = () => {
  return {
    type: CLEAR_RESPONSE
  };
};

/* Instead of trying to replace everywhere that calls clearResponse at the risk of breaking
* existing application functionality, resetResponse will be used moving forward to appropriately
* reset the state to the initial state, rather than clear it to an empty object. */
export const resetResponse = () => {
  return { type: RESET_RESPONSE }
}
