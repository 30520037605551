import React, { Component } from "react";
import dateFns, { startOfYear } from "date-fns";
import { toast } from 'react-toastify';
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import DatePicker from "../UI/datePicker/index";
import FormButton from "../UI/button/FormButton";
import Button from '../UI/button/Button';
import Spinner from "../UI/Spinner";

const reportName = "EducationAndAssociationReport"

class EducationAndAssociation extends Component {
  signal = axios.CancelToken.source();
  state = {
    type: {
      elementType: "select",
      elementConfig: {
        name: "educationAndAssociation",
        options: [],
        isLoading: true,
        isClearable: false,
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Education and Association"
    },
    endDate: new Date(),
    startDate: startOfYear(new Date()),
    downloading: false,
    disabled: true,
    CSVDisabled: true
  }

  componentDidMount() {
    axios.get("/users/formdata", { cancelToken: this.signal.token })
      .then(res => {
        const educationAndAssociations = res.data.json.education_and_association;
        const options = educationAndAssociations.map(educationAndAssociation => {
          return {
            label: educationAndAssociation.name,
            value: educationAndAssociation.id
          }
        })
        this.setState({
          type: {
            ...this.state.type,
            elementConfig: {
              ...this.state.type.elementConfig,
              options,
              isLoading: false
            }
          },
          disabled: false
        })
      })
  }

  inputChangeHandler = event => {
    this.setState({
      type: {
        ...this.state.type,
        selectedOption: event
      }
    })
  }

  submitHandler = event => {
    this.setState({ loading: true, disabled: true })
    event.preventDefault();
    const params = {
      report_name: reportName,
      'report_options[id]': this.state.type.selectedOption.value,
      'report_options[start_date]': dateFns.format(this.state.startDate, "DD-MM-YYYY"),
      'report_options[end_date]': dateFns.format(this.state.endDate, "DD-MM-YYYY")
    }
    axios.get("/reports/education_and_association", {
      params,
      cancelToken: this.signal.token
    })
      .then(res => {
        this.setState({
          educationAndAssociations: res.data["reports/education_and_association"],
          loading: false,
          disabled: false,
          CSVDisabled: false
        })
      })
  }

  downloadReport = async () => {
    try {
      if (this.state.downloading) return;
      this.setState({ downloading: true });
      const report_options = {
        id: this.state.type.selectedOption.value,
        start_date: dateFns.format(this.state.startDate, "DD-MM-YYYY"),
        end_date: dateFns.format(this.state.endDate, "DD-MM-YYYY")
      };
      let report = await axios.get("/reports/education_and_association/download_csv", {
        params: {
          report_name: reportName,
          report_options
        },
        cancelToken: this.signal.token
      });
      if (report.data.json.generating) {
        toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
        let timerId = setInterval(
          () => this.checkDownloadProgress(report.data.json.id),
          5000
        );
        this.setState({ timerId });
      }
    } catch (err) {
      this.setState({ downloading: false });
      console.log(err);
    }
  };

  checkDownloadProgress = async id => {
    try {
      let progress = await axios.get("/reports/education_and_association/check_generating", {
        params: {
          id
        },
        cancelToken: this.signal.token
      });
      if (typeof progress.data.json === "string") {
        clearInterval(this.state.timerId);
        this.setState({ downloading: false });
        toast.success("File successfully downloaded");
        window.open(progress.data.json, "_blank");
        return;
      }
    } catch (e) {
      toast.error("Downloading failed");
      clearInterval(this.state.timerId);
    }
  };

  render() {
    const { educationAndAssociations, type, loading, disabled, CSVDisabled } = this.state;
    let tableBody;
    if (educationAndAssociations) {
      tableBody = educationAndAssociations.map(educationAndAssociation => (
        <tr>
          <td>{educationAndAssociation.full_name}</td>
          <td>
            {
              educationAndAssociation.mobile_phone ?
                educationAndAssociation.mobile_phone :
                educationAndAssociation.home_phone ?
                  educationAndAssociation.home_phone : "--"
            }
          </td>
          <td>{educationAndAssociation.email}</td>
          <td>{dateFns.format(educationAndAssociation.created_at, "YYYY-MM-DD")}</td>
        </tr>
      ))
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3>Education and Associaton Report</h3>
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <form onSubmit={this.submitHandler}>
                    <div className="row">
                      <div className="col-md-3">
                        <Input {...type} changed={this.inputChangeHandler} />
                      </div>
                      <div className="col-md-3 form-group">
                        <label className="font-weight-normal">Start Date</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.startDate}
                          changed={date => this.setState({ startDate: date })}
                          className="w-100"
                        />
                      </div>
                      <div className="col-md-3 from-group">
                        <label className="font-weight-normal">End Date</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.endDate}
                          changed={date => this.setState({ endDate: date })}
                          className="w-100"
                        />
                      </div>
                      <div className="col-auto" style={{ marginTop: '1.9rem' }}>
                        <FormButton disabled={disabled}>Submit</FormButton>
                      </div>
                      <div className="col-auto" style={{ marginTop: '1.9rem' }}>
                        <Button type="warning" disabled={CSVDisabled} clicked={this.downloadReport}>
                          Generate CSV
                        </Button>
                      </div>
                    </div>
                  </form>
                  {!loading && educationAndAssociations && educationAndAssociations.length > 0 ?
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>{tableBody}</tbody>
                    </table>
                    : !loading && educationAndAssociations && educationAndAssociations.length <= 0
                      ? <div className="text-center font-weight-bold">No Data</div>
                      : loading && type.selectedOption && <Spinner />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EducationAndAssociation;
