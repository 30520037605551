import React, { Component } from "react";
import axios from "../../configAxios";
import dateFns from "date-fns";
import Spinner from "../UI/Spinner";
import FormButton from "../UI/button/FormButton";
import DatePicker from "../UI/datePicker/index";

class AssetServicing extends Component {
  constructor() {
    super();
    const currentMonthDate = new Date();
    this.signal = axios.CancelToken.source();
    this.state = {
      startDate: new Date().setMonth(currentMonthDate.getMonth() - 1),
      endDate: new Date(),
      assetServicing: {},
      metaData: {},
      loading: true,
      disabled: true
    };
  }

  componentDidMount() {
    const queryParams = {
      id: "AssetServicingReport"
    };
    this.getAssetServicing(queryParams);
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  getAssetServicing(queryParams) {
    axios.get("/reports/asset_servicing", { params: queryParams, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          assetServicing: res.data.json.data,
          metaData: res.data.json.meta,
          loading: false,
          disabled: false
        });
      });
  }

  submitHandler = event => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const { startDate, endDate } = this.state;
    const report_options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD")
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD")
      }
    };
    const queryParams = {
      id: "AssetServicingReport",
      report_options
    };
    this.getAssetServicing(queryParams);
  };

  render() {
    const { assetServicing } = this.state;
    const assetServicingList = Object.keys(assetServicing).map(key => (
      <tr key={key}>
        <td>{key}</td>
        <td>{assetServicing[key]}</td>
      </tr>
    ));

    return (
      <div className="container service-kind w-100">
        <div className="row mb-4">
          <div className="col-12">
            <h3>Asset Servicing Report</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text overflow-reports-card">
                  <form onSubmit={this.submitHandler}>
                    <div className="row">
                      <div className="col-lg-3">
                        <label>From</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.startDate}
                          changed={date => this.setState({ startDate: date })}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label>To</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.endDate}
                          changed={date => this.setState({ endDate: date })}
                        />
                      </div>
                      <div className="col-lg-3">
                        <FormButton className="build-report" disabled={this.state.disabled}>Build Report</FormButton>
                      </div>
                    </div>
                  </form>
                  <div className="row mt-5">
                    <div className="col-12">
                      <h5 className="font-weight-bold">Statistics</h5>
                    </div>
                    <div className="col-12">
                      {
                        this.state.loading ? (
                          <Spinner className="h-20vh" />
                        ) : (
                            <p>
                              Total Assets serviced during period:{" "}
                              {this.state.loading ? 0 : this.state.metaData.total_count}
                            </p>
                          )
                      }
                    </div>
                  </div>
                  <div className="list mt-5">
                    <h5 className="font-weight-bold">
                      Assets serviced during period
                    </h5>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th className="w-60">Asset</th>
                            <th className="w-40">Frequency</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!this.state.loading &&
                            assetServicingList}
                        </tbody>
                      </table>
                    </div>
                    {this.state.loading && <Spinner className="h-30vh" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssetServicing;
