import React, { Component } from "react"
import { Link } from "react-router-dom"
import NoImage from "../../../assests/images/no-image.png"
import "./ProjectItem.css"

class ProjectItem extends Component {
  render() {
    const { project } = this.props
    const showCheckbox = (
      <input
        type="checkbox"
        disabled={project.has_video ? false : true}
        checked={this.props.isChecked}
      />
    )

    const showText = <p className="font-weight-bold">No video</p>

    const image = project.thumbnail ? project.thumbnail : NoImage
    return (
      <li className="list-group-item project-item">
        <div
          className="row no-gutters"
          data-toggle={project.has_video ? "" : "tooltip"}
          title={project.has_video ? "" : "Project has no video!"}
          onClick={() =>
            this.props.clicked(
              project.id,
              project.has_video ? !this.props.isChecked : this.props.isChecked
            )
          }
        >
          <div className="col-md-2">
            <a
              href={project.thumbnail}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="rounded-circle bg-cover"
                style={{
                  width: "100%",
                  paddingTop: "100%",
                  backgroundImage: `url(${image})`,
                }}
              />
            </a>
          </div>
          <div className="col-md-8 px-3">
            {project.slug ? (
              <Link
                to={`projects/${project.slug}`}
                className="text-info font-weight-normal"
              >
                <div className="project-name">
                  {project.name ? project.name : project.slug}
                </div>
              </Link>
            ) : (
              <Link
                to={`projects/${project.id}`}
                className="text-info font-weight-normal"
              >
                <div className="project-name">{`/projects/${project.id}`}</div>
              </Link>
            )}
            <div className="text-dark small mt-1">
              <div className="project-creator">
                {project.creator}{" "}
                {project.type && <span> | {project.type}</span>}
              </div>
            </div>
            <div className="mt-2 text-justify text-gray-300">
              {project.synopsis}
            </div>
          </div>
          {this.props.showReels ? (
            <div className="col-md-2 text-center" style={{ marginTop: "25px" }}>
              {project.has_video ? showCheckbox : showText}
            </div>
          ) : null}
        </div>
      </li>
    )
  }
}

export default ProjectItem
