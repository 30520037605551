import React, { Component } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { withRouter } from "react-router-dom"
import { title } from "change-case"
import classnames from "classnames"

import axios from "../../configAxios"
import Input from "../UI/input/Input"
import FormButton from "../UI/button/FormButton"
import Modal from "../UI/Modal"
import Spinner from "../UI/Spinner"

import { getProject } from "../../store/actions/index"
import EditAttribute from "./EditAttribute"

import "./ShortAttributes.css"

import isValidUrl from "../../utils/isValidUrl"

class ShortAttributes extends Component {
  signal = axios.CancelToken.source()
  state = {
    attrType: {
      elementType: "select",
      elementConfig: {
        name: "attrType",
        placeholder: "Select Option",
        options: [],
        isLoading: true,
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      label: "Select Short Attribute",
      selectedOption: null,
    },
    elementType: "",
    attrValue: "",
    attributesData: [],
    project: null,
    showModal: false,
    updateAttributesData: {},
    duration: { hours: "", minutes: "", seconds: "" },
    loading: true,
    disabled: true,
    manageAttributesForm: null,
  }

  async componentDidMount() {
    const type = "Short Attributes"
    const projectRes = await axios.get(
      `/projects/${this.props.match.params.id}`,
      { cancelToken: this.signal.token }
    )
    if (projectRes.status !== 202) {
      const { project } = projectRes.data
      project &&
        this.setState({
          projectId: project.id,
        })
    }
    axios
      .get("/project_attributes/formdata", {
        params: { type },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState(
          {
            attrType: {
              ...this.state.attrType,
              elementConfig: {
                ...this.state.attrType.elementConfig,
                options: res.data.project_attributes.map((attr) => {
                  return {
                    label: attr.name,
                    value: attr.id,
                    inputField: attr.ui_type,
                  }
                }),
                isLoading: false,
              },
            },
          },
          () => this.getProjectAttributes()
        )
      })
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios
      .get("/projects/policy", {
        params: { id: this.props.projects.project.id },
      })
      .then((res) => {
        this.setState({
          manageAttributesForm: res.data.json.manage_attributes_form,
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getProjectAttributes = () => {
    const type = "Short Attributes"
    const project_id = this.props.match.params.id
    axios
      .get("/project_attributes", {
        params: { project_id, type },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          attributesData: res.data.project_attributes,
          loading: false,
          disabled: false,
        })
      })
  }

  inputChangedHandler(event, key) {
    const inputValue = {}
    if (key === "attrValue") {
      this.setState({ attrValue: event.target.value })
    } else {
      inputValue.selectedOption = event
      const updatedControls = {
        ...this.state[key],
        ...inputValue,
      }

      this.setState({
        [key]: updatedControls,
        elementType:
          inputValue.selectedOption === null ||
          inputValue.selectedOption.inputField === null
            ? "text_field"
            : inputValue.selectedOption.inputField,
      })
    }
  }

  addAttributeHandler(e) {
    e.preventDefault()
    this.setState({ disabled: true })
    const attrData = {
      project_id: this.state.projectId,
      project_attribute_type_id: this.state.attrType.selectedOption.value,
    }
    const id = this.props.projects.project.slug
      ? this.props.projects.project.slug
      : this.props.projects.project.id
    if (this.state.attrType.selectedOption.inputField === "duration_picker") {
      const { duration } = this.state
      attrData.value = `${duration.hours}:${duration.minutes}:${duration.seconds}`
    } else {
      attrData.value = this.state.attrValue
    }
    axios.post("/project_attributes", attrData).then((res) => {
      if (res.data.json.success) {
        this.setState({
          attrType: {
            ...this.state.attrType,
            selectedOption: null,
          },
          attrValue: "",
          duration: { hours: "", minutes: "", seconds: "" },
          disabled: false,
        })
        toast.success("Short attribute successfully created.")
        this.props.getProject(id)
        this.getProjectAttributes()
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach((error) => {
            toast.error(title(error) + " " + res.data.json.errors[error])
          })
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error)
        }
        this.setState({ disabled: false })
      }
    })
  }

  deleteAttributeHandler = (e, index) => {
    const params = {
      project_id: this.props.match.params.id,
      id: index,
    }
    const id = this.props.projects.project.slug
      ? this.props.projects.project.slug
      : this.props.projects.project.id
    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      axios
        .delete(`/project_attributes/${index}`, { data: params })
        .then((res) => {
          if (res.data.json.success) {
            toast.success("Short attribute successfully deleted.")
            this.props.getProject(id)
            this.getProjectAttributes()
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
          }
        })
    }
  }

  openModal = (updateAttributesData) => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true, updateAttributesData })
  }

  clearShortAttribute = () => {
    this.setState({ attrValue: "" })
  }

  render() {
    const { projects } = this.props
    let removeIcon
    if (this.state.attrValue !== "") {
      removeIcon = (
        <i
          className="fas fa-times remove-icon cursor"
          onClick={() => this.clearShortAttribute()}
        />
      )
    }
    let list = ""
    if (this.state.loading) {
      list = <Spinner />
    } else {
      list = (
        <ul className="list-group list-group-flush">
          <li className="list-group-item rounded-0">
            <div className="row">
              <div className="col-5">
                <span className="font-weight-bold">Attribute</span>
              </div>
              <div className="col-5">
                <span className="font-weight-bold">Value</span>
              </div>
            </div>
          </li>
          {this.state.attributesData.map((data, index) => (
            <li className="list-group-item rounded-0" key={index}>
              <div className="row">
                <div className="col-5">{data.project_attribute_type_name}</div>
                {
                  isValidUrl(data.value)
                    ? <a className="col-5" target="_blank" rel="noopener noreferrer" href={data.value}>{data.value}</a>
                    : <div className="col-5">{data.value}</div>
                }
                <div className="col-2">
                  <div className="d-flex justify-content-around cursor">
                    <i
                      style={{
                        cursor: this.state.manageAttributesForm
                          ? "pointer"
                          : "no-drop",

                        opacity: this.state.manageAttributesForm ? 1 : 0.7,
                      }}
                      className="fas fa-pencil-alt cursor"
                      onClick={
                        this.state.manageAttributesForm
                          ? (event) => this.openModal(data)
                          : null
                      }
                    />
                    <i
                      className={classnames("fas fa-trash", {
                        cursor:
                          projects &&
                          projects.project &&
                          projects.project.can_delete,
                        "cursor-not-allowed element-disabled":
                          projects &&
                          projects.project &&
                          !projects.project.can_delete,
                      })}
                      onClick={(e) => {
                        if (
                          projects &&
                          projects.project &&
                          projects.project.can_delete &&
                          this.state.manageAttributesForm
                        )
                          this.deleteAttributeHandler(e, data.id)
                      }}
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )
    }

    let showList
    if (!this.state.loading && this.state.attributesData.length === 0) {
      showList = (
        <div className="col no-short-attributes-container my-5 py-5">
          <h5 className="no-short-attributes-heading text-center font-weight-bold">
            No Short Attributes
          </h5>
        </div>
      )
    } else {
      showList = <>{list}</>
    }

    let inputField
    switch (this.state.elementType) {
      case null:
      case "text_field":
        inputField = (
          <div>
            {removeIcon}
            <Input
              elementType={"text"}
              elementConfig={{ required: true }}
              label={"Add Short Attribute"}
              value={this.state.attrValue}
              changed={(event) => this.inputChangedHandler(event, "attrValue")}
            />
          </div>
        )
        break
      case "duration_picker":
        inputField = (
          <>
            <label>Add Short Attribute</label>
            <br />
            <input
              className="duration-picker"
              type="number"
              min="0"
              required
              placeholder="hh"
              value={this.state.duration.hours}
              onChange={(event) =>
                this.setState({
                  duration: {
                    ...this.state.duration,
                    hours: event.target.value,
                  },
                })
              }
            />{" "}
            {" : "}
            <input
              className="duration-picker"
              type="number"
              max="59"
              min="0"
              required
              placeholder="mm"
              value={this.state.duration.minutes}
              onChange={(event) =>
                this.setState({
                  duration: {
                    ...this.state.duration,
                    minutes: event.target.value,
                  },
                })
              }
            />{" "}
            {" : "}
            <input
              className="duration-picker"
              type="number"
              max="59"
              min="0"
              required
              placeholder="ss"
              value={this.state.duration.seconds}
              onChange={(event) =>
                this.setState({
                  duration: {
                    ...this.state.duration,
                    seconds: event.target.value,
                  },
                })
              }
            />
          </>
        )
        break
      default:
        inputField = (
          <div>
            {removeIcon}
            <Input
              elementType={"text"}
              elementConfig={{ required: true }}
              label={"Add Short Attribute"}
              value={this.state.attrValue}
              changed={(event) => this.inputChangedHandler(event, "attrValue")}
            />
          </div>
        )
    }
    return (
      <div
        className="short-attr"
        key={this.props.projects ? this.props.projects.project.slug : null}
      >
        <form
          onSubmit={(e) => this.addAttributeHandler(e)}
          className="mt-4 p-3"
        >
          <div className="row">
            <div className="col-lg-5">
              <Input
                {...this.state.attrType}
                className="select-attr"
                placeholder="Select Option"
                changed={(event) => this.inputChangedHandler(event, "attrType")}
              />
            </div>
            <div className="col-lg-5">{inputField}</div>
            <div className="col-lg-2 text-center mt-3 pt-3">
              <FormButton
                disabled={
                  this.state.disabled || !this.state.manageAttributesForm
                }
                style={{
                  cursor: this.state.manageAttributesForm
                    ? "pointer"
                    : "no-drop",
                }}
              >
                Add
              </FormButton>
            </div>
          </div>
        </form>

        {showList}

        <Modal
          show={this.state.showModal}
          title="Edit Short Attribute"
          hide={() => this.setState({ showModal: false })}
        >
          <EditAttribute
            closeModal={() => this.setState({ showModal: false })}
            attributesData={this.state.updateAttributesData}
            getAttributes={this.getProjectAttributes}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProject: (id) => dispatch(getProject(id)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShortAttributes)
)
