import React from "react"
import RentalDetails from "./RentalDetails"
import Spinner from "../../UI/Spinner"

function AssetRentalsTable({ organizationName, loading, rentals }) {
  return (
    <>
      <div className="list mt-5">
        <h5 className="font-weight-bold">
          Rentals Per Asset
        </h5>
        {!loading &&
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="border-top">
              <tr>
                <th className="w-20">Asset</th>
                <th className="w-20">Owner</th>
                <th className="w-20">Booking Contact</th>
                <th className="w-25">Project / Program</th>
                <th className="w-25">Booking Start Date</th>
                <th className="w-25">Booking End Date</th>
                <th className="w-15">{organizationName} / Owner Split</th>
                <th className="w-15">Invoices</th>
                <th className="w-15">External Tracking Number</th>
              </tr>
              </thead>
              <tbody>
                {rentals?.map(rental => <RentalDetails key={rental.id}
                                                       rental={rental}/>)}
              </tbody>
            </table>
          </div>
        }
        {loading && <Spinner className="h-30vh"/>}
      </div>
    </>
  )
}

export default AssetRentalsTable
