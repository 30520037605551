import React, { Component } from "react";
import { toast } from "react-toastify";
import dateFns from "date-fns";
import axios from "../../configAxios";
import DatePicker from "../UI/datePicker";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import errorStatusHandler from "../errorPages/UserNotAuthorized";

class UpdateExpiryDate extends Component {
  state = {
    date: {
      elementType: "input",
      elementConfig: {
        name: "date",
        placeholder: "Ex- Sep 30 2007",
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        required: true
      },
      value: ""
    },
    invoice: false,
    disabled: false
  };
  closeModal = () => {
    this.props.closeModal();
  };

  componentDidMount() {
    let oneYearFromNow = dateFns.format(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), "MMM DD YYYY");
    if (this.props.user.membership_expires === null) {
      this.setState({
        ...this.state,
        date: {
          ...this.state.date,
          value: oneYearFromNow
        }
      })
    }
  }

  componentWillMount() {
    let date = { ...this.state.date };
    date["value"] = this.props.expiryDate
    this.setState({ date: date });
  }

  inputChangedHandler = newDate => {
    let date = { ...this.state.date };
    date["value"] = newDate;
    this.setState({ date: date });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let date = {
      id: parseInt(this.props.id),
      new_expiration_date: this.state.date.value,
      create_invoice: this.state.invoice
    };

    axios
      .put("/users/update_membership_expiration", date)
      .then(res => {
        if (res.data.json.success) {
          this.props.renew(this.state.date.value);
          let message = "Expiration date updated Successfully";
          this.props.closeModal();
          toast.success(message);
        } else {
          errorStatusHandler(res);
          this.setState({ disabled: false })
        }
      })
  }

  selectHandler = () => {
    this.setState({ invoice: !this.state.invoice });
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <p>New Expiration Date</p>
        <DatePicker changed={this.inputChangedHandler} selected={new Date(this.state.date.value)} />
        <div className="d-flex" onClick={this.selectHandler}>
          <div
            style={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              border: this.state.invoice
                ? "6px solid #1aff1a"
                : "1px solid #cccccc",
              cursor: "pointer"
            }}
          />
          <p className="ml-2 cursor">Create an Invoice ?</p>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            className="float-left mr-3"
            clicked={this.props.closeModal}
          >
            Cancel
          </Button>
          <FormButton disabled={this.state.disabled}>Submit</FormButton>
        </div>
      </form>
    );
  }
}
export default UpdateExpiryDate;
