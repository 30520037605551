import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Recaptcha from "react-recaptcha";
import { toast } from "react-toastify";

import axios from "../../configAxios";
import Logo from "./formComponents/Logo"
import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import { loginUser } from "../../store/actions/index";

import AuthContainer from "./AuthContainer";

class Login extends Component {
  state = {
    controls: {
      username: {
        elementType: "input",
        elementConfig: {
          type: "text",
          id: "login-form-username",
          name: "username",
          required: true
        },
        label: "Email",
        value: ""
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          id: "login-form-password",
          name: "password",
          required: true
        },
        label: "Password",
        value: ""
      }
    },
    captchaVerified: true,
    allowSignUp: null,
  };

  componentDidMount() {
    this.setAllowSignUp();
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(localStorage.forwardURL ? localStorage.forwardURL : localStorage.getItem("landingPage"));
    }
    let subdomain = window.location.hostname.split('.')[0]
    let whitelistedSubdomains = ["ams-front-end-react-staging"]
    if (process.env.NODE_ENV === "production" && !whitelistedSubdomains.includes(subdomain)) {
      axios.get("organizations/is_present", { params: { subdomain } })
        .then(res => {
          if (!res.data.json.success) {
            window.location.href = "https://amsnetwork.ca"
          }
        })
    }
  }

  setAllowSignUp = () => {
    let subdomain = window.location.hostname.split(".")[0]
    const whitelistedSubdomains = ["ams-front-end-react-staging"]
    if (
      process.env.NODE_ENV !== "production" &&
      whitelistedSubdomains.includes(subdomain)
    ) {
      subdomain = "favadbstaging"
    } else if (
      process.env.NODE_ENV !== "production" &&
      subdomain === "loaclhost"
    ) {
      subdomain = "fava"
    }
    axios
      .get("/organizations/is_present", { params: { subdomain } })
      .then((res) => {
        this.setState({ allowSignUp: res.data.json.meta.allow_sign_up })
      })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.timezoneHandler();
      this.props.history.push(localStorage.forwardURL ? localStorage.forwardURL : localStorage.getItem("landingPage"));
    }
  }

  timezoneHandler() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneData = {
      browser_timezone: timeZone
    };
    axios.put("/timezone", timeZoneData);
  }

  inputChangedHandler = event => {
    const updatecontrols = {
      ...this.state.controls,
      [event.target.name]: {
        ...this.state.controls[event.target.name],
        value: event.target.value
      }
    };
    this.setState({ controls: updatecontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    if (this.state.captchaVerified) {
      let userData = {
        grant_type: "password",
        username: this.state.controls.username.value,
        password: this.state.controls.password.value
      };
      let subdomain = window.location.hostname.split('.')[0];
      if (window.location.href.includes("inkoop.in")) {
        userData = {
          ...userData,
          subdomain
        }
      }
      this.props.loginUser(userData);
    } else {
      toast.error("Invalid reCAPTCHA");
    }
  };

  verifyCaptcha = response => {
    if (response) {
      this.setState({ captchaVerified: true });
    }
  };

  render() {
    const { loading } = this.props.response;
    let formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key]
      });
    }
    // TODO: fix this
    formElementsArray = formElementsArray.sort((formElement1, formElement2) => {
      return -formElement1.id.localeCompare(formElement2.id);
    });
    const form = formElementsArray.map(formElement => (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        label={formElement.config.label}
        value={formElement.config.value}
        changed={event => this.inputChangedHandler(event)}
        block
      />
    ));
    return (
      <AuthContainer>
        <div className="card-body">
          <div className="card-text p-2">
            <Logo />
            <form onSubmit={this.submitHandler} className="mt-3">
              {form}
              {parseInt(localStorage.getItem("wrongCredentialsCount")) >= 5 && (
                <div className="d-flex justify-content-center mb-4">
                  <Recaptcha
                    sitekey="6LepBRETAAAAABk7USXeKvnifxJs_XG_xIRXP4Or"
                    render="explicit"
                    onloadCallback={() =>
                      this.setState({ captchaVerified: false })
                    }
                    verifyCallback={this.verifyCaptcha}
                  />
                </div>
              )}

              <div className="justify-content-between align-items-center">
                <div className="row">
                  <div className="col-12">
                    <FormButton
                      id="login-button"
                      className="w-100 px-4"
                      disabled={loading}
                    >
                      Login
                    </FormButton>
                  </div>
                </div>
                <div className="row mt-3">
                  {this.state.allowSignUp ?
                  <>
                    <div className="col-6 px-3">
                      <Link to="signup" className="text-info">
                        Create account
                      </Link>
                    </div>
                    <div className="col-6 px-2 pr-3">
                      <Link to="reset_password" className="text-info float-right">
                        Forgot password?
                      </Link>
                    </div>
                  </> : <> 
                    <div className="col-12 text-center">
                      <Link to="reset_password" className="text-info">
                        Forgot password?
                      </Link>
                    </div>
                  </>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </AuthContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    response: state.response
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: userData => dispatch(loginUser(userData))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
