import React, { Component } from 'react';
import DayPicker from "react-day-picker";
import moment from 'moment-timezone';
import "react-day-picker/lib/style.css"
import "./Toolbar.scss"

class Toolbar extends Component {
  state = {
    showPicker: false,
    dayChangeValue: {
      day: 1,
      week: 7,
      agenda: 30
    }
  }

  onClickHandler = event => {
    this.setState({ showPicker: true });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.outsideClickHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.outsideClickHandler);
  }

  outsideClickHandler = (event) => {
    if (this.node.contains(event.target))
      return;
    this.setState({ showPicker: false });
  }

  DaySelectHandler = (day, e) => {
    this.props.dateChangeHandler(day);
    this.setState({ showPicker: false });
  }

  todayButtonClickHandler = (day, modifiers, e) => {
    this.props.dateChangeHandler(day);
    this.setState({ showPicker: false });
  }

  render() {
    const { date, dateChangeHandler, view } = this.props;
    return (
      <div className="row py-3 custom-weekly-calendar">
        <div className="col-1">
          <div onClick={() => dateChangeHandler(moment(date).subtract(this.state.dayChangeValue[view], "day").toDate())} className="btn">
            <i className="fas fa-chevron-left"></i>
          </div>
        </div>
        <div ref={node => { this.node = node }} className="col-10 text-center font-bold position-relative custom-weekly-calendar-header">
          <p onClick={this.onClickHandler} className="m-0 py-1 custom-weekly-calendar-label">
            {this.props.event.label}
          </p>
          {this.state.showPicker ? <DayPicker
            selectedDays={[date]} month={date} className="bg-white position-absolute shadow custom-dayPicker-wrapper" onDayClick={this.DaySelectHandler} todayButton="Today" onTodayButtonClick={this.todayButtonClickHandler} showOutsideDays={true} /> : null}
        </div>
        <div className="col-1 text-right">
          <div onClick={() => dateChangeHandler(moment(date).add(this.state.dayChangeValue[view], "day").toDate())} className="btn">
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
    )
  }

}

export default Toolbar
