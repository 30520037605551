import React, { Component } from "react"
import { connect } from "react-redux"
import StackGrid from "react-stack-grid"
import { toast } from "react-toastify"
import { title } from "change-case"
import Checkbox from "@material-ui/core/Checkbox"

import axios from "../../configAxios"
import Button from "../UI/button/Button"
import Modal from "../UI/Modal"
import Spinner from "../UI/Spinner"

// Modules
import Projects from "./projects"
import ProjectsGraph from "./graphs/projects"
import RentalGraph from "./graphs/rentals"
import MembersGraph from "./graphs/members"
import CreatedInvoicesGraph from "./graphs/createdInvoices"
import Invoices from "./invoices"
import NewFlags from "./newflags"
import Volunteers from "./volunteers"
import Programs from "./programs"
import RentalRequests from "./rental"
import NewMembers from "./newMembers"
import CurrentInvoices from "./currentInvoices/invoices"
import BookingActivities from "./bookingActivities/index"

import "./index.scss"

class Dashboard extends Component {
  signal = axios.CancelToken.source()
  state = {
    showModal: false,
    modules: [],
    selectedModules: [],
    loading: true,
    dashboardWebsite: null,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    document.addEventListener("keydown", this.escFunction, false)
    axios
      .get("/users/formdata", { cancelToken: this.signal.token })
      .then((res) => {
        this.setState({
          ...this.state,
          modules: res.data.json.dashboard_modules,
          dashboardWebsite: res.data.json.dashboard_website,
          loading: false,
        })
        this.updateGrid()
      })
      .catch((error) => error)
    axios
      .get(`/users/${this.props.auth.userId}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          ...this.state,
          selectedModules: res.data.user.dashboard_preferences,
          loading: false,
        })
        this.updateGrid()
      })
      .catch((error) => error)
  }

  componentDidUpdate() {
    this.updateGrid()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
    if (document)
      document.removeEventListener("keydown", this.escFunction, false)
  }

  updateGrid = () => {
    setTimeout(() => {
      if (typeof this.grid !== "undefined") {
        this.grid.updateLayout()
      }
    }, 500)
  }

  escFunction = (event) => {
    if (event.keyCode === 27 && this.state.showModal) {
      this.setState({ ...this.state, showModal: false })
    }
  }

  checkboxHandler = (key) => {
    let { selectedModules } = this.state
    let previousSelectedModules = [...selectedModules]

    if (selectedModules.includes(key)) {
      const index = selectedModules.indexOf(key)
      selectedModules.splice(index, 1)
    } else {
      selectedModules.push(key)
    }
    let data = {
      id: this.props.auth.userId,
      dashboard_preferences: selectedModules.join(),
    }
    this.setState({ ...this.state, selectedModules: selectedModules })
    axios
      .put("/users/update_dashboard_preferences", data)
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Preference updated.")
        } else {
          this.setState({
            ...this.state,
            selectedModules: previousSelectedModules,
          })
          if ("errors" in res.data.json) {
            Object.keys(res.data.json.errors).forEach((key) => {
              toast.error(`${key} ${res.data.json.errors[key][0]}`)
            })
          }
          if ("error" in res.data.json) {
            toast.error(res.data.json.error)
          }
          this.setState({ ...this.state, showModal: false })
        }
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          selectedModules: previousSelectedModules,
        })
        if ("errors" in error.response.data) {
          Object.keys(error.response.data.errors).forEach((key) => {
            toast.error(error.response.data.errors[key][0])
          })
        }
        if ("error" in error.response.data) {
          toast.error(error.response.data.error)
        }
        this.setState({ ...this.state, showModal: false })
      })
  }

  removeModule = (module) => {
    let selectedModules = this.state.selectedModules.filter(
      (moduleName) => moduleName !== module
    )

    let data = {
      id: this.props.auth.userId,
      dashboard_preferences: selectedModules.join(),
    }
    axios
      .put("/users/update_dashboard_preferences", data)
      .then((res) => {
        if (res.data.json.success) {
          this.setState({ ...this.state, selectedModules: selectedModules })
          toast.success(`${module} Module Removed from your preferences`)
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
        this.setState({ ...this.state, showModal: false })
      })
      .catch((error) => {
        if ("errors" in error.response.data) {
          Object.keys(error.response.data.errors).forEach((key) => {
            toast.error(error.response.data.errors[key][0])
          })
        }
        if ("error" in error.response.data) {
          toast.error(error.response.data.error)
        }
        this.setState({ ...this.state, showModal: false })
      })
  }

  showModal = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true })
  }

  render() {
    const modal = (
      <Modal
        show={this.state.showModal}
        title={"Choose Modules"}
        hide={() => this.setState({ showModal: false })}
        closeButton
        closeModal={() => this.setState({ showModal: false })}
      >
        <div className="mt-3">
          {this.state.modules.length > 0 ? (
            this.state.modules.map((key) => (
              <div
                className="row"
                key={key}
                onClick={() => this.checkboxHandler(key)}
              >
                <Checkbox
                  checked={this.state.selectedModules.includes(key)}
                  style={{
                    color: this.state.selectedModules.includes(key)
                      ? "var(--color-primary)"
                      : "#cccc",
                  }}
                />
                <div
                  style={{
                    marginTop: "13px",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                >
                  {key}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center font-weight-bold">No modules</div>
          )}
        </div>
      </Modal>
    )

    return (
      <div className="dashboard container w-100">
        {this.state.loading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div className="row mb-4">
              <div className="col-6 text-left">
                <h3>Dashboard</h3>
              </div>
              <div className="col-6 text-right">
                <Button
                  className="btn-light bg-white border"
                  clicked={() => {
                    window.scrollTo(0, 0)
                    this.setState({ showModal: true })
                  }}
                >
                  Manage modules
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {this.state.selectedModules.includes("Website") && (
                  <div className="row">
                    <div className="col-12 mb-4">
                      <iframe
                        src={this.state.dashboardWebsite}
                        title="Iframe Example"
                        width="100%"
                        height="600px"
                        style={{ border: "1px solid #ddd" }}
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12">
                <StackGrid
                  columnWidth={359}
                  gutterWidth={15}
                  gutterHeight={15}
                  className="mb-5"
                  gridRef={(grid) => (this.grid = grid)}
                >
                  {this.state.selectedModules.includes("Projects") && (
                    <ProjectsGraph
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Projects"
                    />
                  )}
                  {this.state.selectedModules.includes("Members") && (
                    <MembersGraph
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Members"
                    />
                  )}
                  {this.state.selectedModules.includes("Rental") && (
                    <RentalGraph
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Rental"
                    />
                  )}
                  {this.state.selectedModules.includes("Created Invoices") && (
                    <CreatedInvoicesGraph
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Created Invoices"
                    />
                  )}
                  {this.state.selectedModules.includes("New Projects") && (
                    <Projects
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="New Projects"
                    />
                  )}
                  {this.state.selectedModules.includes(
                    "Billing & Payments"
                  ) && (
                    <Invoices
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Billing & Payments"
                    />
                  )}
                  {this.state.selectedModules.includes("New Flags") && (
                    <NewFlags
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="New Flags"
                    />
                  )}
                  {this.state.selectedModules.includes("Volunteers") && (
                    <Volunteers
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Volunteers"
                    />
                  )}
                  {this.state.selectedModules.includes("Upcoming Programs") && (
                    <Programs
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Upcoming Programs"
                    />
                  )}
                  {this.state.selectedModules.includes("Rental Requests") && (
                    <RentalRequests
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Rental Requests"
                    />
                  )}
                  {this.state.selectedModules.includes("New Members") && (
                    <NewMembers
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="New Members"
                    />
                  )}
                  {this.state.selectedModules.includes("Current Invoice") && (
                    <CurrentInvoices
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Current Invoice"
                    />
                  )}
                  {this.state.selectedModules.includes("Booking Activity") && (
                    <BookingActivities
                      updateGrid={this.updateGrid}
                      removeModule={this.removeModule}
                      name="Booking Activity"
                    />
                  )}
                  {this.state.selectedModules.length !==
                    this.state.modules.length && (
                    <div className="dashboard">
                      <div
                        className="card centered card-dims"
                        style={{ height: "300px" }}
                        onClick={() =>
                          this.state.modules.length && this.showModal()
                        }
                      >
                        <i
                          className="fa fa-plus-circle fa-2x cursor text-muted"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  )}
                </StackGrid>
              </div>
            </div>
            {modal}
          </React.Fragment>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(Dashboard)
