import React, { useState } from "react"
import Modal from "../UI/Modal"
import SendReport from "./SendReport"
import ReportSent from "./ReportSent";

const ReportErrorModal = ({ showModal, setShowModal, history }) => {
  const [sent, setSent] = useState(false)

  return (
    <Modal
      show={showModal}
      title={`Confirm`}
      hide={() => setShowModal(false)}
    >
      <div className="mt-3">
        {sent ? <ReportSent history={history} /> : <SendReport setShowModal={setShowModal} setSent={setSent} history={history} />}
      </div>
    </Modal>
  )
}

export default ReportErrorModal
