import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import queryString from "query-string"

import Input from "../UI/input/Input"
import FormButton from "../UI/button/FormButton"
import axios from "../../configAxios"
import langUtil from "../../utils/langUtils"
import InactiveFriendsCheckbox from "../friends/friendSearch/InactiveFriendsCheckbox"
import tagName from "../shared/tagName";

import "./members.css"

class MembersSearch extends Component {
  state = {
    searchKeyword: {
      elementType: "input",
      elementConfig: {
        name: "query_string",
        required: false,
        placeholder: "Enter keyword here",
      },
      iconPosition: "left",
      icon: "glyphicon-search",
      value: queryString.parse(this.props.history.location.search).tag_name
        ? queryString.parse(this.props.history.location.search).tag_name
        : null,
    },
    tagName: queryString.parse(this.props.history.location.search).tag_name
      ? queryString.parse(this.props.history.location.search).tag_name
      : null,
    favaFriends: 0,
    activeMembers: 0,
    archivedFriends: 0,
    staffMembers: 0,
    accountingMembers: 0,
    totalCount: "",
    value: false,
    nonMembers: 0,
    subType: "",
    roleLevel: "",
    memberTypes: [],
    memberByLabelTypes: [],
    memberByOrganizationTypes: [],
    membersPendingRenewal: [],
    memberType: "",
    isOpenMemberByLabel: false,
    isOpenActiveMember: false,
    isOpenMembersByOrganization: false,
    isOpenMembersPendingRenewal: false,
    isArchivedFriendsActive: false,
    activeFriendFilter: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.friendsVar !== prevProps.friendsVar &&
      this.props.friendsVar
    ) {
      this.setState({
        inActive: {
          ...this.state.inActive,
          label: `Include Inactive ${langUtil(
            "txt_global_friends",
            "Friends"
          )} in search?`,
        },
      })
    }
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {}
    if (key === "searchKeyword") {
      inputValue.value = event.target.value
    }
    this.setState({
      [key]: {
        ...this.state[key],
        ...inputValue,
      },
    })
  }

  onCheckboxToggle = () => {
    const activeFriendFilter = !this.state.activeFriendFilter

    this.setState({ ...this.state, activeFriendFilter })
  }

  changeSubType = (subType) => {
    window.scrollTo(0, 0)
    const params = {
      type: "search_and_browse",
      query: this.state.searchKeyword.value,
      page: 1,
      include_inactive: this.state.inActive.value,
      sub_type: subType,
    }
    if (subType === "members_by_organization") {
      if (this.state.isOpenMembersByOrganization) {
        axios
          .get("/users/filters")
          .then((res) => {
            this.setState(
              {
                ...this.state,
                subType,
                memberByOrganizationTypes: res.data.json.organization_names,
                organizationType: null,
              },
              () => {
                params.organization_name = null
                params.include_organization_name = true
                this.props.searchHandler(params, "friends")
              }
            )
          })
          .catch((error) => error)
      }
    }
    if (subType === "organization_labels") {
      if (this.state.isOpenMemberByLabel) {
        axios
          .get("/users/filters")
          .then((res) => {
            this.setState(
              {
                ...this.state,
                subType,
                memberByLabelTypes: res.data.json.labels,
                labelType: null,
              },
              () => {
                params.tag_name = null
                this.props.searchHandler(params, "friends")
              }
            )
          })
          .catch((error) => error)
      }
    }
    const isActiveMember =
      subType === "active_members" && this.state.isOpenActiveMember
    const isMemberPendingRenewal =
      subType === "members_pending_renewal" &&
      this.state.isOpenMembersPendingRenewal
    const isArchivedFriends =
      subType === "archived_friends" && this.state.isArchivedFriendsActive
    if (isActiveMember || isMemberPendingRenewal || isArchivedFriends) {
      const pending = !(subType === "active_members")
      axios
        .get("/users/formdata", { params: { pending } })
        .then((res) => {
          this.setState(
            {
              ...this.state,
              subType,
              memberTypes: res.data.json.member_type,
              memberType: "",
            },
            () => {
              params.member_type_id = ""
              this.props.searchHandler(params, "friends")
            }
          )
        })
        .catch((error) => error)
    }
    if (
      subType === "current_organization_friends" ||
      subType === "non_members" ||
      subType === "role_level"
    ) {
      this.setState(
        {
          ...this.state,
          subType,
          memberTypes: [],
          memberByLabelTypes: [],
          isOpenMembersByOrganization: false,
          memberType: "",
        },
        () => {
          params.member_type_id = null
          if (subType === "role_level") params.role_level = this.state.roleLevel
          this.props.searchHandler(params, "friends")
        }
      )
    }
  }

  dropDownHandler = (subType) => {
    if (subType === "organization_labels")
      this.setState(
        {
          isOpenMemberByLabel: !this.state.isOpenMemberByLabel,
          isOpenActiveMember: false,
          isOpenMembersByOrganization: false,
          isOpenMembersPendingRenewal: false,
          isArchivedFriendsActive: false,
        },
        () => {
          if (this.state.isOpenMemberByLabel) this.props.setLoading()
          this.changeSubType("organization_labels")
        }
      )
    if (subType === "active_members") {
      this.setState(
        {
          isOpenActiveMember: !this.state.isOpenActiveMember,
          isOpenMemberByLabel: false,
          isOpenMembersByOrganization: false,
          isOpenMembersPendingRenewal: false,
          memberTypes: [],
          isArchivedFriendsActive: false,
        },
        () => {
          if (this.state.isOpenActiveMember) this.props.setLoading()
          this.changeSubType("active_members")
        }
      )
    }
    if (subType === "members_by_organization") {
      this.setState(
        {
          isOpenMembersByOrganization: !this.state.isOpenMembersByOrganization,
          isOpenActiveMember: false,
          isOpenMemberByLabel: false,
          isOpenMembersPendingRenewal: false,
          isArchivedFriendsActive: false,
        },
        () => {
          if (this.state.isOpenMembersByOrganization) this.props.setLoading()
          this.changeSubType("members_by_organization")
        }
      )
    }
    if (subType === "members_pending_renewal") {
      this.setState(
        {
          isOpenMembersPendingRenewal: !this.state.isOpenMembersPendingRenewal,
          isOpenActiveMember: false,
          isOpenMemberByLabel: false,
          isOpenMembersByOrganization: false,
          memberTypes: [],
          isArchivedFriendsActive: false,
        },
        () => {
          if (this.state.isOpenMembersPendingRenewal) this.props.setLoading()
          this.changeSubType("members_pending_renewal")
        }
      )
    }
    if (subType === "archived_friends") {
      this.setState(
        {
          isArchivedFriendsActive: !this.state.isArchivedFriendsActive,
          isOpenMembersPendingRenewal: false,
          isOpenActiveMember: false,
          isOpenMemberByLabel: false,
          isOpenMembersByOrganization: false,
          memberTypes: [],
        },
        () => {
          if (this.state.isArchivedFriendsActive) this.props.setLoading()
          this.changeSubType("archived_friends")
        }
      )
    }
  }

  changeType = (memberType, type) => {
    window.scrollTo(0, 0)
    const params = {
      type: "search_and_browse",
      query: this.state.searchKeyword.value,
      page: 1,
      include_inactive: this.state.inActive.value,
      sub_type: this.state.subType,
    }
    if (
      type === "active_members" ||
      type === "members_pending_renewal" ||
      type === "archived_friends"
    ) {
      this.setState(
        {
          ...this.state,
          memberType: memberType,
        },
        () => {
          params.member_type_id = this.state.memberType.id
          this.props.searchHandler(params, "friends")
        }
      )
    }
    if (type === "organization_labels") {
      this.setState(
        {
          ...this.state,
          labelType: memberType,
        },
        () => {
          params.tag_name = this.state.labelType
          this.props.searchHandler(params, "friends")
        }
      )
    }
    if (type === "organization_by_member") {
      this.setState(
        {
          ...this.state,
          organizationType: memberType,
        },
        () => {
          params.organization_name = this.state.organizationType
          params.include_organization_name = null
          this.props.searchHandler(params, "friends")
        }
      )
    }
  }

  changeRoleLevel = (role) => {
    this.setState(
      {
        ...this.state,
        roleLevel: role,
      },
      () => {
        this.changeSubType("role_level")
      }
    )
  }

  deleteTagHandler = () => {
    const params = {
      type: "search_and_browse",
      page: 1,
    }
    this.setState({ tagName: null })
    this.props.history.push("/users/members")
    this.props.searchHandler(params, "friends")
  }

  submitHandler = (event) => {
    event.preventDefault()
    const params = {
      type: "search_and_browse",
      query: this.state.searchKeyword.value,
      page: 1,
      include_inactive: this.state.activeFriendFilter,
    }
    if (this.state.subType) {
      params.sub_type = this.state.subType
    }
    this.props.searchHandler(params, "friends")
  }

  clearQueryString = () => {
    const params = {
      type: "search_and_browse",
      query: "",
      page: 1,
      include_inactive: this.state.inActive.value,
    }
    this.setState({
      searchKeyword: {
        ...this.state.searchKeyword,
        value: "",
      },
      tagName: "",
    })
    this.props.searchHandler(params, "friends")
    this.props.history.push("/users/members")
  }

  render() {
    const { metaData } = this.props
    const memberByOrganizationTypes = Object.keys(
      this.state.memberByOrganizationTypes
    )
      .sort((organizationMember1, organizationMember2) =>
        organizationMember1
          .toLowerCase()
          .localeCompare(organizationMember2.toLowerCase())
      )
      .filter((organizationMember) => !_.isEmpty(organizationMember))

    const memberByOrganizationTypesCount = this.state.memberByOrganizationTypes
      .length
      ? memberByOrganizationTypes.length === 0
        ? 0
        : memberByOrganizationTypes.length
      : metaData && metaData.members_by_organization

    const { isArchivedFriendsActive, memberTypes } = this.state
    let othersCount = 0
    if (isArchivedFriendsActive) {
      let memberTypeCount = 0
      memberTypes.forEach((member) => {
        memberTypeCount += member.archived_members_count
      })
      const archivedMembersCount = metaData ? metaData.archived_members : 0
      othersCount = archivedMembersCount - memberTypeCount
    }

    return (
      <div className="card members">
        <form className="p-4" onSubmit={this.submitHandler}>
          <h4 className="font-weight-bold">Search</h4>
          <div>
            <div>
              <i className="fas fa-search search-icon" />
              {this.state.searchKeyword.value &&
                this.state.searchKeyword.value !== "" && (
                  <i
                    className="fas fa-times remove-icon cursor"
                    onClick={() => this.clearQueryString()}
                  />
                )}
              <Input
                key="searchKeyword"
                {...this.state.searchKeyword}
                changed={(event) =>
                  this.inputChangedHandler(event, "searchKeyword")
                }
                style={{ paddingRight: "55px" }}
              />
            </div>
            <InactiveFriendsCheckbox 
              value={this.state.activeFriendFilter} 
              changed={this.onCheckboxToggle}
            />
          </div>
          <FormButton block className="mt-4" disabled={this.props.disabled}>
            Search
          </FormButton>
        </form>
        {this.props.isAdmin && (
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <div className="d-flex justify-content-between">
                <span
                  className={`font-16 cursor ${
                    this.state.subType === "current_organization_friends"
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() =>
                    this.changeSubType("current_organization_friends")
                  }
                >
                  {langUtil("txt_global_friends", "Friends")}
                </span>
                <span className="font-16 font-weight-bold">
                  {metaData ? metaData.count : 0}
                </span>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between">
                <span
                  className={`font-16 cursor ml-3 ${
                    this.state.subType === "active_members"
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() => this.dropDownHandler("active_members")}
                >
                  Active
                </span>
                <span className="font-16 font-weight-bold">
                  {metaData ? metaData.active_members : 0}
                </span>
              </div>
              {this.state.memberTypes.length &&
              this.state.isOpenActiveMember ? (
                <div className="mt-2 ml-3">
                  {this.state.memberTypes.map((memberType) => (
                    <div
                      key={memberType.id}
                      className={`p-1 py-2 ${
                        this.state.memberType === memberType
                          ? "font-weight-bold"
                          : ""
                      }`}
                      onClick={() =>
                        this.changeType(memberType, "active_members")
                      }
                    >
                      <i className="fa fa-caret-right mr-3 fa-xs" />
                      <span className="cursor">{memberType.name}</span>
                      <span className="float-right">{memberType.count}</span>
                    </div>
                  ))}
                </div>
              ) : null}
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between">
                <span
                  className={`font-16 cursor ml-3 ${
                    this.state.subType === "organization_labels"
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() => this.dropDownHandler("organization_labels")}
                >
                  By Label
                </span>
                <span className="font-16 font-weight-bold">
                  {metaData ? metaData.organization_labels : 0}
                </span>
              </div>
              {this.state.memberByLabelTypes &&
              this.state.isOpenMemberByLabel ? (
                <div className="mt-2 ml-3">
                  {Object.keys(this.state.memberByLabelTypes)
                    .sort((memberLabel1, memberLabel2) =>
                      memberLabel1
                        .toLowerCase()
                        .localeCompare(memberLabel2.toLowerCase())
                    )
                    .map((memberLabel) => {
                      return (
                        <div
                          key={memberLabel}
                          className={`p-1 py-2 ${
                            this.state.labelType === memberLabel
                              ? "font-weight-bold"
                              : ""
                          }`}
                          onClick={() =>
                            this.changeType(memberLabel, "organization_labels")
                          }
                        >
                          <i className="fa fa-caret-right mr-3 fa-xs" />
                          <span className="cursor">{tagName(memberLabel)}</span>
                          <span className="float-right">
                            {this.state.memberByLabelTypes[memberLabel]}
                          </span>
                        </div>
                      )
                    })}
                </div>
              ) : null}
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between">
                <span
                  className={`font-16 cursor ml-3 ${
                    this.state.subType === "members_by_organization"
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() =>
                    this.dropDownHandler("members_by_organization")
                  }
                >
                  By Organization
                </span>
                <span className="font-16 font-weight-bold">
                  {memberByOrganizationTypesCount}
                </span>
              </div>
              {memberByOrganizationTypes.length &&
              this.state.isOpenMembersByOrganization ? (
                <div className="my-2 ml-3 member-by-organization">
                  {memberByOrganizationTypes.map((organizationMember) => {
                    return (
                      <div
                        key={organizationMember}
                        className={`p-1 py-2 ${
                          this.state.organizationType === organizationMember
                            ? "font-weight-bold"
                            : ""
                        }`}
                        onClick={() =>
                          this.changeType(
                            organizationMember,
                            "organization_by_member"
                          )
                        }
                      >
                        <i className="fa fa-caret-right mr-3 fa-xs" />
                        <span className="cursor">{organizationMember}</span>
                        <span className="float-right">
                          {this.state.memberByOrganizationTypes[organizationMember]}
                        </span>
                      </div>
                    )
                  })}
                </div>
              ) : null}
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between">
                <span
                  className={`font-16 cursor ml-3 ${
                    this.state.subType === "members_pending_renewal"
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() =>
                    this.dropDownHandler("members_pending_renewal")
                  }
                >
                  Pending Renewal
                </span>
                <span className="font-16 font-weight-bold">
                  {metaData ? metaData.member_pending_renewal : 0}
                </span>
              </div>
              {this.state.memberTypes.length &&
              this.state.isOpenMembersPendingRenewal ? (
                <div className="mt-2 ml-3">
                  {this.state.memberTypes.map((memberType) => (
                    <div
                      key={memberType.id}
                      className={`p-1 py-2 ${
                        this.state.memberType === memberType
                          ? "font-weight-bold"
                          : ""
                      }`}
                      onClick={() =>
                        this.changeType(memberType, "members_pending_renewal")
                      }
                    >
                      <i className="fa fa-caret-right mr-3 fa-xs" />
                      <span className="cursor">{memberType.name}</span>
                      <span className="float-right">{memberType.count}</span>
                    </div>
                  ))}
                </div>
              ) : null}
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between">
                <span
                  className={`font-16 cursor ml-3 ${
                    this.state.subType === "archived_friends"
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() => this.dropDownHandler("archived_friends")}
                >
                  Inactive
                </span>
                <span className="font-16 font-weight-bold">
                  {metaData ? metaData.archived_members : 0}
                </span>
              </div>
              {this.state.memberTypes.length &&
              this.state.isArchivedFriendsActive ? (
                <div className="mt-2 ml-3">
                  {this.state.memberTypes.map((memberType) => (
                    <div
                      key={memberType.id}
                      className={`p-1 py-2 ${
                        this.state.memberType === memberType
                          ? "font-weight-bold"
                          : ""
                      }`}
                      onClick={() =>
                        this.changeType(memberType, "archived_friends")
                      }
                    >
                      <i className="fa fa-caret-right mr-3 fa-xs" />
                      <span className="cursor">{memberType.name}</span>
                      <span className="float-right">
                        {memberType.archived_members_count}
                      </span>
                    </div>
                  ))}
                  {memberTypes.length && (
                    <div
                      key={"others"}
                      className={`p-1 py-2 ${
                        this.state.memberType &&
                        this.state.memberType.id === "no_member_type"
                          ? "font-weight-bold"
                          : ""
                      }`}
                      onClick={() =>
                        this.changeType(
                          { id: "no_member_type" },
                          "archived_friends"
                        )
                      }
                    >
                      <i className="fa fa-caret-right mr-3 fa-xs" />
                      <span className="cursor">{"others"}</span>
                      <span className="float-right">{othersCount}</span>
                    </div>
                  )}
                </div>
              ) : null}
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between">
                <span
                  className={`font-16 cursor ${
                    this.state.roleLevel === "admins" &&
                    this.state.subType === "role_level"
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() => this.changeRoleLevel("admins")}
                >
                  {this.props.staffText}
                </span>
                <span className="font-16 font-weight-bold">
                  {metaData ? metaData.staff_members : 0}
                </span>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between">
                <span
                  className={`font-16 cursor ${
                    this.state.roleLevel === "accounting" &&
                    this.state.subType === "role_level"
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() => this.changeRoleLevel("accounting")}
                >
                  {this.props.accountingText}
                </span>
                <span className="font-16 font-weight-bold">
                  {metaData ? metaData.accounting_members : 0}
                </span>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between">
                <span
                  className={`font-16 cursor ${
                    this.state.subType === "non_members"
                      ? "font-weight-bold"
                      : ""
                  }`}
                  onClick={() => this.changeSubType("non_members")}
                >
                  {this.props.nonMemberText}
                </span>
                <span className="font-16 font-weight-bold">
                  {metaData ? metaData.non_members : 0}
                </span>
              </div>
            </li>
          </ul>
        )}
      </div>
    )
  }
}

export default withRouter(MembersSearch)
