import React, { Component } from 'react'
import { toast } from "react-toastify";
import { title } from "change-case";
import classNames from "classnames";

import axios from '../../../configAxios'
import { responseErrorHandler } from '../../../utils/errorHandler';
import Button from '../../UI/button/Button'
import ModalSpinner from '../../UI/ModalSpinner';
import FormButton from '../../UI/button/FormButton';

import AssociationInput from './AssociationInput';

class AddAndEditAssociationsAndEducationForm extends Component {
  signal = axios.CancelToken.source();
  formRef = React.createRef();
  state = {
    loading: this.props.edit,
    disableSubmit: false,
    name: "",
    intialInputValue: "",
    associationList: [],
    inputIsLoading: false,
  }

  componentDidMount() {
    if (this.props.edit) {
      axios.get(`/education_and_associations/${this.props.associationsAndEducationId}`, 
        { cancelToken: this.signal.token }
      ).then(res => {
          const { name: associationsAndEducationName } = res.data.education_and_association;
          this.setState({
            ...this.state,
            name: associationsAndEducationName,
            intialInputValue: associationsAndEducationName,
            loading: false
          })
        })
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Requests are being cancelled.");
  }

  inputChangedHandler = (event, key) => {
    let formFields = this.state.formFields
    formFields[key].value = event.target.value

    this.setState({ 
      ...this.state, 
      formFields, 
      disableSubmit: false 
    }, () => this.getAssociationsByName(formFields[key].value));
  }

  submitHandler = e => {
    e.preventDefault();
    this.setState({ ...this.state, disableSubmit: true })
    let data = {
      name: this.state.name
    }
    if (this.props.edit) {
      axios.put(`/education_and_associations/${this.props.associationsAndEducationId}`, data)
        .then(res => {
          if (res.data.json.success) {
            toast.success(`Updated Successfully`)
            this.props.closeModal()
            this.props.getAssociations();
          } else {
            responseErrorHandler(res);
          }
          this.setState({ disableSubmit: false });
        })
    } else {
      axios.post('/education_and_associations', data)
        .then(res => {
          if (res.data.json.success) {
            toast.success(`Created Successfully`);
            this.props.closeModal()
            this.props.getAssociations();
          } else {
            responseErrorHandler(res);
          }
          this.setState({ disableSubmit: false });
        })
    }
  }

  render() {
    const submitBtnDisabled = (
      this.state.disableSubmit ||
      this.state.inputIsLoading
    );

    if (this.state.loading) {
      return (
        <ModalSpinner />
      )
    }
    return (
      <div className="tag-form mt-3">
        <form 
          autoComplete="new-password"
          onSubmit={this.submitHandler}
          ref={this.formRef}
        >
          <AssociationInput
            associationNameChanged={associationName => this.setState({ name: associationName })}
            associationName={this.state.name}
            edit={this.props.edit}
            getAssociationList={associationList => this.setState({ associationList })}
            setInputLoading={inputIsLoading => this.setState({ inputIsLoading })}
            formRef={this.formRef}
          />
          <div className="text-center">
            <Button
              type="secondary"
              clicked={this.props.closeModal}
              className="mr-2"
            >
              Cancel
            </Button>
            <FormButton
              disabled={submitBtnDisabled}
              className={classNames({ "cursor-not-allowed": submitBtnDisabled })}
            >
              {this.props.edit ? "Update" : "Create"}
            </FormButton>
          </div>
        </form>
      </div>
    )
  }
}

export default AddAndEditAssociationsAndEducationForm;