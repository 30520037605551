import React from "react";

const ViewRental = props => (
  <>
    {props.type === 'location' ? < div className="mt-3"><label>Name</label><p>{props.location.name}</p></div>
      : props.type === 'category' ?
        <div>
          <div className="mt-3" >
            <label>Name</label>
            <p>{props.category.name}</p>
          </div >
          <div className="mt-3">
            <label>Short Name:</label>
            <p>{props.category.short_name}</p>
          </div>
        </div>
        : null
    }
  </>
);

export default ViewRental;
