import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import axios from "../../../configAxios";

import InvoiceItem from "./InvoiceItem";
import CardHeader from "../CardHeader";

import Spinner from "../../../assests/images/spinner.gif";
import "../index.scss";

class Invoices extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: false,
    invoices: []
  };

  componentDidMount() {
    this.setState({ ...this.state, loading: true });
    let params = {
      filter: 'past_due',
      per_page: 5,
      order: {
        column: "created_at",
        dir: "desc"
      }
    }
    axios.get(`/invoices`, { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({ invoices: res.data.invoices, loading: false });
        this.props.updateGrid();
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  render() {
    const spinner = (
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    );
    return (
      <div className="dashboard">
        <div className="card">
          {this.state.loading ? (
            <div className="card-body p-0">
              <div className="card-text">
                <div className="spinner">{spinner}</div>
              </div>
            </div>
          ) : (
              <div>
                <CardHeader
                  title={"Billing & Payments"}
                  icon="fa-credit-card"
                  collapse="invoices"
                  updateGrid={this.props.updateGrid}
                  removeModule={this.props.removeModule}
                  name={this.props.name} />
                <div className="dashboard-card collapse show" id="invoices">
                  <div className="card-body p-0">
                    <ul className="list-group">
                      {this.state.invoices.map(invoice => (
                        <InvoiceItem key={invoice.id} invoice={invoice} />
                      ))}
                    </ul>
                    <div className="p-2 text-center cursor card-text" style={{ height: "40px" }}>
                      <Link to="/invoices" className="text-info">View All</Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(Invoices);
