import React from "react";
import { title } from "change-case";

const permissions = {
  "site_admin": "Managing all organizations.",
  "super_admin": "Organization Admin, full control over System Settings and Permissions.",
  "staff": "Managing everything in the organization except profiles and permissions.",
  "accounting": "Ability to manage accounting system settings.",
  "member": "Can manage their profile and create projects and bookings if allowed by Staff.",
  "non_member": "Ability to register in programs."
}

const userPermissions = props => {
  const { customFields } = props;
  const rolesSequence = { "site_admin": 1, "super_admin": 2, "staff": 3, "accounting": 4, "member": 5, "non_member": 6 };
  customFields.sort((field1, field2) => {
    return rolesSequence[field1.column_name] - rolesSequence[field2.column_name];
  })
  let userProfiles = [];
  customFields.forEach(role => {
    Object.keys(permissions).forEach(role_level => {
      if (role_level === role.column_name) {
        userProfiles.push({
          role_level: role.label,
          role_value: permissions[role_level]
        })
      }
    })
  })
  const tableBody = (
    <tbody>
      {
        userProfiles.map(profile => (
          <tr>
            <td>{title(profile.role_level)}</td>
            <td>{profile.role_value}</td>
          </tr>
        ))
      }
    </tbody>
  )
  return (
    <table className="table table-bordered mt-4">
      <tr>
        <th>Profiles</th>
        <th>Permissions</th>
      </tr>
      {tableBody}
    </table>
  )
}

export default userPermissions;