import React, { Component } from "react";

import Spinner from "../UI/Spinner";
import Pagination from "../UI/Pagination";
import CenteredContainer from "../UI/container/CenteredContainer";

import MembersItem from "./MemberItem";

class MemberItems extends Component {
  render() {
    const { members, totalCount, forcePage, perPage } = this.props;
    let memberItems;
    if (this.props.loading) {
      memberItems = <Spinner />;
    } else {
      if (members.length) {
        memberItems = members.map(member => (
          <MembersItem key={member.id} member={member} />
        ));
      } else {
        memberItems = (
          <CenteredContainer>
            <h4>No member found.</h4>
          </CenteredContainer>
        );
      }
    }

    return (
      <div className="webkit-fill-available card">
        <div>
          <ul className="list-group list-group-flush">{memberItems}</ul>
        </div>
        <div style={{ marginTop: "14px" }}>
          {totalCount > 0 && (
            <Pagination
              forcePage={forcePage}
              pageCount={totalCount / perPage}
              handlePageClick={this.props.paginationHandler}
            />
          )}
        </div>
      </div>
    );
  }
}

export default MemberItems;
