import React, { useEffect, useState } from "react"
import Select from "react-select"
import axios from "../../configAxios"
import { flatten, sortBy } from "lodash"
import { orderedTagOptions } from "../shared/groupTagOptions"
import Tag from "./Tag"

const TagDropdown = ({ tags, setTags }) => {
  const [tagOptions, setTagOptions] = useState([])

  useEffect(() => {
    axios
      .get("/tags/search", {
        params: {
          query: "",
          type: "Program",
        },
      })
      .then((res) => {
        const formattedOptions = res.data.json.map((tag) => {
          return { label: tag.name, value: tag.value, order: tag.order, category: tag.category }
        })
        setTagOptions(orderedTagOptions(sortBy(formattedOptions, 'label')));
      })
      .catch((err) => err)
  }, [])

  const tagList = tags?.map(tag =>
    <Tag
      setTags={setTags}
      tagOptions={tagOptions}
      setTagOptions={setTagOptions}
      tagInfo={tag}
    />
  )

  const updateTagsAndOptions = (event) => {
    const filteredOptions = flatten(tagOptions.map( o => o.options)).filter(tag => tag.value !== event.value)

    setTags((prevState) => [...prevState, event])
    setTagOptions(orderedTagOptions(sortBy(filteredOptions, 'label')))
  }

  return (
    <>
      <Select
        options={tagOptions}
        placeholder='Labels'
        onChange={updateTagsAndOptions}
      />
      <div className='row mt-2 pl-3'>
        {tagList}
      </div>
    </>
  )
}

export default TagDropdown
