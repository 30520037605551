import React, { Component } from "react";
import dateFns from "date-fns";
import axios from "../../configAxios";
import Calendar from "react-calendar";
import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import Spinner from "../UI/Spinner";
import "./AddToSchedule.css";

class ProgramStartSelect extends Component {
  signal = axios.CancelToken.source();
  state = {
    date: new Date(),
    
    startTime: {
      elementType: "select",
      elementConfig: {
        name: "from",
        id: "from-program-schedule",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      clearAfterResponse: true,
      label: "From"
    },
    endTime: {
      elementType: "select",
      elementConfig: {
        name: "to",
        id: "to-program-schedule",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "To"
    },
    programs: [],
    loading: true,
    disabled: true
  };

  componentDidMount() {
    let am = [];
    let pm = [];
    am.push({ label: "12:00AM", value: "12:00AM" });
    am.push({ label: "12:30AM", value: "12:30AM" });
    pm.push({ label: "12:00PM", value: "12:00PM" });
    pm.push({ label: "12:30PM", value: "12:30PM" });
    for (let i = 1; i < 12; i++) {
      am.push({ label: `${i}:00AM`, value: `${i}:00AM` });
      am.push({ label: `${i}:30AM`, value: `${i}:30AM` });
    }
    for (let i = 1; i < 12; i++) {
      pm.push({ label: `${i}:00PM`, value: `${i}:00PM` });
      pm.push({ label: `${i}:30PM`, value: `${i}:30PM` });
    }
    this.setState({
      startTime: {
        ...this.state.startTime,
        elementConfig: {
          ...this.state.startTime.elementConfig,
          options: am.concat(pm)
        }
      },
      endTime: {
        ...this.state.endTime,
        elementConfig: {
          ...this.state.endTime.elementConfig,
          options: am.concat(pm)
        }
      }
    });

    const month = this.state.date.getMonth() + 1;
    const year = this.state.date.getFullYear();
    this.getProgramDate(month, year);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getProgramDate = (month, year) => {
    const FirstDate = new Date(year, month - 1, 1);
    const LastDate = new Date(year, month, 0);
    const firstDay = FirstDate.getDate();
    const lastDay = LastDate.getDate();
    const start = {
      date: firstDay,
      month: month,
      year: year
    };
    const end = {
      date: lastDay,
      month: month,
      year: year
    };
    axios
      .get("/programs/calendar", {
        params: {
          type: "program",
          start: start,
          end: end
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        let programs = [];
        for (let key in res.data.programs) {
          programs.push({
            ...res.data.programs[key]
          });
        }
        this.setState({ programs, loading: false });
      });
  };

  monthChangeHandler = date => {
    this.setState({
      date: date.activeStartDate,
      disabled: true,
      loading: true
    });
    const month = date.activeStartDate.getMonth() + 1;
    const year = date.activeStartDate.getFullYear();
    this.getProgramDate(month, year);
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["selectedOption"] = event;
    this.setState({
      [key]: {
        ...this.state[key],
        ...inputValue
      }
    });
  };

  onChange = date => {
    this.setState({ date, disabled: false });
  };

  tileDisabled = ({ date, view }) => {
    if (
      view === "month" &&
      date.getMonth() !== new Date(this.state.date).getMonth()
    ) {
      return true;
    }
  };

  onScheduleSubmitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let { date, startTime, endTime } = this.state;
    let scheduleData = {};
    const dayDate = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    scheduleData["start"] = startTime.selectedOption.value;
    scheduleData["end"] = endTime.selectedOption.value;
    scheduleData["day"] = dayDate;
    scheduleData["month"] = month + 1;
    scheduleData["year"] = year;

    let formattedScheduleData = {
      start: `${scheduleData.year}-${scheduleData.month < 10 ? '0' + scheduleData.month : scheduleData.month}-${scheduleData.day} ${scheduleData.start}`,
      end: `${scheduleData.year}-${scheduleData.month < 10 ? '0' + scheduleData.month : scheduleData.month}-${scheduleData.day} ${scheduleData.end}`,
    }

    this.props.scheduleSetter(formattedScheduleData)
    this.props.closeModal()
  };

  render() {
    const tileContent = ({ date, view }) =>
      this.state.programs.map(program => {
        let tileContent;
        if (
          view === "month" &&
          date.getMonth() === new Date(program.start).getMonth() &&
          date.getDate() === new Date(program.start).getDate()
        ) {
          tileContent = (
            <a
              href={"/programs/" + program.program.id}
              className="tile-content-link"
              target="_blank" rel="noopener noreferrer"
            >
              <p className="tile-content">{program.program.name}</p>
            </a>
          );
        } else {
          tileContent = null;
        }
        return tileContent;
      });

      const tileClassName = ({ date, view }) => {
        let {
          programs
        } = this.state;
        let className;
        let startOfToday = dateFns.startOfDay(new Date());

        programs.map(program => {
          if (view === "month" &&
          date.getMonth() === new Date(program.start).getMonth() &&
          date.getDate() === new Date(program.start).getDate()
          ) {
            className = `booked-color tile-date-${dateFns.format(date, "MM-DD-YYYY")}`;
          }
          return className;
        });
    
        if (date < startOfToday) {
          className = `previous-dates tile-date-${dateFns.format(date, "MM-DD-YYYY")}`;
        } else {
          if (view === "month" && (dateFns.getDay(date) === 0 || dateFns.getDay(date) === 6)) {
            className = `weekend tile-date-${dateFns.format(date, "MM-DD-YYYY")}`;
          }
        }
        return className;
      };
    let { startTime, endTime } = this.state;
    return (
      <div className="add-to-schedule">
        <form onSubmit={this.onScheduleSubmitHandler}>
          <div className="row mt-3 hours-section">
            <div className="col-lg-4 offset-2">
              <Input
                elementType={startTime.elementType}
                elementConfig={startTime.elementConfig}
                label={startTime.label}
                selectedOption={startTime.selectedOption}
                changed={event => this.inputChangedHandler(event, "startTime")}
              />
            </div>
            <div className="col-lg-4">
              <Input
                elementType={endTime.elementType}
                elementConfig={endTime.elementConfig}
                label={endTime.label}
                selectedOption={endTime.selectedOption}
                changed={event => this.inputChangedHandler(event, "endTime")}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mb-3">
            <FormButton id="add-schedule-button" className="success" disabled={this.state.disabled} style={{cursor: this.state.disabled && "no-drop"}}>
              Save
            </FormButton>
          </div>
        </form>
        {this.state.loading && <Spinner />}
        <div style={{ display: !this.state.loading ? "block" : "none" }}>
          <Calendar
            calendarType="US"
            tileContent={tileContent}
            onActiveDateChange={this.monthChangeHandler}
            tileClassName={tileClassName}
            onChange={this.onChange}
            tileDisabled={this.tileDisabled}
          />
        </div>
      </div>
    );
  }
}

export default ProgramStartSelect;
