import React, { Component } from 'react'
import dateFns from "date-fns";
import { withRouter } from 'react-router-dom';
import { title } from 'change-case';
import { toast } from 'react-toastify';

import axios from '../../../../configAxios'
import Button from '../../../UI/button/Button'
import Spinner from "../../../UI/Spinner";
import Modal from "../../../UI/Modal";
import langUtils from '../../../../utils/langUtils';

import CampaignForm from './CampaignForm';
import ViewEmail from "./ViewEmail";

class Campaigns extends Component {
  signal = axios.CancelToken.source();
  state = {
    campaigns: [],
    showForm: false,
    edit: false,
    duplicate: false,
    campaignId: null,
    loading: true,
    disableSend: false,
    showEmail: false
  }

  componentDidMount() {
    this.getCampaigns()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getCampaigns = () => {
    axios.get('/campaigns ', { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          campaigns:
            Array.isArray(res.data.campaigns)
              ? res.data.campaigns
              : [],
          loading: false
        })
      })
  }

  deleteCampaign = (event, campaignId) => {
    this.setState({ loading: true })
    let response = window.confirm("Are you sure you want to delete the Campaign?");
    if (response) {
      axios.delete(`/campaigns/${campaignId}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Campaign deleted successfully")
            this.getCampaigns();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  sendCampaignEmail = (event, campaignId, email) => {
    if (this.state.disableSend) {
      return
    }
    this.setState({ ...this.state, disableSend: true })
    let data = {
      campaign_id: campaignId,
      email
    }
    axios.post('/campaigns/send_email', data)
      .then(res => {
        if (res.data.json.success) {
          toast.success(res.data.json.data)
          this.setState({
            ...this.state,
            campaigns: this.state.campaigns.map(campaign => {
              if (campaign.id === campaignId) {
                campaign.status = 'sending'
                return campaign
              } else {
                return campaign
              }
            }),
            disableSend: false
          })
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      })
  }

  inputChangeHandler = e => {
    this.setState({
      email: {
        ...this.state.email,
        selectedOption: e
      }
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <Spinner />
      )
    } else if (this.state.showForm) {
      return (
        <CampaignForm
          edit={this.state.edit}
          duplicate={this.state.duplicate}
          campaignId={this.state.campaignId}
          closeForm={() => this.setState({ ...this.state, showForm: false })}
          getCampaigns={this.getCampaigns}
        />
      )
    } else {
      return (
        <div>
          <div className="p-4">
            <div className="row">
              <div className="col-6">
                <h5 className="font-weight-bold">Campaigns</h5>
              </div>
              <div className="col-6 text-lg-right text-center">
                <Button
                  type="primary"
                  clicked={() => this.setState({ 
                    ...this.state, 
                    showForm: true, 
                    edit: false, 
                    duplicate: false,
                    showEmail: false, 
                    campaignId: null 
                  })}
                >
                  {langUtils("txt_global_add_new", "Add New")}
                </Button>
              </div>
            </div>
          </div>

          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="row">
                <div className="col-lg-3">
                  <span className="font-weight-bold">Subject</span>
                </div>
                <div className="col-lg-2">
                  <span className="font-weight-bold">Mailing Lists</span>
                </div>
                <div className="col-lg-2 text-center">
                  <span className="font-weight-bold">Date</span>
                </div>
                <div className="col-lg-3 text-center">
                  <span className="font-weight-bold">Quantity <br />Sent / Pending</span>
                </div>
                <div className="col-lg-2" />
              </div>
            </div>
            {
              this.state.campaigns.length === 0 ? (
                <div className="row">
                  <div className="col-12">
                    <div className="text-center p-5 m-5">
                      <h5>No Campaign</h5>
                    </div>
                  </div>
                </div>
              ) :
                this.state.campaigns.map(campaign => (
                  <div className="list-group-item" key={campaign.id}>
                    <div className="row">
                      <div className="col-lg-3 word-wrap-break">{campaign.subject}</div>
                      <div className="col-lg-2">
                        {
                          campaign.mailing_lists.map((mailing_list, index) => (
                            <span>
                              {mailing_list.name}{index < campaign.mailing_lists.length - 1 ? ", " : null}
                            </span>
                          ))
                        }
                      </div>
                      <div className="col-lg-2 text-center">
                        {dateFns.format(new Date(campaign.created_at), "MMM DD YYYY")}
                      </div>
                      <div className="col-lg-3 text-center">
                        {
                          campaign.total_count !== null ?
                            <React.Fragment>
                              {campaign.sent_count ? campaign.sent_count : 0} / {campaign.total_count - campaign.sent_count}
                            </React.Fragment> : null
                        }
                      </div>
                      <div className="col-lg-2 d-flex justify-content-around align-items-center">
                        <i
                          className="far fa-copy cursor"
                          title="Duplicate"
                          onClick={() => this.setState({ ...this.state, showForm: true, edit: true, duplicate: true, campaignId: campaign.id })}
                        />
                        {
                          campaign.status === 'pending' ? (
                            <React.Fragment>
                              <i
                                className="fas fa-pencil-alt cursor"
                                title="Edit"
                                onClick={() => this.setState({ ...this.state, showForm: true, edit: true, duplicate: false, campaignId: campaign.id })}
                              />
                              <i
                                className="fas fa-trash cursor"
                                title="Delete"
                                onClick={event => this.deleteCampaign(event, campaign.id)}
                              />
                              <i
                                className={`fas fa-paper-plane ${campaign.email
                                  ? "cursor"
                                  : "cursor-not-allowed"
                                  }`}
                                title="Send"
                                {...campaign.email && {
                                  onClick: event => this.sendCampaignEmail(event, campaign.id, campaign.email)
                                }}
                              />
                            </React.Fragment>
                          ) : (
                              <React.Fragment>
                                <i 
                                  className="fas fa-eye cursor px-2" 
                                  title="View Mail"
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                    this.setState({ campaignId: campaign.id, showEmail: true })
                                  }} 
                                />
                                {campaign.can_delete &&
                                  <i
                                    className="fas fa-trash cursor mr-2"
                                    title="Delete"
                                    onClick={event => this.deleteCampaign(event, campaign.id)}
                                  />
                                }
                                <span className={
                                  campaign.status === 'sending' ?
                                    "badge badge-info px-2 py-1" :
                                    "badge badge-success px-2 py-1"
                                }>
                                  {title(campaign.status)}
                                </span>
                              </React.Fragment>
                            )
                        }
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>
          <Modal 
            title="Email"
            show={this.state.showEmail}
            hide={() => this.setState({ showEmail: false })}
            closeButton
            closeModal={() => this.setState({ showEmail: false })}
          >
            <ViewEmail 
              campaignId={this.state.campaignId}
              closeEmail={() => this.setState({ showEmail: false })}
            />
          </Modal>
        </div>
      )
    }
  }
}

export default withRouter(Campaigns);