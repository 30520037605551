import React from "react";
import { withRouter } from "react-router-dom";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { OverlayTrigger, Popover } from "react-bootstrap";

import EmailModal from "./EmailModal";

import Modal from "../../UI/Modal";
import axios from "../../../configAxios";
import errorStatusHandler from "../../errorPages/UserNotAuthorized";

import "../../../index.scss";

class EllipsisButtons extends React.Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      showEmailModal: false,
      reportData: [],
    };
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  generatePdf = () => {
    axios
      .get("/invoices/print_pdf", {
        params: {
          id: this.props.invoice.id
        }
      })
      .then(res => {
        window.open(res.data.json.file_path, "_blank");
      })
      .catch(err => toast.error("Failed to download"));
  };

  generateIIF = () => {
    axios
      .get("/invoices/generate_iif", {
        params: {
          id: this.props.invoice.id
        }
      })
      .then(res => {
        window.open(res.data.json.file_path, "_blank");
      })
      .catch(err => toast.error("Failed to download"));
  };

  deleteInovice = () => {
    let response = window.confirm("Are you sure you want to delete this invoice?");
    if (response) {
      axios.delete(`/invoices/${this.props.invoice.id}`)
        .then(res => {
          if (res.data.json.success) {
            this.props.history.push("/invoices");
          } else {
            errorStatusHandler(res);
          }
        })
    }
  }

  renderTooltip = (name) => (
    <div>{name}</div>
  )

  render() {
    const { showEmailModal } = this.state;
    const { invoice } = this.props;
    const emailModal = (
      <Modal
        className="custom-email-invoice-modal" 
        show={showEmailModal}
        title="Email Invoice" 
        hide={() => this.setState({ showEmailModal: false })}
      >
        <EmailModal 
          closeEmailModal={() => this.setState({ showEmailModal: false })}
          invoice={invoice}
          email={this.props.email}
        />
      </Modal>
    );

    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="d-flex justify-content-between">
              <OverlayTrigger
                placement="top"
                overlay={<Popover>{this.renderTooltip("Email Invoice")}</Popover>}
              >
                <div className="cursor mx-2" onClick={() => {
                  window.scrollTo(0, 0);
                  this.setState({ showEmailModal: true })
                }}>
                  <i className="fa fa-envelope"/>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Popover>{this.renderTooltip("Print Invoice")}</Popover>}
              >
                <div className="cursor mx-2" onClick={() => this.generatePdf()}>
                  <i className="fa fa-print"/>
                </div>
              </OverlayTrigger>
              {this.props.policyRes.can_destroy &&
                <OverlayTrigger
                  placement="top"
                  overlay={<Popover>{this.renderTooltip("Trash Invoice")}</Popover>}
                >
                  <div className="cursor ml-2" onClick={() => this.deleteInovice()}>
                    <i className="fa fa-trash"/>
                  </div>
                </OverlayTrigger>
              }
              {/* <div className="m-4 cursor" onClick={() => this.generateIIF()}>
                Generate IIF
              </div> */}
            </div>
          </div>
        </div>
        <div>
          <CSVLink
            data={this.state.reportData}
            filename={`${invoice.reference}.csv`}
            target="_blank"
            ref="csv"
          />
          {emailModal}
        </div>
      </div>
    );
  }
}

export default withRouter(EllipsisButtons);
