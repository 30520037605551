import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "../../../configAxios";

import VolunteerItem from "./VolunteerItem";
import CardHeader from "../CardHeader";

import Spinner from "../../../assests/images/spinner.gif";

class Volunteers extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: false,
    volunteer_tickets: []
  };

  componentDidMount() {
    this.setState({ ...this.state, loading: true });
    let params = {
      per_page: 5,
      order: {
        column: "created_at",
        dir: "desc"
      }
    };
    axios
      .get(`/volunteer_tickets/`, { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          volunteer_tickets: res.data.volunteer_tickets,
          loading: false
        });
        this.props.updateGrid();
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  changeStatus = (id, status) => {
    axios
      .post(`/volunteer_tickets/${status}/`, { id: id })
      .then(res => {
        if (res.data.json.success) {
          toast.success(`Volunteer Ticket ${status === 'approve' ? 'Approved' : 'Rejected'}`);
          this.setState({
            ...this.state,
            volunteer_tickets: this.state.volunteer_tickets.filter(ticket => ticket.id === id ? false : true)
          }, () => {
            this.props.updateGrid();
          })
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(error + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      })
      .catch(error => error);
  };

  render() {
    const spinner = (
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    );
    return (
      <div className="dashboard">
        <div className="card">
          {this.state.loading ? (
            <div className="card-body p-0">
              <div className="card-text">
                <div className="spinner">{spinner}</div>
              </div>
            </div>
          ) : (
              <div>
                <CardHeader
                  title={"Volunteers"}
                  icon="fa-award"
                  collapse="volunteers"
                  updateGrid={this.props.updateGrid}
                  removeModule={this.props.removeModule}
                  name={this.props.name}
                />
                <div className="dashboard-card collapse show" id="volunteers">
                  <div className="card-body p-0">
                    <div className="card-text">
                      <ul className="list-group list-group-flush">
                        {this.state.volunteer_tickets.map(volunteer => (
                          <VolunteerItem
                            key={volunteer.id}
                            volunteer={volunteer}
                            changeStatus={this.changeStatus}
                          />
                        ))}
                      </ul>
                      <div className="p-2 text-center cursor card-text" style={{ height: "40px" }}>
                        <Link to="/volunteer_tickets" className="text-info">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(Volunteers);
