import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { toast } from "react-toastify"
import ApplicationStatusSelect from "./ApplicationStatusSelect"
import { connect } from "react-redux"
import Modal from "../../../UI/Modal";
import EmailModal from "./emailModal";

function ApplicationItem({ applicationItem, location, roleLevel, grantCampaign }) {
  const [status, setStatus] = useState(applicationItem.status)
  const [showModal, setShowModal] = useState(false)

  const { applicant } = applicationItem

  const isAdmin = () => {
    return ["super_admin", "site_admin", "staff"].includes(roleLevel)
  }

  const handleStatusUpdate = function (status) {
    setStatus(status)

    if (status != 'Submitted') {
      setShowModal(true)
    }
  }

  return <>
    <Modal
      className="grants-and-submissions-invoice-modal"
      show={showModal}
      title="Grants and Submissions"
      size={"lg"}
      hide={() => setShowModal(false)}
    >
      <EmailModal applicant={applicant} setShowModal={setShowModal} grantCampaign={grantCampaign} status={status} applicationItem={applicationItem} setStatus={setStatus} />
    </Modal>
    <tr>
      <td>{applicant.full_name}</td>
      <td><a href={`mailto:${applicant.email}`}>{applicant.email}</a></td>
      <td>
        {isAdmin() && <ApplicationStatusSelect status={status} setStatus={handleStatusUpdate}/>}
        {!isAdmin() && <p>{applicationItem.status}</p>}
      </td>
      <td>{applicationItem.updated_at.match(/^[^T]*/)}</td>
      <td>
        <a className="text-info cursor px-2" title="View application"
           href={`${location.pathname}/show/${applicationItem.id}`}>
          <i className="fas fa-eye"/>
        </a>
      </td>
    </tr>
  </>
}

const mapStateToProps = (state) => {
  return {roleLevel: state.auth.roleLevel}
}

export default connect(mapStateToProps)(withRouter(ApplicationItem))
