import React from "react"
import groupTagOptions from "../../shared/groupTagOptions"
import { sortBy } from "lodash"

const Tag = ({ tags, setTags, tagOptions, setTagOptions, tagInfo}) => {

  const deleteTag = (event) => {
    event.preventDefault()

    const tagFilter = tags.filter(tag =>
      tag.value !== tagInfo.value
    )
    setTags(tagFilter)

    const unformattedTagOptions = [
      ...tagOptions[0].options,
      ...tagOptions[1].options,
      tagInfo
    ]

    const tagSorting = groupTagOptions(
      sortBy(unformattedTagOptions, 'label')
    )
    setTagOptions(tagSorting)
  }

  return(
    <div className="tag word-wrap-break">
      <div className="tag-name">{tagInfo.label}</div>
      <button
        className="tag-delete"
        onClick={deleteTag}
      >
        x
      </button>
    </div>
  )
}

export default Tag
