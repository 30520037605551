import React, { PureComponent } from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

class ProjectsGraph extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={this.props.data}
          layout="vertical"
          barGap="35%"
          margin={{ top: 5, right: 30, left: -40, bottom: 5 }}
        >
          <XAxis type="number" />
          <YAxis
            type="category"
            dataKey="name"
            padding={{ top: 40, bottom: 20 }}
            axisLine={false}
          />
          <CartesianGrid stroke="#f5f5f5" horizontal={false} />
          <Tooltip />
          <Legend
            align="left"
            wrapperStyle={{ marginLeft: "60px" }}
            payload={[
              {
                value: "Total Active Projects",
                type: "square",
                color: "#ffbb00"
              },
              {
                value: "New This Year",
                type: "square",
                color: "#7DB6ED"
              },
              {
                value: "New This Month",
                type: "square",
                color: "#90D434"
              },
              {
                value: "New This Week",
                type: "square",
                color: "#FF6C60"
              }
            ]}
          />
          <Bar dataKey="activeProject" fill="#ffbb00" />
          <Bar dataKey="newThisYear" fill="#7DB6ED" />
          <Bar dataKey="newThisMonth" fill="#90D434" />
          <Bar dataKey="newThisWeek" fill="#FF6C60" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default ProjectsGraph;
