import React from "react";

const button = props => {
  let style = {};
  switch (props.type) {
    case "primary":
      style = { color: "white", backgroundColor: "var(--color-primary)" };
      break;
    case "secondary":
      style = { color: "#505050", backgroundColor: "#dddddd" };
      break;
    case "success":
      style = { color: "white", backgroundColor: "var(--color-success)" };
      break;
    case "warning":
      style = { color: "white", backgroundColor: "var(--color-warning)" };
      break;
    case "danger":
      style = { color: "white", backgroundColor: "#F66C61" };
      break;
    case "info":
      style = { color: "white", backgroundColor: "var(--color-info)" };
      break;
    case "delete":
      style = { color: "white", backgroundColor: "rgb(255, 109, 100)" };
      break;
    default:
      style = {};
  }
  return (
    <button
      type="button"
      className={`btn ${props.className && props.className}`}
      style={{ ...style, ...props.style }}
      onClick={props.clicked}
      title={props.title}
      disabled={props.disabled}
      {...props.buttonConfig}
    >
      {props.children}
    </button>
  );
};

export default button;
