import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";
import axios from "../../../../configAxios";
import FormButton from "../../../UI/button/FormButton";
import Button from "../../../UI/button/Button";
import Input from "../../../UI/input/Input";
import Spinner from "../../../UI/ModalSpinner";

class AddOrEditAccreditation extends Component {
  signal = axios.CancelToken.source();
  state = {
    credName: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "credName",
        required: true
      },
      value: "",
      label: "Accreditation Name"
    },
    credLevel: {
      elementType: "select",
      elementConfig: {
        name: "credLevel",
        isLoading: true,
        required: true,
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Accreditation Level"
    },
    notes: {
      elementType: "textarea",
      elementConfig: {
        name: "notes",
        rows: "4"
      },
      value: "",
      label: "Notes"
    },
    disableButton: false,
    loader: true
  }

  componentDidMount() {
    const { credLevels } = this.props;
    if (credLevels) {
      const options = credLevels.map(cred => {
        return { label: cred[1], value: cred[0] }
      })
      this.setState({
        credLevel: {
          ...this.state.credLevel,
          elementConfig: {
            ...this.state.credLevel.elementConfig,
            options,
            isLoading: false
          }
        }
      })
      if (this.props.editCred) {
        axios.get(`/creds/${this.props.credId}`, { cancelToken: this.signal.token })
          .then(res => {
            const cred = res.data.json.data;
            this.setState({
              credName: {
                ...this.state.credName,
                value: cred.name
              },
              credLevel: {
                ...this.state.credLevel,
                selectedOption: {
                  value: cred.cred_level_id,
                  label: credLevels && credLevels.filter(level =>
                    level[0] === cred.cred_level_id ? true : false
                  )[0][1]
                }
              },
              notes: {
                ...this.state.notes,
                value: cred.notes
              },
              loader: false
            })
          })
      }
    }
  }

  inputChangeHanlder = (event, key, type) => {
    let inputValue = {};
    if (type === "text") {
      inputValue["value"] = event.target.value;
    } else {
      inputValue["selectedOption"] = event;
    }

    this.setState({
      ...this.state,
      [key]: {
        ...this.state[key],
        ...inputValue
      }
    })
  }

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disableButton: true });
    const { credName, credLevel, notes } = this.state;
    const formData = {
      name: credName.value,
      cred_level_id: credLevel.selectedOption.value,
      notes: notes.value
    }
    if (!this.props.editCred) {
      axios.post("/creds", formData).then(res => {
        if (res.data.json.success) {
          toast.success("Accreditation successfully created.")
          this.props.getCreds();
          this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error =>
              toast.error(`${title(error)} ${res.data.json.errors[error]}`)
            )
          }
        }
      })
    } else {
      axios.put(`/creds/${this.props.credId}`, formData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Accreditation successfully updated.")
            this.props.getCreds();
            this.props.closeModal();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error =>
                toast.error(`${title(error)} ${res.data.json.errors[error]}`)
              )
            }
          }
        })
    }
  }

  render() {
    const { credName, credLevel, notes } = this.state;
    return (
      <form onSubmit={this.submitHandler}>
        {this.props.editCred && this.state.loader ?
          <Spinner />
          : <div className="row">
            <div className="col-md-12 mt-4">
              <Input {...credName} changed={event => this.inputChangeHanlder(event, "credName", "text")} />
              <Input {...credLevel} changed={event => this.inputChangeHanlder(event, "credLevel", "select")} />
              <Input {...notes} changed={event => this.inputChangeHanlder(event, "notes", "text")} />
              <div className="text-center">
                <Button type="secondary" className="mr-3" clicked={this.props.closeModal}>Cancel</Button>
                <FormButton disabled={this.state.disableButton}>{this.props.editCred ? "Update" : "Create"}</FormButton>
              </div>
            </div>
          </div>
        }
      </form>
    )
  }
}

export default AddOrEditAccreditation;