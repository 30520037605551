import React, { useState, useEffect } from 'react'
import axios from "../../../configAxios"
import _ from "lodash";
import { useSelector, useDispatch } from 'react-redux'
import { createObject, updateObject } from '../../../store/actions/index'
import spinner from "../../../assests/images/spinner-gif.gif";
import FormBody from "./FormBody"
import { getGrantApplications } from "../../../store/slices/grantApplicationSlice";


export default function ApplicationForm({ match, history }) {

  const response = useSelector(state => state.response)
  const dispatch = useDispatch()

  const [activeForm, setActiveForm] = useState(null)
  const [applicationForm, setApplicationForm] = useState(null)
  const [grantApplication, setGrantApplication] = useState(null)
  
  const createGrantApplication = () => {
    const grantApplicationParams = {
      route: "/grant_applications",
      data: {
        grant_campaign_id: match.params.id,
        status: "Incomplete",
      },
      object: "grant_application",
      callback: setGrantApplication,
      message: "Application Created Successfully"
      }
    dispatch(createObject(grantApplicationParams))
  }

  const createApplicationForm = () => {
    const applicationParams = {
      route: "/application_forms",
      data: {
        grant_application_id: grantApplication.id,
        status: "Draft",
        form_id: match.params.form_id,
      },
      object: "application_form",
      callback: setApplicationForm,
      message: "Application form created successfully"
      }
    dispatch(createObject(applicationParams))
  }

  const getGrantApplication = () => {
    axios.get(`/grant_applications/${match.params.application_id}`).then(res => {
      const grantApplicationData = res.data.json.data.grant_application
      _.isEmpty(grantApplicationData) ? createGrantApplication() : setGrantApplication(grantApplicationData)
    })
  }

  const getApplicationForm = () => {
    const params = {
      grant_campaign_id: match.params.id,
      form_id: match.params.form_id,
      grant_application_id: match.params.application_id
    }
      axios.get("/application_forms", { params }).then(res => {
        const applicationData = res.data.json.data.application_form
        _.isEmpty(applicationData) ? createApplicationForm() : setApplicationForm(applicationData)
      })
  }
  
  const completeApplicationForm = () => {
    const applicationParams = {
      route: `/application_forms/${applicationForm.id}`,
      data: {
        status: "Complete",
      },
      object: "application_form",
      callback: () => (history.push("/grant_applications")),
      message: "Application form completed"
      }
      window.confirm("If you complete the form you will no longer be able to make any changes, would you like to proceed?") && dispatch(updateObject(applicationParams))
  }

  useEffect(() => {
    activeForm ||
    axios.get(`/forms/${match.params.form_id}`).then(res => setActiveForm(res.data.json.data.form))
  }, [])

  useEffect(() => {
    grantApplication || getGrantApplication()
  },[])

  useEffect(() => {
    grantApplication && grantApplication.id && getApplicationForm()
  }, [grantApplication])

  const returnToIndex = () => window.location.href = "/grant_applications"
  

  useEffect(() => {
    applicationForm && applicationForm.status === "Complete" && returnToIndex()
  }, [applicationForm])

  const Spinner = () => {
    return (
      <img
        src={spinner}
        style={{ width: "200px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    )
  }

  return (
    <div>
      {activeForm && applicationForm ? 
        <FormBody 
          grantCampaignId={match.params.id}
          form={activeForm}
          applicationForm={applicationForm}
          getApplicationForm={getApplicationForm}
          completeApplicationForm={completeApplicationForm}
          response={response}
          history={history}
        />
        :
        <Spinner />
      }
    </div>
  )
}
