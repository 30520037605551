import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { toast } from "react-toastify"

import axios from "../../../configAxios"
import Pagination from "../../UI/Pagination"
import Spinner from "../../UI/Spinner"
import Input from "../../UI/input/Input"
import Button from "../../UI/button/Button"
import Modal from "../../UI/Modal"

import ReelsItem from "./ReelsItem/ReelsItem"
import AddNewReel from "./AddNewReel"
import langUtils from "../../../utils/langUtils"

import "./Reels.css"

const PER_PAGE_COUNT = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 75, label: 75 },
  { value: 100, label: 100 },
]

class Reels extends Component {
  signal = axios.CancelToken.source()
  timeout = 0
  state = {
    showReels: null,
    showCollections: null,
    selectedPage: 1,
    forcePage: 0,
    reelsList: [],
    totalCount: 0,
    loading: true,
    user_Id: "",
    search: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "search",
      },
      placeholder: "Search",
      value: "",
    },
    perPage: {
      elementType: "select",
      elementConfig: {
        name: "perPage",
        options: PER_PAGE_COUNT,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: { label: 10, value: 10 },
    },
    showNewReelModal: false,
    disabledIds: [],
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getReels()
    this.getPolicy()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  openNewReelModal = () => {
    window.scrollTo(0, 0)
    this.setState({
      showNewReelModal: true,
    })
  }

  inputChangeHandler = (event) => {
    this.setState({
      ...this.state,
      selectedPage: 1,
      search: {
        ...this.state.search,
        value: event.target.value,
      },
      loading: true,
    })
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.getReels()
    }, 500)
  }

  clearQueryString = () => {
    this.setState(
      {
        search: {
          ...this.state.search,
          value: "",
        },
        loading: true,
      },
      () => this.getReels()
    )
  }

  getPolicy = () => {
    axios.get("/projects/policy").then((res) => {
      this.setState({
        showReels: res.data.json.reels,
        showCollections: res.data.json.collections,
      })
    })
  }

  getReels = () => {
    const { selectedOption } = this.state.perPage
    axios
      .get("/reels", {
        params: {
          page: this.state.selectedPage,
          per_page: selectedOption.value,
          query: this.state.search.value,
        },
        cancelToken: this.signal.token,
      })
      .then((response) => {
        const fetchedList = []
        for (const key in response.data.reels) {
          fetchedList.push({
            ...response.data.reels[key],
            id: response.data.reels[key].id,
          })
        }
        const userId = response.data.meta.current_user_id
        this.setState({
          loading: false,
          reelsList: fetchedList,
          totalCount: response.data.meta.total,
          user_Id: userId,
        })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState(
      {
        selectedPage: page.selected + 1,
        forcePage: page.selected,
        loading: true,
      },
      () => this.getReels()
    )
  }

  paginationCountHandler = (selectedOption) => {
    const { perPage } = this.state
    this.setState(
      {
        perPage: {
          ...perPage,
          selectedOption,
        },
        totalCount: 0,
        loading: true,
      },
      () => this.getReels()
    )
  }

  featuredCheckboxHandler = async (reel) => {
    const { disabledIds } = this.state
    const { id, name, sharing, featured, project_attribute_ids } = reel
    try {
      const sharingArr = ["me", "staff", "everyone"]
      this.setState({
        disabledIds: [...this.state.disabledIds, id],
      })
      const reelProjects = project_attribute_ids.join(',')
      const reelData = {
        name,
        sharing: sharingArr.indexOf(sharing.value),
        featured: !featured,
        project_ids: reelProjects,
      }
      const response = await axios.put(`/reels/${id}`, reelData)
      const { success, data } = response.data.json
      if (success) {
        this.getReels()
        setTimeout(() => {
          this.setState({
            disabledIds: disabledIds.filter((item) => item !== id),
          })
        }, 500)
        toast.success(`${data.name} is ${data.featured ? 'now a': 'no longer a'} Featured Reel`)
      }
    } catch (e) {
      this.setState({
        disabledIds: disabledIds.filter((item) => item !== id),
      })
    }
  }

  render() {
    let removeIcon
    if (this.state.search.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times reels-remove-icon cursor"
          onClick={() => this.clearQueryString()}
        />
      )
    }
    let reelsItems
    if (this.state.loading) {
      reelsItems = <Spinner />
    } else {
      reelsItems = this.state.reelsList.map((item) => (
        <ReelsItem
          key={item.id}
          reel={item}
          userId={this.state.user_Id === item.user_id}
          getReels={this.getReels}
          featuredCheckboxHandler={this.featuredCheckboxHandler}
          disabledIds={this.state.disabledIds}
        />
      ))
    }

    let reelsList
    if (!this.state.loading && this.state.totalCount === 0) {
      reelsList = (
        <div className="text-center font-weight-bold my-5">No Reels</div>
      )
    } else {
      reelsList = (
        <li className="list-group-item border-top-0">
          <div className="row">
            <div className="col-1">
              <span className="font-weight-bold">Id</span>
            </div>
            <div className="col-3">
              <span className="font-weight-bold">Name</span>
            </div>
            <div className="col-2">
              <span className="font-weight-bold">Projects</span>
            </div>
            <div className="col-2">
              <span className="font-weight-bold">Shared to</span>
            </div>
            <div className="col-2">
              <span className="font-weight-bold">Featured</span>
            </div>
            <div className="col-1">
            </div>
          </div>
        </li>
      )
    }
    return (
      <div className="container reels w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Reels</h3>
          </div>
          <div className="col-6 text-right">
            <Button type="success" clicked={this.openNewReelModal}>
              {langUtils("txt_global_add_new", "Add New")}
            </Button>
          </div>
        </div>

        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs font-weight-bold">
                <li className="nav-item px-4 inactive-tab">
                  <NavLink className="text-dark decoration-none" to="/projects">
                    Projects
                  </NavLink>
                </li>
                {this.state.showReels && (
                  <li className="nav-item px-4 active-tab">
                    <NavLink className="decoration-none" to="/reels">
                      Reels
                    </NavLink>
                  </li>
                )}
                {this.state.showCollections && (
                  <li className="nav-item px-4 inactive-tab">
                    <NavLink
                      className="text-dark decoration-none"
                      to="/collections"
                    >
                      Collections
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <ul className="list-group list-group-flush">
            <div className="row">
              <div className="col-md-9 col-sm-12">
                <div className="w-100 text-center px-3">
                  <i className="fas fa-search reels-search-icon" />
                  {removeIcon}
                  <Input
                    {...this.state.search}
                    changed={(event) => this.inputChangeHandler(event)}
                    style={{ paddingRight: "70px", height: "40px" }}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="row py-3">
                  <div className="col text-right py-2">Show entries:</div>
                  <div className="col">
                    <div className="pr-3">
                      <Input
                        {...this.state.perPage}
                        changed={this.paginationCountHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {reelsList}
            {reelsItems}
          </ul>

          <Pagination
            forcePage={this.state.selectedPage - 1}
            pageCount={
              this.state.totalCount / this.state.perPage.selectedOption.value
            }
            handlePageClick={this.paginationHandler}
          />
        </div>
        <Modal
          title="Create a Reel"
          show={this.state.showNewReelModal}
          flag={true}
          hide={() => this.setState({ showNewReelModal: false })}
        >
          <AddNewReel
            closeModal={() => this.setState({ showNewReelModal: false })}
            getReels={this.getReels}
          />
        </Modal>
      </div>
    )
  }
}

export default Reels
