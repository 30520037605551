import React, { Component } from "react"

import axios from "../../../configAxios"
import Button from "../../UI/button/Button"
import Modal from "../../UI/Modal"
import Spinner from "../../UI/Spinner"
import errorStatusHandler from "../../errorPages/UserNotAuthorized"
import langUtils from "../../../utils/langUtils"

import AddNew from "./AddNew"
import EditRental from "./EditRental"

class ThirdPartyOwners extends Component {
  signal = axios.CancelToken.source()
  state = {
    thirdPartyOwnersList: [],
    editThirdPartyOwner: false,
    migrate: false,
    thirdPartyOwnerId: null,
    newModal: false,
    editModal: false,
    getThirdPartyOwner: {},
    loading: true,
  }

  componentDidMount() {
    this.getThirdPartyOwners()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getThirdPartyOwners = () => {
    axios
      .get("/third_party_owners", { cancelToken: this.signal.token })
      .then((res) => {
        if (res.status === 202) {
          errorStatusHandler(res)
          this.props.tabChangeHandler("Categories")
        } else {
          this.setState({
            thirdPartyOwnersList: res.data.third_party_owners,
            loading: false,
          })
        }
      })
      .catch(() => console.log("Something went wrong"))
  }

  newModal = () => {
    window.scrollTo(0, 0)
    this.setState({ newModal: true })
  }

  closeModal = () => {
    window.scrollTo(0, 0)
    this.setState({ viewModal: false })
  }

  editModal = (thirdPartyOwner) => {
    window.scrollTo(0, 0)
    this.setState({ thirdPartyOwner, editModal: true })
  }

  render() {
    const thirdPartyOwners = this.state.thirdPartyOwnersList
    let thirdPartyOwnersList
    if (this.state.loading) {
      thirdPartyOwnersList = <Spinner />
    } else {
      thirdPartyOwnersList = thirdPartyOwners.map((thirdPartyOwner) => (
        <li className="list-group-item rounded-0" key={thirdPartyOwner.id}>
          <div className="row">
            <div className="col-md-3">
              {`${thirdPartyOwner.first_name} ${thirdPartyOwner.last_name}`}
            </div>
            <div className="col-md-3">
              <div>
                {thirdPartyOwner.phone_number === null ||
                thirdPartyOwner.phone_number === ""
                  ? "--"
                  : `${thirdPartyOwner.phone_number}`}
              </div>
            </div>
            <div className="col-md-4">
              <div>
                {thirdPartyOwner.email_address === null ||
                thirdPartyOwner.email_address === ""
                  ? "--"
                  : `${thirdPartyOwner.email_address}`}
              </div>
            </div>
            <div className="col-md-1">
              <div className="d-flex justify-content-around text-muted px-5">
                <i
                  className="fas fa-pencil-alt location-edit cursor"
                  onClick={() =>
                    this.setState({
                      thirdPartyOwnerId: thirdPartyOwner.id,
                      newModal: true,
                      editThirdPartyOwner: true,
                      migrate: false,
                    })
                  }
                />
              </div>
            </div>
            {thirdPartyOwner.user_id === null && (
              <div className="col-md-1">
                <div className="d-flex justify-content-around">
                  <i
                    className="fas fa fa-plus-square cursor"
                    onClick={() =>
                      this.setState({
                        thirdPartyOwnerId: thirdPartyOwner.id,
                        newModal: true,
                        editThirdPartyOwner: true,
                        migrate: true,
                      })
                    }
                    closeModal={() =>
                      this.setState({
                        newModal: false,
                        editThirdPartyOwner: false,
                        migrate: false,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </li>
      ))
    }
    return (
      <React.Fragment>
        <ul className="list-group list-group-flush mt-4">
          <li className="list-group-item border-top-0">
            <div className="row">
              <div className="col-md-3">
                <span className="font-weight-bold">Name</span>
              </div>
              <div className="col-md-3">
                <span className="font-weight-bold">Phone Number</span>
              </div>
              <div className="col-md-2">
                <span className="font-weight-bold">Email</span>
              </div>
              <div className="col-md-4 text-right">
                <Button type="success" clicked={this.newModal}>
                  {langUtils("txt_global_add_new", "Add New")}
                </Button>
              </div>
            </div>
          </li>
          {thirdPartyOwnersList}
        </ul>
        <Modal
          show={this.state.newModal}
          title={
            !this.state.editThirdPartyOwner
              ? "New Third Party Owner"
              : this.state.migrate
              ? "Migrate"
              : "Edit Third Party Owner"
          }
          hide={() =>
            this.setState({
              newModal: false,
              editThirdPartyOwner: false,
              migrate: false,
            })
          }
        >
          <AddNew
            type="thirdPartyOwner"
            thirdPartyOwnerId={this.state.thirdPartyOwnerId}
            getThirdPartyOwners={this.getThirdPartyOwners}
            edit={this.state.editThirdPartyOwner}
            migrate={this.state.migrate}
            closeModal={() =>
              this.setState({
                newModal: false,
                editThirdPartyOwner: false,
                migrate: false,
              })
            }
          />
        </Modal>
        <Modal
          show={this.state.editModal}
          title="Edit Location"
          hide={() => this.setState({ editModal: false })}
        >
          <EditRental
            type="thirdPartyOwner"
            editThirdPartyOwner={this.state.editThirdPartyOwner}
            location={this.state.thirdPartyOwner}
            getThirdPartyOwners={this.getThirdPartyOwners}
            closeModal={() =>
              this.setState({ editModal: false, editThirdPartyOwner: false })
            }
          />
        </Modal>
      </React.Fragment>
    )
  }
}

export default ThirdPartyOwners
