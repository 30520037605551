import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import FormButton from "../../UI/button/FormButton"
import Input from "../../UI/input/Input"
import Spinner from "../../UI/Spinner"
import Modal from "../../UI/modal/CalendarModal"
import Pagination from "../../UI/Pagination"
import CenteredContainer from "../../UI/container/CenteredContainer"
import BookAsset from "../../users/booking/BookAsset"
import AssetItem from "../../users/booking/AssetItem"
import axios from "../../../configAxios"
import "./BookAssets.css"

class BookAssets extends Component {
  constructor() {
    super()
    this.signal = axios.CancelToken.source()
    this.state = {
      search: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "search",
        },
        placeholder: "Search...",
        value: "",
      },
      category: {
        elementType: "select",
        elementConfig: {
          name: "category",
          placeholder: "Select Category",
          options: [],
          isMulti: true,
          hide: false,
          components: {
            DropdownIndicator: () => {
              return null
            },
            IndicatorSeparator: () => {
              return null
            },
          },
          styles: {
            multiValueLabel: (base) => ({
              ...base,
              backgroundColor: "#f0e9e9",
              color: "#666666",
            }),
            multiValueRemove: (base) => ({
              ...base,
              backgroundColor: "#f0e9e9",
              color: "#666666",
            }),
          },
        },
        selectedOption: [],
      },
      type: "Equipment",
      selectPage: 1,
      assets: null,
      totalCount: 0,
      forcePage: 0,
      showBookingModal: false,
      selectedAsset: null,
      loading: true,
    }
    this.textInput = React.createRef()
  }

  componentDidMount() {
    this.selectHandler("Equipment")
    this.getAssets()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getAssets = async (params) => {
    this.setState({ assets: [], loading: true })
    try {
      const assetsRes = await axios.get("/assets", {
        params: {
          type: this.state.type,
          is_index_for_booking_page: true,
          ticketable_type: "Program",
          ticketable_id: this.props.programId,
          page: this.state.selectPage,
          per_page: 10,
          include: {
            policy: true,
          },
          ...params,
        },
        cancelToken: this.signal.token,
      })
      const assets = assetsRes.data.assets
      const totalCount = assetsRes.data.meta.total_count
      this.setState({ assets, totalCount, loading: false })
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      forcePage: page.selected,
    })
    this.getAssets({
      type: this.state.type,
      page: page.selected + 1,
    })
  }

  inputChangeHandler = (event, key) => {
    const inputValue = {}
    if (key === "category") {
      inputValue.selectedOption = event
    } else {
      inputValue.value = event.target.value
    }
    this.setState({
      [key]: {
        ...this.state[key],
        ...inputValue,
      },
    })
  }

  searchHandler = (event) => {
    event.preventDefault()
    this.setState({ selectPage: 1, totalCount: 0 })
    const { search, category, type } = this.state
    const types = category.selectedOption.map((category) => category.id)
    const params = {
      query_string: search.value,
    }
    if (types.length) {
      if (type === "Equipment") {
        params.category_ids = `[${types}]`
      } else {
        params.library_types = JSON.stringify(types)
      }
    }
    this.getAssets(params)
  }

  selectHandler = (selectedOption) => {
    const category = { ...this.state.category }
    category.selectedOption = []
    let options
    this.setState({
      type: selectedOption,
      forcePage: 0,
      selectedPage: 1,
      totalCount: 0,
    })
    axios
      .get("/bookings/formdata", { cancelToken: this.signal.token })
      .then((res) => {
        this.setState({
          category: "",
        })
        if (selectedOption === "Equipment") {
          options = res.data.json.categories
          this.setState({ category: category })
        } else {
          options = res.data.json.library_types
          this.setState({ category: category })
        }
        this.setState({
          category: {
            ...this.state.category,
            elementConfig: {
              ...this.state.category.elementConfig,
              options: options.map((option) => {
                return {
                  value: selectedOption === "Equipment" ? option[0] : option,
                  id: selectedOption === "Equipment" ? option[0] : option,
                  label: selectedOption === "Equipment" ? option[1] : option,
                }
              }),
            },
          },
        })
      })
      .catch((error) => error)
    this.getAssets({ type: selectedOption })
  }

  clearTextHandler = () => {
    const search = { ...this.state.search }
    search.value = ""
    this.setState({ search })
    this.textInput.current.focus()
  }

  bookAssetHandler = (showBookingModal, selectedAsset) => {
    this.setState({
      showBookingModal,
      selectedAsset,
    })
  }

  render() {
    const { totalCount, forcePage, assets } = this.state
    const search = { ...this.state.search }
    let removeIcon = ""
    if (search.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times remove cursor"
          onClick={this.clearTextHandler}
        />
      )
    }

    let assetList = null
    if (this.state.loading) {
      assetList = <Spinner />
    } else {
      if (assets.length > 0) {
        assetList = assets.map((asset) => {
          return (
            <AssetItem
              key={asset.id}
              asset={asset}
              bookAsset={this.bookAssetHandler}
              manageBookings={true}
            />
          )
        })
      } else {
        assetList = (
          <CenteredContainer>
            <h4 className="center-block">No assets found...</h4>
          </CenteredContainer>
        )
      }
    }
    return (
      <div className="book-assets">
        <form className="mt-3" onSubmit={this.searchHandler}>
          <i className="fas fa-search search-asset" />
          {removeIcon}
          <Input
            {...this.state.search}
            changed={(event) => this.inputChangeHandler(event, "search")}
            style={{ paddingRight: "55px" }}
            forwardRef={this.textInput}
          />
          <Input
            {...this.state.category}
            changed={(event) => this.inputChangeHandler(event, "category")}
          />
          <div className="d-flex justify-content-between">
            <div className="checkboxes">
              <div className="form-check-inline">
                <label className="customradio cursor">
                  <span
                    className="radiotextsty"
                    style={{
                      cursor: this.state.loading ? "no-drop" : "pointer",
                    }}
                  >
                    Facility & Equipment
                  </span>
                  <input
                    type="radio"
                    checked={this.state.type === "Equipment"}
                    style={{
                      cursor: this.state.loading ? "no-drop" : "pointer",
                    }}
                    name="radio"
                    onClick={() => this.selectHandler("Equipment")}
                  />
                  <span
                    className="checkmark"
                    style={{
                      cursor: this.state.loading ? "no-drop" : "pointer",
                    }}
                  />
                </label>{" "}
                <label className="customradio cursor">
                  <span
                    className="radiotextsty"
                    style={{
                      cursor: this.state.loading ? "no-drop" : "pointer",
                    }}
                  >
                    Library
                  </span>
                  <input
                    type="radio"
                    name="radio"
                    style={{
                      cursor: this.state.loading ? "no-drop" : "pointer",
                    }}
                    checked={this.state.type === "Library"}
                    onClick={() => this.selectHandler("Library")}
                  />
                  <span
                    className="checkmark"
                    style={{
                      cursor: this.state.loading ? "no-drop" : "pointer",
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="search-button">
              <FormButton disabled={!!this.state.loading}>Search</FormButton>
            </div>
          </div>
        </form>
        <div className="card asset-listing mt-3">
          <ul className="book-asset-list">{assetList}</ul>
        </div>
        {totalCount > 0 && (
          <Pagination
            forcePage={forcePage}
            pageCount={totalCount / 10}
            handlePageClick={this.paginationHandler}
            range={4}
          />
        )}
        <Modal
          show={this.state.showBookingModal}
          hide={() => this.setState({ showBookingModal: false })}
          closeModal={() => this.setState({ showBookingModal: false })}
          closeButton
        >
          <BookAsset
            selectedAsset={this.state.selectedAsset}
            programId={this.props.programId}
            ticketId={this.props.ticketId}
            closeModal={() => this.setState({ showBookingModal: false })}
            getTicket={this.props.getTicket}
          />
        </Modal>
      </div>
    )
  }
}

export default withRouter(BookAssets)
