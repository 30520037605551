import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import dateFns from "date-fns";
import { snakeCase } from 'change-case';
import _ from "lodash";

import axios from "../../../configAxios";

import BookingActivity from './BookingActivity';
import BookingActivityFacility from './BookingActivityFacility';
import CardHeader from "../CardHeader";

import Spinner from "../../../assests/images/spinner.gif";
import "../index.scss";

const active = {
  color: "#337ab7"
};

const inActive = {
  color: "#666666"
};

const tabNames = ['EquipmentItem', 'Facility', 'Library'];

class BookingActivities extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: false,
    activeTab: 'EquipmentItem',
    allInAssets: null,
    allOutAssets: null,
    overdueAssets: null,
    params: {
      asset_summary_day: dateFns.format(new Date(), "YYYY-MM-DD")
    }
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
      activeTab: localStorage.hasOwnProperty('lastVisitedTab')
        ? localStorage.getItem('lastVisitedTab') : this.state.activeTab
    }, () => {
      let params = {
        ...this.state.params,
        type_used: this.state.activeTab,
      }
      if (this.state.activeTab === 'Facility') {
        params = {
          ...params,
          only_for_date: true
        }
      }
      this.getBookingActivities(params);
    });
  }

  getBookingActivities = params => {
    axios.get(`/assets/booking_activity`, { params, cancelToken: this.signal.token })
      .then(res => {
        let allInAssets = [];
        let overdueAssets = [];
        if (this.state.activeTab !== "Facility") {
          allInAssets = res.data.json.in;
          overdueAssets = res.data.json.overdue;
          allInAssets = this.getUserAssests(allInAssets);
          overdueAssets = this.getUserAssests(overdueAssets);
        }
        let allOutAssets = res.data.json.out;
        allOutAssets = this.state.activeTab !== "Facility"
          ? this.getUserAssests(allOutAssets)
          : this.getUserAssestsFacility(allOutAssets);
        this.setState({
          allInAssets,
          allOutAssets,
          overdueAssets,
          loading: false
        });
        this.props.updateGrid();
      })
      .catch(error => error);
  }

  getUserAssests = assetsArr => {
    let assetsObj = {};
    assetsArr.forEach(data => {
      const key = data.user_or_program_detail.id;
      data["asset"] = {
        ...data.asset,
        showCheckbox: data.should_show_check_box,
        bookingId: data.id
      };
      if (assetsObj.hasOwnProperty(key)) {
        assetsObj[key]["assets"] = [...assetsObj[key]["assets"], data.asset];
      } else {
        assetsObj[key] = {
          info: data,
          assets: [data.asset]
        };
      }
    });
    return assetsObj;
  };

  getUserAssestsFacility = assetsArr => {
    let assetsObj = {};
    assetsArr.forEach(data => {
      const key = data.asset.id;
      data["asset"] = {
        ...data.asset,
        details: data.details,
        ticket: data.ticket,
        userOrProgramDetail: data.user_or_program_detail,
        userOrProgram: data.user_or_program
      };
      if (assetsObj.hasOwnProperty(key)) {
        assetsObj[key]["assets"] = [...assetsObj[key]["assets"], data.asset];
      } else {
        assetsObj[key] = {
          info: data.asset.name,
          assets: [data.asset]
        };
      }
    });
    return assetsObj;
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  tabChangeHandler = tab => {
    this.setState({ activeTab: tab, loading: true });
    localStorage.setItem('lastVisitedTab', tab);
    let params = {
      ...this.state.params,
      type_used: tab,
    }
    if (tab === 'Facility') {
      params = {
        ...params,
        only_for_date: true
      }
    }
    this.getBookingActivities(params);
  }

  render() {
    const { allInAssets, allOutAssets, overdueAssets } = this.state;
    const spinner = (
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    );

    const tabs = tabNames.map((tab, index) => (
      <li
        className="breadcrumb-item cursor"
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        style={this.state.activeTab === tab ? active : inActive}
      >
        {tab === "EquipmentItem" ? "Equipment" : tab}
      </li>
    ))

    return (
      <div className="dashboard">
        <div className="card">
          {this.state.loading ? (
            <div className="card-body p-0">
              <div className="card-text">
                <div className="spinner">{spinner}</div>
              </div>
            </div>
          ) : (
              <div>
                <CardHeader
                  title={"Booking Activities"}
                  icon="fa-book"
                  collapse="booking-activities"
                  updateGrid={this.props.updateGrid}
                  removeModule={this.props.removeModule}
                  name={this.props.name} />
                <div className="dashboard-card collapse show" id="booking-activities">
                  <div className="card-body p-0">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0" style={{ background: "#fff" }}>
                        {tabs}
                      </ol>
                    </nav>
                    <ul className="list-group" style={{ maxHeight: 450, overflow: "auto" }}>
                      {this.state.activeTab !== "Facility" &&
                        <React.Fragment>
                          <li className="list-group-item px-3 font-weight-bold">In</li>
                          {!_.isEmpty(allInAssets)
                            ? Object.keys(allInAssets).map(id => (
                              <BookingActivity key={id} booking={allInAssets[id].info} activeTab={this.state.activeTab} />
                            ))
                            : <li className="list-group-item px-3 py-2 ">There's no scheduled rental yet</li>
                          }
                        </React.Fragment>
                      }
                      <li className="list-group-item px-3 font-weight-bold">Out</li>
                      {!_.isEmpty(allOutAssets)
                        ? Object.keys(allOutAssets).map(id => {
                          return this.state.activeTab !== "Facility" ?
                            <BookingActivity key={id} booking={allOutAssets[id].info} activeTab={this.state.activeTab} />
                            : <BookingActivityFacility key={id} booking={allOutAssets[id]} activeTab={this.state.activeTab} />
                        })
                        : <li className="list-group-item px-3 py-2">There's no scheduled rental yet</li>
                      }
                      {this.state.activeTab !== "Facility" &&
                        <React.Fragment>
                          <li className="list-group-item px-3 font-weight-bold">Overdue</li>
                          {!_.isEmpty(overdueAssets)
                            ? Object.keys(overdueAssets).map(id => (
                              <BookingActivity key={id} booking={overdueAssets[id].info} activeTab={this.state.activeTab} />
                            ))
                            : <li className="list-group-item px-3 py-2">There's no scheduled rental yet</li>
                          }
                        </React.Fragment>
                      }
                    </ul>
                    <div className="p-2 text-center cursor card-text" style={{ height: "40px" }}>
                      <Link to={`/assets/booking_activity/${snakeCase(this.state.activeTab)}`} className="text-info">View All</Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(BookingActivities);