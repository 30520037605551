import React, { useState, useEffect } from 'react'

export default function MultipleChoiceQuestion({ question, response, setResponseData, completed }) {

  const [selectedOption, setSelectedOption] = useState(response && response.question_option_id || null)

  useEffect(() => {
    selectedOption && setResponseData(question.id, {question_option_id: selectedOption})
  }, [selectedOption])

  const QuestionOption = ({ option }) => {
    return (
      <div className="d-flex flex-row align-items-center ml-3">
        <input 
          type="radio"
          id={option.id}
          onClick={() => setSelectedOption(option.id)}
          name={question.id}
          required={question.mandatory}
          checked={option.id === selectedOption}
          value={{option_id: selectedOption }}
          onInvalid={e => e.target.setCustomValidity("Please select an option")}
          disabled={completed}
        />
        <label className="ml-2 mb-0" htmlFor={option.id}>
          {`${option.option_text}`}
        </label>
      </div>
    )
  }

  return (
    <div className="mb-4 d-flex flex-column">
      <p className="mb-2 font-weight-bold">{question.question_text}</p>
        { question.question_options.map((option, index) => <QuestionOption key={index} option={option} />) }
    </div>
  )
}
