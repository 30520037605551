import React from "react";
import LogoSvg from "../../assests/images/Logo_Circle.svg"

const ErrorContentWrapper = props => {
  return (
    <div className="error-content d-flex flex-row align-items-center">
      <img className='w-50 error-logo' src={LogoSvg} alt="AMS Logo" />
      <div className="not-found d-flex flex-column">
        <h1 className='font-weight-bold'>AMS | Network</h1>
        {props.children}
      </div>
    </div>
  );
}

export default ErrorContentWrapper;
