import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createQuestionResponses, updateQuestionResponses } from '../../../store/actions/index'
import { toast } from "react-toastify";
import _ from "lodash";
import FormQuestions from './FormQuestions'
import FormButton from "../../UI/button/FormButton";
import Button from "../../UI/button/Button";
import BackLink from "../../UI/button/BackLink.js"

export default function FormBody({ form, applicationForm, getApplicationForm, response, completeApplicationForm, history }) {
  const [responses, setResponses] = useState(applicationForm ? applicationForm.responses : [])

  const dispatch = useDispatch()
  const completed = applicationForm.status === "Complete"

  const checkIfSaved = () => {
    return responses[0]['id'] !== undefined
  }

  const mapResponsesToFormData = () => {
    return (
      responses.map(response => {
        let formData = new FormData()
        Object.keys(response).forEach(key => {
          if (key === "file_attachment") {
            response[key] && !response[key]["id"] && formData.append(key, response[key]) //If a new file has been uploaded id will not be present
            checkExistingResponse(response) && !response[key] && formData.append(key, "") //If we have an existing file, and response is null, send empty object
          } else if (response[key] || response[key] === 0) {
            formData.append(key, response[key])
          }
          !checkIfSaved() && formData.append("application_form_id", applicationForm.id)
        })
        return formData
      })
    )
  }

  const checkExistingResponse = (response) => {
    return !!applicationForm.responses.find(item => item.id === response.id)
  }

  const saveFormResponses = (callback=getApplicationForm) => {
    let apiAction = checkIfSaved() ? updateQuestionResponses : createQuestionResponses
    dispatch(apiAction(mapResponsesToFormData(), callback))
  }

  useEffect(() => {
    if (!_.isEmpty(response) && response.success !== null && response.message) {
      const { message, success } = response;
      success ? toast.success(message) : toast.error(message);
    }
  },[response])

  useEffect(() => {
    if (applicationForm.id && responses.length === 0) {
      let emptyResponses = form.questions.map(question => ({
        question_id: question.id,
        application_form_id: applicationForm.id
      }))
      dispatch(createQuestionResponses(emptyResponses, getApplicationForm))
    }
  }, [])

  useEffect(() => {
    applicationForm && applicationForm.responses.length > 0 && setResponses(applicationForm.responses)
  }, [applicationForm])

  return (
    <div className="w-100">
      <div className="d-flex flex-row justify-content-center">
        <div className="w-90 card px-4 pb-4 pt-5">
          <h3 className="mb-3">{form.name}</h3>
          <form onSubmit={(e) => {
            e.preventDefault()
            saveFormResponses(completeApplicationForm)
          }}
          >
            <FormQuestions
              form={form}
              applicantData={applicationForm.applicant_fields}
              responses={responses}
              completed={completed} setResponses={setResponses}
            />
            <div className="mt-3 mb-3 d-flex flex-row justify-content-end">
              <span>
                <Button 
                  className="mr-2"
                  type="success"
                  disabled={_.isEmpty(responses) || completed}
                  clicked={() => saveFormResponses()}
                >
                  Save Draft
                </Button>
                <FormButton disabled={completed}>
                  Complete Form
                </FormButton>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
