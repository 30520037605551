import React, { useEffect } from 'react'
import Input from "../../../UI/input/Input"

export default function JuryMemberSelect({juryMembers, selectedJury, setSelectedJury}) {

  const mapJuryToOptions = (juryArray) => {
    return juryArray.length > 0 ? juryArray.map(member => ({ label: member.full_name, value: member })) : []
  }
  
  const clearValidationError = () => {
    const element = document.getElementById("jury-select")
    element.validity.customError && !element.validity.valueMissing && element.setCustomValidity('')
  }

  useEffect(() => {
    selectedJury.length > 0 && clearValidationError()
  }, [selectedJury])
  
  return (
    <div>
      <Input
        id="jury-select"
        elementType="select"
        selectedOption={mapJuryToOptions(selectedJury)}
        elementConfig={{
          name: "grant_campaigns_jury_members",
          placeholder: "Select review jury",
          required: true,
          options: mapJuryToOptions(juryMembers),
          onInvalid: e => e.target.setCustomValidity("Please select a review jury"),
          isMulti: true,
        }}
        changed={options => {
          setSelectedJury(options.map(option => option.value))
        }}
      />
    </div>
  )
}
