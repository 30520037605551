import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";
import queryString from "query-string";
import Details from "./Details";
import "./index.css";
import Forms from '../../forms/index'
import ApplicationsIndex from './grantApplications/ApplicationsIndex'
import JuryTab from './juryTab/JuryTab'

class Tabs extends Component {
  defaultTab = "Campaign";

  setTabs = () => {
    const juryTabs = ["Campaign", "Applications"]
    const adminTabs = ["Campaign", "Forms", "Applications", "Jury"]
    return this.props.isAdmin() ? adminTabs : juryTabs
  }

  state = {
    tabs: this.setTabs(),
    activeTab: this.defaultTab,
    query: null,
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.toptab !== prevProps.match.params.toptab ||
      this.props.location.search !== prevProps.location.search
    ) {
      if (this.props.match.params.toptab) {
        let query;
        if (this.props.location.search !== "") {
          const values = queryString.parse(this.props.location.search);
          query = values.value_3;
        } else {
          query = null;
        }
        this.tabChangeHandler(title(this.props.match.params.toptab));
        this.setState({ query });
      }
    }
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : this.defaultTab,
    });
  };

  tabChangeHandler = (activeTab) => {
    this.setState({ activeTab });
    this.props.history.push(
      `/campaigns/${this.props.grantCampaign.id}/${snakeCase(activeTab)}`
    );
  };

  render() {
    const { tabs: tabNames } = this.state;
    const { grantCampaign } = this.props;
    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${
          this.state.activeTab === tab ? "active-tab" : "inactive-tab "
        }`}
      >
        {tab}
      </li>
    ));
    let activeTab;
    switch (this.state.activeTab) {
      case "Campaign":
        activeTab = <Details grantCampaign={grantCampaign} isAdmin={this.props.isAdmin} match={this.props.match}/>;
        break;
      case "Applications":
        activeTab = <ApplicationsIndex grantCampaign={grantCampaign} isAdmin={this.props.isAdmin} />;
        break;
      case "Forms":
        if (this.state.tabs.includes("Forms")) {
          activeTab = <Forms grantCampaign={grantCampaign} />
        }
        break;
      case "Jury":
        if (this.state.tabs.includes("Jury")) {
          activeTab = <JuryTab grantCampaign={grantCampaign} />
        }
        break;
      default:
        activeTab = null;
    }
    return (
      <div className="card">
        <ul className="nav nav-tabs ml-0">{tabs}</ul>
        {activeTab}
      </div>
    );
  }
}

Tabs.propTypes = {
  grantCampaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

export default withRouter(Tabs);
