import React, { Component } from "react";
import AssetItem from "./AssetItem";
import Pagination from "../UI/Pagination";
import Spinner from "../UI/Spinner";
import CenteredContainer from "../UI/container/CenteredContainer";

class AssetItems extends Component {
  render() {
    const { assets, totalCount, loading, forcePage } = this.props;
    let assetItems;
    if (assets === null || loading) {
      assetItems = <Spinner />;
    } else {
      if (assets.length > 0) {
        assetItems = assets.map(asset => {
          return <AssetItem key={asset.id} asset={asset} />;
        });
      } else {
        assetItems = (
          <CenteredContainer>
            <h4 className="center-block">No Assets found.</h4>
          </CenteredContainer>
        );
      }
    }

    return (
      <div>
        <div className="webkit-fill-available">
          <ul className="list-group list-group-flush">{assetItems}</ul>
        </div>
        {totalCount > 0 && (
          <Pagination
            forcePage={forcePage}
            pageCount={totalCount / this.props.perPage}
            handlePageClick={this.props.paginationHandler}
            range={5}
          />
        )}
      </div>
    );
  }
}

export default AssetItems;
