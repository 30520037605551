import React, { Component } from "react"
import { connect } from "react-redux"

import axios from "../../configAxios"
import Input from "../UI/input/Input"
import FormButton from "../UI/button/FormButton"
import ActiveProjectsCheckbox from "./projectSearch/ActiveProjectsCheckbox";

import { getProjects } from "../../store/actions/index"

class ProjectSearch extends Component {
  constructor() {
    super()
    this.signal = axios.CancelToken.source()
    this.timeout = 0
    this.state = {
      controls: {
        queryString: {
          elementType: "input",
          elementConfig: {
            name: "query_string",
            placeholder: "Enter keyword",
          },
          value: "",
        },
        year: {
          elementType: "range",
          elementConfig: {
            name: "year",
            allowCross: false,
            min: 2000,
            max: new Date().getFullYear(),
            defaultValue: [2000, new Date().getFullYear()],
            trackStyle: [
              { backgroundColor: "var(--color-slider-first)", height: 10 },
            ],
            railStyle: { height: 10 },
            handleStyle: [
              {
                height: 20,
                width: 20,
                backgroundColor: "var(--color-slider-first-darker)",
                borderColor: "var(--color-slider-first-darker)",
              },
              {
                height: 20,
                width: 20,
                backgroundColor: "var(--color-slider-first-darker)",
                borderColor: "var(--color-slider-first-darker)",
              },
            ],
          },
          value: "",
          label: "Year",
          fromLabel: "Old",
          toLabel: "New",
        },
        length: {
          elementType: "range",
          elementConfig: {
            name: "length",
            allowCross: false,
            min: 0,
            max: 50,
            step: 5,
            defaultValue: [0, 50],
            trackStyle: [
              { backgroundColor: "var(--color-slider-second)", height: 10 },
            ],
            railStyle: { height: 10 },
            handleStyle: [
              {
                height: 20,
                width: 20,
                backgroundColor: "var(--color-slider-second-darker)",
                borderColor: "var(--color-slider-second-darker)",
              },
              {
                height: 20,
                width: 20,
                backgroundColor: "var(--color-slider-second-darker)",
                borderColor: "var(--color-slider-second-darker)",
              },
            ],
          },
          value: "",
          label: "Length",
          fromLabel: "Short",
          toLabel: "Long",
        },
        type: {
          elementType: "select",
          elementConfig: {
            name: "type",
            placeholder: "Type",
            options: [],
            components: {
              IndicatorSeparator: () => {
                return null
              },
            },
          },
          selectedOption: null,
          value: null,
        },
        userId: {
          elementType: "asyncSelect",
          elementConfig: {
            name: "user_id",
            placeholder: "Member Name",
            loadOptions: (inputValue, callback) => {
              if (inputValue.length > 2)
                this.searchMembers(inputValue, callback)
            },
            components: {
              IndicatorSeparator: () => {
                return null
              },
            },
          },
          selectedOption: null,
          value: null,
        },
        activeProjectFilter: false
      },
      enteredMemberCharacters: "",
    }
    this.textInput = React.createRef()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    axios
      .get("/projects/filters", { cancelToken: this.signal.token })
      .then((res) => {
        const { projects: types } = res.data
        this.setState({
          controls: {
            ...this.state.controls,
            type: {
              ...this.state.controls.type,
              elementConfig: {
                ...this.state.controls.type.elementConfig,
                options: types.map((type) => {
                  return { value: type, label: type }
                }),
              },
            },
          },
        })
      })
      .catch((error) => error)
    this.textInput.current.focus()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.refreshList !== this.props.refreshList &&
      this.props.refreshList
    ) {
      this.resetSearchHandler()
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {}
    if (key === "queryString") {
      inputValue["value"] = event.target.value
    } else if (key === "length" || key === "year") {
      inputValue["value"] = event
    } else {
      inputValue["selectedOption"] = event
      inputValue["value"] = event
    }
    const updatedcontrols = {
      ...this.state.controls,
      [key]: {
        ...this.state.controls[key],
        ...{
          ...((key === "length" || key === "year") && {
            elementConfig: {
              ...this.state.controls[key].elementConfig,
              ...inputValue,
            },
          }),
        },
        ...inputValue,
      },
    }
    this.setState({ controls: updatedcontrols })
  }

  submitHandler = (event) => {
    event.preventDefault()
    const { userId, type, length, year, queryString } = this.state.controls

    const defaultMinLength = length.value[0] === 0;
    const defaultMaxLength = length.value[1] === 50
    const defaultLengthRange = defaultMinLength && defaultMaxLength

    const defaultMinYear = year.value[0] === 2000;
    const defaultMaxYear = year.value[1] === new Date().getFullYear();

    let searchParams = { page: "1", inactive: !this.state.controls.activeProjectFilter }

    if (queryString.value) {
      searchParams["query_string"] = queryString.value
    }

    if (year.value && !(defaultMinYear && defaultMaxYear)) {
      searchParams["year[start]"] = year.value[0]
      searchParams["year[end]"] = year.value[1]
    }

    // Apr2022: This doesn't function as expected.
    // Selecting a minimum length also sets max length of 50. It's impossible to get length over 50
    // over 50. I think the year slider has a similar bug.
    if (length.value && !defaultLengthRange) {
      searchParams["length[start]"] = length.value[0]
      searchParams["length[end]"] = length.value[1]
    }

    if (type.selectedOption) {
      searchParams["type"] = type.selectedOption.value
    }

    if (userId.selectedOption) {
      searchParams["user_id"] = userId.selectedOption.value
    }

    this.props.getProjects(searchParams)
  }

  searchMembers = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue,
      },
      cancelToken: this.signal.token,
    }
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.getMembers(queryParams, callback)
    }, 300)
  }

  getMembers = (queryParams, callback) => {
    axios
      .get("/users/autocomplete", queryParams)
      .then((res) => {
        callback(
          res.data.users.map((user) => {
            return {
              value: user.id,
              label: user.full_name,
            }
          })
        )
      })
      .catch((err) => err)
  }

  clearTextHandler = () => {
    const controls = { ...this.state.controls }
    const queryString = { ...controls["queryString"] }
    queryString.value = ""
    controls["queryString"] = queryString
    this.setState({ controls: controls })
    this.textInput.current.focus()
  }

  resetSearchHandler = () => {
    this.setState(
      {
        controls: {
          ...this.state.controls,
          queryString: {
            ...this.state.controls.queryString,
            value: "",
          },
          year: {
            ...this.state.controls.year,
            elementConfig: {
              ...this.state.controls.year.elementConfig,
              value: [2000, new Date().getFullYear()],
            },
            value: [2000, new Date().getFullYear()],
          },
          length: {
            ...this.state.controls.length,
            elementConfig: {
              ...this.state.controls.length.elementConfig,
              value: [0, 50],
            },
            value: [0, 50],
          },
          type: {
            ...this.state.controls.type,
            selectedOption: null,
            value: null,
          },
          userId: {
            ...this.state.controls.userId,
            selectedOption: null,
            value: null,
          },
          activeProjectFilter: false,
        },
      },
      () => this.submitHandler({ preventDefault: () => null })
    )
  }

  toggleActiveProjectFilter = () => {
    const updateActiveFilter = !this.state.controls.activeProjectFilter

    this.setState({...this.state, controls: {...this.state.controls, activeProjectFilter: updateActiveFilter}})
  }

  render() {
    const { controls } = this.state
    const formElementsArray = []
    for (let key in controls) {
      if (key === "queryString" || key === "year" || key === "length") {
        formElementsArray.push({
          key: key === "queryString" ? 0 : key === "year" ? 1 : 2,
          id: key,
          config: controls[key],
        })
      }
    }

    const formElementsArraySorted = formElementsArray.sort(
      (val1, val2) => val1.key > val2.key
    )

    const queryString = { ...controls["queryString"] }
    let removeIcon = ""
    if (queryString.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times cursor"
          style={{
            position: "relative",
            top: 24,
            color: "#b3b3b3",
            paddingRight: 10,
            float: "right",
          }}
          onClick={this.clearTextHandler}
        />
      )
    }
    return (
      <div className="card">
        <div className="mt-lg-4 ml-lg-4 m-lg-0 m-4" style={{ paddingLeft: "" }}>
          <h4 className="font-weight-bold text-dark">Search</h4>
          <form onSubmit={this.submitHandler}>
            <i
              className="fas fa-search"
              style={{
                position: "relative",
                float: "right",
                top: 24,
                color: "#b3b3b3",
                paddingRight: 10,
              }}
            />
            {removeIcon}
            {formElementsArraySorted.map((formElement) => (
              <div
                className={
                  formElement.config.elementType === "range" ? "px-3" : ""
                }
                key={formElement.id}
              >
                <Input
                  key={formElement.id}
                  {...formElement.config}
                  changed={(event) =>
                    this.inputChangedHandler(event, formElement.id)
                  }
                  needPadding={true}
                  forwardRef={this.textInput}
                  style={
                    formElement.config.elementConfig.name === "query_string"
                      ? { paddingRight: "55px" }
                      : {}
                  }
                />
              </div>
            ))}
            <div className="row">
              <div className="col-md-6 type-input">
                <Input
                  key="type"
                  {...this.state.controls.type}
                  changed={(event) => this.inputChangedHandler(event, "type")}
                />
              </div>
              <div className="col-md-6 member-input">
                <Input
                  key="userId"
                  {...this.state.controls.userId}
                  chart={this.state.enteredMemberCharacters}
                  changed={(event) => this.inputChangedHandler(event, "userId")}
                  onInputChange={(event) =>
                    this.setState({ enteredMemberCharacters: event })
                  }
                />
              </div>
            </div>
            <ActiveProjectsCheckbox activeProjectFilter={this.state.controls.activeProjectFilter} changed={this.toggleActiveProjectFilter} />
            <FormButton
              block
              className="mt-4"
              disabled={this.props.response.loading}
            >
              Search
            </FormButton>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.response,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjects: (searchParams) => dispatch(getProjects(searchParams)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSearch)
