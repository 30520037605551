import React, { useEffect, useState } from 'react'

const StatItem = ({ stat, onChange }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (stat?.stat_notes) setValue(stat.stat_notes)
  }, [stat])

  const onInputChange = (e) => {
    onChange({ id: stat.id, stat_value: stat.stat_value, stat_notes: e.target.value })
    setValue(e.target.value)
  }

  return (
    <div className='d-flex flex-column w-100 px-2 my-3'>
      <label>{stat?.stat_name}</label>
      <textarea
        id="stat-name"
        name="stat-name"
        className="w-100 form-control"
        value={value}
        onChange={onInputChange}
      />
    </div>
  )
}

export default StatItem
