import React, {useState, useEffect} from "react"
import Modal from "../../UI/Modal"
import Spinner from "../../UI/ModalSpinner"

const AssetDetailsModal = (props) => {

  const [displayAsset, setDisplayAsset] = useState(null)

  useEffect(() => {
    // Sometimes Assets are given via a serializer that nests data in a sub object
    // This effect lets us flatten the object and access the data nicely
    if (props.asset) {
      setDisplayAsset({...props.asset, ...props.asset?.object})
    }
  }, [props.asset])


  const { show, hide, loadingModal, onLoad } = props

  const AssetDetail = ({ header, children }) => {
    return (
      <div className="my-3">
        <span className="font-weight-bold"> {header} </span>
        <span className="font-weight-normal"> {children} </span>
      </div>
    )
  }

  return (
    <Modal
      show={show}
      title="Asset Detail"
      hide={hide}
      closeModal={hide}
      flag={true}
      program={true}
      closeButton
    >
      {displayAsset &&
        <div className="mt-4 word-wrap-break assetBanana-modal-container">
          {displayAsset.photo && loadingModal &&
            <div className="modal-overlay">
              <Spinner />
            </div>
          }
          {displayAsset.photo &&
            <div className="my-3 text-center">
              <img
                src={displayAsset.photo}
                alt={displayAsset.name}
                className="img-thumbnail assetBanana-img"
                onLoad={onLoad}
                onError={onLoad}
              />
            </div>
          }
          <div className="my-3">
            <span className="font-weight-bold">Name:</span>
            <span className="font-weight-bold"> {displayAsset.name} </span>
          </div>

          <AssetDetail header={"Status:"}>{displayAsset.status_text}</AssetDetail>
          <AssetDetail header={"Type:"}>{displayAsset.type}</AssetDetail>
          <AssetDetail header={"Category:"}>{displayAsset.category_name || "No Category"}</AssetDetail>
          <AssetDetail header={"Description:"}>{displayAsset.description || "No Description"}</AssetDetail>
          <AssetDetail header={"External URL Resources:"}>
            {displayAsset.external_url_resources &&
              <a
                className="font-weight-normal text-primary"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                href={displayAsset.external_url_resources}
              >
            <span className="text-primary">
              Resources <i className="fa fa-external-link" />{" "}
            </span>
              </a>
            }
            {!displayAsset.external_url_resources && "N/A"}
          </AssetDetail>

        </div>
      }
    </Modal>
  )
}

export default AssetDetailsModal
