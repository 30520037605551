import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import Button from "../UI/button/Button";

class LanguageForm extends Component {
  signal = axios.CancelToken.source();
  state = {
    language: {},
    edit: false,
    loading: this.props.location.pathname.includes("/edit"),
    disabled: true,

    formFields: {
      name: {
        elementType: "input",
        elementConfig: { name: "name", type: "text", required: true },
        label: "Name of Language",
        value: ""
      }
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getLanguage()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getLanguage = () => {
    if (this.props.location.pathname.includes("/edit")) {
      axios.get(`/base_languages/${this.props.match.params.id}`, { cancelToken: this.signal.token })
        .then(res => {
          this.setState({
            ...this.state,
            language: res.data.base_language,
            formFields: {
              ...this.state.formFields,
              name: {
                ...this.state.formFields.name,
                value: res.data.base_language.name
              }
            },
            edit: true,
            loading: false
          });
        })
    }
  }

  inputEventHandler = event => {
    let formFields = this.state.formFields
    formFields[event.target.name].value = event.target.value
    this.setState({
      ...this.state,
      formFields
    }, () => {
      if (this.state.formFields.name.value !== "")
        this.setState({ disabled: false })
    })
  }

  submitHandler = event => {
    event.preventDefault()
    let data = {
      name: this.state.formFields.name.value
    }

    if (this.state.edit) {
      axios.put(`/base_languages/${this.props.match.params.id}`, data)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Language updated successfully")
            this.props.history.push("/languages")
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    } else {
      axios.post('/base_languages', data)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Language added successfully")
            this.props.history.push("/languages")
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  render() {
    return (
      <div className="container language w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>{this.props.location.pathname.includes("/edit") ? "Update" : "Add"} Language</h3>
          </div>
          <div className="col-6 text-lg-right text-center">
            <span
              className="cursor"
              onClick={() => this.props.history.goBack()}
            >
              {"<< Back"}
            </span>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="card-text">
              {
                this.state.loading ? <Spinner /> : (
                  <form action="" onSubmit={this.submitHandler}>
                    <div className="row">
                      <div className="col-12">
                        <Input
                          {...this.state.formFields.name}
                          changed={this.inputEventHandler}
                        />
                      </div>
                    </div>
                    <div className="text-center my-4">
                      <Button
                        type="secondary"
                        className="mr-2"
                        clicked={() => this.props.history.push(`/languages`)}
                      >
                        Cancel
                      </Button>
                      <FormButton disabled={this.state.disabled}
                        style={{ cursor: this.state.disabled ? "no-drop" : "pointer", opacity: this.state.disabled ? 0.7 : 1 }}>
                        {this.props.location.pathname.includes("/edit") ? "Update" : "Create"}
                      </FormButton>
                    </div>
                  </form>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LanguageForm);
