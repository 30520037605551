import React, { Component } from "react";
import axios from '../../configAxios';
import Button from '../UI/button/Button';
import ModalSpinner from '../UI/ModalSpinner';

import './Media.css';

const buttonStyle = {
  padding: "0 10px"
}

class AWSComponent extends Component {
  signal = axios.CancelToken.source();
  state = {
    files: [],
    folders: [],
    folderPaths: [],
    prevPath: '',
    loading: true
  }

  componentDidMount() {
    this.getAWSBucketData();
  }

  getAWSBucketData = (params = {}) => {
    axios.get("/aws_s3_buckets", { params, cancelToken: this.signal.token })
      .then(res => {
        const { contents } = res.data.json;
        this.setState({
          files: contents.files,
          folders: contents.folders,
          loading: false
        })
      })
  }

  getAWSFolderData = folderPath => {
    let folderPaths = [...this.state.folderPaths];
    const { prevPath } = this.state;
    folderPaths.push(prevPath);
    this.setState({
      folderPaths,
      prevPath: folderPath,
      selectedFile: null,
      loading: true
    })
    this.getAWSBucketData({ path: folderPath });
  }

  attachFileHandler = () => {
    this.props.selectedAWSFile(this.state.selectedFile);
    this.props.closeModal();
  }

  selectedFileHandler = (selectedFile, index) => {
    this.setState({
      selectedFile,
      index
    });
  }

  backButtonHandler = () => {
    let folderPaths = [...this.state.folderPaths];
    const prevPath = folderPaths.pop();
    if (prevPath === "") {
      this.setState({
        prevPath: ''
      })
    }
    this.setState({
      folderPaths,
      prevPath,
      selectedFile: null,
      loading: true
    }, () => {
      this.getAWSBucketData({ path: prevPath });
    })
  }

  render() {
    const { files, folders } = this.state;
    const filesList = files.map((file, index) => (
      <div className='col-md-12 mt-1 cursor'>
        <div onClick={() => this.selectedFileHandler(file, index)}
          style={{ backgroundColor: this.state.selectedFile && index === this.state.index && '#128bec' }}
          className="py-2"
        >
          <div className="row">
            <div className='col-md-9'
              style={{ color: this.state.selectedFile && index === this.state.index && 'white' }}
            >{file.name}</div>
            <div className='col-md-3'
              style={{ color: this.state.selectedFile && index === this.state.index && 'white' }}
            >{file.size}</div>
          </div>
        </div>
      </div>
    ))
    let content;
    if (!this.state.loading && (folders.length > 0 || files.length > 0)) {
      if (folders.length > 0) {
        const foldersList = folders.map(folder => (
          <div onDoubleClick={() => this.getAWSFolderData(folder.full_path)} className="mt-2">
            <i className="far fa-folder"></i>&nbsp;
              {folder.folder_name}
          </div>
        ))
        content = (
          <div className='col-md-12 cursor'>
            {foldersList}
            {filesList}
          </div>
        )
      } else {
        content = filesList;
      }
    } else if (this.state.loading) {
      content = <div className="col-md-12"><ModalSpinner /></div>
    } else {
      content = <div className="col-md-12">No data present in S3</div>
    }
    return (
      <div className="aws-modal">
        {(this.state.folderPaths.length > 0 || this.state.selectedFile) &&
          <div className="row" style={{ height: 50 }}>
            <div className="button-position d-flex justify-content-between">
              <div>
                {this.state.folderPaths.length > 0 &&
                  <Button type="primary" style={buttonStyle} clicked={this.backButtonHandler}>Back</Button>
                }
              </div>
              <div>
                {this.state.selectedFile &&
                  <Button type="primary" style={buttonStyle} clicked={this.attachFileHandler}>
                    Attach File
                </Button>
                }
              </div>
            </div>
          </div>
        }
        <div className="row mt-3">
          <div className="col-md-9">Name</div>
          <div className="col-md-3">Size</div>
        </div>
        <hr className='my-1' />
        <div className="row mt-1">
          {content}
        </div>
        <hr className='my-1' />
      </div>
    );
  }
}

export default AWSComponent;
