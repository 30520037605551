import React, { useState, useEffect } from 'react'
import Input from "../../../UI/input/Input";

export default function CheckboxQuestion({ question, response, setResponseData, completed }) {
  const [selected, setSelected] = useState(response && Boolean(response.response_text) || false) // For a draft, this will be populated from the database if it has already been filled out

  useEffect(() => {
    setResponseData(question.id, {response_text: `${selected}`})
  }, [selected])

  return (
    <div className="mb-4 mx-3">
      <Input
        elementType="checkbox"
        questionText={question.question_text}
        elementConfig={{
          name: question.id,
          id: question.id,
          type: 'checkbox',
          checked: selected,
        }}
        changed={() => setSelected(prev => !prev)}
        disabled={completed}
      />
    </div>
  )
}
