import React, { useEffect, useMemo, useRef, useState } from 'react'
import Input from "../UI/input/Input";
import { useSelector } from 'react-redux'
import spinner from "../../assests/images/spinner-gif.gif";
import Button from "../UI/button/Button";
import axios from '../../configAxios'
import { withRouter } from "react-router-dom"

function SelectGrantCampaign({ closeModal }) {

  const grantApplications = useSelector(state => state.grantApplications.grantApplicationsList)
  const grantApplicationsLoading = useSelector(state => state.grantApplications.loading || false)
  const grantCampaigns = useSelector(state => state.grantCampaigns)

  const [chosenCampaign, setChosenCampaign ] = useState(null)
  const [applicationName, setApplicationName] = useState("")
  const refChosenCampaign = useRef(chosenCampaign)
  const [campaignOptions, setCampaignOptions ] = useState([])

  useEffect(() => {
    setCampaignOptions(grantCampaignOptions())
  }, [grantApplications])

  const getSelectableCampaignOptions = () => {
    const selectableCampaigns = []
    const campaigns = grantCampaigns.entities

    grantCampaigns.ids.forEach((id) => {
      const applicationForCampaignExists = grantApplications.find(application => application.grant_campaign_id === id);
      const campaignAllowsMultipleApplications = campaigns[id].allow_multiple_applications;

      const allowCampaignToBeSelected = (campaign) => {
        selectableCampaigns.push(campaign)
      }

      if (applicationForCampaignExists) {
        if (campaignAllowsMultipleApplications) {
          allowCampaignToBeSelected(campaigns[id]);
        }
      } else {
        allowCampaignToBeSelected(campaigns[id])
      }
    })

    return selectableCampaigns
  }

  const grantCampaignOptions = () => {
    return getSelectableCampaignOptions().map(campaign => ({ label: campaign.name, value: campaign }))
  }

  const GrantCampaignDetails = () => {
    return (
      <div className="w-100 d-flex flex-column just-content-between align-items-start">
        <p className="mb-4">{ refChosenCampaign.current.value.description }</p>
        <StartApplicationButton />
      </div>
    )
  }
  const createGrantApplicationAxios = () => {
    axios
      .post(
        "/grant_applications",
        {
          grant_campaign_id: chosenCampaign.value.id,
          name: applicationName,
          status: "Incomplete"
        })
      .then( () => {
        closeModal()
      })
  }

  const StartApplicationButton = () => {
    return (
      <div className="w-100 d-flex flex-row justify-content-between align-items-center border-bottom-1 border-secondary">
        <Button
            className="w-100"
            type="success"
            disabled={!refChosenCampaign.current}
            clicked={() => {
              createGrantApplicationAxios()
            }}
          >
            Start Application
          </Button>
      </div>
  )}

  const Spinner = () => {
    return (
      <img
        src={spinner}
        style={{ width: "200px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    )
  }

  const updateChosenCampaign = (newState) => {
    refChosenCampaign.current = newState
    setChosenCampaign(newState)
  }

  const GrantCampaignsList = () => {
    return (
      <>
        { grantCampaigns.ids.length > 0
           ?
            <div className="w-100 d-flex flex-column justify-content-between">
              <Input
                name="formInputs[type]"
                elementType="select"
                elementConfig={{
                  placeholder: "Select a campaign",
                  options: campaignOptions,
                  selectedOption: null
                }}
                changed={updateChosenCampaign}
                selectedOption={refChosenCampaign.current}
              />

              { chosenCampaign && (
                <>
                   <Input
                     key={chosenCampaign.id}
                     name="formInputs[type]"
                     elementType="input"
                     elementConfig={{
                       placeholder: "Give your application a name (optional)"
                     }}
                     value={applicationName}
                     changed={event =>
                       setApplicationName(event.target.value)
                    }
                   />

                  <div className="d-flex flex-column align-items-start justify-content-between">
                    <GrantCampaignDetails />
                  </div>
                </>
              )}
            </div>
          :
          <p>No grants are available at this time</p>
        }
      </>
    )
  }

  const grantCampaignList = useMemo(() => GrantCampaignsList(), [campaignOptions, chosenCampaign, applicationName])

  return (
    <div className="w-100 d-flex flex-row justify-content-center mt-3">
      { grantApplicationsLoading
        ?
        <Spinner />
        :
        grantCampaignList
      }
    </div>
  )
}

export default withRouter(SelectGrantCampaign)
