import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";
import queryString from "query-string";
import Details from "./Details";
import Attributes from "./Attributes";
import "./index.css";
import { connect } from "react-redux";
import { getForm, selectFormById } from "../../../store/slices/form";

class Tabs extends Component {
  defaultTab = "Form";
  state = {
    tabs: ["Form", "Attributes"],
    activeTab: this.defaultTab,
    query: null,
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.toptab !== prevProps.match.params.toptab ||
      this.props.location.search !== prevProps.location.search
    ) {
      if (this.props.match.params.toptab) {
        let query;
        if (this.props.location.search !== "") {
          const values = queryString.parse(this.props.location.search);
          query = values.value_3;
        } else {
          query = null;
        }
        this.tabChangeHandler(title(this.props.match.params.toptab));
        this.setState({ query });
      }
    }
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : this.defaultTab,
    });
  };

  tabChangeHandler = (activeTab) => {
    this.setState({ activeTab });
    this.props.history.push(
      `/forms/${this.props.form.id}/${snakeCase(activeTab)}`
    );
  };

  render() {
    const { tabs: tabNames } = this.state;
    const { form } = this.props;
    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${
          this.state.activeTab === tab ? "active-tab" : "inactive-tab "
        }`}
      >
        {tab}
      </li>
    ));
    let activeTab;
    switch (this.state.activeTab) {
      case "Form":
        activeTab = <Details {...form} />;
        break;
      case "Attributes":
        activeTab = <Attributes form={form} />;
        break;
      default:
        activeTab = null;
    }
    return (
      <div className="card">
        <ul className="nav nav-tabs ml-0">{tabs}</ul>
        {activeTab}
      </div>
    );
  }
}

Tabs.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const currentItemId = ownProps.match.params.id;
  const form = selectFormById(state, currentItemId) || {};

  return {
    form,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForm: (id) => dispatch(getForm(id)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tabs));
