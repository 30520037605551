import React, { Component } from "react";
import { toast } from "react-toastify";
import axios from "../../../configAxios";
import Button from "../../UI/button/Button";
import { title } from "change-case";
import FormButton from "../../UI/button/FormButton";

class EditPhoto extends Component {
  state = {
    photo: "",
    updateBtn: true,
    removeBtn: true,
  };

  handleChange = (selectorFile: File) => {
    this.setState({ photo: selectorFile });
  };

  deletePhotoHandler = () => {
    let response = window.confirm("Are you sure you want to delete the photo?");
    if (response) {
      let id = this.props.assetId;
      toast.success("Removing");
      this.setState({ ...this.state, updateBtn: false, removeBtn: false })
      axios
        .post("/assets/destroy_photo", { id })
        .then(res => {
          if (res.data.json.success) {
            this.props.updatePhoto();
            toast.success("Asset Photo removed successfully");
            this.props.closeModal();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  };

  submitHandler = event => {
    event.preventDefault();
    toast.success("Updating");
    this.setState({ ...this.state, updateBtn: false, removeBtn: false })
    const assetData = new FormData();
    assetData.append(["id"], this.props.assetId);
    assetData.append(["photo"], this.state.photo[0]);
    axios
      .post("/assets/change_photo", assetData)
      .then(res => {
        if (res.data.json.success === true) {
          this.props.updatePhoto();
          toast.success("Asset photo updated successfully.");
          this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ ...this.state, updateBtn: true, removeBtn: true })
        }
      })
      .catch(error => this.setState({ ...this.state, updateBtn: true, removeBtn: true }))
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <input
          type="file"
          style={{
            height: 34,
            width: "100%",
            border: "1px solid #999999",
            padding: 2
          }}
          onChange={e => this.handleChange(e.target.files)}
        />
        <div className="row mt-10 justify-content-center">
          <FormButton 
            disabled={(this.state.photo === "" || !this.state.updateBtn) ? true : false}
            style={{cursor: (this.state.photo === "" || !this.state.updateBtn) ? "no-drop" : "pointer"}}
          >
            Update
          </FormButton>
          <Button
            type="danger"
            className="ml-2"
            clicked={this.deletePhotoHandler}
            disabled={!this.state.removeBtn}
          >
            Remove Photo
          </Button>
          <Button
            type="secondary"
            className="ml-2"
            clicked={this.props.closeModal}
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}
export default EditPhoto;
