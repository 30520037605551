import React, { Component } from 'react'
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment-timezone";
import { withRouter } from "react-router-dom"
import _ from "lodash"

import Toolbar from "./ToolbarContainer";
import AgendaEvent from "./AgendaEvent";

import "./index.scss"

class WeeklyCalendar extends Component {
  state = {
    events: [],
    formats: {
      dayFormat: (date, culture, localizer) => { return localizer.format(date, 'ddd - YYYY.MM.DD', culture) },
      eventTimeRangeStartFormat: (date, culture, localizer) => `${localizer.format(date.start, "hh:mm a", culture)} - ${localizer.format(date.end, "hh:mm a", culture)}`,
      eventTimeRangeEndFormat: (date, culture, localizer) => `${localizer.format(date.start, "hh:mm a", culture)} - ${localizer.format(date.end, "hh:mm a", culture)}`,
      dayRangeHeaderFormat: (date, culture, localizer) => localizer.format(date.start, "YYYY.MM.DD", culture) + " - " + localizer.format(date.end, "YYYY.MM.DD", culture),
      dayHeaderFormat: (date, culture, localizer) => localizer.format(date, "dddd YYYY.MM.DD", culture),
      agendaHeaderFormat: (date, culture, localizer) => `${localizer.format(date.start, "YYYY.MM.DD", culture)} - ${localizer.format(date.end, "YYYY.MM.DD", culture)}`,
      agendaDateFormat: (date, culture, localizer) => localizer.format(date, "YYYY.MM.DD", culture)
    },
    date: this.props.date,
    localizer: momentLocalizer(moment)
  }

  componentDidMount() {
    if (this.props.eventData && !_.isEmpty(this.props.eventData)) {
      let eventData = this.props.eventData.map((outDate) => {
        const { name, start, end } = outDate;
        let details = {};
        details["title"] = `${name} - ${outDate.userOrProgramName}`;
        details["start"] = new Date(start);
        details["end"] = new Date(end);
        details["allDay"] = false;
        if (this.props.private) {
          details["userOrProgram"] = outDate.userOrProgram;
          details["userOrProgramId"] = outDate.userOrProgramId;
          details["userOrProgramName"] = outDate.userOrProgramName;
        }
        return details;
      })
      this.setState({ events: eventData });
    }
  }

  dateChangeHandler = (date) => {
    this.setState({ date }, () => this.props.dateChangeHook(date));
  }

  onSelectHandler = (event, syntheticEvent) => {
    if (this.props.private) {
      const { userOrProgram, userOrProgramId } = event;
      if (userOrProgram === "User")
        this.props.history.push(`/users/${userOrProgramId}/summary`);
      else
        this.props.history.push(`/programs/${userOrProgramId}`);
    }
  }

  render() {
    return (
      <>
        <Calendar
          localizer={this.state.localizer}
          view={this.props.view}
          defaultView={this.props.view}
          views={[this.props.view]}
          onView={() => { return; }}
          events={this.state.events}
          showMultiDayTimes={true}
          startAccessor="start"
          endAccessor="end"
          date={this.state.date}
          step={30}
          min={new Date(2020, 1, 1, 6, 0, 0, 0)}
          onSelectEvent={this.props.private ? this.onSelectHandler : null}
          components={{
            toolbar: Toolbar({
              dateChangeHandler: this.dateChangeHandler,
              date: this.state.date,
              view: this.props.view
            }),
            agenda: {
              event: AgendaEvent
            }
          }}
          onNavigate={(date) => this.setState({ date })}
          formats={this.state.formats}
          messages={{
            noEventsInRange: (<div className="p-2 text-center">No events found</div>)
          }}
        />
      </>
    )
  }
}

export default withRouter(WeeklyCalendar);
