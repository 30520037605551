import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import axios from "../../../configAxios";

import CardHeader from "../CardHeader";
import Project from "./Project";

import Spinner from "../../../assests/images/spinner.gif";
import "../index.scss";

class Projects extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    projects: []
  };

  componentDidMount() {
    const params = {
      order: {
        column: "created_at",
        dir: "desc"
      },
      per_page: 5
    };
    axios.get("/projects", { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({ 
          projects: 
            Array.isArray(res.data.projects)
              ? res.data.projects
              : [], 
          loading: false 
        });
        this.props.updateGrid();
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  render() {
    const spinner = (
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    );
    return (
      <div className="dashboard">
        <div className="card">
          {this.state.loading ? (
            <div className="card-body p-0">
              <div className="card-text">
                <div className="spinner">{spinner}</div>
              </div>
            </div>
          ) : (
              <div>
                <CardHeader
                  title="New Projects"
                  icon="fa-folder-open"
                  collapse="projects"
                  updateGrid={this.props.updateGrid}
                  removeModule={this.props.removeModule}
                  name={this.props.name} />
                <div className="dashboard-card collapse show" id="projects">
                  <div className="card-body p-0">
                    <ul className="list-group">
                      {this.state.projects.map(project => (
                        <Project key={project.id} project={project} />
                      ))}
                    </ul>
                    <div className="p-2 text-center cursor card-text" style={{ height: "40px" }}>
                      <Link to="/projects" className="text-info">View All</Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(Projects);
