import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";

import FormButton from "../../UI/button/FormButton";
import Input from "../../UI/input/Input";
import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";
import ToggleSwitch from "../../common/toggleSwitch/ToggleSwitch"
import onError from "../../../utils/onError";

import './Invoice.css';

class PackingList extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    orgnId: null,
    helcimAccountId: {
      elementType: "input",
      elementConfig: {
        name: "helcimAccountId",
        type: "text",
        autoComplete: "current-password"
      },
      value: "",
    },
    helcimApiKey: {
      elementType: "input",
      elementConfig: {
        name: "helcimApiKey",
        type: "password",
        autoComplete: "current-password"
      },
      value: ""
    },
    helcimToken: {
      elementType: "input",
      elementConfig: {
        name: "helcimToken",
        type: "text",
      },
      value: ""
    },
    disabled: false,
    hideKey: true,
    enableOnlinePayment: false,
    allowUserToModifyPaymentAmount: false,
  }

  componentDidMount() {
    axios.get("/organizations/formdata", { cancelToken: this.signal.token })
      .then(res => {
        const orgnId = res.data.json.current_organization.id;
        this.setState({
          orgnId: orgnId,
        }, () => {
          axios.get(`/organizations/${this.state.orgnId}`, { cancelToken: this.signal.token })
            .then(res => {
              const { organization } = res.data;
              this.setState({
                loading: false,
                enableOnlinePayment:
                  res.data.organization.enable_online_payment,
                helcimAccountId: {
                  ...this.state.helcimAccountId,
                  value: organization.helcim_account_id
                },
                helcimApiKey: {
                  ...this.state.helcimApiKey,
                  value: organization.helcim_api_key
                },
                helcimToken: {
                  ...this.state.helcimToken,
                  value: organization.helcim_token
                },
                allowUserToModifyPaymentAmount:
                  res.data.organization.allow_users_to_modify_payment_amount,
              })
            })
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  inputChangedHandler = (event, key, type) => {
    let inputValue = {};
    if (type === "text") {
      inputValue["value"] = event.target.value;
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  enableOnlinePaymentChangeHandler = (toggle) => {
    const data = {
      id: localStorage.orgnId,
      enable_online_payment: toggle,
    }
    axios.put(`/organizations/${this.state.orgnId}`, data, { cancelToken: this.signal.token })
      .then((res) => {
        toast.success("Invoice Settings updated successfully!")
        this.setState({
          enableOnlinePayment: res.data.json.data.enable_online_payment
        })
      })
      .catch(err => {
        onError(err)
      })
  }

  allowUserToModifyPaymentAmountChangeHandler = (toggle) => {
    axios.put(`/organizations/${localStorage.organizationId}/modify_payment_amount_setting`,
      {
        id: localStorage.organizationId,
        allow_users_to_modify_payment_amount: toggle,
      },
      { cancelToken: this.signal.token }
    )
      .then((res) => {
        const { allow_users_to_modify_payment_amount: allowModifyPaymentAmount } = res.data.json.data

        toast.success("Invoice Settings updated successfully!")
        this.setState({ allowUserToModifyPaymentAmount: allowModifyPaymentAmount })
      })
      .catch(err => {
        onError(err)
      })
  }

  hideKeyHandler = () => {
    const { type: inputType } = this.state.helcimApiKey.elementConfig;
    this.setState({
      helcimApiKey : {
        ...this.state.helcimApiKey,
        elementConfig: {
          ...this.state.helcimApiKey.elementConfig,
          type: inputType !== "password"
            ? "password"
            : "text"
        }
      },
      hideKey: inputType !== "password"
    })
  }

  submitHandler = event => {
    event.preventDefault();
    this.setState({disabled: true})
    let attrData = {};
    const { helcimAccountId, helcimApiKey, helcimToken } = this.state;
    attrData["helcim_account_id"] = helcimAccountId.value;
    attrData["helcim_api_key"] = helcimApiKey.value;
    attrData["helcim_token"] = helcimToken.value;
    axios
      .put(`/organizations/${this.state.orgnId}`, attrData)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Helcim details successfully updated");
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
        this.setState({disabled: false})
      })
  };

  render() {
    const { disabled, allowUserToModifyPaymentAmount, enableOnlinePayment } = this.state

    return (
      this.state.loading ? (
        <div className="card-text">
          <div className="row">
            <div className="col-12">
              <Spinner className="h-60vh" />
            </div>
          </div>
        </div>
      ) : (
          <div className="px-4">
            <form autoComplete="new-password" onSubmit={this.submitHandler} className="mt-4">
              <div className="row mb-3">
                <div className="col-lg-8">
                  <label className="my-2">
                    Active
                  </label>
                </div>
                <div className="col-lg-8">
                  <ToggleSwitch
                    checked={enableOnlinePayment}
                    onChange={this.enableOnlinePaymentChangeHandler}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <p>Helcim Account Id</p>
                </div>
                <div className="col-lg-7">
                  <Input
                    {...this.state.helcimAccountId}
                    changed={event => this.inputChangedHandler(event, "helcimAccountId", "text")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <p>Helcim Api Key</p>
                </div>
                <div className="col-lg-7">
                  <Input
                    {...this.state.helcimApiKey}
                    changed={event => this.inputChangedHandler(event, "helcimApiKey", "text")}
                  />
                  <i
                    className={
                      `fas fa-eye float-right b-40 r-10 position-relative cursor ${this.state.hideKey ? "d-block" : "d-none"}`
                    }
                    onClick={this.hideKeyHandler}
                  />
                  <i
                    className={
                      `fas fa-eye-slash float-right b-40 r-10 position-relative cursor ${this.state.hideKey ? "d-none" : "d-block"}`
                    }
                    onClick={this.hideKeyHandler}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <p>Helcim Token</p>
                </div>
                <div className="col-lg-7">
                  <Input
                    {...this.state.helcimToken}
                    changed={event => this.inputChangedHandler(event, "helcimToken", "text")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <label className="ml-2 my-2">
                    Allow users to modify the payment amount?
                  </label>
                </div>
                <div className="col-lg-8">
                  <ToggleSwitch
                    checked={allowUserToModifyPaymentAmount}
                    onChange={this.allowUserToModifyPaymentAmountChangeHandler}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-center pb-5">
                  <FormButton disabled={this.state.disabled}>Save</FormButton>
                </div>
              </div>
            </form>
          </div>
        )
    );
  }
}
export default PackingList;
