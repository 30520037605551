import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { title, snakeCase } from 'change-case'

import MailingList from './mailingList'
import Campaigns from './campaigns'

class EmailCampaign extends Component {
  state = {
    activeTab:
      typeof this.props.match.params.toptab !== "undefined"
        ? title(this.props.match.params.toptab)
        : "Campaigns"
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : "Campaigns"
    });
  };

  tabChangeHandler = activeTab => {
    this.setState({ activeTab });
    this.props.history.push(
      `/system_settings/email_campaign/${snakeCase(activeTab)}`
    );
  };

  render() {
    const tabNames = [
      "Campaigns",
      "Mailing Lists"
    ];

    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${this.state.activeTab === tab ? "active-tab" : "inactive-tab"}`}
      >
        {tab}
      </li>
    ));

    let activeTab;
    switch (this.state.activeTab) {
      case "Mailing Lists":
        activeTab = <MailingList />;
        break;
      case "Campaigns":
        activeTab = <Campaigns />;
        break;
      default:
        activeTab = null;
    }

    return (
      <div className="card email-campaign">
        <ul className="nav nav-tabs font-weight-bold ml-0">
          {tabs}
        </ul>
        {activeTab}
      </div>
    );
  }
}

export default withRouter(EmailCampaign);