import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import dateFns from "date-fns";
import { title } from "change-case";
import Input from "../UI/input/Input";
import DatePicker from "../UI/datePicker/index";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import axios from "../../configAxios";
import { toast } from "react-toastify";
import "./NewOrEdit.css";
import Spinner from "../UI/Spinner";

class AddOrEditFilmOrAudio extends Component {
  signal = axios.CancelToken.source();
  state = {
    call: null,
    title: null,
    countryOfOrigin: null,
    city: null,
    dateReleased: null,
    dateAdded: null,
    isbn: null,
    featureType: null,
    bulkCount: null,
    cpu: null,
    creator: null,
    rating: null,
    lendingDueDate: null,
    bookmarkType: null,
    bookmark: null,
    publisher: null,
    pages: null,
    genre: null,
    starring: null,
    distributor: null,
    recordLabel: null,
    theatricalReleaseDate: null,
    runningTime: null,
    platform: null,
    myRating: null,
    audioType: null,
    barcode: null,
    description: null,
    contributersName: {
      elementType: "input",
      elementConfig: {
        name: "contributersName",
        type: "text",
        placeholder: "Contributor Name"
      }
    },
    subtitle: {
      elementType: "select",
      elementConfig: {
        name: "subtitle",
        options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
      },
      selectedOption: null,
      label: "Subtitle"
    },
    territoryOfOrigin: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "territoryOfOrigin",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Territory of origin"
    },
    originalLanguage: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "originalLanguage",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    condition: {
      elementType: "select",
      elementConfig: {
        name: "condition",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    flags: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "flags",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Flags"
    },
    tags: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "tags",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Tags"
    },
    artwork: null,
    firstNation: {
      elementType: "creatableSelect",
      elementConfig: {
        name: "firstNation",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "First Nation"
    },
    QASMember: {
      elementType: "select",
      elementConfig: {
        name: "QASMember",
        options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    reference: {
      elementType: "select",
      elementConfig: {
        name: "reference",
        options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    matureContent: {
      elementType: "select",
      elementConfig: {
        name: "matureContent",
        options: [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    contributersType: {
      elementType: "select",
      elementConfig: {
        name: "contributersType",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      label: "Contributers"
    },
    contributers: [{ contributerType: null, name: "", id: "" }],
    loading: true,
    disabled: false
  };

  async componentDidMount() {
    const res = await axios.get(`/assets/formdata?type_used=${title(this.props.type)}`, { cancelToken: this.signal.token });
    const territoryOrigins = res.data.json.territory_origin;
    const originalLanguages = res.data.json.original_language;
    const conditions = res.data.json.conditions;
    const flags = res.data.json.library_flags;
    const tags = res.data.json.library_tags;
    const firstNations = res.data.json.first_nation;
    const contributer_types = res.data.json.contributer_types;
    this.setState({
      territoryOfOrigin: {
        ...this.state.territoryOfOrigin,
        elementConfig: {
          ...this.state.territoryOfOrigin.elementConfig,
          options: territoryOrigins.map(origin => {
            return { label: origin, value: origin };
          })
        }
      },
      originalLanguage: {
        ...this.state.originalLanguage,
        elementConfig: {
          ...this.state.originalLanguage.elementConfig,
          options: originalLanguages.map(language => {
            return { label: language, value: language };
          })
        }
      },
      condition: {
        ...this.state.condition,
        elementConfig: {
          ...this.state.condition.elementConfig,
          options: conditions.map(condition => {
            return { label: condition, value: condition };
          })
        }
      },
      flags: {
        ...this.state.flags,
        elementConfig: {
          ...this.state.flags.elementConfig,
          options: flags.map(flag => {
            return { label: flag, value: flag };
          })
        }
      },
      tags: {
        ...this.state.tags,
        elementConfig: {
          ...this.state.tags.elementConfig,
          options: tags.map(tag => {
            return { label: tag, value: tag };
          })
        }
      },
      firstNation: {
        ...this.state.firstNation,
        elementConfig: {
          ...this.state.firstNation.elementConfig,
          options: firstNations.map(nation => {
            return { label: nation, value: nation };
          })
        }
      },
      contributersType: {
        ...this.state.contributersType,
        elementConfig: {
          ...this.state.contributersType.elementConfig,
          options: contributer_types.map(type => {
            return { label: type, value: type };
          })
        }
      },
      loading: this.props.location.pathname.includes("edit") ? true : false
    });

    if (this.props.location.pathname.includes("edit")) {
      const id = this.props.match.params.id;
      const res = await axios.get(`/assets/${id}`, { cancelToken: this.signal.token });
      const asset = res.data.asset_detail;
      let contributers = [{ contributerType: null, name: "", id: "" }];
      if (asset.asset_contributers.length) {
        contributers = asset.asset_contributers.map(contributer => {
          return {
            contributerType: {
              label: contributer.contributer_type,
              value: contributer.contributer_type
            },
            name: contributer.name,
            id: contributer.id
          };
        });
      }

      this.setState({
        call: asset.parameters.call_number,
        title: asset.name,
        subtitle: {
          ...this.state.subtitle,
          selectedOption: asset.parameters.subtitle && {
            label: asset.parameters.subtitle,
            value: asset.parameters.subtitle
          }
        },
        countryOfOrigin: asset.parameters.country_origin,
        city: asset.parameters.city_published,
        dateReleased: asset.parameters.date_released ?
         new Date(asset.parameters.date_released) : asset.parameters.date_released,
        dateAdded: asset.parameters.date_added ?
         new Date(asset.parameters.date_added) : asset.parameters.date_added,
        isbn: asset.parameters.isbn,
        territoryOfOrigin: {
          ...this.state.territoryOfOrigin,
          selectedOption:
            asset.parameters.territory_origin &&
            asset.parameters.territory_origin.map(origin => {
              return {
                label: origin,
                value: origin
              };
            })
        },
        originalLanguage: {
          ...this.state.originalLanguage,
          selectedOption:
            asset.parameters.original_language &&
            asset.parameters.original_language.map(language => {
              return { label: language, value: language };
            })
        },
        condition: {
          ...this.state.condition,
          selectedOption: {
            label: asset.parameters.condition,
            value: asset.parameters.condition
          }
        },
        featureType: asset.parameters.feature_type,
        flags: {
          ...this.state.flags,
          selectedOption:
            asset.parameters.library_flags &&
            asset.parameters.library_flags.map(flag => {
              return { label: flag, value: flag };
            })
        },
        tags: {
          ...this.state.tags,
          selectedOption:
            asset.parameters.tags &&
            asset.parameters.tags.map(tag => {
              return { label: tag, value: tag };
            })
        },
        bulkCount: asset.bulk_asset.bulk_count_total,
        firstNation: {
          ...this.state.firstNation,
          selectedOption:
            asset.parameters.first_nation &&
            asset.parameters.first_nation.map(nation => {
              return {
                label: nation,
                value: nation
              };
            })
        },
        cpu: asset.parameters.cpu,
        creator: asset.parameters.creator,
        rating: asset.parameters.rating,
        lendingDueDate: asset.parameters.lending_due_date ? 
          new Date(asset.parameters.lending_due_date) : asset.parameters.lending_due_date,
        bookmarkType: asset.parameters.bookmark_type,
        bookmark: asset.parameters.bookmark,
        publisher: asset.parameters.publisher,
        pages: asset.parameters.pages,
        genre: asset.parameters.genre,
        starring: asset.parameters.starring,
        distributor: asset.parameters.distributor,
        recordLabel: asset.parameters.record_label,
        theatricalReleaseDate: asset.parameters.theatrical_release_date ?
          new Date(asset.parameters.theatrical_release_date) : asset.parameters.theatrical_release_date,
        runningTime: asset.parameters.running_time,
        platform: asset.parameters.platform,
        myRating: asset.parameters.my_rating,
        audioType: asset.parameters.audio_type,
        barcode: asset.barcode,
        description: asset.description,
        QASMember: {
          ...this.state.QASMember,
          selectedOption: asset.parameters.qas_member && {
            label: asset.parameters.qas_member,
            value: asset.parameters.qas_member
          }
        },
        reference: {
          ...this.state.reference,
          selectedOption: {
            label: asset.parameters.reference,
            value: asset.parameters.reference
          }
        },
        matureContent: {
          ...this.state.matureContent,
          selectedOption: {
            label: asset.parameters.mature_content,
            value: asset.parameters.mature_content
          }
        },
        contributers,
        loading: false
      });
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  addContributerFields = () => {
    this.setState(prevState => ({
      contributers: [
        ...prevState.contributers,
        { contributerType: null, name: "" }
      ]
    }));
  };

  deleteContributerFields = (index, id) => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      const { contributers } = { ...this.state };
      if (this.props.location.pathname.includes("edit")) {
        if (id) {
          axios
            .delete("/asset_contributers", {
              params: {
                id: id
              }
            })
            .then(res => {
              if (res.data.json.success) {
                toast.success("Contributer deleted successfully.");
              } else {
                if (res.data.json.hasOwnProperty("errors")) {
                  Object.keys(res.data.json.errors).forEach(error => {
                    toast.error(title(error) + " " + res.data.json.errors[error]);
                  });
                }
                if (res.data.json.hasOwnProperty("error")) {
                  toast.error(res.data.json.error);
                }
              }
            });
        }
      }
      contributers.splice(index, 1);
      this.setState({ contributers });
    }
  };

  changeHandler = (event, i, key) => {
    let inputValue = {};
    let { contributers } = this.state;
    if (key === "contributersType") {
      inputValue["selectedOption"] = event;
      contributers[i].contributerType = event;
    } else {
      inputValue["value"] = event.target.value;
      contributers[i].name = event.target.value;
    }
    const updatedControls = {
      ...this.state[key],
      ...inputValue
    };

    this.setState({ [key]: updatedControls, contributers });
  };

  inputChangeHandler = (event, key, type) => {
    if (type === "file") {
      this.setState({ artwork: event.target.files[0] });
    } else if (type === "select") {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event
        }
      });
    } else if (type === "creatableSelect") {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event
        }
      });
    }
  };

  renderTextbox = (label, name, placeholder) => {
    return (
      <>
        <p
          className={
            name === "title" ? "new-type-label required" : "new-type-label"
          }
        >
          <b>{label} </b>
        </p>
        <Input
          elementType={"input"}
          elementConfig={{
            required: name === "title" ? true : false,
            type:
              name === "bulkCount" || name === "pages" || name === "call"
                ? "number"
                : "text",
            placeholder: placeholder
          }}
          value={this.state[name]}
          changed={event => this.setState({ [name]: event.target.value })}
        />
      </>
    );
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let formData = new FormData();
    const {
      contributers,
      territoryOfOrigin,
      originalLanguage,
      flags,
      tags,
      firstNation
    } = this.state;
    const lendingDueDate = this.state.lendingDueDate
      ? dateFns.format(new Date(this.state.lendingDueDate), "YYYY-MM-DD")
      : "";

    const dateReleased = this.state.dateReleased
      ? dateFns.format(new Date(this.state.dateReleased), "YYYY-MM-DD")
      : "";

    const dateAdded = this.state.dateAdded
      ? dateFns.format(new Date(this.state.dateAdded), "YYYY-MM-DD")
      : "";

    const theatricalReleaseDate = this.state.theatricalReleaseDate
      ? dateFns.format(new Date(this.state.theatricalReleaseDate), "YYYY-MM-DD")
      : "";
    const asset_contributers_attributes = contributers
      .map(contributer => {
        if (contributer.contributerType !== null && contributer.name !== "") {
          const contributerData = {
            contributer_type:
              (contributer.contributerType !== null ||
                contributer.contributerType !== "") &&
                contributer.name !== ""
                ? contributer.contributerType.label
                : "",
            name:
              contributer.contributerType === null ||
                contributer.contributerType === ""
                ? ""
                : contributer.name
          };
          if (contributer.id !== "") {
            contributerData["id"] = contributer.id;
          }
          return JSON.stringify(contributerData);
        } else {
          return null;
        }
      })
      .filter(item => item !== null);
    const selectedTerritories =
      territoryOfOrigin.selectedOption &&
      territoryOfOrigin.selectedOption.map(
        territoryOfOrigin => territoryOfOrigin.label
      );
    const selectedLanguages =
      originalLanguage.selectedOption &&
      originalLanguage.selectedOption.map(
        originalLanguage => originalLanguage.label
      );
    const selectedFlags =
      flags.selectedOption && flags.selectedOption.map(flag => flag.label);
    const selectedTags =
      tags.selectedOption && tags.selectedOption.map(tag => tag.label);
    const selectedNations =
      firstNation.selectedOption &&
      firstNation.selectedOption.map(firstNation => firstNation.label);
    formData.append(["status"], 0);
    formData.append(["name"], this.state.title);
    this.state.call && formData.append(["call_number"], this.state.call);
    this.state.subtitle.selectedOption &&
      formData.append(["subtitle"], this.state.subtitle.selectedOption.label);
    this.state.countryOfOrigin &&
      formData.append(["country_origin"], this.state.countryOfOrigin);
    this.state.city && formData.append(["city_published"], this.state.city);
    formData.append(["date_released"], dateReleased);
    formData.append(["date_added"], dateAdded);
    this.state.isbn && formData.append(["isbn"], this.state.isbn);
    selectedTerritories &&
      formData.append(
        ["territory_origin"],
        JSON.stringify(selectedTerritories)
      );
    selectedLanguages &&
      formData.append(["original_language"], JSON.stringify(selectedLanguages));
    this.state.condition.selectedOption &&
      formData.append(["condition"], this.state.condition.selectedOption.label);
    this.state.featureType &&
      formData.append(["feature_type"], this.state.featureType);
    selectedFlags &&
      formData.append(["library_flags"], JSON.stringify(selectedFlags));
    selectedTags && formData.append(["tags"], JSON.stringify(selectedTags));
    this.state.artwork && formData.append(["photo"], this.state.artwork);
    formData.append(
      ["bulk_count_total"],
      this.state.bulkCount ? parseInt(this.state.bulkCount) : ""
    );
    selectedNations &&
      formData.append(["first_nation"], JSON.stringify(selectedNations));
    this.state.cpu && formData.append(["cpu"], this.state.cpu);
    this.state.creator && formData.append(["creator"], this.state.creator);
    this.state.rating && formData.append(["rating"], this.state.rating);
    formData.append(["lending_due_date"], lendingDueDate);
    this.state.bookmarkType &&
      formData.append(["bookmark_type"], this.state.bookmarkType);
    this.state.bookmark && formData.append(["bookmark"], this.state.bookmark);
    this.state.publisher &&
      formData.append(["publisher"], this.state.publisher);
    this.state.pages && formData.append(["pages"], this.state.pages);
    this.state.genre && formData.append(["genre"], this.state.genre);
    this.state.starring && formData.append(["starring"], this.state.starring);
    this.state.distributor &&
      formData.append(["distributor"], this.state.distributor);
    this.state.recordLabel &&
      formData.append(["record_label"], this.state.recordLabel);
    formData.append(["theatrical_release_date"], theatricalReleaseDate);
    this.state.runningTime &&
      formData.append(["running_time"], this.state.runningTime);
    this.state.platform && formData.append(["platform"], this.state.platform);
    this.state.myRating && formData.append(["my_rating"], this.state.myRating);
    this.state.audioType &&
      formData.append(["audio_type"], this.state.audioType);
    this.state.barcode && formData.append(["barcode"], this.state.barcode);
    this.state.description &&
      formData.append(["description"], this.state.description);
    this.state.QASMember.selectedOption &&
      formData.append(
        ["qas_member"],
        this.state.QASMember.selectedOption.label
      );
    this.state.reference.selectedOption &&
      formData.append(["reference"], this.state.reference.selectedOption.label);
    this.state.matureContent.selectedOption &&
      formData.append(
        ["mature_content"],

        this.state.matureContent.selectedOption.label
      );
    asset_contributers_attributes.length &&
      formData.append(
        ["asset_contributers_attributes"],
        `[${asset_contributers_attributes}]`
      );

    if (this.props.type === "audio") {
      formData.append(["type_used"], "Audio");
    } else {
      formData.append(["type_used"], "Film");
    }

    if (this.props.location.pathname.includes("edit")) {
      axios.put(`/assets/${this.props.match.params.id}`, formData).then(res => {
        if (res.data.json.success) {
          toast.success(`${title(this.props.type)} updated successfully.`);
          this.props.history.push(`/assets/${this.props.match.params.id}`);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    } else {
      axios.post("/assets", formData).then(res => {
        if (res.data.json.success) {
          toast.success(`${title(this.props.type)} created successfully.`);
          this.props.history.push(`/libraries/all_media`);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    }
  };

  render() {
    return (
      <div className="card mt-4 mb-4 new-library-type">
        {this.state.loading ? (
          <Spinner />
        ) : (
            <form onSubmit={this.submitHandler}>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Call #", "call")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Title", "title")}
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.subtitle}
                    changed={event =>
                      this.inputChangeHandler(event, "subtitle", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Country of origin", "countryOfOrigin")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("City published", "city")}
                </div>
                <div className="col-md input-fields">
                  <p className="mb-2">
                    <b>Date released</b>
                  </p>
                  <DatePicker
                    selected={this.state.dateReleased}
                    changed={date => this.setState({ dateReleased: date })}
                  />
                </div>
                <div className="col-md input-fields">
                  <p className="mb-2">
                    <b>Date added</b>
                  </p>
                  <DatePicker
                    selected={this.state.dateAdded}
                    changed={date => this.setState({ dateAdded: date })}
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("ISBN", "isbn")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <Input
                    {...this.state.territoryOfOrigin}
                    changed={event =>
                      this.inputChangeHandler(
                        event,
                        "territoryOfOrigin",
                        "creatableSelect"
                      )
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <label className="required">Original Language </label>
                  <Input
                    {...this.state.originalLanguage}
                    changed={event =>
                      this.inputChangeHandler(
                        event,
                        "originalLanguage",
                        "creatableSelect"
                      )
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <label className="required">Condition </label>
                  <Input
                    {...this.state.condition}
                    changed={event =>
                      this.inputChangeHandler(event, "condition", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Feature type", "featureType")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <Input
                    {...this.state.flags}
                    changed={event =>
                      this.inputChangeHandler(event, "flags", "creatableSelect")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.tags}
                    changed={event =>
                      this.inputChangeHandler(event, "tags", "creatableSelect")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <label>Upload a Thumbnail/Artwork</label>
                  <Input
                    elementType={"file"}
                    value={this.state.artwork}
                    changed={event =>
                      this.inputChangeHandler(event, "artwork", "file")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox(
                    "# of Copies (if more than one)",
                    "bulkCount"
                  )}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <Input
                    {...this.state.firstNation}
                    changed={event =>
                      this.inputChangeHandler(
                        event,
                        "firstNation",
                        "creatableSelect"
                      )
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("CPU", "cpu")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Creator", "creator")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Rating", "rating")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <p className="mb-2">
                    <b>Lending due date</b>
                  </p>
                  <DatePicker
                    selected={this.state.lendingDueDate}
                    changed={date =>
                      this.setState({
                        lendingDueDate: date
                      })
                    }
                    placeholder={"Select date"}
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Bookmark type", "bookmarkType")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Bookmark", "bookmark")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Publisher", "publisher")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Pages", "pages")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Genre", "genre")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Starring", "starring")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Distributor", "distributor")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Record label", "recordLabel")}
                </div>
                <div className="col-md input-fields">
                  <p className="mb-2">
                    <b>Theatrical release date</b>
                  </p>
                  <DatePicker
                    selected={this.state.theatricalReleaseDate}
                    changed={date =>
                      this.setState({
                        theatricalReleaseDate: date
                      })
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Running time", "runningTime")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Platform", "platform")}
                </div>
              </div>
              <div className="row m-0 mt-4">
                <div className="col-md-3 input-fields">
                  {this.renderTextbox("My rating", "myRating")}
                </div>
                <div className="col-md-3 input-fields">
                  {this.renderTextbox("Audio type", "audioType")}
                </div>
                <div className="col-md-3 input-fields">
                  {this.renderTextbox("Barcode", "barcode")}
                </div>
              </div>
              <div className="row m-0 mt-4">
                <div className="col-md">
                  <label>Description/Notes</label>
                  <Input
                    elementType={"textarea"}
                    elementConfig={{ rows: "4" }}
                    value={this.state.description}
                    changed={event =>
                      this.setState({ description: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row m-0 mt-4">
                <div className="col-md-3 input-fields">
                  <label>QAS Member? </label>
                  <Input
                    {...this.state.QASMember}
                    changed={event =>
                      this.inputChangeHandler(event, "QASMember", "select")
                    }
                  />
                </div>
                <div className="col-md-3 input-fields">
                  <label className="required">Reference? </label>
                  <Input
                    {...this.state.reference}
                    changed={event =>
                      this.inputChangeHandler(event, "reference", "select")
                    }
                  />
                </div>
                <div className="col-md-3 input-fields">
                  <label className="required">Mature Content? </label>
                  <Input
                    {...this.state.matureContent}
                    changed={event =>
                      this.inputChangeHandler(event, "matureContent", "select")
                    }
                  />
                </div>
              </div>
              <div className="contributers  mt-4" id="contributer">
                {this.state.contributers.map((val, index) => {
                  return (
                    <li className="row m-0" key={index}>
                      <div className="col-md-3 input-fields">
                        <Input
                          {...this.state.contributersType}
                          selectedOption={val.contributerType || null}
                          changed={event =>
                            this.changeHandler(event, index, "contributersType")
                          }
                        />
                      </div>
                      <div className="col-md-3 input-fields contributer-name">
                        <Input
                          {...this.state.contributersName}
                          value={val.name || ""}
                          changed={event =>
                            this.changeHandler(event, index, "contributersName")
                          }
                        />
                      </div>
                      <input type="hidden" name="contributerId" value={val.id} />
                      <i
                        className="fas fa-trash-alt library-delete"
                        onClick={() =>
                          this.deleteContributerFields(index, val.id)
                        }
                      />
                    </li>
                  );
                })}
              </div>
              <div className="row m-0 mt-2 ml-4 mb-3 add-button audio-video">
                <Button type="success" clicked={this.addContributerFields}>
                  Add
              </Button>
              </div>
              <div className="row m-0 mt-0 mb-5 justify-content-center">
                <Link
                  to={`/libraries/${this.props.type}?type=${this.props.type}`}
                >
                  <Button type="secondary" className="mr-3">
                    Cancel
                </Button>
                </Link>
                <FormButton className="submit" disabled={this.state.disabled}>Submit</FormButton>
              </div>
            </form>
          )}
      </div>
    );
  }
}

export default withRouter(AddOrEditFilmOrAudio);
