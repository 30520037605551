import React, { Component } from "react";
import { Link } from "react-router-dom";
import NoImage from "../../assests/images/no-image.png";
import Modal from "../UI/Modal";

class AssetItem extends Component {
  state = {
    showImageModal: false
  }

  getDescription(str) {
    const str_array = str.split(/\s+/g);
    if (str_array.length >= 100) {
      return str_array.slice(0, 100).join(" ") + "...";
    }
    else {
      return str_array.slice(0, 100).join(" ");
    }
  }

  render() {
    const { asset } = this.props;
    const image = asset.photo ? asset.photo : NoImage;

    const description = asset.description && this.getDescription(asset.description);

    const viewPhotoModal = (
      <Modal show={this.state.showImageModal} hide={() => { window.scrollTo(0, 0); this.setState({ showImageModal: false }) }}
        closeButton closeModal={() => this.setState({ showImageModal: false })} size='lg' >
        <div className="mt-4 text-center">
          <img className="mt-1 img-fluid" src={asset.photo_medium || NoImage} onError={e => {
            e.target.onerror = null;
            e.target.src = asset.photo ? asset.photo : NoImage;
          }} alt="asset" />
        </div>
      </Modal>
    )

    return (
      <div className="mt-4 mb-4 asset-item">
        <div className="row">
          <div className="col-lg-2 d-flex justify-content-center align-items-start" onClick={() => this.setState({ showImageModal: true })}>
            <div className="rounded-circle bg-cover" style={{
              width: 90,
              height: 90,
              backgroundImage: `url(${image})`
            }} />
          </div>
          <div className="col-lg-7">
            <Link
              to={`/assets/${asset.id}`}
              className="text-info font-weight-bold li-link decoration-none"
            >
              <span>{asset.name}</span>
            </Link>
            <div className="mt-2">
              <span>
                {asset.description && description}
              </span>
            </div>
            <div className="mt-2">
              <span
                className="status"
                style={{
                  backgroundColor:
                    asset.status_text === "Active" ? "#ececec" : "#337AB7",
                  color: asset.status_text !== "Active" && "white"
                }}
              >
                {asset.status_text}
              </span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="mt-2">Category : {asset.category_name}</div>
            {asset.price_types.map(type => (
              <div className="mt-2">{type[0].replace(/-([^-]*)$/, ": $1")}</div>
            ))}
          </div>
        </div>
        {viewPhotoModal}
      </div>
    );
  }
}

export default AssetItem;
