import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";

import Spinner from "../../UI/Spinner";
import Button from "../../UI/button/Button";
import Modal from "../../UI/Modal";
import axios from "../../../configAxios";
import langUtils from "../../../utils/langUtils";

import NewProjectCues from "./NewProjectCues";
import ProjectCueItem from "./ProjectCueItem";

class ProjectCues extends Component {
  signal = axios.CancelToken.source();
  state = {
    projectCues: [],
    edit: false,
    newModal: false,
    id: null,
    uiTypeIndex: null,
    loading: true,
    flag: true
  };

  componentDidMount() {
    this.getProjectCuesList();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getProjectCuesList = () => {
    axios
      .get("/project_attribute_types", {
        params: {
          category: "Cues"
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          projectCues: res.data.project_attribute_types,
          loading: false
        });
      });
  };

  editModal = id => {
    this.setState({ edit: true, id: id, newModal: true });
  };

  unarchiveCue = id => {
    this.setState({ id: id }, () => {
      let attrData = {};
      attrData["id"] = this.state.id;
      axios
        .put(`/project_attribute_types/unarchive_cue/`, attrData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Successfully unarchived");
            this.getProjectCuesList();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        });
    });
  };

  archiveCue = id => {
    let attrData = {};
    attrData["id"] = id;
    axios.put(`/project_attribute_types/archive_cue/`, attrData).then(res => {
      if (res.data.json.success) {
        toast.success("Successfully archived");
        this.getProjectCuesList();
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(error + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
      }
    });
  };

  deleteCueHandler = id => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      this.setState({ loading: true });
      axios.delete(`/project_attribute_types/${id}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Project submission successfully deleted.")
            this.getProjectCuesList();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  render() {
    const projectCues = this.state.projectCues;
    let projectCuesList;
    if (this.state.loading) {
      projectCuesList = <Spinner />;
    } else {
      const sortedProjectCues = projectCues.sort((cue1, cue2) => (cue1.name > cue2.name) ? 1 : -1)
      projectCuesList = sortedProjectCues.map(item => (
        <li className="list-group-item rounded-0 border-top projectAttr" key={item.id}>
          <ProjectCueItem cue={item}  
            getProjectCuesList={this.getProjectCuesList} 
            editModal={this.editModal} 
            deleteCueHandler={this.deleteCueHandler} />
        </li>
      ));
    }
    return (
      <div>
        <div className="p-3">
          <div className="row">
            <div className="col-lg-12 text-right">
              <Button
                type="success"
                clicked={() => { window.scrollTo(0, 0); this.setState({ newModal: true, edit: false }) }}
              >
                {langUtils("txt_global_add_new", "Add New")}
            </Button>
            </div>
          </div>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="row">
              <div className="col-lg-7">
                <span className="font-weight-bold">Submissions Name</span>
              </div>

              <div className="col-lg-3">
                <span className="font-weight-bold">Visible / Hidden</span>
              </div>

              <div className="col-lg-2" />
            </div>
          </li>
          {projectCuesList}
        </ul>

        <Modal
          show={this.state.newModal}
          flag={this.state.flag}
          title={this.state.edit ? "Edit Project Submission" : "New Project Submission"}
          hide={() => this.setState({ newModal: false })}
        >
          <NewProjectCues
            closeModal={() => this.setState({ newModal: false })}
            getProjectCuesList={this.getProjectCuesList}
            edit={this.state.edit}
            id={this.state.id}
          />
        </Modal>
      </div>
    );
  }
}

export default ProjectCues;
