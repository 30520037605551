import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { title } from "change-case"
import { toast } from "react-toastify"
import { last } from "lodash"

import axios from "../../../../configAxios"
import Button from "../../../UI/button/Button"
import Modal from "../../../UI/Modal"
import Spinner from "../../../UI/Spinner"
import langUtils from "../../../../utils/langUtils"

import MailingListForm from "./MailingListForm"
import MailListCSV from "./MailListCSV"

class MailingList extends Component {
  signal = axios.CancelToken.source()
  state = {
    mailingLists: [],
    showModal: false,
    edit: false,
    mailingListId: null,
    loading: true,
  }

  componentDidMount() {
    axios
      .get("/mailing_lists", { cancelToken: this.signal.token })
      .then(({ data: { mailing_lists } }) => {
        this.setState({
          ...this.state,
          mailingLists: mailing_lists.map(list => (
            { ...list, tags: list.tags.map(tag => last(tag.split('user:'))) }
          )),
          loading: false,
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  deleteMailingList = (event, mailingListId) => {
    let response = window.confirm(
      "Are you sure you want to delete the Mailing List?"
    )
    if (response) {
      axios.delete(`/mailing_lists/${mailingListId}`).then((res) => {
        if (res.data.json.success) {
          toast.success("Mailing List deleted successfully")
          this.setState({
            ...this.state,
            mailingLists: this.state.mailingLists.filter(
              (mailingList) => mailingList.id !== mailingListId
            ),
          })
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
    }
  }

  updateData = (data) => {
    const formattedData = {...data, tags: data.tags.map(tag => last(tag.split('user:')))}

    if (this.state.edit) {
      this.setState({
        ...this.state,
        mailingLists: this.state.mailingLists.map(list =>
          list.id === data.id ? formattedData : list
        ),
        edit: false,
      })
    } else {
      this.setState({
        ...this.state,
        mailingLists: [formattedData, ...this.state.mailingLists],
      })
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner />
    } else {
      return (
        <div>
          <div className="p-4">
            <div className="row">
              <div className="col-lg-6">
                <h5 className="font-weight-bold">Mailing List</h5>
              </div>
              <div className="col-lg-6 text-lg-right text-center">
                <Button
                  type="primary"
                  clicked={() => {
                    window.scrollTo(0, 0)
                    this.setState({
                      ...this.state,
                      showModal: true,
                      edit: false,
                    })
                  }}
                >
                  {langUtils("txt_global_add_new", "Add New")}
                </Button>
              </div>
            </div>
          </div>

          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="row">
                <div className="col-lg-5">
                  <span className="font-weight-bold">Name</span>
                </div>
                <div className="col-lg-5">
                  <span className="font-weight-bold">Labels</span>
                </div>
              </div>
            </div>
            {this.state.mailingLists.length === 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="text-center p-5 m-5">
                    <h5>No Mailing List</h5>
                  </div>
                </div>
              </div>
            ) : (
              this.state.mailingLists.map((mailingList) => (
                <div className="list-group-item" key={mailingList.id}>
                  <div className="row">
                    <div className="col-lg-5">{mailingList.name}</div>
                    <div className="col-lg-5">
                      {mailingList.tags.join(", ")}
                    </div>
                    <div className="col-lg-2 d-flex justify-content-around align-items-center">
                      <i
                        className="fas fa-pencil-alt cursor"
                        onClick={() => {
                          window.scrollTo(0, 0)
                          !mailingList.immutable &&
                            this.setState({
                              ...this.state,
                              showModal: true,
                              edit: true,
                              mailingListId: mailingList.id,
                            })
                        }}
                        style={{
                          cursor:
                            mailingList.immutable ||
                            mailingList.is_admin_and_staff
                              ? "no-drop"
                              : "pointer",
                          opacity:
                            mailingList.immutable ||
                            mailingList.is_admin_and_staff
                              ? 0.7
                              : 1,
                        }}
                      />
                      <MailListCSV mailListId={mailingList.id} />
                      <i
                        className="fas fa-trash cursor"
                        style={{
                          cursor:
                            mailingList.immutable ||
                            mailingList.is_admin_and_staff
                              ? "no-drop"
                              : "pointer",
                          opacity:
                            mailingList.immutable ||
                            mailingList.is_admin_and_staff
                              ? 0.7
                              : 1,
                        }}
                        onClick={(event) =>
                          !mailingList.immutable &&
                          this.deleteMailingList(event, mailingList.id)
                        }
                      />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <Modal
            show={this.state.showModal}
            title={this.state.edit ? "Update Mailing List" : "New Mailing List"}
            hide={() => this.setState({ showModal: false })}
          >
            <MailingListForm
              mailingListId={this.state.mailingListId}
              edit={this.state.edit}
              closeModal={() => this.setState({ showModal: false })}
              updateData={this.updateData}
            />
          </Modal>
        </div>
      )
    }
  }
}

export default withRouter(MailingList)
