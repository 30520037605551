import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import { toast } from "react-toastify"
import { formatDate } from "../../../utils/formatDate"
import { title } from "change-case"
import Button from "../../UI/button/Button"
import Input from "../../UI/input/Input"
import axios from "../../../configAxios"
import Modal from "../../UI/Modal"
import BookedAsset from "../../users/booking/BookedAsset"
import EditChargedLoan from "./EditChargedLoan"

import "./ReservedAssets.css"

class ReservedAssets extends Component {
  signal = axios.CancelToken.source();
  state = {
    ticketNotes: {
      elementType: "textarea",
      elementConfig: {
        type: "textarea",
        rows: 3,
        name: "ticketNotes"
      },
      value: this.props.ticket.notes,
      label: "Ticket Notes"
    },
    selectedAssets: [],
    showChargedLoanModal: false,
    memberTypes: [],
    showEmailModal: false,
    policyRes: {},
    bookingId: null,
    disabled: false
  };

  componentDidMount() {
    axios
      .get("/bookings/formdata", { cancelToken: this.signal.token })
      .then(res => {
        const memberTypes = res.data.json.asset_price_types;
        this.setState({ memberTypes });
      });
    axios
      .get("/tickets/policy", {
        params: { id: this.props.ticket.id },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({ policyRes: res.data.json })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  selectAssetHandler = select => {
    const bookedAssets = this.props.ticket.bookings;
    let selectedAssets = [];
    if (select) {
      const selectedBookings = [];
      bookedAssets && bookedAssets.forEach(booking => {
        selectedBookings.push(booking.booking_ids);
        selectedAssets = [].concat(...selectedBookings)
      });
    }
    this.setState({ selectedAssets });
  };

  updateTicketHandler = sendEmail => {
    this.setState({ disabled: true })
    const { ticketNotes } = this.state;
    this.setState({ showEmailModal: false });
    let formData = {
      send_email: sendEmail
    };
    if (ticketNotes.value) formData["notes"] = ticketNotes.value;
    axios
      .put(`/tickets/${this.props.ticket.id}`, formData)
      .then(res => {
        if (res.data.json.success) {
          this.setState({
            projectName: {
              ...this.state.projectName,
              value: ""
            },
            ticketNotes: {
              ...this.state.ticketNotes,
              value: ""
            },
            programRental: {
              ...this.state.programRental,
              selectedOption: null
            }
          });
          toast.success("Booking successfully updated");
          this.props.getReservedAssets(false);
          this.props.history.push(`/programs/${this.props.programId}`)
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      })
      .catch(err => toast.error("Failed to update"));
  };

  deleteTicketHandler = () => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      axios
        .delete(`/tickets/${this.props.ticket.id}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Ticket destroyed successfully.");
            this.props.history.push(`/programs/${this.props.programId}`);
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
        .catch(err => console.log(err));
    }
  };

  destroyBookings = () => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      const { selectedAssets } = this.state;
      axios
        .delete("/tickets/destroy_bookings_for", {
          data: {
            id: this.props.ticket.id,
            booking_ids: `[${selectedAssets}]`
          }
        })
        .then(res => {
          toast.success("Booking(s) removed successfully.");
          this.props.getReservedAssets(false);
        })
        .catch(err => console.log(err));
    }
  };

  checkedAssetHandler = (isChecked, assetId) => {
    const selectedAssets = [...this.state.selectedAssets];
    if (isChecked) {
      selectedAssets.push(assetId);
      const selectedBookings = [].concat(...selectedAssets);
      this.setState({ selectedAssets: selectedBookings });
    } else {
      const selectedBookings = selectedAssets.filter(el => !assetId.includes(el));
      this.setState({ selectedAssets: selectedBookings });
    }
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;

    const updatedNotes = { ...this.state[key], ...inputValue };

    this.setState({ [key]: updatedNotes });
  };

  render() {
    const { selectedAssets, policyRes } = this.state;
    const { created_at: createdAt, full_name_cached } = this.props.ticket;
    let reservedAssetContent;

    const emailModal = (
      <Modal
        show={this.state.showEmailModal}
        center
        hide={() => this.setState({ showEmailModal: false })}
        closeModal={() => this.setState({ showEmailModal: false })}
        closeButton
      >
        <div className="col-md-12">
          <div className="p-3">
            <p className="text-center font-weight-bold word-wrap-break">
              Would you like to send confirmation email to {full_name_cached}?
            </p>
            <div className="text-center mt-2">
              <Button
                type="success"
                className="mr-2 buttons"
                style={{ width: "80px" }}
                clicked={() => this.updateTicketHandler(true)}
              >
                Yes
              </Button>
              <Button
                type="delete"
                className="buttons"
                style={{ width: "80px" }}
                clicked={() => this.updateTicketHandler(false)}
              >
                No
            </Button>
            </div>
          </div>
        </div>
      </Modal>
    );

    reservedAssetContent =
      this.props.ticket.bookings &&
      this.props.ticket.bookings.map(obj => (
        <BookedAsset
          canEditBookings={policyRes.can_edit_bookings}
          key={obj.id}
          booking={obj}
          loanPeriod={obj.details}
          ticketId={this.props.ticket.id}
          checked={
            this.state.selectedAssets &&
              this.state.selectedAssets.indexOf(obj.id) === -1
              ? false
              : true
          }
          clicked={this.checkedAssetHandler}
          getTicket={this.props.getTickets}
        />
      ));

    return (
      <div className="reserved-assets">
        <div className="row mt-2 pt-2">
          <div className="col-12">
            <div className="row">
              <Link to={`/programs/${this.props.programId}`}>
                <Button className="ml-3" type="success">
                  View Program
                 </Button>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h5 className="mt-3 pl-3">
                Ticket created on
                {
                  createdAt &&
                  ` ${formatDate(new Date(createdAt), "YYYY/MM/DD @ hh.mm A")}`
                }
              </h5>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-8">
            <Button
              type="success"
              className="mr-3"
              clicked={() => { window.scrollTo(0, 0); this.setState({ showEmailModal: true }) }}
              disabled={this.state.disabled}
            >
              Update Ticket
            </Button>
            <Button type="danger" clicked={this.deleteTicketHandler}>
              Delete Ticket
            </Button>
          </div>
          <div className="col-4 text-right">
            <h5 className="my-1 font-weight-bold">Total {this.props.ticket.total}</h5>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <Input
              {...this.state.ticketNotes}
              changed={event => this.inputChangedHandler(event, "ticketNotes")}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-8">
            <span
              className="cursor"
              onClick={() => this.selectAssetHandler(true)}
            >
              Select all
            </span>
            {" / "}
            <span
              className="cursor"
              onClick={() => this.selectAssetHandler(false)}
            >
              Deselect all
            </span>
          </div>
          <div className="col-4 icons text-right">
            <Button
              className="fas fa-trash"
              title="Remove selected bookings"
              clicked={this.destroyBookings}
              disabled={selectedAssets && selectedAssets.length ? false : true}
            />
            <Button
              className="fas fa-pencil-alt cursor"
              title="Edit selected charge periods"
              clicked={() => { window.scrollTo(0, 0); this.setState({ showChargedLoanModal: true }) }}
              disabled={selectedAssets && selectedAssets.length ? false : true}
            />
          </div>
        </div>

        <div className="mt-2">{reservedAssetContent}</div>
        {emailModal}
        <Modal
          title="Edit Charged Loan"
          show={this.state.showChargedLoanModal}
          hide={() => this.setState({ showChargedLoanModal: false })}
          closeModal={() => this.setState({ showChargedLoanModal: false })}
          closeButton
        >
          <EditChargedLoan
            memberTypes={this.state.memberTypes}
            ticketId={this.props.ticket.id}
            selectedAssets={this.state.selectedAssets}
            getTicket={() => this.props.getReservedAssets(false)}
            closeModal={() => this.setState({ showChargedLoanModal: false })}
          />
        </Modal>
      </div>
    );
  }
}

export default withRouter(ReservedAssets);
