import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case"
import DatePicker from "../../UI/datePicker";
import Input from "../../UI/input/Input";
import FormButton from "../../UI/button/FormButton";
import Button from "../../UI/button/Button";
import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";
import Tab from "../../formTabs";

import RentalAgreementModal from "./RentalAgreementModal";
import attrFields from "./attrFormFields";
import "./index.css";

class CreateAsset extends Component {
  signal = axios.CancelToken.source();
  state = {
    activeTab: "Equipment",
    assetName: null,
    photo: null,
    description: null,
    includedAccessories: null,
    warrantyInfo: null,
    purchaseAmount: null,
    insuranceValue: null,
    depricatedValue: null,
    status: null,
    notes: null,
    barcode: null,
    rentalAgreement: false,
    accreditation: false,
    bulkAsset: false,
    serialNumber: null,
    externalUrl: null,
    uniqueName: null,
    purchaseDate: new Date(),
    gst: false,
    pst: false,
    hst: false,
    assetForSale: false,
    category: {
      elementType: "select",
      elementConfig: {
        name: "category",
        placeholder: "Select Category",
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        selectedOption: null
      }
    },
    location: {
      elementType: "select",
      elementConfig: {
        name: "location",
        placeholder: "Select Location",
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        selectedOption: null
      }
    },
    accountingCode: {
      elementType: "select",
      elementConfig: {
        name: "accountingCode",
        placeholder: "Select Option",
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        selectedOption: null
      }
    },
    credName: {
      elementType: "select",
      elementConfig: {
        name: "credName",
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        selectedOption: null
      }
    },

    statusArr: [],
    assetTypes: [],
    editAsset: false,
    statusText: null,
    loading: true,
    tabs: ["Equipment", "Facility"],
    assetPriceData: {},
    bulkTotalCount: null,
    rentalAgreementModal: false,
    thirdPartyOwners: [],
    thirdPartyOwner: null,
    favaPercentage: "",
    rentalAgreementNotes: "",
    disabled: false
  };

  async componentDidMount() {
    const { category, location, accountingCode, credName } = this.state;
    const organizationData = await axios.get(`/organizations/${localStorage.organizationId}`, { cancelToken: this.signal.token });
    const { organization } = organizationData.data;
    let gst = false, hst = false, pst = false;
    if (organization) {
      if (typeof organization.is_gst_used === "boolean") 
        gst = organization.is_gst_used;
      if (typeof organization.is_pst_used === "boolean") 
        pst = organization.is_pst_used;
      if (typeof organization.is_hst_used === "boolean") 
        hst = organization.is_hst_used;
    }
    axios
      .get("/assets/formdata", { cancelToken: this.signal.token })
      .then(res => {
        const {
          categories,
          locations,
          accounting_codes,
          status,
          creds,
          third_party_owners
        } = res.data.json;
        const categoryDefaultValue = categories.length && {
          label: categories[0][1],
          value: categories[0][0]
        };
        const locationDefaultValue = locations.length && {
          label: locations[0][1],
          value: locations[0][0]
        };

        const credDefaultValue = creds.length && {
          label: creds[0][1],
          value: creds[0][0]
        };

        let accountingCodeOptions = accounting_codes.map(option => {
          if (option[2])
            return { label: option[1] + " - " + option[2], value: option[0] };
          else
            return { label: option[1], value: option[0] };
        });

        this.setState({
          category: {
            ...category,
            elementConfig: {
              ...category.elementConfig,
              defaultValue: categoryDefaultValue,
              options: categories.map(option => {
                return { label: option[1], value: option[0] };
              })
            },
            selectedOption: categoryDefaultValue
          },
          location: {
            ...location,
            elementConfig: {
              ...location.elementConfig,
              defaultValue: locationDefaultValue,
              options: locations.map(option => {
                return { label: option[1], value: option[0] };
              })
            },
            selectedOption: locationDefaultValue
          },
          accountingCode: {
            ...accountingCode,
            elementConfig: {
              ...accountingCode.elementConfig,
              options: accountingCodeOptions
            },
            selectedOption: null
          },
          credName: {
            ...credName,
            elementConfig: {
              ...credName.elementConfig,
              defaultValue: credDefaultValue,
              options: creds.map(option => {
                return { label: option[1], value: option[0] };
              })
            },
            selectedOption: credDefaultValue
          },
          thirdPartyOwners: third_party_owners,
          statusArr: status,
          gst,
          pst,
          hst,
          assetPriceTypes: res.data.json.asset_price_types,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  updateCredOptions = () => {
    axios.get("/assets/formdata").then(res => {
      const { creds } = res.data.json;
      this.setState({
        credName: {
          ...this.state.credName,
          elementConfig: {
            ...this.state.credName.elementConfig,
            options: creds.map(type => {
              return { label: type[1], value: type[0] };
            })
          }
        }
      });
    });
  };

  inputChangeHandler = (event, key, type) => {
    if (type === "file") {
      this.setState({ photo: event.target.files[0] });
    } else if (type === "select") {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event
        }
      });
    } else if (type === "checkbox" || type === "radio") {
      if (key === "rentalAgreement") {
        event && window.scrollTo(0, 0);
        event &&
          this.setState({
            rentalAgreementModal: true
          });
      }
      this.setState({ [key]: event });
    } else {
      this.setState({ [key]: event.target.value });
    }
  };

  saveRentalAgreement = data => {
    this.setState({
      ...data,
      rentalAgreementModal: false
    });
  };

  renderTextAreas = () => {
    const rowObj = {
      description: "Description",
      includedAccessories: "Included Accessories",
      warrantyInfo: "Warranty Information"
    };
    const form = Object.keys(rowObj).map(key => (
      <div key={key}>
        <p>{rowObj[key]}</p>
        <Input
          elementType={"textarea"}
          elementConfig={{ rows: "4" }}
          value={this.state[key]}
          changed={event => this.inputChangeHandler(event, key, "text")}
        />
      </div>
    ));
    return form;
  };

  renderCheckboxes = () => {
    const checkboxes = [
      {
        section: "Third Party Rental Agreement",
        label: "This Asset is owned by a Third Party",
        key: "rentalAgreement"
      },
      {
        section: "Accreditation Restrictions",
        label: "This Asset requires a accreditation",
        key: "accreditation"
      },
      {
        section: "Bulk Asset",
        label: "This is a bulk Asset?",
        key: "bulkAsset"
      },
      {
        section: "For Sale",
        label: "This Asset is for sale?",
        key: "assetForSale"
      },
    ];
    return checkboxes.map(checkbox => {
      let show = true;
      if (checkbox.key === "rentalAgreement") {
        show = !this.state.editAsset ? true : false;
      } else if (checkbox.key === "bulkAsset") {
        show = this.state.activeTab === "Equipment" ? true : false;
      }
      let content = null;
      if (show) {
        content = (
          <div key={checkbox.key}>
            <label className="mb-4">{checkbox.section}</label>
            <div className="row m-0 mb-4">
              <div
                onClick={() =>
                  this.inputChangeHandler(
                    !this.state[checkbox.key],
                    checkbox.key,
                    "checkbox"
                  )
                }
                className="custom-checkbox"
              >
                <div
                  className="box"
                  style={{
                    backgroundColor: this.state[checkbox.key]
                      ? "#1aff1a"
                      : "white"
                  }}
                >
                  <i className="fas fa-check check-icon" />
                </div>
                <p className="ml-3">{checkbox.label}</p>
              </div>
            </div>
            {checkbox.key === "bulkAsset" && this.state.bulkAsset && (
              <div>
                <p># of Units</p>
                <Input
                  elementType={"input"}
                  value={this.state.bulkTotalCount}
                  changed={event =>
                    this.inputChangeHandler(event, "bulkTotalCount", "text")
                  }
                />
              </div>
            )}
            {checkbox.key === "accreditation" && this.state.accreditation && (
              <div>
                <div
                  className="text-primary cursor"
                  onClick={() => this.props.history.push("/system_settings/users/accreditations")}
                >
                  <u> Create New</u>
                </div>
                <div className="mt-2">
                  {" "}
                  <Input
                    {...this.state.credName}
                    changed={event =>
                      this.inputChangeHandler(event, "credName", "select")
                    }
                  />
                </div>
              </div>
            )}
          </div>
        );
      }
      return content;
    });
  };

  renderCheckBoxes2 = () => {
    const checkboxes = ["GST", "PST", "HST"];
    return checkboxes.map(key => (
      <div className="col-4" key={key}>
        <div className="row">
          <div
            onClick={() =>
              this.inputChangeHandler(
                !this.state[key.toLowerCase()],
                key.toLowerCase(),
                "checkbox"
              )
            }
            className="custom-checkbox"
          >
            <div
              className="box"
              style={{
                backgroundColor: this.state[key.toLowerCase()]
                  ? "#337ab7"
                  : "white"
              }}
            >
              <i className="fas fa-check check-icon" />
            </div>
            <p className="ml-3">{key}</p>
          </div>
        </div>
      </div>
    ));
  };

  renderRadioButtons = () => {
    const { statusArr: status } = this.state;
    const radioButtons = {
      Active: status["ACTIVE"],
      "Being Serviced": status["SERVICE"],
      Decommissioned: status["DECOMISSIONED"],
    };
    return Object.keys(radioButtons).map(key => (
      <p
        key={key}
        onClick={() =>
          this.inputChangeHandler(radioButtons[key], "status", "radio")
        }
        className="cursor"
      >
        <input
          type="radio"
          name="status"
          checked={this.state.status === radioButtons[key] ? true : false}
          required
        />
        <span className="mx-3">{key}</span>
      </p>
    ));
  };

  assetPriceHandler = (event, key) => {
    this.setState({
      assetPriceData: {
        ...this.state.assetPriceData,
        [key]: {
          value: event.target.value
        }
      }
    });
  };

  renderAssetPriceTypes = () => {
    return (
      this.state.assetPriceTypes &&
      this.state.assetPriceTypes.map(type => (
        <div className="col-6 m-0">
          <p>{this.state.activeTab === "Facility" ? `${type[1]} (per hour)` : `${type[1]} (per day)`}</p>
          <Input
            elementType={"input"}
            elementConfig={{ type: "number", step: "0.01" }}
            value={
              this.state.assetPriceData[type[0]] &&
              this.state.assetPriceData[type[0]].value
            }
            changed={event => this.assetPriceHandler(event, type[0])}
          />
        </div>
      ))
    );
  };

  createAssetHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const assetData = new FormData();
    const typeUsed =
      this.state.activeTab === "Equipment" ? "EquipmentItem" : "Facility";

    assetData.append("type_used", typeUsed);

    Object.keys(attrFields.textInput).forEach(key => {
      const stateKey = attrFields.textInput[key];
      if (this.state[stateKey]) {
        assetData.append(key, this.state[stateKey]);
      }
    });

    Object.keys(attrFields.dropdown).forEach(key => {
      const stateKey = attrFields.dropdown[key];
      if (
        this.state[stateKey].selectedOption &&
        this.state[stateKey].selectedOption.value
      ) {
        assetData.append(key, this.state[stateKey].selectedOption.value);
      }
    });

    Object.keys(attrFields.checkbox).forEach(key => {
      const stateKey = attrFields.checkbox[key];
      if (this.state[stateKey] !== null) {
        assetData.append(key, this.state[stateKey]);
      }
    });

    this.state.status !== null && assetData.append("status", this.state.status);

    if (this.state.accreditation) {
      assetData.append(
        "cred_id",
        this.state.credName.selectedOption.value
      );
      assetData.append(
        "asset_contributers_attributes",
        `[${JSON.stringify({
          contributer_type: this.state.credName.selectedOption.value,
          name: this.state.credName.selectedOption.label
        })}]`
      );
    }

    if (typeUsed === "EquipmentItem" && this.state.bulkAsset !== null) {
      assetData.append("is_bulk_asset_check_box", this.state.bulkAsset);
      this.state.bulkTotalCount &&
        assetData.append("bulk_count_total", this.state.bulkTotalCount);
    }

    let assetPrices = Object.keys(this.state.assetPriceData).map(key => {
      var obj = {
        asset_price_type_id: key,
        value: this.state.assetPriceData[key].value
      };
      if (this.state.editAsset) obj["id"] = this.state.assetPriceData[key].id;
      return obj;
    });

    assetPrices.length &&
      assetData.append(
        "asset_prices_attributes",
        `${JSON.stringify(assetPrices)}`
      );

    if (this.state.rentalAgreement && this.state.thirdPartyOwner) {
      const data =
      {
        third_party_owner_id: this.state.thirdPartyOwner,
        middleman_percentage: this.state.favaPercentage,
        notes: this.state.rentalAgreementNotes
      };
      assetData.append(
        "third_party_rental_agreement_attributes", JSON.stringify(data));
    }
    axios.post("/assets", assetData).then(res => {
      if (res.data.json.success) {
        toast.success("Asset created successfully.");
        this.props.history.push(`/assets/${res.data.json.data.id}`);
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(title(error) + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
        this.setState({ disabled: false })
      }
    });
  };

  render() {
    return (
      <div
        className="container my-3 create-asset w-100"
        key={this.state.activeTab}
      >
        <div className="row mb-4">
          <div className="col-6">
            <h4>Our Stuff</h4>
          </div>
          <div className="col-6 text-right">
            <span
              className="cursor"
              onClick={() => this.props.history.goBack()}
            >
              {"<< Back"}
            </span>
          </div>
        </div>
        <div className="card">
          <div>
            {!this.state.loading && (
              <Tab
                tabs={this.state.tabs}
                activeTab={this.state.activeTab}
                tabChangedHandler={activeTab => this.setState({ activeTab, rentalAgreement: false, accreditation: false })}
                hrMargin={"182px"}
              />
            )}
          </div>
          {this.state.loading ? (
            <Spinner />
          ) : (
              <React.Fragment>
                <RentalAgreementModal
                  rentalAgreementModal={this.state.rentalAgreementModal}
                  thirdPartyOwners={this.state.thirdPartyOwners}
                  saveRentalAgreement={this.saveRentalAgreement}
                  closeModal={() =>
                    this.setState({ rentalAgreementModal: false, rentalAgreement: false })
                  }
                />
                <form onSubmit={this.createAssetHandler} className="p-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mt-4 pt-2">
                        <p>Asset Name</p>
                        <Input
                          elementType={"input"}
                          elementConfig={{ required: true }}
                          value={this.state.assetName}
                          changed={event =>
                            this.inputChangeHandler(event, "assetName", "text")
                          }
                        />
                      </div>
                      <div>
                        <p>Status</p>
                        <div className="row ml-1">
                          {this.renderRadioButtons()}
                        </div>
                      </div>
                      <div>
                        <p>Photo <small>(Recommended size 800px By 800px)</small></p>
                        <Input
                          elementType={"file"}
                          value={this.state.photo}
                          changed={event =>
                            this.inputChangeHandler(event, "photo", "file")
                          }
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <p>Category</p>
                          <Input
                            {...this.state.category}
                            changed={event =>
                              this.inputChangeHandler(event, "category", "select")
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <p>Location</p>
                          <Input
                            {...this.state.location}
                            changed={event =>
                              this.inputChangeHandler(event, "location", "select")
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <p>Unique/Quickbook Name</p>
                        <Input
                          elementType={"input"}
                          value={this.state.uniqueName}
                          changed={event =>
                            this.inputChangeHandler(event, "uniqueName", "text")
                          }
                        />
                      </div>
                      <div>
                        <p>External Url Resources</p>
                        <Input
                          elementType={"input"}
                          value={this.state.externalUrl}
                          changed={event =>
                            this.inputChangeHandler(event, "externalUrl", "text")
                          }
                        />
                      </div>
                      <div className="mb-4">
                        <p>Purchase Date</p>
                        <DatePicker
                          selected={this.state.purchaseDate}
                          changed={purchaseDate =>
                            this.setState({ purchaseDate })
                          }
                          style={{ minWidth: "100%", maxWidth: "100%" }}
                        />
                      </div>
                      {this.renderTextAreas()}
                      {this.state.activeTab === "Equipment" && (
                        <div>
                          <p>Serial Number</p>
                          <Input
                            elementType={"input"}
                            value={this.state.serialNumber}
                            changed={event =>
                              this.inputChangeHandler(
                                event,
                                "serialNumber",
                                "text"
                              )
                            }
                          />
                        </div>
                      )}
                      <div>
                        <label>Taxes</label>
                        <div className="px-4">
                          <div className="row">{this.renderCheckBoxes2()}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label>Price</label>
                      <div className="row">{this.renderAssetPriceTypes()}</div>
                      <div>
                        <p>Accounting Code</p>
                        <Input
                          {...this.state.accountingCode}
                          changed={event =>
                            this.inputChangeHandler(
                              event,
                              "accountingCode",
                              "select"
                            )
                          }
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <p>Purchase Amount</p>
                          <Input
                            elementType={"input"}
                            elementConfig={{ type: "number", step: "0.01" }}
                            value={this.state.purchaseAmount}
                            changed={event =>
                              this.inputChangeHandler(
                                event,
                                "purchaseAmount",
                                "number"
                              )
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <p>Insurance Value</p>
                          <Input
                            elementType={"input"}
                            elementConfig={{ type: "number", step: '0.01' }}
                            value={this.state.insuranceValue}
                            changed={event =>
                              this.inputChangeHandler(
                                event,
                                "insuranceValue",
                                "number"
                              )
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <p>Status Change Notes</p>
                        <Input
                          elementType={"textarea"}
                          elementConfig={{ rows: "4" }}
                          value={this.state.notes}
                          changed={event =>
                            this.inputChangeHandler(event, "notes", "text")
                          }
                        />
                      </div>
                      <div>
                        <label>Barcode/ISBN</label>
                        <Input
                          elementType={"input"}
                          value={this.state.barcode}
                          changed={event =>
                            this.inputChangeHandler(event, "barcode", "text")
                          }
                        />
                      </div>
                      {this.renderCheckboxes()}
                    </div>
                    <div className="col-12 text-center">
                      <Button
                        type="secondary"
                        clicked={() => this.props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <FormButton
                        className="ml-2"
                        disabled={this.state.disabled}
                      >
                        {this.state.editAsset ? "Update" : "Create"}
                      </FormButton>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            )}
        </div>
      </div>
    );
  }
}

export default CreateAsset;
