import Button from "../UI/button/Button";
import React from "react";
import axios from "axios";

const SendReport = ({ setSent, setShowModal, history }) => {
  const sendReport = () => {
    const id = localStorage.getItem("userId")
    const time = new Date()
    const error_obj = history.location?.state?.detail

    delete error_obj.headers
    const error_json = JSON.stringify(error_obj)

    axios({
      method: 'post',
      url: `/users/${id}/send_error`,
      params: {
        time: time,
        error: error_json
      }
    })
      .then(_ => setSent(true))
      .catch(err => console.error(err));
  }

  return (<>
    <h4 className="text-center">Send Issue Report?</h4>
    <div className="text-center mt-3">
      <Button
        type="secondary"
        clicked={() => {
          setShowModal(false)
        }}
        className="mr-2"
      >
        Cancel
      </Button>
      <Button
        type="primary"
        clicked={() => {
          sendReport()
        }}
      >
        Send
      </Button>
    </div>
  </>)
}

export default SendReport
