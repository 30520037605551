import React from 'react'
import Input from "../../../UI/input/Input";

export default function CurrencyQuestion({ question, response, setResponseData, completed }) {
  return (
    <div className="mb-4">
      <p className="mb-2 font-weight-bold">{question.question_text}</p>
      <div className="mx-3">
        <Input
          elementType="currency"
          elementConfig={{
            name: question.id,
            type: 'currency',
            required: question.mandatory,
            placeholder: 0.00
          }}
          changed={setResponseData}
          value={response?.response_number}
          disabled={completed}
        />
      </div>
    </div>
  )
}
