import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";
import Quickbooks from "./Quickbooks.js";
import AccountingCodes from "./AccountingCodes.js";

import "./Accounting.css";

class Accounting extends Component {
  state = {
    activeTab: "Chart of Accounts"
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? this.props.match.params.toptab === "chart_of_accounts" ? "Chart of Accounts" : title(this.props.match.params.toptab)
          : "Chart of Accounts"
    });
  };

  tabChangeHandler = activeTab => {
    this.setState({ activeTab });
    this.props.history.push(
      `/system_settings/accounting/${snakeCase(activeTab)}`
    );
  };

  render() {
    const tabNames = ["Chart of Accounts", "Quickbooks"];
    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${this.state.activeTab === tab ? "active-tab " : "inactive-tab"}`}
      >
        {tab}
      </li>
    ));
    let activeTab;
    switch (this.state.activeTab) {
      case "Chart of Accounts":
        activeTab = <AccountingCodes />;
        break;
      case "Quickbooks":
        activeTab = <Quickbooks />;
        break;
      default:
        activeTab = null;
    }
    return (
      <div className="card system-settings-accounting w-100">
        <ul className="nav nav-tabs ml-0">
          {tabs}
        </ul>
        {activeTab}
      </div>
    );
  }
}

export default withRouter(Accounting);
