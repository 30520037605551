import { toast } from "react-toastify";

import deselectCurrent from "./toggleSelection";

const copyToClipboard = (copyText) => {
  let reselectPrevious,
    success,
    range,
    selection,
    mark;
  try {
    reselectPrevious = deselectCurrent();
    range = document.createRange();
    selection = document.getSelection();

    mark = document.createElement("span");
    mark.textContent = copyText
    mark.style.all = "unset";
    mark.style.position = "fixed";
    mark.style.top = 0;
    mark.style.clip = "rect(0, 0, 0, 0)";
    mark.style.whiteSpace = "pre";
    mark.style.webkitUserSelect = "text";
    mark.style.MozUserSelect = "text";
    mark.style.msUserSelect = "text";
    mark.style.userSelect = "text";
    mark.addEventListener("copy", event => {
      event.preventDefault();
      event.stopPropagation();
      if (typeof event.clipboardData === "undefined") { // For IE 11
        window.clipboardData.clearData();
        window.clipboardData.setData("Text", copyText);
      } else {
        event.clipboardData.clearData();
        event.clipboardData.setData("Text", copyText)
      }
    })

    document.body.appendChild(mark);

    range.selectNodeContents(mark);
    selection.addRange(range);

    let successful = document.execCommand("copy");
    if (!successful)
      throw new Error("copy command was unsuccessful");
    success = true;
  } catch(err) {
    try {
      window.clipboardData.setData("Text", copyText);
      success = true;
    } catch(err) {
      toast.error("Click to copy is not supported in your browser");
    }
  } finally {
    if (selection) {
      if (typeof selection.removeRange === "function") {
        selection.removeRange(range);
      } else {
        selection.removeAllRanges();
      }
    }

    if (mark) {
      document.body.removeChild(mark);
    }
    reselectPrevious();
  }
  return success;
}

export default copyToClipboard;
