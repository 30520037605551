import React from 'react';
import _ from "lodash";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { title } from "change-case"

const FilterAccountingCode = ({ code, deleteHandler, accEditModal }) => {
  return (
    <li className="list-group-item rounded-0">
      <div className="row">
        <div className="col-4">
          {_.isEmpty(code.name) ? "--" : code.name} <br />
          {code.assets_count > 0 && (
            <span className="asset-name-font">
              This Accounting Code is currently associated with{" "}
              <Link to={{
                pathname: "/assets",
                search: `?accounting_id=${code.id}`
              }}>{code.assets_count} Assets </Link>
            </span>
          )}
        </div>
        <div className={classnames("col-2", { "text-danger font-weight-bold": _.isNull(code.code) })}>
          {_.isNull(code.code) ? "Missing Code" : code.code}
        </div>
        <div className="col-2">
          {code.account_number ? code.account_number : "--"}
        </div>
        <div className="col-3">
          {code.code_type ? title(code.code_type) : "--"}
        </div>
        <div className="col-1 d-flex p-0">
          <i
            className="fas fa-pencil-alt accounting-code-edit cursor pr-4"
            onClick={() => accEditModal(code.id)}
          />
          {code.can_delete &&
            <i
              className="fas fa-trash cursor accounting-code-edit"
              onClick={() => deleteHandler(code.id)}
            />
          }
        </div>
      </div>
    </li>
  )
}

export default FilterAccountingCode
