import React from "react";
import { format } from "date-fns";

const ShowCampaignInfo = ({ showCampainDetails }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="my-2">
          <div className="font-weight-bold">Name</div>
          <div>{showCampainDetails.name}</div>
        </div>
        <div className="my-2">
          <div className="font-weight-bold">Status</div>
          <div className="text-transform-capitalize">{showCampainDetails.status}</div>
        </div>
        <div className="my-2">
          <div className="font-weight-bold">Goal Amount</div>
          <div>
            ${showCampainDetails.target_amount ? showCampainDetails.target_amount : "0.00"}
          </div>
        </div>
        <div className="my-2">
          <div className="font-weight-bold">Raised Amount</div>
          <div>
            ${showCampainDetails.raised ? showCampainDetails.raised : "0.00"}
          </div>
        </div>
        <div className="my-2">
          <div className="font-weight-bold">Start Date</div>
          <div>{format(new Date(showCampainDetails.start_date), "YYYY.MM.DD")}</div>
        </div>
        <div className="my-2">
          <div className="font-weight-bold">End Date</div>
          <div>{format(new Date(showCampainDetails.end_date), "YYYY.MM.DD")}</div>
        </div>
      </div>
    </div>
  )
}

export default ShowCampaignInfo
