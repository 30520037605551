import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";
import { sortBy } from "lodash"

import ProjectAttributeList from "./ProjectAttributeList";
import translateModuleName from "../../../utils/translateModuleName"
import "./Projects.css";
import "./../../../App.css";

import axios from "../../../configAxios";

class ProjectAttributes extends Component {
  signal = axios.CancelToken.source();
  state = {
    activeTab: "Short Attributes",
    tabNames: ["Crew Role", "Long Attributes", "Media Type", "Short Attributes"],
    typeList: [],
    loading: true,
    edit: false
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
    this.signal.cancel("Request is being Cancelled");
  }

  handleTabFromProp = () => {
    let { innertab } = this.props.match.params;
    innertab = title(innertab);
    this.setState({
      ...this.state,
      activeTab: innertab && this.state.tabNames.includes(innertab) ? innertab : this.state.tabNames[0]
    }, () => {
      this.attrTypeList();
    });
  };

  attrTypeList = () => {
    this.setState({ ...this.state, loading: true })
    axios
      .get(`/project_attribute_types?category=${this.state.activeTab}`, {
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          typeList: res.data.project_attribute_types,
          loading: false
        });
      });
  };

  tabChangeHandler = activeTab => {
    this.setState({ ...this.state, loading: true })
    const tab = activeTab;
    axios
      .get(`/project_attribute_types?category=${tab}`, {
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          typeList: res.data.project_attribute_types,
          loading: false
        });
      });
    this.setState({ activeTab });
    this.props.history.push(
      `/system_settings/projects/project_attributes/${snakeCase(activeTab)}`
    );
  };

  render() {
    const unsortedTabs = [...this.state.tabNames]

    const sortedTabs = sortBy(unsortedTabs, (item) => item.toLowerCase() )

    const tabs = sortedTabs.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={
          this.state.activeTab === tab
            ? "list-item d-inline text-primary"
            : "list-item d-inline text-muted"
        }
      >
        {`${translateModuleName(tab)} `}
        <span className="slash">
          /
        </span>
      </li>
    ));
    let activeTab;
    switch (this.state.activeTab) {
      case "Short Attributes":
        activeTab = (
          <ProjectAttributeList
            type={this.state.activeTab}
            typeList={this.state.typeList}
            loading={this.state.loading}
            attrTypeList={this.attrTypeList}
            tabChangeHandler={this.tabChangeHandler}
          />
        );
        break;
      case "Long Attributes":
        activeTab = (
          <ProjectAttributeList
            type={this.state.activeTab}
            typeList={this.state.typeList}
            loading={this.state.loading}
            attrTypeList={this.attrTypeList}
            tabChangeHandler={this.tabChangeHandler}
          />
        );
        break;
      case "Crew Role":
        activeTab = (
          <ProjectAttributeList
            type={this.state.activeTab}
            typeList={this.state.typeList}
            loading={this.state.loading}
            attrTypeList={this.attrTypeList}
            tabChangeHandler={this.tabChangeHandler}
          />
        );
        break;
      case "Media Type":
        activeTab = (
          <ProjectAttributeList
            type={this.state.activeTab}
            typeList={this.state.typeList}
            loading={this.state.loading}
            attrTypeList={this.attrTypeList}
            tabChangeHandler={this.tabChangeHandler}
          />
        );
        break;
      default:
        activeTab = null;
    }
    return (
      <div className="card project-attributes">
        <div className="p-4">
          <ul className="list-inline cursor">{tabs}</ul>
        </div>

        {activeTab}
      </div>
    );
  }
}

export default withRouter(ProjectAttributes);
