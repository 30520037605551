import React, { Component } from "react";
import axios from "../../configAxios";

import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import { toast } from "react-toastify";

class CreateCollection extends Component {
  signal = axios.CancelToken.source();
  state = {
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true
      },
      label: "Name",
      value: ""
    },
    contest: {
      elementType: "select",
      elementConfig: {
        name: "contest",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Contest"
    },
    projects: {
      elementType: "select",
      elementConfig: {
        name: "projects",
        options: [],
        isMulti: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Projects"
    },
    reels: {
      elementType: "select",
      elementConfig: {
        name: "reels",
        options: [],
        isMulti: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Reels"
    },
    disabled: true
  };

  componentDidMount() {
    axios
      .get("/collections/formdata", { cancelToken: this.signal.token })
      .then(res => {
        let contestsData = [];
        for (let key in res.data.collections) {
          contestsData.push({ ...res.data.collections[key] });
        }
        const contests = contestsData.map(contest => {
          var obj = {
            label: contest[0],
            value: contest[1]
          };
          return obj;
        });

        this.setState({
          contest: {
            ...this.state.contest,
            elementConfig: {
              ...this.state.contest.elementConfig,
              options: contests
            }
          },
          disabled: false
        });
      });

    if (this.props.isEdit) {
      axios
        .get("/collections/reels", { cancelToken: this.signal.token })
        .then(res => {
          const reelsData = res.data.collections;
          const reels = reelsData.map(reel => {
            var obj = {
              label: reel[1],
              value: reel[0]
            };
            return obj;
          });
          this.setState({
            reels: {
              ...this.state.reels,
              elementConfig: {
                ...this.state.reels.elementConfig,
                options: reels
              }
            }
          });
        });

      axios
        .get("/collections/projects", {
          params: {
            id: this.props.collection.id
          },
          cancelToken: this.signal.token
        })
        .then(res => {
          if (res.data) {
            const projectsData = res.data.collections;
            const projects = projectsData.map(project => {
              var obj = {
                label: project[0],
                value: project[1]
              };
              return obj;
            });
            this.setState({
              projects: {
                ...this.state.projects,
                elementConfig: {
                  ...this.state.projects.elementConfig,
                  options: projects
                }
              }
            });
          }
        });
      const collection = this.props.collection;
      this.setState({
        name: {
          ...this.state.name,
          value: collection.name
        },
        contest: {
          ...this.state.contest,
          selectedOption: {
            label: collection.contest.name,
            value: collection.contest.id
          }
        },
        projects: {
          ...this.state.projects,
          selectedOption:
            collection.projects &&
            collection.projects.map(project => {
              return { label: project.name, value: project.id };
            })
        },
        reels: {
          ...this.state.reels,
          selectedOption:
            collection.reels &&
            collection.reels.map(reel => {
              return { label: reel.name, value: reel.id };
            })
        }
      });
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    if (key === "name") {
      inputValue["value"] = event.target.value;
    } else {
      inputValue["selectedOption"] = event;
    }

    this.setState({
      [key]: {
        ...this.state[key],
        ...inputValue
      }
    });
  };

  submitHandler = event => {
    this.setState({ disabled: true })
    event.preventDefault();
    let collectionData = {};
    collectionData["name"] = this.state.name.value;
    collectionData["contest_id"] = this.state.contest.selectedOption.value;
    if (this.props.isEdit) {
      const projects =
        this.state.projects.selectedOption ?
          this.state.projects.selectedOption.map(option => option.value) : `[]`;
      const reels =
        this.state.reels.selectedOption ?
          this.state.reels.selectedOption.map(option => option.value) : `[]`;
      collectionData["id"] = this.props.collection.id;
      collectionData["project_ids"] = JSON.stringify(projects);
      collectionData["reel_ids"] = JSON.stringify(reels);
      axios
        .put(`/collections/${this.props.collection.id}`, collectionData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Collection updated successfully.");
            this.props.getCollections();
            this.props.closeModal();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
            this.setState({ disabled: false })
          }
        });
    } else {
      axios.post("/collections", collectionData).then(res => {
        if (res.data.json.success) {
          toast.success("Collection created successfully.");
          this.props.getCollections();
          this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(error + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    }
  };

  render() {
    const formData = (
      <>
        <Input
          {...this.state.projects}
          changed={event => this.inputChangedHandler(event, "projects")}
        />
        <Input
          {...this.state.reels}
          changed={event => this.inputChangedHandler(event, "reels")}
        />
      </>
    );
    return (
      <form className="mt-4" onSubmit={this.submitHandler}>
        <Input
          {...this.state.name}
          changed={event => this.inputChangedHandler(event, "name")}
        />
        <Input
          {...this.state.contest}
          changed={event => this.inputChangedHandler(event, "contest")}
        />
        {this.props.isEdit ? formData : ""}
        <div className="text-center">
          <Button type="secondary" clicked={this.props.closeModal}>
            Cancel
          </Button>
          <FormButton className="ml-2" disabled={this.state.disabled}>
            {this.props.isEdit ? "Update" : "Create"}
          </FormButton>
        </div>
      </form>
    );
  }
}

export default CreateCollection;
