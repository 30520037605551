import React, { Component } from "react"
import axios from "../../configAxios"
import { Link } from "react-router-dom"
import dateFns from "date-fns"

class BookingItem extends Component {
  signal = axios.CancelToken.source()
  state = {
    managePackingList: null,
    manageInvoice: null,
  }

  downloadPackingInvoice = () => {
    axios
      .get("/tickets/packing_list", {
        params: {
          id: this.props.bookingDetail.id,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        window.open(res.data.json.file_path, "_blank")
      })
  }

  componentDidMount() {
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios
      .get("/tickets/policy", { params: { id: this.props.ticketId } })
      .then((res) => {
        this.setState({
          managePackingList: res.data.json.manage_packing_list,
          manageInvoice: res.data.json.manage_invoice,
        })
      })
  }

  render() {
    let details = null
    const { bookingDetail } = this.props
    if (bookingDetail.equipment_items != null) {
      details = (
        <div className="row">
          <div className="col-md-9">
            <div className="mt-2 booking-text-color">
              <p>
                <span>{bookingDetail.equipment_items.count}</span>
                <span> </span>
                Equipment Booking between
                <span>
                  {" "}
                  {dateFns.format(
                    new Date(bookingDetail.equipment_items.start_time),
                    "ddd MMM DD/YY"
                  )}
                </span>{" "}
                and
                <span>
                  {" "}
                  {dateFns.format(
                    new Date(bookingDetail.equipment_items.end_time),
                    "ddd MMM DD/YY"
                  )}
                </span>
              </p>
              <p className="font-style">
                Created{" "}
                <span>
                  {dateFns.format(
                    new Date(bookingDetail.created_at),
                    "ddd MMM DD/YY"
                  )}
                </span>
              </p>
              <span
                className="text-primary font-weight-normal cursor"
                onClick={
                  this.state.managePackingList
                    ? this.downloadPackingInvoice
                    : null
                }
                style={{
                  cursor: this.state.managePackingList ? "pointer" : "no-drop",
                }}
              >
                Packing List
              </span>
              <span> | </span>
              <span className="font-weight-normal">
                {bookingDetail.invoice_or_program != null ? (
                  bookingDetail.invoice_or_program.invoice != null ? (
                    <Link
                      to={
                        this.state.manageInvoice
                          ? `/invoices/${bookingDetail.invoice_or_program.invoice}`
                          : ""
                      }
                      className="text-info"
                      onClick={
                        this.state.manageInvoice
                          ? null
                          : (e) => e.preventDefault()
                      }
                      style={{
                        cursor: this.state.manageInvoice
                          ? "pointer"
                          : "no-drop",
                      }}
                    >
                      View Invoice
                    </Link>
                  ) : bookingDetail.invoice_or_program.program != null ? (
                    bookingDetail.invoice_or_program.program
                  ) : null
                ) : null}
              </span>
            </div>
          </div>
          <div className="col-md-3">
            <p className="text-right font-style">
              <span>{bookingDetail.project_name}</span>
            </p>
            <p className="text-right font-style">Asset Booking</p>
            <p className="text-right font-weight-bold">
              <span>{bookingDetail.total}</span>
            </p>
          </div>
        </div>
      )
    } else {
      if (bookingDetail.facilities != null) {
        const facilitiesList = bookingDetail.facilities.map((facility) => (
          <div className="mt-2 booking-text-color" key={facility.asset.id}>
            <p>
              <Link
                to={`/assets/${facility.asset.id}`}
                className="text-info font-weight-normal font-style"
              >
                {facility.asset.name}
              </Link>
            </p>
            <p>
              <span>
                {dateFns.format(
                  new Date(facility.details.day),
                  "ddd MMM DD/YY"
                )}{" "}
              </span>
              <span>
                {" "}
                {dateFns.format(
                  new Date(facility.details.start_time),
                  "hh:mm A"
                )}{" "}
              </span>
              <span>-</span>
              <span>
                {" "}
                {dateFns.format(
                  new Date(facility.details.end_time),
                  "hh:mm A"
                )}{" "}
              </span>
            </p>
          </div>
        ))
        details = (
          <div className="row">
            <div className="col-md-9">
              {facilitiesList}
              <p className="font-style">
                Created{" "}
                <span>
                  {dateFns.format(
                    new Date(bookingDetail.created_at),
                    "ddd MMM DD/YY"
                  )}
                </span>
              </p>
              <span
                className="text-primary font-weight-normal cursor"
                onClick={this.downloadPackingInvoice}
              >
                Packing List
              </span>
              <span> | </span>
              <span className="font-weight-normal">
                {bookingDetail.invoice_or_program != null ? (
                  bookingDetail.invoice_or_program.invoice != null ? (
                    <Link
                      to={`/invoices/${bookingDetail.invoice_or_program.invoice}`}
                      className="text-info"
                    >
                      View Invoice
                    </Link>
                  ) : bookingDetail.invoice_or_program.program != null ? (
                    bookingDetail.invoice_or_program.program
                  ) : null
                ) : null}
              </span>
            </div>
            <div className="col-md-3">
              <p className="text-right font-style">
                <span>{bookingDetail.project_name}</span>
              </p>
              <p className="text-right font-style">Asset Booking</p>
              <p className="text-right font-weight-bold">
                <span>{bookingDetail.total}</span>
              </p>
            </div>
          </div>
        )
      }
    }
    return <li className="list-group-item">{details}</li>
  }
}

export default BookingItem
