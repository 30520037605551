import React, { useEffect, useReducer, useState } from "react"
import axios from "axios"
import { flatten, isEmpty, groupBy, sortBy } from "lodash"
import { DragDropContext } from 'react-beautiful-dnd'
import { toast } from "react-toastify"
import SearchAndCreate from "./SearchAndCreate"
import LabelHeader from "./LabelHeader"
import Spinner from "../UI/Spinner"
import LabelCategoryGroup from "./LabelCategoryGroup"
import SelectPageItems from "./SelectPageItems"
import Footer from "./Footer"

const LabelsTab = () => {
  const [{ collection, collectionCount, loading, pendingSubmission }, dispatch] = useReducer(dndReducer, initialDnDState)
  const [searchTerm, setSearchTerm] = useState("")
  const [pageItems, setPageItems] = useState(100)
  const [page, setPage] = useState(1)

  const [labelCategories, setLabelCategories] = useState([])

  const getAllLabels = () => {
    axios
      .get("labels", {
        params: {
          name: searchTerm,
          page: page,
          per_page: pageItems,
          tag_type: "program_global",
        },
      })
      .then((res) => {
        dispatch({
          type: INIT_DND_STATE,
          collection: res.data.json.data,
          collectionCount: res.data.json.total_count,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getAllLabelCategories = () => {
    axios
      .get("label_categories")
      .then((res) => {
        const sortedCategories = sortBy(res.data.json.label_category, 'order')
        setLabelCategories(sortedCategories)
        dispatch({ type: SET_LOADING, value: false })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getLabelCategoryName = (categoryOrder) => {
    return labelCategories && labelCategories.find(category => category.order === parseInt(categoryOrder))?.name
  }

  const updateCollectionOrder = ({ source, destination }) => {
    const reorderedCollection = [...collection[source.droppableId]]
    const [removedItem] = reorderedCollection.splice(source.index, 1)
    reorderedCollection.splice(destination.index, 0, removedItem)

    dispatch({
      type: SET_COLLECTION,
      categoryCollection: reorderedCollection,
      categoryId: source.droppableId,
    })
  }

  const submitCollectionOrder = () => {
    if (!isEmpty(collection)) {
      const updatedLabelIds = getUpdatedLabels()
      Promise
        .all(
          updatedLabelIds.map(label => {
            axios.put(`labels/${label.id}`, { name: label.name, order: label.order })
          })
        )
        .then(() => toast.success("Successfully reordered labels"))
        .catch(() => toast.error("Could not update the label order"))
        .finally(() => dispatch({ type: SET_PENDING_SUBMISSION, value: false }))
    }
  }

  const getUpdatedLabels = () => {
    return flatten(
      Object.keys(collection).map(categoryId => (
        collection[categoryId]
          .filter(label => label?.isUpdated) // returns only updated labels
      ))
    )
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) return
    if (source.index === destination.index) return

    updateCollectionOrder(result)
  }

  useEffect(() => {
    if (pageItems > 100) {
      setPage(1)
    }

    getAllLabels()
    getAllLabelCategories()
  }, [searchTerm, pageItems, page])

  useEffect(() => {
    if (pendingSubmission) {
      submitCollectionOrder()
    }
  }, [collection])

  const labelList =
    collection && labelCategories && (Object.keys(collection)).map((categoryOrder) => (
      <LabelCategoryGroup
        key={categoryOrder}
        categoryOrder={categoryOrder}
        labelCategories={labelCategories}
        labelCategoryName={getLabelCategoryName(categoryOrder)}
        labels={collection[categoryOrder]}
        onUpdate={getAllLabels}
      />
    ))

  return (
    <>
      <SearchAndCreate
        searchTerm={searchTerm}
        labelCategories={labelCategories}
        setSearchTerm={setSearchTerm}
        onLabelUpdate={getAllLabels}
        onCategoryUpdate={getAllLabelCategories}
      />
      <SelectPageItems setPageItems={setPageItems} />
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="list-group list-group-flush">
          <LabelHeader />
          {loading ? <Spinner /> : labelList}
        </div>
      </DragDropContext>
      <Footer
        pageItems={pageItems}
        setPage={setPage}
        page={page}
        labelsCount={collectionCount}
      />
    </>
  )
}

const initialDnDState = {
  collection: [],
  collectionCount: 0,
  loading: true,
  pendingSubmission: false,
}

const INIT_DND_STATE = 'INIT_DND_STATE'
const SET_COLLECTION = 'SET_COLLECTION'
const SET_LOADING = 'SET_LOADING'
const SET_PENDING_SUBMISSION = 'SET_PENDING_SUBMISSION'

const dndReducer = (state, action) => {
  switch (action.type) {
    case INIT_DND_STATE:
      const collection = groupBy(sortBy(action.collection, 'order'), 'label_category.order')
      return { ...state, collection, collectionCount: action.collectionCount, loading: false }

    case SET_COLLECTION:
      const { categoryCollection, categoryId } = action
      const orderedCollection = categoryCollection.map((label, index) => {
        const newOrder = index + 1
        const isUpdated = !(label.order === newOrder)

        return { ...label, order: newOrder, isUpdated }
      })

      return {
        ...state,
        pendingSubmission: true,
        collection: {
          ...state.collection,
          [categoryId]: orderedCollection,
        }
      }

    case SET_LOADING:
      return { ...state, loading: action.value }

    case SET_PENDING_SUBMISSION:
      return { ...state, pendingSubmission: action.value }

    default:
      return state
  }
}

export default LabelsTab
