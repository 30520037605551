import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getForm,
  selectFormById,
  selectFormsLoading,
} from "../../../store/slices/form";
import Spinner from "../../UI/Spinner";
import Sidebar from "./Sidebar";
import Tabs from "./Tabs";

class Form extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getForm(this.props.match.params.id);
  }

  render() {
    const { loading, form } = this.props;
    // const currentItemId = this.props.match.params.id;
    // const currentItem = items.find((f) => f.id == currentItemId);

    // console.log(this.props);
    // console.log(this.props.match.params);
    // console.log({ currentItemId });
    // console.log({ currentItem });

    let content;
    if (loading) {
      content = <Spinner />;
    } else {
      content = (
        <div className="container w-100">
          <div className="row mb-4">
            <div className="col-12">
              <h3>Form</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Sidebar form={form} />
            </div>
            <div className="col-md-9">
              <Tabs form={form} />
            </div>
          </div>
        </div>
      );
    }
    return <div>{content}</div>;
  }
}

Form.propTypes = {
  form: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  getForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const currentItemId = ownProps.match.params.id;
  const form = selectFormById(state, currentItemId) || {};
  const loading = selectFormsLoading(state);

  return {
    form,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForm: (id) => dispatch(getForm(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
