import React from "react"

const UserInfoPanel = (props) => {
  return (
    <div className="row no-gutters border-bottom py-2">
      <div className="col-11 pl-2">{props.children}</div>
    </div>
  )
}

export default UserInfoPanel
