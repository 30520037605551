import React from "react"
import classNames from "classnames"
import "rc-slider/assets/index.css"

import "./Input.css"
import InputElement from "./inputElement"

const input = (props) => {
  const CheckboxInputField = ({ children }) => {
    let label
    if (props.label) {
      label = (
        <label className="font-weight-normal cursor word-wrap-break w-100">
          {children} {props.label}
        </label>
      )
    }
    return <div className="form-group">{label || children}</div>
  }

  const buildFormToLabel = () => {
    if (props.elementType === "range" && props) {
      return (
        <div className="d-flex justify-content-between mb-3">
          {props.fromLabel && <div>{props.fromLabel}</div>}
          {props.toLabel && <div>{props.toLabel}</div>}
        </div>
      )
    }
  }

  if (props?.elementConfig?.type === "checkbox") {
    return (
      <CheckboxInputField>
        <InputElement props={props} />
      </CheckboxInputField>
    )
  } else {
    return (
      <div>
        {!props.hide && (
          <div>
            <div
              className={classNames({
                "text-center": props.elementType === "range",
              })}
            >
              {props.label && props.label.trim(" ") && (
                <label className={props.className}>{props.label}</label>
              )}
            </div>
            <div className="form-group">
              <InputElement props={props} />
            </div>
            {buildFormToLabel()}
          </div>
        )}
      </div>
    )
  }
}
export default input
