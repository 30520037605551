/* eslint-disable no-case-declarations */
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const items = [
  { id: 31, formAttributeId: 11, submissionId: 21, value: "Joel" },
  { id: 32, formAttributeId: 12, submissionId: 21, value: "Duhaime" },
  { id: 33, formAttributeId: 11, submissionId: 22, value: "John" },
  { id: 34, formAttributeId: 12, submissionId: 22, value: "Hazlett" },
  { id: 35, formAttributeId: 13, submissionId: 24, value: "106" },
];

const initialState = () => {
  return {
    loading: false,
  };
};

const submissionAnswersAdapter = createEntityAdapter({});

const slice = createSlice({
  name: "submissionsAnswer",
  initialState: submissionAnswersAdapter.getInitialState(initialState()),
  reducers: {
    getSubmissionAnswers(state, action) {
      state.loading = false;
      return submissionAnswersAdapter.addMany(state, items);
    },
    updateSubmissionAnswers(state, action) {
      const updated = action.payload.submissionAnswers.map((a) => {
        if (a.id == null) {
          const id = Math.floor(Math.random() * Math.floor(100000000));
          return { ...a, id };
        } else {
          return a;
        }
      });
      submissionAnswersAdapter.upsertMany(state, updated);
    },
  },
});

export const selectSubmissionAnswersLoading = (state) =>
  state.submissionAnswers.loading;

export const {
  selectById: selectSubmissionAnswerById,
  selectIds: selectSubmissionAnswerIds,
  selectEntities: selectSubmissionAnswerEntities,
  selectAll: selectAllSubmissionAnswers,
  selectTotal: selectTotalSubmissionAnswers,
} = submissionAnswersAdapter.getSelectors((state) => state.submissionAnswers);

export const selectSubmissionAnswersBySubmissionId = createSelector(
  [selectAllSubmissionAnswers, (state, submissionId) => submissionId],
  (answers, submissionId) =>
    answers.filter((answer) => answer.submissionId === submissionId)
);

const { actions, reducer } = slice;
export const { getSubmissionAnswers, updateSubmissionAnswers } = actions;

export default reducer;
