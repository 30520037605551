import React, { useState } from "react"
import Button from "../UI/button/Button"
import DatePicker from "../UI/datePicker"
import FormButton from "../UI/button/FormButton"

const DateFilter = ({ onDateClear, onDateSubmit }) => {
  const [selectedDates, setSelectedDates] = useState({
    selectedStart: null,
    selectedEnd: null,
  })

  const clearSelectedDates = () => {
    onDateClear()
    setSelectedDates({
      selectedStart: null, 
      selectedEnd: null,
    })
  }

  return (
    <div className="col-12">
      <form
        onSubmit={(e) => {
          onDateSubmit(e, selectedDates.selectedStart, selectedDates.selectedEnd)
        }}
      >
        <div className="d-flex flex-row pb-3 px-2 mx-3 justify-content-between">
          <div className="d-flex">
            <div className="d-flex flex-column mr-5">
              <label>From</label>
              <DatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={selectedDates.selectedStart}
                changed={date => setSelectedDates({
                  ...selectedDates,
                  selectedStart: date
                })}
              />
            </div>
            <div className="d-flex flex-column mr-5">
              <label>To</label>
              <DatePicker
                dateFormat={"yyyy-MM-dd"}
                selected={selectedDates.selectedEnd}
                changed={date => setSelectedDates({
                  ...selectedDates,
                  selectedEnd: date
                })}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex align-items-end">
              <FormButton className="mr-2">Apply Filter</FormButton>
              <Button type="danger" clicked={clearSelectedDates}>Clear Filter</Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default DateFilter
