import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Spinner from "../../UI/Spinner";
import FormButton from "../../UI/button/FormButton";
import Input from "../../UI/input/Input";
import Modal from "../../UI/Modal";
import { FORM_ATTRIBUTE_TYPES } from "./Constants";

import EditAttribute from "./EditAttribute";
import {
  addFormAttribute,
  deleteFormAttribute,
  getFormAttributes,
  selectAllFormAttributes,
} from "../../../store/slices/formAttributes";
import { connect } from "react-redux";

class Attributes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attrType: {
        elementType: "select",
        elementConfig: {
          name: "attrType",
          placeholder: "Select Option",
          options: FORM_ATTRIBUTE_TYPES,
          required: true,
          isLoading: false,
          components: {
            IndicatorSeparator: () => {
              return null;
            },
          },
        },
        selectedOption: null,
        label: "Select Form Attribute",
      },
      attrName: {
        elementType: "input",
        elementConfig: {
          required: true,
        },
        value: "",
        label: "Name",
      },
      showModal: false,
      checkFalse: !this.props.loading,
      isChecked: false,
      selectedReels: [],
    };
  }

  componentDidMount() {
    this.props.getFormAttributes();
  }

  selectChangedHandler(event, key) {
    this.setState({
      [key]: {
        ...this.state[key],
        selectedOption: _.isEmpty(event) ? null : event,
      },
    });
  }

  inputChangeHandler = (event, key) => {
    this.setState({ [key]: { ...this.state[key], value: event.target.value } });
  };

  openModal = (currentAttribute) => {
    window.scrollTo(0, 0);
    this.setState({ showModal: true, currentAttribute });
  };

  addAttributeHandler(e) {
    const { form, addFormAttribute } = this.props;

    console.log({ props: this.props });

    e.preventDefault();
    addFormAttribute({
      formId: form.id,
      type: this.state.attrType.selectedOption,
      name: this.state.attrName.value,
    });

    this.resetForm();
  }

  deleteAttributeHandler(e, id) {
    const { deleteFormAttribute } = this.props;

    e.preventDefault();
    deleteFormAttribute({ id });
  }

  resetForm() {
    this.setState({
      attrType: {
        ...this.state.attrType,
        selectedOption: "",
      },
      attrName: {
        ...this.state.attrName,
        value: "",
      },
    });
  }

  render() {
    const { loading, attributes } = this.props;
    let list = "";
    if (loading) {
      list = <Spinner />;
    } else {
      list = (
        <ul className="list-group list-group-flush mt-3">
          <li className="list-group-item">
            <div className="row">
              <div className="col-md-5">
                <span className="font-weight-bold">Type</span>
              </div>
              <div className="col-md-5">
                <span className="font-weight-bold">Name</span>
              </div>
            </div>
          </li>
          {attributes.map((data, index) => (
            <li className="list-group-item" key={index}>
              <div className="row">
                <div className="col-md-5">{data.type}</div>
                <div className="col-md-5">{data.name}</div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-around">
                    <i
                      className="fas fa-pencil-alt cursor"
                      onClick={(event) => this.openModal(data)}
                    />
                    <i
                      className="fas fa-trash"
                      onClick={(e) => this.deleteAttributeHandler(e, data.id)}
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      );
    }

    let showList;
    if (!loading && attributes.length === 0) {
      showList = (
        <div className="col no-crew-container my-5 py-5">
          <h5 className="no-crew-heading text-center font-weight-bold">
            No Form Attributes
          </h5>
        </div>
      );
    } else {
      showList = <>{list}</>;
    }

    return (
      <div>
        <form onSubmit={(e) => this.addAttributeHandler(e)} className="mt-4 ">
          <div className="row m-0">
            <div className="col-md-5">
              <Input
                {...this.state.attrType}
                className="select-attr"
                changed={(event) =>
                  this.selectChangedHandler(event, "attrType")
                }
              />
            </div>
            <div className="col-md-5 short-attr">
              <Input
                {...this.state.attrName}
                changed={(event) => this.inputChangeHandler(event, "attrName")}
              />
            </div>
            <div className="col-md-2 attr-add-button">
              <FormButton disabled={this.state.disabled}>Add</FormButton>
            </div>
          </div>
        </form>

        {showList}
        <Modal
          show={this.state.showModal}
          title="Edit Attribute"
          hide={() => this.setState({ showModal: false })}
        >
          <EditAttribute
            closeModal={() => this.setState({ showModal: false })}
            attribute={this.state.currentAttribute}
          />
        </Modal>
      </div>
    );
  }
}

Attributes.propTypes = {
  submissions: PropTypes.shape({
    submissions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
      })
    ).isRequired,
    totalCount: PropTypes.number.isRequired,
  }).isRequired,
  getFormAttributes: PropTypes.func.isRequired,
  addFormAttribute: PropTypes.func.isRequired,
  deleteFormAttribute: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const attributes = selectAllFormAttributes(state).filter(
    (attr) => attr.formId === ownProps.form.id
  );

  return {
    attributes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormAttributes: (params) => dispatch(getFormAttributes(params)),
    addFormAttribute: (params) => dispatch(addFormAttribute(params)),
    deleteFormAttribute: (params) => dispatch(deleteFormAttribute(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attributes);
