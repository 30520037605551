import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import "./Item.css";
import { clearResponse, deleteForm } from "../../store/actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../configAxios"
import { CSVLink } from "react-csv"

class Item extends Component {
  csvLink = React.createRef();
  state = {
    grant_campaign_id: this.props.match.params.id,
    exportData: ""
  }

  handleDelete = () => {
    let formData = {
      form_id: this.props.item.id,
      content: {
        grant_campaign_id: this.state.grant_campaign_id
      }
    }
    window.confirm("Permanently delete form?") && this.props.deleteFormCall(formData)
  }

  downloadCSV = () => {
    axios
      .get(`/forms/${this.props.item.id}/export_response_data`)
      .then(res => {
        this.setState({
          ...this.state,
          exportData: res.data.json.data
        }, () => { this.csvLink.current.link.click() })
      })
  }

  componentDidUpdate() {
    if ( this.props.response && this.props.response.success) {
      const { message, success } = this.props.response;
      this.props.clearResponse();
      if (success) {
        toast.success(message);
        window.location.reload();
      } else {
        toast.error(message);
      }
    }
  }

  render() {
    const { item } = this.props;
    const formLink = `/campaigns/${this.state.grant_campaign_id}/forms/${this.props.item.id}/edit`

    return (
      <li className="list-group-item project-item">
        <div className="px-3">
          <div className="w-full d-flex flex-row justify-content-between align-items-center">
            <div className="text-info font-weight-normal project-name cursor" onClick={() => this.props.history.push(formLink)}>{item.name}</div>
            <span>
              <span className="mr-1 fas fa-pen cursor" data-toggle="tooltip" data-placement="top" title="Edit" onClick={() => this.props.history.push(formLink)} />
              <span className="mx-1 fas fa-trash cursor" data-toggle="tooltip" data-placement="top" title="Delete" onClick={this.handleDelete} />
              <span className="ml-1 fas fa-file-download cursor" data-toggle="tooltip" data-placement="top" title="Export Responses" onClick={this.downloadCSV} />
            </span>
          </div>
            <div className="text-dark small mt-1">
              <div className="project-creator">
                {item.creator} {item.type && <span> | {item.type}</span>}
              </div>
            </div>
            <div className="mt-2 text-justify text-gray-300 cursor" onClick={() => this.props.history.push(formLink)}>
              {item.description}
            </div>
          </div>
        <CSVLink
          data={this.state.exportData}
          filename={`${this.props.item.name}_responses.csv`}
          className="hidden"
          ref={this.csvLink}
          target="_self"
        />
      </li>
    );
  }
}

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    response: state.response,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFormCall: (id) => dispatch(deleteForm(id)),
    clearResponse: () => dispatch(clearResponse()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Item));
