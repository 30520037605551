import React, { useState } from 'react'
import Input from "../../../../../UI/input/Input";

export default function JuryMemberReviewItem({ juryMember, review }) {
  const [isToggled, setIsToggled] = useState(false)

  const ChevronToggle = () => {
    const direction = isToggled ? "up" : "down"
    return <span className={`fas fa-sm fa-chevron-${direction}`}/>
  }
  
  return (
    <>
      <tr className={`p-1 border-top ${review ? "" : "text-muted"}`} 
        style={review ? {} : {"opacity": "50%"}}
      >
        <td className="font-weight-bold mb-0">{juryMember.full_name}</td>
        <td><a className="mb-0" href={`mailto: ${juryMember.email}`}>{juryMember.email}</a></td>
        <td className="mb-0"><span className="font-weight-bold">Rating: </span>{review && review.rating || "N/A"}</td>
          <td 
            className={`cursor mb-0 font-weight-bold ${review ? "text-primary" : ""}`}
            onClick={() => review && setIsToggled(!isToggled)}
          >
            <span className="font-weight-bold mr-2"><ChevronToggle /></span>
              Comments
          </td>
      </tr>
      { isToggled && review &&
        <tr>
          <td colSpan="4">
            <Input
              elementType="textarea"
              elementConfig={{
                name: 'comments',
                rows: 3,
              }}
              value={review.comments}
              disabled={true}
            />
          </td>
        </tr>
      }
    </>
  )
}
