import React, { Component } from "react";
import Input from "../../UI/input/Input";
import "./index.css";

class AdditionalInformation extends Component {
  state = {
    notes: "",
    uniqueName: ""
  };

  componentDidMount() {
    if (this.props.editUser) {
      this.setState({ ...this.state, ...this.props.formData });
    }
  }

  inputChangeHandler = (event, key) => {
    this.props.inputChangeHandler(event, "text", key);
    this.setState({ [key]: event.target.value });
  };

  isAccountingUser = () => (localStorage.getItem("roleLevel") === "accounting") ? true : false

  render() {
    return (
      <div className="mb-2 additional-info">
        <button
          className="btn header-btn"
          onClick={() => this.props.tabHandler("additionalInfo")}
          type="button"
          data-toggle="collapse"
          data-target=".additionalInfo"
          aria-expanded="false"
          aria-controls="multiCollapseExample1 multiCollapseExample2"
        >
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Additional Information</p>
            <i
              className={
                this.props.selectedTabs.includes("additionalInfo")
                  ? "fas fa-minus-circle text-primary fa-2x"
                  : "fas fa-plus-circle text-primary fa-2x"
              }
            />
          </div>
        </button>
        <div className="row">
          <div className="col">
            <div className="collapse additionalInfo" id="multiCollapseExample1">
              <div className="row mt-2">
                <div className="col-md-6">
                  {" "}
                  <p>Photo</p>
                  <input
                    type="file"
                    className="image-input"
                    onChange={e => this.props.handleChange(e.target.files)}
                  />
                </div>
                {this.isAccountingUser() &&
                  <div className="col-md-6">
                    {" "}
                    <p>Unique/Quickbooks Name</p>
                    <Input
                      elementType={"input"}
                      value={this.state.uniqueName}
                      changed={event =>
                        this.inputChangeHandler(event, "uniqueName")
                      }
                    />
                  </div>
                }
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  {" "}
                  <p>Notes</p>
                  <Input
                    elementType={"textarea"}
                    elementConfig={{ rows: "4" }}
                    value={this.state.notes}
                    changed={event => this.inputChangeHandler(event, "notes")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdditionalInformation;
