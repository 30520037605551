import React, { Component } from 'react'
import { toast } from "react-toastify"
import { title } from 'change-case'
import { connect } from "react-redux";

import Input from "../../UI/input/Input"
import Spinner from "../../UI/Spinner"
import Modal from "../../UI/Modal"
import Button from "../../UI/button/Button"
import FormButton from "../../UI/button/FormButton"
import ToggleSwitch from "../../common/toggleSwitch";
import errorHandler from "../../errorPages/UserNotAuthorized";
import axios from "../../../configAxios"
import langUtils from '../../../utils/langUtils'

class EmailAdresses extends Component {
  constructor(props) {
    super(props);
    this.timeOut = 0;
    this.signal = axios.CancelToken.source();
    this.state = {
      emailList: [],
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "Enter Email Address",
          required: true
        },
        label: "Email Address",
        value: ""
      },
      showAddEmailModal: false,
      disableEmail: false,
      emailSuccess: false,
      useVerifiedEmails: false,
      disableVerifiedEmailToggle: false,
      hideToggle: true,
      firstVerifiedEmail: "",
    }
  }

  componentDidMount = () => {
    this.setState({ hideToggle: true });
    this.getAllEmails(true);
  }

  componentWillUnmount() {
    if (this.timeOut)
      clearInterval(this.timeOut)
    this.signal.cancel("Request is being cancelled");
  }

  getAllEmails = (fromMounting) => {
    this.setState({ loading: true });
    axios.get("/organization_communication_emails", { cancelToken: this.signal.token })
      .then(res => {
        const { 
          organization_communication_emails: orgEmails 
        } = res.data;
        const verifiedEmails = orgEmails.filter(
          email => email.is_verified
        );
        this.setState({
            loading: false,
            emailList: res.data.organization_communication_emails
              ? res.data.organization_communication_emails
              : [],
            useVerifiedEmails: 
              !!(res.data.meta && res.data.meta.use_verified_email),
            ...fromMounting && {
              hideToggle: false,
            firstVerifiedEmail: 
              verifiedEmails.length
               ? verifiedEmails[0].email
               : ""
          }
        })
      })
  }

  emailChangeHandler = e => {
    this.setState({
      email: {
        ...this.state.email,
        value: e.target.value
      }
    })
  }

  resetEmailModal = () => {
    if (this.state.emailSuccess)
      this.getAllEmails();

    this.setState({
      showAddEmailModal: false,
      disableEmail: false,
      email: {
        ...this.state.email,
        value: ""
      }
    }, () => {
      if (this.state.emailSuccess) {
        this.timeOut = setTimeout(() => {
          this.setState({ emailSuccess: false })
        }, 150)
      }
    })
  }

  submitHandler = e => {
    e.preventDefault();
    this.setState({ disableEmail: true })
    axios.post("/organization_communication_emails", {
      email: this.state.email.value
    }, {
      cancelToken: this.signal.token,
    })
      .then(res => {
        if (res.data.json.success) {
          this.setState({ emailSuccess: true })
        } else {
          if (Object.keys(res.data.json).includes("errors")) {
            const { errors } = res.data.json;
            if (typeof errors === "string") {
              toast.error(res.data.json.errors);
            } else if (typeof errors === "object") {
              Object.keys(errors).forEach(error => {
                toast.error(`${title(error)} ${errors[error]}`)
              })
            }
          }
          if (Object.keys(res.data.json).includes("error")) {
            toast.error(res.data.json.error)
          }
          this.setState({ disableEmail: false })
        }
      })
      .catch(err => this.setState({ disableEmail: false }));
  }

  deleteHandler = (id, email) => {
    const delEmailConfirmation = window.confirm("Are you sure you want to delete this email address?")
    if (delEmailConfirmation) {
      axios.delete(`/organization_communication_emails/${id}`, {
        cancelToken: this.signal.token,
        params: {
          email
        }
      })
        .then(res => {
          if (res.data.json.success) {
            toast.success("Successfully deleted email address");
            this.getAllEmails();
          } else {
            errorHandler(res);
          }
        })
    }
  }

  useVerifiedEmailHandler = checked => {
    const {
      useVerifiedEmails,
      firstVerifiedEmail
    } = this.state;
    const { auth } = this.props;
    this.setState({ disableVerifiedEmailToggle: true })
    axios.put(`/organizations/${auth.organizationId}`, {
      use_verified_email: checked
    }, { cancelToken: this.signal.token })
      .then(res => {
        if (res.data.json.success) {
          this.setState({
            useVerifiedEmails: checked,
            disableVerifiedEmailToggle: false,
          });
          toast.success("Settings updated successfully.");
          if (firstVerifiedEmail && checked)
            toast.success(`${firstVerifiedEmail} has been set for all communication emails`);
        } else {
          errorHandler(res);
          this.setState({
            useVerifiedEmails,
            disableVerifiedEmailToggle: false,
          })
        }
      }).catch(err => {
        this.setState({
          useVerifiedEmails,
          disableVerifiedEmailToggle: false,
        }); 
      })
  }

  render() {
    const {
      emailList,
      loading,
      showAddEmailModal,
      email,
      disableEmail,
      emailSuccess,
      useVerifiedEmails,
      disableVerifiedEmailToggle,
      hideToggle
    } = this.state;
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 my-3 align-self-center">
              <div className={`${hideToggle ? "d-none": "d-flex" }`}>
                <ToggleSwitch 
                  checked={useVerifiedEmails}
                  onChange={this.useVerifiedEmailHandler}
                  disabled={disableVerifiedEmailToggle || loading}
                />
                <div className="ml-3 font-weight-bold">
                  Use verified email addresses for the communication emails?
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-3 text-right">
              <Button
                type="primary"
                clicked={() => this.setState({ showAddEmailModal: true })}
                disabled={loading}
              >
                {langUtils("txt_global_add_new", "Add New")}
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ul className="list-group list-group-flush border-top mt-2">
              <li className="list-group-item">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="font-weight-bold">
                      Email
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="font-weight-bold">
                      Status
                    </div>
                  </div>
                  <div className="col-lg-3 text-center">
                    <div className="font-weight-bold">
                      Delete
                    </div>
                  </div>
                </div>
              </li>
              {loading
                ? <Spinner /> :
                (
                  <>
                    {emailList && emailList.length ?
                      (
                        <>
                          {emailList.map(email => (
                            <li 
                              key={email.id}
                              className="list-group-item rounded-0 border-top"
                            >
                              <div className="row">
                                <div className="col-lg-5">
                                  {email.email}
                                </div>
                                <div className="col-lg-4">
                                  {
                                    email.is_verified 
                                      ? "Verified"
                                      : "Pending"
                                  }
                                </div>
                                <div className="col-lg-3 text-center">                                    
                                  <span 
                                    onClick={() => email.can_delete && this.deleteHandler(email.id, email.email)} 
                                    className="cursor"
                                    style={{ cursor: email.can_delete ? "pointer" : "no-drop", opacity: email.can_delete ? 1 : 0.5 }}
                                  >
                                    <i className="fas fa-trash" />
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </>   
                      ) : (
                        <div className="text-center h4 my-5 py-5">
                          No Email Addresses Found
                        </div>
                      )
                    }
                  </>
                )
              }
            </ul>
          </div>
        </div>
        <Modal
          className="custom-modal"
          show={showAddEmailModal}
          closeButton
          closeModal={this.resetEmailModal}
          hide={this.resetEmailModal}
          title="Add New Email Address"
        >
          {!emailSuccess
            ? (<form className="mt-4" onSubmit={this.submitHandler}>
              <Input {...email} changed={this.emailChangeHandler} />
              <div className="text-center">
                <Button
                  type="secondary"
                  className="mr-3"
                  clicked={this.resetEmailModal}
                >
                  Cancel
                </Button>
                <FormButton
                  disabled={disableEmail}
                >
                  Verify
                </FormButton>
              </div>
            </form>)
            : (
              <div className="p-4">
                You have successfully sent a verification email to <span className="font-weight-bold">{email.value}</span>. It may take up to an hour for the verification email to arrive in this user's inbox. The address has been added to the list of Verified Identities with a status of "pending verification" and will be marked as "verified" when the user opens the email message and completes the verification process.
              </div>
            )
          }
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(
  mapStateToProps
)(EmailAdresses);
