import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from "../../configAxios";
import Spinner from '../UI/Spinner';
import Pagination from "../UI/Pagination";
import Button from '../UI/button/Button';
import langUtils from '../../utils/langUtils';

import SuperAdminItem from './SuperAdminItem';

class SuperAdminList extends Component {
  signal = axios.CancelToken.source();
  state = {
    pagination: {
      currentPage: 0,
      totalResults: 0
    },
    filters: {
      perPage: 10
    },
    users: [],
    organization: '',
    loading: false
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({ ...this.state, loading: true })
    axios.get(`/organizations/${this.props.match.params.id}`, { cancelToken: this.signal.token })
      .then(res => {
        this.setState({ ...this.state, organization: res.data.organization })
      })
      .catch(error => error);
    this.updateData()
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.pagination.currentPage !== this.state.pagination.currentPage) {
      this.setState({ ...this.state, loading: true });
      this.updateData();
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  updateData = () => {
    this.setState({ ...this.state, loading: true })
    let params = {
      type: 'super_admin',
      organization_id: this.props.match.params.id,
      page: this.state.pagination.currentPage + 1,
      per_page: this.state.filters.perPage
    }
    axios.get('/users/', { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          users: res.data.users,
          pagination: {
            ...this.state.pagination,
            totalResults: res.data.meta.total_count
          },
          loading: false
        })
      })
      .catch(error => error);
  }

  resetPassword = (id) => {
    axios.post('/users/reset_password', { id })
      .then(res => {
        if (res.data.json.success) {
          toast.success(res.data.json.message)
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      })
      .catch(error => error);
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState({
      ...this.state,
      pagination: {
        ...this.state.pagination,
        currentPage: page.selected
      }
    })
  }

  deleteSuperAdminHandler = (id, name) => {
    let response = window.confirm(`Are you sure you want to delete ${name}?`);
    if (response) {
      axios.delete("/users", {
        params: {
          id,
          organization_id: this.props.match.params.id
        }
      })
        .then(res => {
          if (res.data.json.success) {
            toast.success("Super admin successfully deleted.");
            this.updateData();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(key => {
                toast.error(`${title(key)} ${res.data.json.errors[key]}`);
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  render() {
    let { totalResults, currentPage } = this.state.pagination;
    let perPage = this.state.filters.perPage;

    let from = (perPage * (currentPage + 1));
    from -= perPage;
    from++;
    let to = perPage * (currentPage + 1);
    if (to > totalResults) {
      to = totalResults;
    }

    let showing = `Showing ${from} to ${to} of ${totalResults} entries`;
    return (
      <div className="container w-100">
        <div className="row mb-4">
          <div className="col-lg-6">
            {
              this.state.organization === '' ? '' : (
                <h3>{this.state.organization.full_name} - Super Admins</h3>
              )
            }
          </div>
          <div className="col-lg-6 text-lg-right text-center">
            <div
              className="mr-3 btn btn-link"
              onClick={() => this.props.history.goBack()}
            >
              {"<< Back"}
            </div>
            {
              this.state.loading ? '' :
                <Link to={`/organizations/${this.props.match.params.id}/super_admins/new`}>
                  <Button type="success">
                    {langUtils("txt_global_add_new", "Add New")}
                  </Button>
                </Link>
            }
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body px-4 pt-4">
                {
                  this.state.loading ? (
                    <div className="card-text">
                      <div className="row">
                        <div className="col-12">
                          <Spinner className="h-60vh" />
                        </div>
                      </div>
                    </div>
                  ) : (
                      <div className="card-text">
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover">
                            <thead className="text-muted border-top">
                              <tr>
                                <th scope="col">
                                  Email
                                </th>
                                <th scope="col">
                                  Name
                                </th>
                                <th scope="col" className="w-20">
                                  Last Login
                                </th>
                                <th>
                                  Login URL 
                                </th>
                                <th>
                                  Profile
                                </th>
                                <th scope="col" className="w-12">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.users.map(user => (
                                  <SuperAdminItem key={user.id} user={user} resetPassword={this.resetPassword}
                                    deleteSuperAdminHandler={this.deleteSuperAdminHandler} 
                                    organizationId={this.props.match.params.id} />
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-lg-6 text-lg-left text-center font-weight-bold">
                              <div className="mt-2 pt-1 mb-3">
                                {showing}
                              </div>
                            </div>
                            <div className="col-lg-6 text-lg-right text-center">
                              <Pagination
                                pageCount={
                                  this.state.pagination.totalResults /
                                  this.state.filters.perPage
                                }
                                currentPage={this.state.pagination.currentPage}
                                handlePageClick={this.paginationHandler}
                                style={{ marginRight: "-20px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SuperAdminList;
