import React, { Component } from "react";
import { toast } from "react-toastify";

import axios from "../../../configAxios";
import Input from "../../UI/input/Input";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import DatePicker from "../../UI/datePicker"

class Payment extends Component {
  signal = axios.CancelToken.source();
  state = {
    paymountAmount: {
      elementType: "input",
      elementConfig: {
        name: "paymountAmount",
        required: true,
        type: "number",
        min: 0,
        max: 99999999,
        step: "0.01"
      },
      value: ""
    },
    paymentType: {
      elementType: "select",
      elementConfig: {
        name: "paymentType",
        required: true,
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    notes: {
      elementType: "textarea",
      elementConfig: {
        name: "notes",
        rows: "6",
        required: true
      },
      value: `Invoice #${this.props.invoiceReference}`
    },
    paid: {
      elementType: "input",
      elementConfig: {
        name: "paid",
        type: "checkbox"
      },
      value: false,
      label: "Mark this Invoice Paid?"
    },
    paymentDate: new Date(),
    disabled: false
  };

  componentDidMount() {
    axios
      .get("/payments/formdata", {
        params: {
          invoice_id: this.props.invoiceId,
          refund: false,
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        const { payment_types } = res.data.json;
        this.setState({
          paymentType: {
            ...this.state.paymentType,
            elementConfig: {
              ...this.state.paymentType.elementConfig,
              options: payment_types.map(type => {
                return { label: type[0], value: type[1] };
              })
            }
          }
        });
      });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, type, key) => {
    const inputValue = {};
    if (type === "select") {
      inputValue["selectedOption"] = event;
    } else if (type === "checkbox") {
      inputValue["value"] = !this.state[key].value;
    } else {
      inputValue["value"] = event.target.value;
    }
    this.setState({
      [key]: {
        ...this.state[key],
        ...inputValue
      }
    });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const { accountId, invoiceType } = this.props;
    const paymentData = {
      invoice_id: this.props.invoiceId,
      payment_amount: this.state.paymountAmount.value,
      payment_type_id: this.state.paymentType.selectedOption.value,
      notes: this.state.notes.value,
      invoice_mark_paid: this.state.paid.value,
      payment_date: this.state.paymentDate
    };
    if (accountId && invoiceType !== "Floating") {
      paymentData["account_id"] = accountId;
    }
    axios.post("/payments", paymentData).then(res => {
      if (res.data.json.success) {
        toast.success("Payment Success");
        this.props.reload();
        this.props.closeModal();
      } else {
        toast.error("Payment Failed");
        this.setState({ disabled: false })
      }
    });
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <p>Payment Amount</p>
        <Input
          {...this.state.paymountAmount}
          changed={event =>
            this.inputChangedHandler(event, "text", "paymountAmount")
          }
        />
        <p>Payment Type</p>
        <Input
          {...this.state.paymentType}
          changed={event =>
            this.inputChangedHandler(event, "select", "paymentType")
          }
        />
        <div className="my-3">
          <label>Payment Date</label>
          <DatePicker
            dateFormat="yyyy.MM.dd"
            selected={this.state.paymentDate}
            changed={event => this.setState({ paymentDate: event })}
          />
        </div>
        <p>Notes: </p>
        <Input
          {...this.state.notes}
          changed={event => this.inputChangedHandler(event, "text", "notes")}
        />
        <Input
          {...this.state.paid}
          changed={event => this.inputChangedHandler(event, "checkbox", "paid")}
        />
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            className="float-left mr-3"
            clicked={() => this.props.closeModal()}
          >
            Cancel
          </Button>
          <FormButton disabled={this.state.disabled}>Submit</FormButton>
        </div>
      </form>
    );
  }
}
export default Payment;
