import React, { Component } from "react";
import { toast } from "react-toastify";
import dateFns from "date-fns";

import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import axios from "../../configAxios";

class UpdateThirdPartyPayment extends Component {
  state = {
    asset: this.props.payment.booking.asset.name,
    owner: this.props.payment.asset_owner,
    amount: this.props.payment.amount.replace("$", ""),
    hasPaid: true,
    notes: this.props.payment.notes,
    amountDue: this.props.payment.calculated_amount_due,
    bookingDate: dateFns.format(this.props.payment.booking.start, "MMM DD / YY"),
    disabled: false
  };

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  inputChangedHandler = (event, key, type) => {
    if (type === "checkbox") {
      this.setState({ [key]: event });
    } else {
      this.setState({ [key]: event.target.value });
    }
  };

  updatePayment = () => {
    this.setState({ disabled: true })
    const { amount, notes, hasPaid } = this.state;
    const data = {
      amount,
      notes,
      mark_paid: hasPaid ? 1 : 0
    };
    axios
      .put(`/third_party_payments/${this.props.payment.id}`, data)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Payment updated successfully.");
          this.props.getPayments();
          this.props.closeModal();
        } else {
          toast.error("Failed to update");
        }
      });
  };

  render() {
    return (
      <div className="billing">
        {!this.state.loading && (
          <div>
            <div className="row my-3">
              <div className="col-md-5">Asset</div>
              <div className="col-md-6 update-value">
                {this.state.asset} - {this.state.bookingDate}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-5">Owner</div>
              <div className="col-md-6 update-value">{this.state.owner}</div>
            </div>
            <div className="row my-3">
              <div className="col-md-5">Calculated Amount Due </div>
              <div className="col-md-6 update-value">
                {this.state.amountDue}
              </div>
            </div>
            <div>
              {" "}
              <Input
                elementType={"input"}
                value={this.state.amount}
                elementConfig={{ type: "number", step: "0.01" }}
                style={{ paddingLeft: "3%" }}
                changed={e => this.inputChangedHandler(e, "amount")}
                label={"Payment to Record"}
              />
            </div>
            <Input
              elementType={"textarea"}
              elementConfig={{ rows: "6" }}
              value={this.state.notes}
              changed={e => this.inputChangedHandler(e, "notes")}
              label={"Notes"}
            />
            <div
              className="row m-0 mb-4"
              onClick={() =>
                this.inputChangedHandler(
                  !this.state["hasPaid"],
                  "hasPaid",
                  "checkbox"
                )
              }
            >
              <div className="custom-checkbox">
                <div
                  className="box"
                  style={{
                    backgroundColor: this.state.hasPaid ? "rgb(117,0,0)" : "white"
                  }}
                >
                  <i className="fas fa-check check-icon" />
                </div>
                <p className="ml-3 cursor">Mark Paid</p>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                type="primary"
                clicked={this.updatePayment}
                style={{ marginRight: "10px", cursor: this.state.disabled ? "no-drop" : "pointer" }}
                disabled={this.state.disabled}
              >
                Save
              </Button>
              <Button type="secondary" clicked={this.props.closeModal}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UpdateThirdPartyPayment;
