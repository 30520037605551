import React from 'react'

function ApplicationFormsTable({ formsList, grantApplication }) {

  const getApplicationForForm = (formId) => {
    return grantApplication.application_forms.find(application => application.form_id === formId) || null
  }

  const ApplicationFormItem = ({ form, applicationForm }) => { 
    return (
      <tr>
        <td>{grantApplication.updated_at.match(/^[^T]*/)}</td>
        <td>{grantApplication.status}</td>
        <td>{form.name}</td>
        <td>{applicationForm?.updated_at.match(/^[^T]*/)}</td>
        <td>
          <a className="text-info cursor" href={`${window.location.pathname}/application_forms/${applicationForm?.id}`}>View</a>
        </td>
      </tr>
    )
  }

  return (
    <div>
      <table className="w-100 border-left border-right mb-4">
        <thead className="text-center border-top">
          <tr>
            <th>Submitted</th>
            <th>Status</th>
            <th>Form</th>
            <th>Completed</th>
            <th>Show</th>
          </tr>
        </thead>
        <tbody className="text-center border-top">
          {
            formsList.map((form, index) => 
              <ApplicationFormItem key={index} form={form} applicationForm={getApplicationForForm(form.id)} />
            )
          }
        </tbody>
        <tfoot className="border-bottom">
          <tf />
        </tfoot>
      </table>
    </div>
  )
}

export default ApplicationFormsTable
