import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import NoUser from "../../assests/images/user-icon.jpg";
import "./experts.css";

class ExpertsItem extends Component {
  render() {
    const tooltip = (
      <OverlayTrigger
        placement={"left"}
        overlay={
          <Popover
            style={{
              background: "white",
              border: "none",
              borderRadius: "0px",
              width: "300px"
            }}
          >
            <div className="list-group">
              {this.props.expert.user_skills &&
                this.props.expert.user_skills.map((userSkills, index) => (
                  <div className="list-group-item rounded-0 py-1 bg-light" key={index}>
                    <div className="row">
                      <div className="col-10 font-14">
                        {userSkills.expertise_area}
                      </div>
                      <div className="col-1 font-weight-bold font-14">
                        {userSkills.skill_level}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Popover>
        }
      >
        <h4>
          <i className="fa fa-ellipsis-v cursor text-muted p-3" />
        </h4>
      </OverlayTrigger>
    );

    const { expert } = this.props;
    const image = expert.photo ? expert.photo : NoUser;
    return (
      <div className="list-group-item">
        <div className="row">
          <div className="col-md-2">
            <div className="rounded-circle mx-auto d-block bg-cover" style={{
              width: 115,
              height: 115,
              backgroundImage: `url(${image})`
            }} />
          </div>
          <div className="col-md-10 mt-1">
            <div className="pl-5 pr-4">
              <div className="row">
                <div className="col-11 text-primary font-weight-bold mb-4">
                  <Link
                    to={`/users/${expert.id}/summary`}
                    className="text-info font-weight-bold username"
                  >
                    {expert.organization_or_name}
                  </Link>
                </div>
                <div className="col-1">{tooltip}</div>
              </div>
              <div style={{ marginTop: "-10px" }}>
                <span className="font-weight-bold">Mobile: </span>{" "}
                {expert.mobile_phone ? expert.mobile_phone : expert.home_phone ? expert.home_phone : null}
              </div>
              <div>
                <span className="font-weight-bold mt-5">Email: </span>{" "}
                {expert.email}
              </div>
            </div>

            <div />
          </div>
        </div>
      </div>
    );
  }
}

export default ExpertsItem;
