import React from "react"
import { withRouter } from "react-router-dom";
import BackLink from "../UI/button/BackLink"
import Input from "../UI/input/Input"
import FormButton from "../UI/button/FormButton"

const FormDetails = ({ history, formDescription, formName, inputChangedHandler, setState, campaignID, edited }) => {

  const backUrl = `/campaigns/${campaignID}/forms`
  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-3">
        <h3 className="mb-0">Form Details</h3>
        <BackLink history={history} backUrl={backUrl}>
          Back to Applications
        </BackLink>
      </div>
      <Input
        key={formName.name}
        {...formName}
        style={{ paddingLeft: "11.25px"}}
        changed={(event) =>
          inputChangedHandler(event, formName.name, "createForm")
        }
        onInputChange={(event) =>
          setState({ enteredUserCharacters: event })
        }
      />
      <Input
        key={formDescription.name}
        {...formDescription}
        changed={(event) =>
          inputChangedHandler(event, formDescription.name, "createForm")
        }
        onInputChange={(event) =>
          setState({ enteredUserCharacters: event })
        }
      />
      <div className="text-right mb-4">
        <FormButton disabled={!edited}>
          Update Form Details
        </FormButton>
      </div>
    </>
  )
}

export default withRouter(FormDetails)
