import React, { Component } from "react";
import { toast } from "react-toastify";
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import "./Account.css";

class Account extends Component {
  signal = axios.CancelToken.source();
  state = {
    userName: {
      elementType: "select",
      elementConfig: {
        name: "userName",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        isLoading: true
      },
      selectedOption: null
    },
    downloading: false,
    disabled: true
  };

  componentDidMount() {
    axios
      .get("/reports/account/formdata", { cancelToken: this.signal.token })
      .then(res => {
        const options = res.data.json.members.map(member => {
          return { label: member.login, value: member.id };
        });
        this.setState({
          userName: {
            ...this.state.userName,
            elementConfig: {
              ...this.state.userName.elementConfig,
              options,
              isLoading: false
            }
          },
          disabled: false
        });
      });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["selectedOption"] = event;

    const updatedControls = {
      ...this.state[key],
      ...inputValue
    };

    this.setState({ [key]: updatedControls });
  };

  downloadReport = async event => {
    event.preventDefault();
    this.setState({ disabled: true })
    try {
      if (this.state.downloading) return;
      this.setState({ downloading: true });

      let report = await axios.get("/reports/account/download", {
        params: {
          id: "AccountReport",
          "report_options[user_id]": this.state.userName.selectedOption.value
        },
        cancelToken: this.signal.token
      });
      if (report.data.json.generating) {
        toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
        let timerId = setInterval(
          () => this.checkDownloadProgress(report.data.json.id),
          10000
        );
        this.setState({
          timerId,
          userName: {
            ...this.state.userName,
            selectedOption: null
          },
          disabled: false
        });
      }
    } catch (err) {
      this.setState({ downloading: false });
      console.log(err);
    }
  };

  checkDownloadProgress = async id => {
    try {
      let progress = await axios.get("/reports/account/check_generating", {
        params: { id },
        cancelToken: this.signal.token
      });
      if (typeof progress.data.json === "string") {
        clearInterval(this.state.timerId);
        this.setState({
          downloading: false
        });
        toast.success("File successfully downloaded");
        window.open(progress.data.json, "_blank");
        return;
      }
      if (progress.data.json.success === false) {
        toast.success("Generating report in background");
      }
    } catch (e) {
      toast.error("Downloading failed");
      clearInterval(this.state.timerId);
      this.setState({ downloading: false });
    }
  };

  render() {
    return (
      <div className="container reports-account w-100">
        <div className="row mb-4">
          <div className="col-12">
            <h3>Account Report</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text p-3">
                  <form onSubmit={this.downloadReport}>
                    <div className="row">
                      <div className="col-lg-4">
                        <label>Select Username</label>
                        <Input
                          {...this.state.userName}
                          changed={event =>
                            this.inputChangedHandler(event, "userName")
                          }
                        />
                      </div>
                      <div className="col button">
                        <FormButton className="build-report" disabled={this.state.disabled}>Build Report</FormButton>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
