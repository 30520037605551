import React, { useEffect, useState } from 'react'

const StatItem = ({ stat, onChange }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (stat?.stat_value) setValue(stat.stat_value)
  }, [stat])

  const onInputChange = (e) => {
    onChange({ id: stat.id, stat_value: e.target.value, stat_notes: stat.stat_notes })
    setValue(e.target.value)
  }

  return (
    <>
      {stat?.stat_name !== 'Notes' && (
        <tr>
          <td className='w-70'>
            {stat?.stat_name}
          </td>
          <td className='w-30'>
            <input
              id="stat-name"
              type='number'
              step={1}
              min={0}
              name="stat-name"
              placeholder="0"
              className="form-control"
              disabled={stat.stat_name === 'Number of Events'}
              value={value}
              onChange={onInputChange}
            />
          </td>
        </tr>
      )}
    </>
  )
}

export default StatItem
