import React, { Component } from "react"
import { title } from "change-case"
import { toast } from "react-toastify"

import axios from "../../../configAxios"
import Button from "../../UI/button/Button"
import Spinner from "../../UI/Spinner"
import ToggleSwitch from "../../common/toggleSwitch"
import langUtils from "../../../utils/langUtils"
import translateModuleName from "../../../utils/translateModuleName"

class GeneralSettings extends Component {
  signal = axios.CancelToken.source()
  timeout = 0

  state = {
    organizationModule: [],
    loading: true,
  }

  componentDidMount() {
    this.getOrganizationModule()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getOrganizationModule = () => {
    axios
      .get("/organization_modules", { cancelToken: this.signal.token })
      .then((res) => {
        const tempUserTabs = []
        const tempProjectTabs = []
        const projectTabs = []
        const organizationModules = []
        res.data.organization_modules.forEach((module) => {
          if (module.is_user) {
            tempUserTabs.push(module)
            return
          } else if (module.is_project) {
            tempProjectTabs.push(module)
            return
          }
          organizationModules.push(module)
        })
        const userTabs = []
        const userTabNamesOrder = [
          "UserSummary",
          "UserBooking",
          "UserProject",
          "UserProgram",
          "UserVolunteerTicket",
          "UserAccount",
        ]
        userTabNamesOrder.forEach((name, index) => {
          const indexOfTab = tempUserTabs.findIndex((el) => el.name === name)
          
          if (indexOfTab > -1) {
            userTabs[index] = tempUserTabs[indexOfTab]
          }
        })

        const projectTabNamesOrder = [
          "ProjectReels",
          "ProjectCollections",
          "ProjectLongAttributes",
          "ProjectShortAttributes",
          "ProjectCrewRoles",
          "ProjectBookings",
          "ProjectSubmissions",
        ]

        projectTabNamesOrder.forEach((name, index) => {
          const indexOfTab = tempProjectTabs.findIndex((el) => el.name === name)
          if (indexOfTab > -1) {
            projectTabs[index] = tempProjectTabs[indexOfTab]
          }
        })
        
        this.setState({
          organizationModule: organizationModules,
          userTabs: userTabs,
          projectTabs: projectTabs,
          loading: false,
        })
      })
      .catch((error) => error)
  }

  setOrganizationModule = (index, key) => {
    const updatedModule = this.state[key]
    updatedModule[index].enabled = !updatedModule[index].enabled
    this.setState({ [key]: updatedModule })
  }

  updateOrganizationModule = () => {
    const activeOrganizationModuleIds = []
    const inactiveOrganizationModuleIds = []
    const organizationModules = [
      ...this.state.organizationModule,
      ...this.state.userTabs,
      ...this.state.projectTabs,
    ]
    Object.keys(organizationModules).forEach((key) => {
      if (organizationModules[key].enabled) {
        activeOrganizationModuleIds.push(organizationModules[key].id)
        return
      }
      inactiveOrganizationModuleIds.push(organizationModules[key].id)
    })
    axios
      .put("/organization_modules/update_organization_modules", {
        cancelToken: this.signal.token,
        active_ids: { ids: activeOrganizationModuleIds },
        inactive_ids: { ids: inactiveOrganizationModuleIds },
      })
      .then((res) => {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          window.location.reload()
        }, 4000)
        toast.success("Module/Menu manager successfully updated.")
      })
  }

  tabs = (userOrProjectTabs) => {
    return (
      <div>
        {userOrProjectTabs.map((userOrProjectTab, userOrProjectTabIndex) => {
          const substring = userOrProjectTab.is_user
            ? userOrProjectTab.name.lastIndexOf("User") + 4
            : userOrProjectTab.name.lastIndexOf("Project") + 7
          return (
            <ul key={userOrProjectTabIndex}>
              <div className="my-2 d-flex">
                <ToggleSwitch
                  checked={userOrProjectTab.enabled}
                  onChange={() =>
                    this.setOrganizationModule(
                      userOrProjectTabIndex,
                      `${userOrProjectTab.is_user ? "user" : "project"}Tabs`
                    )
                  }
                />
                <label className="ml-3">
                  {translateModuleName(
                    langUtils(
                      userOrProjectTab.language_util_key,
                      `${title(
                        userOrProjectTab.name.substr(substring).split(" ")[0]
                      )}`
                    )
                  )}
                </label>
              </div>
            </ul>
          )
        })}
      </div>
    )
  }

  render() {
    const organization_modules =
      this.state.organizationModule &&
      this.state.organizationModule.map((organization_module, index) => {
        return (
          <div className="row" key={index}>
            <div className="col-12">
              <div
                className={
                  ["User", "Project"].includes(organization_module.name)
                    ? "my-2 align-items-center"
                    : "my-2 d-flex align-items-center"
                }
              >
                <ToggleSwitch
                  checked={organization_module.enabled}
                  onChange={() =>
                    this.setOrganizationModule(index, "organizationModule")
                  }
                />
                <label className="ml-3">
                  {langUtils(
                    organization_module.language_util_key,
                    `${title(organization_module.name)}`
                  )}
                </label>
                {organization_module.name === "Project" &&
                  organization_module.enabled && this.state.projectTabs.length > 0 &&
                  this.tabs(this.state.projectTabs)}
                {organization_module.name === "User" && 
                  organization_module.enabled && this.state.userTabs.length > 0 &&
                  this.tabs(this.state.userTabs)}
              </div>
            </div>
          </div>
        )
      })
    return (
      <div className="my-3 mx-4">
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <div className="my-4 border-bottom">
              <h5 className="font-weight-bold">Modules and Navigation</h5>
            </div>
            <div>{organization_modules}</div>
            <div className="d-flex mt-3 justify-content-left">
              <Button
                type="primary"
                className="mt-1 mb-2"
                clicked={() => this.updateOrganizationModule()}
              >
                Apply changes
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default GeneralSettings
