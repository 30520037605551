import React, { Component } from "react";
import axios from "../../configAxios";
import ModalSpinner from "../UI/ModalSpinner";
import { toast } from "react-toastify";
import { title } from "change-case";
import { Link } from "react-router-dom";
import dateFns from "date-fns";

class CurrentRegistration extends Component {
  signal = axios.CancelToken.source();
  state = {
    registrations: null,
    loader: true
  };

  componentDidMount() {
    this.getCurrentRegistrations()
  }

  getCurrentRegistrations = () => {
    axios.get("/registrations/current_registrations", {
      params: {
        id: this.props.userId
      },
      cancelToken: this.signal.token
    })
      .then(res => {
        this.setState({ registrations: res.data.registrations, loader: false });
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  deleteCurrentRegistrations = id => {
    this.setState({ loader: true })
    axios
      .delete("/registrations/id", {
        params: {
          id
        }
      }).then(res => {
        const { success } = res.data.json;
        this.setState({ loader: false })
        if (success) {
          this.getCurrentRegistrations()
          this.props.registeredPrograms()
          toast.success("Current Registration deleted successfully.");
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      });
  }

  render() {
    let registrationList = null;
    if (this.state.loader) {
      return <ModalSpinner />
    } else {
      if (this.state.registrations !== null) {
        if (this.state.registrations.length !== 0) {
          registrationList = this.state.registrations.map(
            (registration, index) => {
              return <div className="row cursor" key={registration.id}>
                <p className="col-6" >
                  <Link to={`/programs/${registration.program_id}`} className="text-info">
                    {registration.program_name}
                  </Link>
                </p>
                {registration.program_drop_cutoff &&
                  dateFns.startOfDay(registration.program_drop_cutoff) >= dateFns.startOfDay(new Date())
                  && <p className="col-6 text-right"
                    style={{ color: "red" }}
                    onClick={() => this.deleteCurrentRegistrations(registration.id)}>Drop</p>
                }
              </div>;
            }
          );
        } else {
          registrationList = (
            <p
              className="text-center text-dark font-weight-bold"
              style={{ color: "black" }}
            >
              No current registration.
            </p>
          );
        }
      }
    }
    return (
      <div className="pl-3">
        <div
          className="d-flex justify-content-between position-absolute"
          style={{ top: -22 }}
        />
        <div className="mt-4">{registrationList}</div>
      </div>
    );
  }
}

export default CurrentRegistration;
