import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { title } from "change-case"
import { formatDate } from "../../utils/formatDate"
import axios from "../../configAxios"
import Modal from "../UI/Modal"
import Adjustment from "./Adjustment"
import FavaBucks from "./FAVABucks"
import TransferAmount from "./TransferAmount"
import Pagination from "../UI/Pagination"
import Spinner from "../UI/Spinner"
import errorStatusHandler from "../errorPages/UserNotAuthorized"

import "./AccountTab.css"

class AccountTab extends Component {
  signal = axios.CancelToken.source()
  state = {
    accountData: "",
    invoiceData: [],
    selectedIndex: -1,
    showFavaBucksModal: false,
    showAdjustmentModal: false,
    amountTransferModal: false,
    totalCount: 0,
    loading: false,
    selectedPage: 1,
    availableCredits: {
      elementType: "input",
      elementConfig: {
        name: "availableCredits",
      },
      value: "",
    },
    downloading: false,
    policyRes: {},
    manageAccounts: null,
    manageGeneralInvoice: null,
  }

  componentDidMount() {
    const accountId = this.props.accountId || this.props.match.params.accountId
    axios
      .get("/accounts/policy", {
        params: {
          id: accountId,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({ policyRes: res.data.json })
      })
    this.getAccountDetails()
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios
      .get("/users/policy", { params: { id: this.props.userId } })
      .then((res) => {
        this.setState({
          manageAccounts: res.data.json.manage_accounts,
          manageGeneralInvoice: res.data.json.manage_general_invoice,
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getAccountDetails = async () => {
    const invoiceData = []
    const accountId = this.props.accountId || this.props.match.params.accountId
    if (accountId) {
      this.setState({ loading: true })
      axios
        .get(`accounts/${accountId}`, { cancelToken: this.signal.token })
        .then((res) => {
          this.setState({ accountData: res.data.account })
        })
        .catch((error) => error)
      try {
        const invoiceRes = await axios.get("/invoices", {
          params: {
            account_id: accountId,
          },
          cancelToken: this.signal.token,
        })
        if (invoiceRes.status === 202) {
          errorStatusHandler(invoiceRes)
          this.props.tabChangeHandler("Summary", null)
        } else {
          invoiceData.push(...invoiceRes.data.invoices)
          await invoiceData.map(async (i) => {
            const b = await axios.get("/invoices/policy", {
              params: {
                id: i.id,
              },
            })
            i.canViewInvoiceDetailsPage =
              b.data.json.can_view_invoice_detail_page
          })
          const paymentRes = await axios.get("/payments", {
            params: {
              account_id: this.props.accountId,
            },
            cancelToken: this.signal.token,
          })
          invoiceData.push(...paymentRes.data.payments)

          const adjustmentRes = await axios.get("/adjustments", {
            params: {
              account_id: this.props.accountId,
            },
            cancelToken: this.signal.token,
          })
          invoiceData.push(...adjustmentRes.data.adjustments)
          invoiceData.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
          this.setState({
            invoiceData,
            totalCount: invoiceData.length,
            loading: false,
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
    })
  }

  mouseEnterHandle = (index) => {
    this.setState({ selectedIndex: index })
  }

  mouseRemoveHandle = () => {
    this.setState({ selectedIndex: "" })
  }

  downloadReport = async () => {
    try {
      if (this.state.downloading) return
      this.setState({ downloading: true })
      const report = await axios.get("/reports/account/download", {
        params: {
          id: "AccountReport",
          "report_options[user_id]": this.props.userId,
        },
        cancelToken: this.signal.token,
      })
      if (report.data.json.generating) {
        toast.success(
          "Request has been made it will take few minutes to download."
        )
        const timerId = setInterval(() => {
          if (report.data.json.id) {
            this.checkDownloadProgress(report.data.json.id)
          } else {
            clearInterval(this.state.timerId)
            this.setState({ downloading: false })
            toast.error("Failed to print summary")
          }
        }, 5000)
        this.setState({ timerId })
      }
    } catch (err) {
      this.setState({ downloading: false })
    }
  }

  checkDownloadProgress = async (id) => {
    const progress = await axios
      .get("/reports/account/check_generating", {
        params: {
          id,
        },
        cancelToken: this.signal.token,
      })
      .catch(() => {
        clearInterval(this.state.timerId)
        this.setState({ downloading: false })
        toast.error("Failed to print summary")
      })
    if (typeof progress.data.json === "string") {
      clearInterval(this.state.timerId)
      this.setState({ downloading: false })
      toast.success("File successfully downloaded")
      window.open(progress.data.json, "_blank")
    }
  }

  render() {
    const {
      invoiceData,
      selectedPage,
      totalCount,
      loading,
      policyRes,
    } = this.state
    const start = (selectedPage - 1) * 10
    const end = selectedPage * 10
    const invoiceContent = invoiceData
      .slice(start, end)
      .map((invoice, index) => {
        if (invoice.hasOwnProperty("total_due_cached")) {
          return (
            <li
              key={index}
              className="list-group-item rounded-0"
              style={{
                backgroundColor:
                  this.state.selectedIndex === index ? "#f2f2f2" : "",
              }}
              onMouseEnter={() => this.mouseEnterHandle(index)}
              onMouseLeave={() => this.mouseRemoveHandle()}
            >
              {invoice.hasOwnProperty("credit_amount") &&
                invoice.credit_amount !== null && (
                  <div className="row m-0 w-100">
                    <div className="col-md-7">
                      <p>{`Service In Kind Credit on Invoice #${invoice.reference}`}</p>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center">
                      <div className="invoice-label">
                        <Link to={`/invoices/${invoice.id}`}>credit</Link>
                      </div>
                    </div>
                    <div className="col-md-2 d-flex justify-content-end">
                      <p className="credit-amount">{invoice.credit_amount}</p>
                    </div>
                  </div>
                )}
              {invoice.hasOwnProperty("credit_payment_amount") &&
                invoice.credit_payment_amount !== null && (
                  <div className="row m-0 w-100">
                    <div className="col-md-7">
                      <p>{`Fava Bucks Payments on Invoice #${invoice.reference}`}</p>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center">
                      <div className="invoice-label">
                        <Link to={`/invoices/${invoice.id}`}>credit</Link>
                      </div>
                    </div>
                    <div className="col-md-2 d-flex justify-content-end">
                      <p className="credit-amount">
                        {`${invoice.credit_payment_amount}`}
                      </p>
                    </div>
                  </div>
                )}

              <div className="row m-0 w-100">
                <div className="col-md-7">
                  {invoice.ticket_id || invoice.registration_id ? (
                    <Link to={"#"} className="text-info font-weight-bold">
                      {invoice.asset_or_program}
                    </Link>
                  ) : (
                    <p className="text-info font-weight-bold">
                      {invoice.invoice_items_description}
                    </p>
                  )}
                  <p>
                    <span>
                      {formatDate(
                        new Date(invoice.invoice_date),
                        "YYYY/MM/DD"
                      )}
                    </span>
                    <br />
                    <span>{invoice.status_string}</span>
                  </p>
                </div>
                <div className="col-md-3 d-flex justify-content-center col-sm-6 col-6 mt-4">
                  <div
                    className={
                      invoice.status_string === "Current Invoice"
                        ? "invoice-label current-invoice"
                        : "invoice-label paid-invoice"
                    }
                  >
                    <Link
                      to={
                        invoice.canViewInvoiceDetailsPage
                          ? `/invoices/${invoice.id}`
                          : ""
                      }
                      style={{
                        cursor: invoice.canViewInvoiceDetailsPage
                          ? "pointer"
                          : "no-drop",
                      }}
                      onClick={
                        invoice.canViewInvoiceDetailsPage
                          ? null
                          : (e) => e.preventDefault()
                      }
                    >
                      Invoice {invoice.reference}
                    </Link>
                  </div>
                </div>
                <div className="col-md-2 text-right col-sm-6 col-6">
                  <p className="amount">{`${invoice.total_due_cached}`}</p>
                </div>
              </div>
            </li>
          )
        } else {
          return (
            <li className="list-group-item rounded-0">
              <div key={index} className="row m-0 w-100">
                <div className="col-md-7 col-sm-6 col-6">
                  <p className="text-primary font-weight-bold">
                    {invoice.hasOwnProperty("payment_type_name") &&
                      invoice.payment_type_name}{" "}
                    {` ${invoice.notes}`}
                  </p>
                  <p>
                    <span>
                      {formatDate(
                        new Date(invoice.created_at),
                        "YYYY/MM/DD"
                      )}
                    </span>
                  </p>
                </div>
                <div className="col-md-3 d-flex justify-content-center col-sm-6 col-6 mt-4">
                  <div
                    className="invoice-label"
                    style={{
                      backgroundColor: invoice.hasOwnProperty("payment_amount")
                        ? "var(--color-primary)"
                        : "grey",
                    }}
                  >
                    {invoice.hasOwnProperty("payment_amount")
                      ? "Payment"
                      : "Adjustment"}
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-end col-sm-6 col-6">
                  <p className="amount">
                    {invoice.hasOwnProperty("payment_amount")
                      ? invoice.payment_amount
                      : invoice.adjustment_amount}
                  </p>
                </div>
              </div>
            </li>
          )
        }
      })
    const { accountData } = this.state
    const bucksTitle = "Adjust  " + accountData.credit_name
    const acccountContent = (
      <div>
        {" "}
        {policyRes.can_transfer_balance && (
          <div>
            <p
              className="text-primary ml-4 mt-3 cursor"
              onClick={
                this.state.manageAccounts
                  ? () => {
                      window.scrollTo(0, 0)
                      this.setState({ amountTransferModal: true })
                    }
                  : null
              }
              style={{
                cursor: this.state.manageAccounts ? "pointer" : "no-drop",
              }}
            >
              **Transfer Old Account Balance (full amount, tax included)**
            </p>
          </div>
        )}
        <div style={{ margin: "20px 0px 20px 20px" }}>
          <p>
            {policyRes.can_make_an_adjustment && (
              <React.Fragment>
                <span
                  className="cursor"
                  onClick={
                    this.state.manageAccounts
                      ? () => {
                          window.scrollTo(0, 0)
                          this.setState({ showAdjustmentModal: true })
                        }
                      : null
                  }
                  style={{
                    cursor: this.state.manageAccounts ? "pointer" : "no-drop",
                  }}
                >
                  Make An Adjustment
                </span>{" "}
                /{" "}
              </React.Fragment>
            )}
            {policyRes.can_transfer_balance && (
              <React.Fragment>
                <span
                  className="cursor"
                  onClick={
                    this.state.manageAccounts
                      ? () => {
                          window.scrollTo(0, 0)
                          this.setState({ showFavaBucksModal: true })
                        }
                      : null
                  }
                  style={{
                    cursor: this.state.manageAccounts ? "pointer" : "no-drop",
                  }}
                >
                  Adjust {accountData.credit_name}
                </span>{" "}
                /{" "}
              </React.Fragment>
            )}
            {policyRes.can_create_general_invoice && (
              <React.Fragment>
                <span
                  className="cursor"
                  onClick={
                    this.state.manageGeneralInvoice
                      ? () =>
                          this.props.history.push({
                            pathname: "/invoices/new",
                            search: `?type=General&userId=${this.props.userId}&accountId=${this.props.accountId}`,
                          })
                      : null
                  }
                  style={{
                    cursor: this.state.manageGeneralInvoice
                      ? "pointer"
                      : "no-drop",
                  }}
                >
                  Create a General Invoice
                </span>{" "}
                /{" "}
              </React.Fragment>
            )}
            {policyRes.can_print_summary && (
              <span
                className="cursor"
                onClick={this.state.manageAccounts ? this.downloadReport : null}
                style={{
                  cursor: this.state.manageAccounts ? "pointer" : "no-drop",
                }}
              >
                Print Summary
              </span>
            )}
          </p>
        </div>
        <div className="p-4">
          <span style={{ fontSize: 20 }}>
            Account Balance:{" "}
            <span className="text-dark">{accountData.account_balance}</span>
          </span>
          <span
            style={{
              padding: "4px 9px 4px 9px",
              backgroundColor:
                accountData.credit_or_owing != null ? "#ff8080" : "white",
              color: "white",
              width: 60,
              height: 30,
              borderRadius: "7px",
              marginLeft: 20,
            }}
          >
            {title(accountData.credit_or_owing)}
          </span>
        </div>
        <div style={{ margin: "0px 10px 30px 20px" }}>
          <p style={{ fontSize: 20 }}>
            {accountData.credit_name} Balance:{" "}
            <span className="text-dark">{accountData.available_credits}</span>
            <br />
            <span className="text-dark" style={{ fontSize: 15 }}>
              {accountData.has_unpaid_invoices
                ? "There are unpaid invoices"
                : ""}
            </span>
          </p>
        </div>
      </div>
    )
    const modalContent = (
      <div>
        {" "}
        <Modal
          title="Make an Adjustment"
          show={this.state.showAdjustmentModal}
          hide={() => this.setState({ showAdjustmentModal: false })}
          style={{ top: "15%" }}
          flag={true}
        >
          <Adjustment
            userId={this.props.userId}
            accountId={this.props.accountId}
            getAccountDetails={this.getAccountDetails}
            closeModal={() => this.setState({ showAdjustmentModal: false })}
          />
        </Modal>
        <Modal
          title={bucksTitle}
          show={this.state.showFavaBucksModal}
          hide={() => this.setState({ showFavaBucksModal: false })}
          style={{ top: "15%" }}
          flag={true}
        >
          <FavaBucks
            userId={this.props.userId}
            favaBalance={
              this.state.accountData.available_credits &&
              this.state.accountData.available_credits.replace("$", "")
            }
            updateFavaBucks={this.getAccountDetails}
            accountId={this.props.accountId}
            closeModal={() => this.setState({ showFavaBucksModal: false })}
          />
        </Modal>
        <Modal
          show={this.state.amountTransferModal}
          style={{ top: "15%" }}
          title="Transfer Balance"
          hide={() => this.setState({ amountTransferModal: false })}
          program={true}
          headerStyle={{
            display: "flex",
            justifyContent: "start",
            padding: "15px 0px 15px 0px",
          }}
          titleStyle={{
            color: "black",
            fontSize: "21px",
          }}
        >
          <TransferAmount
            userId={this.props.userId}
            favaBalance={this.state.accountData.available_credits}
            getAccountDetails={this.getAccountDetails}
            accountId={this.props.accountId}
            closeModal={() => this.setState({ amountTransferModal: false })}
          />
        </Modal>
      </div>
    )
    const paginationContent = (
      <div className="mt-4">
        <Pagination
          pageCount={totalCount / 10}
          handlePageClick={this.paginationHandler}
          currentPage={selectedPage - 1}
        />
      </div>
    )
    return (
      <div className="friends_account_tab webkit-fill-available">
        {loading ? (
          <Spinner />
        ) : (
          <div>
            {acccountContent}
            <div className="list-group list-group-flush">{invoiceContent}</div>
            {modalContent}
            {paginationContent}
          </div>
        )}
      </div>
    )
  }
}
export default withRouter(AccountTab)
