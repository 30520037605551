import React, { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import Button from "../../UI/button/Button"
import LabelCategoryModal from "./LabelCategoryModal"

const CreateLabelCategory = ({ onComplete }) => {
  const [showLabelCategoryModal, setLabelCategoryModal] = useState(false)
  const [labelCategoryName, setLabelCategoryName] = useState()

  const createLabelCategory = (name) => {
    if (name) {
      axios
        .post(`label_categories`, { name: name })
        .then((res) => {
          toast.success(res.data.json.message)
          onComplete()
        })
        .catch((err) => {
          if (err.response.status === 403) {
            toast.error(err.response.data.json.errors)
          }
        })
    } else {
      toast.error("Label Category Name Required")
    }
  }

  return <>
    <Button
      type='primary'
      clicked={() => {
        setLabelCategoryModal(true)
      }}
    >
      Add Category
    </Button>
    <LabelCategoryModal
      showLabelCategoryModal={showLabelCategoryModal}
      setLabelCategoryModal={setLabelCategoryModal}
      labelCategoryName={labelCategoryName}
      setLabelCategoryName={setLabelCategoryName}
      clicked={() => createLabelCategory(labelCategoryName)}
      modalTitle={'Create'}
    /></>
}

export default CreateLabelCategory
