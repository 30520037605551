import React from "react";

const NotFound = ({ history }) => {

  return <>
    <h4>OOPS! 404 ERROR</h4>
    <p className='not-found-text'>Sorry but the page you are looking for does not exist, has been removed, changed names, or is temporarily unavailable</p>
    <div className="d-flex flex-row">
      <p
        className='error-back-link cursor'
        onClick={() => history.goBack()}
      >Go Back</p>
    </div>
  </>
}

export default NotFound
