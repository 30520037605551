import CKEditor from "ckeditor4-react";

class CKEditorFull extends CKEditor {
  constructor(props) {
    super(props);
    if (window.CKEditor && (!CKEditor.env.ie || CKEditor.env.version > 7))
      CKEditor.env.isCompatible = true;
    CKEditor.editorUrl = "https://cdn.ckeditor.com/4.14.1/full-all/ckeditor.js";
  }
}

export default CKEditorFull;