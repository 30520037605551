import React, { Component } from "react"
import Spinner from "../UI/Spinner"
import { connect } from "react-redux"
import axios from "../../configAxios"
import BookingItem from "./BookingItem"
import CenteredContainer from "../UI/container/CenteredContainer"

import "./Project.css"

class BookingItems extends Component {
  signal = axios.CancelToken.source()
  state = {
    id: this.props.projects.project.slug
      ? this.props.projects.project.slug
      : this.props.projects.project.id,
    bookingDetails: {},
    loading: true,
  }

  componentDidMount() {
    this.getProjectBookings()
  }

  getProjectBookings(id) {
    const queryParams = {
      params: {
        id: this.state.id,
      },
      cancelToken: this.signal.token,
    }
    axios
      .get("/projects/booking", queryParams)
      .then((res) => {
        this.setState({
          bookingDetails: res.data.projects,
          loading: false,
        })
      })
      .catch((err) => err)
  }

  componentWillReceiveProps(props) {
    this.setState(
      {
        id: props.projects.project.slug,
      },
      () => this.getProjectBookings()
    )
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  render() {
    let projectBookingDetails = null
    const { bookingDetails, loading } = this.state
    if (bookingDetails === null || loading) {
      projectBookingDetails = <Spinner />
    } else if (bookingDetails.length > 0) {
      projectBookingDetails = this.state.bookingDetails.map((bookingDetail) => (
        <BookingItem
          key={bookingDetail.id}
          bookingDetail={bookingDetail}
          ticketId={bookingDetail.bookings[0].ticket.id}
        />
      ))
    } else {
      projectBookingDetails = (
        <CenteredContainer>
          <h4 className="center-block">No Bookings found...</h4>
        </CenteredContainer>
      )
    }
    return (
      <div className="list-group list-group-flush project-bookings">
        {projectBookingDetails}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  }
}

export default connect(mapStateToProps)(BookingItems)
