import React, { Component } from "react";
import { toast } from "react-toastify";
import axios from "../../configAxios";
import dateFns from "date-fns";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import DatePicker from "../UI/datePicker/index";
import Input from "../UI/input/Input"
import Spinner from "../UI/Spinner";
import Pagination from "../UI/Pagination";
import _ from "lodash"
import "./index.css";

class Project extends Component {
  constructor() {
    super();
    const currentMonthDate = new Date();
    this.signal = axios.CancelToken.source();
    this.state = {
      video: {
        elementType: "select",
        elementConfig: {
          name: "video",
          options: [
            { label: "Video", value: "video" }
          ],
          components: {
            IndicatorSeparator: () => {
              return null
            }
          },
          selectedOption: null
        }
      },
      startDate: new Date().setMonth(currentMonthDate.getMonth() - 1),
      endDate: new Date(),
      projects: [],
      forcePage: "",
      projectCues: [],
      selectedPage: 1,
      reportOptions: null,
      downloading: false,
      totalCount: 0,
      loading: true,
      disabled: true
    };
  }

  componentDidMount() {
    const queryParams = {
      id: "ProjectReport",
      page: this.state.selectedPage,
      per_page: 10,
      video: this.state.video.selectedOption && true
    };
    this.getReportData(queryParams);
    axios
      .get("/reports/project/formdata", { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          projectCues: res.data.json.project_cues
        });
      });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getReportData(queryParams) {
    axios
      .get("/reports/project", {
        params: queryParams,
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          projects: res.data["reports/project"],
          totalCount: res.data.meta.total_unique_projects,
          loading: false,
          disabled: false
        });
      });
  }

  submitHandler = event => {
    event.preventDefault();
    this.setState({ loading: true, selectedPage: 1, totalCount: 0, disabled: true });
    const { startDate, endDate } = this.state;
    const report_options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD")
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD")
      }
    };
    this.setState({ reportOptions: report_options });
    const queryParams = {
      id: "ProjectReport",
      report_options,
      page: 1,
      per_page: 10,
      video: this.state.video.selectedOption && true
    };
    this.getReportData(queryParams);
  };

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      forcePage: page.selected,
      loading: true
    });
    const queryParams = {
      id: "ProjectReport",
      report_options: this.state.reportOptions,
      page: page.selected + 1,
      per_page: 10,
      video: this.state.video.selectedOption && true
    };
    this.getReportData(queryParams);
  };

  downloadReport = async () => {
    try {
      if (this.state.downloading) return;
      this.setState({ downloading: true });
      const { startDate, endDate } = this.state;
      const report_options = {
        start: {
          year: dateFns.format(startDate, "YYYY"),
          month: dateFns.format(startDate, "MM"),
          day: dateFns.format(startDate, "DD")
        },
        end: {
          year: dateFns.format(endDate, "YYYY"),
          month: dateFns.format(endDate, "MM"),
          day: dateFns.format(endDate, "DD")
        }
      };
      let report = await axios.get("/reports/project/download_csv", {
        params: {
          id: "ProjectReport",
          report_options,
          video: this.state.video.selectedOption && true
        },
        cancelToken: this.signal.token
      });
      if (report.data.json.generating) {
        toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
        let timerId = setInterval(
          () => this.checkDownloadProgress(report.data.json.id),
          5000
        );
        this.setState({ timerId });
      }
    } catch (err) {
      this.setState({ downloading: false });
      console.log(err);
    }
  };

  checkDownloadProgress = async id => {
    try {
      let progress = await axios.get("/reports/project/check_generating", {
        params: { id },
        cancelToken: this.signal.token
      });
      if (typeof progress.data.json === "string") {
        clearInterval(this.state.timerId);
        this.setState({ downloading: false });
        toast.success("File successfully downloaded");
        window.open(progress.data.json, "_blank");
        return;
      }
    } catch (e) {
      toast.error("Downloading failed");
      clearInterval(this.state.timerId);
    }
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {}
    inputValue["selectedOption"] = event;

    const updateControls = {
      ...this.state[key],
      ...inputValue
    };

    this.setState({ [key]: updateControls })
  }

  render() {
    const { projects } = this.state;
    const projectList = projects.map(project => {
      let cueObj = {};
      this.state.projectCues.forEach(cue => {
        project.project_cues &&
          project.project_cues.forEach(project => {
            if (cue.id === project.id) {
              cueObj["id"] = cue.id;
              cueObj["value"] = project.value;
            }
          });
      });
      return (
        <tr>
          <td>{project.name}</td>
          <td>{project.creator}</td>
          <td>{project.associated_rental}</td>
          <td>{project.associated_program}</td>
          <td>{project.completed_year}</td>
          <td>{dateFns.format(project.created_at, "YYYY")}</td>
          <td>{project.synopsis}</td>
          {project.project_cues.map(cue => (
            <td key={cue.id}>{cue.value}</td>
          ))}
          <td>{project.unique_creators_count}</td>
          <td>{project.favatv_views}</td>
          <td>{project.has_video ? "Yes" : "No"}</td>
          <td>{project.has_image}</td>
          <td>{project.uploaded_by ? _.isEmpty(project.uploaded_by.name) ? "--" : project.uploaded_by.name : "--"}</td>
        </tr>
      );
    });
    return (
      <div className="container project-report w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Project Report</h3>
          </div>
          <div className="col-6 text-right">
            <Button
              type="success"
              clicked={this.downloadReport}
            >
              Generate CSV
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text overflow-reports-card">
                  <form onSubmit={this.submitHandler}>
                    <div className="row">
                      <div className="col-lg-3">
                        <label>From</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.startDate}
                          changed={date => this.setState({ startDate: date })}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label>To</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.endDate}
                          changed={date => this.setState({ endDate: date })}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label>Sort Project by</label>
                        <Input
                          {...this.state.video}
                          changed={event => this.inputChangedHandler(event, "video")}
                        />
                      </div>
                      <div className="col-lg-3">
                        <FormButton className="build-report" disabled={this.state.disabled}>Build Report</FormButton>
                      </div>
                    </div>
                  </form>
                  <div className="list mt-5 pt-3">
                    {
                      this.state.loading ? <Spinner className="h-30vh" /> : (
                        <React.Fragment>
                          <h4 className="text-center my-3">
                            <strong>Total number of unique projects: {this.state.totalCount}</strong>
                          </h4>
                          <table className="table table-bordered reports-table-layout">
                            <tr>
                              <th style={{ width: 100 }}>Project Name</th>
                              <th style={{ width: 100 }}>Member Name</th>
                              <th style={{ width: 100 }}>Associated Rental</th>
                              <th style={{ width: 100 }}>Associated Program</th>
                              <th style={{ width: 100 }}>Year Completed</th>
                              <th style={{ width: 100 }}>Year Uploaded</th>
                              <th style={{ width: 250 }}>Synopsis</th>
                              {this.state.projectCues.map(cue => (
                                <th style={{ width: 100 }}>{cue.name}</th>
                              ))}
                              <th style={{ width: 100 }}># of Unique Creators</th>
                              <th style={{ width: 100 }}># of views on FAVATV</th>
                              <th style={{ width: 100 }}>Video</th>
                              <th style={{ width: 100 }}>Image</th>
                              <th style={{ width: 100 }}>Uploaded By</th>
                            </tr>
                            <tbody>{!this.state.loading && projectList}</tbody>
                          </table>
                        </React.Fragment>
                      )
                    }
                    {this.state.totalCount > 0 && (
                      <Pagination
                        forcePage={this.state.selectedPage - 1}
                        pageCount={this.state.totalCount / 10}
                        handlePageClick={this.paginationHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Project;
