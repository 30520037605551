import React, { Component } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import _ from "lodash"

import axios from "../../../configAxios"
import errorHandler from "../../errorPages/UserNotAuthorized"

import FormButton from "../../UI/button/FormButton"
import Button from "../../UI/button/Button"
import ModalSpinner from "../../UI/ModalSpinner"
import Input from "../../UI/input/Input"
import Spinner from "../../UI/Spinner"
import Modal from "../../UI/Modal"

class General extends Component {
  constructor(props) {
    super(props)
    this.signal = axios.CancelToken.source()
    this.timeout = 0
    this.state = {
      disabled: false,
      loading: true,
      isEmailSettingsLoading: false,
      defaultEmails: {
        elementType: "select",
        elementConfig: {
          name: "defaultEmails",
          type: "text",
          options: [],
          required: true,
        },
        label: "Email",
        selectedOption: null,
        style: { minWidth: "100%", maxWidth: "100%" },
      },
      organizationEmailSettings: [],
      showEditEmailSettingsModal: false,
      updateSettingData: null,
    }
  }

  componentDidMount() {
    this.setDefaultEmails()
    this.setOrganizationEmailSettings()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being cancelled")
  }

  setOrganizationEmailSettings = () => {
    axios
      .get("organization_email_settings", {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          loading: false,
          organizationEmailSettings: res.data.organization_email_settings,
        })
      })
  }

  setDefaultEmails = () => {
    axios
      .get("/organization_communication_emails", {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          loading: false,
          defaultEmails: {
            ...this.state.defaultEmails,
            elementConfig: {
              ...this.state.defaultEmails.elementConfig,
              options: res.data.organization_communication_emails.map(
                (email) => {
                  if (email.is_verified) {
                    return { value: email.id, label: email.email }
                  }
                }
              ),
            },
          },
        })
      })
  }

  showEditEmailSettingsModalHandler = async (id) => {
    const settingsResponse = await axios.get(
      `/organization_email_settings/${id}`,
      {
        cancelToken: this.signal.token,
      }
    )
    const defaultEmailsLabel =
      settingsResponse.data.json.data.from_email_address
    const defaultEmailsValue = settingsResponse.data.json.data.from_email_id

    this.setState({
      updateSettingData: settingsResponse.data.json.data,
      defaultEmails: {
        ...this.state.defaultEmails,
        selectedOption:
          defaultEmailsLabel && defaultEmailsValue
            ? {
                value: defaultEmailsValue,
                label: defaultEmailsLabel,
              }
            : null,
      },
    })
    this.setState({
      isEmailSettingsLoading: false,
      showEditEmailSettingsModal: true,
    })
  }

  editEmailSettings = (event) => {
    event.preventDefault()
    const { defaultEmails } = this.state
    const keyName = Object.keys(this.state.updateSettingData.key)[0]
    const formData = {
      id: this.state.updateSettingData.id,
      key: keyName,
      from_email_id: defaultEmails.selectedOption.value,
    }
    axios
      .put(
        `/organization_email_settings/${this.state.updateSettingData.id}`,
        formData
      )
      .then((res) => {
        if (res.data.json.success) {
          this.setState({
            loading: true,
          })
          toast.success("Your information has successfully updated")
          this.setDefaultEmails()
          this.setOrganizationEmailSettings()
        } else {
          errorHandler(res)
        }
      })
      .catch((err) =>
        toast.error(
          _.isEmpty(err.message) ? "Something Went Wrong" : err.message
        )
      )
    this.setState({ showEditEmailSettingsModal: false })
  }

  dropdownChangedHandler = (event, key) => {
    const inputValue = {}
    inputValue.selectedOption = event
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState({ [key]: updatedcontrols })
  }

  render() {
    const { loading, organizationEmailSettings, defaultEmails } = this.state
    const editEmailSettingsModal = (
      <Modal
        title={
          this.state.updateSettingData &&
          "Update Email - " +
            this.state.updateSettingData.key[
              Object.keys(this.state.updateSettingData.key)[0]
            ]
        }
        show={this.state.showEditEmailSettingsModal}
        hide={() => this.setState({ showEditEmailSettingsModal: false })}
      >
        <div className="mt-2 accr-modal">
          {this.state.isEmailSettingsLoading ? (
            <ModalSpinner />
          ) : (
            <div>
              <form className="mt-3" onSubmit={this.editEmailSettings}>
                <div>
                  <Input
                    {...defaultEmails}
                    selectedOption={this.state.defaultEmails.selectedOption}
                    changed={(event) =>
                      this.dropdownChangedHandler(event, "defaultEmails")
                    }
                  />
                </div>
                <div className="text-center">
                  <Button
                    type="secondary"
                    clicked={() =>
                      this.setState({ showEditEmailSettingsModal: false })
                    }
                  >
                    Cancel
                  </Button>
                  <FormButton className="ml-2">Update</FormButton>
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal>
    )
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <div className="card">
                  <div className="card-body">
                    <table className="w-100 table-responsive">
                      <thead>
                        <tr>
                          <th style={{ width: "40%" }} className="px-4">
                            Settings Name
                          </th>
                          <th style={{ width: "30%" }} className="px-1">
                            Email
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {organizationEmailSettings.map((setting, index) => {
                          const keyName = Object.keys(setting.key)[0]
                          return (
                            <tr key={index} className="border-top">
                              <td className="py-3 px-1">
                                {setting.key[keyName]}
                              </td>
                              <td className="py-3 px-1">
                                {setting.from_email_address}
                              </td>
                              <td className="py-3 px-1" style={{ width: "9%" }}>
                                <p className="cursor mb-0 text-center">
                                  {
                                    <i
                                      className="fas fa-pen pl-3"
                                      onClick={() => {
                                        this.setState({
                                          isEmailSettingsLoading: true,
                                          showEditEmailSettingsModal: true,
                                        })
                                        window.scrollTo(0, 0)
                                        this.showEditEmailSettingsModalHandler(
                                          setting.id
                                        )
                                      }}
                                    />
                                  }
                                </p>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {editEmailSettingsModal}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, null)(General)
