import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";
import { Link, withRouter } from "react-router-dom";
import dateFns from "date-fns";
import _ from "lodash";
import { formatToTimeZone } from "date-fns-timezone"
import { connect } from "react-redux";
import Pusher from "pusher-js"

import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";
import Pagination from "../../UI/Pagination";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import Modal from "../../UI/Modal";
import Input from "../../UI/input/Input";
import DatePicker from "../../UI/datePicker";

import DateRange from "./DateRange";

import "./Accounting.css";

const perPageCount = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 150, label: 150 }
];

const types = [
  { value: "all", label: "All" },
  { value: "updated", label: "Updated" },
  { value: "date_range", label: "Date Range" },
  { value: "submitted_to_accounting", label: "Submitted to accounting" },
  { value: "failed", label: "Failed on Sync" }
];

class Quickbooks extends Component {
  signal = axios.CancelToken.source();
  timeout = 0;
  reloadTimeout = 0;
  state = {
    invoiceList: [],
    invoiceIds: [],
    loading: true,
    disabled: true,
    quickbookSync: false,
    sync_type: "",
    invoice_id_for_sync: "",
    selectedPage: 1,
    perPage: 10,
    query: null,
    totalItems: null,
    dateRange: false,
    showErrorModal: false,
    errormsg: "",
    search: {
      elementType: "input",
      elementConfig: {
        name: "search",
        type: "text",
        required: true
      },
      value: ""
    },
    items: {
      elementType: "select",
      elementConfig: {
        name: "items",
        options: perPageCount,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: { label: 10, value: 10 }
    },
    type: {
      elementType: "select",
      elementConfig: {
        name: "type",
        options: types,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: {
        label: "Submitted to accounting",
        value: "submitted_to_accounting"
      }
    },
    gst: {
      elementType: "select",
      elementConfig: {
        name: "gst",
        options: [],
        isLoading: true,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: {}
    },

    pst: {
      elementType: "select",
      elementConfig: {
        name: "pst",
        options: [],
        isLoading: true,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: {}
    },

    hst: {
      elementType: "select",
      elementConfig: {
        name: "hst",
        options: [],
        isLoading: true,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: {}
    },
    oos: {
      elementType: "select",
      elementConfig: {
        name: "oos",
        options: [],
        isLoading: true,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: {}
    },
    groupedGstPst: {
      elementType: "select",
      elementConfig: {
        name: "groupedGstPst",
        options: [],
        isLoading: true,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    groupedGstHst: {
      elementType: "select",
      elementConfig: {
        name: "groupedGstHst",
        options: [],
        isLoading: true,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    groupedPstHst: {
      elementType: "select",
      elementConfig: {
        name: "groupedPstHst",
        options: [],
        isLoading: true,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    groupedGstPstHst: {
      elementType: "select",
      elementConfig: {
        name: "groupedGstPstHst",
        options: [],
        isLoading: true,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    startDate: null,
    endDate: null,
    params: null,
    quickbooksFile: [],
    showTaxMatchModal: false,
    savedTaxcodes: null,
    quickbookTaxcodes: [],
    syncChartOfAccountModal: false,
    outerLoading: false,
    settingLoading: true,
    qbLinked: false,
    syncDatePickedDisabled: false,
    syncFromDate: null,
    syncTillDate: null,
    organization: null,
    resetState: {},
  };

  componentDidMount() {
    const params = {
      page: this.state.selectedPage,
      per_page: this.state.items.selectedOption.value,
      type: this.state.type.selectedOption.value
    };
    this.setState({ 
      params,
      settingLoading: true,
      resetState: {
        params,
        type: this.state.type.selectedOption,
        items: this.state.items.selectedOption,
      }
    }, () => this.getInvoices(true));
    
    let pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: 'us2',
      forceTLS: true
    });
    let channel = pusher.subscribe('account-' + localStorage.getItem('userId'));
    channel.bind('qboauth-callback', data => {
      this.setState({
        qbLinked: true
      }, this.resetInvoiceList);
    })
  }

  getInvoices = (getOrgData) => {
    axios
      .get("/quickbooks/invoices", {
        params: this.state.params,
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          ...this.state,
          invoiceList: res.data.quickbooks,
          invoiceIds: res.data.meta.invoice_ids,
          loading: false,
          quickbookTimestamps: res.data.meta.sync_timestamps,
          disabled: _.isEmpty(res.data.quickbooks),
          quickbookSync: res.data.meta.is_syncing_invoices,
          totalItems: res.data.meta.total
        },() => {
          if (getOrgData)
            this.getOrgDetails();
        });
      });
  };

  getOrgDetails = () => {
    axios.get(`/organizations/${this.props.auth.organizationId}`)
      .then(res => {
        const { organization } = res.data;
        const quickbookSyncSetting = {
          syncFromDate: null,
          syncTillDate: null,
        };
        
        if (organization.quickbook && organization.quickbook.data) {
          const { sync_from, sync_till } = organization.quickbook.data;
          if (sync_from)
            quickbookSyncSetting.syncFromDate = new Date(sync_from);
          if(sync_till)
            quickbookSyncSetting.syncTillDate = new Date(sync_till);
        }
 
        this.setState({
          ...this.state,
          ...quickbookSyncSetting,
          organization: organization,
          settingLoading: false,
          qbLinked: !!organization.is_quickbook_linked
        })
      }).catch(err => err)
  }

  paginationHandler = page => {
    window.scrollTo(0, 0);
    this.setState(
      {
        ...this.state,
        selectedPage: page.selected + 1,
        loading: true,
        disabled: true
      },
      () => {
        let params = {
          page: this.state.selectedPage,
          per_page: this.state.items.selectedOption.value,
          type: this.state.type.selectedOption.value
        };
        if (this.state.startDate && this.state.endDate) {
          params["start_date"] = this.state.startDate;
          params["end_date"] = this.state.endDate;
        }
        if (this.state.search.value) {
          params["query"] = this.state.search.value;
        }
        this.setState({ params }, () => this.getInvoices());
      }
    );
  };

  dateRange = (startDate, endDate) => {
    this.setState(
      {
        ...this.state,
        startDate: dateFns.format(startDate, "YYYY-MM-DD"),
        endDate: dateFns.format(endDate, "YYYY-MM-DD"),
        dateRange: false
      },
      () => {
        const params = {
          page: this.state.selectedPage,
          per_page: this.state.items.selectedOption.value,
          type: this.state.type.selectedOption.value,
          query: this.state.search.value,
          start_date: startDate,
          end_date: endDate
        };
        this.setState({ params }, () => this.getInvoices());
      }
    );
  };

  openModal = () => {
    window.scrollTo(0, 0);
    this.setState({
      ...this.state,
      dateRange: true,
      totalItems: 0
    });
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    switch (key) {
      case "search":
        inputValue["value"] = event.target.value;
        break;
      case "items":
        inputValue["selectedOption"] = event;
        this.setState({ ...this.state, perPage: event.value });
        break;
      case "type":
      case "gst":
      case "pst":
      case "hst":
      case "oos":
      case "groupedGstPst":
      case "groupedGstHst":
      case "groupedPstHst":
      case "groupedGstPstHst":
        inputValue["selectedOption"] = event;
        break;
      default:
        console.log("Invalid Key");
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };

    if (["search", "items", "type"].indexOf(key) > -1) {
      this.setState(
        {
          ...this.state,
          [key]: updatedcontrols,
          totalItems: 0,
          loading: true,
          disabled: true,
          selectedPage: 1
        },
        () => {
          if ("selectedOption" in this.state[key]) {
            if (this.state[key].selectedOption.value === "date_range") {
              this.openModal();
            } else {
              let params = {};
              params = {
                page: this.state.selectedPage,
                per_page: this.state.items.selectedOption.value,
                type: this.state.type.selectedOption.value
              };
              if (this.state.search.value !== "") {
                params["query"] = this.state.search.value;
              }
              if (
                this.state.type.selectedOption.value === "date_range" &&
                this.state.startDate &&
                this.state.endDate
              ) {
                params["start_date"] = this.state.startDate;
                params["end_date"] = this.state.endDate;
              }
              this.setState({ params }, () => this.getInvoices());
            }
          } else {
            const params = {
              page: this.state.selectedPage,
              per_page: this.state.items.selectedOption.value,
              type: this.state.type.selectedOption.value,
              query: this.state.search.value
            };
            this.setState({ params }, () => this.getInvoices());
          }
        }
      );
    } else {
      this.setState({ [key]: updatedcontrols });
    }
  };

  searchHandler = (event, key) => {
    let inputValue = {};
    if (key === "search") {
      inputValue["value"] = event.target.value;
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState(
      {
        ...this.state,
        [key]: updatedcontrols,
        totalItems: 0,
        loading: true,
        disabled: true,
        selectedPage: 1
      },
      () => {
        let params = {
          page: this.state.selectedPage,
          per_page: this.state.items.selectedOption.value,
          type: this.state.type.selectedOption.value,
          query: this.state.search.value
        };
        if (this.state.startDate && this.state.endDate) {
          params["start_date"] = this.state.startDate;
          params["end_date"] = this.state.endDate;
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.setState({ params }, () => this.getInvoices());
          }, 1000);
        } else {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.setState({ params }, () => this.getInvoices());
          }, 1000);
        }
      }
    );
  };

  clearQueryString = () => {
    const params = {
      page: 1,
      per_page: this.state.items.selectedOption.value,
      type: this.state.type.selectedOption.value
    };
    if (this.state.startDate && this.state.endDate) {
      params["start_date"] = this.state.startDate;
      params["end_date"] = this.state.endDate;
    }
    this.setState(
      {
        search: {
          ...this.state.search,
          value: ""
        },
        totalItems: 0,
        loading: true,
        disabled: true,
        selectedPage: 1,
        params
      },
      () => this.getInvoices()
    );
  };

  getTaxCodesStatus = () => {
    this.setState({ outerLoading: true });
    axios.post("/quickbooks/get_qbo_taxcodes").then(res => {
      if (res.data.json.status) {
        if (_.isEmpty(res.data.json.taxcodes)) {
          this.setState({ outerLoading: false });
          toast.error(
            "Before Syncing you have to setup taxes on your QuickBooks Account"
          );
        }
        if (!res.data.json.is_discount_enabled) {
          this.setState({ outerLoading: false });
          toast.error(
            "Before Syncing you have to enable Discount / Deposit on your QuickBooks Account Settings"
          );
        }
        if (
          !_.isEmpty(res.data.json.taxcodes) &&
          res.data.json.is_discount_enabled
        ) {
          this.setState({ quickbookTaxcodes: res.data.json.taxcodes }, () =>
            this.getTaxcodes()
          );
        }
      } else {
        toast.error(res.data.json.message);
        this.setState({ outerLoading: false });
      }
    });
  };

  getDefaultOption = (option, taxcodoes) => {
    return this.state.savedTaxcodes && this.state.savedTaxcodes[option] &&
      this.state.savedTaxcodes[option].name &&
      taxcodoes.filter(taxcodoe => taxcodoe.label === this.state.savedTaxcodes[option].name)[0]
  }

  getTaxcodes = () => {
    axios
      .get("/quickbooks/saved_taxcodes", {
        cancelToken: this.signal.token
      })
      .then(res => {
        if (res.data.json.success) {
          this.setState(
            {
              savedTaxcodes: res.data.json.taxcodes
            },
            () => {
              var taxcodoes = Array.isArray(this.state.quickbookTaxcodes)
                ? this.state.quickbookTaxcodes.map(val => ({ label: val.name, value: val.id }))
                : [];
              this.setState({
                ...this.state,
                outerLoading: false,
                syncChartOfAccountModal: true,
                gst: {
                  ...this.state.gst,
                  elementConfig: {
                    ...this.state.gst.elementConfig,
                    options: taxcodoes,
                    isLoading: false
                  },
                  selectedOption:
                    this.state.savedTaxcodes &&
                      this.state.savedTaxcodes.GST &&
                      this.state.savedTaxcodes.GST.name
                      ? taxcodoes.filter(
                        taxcodoe =>
                          taxcodoe.label === this.state.savedTaxcodes.GST.name
                      )[0]
                      : taxcodoes[0]
                },
                pst: {
                  ...this.state.pst,
                  elementConfig: {
                    ...this.state.pst.elementConfig,
                    options: taxcodoes,
                    isLoading: false
                  },
                  selectedOption:
                    this.state.savedTaxcodes &&
                      this.state.savedTaxcodes.PST &&
                      this.state.savedTaxcodes.PST.name
                      ? taxcodoes.filter(
                        taxcodoe =>
                          taxcodoe.label === this.state.savedTaxcodes.PST.name
                      )[0]
                      : taxcodoes[0]
                },
                hst: {
                  ...this.state.hst,
                  elementConfig: {
                    ...this.state.hst.elementConfig,
                    options: taxcodoes,
                    isLoading: false
                  },
                  selectedOption:
                    this.state.savedTaxcodes &&
                      this.state.savedTaxcodes.HST &&
                      this.state.savedTaxcodes.HST.name
                      ? taxcodoes.filter(
                        taxcodoe =>
                          taxcodoe.label === this.state.savedTaxcodes.HST.name
                      )[0]
                      : taxcodoes[0]
                },
                oos: {
                  ...this.state.oos,
                  elementConfig: {
                    ...this.state.oos.elementConfig,
                    options: taxcodoes,
                    isLoading: false
                  },
                  selectedOption:
                    this.state.savedTaxcodes &&
                      this.state.savedTaxcodes.OOS &&
                      this.state.savedTaxcodes.OOS.name
                      ? taxcodoes.filter(
                        taxcodoe =>
                          taxcodoe.label === this.state.savedTaxcodes.OOS.name
                      )[0]
                      : taxcodoes[0]
                },
                groupedGstHst: {
                  ...this.state.groupedGstHst,
                  elementConfig: {
                    ...this.state.groupedGstHst.elementConfig,
                    options: taxcodoes,
                    isLoading: false
                  },
                  selectedOption: this.getDefaultOption("GST_HST", taxcodoes)
                },
                groupedGstPst: {
                  ...this.state.groupedGstPst,
                  elementConfig: {
                    ...this.state.groupedGstPst.elementConfig,
                    options: taxcodoes,
                    isLoading: false
                  },
                  selectedOption: this.getDefaultOption("GST_PST", taxcodoes)
                },
                groupedPstHst: {
                  ...this.state.groupedPstHst,
                  elementConfig: {
                    ...this.state.groupedPstHst.elementConfig,
                    options: taxcodoes,
                    isLoading: false
                  },
                  selectedOption: this.getDefaultOption("PST_HST", taxcodoes)
                },
                groupedGstPstHst: {
                  ...this.state.groupedGstPstHst,
                  elementConfig: {
                    ...this.state.groupedGstPstHst.elementConfig,
                    options: taxcodoes,
                    isLoading: false
                  },
                  selectedOption: this.getDefaultOption("GST_PST_HST", taxcodoes)
                }
              });
            }
          );
        } else {
          toast.error(res.data.json.message);
          this.setState({ outerLoading: false });
        }
      });
  };

  saveTaxMatches = () => {
    let formData = new FormData();
    let savedTaxcodes = {
      gst: parseInt(this.state.gst.selectedOption.value),
      pst: parseInt(this.state.pst.selectedOption.value),
      hst: parseInt(this.state.hst.selectedOption.value),
      oos: parseInt(this.state.oos.selectedOption.value),
      gst_pst_grouped: this.state.groupedGstPst.selectedOption && parseInt(this.state.groupedGstPst.selectedOption.value),
      pst_hst_grouped: this.state.groupedPstHst.selectedOption && parseInt(this.state.groupedPstHst.selectedOption.value),
      gst_hst_grouped: this.state.groupedGstHst.selectedOption && parseInt(this.state.groupedGstHst.selectedOption.value),
      gst_pst_hst_grouped: this.state.groupedGstPstHst.selectedOption && parseInt(this.state.groupedGstPstHst.selectedOption.value)
    };
    for (let key in savedTaxcodes) {
      if (savedTaxcodes[key]) formData.append(key, savedTaxcodes[key]);
    }
    axios
      .post("/quickbooks/save_taxcodes", formData)
      .then(res => {
        if (res.data.json.success) {
          this.setState(
            {
              ...this.state,
              showTaxMatchModal: false,
              outerLoading: true
            },
            () => this.syncInvoiceQB()
          );
        }
      });
  };

  syncChartOfAccountHandler = event => {
    event.preventDefault();
    this.setState({
      syncChartOfAccountModal: false,
      showTaxMatchModal: true
    });
  };

  syncInvoiceQB = () => {
    let formData = new FormData();
    let obj = {}

    if (this.state.sync_type === "single"){
      obj = {
        type: "single",
        invoice_id: this.state.invoice_id_for_sync
      };
    }
    else{
      obj = this.state.params
    }

    for (let key in obj) {
      if (obj[key]) formData.append(key, obj[key]);
    }
    axios
      .post("/quickbooks/sync_invoice", formData)
      .then(res => {
        if (res.data.json.message) {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            toast.success(res.data.json.message);
            if (this.reloadTimeout) clearTimeout(this.reloadTimeout);
            this.reloadTimeout = setTimeout(() => {
              window.location.reload();
            }, 1000);
          }, 3000);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      })
      .catch(err => {
        if (err.res){
          const error = err.res.data.json;
          if (error.hasOwnProperty("errors")) {
            Object.keys(error).forEach(key => {
              toast.error(title(key) + " " + error[key]);
            });
          }
          if (error.hasOwnProperty("error")) {
            toast.error(error.error);
          }
        }
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.reloadTimeout) clearTimeout(this.reloadTimeout);
          this.reloadTimeout = setTimeout(() => {
            window.location.reload();
          }, 1000);
        }, 3000);
        
        
      });
  };

  syncChartOfAccountModal = () => {
    window.scrollTo(0, 0);
    this.setState({ syncChartOfAccountModal: true });
  };

  setSyncHandler = (event) => {
    event.preventDefault();
    this.setState({
      syncDatePickedDisabled: true,
    })
    const {
      syncTillDate,
      syncFromDate,
    } = this.state;
    axios.post("quickbooks/set_sync_from", {
      sync_from: syncFromDate 
        ? dateFns.format(
            new Date(syncFromDate),
            "YYYY-MM-DD"
          )
        : null,
      sync_till: syncTillDate 
        ? dateFns.format(
            new Date(syncTillDate),
            "YYYY-MM-DD"
          )
        : null
    })
      .then(res => {
        if (res.data.json.status) {
          toast.success("Your information has been saved successfully.")
          this.resetInvoiceList();
        }
        else {
          toast.error(res.data.json.message)
        }
        this.setState({
          syncDatePickedDisabled: false,
        })
      })
      .catch(err => {
        toast.error(err.error);
        this.setState({
          syncDatePickedDisabled: false,
        });
      })
  }

  unlinkQBConnect = () => {
    axios
      .delete("/quickbooks")
      .then(res => {
        if (res.data.json.success) {
          toast.success(res.data.json.message);
          this.setState({
            qbLinked: false,
            syncFromDate: null,
            syncTillDate: null,
          }, this.resetInvoiceList);
        }
      });
  }

  qbAuth = () => {
    window.open(
      'https://fava.amsnetwork.app/auth/quickbooks_oauth2?guid=' + 
      localStorage.getItem('userId') + 
      '&subdomain=' + 
      localStorage.getItem('organizationSubDomain'), 
      'QuickBookOAuth', 
      'width=1080, height=720'
    );
  }

  resetInvoiceList = () => {
    const { resetState } = this.state;
    this.setState({
      search: {
        ...this.state.search,
        value: ""
      },
      type: {
        ...this.state.type,
        selectedOption: resetState.type
      },
      items: {
        ...this.state.items,
        selectedOption: resetState.items
      },
      totalItems: 0,
      loading: true,
      disabled: true,
      selectedPage: 1,
      params: resetState.params,
      startDate: null,
      endDate: null
    }, this.getInvoices);
  }

  closeModal = () => {
    this.setState(
      {
        dateRange: false,
        type: {
          ...this.state.type,
          selectedOption: { label: "All", value: "all" }
        },
        loading: true,
        disabled: true
      },
      () => {
        const params = {
          page: this.state.selectedPage,
          per_page: this.state.items.selectedOption.value,
          type: this.state.type.selectedOption.value
        };
        this.setState({ params }, () => this.getInvoices());
      }
    );
  };

  showErroDetail = index => {
    this.setState({
      ...this.state,
      showErrorModal: true,
      errormsg: this.state.invoiceList[index].quickbook_last_synced_detail
    });
  };

  formatDate = dateString => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = new Date(dateString);
    return formatToTimeZone(zonedDate, "MM/DD/YYYY HH:mm:ss", { timeZone: clientTimeZone });
  };

  renderQuickBookTimeStamp = () => {
    const { quickbookTimestamps } = this.state;
    const quickbookTimeStampCopy = [...quickbookTimestamps];
    quickbookTimeStampCopy.sort((date1, date2) => dateFns.compareDesc(new Date(date1), new Date(date2)));
    return quickbookTimeStampCopy.map(timeStamp => (
      <p>
        {this.formatDate(timeStamp)}
      </p>
    ))
  }

  dateChangeHandler = (dateKey, date) => {
    this.setState({ [dateKey]: date })
  }

  render() {
    let removeIcon;
    if (this.state.search.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times remove-icon cursor"
          onClick={() => this.clearQueryString()}
        />
      );
    }
    const invoiceList = this.state.invoiceList.map((invoice, index) => (
      <tr
        style={{
          width: "100%",
          height: 50,
          border: "1px solid rgb(225,225,225)"
        }}
      >
        <td>
          <Link to={`/invoices/${invoice.id}`} target="_blank">
            {invoice.reference}
          </Link>
        </td>
        <td>
          {invoice.name_for_reference ? invoice.name_for_reference : "--"}
        </td>
        <td>
          {invoice.created_at
            ? dateFns.format(invoice.created_at, "MM/DD/YYYY")
            : "--"}
        </td>
        {this.state.type.selectedOption.label === "Submitted to accounting" && (
          <td>
            {invoice.sent_to_accounting_via
              ? invoice.sent_to_accounting_via
              : "--"}
          </td>
        )}
        <td>
          {invoice.quickbook_last_synced_time
            ? dateFns.format(
              invoice.quickbook_last_synced_time,
              "MM/DD/YYYY HH:mm:ss"
            )
            : "--"}
        </td>
        <td>
          {invoice.quickbook_last_synced_detail ? (
            invoice.quickbook_last_synced_detail == "success" ? (
              "Success"
            ) : (
                <a
                  href="javascript:void(0);"
                  onClick={() => this.showErroDetail(index)}
                >
                  Failed
                </a>
              )
          ) : (
              "--"
            )}
        </td>

        <td>
            <div className="mt-1">
              <Button
                type="success"
                className="btn-sm"
                clicked={() => {
                  this.setState({sync_type: "single", invoice_id_for_sync: invoice.id});
                  this.getTaxCodesStatus();
                }}
                disabled={this.state.quickbookSync || this.state.disabled}
                style={{
                  cursor:
                    this.state.quickbookSync || this.state.disabled
                      ? "no-drop"
                      : "pointer"
                }}
              >
                {this.state.quickbookSync
                  ? "Syncing"
                  : "Sync"}
              </Button>
            </div>
        </td>
      </tr>
    ));
    let totalItems =
      this.state.totalItems < this.state.items.selectedOption.value
        ? this.state.totalItems
        : this.state.items.selectedOption.value;
    let to =
      this.state.totalItems < this.state.selectedPage * totalItems
        ? this.state.totalItems
        : this.state.selectedPage * totalItems;
    let paginationContent = (
      <div className="row">
        <div className="col-lg-5 d-flex justify-content-start align-items-center">
          {`Showing ${(this.state.selectedPage - 1) *
            this.state.items.selectedOption.value +
            1} to ${to} of ${this.state.totalItems}`}
        </div>

        <div className="col-lg-7 d-flex align-items-center justify-content-end">
          <Pagination
            className="quickbooks-invoice-pagination mr-n-1 mt-1"
            pageCount={
              this.state.totalItems / this.state.items.selectedOption.value
            }
            forcePage={this.state.selectedPage - 1}
            handlePageClick={this.paginationHandler}
            range={2}
          />
        </div>
      </div>
    );

    this.state.quickbooksFile.sort(
      (file1, file2) =>
        new Date(file2.created_at).getTime() - new Date(file1.created_at).getTime()
    );

    return (
      <div className="system-setting-accounting-codes p-3">
        {this.state.settingLoading || this.state.outerLoading
          ? (
            <Spinner />
          ) : (
        <div className="quickbook-settings">
          <div className="row mt-3">
            <div className="col">
              <h5 className="font-weight-bold m-0">Quickbooks Settings</h5>
            </div>
          </div>
          <div className="card border mt-4">
            <div className="card-body">
              {this.state.settingLoading
                ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="row">
                      {this.state.qbLinked ? (
                        <div className="col-12">
                          <span>
                            Quickbooks is Integrated: 
                          </span>&nbsp;&nbsp;
                          <button 
                            className="mt-2 font-weight-bold rounded text-primary cursor" 
                            onClick={this.unlinkQBConnect}
                          >
                              Unlink from Quickbooks
                          </button>
                        </div>
                      ) : (
                        <div className="col-12">
                        <span>
                          Quickbooks is not Integrated: 
                        </span>&nbsp;&nbsp;
                        <button 
                          className="mt-2 font-weight-bold rounded text-primary cursor" 
                          onClick={this.qbAuth}
                          >
                          Link to Quickbooks
                        </button>
                      </div>
                      )}
                    </div>
                    <div className="row mt-3">
                      <div className="col-12">
                        <form
                          onSubmit={this.setSyncHandler}
                          >
                          <div className="row">
                            <div className="col-lg-6 mt-3">
                              <label>
                                Only Sync from this date forward
                              </label>
                              <div className="position-relative">
                                { this.state.syncFromDate &&
                                  (<i 
                                    className="fas fa-times cursor position-absolute date-clear-icon"
                                    onClick={() => this.setState({ syncFromDate: null })} 
                                  />)
                                }
                                <DatePicker
                                  className="pr-4"
                                  selected={this.state.syncFromDate}
                                  dateFormat="yyyy.MM.dd"
                                  changed={date => 
                                    this.dateChangeHandler("syncFromDate", date)
                                  }
                                  disabled={this.state.syncDatePickedDisabled}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                              <label>
                                Only Sync till this date
                              </label>
                              <div className="position-relative">
                                { this.state.syncTillDate &&
                                  (<i 
                                    className="fas fa-times cursor position-absolute date-clear-icon"
                                    onClick={() => this.setState({ syncTillDate: null })} 
                                    />)
                                  }
                                <DatePicker
                                  className="pr-4"
                                  selected={this.state.syncTillDate}
                                  dateFormat="yyyy.MM.dd"
                                  changed={date => 
                                    this.dateChangeHandler("syncTillDate", date)
                                  }
                                  disabled={this.state.syncDatePickedDisabled}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12">
                              <FormButton
                                disabled={
                                  this.state.syncDatePickedDisabled ||
                                  (!this.state.syncTillDate &&
                                    this.state.syncFromDate) ||
                                  (!this.state.syncFromDate && 
                                    this.state.syncTillDate)
                                }
                              >
                                Update Settings
                              </FormButton>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          </div>
          {this.state.outerLoading ? (
            <Spinner />
          ) : (
            <div className="mt-5">
              <div className="row mb-2">
                <div className="col-4">
                  <h5 className="font-weight-bold">Invoices</h5>
                </div>
                <div className="col-8 text-right">
                  <div className="btn-group">
                    <Button
                      type="success"
                      className="ml-2"
                      clicked={() => {
                        this.getTaxCodesStatus();
                        this.setState({sync_type: "multiple"});
                      }}
                      disabled={true}
                      style={{
                        cursor: "no-drop"
                      }}
                    >
                      {this.state.quickbookSync
                        ? "Syncing to Quickbooks"
                        : "Sync to Quickbooks"}
                    </Button>
                    <Button
                      type="success"
                      disabled={this.state.disabled}
                      style={{
                        cursor: this.state.disabled ? "no-drop" : "pointer"
                      }}
                      clicked={() =>
                        this.setState({ quickbookTimestampModal: true })
                      }
                    >
                      <i className="fa fa-clock-o" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row py-3">
                <div className="col-lg-4 col-md-6">
                  <div className="d-flex align-items-baseline justify-content-md-start justify-content-end">
                    <span className="pr-2">
                      Show entries:
                    </span>
                    <div className="w-50">
                      <Input
                        {...this.state.items}
                        changed={event => this.inputChangedHandler(event, "items")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Input
                    {...this.state.type}
                    changed={event => this.inputChangedHandler(event, "type")}
                  />
                </div>
                <div className="col-lg-4 col-md-8 offset-md-4 offset-lg-0">
                  <div className="d-flex align-items-baseline justify-content-end">
                    <span className="pr-2">
                      Search:
                    </span>
                    <div className="w-60">
                      <Input
                        {...this.state.search}
                        changed={event => this.searchHandler(event, "search")}
                        className="pr-5"
                        onBlur={this.searchHandler}
                      />
                      <i className="fas fa-search search-icon" />
                      {removeIcon}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row quickbooks-invoices">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-bordered" id="table-heading">
                      <thead>
                        <tr>
                          <th>Invoice#</th>
                          <th>Member Name</th>
                          <th>Creation Date</th>
                          {this.state.type.selectedOption.label ===
                            "Submitted to accounting" && <th>Submitted</th>}
                          <th>Last Synced</th>
                          <th>Last Synced Status</th>
                          <th>Sync</th>
                        </tr>
                      </thead>
                      <tbody>{!this.state.loading && invoiceList}</tbody>
                    </table>
                  </div>
                  {this.state.loading && <Spinner />}
                </div>
              </div>
              {!this.state.loading &&
                this.state.invoiceList &&
                this.state.invoiceList.length === 0 && (
                  <h4 className="text-lg-center py-4">No Data Found</h4>
                )}
              {this.state.totalItems > 0 && paginationContent}

              <Modal
                title="Select Date Range"
                show={this.state.dateRange}
                hide={this.closeModal}
              >
                <DateRange
                  closeModal={this.closeModal}
                  dateRange={this.dateRange}
                />
              </Modal>

              <Modal
                title="Error Detail"
                show={this.state.showErrorModal}
                hide={() => this.setState({ showErrorModal: false })}
                center
              >
                <div className="row">
                  <div className="col-md-12 text-center">
                    <span>{this.state.errormsg}</span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12 text-center">
                    <Button
                      type="delete"
                      clicked={() =>
                        this.setState({ ...this.state, showErrorModal: false })
                      }
                    >
                      Cancel
                  </Button>
                  </div>
                </div>
              </Modal>
              <Modal
                title="Confirm Tax Codes"
                show={this.state.showTaxMatchModal}
                hide={() =>
                  this.setState({
                    showTaxMatchModal: false
                  })
                }
                center
              >
                <div className="row mt-3">
                  <div className="col-lg-4">
                    <span className="font-weight-bold">GST: </span>
                  </div>
                  <div className="col-lg-8">
                    <Input
                      className="ml-4"
                      {...this.state.gst}
                      changed={event => this.inputChangedHandler(event, "gst")}
                    />
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-4">
                    <span className="font-weight-bold">PST: </span>
                  </div>
                  <div className="col-lg-8">
                    <Input
                      className="ml-4"
                      {...this.state.pst}
                      changed={event => this.inputChangedHandler(event, "pst")}
                    />
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-4">
                    <span className="font-weight-bold">HST: </span>
                  </div>
                  <div className="col-lg-8">
                    <Input
                      className="ml-4"
                      {...this.state.hst}
                      changed={event => this.inputChangedHandler(event, "hst")}
                    />
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-4">
                    <span className="font-weight-bold">Out of Scope: </span>
                  </div>
                  <div className="col-lg-8">
                    <Input
                      className="ml-4"
                      {...this.state.oos}
                      changed={event => this.inputChangedHandler(event, "oos")}
                    />
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-4">
                    <span className="font-weight-bold">Grouped (GST + PST): </span>
                  </div>
                  <div className="col-lg-8">
                    <Input className="ml-4" {...this.state.groupedGstPst} changed={event => this.inputChangedHandler(event, "groupedGstPst")} />
                  </div>
                </div><div className="row mt-1">
                  <div className="col-lg-4">
                    <span className="font-weight-bold">Grouped (GST + HST): </span>
                  </div>
                  <div className="col-lg-8">
                    <Input className="ml-4" {...this.state.groupedGstHst} changed={event => this.inputChangedHandler(event, "groupedGstHst")} />
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-4">
                    <span className="font-weight-bold">Grouped (PST + HST): </span>
                  </div>
                  <div className="col-lg-8">
                    <Input className="ml-4" {...this.state.groupedPstHst} changed={event => this.inputChangedHandler(event, "groupedPstHst")} />
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-4">
                    <span className="font-weight-bold">Grouped (GST + PST + HST): </span>
                  </div>
                  <div className="col-lg-8">
                    <Input className="ml-4" {...this.state.groupedGstPstHst} changed={event => this.inputChangedHandler(event, "groupedGstPstHst")} />
                  </div>
                </div>
                <div className="row mt-3 d-flex justify-content-center">
                  <div className="text-center">
                    <Button type="success" clicked={this.saveTaxMatches}>
                      OK
                  </Button>
                  </div>
                  <div className="ml-3 text-center">
                    <Button
                      type="delete"
                      clicked={() =>
                        this.setState({
                          ...this.state,
                          showTaxMatchModal: false
                        })
                      }
                    >
                      Cancel
                  </Button>
                  </div>
                </div>
              </Modal>
              <Modal
                title="Chart of Account Sync"
                show={this.state.syncChartOfAccountModal}
                hide={() => this.setState({ syncChartOfAccountModal: false })}
              >
                <React.Fragment>
                  <form
                    className="mt-3"
                    onSubmit={event => this.syncChartOfAccountHandler(event)}
                  >
                    <div className="pl-3">
                      <div className="row cursor font-weight-bold">
                        In order to ensure a correct synchronization, we need to
                        Sync your chart of accounts
                    </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-center">
                      <Button
                        className="ml-2"
                        type="danger"
                        clicked={() =>
                          this.setState({ syncChartOfAccountModal: false })
                        }
                      >
                        Cancel
                    </Button>
                      <FormButton className="ml-2">Accept</FormButton>
                    </div>
                  </form>
                </React.Fragment>
              </Modal>
              <Modal
                title="Sync timestamps"
                show={this.state.quickbookTimestampModal}
                hide={() => this.setState({ quickbookTimestampModal: false })}
                closeButton={true}
                closeModal={() => this.setState({ quickbookTimestampModal: false })}
              >
                <React.Fragment>
                  <div className="pl-3">
                    <div className="mt-4">
                      {this.state.quickbookTimestamps && !_.isEmpty(this.state.quickbookTimestamps) ? (
                        <div className="text-center">
                          {this.renderQuickBookTimeStamp()}
                        </div>
                      ) : (
                          <h5 className="text-center">Not synced to Quickbooks yet</h5>
                        )}
                    </div>
                  </div>
                </React.Fragment>
              </Modal>
            </div>
          )}
        </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default withRouter(
  connect(mapStateToProps)(Quickbooks)
);
