import React, { useState, useEffect } from "react"
import axios from "../../../configAxios"
import LabelCategoryDropdown from "./LabelCategoryDropdown"
import LabelDropdown from "./LabelDropdown"
import LabelList from "./LabelList"
import { sortBy } from "lodash"

const LabelSection = ({ programId }) => {
  const [categoryId, setCategoryId] = useState(null)
  const [labelOptions, setLabelOptions] = useState([])
  const [tags, setTags] = useState([])

  const labelCategorySelectHandler = (categoryId) => { setCategoryId(categoryId) }

  useEffect(() => {
    if (categoryId) {
      axios.get("/labels", { params: { label_category_id: categoryId, tag_type: "program_global" } })
        .then((res) => {
          const formatLabelsInCategory = res.data.json.data.map(label => {
            return { label: label.name, value: `${label.tag_type}:${label.name}`, order: label.order, definition: label.definition }
          })
          const orderedLabels = sortBy(formatLabelsInCategory, 'order')
          setLabelOptions(orderedLabels)
        })
        .catch((err) => err)
    }
  }, [categoryId])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="card-text">
            <div className="row border-bottom">
              <div className="col-lg-7">
                <h5 className="pb-2">Labels</h5>
              </div>
            </div>
            <div className="py-4">
              <LabelCategoryDropdown
                categoryId={categoryId}
                labelCategorySelectHandler={labelCategorySelectHandler}
              />
            </div>
            <LabelDropdown
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              labelOptions={labelOptions}
              programId={programId}
              setTags={setTags}
              tags={tags}
            />
            <LabelList
              programId={programId}
              tags={tags}
              setTags={setTags}
              setCategoryId={setCategoryId}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default LabelSection
