import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { title, snakeCase } from "change-case"
import queryString from "query-string"

import langUtils from "../../utils/langUtils"
import Spinner from "../UI/Spinner"

import Donor from "./Donor"
import Summary from "./Summary"
import Bookings from "./booking/Bookings"
import Volunteer from "./Volunteer"
import Programs from "./Programs"
import AccountTab from "./AccountTab"
import ProjectTab from "./ProjectTab"

class Tabs extends Component {
  state = {
    activeTab: "",
    ticketId: null,
    tabs: [],
    userId: this.props.userId,
    loading: true,
  }

  componentDidMount() {
    if (this.props.policyRes) {
      const json = this.props.policyRes
      const tabs = []
      if (json.summary) tabs.push("Summary")
      if (json.booking) tabs.push("Bookings")
      if (json.projects) tabs.push("Projects")
      if (json.programs) tabs.push("Programs")
      if (json.volunteer) tabs.push("Volunteer")
      if (json.account) tabs.push("Account")
      if (json.donation) tabs.push("Donation")
      this.setState(
        {
          tabs,
          loading: false,
        },
        () => {
          this.handleTabFromProp()
          window.addEventListener("popstate", this.handleTabFromProp)
        }
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp)
  }

  tabChangeHandler = (activeTab, ticketId) => {
    this.setState({ activeTab, ticketId })
    let url
    if (activeTab === "Account") {
      url = `/users/${this.props.userId}/${snakeCase(activeTab)}?accountId=${
        this.props.accountId
      }`
    } else if (activeTab === "Bookings") {
      if (ticketId) {
        url = `/users/${this.props.userId}/${snakeCase(
          activeTab
        )}?ticketId=${ticketId}`
      } else {
        url = `/users/${this.props.userId}/${snakeCase(activeTab)}`
      }
    } else {
      url = `/users/${this.props.userId}/${snakeCase(activeTab)}`
    }
    this.props.history.push(url)
  }

  handleTabFromProp = () => {
    let { tab } = this.props.match.params
    const query = queryString.parse(this.props.location.search)
    tab = title(tab)
    this.setState({
      ...this.state,
      activeTab:
        tab && this.state.tabs.includes(tab) ? tab : this.state.tabs[0],
      ticketId: query.ticket !== undefined ? query.ticket : "",
    })
  }

  tabNames = (tab) => {
    let tabName = null
    switch (tab) {
      case "Projects":
        tabName = langUtils("projects", "Projects")
        break
      case "Programs":
        tabName = langUtils("programs", "Programs")
        break
      case "Volunteer":
        tabName = langUtils("volunteer_tickets", "Volunteer")
        break
      default:
        tabName = tab
    }
    return tabName
  }

  render() {
    const tabs = this.state.tabs.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${
          this.state.activeTab === tab ? "active-tab" : "inactive-tab "
        } text-center`}
      >
        {this.tabNames(tab)}
      </li>
    ))
    let activeTab
    switch (this.state.activeTab) {
      case "Summary":
        activeTab = (
          <Summary
            userId={this.state.userId}
            tabChangeHandler={this.tabChangeHandler}
            policyRes={this.props.policyRes}
          />
        )
        break
      case "Bookings":
        activeTab = (
          <Bookings
            userId={this.state.userId}
            userData={this.props.userData}
            ticketId={this.state.ticketId}
            tabChangeHandler={this.tabChangeHandler}
            policyRes={this.props.policyRes}
          />
        )
        break
      case "Programs":
        activeTab = <Programs userId={this.state.userId} />
        break
      case "Volunteer":
        activeTab = (
          <Volunteer
            userId={this.state.userId}
            policyRes={this.props.policyRes}
          />
        )
        break
      case "Account":
        activeTab = (
          <AccountTab
            userId={this.state.userId}
            accountId={this.props.accountId}
            policyRes={this.props.policyRes}
            tabChangeHandler={this.tabChangeHandler}
          />
        )
        break
      case "Projects":
        activeTab = (
          <ProjectTab
            userId={this.state.userId}
            policyRes={this.props.policyRes}
          />
        )
        break
      case "Donation":
        activeTab = <Donor userId={this.state.userId} />
        break
      default:
        activeTab = null
    }
    return (
      <div className="card">
        {this.state.loading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div className="card-header bg-white p-0 border-bottom-0">
              <ul className="nav nav-tabs ml-0">{tabs}</ul>
            </div>
            <div className="card-body p-0">
              <div className="card-text">
                <div className="row">
                  <div className="col-12">{activeTab}</div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default withRouter(Tabs)