import React from 'react'
import GrantApplicationItem from './GrantApplicationItem'

export default function GrantApplicationsTable({ grantApplications }) {

  const GrantApplicationItems = () => {
    return (
      grantApplications.map((grantApplication,index) => {
        return ( 
          <GrantApplicationItem
            key={index}
            grantApplication={grantApplication} 
          />
        )}
      )
    )
  }

  return (
    <div className="w-100 pb-3 px-4">
      { grantApplications?.length > 0
        ?
        <GrantApplicationItems />
        :
        <p className="mt-4 ml-4">
          You have no applications
        </p>
      }
    </div>
  )
}
