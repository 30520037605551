import React, { Component } from 'react';
import changeCase from "change-case"
import { connect } from "react-redux";
import dateFormat from "date-fns/format"
import WeeklyCalendar from "./weeklyCalendar"
import Spinner from "../../UI/Spinner"
import MonthlyCalendar from "./monthlyCalendar"
import axios from "../../../configAxios";

class index extends Component {
  signal = axios.CancelToken.source();
  state = {
    views: {
      options: ["week", "month", "day", "agenda"],
      selected: 'week',
    },
    events: [],
    loading: true,
    loadingCalendar: true,
    organizationName: "FAVADBSTAGING",
    date: new Date()
  }

  componentDidMount() {
    this.getCompanyData();
    this.getData();
    this.interval = setInterval(this.getData, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.signal.cancel("Request in being cancelled");
  }

  getCompanyData = () => {
    this.setState({ loading: true });
    let subdomain = window.location.hostname.split('.')[0]
    let whitelistedSubdomains = ["filmreelui", "filmreelui1", "filmreelui2", "filmreelui3", "filmreelui4", "filmreelui5"];
    if (process.env.NODE_ENV === "production" && !whitelistedSubdomains.includes(subdomain)) {
      axios.get("/organizations/is_present", { params: { subdomain }, cancelToken: this.signal.token }).then(res => {
        if (res.data.json.success) {
          this.setState({ organizationName: changeCase.upperCase(subdomain), loading: false });
          document.title = res.data.json.organization_name;
        } else {
          this.setState({ organizationName: "NO ORGANIZATION", loading: false });
        }
      })
    } else {
      this.setState({ organizationName: changeCase.upperCase(subdomain), loading: false });
    }
  }

  getData = () => {
    const params = {
      type_used: "Facility",
      asset_summary_day: dateFormat(this.state.date, "YYYY-M-D")
    };
    this.setState({ loadingCalendar: true });
    axios.get(`/assets/booking_activity`, { params, cancelToken: this.signal.token })
      .then(res => {
        const { out: outData } = res.data.json;
        const eventData = outData.map(data => {
          let name, start, end;
          if (this.props.auth.isAuthenticated) {
            const { user_or_program, user_or_program_detail } = data;
            name = user_or_program === "User" ? `${user_or_program_detail.first_name} ${user_or_program_detail.last_name}` : user_or_program_detail.name;
            start = data.start;
            end = data.end;
          } else {
            name = data.name;
            start = data.start_time;
            end = data.end_time
          }
          return { name, start, end }
        });
        this.setState({
          events: eventData,
          loadingCalendar: false
        });
      })
  }

  viewSwitchHandler = (event, view) => {
    this.setState({ views: { ...this.state.views, selected: view } })
  }

  dateChangeHook = date => {
    this.setState({ date }, () => { this.getData() });
  }

  render() {
    const { views, date, loadingCalendar } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            {this.state.loading ?
              <Spinner />
              : (<><h2 className="text-center">{this.state.organizationName}</h2>
                <div className="card bg-white">
                  <div className="card-header text-center">
                    <h4>
                      Facility Calendar
                    </h4>
                    <div className="row">
                      <div className="col-12">
                        <div className="btn-toolbar justify-content-end" role="toolbar" aria-label="Toolbar with button groups">
                          <div className="btn-group" role="group" aria-label="view button group">
                            {views.options.map((option, index) => (
                              <button type="button" onClick={(event) => this.viewSwitchHandler(event, option)} id={option} key={`${option}-${index}`} className="btn border-dark" style={{ backgroundColor: `${option === this.state.views.selected ? "#3174ad" : "#fff"}`, color: `${option === this.state.views.selected ? "#fff" : "#999"}` }}>{changeCase.upperCaseFirst(option)}</button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {loadingCalendar ? <Spinner /> :
                        <>
                          {this.state.views.selected === "month" ? <MonthlyCalendar dateChangeHook={this.dateChangeHook} date={date} assetsOut={this.state.events} /> : <WeeklyCalendar date={date} view={this.state.views.selected} dateChangeHook={this.dateChangeHook} eventData={this.state.events} />}
                        </>
                      }
                    </div>
                  </div>
                </div></>)}
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center my-5 py-5">
            {`Powered By FAVA © ${new Date().getFullYear()} All rights reserved`}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({ auth: state.auth })

export default connect(mapStateToProps)(index)
