import React, { Component } from "react";
import { title } from "change-case";
import { toast } from "react-toastify";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";

import Spinner from "../../UI/Spinner";
import Button from "../../UI/button/Button";
import axios from "../../../configAxios";
import Modal from "../../UI/Modal";
import Input from "../../UI/input/Input";
import langUtils from "../../../utils/langUtils";

import NewAccountingCode from "./NewAccountingCode.js";
import FilterAccountingCode from "./FilterAccountingCode"

import "./Accounting.css";

class AccountingCodes extends Component {
  signal = axios.CancelToken.source();
  state = {
    accountingCodes: [],
    missingAccountingCodes: [],
    generalAccountingCodes: [],
    registrationCodes: [],
    renewalCodes: [],
    newModal: false,
    viewModal: false,
    editModal: false,
    loading: true,
    edit: false,
    id: null,
    flag: true,
    order: {
      column: "name",
      dir: "asc"
    },
    timeout: 0,
    query: "",
    search: {
      elementType: "input",
      elementConfig: {
        name: "search",
        type: "text",
        required: true
      },
      value: ""
    },
    isQuickbooksLinked: null
  };

  componentDidMount() {
    this.getAccountingCodes();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getAccountingCodes = () => {
    let params = {
      order: {
        column: this.state.order.column,
        dir: this.state.order.dir
      }
    };
    if (!_.isEmpty(this.state.query)) {
      params = {
        ...params,
        query: this.state.query
      };
    }

    axios
      .get("/accounting_codes", { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          accountingCodes: res.data.accounting_codes.filter(accountCode => !_.isNull(accountCode.code) && !accountCode.general),
          missingAccountingCodes: res.data.accounting_codes.filter(accountCode => _.isNull(accountCode.code)),
          generalAccountingCodes: res.data.accounting_codes.filter(accountingCode => accountingCode.general && !_.isNull(accountingCode.code)),
          loading: false,
          isQuickbooksLinked: res.data.meta.is_quickbook_linked
        });
      });
  };

  sortHandler = (event, category, order) => {
    this.setState({
      ...this.state,
      order: {
        column: category,
        dir: order
      },
      loading: true
    }, () => this.getAccountingCodes()
    );
  };

  getSortingOrder = () => {
    return this.state.order.dir === "desc" ? "asc" : "desc"
  }

  newModal = () => {
    window.scrollTo(0, 0);
    if(this.state.isQuickbooksLinked) {
      this.setState({ viewModal: true, edit: false });
    }
    else {
      this.setState({ newModal: true, edit: false });
    } 
  };


  accEditModal = id => {
    window.scrollTo(0, 0);
    this.setState({
      edit: true,
      newModal: true,
      id: id
    });
  };

  clearQueryString = () => {
    this.setState({
      search: {
        ...this.state.search,
        value: ""
      },
      query: "",
      loading: true
    }, () => this.getAccountingCodes());
  }

  searchHandler = (event, key) => {
    let inputValue = {};
    if (key === "search") {
      inputValue["value"] = event.target.value;
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({
      [key]: updatedcontrols,
      query: event.target.value,
      loading: true
    })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getAccountingCodes();
    }, 1000);
  }

  deleteHandler = id => {
    this.setState({ loading: true })
    let response = window.confirm("Are you sure you want to delete the Accounting Code?");
    if (response) {
      axios.delete(`/accounting_codes/${id}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Accounting code successfully deleted");
            this.getAccountingCodes();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  render() {
    let removeIcon;
    if (this.state.search.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times remove-icon-accounting"
          onClick={() => this.clearQueryString()}
        />
      );
    }
    let accountingCodeList;
    accountingCodeList = this.state.accountingCodes.map(code => {
      return (<li className="list-group-item rounded-0" key={code.id}>
        <div className="row">
          <div className="col-4">
            {_.isEmpty(code.name) ? "--" : code.name} <br />
            {code.assets_count > 0 && (
              <span className="asset-name-font">
                This Accounting Code is currently associated with{" "}
                <Link to={{
                  pathname: "/assets",
                  search: `?accounting_id=${code.id}`
                }}>{code.assets_count} Assets </Link>
              </span>
            )}
          </div>
          <div className="col-2">
            {code.code}
          </div>
          <div className="col-2">
            {code.account_number ? code.account_number : "--"}
          </div>
          <div className="col-3">
            {code.code_type ? title(code.code_type) : "--"}
          </div>
          <div className="col-1 d-flex p-0">
            <i
              className="fas fa-pencil-alt accounting-code-edit cursor pr-4"
              onClick={() => this.accEditModal(code.id)}
            />
            {code.can_delete &&
              <i
                className="fas fa-trash cursor accounting-code-edit"
                onClick={() => this.deleteHandler(code.id)}
              />
            }
          </div>
        </div>
      </li>
      )
    });

    let missingAccountingCodesList;
    missingAccountingCodesList = this.state.missingAccountingCodes.map(code => {
      return (
        <FilterAccountingCode key={code.id} code={code} deleteHandler={this.deleteHandler} accEditModal={this.accEditModal} />
      )
    });


    let generalAccountingCodesList;
    generalAccountingCodesList = this.state.generalAccountingCodes.map(code => {
      return <FilterAccountingCode key={code.id} code={code} deleteHandler={this.deleteHandler} accEditModal={this.accEditModal} />
    });

    return (
      <div className="system-setting-accounting-codes p-3">
        <div className="card-body m-0 p-0">
          <div className="card-text">
            <div className="row">
              <div className="col-4" />
              <div className="col-8 d-flex justify-content-end">
                <Button type="success" clicked={this.newModal}>
                  {langUtils("txt_global_add_new", "Add New")}
                </Button>
              </div>
            </div>
            <div className="row mt-3 ml-2">
              Note: You can only delete an accounting code once all associated
              Assets have been disassociated
            </div>
            <div className="row mt-2 ml-1">
              <div className="col-6 px-0" />
              <div className="col-2 p-0 mt-3 d-flex justify-content-end align-items-center">
                Search:
                </div>
              <div className="col-4 mt-3 justify-content-end ">
                <i className="fas fa-search search-icon-accounting" />
                {removeIcon}
                <Input
                  {...this.state.search}
                  changed={event => this.searchHandler(event, "search")}
                  style={{ padding: "2%", paddingRight: "55px" }}
                  onBlur={this.searchHandler}
                />
              </div>
            </div>
            <div className="system-setting-accounting-codes p-3">
              <div className="card-body m-0 p-0">
                {this.state.loading ? (
                  <div className="card-text">
                    <div className="row">
                      <div className="col-12">
                        <Spinner className="h-60vh" />
                      </div>
                    </div>
                  </div>
                ) :
                  (!_.isEmpty(this.state.accountingCodes) || !_.isEmpty(this.state.generalAccountingCodes) || !_.isEmpty(this.state.missingAccountingCodes))
                    ? <div className="card-text">
                      <ul className="list-group border-top list-group-flush">
                        <li className="list-group-item rounded-0">
                          <div className="row">
                            <div className="col-4 text-center d-flex justify-content-between cursor"
                              onClick={(event) => {
                                const order = this.getSortingOrder()
                                this.sortHandler(event, "name", order)
                              }}>
                              <div className="font-weight-bold">Name</div>
                              <div className="position-relative text-muted"
                              >
                                <i
                                  className="fas fa-caret-up"
                                  style={{
                                    color: this.state.order.column === "name"
                                      ? this.state.order.dir === "asc"
                                        ? "#808080"
                                        : "white"
                                      : "#808080"
                                  }}
                                />
                                <i
                                  className="fas fa-caret-down cursor position-relative"
                                  style={{
                                    top: 10,
                                    right: 8.7,
                                    color: this.state.order.column === "name"
                                      ? this.state.order.dir === "desc"
                                        ? "#808080"
                                        : "white"
                                      : "#808080"
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="col-2 cursor"
                              onClick={(event) => {
                                const order = this.getSortingOrder()
                                this.sortHandler(event, "code", order)
                              }}>
                              <div className="font-weight-bold">Accounting Code</div>
                              <div className="position-relative text-muted mt-n-25">
                                <i
                                  className="fas fa-caret-up"
                                  style={{
                                    color: this.state.order.column === "code"
                                      ? this.state.order.dir === "asc"
                                        ? "#808080"
                                        : "white"
                                      : "#808080"
                                  }}
                                />
                                <i
                                  className="fas fa-caret-down cursor position-relative cursor"
                                  style={{
                                    top: 10,
                                    right: 8.7,
                                    color: this.state.order.column === "code"
                                      ? this.state.order.dir === "desc"
                                        ? "#808080"
                                        : "white"
                                      : "#808080"
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="col-2 cursor"
                              onClick={(event) => {
                                const order = this.getSortingOrder()
                                this.sortHandler(event, "account_number", order)
                              }}>
                              <div className="font-weight-bold">Tax Code</div>
                              <div className="position-relative text-muted mt-n-25">
                                <i
                                  className="fas fa-caret-up"
                                  style={{
                                    color: this.state.order.column === "account_number"
                                      ? this.state.order.dir === "asc"
                                        ? "#808080"
                                        : "white"
                                      : "#808080"
                                  }}
                                />
                                <i
                                  className="fas fa-caret-down cursor position-relative"
                                  style={{
                                    top: 10,
                                    right: 8.7,
                                    color: this.state.order.column === "account_number"
                                      ? this.state.order.dir === "desc"
                                        ? "#808080"
                                        : "white"
                                      : "#808080"
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="col-3 text-center d-flex justify-content-between cursor"
                            >
                              <div className="font-weight-bold">Type</div>
                            </div>
                            <div className="col-1" />
                          </div>
                        </li>
                        {missingAccountingCodesList}
                        {generalAccountingCodesList}
                        {accountingCodeList}
                      </ul>
                    </div>
                    : <h2 className="text-center my-5 py-5">
                      No Accounting code found...
            </h2>
                }
              </div>
            </div>

            <Modal
              show={this.state.newModal}
              title={this.state.edit
                ? "Edit Accounting Code"
                : "Create Accounting Code"
              }
              hide={() => this.setState({ newModal: false })}
              flag={this.state.flag}
            >
              {this.state.isQuickbooksLinked && (
                <div className="mt-4">
                  When the QuickBooks integration is enabled, the Chart of
                  Accounts should be updated in QuickBooks. After
                  synchronization, the updated chart will be synchronized with
                  AMS.
                  <hr />
                </div>
              )}
              <NewAccountingCode
                closeModal={() => this.setState({ newModal: false })}
                getAccountingCodes={this.getAccountingCodes}
                edit={this.state.edit}
                accEditModal={this.accEditModal}
                id={this.state.id}
              />
            </Modal>

            <Modal
              show={this.state.viewModal}
              title="Chart of Accounts"
              hide={() => this.setState({ viewModal: false })}
            >
              <div className="row">
                <div className="col-md-12 mt-4">
                  <p className="text-center font-weight-bold word-wrap-break">
                    When the QuickBooks integration is enabled, the Chart of
                    Accounts should be created in QuickBooks. After
                    synchronization, the new chart will be imported to AMS.
                  </p>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div >
    );
  }
}

export default withRouter(AccountingCodes);
