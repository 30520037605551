import Input from "../../UI/input/Input"
import React from "react"

const ShowToggle = ({ checked, setChecked, label }) => {
  return(
    <Input
      elementType={"input"}
      elementConfig={{ type: "checkbox", checked: checked }}
      changed={setChecked}
      label={label}
    />
  )
}

export default ShowToggle
