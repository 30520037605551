import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";
import { withRouter } from "react-router-dom";
import axios from "../../../../configAxios";
import Input from "../../../UI/input/Input";
import FormButton from "../../../UI/button/FormButton";
import Button from "../../../UI/button/Button";
import Spinner from "../../../UI/Spinner";
import Modal from "../../../UI/Modal";
import UserPermissions from "./UserPermissions";

class AddOrEditUser extends Component {
  signal = axios.CancelToken.source();
  state = {
    memberType: [],
    roleType: [],
    firstName: {
      elementType: "input",
      elementConfig: {
        name: "firstName",
        type: "text",
        required: true
      },
      label: "First Name",
      value: ""
    },
    lastName: {
      elementType: "input",
      elementConfig: {
        name: "lastName",
        type: "text",
        required: true
      },
      label: "Last Name",
      value: ""
    },
    jobPosition: {
      elementType: "input",
      elementConfig: {
        name: "jobPosition",
        type: "text"
      },
      label: "Job Position / Description",
      value: ""
    },
    email: {
      elementType: "input",
      elementConfig: {
        name: "email",
        type: "email",
        required: true
      },
      label: "Email Address",
      value: ""
    },
    memberTypeId: {
      elementType: "select",
      elementConfig: {
        name: "memberTypeId",
        type: "number",
        options: [],
        required: true
      },
      disabled: false,
      label: "Member Type",
      selectedOption: null
    },
    password: {
      elementType: "input",
      elementConfig: {
        autoComplete: "new-password",
        name: "password",
        type: "password",
        required: !this.props.edit
      },
      label: "Reset Password",
      value: ""
    },
    passwordConfirmation: {
      elementType: "input",
      elementConfig: {
        autoComplete: "new-password",
        name: "passwordConfirmation",
        type: "password",
        required: !this.props.edit
      },
      label: "Confirm New Password",
      value: ""
    },
    permissions: {
      elementType: "select",
      elementConfig: {
        name: "permissions",
        options: [],
        required: true,
        isClearable: false
      },
      selectedOption: null
    },
    loading: true,
    disabled: false,
    showPermissionsModal: false,
    disableMemberType: false,
    customFields: null
  };

  async componentDidMount() {
    const res = await axios.get(`/users/formdata`, {
      cancelToken: this.signal.token
    });
    const response = await axios.get("/custom_fields", {
      cancelToken: this.signal.token
    });
    const { member_type } = res.data.json;
    let { role: roles } = res.data.json;
    const rolesSequence = { "super_admin": 1, "staff": 2, "accounting": 3, "member": 4, "non_member": 5 };
    roles = roles.sort((role1, role2) => {
      return rolesSequence[role1.level] - rolesSequence[role2.level];
    })
    const { custom_fields } = response.data;
    const klassName = "Role";
    let customFields = custom_fields.filter(field => field.klass_name === klassName);
    this.setState({
      ...this.state,
      memberType: member_type,
      roleType: roles,
      memberTypeId: {
        ...this.state.memberTypeId,
        elementConfig: {
          ...this.state.memberTypeId.elementConfig,
          options: member_type.map(item => {
            return { value: item.id, label: item.name };
          })
        }
      },
      permissions: {
        ...this.state.permissions,
        elementConfig: {
          ...this.state.permissions.elementConfig,
          options: roles.map(item => {
            return { value: item.id, label: item.name, level: item.level };
          })
        }
      },
      loading: this.props.edit,
      customFields
    });

    if (this.props.edit) {
      const res = await axios.get(`/users/${this.props.id}`, {
        cancelToken: this.signal.token
      });
      const user = res.data.user;
      this.setState({
        ...this.state,
        firstName: {
          ...this.state.firstName,
          value: user.first_name
        },
        lastName: {
          ...this.state.lastName,
          value: user.last_name
        },
        jobPosition: {
          ...this.state.jobPosition,
          value: user.job_position
        },
        email: {
          ...this.state.email,
          value: user.email
        },
        memberTypeId: {
          ...this.state.memberTypeId,
          elementConfig: {
            ...this.state.memberTypeId.elementConfig,
            required: user.member_type_id ? true : false
          },
          selectedOption: user.member_type_id && {
            value: user.member_type_id,
            label: this.state.memberType.filter(type =>
              type.id === user.member_type_id ? true : false
            )[0].name
          },
          disabled: this.state.roleType.filter(type =>
            type.id === user.role_id
          )[0].name === "User basic"
        },
        permissions: {
          ...this.state.permissions,
          selectedOption: user.role_id && {
            value: user.role_id,
            label: this.state.roleType.filter(type =>
              type.id === user.role_id ? true : false
            )[0].name,
            level: this.state.roleType.filter(type =>
              type.id === user.role_id ? true : false
            )[0].level
          }
        },
        loading: false
      });
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    if (key !== "memberTypeId" && key !== "permissions") {
      inputValue["value"] = event.target.value;
    } else {
      inputValue["selectedOption"] = event;
      if (event && event.label === "User basic") {
        this.setState({
          memberTypeId: {
            ...this.state.memberTypeId,
            elementConfig: {
              ...this.state.memberTypeId.elementConfig,
              required: false
            },
            disabled: true,
            selectedOption: null
          }
        })
      } else {
        this.setState({
          memberTypeId: {
            ...this.state.memberTypeId,
            elementConfig: {
              ...this.state.memberTypeId.elementConfig,
              required: true
            },
            disabled: false
          }
        })
      }
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let updatedData = {};
    let updatedEdit = {};
    const {
      firstName,
      lastName,
      jobPosition,
      email,
      memberTypeId,
      password,
      passwordConfirmation,
      permissions
    } = this.state;
    updatedData["first_name"] = firstName.value;
    updatedData["last_name"] = lastName.value;
    updatedData["job_position"] = jobPosition.value;
    updatedData["email"] = email.value;
    updatedData["member_type_id"] = memberTypeId.selectedOption && memberTypeId.selectedOption.value;
    updatedData["password"] = password.value;
    updatedData["password_confirmation"] = passwordConfirmation.value;
    updatedData["role_id"] = permissions.selectedOption.value;

    updatedEdit["id"] = this.props.id;
    updatedEdit["first_name"] = firstName.value;
    updatedEdit["last_name"] = lastName.value;
    updatedEdit["job_position"] = jobPosition.value;
    updatedEdit["email"] = email.value;
    updatedEdit["member_type_id"] = memberTypeId.selectedOption && memberTypeId.selectedOption.value;
    updatedEdit["password"] = password.value;
    updatedEdit["password_confirmation"] = passwordConfirmation.value;
    updatedEdit["role_id"] = permissions.selectedOption.value;

    if (this.props.edit) {
      axios
        .put(`/users/database_user`, updatedEdit)
        .then(res => {
          if (res.data.json.success) {
            toast.success("User successfully updated");
            this.props.tabChangeHandler("Users")
            this.props.history.push(`/system_settings/users/users/${permissions.selectedOption.level}`)
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disabled: false })
        })
        .catch(err => err);
    } else {
      axios
        .post(`/users/database_user`, updatedData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("User successfully created");
            this.props.tabChangeHandler("Users")
            this.props.history.push(`/system_settings/users/users/${permissions.selectedOption.level}`)
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disabled: false })
        })
        .catch(err => err);
    }
  };

  render() {
    const {
      firstName,
      lastName,
      jobPosition,
      email,
      memberTypeId,
      password,
      passwordConfirmation,
      permissions
    } = this.state;
    return (
      <div>
        {
          this.state.loading ? (
            <Spinner className="h-60vh" />
          ) : (
              <div>
                <form autoComplete="off" className="add-or-edit-user mt-3" onSubmit={this.submitHandler}>
                  {this.props.edit
                    ? (<h4 className="col font-weight-bold">Edit User</h4>)
                    : (<h4 className="col">New User</h4>)
                  }
                  <hr className="mt-3 mb-3" />
                  <div className="col-lg-12 row pt-3">
                    <div className="col-lg-6">
                      <Input className="font-weight-normal"
                        {...firstName}
                        changed={event => this.inputChangedHandler(event, "firstName")}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Input className="font-weight-normal"
                        {...lastName}
                        changed={event => this.inputChangedHandler(event, "lastName")}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Input className="font-weight-normal"
                        {...jobPosition}
                        changed={event =>
                          this.inputChangedHandler(event, "jobPosition")
                        }
                      />
                    </div>
                    <div className="col-lg-6">
                      <Input className="font-weight-normal"
                        {...email}
                        changed={event => this.inputChangedHandler(event, "email")}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label className="mb-0">
                        <span className="cursor"
                          onClick={() => { window.scrollTo(0, 0); this.setState({ showPermissionsModal: true }) }}
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>&nbsp;
                      <span>Permissions</span>
                      </label>
                      <Input className="mt-2 font-weight-normal"
                        {...permissions}
                        changed={event =>
                          this.inputChangedHandler(event, "permissions")
                        }
                      />
                    </div>
                    <div className="col-lg-6" style={{ cursor: memberTypeId.disabled ? "no-drop" : "" }}>
                      <Input className="font-weight-normal"
                        {...memberTypeId}
                        changed={event =>
                          this.inputChangedHandler(event, "memberTypeId")
                        }
                      />
                    </div>
                    <div className="col-lg-6">
                      <Input className="font-weight-normal"
                        {...password}
                        changed={event => this.inputChangedHandler(event, "password")}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Input className="font-weight-normal"
                        {...passwordConfirmation}
                        changed={event =>
                          this.inputChangedHandler(event, "passwordConfirmation")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center pb-5 pt-5">
                    <Button
                      type="secondary"
                      className="mr-2"
                      clicked={() => this.props.tabChangeHandler("Users")}
                    >
                      Cancel
                  </Button>
                    <FormButton
                      className="float-right ml-2"
                      disabled={this.state.disabled}
                    >
                      {this.props.edit ? "Update User" : "Create User"}
                    </FormButton>
                  </div>
                </form>
                <Modal show={this.state.showPermissionsModal}
                  title="User Permissions"
                  closeModal={() => this.setState({ showPermissionsModal: false })}
                  hide={() => this.setState({ showPermissionsModal: false })}
                  closeButton
                >
                  <UserPermissions customFields={this.state.customFields} />
                </Modal>
              </div>
            )
        }
      </div>
    );
  }
}

export default withRouter(AddOrEditUser);
