import React, { useState, useEffect } from 'react'
import Input from "../../../../UI/input/Input";
import Button from "../../../../UI/button/Button";
import FileAttachment from "./FileAttachment"
import MandatoryCheckbox from "./MandatoryCheckbox";
import Editor from "rich-markdown-editor"
import { toast } from "react-toastify"

export default function SingleQuestion({question, submitNewQuestion, updateQuestion, updateQuestionState, dragging}) {
  const editingCheck = () => (!question.question_text)

  const [questionText, setQuestionText] = useState(question.question_text)
  const [fileAttachment, setFileAttachment] = useState(question.file_attachment)
  const [isMandatory, setIsMandatory] = useState(question.mandatory)
  const [isEditing, setIsEditing] = useState(editingCheck())

  const mandatoryCheckboxVisible = () => {
    return ["ShortAnswerQuestion", "LongAnswerQuestion", "FileUploadQuestion", "DateSelectQuestion", "CurrencyQuestion"].includes(question.type)
  }

  const isFileDownloadQuestion = question.type === "FileDownloadQuestion"

  useEffect(() => {
    setIsEditing(editingCheck())
    },
    [question])

  const disabledCheck = () => {
    return (
      (isFileDownloadQuestion && !fileAttachment) ||
      (!questionText)
    )
  }

  const handleSubmit = function() {
    if (isEditing) {
      let payload = {
        question_text: questionText,
        file_attachment: fileAttachment,
        mandatory: isMandatory,
        type: question.type,
        order: question.order,
        id: question.id || question.question_id || null
      }

      if (question.id || question.question_id) {
        setIsEditing(false)
        updateQuestionState(payload)
        updateQuestion(payload).then(() => {
          toast.success("Successfully Updated Question!")
        })
      } else {
        submitNewQuestion(payload)
        updateQuestionState(payload)
        setIsEditing(false)
      }
    }

    if (!isEditing) {
      setIsEditing(!isEditing)
    }
  }

  const editingTheme = () => {
    if (!isEditing) {
      return { background: "#e9ecef" }
    }
    return {zIndex: 100, blockToolbarBackground: "#ffffff"}
  }

  return (
    <div className="d-flex flex-column mt-3 w-100">
      { mandatoryCheckboxVisible() &&
        <MandatoryCheckbox
          isMandatory={isMandatory}
          isEditing={isEditing}
          setIsMandatory={() => setIsMandatory(prev => !prev)}
        />
      }
      { question.type === "TextQuestion" ?
        <div className="p-4 mb-3 border" style={editingTheme()} >
          <Editor
            placeholder="Please enter some question text"
            defaultValue={questionText || ""}
            readOnly={dragging || !isEditing}
            onChange={(getValue) => {
              const data = getValue()
              setQuestionText(data)
            }}
            theme={editingTheme()}
          />
        </div>
        :
        <Input
          elementType="textarea"
          elementConfig={{
            name: "question_text",
            required: true,
            rows: 3,
            readOnly: !isEditing,
          }}
          changed={event => setQuestionText(event.target.value)}
          value={questionText}
          label={"Question Text"}
          style={{ width: "100%" }}
        />

      }
      { isFileDownloadQuestion &&
        <FileAttachment fileAttachment={fileAttachment} setFileAttachment={setFileAttachment} isEditing={isEditing} />
      }
      <div className='d-flex flex-row justify-content-end'>
        <Button
          className="questionSaveButton"
          type="success"
          disabled={disabledCheck()}
          clicked={() => {
            handleSubmit()
          }}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </div>
    </div>
  )
}
