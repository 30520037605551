import React, { Component } from "react";
import { toast } from "react-toastify";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Button from "../UI/button/Button";
import Pagination from "../UI/Pagination";
import Input from "../UI/input/Input";

class FavaStatsReport extends Component {
  constructor() {
    super();
    this.signal = axios.CancelToken.source();
    this.state = {
      stats: [],
      forcePage: "",
      selectedPage: 1,
      reportOptions: null,
      downloading: false,
      totalCount: null,
      loading: true,
      disabled: true,
      filters:{
        perPage: 10,
      },
      type: {
        select: {
          elementType: "select",
          elementConfig: {
            name: "type",
            options: [
              { label: "2", value: 2 },
              { label: "5", value: 5 },
              { label: "10", value: 10 },
              { label: "20", value: 20 },
              { label: "30", value: 30 },
              { label: "50", value: 50 }
            ],
            defaultValue: { label: "10", value: 10 },
            isClearable: false
          }
        },
      }
    };
  }

  componentDidMount() {
    const queryParams = {
      id: "FavaStatsReport",
      page: this.state.selectedPage,
      per_page: this.state.filters.perPage
    };
    this.getReportData(queryParams);
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  getReportData(queryParams) {
    axios.get("/reports/fava_stats", { params: queryParams, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          stats: res.data["reports/fava_stats"],
          totalCount: res.data.meta.total_count,
          loading: false,
          disabled: false
        });
      });
  }

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      forcePage: page.selected,
      loading: true
    });
    const queryParams = {
      id: "FavaStatsReport",
      page: page.selected + 1,
      per_page: this.state.filters.perPage
    };
    this.getReportData(queryParams);
  };

  downloadReport = async () => {
    try {
      if (this.state.downloading) return;
      this.setState({ downloading: true });
      let report = await axios.get("/reports/fava_stats/download_csv", {
        params: {
          id: "FavaStatsReport",
        },
        cancelToken: this.signal.token
      });
      if (report.data.json.generating) {
        toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
        let timerId = setInterval(
          () => this.checkDownloadProgress(report.data.json.id),
          5000
        );
        this.setState({ timerId });
      }
    } catch (err) {
      this.setState({ downloading: false });
      console.log(err);
    }
  };

  checkDownloadProgress = async id => {
    try {
      let progress = await axios.get("/reports/fava_stats/check_generating", {
        params: { id },
        cancelToken: this.signal.token
      });
      if (typeof progress.data.json === "string") {
        clearInterval(this.state.timerId);
        this.setState({ downloading: false });
        toast.success("File successfully downloaded");
        window.open(progress.data.json, "_blank");
        return;
      }
    } catch (e) {
      toast.error("Downloading failed");
      clearInterval(this.state.timerId);
    }
  };

  inputChangeHandler = (event) => {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        perPage: event !== null ? event.value : 10
      },
      loading: true,
      selectedPage: 1
    });
    const queryParams = {
      id: "FavaStatsReport",
      page: 1,
      per_page: event !== null ? event.value : 10
    };
    this.getReportData(queryParams);
  }

  render() {
    const statsList = this.state.stats.map(stat => (
      <tr key={stat.id}>
        <td>{stat.name}</td>
        <td>{stat.view_count}</td>
        <td>
          {stat.site_types &&
            stat.site_types.map((site_type, index) => <div key={index}>{site_type}</div>)}
        </td>
      </tr>
    ));
    return (
      <div className="container service-kind w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>FAVA Stats Report</h3>
          </div>
          <div className="col-6 text-right">
            <Button
              type="success"
              clicked={this.downloadReport}
            >
              Generate CSV
            </Button>
          </div>
        </div>
        <div className="col-lg-4 pl-0">
          <div className="font-weight-bold float-left mt-2 mr-3">
            Items per page:
          </div>
          <Input
            {...this.state.type.select}
            selectedOption={{
              label: this.state.filters.perPage,
              value: this.state.filters.perPage
            }}
            changed={event => this.inputChangeHandler(event)}
            className="w-75"
          />
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <div className="list">
                    <div className="table-responsive">
                      <table className="table table-bordered reports-table-layout">
                        <thead className="border-top">
                          <tr>
                            <th className="w-30">Name</th>
                            <th className="w-20">View Count</th>
                            <th className="w-20">Types of Sites</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!this.state.loading &&
                            statsList
                          }
                        </tbody>
                      </table>
                    </div>
                    {this.state.loading && <Spinner className="h-30vh" />}
                    <Pagination
                      forcePage={this.state.selectedPage - 1}
                      pageCount={this.state.totalCount / this.state.filters.perPage}
                      handlePageClick={this.paginationHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FavaStatsReport;
