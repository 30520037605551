import React, { Component } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { title } from "change-case"
import { Modal } from "react-bootstrap"
import queryString from "query-string"
import Dropzone from "react-dropzone"
import AWS from 'aws-sdk'

import {
  getProject,
  getProjectAttributeTypes,
  getProjectAttributes,
  addProjectAttribute,
  deleteProjectAttribute,
  updateProjectAttribute,
  clearResponse,
} from "../../store/actions/index"
import Input from "../UI/input/Input"
import MediaCard from "./MediaCard"
import MediaListView from "./MediaListView"
import Spinner from "../UI/Spinner"
import FormButton from "../UI/button/FormButton"
import axios from "../../configAxios"

import AWSComponent from "./AWS"

import "./Media.css"
import "react-html5video/dist/styles.css"

const baseStyle = {
  borderWidth: 1,
  borderColor: "#e3e3e3",
  borderStyle: "solid",
}
const activeStyle = {
  borderStyle: "solid",
  borderColor: "#6c6",
  backgroundColor: "#eee",
}
const rejectStyle = {
  borderStyle: "solid",
  borderColor: "#c66",
  backgroundColor: "#eee",
}

const fileTypes = {
  Photo:
    ".jpg, .jpeg, .png, .tif, .bmp, .raw, image/jpeg, image/png, image/bmp, image/tiff, image/tiff-fx",
  Graphic: ".gif, image/gif",
  Audio: "audio/*",
  PDF: ".pdf, application/pdf",
  Video: "video/mp4, video/x-m4v, video/*",
}

class Media extends Component {
  signal = axios.CancelToken.source()
  state = {
    controls: {
      mediaType: {
        elementType: "select",
        elementConfig: {
          name: "mediaType",
          placeholder: "Select Option",
          options: [],
          required: true,
          isLoading: true,
          components: {
            IndicatorSeparator: () => {
              return null
            },
          },
        },
        selectedOption: null,
        label: "Media Type",
      },
      description: {
        elementType: "input",
        elementConfig: {
          name: "description",
          id: "description",
          type: "text",
          required: true,
        },
        value: "",
        label: "Description",
      },
    },
    options: [],
    files: [],
    attributes: [],
    fileLink: null,
    loading: true,
    progressWidth: 0,
    progressBar: false,
    disabled: true,
    query: this.props.query,
    showList: false,
    fileError: true,
    s3bucketName: "",
    organizationSubDomain: "",
    manageAttributesForm: null,
    canEditSetting: null,
    showShareMedia: false,
  }

  async componentDidMount() {
    const organizationInfo = await axios.get(
      `/organizations/${this.props.auth.organizationId}/basic`,
      {
        cancelToken: this.signal.token,
      }
    )
    this.setState({
      s3bucketName: organizationInfo.data.json.organization.bucket_name,
      organizationSubDomain: organizationInfo.data.json.organization.subdomain,
    })
    const res = await axios.get("/aws_s3_buckets/policy", {
      cancelToken: this.signal.token,
    })
    this.setState({
      policyRes: res.data.json,
      isPolicyRes: true,
    })
    axios
      .get("/project_attributes/formdata", {
        params: { type: "Media Type" },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const options = res.data.project_attributes.map((attr) => {
          return { label: attr.name, value: attr.id }
        })
        const selectedOption = options.filter(
          (option) => option.label === "Photo"
        )[0]
        const updatedControls = {
          ...this.state.controls,
          mediaType: {
            ...this.state.controls.mediaType,
            elementConfig: {
              ...this.state.controls.mediaType.elementConfig,
              options: options,
              isLoading: false,
            },
            selectedOption: this.state.query ? selectedOption : null,
          },
        }
        this.setState({
          controls: updatedControls,
          projectAttributes: options,
        })
      })
    await this.props.getProjectAttributes(
      "Media Type",
      this.props.match.params.id,
      this.signal.token
    )
    this.getProjectPolicy()
  }

  getProjectPolicy = () => {
    axios
      .get("/projects/policy", {
        params: { id: this.props.projects.project.id },
      })
      .then((res) => {
        this.setState({
          canEditSetting: res.data.json.can_edit_public_setting,
          manageAttributesForm: res.data.json.manage_attributes_form,
          showShareMedia: res.data.json.show_share_media_toggle,
        })
      })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== "" && !this.state.query) {
      const values = queryString.parse(this.props.location.search)
      const selectedOption = this.state.projectAttributes.filter(
        (option) => option.label === "Photo"
      )[0]
      this.setState({
        query: values.value_3,
        controls: {
          ...this.state.controls,
          mediaType: {
            ...this.state.controls.mediaType,
            selectedOption,
          },
        },
      })
    }
    if (this.props.response !== nextProps.response) {
      if (!nextProps.response.loading) {
        this.setState({
          loading: false,
          disabled: false,
        })
      }
    }
    if (nextProps.response.success) {
      if (nextProps.response.message === "deleted") {
        toast.success("Media deleted successfully")
        this.props.clearResponse()
        this.props.getProjectAttributes(
          "Media Type",
          this.props.match.params.id,
          this.signal.token
        )
      } else {
        this.setState({
          files: {},
          fileLink: null,
        })
        toast.success("Media uploaded successfully")
        this.props.clearResponse()
        this.props.getProjectAttributes(
          "Media Type",
          this.props.match.params.id,
          this.signal.token
        )
      }
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being cancelled")
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {}
    if (key === "mediaType") {
      inputValue.value = event
      inputValue.selectedOption = event
    } else {
      inputValue.value = event.target.value
    }
    const updatedcontrols = {
      ...this.state.controls,
      [key]: {
        ...this.state.controls[key],
        ...inputValue,
      },
    }
    this.setState({ controls: updatedcontrols })
  }

  addAttributeHandler = (event) => {
    event.preventDefault()
    const { mediaType, description } = this.state.controls
    const { query } = this.state
    this.setState({
      disabled: true,
      progressBar:
        mediaType.selectedOption.label === "Video" &&
        this.state.files.length > 0 &&
        true,
    })
    const attrData = new FormData()
    if (this.state.files.length > 0) {
      attrData.append("file_attachment", this.state.files[0])
    } else {
      attrData.append("value_2", this.state.fileLink)
    }
    if (this.state.awsFile && !query) {
      attrData.append("value_3", false)
    }
    if (
      query &&
      (mediaType.selectedOption.label === "Photo" ||
        mediaType.selectedOption.label === "Graphic")
    ) {
      attrData.append("value_3", query)
    }
    attrData.append("project_id", this.props.projects.project.id)
    attrData.append("project_attribute_type_id", mediaType.selectedOption.value)
    attrData.append("value", description.value)
    if (this.state.files.length > 0 || this.state.fileLink) {
      axios
        .post("/project_attributes", attrData, {
          onUploadProgress: (progressEvent) => {
            if (
              mediaType.selectedOption.label === "Video" &&
              this.state.files.length > 0
            ) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              this.setState({ progressWidth: percentCompleted })
            }
          },
        })
        .then((res) => {
          if (res.data.json.success) {
            toast.success("Media uploaded successfully")
            this.props.getProjectAttributes(
              "Media Type",
              this.props.match.params.id,
              this.signal.token
            )
            this.props.getProject(this.props.match.params.id)
            this.setState({
              controls: {
                ...this.state.controls,
                mediaType: {
                  ...this.state.controls.mediaType,
                  selectedOption: null,
                },
                description: {
                  ...this.state.controls.description,
                  value: "",
                },
              },
              files: [],
              fileLink: null,
              disabled: false,
              awsFile: null,
              progressBar: false,
              query: false,
              progressWidth: 0,
            })
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
            this.setState({ disabled: false })
          }
        })
    } else {
      toast.error("Please select media file.")
      this.setState({ disabled: false })
    }
  }

  deleteAttributeHandler = (e, index) => {
    const params = {
      project_id: this.props.match.params.id,
      id: index,
    }
    this.props.deleteProjectAttribute(params)
  }

  updateAttributeHandler = (index, id, params) => {
    params = {
      project_id: this.props.projects.project.id,
      id: id,
      value_type: 'share',
      value: params.value,
      share: params.share,
      project_attribute_type_id: params.project_attribute_type_id,
    }
    this.props.updateProjectAttribute(params)
  }

  onCancel() {
    this.setState({ fileLink: null })
  }

  onSuccess(files) {
    this.setState({
      fileLink: files[0].link,
      files: [],
      defaultLabel: false,
      awsFile: null,
    })
  }

  selectedAWSFile = (data) => {
    this.setState({
      fileLink: data.url,
      awsFile: data.name,
      files: [],
    })
  }

  onDrop = (files) => {
    this.setState({ files, awsFile: null, fileLink: null })
  }

  resetUpload = () =>
    this.setState({
      progressBar: false,
      progressWidth: 0,
      disabled: false,
    })

  uploadToS3 = async () => {
    /*AWS direct upload*/
    const S3_BUCKET = this.state.s3bucketName
    const REGION = 'us-east-1'
    const FILE_PATH = `projects/${this.props.projects.project.unique_id}`
    const FILE_NAME = this.state.files[0].name
    
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    })

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    })
    this.s3 = s3

    const s3Params = {
      Bucket: S3_BUCKET,
      Key: `${FILE_PATH}/${FILE_NAME}`,
      Body: this.state.files[0],
    }

    const s3upload = s3
      .putObject(s3Params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        const percentCompleted = parseInt((evt.loaded * 100) / evt.total)
        this.setState({ progressWidth: percentCompleted })
      })
      .promise()

    return s3upload.then((res)=> {
      return res
    }).catch((e) => console.log(e))
  }

  handleS3Success = (mediaData) => {
    const uploadMediaData = { ...mediaData }
    uploadMediaData.file_name = this.state.files[0].name

    axios
      .post(
        `/projects/${this.props.projects.project.id}/process_file`,
        uploadMediaData,
        { cancelToken: this.signal.token, }
      )
      .then((res) => {
        if (res.data.json?.success) {
          toast.success("Project file processed successfully.")
          this.props.getProjectAttributes("Media Type", this.props.match.params.id, this.signal.token)
          this.props.getProject(this.props.match.params.id)
          this.setState({
            controls: {
              ...this.state.controls,
              description: {
                ...this.state.controls.description,
                value: "",
              },
              mediaType: {
                ...this.state.controls.mediaType,
                selectedOption: null,
              },
            },
            files: [],
            awsFile: null,
            query: false,
          })
        } else {
          console.warn(res)
          toast.error("Failed to process project file.")
        }
      })
  }

  largeFileUploader = async (e) => {
    e.preventDefault()
    if (!this.state.files.length) {
      toast.error("Please select a media file")
      return
    }
    this.setState({ disabled: true, progressBar: true })
    const formData = new FormData()
    // unused?
    formData.append("utf8", "✓")
    // only used in b2 upload_file
    formData.append(
      "redirect_to",
      `https://${this.state.organizationSubDomain}.amsnetwork.app/projects/${this.props.projects.project.slug}/file_attachments/process_upload`
    )
    // only used in direct upload
    formData.append("bucket_name", this.state.s3bucketName)
    // REQUIRED in api to key the multipart file
    formData.append(
      "file_path",
      `projects/${this.props.projects.project.unique_id}`
    )
    // in api but seemingly unused
    formData.append(
      "project_attribute_type_id",
      this.state.controls.mediaType.selectedOption.value
    )
    // in api but seemingly unused
    formData.append("description", this.state.controls.description.value)
    // REQUIRED tempfile in api
    formData.append("file_attachment[file]", this.state.files[0])

    const uploadMediaData = {
      description: this.state.controls.description.value,
      project_attribute_type_id: this.state.controls.mediaType.selectedOption
        .value,
    }

    await this.uploadToS3()
      .then(res => {
        if (res.$response.httpResponse.statusCode === 200) {
          this.handleS3Success(uploadMediaData)
        } else {
          console.warn('Upload encountered something unexpected', res)
          toast.warn("Upload encountered something unexpected.")
        }
      })
      .catch(e => {
        console.error(e)
        toast.error("AWS media upload failed!")
      })
      .finally(() => {
        this.resetUpload()
      })

  }

  renderMediaCards = () => {
    const { projectAttributes } = this.props.projects

    return (
      <>
        {projectAttributes?.map((attr, index) => (
          <MediaCard
            key={index}
            index={index}
            attr={attr}
            deleteHandler={this.deleteAttributeHandler}
            updateHandler={this.updateAttributeHandler}
            project_id={this.props.projects.project.slug}
            canDelete={this.props.projects.project.can_delete}
            canEditSetting={this.state.canEditSetting}
            showShareMedia={this.state.showShareMedia}
          />
        ))}
      </>
    )
  }

  renderMediaList = () => {
    const { loading } = this.state

    return (
      <>
        {loading && <Spinner />}
        {!loading && (
          <div className="p-3">
            <div className="row">{this.renderMediaCards()}</div>
          </div>
        )}
      </>
    )
  }

  render() {
    const { controls } = this.state
    const attributes = this.props.projects.projectAttributes

    const files = this.state.files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ))

    let acceptFileType
    if (controls.mediaType.selectedOption) {
      acceptFileType = fileTypes[controls.mediaType.selectedOption.label]
    }

    return (
      <div className="project-media mb-5 mt-3">
        {this.state.isPolicyRes && (
          <form
            {...(this.state.awsFile || this.state.fileLink
              ? { onSubmit: this.addAttributeHandler }
              : {
                  id: "new_file_attachment",
                  onSubmit: this.largeFileUploader,
                })}
          >
            {!this.state.awsFile && (
              <>
                <input name="utf8" type="hidden" value="✓" />
                <p>
                  <input
                    type="hidden"
                    name="redirect_to"
                    id="redirect_to"
                    value={`https://${this.state.organizationSubDomain}.amsnetwork.app/projects/${this.props.projects.project.slug}/file_attachments/process_upload`}
                  />
                  <input
                    type="hidden"
                    name="bucket_name"
                    id="bucket_name"
                    value={this.state.s3bucketName}
                  />
                  <input
                    type="hidden"
                    name="file_path"
                    id="file_path"
                    value={`projects/${this.props.projects.project.unique_id}`}
                  />
                  <input
                    type="hidden"
                    name="project_attribute_type_id"
                    id="project_attribute_type_id"
                    {...(this.state.controls.mediaType.selectedOption && {
                      value: this.state.controls.mediaType.selectedOption.value,
                    })}
                  />
                </p>
              </>
            )}
            <div className="row justify-content-between mt-2 m-0 project-media">
              <div className="col-md-4 media-input">
                <Input
                  {...controls.mediaType}
                  changed={(selectedOption) =>
                    this.inputChangedHandler(selectedOption, "mediaType")
                  }
                />
              </div>
              <div className="col-md-8 description">
                <Input
                  {...controls.description}
                  media={true}
                  changed={(e) => this.inputChangedHandler(e, "description")}
                />
              </div>
            </div>
            <div className="row m-0">
              <div className="col-md-4 dropzone">
                <h5>
                  <b>Choose file via computer</b>
                </h5>
                <Dropzone
                  accept={acceptFileType}
                  onDrop={this.onDrop}
                  disabled={this.state.disabled}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                  }) => {
                    let styles = { ...baseStyle }
                    styles = isDragActive
                      ? { ...styles, ...activeStyle }
                      : styles
                    styles = isDragReject
                      ? { ...styles, ...rejectStyle }
                      : styles
                    styles = {
                      ...styles,
                      ...(this.state.disabled && { opacity: "0.8" }),
                    }

                    return (
                      <div {...getRootProps()} style={styles}>
                        <input
                          {...getInputProps()}
                          name="file_attachment[file]"
                          id="file_attachment_file"
                        />
                        <div className="text-center">
                          <div style={{ marginTop: 30, color: "gray" }}>
                            <span className="text-center fas fa-folder-open mb-2" />
                            <div className="text-center">
                              Choose a file or drag it here (MAX 5GB)
                            </div>
                            <div className="col-md-12 mb-3 text-center">
                              {files.length > 0 ? files : "(No file selected)"}
                            </div>
                            {this.state.progressBar && (
                              <div className="progress">
                                <div
                                  className="progress-bar progress-bar-striped progress-bar-animated"
                                  id="progress"
                                  role="progressbar"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{
                                    width: `${this.state.progressWidth}%`,
                                  }}
                                >
                                  {this.state.progressWidth}%
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {isDragReject && (
                          <div className="text-center py-2">
                            Unsupported file type...
                          </div>
                        )}
                      </div>
                    )
                  }}
                </Dropzone>
              </div>
              {this.state.policyRes && this.state.policyRes.show_aws && (
                <>
                  {this.state.policyRes.supports_s3_upload ? (
                    <div className="col-md-4">
                      <h5>
                        <b>Choose file via AWS</b>
                      </h5>
                      <div className="border">
                        <div
                          className="text-center text-muted aws-section cursor"
                          onClick={() => this.setState({ AWSModal: true })}
                        >
                          <i className="fas fa-folder-open text-muted"></i>
                          <div>Choose a AWS file</div>
                          <div>
                            {this.state.awsFile
                              ? `(${this.state.awsFile})`
                              : "(No file selected)"}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-4">
                      <h5>
                        <b>Choose file via AWS</b>
                      </h5>
                      <Link to="/system_settings/organization_integrations">
                        <div className="border">
                          <div className="text-center text-muted cursor p-3">
                            <i className="fas fa-link text-muted mt-3"></i>
                            <div className="pt-2 pb-4">Connect AWS S3</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="row ml-4 mt-4">
              <FormButton
                disabled={
                  this.state.disabled || !this.state.manageAttributesForm
                }
                style={{
                  cursor: this.state.manageAttributesForm
                    ? "pointer"
                    : "no-drop",
                }}
              >
                Upload
              </FormButton>
            </div>
          </form>
        )}
        {!this.state.loading &&
          attributes &&
          attributes.length > 0 &&
          this.state.canEditSetting && (
            <div className="row">
              <div className="col-12 text-right">
                <span
                  className="mr-4 mt-4 cursor bg-primary py-1 px-2"
                  onClick={() => this.setState({ showList: true })}
                >
                  <i className="fas fa-download text-light" />
                </span>
              </div>
            </div>
          )}
        {this.renderMediaList()}
        <Modal
          className="project-media-modal"
          show={this.state.AWSModal}
          onHide={() => this.setState({ AWSModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Attach AWS S3 Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AWSComponent
              selectedAWSFile={this.selectedAWSFile}
              closeModal={() => this.setState({ AWSModal: false })}
            />
          </Modal.Body>
        </Modal>
        <Modal
          className="project-media-modal"
          show={this.state.showList}
          onHide={() =>
            this.setState({
              showList: false,
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Select to download</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MediaListView
              attributes={this.props.projects.projectAttributes}
              id={this.props.projects.project.id}
              name={this.props.projects.project.name}
              section="Project"
              closeListView={() =>
                this.setState({
                  showList: false,
                })
              }
            />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    projects: state.projects,
    response: state.response,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProject: (id) => dispatch(getProject(id)),
    getProjectAttributeTypes: (type) =>
      dispatch(getProjectAttributeTypes(type)),
    getProjectAttributes: (type, project_id, cancelToken) =>
      dispatch(getProjectAttributes(type, project_id, cancelToken)),
    addProjectAttribute: (attrData) => dispatch(addProjectAttribute(attrData)),
    deleteProjectAttribute: (params) =>
      dispatch(deleteProjectAttribute(params)),
    updateProjectAttribute: (params) =>
      dispatch(updateProjectAttribute(params)),
    clearResponse: () => dispatch(clearResponse()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Media))
