import React, { Component } from "react"
import axios from "../../../configAxios"
import Pagination from "../../UI/Pagination"
import "./Booking.css"

import AssetItem from "./AssetItem"
import BookAsset from "./BookAsset"
import CenteredContainer from "../../UI/container/CenteredContainer"
import FormButton from "../../UI/button/FormButton"
import Input from "../../UI/input/Input"
import Modal from "../../UI/modal/CalendarModal"
import Spinner from "../../UI/Spinner"

class AssetItems extends Component {
  signal = axios.CancelToken.source()
  state = {
    search: {
      elementType: "input",
      elementConfig: {
        name: "search",
        placeholder: "Search...",
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      value: "",
    },
    category: {
      elementType: "select",
      elementConfig: {
        name: "category",
        placeholder: "Select Category",
        options: [],
        isMulti: true,
        hide: false,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
        styles: {
          multiValueLabel: (base) => ({
            ...base,
            backgroundColor: "#f0e9e9",
            color: "#666666",
          }),
          multiValueRemove: (base) => ({
            ...base,
            backgroundColor: "#f0e9e9",
            color: "#666666",
          }),
        },
      },
      selectedOption: [],
    },
    selectedOption: "Equipment",
    assets: [],
    selectedAsset: null,
    showBookingModal: false,
    selectedPage: 1,
    forcePage: 0,
    totalCount: 0,
    loading: true,
    queryParams: {
      type: "Equipment",
      ticketable_type: "User",
      ticketable_id: this.props.userId,
      page: 1,
      include: {
        policy: true,
      },
      is_index_for_booking_page: true,
    },
    policyRes: {},
  }

  async componentDidMount() {
    this.selectHandler("Equipment")
    this.getAssets()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getAssets = async () => {
    this.setState({ assets: [], loading: true })

    const assetsRes = await axios.get("/assets", {
      params: this.state.queryParams,
      cancelToken: this.signal.token,
    })

    this.setState({
      assets: assetsRes.data.assets,
      totalCount: assetsRes.data.meta.total_count,
      loading: false,
    })
  }

  selectHandler = (selectedOption) => {
    let options
    this.setState({
      selectedOption: selectedOption,
      forcePage: 0,
      selectedPage: 1,
      totalCount: 0,
      category: {
        ...this.state.category,
        selectedOption: [],
      },
      search: {
        ...this.state.search,
        value: "",
      },
    })
    axios
      .get("/bookings/formdata", { cancelToken: this.signal.token })
      .then((res) => {
        if (selectedOption === "Equipment") {
          options = res.data.json.categories
        } else {
          options = res.data.json.library_types
        }
        this.setState({
          category: {
            ...this.state.category,
            elementConfig: {
              ...this.state.category.elementConfig,
              options: options.map((option) => {
                return {
                  value: selectedOption === "Equipment" ? option[0] : option,
                  id: selectedOption === "Equipment" ? option[0] : option,
                  label: selectedOption === "Equipment" ? option[1] : option,
                }
              }),
            },
          },
        })
      })
    this.setState(
      {
        queryParams: {
          ...this.state.queryParams,
          query_string: "",
          category_ids: null,
          library_types: null,
          type: selectedOption,
        },
      },
      () => this.getAssets()
    )
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState(
      {
        selectedPage: page.selected + 1,
        forcePage: page.selected,
        queryParams: {
          ...this.state.queryParams,
          page: page.selected + 1,
        },
      },
      () => this.getAssets()
    )
    window.scrollTo(0, 0)
  }

  bookAssetHandler = (showBookingModal, selectedAsset) => {
    this.setState({
      showBookingModal,
      selectedAsset,
    })
  }

  inputChangeHandler = (event, key) => {
    const inputValue = {}
    if (key === "category") {
      inputValue.selectedOption = event
    } else {
      inputValue.value = event.target.value
    }
    this.setState({
      [key]: {
        ...this.state[key],
        ...inputValue,
      },
    })
  }

  searchHandler = (event) => {
    event.preventDefault()
    const { search, category, selectedOption } = this.state
    const types = category.selectedOption.map((category) => category.id)
    const params = {
      query_string: search.value,
      category_ids: null,
      library_types: null,
    }
    if (types.length) {
      if (selectedOption === "Equipment") {
        params.category_ids = `[${types}]`
      } else {
        params.library_types = JSON.stringify(types)
      }
    }
    this.setState(
      {
        totalCount: 0,
        queryParams: {
          ...this.state.queryParams,
          page: 1,
          ...params,
        },
      },
      () => this.getAssets()
    )
  }

  render() {
    const {
      assets,
      totalCount,
      showBookingModal,
      selectedAsset,
      loading,
    } = this.state
    let assetItems
    if (loading) {
      assetItems = (
        <div style={{ marginLeft: "-15%" }}>
          <Spinner />
        </div>
      )
    } else {
      if (assets.length > 0) {
        assetItems = assets.map((asset) => (
          <AssetItem
            manageBookings={this.props.manageBookings}
            key={asset.id}
            asset={asset}
            bookAsset={this.bookAssetHandler}
          />
        ))
      } else {
        assetItems = (
          <CenteredContainer>
            <h4 className="center-block">No assets found...</h4>
          </CenteredContainer>
        )
      }
    }
    return (
      <div className="row">
        <div className="col-md-12 mt-1 booking">
          <div className="pb-3">
            <form onSubmit={this.searchHandler}>
              <div>
                <i className="fas fa-search position-relative float-right t-25 r-15" />
                {this.state.search.value !== "" && (
                  <i
                    className="fas fa-times position-relative float-right t-25 r-25 cursor"
                    onClick={() =>
                      this.setState({
                        search: {
                          ...this.state.search,
                          value: "",
                        },
                      })
                    }
                  />
                )}
                <Input
                  {...this.state.search}
                  style={{
                    paddingTop: "2%",
                    paddingLeft: "2%",
                    paddingRight: "55px",
                  }}
                  changed={(event) => this.inputChangeHandler(event, "search")}
                />
              </div>
              <Input
                {...this.state.category}
                changed={(event) => this.inputChangeHandler(event, "category")}
              />
              <FormButton block disabled={this.state.loading}>
                Search
              </FormButton>
            </form>
          </div>
          <div className="my-2 row col-md-12 ml-2">
            <div
              className="row"
              onClick={() =>
                !this.state.loading && this.selectHandler("Equipment")
              }
              style={{ cursor: this.state.loading ? "no-drop" : "pointer" }}
            >
              <div className="radio-btn cursor">
                <div
                  className={`${
                    this.state.selectedOption === "Equipment" && "selected"
                  }`}
                />
              </div>
              <div className="ml-2">{"Facility & Equipment"}</div>
            </div>
            <div
              className="row ml-5"
              onClick={() =>
                !this.state.loading && this.selectHandler("Library")
              }
              style={{ cursor: this.state.loading ? "no-drop" : "pointer" }}
            >
              <div className="radio-btn cursor">
                <div
                  className={`${
                    this.state.selectedOption === "Library" && "selected"
                  }`}
                />
              </div>
              <div className="ml-2">Library</div>
            </div>
          </div>

          <div className="mt-4 mb-3">{assetItems}</div>

          <Pagination
            prevLabel={"<<"}
            nextLabel={">>"}
            pageCount={totalCount / 10}
            handlePageClick={this.paginationHandler}
            range={2}
          />

          <Modal
            show={showBookingModal}
            closeModal={() => this.bookAssetHandler(false, null)}
            hide={() => this.bookAssetHandler(false, null)}
            closeButton
          >
            <BookAsset
              selectedAsset={selectedAsset}
              userId={this.props.userId}
              ticketId={this.props.ticketId}
              getTicket={this.props.getTicket}
              tabChangeHandler={this.props.tabChangeHandler}
              calendarType={this.state.selectedOption}
              closeModal={() => this.bookAssetHandler(false, null)}
            />
          </Modal>
        </div>
      </div>
    )
  }
}

export default AssetItems
