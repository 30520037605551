import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "../../configAxios";

const initialState = {
  loading: false,
};

export const getGrantCampaigns = createAsyncThunk(
  "grantCampaign/getGrantCampaigns",
  async (params) => {
    const response = await axios.get("/grant_campaigns", { params });
    return response.data.grant_campaigns;
  }
);

export const getActiveGrantCampaigns = createAsyncThunk(
  "grantCampaign/getActiveGrantCampaigns",
  async (params) => {
    const response = await axios.get("/grant_campaigns/list", { params });
    return response.data.grant_campaigns;
  }
)

export const getGrantCampaign = createAsyncThunk(
  "grantCampaign/getGrantCampaign",
  async (id) => {
    const response = await axios.get(`/grant_campaigns/${id}`);
    return response.data.grant_campaign;
  }
);

const grantCampaignsAdapter = createEntityAdapter({});

const slice = createSlice({
  name: "grantCampaign",
  initialState: grantCampaignsAdapter.getInitialState(initialState),
  reducers: {
    grantCampaignUpdate: grantCampaignsAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(getGrantCampaign.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getGrantCampaign.fulfilled, (state, { payload }) => {
      state.loading = false;
      return grantCampaignsAdapter.addOne(state, payload);
    });
    builder.addCase(getGrantCampaigns.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getGrantCampaigns.fulfilled, (state, { payload }) => {
      state.loading = false;
      return grantCampaignsAdapter.addMany(state, payload);
    });
    builder.addCase(getActiveGrantCampaigns.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getActiveGrantCampaigns.fulfilled, (state, { payload }) => {
      state.loading = false;
      return grantCampaignsAdapter.addMany(state, payload);
    })
  },
});

export const selectGrantCampaignsLoading = (state) =>
  state.grantCampaigns.loading;

export const {
  selectById: selectGrantCampaignById,
  selectIds: selectGrantCampaignIds,
  selectEntities: selectGrantCampaignEntities,
  selectAll: selectAllGrantCampaigns,
  selectTotal: selectTotalGrantCampaigns,
} = grantCampaignsAdapter.getSelectors((state) => state.grantCampaigns);

const { actions, reducer } = slice;
export const { grantCampaignUpdate } = actions

export default reducer;
