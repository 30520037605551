import React, { useEffect, useState } from "react"
import Select from "react-select"
import axios from "../../../configAxios"
import { sortBy } from "lodash"
import groupTagOptions from "../../shared/groupTagOptions"
import Tag from "./Tag"
import onError from "../../../utils/onError"

const TagDropdown = ({ tags, setTags }) => {
  const [tagOptions, setTagOptions] = useState([])

  useEffect(() => {
    axios
      .get("/tags/search", {
        params: {
          query: "",
          type: "Program",
        },
      })
      .then((res) => {
        const formattedOptions = res.data.json.map((tag) => {
          return {label: tag.name, value: tag.value}
        })
        setTagOptions(groupTagOptions(sortBy(formattedOptions, 'label')));
      })
      .catch((err) => onError(err))
  }, [])

  const tagList = tags?.map((tag, index) =>
    <Tag
      key={index}
      tags={tags}
      setTags={setTags}
      tagOptions={tagOptions}
      setTagOptions={setTagOptions}
      tagInfo={tag}
    />
  )

  const updateTagsAndOptions = (tagUpdate) => {
    setTags([...tags, tagUpdate])

    const unformattedTagOptions = [
      ...tagOptions[0].options,
      ...tagOptions[1].options
    ]
    const updatedOptions = unformattedTagOptions.filter(tag => tag.value !== tagUpdate.value)
    setTagOptions(groupTagOptions(sortBy(updatedOptions, 'label')))
  }

  return (
    <>
      <Select
        options={tagOptions}
        placeholder='Select Labels'
        value=''
        onChange={(tagUpdate) => updateTagsAndOptions(tagUpdate)}
      />
      <div className='row mt-2 pl-3'>
        {tagList}
      </div>
    </>
  )
}

export default TagDropdown
