import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { CSVLink } from "react-csv"
import { toast } from "react-toastify"
import classnames from "classnames"
import { title } from "change-case"
import dateFns from "date-fns"
import { parseFromTimeZone } from "date-fns-timezone"
import moment from "moment-timezone"
import Editor from "rich-markdown-editor"

import axios from "../../configAxios"
import { getAdminStatus } from "../../store/actions/auth"

import Button from "../UI/button/Button"
import CalendarModal from "../UI/modal/CalendarModal"
import Modal from "../UI/Modal"
import AddToSchedule from "./AddToSchedule"
import DetailTableList from "./DetailTableList"
import PromoDetails from "./PromoDetails"
import ToggleSwitch from "../common/toggleSwitch"
import ProgramConfirmation from "../users/ProgramConfirmation"
import { responseErrorHandler } from "../../utils/errorHandler"

import NoImage from "../../assests/images/no-image.png"
import ProgramStats from "./ProgramStats"


class ProgramDetail extends Component {
  csvLink = React.createRef()
  signal = axios.CancelToken.source()
  state = {
    scheduledDates: [],
    showModal: false,
    isActive: null,
    statusString: "",
    reportData: [],
    showRegisterModal: false,
    showStatsModal: false,
  }

  componentDidMount() {
    const { content, getAdminStatus, userId } = this.props
    if (content) {
      this.setState({
        isActive: content.is_active,
        statusString: content.status_string,
      })
    }
    this.getSchedules()
    getAdminStatus(userId)
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getSchedules() {
    axios
      .get("/scheduled_program_dates", {
        params: {
          program_id: this.props.programId,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const fetchedScheduledDates = res.data.json.scheduled_program_dates
        const secheduled_dates = []
        fetchedScheduledDates.map((schedule) => {
          const scheduledDates = {}
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          const browserStartDate = parseFromTimeZone(schedule.start, {
            timeZone,
          })
          const startTime = dateFns.format(schedule.start, "hh:mma")
          const endTime = dateFns.format(schedule.end, "hh:mma")
          const date = `${dateFns.getYear(browserStartDate)}.${dateFns.format(
            browserStartDate,
            "MM"
          )}.${dateFns.getDate(browserStartDate)} `
          const time = `${startTime} - ${endTime}`
          scheduledDates.date = date
          scheduledDates.time = time
          scheduledDates.id = schedule.id
          scheduledDates.canDelete = schedule.can_delete
          scheduledDates.abbreviatedTimezone =
            schedule.program.abbreviated_timezone
          return secheduled_dates.push(scheduledDates)
        })
        this.setState({ scheduledDates: secheduled_dates })
      })
  }

  openModal = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true })
  }

  deleteScheduleHandler = (id) => {
    const response = window.confirm(
      "Are you sure you want to delete this program?"
    )
    if (response) {
      axios.delete("/scheduled_program_dates/" + id).then((res) => {
        if (res.data.json.success) {
          toast.success("Successfully deleted")
          this.getSchedules()
        } else {
          toast.error("Error occured")
        }
      })
    }
  }

  deleteHandler = () => {
    const response = window.confirm(
      "Are you sure you want to delete this program?"
    )
    if (response) {
      axios
        .delete("/programs/" + this.props.programId)
        .then((res) => {
          if (res.data.json.success) {
            this.props.history.push("/programs")
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
          }
        })
        .catch((err) => console.log(err))
    }
  }

  clickHandler = (event) => {
    let value
    if (event.target.innerText === "Cancel") {
      value = 2
    } else if (event.target.innerText === "Mark Finished") {
      value = 3
    } else if (event.target.innerText === "Reopen") {
      value = 1
    }
    const response = window.confirm(
      "Are you sure you want to " + event.target.innerText + " this program?"
    )
    if (response) {
      const updatedData = {}
      updatedData.status = value
      axios.put(`/programs/${this.props.programId}`, updatedData).then((res) => {
        if (res.data.json.success) {
          toast.success("Program updated successfully.")
          const {
            is_active: isActive,
            status_string: statusString,
          } = res.data.json.data
          this.setState({ isActive, statusString })
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
    } else {
      this.setState({ isActive: this.state.isActive })
    }
  }

  reportDownloadHandler = () => {
    axios
      .get("/programs/export_paid_registrations_csv", {
        params: {
          id: this.props.programId,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({ reportData: res.data.json.data }, () => {
          this.csvLink.current.link.click()
        })
      })
  }

  inputChangedHandler = (event) => {
    const key = event.target.name
    this.setState({
      [key]: {
        ...this.state[key],
        value: event.target.value,
      },
    })
  }

  closeRegisterModal = () => this.setState({ showRegisterModal: false })

  registerHandler = () => {
    const formData = new FormData()
    formData.append("program_id", this.props.content.id)
    formData.append("user_id", localStorage.userId)

    this.setState({ disableRegisterButton: true })
    axios
      .post("/registrations", formData)
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Successfully registered for program.")
          this.setState({ showRegisterModal: false })
          window.location.reload()
        } else {
          this.setState({ disableRegisterButton: false })
          responseErrorHandler(res)
        }
      })
      .catch(() => {
        this.setState({ disableRegisterButton: false })
      })
  }

  render() {
    const currentUserTimezoneAbbreviation = moment()
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .zoneAbbr()
    const program = this.props.content
    const { content, isAdmin, policyRes } = this.props
    const url = content.photo.photo.thumb.url
      ? content.photo.photo.thumb.url
      : NoImage

    const earlybirdDate = dateFns.format(content.earlybird_cutoff, "YYYY.MM.DD")
    const cutoffDate = dateFns.format(content.drop_cutoff, "YYYY.MM.DD")

    const details = (
      <div className="program-detail" key={content.id}>
        <div className="row">
          <div className="col-md-2 text-center">
            <a
              href={content.photo.photo.thumb.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="rounded-circle bg-cover"
                style={{
                  width: "100%",
                  paddingTop: "100%",
                  backgroundImage: `url(${url})`,
                }}
              />
            </a>
          </div>
          <div className="col-md-7">
            <h4>{content.name}</h4>
            {policyRes && policyRes.can_update && (
              <ul className="options-list pl-0 py-2">
                  <>
                    <Link
                      to={`/programs/${this.props.programId}/edit`}
                      className="text-muted"
                    >
                      <li>Edit</li>
                    </Link>
                    {this.state.isActive && 
                      <li className="cursor" onClick={this.clickHandler}>
                        Cancel
                      </li>
                    }
                    {!this.state.isActive &&
                      <li className="cursor" onClick={this.deleteHandler}>
                        Delete
                      </li>
                    }
                    {isAdmin && (
                      <li className="cursor" onClick={() => this.setState({ showStatsModal: true })}>
                        Stats
                      </li>
                    )}
                  </>
                {policyRes && policyRes.can_export && (
                  <li
                    className="cursor last-item"
                    onClick={this.reportDownloadHandler}
                  >
                    Export Paid Registration
                  </li>
                )}
                <CSVLink
                  data={this.state.reportData}
                  filename={`program_${this.props.programId}.csv`}
                  className="hidden"
                  ref={this.csvLink}
                  target="_self"
                />
              </ul>
            )}
          </div>

          <div className="col-md-3 text-md-right">
            <div
              className={classnames(
                "badge px-3 py-2",
                { "badge-success": this.state.isActive },
                { "badge-danger": !this.state.isActive }
              )}
            >
              {this.state.statusString + " "}
            </div>

            {policyRes &&
              !policyRes.can_create &&
              this.state.isActive &&
              policyRes.can_view_program_tab && (
                <div className="d-flex justify-content-md-end justify-content-start align-items-center mt-2 pt-1">
                  {policyRes.is_current_user_registered ? (
                    <span>Registered</span>
                  ) : (
                    <Button
                      type="primary"
                      className="cursor"
                      clicked={() => {
                        window.scrollTo(0, 0)
                        this.setState({ showRegisterModal: true })
                      }}
                    >
                      Register
                    </Button>
                  )}
                </div>
              )}

            {policyRes && policyRes.can_update && (
              <div className="d-flex justify-content-md-end justify-content-start align-items-center mt-2 pt-1">
                <div className="ml-2">
                  <ToggleSwitch
                    checked={this.state.isActive}
                    onChange={() =>
                      this.clickHandler({
                        target: {
                          innerText: this.state.isActive
                            ? "Mark Finished"
                            : "Reopen",
                        },
                      })
                    }
                    customCheckContent="ON"
                    customUncheckContent="OFF"
                  />
                </div>
                <label htmlFor="program-status-toggle" className="mx-1 my-2">
                  {this.state.isActive ? "Active" : " Finished"}
                </label>
              </div>
            )}
          </div>
        </div>

        <hr className="horizontal-row" />

        <div className="row">
          <div className="col-12">
              <p>{content.description}</p>
          </div>
        </div>

        <hr className="horizontal-row" />

        <div className="row mb-4">
          <div className="col-12">
            <div className="p-2">
              <Editor
                readOnly={true}
                defaultValue={content.program_details || ""}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12">
                <h6 className="font-weight-bold text-muted">Location:</h6>
                <p className="text-dark">{content.location}</p>
              </div>
              <div className="col-12">
                <h6 className="font-weight-bold text-muted">Instructors:</h6>
                <p className="text-dark">{content.instructors}</p>
              </div>
              <div className="col-12">
                <h6 className="font-weight-bold text-muted">
                  Maximum Participants:
                </h6>
                <p className="text-dark">{content.max_participants}</p>
              </div>
              <div className="col-12">
                <h6 className="font-weight-bold text-muted">
                  Minimum Participants:
                </h6>
                <p className="text-dark">{content.min_participants}</p>
              </div>
              <div className="col">
                <h6 className="font-weight-bold text-muted">
                  Enable Asset double-booking by Program Director and
                  Participants?:
                </h6>
                <p className="text-dark">
                  {content.allow_multiple_booking ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-12">
                <h6 className="font-weight-bold text-muted">Location Notes:</h6>
                <p className="text-dark">{content.location_notes}</p>
              </div>
              <div className="col-12">
                <h6 className="font-weight-bold text-muted">Price:</h6>
                <p className="text-dark">
                  ${content.enrollment_price} (Non Members) / $
                  {content.member_enrollment_price} (Members)
                </p>
              </div>
              <div className="col-12">
                <h6 className="font-weight-bold text-muted">
                  Earlybird Discount:
                </h6>
                <p className="text-dark">${content.earlybird_discount}</p>
              </div>
              <div className="col-12">
                <h6 className="font-weight-bold element-heading">
                  Earlybird Register By:
                </h6>
                <p className="text-dark">{earlybirdDate}</p>
              </div>
              <div className="col-12">
                <h6 className="font-weight-bold element-heading">
                  Drop Cutoff:
                </h6>
                <p className="text-dark">{cutoffDate}</p>
              </div>
            </div>
          </div>
        </div>

        {policyRes && policyRes.can_create && (
          <div className="row">
            <div className="col-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Program Expenses</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Manually Entered Expenses
                      <span className="amount">
                        ${content.manually_entered_expenses}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Program Reservations
                      <span className="amount">
                        ${content.program_reservations}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Total
                      <span className="amount">${content.total}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        <DetailTableList title="Scheduled Program Dates">
          {this.state.scheduledDates.map((schedule) => (
            <li className="list-group-item" key={schedule.id}>
              <div className="row">
                <div className="col-3">{schedule.date}</div>
                <div className="col-7">
                  {schedule.time + " " + currentUserTimezoneAbbreviation}
                </div>
                {schedule.canDelete && (
                  <div className="col-2 text-center">
                    <Link
                      to={"/programs/" + this.props.programId}
                      className="text-danger"
                      onClick={() =>
                        this.deleteScheduleHandler(schedule.id)
                      }
                    >
                      <i className="fas fa-trash cursor" />
                    </Link>
                  </div>
                )}
              </div>
            </li>
          ))}
        </DetailTableList>

        {policyRes &&
          policyRes.can_add_to_schedule &&
          (content.is_active || content.is_draft) && (
            <div className="row my-3">
              <div className="col-12 d-flex flex-row-reverse">
                <Button
                  type="success"
                  clicked={this.openModal}
                >
                  Add to Schedule <i className="far fa-calendar-alt" />
                </Button>
              </div>
            </div>
          )}

        {isAdmin && (
          <DetailTableList title="Promos">
            <PromoDetails/>
          </DetailTableList>
        )}

        <Modal
          closeButton
          closeModal={this.closeRegisterModal}
          hide={() => this.setState({ showRegisterModal: false })}
          show={this.state.showRegisterModal}
          title={`Program Registeration - ${program.name}`}
          style={{ top: "10%" }}
        >
          <ProgramConfirmation
            program={program}
            closeModal={this.closeRegisterModal}
            registerHandler={this.registerHandler}
            disabled={this.state.disableRegisterButton}
          />
        </Modal>
        {this.state.showStatsModal && (
          <ProgramStats
            programId={this.props.programId}
            showModal={this.state.showStatsModal}
            setShowModal={(e) => this.setState({ showStatsModal: e })}
          />
        )}
      </div>
    )
    return (
      <>
        {details}
        <CalendarModal
          closeButton
          closeModal={() => this.setState({ showModal: false })}
          hide={() => this.setState({ showModal: false })}
          title="Add to Schedule"
          show={this.state.showModal}
        >
          <AddToSchedule
            programId={this.props.programId}
            closeModal={() => this.setState({ showModal: false })}
            getSchedules={() => this.getSchedules()}
          />
        </CalendarModal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    userId: state.auth.userId
  }
}

export default withRouter(
  connect(mapStateToProps, { getAdminStatus })(ProgramDetail)
)
