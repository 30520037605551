import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";
import * as EmailValidator from "email-validator";

import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import Input from "../../UI/input/Input";
import MembershipCheckbox from "./MembershipCheckbox"
import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";

import Config from "./config";
import CKEditorFull from "./CkEditorFull";
import VerifiedEmailSelect from "./VerifiedEmailsSelect";

import "./Invoicing.css";

class MembershipRenewal extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    membershipRenewal: [],
    orgnId: null,
    noOfEmail: 0,
    id: null,
    emailBody: null,
    emailFooter: null,
    memberTypes: [],
    fromEmail: {
      elementType: "input",
      elementConfig: {
        name: "fromEmail",
        type: "email",
        required: true
      },
      value: ""
    },
    subject: {
      elementType: "input",
      elementConfig: {
        name: "subject",
        type: "text",
        required: true
      },
      value: ""
    },
    header: {
      elementType: "input",
      elementConfig: {
        name: "header",
        type: "text",
        required: true
      },
      value: ""
    },
    memberImage: {
      elementType: "input",
      elementConfig: {
        name: "memberImage",
        id: "memberImage",
        type: "checkbox",
        required: true,
        checked: null
      }
    },
    contactInfo: {
      elementType: "input",
      elementConfig: {
        name: "contactInfo",
        id: "contactInfo",
        type: "checkbox",
        required: true,
        checked: null
      }
    },
    testMail: {
      elementType: "input",
      elementConfig: {
        name: "test",
        type: "email",
        required: true
      },
      value: ""
    },
    checkbox: {
      elementType: "input",
      elementConfig: {
        name: "lifetime",
        type: "checkbox",
        checked: null
      },
      id: null
    },
    months: {
      elementType: "input",
      elementConfig: {
        name: "test",
        type: "number"
      },
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" }
    },
    labels: {
      elementType: "select",
      elementConfig: {
        name: "labels",
        placeholder: "Select Labels",
        options: [],
        isMulti: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    mailList: [],
    labelIds: [],
    disabled: false,
    testMailButton: false,
    sendMail: false,
    selectedLabels: null,
    verifiedEmails: [],
    useEmailDropdown: false,
    fromEmailSelected: "",
    defaultFromEmail: "",
  };

  componentDidMount = async () => {
    const orgEmailRes = await axios.get("/organization_communication_emails", {
      cancelToken: this.signal.token 
    });
    const { use_verified_email } = orgEmailRes.data.meta;
    let verifiedEmails = [];
    let useEmailDropdown = false;
    if ( use_verified_email ) {
      const { organization_communication_emails: orgEmails } = orgEmailRes.data;
      verifiedEmails =
        Array.isArray(orgEmails)
          ? orgEmails.filter(email => email.is_verified)
          : [];
      useEmailDropdown = verifiedEmails.length >= 1;
    }

    const mailRes = await axios.get("/mail_formats", { cancelToken: this.signal.token });
    const { mail_formats } = mailRes.data;
    const membershipRenewal = mail_formats.filter(
      data => data.tab_name === "Membership Renewal"
    );

    const orgLabelRes = await axios.get('/organization_tags', {
      params: {
        tag_type: "User",
        required_pagination: false
      },
      cancelToken: this.signal.token
    });
    

    const memberTypesRes = await axios.get("/mail_formats/formdata", { 
      cancelToken: this.signal.token 
    });
    const { member_types } = memberTypesRes.data.json;

    this.setState({
      verifiedEmails,
      useEmailDropdown,
      id: membershipRenewal[0].id,
      orgnId: membershipRenewal[0].organization_id,
      fromEmail: {
        ...this.state.fromEmail,
        value: membershipRenewal[0].data.from_email
      },
      defaultFromEmail: membershipRenewal[0].data.from_email,
      subject: {
        ...this.state.subject,
        value: membershipRenewal[0].data.subject
      },
      header: {
        ...this.state.header,
        value: membershipRenewal[0].data.header
      },
      bodyContent: membershipRenewal[0].data.body_email,
      footerContent: membershipRenewal[0].data.footer_text_email,
      memberImage: {
        ...this.state.memberImage,
        elementConfig: {
          ...this.state.memberImage.elementConfig,
          checked:
            membershipRenewal[0].data.show_member_image === 1 ? true : false
        }
      },
      contactInfo: {
        ...this.state.contactInfo,
        elementConfig: {
          ...this.state.contactInfo.elementConfig,
          checked:
            membershipRenewal[0].data.review_contact_info === 1 ? true : false
        }
      },
      emailBody: membershipRenewal[0].data.body_email,
      emailFooter: membershipRenewal[0].data.footer_text_email,
      selectedLabels: orgLabelRes.data.organization_tags,
      labels: {
        ...this.state.labels,
        elementConfig: {
          ...this.state.labels.elementConfig,
          options: orgLabelRes.data.organization_tags.map(label => {
            return { label: label.name, value: label.id };
          })
        }
      },
      memberTypes: member_types,
      loading: false,
    });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key, type) => {
    let inputValue = {};
    if (type === "text") {
      inputValue["value"] = event.target.value;
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols }, () => {
      if (key === 'months' || key === 'labels') {
        this.setState({
          labelIds: this.state.labels.selectedOption
            ? this.state.labels.selectedOption.map(label => label.value)
            : []
        }, () => this.fetchEmailCount()
        )
      }
    });
  };

  toggleMemberOrContactInfo = (key, event) => {
    if (key === 'memberImage') {
      this.setState({
        ...this.state,
        memberImage: {
          ...this.state.memberImage,
          elementConfig: {
            ...this.state.memberImage.elementConfig,
            checked: !this.state.memberImage.elementConfig.checked
          }
        }
      })
    }
    if (key === 'contactInfo') {
      this.setState({
        ...this.state,
        contactInfo: {
          ...this.state.contactInfo,
          elementConfig: {
            ...this.state.contactInfo.elementConfig,
            checked: !this.state.contactInfo.elementConfig.checked
          }
        }
      })
    }
  };

  fetchEmailCount = () => {
    axios
      .get(`/mail_formats/memberships_expired_count`, {
        params: {
          organization_id: this.state.orgnId,
          member_expiration_months: this.state.months.value,
          member_type_ids: `[${this.state.mailList.join()}]`,
          label_ids: JSON.stringify(this.state.labelIds)
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          ...this.state,
          noOfEmail: res.data.json.number_of_members
        });
      });
  };

  onCheckboxToggle = (mailListArray) => {
    this.setState({
      ...this.state,
      mailList: mailListArray
    }, () => this.fetchEmailCount())
  }

  renderCheckBoxes = () => {
    const { mailList, memberTypes } = this.state
    return memberTypes.map(memberType => (
      <MembershipCheckbox
        mailList={mailList}
        memberType={memberType}
        onCheckboxToggle={this.onCheckboxToggle}
      />
    ));
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let attrData = {};
    const {
      id,
      fromEmail,
      subject,
      header,
      memberImage,
      contactInfo,
      emailBody,
      emailFooter,
      useEmailDropdown,
      fromEmailSelected,
    } = this.state;

    if (
      (useEmailDropdown && !fromEmailSelected) ||
      (!useEmailDropdown && !fromEmail.value)
    ) {
      this.setState({ disabled: false });
      toast.error(`Please ${
        useEmailDropdown ? "Select" : "Enter"
      } a valid email address`)
      return;
    }


    attrData["body_email"] = emailBody;
    attrData["footer_text_email"] = emailFooter;
    attrData["id"] = id;
    attrData["from_email"] = 
      useEmailDropdown
        ? fromEmailSelected
        : fromEmail.value;
    attrData["subject"] = subject.value;
    attrData["header"] = header.value;
    attrData["show_member_image"] =
      memberImage.elementConfig.checked === true ? 1 : 0;
    attrData["review_contact_info"] =
      contactInfo.elementConfig.checked === true ? 1 : 0;

    axios.put(`/mail_formats`, attrData).then(res => {
      if (res.data.json.success) {
        toast.success("Mail format successfully saved");
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            return toast.error(title(error) + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
      }
      this.setState({ disabled: false })
    });
  };

  inputChangeBody = event => {
    this.setState({
      emailBody: event.editor.getData()
    });
  };

  inputChangeFooter = event => {
    this.setState({
      emailFooter: event.editor.getData()
    });
  };

  testMail = event => {
    event.preventDefault();
    this.setState({ testMailButton: true })
    if (EmailValidator.validate(this.state.testMail.value)) {
      axios
        .get(`/mail_formats/send_test_email`, {
          params: {
            send_test_email: this.state.testMail.value
          },
          cancelToken: this.signal.token
        })
        .then(res => {
          if (res.data.json.success) {
            toast.success(res.data.json.message);
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                return toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ testMailButton: false })
        });
    } else {
      toast.error("Please enter valid email.");
      this.setState({ testMailButton: false })
    }
  };

  qtyemail = event => {
    event.preventDefault();
    if (this.state.noOfEmail) {
      this.setState({ sendMail: true })
      axios
        .get("/mail_formats/send_email", {
          params: {
            member_expiration_months: this.state.months.value,
            member_type_ids: `[${this.state.mailList.join()}]`,
            label_ids: `[${this.state.labelIds.join()}]`
          },
          cancelToken: this.signal.token
        })
        .then(res => {
          if (res.data.json.success) {
            toast.success(res.data.json.message);
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                return toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ sendMail: false })
        });
    } else {
      toast.error(`There are no members who's membership has expired in ${this.state.months.value} months`)
    }
  };

  render() {
    const { 
      useEmailDropdown,
      verifiedEmails, 
      fromEmailSelected,
      defaultFromEmail 
    } = this.state;

    return this.state.loading ? (
      <div className="card-text">
        <div className="row">
          <div className="col-12">
            <Spinner className="h-60vh" />
          </div>
        </div>
      </div>
    ) : (
        <div className="membership p-4">
          <div className="row">
            <div className="col-lg-12">
              <h5 className="font-weight-bold pt-2">Automated Membership Renewal Emailing</h5>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-3">
              <h6 className="text-dark">From Address</h6>
            </div>
            <div className="col-lg-6">
              {
                useEmailDropdown
                  ? (
                    <VerifiedEmailSelect 
                      defaultEmail={defaultFromEmail}
                      emailAddresses={verifiedEmails}
                      emailChangeHandler={email => this.setState({
                        fromEmailSelected: email ? email.label : "",
                      })}
                    />
                  ) : (
                    <Input
                      {...this.state.fromEmail}
                      changed={event =>
                        this.inputChangedHandler(event, "fromEmail", "text")
                      }
                    />
                  )
              }
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-3">
              <h6 className="text-dark">Subject</h6>
            </div>
            <div className="col-lg-6">
              <Input
                {...this.state.subject}
                changed={event =>
                  this.inputChangedHandler(event, "subject", "text")
                }
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-3">
              <h6 className="text-dark">Header</h6>
            </div>
            <div className="col-lg-6">
              <Input
                {...this.state.header}
                changed={event =>
                  this.inputChangedHandler(event, "header", "text")
                }
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-3">
              <h6 className="text-dark">
                <label htmlFor="memberImage" className="cursor font-weight-normal">Show Member Image</label>
              </h6>
            </div>
            <div className="col-lg-6">
              <Input
                {...this.state.memberImage}
                changed={event => this.toggleMemberOrContactInfo("memberImage", event)}
                className="cursor mt-1"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12 body-text">
              <div id="toolbar-container" />
              <CKEditorFull
                data={this.state.emailBody}
                config={Config}
                onChange={this.inputChangeBody}
                onBeforeLoad={(CKEDITOR) => ( CKEDITOR.disableAutoInline = true )}
              />
            </div>
          </div>

          <div className="row short-codes mt-4">
            <div className="col-12">
              <h6 className="text-dark">Short Codes</h6>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Membership Type: &lt;membshiptype&gt;</span>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Number of Volunteer Hours Required: &lt;volhours&gt;</span>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Member Full Name: &lt;membfullname&gt;</span>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Number of Volunteer Hours Recorded this year: &lt;volyear&gt;</span>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Member First Name: &lt;membfirstname&gt;</span>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Current Account Balance: &lt;acctbal&gt;</span>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Member Last Name: &lt;memblastname&gt;</span>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Year they became a member: &lt;membyear&gt;</span>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Membership Annual Rate: &lt;membshiprate&gt;</span>
            </div>
            <div className="col-lg-6">
              <span className="pl-3">Number of years they have been a member: &lt;membyearstotal&gt;</span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-3">
              <h6 className="text-dark">
                <label htmlFor="contactInfo" className="cursor">Review Contact Info</label>
              </h6>
            </div>
            <div className="col-lg-6">
              <Input
                {...this.state.contactInfo}
                changed={event => this.toggleMemberOrContactInfo("contactInfo", event)}
                className="cursor mt-1"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-4">
              <h6 className="text-dark">Footer Text</h6>
            </div>
            <div className="col-lg-12 footer w-100">
              <div id="toolbar-container-footer" />
              <CKEditorFull
                data={this.state.emailFooter}
                config={Config}
                onChange={this.inputChangeFooter}
                onBeforeLoad={(CKEDITOR) => ( CKEDITOR.disableAutoInline = true )}
              />
            </div>
            <div className="col-lg-4 mt-4 d-flex justify-content-between">
              <Button
                type="success"
                clicked={this.submitHandler}
                className="px-5 mr-2"
                disabled={this.state.disabled}>
                Save
              </Button>
              <Button
                type="secondary"
                className="px-5"
              >
                View
              </Button>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-4">
              <h6>Test Email</h6>
            </div>
            <div className="col-lg-7">
              <form
                onSubmit={this.testMail}
                className="row d-flex justify-content-around"
              >
                <div>
                  <Input
                    {...this.state.testMail}
                    changed={event =>
                      this.inputChangedHandler(event, "testMail", "text")
                    }
                  />
                </div>
                <div>
                  <FormButton attr={true} className="px-5" disabled={this.state.testMailButton}>Test</FormButton>
                </div>
              </form>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6">
              <h4>Send Email to members</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 mt-2">
              <h6>Select Members</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <form onSubmit={this.testMail}>
                <div className="row">
                  {this.renderCheckBoxes()}
                </div>
              </form>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-8 pt-2">
              Send to members who's membership has expired in the last
            </div>
            <div className="col-lg-2">
              <Input
                {...this.state.months}
                changed={event =>
                  this.inputChangedHandler(event, "months", "text")
                }
              />
            </div>
            <div className="col-lg-2 pt-2">months</div>
            <div className="col-lg-12">
              Estimated number of members who will receive an email:{" "}
              <strong>{this.state.noOfEmail}</strong>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center my-4">
              <Button type="success" clicked={this.qtyemail} className="px-4 py-2" disabled={this.state.sendMail}>
                Send
              </Button>
            </div>
          </div>
        </div>
      );
  }
}

export default MembershipRenewal;
