import React from 'react'
import { Link } from "react-router-dom"

const AgendaEvent = (props) => {
  const { event, title } = props;
  return (
    <>
      {event.userOrProgram
        ? <Link to={`/${event.userOrProgram === "User" ? "users" : "programs"}/${event.userOrProgramId}/${event.userOrProgram === "User" ? "summary" : ""}`}>
          {title}
        </Link>
        : <>{title}</>}
    </>
  )
}

export default AgendaEvent
