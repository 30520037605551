import React from "react";
import "./AuthContainer.css";

const authContainer = props => (
  <div className="vertical-center bg-light-blue">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mx-auto">
              <div className="card rounded-0">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12 text-center my-5 py-5">
        {`Powered By AMS Network © ${new Date().getFullYear()}`}
      </div>
    </div>
  </div>
);

export default authContainer;
