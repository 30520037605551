import React, { Component } from "react"

import axios from "../../configAxios"
import Tabs from "../formTabs"

import ExpertsSearch from "./ExpertsSearch"
import ExpertsItems from "./ExpertsItems"

class Experts extends Component {
  signal = axios.CancelToken.source()
  state = {
    loading: true,
    experts: [],
    totalCount: null,
    forcePage: 0,
    queryParams: {
      type: "experts",
      page: 1,
    },
    tabs: ["Members", "Experts"],
    activeTab: "Experts",
    disabled: true,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getUsers = (params, search) => {
    this.setState({ loading: true })
    axios
      .get("/users", {
        params: this.state.queryParams,
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          experts: res.data.users,
          totalCount: res.data.meta.total_count,
          disabled: false,
          loading: false,
        })
      })
      .catch((error) => console.log(error))
  }

  searchHandler = (params) => {
    const { queryParams } = this.state
    this.setState(
      {
        totalCount: null,
        queryParams: {
          ...queryParams,
          ...params,
        },
        disabled: true,
      },
      () => this.getUsers()
    )
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    const { queryParams } = this.state
    this.setState(
      {
        queryParams: {
          ...queryParams,
          page: page.selected + 1,
        },
      },
      () => this.getUsers()
    )
    window.scrollTo(0, 0)
  }

  render() {
    const { loading, experts, totalCount, forcePage, perPage } = this.state
    return (
      <React.Fragment>
        <div className="col-lg-4">
          <ExpertsSearch
            searchHandler={this.searchHandler}
            disabled={this.state.disabled}
          />
        </div>
        <div className="col-lg-8">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-white p-0 border-bottom-0">
                  <Tabs
                    tabs={this.state.tabs}
                    removeExpertsTab={false}
                    activeTab={this.state.activeTab}
                    tabChangedHandler={this.props.tabChangedHandler}
                    hrMargin={173}
                  />
                </div>
                <div className="card-body p-0">
                  <div className="card-text">
                    <ExpertsItems
                      experts={experts}
                      totalCount={totalCount}
                      loading={loading}
                      forcePage={forcePage}
                      perPage={perPage}
                      paginationHandler={this.paginationHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Experts
