import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { title, snakeCase } from "change-case"
import queryString from "query-string"

import axios from "../../configAxios"
import langUtils from "../../utils/langUtils"

import ShortAttributes from "./ShortAttributes"
import Cues from "./Cues"
import Crew from "./Crew"
import LongAttributes from "./LongAttributes"
import Media from "./Media"
import Bookings from "./BookingItems"

import "./Project.css"

class Attributes extends Component {
  state = {
    tabs: [],
    activeTab: "Short Attributes",
    query: null,
  }

  componentDidMount() {
    this.setTabs()
    this.handleTabFromProp()
    window.addEventListener("popstate", this.handleTabFromProp)
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.toptab !== prevProps.match.params.toptab ||
      this.props.location.search !== prevProps.location.search
    ) {
      if (this.props.match.params.toptab) {
        let query
        if (this.props.location.search !== "") {
          const values = queryString.parse(this.props.location.search)
          query = values.value_3
        } else {
          query = null
        }
        this.tabChangeHandler(title(this.props.match.params.toptab))
        this.setState({ query })
      }
    }
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : "Short Attributes",
    })
  }

  tabChangeHandler = (activeTab) => {
    this.setState({ activeTab })
    this.props.history.push(
      `/projects/${this.props.id}/${snakeCase(activeTab)}`
    )
  }

  setTabs = () => {
    const params = { id: this.props.id }
    const tabsPolicy = {
      media: "Media",
      long_attributes: "Long Attributes",
      short_attributes: "Short Attributes",
      crew_roles: "Contributors",
      bookings: "Bookings",
      submissions: "Submissions",
    }
    axios.get("projects/policy", { params }).then((res) => {
      const tabs = []
      Object.keys(tabsPolicy).map((policy, tab) => {
        if (res.data.json[policy]) {
          tabs.push(tabsPolicy[policy])
        }
      })
      this.setState({
        tabs: tabs,
        activeTab: tabs[0],
      })
    })
  }

  render() {
    const { tabs: tabNames } = this.state
    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${
          this.state.activeTab === tab ? "active-tab" : "inactive-tab "
        }`}
      >
        {tab === "Submissions" ? langUtils("Submissions", "Submissions") : tab}
      </li>
    ))
    let activeTab
    switch (this.state.activeTab) {
      case "Short Attributes":
        activeTab = <ShortAttributes id={this.props.id} />
        break
      case "Contributors":
        activeTab = <Crew />
        break
      case "Long Attributes":
        activeTab = <LongAttributes />
        break
      case "Media":
        activeTab = <Media query={this.state.query} />
        break
      case "Submissions":
        activeTab = <Cues />
        break
      case "Bookings":
        activeTab = <Bookings />
        break
      default:
        activeTab = null
    }
    return (
      <div className="card">
        <ul className="nav nav-tabs ml-0">{tabs}</ul>
        {activeTab}
      </div>
    )
  }
}

export default withRouter(Attributes)
