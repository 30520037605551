import React, { Component } from "react"
import classnames from "classnames"
import { OverlayTrigger, Popover } from "react-bootstrap"
import axios from "../../../configAxios"
import { responseErrorHandler } from '../../../utils/errorHandler'
import langUtils from "../../../utils/langUtils"

import Button from "../../UI/button/Button"
import Modal from "../../UI/Modal"
import Spinner from "../../UI/Spinner"
import AddNew from "./AddNew"
import EditRental from "./EditRental"

class Categories extends Component {
  signal = axios.CancelToken.source();
  state = {
    categoriesList: [],
    newModal: false,
    editModal: false,
    category: {},
    loading: true
  };

  componentDidMount() {
    this.getCategories();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getCategories = () => {
    axios.get("/categories", { cancelToken: this.signal.token }).then(res => {
      this.setState({ categoriesList: res.data.categories, loading: false });
    });
  };

  deleteCategory = (categoryId) => {
    let response = window.confirm("Are you sure you want to delete the category?");
    if (response) {
      axios
        .delete(`/categories/${categoryId}`, { cancelToken: this.signal.token })
        .then(res => {
          if (res.data.json.success){
            let categoriesList = this.state.categoriesList;
            categoriesList = categoriesList.filter((category) => {
              return category.id !== categoryId;
            });
            this.setState({categoriesList})
          } else {
            responseErrorHandler(res);
          }
        });
    }
  }

  render() {
    const categories = this.state.categoriesList;
    let categoriesList;
    if (this.state.loading) {
      categoriesList = <Spinner />;
    } else {
      categoriesList = categories.map(category => (
        <li className="list-group-item category rounded-0">
          <div className="row">
            <div className="px-3">
              {
                <OverlayTrigger key={"top"} placement={"top"}
                overlay={
                  <Popover>
                    <div>
                      Category Id: <span className="font-weight-bold">{category.id}</span>
                    </div>
                  </Popover>
                  }
                >       
                  <i className="fa fa-info-circle text-muted cursor" aria-hidden="true"/>
                </OverlayTrigger>
              }
            </div>
            <div className="col-md">
                <span class="font-weight-bold">
                  {category.name}
                </span>
                <div>
                  {category.count || 0} assets with this category
                </div>
            </div>
            <div className="col-md-2">
              <i className={classnames(
                  "fas fa-check-circle",
                  {
                    "text-info": category.bookable_by_admin_only,
                    "text-secondary element-disabled": !category.bookable_by_admin_only,
                  }
                )} 
              />
            </div>
            <div className="col-md-2">
              <i className={classnames(
                "fas fa-check-circle",
                {
                  "text-info": category.is_public,
                  "text-secondary element-disabled": !category.is_public,
                }
              )}
              />
            </div>
            <div className="col-md">
              <div className="d-flex justify-content-around text-muted px-5">
                <i
                  className="fas fa-pencil-alt cursor"
                  onClick={() => { window.scrollTo(0, 0); this.setState({ category, editModal: true }) }}
                />
                <i
                  className={`fas fa-trash ${category.can_delete ? "cursor" : ""}`}
                  style={{ cursor: category.can_delete ? "pointer" : "no-drop", opacity: category.can_delete ? 1 : 0.7 }}
                  onClick={() => {category.can_delete && this.deleteCategory(category.id)}}
                />
              </div>
            </div>
          </div>
        </li>
      ));
    }
    return (
      <div className="rental-categories">
        <ul className="list-group list-group-flush mt-4">
          <li className="list-group-item">
            <div className="row">
               <div className="px-3">
                <span className="font-weight-bold">Id</span>
              </div>
              <div className="col-md">
                <span className="font-weight-bold">Name</span>
              </div>
              <div className="col-md-2">
                <span className="font-weight-bold">Staff Only</span>
              </div>
              <div className="col-md">
                <span className="font-weight-bold">Public (API)</span>
              </div>
              <div className="col-md text-center">
                <Button
                  type="success"
                  clicked={() => { window.scrollTo(0, 0); this.setState({ newModal: true }) }}
                >
                  {langUtils("txt_global_add_new", "Add New")}
                </Button>
              </div>
            </div>
          </li>
          {categoriesList}
        </ul>
        <Modal
          show={this.state.newModal}
          title="New Category"
          hide={() => this.setState({ newModal: false })}
        >
          <AddNew
            type="category"
            getCategories={this.getCategories}
            closeModal={() => this.setState({ newModal: false })}
          />
        </Modal>
        <Modal
          show={this.state.editModal}
          title="Edit Category"
          hide={() => this.setState({ editModal: false })}
        >
          <EditRental
            type="category"
            category={this.state.category}
            isCategory={true}
            getCategories={this.getCategories}
            closeModal={() => this.setState({ editModal: false })}
          />
        </Modal>
      </div>
    );
  }
}

export default Categories;
