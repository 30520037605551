import React, { Component } from 'react'
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from '../../../../configAxios'
import Button from '../../../UI/button/Button'
import Input from '../../../UI/input/Input'
import FormButton from '../../../UI/button/FormButton';

class OverrideForm extends Component {
  signal = axios.CancelToken.source();
  timeout = 0;
  state = {
    disableSubmit: false,
    tags: [],
    formFields: {
      key: {
        elementType: "input",
        elementConfig: {
          name: "key",
          type: "text",
          required: true
        },
        label: "Key",
        value: ""
      },
      value: {
        elementType: "input",
        elementConfig: {
          name: "value",
          type: "text",
          required: true
        },
        label: "Value",
        value: ""
      },
      word: {
        elementType: "asyncSelect",
        elementConfig: {
          name: "words",
          placeholder: "Search a word here",
          required: true,
          inputLength: 1,
          loadOptions: (inputValue, callback) => {
            if (inputValue.length > 0) this.searchWordsByName(inputValue, callback);
          },
          components: {
            DropdownIndicator: () => {
              return null;
            },
            IndicatorSeparator: () => {
              return null;
            }
          },
          styles: {
            multiValueLabel: base => ({
              ...base,
              backgroundColor: "#f8f9fa",
              color: "#666666"
            }),
            multiValueRemove: base => ({
              ...base,
              backgroundColor: "#f8f9fa",
              color: "#666666"
            })
          }
        },
        selectedOption: {}
      }
    },
    tagCharacters: ""
  }

  componentDidMount() {
    if (this.props.edit) {
      let formFields = this.state.formFields
      formFields.key.value = this.props.overrideKey
      formFields.value.value = this.props.overrideValue

      this.setState({ ...this.state, formFields })
    }
  }

  searchWordsByName = (inputValue, callback) => {
    const queryParams = {
      params: { query: inputValue },
      cancelToken: this.signal.token
    };
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getWordsByName(queryParams, callback);
    }, 300);
  };

  getWordsByName = (queryParams, callback) => {
    axios
      .get("/languages/search", queryParams)
      .then(res => {
        callback(Object.keys(res.data.json).map(key => ({ value: res.data.json[key], label: key })));
      })
  }

  inputChangedHandler = (event, key) => {
    if (key === "word") {
      let formFields = this.state.formFields
      formFields.word.selectedOption = event
      formFields.key.value = event ? event.label : ""
      formFields.value.value = event ? event.value : ""
      this.setState({ ...this.state, formFields })
    } else {
      let formFields = this.state.formFields
      formFields[key].value = event.target.value
      this.setState({ ...this.state, formFields });
    }
  }

  submitHandler = e => {
    e.preventDefault()

    this.setState({ ...this.state, disableSubmit: true })
    let data = {
      key: this.state.formFields.key.value,
      value: this.state.formFields.value.value
    }

    axios.put(`/languages/`, data)
      .then(res => {
        if (res.data.json.success) {
          toast.success(this.props.edit ? "Override/Slang Updated" : "Override Successful")
          this.props.updateData()
          this.props.closeModal()
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      })
  }

  render() {
    return (
      <div className="mt-3">
        <form onSubmit={this.submitHandler}>
          {
            this.props.edit ? null : (
              <React.Fragment>
                <Input
                  {...this.state.formFields.word}
                  chart={this.state.tagCharacters}
                  changed={event => this.inputChangedHandler(event, "word")}
                  onInputChange={event => this.setState({ tagCharacters: event })}
                />
                <hr />
              </React.Fragment>
            )
          }
          <Input {...this.state.formFields.key} />
          <Input
            {...this.state.formFields.value}
            changed={event => this.inputChangedHandler(event, "value")}
          />
          <div className="text-center">
            <Button
              type="secondary"
              clicked={this.props.closeModal}
              className="mr-2"
            >
              Cancel
            </Button>
            <FormButton disabled={this.state.disableSubmit}>
              {this.props.edit ? "Update" : "Create"}
            </FormButton>
          </div>
        </form>
      </div>
    )
  }
}

export default OverrideForm;