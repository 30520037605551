import React from "react";

import ProjectItem from "./ProjectItem";

const ProjectsList = props => {
  return (
    <div>
      {props.projects.length === 0 ? (
        <div
          className="d-flex justify-content-center flex-column"
          style={{ height: "40vh" }}
        >
          <h3 className="text-center">
            There is no project associated with {props.projectCueName}
          </h3>
        </div>
      ) : (
          ""
        )}
      {
        props.projects.map((project, index) => (
          <ProjectItem
            index={index}
            key={`${project.id}-${project.consider_type_id}`}
            project={project}
            projectCue={props.projectCue}
            statusChangeHandler={props.statusChangeHandler}
          />
        )
        )
      }
    </div>
  );
};

export default ProjectsList;
