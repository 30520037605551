import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { title } from "change-case"
import { format as dateFormat } from "date-fns"

import {
  getProject,
  getProjectAttributes,
  updateProjectAttribute,
} from "../../store/actions/index"
import axios from "../../configAxios"
import Spinner from "../UI/Spinner"
import Modal from "../UI/Modal"
import DatePicker from "../UI/datePicker"
import FormButton from "../UI/button/FormButton"
import Button from "../UI/button/Button"
import Pagination from "../UI/Pagination"
import SectionSpinner from "../UI/ModalSpinner"
import ToggleSwitch from "../common/toggleSwitch"

import "./Project.css"

class Cues extends Component {
  constructor(props) {
    super(props)
    this.signal = axios.CancelToken.source()
    this.submitFavaTV = "Submit to FAVATV"
    this.state = {
      attributes: [],
      loading: true,
      isPrivatelyHosted: false,
      isPasswordProtected: false,
      favatvUrl: null,
      showModal: false,
      showDetailsModal: false,
      isDownloadable: false,
      disabled: false,
      disableCheckbox: false,
      showFavaSubmitNotice: false,
      disableFavaNoticeButton: false,
      submitFavaAttrId: null,
      password: "",
      favaNoticeForm: {
        name: "",
        date: null,
        notes: "",
      },
      projectReels: [],
      projectReelsLoading: true,
      projectReelPaginate: {
        totalCount: 0,
        forcePage: 0,
        perPage: 10,
      },
      manageAttributesForm: null,
    }
  }

  componentDidMount() {
    axios
      .get(`/projects/${this.props.match.params.id}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState(
          {
            isPrivatelyHosted: res.data.project.is_privately_hosted,
            isFavatvEnabled: res.data.project.is_favatv_enabled,
            favatvUrl: res.data.project.favatv_url,
            project: res.data.project,
          },
          () => {
            this.props.getProjectAttributes(
              "Cues",
              this.props.projects.project.slug
                ? this.props.projects.project.slug
                : this.props.projects.project.id,
              this.signal.token
            )
            this.getProjectReels()
          }
        )
      })
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios
      .get("/projects/policy", {
        params: { id: this.props.projects.project.id },
      })
      .then((res) => {
        this.setState({
          manageAttributesForm: res.data.json.manage_attributes_form,
        })
      })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.projects !== nextProps.projects) {
      const { projectAttributes } = nextProps.projects
      const attributes = []
      if (projectAttributes) {
        projectAttributes.map((attr) => {
          if (attr.project_attribute_type_name === this.submitFavaTV) {
            this.setState({ submitFavaAttrId: attr.id })
          }
          const formControl = {
            elementType: "input",
            elementConfig: {
              name: attr.project_attribute_type_id,
              type: "checkbox",

              checked: attr.value_3,
            },
            id: attr.id,
            projectAttributeTypeId: attr.project_attribute_type_id,
            label: attr.project_attribute_type_name,
          }
          attributes.push(formControl)
          return attributes
        })
      }
      this.setState({ attributes, loading: false, disabled: false })
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Rquest is being cancelled")
  }

  getProjectReels = (page) => {
    const forPagination = page && page.hasOwnProperty("selected")
    const { project } = this.props.projects

    this.setState({
      projectReelPaginate: {
        ...this.state.projectReelPaginate,
        forcePage: forPagination ? page.selected : 0,
      },
      projectReelsLoading: true,
    })

    axios
      .get("/reels", {
        params: {
          project_id: project.id,
          ...(forPagination && { page: page.selected + 1 }),
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          projectReels: res.data.reels ? res.data.reels : [],
          projectReelPaginate: {
            ...this.state.projectReelPaginate,
            totalCount:
              res.data.meta && res.data.meta.total ? res.data.meta.total : 0,
          },
          projectReelsLoading: false,
        })
      })
  }

  inputChangedHandler = (index, attr, checked) => {
    if (
      attr.label === this.submitFavaTV &&
      !this.state.attributes[index].elementConfig.checked
    ) {
      this.setState({ showFavaSubmitNotice: true })
    } else {
      const checkboxValue = checked
      this.setState({ index, disabled: true })
      this.updateAttributeHandler(checkboxValue, attr)
    }
  }

  updateAttributeHandler = (checkboxValue, attr) => {
    let attrData
    if (attr.id) {
      attrData = {
        id: attr.id,
        project_id: this.props.projects.project.id,
        value: null,
        value_type: "value_3",
        value_3: checkboxValue,
        project_attribute_type_id: attr.projectAttributeTypeId,
      }
    } else {
      attrData = {
        project_id: this.props.projects.project.id,
        value: null,
        value_type: "value_3",
        value_3: checkboxValue,
        project_attribute_type_id: attr.projectAttributeTypeId,
      }
    }
    this.props.updateProjectAttribute(attrData)
    const id = this.props.projects.project.slug
      ? this.props.projects.project.slug
      : this.props.projects.project.id
    this.props.getProject(id)
  }

  checkboxHandler = () => {
    if (this.state.isPrivatelyHosted) {
      this.setState({ disableCheckbox: true, loading: true })
      const response = window.confirm("Are you sure?")
      if (response) {
        axios
          .put("/projects/unset_private", {
            id: this.props.match.params.id,
          })
          .then((res) => {
            if (res.data.json.success) {
              toast.success("Prefeerence successfully updated.")
              this.setState({
                isPrivatelyHosted: false,
                disableCheckbox: false,
                loading: false,
              })
            } else {
              if (res.data.json.hasOwnProperty("errors")) {
                Object.keys(res.data.json.errors).forEach((error) => {
                  toast.error(title(error) + " " + res.data.json.errors[error])
                })
              }
              if (res.data.json.hasOwnProperty("error")) {
                toast.error(res.data.json.error)
              }
              this.setState({ loading: false, disableCheckbox: false })
            }
          })
      } else {
        this.setState({ loading: false, disableCheckbox: false })
      }
    } else {
      window.scrollTo(0, 0)
      this.setState({
        isPrivatelyHosted: true,
        showModal: true,
        disableCheckbox: true,
      })
    }
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const { password, isDownloadable, isPasswordProtected } = this.state
    const formData = {
      id: this.props.match.params.id,
      ...(isPasswordProtected && { password }),
      is_downloadable: isDownloadable,
      is_hosted_with_password_protection: isPasswordProtected,
    }

    axios
      .put("/projects/set_private", formData)
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Prefrence successfully updated.")
          this.setState({
            showModal: false,
            showDetailsModal: true,
            favatvUrl: res.data.json.data.favatv_url,
            isDownloadable: false,
            disableCheckbox: false,
            password: "",
            isPasswordProtected: false,
            disabled: false,
          })
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
          this.setState({ disabled: false })
        }
      })
      .catch(() => this.setState({ disabled: false }))
  }

  favaFormSubmitHandler = (e) => {
    e.preventDefault()
    this.setState({ disableFavaNoticeButton: true })
    const submitFavaAttr = this.state.attributes.filter(
      (attr) => attr.label === this.submitFavaTV
    )
    const { name, date, notes } = this.state.favaNoticeForm
    axios
      .post(
        "/project_cue_agreements",
        {
          project_attribute_id: this.state.submitFavaAttrId,
          name,
          notes,
          date: dateFormat(date, "DD-MM-YYYY"),
        },
        { cancelToken: this.signal.token }
      )
      .then((res) => {
        if (res.data.json.success) {
          this.updateAttributeHandler(true, submitFavaAttr[0])
          this.resetFavaNoticeModal()
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(`${title(error)} ${res.data.json.errors[error]}`)
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
          this.setState({ disableFavaNoticeButton: false })
        }
      })
  }

  noticeInputHandler = (e, key) => {
    let value
    if (key === "date") {
      value = e
    } else {
      value = e.target.value
    }
    this.setState({
      favaNoticeForm: {
        ...this.state.favaNoticeForm,
        [key]: value,
      },
    })
  }

  resetFavaNoticeModal = () => {
    this.setState({
      showFavaSubmitNotice: false,
      disableFavaNoticeButton: false,
      favaNoticeForm: {
        notes: "",
        name: "",
        date: null,
      },
    })
  }

  resetPrivateHostedModal = () => {
    this.setState({
      showModal: false,
      isPrivatelyHosted: false,
      disableCheckbox: false,
      isDownloadable: false,
      isPasswordProtected: false,
      password: "",
      disabled: false,
    })
  }

  render() {
    const {
      attributes,
      projectReelPaginate: { totalCount, forcePage, perPage },
    } = this.state
    const sortedAttributes = attributes.sort((attr1, attr2) =>
      attr1.elementConfig.name > attr2.elementConfig.name ? 1 : -1
    )
    const Cues = sortedAttributes.map((attr, index) => {
      return (
        <div className="row" key={index}>
          <div className="col-10 my-1">
            <div className="d-flex">
              <div className="mr-3">
                <ToggleSwitch
                  id={index}
                  className="custom-control-input"
                  disabled={
                    !this.state.manageAttributesForm ||
                    (index === this.state.index && this.state.disabled)
                  }
                  checked={this.state.attributes[index].elementConfig.checked}
                  onChange={(checked) =>
                    !this.state.disabled &&
                    this.inputChangedHandler(index, attr, checked)
                  }
                  customCheckContent="YES"
                  customUncheckContent="NO"
                />
              </div>
              <label htmlFor={index} className="w-100 word-wrap-break">
                {this.state.attributes[index].label}
              </label>
            </div>
          </div>
        </div>
      )
    })

    const privatelyHostedModal = (
      <Modal
        title="Private - Web Link"
        show={this.state.showModal}
        closeModal={this.resetPrivateHostedModal}
        closeButton
        hide={this.resetPrivateHostedModal}
      >
        <form onSubmit={this.submitHandler} autoComplete="new-password">
          <div className="row settings mt-2">
            <div className="col-12 my-2">
              Only viewers with the URL and password will be able to watch your
              video, it will not be shown publicly on FAVATV
            </div>
            <div className="col-12">
              <div className="my-2 d-flex">
                <div className="mr-2">
                  <ToggleSwitch
                    className="form-check-input"
                    id="isPasswordProtected"
                    checked={this.state.isPasswordProtected}
                    onChange={() =>
                      this.setState({
                        isPasswordProtected: !this.state.isPasswordProtected,
                      })
                    }
                  />
                </div>
                <label
                  className="font-weight-bold"
                  htmlFor="isPasswordProtected"
                >
                  Is Password Protected?
                </label>
              </div>
            </div>
            {this.state.isPasswordProtected && (
              <div className="col-12">
                <div className="ml-sm-3 pl-sm-1 d-flex flex-sm-row flex-column">
                  <label
                    className="ml-sm-0 pl-sm-0 ml-3 pl-1 my-2 mr-2 font-weight-bold required"
                    htmlFor="mediaPassword"
                  >
                    Password
                  </label>
                  <div className="mx-sm-0 px-sm-0 mx-3 px-1">
                    <input
                      type="password"
                      name="password"
                      id="mediaPassword"
                      autoComplete="off"
                      className="my-2"
                      required
                      onChange={(event) =>
                        this.setState({ password: event.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="col-12">
              <div className="d-flex my-2">
                <div className="mr-2">
                  <ToggleSwitch
                    className="form-check-input"
                    id="downloadable"
                    checked={this.state.isDownloadable}
                    onChange={() =>
                      this.setState({
                        isDownloadable: !this.state.isDownloadable,
                      })
                    }
                  />
                </div>
                <label className="font-weight-bold" htmlFor="downloadable">
                  Is downloadable?
                </label>
              </div>
            </div>
          </div>
          <div className="text-center">
            <FormButton disabled={this.state.disabled} className="mt-2">
              Make Private
            </FormButton>
          </div>
        </form>
      </Modal>
    )

    const showDetailsModal = (
      <Modal
        title="Private - Web Link"
        show={this.state.showDetailsModal}
        hide={() => this.setState({ showDetailsModal: false })}
        closeModal={() => this.setState({ showDetailsModal: false })}
        closeButton
      >
        <div className="row mt-2">
          <div className="col-md-12 my-2">
            The project is now private. You can share the below URL:
          </div>
          <div className="col-md-12 my-2 font-weight-bold">Private URL:</div>
          <div className="col-md-12 my-2">{this.state.favatvUrl}</div>
          <div className="col-md-12 my-2 font-weight-bold">
            The video may take some time to publish to FAVATV.
          </div>
        </div>
      </Modal>
    )

    const favaSubmitNoticeModal = (
      <Modal
        title="Notice"
        show={this.state.showFavaSubmitNotice}
        closeButton
        closeModal={this.resetFavaNoticeModal}
        hide={this.resetFavaNoticeModal}
      >
        <form onSubmit={this.favaFormSubmitHandler}>
          <div className="row">
            <div className="col mt-4">
              <div>
                If approved your project will be publicly available on FAVATV,
                by doing so you agree that you either own the full copyright or
                have expressed permission to do so.
              </div>
              <div className="my-3">
                The video is encrypted by default, meaning that you or other
                cannot embed or download this movie from FAVATV, you can always
                share the direct link and other can view it on FAVATV. If you
                wish to make it directly embedded in other website or social
                media, please select that option as well.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <input
                className="form-control mb-3"
                required
                type="text"
                placeholder="Name *"
                id="fava-notice-name"
                onChange={(e) => this.noticeInputHandler(e, "name")}
                value={this.state.favaNoticeForm.name}
              />
            </div>
            <div className="col-md-6">
              <DatePicker
                className="mb-3"
                selected={this.state.favaNoticeForm.date}
                dateFormat={"yyyy.MM.dd"}
                placeholder="Date"
                changed={(e) => this.noticeInputHandler(e, "date")}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                name="notes"
                id="fava-notice-notes"
                className="form-control mb-3"
                placeholder="Notes"
                rows="5"
                value={this.state.favaNoticeForm.notes}
                onChange={(e) => this.noticeInputHandler(e, "notes")}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <FormButton
                className="mb-3 mr-3"
                disabled={this.state.disableFavaNoticeButton}
              >
                I Agree!
              </FormButton>
              <Button
                type="secondary"
                clicked={this.resetFavaNoticeModal}
                className="mb-3"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    )

    if (this.state.loading) {
      return <Spinner />
    } else {
      return (
        <div className="my-3 mx-4">
          {Cues}
          {this.state.isFavatvEnabled && this.state.project.has_video && (
            <React.Fragment>
              <hr />
              <div className="row">
                <div className="col-lg-12">
                  <h5 className="font-weight-bold">Private Link</h5>
                  <div className="d-flex font-weight-bold pt-2">
                    <div className="mr-3">
                      <ToggleSwitch
                        id="isPrivatelyHosted"
                        className="form-check-input cursor"
                        disabled={this.state.disableCheckbox}
                        checked={this.state.isPrivatelyHosted}
                        onChange={
                          !this.state.disableCheckbox
                            ? this.checkboxHandler
                            : null
                        }
                        customCheckContent="YES"
                        customUncheckContent="NO"
                      />
                    </div>
                    <label className="" htmlFor="isPrivatelyHosted">
                      Do you want to privately host the video on FAVATV?
                    </label>
                  </div>

                  {this.state.isPrivatelyHosted &&
                    !this.state.showModal &&
                    !this.state.showDetailsModal && (
                      <div className="mt-2">
                        <span className="font-weight-bold">Private URL :</span>
                        &nbsp;
                        <span>
                          <a
                            href={this.state.favatvUrl}
                            className="text-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.state.favatvUrl}
                          </a>
                        </span>
                      </div>
                    )}
                </div>
                {showDetailsModal}
                {privatelyHostedModal}
              </div>
            </React.Fragment>
          )}
          <div className="my-3">
            <hr />
            <div className="row">
              <div className="col">
                <h5 className="font-weight-bold">
                  This film is in the following Reels:
                </h5>
                {this.state.projectReelsLoading ? (
                  <SectionSpinner />
                ) : (
                  <>
                    {this.state.projectReels.length ? (
                      <ul>
                        {this.state.projectReels.map((reel) => (
                          <li key={reel.id}>
                            <Link to={`/reels/${reel.id}`}>{reel.name}</Link>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="text-center">No Reels Found</div>
                    )}
                  </>
                )}
                <Pagination
                  forcePage={forcePage}
                  pageCount={totalCount / perPage}
                  handlePageClick={this.getProjectReels}
                />
              </div>
            </div>
          </div>
          {favaSubmitNoticeModal}
        </div>
      )
    }
  }
}
const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProject: (id) => dispatch(getProject(id)),
    getProjectAttributes: (type, project_id, cancelToken) =>
      dispatch(getProjectAttributes(type, project_id, cancelToken)),
    updateProjectAttribute: (attrData) =>
      dispatch(updateProjectAttribute(attrData)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cues))
