import { toast } from "react-toastify"
import { title } from "change-case"

const errorStatusHandler = (response) => {
  if ("errors" in response.data.json) {
    Object.keys(response.data.json.errors).forEach((key) => {
      toast.error(title(key) + " " + response.data.json.errors[key])
    })
  }
  if ("error" in response.data.json) {
    toast.error(response.data.json.error)
  }
}

export default errorStatusHandler
