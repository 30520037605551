import React, { useEffect, useState } from "react"

const CurrencyInput = ({ props }) => {
  const [numericalInput, setNumericalInput] = useState(0)

  useEffect(() => {
    const convertedToCurrency = (props.value / 100).toFixed(2)
    setNumericalInput(convertedToCurrency)
  }, [])

  useEffect(() => {
    const fromCurrency = numericalInput * 100
    props.changed(props.elementConfig.name, { response_number: fromCurrency })
  }, [numericalInput])

  const handleChange = (e) => {
    setNumericalInput(e.target.value)
  }

  return (
    <input
      type="number"
      min="0.01"
      step="0.01"
      className={`form-control` + (props.className || "")}
      value={numericalInput}
      placeholder={
        props.elementConfig.hasOwnProperty("placeholder")
          ? props.elementConfig.placeholder
          : 0.00
      }
      onChange={handleChange}
      disabled={props.disabled}
    />
  )
}

export default CurrencyInput
