import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from "../../configAxios";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";

class EditPhoto extends Component {
  state = {
    photo: "",
    disabled: true,
    disableRemovePhoto: this.props.disableRemovePhoto
  };

  handleChange = (selectorFile: File) => {
    this.setState({ photo: selectorFile, disabled: false, disableRemovePhoto: true });
  };

  deletePhotoHandler = () => {
    this.setState({ disableRemovePhoto: true });
    let id = this.props.userId;
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      axios
        .post(`/users/destroy_photo${this.props.userId}`, { id })
        .then(res => {
          if (res.data.json.success) {
            toast.success("Removed Photo Successfully");
            this.props.updatePhoto();
            this.props.closeModal();
          } else {
            if ("error" in res.data.json) {
              toast.error(res.data.json.error)
            }
            if ("errors" in res.data.json) {
              Object.keys(res.data.json.errors).forEach(key => {
                toast.error(title(key) + " " + res.data.json.errors[key]);
              });
            }
            this.props.closeModal();
          }
        })
    } else {
      this.props.closeModal();
    }
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    if (this.state.photo) {
      const attrData = new FormData();
      attrData.append(["id"], this.props.userId);
      attrData.append(["photo"], this.state.photo[0]);
      axios
        .post(`/users/change_photo${this.props.userId}`, attrData)
        .then(res => {
          if (res.data.json.success) {
            this.props.updatePhoto();
            toast.success("Updated Successfully");
            this.props.closeModal();
          } else {
            if ("error" in res.data.json) {
              toast.error(res.data.json.error)
            }
            if ("errors" in res.data.json) {
              Object.keys(res.data.json.errors).forEach(key => {
                toast.error(title(key) + " " + res.data.json.errors[key]);
              });
            }
            this.props.closeModal();
          }
        })
        .catch(err => err);
    } else {
      toast.error("Please select a Photo")
    }
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <input
          type="file"
          className="w-100 border"
          style={{
            height: 34,
            padding: 2,
            overflow: "hidden"
          }}
          onChange={e => this.handleChange(e.target.files)}
        />
        <div className="row mt-10 justify-content-center">
          <FormButton disabled={this.state.disabled} 
            style={{ cursor: this.state.disabled ? "no-drop" : "pointer" }} >Update</FormButton>
          <Button
            type="danger"
            className="ml-2"
            clicked={this.deletePhotoHandler}
            disabled={this.state.disableRemovePhoto}
            style={{ cursor: this.state.disableRemovePhoto ? "no-drop" : "pointer" }}
          >
            Remove Photo
          </Button>
          <Button
            type="secondary"
            className="ml-2"
            clicked={this.props.closeModal}
          >
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}
export default EditPhoto;
