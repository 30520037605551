import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import dateFns from "date-fns";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Button from "../UI/button/Button";

class MineToApprove extends Component {
  signal = axios.CancelToken.source();
  state = {
    volunteerTickets: [],
    loading: true
  };

  componentDidMount() {
    this.getVolunteerTickets();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { formattedStart, formattedEnd } = this.props.formattedDates

    const updatedStartDate = prevProps.formattedDates.formattedStart !== formattedStart;
    const updatedEndDate = prevProps.formattedDates.formattedEnd !== formattedEnd;

    if (updatedStartDate || updatedEndDate) {
      this.getVolunteerTickets()
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getVolunteerTickets = () => {
    const { formattedStart, formattedEnd } = this.props.formattedDates
    const params = { filter: 'mine' }

    if (formattedStart) params['start_date'] = formattedStart
    if (formattedEnd) params['end_date'] = formattedEnd

    axios
      .get("/volunteer_tickets/", { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          volunteerTickets: res.data.volunteer_tickets,
          loading: false,
        })
      })
  };

  approve = id => {
    axios
      .post("/volunteer_tickets/approve", {
        id: id
      })
      .then(res => {
        if (res.data.json.success) {
          toast.success("Successfully approved");
        } else {
          toast.error("Approve unsuccessful");
        }
        this.getVolunteerTickets();
      });
  };

  decline = id => {
    axios
      .post("/volunteer_tickets/reject", {
        id: id
      })
      .then(res => {
        if (res.data.json.success) {
          toast.success("Successfully declined");
        } else {
          toast.error("Decline unsuccessful");
        }
        this.getVolunteerTickets();
      });
  };

  render() {
    const pendingList = this.state.volunteerTickets;
    let volunteerTicketsContent;
    if (this.state.loading) {
      volunteerTicketsContent = <Spinner />;
    } else {
      volunteerTicketsContent = pendingList.map(item => (
        <li className="list-group-item border-top rounded-0 pt-2 px-3 pb-0">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-12 mt-1">
                  <Link to={`/users/${item.user.id}/volunteer`} className="text-info">
                    <h5>{item.user.login}/{item.user.full_name}</h5>
                  </Link>
                </div>
                <div className="col-lg-12">{item.description}</div>

                <div className="col-lg-12">
                  {dateFns.format(item.date_completed, "MMMM DD YYYY")}
                </div>
                <div className="col-lg-12 mt-1">
                  {item.status === 0 ? (
                    <p className="text-danger">Pending Approval</p>
                  ) : item.status === 1 ? (
                    <Button type="success">Approved</Button>
                  ) : (
                    <Button type="danger">Not Approved</Button>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-3 d-flex justify-content-center align-items-center">
              <div className="row">
                <div className="col-lg-6 d-flex justify-content-center align-items-center">
                  <div className="badge bg-warning py-2 px-3">{item.volunteer_hours} hours</div>
                </div>
                <div className="col-lg-3 d-flex justify-content-center align-items-center">
                  <Button clicked={() => this.approve(item.id)} type="success" className="rounded-circle">
                    <i className="fas fa-check" />
                  </Button>
                </div>
                <div className="col-lg-3 d-flex justify-content-center align-items-center">
                  <Button clicked={() => this.decline(item.id)} type="danger" className="rounded-circle">
                    <i className="fas fa-times" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </li>
      ));
    }
    return (
      <React.Fragment>
        <ul className="list-group list-group-flush webkit-fill-available">
          {volunteerTicketsContent}
        </ul>
      </React.Fragment>
    );
  }
}

export default MineToApprove;
