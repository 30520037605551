const formControls = {
  textInput: {
    organization: "user_organization_name",
    firstName: "first_name", //
    lastName: "last_name", //
    role: "role_id", //
    memberType: "member_type_id", //
    membershipDues: "membership_dues",
    volunteerHours: "volunteer_hours_required_per_year",

    address1: "address1", //
    address2: "address2", //
    specializations: "specializations", //
    city: "city",
    province: "province",
    country: "country",
    postCode: "postal_code",
    phone: "home_phone",
    mobile: "mobile_phone",
    email: "email",
    alternateEmail: "alternate_email", //
    website: "website", //
    jobDescription: "job_position", //
    bio: "bio_link", //
    twitter: "twitter", //
    instagram: "instagram", //
    facebook: "facebook",
    pronouns: "pronouns",
    dateOfBirth: "date_of_birth",

    notes: "notes",
    uniqueName: "unique_name",
  },
  checkboxes: {
    can_rent_equipment: "can_rent_equipment",
    can_book_facilities: "can_book_facilities",
    can_register_in_programs: "can_register_in_programs",
    gets_member_rates: "gets_member_rates",
    is_on_mailing_list: "is_on_mailing_list",
    gets_insurance_coverage: "gets_insurance_coverage",
    allow_booking: "allow_booking",
    allow_registration: "allow_registration",
    jury_member: "jury_member",
  },
}

// friend_since

export default formControls
