import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import dateFns from "date-fns";

import Button from "../../UI/button/Button";
import Input from "../../UI/input/Input";
import FormButton from "../../UI/button/FormButton";
import DatePicker from "../../UI/datePicker";
import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";

import "./Projects.css";

class NewContest extends Component {
  signal = axios.CancelToken.source();
  state = {
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true
      },
      label: "",
      value: ""
    },
    startDate: new Date(),
    endDate: new Date(),
    loading: false,
    disabled: false
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setState({ ...this.state, loading: true })
      axios
        .get(`/contests/${this.props.id}`, { cancelToken: this.signal.token })
        .then(res => {
          this.setState({
            name: {
              ...this.state.name,
              value: res.data.json.data.name
            },
            startDate: new Date(res.data.json.data.start_date),
            endDate: new Date(res.data.json.data.end_date),
            loading: false
          });
        })
        .catch(err => err);
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    if (key === "name") {
      inputValue["value"] = event.target.value;
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const dateFormatString = "YYYY-MM-DD HH:mm:ss"
    const contestInfo = {
      name: this.state.name.value,
      start_date: this.state.startDate
        ? dateFns.format(this.state.startDate, dateFormatString)
        : "",
      end_date: this.state.endDate
        ? dateFns.format(this.state.endDate, dateFormatString)
        : ""
    };
    if (this.props.edit) {
      axios
        .put(`/contests/${this.props.id}`, contestInfo)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Contest edited successfully");
            this.setState({ disabled: false });
            this.props.showContests();
            this.props.getContests();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
            this.setState({ disabled: false })
          }
        })
        .catch(err => err);
    } else {
      axios
        .post("/contests", contestInfo)
        .then(res => {
          if (res.data.json.success) {
            toast.success("New contest created successfully");
            this.setState({ disabled: false })
            this.props.showContests();
            this.props.getContests();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
            this.setState({ disabled: false })
          }
        })
        .catch(err => err);
    }
  };

  render() {
    return (
      <div className="new-contest p-4">
        {this.props.edit ? (
          <h5 className="font-weight-bold">EDIT CONTEST</h5>
        ) : (
            <h5 className="font-weight-bold">CREATE CONTEST</h5>
          )}
        {
          this.state.loading ? (
            <Spinner />
          ) : (
              <form onSubmit={this.submitHandler}>
                <div className="row mt-4">
                  <div className="col-lg-2">Name</div>
                  <div className="col-lg-5">
                    <Input
                      {...this.state.name}
                      disabled={this.props.edit ? true : false}
                      changed={event => this.inputChangedHandler(event, "name")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-2">Start Date</div>
                  <div className="col-lg-5">
                    <DatePicker
                      selected={this.state.startDate}
                      required={true}
                      changed={date => this.setState({ startDate: date })}
                      showTimeSelect
                      timeIntervals={1}
                      timeFormat="HH:mm"
                      dateFormat="yyyy.MM.dd h:mm aa"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-2">End Date</div>
                  <div className="col-lg-5">
                    <DatePicker
                      selected={this.state.endDate}
                      required={true}
                      changed={date => this.setState({ endDate: date })}
                      showTimeSelect
                      timeIntervals={1}
                      timeFormat="HH:mm"
                      dateFormat="yyyy.MM.dd h:mm aa"
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <Button
                      type="secondary"
                      className="mr-3"
                      clicked={() => this.props.showContests()}
                    >
                      Cancel
                    </Button>
                    <FormButton disabled={this.state.disabled}>Save</FormButton>
                  </div>
                </div>
              </form>
            )
        }
      </div>
    );
  }
}

export default withRouter(NewContest);
