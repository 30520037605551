import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import ReportErrorModal from "./ReportErrorModal";
import ErrorContentWrapper from "./ErrorContentWrapper";
import NotFound from "./NotFound";

const ErrorPage = props => {
  const [showModal, setShowModal] = useState(false)

  return <>
    <ErrorContentWrapper {...props}>
      {props.match.params.code == '404' ?
        <NotFound history={props.history} /> : <>
          <h4>OOPS! AN ERROR HAS APPEARED</h4>
          <p className='not-found-text'>Something went wrong! Help us improve your experience by sending an error report.</p>
          <div className="d-flex flex-row">
            <p
              className='error-back-link cursor'
              onClick={() => props.history.goBack()}
            >Go Back</p>
            <p className='mx-3'>|</p>
            <p
              className='error-back-link cursor'
              onClick={() => setShowModal(true)}
            >Report Issue</p>
          </div>
        </>
      }
    </ErrorContentWrapper>
    <ReportErrorModal setShowModal={setShowModal} showModal={showModal} history={props.history}/>
  </>
};

export default withRouter(ErrorPage);
