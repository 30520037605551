import React, { Component } from "react"
import classnames from "classnames"
import { OverlayTrigger, Popover } from "react-bootstrap"

import ToggleSwitch from "../common/toggleSwitch"
import VideoPlayer from "../videoPlayer/VideoPlayer"

import MP3Icon from "../../assests/images/mp3Icon.jpeg"
import VideoIcon from "../../assests/images/videoIcon.png"
import NoImage from "../../assests/images/no-image.png"

class MediaCard extends Component {
  state = {
    showPlayer: false,
    mediaSource: null,
    player: "htmlPlayer"
  }

  componentDidMount = () => {
    this.updatePlayerSettings()
  }

  componentDidUpdate(prevProps, prevState) {
    // if the share button is toggled, update the player settings
    if (prevProps.attr.share !== this.props.attr.share) {
      this.updatePlayerSettings()
    }
  }

  togglePlayer = () => {
    window.scrollTo(0, 0)
    this.setState({ ...this.state, showPlayer: !this.state.showPlayer })
  }

  updatePlayerSettings() {
    const { file_attachment, share, value_4 } = this.props.attr

    if (share && value_4) {
      this.setState({ ...this.state, mediaSource: value_4, player: "jwPlayer" })
    } else {
      this.setState({ ...this.state, mediaSource: file_attachment, player: "htmlPlayer" })
    }
  }

  renderProjectImage(attr) {
    const { project_attribute_type_name, file_attachment_thumbnail, file_attachment } = attr

    if (["Photo", "Graphic", "Image"].includes(project_attribute_type_name)) {
      return (
        <a
          href={file_attachment}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="card-img-top bg-cover h-30vh"
            style={{
              backgroundImage: `url(${file_attachment_thumbnail || NoImage})`,
            }}
          />
        </a>
      )
    }

    if (project_attribute_type_name === "Video") {
      return (
        <div
          className="card-img-top bg-cover h-30vh"
          onClick={this.togglePlayer}
          style={{ backgroundImage: `url(${file_attachment_thumbnail || VideoIcon})`, cursor: "pointer" }}
        />
      )
    }

    if (project_attribute_type_name === "Audio") {
      return (
        <div
          className="card-img-top bg-cover h-30vh"
          onClick={this.togglePlayer}
          style={{ backgroundImage: `url(${file_attachment_thumbnail || MP3Icon})`, cursor: "pointer" }}
        />
      )
    }

    if (project_attribute_type_name === "PDF") {
      return (
        <a href={file_attachment}>
          <div
            className="card-img-top bg-cover h-30vh"
            style={{ backgroundImage: `url(${file_attachment || NoImage})` }}
          />
        </a>
      )
    }
    //default
    return (
      <a href={file_attachment}>
        <div
          className="bg-cover"
          style={{
            width: "auto",
            height: "30vh",
            backgroundImage: `url(${NoImage})`,
          }}
        />
      </a>
    )
  }

  renderMediaPopover = (props, filename, mediaDescription, index) => {
    return (
      <Popover
        id={'pop' + index}
        style={{ width: 250, fontFamily: 'Montserrat, sans-serif' }}
        {...props}
      >
        <>
          <div className="font-15 font-weight-bold mb-0" style={{ color: "#666666"}}>
            Media Details
          </div>
          <div className="border mt-0 mb-2" />
          <div className="mb-1">
            Media Description:
          </div>
          <div className="text-muted pb-3" style={{ wordBreak: "break-word" }}>
            {mediaDescription}
          </div>
          <div className="mb-1">
            File Name:
          </div>
          <div className="text-muted pb-2">
            {filename}
          </div>
        </>
      </Popover>
    )
  }

  render() {
    const {index, attr, canDelete} = this.props
    
    const mediaDescription = attr.media_description
    const filename = attr.get_filename
    const fileExtension = attr.file_extension

    return (
      <div className="col-md-4 my-3">
        <div className="card border">
          {this.renderProjectImage(attr)}
          <div className="card-body p-0">
            {this.props.canEditSetting ? (
              <div className="card-text text-right">
                <a
                  download
                  className="bg-primary position-relative py-1 px-2"
                  href={attr.file_attachment}
                  style={{
                    bottom: "60px",
                    right: "-28px",
                  }}
                  target="_blank"
                >
                  <i className="fas fa-download text-light"/>
                </a>
                <span
                  className="bg-primary position-relative cursor py-1 px-2"
                  onClick={(e) => {
                    if (canDelete)
                      this.props.deleteHandler(e, this.props.attr.id)
                  }}
                  style={{
                    bottom: "24px",
                  }}
                >
                  <i
                    className={classnames("fas fa-trash text-light", {
                      cursor: canDelete,
                      "cursor-not-allowed element-disabled": !canDelete,
                    })}
                  />
                </span>
              </div>
            ) : null}
          </div>
          <div className="card-body bg-light">
            <div className="card-text text-center font-weight-bold">
              {attr.project_attribute_type_name === "Video" ? (
                <div
                  className="text-center cursor text-primary"
                >
                  <p className="text-center mb-1" onClick={this.togglePlayer}>
                  </p>
                  <div className="text-primary mb-2">
                    <OverlayTrigger
                      delay={250}
                      overlay={this.renderMediaPopover(null, filename, mediaDescription, index)}
                      filename={filename}
                      placement="right"
                      trigger="hover"
                    >
                      <div className='d-flex justify-content-center align-items-baseline'>
                        <i className="fas fa-info-circle mr-1"/>
                        <p className='text-uppercase fs-6'>{fileExtension}</p>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              ) : (
                <a
                  href={attr.file_attachment ? attr.file_attachment : attr.value_2}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor text-primary"
                >
                  <div className="text-primary mb-2">
                    <OverlayTrigger
                      key={index}
                      delay={250}
                      overlay={this.renderMediaPopover(null, filename, mediaDescription, index)}
                      placement="right"
                      trigger="hover"
                    >
                      <div className='d-flex justify-content-center align-items-baseline'>
                        <i className="fas fa-info-circle mr-1"/>
                        <p className='text-uppercase fs-6'>{fileExtension}</p>
                      </div>
                    </OverlayTrigger>
                  </div>
                </a>
              )}
              { (this.props.canEditSetting && this.props.showShareMedia) && (
                <div>
                  <label htmlFor={`toggle-${index}`} className="mr-2 w-100">
                    Share Media
                  </label>
                  <ToggleSwitch
                    id={`toggle-${index}`}
                    className="custom-control-input"
                    disabled={!this.props.canEditSetting}
                    checked={attr.share}
                    onChange={(checked) =>
                      !this.state.disabled &&
                      this.props.updateHandler(
                        index,
                        attr.id,
                        {
                          share: checked,
                          project_attribute_type_id: attr.project_attribute_type_id,
                          value: this.props.attr.value
                        }
                      )
                    }
                    customCheckContent="YES"
                    customUncheckContent="NO"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <VideoPlayer
          show={this.state.showPlayer}
          src={this.state.mediaSource}
          player={this.state.player}
          togglePlayer={this.togglePlayer}
        />
      </div>
    )
  }
}

export default MediaCard
