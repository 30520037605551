import React from "react";
import { Link } from "react-router-dom";
import NoImage from "../../../assests/images/no-image.png";

const Project = props => {
  const { project } = props;
  const image = project.thumbnail ? project.thumbnail : NoImage;
  return (
    <li className="list-group-item p-3">
      <div className="row">
        <div className="col-3">
          <div className="rounded-circle mx-auto bg-cover" style={{
            width: 60,
            height: 60,
            backgroundImage: `url(${image})`
          }} />
        </div>
        <div className="col-9">
          <div>
            <Link to={`projects/${project.slug}`} className="text-info">
              {project.name}
            </Link><br />
            <span>{`by ${project.creator}`}{project.type && `, ${project.type}`} </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Project;
