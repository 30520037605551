import React, { Component } from "react";
import classNames from "classnames";

import TicketItems from "./TicketItems";
import AssetItems from "./AssetItems";

import "./index.css";

class CheckedOut extends Component {
  state = {
    activeTab: "checked_out"
  };

  render() {
    const { activeTab } = this.state;
    const tabNames = ["checked_out", "ticket_builder", "being_serviced"];
    return (
      <div className="currently-out">
        <div className="row mt-3 mb-2 py-4">
          <div className="col-12">
            <nav aria-label="breadcrumb" className="px-3">
              <ol className="breadcrumb mb-0 pl-3 bg-white">
                {tabNames.map((tab, index) => (
                  <li
                    key={index}
                    className={classNames(
                      "breadcrumb-item cursor text-capitalize",{
                        "text-primary": activeTab === tab
                      })}
                    onClick={() => this.setState({ activeTab: tab })}
                  >
                    {tab.replace("_", " ")}
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
        {this.state.activeTab === "ticket_builder"
          ? (<div className="m-4 mt-5">
            <h5 className="text-dark">
              Tickets currently in the Ticket Builder.
          </h5>
            <p>
              {
                "There should not be any Tickets in the Ticket Builder unless they are currently being created, or have been saved to be completed later. You can destroy stale Tickets either by clicking 'Clear It!' below, or going to the user's 'Book Equipment & Facilities' page to destroy the Ticket manually."
              }
            </p>
            <div><TicketItems /></div>
          </div>)
          : (<div>
            <AssetItems
              activeTab={this.state.activeTab}
              key={this.state.activeTab}
            />
          </div>)}
      </div>
    );
  }
}

export default CheckedOut;
