import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { title } from "change-case"
import classnames from "classnames"
import { isEmpty, uniqBy } from "lodash"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import dateFns from "date-fns"

import LabelSection from "./LabelSection";
import RegistrationModal from "./RegistrationsModal"
import RecordNotes from "./RecordNotes"

import axios from "../../configAxios"
import Modal from "../UI/Modal"
import Button from "../UI/button/Button"
import FormButton from "../UI/button/FormButton"
import Spinner from "../UI/ModalSpinner"

import "./Sidebar.css"

const CustomTooltip = (props) => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip>{props.tooltipData}</Tooltip>}
  >
    {props.children}
  </OverlayTrigger>
)

class Sidebar extends Component {
  signal = axios.CancelToken.source()
  state = {
    reservedAssets: [],
    totalCount: null,
    showRegnModal: false,
    registrations: [],
    cancelRegnModal: false,
    showFileModal: false,
    fileAttachLink: "",
    fileModalContent: null,
    selectedFile: null,
    fileUploadRegId: null,
    id: null,
    disableUpload: false,
    showNoteModal: false,
  }

  componentDidMount() {
    this.getAssets()
    this.regUsers()
  }

  regUsers = () => {
    axios
      .get(`/registrations?program_id=${this.props.match.params.id}`, {
        cancelToken: this.signal.token,
      })
      .then(res => {
        const uniqRegistrations = uniqBy(res.data.registrations,
          (r) => r.invoice_registrations.invoice_id
        )
        this.setState({ registrations: uniqRegistrations })
      })
      .catch(error => error)
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getAssets = () => {
    axios
      .get("/programs/reserved_assets", {
        params: {
          id: this.props.match.params.id,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const reservedAssets = []
        for (const key in res.data.programs) {
          reservedAssets.push({ ...res.data.programs[key] })
        }
        const totalCount = res.data.meta.total_count

        this.setState({ reservedAssets, totalCount })
      })
  }

  reservationHandler = () => {
    this.props.history.push(`/programs/${this.props.match.params.id}/bookings`)
  }

  deleteRegistrationHandler = (id) => {
    const response = window.confirm(
      "Are you sure you want to delete registration?"
    )
    if (response) {
      axios
        .delete(`programs/${this.props.program.id}/registrations`, {
          params: {
            registration_id: id,
          },
        })
        .then((res) => {
          if (res.data.json.success) {
            this.regUsers()
            toast.success("Successfully deleted.")
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
          }
        })
    }
  }

  attachmentHandler = (registration) => {
    const { file_attachment, id: regId } = registration
    if (file_attachment && file_attachment.file && file_attachment.file.url)
      window.open(file_attachment.file.url, "_blank")
    else
      this.setState({
        fileModalContent: true,
        showFileModal: true,
        fileUploadRegId: regId,
      })
  }

  fileChangeHandler = (e) => {
    if (!isEmpty(e.target) && !isEmpty(e.target.files)) {
      if (e.target.files[0].type === "application/pdf")
        this.setState({ selectedFile: e.target.files[0] })
      else {
        toast.error(
          `${e.target.files[0].type} not allowed. only PDF format files allowed`
        )
        this.setState({ selectedFile: null })
      }
    } else {
      this.setState({ selectedFile: null })
    }
  }

  fileUploadHandler = (e) => {
    e.preventDefault()

    const { fileUploadRegId: regId, selectedFile } = this.state
    const formData = new FormData()
    formData.append("file_attachment", selectedFile)

    this.setState({ disableUpload: true })

    axios.put(`/registrations/${regId}`, formData).then((res) => {
      if (res.data.json.success) {
        toast.success("Successfully upload the file")
        this.regUsers()
        this.fileModalCloseHandler()
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach((error) => {
            toast.error(`${title(error)} ${res.data.json.errors[error]}`)
          })
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error)
        }
        this.setState({ disableUpload: false })
      }
    })
  }

  deleteFileHandler = (registration) => {
    const confirmation = window.confirm(
      "Are you sure, you want to delete the file attachment?"
    )
    if (confirmation) {
      axios
        .delete("/registrations/delete_attachment", {
          params: {
            id: registration.id,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          if (res.data.json.success) {
            this.regUsers()
            toast.success("File Attachment successfully removed")
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(`${title(error)} ${res.data.json.errors[error]}`)
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
          }
        })
    }
  }

  fileModalCloseHandler = () => {
    this.setState({
      showFileModal: false,
      selectedFile: null,
      disableUpload: false,
      fileUploadRegId: null,
    })
  }

  checkFileExistence = (registration) =>
    registration.file_attachment &&
    registration.file_attachment.file &&
    registration.file_attachment.file.url


  render() {
    const { program, policyRes, loadSidebar } = this.props

    return (
      <div className="sidebar">
        <LabelSection programId={this.props.match.params.id}/>
        {policyRes && policyRes.can_add_registration && (
          <div className="card mt-3">
            <div className="card-body">
              <div className="card-text">
                <div className="row border-bottom">
                  <div className="col-lg-7">
                    <h5 className="pb-2">Registrations</h5>
                  </div>
                  {program && (program.is_active || program.is_draft) && (
                    <div className="col-lg-5 text-right">
                      <h6
                        className="text-danger cursor pt-1"
                        onClick={() => {
                          window.scrollTo(0, 0)
                          this.setState({ showRegnModal: true })
                        }}
                      >
                        Add
                      </h6>
                    </div>
                  )}
                </div>
                {this.state.registrations.map((registration) => (
                  <div key={registration.id} className="row border-bottom">
                    <div className="col-7">
                      <h6 className="text-primary mt-3">
                        {registration.user_id ? (
                          <Link
                            to={`/users/${registration.user_id}/summary`}
                            className="text-info"
                          >
                            {registration.cache.full_name_cached} {`(${registration.invoice_registrations.total_registrations})`}
                          </Link>
                        ) : (
                          <Link
                            to={`/invoices/${registration.registrant_details.invoice.id}`}
                            className="text-info"
                          >
                            {registration.registrant_details.user_first_name} {registration.registrant_details.user_last_name} {`(${registration.invoice_registrations.total_registrations})`}
                          </Link>
                        )}
                        {program && program.allow_media_upload && (
                          <>
                            <i
                              className={classnames(
                                "fas text-secondary ml-2 cursor",
                                {
                                  "fa-download": this.checkFileExistence(
                                    registration
                                  ),
                                },
                                {
                                  "fa-paperclip": !this.checkFileExistence(
                                    registration
                                  ),
                                }
                              )}
                              onClick={(e) =>
                                this.attachmentHandler(registration)
                              }
                            />
                            {this.checkFileExistence(registration) && (
                              <CustomTooltip tooltipData="Delete attached file">
                                <i
                                  className="fas fa-trash ml-1 cursor file-attch-delete"
                                  onClick={(e) =>
                                    this.deleteFileHandler(registration)
                                  }
                                />
                              </CustomTooltip>
                            )}
                          </>
                        )}
                      </h6>
                      <p>
                        {dateFns.format(registration.created_at, "YYYY.MM.DD")}
                      </p>
                    </div>
                    <div className="col-3 d-flex justify-content-center align-items-center">
                      {registration.is_invoice_paid ? (
                        <div className="badge badge-success px-3 py-2">
                          Paid
                        </div>
                      ) : (
                        <div className="badge badge-danger px-3 py-2">
                          Not Paid
                        </div>
                      )}
                    </div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <CustomTooltip tooltipData="Delete registration">
                        <span
                          className="cursor float-right"
                          onClick={() =>
                            this.deleteRegistrationHandler(registration.id)
                          }
                        >
                          <i className="fas fa-trash cursor text-danger" />
                        </span>
                      </CustomTooltip>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {policyRes && policyRes.can_create && (
          <div className="card mt-3">
            <div className="card-body">
              <div className="card-text">
                <div className="row border-bottom">
                  <div className="col-9">
                    <h5 className="text-nowrap">Record Notes</h5>
                  </div>
                  <div className="col-3">
                    <h6
                      onClick={() => {
                        this.setState({
                          showNoteModal: true,
                          editNotes: Boolean(program.program_notes),
                        })
                      }}
                      className="text-danger text-right cursor"
                    >
                      {program && !loadSidebar
                        ? program.program_notes
                          ? "Edit"
                          : "Add"
                        : null}
                    </h6>
                  </div>
                </div>
                {loadSidebar ? (
                  <Spinner />
                ) : (
                  <>
                    {program && program.program_notes && (
                      <div className="row">
                        <div className="col">
                          <p className="py-2 m-0">{program.program_notes}</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {policyRes && policyRes.can_reserve_assets && (
          <div className="card mt-3">
            <div className="card-body">
              <div className="card-text">
                <div className="row border-bottom">
                  <div className="col-9">
                    <h5 className="text-nowrap">Asset Reservations</h5>
                  </div>
                  {program && (program.is_active || program.is_draft) && (
                    <div className="col-3">
                      <h6
                        className="text-danger text-right cursor"
                        onClick={this.reservationHandler}
                      >
                        {this.state.totalCount > 0 ? "Edit" : "Add"}
                      </h6>
                    </div>
                  )}
                </div>

                {this.state.reservedAssets.map((obj) => (
                  <div className="row border-bottom" key={obj.id}>
                    <div className="col-12">
                      <div className="py-3">
                        <div>
                          <Link
                            to={`/assets/${obj.asset.id}`}
                            className="text-info"
                          >
                            {obj.asset.name}
                          </Link>
                        </div>
                        {obj.asset.type === "Facility"
                          ? `${dateFns.format(
                              new Date(obj.details.day),
                              "YYYY.MM.DD"
                            )}: 
                            ${dateFns.format(obj.details.start_time, "hh:mmA ")}
                            to ${dateFns.format(
                              obj.details.end_time,
                              "hh:mmA"
                            )}`
                          : `${dateFns.format(
                              new Date(obj.details.start_time),
                              "YYYY.MM.DD"
                            )} to 
                            ${dateFns.format(
                              new Date(obj.details.end_time),
                              "YYYY.MM.DD"
                            )}`}
                      </div>
                    </div>
                  </div>
                ))}

                {policyRes &&
                  policyRes.can_view_invoice &&
                  (this.state.totalCount && this.props.invoiceId !== null ? (
                    <h6 className="mt-3 mb-0 text-center">
                      <Link
                        to={`/invoices/${this.props.invoiceId}`}
                        className="text-info"
                      >
                        View Invoice
                      </Link>
                    </h6>
                  ) : null)}
              </div>
            </div>
          </div>
        )}
        <Modal
          title="Register for this Program"
          show={this.state.showRegnModal}
          closeButton
          closeModal={() => this.setState({ showRegnModal: false })}
          hide={() => this.setState({ showRegnModal: false })}
          flag={true}
        >
          <RegistrationModal
            regUsers={() => this.regUsers()}
            programId={this.props.programId}
            closeModal={() => this.setState({ showRegnModal: false })}
          />
        </Modal>
        <Modal
          {...(this.state.fileModalContent && { title: "Upload A File" })}
          show={this.state.showFileModal}
          closeButton
          closeModal={this.fileModalCloseHandler}
          hide={this.fileModalCloseHandler}
          flag={true}
        >
          <form
            onSubmit={this.fileUploadHandler}
            className="mt-3 file-uploader custom-text-wrapper"
          >
            <div className="mt-4">
              <div className="border file-border-secondary p-2">
                <input
                  onChange={this.fileChangeHandler}
                  type="file"
                  accept="application/pdf"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                />
              </div>
            </div>
            <div className="text-center mt-3">
              <Button
                type="secondary"
                className="mr-3"
                clicked={this.fileModalCloseHandler}
              >
                Cancel
              </Button>
              <FormButton
                disabled={this.state.disableUpload || !this.state.selectedFile}
              >
                Submit
              </FormButton>
            </div>
          </form>
        </Modal>
        <Modal
          title={`${
            program && !loadSidebar && (program.program_notes ? "Edit" : "Add")
          } Record Notes`}
          show={this.state.showNoteModal}
          closeButton
          closeModal={() => this.setState({ showNoteModal: false })}
          hide={() => this.setState({ showNoteModal: false })}
        >
          <RecordNotes
            getProgramAndPolicy={this.props.getProgramAndPolicy}
            programId={this.props.programId}
            editNotes={this.state.editNotes}
            closeModal={() => this.setState({ showNoteModal: false })}
          />
        </Modal>
      </div>
    )
  }
}

export default withRouter(Sidebar)
