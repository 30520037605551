import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from "../../../configAxios";
import Spinner from "../../../assests/images/spinner.gif";

import FlagItem from "./FlagItem";
import CardHeader from "../CardHeader";

class NewFlags extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: false,
    flags: []
  };

  componentDidMount() {
    this.setState({ ...this.state, loading: true });
    let params = {
      sort: true,
      per_page: 5,
      order: {
        column: "created_at",
        dir: "desc"
      }
    };
    axios
      .get(`/flags`, { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({ flags: res.data.flags, loading: false });
        this.props.updateGrid();
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  deleteFlag = id => {
    let params = {
      id: id
    };
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      axios
        .delete("/flags", { params })
        .then(res => {
          if (res.data.json.success) {
            toast.success("Flag deleted successfully.");
            this.setState({
              ...this.state,
              flags: this.state.flags.filter(flag => flag.id !== id)
            });
            this.props.updateGrid();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  };

  render() {
    const spinner = (
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    );
    return (
      <div className="dashboard">
        <div className="card">
          {this.state.loading ? (
            <div className="card-body p-0">
              <div className="card-text">
                <div className="spinner">{spinner}</div>
              </div>
            </div>
          ) : (
              <div>
                <CardHeader
                  title={"New Flags"}
                  icon="fa-flag"
                  collapse="newflags"
                  updateGrid={this.props.updateGrid}
                  removeModule={this.props.removeModule}
                  name={this.props.name}
                />
                <div className="dashboard-card collapse show" id="newflags">
                  <div className="card-body p-0">
                    <ul className="list-group">
                      {this.state.flags.map(flag => (
                        <FlagItem
                          key={flag.id}
                          flag={flag}
                          deleteFlag={this.deleteFlag}
                        />
                      ))}
                    </ul>
                    <div className="p-2 text-center cursor card-text" style={{ height: "40px" }}>
                      <Link to="/flags" className="text-info">View All</Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(NewFlags);
