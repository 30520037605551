import React, { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import axios from "../../../../configAxios"
import errorHandler from "../../../errorPages/UserNotAuthorized"
import Input from "../../../UI/input/Input"
import Spinner from "../../../UI/Spinner"
import FormButton from "../../../UI/button/FormButton"
import ProjectAttributes from "./ProjectAttributes"

class EditReelItem extends Component {
  signal = axios.CancelToken.source()
  state = {
    defaultReelName: "",
    reelSharing: "",
    featured: false,
    selectedProjects: [],
    reelName: {
      elementType: "input",
      elementConfig: {
        name: "reelName",
        type: "text",
        required: true,
      },
      label: "Name",
      value: "",
    },
    sharing: {
      elementType: "select",
      elementConfig: {
        name: "reelSharing",
        options: [],
        required: true,
        isLoading: true,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
      label: "Sharing",
    },
    reelProjects: {
      elementType: "asyncSelect",
      elementConfig: {
        name: "projects",
        placeholder: "Enter Projects here",
        inputLength: 1,
        loadOptions: (inputValue, callback) => {
          if (inputValue.length > 0)
            this.searchProjectsByName(inputValue, callback)
        },
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: [],
      label: "Reel Projects",
      value: null,
    },
    // projectsID: [],
    loading: true,
    isAdmin: false,
    disabled: true,
  }

  async componentDidMount() {
    let sharings = []
    const res = await axios.get("/reel_project_attribute/formdata", {
      cancelToken: this.signal.token,
    })
    const sharingArray = res.data.json.sharings
    const isAdmin = res.data.json.is_admin
    sharings = sharingArray.map((item) => {
      let displayName = ""
      if (item[0] === "me") {
        displayName = "Private"
      } else if (item[0] === "staff") {
        displayName = "Staff Only"
      } else if (item[0] === "everyone") {
        displayName = "Public"
      }
      const obj = {
        label: displayName,
        value: item[1],
        name: item[0],
      }
      return obj
    })
    this.setState({
      sharing: {
        ...this.state.sharing,
        elementConfig: {
          ...this.state.sharing.elementConfig,
          options: sharings,
          isLoading: false,
        },
      },
      isAdmin: isAdmin,
    })
    const reelData = await axios.get("/reels/" + this.props.match.params.id, {
      cancelToken: this.signal.token,
    })
    const reelName = reelData.data.json.data.name
    const reelSharing = reelData.data.json.data.sharing
    const featured = reelData.data.json.data.featured
    const selectedOption = sharings.filter(
      (sharing) => sharing.name === reelSharing.label
    )[0]
    this.setState({
      defaultReelName: reelName,
      reelSharing: reelSharing,
      reelName: {
        ...this.state.reelName,
        value: reelName,
      },
      sharing: {
        ...this.state.sharing,
        elementConfig: {
          ...this.state.sharing.elementConfig,
          defaultValue: selectedOption,
        },
        selectedOption,
      },
      featured: featured,
      /* projectsId: projectsId,
      reelProjects: {
        ...this.state.reelProjects,
        selectedOption: projectsId,
      }, */
    })
    this.getProjectsAttributes()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getProjectsAttributes = () => {
    const selectedProjects = []
    axios
      .get("reels/project_attributes?id=" + this.props.match.params.id, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        for (const key in res.data.reels) {
          selectedProjects.push({
            ...res.data.reels[key],
            id: key,
          })
        }

        const projectsId = res.data.reels.map((item) => ({
          label: item.full_name,
          value: item.id,
        }))
        this.setState({
          selectedProjects: selectedProjects,
          loading: false,
          disabled: false,
          reelProjects: {
            ...this.state.reelProjects,
            selectedOption: projectsId,
          },
        })
      })
  }

  deleteProjectAttributeHandler = (id) => {
    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      axios.delete("reel_project_attribute/" + id[0]).then((res) => {
        if (res.data.json.success) {
          this.getProjectsAttributes()
          toast.success("Successfully deleted")
        } else {
          errorHandler(res)
        }
      })
    }
  }

  deleteProjectHandler = (projectName, index) => {
    this.setState({
      reelProjects: {
        ...this.state.reelProjects,
        selectedOption: this.state.reelProjects.selectedOption.filter(
          (project) => project.label !== projectName
        ),
      },
    })
  }

  searchProjectsByName = (inputValue, callback) => {
    const queryParams = {
      params: {
        query_string: inputValue,
      },
      cancelToken: this.signal.token,
    }
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.getProjectsByName(queryParams, callback)
    }, 500)
  }

  getProjectsByName = async (queryParams, callback) => {
    await axios
      .get("/projects", queryParams)
      .then((res) => {
        if (res.status === 202) {
          errorHandler(res)
          callback(null)
        } else {
          const options = res.data.projects.filter(
            (project) => project.has_video
          )
          callback(
            options.map((project) => ({
              value: project.id,
              label: project.name,
            }))
          )
        }
      })
      .catch((err) => err)
  }

  updateReelAttributesHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const { reelName, sharing, isAdmin, featured, reelProjects } = this.state
    const updatedReelData = {}
    updatedReelData.id = this.props.match.params.id
    updatedReelData.name = reelName.value
    updatedReelData.sharing = sharing.selectedOption.value
    const projects = reelProjects.selectedOption
      .map((project) => project.value)
      .join(",")
    if (projects === "") {
      toast.error("Reel projects is required")
      this.setState({ disabled: false })
      return
    }
    updatedReelData.project_ids = projects
    if (isAdmin) {
      updatedReelData.featured = featured
    }
    console.log(updatedReelData)
    axios
      .put("/reels/" + this.props.match.params.id, updatedReelData)
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Reel has successfully updated")
          this.props.history.push("/reels")
        } else {
          errorHandler(res)
        }
        this.setState({ disabled: false })
      })

      .catch(() => toast.error("Failed to update"))
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {}
    if (key === "reelName") {
      inputValue.value = event.target.value
    }
    if (key === "reelProjects") {
      if (event.value !== undefined) {
        if (
          this.state[key].selectedOption.some((el) => el.value === event.value)
        ) {
          toast.error("Project is already added.")
        } else {
          inputValue.selectedOption = [...this.state[key].selectedOption, event]
        }
      }
    } else {
      inputValue.selectedOption = event
    }
    const updatedState = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState({ [key]: updatedState })
  }

  isCheckedHandler = () => {
    this.setState({ featured: !this.state.featured })
  }

  listItemClickedHandler = (projectSlug) => {
    this.props.history.push("/projects/" + projectSlug)
  }

  render() {
    const { reelProjects } = this.state
    let projectAttributes
    if (this.state.loading) {
      projectAttributes = <Spinner />
    } else {
      projectAttributes = (
        <ProjectAttributes
          selectedProjects={this.state.selectedProjects}
          listItemClickedHandler={this.listItemClickedHandler}
          deleteProjectAttributeHandler={this.deleteProjectAttributeHandler}
          id={this.props.match.params.id}
        />
      )
    }
    return (
      <div className="container edit-reel w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Reel - {this.state.defaultReelName}</h3>
          </div>
          <div className="col-6 text-right">
            <span
              className="cursor text-primary"
              onClick={() => this.props.history.goBack()}
            >
              {"<< Back"}
            </span>
          </div>
        </div>
        <div className="card">
          <div className="card-body p-0">
            <div className="card-text p-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="font-weight-bold">Edit Detail</h5>
                </div>
              </div>
              <form onSubmit={this.updateReelAttributesHandler}>
                <div className="row mt-3">
                  <div className="col-lg-5">
                    <Input
                      {...this.state.reelName}
                      changed={(event) =>
                        this.inputChangedHandler(event, "reelName")
                      }
                    />
                  </div>
                  <div className="col-lg-5">
                    <Input
                      {...this.state.sharing}
                      changed={(event) =>
                        this.inputChangedHandler(event, "sharing")
                      }
                    />
                  </div>
                  <div className="col-lg-2 d-flex justify-content-center align-items-center mt-2">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="isFeatured"
                        className="form-check-input"
                        onChange={this.isCheckedHandler}
                        checked={this.state.featured}
                      />
                      <label
                        htmlFor="isFeatured"
                        className="form-check-label cursor"
                      >
                        Featured
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <Input
                      {...reelProjects}
                      chart={this.state.projectCharacters}
                      changed={(event) =>
                        this.inputChangedHandler(event, "reelProjects")
                      }
                      onInputChange={(event) =>
                        this.setState({ projectCharacters: event })
                      }
                    />
                  </div>

                  <div className="col-lg-12 d-flex justify-content-start align-items-center mt-1">
                    <FormButton disabled={this.state.disabled}>
                      Update
                    </FormButton>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-text">{projectAttributes}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default EditReelItem
