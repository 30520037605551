import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import Input from "../../UI/input/Input";
import axios from "../../../configAxios";

import "./TagInput.css";

class TagInput extends Component {
  constructor(props) {
    super(props);
    this.cancelSearch = null;
    this.inputRef = React.createRef();
    this.containerRef = React.createRef();
    this.timeout = null;
    this.state = {
      tagName: "",
      tagList: [],
      tagNameList: [],
      isLoading: false,
      isFocused: false,
      hoveredIndex: -1,
    }
  }

  componentDidMount() {
    const { edit, tagName } = this.props;
    if (edit) {
      this.setState({ tagName });
    }
    if (window.PointerEvent) {
      window.addEventListener("pointerdown", this.handleOutsideClicks);
    } else {
      window.addEventListener("mousedown", this.handleOutsideClicks);
      window.addEventListener("touchstart", this.handleOutsideClicks);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tagList !== this.state.tagList) {
      if (this.state.tagList.length === 0) {
        this.setState({ hoveredIndex: -1 });
      }
      this.props.getTagList(
        this.state.tagList.map(tag => tag.name)
      );
    }
    if (prevState.isLoading !== this.state.isLoading) {
      this.props.setInputLoading(this.state.isLoading);
      this.setState({ hoveredIndex: -1 });
    }
    if (prevState.isFocused !== this.state.isFocused) {
      this.setState({ hoveredIndex: -1 });
    }
  }

  componentWillUnmount() {
    if (this.cancelSearch !== null)
      this.cancelSearch("Request is being cancelled");
    if (this.timeout)
      clearTimeout(this.timeout);
    if (window.PointerEvent) {
      window.removeEventListener("pointerdown", this.handleOutsideClicks);
    } else {
      window.removeEventListener("mousedown", this.handleOutsideClicks);
      window.removeEventListener("touchstart", this.handleOutsideClicks);
    }
  }

  handleOutsideClicks = (e) => {
    if (this.containerRef.current) {
      const clickedOutside = this.containerRef.current.contains(e.target);
      if (!clickedOutside) {
        this.setState({ isFocused: false });
      }
    }
  }

  inputChangeHandler = e => {
    const tagName = e.target.value;
    this.setState({
      tagName,
    }, () => {
      this.props.tagNameChanged(tagName);
      if ( tagName.length > 0 ) {
        this.getTagsByName(tagName);
      } else {
        if (this.cancelSearch !== null) {
          this.cancelSearch();
        }
        this.setState({ 
          tagList: [],
          isLoading: false,
        })
      }
    })
  }

  getTagsByName = (tagName) => {
    if (this.timeout) {
      if (this.cancelSearch !== null)
        this.cancelSearch();
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(
      () => this.searchTag(tagName),
      300
    );
  }

  searchTag = (tagName) => {
    const { CancelToken } = axios;
    this.setState({ isLoading: true });
    axios.get("/tags/search", {
      params: {
        query: tagName,
        type: this.props.tagType,
      },
      cancelToken: new CancelToken(cancel => {
        this.cancelSearch = cancel;
      }),
    }).then(res => {
      const { json: tags } = res.data;
      this.setState({
        tagList: Array.isArray(tags)
          ? tags
          : [],
        isLoading: false,
      })
    }).catch(err => {
      if (axios.isCancel(err));
    });
  }

  keyDownHandler = e => {
    const { formRef } = this.props;
    if (
      e.key === "Enter" &&
      e.keyCode !== 299 &&
      this.inputRef.current &&  
      typeof this.inputRef.current.blur === "function"
    ) {
      this.inputRef.current.blur();
      this.inputRef.current.reportValidity();
      this.setState({ isFocused: false });
      if (
        !this.state.isLoading &&
        this.state.tagName &&
        formRef && formRef.current &&
        typeof formRef.current.dispatchEvent === "function"
      ) {
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true })
        )
      }
    }
  }

  itemSelectHandler = (tag) => {
    const { tagList } = this.state;
    const { tagNameChanged } = this.props;
    if (tagList.length > 1) {
      this.inputChangeHandler({ 
        target: { value: tag.name }
      })
    } else {
      this.setState({ 
        isFocused: false,
        tagName: tag.name, 
      }, () => tagNameChanged(tag.name));
    }
  }

  tagHoverHandler = hoveredIndex => {
    this.setState({ hoveredIndex });
  }

  renderLoadingIndicator = () => {
    const { isLoading } = this.state;
    
    if (!isLoading) return null;

    return (
      <div className="loading-icons position-absolute">
        <span className="loading-icon d-inline-block" />
        <span className="loading-icon d-inline-block" />
        <span className="loading-icon d-inline-block" />
      </div>
    )
  }

  renderTagList = () => {
    const {
      tagList,
      isFocused,
      isLoading,
      hoveredIndex,
    } = this.state;
    
    if (isFocused && isLoading) {
      return (
        <div className="tag-list w-100 border rounded position-absolute bg-white shadow">
          <div className="p-3 text-center">
            Loading...
          </div>
        </div>
      )
    }
    
    if (
      tagList.length === 0 ||
      !isFocused
    ) {
      return;
    }
    
    return (
      <div className="tag-list w-100 border rounded position-absolute bg-white shadow">
        {tagList.map((tag, index) => (
          <div
            key={tag.id}
            className={classNames(
              "tag-item cursor",
              { "border-top": index !== 0 }
            )}
            onClick={() => this.itemSelectHandler(tag)}
            onMouseOver={() => this.tagHoverHandler(index)}
            onMouseLeave={() => this.tagHoverHandler(-1)}
            onTouchStart={() => this.tagHoverHandler(index)}
          >
            <span
              className={classNames(
                "d-block tag-text px-2 py-3",
                { "tag-text-hover": hoveredIndex === index }
              )}
            >
              {tag.name}
            </span>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { tagType } = this.props;
    const { tagName, isLoading, isFocused } = this.state;

    const label = "label";

    return (
      <>
        <label htmlFor={`${label}-name`}>
          <span className="text-capitalize">{`${label} name`}</span>
        </label>
        <div
          className="tag-form-input position-relative"
          ref={this.containerRef}
        >
          <Input
            elementType="input"
            forwardRef={this.inputRef}
            elementConfig={{
              name: "name",
              id: `${label}-name`,
              type: "text",
              required: true,
              onFocus: () => this.setState({ isFocused: true }),
              onKeyDown: this.keyDownHandler,
              autoComplete: "off"
            }}
            className={classNames({ "tag-form-input-loading": isLoading && isFocused })}
            value={tagName}
            changed={this.inputChangeHandler}
          />
          {this.renderLoadingIndicator()}
          {this.renderTagList()}
        </div>
      </>
    )
  }
}

TagInput.propTypes = {
  tagType: PropTypes.string.isRequired,
  tagName: PropTypes.string.isRequired,
  tagNameChanged: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
}

TagInput.defaultProps = {
  tagType: "",
  tagName: "",
  tagNameChanged: () => null,
  edit: false,
  formRef: null,
}

export default TagInput
