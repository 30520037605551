import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";

import Spinner from "../UI/Spinner";
import axios from "../../configAxios";

import OrganizationIntegrations from "./OrganizationIntegrations";
import Communication from "./communication";
import Rentals from "./rentals/index";
import BasicInformation from "./basicInformation/index";
import GeneralSettings from "./generalSettings/index";
import UsersAndMembers from "./usersAndMembers/index";
import Accounting from "./accounting/Accounting";
import AssetPriceType from "./AssetPriceType/AssetPriceType";
import Projects from "./Projects/Projects";
import FlagTypes from "./FlagTypes/FlagTypes";
import CustomFieldsSettings from "./customFieldsSettings";
import Invoice from "./invoice/Invoice";
import ExportData from "./exportData/ExportData";
import EmailCampaign from "./emailCampaign";
import LabelsAndTags from "./labelsAndTags"

import "./index.css";

class SystemSettings extends Component {
  state = {
    activeTab: "",
    tabNames: [],
    loading: true
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener("popstate", this.handleTabFromProp);
    axios.get("/sidebar/system_settings").then(res => {
      let tabNames = [];
      Object.keys(res.data.json).forEach(tab => {
        if (res.data.json[tab]) {
          if (tab === "basic_information") tabNames.push("Basic Information");
          if (tab === "general_settings") tabNames.push("General Settings");
          if (tab === "communication") tabNames.push("Communication");
          if (tab === "email_campaign") tabNames.push("Email Campaign");
          if (tab === "organization_integrations")
            tabNames.push("Integrations");
          if (tab === "flag_types") tabNames.push("Flag Types");
          if (tab === "custom_fields") tabNames.push("Custom Fields");
          if (tab === "user_member_types") tabNames.push("Users");
          if (tab === "rentals") tabNames.push("Rentals");
          if (tab === "asset_price_types") tabNames.push("Asset Price Types");
          if (tab === "accounting") tabNames.push("Accounting");
          if (tab === "invoice") tabNames.push("Invoice");
          if (tab === "projects") tabNames.push("Projects");
          if (tab === "export_data") tabNames.push("Export Data");
          if (tab === "organization_tags") tabNames.push("Labels And Associations");
        }
      });
      this.setState(
        {
          tabNames,
          loading: false,
        },
        () => {
          this.handleTabFromProp();
          window.addEventListener("popstate", this.handleTabFromProp);
        }
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.sidetab !== prevProps.match.params.sidetab &&
      this.props.match.params.toptab
    ) {
      this.handleTabFromProp();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  handleTabFromProp = () => {
    let { sidetab } = this.props.match.params;
    let tab = sidetab && title(sidetab);
    this.setState(
      {
        ...this.state,
        activeTab:
          tab && this.state.tabNames.includes(tab)
            ? tab
            : title(this.state.tabNames[0])
      });
  };

  tabChangeHandler = activeTab => {
    this.setState({ activeTab });
    let url = `/system_settings/${snakeCase(activeTab)}`;
    this.props.history.push(url);
  };

  render() {
    const activeStyle = {
      padding: "10px 20px",
      borderLeft: "2px solid var(--color-primary)",
      color: "var(--color-primary)",
      cursor: "pointer"
    };
    const inActiveStyle = {
      padding: "10px 20px",
      cursor: "pointer"
    };
    const tabs = this.state.tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className="list-items"
        style={this.state.activeTab === tab ? activeStyle : inActiveStyle}
      >
        {tab}
      </li>
    ));
    let activeTab;
    switch (this.state.activeTab) {
      case "Basic Information":
        activeTab = <BasicInformation />;
        break;
      case "General Settings":
        activeTab = <GeneralSettings />;
        break;
      case "Communication":
        activeTab = <Communication />;
        break;
      case "Integrations":
        activeTab = <OrganizationIntegrations />;
        break;
      case "Flag Types":
        activeTab = <FlagTypes />;
        break;
      case "Custom Fields":
        activeTab = <CustomFieldsSettings />;
        break;
      case "Users":
        activeTab = <UsersAndMembers />;
        break;
      case "Rentals":
        activeTab = <Rentals />;
        break;
      case "Asset Price Types":
        activeTab = <AssetPriceType />;
        break;
      case "Accounting":
        activeTab = <Accounting />;
        break;
      case "Invoice":
        activeTab = <Invoice />;
        break;
      case "Projects":
        activeTab = <Projects />;
        break;
      case "Export Data":
        activeTab = <ExportData />;
        break;
      case "Theme":
        activeTab = <Communication />;
        break;
      case "Email Campaign":
        activeTab = <EmailCampaign />;
        break;
      case "Labels And Associations":
        activeTab = <LabelsAndTags />;
        break;
      default:
        activeTab = null;
    }
    return (
      <div className="container system-settings w-100">
        <div className="row system-title mb-4">
          <div className="col-12">
            <h3>System Settings</h3>
          </div>
        </div>
        {this.state.loading ? (
          <div className="card">
            <Spinner />
          </div>
        ) : (
            <div className="row">
              <div className="col-md-3 sidebar">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="card-text">
                      <div className="row">
                        <div className="col-12">
                          <ul className="list ml-0">
                            {tabs}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="card-text">
                      <div className="row">
                        <div className="col-12">
                          {activeTab}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default withRouter(SystemSettings);
