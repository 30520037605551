import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { title } from "change-case"
import dateFns from "date-fns"
import { formatDate } from "../../utils/formatDate"
import Spinner from "./../UI/Spinner"
import Input from "../UI/input/Input"
import Button from "../UI/button/Button"
import FormButton from "../UI/button/FormButton"
import Pagination from "../UI/Pagination"

import DatePicker from "../UI/datePicker"
import axios from "../../configAxios"
import errorStatusHandler from "../errorPages/UserNotAuthorized"

import "./Volunteer.scss"

class Volunteer extends Component {
  signal = axios.CancelToken.source()
  state = {
    hour: {
      elementType: "input",
      elementConfig: {
        name: "hour",
        required: true,
      },
      value: "",
    },
    assignedBy: {
      elementType: "select",
      elementConfig: {
        name: "assignedBy",
        isLoading: true,
        placeholder: "Pick a staff member",
        options: [],
        required: true,
        hide: false,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
    },
    description: {
      elementType: "textarea",
      elementConfig: {
        name: "description",
        rows: "6",
      },
      value: "",
    },
    params: {
      user_id: this.props.userId,
      page: 1,
      include: {
        policy: true,
      },
    },
    date: new Date(),
    tickets: [],
    totalCount: 0,
    perPage: 10,
    selectedPage: 1,
    forcePage: "",
    showCalendar: false,
    disabled: false,
    loading: true,
    manageVolunteerTickets: null,
  }

  componentDidMount() {
    axios
      .get("/volunteer_tickets/formdata", { cancelToken: this.signal.token })
      .then((res) => {
        const options = res.data.json.assigned_by
        this.setState({
          assignedBy: {
            ...this.state.assignedBy,
            elementConfig: {
              ...this.state.assignedBy.elementConfig,
              isLoading: false,
              options: options.map((option) => {
                return { value: option[0], id: option[0], label: option[1] }
              }),
            },
          },
        })
      })
      .catch((error) => error)
    this.getTickets()
    this.getUserPolicy()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getUserPolicy = () => {
    axios
      .get("/users/policy", { params: { id: this.props.userId } })
      .then((res) => {
        this.setState({
          manageVolunteerTickets: res.data.json.manage_volunteer_tickets,
        })
      })
  }

  getTickets = () => {
    const { params } = this.state
    axios
      .get("/volunteer_tickets", {
        params,
        cancelToken: this.signal.token,
      })
      .then((res) => {
        if (res.status === 202) {
          this.props.history.goBack()
          errorStatusHandler(res)
        } else
          this.setState({
            tickets: res.data.volunteer_tickets,
            totalCount: res.data.meta.total_count,
            loading: false,
          })
      })
      .catch((error) => error)
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState(
      {
        params: {
          ...this.state.params,
          page: page.selected + 1,
        },
        forcePage: page.selected,
      },
      () => this.getTickets()
    )
  }

  inputChangeHandler = (event, key) => {
    if (key === "hour") {
      const hour = { ...this.state.hour }
      hour.value = event.target.value
      this.setState({ hour: hour })
    } else if (key === "description") {
      const description = { ...this.state.description }
      description.value = event.target.value
      this.setState({ description: description })
    } else {
      const assignedBy = { ...this.state.assignedBy }
      assignedBy.selectedOption = event
      this.setState({ assignedBy: assignedBy })
    }
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const attrData = new FormData()
    attrData.append(
      ["user_id"],
      this.state.assignedBy.selectedOption &&
        this.state.assignedBy.selectedOption.value
    )
    attrData.append(["for_user_id"], parseInt(this.props.userId))
    attrData.append(["volunteer_hours"], parseFloat(this.state.hour.value))
    attrData.append(["description"], this.state.description.value)
    attrData.append(["date_completed[day]"], dateFns.getDate(this.state.date))
    attrData.append(
      ["date_completed[month]"],
      dateFns.getMonth(this.state.date) + 1
    )
    attrData.append(["date_completed[year]"], dateFns.getYear(this.state.date))
    axios
      .post("/volunteer_tickets", attrData)
      .then((res) => {
        if (res.data.json.success) {
          this.getTickets()
          toast.success("Volunteer hour created successfully")
          this.setState({
            hour: {
              ...this.state.hour,
              value: "",
            },
            assignedBy: {
              ...this.state.assignedBy,
              selectedOption: null,
            },
            description: {
              ...this.state.description,
              value: "",
            },
            date: new Date(),
            disabled: false,
          })
        } else {
          errorStatusHandler(res)
          this.setState({
            hour: {
              ...this.state.hour,
              value: "",
            },
            assignedBy: {
              ...this.state.assignedBy,
              selectedOption: null,
            },
            description: {
              ...this.state.description,
              value: "",
            },
            disabled: false,
          })
        }
      })
      .catch((error) => error)
  }

  deleteHandler = (id) => {
    const response = window.confirm("Do you want to delete volunteer hour?")
    if (response) {
      axios.delete(`/volunteer_tickets/${id}`).then((res) => {
        toast.success("Volunteer hour deleted successfully")
        this.getTickets()
      })
    }
  }

  acceptHandler = (id) => {
    axios
      .post("/volunteer_tickets/approve", {
        id: id,
      })
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Approved")
          this.getTickets()
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
      .catch((err) => err)
  }

  denyHandler = (id) => {
    axios
      .post("/volunteer_tickets/reject", {
        id: id,
      })
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Rejected")
          this.getTickets()
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
      .catch((err) => err)
  }

  acceptHandler = (id) => {
    axios
      .post("/volunteer_tickets/approve", {
        id: id,
      })
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Approved")
          this.getTickets()
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
      .catch((err) => err)
  }

  denyHandler = (id) => {
    axios
      .post("/volunteer_tickets/reject", {
        id: id,
      })
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Rejected")
          this.getTickets()
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
      .catch((err) => err)
  }

  render() {
    const ticketList = this.state.tickets.map((ticket, index) => {
      return (
        <li className="list-group-item" key={index}>
          <div className="row">
            <div className="col-md-8">
              {" "}
              <div>
                <Link to={"#"} className="text-info font-weight-bold">
                  {ticket.staff_and_friend.friend}
                </Link>
                <p>
                  <span>{ticket.description}</span>
                  <br />
                  <span className="text-dark">
                    by {ticket.staff_and_friend.staff} |{" "}
                    {formatDate(
                      new Date(ticket.date_completed),
                      "YYYY/MM/DD"
                    )}
                  </span>
                </p>
                <p className="text-danger">
                  {ticket.status === 0
                    ? "Pending Approval"
                    : ticket.status === 2
                    ? "Rejected"
                    : "Approved"}
                </p>
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-center align-items-center">
              {" "}
              <div className="volunteer-hour text-center rounded">
                {ticket.volunteer_hours} hrs
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-center align-items-center">
              {ticket.status === 0 ? (
                <div className="row">
                  {!ticket.can_approve && !ticket.can_reject ? (
                    <div className="col-md text-danger">Pending</div>
                  ) : (
                    <div className="row">
                      <div className="col-4">
                        {ticket.can_approve && (
                          <div className="approved bg-success" title="approve">
                            <p
                              className="cursor text-light pt-1"
                              onClick={() => this.acceptHandler(ticket.id)}
                            >
                              <i className="fas fa-check cursor icon-style" />
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="col-4">
                        {ticket.can_reject && (
                          <div className="deny bg-danger" title="reject">
                            <p
                              className="cursor text-light font-weight-bold pt-1"
                              onClick={() => this.denyHandler(ticket.id)}
                            >
                              <i className="fas fa-times cursor icon-style" />
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <Button
                    className="rounded"
                    type="delete"
                    title={"delete"}
                    clicked={() => this.deleteHandler(ticket.id)}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </div>
        </li>
      )
    })

    return (
      <div className="volunteer p-3">
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            <form onSubmit={this.submitHandler}>
              <div className="row mt-4">
                <div className="col-lg-4">
                  <p>Hours</p>
                  <Input
                    {...this.state.hour}
                    changed={(event) => this.inputChangeHandler(event, "hour")}
                  />
                </div>
                <div className="col-lg-4">
                  <p>Date Completed</p>
                  <DatePicker
                    selected={this.state.date}
                    changed={(date) => this.setState({ date })}
                    dateFormat='yyyy/MM/dd'
                  />
                </div>
                <div className="col-lg-4">
                  <p>Assigned By</p>
                  <Input
                    {...this.state.assignedBy}
                    changed={(event) =>
                      this.inputChangeHandler(event, "assignedBy")
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>Description of Volunteer Work</p>
                  <Input
                    {...this.state.description}
                    changed={(event) =>
                      this.inputChangeHandler(event, "description")
                    }
                  />
                  <p>
                    Note:
                    <span>
                      {" "}
                      Volunteer hours older than 30 days may not be approved
                    </span>
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center mb-5">
                <FormButton
                  disabled={
                    this.state.disabled || !this.state.manageVolunteerTickets
                  }
                  style={{
                    cursor: this.state.manageVolunteerTickets
                      ? "pointer"
                      : "no-drop",
                  }}
                >
                  Submit
                </FormButton>
              </div>
            </form>
            <div className="list-group list-group-flush">{ticketList}</div>
            <Pagination
              forcePage={this.state.forcePage}
              pageCount={this.state.totalCount / this.state.perPage}
              handlePageClick={this.paginationHandler}
            />
          </div>
        )}
      </div>
    )
  }
}
export default withRouter(Volunteer)
