import { toast } from "react-toastify"

/* This is to handle actual API error status codes, unlike the existing responseErrorHandler
*  which only handles successful responses for res.data that return "success false". */
const onError = (err) => {
  console.error(err)
  toast.error(err)
}

export default onError
