import React from 'react'
import Input from "../../../UI/input/Input";

export default function ShortAnswerQuestion({ question, response, setResponseData, completed }) {
  return (
    <div className="mb-4">
      <p className="mb-2 font-weight-bold">{question.question_text}</p>
      <div className="mx-3">
        <Input
          elementType="text"
          elementConfig={{
            name: question.id,
            type: 'text',
            required: question.mandatory,
          }}
          changed={e => setResponseData(question.id, {response_text: e.target.value})}
          value={response && response.response_text || ""}
          disabled={completed}
        />
      </div>
    </div>
  )
}
