import React, { Component } from 'react'
import { ChromePicker, CustomPicker } from "react-color"
import { EditableInput } from "react-color/lib/components/common"
import "./ColorPicker.css" ;

class ColorPicker extends Component {
   state = {
      showColorPicker: false,
      color: this.props.value ? `#${this.props.value}` : "#000000",
      inputStyles : {
         input: { 
            width: "100%", 
            height: "37px",
            paddingLeft: "40px", 
            border: `1px solid #ced4da`,
         } 
      }
   }

   componentDidMount() {
      document.addEventListener("mousedown", this.outsideClickHandler);
   }

   componentWillUnmount() {
      document.removeEventListener("mousedown", this.outsideClickHandler);
   }

   changeHandler = (color, event) => {
      this.setState({ color: color.hex });
      this.props.onColorChange(color.hex.substr(1));  
   }

   clickHandler = (event) => {
      this.setState({ showColorPicker: true});
   }

   outsideClickHandler = (event) => {
      if(this.node.contains(event.target))
         return;
      this.setState({showColorPicker: false});
   }

   colorInputChangeHandler = (event) => {
         const validation = this.colorValidator(event);
         this.setState({
            inputStyles: {
               ...this.state.inputStyles,
               input: {
                  ...this.state.inputStyles.input,
                  border: validation ? "1px solid #ced4da" : "1px solid red" 
               }
            },
            color: event
         });
      this.props.onColorChange(event.substr(1));
   }

   colorValidator = (color) => {
      if(/^#[0-9A-F]{6}$/i.test(color)){
         return true;
      } else if(/^#([0-9A-F]{3}){1,2}$/i.test(color)){
         return true;
      }
      return false;
   }

   render() {
      return (
         <div className="form-group color-picker">
            <div className="mb-2">{this.props.label}</div>
            <div ref={(node) => {this.node = node}}>
               {this.state.showColorPicker ? 
                  <div className="inbuilt-color-picker">
                     <ChromePicker onChange={this.changeHandler} color={`${this.state.color}`}/> 
                  </div> 
               : null}
               <div onClick={this.clickHandler}>
                  <div className="color-sample" style={{ background: `${this.state.color}` }}></div>
                  <EditableInput style={this.state.inputStyles} value={this.state.color} onChange={this.colorInputChangeHandler}/>
               </div>
            </div>
         </div>
      )
   }
}

export default CustomPicker(ColorPicker)
