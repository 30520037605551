import React from "react";
import { connect } from "react-redux"

import DefaultLogo from "../../../assests/images/logo.png";
import Spinner from "../../../assests/images/spinner-gif.gif"

const Logo = ({ assetLoading, loginLogo }) => {
  return (
    <div>
      {assetLoading && (
        <img
          src={Spinner}
          style={{ width: "200px", margin: "auto", display: "block" }}
          alt="Loading..."
        />
      )}
      {!assetLoading && loginLogo && (
        <img
          className="card-img-top mx-auto d-block"
          src={loginLogo}
          alt="Logo"
          style={{ maxWidth: "400px" }}
        />
      )}
      {!assetLoading && !loginLogo && (
        <img
          className="card-img-top mx-auto d-block"
          src={DefaultLogo}
          alt="Logo"
          style={{ maxWidth: "400px" }}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loginLogo: state.loginAssets.loginLogo,
    assetLoading: state.loginAssets.assetLoading
  }
}

export default connect(mapStateToProps)(Logo)
