import React, { Component } from "react";
import { Link } from "react-router-dom";
import dateFns from "date-fns";

class AssetItem extends Component {
  render() {
    const { asset: data, activeTab } = this.props;

    let start, end;
    if (activeTab === "checked_out") {
      start = dateFns.format(new Date(data.booking.start), "ddd MMM DD");
      end = dateFns.format(new Date(data.booking.end), "ddd MMM DD");
    }

    return (
      <li className="list-group-item rounded-0 hover-effect">
        {activeTab === "checked_out" ? (
          <div className="row">
            <div className="col-md-4">
              <Link
                to={`/assets/${data.asset.id}`}
                className="text-info"
              >
                {data.asset.name}
              </Link>
            </div>
            <div className="col-md-2 text-center">
              {data.booking.quantity}
            </div>
            <div className="col-md-4 text-center">
              {`${start} - ${end}`}
            </div>
            <div className="col-md-2">
              <Link
                to={data.booking.ticketable_type === "User"
                  ? `/users/${data.booking.user_id}/summary`
                  : `/programs/${data.booking.user_id}`}
                className="text-info"
              >
                {data.booking.user_name}
              </Link>
            </div>
          </div>
        ) : (
            <div className="row">
              <div className="col-md-6">
                <Link
                  to={`/assets/${data.id}`}
                  className="text-info"
                >
                  {data.name}
                </Link>
              </div>
              <div className="col-md-6">
                {data.last_flagged_by && data.last_flagged_by.id ?
                  <Link
                    to={`/users/${data.last_flagged_by.id}/summary`}
                    className="text-info"
                  >
                    {data.last_flagged_by.name}
                  </Link>
                  : <div>{"--"}</div>
                }
              </div>
            </div>
          )}
      </li>
    );
  }
}

export default AssetItem;
