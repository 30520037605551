import React, { Component } from "react";
import Input from "../UI/input/Input";
import axios from "../../configAxios";

class ExpertsSearch extends Component {
  signal = axios.CancelToken.source();
  state = {
    searchForm: {
      memberExpertise: {
        elementType: "select",
        elementConfig: {
          name: "memberExpertise",
          options: [],
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          },
          isClearable: false
        },
        selectedOption: null
      }
    }
  };

  componentDidMount() {
    axios
      .get("/users/filters", { cancelToken: this.signal.token })
      .then(res => {
        const types = res.data.json.expertise_area;
        const options = types.map(type => {
          return { value: type[1], label: type[0] };
        })

        options.unshift({value: null, label: "All"})
        this.setState({
          searchForm: {
            ...this.state.searchForm,
            memberExpertise: {
              ...this.state.searchForm.memberExpertise,
              elementConfig: {
                ...this.state.searchForm.memberExpertise.elementConfig,
                options
              },
              selectedOption: options[0]
            }
          }
        }, () => {
          let params = { page: 1, user_skill_level: null };
          const { selectedOption } = this.state.searchForm.memberExpertise;
          if (selectedOption) {
            params["user_skill_level"] = selectedOption.value;
          }
          this.props.searchHandler(params);
        });
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, name) => {
    const updatedSearchForm = {
      ...this.state.searchForm,
      [name]: {
        ...this.state.searchForm[name],
        selectedOption: event
      }
    };
    this.setState({ ...this.state, searchForm: updatedSearchForm });
    let params = { page: 1, user_skill_level: null };
    if (event) {
      params["user_skill_level"] = event.value;
    }
    this.props.searchHandler(params);
  };

  submitHandler = event => {
    event.preventDefault();
    let params = { page: 1, user_skill_level: null };
    const { selectedOption } = this.state.searchForm.memberExpertise;
    if (selectedOption) {
      params["user_skill_level"] = selectedOption.value;
    }
    this.props.searchHandler(params);
  };

  render() {
    return (
      <div className="card">
        <div className="card-body p-4">
          <h4 className="font-weight-bold text-dark">
            Search
          </h4>
          <div>List members with the following expertise</div>
          <div className="mt-3">
            <Input
              {...this.state.searchForm.memberExpertise}
              changed={event => this.inputChangedHandler(event, "memberExpertise")}
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ExpertsSearch;
