import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { title } from "change-case"
import { toast } from "react-toastify"
import { first } from "lodash"
import Editor from "rich-markdown-editor"
import CharacterCounter from "../shared/CharacterCounter"

import LocationSearch from "../common/locationSearch/LocationSearch"
import ToggleSwitch from "../common/toggleSwitch"
import Input from "../UI/input/Input"
import Spinner from "../UI/Spinner"
import DatePicker from "../UI/datePicker/index"
import CalendarModal from "../UI/modal/CalendarModal"
import ProgramStartSelect from "./ProgramStartSelect"
import Button from "../UI/button/Button"
import FormButton from "../UI/button/FormButton"
import axios from "../../configAxios"
import errorStatusHandler from "../errorPages/UserNotAuthorized"
import NewOrEditProgramLabels from "./NewOrEditProgramLabels"

import { timezone } from "../systemSettings/basicInformation/timezones"

import "./NewOrEditProgram.css"

class NewProgram extends Component {
  constructor(props) {
    super(props)
    this.signal = axios.CancelToken.source()
    this.state = {
      showModal: false,
      title: {
        elementType: "input",
        elementConfig: {
          type: "text",
          id: "program-title",
          name: "title",
          required: true,
        },
        value: "",
      },
      timezone: {
        elementType: "select",
        elementConfig: {
          name: "timezone",
          type: "text",
          options: timezone,
          required: true,
        },
        label: "Timezone",
        value: "",
        selectedOption: {},
        style: { minWidth: "100%", maxWidth: "100%" },
      },
      description: {
        elementType: "textarea",
        elementConfig: {
          type: "textarea",
          id: "program-description",
          name: "description",
          rows: "4",
          required: true,
        },
        value: "",
        label: "Description",
      },
      program_details: {
        value: "",
      },
      scheduledProgramDatesAttributes: [],
      instructor: {
        elementType: "textarea",
        elementConfig: {
          type: "textarea",
          id: "program-instructor",
          name: "instructor",
          rows: "5",
        },
        value: "",
        label: "Instructor(s) / Guests",
      },
      location: "",
      location_notes: {
        elementType: "input",
        elementConfig: {
          type: "text",
          id: "location-notes",
          name: "location-notes",
        },
        value: "",
        label: "Additional Location Information"
      },
      maxParticipant: {
        elementType: "input",
        elementConfig: {
          type: "number",
          id: "program-max-participants",
          name: "maxParticipant",
          required: true,
        },
        value: "",
        label: "Maximum Participants",
      },
      minParticipant: {
        elementType: "input",
        elementConfig: {
          type: "number",
          id: "program-min-participants",
          name: "minParticipant",
          required: true,
        },
        value: "",
        label: "Minimum Participants",
      },
      nonMemberPrice: {
        elementType: "input",
        elementConfig: {
          type: "number",
          id: "program-non-member-price",
          name: "nonMemberPrice",
          required: true,
        },
        value: "",
        label: "Non-Member Price ($)",
      },
      memberPrice: {
        elementType: "input",
        elementConfig: {
          type: "number",
          id: "program-member-price",
          name: "memberPrice",
          required: true,
        },
        value: "",
        label: "Member Price ($)",
      },
      earlybirdDiscount: {
        elementType: "input",
        elementConfig: {
          type: "number",
          id: "program-earlybird-discount",
          name: "earlybirdDiscount",
          required: true,
        },
        value: "",
        label: "Early Bird Discount ($)",
      },
      programExpenses: {
        elementType: "input",
        elementConfig: {
          type: "number",
          id: "program-expenses",
          name: "programExpenses",
        },
        value: "",
        label: "Program Expenses ($)",
      },
      projects: {
        elementType: "select",
        elementConfig: {
          name: "projects",
          type: "text",
          options: [],
        },
        label: "Connect a Project to this Program (Optional)",
        selectedOption: null,
        style: { minWidth: "100%", maxWidth: "100%" },
      },
      earlybirdDate: new Date(),
      dropCutoffDate: new Date(),
      allowMediaUpload: false,
      status: [],
      tagged: [],
      selectedOption: "draft",
      isDraft: "",
      isActive: "",
      programImage: null,
      allowMultipleBooking: false,
      isProgramPublic: false,
      disabled: false,
      loading: false,
      fileKey: new Date(),
    }
    this.backupState = this.state
  }

  componentDidMount() {
    this.getFormData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState(
        {
          ...this.backupState,
          fileKey: new Date(),
        },
        () => {
          this.getFormData()
        }
      )
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  formatAvailableProjects = (projects) => {
    return projects.map((p) => ({ label: p.name, value: p.id }))
  }

  getFormData = () => {
    axios
      .get("/programs/formdata", { cancelToken: this.signal.token })
      .then((res) => {
        const {
          program_types,
          status,
          organization_timezone,
          available_projects
        } = res.data.json
        const availableProjects = this.formatAvailableProjects(available_projects)

        this.setState({
          status,
          timezone: {
            ...this.state.timezone,
            selectedOption: organization_timezone && {
              label: organization_timezone,
              value: organization_timezone,
            },
          },
          projects: {
            ...this.state.projects,
            elementConfig: {
              ...this.state.elementConfig,
              options: availableProjects
            },
          },
        })
      })
      .then((res) => {
        if (this.props.location.pathname.includes("edit")) {
          this.fetchProgramDetails()
        } 
      })
  }

  selectProjectsHandler = (e) => {
    let selectedOption = null

    if (e) selectedOption = { ...e }

    this.setState({
      projects: {
        ...this.state.projects,
        selectedOption,
      },
    })
  }

  scheduleSetter = (selectedDate) => {
    this.setState({
      scheduledProgramDatesAttributes: [
        selectedDate
      ]
    })
  }

  submitTags = (programId) => {
    this.state.tagged.forEach((tag) => {
      axios.post("/tags", { query: tag, program_id: programId })
        .then((res) => {
        if (res.data.json.success) {
          if (this.props.location.pathname === "/programs/new") {
            this.props.history.push("/programs/" + programId)
          }
        } else {
          errorStatusHandler(res)
        }
      })
    })
  }

  tagsForSubmission = (tags) => {
    const tagStrings = tags.map(tag => tag.value)
    this.setState({ tagged: tagStrings })
  }

  fetchProgramDetails = () => {
    this.setState({ loading: true })
    axios
      .get(`/programs/${this.props.match.params.id}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const program = res.data.program
        let defaultTimezone
        if (program.timezone) {
          defaultTimezone = program.timezone
        } else {
          defaultTimezone = localStorage.getItem("organizationTimezone")
        }

        const availableProjects = this.state.projects.elementConfig.options
        const filteredProject = availableProjects.filter((o) => o.value == program.project_id)
        const linkedProject = first(filteredProject)

        this.setState({
          title: { ...this.state.title, value: program.name },
          description: {
            ...this.state.description,
            value: program.description,
          },
          program_details: {
            ...this.state.program_details,
            value: program.program_details,
          },
          scheduledProgramDatesAttributes: [
            ...this.state.scheduledProgramDatesAttributes,
          ],
          timezone: {
            ...this.state.timezone,
            selectedOption: defaultTimezone && {
              label: defaultTimezone,
              value: defaultTimezone,
            },
          },
          instructor: {
            ...this.state.instructor,
            value: program.instructors,
          },
          location: program.location,
          location_notes: { 
            ...this.state.location_notes,
            value: program.location_notes
          },
          maxParticipant: {
            ...this.state.maxParticipant,
            value: program.max_participants,
          },
          minParticipant: {
            ...this.state.minParticipant,
            value: program.min_participants,
          },
          nonMemberPrice: {
            ...this.state.nonMemberPrice,
            value: program.enrollment_price,
          },
          memberPrice: {
            ...this.state.memberPrice,
            value: program.member_enrollment_price,
          },
          earlybirdDiscount: {
            ...this.state.earlybirdDiscount,
            value: program.earlybird_discount,
          },
          programExpenses: {
            ...this.state.programExpenses,
            value: program.manually_entered_expenses,
          },
          earlybirdDate: program.earlybird_cutoff
            ? new Date(program.earlybird_cutoff)
            : null,
          dropCutoffDate: program.drop_cutoff
            ? new Date(program.drop_cutoff)
            : null,
          isActive: program.is_active,
          isDraft: program.is_draft,
          selectedOption: program.is_active === true ? "Active" : "Draft",
          allowMediaUpload: !!program.allow_media_upload,
          allowMultipleBooking: !!program.allow_multiple_booking,
          isProgramPublic: program.public,
          loading: false,
          projects: {
            ...this.state.projects,
            selectedOption: linkedProject,
          },
        })
      })

    this.setState({ loading: true })
  }

  titleChangeHandler = (title) => {
    this.setState({ selectedTitle: title })
  }

  inputChangeHandler = (event, key) => {
    const inputValue = {}

    switch (key) {
      case "draft":
        this.setState({
          isDraft: true,
          isActive: false,
          selectedOption: "draft",
        })
        break
      case "active":
        this.setState({
          isActive: true,
          isDraft: false,
          selectedOption: "active",
        })
        break
      case "allowUpload":
        this.setState({
          allowMediaUpload: event.target.value === "Yes",
        })
        break
      default:
        inputValue.value = event.target.value
    }

    const updatedControls = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState({ [key]: updatedControls })
  }

  fileUploadHandler = (e) => {
    const file = e.target.files && e.target.files[0] ? e.target.files[0] : null
    this.setState({ programImage: file })
  }

  dropdownChangedHandler = (event, key) => {
    const inputValue = {}
    inputValue.selectedOption = event
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState({ [key]: updatedcontrols })
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const { status } = this.state
    let programStatus
    status.map((status) => {
      if (
        status.value.toLowerCase() === this.state.selectedOption.toLowerCase()
      ) {
        programStatus = status.key
      }
      return programStatus
    })

    const earlybirdDate = new Date(this.state.earlybirdDate).getDate()
    const earlybirdMonth = new Date(this.state.earlybirdDate).getMonth() + 1
    const earlybirdYear = new Date(this.state.earlybirdDate).getFullYear()
    const dropCutoffDate = new Date(this.state.dropCutoffDate).getDate()
    const dropCutoffMonth = new Date(this.state.dropCutoffDate).getMonth() + 1
    const dropCutoffYear = new Date(this.state.dropCutoffDate).getFullYear()

    const earlybird_cutoff = {
      day: earlybirdDate,
      month: earlybirdMonth,
      year: earlybirdYear,
    }
    const drop_cutoff = {
      day: dropCutoffDate,
      month: dropCutoffMonth,
      year: dropCutoffYear,
    }

    const formData = new FormData()

    formData.append("name", this.state.title.value)
    formData.append("description", this.state.description.value)
    formData.append("program_details", this.state.program_details.value)
    formData.append("scheduled_program_dates_attributes", JSON.stringify(this.state.scheduledProgramDatesAttributes))
    formData.append("status", programStatus)
    formData.append("instructors", this.state.instructor.value)
    formData.append("location", this.state.location)
    formData.append("location_notes", this.state.location_notes.value)
    formData.append("max_participants", this.state.maxParticipant.value)
    formData.append("min_participants", this.state.minParticipant.value)
    formData.append("enrollment_price", this.state.nonMemberPrice.value)
    formData.append("member_enrollment_price", this.state.memberPrice.value)
    formData.append("earlybird_discount", this.state.earlybirdDiscount.value)
    formData.append("expenses", this.state.programExpenses.value)
    formData.append("earlybird_cutoff", JSON.stringify(earlybird_cutoff))
    formData.append("drop_cutoff", JSON.stringify(drop_cutoff))
    formData.append("allow_media_upload", this.state.allowMediaUpload)
    formData.append("allow_multiple_booking", this.state.allowMultipleBooking)
    formData.append("public", this.state.isProgramPublic)
    formData.append(
      "timezone",
      this.state.timezone.selectedOption &&
        this.state.timezone.selectedOption.value
    )

    if (this.state.programImage) {
      formData.append("photo", this.state.programImage)
    }
    
    if (this.state.projects.selectedOption) {
      formData.append("project_id", this.state.projects.selectedOption.value)
    }

    if (this.props.location.pathname.includes("edit")) {
      if (!this.state.projects.selectedOption) {
        formData.append("project_id", "")
      }

      axios.put(`/programs/${this.props.match.params.id}`, formData).then((res) => {
        if (res.data.json.success) {
          toast.success("Program was updated successfully.")
          this.props.history.push("/programs/" + res.data.json.data.id)
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
          this.setState({ disabled: false })
        }
      })
    } else {
      axios.post("/programs", formData).then((res) => {
        if (res.data.json.success) {
          const programId = res.data.json.data.id
          toast.success("Program was created successfully.")
          this.submitTags(programId)
          this.props.history.push("/programs/" + programId)
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
          this.setState({ disabled: false })
        }
      })
    }
  }

  openModal = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true })
  }

  render() {
    const { allowMultipleBooking, isProgramPublic } = this.state
    return (
      <div className="container new-program w-100">
        {this.props.location.pathname.includes("edit") ? (
          <div className="row mb-4">
            <div className="col-12">
              <h3>Edit Program</h3>
            </div>
          </div>
        ) : (
          <div className="row mb-4">
            <div className="col-12">
              <h3>New Program</h3>
            </div>
          </div>
        )}
        <div className="card">
          <div className="card-body">
            <div className="card-text">
              {this.state.loading ? (
                <Spinner />
              ) : (
                <form className="mt-4" onSubmit={this.submitHandler}>
                  <div className="row m-0">
                    <div className="col-md-5">
                    <div className="mt-2">
                        <p> Labels</p>
                        <NewOrEditProgramLabels
                          programId={this.props.match.params.id}
                          programEditPage={this.props.location.pathname.includes("edit")}
                          tagsForSubmission={this.tagsForSubmission}
                        />
                      </div>
                      <Input
                        {...this.state.title}
                        label={
                          this.props.location.pathname.includes("edit")
                            ? "Event Title"
                            : "Title"
                        }
                        changed={(event) =>
                          this.inputChangeHandler(event, "title")
                        }
                      />
                      {!this.props.location.pathname.includes("edit") && <label className="mb-2">Program Start Date</label>}
                      {!this.props.location.pathname.includes("edit") && (this.state.scheduledProgramDatesAttributes?.length < 1) && (
                        <div className="pb-3">
                          <Button
                            type="success"
                            clicked={this.openModal}
                            className="float-none w-100"
                          >
                            Add Start Date
                          </Button>
                        </div>
                      )}
                      {!this.props.location.pathname.includes("edit") && (this.state.scheduledProgramDatesAttributes?.length >= 1) && (
                        <div className="d-flex justify-content-between py-2 px-2 border">
                          {this.state.scheduledProgramDatesAttributes[0].start}
                          <a
                            onClick={() =>
                              this.setState({ scheduledProgramDatesAttributes: [] })
                            }
                          >
                            <i className="fas fa-times cursor mx-2 text-danger"/>
                          </a>
                        </div>
                      )}
                      <Input
                        {...this.state.description}
                        changed={(event) =>
                          this.inputChangeHandler(event, "description")
                        }
                      />
                      <div className="row mb-3 px-3 justify-content-between" style={{marginTop: "-1rem"}} >
                        <small className="pl-2">
                          (Max 150 characters)
                        </small>
                        <small className="pr-2">
                          <CharacterCounter data={this.state.description.value} range={{min: 1, max: 150}} />
                        </small>
                      </div>
                      <label className="mb-2">Program Details</label>
                      <div className="py-2 pr-2 border" style={{height: "20rem", overflow: "auto", paddingLeft: "2rem"}}>
                        <Editor
                          placeholder="Please enter the program details"
                          defaultValue={this.state.program_details.value || ""}
                          onChange={(getValue) => {
                            this.setState({
                              program_details: {
                                ...this.state.program_details,
                                value: getValue(),
                              },
                            })
                          }}
                        />
                      </div>
                      <div className="mt-3">
                        <label htmlFor="programImage">
                          Program Image{" "}
                          <small>(Suggested aspect ratio 16:9)</small>
                        </label>
                        <div className="p-2 border">
                          <input
                            key={this.state.fileKey}
                            type="file"
                            name="programImage"
                            id="programImage"
                            onChange={this.fileUploadHandler}
                            accept="image/*"
                          />
                        </div>
                      </div>

                    </div>
                    <div className="col-md-7 mb-4">
                      <div className="row">
                        <div className="col-md-6">
                          {!this.props.location.pathname.includes("edit")
                            && <div className="mt-3" />
                          }
                          <Input
                            {...this.state.nonMemberPrice}
                            changed={(event) =>
                              this.inputChangeHandler(event, "nonMemberPrice")
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          {!this.props.location.pathname.includes("edit")
                            && <div className="mt-3" />
                          }
                          <Input
                            {...this.state.memberPrice}
                            changed={(event) =>
                              this.inputChangeHandler(event, "memberPrice")
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Input
                            {...this.state.maxParticipant}
                            changed={(event) =>
                              this.inputChangeHandler(event, "maxParticipant")
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            {...this.state.minParticipant}
                            changed={(event) =>
                              this.inputChangeHandler(event, "minParticipant")
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Input
                            {...this.state.earlybirdDiscount}
                            changed={(event) =>
                              this.inputChangeHandler(
                                event,
                                "earlybirdDiscount"
                              )
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <Input
                            {...this.state.programExpenses}
                            changed={(event) =>
                              this.inputChangeHandler(event, "programExpenses")
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Input
                            {...this.state.instructor}
                            changed={(event) =>
                              this.inputChangeHandler(event, "instructor")
                            }
                          />
                          <div className="row mb-3 px-3 justify-content-between" style={{marginTop: "-1rem"}} >
                            <small className="pl-2">
                              (Max 500 characters)
                            </small>
                            <small className="pr-2">
                              <CharacterCounter data={this.state.instructor.value} range={{min: 0, max: 500}} />
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <LocationSearch
                            label="Address (If online specify City)"
                            editMode={this.props.location.pathname.includes(
                              "edit"
                            )}
                            locationValue={this.state.location}
                            changeLocation={(e) =>
                              this.setState({ location: e })
                            }
                          />
                          <Input
                            {...this.state.location_notes}
                            changed={(event) => 
                              this.inputChangeHandler(event, "location_notes")}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="date-picker mt-4">
                            <p className="font-weight-bold">
                              Earlybird Register by
                            </p>
                            <DatePicker
                              id="program-earlybird-date"
                              selected={this.state.earlybirdDate}
                              changed={(date) =>
                                this.setState({ earlybirdDate: date })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="date-picker mt-4">
                            <p className="font-weight-bold">Drop Cutoff</p>
                            <DatePicker
                              id="program-drop-cutoff-date"
                              selected={this.state.dropCutoffDate}
                              changed={(date) =>
                                this.setState({ dropCutoffDate: date })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="program-status mt-4">
                            <p className="font-weight-bold">Program Status</p>
                            <div
                              className="form-check form-check-inline"
                              id="program-draft"
                            >
                              <label
                                className="form-check-label form-container"
                                htmlFor="draft"
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="programStatus"
                                  id="draft"
                                  value="draft"
                                  checked={
                                    this.props.location.pathname.includes(
                                      "edit"
                                    )
                                      ? !!this.state.isDraft
                                      : this.state.selectedOption === "draft"
                                  }
                                  onClick={(event) =>
                                    this.inputChangeHandler(event, "draft")
                                  }
                                />
                                <span className="checkmark"></span>
                                Draft
                              </label>
                            </div>
                            <div
                              className="form-check form-check-inline"
                              id="program-active"
                            >
                              <label
                                className="form-check-label form-container"
                                htmlFor="active"
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="active"
                                  name="programStatus"
                                  value="active"
                                  checked={
                                    this.props.location.pathname.includes(
                                      "edit"
                                    )
                                      ? !!this.state.isActive
                                      : this.state.selectedOption === "active"
                                  }
                                  onClick={(event) =>
                                    this.inputChangeHandler(event, "active")
                                  }
                                />
                                <span className="checkmark"></span>
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mt-4">
                            <p className="font-weight-bold">
                              Allow Registrants to Upload Files
                            </p>
                            <div
                              className="form-check form-check-inline"
                              id="program-draft"
                            >
                              <label
                                className="form-check-label form-container"
                                htmlFor="Yes"
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="allowUpload"
                                  id="Yes"
                                  value="Yes"
                                  checked={this.state.allowMediaUpload}
                                  onClick={(event) =>
                                    this.inputChangeHandler(
                                      event,
                                      "allowUpload"
                                    )
                                  }
                                />
                                <span className="checkmark"></span>
                                Yes
                              </label>
                            </div>
                            <div
                              className="form-check form-check-inline"
                              id="program-draft"
                            >
                              <label
                                className="form-check-label form-container"
                                htmlFor="No"
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="allowUpload"
                                  id="No"
                                  value="No"
                                  checked={!this.state.allowMediaUpload}
                                  onClick={(event) =>
                                    this.inputChangeHandler(
                                      event,
                                      "allowUpload"
                                    )
                                  }
                                />
                                <span className="checkmark"></span>
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <Input
                          { ...this.state.projects }
                          changed={this.selectProjectsHandler}
                        />
                      </div>
                      <div className="mt-3">
                        <Input
                          {...this.state.timezone}
                          changed={(event) =>
                            this.dropdownChangedHandler(event, "timezone")
                          }
                        />
                      </div>
                      <div className="mt-4 row">
                        <div className="d-flex">
                          <div className="pt-2 mr-3 col-2">
                            <ToggleSwitch
                              checked={allowMultipleBooking}
                              onChange={() =>
                                this.setState({
                                  allowMultipleBooking: !allowMultipleBooking,
                                })
                              }
                            />
                          </div>
                          <div className="col-10">
                            <p className="font-weight-bold">
                              Enable Asset double-booking by Program Director
                              and Participants?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 row">
                        <div className="d-flex w-100">
                          <div className="pt-2 col-2">
                            <ToggleSwitch
                              checked={isProgramPublic}
                              onChange={() =>
                                this.setState({
                                  isProgramPublic: !isProgramPublic,
                                })
                              }
                            />
                          </div>
                          <div className="col-10">
                            <p className="ml-3 font-weight-bold">
                              Make this program or event public on the web?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 text-right">
                          <Link
                            to={
                              this.props.location.pathname.includes("edit")
                                ? `/programs/${this.props.match.params.id}`
                                : "/programs"
                            }
                          >
                            <Button type="secondary" className="float-none">
                              Cancel
                            </Button>
                          </Link>
                          <FormButton
                            id="create-program"
                            className="ml-3 float-none"
                            disabled={this.state.disabled}
                          >
                            {this.props.location.pathname.includes("edit")
                              ? "Update"
                              : "Create Program"}
                          </FormButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <CalendarModal
          closeButton
          closeModal={() => this.setState({ showModal: false })}
          hide={() => this.setState({ showModal: false })}
          title="Select Program Start"
          show={this.state.showModal}
        >
          <ProgramStartSelect
            closeModal={() => this.setState({ showModal: false })}
            getSchedules={() => this.getSchedules()}
            scheduleSetter={this.scheduleSetter}
          />
        </CalendarModal>
      </div>
    )
  }
}

export default withRouter(NewProgram)
