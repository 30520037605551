import React, { useState } from "react"
import { Link } from "react-router-dom"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { toast } from "react-toastify"
import { serialize } from "object-to-formdata";
import axios from "../../../../configAxios";

const ProjectAttributes = ({
  selectedProjects,
  listItemClickedHandler,
  deleteProjectAttributeHandler,
  id,
}) => {

  let [projectAttributes, setProjectAttributes] = useState(selectedProjects)

  const submitProjectAttributeOrder = (projectAttributesForSubmission) => {
    if (projectAttributesForSubmission.length > 0) {
      Promise.all(projectAttributesForSubmission.map(projectAttribute => {
          const payload = { id: projectAttribute.reel_project_attribute[0].id, order: projectAttribute.order }
          return updateProjectAttribute(payload)
        })
      ).then(() => {
        toast.success("Successfully reordered projectAttributes.")
      }).catch(() => {
        setProjectAttributes(selectedProjects)
        toast.error("Could not update projectAttribute order.")
      })
    }
  }

  const updateProjectAttribute = (projectAttribute) => {

    const projectAttributeCopy = {...projectAttribute}

    const serializedProjectAttribute = serialize(projectAttributeCopy)

    return axios.put(`reel_project_attribute/${projectAttribute.id}`, serializedProjectAttribute)
  }

  const parseProjectAttributesOrderData = (projectAttributesCopy, projectAttributesForSubmission) => {
    return projectAttributesCopy.map((projectAttribute, index) => {
      const newOrder = index + 1
      const updatedProjectAttribute = { ...projectAttribute, order: newOrder }
      if (projectAttribute.order !== newOrder) {
        projectAttributesForSubmission.push(updatedProjectAttribute)
      }

      return updatedProjectAttribute
    })
  }

  const updateProjectAttributesOrder = (result) => {
    let projectAttributesCopy = [...projectAttributes]
    let [movedProjectAttribute] = projectAttributesCopy.splice(result.source.index, 1)
    projectAttributesCopy.splice(result.destination.index, 0, movedProjectAttribute)

    const projectAttributesForSubmission = []
    const formattedProjectAttributes = parseProjectAttributesOrderData(projectAttributesCopy, projectAttributesForSubmission)

    setProjectAttributes(formattedProjectAttributes)
    submitProjectAttributeOrder(projectAttributesForSubmission)
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return
    updateProjectAttributesOrder(result)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="projectAttributes">
        {(provided) => (
          <ul
            className="list-group list-group-flush"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className="list-group-item">
              <span className="font-weight-bold">Projects Name</span>
            </div>
            {projectAttributes.map((project, index) => {
              return (
                <Draggable
                  key={project.id || project.dragId}
                  draggableId={`${project.id || project.dragId}`}
                  index={index}
                >
                  {(provided) => (
                    <>
                      <li
                        className="list-group-item"
                        key={project.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="row">
                          <i className="fa fa-arrows-v pt-1 pl-2"/>
                          <div
                            className="col-md-11 ml-2 cursor"
                            onClick={() =>
                              listItemClickedHandler(project.project_slug)
                            }
                          >
                            {project.full_name}
                          </div>
                          <div className="updates">
                            <Link
                              to={"/reels/" + id}
                              className="text-muted"
                              onClick={() => {
                                deleteProjectAttributeHandler(
                                  project.reel_project_attribute.map(
                                    (attribute) => attribute.id
                                  )
                                )
                              }}
                            >
                              <i className="fas fa-trash cursor" />
                            </Link>
                          </div>
                        </div>
                      </li>
                    </>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default ProjectAttributes
