import * as actionTypes from "../actions/actionTypes";

const initialState = {
  projects: [],
  totalCount: null,
  project: null,
  projectAttributeTypes: null,
  projectAttributes: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case ADD_PROJECT:
    //   return {
    //     ...state,
    //     projects: [...state.projects, action.project]
    //   };
    case actionTypes.GET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
        totalCount: action.totalCount,
        params: action.params,
        loading: false
      };
    case actionTypes.GET_PROJECT:
      return {
        ...state,
        loading: false,
        project: action.project
      };
    case actionTypes.GET_PROJECT_ATTRIBUTE_TYPES:
      return {
        ...state,
        projectAttributeTypes: action.projectAttributeTypes
      };
    case actionTypes.GET_PROJECT_ATTRIBUTES:
      return {
        ...state,
        projectAttributes: action.projectAttributes
      };
    case actionTypes.UPDATE_PROJECT_ATTRIBUTE:
      let projectAttributes = [...state.projectAttributes];
      let index = projectAttributes.findIndex(pa => pa.id === action.id);
      projectAttributes[index] = {
        ...projectAttributes[index],
        [action.valueType]: action.value
      };
      return {
        ...state,
        projectAttributes
      };
    case actionTypes.CREATE_PROJECT_ATTRIBUTE:
      let projectAttributesList = state.projectAttributes;
      let indexElement = projectAttributesList.findIndex(pa => pa.project_attribute_type_id === action.project_attribute_type_id);
      projectAttributesList[indexElement] = {
        ...projectAttributesList[indexElement],
        id: action.id,
        [action.valueType]: action.value
      };
      return {
        ...state,
        projectAttributesList
      };
    default:
      return state;
  }
};

export default reducer;
