import React, { Component } from "react"
import { NavLink, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { upperCase } from "change-case"
import PropTypes from "prop-types"

import axios from "../../configAxios"
import langUtil from "../../utils/langUtils"

import {
  logoutUser,
  getAdminStatus,
  getSideBar,
  getInvoicesPolicy,
  getLoginAssets,
} from "../../store/actions"

import "./Navbar.scss"

class Navbar extends Component {
  signal = axios.CancelToken.source()
  state = {
    activeTab: "",
    isAuthenticated: this.props.isAuthenticated,
  }
  subdomain = window.location.hostname.split(".")[0]

  componentDidMount() {
    const { getLoginAssets } = this.props

    /* Redux store action to request the login url override and subdomain logo.
    * This Navbar component renders on application load and is the entry point
    * for the global state of the entire App. */
    getLoginAssets(this.subdomain)

    this.getSidebarData()
    window.addEventListener("popstate", this.onRouteChanged)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.sideBar !== this.props.sideBar &&
      this.props.sideBar.length === 0 &&
      this.props.isAuthenticated
    ) {
      this.getSidebarData()
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
    window.removeEventListener("popstate", this.onRouteChanged)
  }

  getSidebarData = () => {
    if (this.state.isAuthenticated) {
      const { getAdminStatus, getInvoicesPolicy, getLoginAssets, getSideBar, userId } = this.props

      getSideBar()
      getInvoicesPolicy()

      getLoginAssets(this.subdomain)
      getAdminStatus(userId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
      this.setState(
        {
          ...this.state,
          isAuthenticated: nextProps.isAuthenticated,
        },
        () => {
          this.getSidebarData()
        }
      )
    }
  }

  logoutHandler = () => {
    this.props.logoutUser()
  }

  changeTab = (tabName) => {
    this.props.changeTab()
    if (typeof tabName === "string") {
      if (
        [
          "index",
          "dashboard",
          "library",
          "billing_and_payments",
          "reports",
          "system",
          "organizations",
          "submissions",
          "donations",
        ].includes(tabName)
      ) {
        const collapse = document.querySelectorAll(".collapse")
        collapse.forEach((coll) => {
          if (coll.className === "collapse show") {
            coll.className = "collapse"
          }
        })
      }
    }
  }

  render() {
    const { isAdmin, isAuthenticated, sideBar, policyRes } = this.props
    let navbar = null
    const userRoleLevel = localStorage.getItem("roleLevel")

    if (isAuthenticated) {
      navbar = (
        <React.Fragment>
          <nav
            className="sidebar-main accordion"
            id="navbarAccordion"
            style={{ left: `${this.props.show ? "0px" : "-300px"}` }}
          >
            <NavLink
              to="/"
              className="navbar-logo"
              onClick={() => this.changeTab("index")}
            >
              <span className="pr-2 pl-0">AMS</span>
              <span className="p-0">|</span>
              <span className="pl-2">
                {localStorage.organizationSubDomain !== null &&
                localStorage.organizationSubDomain !== "" &&
                typeof localStorage.organizationSubDomain !== "undefined"
                  ? upperCase(localStorage.organizationSubDomain)
                  : upperCase(window.location.hostname.split(".")[0])}
              </span>
            </NavLink>
            <NavLink
              to="/dashboard"
              id="dashboard-nav-link"
              className="navbar-brand"
              activeClassName="navbar-active"
              onClick={() => this.changeTab("dashboard")}
            >
              <i className="fas fa-tachometer-alt mr-3" />
              {window.locale
                ? window.locale.dashboard || "Dashboard"
                : "Dashboard"}
            </NavLink>
            {sideBar.account && (
              <NavLink
                to={`/users/${this.props.userId}/summary`}
                className="navbar-brand"
                id="account-nav-link"
                activeClassName="navbar-active"
                onClick={() => this.changeTab("account")}
              >
                <i className="fas fa-user-friends mr-3" />
                {langUtil("txt_account", "Account")}
              </NavLink>
            )}
            {sideBar.friends ? (
              <React.Fragment>
                <NavLink
                  to={
                    !sideBar.allow_profile_access_to_members
                      ? "/users"
                      : "/users/members"
                  }
                  id="friends-nav-link"
                  className="navbar-brand"
                  activeClassName="navbar-active active-open"
                  data-toggle={
                    !sideBar.allow_profile_access_to_members ? "collapse" : null
                  }
                  data-target={
                    !sideBar.allow_profile_access_to_members
                      ? "#collapseFriends"
                      : null
                  }
                  aria-expanded={
                    !sideBar.allow_profile_access_to_members ? "false" : null
                  }
                  aria-controls={
                    !sideBar.allow_profile_access_to_members
                      ? "collapseFriends"
                      : null
                  }
                >
                  <i className="fas fa-user-friends mr-3" />
                  {langUtil("txt_global_friends", "Friends")}
                  {!sideBar.allow_profile_access_to_members ? (
                    <i className="fas fa-sm fa-chevron-down float-right" />
                  ) : null}
                </NavLink>
                {!sideBar.allow_profile_access_to_members ? (
                  <div
                    className="collapse"
                    id="collapseFriends"
                    data-parent="#navbarAccordion"
                  >
                    <NavLink
                      to={"/users/members"}
                      id="friends-search-nav-link"
                      className="navbar-brand navbar-sub"
                      activeClassName="navbar-sub-active"
                      onClick={() => this.changeTab()}
                    >
                      <i className="fa fa-caret-right mr-3 fa-xs" />
                      Search
                    </NavLink>
                    <NavLink
                      to={`/users/member/new`}
                      id="add-new-friend-nav-link"
                      className="navbar-brand navbar-sub"
                      activeClassName="navbar-sub-active"
                      onClick={() => this.changeTab()}
                    >
                      <i className="fa fa-caret-right mr-3 fa-xs" />
                      Add New
                    </NavLink>
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}
            {sideBar.projects ? (
              <React.Fragment>
                <NavLink
                  to="/projects"
                  id="projects-nav-link"
                  className="navbar-brand"
                  activeClassName="navbar-active active-open"
                  data-toggle={
                    !sideBar.allow_project_access_to_members ? "collapse" : null
                  }
                  data-target={
                    !sideBar.allow_project_access_to_members
                      ? "#collapseProjects"
                      : null
                  }
                  aria-expanded={
                    !sideBar.allow_project_access_to_members ? "false" : null
                  }
                  aria-controls={
                    !sideBar.allow_project_access_to_members
                      ? "collapseProjects"
                      : null
                  }
                >
                  <i className="fas fa-folder-open mr-3" />
                  {window.locale
                    ? window.locale.projects || "Projects"
                    : "Projects"}
                  {!sideBar.allow_project_access_to_members ? (
                    <i className="fas fa-sm fa-chevron-down float-right" />
                  ) : null}
                </NavLink>
                {!sideBar.allow_project_access_to_members ? (
                  <div
                    className="collapse"
                    id="collapseProjects"
                    data-parent="#navbarAccordion"
                  >
                    <NavLink
                      to="/projects"
                      id="projects-search-nav-link"
                      className="navbar-brand navbar-sub"
                      activeClassName="navbar-sub-active"
                      onClick={() => this.changeTab()}
                    >
                      <i className="fa fa-caret-right mr-3 fa-xs" />
                      Search
                    </NavLink>
                    <NavLink
                      to="/projects/new"
                      id="add-new-project-nav-link"
                      className="navbar-brand navbar-sub"
                      activeClassName="navbar-sub-active"
                      onClick={() => this.changeTab()}
                    >
                      <i className="fa fa-caret-right mr-3 fa-xs" />
                      Add New
                    </NavLink>
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}
            {sideBar?.grant_campaigns && (
              <React.Fragment>
                <NavLink
                  to="/campaigns"
                  id="campaigns-nav-link"
                  className="navbar-brand"
                  activeClassName="navbar-active active-open"
                  data-toggle="collapse"
                  data-target="#collapseCampaigns"
                  aria-expanded="false"
                  aria-controls="collapseCampaigns"
                >
                  <i className="fas fa-folder-open mr-3" />
                  {window.locale
                    ? window.locale.campaigns || "Campaigns"
                    : "Campaigns"}
                  <i className="fas fa-sm fa-chevron-down float-right" />
                </NavLink>
                <div
                  className="collapse"
                  id="collapseCampaigns"
                  data-parent="#navbarAccordion"
                >
                  <NavLink
                    to="/campaigns"
                    id="campaigns-search-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Search
                  </NavLink>
                  <NavLink
                    to="/campaigns/new"
                    id="add-new-campaign-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Add New
                  </NavLink>
                </div>
              </React.Fragment>
            )}
            {sideBar?.grant_applications && (
              <React.Fragment>
                <NavLink
                  to="/grant_applications"
                  id="campaigns-nav-link"
                  className="navbar-brand"
                  activeClassName="navbar-active active-open"
                  data-toggle="collapse"
                  data-target="#collapseGrantApplications"
                  aria-expanded="false"
                  aria-controls="collapseGrantApplications"
                >
                  <i className="fas fa-folder-open mr-3" />
                  {window.locale
                    ? window.locale.grant_applications || "Applications"
                    : "Applications"}
                  <i className="fas fa-sm fa-chevron-down float-right" />
                </NavLink>
                <div
                  className="collapse"
                  id="collapseGrantApplications"
                  data-parent="#navbarAccordion"
                >
                  <NavLink
                    to="/grant_applications"
                    id="campaigns-search-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    My Applications
                  </NavLink>
                  <NavLink
                    to="/grant_applications/new"
                    id="campaigns-search-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    New Application
                  </NavLink>
                </div>
              </React.Fragment>
            )}
            {sideBar.our_stuff ? (
              <React.Fragment>
                <NavLink
                  to="/assets"
                  id="assets-nav-link"
                  className="navbar-brand"
                  activeClassName="navbar-active active-open"
                  data-toggle="collapse"
                  data-target="#collapseOurStuff"
                  aria-expanded="false"
                  aria-controls="collapseOurStuff"
                >
                  <i className="fas fa-briefcase mr-3" />
                  {langUtil("txt_global_stuff", "Our Stuff")}
                  <i className="fas fa-sm fa-chevron-down float-right" />
                </NavLink>
                <div
                  className="collapse"
                  id="collapseOurStuff"
                  data-parent="#navbarAccordion"
                >
                  <NavLink
                    to="/assets"
                    id="assets-search-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                    exact
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Search
                  </NavLink>
                  <NavLink
                    to="/assets/booking_activity"
                    id="assets-activity-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Activity
                  </NavLink>
                  <NavLink
                    to="/assets/checked_out"
                    id="assets-checked-out-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Checked Out
                  </NavLink>
                  <NavLink
                    to="/assets/rental_requests"
                    id="assets-rental-request-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Rental Requests
                  </NavLink>
                  <NavLink
                    to="/assets/new"
                    id="add-new-asset-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Add New
                  </NavLink>
                </div>
              </React.Fragment>
            ) : null}
            {sideBar.library ? (
              <NavLink
                to="/libraries"
                id="libraries-nav-link"
                className="navbar-brand"
                activeClassName="navbar-active"
                onClick={() => this.changeTab("library")}
              >
                <i className="fas fa-book mr-3" />
                {window.locale ? window.locale.library || "Library" : "Library"}
              </NavLink>
            ) : null}
            {sideBar.programs ? (
              <React.Fragment>
                <NavLink
                  to="/programs"
                  id="programs-nav-link"
                  className="navbar-brand"
                  activeClassName="navbar-active active-open"
                  data-toggle="collapse"
                  data-target="#collapsePrograms"
                  aria-expanded="false"
                  aria-controls="collapsePrograms"
                >
                  <i className="fas fa-calendar-alt mr-3" />
                  {window.locale
                    ? window.locale.programs || "Programs"
                    : "Programs"}
                  <i className="fas fa-sm fa-chevron-down float-right" />
                </NavLink>
                <div
                  className="collapse"
                  id="collapsePrograms"
                  data-parent="#navbarAccordion"
                >
                  <NavLink
                    to="/programs/calendar_view"
                    id="programs-calendar-view-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Calendar View
                  </NavLink>
                  <NavLink
                    to="/programs/list_view"
                    id="programs-list-view-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    List View
                  </NavLink>
                  <NavLink
                    to="/programs/new"
                    id="add-new-program-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Add New
                  </NavLink>
                </div>
              </React.Fragment>
            ) : null}
            {sideBar.billing_and_payments ? (
              <React.Fragment>
                <NavLink
                  to="/invoices"
                  id="invoices-nav-link"
                  className="navbar-brand"
                  activeClassName="navbar-active active-open"
                  data-toggle="collapse"
                  data-target="#collapseInvoices"
                  aria-expanded="false"
                  aria-controls="collapseInvoices"
                >
                  <i className="fas fa-wallet mr-3" />
                  {window.locale
                    ? window.locale.billing_payments || "Billing & Payments"
                    : "Billing & Payments"}
                  <i className="fas fa-sm fa-chevron-down float-right" />
                </NavLink>
                <div
                  className="collapse"
                  id="collapseInvoices"
                  data-parent="#navbarAccordion"
                >
                  <NavLink
                    to="/invoices"
                    id="our-payment-invoices-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fa fa-caret-right mr-3 fa-xs" />
                    Our Payments
                  </NavLink>
                  {policyRes.is_third_party_payment_visible && (
                    <NavLink
                      to="/invoices/third_party_payments/unpaid"
                      id="third-party-invoices-nav-link"
                      className="navbar-brand navbar-sub"
                      activeClassName="navbar-sub-active"
                      onClick={() => this.changeTab()}
                    >
                      <i className="fa fa-caret-right mr-3 fa-xs" />
                      Third Party Payments
                    </NavLink>
                  )}
                </div>
              </React.Fragment>
            ) : null}
            {sideBar.reports ? (
              <NavLink
                to="/reports"
                id="reports-nav-link"
                className="navbar-brand"
                activeClassName="navbar-active"
                onClick={() => this.changeTab("reports")}
              >
                <i className="fas fa-chart-pie mr-3" />
                {window.locale ? window.locale.reports || "Reports" : "Reports"}
              </NavLink>
            ) : null}
            {isAdmin && sideBar.submissions && (
              <NavLink
                to="/submissions"
                id="submissions-nav-link"
                className="navbar-brand"
                activeClassName="navbar-active"
                onClick={() => this.changeTab("submissions")}
              >
                <i className="fas fa-layer-group mr-3" />
                {window.locale
                  ? window.locale.submissions || "Submissions"
                  : "Submissions"}
              </NavLink>
            )}
            {sideBar.flags ? (
              <NavLink
                to="/flags"
                id="flags-nav-link"
                className="navbar-brand"
                activeClassName="navbar-active"
                onClick={() => this.changeTab()}
              >
                <i className="fas fa-flag mr-3" />
                {window.locale ? window.locale.flags || "Flags" : "Flags"}
              </NavLink>
            ) : null}
            {sideBar.volunteer ? (
              <NavLink
                to="/volunteer_tickets"
                id="volunteers-nav-link"
                className="navbar-brand"
                activeClassName="navbar-active"
                onClick={() => this.changeTab()}
              >
                <i className="fas fa-hands-helping mr-3" />
                {window.locale
                  ? window.locale.volunteer_tickets || "Volunteer Tickets"
                  : "Volunteer Tickets"}
              </NavLink>
            ) : null}
            {sideBar.system ? (
              <NavLink
                to="/system_settings"
                id="system-settings-nav-link"
                className="navbar-brand"
                activeClassName="navbar-active"
                onClick={() => this.changeTab("system")}
              >
                <i className="fas fa-cog mr-3" />
                {window.locale ? window.locale.system || "System" : "System"}
              </NavLink>
            ) : null}
            {userRoleLevel === "site_admin" || sideBar.language ? (
              <>
                <NavLink
                  to="/organizations"
                  isActive={(match, location) => {
                    return (
                      location.pathname === "/organizations" ||
                      location.pathname === "/languages" ||
                      location.pathname.includes("ams_super_admins")
                    )
                  }}
                  id="super-admin-nav-link"
                  className="navbar-brand"
                  activeClassName="navbar-active active-open"
                  data-toggle="collapse"
                  data-target="#collapseSuperAdmin"
                  aria-expanded="false"
                  aria-controls="collapseSuperAdmin"
                >
                  <i className="fas fa-user-cog mr-3" />
                  AMS Super Admin
                  <i className="fas fa-sm fa-chevron-down float-right" />
                </NavLink>
                <div
                  className="collapse"
                  id="collapseSuperAdmin"
                  data-parent="#navbarAccordion"
                >
                  <NavLink
                    to="/ams_super_admins"
                    id="organizations-nav-link"
                    className="navbar-brand navbar-sub"
                    activeClassName="navbar-sub-active"
                    onClick={() => this.changeTab()}
                  >
                    <i className="fas fa-clipboard-list mr-3" />
                    Access Credentials
                  </NavLink>
                  {sideBar.organizations && (
                    <NavLink
                      to="/organizations"
                      id="organizations-nav-link"
                      className="navbar-brand navbar-sub"
                      activeClassName="navbar-sub-active"
                      onClick={() => this.changeTab()}
                    >
                      <i className="fas fa-building mr-3" />
                      {window.locale
                        ? window.locale.organizations || "Organizations"
                        : "Organizations"}
                    </NavLink>
                  )}
                  {sideBar.language && (
                    <NavLink
                      to="/languages"
                      id="languages-nav-link"
                      className="navbar-brand navbar-sub"
                      activeClassName="navbar-sub-active"
                      onClick={() => this.changeTab()}
                    >
                      <i className="fas fa-language mr-3" />
                      {window.locale
                        ? window.locale.languages || "Languages"
                        : "Languages"}
                    </NavLink>
                  )}
                  {sideBar.labels && (
                    <NavLink
                      to="/labels"
                      id="labels-nav-link"
                      className="navbar-brand navbar-sub"
                      activeClassName="navbar-sub-active"
                      onClick={() => this.changeTab("label")}
                    >
                      <i className="fas fa-tags mr-3" />
                      Labels
                    </NavLink>
                  )}
                </div>
              </>
            ) : (
              <>
                {sideBar.organizations && (
                  <NavLink
                    to="/organizations"
                    id="organizations-nav-link"
                    className="navbar-brand"
                    activeClassName="navbar-active"
                    onClick={() => this.changeTab("organizations")}
                  >
                    <i className="fas fa-building mr-3" />
                    {window.locale
                      ? window.locale.organizations || "Organizations"
                      : "Organizations"}
                  </NavLink>
                )}
                {sideBar.language && (
                  <NavLink
                    to="/languages"
                    id="languages-nav-link"
                    className="navbar-brand"
                    activeClassName="navbar-active"
                    onClick={() => this.changeTab("organizations")}
                  >
                    <i className="fas fa-language mr-3" />
                    {window.locale
                      ? window.locale.languages || "Languages"
                      : "Languages"}
                  </NavLink>
                )}
              </>
            )}
            {sideBar.donations ? (
              <NavLink
                to="/donations"
                id="donations-nav-link"
                className="navbar-brand"
                activeClassName="navbar-active"
                onClick={() => this.changeTab("donations")}
              >
                <i className="fas fa-hand-holding-usd mr-3" />
                Donations
              </NavLink>
            ) : null}
            <div
              className="navbar-brand"
              id="logout-button"
              onClick={this.logoutHandler}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-sign-out-alt mr-3"></i>
              Logout
            </div>
          </nav>

          <nav
            className="top-nav bg-light"
            style={{
              marginLeft: `${this.props.show ? "300px" : "0px"}`,
            }}
          >
            <button
              className="btn btn-lg btn-link"
              id="toggle-sidebar-icon"
              onClick={() => this.props.toggleSidebar()}
            >
              <i className="fas fa-bars text-dark" />
            </button>
          </nav>
        </React.Fragment>
      )
    }
    return <div>{navbar}</div>
  }
}

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  sidebar: PropTypes.object,
  policyRes: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    ...state.auth,
    loginAssets: state.loginAssets,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    getSideBar: (params) => dispatch(getSideBar()),
    getInvoicesPolicy: (params) => dispatch(getInvoicesPolicy()),
    getLoginAssets: (subdomain) => dispatch(getLoginAssets(subdomain)),
    getAdminStatus: (userId) => dispatch(getAdminStatus(userId)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
