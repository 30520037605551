import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "../../configAxios";
import { title, snakeCase } from "change-case";
import Button from "../UI/button/Button";
import LanguagesList from "./LanguagesList";
import ConstantsList from "./ConstantsList";

class Languages extends Component {
  signal = axios.CancelToken.source();
  state = {
    activeTab: "Languages"
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab: typeof this.props.match.params.tab !== "undefined"
        ? title(this.props.match.params.tab)
        : "Languages"
    })
  }

  toggleTab = activeTab => {
    this.setState({ activeTab });
    this.props.history.push(`/languages/${snakeCase(activeTab)}`);
  };

  render() {
    const tabNames = ["Languages", "Constants"];
    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.toggleTab(tab)}
        className={`nav-item font-weight-bold px-4 ${this.state.activeTab === tab ? "active-tab" : "inactive-tab"}`}
      >
        {tab}
      </li>
    ))
    return (
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h3>Languages</h3>
          </div>
        </div>
        <div className="card language w-100">
          <ul className="nav nav-tabs">
            {tabs}
          </ul>
          {this.state.activeTab === "Languages" ?
            <LanguagesList />
            :
            <ConstantsList />
          }
        </div>
      </div>
    );
  }
}

export default Languages;
