import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import queryString from "query-string"

import axios from "../../configAxios"
import langUtil from "../../utils/langUtils"
import SearchFilter from "../common/searchFilter/SearchFilter"

import MemberItems from "./MemberItems"
import MemberSearch from "./MemberSearch"
import Tabs from "../formTabs"

class Assets extends Component {
  signal = axios.CancelToken.source()
  state = {
    loading: true,
    members: null,
    totalCount: null,
    queryParams: {
      type: "search_and_browse",
      page: 1,
    },
    tagName: queryString.parse(this.props.history.location.search).tag_name,
    tabs: ["Members", "Experts"],
    tabsExperts: ["Members"],
    activeTab: "Members",
    metaData: null,
    disabled: true,
    searchType: null,
    perPage: 10,
    friendsVar: "",
    nonMemberText: null,
    staffText: null,
    accountingText: null,
    experts: this.props.experts,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getUsers()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getUsers = () => {
    this.setState({ loading: true })
    let tagName = null
    if (this.state.searchType !== "friends" && this.state.tagName) {
      tagName = this.state.tagName
    }
    if (
      this.state.queryParams.sub_type === "organization_labels" &&
      this.state.queryParams.tag_name
    )
      tagName = this.state.queryParams.tag_name
    axios
      .get("/users", {
        params: {
          ...this.state.queryParams,
          tag_name: tagName,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const stateData = {}
        const { users } = res.data
        stateData.loading = false
        stateData.disabled = false
        stateData.members = []
        if (!_.isEmpty(users)) stateData.members = res.data.users
        if (_.isEmpty(res.data.meta)) {
          stateData.metaData = null
          stateData.totalCount = null
          stateData.members = []
        } else {
          stateData.totalCount = !_.isUndefined(users)
            ? res.data.meta.total_count
            : null
          stateData.metaData = !_.isUndefined(users) ? res.data.meta : null
        }
        stateData.friendsVar = langUtil("txt_global_friends", "Friends")
        stateData.nonMemberText = res.data.meta.non_member_text
        stateData.staffText = res.data.meta.staff_text
        stateData.accountingText = res.data.meta.accounting_text
        this.setState({ ...stateData })
      })
  }

  searchHandler = (params, searchType) => {
    const { queryParams } = this.state
    this.setState(
      {
        totalCount: null,
        queryParams: {
          ...queryParams,
          ...params,
        },
        disabled: true,
        searchType: searchType,
      },
      () => this.getUsers()
    )
  }

  setLoading = () => {
    this.setState({ loading: true })
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    const { queryParams } = this.state
    this.setState(
      {
        queryParams: {
          ...queryParams,
          page: page.selected + 1,
        },
      },
      () => this.getUsers()
    )
    window.scrollTo(0, 0)
  }

  inputChangeHandler = (perPage) => {
    window.scrollTo(0, 0)
    this.setState(
      {
        queryParams: {
          ...this.state.queryParams,
          per_page: perPage,
          page: 1,
        },
        perPage,
      },
      () => this.getUsers()
    )
    window.scrollTo(0, 0)
  }

  render() {
    const { loading, members, totalCount, perPage } = this.state
    return (
      <React.Fragment>
        <div className="col-lg-4">
          <MemberSearch
            isAdmin={this.props.isAdmin}
            disabled={this.state.disabled}
            searchHandler={this.searchHandler}
            setLoading={this.setLoading}
            metaData={this.state.metaData}
            friendsVar={this.state.friendsVar}
            nonMemberText={this.state.nonMemberText}
            staffText={this.state.staffText}
            accountingText={this.state.accountingText}
          />
        </div>
        <div className="col-lg-8">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-white p-0 border-bottom-0">
                  <Tabs
                    tabs={
                      this.props.experts
                        ? this.state.tabs
                        : this.state.tabsExperts
                    }
                    removeExpertsTab={true}
                    activeTab={this.state.activeTab}
                    tabChangedHandler={this.props.tabChangedHandler}
                    hrMargin={157}
                  />
                </div>
                <div className="row">
                  <div className="col-lg-5 offset-lg-7 col-md-5 offset-md-7 col-sm-6 offset-sm-6 mt-2">
                    <SearchFilter
                      perPage={this.state.perPage}
                      inputChangeHandler={this.inputChangeHandler}
                    />
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="card-text">
                    <MemberItems
                      members={members}
                      totalCount={totalCount}
                      loading={loading}
                      forcePage={this.state.queryParams.page - 1}
                      perPage={perPage}
                      paginationHandler={this.paginationHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Assets)
