import React, { Component } from "react"
import Switch from "react-switch"
import PropTypes from "prop-types"

import "./ToggleSwitch.css"

class ToggleSwitch extends Component {
  render() {
    const {
      checked,
      onChange,
      disabled,
      customCheckContent,
      customUncheckContent,
    } = this.props

    const checkedIcon = (
      <p className="w-100 text-right custom-toggle-text text-white text-uppercase text-toggle-checked m-0">
        {`${customCheckContent || "Yes"}`}
      </p>
    )

    const uncheckedIcon = (
      <div>
        <p className="w-100 text-left custom-toggle-text text-toggle-unchecked text-uppercase m-0 pr-1">
        {`${customUncheckContent || "No"}`}
      </p>
      </div>
    )

    return (
      <div className="custom-react-switch-container">
        <Switch
          width={65}
          height={25}
          className="custom-react-switch"
          onColor="#337ab7"
          offColor="#eceef0"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          checkedIcon={checkedIcon}
          uncheckedIcon={uncheckedIcon}
        />
      </div>
    )
  }
}

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  customCheckContent: PropTypes.string,
  customUncheckContent: PropTypes.string,
}

ToggleSwitch.defaultProps = {
  checked: false,
  onChange: () => null,
  disabled: false,
}

export default ToggleSwitch
