import React from "react"
import UserInfoPanel from "./UserInfoPanel"

const PhoneNumber = (props) => {
  const user = props.user
  return (
    <>
      {(user.mobile_phone || user.home_phone) && (
        <UserInfoPanel>{user.mobile_phone || user.home_phone}</UserInfoPanel>
      )}
    </>
  )
}

export default PhoneNumber
