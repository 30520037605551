import React from 'react'
import ApplicationFormItem from "./applicationFormItem";

export default function ApplicationFormTable({forms, grantApplication}) {

  const getApplicationForForm = (form_id) => {
    return grantApplication.application_forms.find(application => application.form_id === form_id)
  }

  return (
    <table className="w-100">
      <colgroup>
        <col className="w-25" />
        <col className="w-25" />
        <col className="w-25" />
      </colgroup>
      <thead className="">
      <tr>
        <th>Form Name</th>
        <th>Updated</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      {forms.map((form, index) => {
        return (
          <ApplicationFormItem
            key={index}
            form={form}
            grantApplication={grantApplication}
            applicationFormItem={getApplicationForForm(form.id)}
          />
        )})
      }
      </tbody>
    </table>
  )
}
