import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import Payment from "./Payment";
import Button from "../../UI/button/Button";
import Modal from "../../UI/Modal";
import axios from "../../../configAxios";
import errorStatusHandler from "../../errorPages/UserNotAuthorized";

class Buttons extends Component {
  state = {
    showPaymentModal: false,
    accounting: this.props.invoice.sent_to_accounting,
    isCurrent: this.props.invoice.is_current
  };

  accountingHandler = () => {
    axios
      .post("/invoices/update_sent_to_accounting", {
        id: this.props.invoice.id
      })
      .then(res => {
        const { success } = res.data.json;
        if (success) {
          toast.success("Sent Invoice to accounting.");
          this.props.reload();
        } else {
          errorStatusHandler(res);
        }
      });
  };

  paidStatusHandler = status => {
    axios.put(`/invoices/${this.props.invoice.id}`, { status }).then(res => {
      const { success } = res.data.json;
      if (success) {
        this.props.reload();
        toast.success(
          `Invoice marked as ${status === 1 ? "paid" : "current"} successfully`
        );
      } else {
        toast.error("Failed to mark");
      }
    });
  };

  render() {
    const { isCurrent, accounting, showPaymentModal } = this.state;
    const { invoice, policyRes } = this.props;
    const paymentModal = (
      <Modal title="Make a Payment" show={showPaymentModal} flag={true} hide={() => this.setState({ showPaymentModal: false })}>
        <Payment
          closeModal={() => this.setState({ showPaymentModal: false })}
          invoiceId={this.props.invoice.id}
          invoiceType={this.props.invoice.invoice_type}
          accountId={this.props.invoice.account_id}
          invoiceReference={this.props.invoice.reference}
          reload={() => this.props.reload()}
        />
      </Modal>
    );
    return (
      <React.Fragment>
        <div className="d-flex justify-content-end">
          {isCurrent ? (
            <>
              {parseFloat(invoice.total_outstanding.split("$")[1]) > 0 &&
                invoice.online_payment_enabled && (
                  <Button
                    type="success"
                    style={{ marginRight: "10px" }}
                    clicked={() =>
                      this.props.history.push(
                        `/invoices/${this.props.invoice.id}/online_payment`
                      )
                    }
                  >
                    Pay Online
                  </Button>
                )}
              {policyRes.can_make_payment &&
                <Button
                  type="success"
                  style={{ marginRight: "10px" }}
                  clicked={() => { window.scrollTo(0, 0); this.setState({ showPaymentModal: true }) }}
                >
                  Make Payment
                </Button>
              }
              {policyRes.can_update &&
                < Button
                  type="primary"
                  style={{ marginRight: "10px" }}
                  clicked={() => this.paidStatusHandler(1)}
                >
                  Mark Paid
                </Button>
              }
              {policyRes.can_update &&
                <Button
                  type="warning"
                  style={{ marginRight: "10px" }}
                  clicked={() =>
                    this.props.history.push({
                      pathname: `/invoices/edit/${this.props.invoice.id}`,
                      state: { tab: this.props.match.params.tab }
                    })
                  }
                >
                  Edit Invoice
                </Button>
              }
            </>
          ) : (
              policyRes.can_update && !invoice.is_for_a_program &&
              <Button
                type="success"
                style={{ marginRight: "10px" }}
                clicked={() => this.paidStatusHandler(0)}
              >
                Mark Current
            </Button>
            )}
          {!accounting && policyRes.can_send_to_accounting && (
            <Button
              type="danger"
              style={{ marginRight: "10px" }}
              clicked={this.accountingHandler}
            >
              Send to Accounting
            </Button>
          )}
          <div>{paymentModal}</div>
        </div>
      </React.Fragment >
    );
  }
}

export default withRouter(Buttons);
