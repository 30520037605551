import React, { Component, createRef } from 'react'
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import axios from "../../../../configAxios";

class MailListCSV extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.csvRef = createRef();
    this.state = {
      disabled: false,
      report: [],
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being cancelled");
  }
  
  getMailListCSV = () => {
    if (this.state.disabled) return;
    
    this.setState({ disabled: true });
    axios.get("/mailing_lists/download_csv", {
      params: {
        id: this.props.mailListId
      },
      cancelToken: this.signal.token,
    }).then(res => {
      if (res.data.json && res.data.json.data) {
        this.setState({
          report: res.data.json.data.trim("\n").concat(","),
        }, () => {
          this.csvRef.current.link.click();
          this.setState({ disabled: false });
        });
      } else {
        toast.error("Something went wrong");
        this.setState({ disabled: false });
      }
    })
  } 

  render() {
    const { disabled, report } = this.state;
    return (
      <>
        <i 
          className={`fas fa-download ${
            disabled
              ? "cursor-not-allowed"
              : "cursor"
          }`}
          style={{
            opacity: disabled
              ? 0.7
              : 1
          }}
          onClick={this.getMailListCSV} 
        />
        <CSVLink 
          data={report}
          filename={`mailing_list_${this.props.mailListId}.csv`}
          className="d-none"
          target="_self"
          ref={this.csvRef}
        />
      </>
    )
  }
}

MailListCSV.propTypes = {
  mailListId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
}

export default MailListCSV
