import React, { Component } from "react";
import { Link } from "react-router-dom";

import ListItem from "./ListItem";
import langUtils from "../../../utils/langUtils";

import Spinner from "../../UI/Spinner";
import Button from "../../UI/button/Button";
import Pagination from "../../UI/Pagination";
import axios from "../../../configAxios";
import SearchFilter from "../../common/searchFilter/SearchFilter";

import "./index.css";

class List extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      superAdminList: [],
      loading: false,
      pagination: {
        totalResults: 0,
        currentPage: 0,
      },
      perPage: 10,
    }
  }
  
  componentDidMount() {
    this.getSiteAdmins()
  }

  componentWillUnmount() {
    this.signal.cancel("Requests are being cancelled");
  }

  getSiteAdmins = () => {
    window.scrollTo(0,0);
    this.setState({ loading: true });
    const { 
      pagination: {
        currentPage
      }, 
      perPage 
    } = this.state;
    const params = {
      type: "site_admin",
      page: currentPage + 1,
      per_page: perPage
    }
    axios.get("/users", {
      params,
      cancelToken: this.signal.token 
    }).then(res => {
      const { users, meta } = res.data;
      this.setState({
        superAdminList: users || [],
        pagination: {
          ...this.state.pagination,
          totalResults: meta && meta.total_count
            ? meta.total_count
            : 0
        },
        loading: false, 
      })
    })
  }

  paginationHandler = (page, perPage) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        currentPage: page ? page.selected : 0,
        ...perPage && { perPage }
      }
    }, this.getSiteAdmins);
  }

  render() {
    const { loading, superAdminList, pagination, perPage } = this.state;
    const { totalResults , currentPage } = pagination;

    let from = (perPage * (currentPage + 1));
    from -= perPage;
    from++;
    let to = perPage * (currentPage + 1);
    if (to > totalResults) {
      to = totalResults;
    }
    let showing = `Showing ${from} to ${to} of ${totalResults} entries`;

    return (
      <div className="container ams-super-admins">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-text">
                <div className="row align-items-center">
                  <div className="col-md-10">
                    <h4 className="m-4" >Super Admins</h4>
                  </div>
                  <div className="col-md-2 text-md-right">
                    <Link to="/ams_super_admins/new">
                      <Button type="primary" className="mr-md-4 ml-md-0 ml-4">
                      {langUtils("txt_global_add_new", "Add New")}
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="mx-4 mb-4">
                  <div className="row">
                    <div className="col-12">
                      {superAdminList && !!superAdminList.length && (
                        <div className="row">
                          <div className="col-lg-5 offset-lg-7 col-md-6 offset-md-6">
                            <div className="search-filter-negative-margin">
                              <SearchFilter 
                                perPage={perPage}
                                inputChangeHandler={(perPageRes) => this.paginationHandler(null, perPageRes)}
                              />
                            </div>
                          </div>
                        </div>)}
                      {loading 
                        ? <Spinner />
                        : (
                          <>
                            {superAdminList && !superAdminList.length ? 
                              (<div className="text-center">
                                <h4 className="my-5">No Super Admins Found</h4>
                              </div>)
                            : (
                              <div className="table-responsive">
                                <table className="table table-bordered table-hover">
                                  <thead className="text muted border-top">
                                    <tr>
                                      <th scope="col">Email</th>
                                      <th scope="col">Name</th>
                                      <th scope="col" className="w-20" >Last Login</th>
                                      <th scope="col">Login URL</th>
                                      <th scope="col" className="w-12">
                                        Actions
                                      </th>
                                      <th scope="col"
                                      >
                                        Enabled  
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {superAdminList.map(superAdmin => (
                                      <ListItem 
                                        key={superAdmin.id}
                                        name={superAdmin.full_name}
                                        email={superAdmin.email}
                                        loginUrl={
                                          superAdmin.data && superAdmin.data.last_login_url
                                            ? superAdmin.data.last_login_url
                                            : ""
                                        }
                                        lastLogin={superAdmin.last_login}
                                        id={superAdmin.id}
                                        status={superAdmin.status}
                                      />
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </>)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 text-lg-left text-center font-weight-bold">
                      <div className="mt-3">
                        {showing}
                      </div>
                    </div>
                    <div className="col-lg-6 text-lg-right text-center">
                      <Pagination
                        pageCount={
                          pagination.totalResults /
                          perPage
                        }
                        currentPage={pagination.currentPage}
                        handlePageClick={this.paginationHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default List
