import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from '../../../../configAxios'
import JuryMemberSelect from './JuryMemberSelect'
import Button from "../../../UI/button/Button";
import FormButton from "../../../UI/button/FormButton";
import { createObjects, deleteObjects } from "../../../../store/actions";
import _ from "lodash"

export default function EditJuryMembers({ grantCampaignId, existingJury, organizationId, closeModal }) {

  const [allJuryMembers, setAllJuryMembers] = useState(null)
  const [selectedJury, setSelectedJury] = useState(null)
  const dispatch = useDispatch()

  const getAllJuryMembers = () => {
    axios.get(`/users/search?organization_id=${organizationId}`)
    .then(res => setAllJuryMembers(res.data.json.data))
  }

  const findMatchingMembers = () => { //In order to make the drop down list remove the existing jury members they have to come from the same array
    if (existingJury.length > 0) {
      return existingJury.map(item => {
        return allJuryMembers.find(member => item.jury_member_id === member.id)
      })
    } else {
      return []
    }
  }

  const separateJuryMembers = () => {
    const deletedMembers = existingJury.filter(member => {
      return !selectedJury.find(item => item.id === member.jury_member_id)
    })

    const addedMembers = selectedJury.filter(item => {
      return !existingJury.find(member => item.id === member.jury_member_id)
    })

    return {
      deletedMembers: deletedMembers.map(member =>( {id: member.id })),
      addedMembers: addedMembers.map(item => ({ grant_campaign_id: grantCampaignId, jury_member_id: item.id }))
    }
  }

  const createJuryMembers = (addedMembers) => {
    const requestData = {
      route: "/grant_campaigns_jury_members",
      dataArray: addedMembers,
      object: 'grant_campaigns_jury_member',
      message: 'Review jury updated successfully',
      callback: () => closeModal()
    }
    dispatch(createObjects(requestData))
  }

  const deleteJuryMembers = (deletedMembers, callback) => {
    const deletionRequestData = {
      route: "/grant_campaigns_jury_members",
      dataArray: deletedMembers,
      object: 'grant_campaigns_jury_member',
      message: 'Jury members successfully removed',
      callback: callback
    }

    dispatch(deleteObjects(deletionRequestData))
  }

  const submitJury = (event) => {
    event.preventDefault()
    const {deletedMembers, addedMembers} = separateJuryMembers()
    
    if (!_.isEmpty(deletedMembers)) {
      let callback = !_.isEmpty(addedMembers) ? () => createJuryMembers(addedMembers) : () => closeModal()
      deleteJuryMembers(deletedMembers, callback)
    } else if (!_.isEmpty(addedMembers)) {
      createJuryMembers(addedMembers)
    }
  }

  useEffect(() => {
    !allJuryMembers && getAllJuryMembers()
  }, [])

  useEffect(() => {
    if (allJuryMembers && allJuryMembers.length > 0 && !selectedJury) {
      setSelectedJury(findMatchingMembers())
    }
  }, [allJuryMembers])

  return (
    <form onSubmit={submitJury}>
       { allJuryMembers && selectedJury &&
          <div className="mt-4">
            <JuryMemberSelect 
              juryMembers={allJuryMembers} 
              selectedJury={selectedJury} 
              setSelectedJury={setSelectedJury} 
            />
          </div>
        }
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            className="mr-2"
            clicked={closeModal}
          >
            Cancel
          </Button>
          <FormButton>Update Jury</FormButton>
        </div>
    </form>
  )
}
