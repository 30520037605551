import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import WorkShops from "./WorkShops";
import Pagination from "../UI/Pagination";
import Spinner from "../UI/Spinner";
import { connect } from "react-redux";
import "./ProgramItems.css";

const ProgramItems = ({programs, totalCount, setParams}) => {
  const [selectedPage, setSelectedPage] = useState(1);

  const paginationHandler = (page) => {
    window.scrollTo(0, 0)
    setSelectedPage(page.selected + 1)
    let params = {page: page.selected + 1}
    setParams(prev => ({...prev, ...params}))
  };

  const Content = () => {
    if (programs === null) return <Spinner />;
    if (programs.length === 0) return (
      <div className="d-flex justify-content-center">No data found</div>
    );

    return (
      programs.map((program, index) => (
      <WorkShops
        content={program}
        key={program.id}
        index={index}
        selectedPage={selectedPage - 1}
        lastPage={totalCount / 10}
        lastIndex={totalCount % 10}
      />
    ))
    )
  };
  
  return (
    <div className="program-items webkit-fill-available border-left">
    <div className="d-flex justify-content-between">
      <p>
        {totalCount
          ? <h6 className="mt-4 ml-4 pt-1">
            Total Programs: <strong>{totalCount}</strong>
          </h6>
          : ""}
      </p>
    </div>
    <hr className="mt-0" />
    <div className="list-content">
      <Content/>
      </div>
    {totalCount !== 0 
    && (
      <Pagination
        pageCount={totalCount / 10}
        forcePage={selectedPage - 1}
        handlePageClick={page => paginationHandler(page)}
        range={5}
      />
    )}
  </div>
  )
}

const mapStateToProps = state => {
  return {
    projects: state.projects
  };
};

export default withRouter(connect(mapStateToProps)(ProgramItems));
