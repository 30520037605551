import React, { Component } from "react"
import { Tooltip } from "react-lightweight-tooltip"
import { Link } from "react-router-dom"
import { formatDate } from "../../utils/formatDate"

import NoImage from "../../assests/images/no-image.png"

const greenRoundedStyle = {
  content: {
    backgroundColor: "white",
    cursor: "context-menu",
    color: "#000"
  },
  tooltip: {
    backgroundColor: "white",
    cursor: "context-menu",
    border: "solid white 1px ",
    boxShadow: "5px 5px 5px #aaaaaa,-2px -2px 5px #aaaaaa",
    width: 400,
    position: "absolute",
    left: -205,
    bottom: -60
  },
  arrow: {
    position: "absolute",
    cursor: "context-menu",
    width: "10",
    height: "20",
    left: "100.80%",
    bottom: "18%",
    transform: " rotate(-90deg)",
    marginLeft: "-5px",
    borderTop: "solid  white 5px",
    borderLeft: "solid transparent 5px",
    borderRight: "solid transparent 5px"
  },
  gap: {
    position: "absolute",
    cursor: "context-menu",
    width: "100%",
    height: "20px",
    bottom: "-20px"
  }
};

const greenRounded = {
  content: {
    backgroundColor: "white",
    cursor: "context-menu",
    color: "#000"
  },
  tooltip: {
    backgroundColor: "white",
    cursor: "context-menu",
    border: "solid white 1px ",
    boxShadow: "5px 5px 5px #aaaaaa,-2px -2px 5px #aaaaaa",
    width: 400,
    position: "absolute",
    left: -205,
    bottom: -260
  },
  arrow: {
    position: "absolute",
    width: "10",
    height: "20",
    left: "100.80%",
    bottom: "87%",
    cursor: "context-menu",
    transform: " rotate(-90deg)",
    marginLeft: "-5px",
    borderTop: "solid  white 5px",
    borderLeft: "solid transparent 5px",
    borderRight: "solid transparent 5px"
  },
  gap: {
    position: "absolute",
    cursor: "context-menu",
    width: "100%",
    height: "20px",
    bottom: "-20px"
  }
};

class WorkShops extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number_of_enrolled: this.props.content.number_of_enrolled,
      public: this.props.content.public
    };
  }

  updateEnrolement = () => {
    this.setState({ number_of_enrolled: this.state.number_of_enrolled + 1 });
  };

  render() {
  const {
    created_at, number_of_enrolled, max_participants, member_enrollment_price, enrollment_price,
    earlybird_cutoff, earlybird_discount, instructors, location, min_participants, photo, id,
    name, earliest_scheduled_program_date, status_string, description
  } = this.props.content

    let con = (
      <div style={{ marginRight: 20, marginLeft: 20, cursor: "context-menu" }}>
        <div className="d-flex justify-content-between font-15">
          <p classname="text-muted">Created:</p>
          <p className="font-weight-bold">
            <span>
              {
                formatDate(
                  new Date(created_at),
                  "YYYY/MM/DD")
              }
            </span>
          </p>
        </div>
        <div className="d-flex justify-content-between font-15">
          <p classname="text-muted">Enrollment:</p>
          <p style={{ fontWeight: "bold", color: "red" }}>
            {number_of_enrolled !== "" &&
              max_participants !== "" ? (
                <span>
                  {this.state.number_of_enrolled +
                    "/" +
                    max_participants}
                </span>
              ) : (
                ""
              )}
          </p>
        </div>
        <div className="d-flex justify-content-between font-15">
          <p classname="text-muted">For Members:</p>
          <p className="font-weight-bold">
            ${member_enrollment_price}
          </p>
        </div>
        <div className="d-flex justify-content-between font-15">
          <p classname="text-muted">For Non Members:</p>
          <p className="font-weight-bold">
            ${enrollment_price}
          </p>
        </div>
        <div className="d-flex justify-content-between font-15">
          <p classname="text-muted">
            Off before{" "}
            <span>
              {new Date(earlybird_cutoff)
                .toDateString()
                .substring(0, 11)}
            </span>
            :
          </p>
          <p className="font-weight-bold">
            $
            {earlybird_discount !== ""
              ? earlybird_discount
              : 0.0}
          </p>
        </div>
        <div className="d-flex justify-content-between font-15">
          <p classname="text-muted">Instructors:</p>
          <p className="font-weight-bold">{instructors}</p>
        </div>
        <div className="d-flex justify-content-between font-15">
          <p classname="text-muted">Location:</p>
          <p className="font-weight-bold">{location}</p>
        </div>
        <div className="d-flex justify-content-between font-15">
          <p classname="text-muted">Min Registrations:</p>
          <p className="font-weight-bold">
            {min_participants}
          </p>
        </div>
      </div>
    );

    const url = photo.photo.thumb.url ? photo.photo.thumb.url : NoImage;

    return (
      <>
        <div className="mx-3">
          <div className="row no-gutters">
            <div className="col-md-2 text-center">
              <a href={photo.photo.thumb.url} target="_blank" rel="noopener noreferrer">
                <div className="rounded-circle bg-cover" style={{
                  width: "100%",
                  paddingTop: "100%",
                  backgroundImage: `url(${url})`
                }} />
              </a>
            </div>
            <div className="col-md-10">
              <div className="d-flex justify-content-between ml-4">
                <Link
                  to={"/programs/" + id}
                  exact="true"
                  className="text-info font-weight-normal decoration-none"
                >
                  {name}
                </Link>
                <Tooltip
                  content={con}
                  data-event="click"
                  data-event-off="click"
                  styles={
                    this.props.index + 1 === 10
                      ? greenRoundedStyle
                      : this.props.selectedPage >= this.props.lastPage
                        ? this.props.index + 1 === this.props.lastIndex
                          ? greenRoundedStyle
                          : greenRounded
                        : greenRounded
                  }
                >
                  <i className="fas fa-ellipsis-v cursor-context-menu text-muted p-3" />
                </Tooltip>
              </div>
              <p className="ml-4 mb-2 font-weight-bold">
                Date:{" "}
                <span>
                  {
                    earliest_scheduled_program_date === null
                    ? "No date available"
                    : formatDate(new Date(earliest_scheduled_program_date), "YYYY/MM/DD")
                  }
                </span>
              </p>
              <p className="ml-4 mb-2">
                <span className="font-weight-bold">
                  Program Status:{" "}
                </span>
                {status_string === ""
                  ? " N/A"
                  : <span>{status_string}</span>
                }
              </p>
              <p  className="ml-4 mb-2">
                <span className="font-weight-bold">
                  Web Status:
                </span>
                { this.props.content.public ? " Shared" : " Not Shared" }
              </p>
              <p className="ml-4 mb-2">
                <span className="font-weight-bold">
                  Description:
                </span>
                {description === ""
                  ? " N/A"
                  : <p>{description}</p>
                }
              </p>
            </div>
          </div>
        </div>
        <hr />
      </>
    );
  }
}
export default WorkShops;
