import React, { Component } from "react"

import Input from "../../UI/input/Input"
import FormButton from "../../UI/button/FormButton"
import Button from "../../UI/button/Button"

import Modal from "../../UI/Modal"

class RentalAgreementModal extends Component {
  state = {
    thirdPartyOwners: {
      elementType: "select",
      elementConfig: {
        name: "",
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
        selectedOption: null,
      },
    },
    favaPercentage: "",
    rentalAgreementNotes: "",
    disabled: true,
  }

  componentWillReceiveProps(props) {
    const options = props.thirdPartyOwners.map((option) => {
      return {
        label: option.first_name + " " + option.last_name,
        value: option.id,
      }
    })
    this.setState({
      thirdPartyOwners: {
        ...this.state.thirdPartyOwners,
        elementConfig: {
          ...this.state.thirdPartyOwners.elementConfig,
          options,
        },
        selectedOption:
          this.props.edit &&
          this.props.rentalAgreementData &&
          this.props.rentalAgreementData.thirdPartyOwner
            ? options.filter(
                (owner) =>
                  owner.value === this.props.rentalAgreementData.thirdPartyOwner
              )[0]
            : null,
      },
      favaPercentage:
        this.props.edit && this.props.rentalAgreementData
          ? this.props.rentalAgreementData.favaPercentage
          : "",
      rentalAgreementNotes:
        this.props.edit && this.props.rentalAgreementData
          ? this.props.rentalAgreementData.rentalAgreementNotes
          : "",
    })
  }

  inputChangeHandler = (event, key, type) => {
    if (type === "select") {
      this.setState(
        {
          [key]: {
            ...this.state[key],
            selectedOption: event,
          },
        },
        this.checkIfThirdPartyOwnerAndFavaPercentagePresent
      )
    } else {
      this.setState(
        { [key]: event.target.value },
        this.checkIfThirdPartyOwnerAndFavaPercentagePresent
      )
    }
  }

  checkIfThirdPartyOwnerAndFavaPercentagePresent = () => {
    if (
      this.state.thirdPartyOwners.selectedOption !== null &&
      this.state.thirdPartyOwners.selectedOption.value !== null &&
      this.state.favaPercentage !== ""
    ) {
      this.setState({ disabled: false })
    } else {
      this.setState({ disabled: true })
    }
  }

  saveRentalAgreement = (event) => {
    event.preventDefault()
    const data = {
      thirdPartyOwner:
        this.state.thirdPartyOwners.selectedOption &&
        this.state.thirdPartyOwners.selectedOption.value,
      favaPercentage: this.state.favaPercentage,
      rentalAgreementNotes: this.state.rentalAgreementNotes,
    }
    this.setState({ disabled: true })
    this.props.saveRentalAgreement(data)
  }

  render() {
    return (
      <Modal
        show={this.props.rentalAgreementModal}
        title={"Third Party Rental Agreement"}
        hide={this.props.closeModal}
        closeModal={this.props.closeModal}
        closeButton
      >
        <form onSubmit={this.saveRentalAgreement}>
          <div className="mt-3">
            <div>
              <p>3rd Party Owner</p>
              <Input
                {...this.state.thirdPartyOwners}
                changed={(event) =>
                  this.inputChangeHandler(event, "thirdPartyOwners", "select")
                }
              />
            </div>
            <div>
              <p>{`${localStorage.organizationName}'s %`}</p>
              <Input
                elementType={"input"}
                elementConfig={{
                  required: !!(
                    this.state.thirdPartyOwners.selectedOption &&
                    this.state.thirdPartyOwners.selectedOption.value !== null
                  ),
                  type: "number",
                }}
                value={this.state.favaPercentage}
                changed={(event) =>
                  this.inputChangeHandler(event, "favaPercentage", "text")
                }
              />
            </div>
            <div>
              <p>Notes</p>
              <Input
                elementType={"textarea"}
                elementConfig={{ rows: "4" }}
                value={this.state.rentalAgreementNotes}
                changed={(event) =>
                  this.inputChangeHandler(event, "rentalAgreementNotes", "text")
                }
              />
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <FormButton
                  disabled={this.state.disabled}
                  style={{
                    cursor: this.state.disabled ? "no-drop" : "pointer",
                  }}
                >
                  Save
                </FormButton>
              </div>
              <div className="ml-2">
                <Button type="delete" clicked={this.props.closeModal}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    )
  }
}

export default RentalAgreementModal
