import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import dateFns from "date-fns";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { title } from "change-case";
import queryString from "query-string";

import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import Pagination from "../UI/Pagination";
import Spinner from "../UI/Spinner";
import axios from "../../configAxios";
import errorStatusHandler from "../errorPages/UserNotAuthorized";

import "./index.css";

class Billing extends Component {
  constructor(props) {
    super(props);

    this.searchHandler = this.searchHandler.bind(this);

    this.signal = axios.CancelToken.source();
    let { filter } = queryString.parse(this.props.location.search);
    filter = filter ? filter : "current";
    this.state = {
      invoices: null,
      currentCount: 0,
      pastDueCount: 0,
      thirdPartyPaymentsCount: 0,
      paidCount: 0,
      loading: true,
      query: "",
      perPage: 10,
      sortField: "id",
      sortOrder: "desc",
      activeTab: filter,
      reportData: [],
      typingTimeout: 0,
      totalCount: 0,
      policyRes: {}
    };
  }

  searchHandler = e => {
    let query = e.target.value;
    if (this.state.typingTimeout) clearTimeout(this.state.typingTimeout);
    this.setState({
      query,
      totalCount: 0,
      loading: true,
      invoices: [],
      typingTimeout: setTimeout(() => this.getInvoices(1), 1000)
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
    axios
      .get("invoices/policy", {
        params: {
          id: this.props.match.params.id
        }
      })
      .then(res => {
        this.setState({
          policyRes: res.data.json
        })
      })
    this.getInvoices(1);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
    this.signal.cancel("Request is being Cancelled");
  }

  getInvoices = page => {
    this.setState({ loading: true, invoices: null });
    const params = {
      filter: this.state.activeTab,
      search: this.state.query,
      page,
      per_page: this.state.perPage
    };
    params["order"] = {
      column: this.state.sortField,
      dir: this.state.sortOrder
    };
    axios
      .get("/invoices", { params, cancelToken: this.signal.token })
      .then(res => {
        if(res.status === 202) {
          errorStatusHandler(res);
          this.props.history.push("/dashboard");
        } else {
          this.setState({
            invoices: res.data.invoices,
            totalCount: res.data.meta.total_count,
            currentCount: res.data.meta.total_count_current,
            pastDueCount: res.data.meta.total_count_past_due,
            thirdPartyPaymentsCount:
              res.data.meta.total_count_third_party_payments,
            paidCount: res.data.meta.total_count_paid,
            loading: false
          });
        }
      })
      .catch(e => {
        if (e.response) {
          toast.error(e.response.data.error);
        }
        this.setState({ loading: false, invoices: [] });
      });
  };

  sortHandler = (sortOrder, sortField) => {
    this.setState(
      { sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc", sortField },
      () => {
        this.getInvoices(1);
      }
    );
  };

  handleTabFromProp = () => {
    let { filter } = queryString.parse(this.props.location.search);
    filter = filter ? filter : "current";
    this.setState({
      ...this.state,
      activeTab: filter
    });
  };

  toggleActiveTab = activeTab => {
    this.setState(
      {
        activeTab,
        query: "",
        totalCount: 0,
        invoices: [],
        sortField: "id",
        sortOrder: "desc"
      },
      () => {
        this.getInvoices(1);
      }
    );
    this.props.history.push(`/invoices?filter=${activeTab}`);
  };

  generateCSV = () => {
    const params = {
      filter: this.state.activeTab
    }
    axios
      .get("/invoices/export_csv", {
        params,
        cancelToken: this.signal.token
      })
      .then(res => {
        if(res.data.json.success) {
          this.setState(
            {
              reportData: res.data.json.data
            },
            () => this.refs.csv.link.click()
          );
        }
      })
      .catch(err => {
        const error = err.response.data.json;
        if (error.hasOwnProperty("errors")) {
          Object.keys(error).forEach(key => {
            toast.error(title(key) + " " + error[key]);
          });
        }
        if (error.hasOwnProperty("error")) {
          toast.error(error.error);
        }
      })
  };

  renderTableHeader = () => {
    const headerArr = {
      invoice_post_date: "Date",
      reference: "Reference",
      name_for_reference: "Name of Reference",
      description: "Description",
      total_due: "Total Due"
    };
    const sortFields = ["description", "total_due"];
    const tableHeaders = Object.keys(headerArr).map(key => (
      <th key={key} style={headerArr[key] === 'Description' ? { width: '25%' } : {width: '15%'}}>
        <div
          className={`d-flex justify-content-between ${!sortFields.includes(
            key
          ) && "cursor"}`}
          onClick={() => {
            if (sortFields.includes(key)) return;
            this.sortHandler(this.state.sortOrder, key);
          }}
        >
          <div>{headerArr[key]}</div>
          {!sortFields.includes(key) && (
            <div className="cursor position-relative text-muted d-flex justify-content-around ml-3">
              <i
                className="fas fa-caret-up"
                style={{
                  color:
                    this.state.sortField === key
                      ? this.state.sortOrder === "asc"
                        ? "#808080"
                        : "white"
                      : "#808080"
                }}
              />
              <i
                className="fas fa-caret-down caret-position position-relative"
                style={{
                  top: 8,
                  right: 9,
                  color:
                    this.state.sortField === key
                      ? this.state.sortOrder === "desc"
                        ? "#808080"
                        : "white"
                      : "#808080"
                }}
              />
            </div>
          )}
        </div>
      </th>
    ));

    return <tr className="border-top">{tableHeaders}</tr>;
  };

  render() {
    const { invoices, currentCount, pastDueCount, paidCount, activeTab, policyRes } = this.state;
    let tableBody;
    if (!this.state.loading) {
      tableBody =
        invoices &&
        invoices.map((invoice, index) => (
          <tr key={index}>
            <td>{`${dateFns.format(invoice.invoice_post_date,
              "ddd MMM DD YYYY"
            )}`}</td>
            <td>
              <Link to={`/invoices/${invoice.id}`} target="_self" className="text-info">
                {invoice.reference}
                <br/>
                {invoice?.external_tracking_number}
              </Link>
            </td>
            <td>{invoice.name_for_reference}</td>
            <td>{invoice.description_cached}</td>
            <td>
              {`${
                invoice.total_due_cached
                  ? `${invoice.total_due_cached}`
                  : invoice.total
                    ? invoice.total
                    : ""
                }`}
            </td>
          </tr>
        ));
    }

    let removeIcon;
    if (this.state.query !== "") {
      removeIcon = (
        <i
          className="fas fa-times remove-icon cursor"
          onClick={() =>
            this.setState({ query: "" }, () => this.getInvoices(1))
          }
        />
      );
    }

    return (
      <div className="container billing w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>{window.locale?.billing_payments}</h3>
          </div>
          {
            policyRes.can_create_floating &&
            <div className="col-6 text-right">
              <Button
                type="success"
                clicked={() =>
                  this.props.history.push({
                    pathname: "/invoices/new",
                    search: "?type=Floating"
                  })
                }
              >
                New Floating Invoice
              </Button>
            </div>
          }
        </div>

        <div className="card">
          <div className="card-body p-3">
            <div className="card-text">
              <div className="breadcrumb">
                <span
                  className={`cursor ${activeTab === "current" && "text-primary"}`}
                  onClick={() => this.toggleActiveTab("current")}
                >{`Current (${currentCount})`}</span>
                <span className="mx-3"> / </span>
                <span
                  className={`cursor ${activeTab === "past_due" && "text-primary"}`}
                  onClick={() => this.toggleActiveTab("past_due")}
                >{`Past Due (${pastDueCount})`}</span>
                <span className="mx-3"> / </span>
                <span
                  className={`cursor ${activeTab === "paid" && "text-primary"}`}
                  onClick={() => this.toggleActiveTab("paid")}
                >
                  {`Paid (${paidCount})`}
                </span>
                {policyRes.can_export && <span className="mx-3"> / </span>}
                  {policyRes.can_export && (
                    <span
                      className="cursor"
                      onClick={() => this.generateCSV()}
                    >{`Export Invoices to CSV`}</span>
                  )}
              </div>
              <CSVLink
                data={this.state.reportData}
                filename={"invoices.csv"}
                target="_blank"
                ref="csv"
              />

              <div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-start">
                    <p className="mt-2 mr-3 font-weight-bold">Show entries: </p>
                    <div>
                      <select
                        style={{ borderColor: "rgb(221, 220, 220)" }}
                        className="form-control"
                        onChange={e =>
                          this.setState(
                            { perPage: e.target.value, totalCount: 0 },
                            () => this.getInvoices(1)
                          )
                        }
                      >
                        <option value={10}>10</option>
                        <option value={25}>25 </option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <p
                      className="font-weight-bold mr-3"
                      style={{ marginTop: "6px" }}
                    >
                      Search:
                    </p>
                    {removeIcon}
                    <i className="fas fa-search search-icon" />
                    <Input
                      elementType={"input"}
                      style={{ paddingRight: "55px" }}
                      value={this.state.query}
                      changed={this.searchHandler}
                    />
                  </div>
                </div>

                <div className="table-responsive">
                  <table
                    className="table table-bordered table-striped"
                    style={{tableLayout: "fixed"}}
                  >
                    <thead>{this.renderTableHeader()}</thead>
                    <tbody>{tableBody}</tbody>
                  </table>
                </div>
                {
                  this.state.loading ? <Spinner /> : this.state.totalCount === 0 && (
                    <h4 className="d-flex justify-content-center my-5 font-weight-bold">
                      No Invoices Found
                    </h4>
                  )
                }
                <Pagination
                  handlePageClick={page => { window.scrollTo(0, 0); this.getInvoices(page.selected + 1); }}
                  pageCount={this.state.totalCount / this.state.perPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Billing);
