import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { title } from 'change-case';

import axios from '../../../../configAxios'
import Button from '../../../UI/button/Button'
import Modal from "../../../UI/Modal";
import Spinner from "../../../UI/Spinner";
import langUtils from '../../../../utils/langUtils';

import OverrideForm from './OverrideForm';

class Override extends Component {
  signal = axios.CancelToken.source();
  state = {
    overrides: {},
    showModal: false,
    edit: false,
    overrideKey: null,
    overrideValue: null,
    loading: true
  }

  componentDidMount() {
    this.getOverrides()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getOverrides = () => {
    this.setState({ loading: true })
    axios.get('/languages', { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          overrides: res.data.json.data,
          loading: false
        })
      })
  }

  updateData = () => {
    axios.get('languages/show', { cancelToken: this.signal.token })
      .then(res => {
        window.locale = res.data.language.locale
        sessionStorage.setItem('locale', JSON.stringify(res.data.language.locale))
        window.location.reload()
      })
  }

  deleteOverrideHandler = (key) => {
    let response = window.confirm("Are you sure you want to delete the Override?");
    if (response) {
      axios.delete('/languages/', { params: { key } })
        .then(res => {
          if (res.data.json.success) {
            toast.success("Override Deleted successfully")
            this.getOverrides()
            this.updateData()
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Spinner />
      )
    } else {
      return (
        <div>
          <div className="p-4">
            <div className="row">
              <div className="col-12 text-lg-right text-center">
                <Button
                  type="primary"
                  clicked={() => { window.scrollTo(0, 0); this.setState({ ...this.state, showModal: true, edit: false }) }}
                >
                  {langUtils("txt_global_add_new", "Add New")}
                </Button>
              </div>
            </div>
          </div>

          {
            Object.keys(this.state.overrides).length === 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="text-center p-5 m-5">
                    <h5>No Override/Slang</h5>
                  </div>
                </div>
              </div>
            ) : (
                <div className="list-group list-group-flush">
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-5">
                        <span className="font-weight-bold">Key</span>
                      </div>
                      <div className="col-5">
                        <span className="font-weight-bold">Override</span>
                      </div>
                    </div>
                  </div>
                  {
                    Object.keys(this.state.overrides).map((key, index) => (
                      <div className="list-group-item" key={index}>
                        <div className="row">
                          <div className="col-5">{key}</div>
                          <div className="col-5">{this.state.overrides[key]}</div>
                          <div className="col-2 text-center">
                            <div className="d-flex justify-content-around">
                              <i
                                className="fas fa-pencil-alt cursor"
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  this.setState({
                                    ...this.state,
                                    showModal: true,
                                    edit: true,
                                    overrideKey: key,
                                    overrideValue: this.state.overrides[key]
                                  })
                                }}
                              />
                              <i
                                className="fas fa-trash cursor"
                                onClick={() => this.deleteOverrideHandler(key)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )
          }
          <Modal
            show={this.state.showModal}
            title={this.state.edit ? "Update Override" : "New Override"}
            hide={() => this.setState({ showModal: false })}
          >
            <OverrideForm
              overrideKey={this.state.overrideKey}
              overrideValue={this.state.overrideValue}
              edit={this.state.edit}
              closeModal={() => this.setState({ showModal: false })}
              updateData={this.updateData}
            />
          </Modal>
        </div>
      )
    }
  }
}

export default withRouter(Override);