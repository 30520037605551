import * as actionTypes from "./actionTypes";
import { setResponse, clearResponse, setLoading } from "../actions/index";
import axios from "../../configAxios";
import { title } from "change-case"

export const getProjects = (params = {}) => {
  return dispatch => {
    dispatch(setLoading(true));
    axios
      .get("/projects", { params })
      .then(res => {
        dispatch(setLoading(false));
        dispatch({
          type: actionTypes.GET_PROJECTS,
          projects: res.data.projects,
          params: params,
          totalCount: res.data.meta.total_count
        });
      })
      .catch(err => dispatch(setLoading(false)));
  };
};

export const createProject = (projectData, history) => {
  return dispatch => {
    dispatch(clearResponse());
    axios
      .post("/projects", projectData)
      .then(res => {
        const { success } = res.data.json;
        if (success) {
          dispatch(setResponse(true, "Project Created Successfully"));
        } else {
          const { errors } = res.data.json;
          const errorMessage = Object.keys(errors)
            .map(
              key =>
                `${key.charAt(0).toUpperCase() + key.slice(1)} ${errors[key]}`
            )
            .join(" ");
          dispatch(setResponse(false, errorMessage));
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(setResponse(false, "Something went wrong"));
      });
  };
};

export const getProject = id => {
  return dispatch => {
    dispatch(setLoading(true));
    axios
      .get(`/projects/${id}`)
      .then(res => {
        dispatch(setLoading(false));
        if (res.status === 202) {
          let errorMessage;
          if (res.data.hasOwnProperty("json")) {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                errorMessage = title(error) + " " + res.data.json.errors[error];
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              errorMessage = res.data.json.error;
            }
          }
          dispatch(setResponse(false, errorMessage));
        } else {
          dispatch({
            type: actionTypes.GET_PROJECT,
            project: res.data.project
          });
        }
      })
  };
};

export const getProjectAttributeTypes = type => {
  return dispatch => {
    axios
      .get("/project_attributes/formdata", {
        params: { type }
      })
      .then(res => {
        dispatch({
          type: actionTypes.GET_PROJECT_ATTRIBUTE_TYPES,
          projectAttributeTypes: res.data.project_attributes
        });
      });
  };
};

export const getProjectAttributes = (type, project_id, cancelToken) => {
  return dispatch => {
    axios
      .get("/project_attributes", {
        params: { type, project_id },
        cancelToken
      })
      .then(res => {
        dispatch(setLoading(false));
        dispatch({
          type: actionTypes.GET_PROJECT_ATTRIBUTES,
          projectAttributes: res.data.project_attributes
        });
      });
  };
};

export const addProjectAttribute = attrData => {
  return dispatch => {
    axios
      .post("/project_attributes", attrData)
      .then(res => {
        if (res.data.json) {
          dispatch(setResponse(true));
        }
      })
      .catch(err => console.log(err));
  };
};

export const updateProjectAttribute = attrData => {
  let valueType = attrData.value_type;
  delete attrData.value_type;
  return dispatch => {
    if (attrData.id) {
      axios
        .put(`/project_attributes/${attrData.id}`, attrData)
        .then(res => {
          if (res.data.json) {
            dispatch({
              type: actionTypes.UPDATE_PROJECT_ATTRIBUTE,
              id: attrData.id,
              valueType,
              value: attrData[valueType]
            });
          }
        })
        .catch(err => console.log(err));
    }
    else {
      axios
        .post(`/project_attributes`, attrData)
        .then(res => {
          if (res.data.json) {
            dispatch({
              type: actionTypes.CREATE_PROJECT_ATTRIBUTE,
              id: res.data.json.data.id,
              valueType,
              value: attrData[valueType],
              project_attribute_type_id: res.data.json.data.project_attribute_type_id
            });
          }
        })
        .catch(err => console.log(err));
    }
  };
};

export const deleteProjectAttribute = params => {
  return dispatch => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      axios
        .delete(`/project_attributes/${params.id}`, { data: params })
        .then(res => {
          // dispatch({
          //   type: actionTypes.DELETE_PROJECT_ATTRIBUTE,
          //   id: params.id
          // });
          dispatch(setResponse(true, "deleted"));
        })
    }
  };
};
