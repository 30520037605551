import React, { Component } from "react"

import Input from "../../UI/input/Input"
import Modal from "../../UI/Modal"
import UserPermissions from "../../systemSettings/usersAndMembers/users/UserPermissions"

import "./index.css"

class NameAndOrganization extends Component {
  state = {
    role: {
      elementType: "select",
      elementConfig: {
        name: "role",
        placeholder: "Select Option",
        required: true,
        options: this.props.roles,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
        isClearable: false,
      },
      selectedOption: null,
    },
    memberType: {
      elementType: "select",
      elementConfig: {
        name: "memberType",
        placeholder: "Select Option",
        options: this.props.memberTypes,
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
        isClearable: true,
      },
    },
  }

  componentDidMount() {
    if (this.props.editUser) {
      const { formData } = this.props
      let selectedOption
      if (this.props.policyRes.show_role) {
        selectedOption = this.props.roles.filter(
          (role) => role.value === formData.role
        )[0]
      }
      this.setState({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        pronouns: formData.pronouns,
        organization: formData.organization,
        membershipDues: formData.membershipDues,
        volunteerHours: formData.volunteerHours,
        memberType: {
          ...this.state.memberType,
          elementConfig: {
            ...this.state.memberType.elementConfig,
            defaultValue: this.props.memberTypes.filter(
              (mt) => mt.value === formData.memberType
            )[0],
          },
          selectedOption: this.props.memberTypes.filter(
            (mt) => mt.value === formData.memberType
          )[0],
        },
        role: {
          ...this.state.role,
          selectedOption,
        },
      })
      this.props.inputChangeHandler(selectedOption, "select", "role")
    }
  }

  inputChangeHandler = (event, type, key) => {
    if (type === "select") {
      this.setState(
        {
          [key]: {
            ...this.state[key],
            selectedOption: event,
          },
        },
        () => {
          if (key === "memberType") {
            const price = event
              ? this.props.membershipPrices[event.value]
              : null
            this.setState({ membershipDues: price || "" })
            this.props.inputChangeHandler(
              { target: { value: price || "" } },
              "text",
              "membershipDues"
            )
          }
        }
      )
    } else {
      this.setState({ [key]: event.target.value })
    }
    if (key === "role" && event.level === "non_member") {
      this.setState({
        memberType: {
          ...this.state.memberType,
          selectedOption: null,
        },
        membershipDues: "",
        volunteerHours: "",
      })
    }
    this.props.inputChangeHandler(event, type, key)
  }

  renderCheckboxFirstRow = () => {
    const { selectedInfo } = this.props
    const checkboxObj = {
      status: "Active?",
      can_rent_equipment: "Rent Equipment?",
      can_book_facilities: "Book Facilities?",
      can_register_in_programs: "Register in Programs?",
    }
    const checkboxes = Object.keys(checkboxObj).map((key) => (
      <div
        className="col-md-3 cursor"
        onClick={() => this.props.checkedPersonalInfo(key)}
        key={key}
      >
        <div className="d-flex pt-2">
          <div
            className="info-checkbox"
            style={{
              backgroundColor: !selectedInfo.includes(key)
                ? "white"
                : "var(--color-primary)",
            }}
          >
            <i
              className="fas fa-check text-white"
              style={{ marginLeft: "2px" }}
            />
          </div>
          <p className="ml-4">{checkboxObj[key]}</p>
        </div>
      </div>
    ))
    return <div className="row">{checkboxes}</div>
  }

  renderCheckboxSecondRow = () => {
    const { selectedInfo } = this.props
    const checkboxObj = {
      gets_member_rates: "Preferred Rates?",
      is_on_mailing_list: "Permission to Contact?",
      gets_insurance_coverage: "Insurance Coverage?",
      allow_booking: "Online Booking?",
    }
    const checkboxes = Object.keys(checkboxObj).map((key) => (
      <div
        key={key}
        className="col-md-3 cursor"
        onClick={() => this.props.checkedPersonalInfo(key)}
      >
        <div className="d-flex pt-2">
          <div
            className="info-checkbox"
            style={{
              backgroundColor: !selectedInfo.includes(key)
                ? "white"
                : "var(--color-primary)",
            }}
          >
            <i
              className="fas fa-check text-white"
              style={{ marginLeft: "2px" }}
            />
          </div>
          <p className="ml-4">{checkboxObj[key]}</p>
        </div>
      </div>
    ))
    return <div className="row">{checkboxes}</div>
  }

  renderCheckboxThirdRow = () => {
    const { selectedInfo } = this.props
    const checkboxObj = {
      allow_registration: "Online Registration?",
      jury_member: "Jury Member?",
    }
    const checkboxes = Object.keys(checkboxObj).map((key) => (
      <div
        key={key}
        className="col-md-3 cursor"
        onClick={() => this.props.checkedPersonalInfo(key)}
      >
        <div className="d-flex pt-2">
          <div
            className="info-checkbox"
            style={{
              backgroundColor: !selectedInfo.includes(key)
                ? "white"
                : "var(--color-primary)",
            }}
          >
            <i
              className="fas fa-check text-white"
              style={{ marginLeft: "2px" }}
            />
          </div>
          <p className="ml-4">{checkboxObj[key]}</p>
        </div>
      </div>
    ))
    return <div className="row">{checkboxes}</div>
  }

  render() {
    const organization = (
      <div className="mt-3">
        <p>Organization</p>
        <Input
          elementType="input"
          value={this.state.organization}
          className="p-1"
          changed={(event) =>
            this.inputChangeHandler(event, "text", "organization")
          }
        />
      </div>
    )

    const pronouns = (
      <div className="mt-3">
        <p>Pronouns </p>
        <Input
          elementType="input"
          elementConfig={{ required: false }}
          value={this.state.pronouns}
          changed={(event) =>
            this.inputChangeHandler(event, "text", "pronouns")
          }
        />
      </div>
    )

    const firstName = (
      <div className="mt-3">
        <p className="required">First Name </p>
        <Input
          elementType="input"
          elementConfig={{ required: true }}
          value={this.state.firstName}
          changed={(event) =>
            this.inputChangeHandler(event, "text", "firstName")
          }
        />
      </div>
    )

    const lastName = (
      <div className="mt-3">
        <p className="required">Last Name </p>
        <Input
          elementType="input"
          elementConfig={{ required: true }}
          value={this.state.lastName}
          changed={(event) =>
            this.inputChangeHandler(event, "text", "lastName")
          }
        />
      </div>
    )

    const email = (
      <div className="mt-3">
        <p className="required">Email </p>
        <Input
          elementType="input"
          elementConfig={{ type: "email", required: true }}
          value={this.state.email}
          changed={(event) => this.inputChangeHandler(event, "text", "email")}
        />
      </div>
    )

    const role = (
      <div className="mt-3">
        <p className="mt-10 required">
          <span
            className="cursor"
            onClick={() => {
              window.scrollTo(0, 0)
              this.setState({ showPermissionsModal: true })
            }}
          >
            <i className="fas fa-question-circle"></i>
          </span>
          &nbsp;
          <span>
            {window.locale ? window.locale.txt_role || "Role" : "Role"}
          </span>
        </p>
        <Input
          {...this.state.role}
          changed={(event) => this.inputChangeHandler(event, "select", "role")}
        />
      </div>
    )

    const memberType = (
      <div className="mt-3">
        <p className="mt-10 required">
          {window.locale ? window.locale.txt_contact_type || "Type" : "Type"}
        </p>
        <Input
          {...this.state.memberType}
          changed={(event) =>
            this.inputChangeHandler(event, "select", "memberType")
          }
        />
      </div>
    )

    const memberDues = (
      <div className="mt-3">
        <p className="mt-10">Membership Dues / Year</p>
        <Input
          elementType="input"
          elementConfig={{ type: "number", step: 0.01 }}
          value={this.state.membershipDues}
          changed={(event) =>
            this.inputChangeHandler(event, "text", "membershipDues")
          }
        />
      </div>
    )

    const volunteerHours = (
      <div className="mt-3">
        <p className="mt-10">Volunteer Hours</p>
        <Input
          elementType="input"
          elementConfig={{ type: "number" }}
          value={this.state.volunteerHours}
          changed={(event) =>
            this.inputChangeHandler(event, "text", "volunteerHours")
          }
        />
      </div>
    )

    const nonMemberForm = (
      <div>
        <div className="row">
          <div className="col-md-12">{organization}</div>
        </div>
        <div className="row">
          <div className="col-md-3">{pronouns}</div>
          <div className="col-md-3">{firstName}</div>
          <div className="col-md-3">{lastName}</div>
          <div className="col-md-3">{email}</div>
          <div className="col-md-3">{role}</div>
          {this.state.role.selectedOption &&
          this.state.role.selectedOption.level === "non_member" ? (
            <React.Fragment>
              <div className="col-md-3 cursor d-flex justify-content-start align-items-center">
                <div
                  className="d-flex justify-content-center mt-5"
                  onClick={() => this.props.checkedPersonalInfo("status")}
                >
                  <div
                    className="info-checkbox"
                    style={{
                      backgroundColor: !this.props.selectedInfo.includes(
                        "status"
                      )
                        ? "white"
                        : "var(--color-primary)",
                    }}
                  >
                    <i
                      className="fas fa-check text-white"
                      style={{ marginLeft: "2px" }}
                    />
                  </div>
                  <p className="ml-4">Active?</p>
                </div>
              </div>
              <div className="col-md-3 cursor d-flex justify-content-start align-items-center">
                <div
                  className="d-flex justify-content-center mt-5"
                  onClick={() => this.props.checkedPersonalInfo("jury_member")}
                >
                  <div
                    className="info-checkbox"
                    style={{
                      backgroundColor: !this.props.selectedInfo.includes("jury_member")
                        ? "white"
                        : "var(--color-primary)",
                    }}
                  >
                    <i
                      className="fas fa-check text-white"
                      style={{ marginLeft: "2px" }}
                    />
                  </div>
                  <p className="ml-4">Jury Member?</p>
                </div>
              </div>
              <div className="col-md-3 cursor d-flex justify-content-start align-items-center">
                <div
                  className="d-flex justify-content-center mt-5"
                  onClick={() =>
                    this.props.checkedPersonalInfo("is_on_mailing_list")
                  }
                >
                  <div
                    className="info-checkbox"
                    style={{
                      backgroundColor: !this.props.selectedInfo.includes(
                        "is_on_mailing_list"
                      )
                        ? "white"
                        : "var(--color-primary)",
                    }}
                  >
                    <i
                      className="fas fa-check text-white"
                      style={{ marginLeft: "2px" }}
                    />
                  </div>
                  <p className="ml-4">Permission to Contact?</p>
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    )

    const memberForm = (
      <div>
        <div className="row">
          <div className="col-md-12">{organization}</div>
        </div>
        <div className="row">
          <div className="col-md-3">{pronouns}</div>
          <div className="col-md-3">{firstName}</div>
          <div className="col-md-3">{lastName}</div>
          <div className="col-md-3">{email}</div>
        </div>
        <div className="row">
          {this.props.policyRes.show_role && (
            <div className="col-md-3">{role}</div>
          )}
          <div className="col-md-3">{memberType}</div>
          <div className="col-md-3">{memberDues}</div>
          <div className="col-md-3">{volunteerHours}</div>
        </div>
        {this.renderCheckboxFirstRow()}
        {this.renderCheckboxSecondRow()}
        {this.renderCheckboxThirdRow()}
      </div>
    )

    return (
      <div className="mb-2 personal-info">
        <button
          className="btn header-btn"
          onClick={() => this.props.tabHandler("nameAndOrganization")}
          type="button"
          data-toggle="collapse"
          data-target="#nameAndOrganization"
          aria-expanded="false"
          aria-controls="collapse"
        >
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Name & Organization</p>
            <i
              className={
                this.props.selectedTabs.includes("nameAndOrganization")
                  ? "fas fa-minus-circle fa-2x bg-muted text-primary"
                  : "fas fa-plus-circle fa-2x bg-muted text-primary"
              }
            />
          </div>
        </button>
        <div className="row">
          <div className="col">
            <div className="collapse show" id="nameAndOrganization">
              {!this.state.role.selectedOption
                ? memberForm
                : this.state.role.selectedOption.level !== "non_member"
                ? memberForm
                : nonMemberForm}
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showPermissionsModal}
          title="User Permissions"
          closeModal={() => this.setState({ showPermissionsModal: false })}
          hide={() => this.setState({ showPermissionsModal: false })}
          closeButton
        >
          <UserPermissions customFields={this.props.customFields} />
        </Modal>
      </div>
    )
  }
}
export default NameAndOrganization
