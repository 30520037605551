import React, { Component } from "react";
import dateFns from "date-fns";
import _ from "lodash"
import { toast } from "react-toastify";

import AssetTable from "./AssetTable";
import DetailsButtons from "./Buttons";
import EllipsisButtons from "./EllipsisButtons";
import PersonalInfo from "./PersonalInfo";
import DetailTable from "./DetailTable";
import BookingsTable from "./BookingsTable";
import InvoiceTable from "./InvoiceTable";
import RegistrationTable from "./RegistrationTable";
import EditPaymentDate from "./EditPaymentDate"

import Spinner from "../../UI/Spinner";
import Modal from "../../UI/Modal";
import Input from "../../UI/input/Input";
import FormButton from "../../UI/button/FormButton";
import Button from "../../UI/button/Button";
import axios from "../../../configAxios";
import { displayTax } from "../helpers";


class Detail extends Component {
  signal = axios.CancelToken.source();
  state = {
    invoice: null,
    loading: true,
    policyRes: {},
    email: null,
    openRefundModal: false,
    paidAmount: {
      elementType: "input",
      elementConfig: {
        name: "paidAmount",
        required: true,
        type: "number",
        min: 0,
        max: 99999999,
        step: "0.01"
      },
      value: ""
    },
    paymentType: {
      elementType: "select",
      elementConfig: {
        name: "paymentType",
        required: true,
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    notes: {
      elementType: "textarea",
      elementConfig: {
        name: "notes",
        rows: "6",
        required: true
      },
      value: ""
    },
    editPaymentDate: null,
    editPaymentId: null,
    showEditPayment: false,
    disabled: false
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    const { history, location } = this.props;
    if (location.state && location.state.fromPaymentPage) {
      const paymentMssgError = "Your payment could not be completed, if you were charged please wait for 12-24 hours for us to check with our payment provider. else please try again";
      const paymentMssgSuccess = "Your payment was completed successfully"
      const { paymentSuccess, response, responseMessage, transactionId } = location.state;
      if (paymentSuccess) {
        let data = {
          responseMessage,
          response,
          transactionId,
          id: this.props.match.params.id
        };
        axios.post("/invoices/verify_online_payment", data).then(res => {
          if (res.data.json.success) {
            toast.success(paymentMssgSuccess)
            history.go(0)
          } else {
            toast.error(res.data.json.message);
            toast.error(paymentMssgError)
          }
        });
      } else {
        toast.error(responseMessage);
        toast.error(paymentMssgError)
      }
      const state = { ...history.location.state }
      delete state.paymentSuccess;
      delete state.responseMessage;
      delete state.response;
      delete state.transactionId;
      delete state.fromPaymentPage;
      delete state.invoiceId
      history.replace({ ...history.location, state })
    }
    axios
      .get("invoices/policy", {
        params: {
          id: this.props.match.params.id
        }
      })
      .then(res => {
        this.setState({
          policyRes: res.data.json
        })
      })
    this.getPaymentType();
    this.getInvoice();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getPaymentType = () => {
    axios
      .get("/payments/formdata", {
        params: {
          invoice_id: this.props.match.params.id,
          refund: true
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        let { payment_types } = res.data.json;
        if (!_.isEmpty(payment_types)) {
          const refundPaymentType = payment_types.filter(type => type[0] === "REFUND");
          if (!_.isEmpty(refundPaymentType)) {
            const [label, value] = refundPaymentType[0]
            this.setState({
              paymentType: {
                ...this.state.paymentType,
                elementConfig: {
                  ...this.state.paymentType.elementConfig,
                  defaultValue: { label, value }
                },
                selectedOption: { label, value },
              }
            });
          }
        }
      });
  }

  getInvoice = () => {
    this.setState({ loading: true });
    axios
      .get(`/invoices/${this.props.match.params.id}`, {
        cancelToken: this.signal.token
      })
      .then(res => {
        if (!res.data.invoice) {
          window.location.reload();
          return;
        }
        let email = null;
        if (res.data.invoice && res.data.invoice.user) {
          if (res.data.invoice.user.email)
            email = res.data.invoice.user.email;
          else if (res.data.invoice.user.alternate_email)
            email = res.data.invoice.user.alternate_email;
        }
        this.setState({
          invoice: res.data.invoice,
          loading: false,
          email: email,
          reference: res.data.invoice.reference,
          accountId: res.data.invoice.account_id
        });
      });
  };

  inputChangedHandler = (event, key) => {
    if (key === "paidAmount") {
      let { value, min, max } = event.target;
      event.target.value = Math.max(Number(min), Math.min(Number(max), Number(value)));
      this.setState({
        [key]: {
          ...this.state[key],
          value: event.target.value
        }
      });
    }
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const paymentData = {
      invoice_id: this.state.invoiceId,
      payment_amount: this.state.paidAmount.value,
      notes: this.state.notes.value,
      payment_type_id: this.state.paymentType.selectedOption.value,
      account_id: this.state.accountId
    };
    axios.post(`/payments/refund`, paymentData).then(res => {
      if (res.data.json.success) {
        toast.success("Payment Refunded Successfully");
        this.setState({
          openRefundModal: false,
          disabled: false
        }, () => this.getInvoice())
      } else {
        toast.error("Payment Failed");
        this.setState({ disabled: false })
      }
    });
  };

  refundHandler = (paymentDetails) => {
    if (_.isEmpty(this.state.paymentType.selectedOption))
      toast.error("Refund cannot be performed now. Try again after a few moments");
    else
      this.setState({
        openRefundModal: true,
        paidAmount: {
          ...this.state.paidAmount,
          elementConfig: {
            ...this.state.paidAmount.elementConfig,
            max: paymentDetails.toFixed(2)
          },
          value: paymentDetails.toFixed(2)
        },
        notes: {
          ...this.state.notes,
          value: `Invoice #${this.state.reference} - Refund`
        },
        invoiceId: this.props.match.params.id,
      })
  }

  editPaymentHandler = (editPaymentId, editPaymentDate) => {
    this.setState({
      editPaymentDate,
      editPaymentId,
      showEditPayment: true
    })
  }

  render() {
    const { invoice, email } = this.state;
    let totalPayment = 0;
    invoice && invoice.payments.length && invoice.payments.forEach(payment => {
      if (payment.payment_amount.startsWith('-')) {
        let paymentAmount
        paymentAmount = parseFloat((payment.payment_amount.replace(/[$,-]/g, "")))
        totalPayment -= paymentAmount;
      }
      else {
        totalPayment += parseFloat((payment.payment_amount.replace(/[$,]/g, "")))
      }
    })

    return (
      <div className="container w-100">
        {!this.state.loading && (
          <div className="row mb-3">
            <div className="col-lg-5">
              <h3>
                Current Invoice ({invoice.invoice_type})
              </h3>
            </div>
            <div className="col-lg-7">
              <DetailsButtons
                invoice={invoice}
                policyRes={this.state.policyRes}
                reload={() => this.getInvoice()}
              />
            </div>
          </div>
        )}
        <div className="card">
          {!this.state.loading ? (
            <div className="card-body p-3">
              <div className="card-text">
                <div className="row">
                  <div className="col-lg-12 mt-3 mr-3 d-flex justify-content-end align-items-end">
                    <EllipsisButtons
                      invoice={invoice}
                      email={email}
                      policyRes={this.state.policyRes}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 pb-3 d-flex justify-content-start align-items-end">
                    <PersonalInfo
                      invoice={invoice}
                      email={email}
                    />
                  </div>
                  <div className="col-lg-6 pb-3 d-flex justify-content-end align-items-end">
                    <div className="my-3">

                      {invoice.is_current && <div className="mt-5 details-text text-right">
                        Payment is due by{" "}
                        {dateFns.format(
                          invoice.secure_due_by,
                          "dddd MMMM DD YYYY"
                        )}
                      </div>}
                      <div className="mt-5">
                        <div className="details-text text-right">
                          {`Facture / Invoice #${invoice.reference}`}
                        </div>
                        {invoice.ticket && (
                          <div className="details-text text-right">
                            {`Billet / Ticket #${invoice.ticket.id}`}
                          </div>
                        )}
                        {invoice.external_tracking_number && (
                          <div className="details-text text-right">
                            {`External Tracking # ${invoice.external_tracking_number}`}
                          </div>
                        )}
                        <div className="details-text text-right">
                          {`${dateFns.format(invoice.invoice_post_date, "dddd MMMM DD YYYY")}`}
                        </div>
                        {invoice.last_sent && (
                          <div>
                            <span className="info-key">Sent: </span>
                            <span className="info-value">
                              {invoice.last_sent}
                            </span>
                          </div>
                        )}
                        {invoice.entered_into_accounting_on && (
                          <div>
                            <span className="info-key">
                              Entered into Accounting:{" "}
                            </span>
                            <span className="info-value">
                              {invoice.entered_into_accounting_on}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    {invoice.asset_line_items && (
                      <AssetTable assetLineItems={invoice.asset_line_items}/>
                    )}
                    {invoice.registrations_line_item && (
                      <DetailTable  descriptionTitle="Program Name">
                        <RegistrationTable registrationLineItem={invoice.registrations_line_item}/>
                      </DetailTable>
                    )}
                    {invoice.bookings?.length > 0 && (
                      <BookingsTable
                        bookings={invoice.bookings}
                        organization={invoice.organization}
                      />
                    )}
                    <DetailTable  descriptionTitle="Additional Invoice Line Item">
                      <InvoiceTable invoice={invoice} />
                    </DetailTable>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-8" />
                  <div className="col-lg-4">
                    <div className="row my-2">
                      <div className="col-8">SOUS-TOTAL / SUBTOTAL</div>
                      <div className="col-4 text-left font-weight-bold">{invoice.subtotal_due}</div>
                    </div>
                    {
                      invoice.insurance_premium && (
                        <div className="row my-2">
                          <div className="col-8">Insurance Premium</div>
                          <div className="col-4 text-left font-weight-bold">
                            ${invoice.insurance_premium
                              ? parseFloat(invoice.insurance_premium).toFixed(2)
                              : "0.00"}
                          </div>
                        </div>
                      )
                    }
                    {
                      invoice.credit_amount && !["$0.00", "$0.0", null].includes(invoice.credit_amount) && (
                        <div className="row">
                          <div className="col-8">Service In-Kind</div>
                          <div className="col-4 text-left font-weight-bold">{invoice.credit_amount}</div>
                        </div>
                      )
                    }
                    {
                      invoice.is_for_user && !["$0.00", "$0.0", null].includes(invoice.credit_payment_amount) && (
                        <div className="row my-2">
                          <div className="col-8">
                            {` ${invoice.organization.credit_name} payment`}
                          </div>
                          <div className="col-4 text-left font-weight-bold">
                            ${invoice.credit_payment_amount
                              ? parseFloat(invoice.credit_payment_amount).toFixed(2)
                              : "0.00"}
                          </div>
                        </div>
                      )
                    }
                    {
                      invoice.organization.is_gst_used && invoice.is_gst_used && (
                        <div className="row my-2">
                          <div className="col-8">
                            {`TPS / GST (${displayTax(invoice.gst_rate)})`}
                          </div>
                          <div className="col-4 text-left font-weight-bold">
                            ${invoice.gst_amount
                              ? parseFloat(invoice.gst_amount).toFixed(2)
                              : "0.00"}
                          </div>
                        </div>
                      )
                    }
                    {
                      invoice.organization.is_pst_used && invoice.is_pst_used && (
                        <div className="row my-2">
                          <div className="col-8">
                            {`TVQ / PST (${displayTax(invoice.pst_rate)})`}
                          </div>
                          <div className="col-4 text-left font-weight-bold">
                            ${invoice.pst_amount
                              ? parseFloat(invoice.pst_amount).toFixed(2)
                              : "0.00"}
                          </div>
                        </div>
                      )
                    }
                    {
                      invoice.organization.is_hst_used && invoice.is_hst_used && (
                        <div className="row my-2">
                          <div className="col-8">
                            {`TVH / HST (${displayTax(invoice.hst_rate)})`}
                          </div>
                          <div className="col-4 text-left font-weight-bold">
                            ${invoice.hst_amount
                              ? parseFloat(invoice.hst_amount).toFixed(2)
                              : "0.00"}
                          </div>
                        </div>
                      )
                    }
                    <div className="row my-2">
                      <div className="col-8">
                        {
                          parseFloat(invoice.special_credits) > 0
                            ? "TOTAL DÛ / TOTAL DUE AFTER CREDITS"
                            : "TOTAL DÛ / TOTAL DUE"
                        }
                      </div>
                      <div className="col-4 text-left font-weight-bold">
                        {invoice.total}
                      </div>
                    </div>
                  </div>
                </div>

                {
                  invoice.payments && invoice.payments.length > 0 && (
                    <div className="row">
                      <div className="col-lg-5" />
                      <div className="col-lg-7">
                        <div className="px-3 pb-3 bg-neon-green">
                          <div className="row">
                            <div className="col-12 font-weight-bold my-4">
                              <u>PAYMENTS</u>
                            </div>
                          </div>
                          {
                            invoice.payments.map(payment => (
                              <div className="row my-3">
                                <div className="col-6 col-sm-4">
                                  <div>Paid By {payment.formatted_name}</div>
                                  <div>
                                    {payment.notes}
                                  </div>
                                </div>
                                <div className="col-6 col-sm-4 text-center">
                                  {payment.payment_amount}
                                </div>
                                <div className="col-12 col-sm-4 text-center">
                                  {dateFns.format(
                                    payment.payment_date
                                      ? payment.payment_date
                                      : payment.created_at,
                                    "YYYY.MM.DD"
                                  )}
                                  <span
                                    onClick={() => this.editPaymentHandler(
                                      payment.id,
                                      payment.payment_date
                                        ? payment.payment_date
                                        : payment.created_at
                                    )}
                                    className="ml-2 cursor"
                                  >
                                    <i className="fas fa-pen" />
                                  </span>
                                </div>
                              </div>
                            ))
                          }
                          {
                            !["0.0", "0", 0, 0.0, null].includes(invoice.total_outstanding) && (
                              <React.Fragment>
                                <div className="row mb-4 font-weight-bold">
                                  <div className="col-6 col-sm-4">TOTAL OUTSTANDING</div>
                                  <div className="col-6 col-sm-4 text-center">{`${invoice.total_outstanding}`}</div>
                                </div>
                              </React.Fragment>
                            )
                          }
                        </div>
                        {invoice.payments.length && (
                          <div className="row">
                            <div
                              className="text-primary cursor mt-3 ml-3 mb-2"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                this.refundHandler(totalPayment);
                              }}
                            >
                              <Button type="primary" disabled={totalPayment === 0}>Payment Refund</Button>
                            </div>
                          </div>)}
                      </div>
                      <div className="col-lg-5" />
                      <div className="col-lg-7">
                        <div className="px-3 pb-3">
                          <div className="row">
                            <div className="col-12 font-weight-bold my-4">
                              <u>BALANCE</u>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5 font-weight-bold">TOTAL DUE</div>
                            <div className="col-6 font-weight-bold">TOTAL PAID</div>
                            <div className="col-5">{`${invoice.total_outstanding}`}</div>
                            <div className="col-6">{`${invoice.total_paid}`}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          ) : (
              <Spinner />
            )}
        </div>
        <Modal
          show={this.state.openRefundModal}
          hide={() => this.setState({ openRefundModal: false })}
          title={"Payment Refund"}
        >
          <form onSubmit={this.submitHandler} className="mt-4">
            <p>Refund Payment Amount</p>
            <Input
              {...this.state.paidAmount}
              changed={event =>
                this.inputChangedHandler(event, "paidAmount")
              }
            />
            <p>Payment Type</p>
            <Input
              {...this.state.paymentType}
              disabled={true}
            />
            <p>Notes: </p>
            <Input
              {...this.state.notes}
              value={`Invoice #${this.state.reference} - Refund`}
            />
            <div className="d-flex justify-content-center">
              <Button
                type="secondary"
                className="float-left mr-3"
                clicked={() => this.setState({ openRefundModal: false })}
              >
                Cancel
          </Button>
              <FormButton disabled={this.state.disabled}>Submit</FormButton>
            </div>
          </form>
        </Modal>
        <Modal
          title="Edit Payment"
          show={this.state.showEditPayment}
          closeButton
          closeModal={() => this.setState({ showEditPayment: false })}
          hide={() => this.setState({ showEditPayment: false })}
        >
          <EditPaymentDate
            paymentDate={this.state.editPaymentDate}
            paymentId={this.state.editPaymentId}
            closeModal={() => this.setState({ showEditPayment: false })}
            updateInvoice={this.getInvoice}
          />
        </Modal>
      </div>
    );
  }
}

export default Detail;
