import React, { Component } from "react";
import _ from "lodash";
import queryString from "query-string"
import { withRouter } from "react-router-dom";
import FormButton from "../UI/button/FormButton";
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import "./index.css";

class Search extends Component {
  signal = axios.CancelToken.source();
  state = {
    controls: {
      queryString: {
        index: 1,
        elementType: "input",
        elementConfig: {
          name: "queryString",
          placeholder: "Enter asset name"
        },
        value: ""
      },
      category: {
        index: 2,
        elementType: "select",
        elementConfig: {
          name: "category",
          placeholder: "Select Category",
          options: [],
          isMulti: true,
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          }
        },
        label: "Category",
        selectedOption: null
      },
      accountingCode: {
        index: 3,
        elementType: "select",
        elementConfig: {
          name: "accountingCodes",
          placeholder: "Select Accounting Code",
          options: [],
          isMulti: true,
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          }
        },
        label: "Accounting Code",
        selectedOption: null
      },
      locations: {
        index: 5,
        elementType: "select",
        elementConfig: {
          name: "locations",
          placeholder: "Select Location",
          options: [],
          isMulti: true,
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          }
        },
        label: "Location",
        selectedOption: null
      },
      ownership: {
        index: 6,
        elementType: "select",
        elementConfig: {
          name: "ownership",
          placeholder: "Select Ownership",
          options: [
            {label: 'Third Party Owned', value: true},
            {label: `${localStorage.getItem('organizationName')}`, value: false},
          ],
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          }
        },
        label: "Ownership",
        selectedOption: null
      },
      includeDecomissioned: {
        index: 4,
        elementType: "input",
        elementConfig: {
          name: "includeDecomissioned",
          type: "checkbox",
          checked: queryString.parse(this.props.history.location.search).accounting_id ? true : false,
        },
        label: "Include Decommissioned  Assets in search?"
      }
    }
  };

  componentDidMount() {
    const { category, accountingCode, locations } = this.state.controls;
    const { location } = this.props.history;
    const accountingId = location.search ? parseInt(queryString.parse(location.search).accounting_id) : null;

    axios
      .get("/assets/formdata", { cancelToken: this.signal.token })
      .then(res => {
        const { categories, locations: locationData } = res.data.json;
        this.setState({
          controls: {
            ...this.state.controls,
            category: {
              ...category,
              elementConfig: {
                ...category.elementConfig,
                options: categories.map(element => {
                  const [value, label] = element;
                  return { label, value };
                })
              }
            },
            locations: {
              ...locations,
              elementConfig: {
                ...locations.elementConfig,
                options: locationData.map(element => {
                  const [value, label] = element;
                  return { label, value }
                })
              },
              selectedOption: this.props.searchLocation ? [this.props.searchLocation] : null
            }
          }
        }, () => this.submitHandler(null));
      }).catch(err => err);

    axios
      .get("/assets/filter", { cancelToken: this.signal.token })
      .then(res => {
        const { accounting_codes: accountingCodes } = res.data.json;
        const { elementConfig } = accountingCode;
        const accountingCodeOptions = accountingCodes.map(element => {
          const [accountCodeId, code, name] = element;
          return { label: `${code} - ${name}`, value: accountCodeId };
        })
        const defaultSelection = accountingId ? accountingCodeOptions.filter(option => accountingId === option.value) : null;

        this.setState({
          controls: {
            ...this.state.controls,
            accountingCode: {
              ...accountingCode,
              elementConfig: {
                ...elementConfig,
                options: accountingCodeOptions 
              },
              selectedOption: defaultSelection
            }
          }
        });
      }).catch(err => err);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key, triggerAction) => {
    let inputValue = {};
    if (key === "category" || key === "accountingCode" || key === "locations" || key === "ownership") {
      inputValue["selectedOption"] = event;
    } else if (key  === "includeDecomissioned") {
      inputValue["elementConfig"] =  {
        ...this.state.controls.includeDecomissioned.elementConfig,
        checked: !this.state.controls.includeDecomissioned.elementConfig.checked
      };
    } else {
      inputValue["value"] = event.target.value
    }

    const controls = {
      ...this.state.controls,
      [key]: {
        ...this.state.controls[key],
        ...inputValue
      }
    };

    this.setState({ controls }, () => {
      if(!_.isUndefined(triggerAction) && triggerAction.action === "clear")
        this.submitHandler();
    });
  };

  submitHandler = event => {
    if(event)
      event.preventDefault();
    const {
      queryString,
      category,
      includeDecomissioned,
      accountingCode,
      locations,
      ownership
    } = this.state.controls;
    const categoryIds =
      category.selectedOption &&
      category.selectedOption.map(category => category.value);
    const accountingCodeIds =
      accountingCode.selectedOption &&
      accountingCode.selectedOption.map(code => code.value);
    const locationsIds = 
      locations.selectedOption &&
      locations.selectedOption.map(location => location.value);
    const params = {
      page: 1,
      query_string: queryString.value !== "" ? queryString.value : undefined,
      category_ids:
        categoryIds && categoryIds.length > 0
          ? JSON.stringify(categoryIds)
          : undefined,
      accounting_code_ids:
        !_.isNull(accountingCodeIds) && !_.isEmpty(accountingCodeIds)
          ? JSON.stringify(accountingCodeIds)
          : undefined,
      location_ids:
        locationsIds && locationsIds.length > 0
          ? JSON.stringify(locationsIds)
          : undefined,
      include_decomissioned: includeDecomissioned.elementConfig.checked,
      is_third_party: ownership.selectedOption?.value
    };
    this.props.searchHandler(params);
  };

  clearQueryString = () => {
    const { controls } = this.state;
    this.setState({
      controls: {
        ...controls,
        queryString: {
          ...controls.queryString,
          value: ""
        }
      }
    },this.submitHandler);
  };

  render() {
    const { controls } = this.state;
    let removeIcon;
    if (controls.queryString.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times remove-icon cursor"
          onClick={() => this.clearQueryString()}
        />
      );
    }
    const formControls = Object.keys(controls)
      .filter(key => key !== "queryString")
      .sort((val1, val2) => controls[val1].index > controls[val2].index)
      .map(key => (
        <Input
          key={key}
          {...controls[key]}
          changed={(event, triggeredAction) => this.inputChangedHandler(event, key, triggeredAction)}
        />
      ));
    return (
      <div className="p-4 assetSearchWrapper">
        <h4 className="font-weight-bold text-dark">Search</h4>
        <form onSubmit={this.submitHandler}>
          <div>
            <i className="fas fa-search search-icon" />
            {removeIcon}
            <Input
              {...controls["queryString"]}
              className="customInput"
              changed={event => this.inputChangedHandler(event, "queryString")}
            />
          </div>
          {formControls}
          <FormButton block disabled={this.props.disabled}>
            Search
          </FormButton>
        </form>
      </div>
    );
  }
}

export default withRouter(Search);
