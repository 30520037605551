import React, { Component } from "react";

import axios from "../../../../configAxios";

import CardHeader from "../../CardHeader";
import CreatedInvoicesGraph from "./CreatedInvoicesGraph";

import Spinner from "../../../../assests/images/spinner.gif";

class CreatedInvoices extends Component {
  
  signal = axios.CancelToken.source();

  state = {
    loading: true,
    data: []
  };

  componentDidMount() {
    axios.get('/invoices/stats', { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          data: res.data.json.data.reverse(),
          loading: false
        })
        this.props.updateGrid();
      })
      .catch(error => error)
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  render() {
    const spinner = (
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    );
    return (
      <div className="dashboard">
        <div className="card">
          {this.state.loading ? (
            <div className="card-body p-0">
              <div className="card-text">
                <div className="spinner">{spinner}</div>
              </div>
            </div>
          ) : (
              <React.Fragment>
                <CardHeader
                  title="Created Invoices"
                  icon="fa-wallet"
                  collapse="currentinvoicegraph"
                  updateGrid={this.props.updateGrid}
                  removeModule={this.props.removeModule}
                  name={this.props.name}
                />
                <div className="dashboard-card collapse show" id="currentinvoicegraph">
                  <div className="card-body">
                    <div className="card-text" style={{ height: '300px' }}>
                      <CreatedInvoicesGraph data={this.state.data} />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
        </div>
      </div>
    )
  }

}

export default CreatedInvoices;
