import React, { Component } from "react";
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import Search from "./Search";
import AssetItems from "./AssetItems";
import axios from "../../configAxios";
import SearchFilter from "../common/searchFilter/SearchFilter";
import "./index.css";

class Assets extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    assets: null,
    totalCount: null,
    forcePage: 0,
    queryParams: { type: "Equipment", is_index_for_booking_page : false, page: 1 },
    disabled: true,
    perPage: 10,
    searchLocation: null
  };

  componentDidMount() {
    const {location} = this.props.history;
    const locations = queryString.parse(location.search);
    if (locations.locationValue) {
      this.setState({
        queryParams: {
          ...this.state.queryParams,
          location_ids: locations.locationValue
        },
        searchLocation: { value: locations.locationValue, label: locations.locationLabel }
      })
    } else {
      const accountingCodeId = locations.accounting_id ? parseInt(locations.accounting_id) : null
      this.getAssets(accountingCodeId);
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getAssets = (accountingId) => {
    const { queryParams } = this.state;
    const page = queryParams.page ? queryParams.page : 1;
    this.setState({ forcePage: page - 1, loading: true });

    axios
      .get("/assets", {
        params: accountingId ?
          {
            ...this.state.queryParams,
            accounting_code_ids: JSON.stringify([accountingId]),
            include_decomissioned: true,
          }
          : this.state.queryParams,
        cancelToken: this.signal.token
      })
      .then(res => {
        const { assets, meta } = res.data;
        this.setState({
          loading: false,
          assets,
          totalCount: meta.total_count,
          disabled: false
        });
      });
  };

  searchHandler = params => {
    const { queryParams } = this.state;
    this.setState(
      {
        totalCount: null,
        queryParams: {
          ...queryParams,
          ...params
        },
        disabled: true
      },
      () => this.getAssets()
    );
  };

  inputChangeHandler = perPage => {
    const { queryParams } = this.state;
    this.setState(
      {
        queryParams: {
          ...queryParams,
          per_page: perPage,
          page: 1
        },
        perPage
      },
      () => this.getAssets()
    );
  };

  paginationHandler = page => {
    window.scrollTo(0, 0);
    const { queryParams } = this.state;
    this.setState(
      {
        queryParams: {
          ...queryParams,
          page: page.selected + 1
        }
      },
      () => this.getAssets()
    );
  };

  render() {
    const { loading, assets, totalCount, perPage, searchLocation } = this.state;
    return (
      <div className="mt-3 pb-3 assets">
        <div className="tab-content container-fluid">
          <div id="home">
            <div className="row">
              <div className="col-lg-5">
                <Search
                  searchLocation={searchLocation}
                  searchHandler={this.searchHandler}
                  disabled={this.state.disabled}
                />
              </div>
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-sm-6 offset-sm-6 ">
                    <SearchFilter
                      perPage={perPage}
                      inputChangeHandler={this.inputChangeHandler}
                    />
                  </div>
                </div>
                <AssetItems
                  assets={assets}
                  totalCount={totalCount}
                  loading={loading}
                  forcePage={this.state.queryParams.page - 1}
                  perPage={this.state.perPage}
                  paginationHandler={this.paginationHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Assets);
