import React, { Component } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom"
import dateFns, { startOfMonth, endOfMonth } from "date-fns";
import "./index.css";
import Spinner from "../../../UI/Spinner";

class FacilityDetailCalendar extends Component {
  state = {
    startOfMonth: startOfMonth(this.props.date),
    endOfMonth: endOfMonth(this.props.date),
    bookedDates: {},
    loading: false
  };

  componentDidMount() {
    this.getProgramDate();
  }

  getProgramDate = () => {
    const assetsOut = this.props.assetsOut;
    const bookedDates = {};
    assetsOut && assetsOut.forEach((asset, index) => {
      const { name, start, end } = asset;
      const title = name;
      if (dateFns.isSameMonth(new Date(start), this.state.startOfMonth)) {
        let day = dateFns.getDate(start);
        let details = {};
        const startTime = dateFns.format(start, "hh:mmA");
        const endTime = dateFns.format(end, "hh:mmA");
        details["id"] = index;
        details["title"] = title;
        details["time"] = `${startTime} - ${endTime}`;
        if (this.props.private) {
          details["userOrProgram"] = asset.userOrProgram;
          details["userOrProgramId"] = asset.userOrProgramId
        }
        if (bookedDates[day] && bookedDates[day].length) {
          bookedDates[day].push(details);
        } else {
          bookedDates[day] = [details];
        }
      }
    });
    this.setState({ bookedDates, loading: false });
  };

  monthChangeHandler = date => {
    this.props.dateChangeHook(date)
  };

  render() {
    const { bookedDates, startOfMonth } = this.state;
    let tileContent = ({ date, view }) =>
      Object.keys(bookedDates).map(bookedDate => {
        let tileContent;
        if (
          view === "month" &&
          date.getMonth() === dateFns.getMonth(startOfMonth) &&
          date.getDate() === parseInt(bookedDate)
        ) {
          let tooltip;
          tooltip = (
            <div className={date.getDay() === 6 ? "custom-tooltip tooltip-left" : "custom-tooltip tooltip-right"}
              style={{ pointerEvents: "default" }}>
              {bookedDates[bookedDate].map((obj, index) => {
                if (index === 0) {
                  return null;
                } else {
                  return (
                    <div className="row font-15 font-weight-bold" key={`${obj.id}-${obj.time}`}>
                      {" "}
                      <div className="col-md-12 mb-2" style={{ whiteSpace: "pre-line" }}>
                        {this.props.private ?
                          <>{
                            obj.userOrProgram === "User" ?
                              <Link to={`/users/${obj.userOrProgramId}/summary`} className="text-info">{obj.title}</Link>
                              : <Link to={`/programs/${obj.userOrProgramId}`}>{obj.title}</Link>
                          }</>
                          : <p className="text-info">{obj.title}</p>}
                      </div>{" "}
                      <div className="col-md-12 mb-2">{obj.time}</div>
                    </div>
                  );
                }
              })}
            </div>
          );

          const obj = bookedDates[bookedDate];
          tileContent = (
            <div
              className="tile-content-container justify-content-center tile-content"
              key={obj[0].id}
            >
              <div className="row text-container">
                {" "}
                <div
                  className={`${
                    bookedDates[bookedDate].length > 1
                      ? "col-md-9"
                      : "col-md-12"
                    }`}
                >
                  {this.props.private ?
                    <>{
                      obj[0].userOrProgram === "User" ?
                        (<Link to={`/users/${obj[0].userOrProgramId}/summary`} className="tile-content-link">
                          <p className="tile-content-text mt-2">{obj[0].title}</p>
                          <p className="tile-content-text mt-2">{obj[0].time}</p>
                        </Link>)
                        : (
                          <Link to={`/programs/${obj[0].userOrProgramId}`} className='tile-content-link'>
                            <p className="tile-content-text mt-2">{obj[0].title}</p>
                            <p className="tile-content-text mt-2">{obj[0].time}</p>
                          </Link>
                        )
                    }</>
                    : <div className="tile-content-link">
                      <p className="tile-content-text mt-2">{obj[0].title}</p>
                      <p className="tile-content-text mt-2">{obj[0].time}</p>
                    </div>}
                </div>
                {bookedDates[bookedDate].length > 1 && (
                  <div className="col-md-2">
                    <p className="badge mt-3" style={{ marginLeft: "-20px" }}>
                      {`${
                        bookedDates[bookedDate].length > 1
                          ? `${bookedDates[bookedDate].length - 1}+`
                          : ""
                        }`}
                      {tooltip}
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        }
        return tileContent;
      });
    return (
      <div className="row facility-detail-calendar">
        {this.state.loading && <Spinner />}
        <div
          className="col-md-12 show-calendar"
          style={{
            display: !this.state.loading ? "block" : "none",
            width: "100%"
          }}
        >
          <Calendar
            calendarType="US"
            activeStartDate={this.state.startOfMonth}
            tileContent={tileContent}
            onActiveDateChange={payload =>
              this.monthChangeHandler(payload.activeStartDate)
            }
            onClickMonth={date => this.monthChangeHandler(date)}
          />
        </div>
      </div>
    )
  }

}


export default FacilityDetailCalendar;