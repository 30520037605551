import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";

import Button from "../UI/button/Button";
import langUtils from "../../utils/langUtils";
import axios from "../../configAxios";

import ListView from "./ListView";
import CalendarView from "./CalendarView";
import Drafts from "./Drafts";

class Programs extends Component {
  state = {
    tabs: [],
    activeTab: "Calendar View",
    showModal: false,
    newProgramTitle: "Workshop",
    canCreate: null
  };

  componentDidMount() {
    const tabsPolicy = {
      can_view_calendar: "Calendar View",
      can_view_list: "List View",
      can_view_drafts: "Drafts"
    }
    this.handleTabFromProp();
    axios
      .get("/programs/policy")
      .then(res => {
        const tabs = []
        Object.keys(tabsPolicy).map((policy, tab) => {
          if (res.data.json[policy]) {
            tabs.push(tabsPolicy[policy])
          }
        });
      this.setState({
        canCreate: res.data.json["can_create"],
        tabs: tabs
      })
    });
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("popstate", this.handleTabFromProp);
    }
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.tab !== "undefined"
          ? title(this.props.tab)
          : "Calendar View"
    });
  };

  tabChangeHandler = activeTab => {
    this.setState({ activeTab });
    this.props.history.push(`/programs/${snakeCase(activeTab)}`);
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  render() {
    const { tabs: tabNames } = this.state;
    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${this.state.activeTab === tab ? "active-tab " : "inactive-tab "}`}
      >
        {tab}
      </li>
    ));
    let activeTab;
    switch (this.state.activeTab) {
      case "Calendar View":
        activeTab = <CalendarView />;
        break;
      case "List View":
        activeTab = <ListView changeNewProgramTitle={activeTab => this.setState({ newProgramTitle: activeTab })} />;
        break;
      case "Drafts":
        activeTab = <Drafts />;
        break;
      default:
        activeTab = null;
    }
    return (
      <React.Fragment>
        <div className="container programs w-100">
          <div className="row mb-4">
            <div className="col-6">
              <h3>Programs</h3>
            </div>
            <div className="col-6 text-right">
              <Link to={this.state.activeTab === "List View"
                ? {
                  pathname: "/programs/new",
                  state: { preSelectedTitle: this.state.newProgramTitle }
                }
                : "/programs/new"}>
                { this.state.canCreate ? (
                  <Button type="success">{langUtils("txt_global_add_new", "Add New")}</Button>)
                : ""}
              </Link>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-header bg-white p-0 border-bottom-0">
              <ul className="nav nav-tabs ml-0">{tabs}</ul>
            </div>
            <div className="card-body p-0">
              <div className="card-text">
                {activeTab}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Programs);
