import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";
import PackingList from "./PackingList";
import OnlinePayment from "./OnlinePayment";
import NewTerm from "./NewTerm";
import InvoiceMessage from "./InvoiceMessage"

class Invoice extends Component {
  state = {
    activeTab: "Online Payment"
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : "Online Payment"
    });
  };

  tabChangeHandler = activeTab => {
    this.setState({ activeTab });
    this.props.history.push(`/system_settings/invoice/${snakeCase(activeTab)}`);
  };

  render() {
    const tabNames = ["Online Payment", "Net Terms", "Packing List", "Invoice Message"];

    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${this.state.activeTab === tab ? "active-tab " : "inactive-tab"}`}
      >
        {tab}
      </li>
    ));

    let activeTab;
    switch (this.state.activeTab) {
      case "Packing List":
        activeTab = <PackingList />;
        break;
      case "Online Payment":
        activeTab = <OnlinePayment />;
        break;
      case "Net Terms":
        activeTab = <NewTerm />;
        break;
      case "Invoice Message":
        activeTab = <InvoiceMessage />
        break;
      default:
        activeTab = null;
    }

    return (
      <div className="card">
        <ul className="nav nav-tabs ml-0">
          {tabs}
        </ul>
        {activeTab}
      </div>
    );
  }
}

export default withRouter(Invoice);
