import { createSlice } from "@reduxjs/toolkit";
import axios from "../../configAxios";
const thunkMiddleware = require('redux-thunk').default

export const formSlice = createSlice({
  name: 'forms',
  initialState: {
    loading: false,
    formsList: [],
    error: ''
  },
  reducers: {
    getFormRequest: state => {
      state.loading = true
    },
    getFormSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        formsList: action.payload
      }
    },
    getFormFailure: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
  }
})

export const { getFormRequest, getFormSuccess, getFormFailure } = formSlice.actions

export default formSlice.reducer

export const getForms = (id) => {
  return dispatch => {
    dispatch(formSlice.actions.getFormRequest())
    axios.get(`/grant_campaigns/${id}/forms`)
    .then(res => dispatch(formSlice.actions.getFormSuccess(res.data.json.data)))
    .catch(err => dispatch(formSlice.actions.getFormFailure(err.message)))
  }
}
