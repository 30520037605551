import "./Item.css";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteObject } from "../../store/actions/index"
import { Link } from "react-router-dom";
import DuplicateCampaign from "./DuplicateCampaign"
import Modal from "../UI/Modal"

export default function Item({ campaign, getGrantCampaigns, isAdmin, isProgramOfficer }) {
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()

  const deleteGrantCampaign = () => {
    const grantCampaignParams = {
      route: `/grant_campaigns/${campaign.id}`,
      data: {},
      callback: getGrantCampaigns,
      message: "Grant campaign deleted successfully"
    }

    if (window.confirm("Permanently delete this grant campaign?")) {
      window.confirm("Are you sure?") && dispatch(deleteObject(grantCampaignParams))
    }
  }

  return (
    <li className="list-group-item project-item">
      <div className="d-flex flex-column px-3">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Link
            to={`campaigns/${campaign.id}/campaign`}
            className="text-info font-weight-normal"
          >
            <div className="project-name">{campaign.name}</div>
          </Link>
          { (isAdmin() || isProgramOfficer) &&
            <div>
              <span
                className="mx-1 fas fa-trash cursor"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
                onClick={deleteGrantCampaign}
              />
              <span
                className="mx-1 fas fa-copy cursor"
                data-toggle="tooltip"
                data-placement="top"
                title="Duplicate Grant Campaign"
                onClick={() => setShowModal(true)}
              />
            </div>
          }
        </div>
        <div className="mt-2 text-justify text-gray-300">
          {campaign.description}
        </div>
      </div>
      <Modal
        title="Duplicate Campaign"
        show={showModal}
        flag={true}
        hide={() => setShowModal(false)}
      >
        <DuplicateCampaign
          campaign={campaign}
          closeModal={() => {
            setShowModal(false)
          }}
          getGrantCampaigns={getGrantCampaigns}
        />
      </Modal>
    </li>
  );
}
