import React, { Component } from "react";
import PropTypes from "prop-types";
import Spinner from "../../UI/Spinner";
// import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import {
  getSubmissionAnswers,
  selectSubmissionAnswerById,
  selectSubmissionAnswersBySubmissionId,
  selectSubmissionAnswersLoading,
  updateSubmissionAnswers,
} from "../../../store/slices/submissionAnswer";
import {
  getSubmissions,
  selectSubmissionById,
  selectSubmissionsLoading,
} from "../../../store/slices/submission";
import Input from "../../UI/input/Input";
import FormButton from "../../UI/button/FormButton";
import Button from "../../UI/button/Button";
import "./index.css";
import { Link } from "react-router-dom";
import {
  getForms,
  selectFormById,
  selectFormsLoading,
} from "../../../store/slices/form";
import {
  getFormAttributes,
  selectAllFormAttributes,
  selectFormAttributesByFormId,
  selectFormAttributesById,
  selectFormAttributesLoading,
} from "../../../store/slices/formAttributes";

class SubmissionEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submissionAnswers: this.getSubmissionAnswerProps(),
    };
  }

  back = `/campaigns/${this.props.match.params.campaignId}/submissions/${this.props.match.params.id}`;
  goBack = () => {
    console.log({ props: this.props, back: this.back });
    return this.props.history.push(this.back);
  };

  getSubmissionAnswerProps = () => {
    const { submissionAnswers } = this.props;
    return submissionAnswers.reduce((obj, answer) => {
      return { ...obj, [answer.formAttributeId]: answer };
    }, {});
  };

  componentDidMount() {
    this.props.getSubmissions({ campaignId: 3 });
    this.props.getForms({ campaignId: 3 });
    this.props.getSubmissionAnswers();
    this.props.getFormAttributes();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.submissionAnswers !== this.props.submissionAnswers) {
      this.setState({ submissionAnswers: this.getSubmissionAnswerProps() });
    }
  };

  updateFormHandler = (event) => {
    event.preventDefault();
    const submissionAnswers = Object.values(this.state.submissionAnswers);
    console.log({ event, state: this.state, submissionAnswers });
    this.props.updateSubmissionAnswers({
      submissionAnswers,
    });
    this.goBack();
  };

  formInputChanged(event, formAttributeId) {
    const { submission } = this.props;
    const answer = this.state.submissionAnswers[formAttributeId] || {
      formAttributeId,
      submissionId: submission.id,
    };
    this.setState({
      submissionAnswers: {
        ...this.state.submissionAnswers,
        [formAttributeId]: { ...answer, value: event.target.value },
      },
    });
  }

  renderRows = ({ formAttributes, submissionAnswers }) => {
    const form = formAttributes.map((attr) => {
      // debugger;
      const answer = submissionAnswers[attr.id];
      // const answer = {};
      return (
        <div key={attr.id} className="row">
          <div className="col-md-4">
            <p>{attr.name}</p>
            <Input
              value={answer && answer.value}
              elementType={this.getElementType(attr)}
              changed={(event) => this.formInputChanged(event, attr.id)}
            />
          </div>
        </div>
      );
    });
    return form;
  };

  getElementType = (attr) => {
    if (attr) {
      if (attr.type === "Long Answer") {
        return "textarea";
      } else if (attr.type === "Text") {
        return "textarea";
      } else {
        return "input";
      }
    } else {
      return "input";
    }
  };

  render() {
    const { submission, form, formAttributes, loading } = this.props;
    const { submissionAnswers } = this.state;

    let content;
    if (loading) {
      content = <Spinner />;
    } else {
      content = (
        <div className="detail container w-100">
          <div className="row mb-4">
            <div className="col-6">
              <h4 className="word-wrap-break">
                {form.name} - {submission.creator}
              </h4>
            </div>
            <div className="col-6 text-right">
              <Link to={this.back} className="text-right cursor mt-3">
                {"<< Back"}
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={this.updateFormHandler}>
                    <div className="card-text">
                      {this.renderRows({ formAttributes, submissionAnswers })}
                    </div>

                    <div className="text-center mt-5 mb-4">
                      <Button type="secondary" clicked={() => this.goBack()}>
                        Cancel
                      </Button>
                      <FormButton
                        className="ml-2"
                        disabled={this.state.disabled}
                      >
                        Save
                      </FormButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div>{content}</div>;
  }
}

SubmissionEdit.propTypes = {
  submission: PropTypes.shape({
    id: PropTypes.number,
    creator: PropTypes.string,
    formId: PropTypes.number,
  }),
  form: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  submissionAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      formAttributeId: PropTypes.number.isRequired,
      submissionId: PropTypes.number.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  getForms: PropTypes.func.isRequired,
  getSubmissions: PropTypes.func.isRequired,
  getSubmissionAnswers: PropTypes.func.isRequired,
  getFormAttributes: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const currentItemId = ownProps.match.params.id;
  const submission = selectSubmissionById(state, currentItemId) || {};
  const form = selectFormById(state, submission.formId);
  const submissionAnswers = selectSubmissionAnswersBySubmissionId(
    state,
    submission.id
  ).map((answer) => {
    return {
      ...answer,
      form: selectFormAttributesById(state, answer.formAttributeId),
    };
  });
  const formAttributes = selectFormAttributesByFormId(state, submission.formId);

  const loading =
    selectSubmissionsLoading(state) ||
    selectFormsLoading(state) ||
    selectSubmissionsLoading(state) ||
    selectSubmissionAnswersLoading(state) ||
    selectFormAttributesLoading(state);

  return {
    loading,
    response: state.response,
    submission,
    form,
    submissionAnswers,
    formAttributes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubmissions: (params) => dispatch(getSubmissions(params)),
    getForms: (params) => dispatch(getForms(params)),
    getSubmissionAnswers: (params) => dispatch(getSubmissionAnswers(params)),
    getFormAttributes: (params) => dispatch(getFormAttributes(params)),
    updateSubmissionAnswers: (params) =>
      dispatch(updateSubmissionAnswers(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionEdit);
