import React, { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { parseFromTimeZone } from "date-fns-timezone"
import { title } from "change-case"

import dateFns from "date-fns"
import axios from "../../../configAxios"
import NoImage from "../../../assests/images/user-icon.jpg"

import "./ActivityDetails.css"

class ActivityDetail extends Component {
  state = {
    hideBookings: true,
    selectedBookings: [],
  }

  selectTicketHandler = (bookingId) => {
    if (this.state.selectedBookings.includes(bookingId)) {
      this.setState({
        ...this.state,
        selectedBookings: this.state.selectedBookings.filter(
          (id) => id !== bookingId
        ),
      })
    } else {
      this.setState({
        ...this.state,
        selectedBookings: [...this.state.selectedBookings, bookingId],
      })
    }
  }

  selectAllHandler = () => {
    const selectedBookings = this.props.assets
      .map((asset) => {
        if (asset.showCheckbox) return asset.bookingId
        return null
      })
      .filter((item) => item !== null)
    this.setState({ selectedBookings })
  }

  deSelectAllHandler = () => {
    this.setState({
      selectedBookings: [],
    })
  }

  checkSelectedHandler = () => {
    const { type } = this.props
    this.setState({ disableSelectedEquipmentButton: true })
    if (type === "In") {
      axios
        .post("/assets/check_in", {
          bookings: JSON.stringify(this.state.selectedBookings),
        })
        .then((res) => {
          const { success, message } = res.data.json
          if (success) {
            toast.success(message)
            this.props.getAssets(false)
            this.setState({
              selectAll: false,
              selectedBookings: [],
              disableSelectedEquipmentButton: false,
            })
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
            this.setState({ disableSelectedEquipmentButton: false })
          }
        })
    } else {
      axios
        .post("/assets/check_out", {
          bookings: JSON.stringify(this.state.selectedBookings),
        })
        .then((res) => {
          const { success, message } = res.data.json
          if (success) {
            toast.success(message)
            this.props.getAssets(false)
            this.setState({
              selectAll: false,
              selectedBookings: [],
              disableSelectedEquipmentButton: false,
            })
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
            this.setState({ disableSelectedEquipmentButton: false })
          }
        })
    }
  }

  render() {
    const { assets, asset, type } = this.props
    const { selectedBookings, hideBookings } = this.state
    const assetCheckboxArr = assets.map((asset) => (
      <div key={asset.bookingId} className="my-1">
        {asset.showCheckbox ? (
          <span
            className="cursor form-check"
            onClick={() => this.selectTicketHandler(asset.bookingId)}
          >
            <input
              type="checkbox"
              checked={selectedBookings.includes(asset.bookingId)}
              className="form-check-input"
            />
            <label className="cursor form-check-label word-wrap-break w-90">
              {asset.name}
            </label>
          </span>
        ) : (
          <React.Fragment>
            <span className="font-weight-bold text-danger">{type}</span>
            <span className="ml-2">{asset.name}</span>
          </React.Fragment>
        )}
      </div>
    ))

    let title
    if (asset.user_or_program === "User") {
      title =
        asset.user_or_program_detail.organization_name ||
        `${asset.user_or_program_detail.first_name} ${asset.user_or_program_detail.last_name}`
    } else {
      title = asset.user_or_program_detail.name
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let ticketCreatedAt = parseFromTimeZone(asset.ticket.created_at, {
      timeZone,
    })

    ticketCreatedAt = `${dateFns.format(ticketCreatedAt, "ddd MMM DD YYYY")}`
    const image =
      asset.user_or_program_detail.photo &&
      asset.user_or_program_detail.photo.thumb &&
      asset.user_or_program_detail.photo.thumb.url
        ? asset.user_or_program_detail.photo.thumb.url
        : NoImage
    return (
      <li className="list-group-item booking-activity" style={{ zIndex: 0 }}>
        <div className="row">
          <div className="col-3">
            <div
              className="rounded-circle mx-auto bg-cover"
              style={{
                width: 90,
                height: 90,
                backgroundImage: `url(${image})`,
              }}
            />
          </div>
          <div className="col-9">
            {asset.user_or_program === "User" ? (
              <Link
                to={`/users/${asset.user_or_program_detail.id}/summary`}
                className="text-info font-weight-normal decoration-none"
              >
                <div className="asset-name">
                  {`${title} - ${
                    asset.ticket.project_name
                      ? asset.ticket.project_name
                      : "No Project Name"
                  }`}
                </div>
              </Link>
            ) : (
              <Link
                to={`/programs/${asset.user_or_program_detail.id}`}
                className="text-info font-weight-normal decoration-none"
              >
                <div className="asset-name">
                  {`${title} - ${
                    asset.ticket.project_name
                      ? asset.ticket.project_name
                      : "No Project Name"
                  }`}
                </div>
              </Link>
            )}
            <div>
              <span className="font-weight-bold text-dark">
                Ticket Created:{" "}
              </span>
              <span>{ticketCreatedAt}</span>
            </div>
            <div className="activity-details">
              <div className="label other">{asset.ticket.status_string}</div>
            </div>
            <div className="row">
              <div
                className="col-md-7 my-3 cursor text-primary"
                onClick={() => this.setState({ hideBookings: !hideBookings })}
              >
                {hideBookings
                  ? "Show Individual Bookings"
                  : "Hide Individual Bookings"}
              </div>
              <div
                className="col-md-5 my-3 cursor text-primary"
                onClick={() =>
                  this.props.downloadPackingInvoice(asset.ticket.id)
                }
              >
                Packing List
              </div>
            </div>

            {!hideBookings && (
              <React.Fragment>
                <button
                  className="btn-sm btn-primary"
                  disabled={
                    !this.state.selectedBookings.length ||
                    this.state.disableSelectedEquipmentButton
                  }
                  style={{
                    cursor:
                      !this.state.selectedBookings.length ||
                      this.state.disableSelectedEquipmentButton
                        ? "no-drop"
                        : "pointer",
                  }}
                  onClick={() => this.checkSelectedHandler()}
                >
                  {`Check Selected Equipment ${type} for This Ticket`}
                </button>
                <div className="mt-3">
                  <span className="cursor" onClick={this.selectAllHandler}>
                    Select All
                  </span>
                  <span className="mx-2">|</span>
                  <span className="cursor" onClick={this.deSelectAllHandler}>
                    Deselect All
                  </span>
                </div>

                <div className="my-2">{assetCheckboxArr}</div>
              </React.Fragment>
            )}
          </div>
        </div>
      </li>
    )
  }
}

export default ActivityDetail
