import axios from "axios";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/browser";
import history from "./history"

import storeConfig from "./store/configStore";

import { logoutUser } from "./store/actions";

if (
  process.env.NODE_ENV === "production" &&
  (window.location.hostname.includes("amsnetwork.ca") ||
    window.location.hostname.includes("members.fava.ca"))
) {
  axios.defaults.baseURL = `https://${
    window.location.hostname.split(".")[0]
  }.amsnetwork.app/api/v3`;
} else if (process.env.REACT_APP_API_HOST === undefined) {
  axios.defaults.baseURL = "https://favadbstaging.herokuapp.com/api/v3";
} else {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}/api/v3`;
}

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

window.addEventListener("storage", (event) => {
  if (event.key === "authToken" && event.oldValue && !event.newValue) {
    window.location.href = "/login";
  }
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const store = storeConfig();

    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch(logoutUser());
        window.location.href = "/login";
      } else if (error.response.status === 404) {
        if (process.env.NODE_ENV === "production" && navigator.onLine) {
          Sentry.captureException(error);
        }
        history.push(`/error/404`)
      } else {
        history.push({
          pathname: '/error/0',
          state: { detail: error }
        })
      }
    }
    if (error.code === "ECONNABORTED") {
      toast.error("Request Timeout");
    }
    return Promise.reject(error);
  }
);

export default axios;
