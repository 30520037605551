import React, { Component } from 'react';
import { withRouter } from "react-router-dom"
import is from "is_js"

import Modal from "../UI/Modal";
import SearchFilter from "../common/searchFilter/SearchFilter"
import Pagination from "../UI/Pagination";
import Spinner from "../UI/Spinner"
import axios from "../../configAxios";

import AddorEditDonationCampaign from "./AddorEditDonationCampaign";
import ShowCampaignInfo from "./ShowCampaignInfo";

export class DonationsCampaigns extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      loading: true,
      campaigns: [],
      showCampaignModal: false,
      showCampainDetails: null,
      showEditCampaignModal: false,
      editCampaignId: null,
      forcePage: 0,
      perPage: 10,
      totalCount: 0
    }
  }

  componentDidMount = () => {
    this.getCampaigns()
  }

  componentWillUnmount = () => {
    this.signal.cancel("Request is being cancelled");
  }

  getCampaigns = page => {
    const forPageChange = page && page.hasOwnProperty("selected");

    if (forPageChange)
      window.scrollTo(0, 0);

    this.setState({
      loading: true,
      forcePage: forPageChange ? page.selected : 0
    });
    const attrValue = {
      params: {
        ...forPageChange && { page: page.selected + 1 },
        per_page: this.state.perPage,
      },
      cancelToken: this.signal.token
    }
    axios.get("/donation_campaigns", attrValue)
      .then(res => {
        this.setState({
          loading: false,
          campaigns: res.data.donation_campaigns
            ? res.data.donation_campaigns
            : [],
          totalCount: res.data.meta.total
        })
      })
  }

  pageFilterHandler = (newPerPage) => {
    this.setState({
      perPage: newPerPage
    }, () => this.getCampaigns())
  }

  modalCloseHandler = () => this.setState({ showCampaignModal: false });

  render() {
    const {
      campaigns,
      loading,
      showCampainDetails,
      forcePage,
      totalCount,
      perPage
    } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h4 className="mb-4">Campaigns</h4>
          </div>
          <div className="col-6 text-right">
            <span
              className="cursor"
              onClick={e => {
                if (is.firefox())
                  this.props.history.push("/donations")
                else
                  this.props.history.goBack()
              }}
            >
              {"<< Back"}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white pt-3">
              <div className="row">
                <div className="offset-sm-8 col-sm-4">
                  <SearchFilter
                    perPage={perPage}
                    inputChangeHandler={this.pageFilterHandler}
                  />
                </div>
              </div>
              {loading
                ? <Spinner /> :
                (<>
                  {campaigns ?
                    (<>
                      <div className="table-responsive-md p-0">
                        <table className="table text-center">
                          <thead>
                            <tr>
                              <th className="border-top-0 text-left" scope="col">Name</th>
                              <th className="border-top-0" scope="col">Status</th>
                              <th className="border-top-0" scope="col">Goal</th>
                              <th className="border-top-0" scope="col">Raised</th>
                              <th className="border-top-0" scope="col">Info</th>
                              <th className="border-top-0" scope="col">Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaigns.map(campaign => (
                              <tr key={campaign.id}>
                                <td className="text-left py-3">{campaign.name}</td>
                                <td className="py-3 text-transform-capitalize">{campaign.status}</td>
                                <td className="py-3 text-transform-capitalize">
                                  ${campaign.target_amount ? campaign.target_amount : "0.00"}
                                </td>
                                <td className="py-3 text-transform-capitalize">
                                  ${campaign.raised ? campaign.raised : "0.00"}
                                </td>
                                <td className="py-3">
                                  <i
                                    className="fas fa-info-circle cursor"
                                    onClick={e => this.setState({
                                      showCampaignModal: true,
                                      showCampainDetails: campaign
                                    })}
                                  />
                                </td>
                                <td className="py-3">
                                  <i
                                    className="fas fa-pen cursor"
                                    onClick={e => this.setState({
                                      showEditCampaignModal: true,
                                      editCampaignId: campaign.id
                                    })}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        forcePage={forcePage}
                        pageCount={totalCount / perPage}
                        handlePageClick={this.getCampaigns}
                      />
                    </>)
                    : (
                      <div className="text-center h4 p-5 bg-white">
                        No Campaigns
                      </div>)
                  }
                </>)
              }
            </div>
          </div>
        </div>
        <Modal
          closeButton
          closeModal={this.modalCloseHandler}
          hide={this.modalCloseHandler}
          show={this.state.showCampaignModal}
          title="Campaign Details"
        >
          {showCampainDetails && (
            <ShowCampaignInfo showCampainDetails={showCampainDetails} />
          )}
        </Modal>
        <Modal
          closeButton
          closeModal={e => this.setState({ showEditCampaignModal: false })}
          hide={e => this.setState({ showEditCampaignModal: false })}
          show={this.state.showEditCampaignModal}
          title="Campaign Details"
        >
          <AddorEditDonationCampaign
            modalCloseHandler={e => this.setState({ showEditCampaignModal: false })}
            campaignId={this.state.editCampaignId}
            editCampaign={true}
            getUpdatedCampaigns={() => this.getCampaigns({ selected: forcePage })}
          />
        </Modal>
      </div>
    )
  }
}

export default withRouter(DonationsCampaigns);
