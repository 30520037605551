import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import dateFns from "date-fns";

import DatePicker from "../../UI/datePicker";
import Button from "../../UI/button/Button";
import axios from "../../../configAxios";
import Spinner from "../../UI/Spinner";

import "./index.css";

class Report extends Component {
  constructor(props) {
    super(props);
    const currentMonthDate = new Date();
    this.signal = axios.CancelToken.source();
    this.state = {
      startDate: new Date().setMonth(currentMonthDate.getMonth() - 1),
      endDate: currentMonthDate,
      assetName: null,
      metaData: null,
      reports: [],
      loading: true,
      bookedDates: null,
      reportData: [],
      disabled: true
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getReport({ generate_csv: false });
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  getReport = params => {
    if (params.generate_csv) {
      toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
    } else {
      this.setState({ disabled: true })
    }
    const { startDate, endDate } = this.state;
    axios.get("/reports/asset/report", {
      params: {
        id: this.props.match.params.id,
        "report_options[start][day]": dateFns.format(startDate, "D"),
        "report_options[start][month]": dateFns.format(startDate, "M"),
        "report_options[start][year]": dateFns.format(startDate, "YYYY"),
        "report_options[end][day]": dateFns.format(endDate, "D"),
        "report_options[end][month]": dateFns.format(endDate, "M"),
        "report_options[end][year]": dateFns.format(endDate, "YYYY"),
        ...params
      },
      cancelToken: this.signal.token
    })
      .then(res => {
        if (params.generate_csv) {
          this.setState(
            {
              reportData: res.data.json.data,
            },
            () => this.refs.csv.link.click()
          );
        } else {
          const bookedDates = {};
          res.data.meta.booking_dates.forEach(date => {
            const monthYear = dateFns.format(date, "MMMM YYYY");
            const formatedDate = dateFns.format(date, "ddd DD");
            if (bookedDates[monthYear] && bookedDates[monthYear].length) {
              bookedDates[monthYear].push(formatedDate);
            } else {
              bookedDates[monthYear] = [formatedDate];
            }
          });
          this.setState({
            loading: false,
            metaData: res.data.meta,
            reports: res.data["reports/asset"],
            bookedDates,
            disabled: false
          });
        }
      });
  };

  render() {
    const { metaData, reports, bookedDates } = this.state;
    const info = {
      total_value_of_invoices:
        "Total Value of Invoices that include this Asset",
      total_value_of_invoices_for_program_reservations:
        "Total Value of Invoices for Program Reservations that include this Asset",
      total_revenue_from_bookings:
        "Total Revenue strictly from Bookings of this Asset",
      total_fava_buck_payments:
        "Total FAVA Bucks Payments made on Invoices that include this Asset",
      total_credit_amounts:
        "Total Service in Kind amounts on Invoices that include this Asset",
      total_period_booked: "Total number of days/hours Booked",
      total_period_charged: "Total number of days/hours Charged",
      average_amount: "Average amount of time the Asset was booked in advance"
    };

    const table = (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col" className="w-20">Rental date</th>
            <th scope="col" className="w-10">Invoice Number</th>
            <th scope="col" className="w-30">Project Name</th>
            <th scope="col" className="w-10">Ticket Notes</th>
            <th scope="col" className="w-20">Program Name</th>
            <th scope="col" className="w-10">Member Name</th>
            <th scope="col" className="w-10">Booking Value</th>
          </tr>
        </thead>
        <tbody>
          {
            reports.map((report, index) => {
              return (
                <tr key={index}>
                  <td>
                    {
                      `${dateFns.format(report.details.start_time, "YYYY-MM-DD")} 
                      to 
                      ${dateFns.format(report.details.end_time, "YYYY-MM-DD")}`
                    }
                  </td>
                  <td>
                    <Link to={`/invoices/${report.invoice.id}`} target="_self" className="text-info">
                      {`${report.invoice.reference ? report.invoice.reference : "--"}`}
                    </Link>
                  </td>
                  <td>
                    {`${report.ticket.project_name ? report.ticket.project_name : "--"} `}
                  </td>
                  <td>
                    {`${report.ticket.notes ? report.ticket.notes : "--"} `}
                  </td>
                  <td>
                    {`${report.user_or_program_detail.name ? report.user_or_program_detail.name : "--"} `}
                  </td>
                  <td>
                    {
                      `${report.user_or_program_detail.first_name ? report.user_or_program_detail.first_name : ""} 
                    ${report.user_or_program_detail.last_name ? report.user_or_program_detail.last_name : ""}`
                    }
                  </td>
                  <td>
                    {report.value_of_booking}
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );

    let bookedDatesContent;
    if (!this.state.loading) {
      bookedDatesContent = Object.keys(bookedDates).map(key => {
        return (
          <div className="col-md-12" key={key}>
            <div className="font-weight-bold mt-2">{key}</div>
            <div className="row mt-2">
              {" "}
              {bookedDates[key].map((date, index) => (
                <div className="col-md-1" key={index}>
                  {date}
                </div>
              ))}
            </div>
          </div>
        );
      });
    }

    return (
      <div className="asset-report container w-100">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  {
                    this.state.loading ? <Spinner /> : (
                      <div>
                        <div className="heading">
                          {`Asset Report for ${metaData.asset_name}`}
                        </div>
                        <div className="row">
                          <div className="col-lg-3 ml-3">
                            <DatePicker
                              selected={this.state.startDate}
                              changed={startDate => this.setState({ startDate })}
                            />
                          </div>
                          <div className="col-lg-1 text-center">
                            <div className="mt-2 text-center">to</div>
                          </div>
                          <div className="col-lg-3 ml-3">
                            <DatePicker
                              selected={this.state.endDate}
                              changed={endDate => this.setState({ endDate })}
                            />
                          </div>
                          <div className="col-md-2 buttons">
                            <Button
                              type="warning"
                              clicked={() => this.getReport({ generate_csv: false })}
                              disabled={this.state.disabled}
                            >
                              Run Report
                            </Button>
                          </div>
                          <div className="col-md-2 buttons">
                            <Button
                              type="success"
                              clicked={() => this.getReport({ generate_csv: true })}
                            >
                              Generate CSV
                            </Button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-5 info-text">
                            The following two Invoice Totals represents the line items on
                            the Invoice(including manually entered line items); they do not
                            include any Special Payments or Tax Amounts
                          </div>
                          <div className="col-md-12 mt-2 mb-4 info-text">
                            Note that these amounts are for entire Invoice, which likely
                            contains booking for other Assets as well
                          </div>
                        </div>


                        <div className="row my-4 no-gutters">
                          {
                            Object.keys(info).map(key => {
                              return (
                                <div className="col-lg-4">
                                  <div className="card border m-2 h-20vh" key={key}>
                                    <div className="card-body">
                                      <div className="card-text">
                                        <div className="amount">{metaData[key]}</div>
                                        <div className="text-center info-text">{info[key]}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>

                        <div className="table-responsive">{table}</div>

                        <div className="row">
                          <div className="col-12 booked-dates-heading">Booking Dates</div>
                          <div className="col-12 booking-dates">{bookedDatesContent}</div>
                          <CSVLink
                            data={this.state.reportData}
                            filename={"asset_report.csv"}
                            target="_blank"
                            ref="csv"
                          />
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Report;
