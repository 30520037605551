import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";

import AuthContainer from "./AuthContainer";
import Logo from "./formComponents/Logo"
import onError from "../../utils/onError";

class UpdatePassword extends Component {
  state = {
    controls: {
      token: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "token",
          required: true,
        },
        label: "Validation Token",
        value: ""
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          name: "password",
          required: true
        },
        label: "New Password",
        value: ""
      },
      confirm: {
        elementType: "input",
        elementConfig: {
          type: "password",
          name: "confirm",
          required: true
        },
        label: "Confirm Password",
        value: ""
      }
    },
    disableSubmitButton: false,
    message: ""
  };

  inputChangedHandler = event => {
    const updateControls = {
      ...this.state.controls,
      [event.target.name]: {
        ...this.state.controls[event.target.name],
        value: event.target.value
      }
    };
    this.setState({ controls: updateControls });
  };

  submitHandler = event => {
    event.preventDefault();

    if (this.state.controls.password.value === this.state.controls.confirm.value) {
      const data = {
        token: this.state.controls.token.value,
        password: this.state.controls.password.value,
        password_confirmation: this.state.controls.confirm.value
      };
      axios.put('/passwords', data)
        .then(res => {
          toast.success("Password Updated Successfully")
          this.props.history.push(`/confirm_password_update`)
        })
        .catch((err) => {
          const { errors: resError } = err.response.data.json
          onError(resError)
        })
    } else {
      toast.error("Passwords do not match")
    }
  };

  renderFormInputs = () => {
    return (
      <>
        <Input
          {...this.state.controls.token}
          changed={event => this.inputChangedHandler(event)}
        />
        <Input
          {...this.state.controls.password}
          changed={event => this.inputChangedHandler(event)}
        />
        <Input
          {...this.state.controls.confirm}
          changed={event => this.inputChangedHandler(event)}
        />
      </>
    )
  }

  render() {
    return (
      <AuthContainer>
        <div className="card-body">
          <div className="card-text p-3">
            <Logo />
            <div className="text-dark text-center">
              Update your password
            </div>
            <form onSubmit={this.submitHandler} className="mt-4">
              {this.renderFormInputs()}
              <FormButton block disabled={this.state.disableSubmitButton}>
                Update my password
              </FormButton>
            </form>
          </div>
        </div>
      </AuthContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginUrlOverride: state.loginAssets.loginUrlOverride }
}

export default withRouter(connect(mapStateToProps)(UpdatePassword))
