import React from 'react'
import Spinner from "../../assests/images/spinner.gif";

const ModalSpinner = () => {
  return (
    <div>
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading"
      />
    </div>
  )
}

export default ModalSpinner;