import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import dateFnsFormat from "date-fns/format"
import { OverlayTrigger, Popover } from "react-bootstrap"

import Spinner from "../../UI/Spinner"
import Button from "../../UI/button/Button"
import axios from "../../../configAxios"
import langUtils from "../../../utils/langUtils"

import NewContest from "./NewContest"
import Contest from "./Contest"


class Contests extends Component {
  signal = axios.CancelToken.source();
  state = {
    contests: [],
    id: null,
    isEdit: false,
    loading: true
  };

  componentDidMount() {
    this.getContests();
  }

  componentWillUnmount() {
    this.signal.cancel("Requests being Cancelled");
  }

  getContests = () => {
    this.setState({ loading: true });
    axios.get("/contests", { cancelToken: this.signal.token }).then(res => {
      this.setState({ 
        contests: res.data.contests,
        loading: false
      });
    })
  };

  showVotes = (item) => {
    this.props.tabChangeHandler("Votes", "votes", item.id, item.name)
  }

  render() {
    const contests = this.state.contests;
    let contestsList;
    if (this.state.loading) {
      contestsList = <Spinner />;
    } else {
      contestsList = contests.map((item, key) => (
        <li className="list-group-item rounded-0 border-top projectAttr" key={key}>
          <div className="row">
            <div className="col-lg-1">
              <OverlayTrigger
                key={"top"}
                placement={"top"}
                overlay={
                  <Popover>
                    Contest Id: <span className="font-weight-bold">{item.id}</span>
                  </Popover>
                }
              >
                <i className="fa fa-info-circle text-muted cursor" aria-hidden="true" />
              </OverlayTrigger>
            </div>
            <div className="col-lg-3">{item.name}</div>
            <div className="col-lg-3 text-center">
              {dateFnsFormat(item.start_date, "YYYY-MM-DD hh:mm A")}
            </div>
            <div className="col-lg-3 text-center">
              {dateFnsFormat(item.end_date, "YYYY-MM-DD hh:mm A")}
            </div>
            <div className="col-lg-2">
              <div className="d-flex justify-content-around">
                <i
                  className="fas fa-eye projectAttr-show cursor"
                  onClick={() => this.props.showContestDetails(item.id)}
                />
                <i
                  className="fas fa-pencil-alt cursor projectAttr-edit"
                  onClick={() => this.props.showEditContestForm(item.id)}
                />
                <span className="cursor" onClick={() => this.showVotes(item)}>
                  Votes
                </span>
              </div>
            </div>
          </div>
        </li>
      ));
    }
    let content;
    if (this.props.newForm) {
      content = (
        <NewContest
          edit={false}
          showContests={this.props.showContests}
          getContests={this.getContests}
        />
      )
    } else if (this.props.edit) {
      content = (
        <NewContest
          edit={true}
          showContests={this.props.showContests}
          id={this.props.id}
          getContests={this.getContests}
        />
      )
    } else if (this.props.details) {
      content = (
        <Contest
          showContests={this.props.showContests}
          id={this.props.id}
        />
      )
    } else {
      content = (
        <>
          <div className="row p-3">
            <div className="col-lg-12 text-right">
              <Button type="success" clicked={this.props.showCreateContestForm}>
                {langUtils("txt_global_add_new", "Add New")}
              </Button>
            </div>
          </div>

          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <div className="row">
                <div className="col-lg-1">
                  <span className="font-weight-bold">Id</span>
                </div>
                <div className="col-lg-3">
                  <span className="font-weight-bold">Name</span>
                </div>
                <div className="col-lg-3 text-center">
                  <span className="font-weight-bold">Start Date</span>
                </div>

                <div className="col-lg-3 text-center">
                  <span className="font-weight-bold">End Date</span>
                </div>

                <div className="col-lg-2" />
              </div>
            </li>
            {contestsList}
          </ul>
        </>
      )
    }
    return (
      <div>{content}</div>
    );
  }
}

export default withRouter(Contests);
