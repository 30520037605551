import React from 'react'
import Spinner from '../../Spinner'
import ApplicationsList from './ApplicationsList'
import { Route, Switch } from 'react-router-dom'
import Button from "../../../UI/button/Button"

export default function ApplicationsIndexBody({ grantCampaign, applicationsList, isLoading, match }) {
  const activeTab = (string) => match.url.includes(string) ? "active-tab" : "inactive-tab"

  const JuryPackageDownload = () => {
    return (
      <a target="_blank" rel="noopener noreferrer" href={grantCampaign.jury_package}>
        <Button type="primary" className="btn btn-primary mr-4">
          <div>View Jury Package</div>
        </Button>
      </a>
    )
  }

  return (
    <div>
      { isLoading 
        ?
        <Spinner />
        :
        <>
          <div className="ml-4 mt-3 mb-5 d-flex flex-row justify-content-between align-items-center">
            <h3>Applications</h3>
            {grantCampaign.jury_package &&
            <JuryPackageDownload/>
            }
          </div>
          <ul className="nav nav-tabs font-weight-bold mb-4">
            <li className={`nav-item px-4 ${activeTab("applications")}`}>
              Applications List
            </li>
          </ul>
          <Switch>
            <Route path="" render={() => <ApplicationsList grantCampaign={grantCampaign} applicationsList={applicationsList} />} />
          </Switch>
        </>
      }
    </div>
  )
}
