import React, { useEffect, useState } from 'react'
import Spinner from "../../../Spinner";
import axios from '../../../../../configAxios'
import FormQuestions from '../../../../grantApplications/applicationForms/FormQuestions'
import BackLink from "../../../../UI/button/BackLink"

function CompletedApplicationForm({ match, history }) {
  const [applicationForm, setApplicationForm] = useState(null)
  const [form, setForm] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const campaignBackUrl = `/campaigns/${match.params.id}/applications/show/${match.params.application_id}`
  const applicationBackUrl = `/grant_applications`

  const getApplicationForm = () => {
    axios.get(`/application_forms/${match.params.application_form_id}`).then(res => {
      setApplicationForm(res.data.json.data.application_form)
    })
  }

  const getForm = () => {
    axios.get(`/forms/${applicationForm.form_id}`).then(res => {
      setForm(res.data.json.data.form)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    !applicationForm && getApplicationForm()
  }, [])

  useEffect(() => {
    applicationForm && getForm()
  }, [applicationForm])

  const CampaignBack = () => {
    return (
      <BackLink history={history} backUrl={campaignBackUrl}>
        Back to Application
      </BackLink>
    )
  }

  const ApplicationBack = () => {
    return (
      <BackLink history={history} backUrl={applicationBackUrl}>
        Back to Applications
      </BackLink>
    )
  }

  return (
    <div>
      { isLoading
        ?
        <Spinner />
        :
        <>
          { form && applicationForm &&
            <div className="w-100">
              <div className="d-flex flex-row justify-content-center">
                <div className="w-90 card px-4 pb-4 pt-5">
                  <div className="d-flex flex-row justify-content-between mb-3">
                    <h3 className="m-0">{form.name}</h3>
                    { match.path.includes(`/campaigns`)
                      ? <CampaignBack />
                      : <ApplicationBack />
                    }
                  </div>
                  <FormQuestions form={form} responses={applicationForm.responses} applicantData={applicationForm.applicant_fields} completed={true} setResponses={() => ""}/>
                </div>
              </div>
            </div>
          }
        </>
      }
    </div>
  )
}

export default CompletedApplicationForm
