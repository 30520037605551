import React, { Component } from "react";
import Button from "../../../UI/button/Button";
import FormButton from "../../../UI/button/FormButton";
import axios from "../../../../configAxios";
import { toast } from "react-toastify";
import { title } from "change-case";
import Input from "../../../UI/input/Input";
import ModalSpinner from "../../../UI/ModalSpinner";

class EditCustomFields extends Component {
  signal = axios.CancelToken.source();
  state = {
    categoryNames: [],

    label: {
      elementType: "input",
      elementConfig: {
        name: "label",
        type: "text",
        required: true
      },
      label: "Label",
      value: ""
    },
    customFieldData: [],
    loading: true,
    disabled: false
  };

  componentDidMount() {
    axios
      .get(`/custom_fields/${this.props.id}`, {
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          customFieldData: res.data.json.data,
          label: {
            ...this.state.label,
            value: res.data.json.data.label
          },
          loading: false
        });
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({disabled: true})
    const customLabel = {
      label: this.state.label.value
    };
    axios
      .put(`/custom_fields/${this.props.id}`, customLabel)
      .then(res => {
        if (res.data.json.success) {
          let message = "Custom fields updated successfully.";
          this.props.closeModal();
          this.props.getCustomFieldsList();
          toast.success(message);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
        this.setState({disabled: false})
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        {
          this.state.loading ? <ModalSpinner /> : (
            <React.Fragment>
              <div className="row">
                <div className="col-lg-4">
                  <div className="py-2">
                    Type/Section
                    </div>
                </div>
                <div className="col-lg-8">
                  <div className="py-2">
                    {this.state.customFieldData.klass_name}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="py-2">
                    Field
                </div>
                </div>
                <div className="col-lg-8">
                  <div className="py-2">
                    {this.state.customFieldData.column_name}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <Input
                    {...this.state.label}
                    changed={event => this.inputChangedHandler(event, "label")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <Button type="secondary" clicked={this.props.closeModal}>
                    Cancel
                  </Button>
                  <FormButton className="ml-2" disabled={this.state.disabled}>Update Custom Field Label</FormButton>
                </div>
              </div>
            </React.Fragment>
          )
        }
      </form>
    );
  }
}

export default EditCustomFields;
