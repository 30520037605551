import Select from "react-select";
import React from "react";
import { toast } from "react-toastify";
import axios from "../../../configAxios";

const LabelDropdown = ({ categoryId, setCategoryId, labelOptions, tags, setTags, programId }) => {

  const addTag = (selected) => {
    const programTagArray = tags.map(tag => tag.label)

    if (programTagArray.includes(selected.label)){
      setCategoryId(null)
      return toast.error('Program already has label assigned')
    }
    
    axios.post("/tags", {query: selected.value, program_id: programId})
      .then((res) => {
        if (res.data.json.success) {
          setTags(prevState => [...prevState, selected])
          toast.success("Label successfully added.")
          setCategoryId(null)
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <Select
      placeholder="Select Label"
      isDisabled={!categoryId}
      options={labelOptions}
      onChange={selected => addTag(selected)}
      value={null}
    />
  )
}

export default LabelDropdown
