import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { title, snakeCase } from "change-case"

import Invoicing from "./Invoicing"
import MembershipRenewal from "./MembershipRenewal"
import Rentals from "./Rentals"
import VolunteerHour from "./VolunteerHour"
import EmailAdresses from "./EmailAdresses"
import General from "./General"

import "./Invoicing.css"

class Communication extends Component {
  state = {
    activeTab:
      typeof this.props.match.params.toptab !== "undefined"
        ? title(this.props.match.params.toptab)
        : "Invoicing",
  }

  componentDidMount() {
    this.handleTabFromProp()
    window.addEventListener("popstate", this.handleTabFromProp)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.toptab !== this.props.match.params.toptab)
      this.handleTabFromProp()
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp)
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : "Invoicing",
    })
  }

  tabChangeHandler = (activeTab) => {
    this.setState({ activeTab })
    this.props.history.push(
      `/system_settings/communication/${snakeCase(activeTab)}`
    )
  }

  render() {
    const tabNames = [
      "Invoicing",
      "Membership Renewal",
      "Rentals",
      "Volunteer Hour",
      "Email Adresses",
      "General",
    ]

    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${
          this.state.activeTab === tab ? "active-tab" : "inactive-tab"
        }`}
      >
        {tab}
      </li>
    ))

    let activeTab
    switch (this.state.activeTab) {
      case "Invoicing":
        activeTab = <Invoicing />
        break
      case "Membership Renewal":
        activeTab = <MembershipRenewal />
        break
      case "Rentals":
        activeTab = <Rentals />
        break
      case "Volunteer Hour":
        activeTab = <VolunteerHour />
        break
      case "Email Adresses":
        activeTab = <EmailAdresses />
        break
      case "General":
        activeTab = <General />
        break
      default:
        activeTab = null
    }

    return (
      <div className="card communication">
        <ul className="nav nav-tabs font-weight-bold ml-0">{tabs}</ul>
        {activeTab}
      </div>
    )
  }
}

export default withRouter(Communication)
