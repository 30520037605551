import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";

import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import Button from "../UI/button/Button";
import Spinner from "../UI/ModalSpinner";
import axios from "../../configAxios";

class RecordNotes extends Component {
  state = {
    recordNotes: {
      elementType: "textarea",
      elementConfig: {
        name: "recordNotes",
        rows: "4"
      },
      value: "",
      label: "Record Notes"
    },
    loading: true,
    disabled: false
  };

  componentDidMount() {
    axios.get(`/programs/${this.props.programId}`).then(res => {
      this.setState({
        loading: false,
        recordNotes: {
          ...this.state.recordNotes,
          value: res.data.program.program_notes
        }
      });
    });
  }

  inputChangedHandler = event => {
    this.setState({
      recordNotes: {
        ...this.state.recordNotes,
        value: event.target.value
      }
    });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    axios
      .put(`/programs/${this.props.programId}/record_notes`, { program_notes: this.state.recordNotes.value })
      .then(res => {
        if (res.data.json.success) {
          toast.success(`Record notes ${this.props.editNotes ? "updated" : "added"} successfully.`);
          this.props.getProgramAndPolicy()
          this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      });
  };

  render() {
    return (
      <>
        {this.state.loading
          ? <Spinner />
          : <form className="mt-3" onSubmit={this.submitHandler}>
            <Input {...this.state.recordNotes} changed={this.inputChangedHandler} />
            <div className="text-center">
              <Button type="secondary" clicked={this.props.closeModal}>
                Cancel
              </Button>
              <FormButton
                className="ml-2"
                disabled={this.state.disabled}
              >
                {this.props.editNotes ? "Update" : "Submit"}
              </FormButton>
            </div>
          </form>
        }
      </>
    );
  }
}

export default RecordNotes;
