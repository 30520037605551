import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";
import axios from "../../configAxios";
import NoImage from "../../assests/images/no-image.png";
import { getProject } from "../../store/actions";
import { connect } from "react-redux"

class EditImage extends Component {
  state = {
    images: [],
    projectId: null,
    disableSelectButton: false
  };

  componentDidMount() {
    axios
      .get("/project_attributes", {
        params: {
          project_id: this.props.projectId,
          type: "Media Type"
        }
      })
      .then(res => {
        let images = [];
        res.data.project_attributes.forEach(attr => {
          if (["Photo", "Graphic", "Image"].includes(attr.project_attribute_type_name)) {
            images.push({
              id: attr.id,
              fileName: attr.file_attachment_thumbnail,
              projectAttrTypeId: attr.project_attribute_type_id,
              projectId: attr.project_id
            });
          }
        });
        this.setState({
          images
        });
      });
  }

  selectedImageHandler = file => {
    this.setState({ disableSelectButton: true });
    let attrData = {};
    attrData["id"] = file.id;
    attrData["project_id"] = file.projectId;
    attrData["project_attribute_type_id"] = file.projectAttrTypeId;
    attrData["value_3"] = true;
    axios.put(`/project_attributes/${file.id}`, attrData).then(res => {
      if (res.data.json.success) {
        this.props.closeModal();
        this.props.getProject(this.props.projectId)
        this.props.updateThumbnail()
        toast.success("Image uploaded successfully");
      } else {
        this.props.closeModal();
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(title(error) + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
      }
    });
  };

  render() {
    const { images } = this.state;
    const imageFiles = (
      <div className="row existing-images m-0">
        {images.map(ele => {
          const image = ele.fileName ? ele.fileName : NoImage;
          return <div className="col-md-6 mt-3" key={ele.id}>
            <div
              className="present-image bg-cover"
              style={{ backgroundImage: `url(${image})` }}
            />
            <div className="button mt-3">
              <button
                className="btn btn-secondary"
                disabled={this.state.disableSelectButton}
                onClick={() => this.selectedImageHandler(ele)}
              >
                Select
              </button>
            </div>
          </div>
        })}
      </div>
    );
    return (
      <div className="project-edit-image">
        <div className="existing-images">
          {imageFiles}
        </div>
      </div>
    );
  }
}

export default connect(null, { getProject })(EditImage)
