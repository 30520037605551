import React, { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { formatDate } from "../../utils/formatDate"
import { title } from "change-case"

import Modal from "../UI/Modal"
import MoreInfo from "./MoreInfo"
import ProgramConfirmation from "./ProgramConfirmation"
import axios from "../../configAxios"

class Program extends Component {
  signal = axios.CancelToken.source()
  state = {
    showModal: false,
    programs: [],
    registeredId: this.props.registeredId,
    userData: "",
    showRegisterModal: false,
    disableRegisterButton: false,
  }

  openModal = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  registerHandler = (file) => {
    const registerId = this.props.registeredId.slice(0)
    const formData = new FormData()
    formData.append("program_id", this.props.program.id)
    formData.append("user_id", this.props.userId)
    if (file) formData.append("file_attachment", file)

    this.setState({ disableRegisterButton: true })
    axios
      .post("/registrations", formData)
      .then((res) => {
        if (res.data.json.success) {
          this.props.registerUpdate(this.props.program.id)
          toast.success("Successfully registered for program.")
          this.setState({ showRegisterModal: false })
          registerId.push(this.props.program.id)
        } else {
          this.setState({ disableRegisterButton: false })
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
      .catch(() => {
        this.setState({ disableRegisterButton: false })
      })
  }

  closeRegisterModal = () => this.setState({ showRegisterModal: false })

  render() {
    const program = this.props.program
    const formattedEarlyBirdDiscountCutoff = formatDate(
      new Date(program.earlybird_cutoff).toDateString().substring(4),
      "YYYY/MM/DD")
    const formattedDropDeadlineDate = formatDate(
      new Date(program.drop_cutoff),
      "YYYY/MM/DD")

    return (
      <div className="list-group-item">
        <div className="row">
          <div className="col-lg-6">
            <Link
              to={this.props.managePrograms ? `/programs/${program.id}` : ""}
              className="text-info font-weight-bold"
              style={{
                cursor: this.props.managePrograms ? "pointer" : "no-drop",
              }}
              onClick={
                this.props.managePrograms ? null : (e) => e.preventDefault()
              }
            >
              {program.name}
            </Link>
            <p className="mt-2">
              Spots:{" "}
              <span>
                {program.number_of_enrolled} / {program.max_participants}
              </span>
              <br />
              <span>
                Min Registrations Required: {program.min_participants}
              </span>
            </p>
            <p
              className="text-color cursor mb-0"
              onClick={this.props.managePrograms ? this.openModal : null}
              style={{
                cursor: this.props.managePrograms ? "pointer" : "no-drop",
              }}
            >
              More Info...
            </p>
          </div>
          <div className="col-lg-6">
            <p className="text-right">
              Non Member ${program.enrollment_price} / Member $
              {program.member_enrollment_price}
              <br />
              <span>
                Discount: ${program.earlybird_discount} off before{" "}
                {formattedEarlyBirdDiscountCutoff}
              </span>
              <br />
              <span>
                Drop Deadline:{" "}
                {formattedDropDeadlineDate}
              </span>
            </p>
            <p className="text-right text-color mb-0">
              <span className="cursor">
                <Link
                  to={
                    this.props.managePrograms ? "/programs/" + program.id : ""
                  }
                  className="text-info"
                  onClick={
                    this.props.managePrograms ? null : (e) => e.preventDefault()
                  }
                  style={{
                    cursor: this.props.managePrograms ? "pointer" : "no-drop",
                  }}
                >
                  View Program
                </Link>
              </span>{" "}
              |{" "}
              <span>
                {this.props.registeredId.includes(program.id) ? (
                  <span className="registered-text">Registered</span>
                ) : (
                  <span
                    className="cursor"
                    onClick={
                      this.props.managePrograms
                        ? () => {
                          window.scrollTo(0, 0)
                          this.setState({ showRegisterModal: true })
                        }
                        : null
                    }
                    style={{
                      cursor: this.props.managePrograms ? "pointer" : "no-drop",
                    }}
                  >
                    Register
                  </span>
                )}
              </span>
            </p>
          </div>
        </div>
        <Modal
          closeButton
          closeModal={() => this.closeModal()}
          hide={() => this.setState({ showModal: false })}
          show={this.state.showModal}
          title={program.name}
          style={{ top: "18%" }}
          flag={true}
          program={true}
        >
          <MoreInfo
            program={program}
            closeModal={() => this.setState({ showModal: false })}
          />
        </Modal>
        <Modal
          closeButton
          closeModal={this.closeRegisterModal}
          hide={() => this.setState({ showRegisterModal: false })}
          show={this.state.showRegisterModal}
          title={`Program Registeration - ${program.name}`}
          style={{ top: "10%" }}
        >
          <ProgramConfirmation
            program={program}
            closeModal={this.closeRegisterModal}
            registerHandler={this.registerHandler}
            disabled={this.state.disableRegisterButton}
          />
        </Modal>
      </div>
    )
  }
}

export default Program
