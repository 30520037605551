import "./index.css"
import React from "react";
import InvoiceDetailEditor from "./InvoiceDetailEditor";

const InvoiceTable = props => {
  const { organization, invoice_items, show_community_support_billing } = props.invoice
  let differentialTotal = props.invoice.full_rate_differential_total.toString()
  let positive = !differentialTotal.includes("-")
  if (!positive) {
    differentialTotal = differentialTotal.replace("-", "")
  }
  let tableData = invoice_items.map(item => {
    let accountingCode, accountingCodeId;
    if (item.accounting_code && item.accounting_code.code) {
      accountingCode = item.accounting_code.code;
      accountingCodeId = item.accounting_code.id
    } else if (item.registration_code && item.registration_code.code) {
      accountingCode = item.registration_code.code;
      accountingCodeId = item.registration_code.id;
    }
    return {
      code: accountingCode,
      codeId: accountingCodeId,
      description: item.description,
      amount: item.amount,
      invoiceId: item.id
    };
  });

  const EditFormRender = ({ rowData }) => {
    const attr = ["description", "amount"]
    const invoiceIncluded = props.selectedToEdit.includes(rowData.invoiceId)

    return (
     <>
       {attr.map(att => (
         <td>
           {invoiceIncluded && (
             <InvoiceDetailEditor
               type={att}
               editDetailHandler={detail => {
                 props.editInvoiceDetailHandler(rowData.invoiceId, detail, att)
               }}
               detail={props.selectedToEditInvoiceDetails[props.selectedToEditInvoiceDetails.findIndex(detail => detail.id === rowData.invoiceId)][att]}
             />
           )}
           {!invoiceIncluded && rowData[att]}
         </td>
       ))}
     </>
    )
  }

  return (
    <>
      {tableData.map(rowData => (
        <tr className="w-100">
          <td className="column-15 text-center">{rowData.code}</td>
          {!props.editInvoice && <td className="column-70 text-center">{rowData.description}</td>}
          {!props.editInvoice && (
            <td className="column-15 text-center">
              {`${rowData.amount && rowData.amount.toString().includes("$") ? "" : "$"} ${rowData.amount}`}
            </td>
          )}
          {props.editInvoice && <EditFormRender {...props} rowData={rowData} />}
          {props.editInvoice && (
            <>
              <td className="text-center">
                <input
                  type="checkbox"
                  checked={props.selectedInvoices.includes(rowData.invoiceId)}
                  onChange={e => props.selectedInvoiceHandler(rowData.invoiceId)}
                />
              </td>
              <td className="text-center">
                <i className="fas fa-pen cursor" onClick={e => props.selectToEditHandler(rowData.invoiceId, rowData)} />
              </td>
            </>
          )}
        </tr>
      ))}
      {show_community_support_billing && !props.editInvoice && (
        <>
          <tr>
            <td className="column-15 text-center">
              {organization.full_rate_account_code?.code}
            </td>
            <td className="column-70 text-center">
              {organization["full_rate_description"]}
            </td>
            <td className="column-15 text-center">
              {`${positive ? "" : "-"} ${differentialTotal}`}
            </td>
            {props.editInvoice && <td />}
          </tr>
          <tr>
            <td className="text-center">
              {organization.community_account_code && organization.community_account_code.code}
            </td>
            <td className="text-center">
              {organization["community_description"]}
            </td>
            <td className="text-center">
              {`${!positive ? "" : "-"} ${
                differentialTotal
                }`}
            </td>
            {props.editInvoice && <td />}
          </tr>
        </>
      )}
    </>
  )
}

export default InvoiceTable
