import React from 'react'
import classnames from "classnames"
import { format } from "date-fns"

import "./CampaignItem.css"

const CampaignItem = ({
  status,
  startTime,
  endTime,
  name,
  campaignInfoHandler
}) => {
  return (
    <div className="campaign-item row border-bottom py-3">
      <div className="col-sm-7">
        <div
          className="text-primary cursor campaign-item-name"
          onClick={e => campaignInfoHandler()}
        >
          {name}
        </div>
        <div>
          {startTime
            ? format(startTime, "YYYY.MM.DD")
            : null}
          {startTime && endTime ? " to " : ""}
          {endTime
            ? format(endTime, "YYYY.MM.DD")
            : null}
        </div>
      </div>
      <div className="col-sm-5 py-2 text-right">
        <span
          className={
            classnames(
              "badge text-transform-capitalize white-space p-2 my-auto",
              { "badge-success": status === "active" },
              { "badge-danger": status === "archive" },
              { "badge-muted": status === "draft" }
            )}>
          {status}
        </span>
      </div>
    </div>
  )
}

export default CampaignItem
