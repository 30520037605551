import React from 'react'
import Button from "../../../UI/button/Button";

export default function FileDownloadQuestion({ question }) {
  return (
   <div className="mb-4">
      <p className="mb-2 font-weight-bold">{question.question_text}</p>
      <div className="mb-3 ml-3 d-flex flex-row justify-content-start align-items-center">
        <a href={question.file_attachment.file.url} download="file_download" target="_blank" rel="noopener noreferrer">
          <Button
            type="primary"
          >
            Download
          </Button>
        </a>
      </div>
    </div>
  )
}
