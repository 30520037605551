import React from "react";
import dateFns from "date-fns";

import BookingContact from "./BookingContact"

const BookingDetails = ({ booking }) => {

  const bookingIsForProgram = booking.user_or_program === "Program"
  const bookingIsForProject = booking.ticket?.project_name
  const isGeneralBooking = (!bookingIsForProject && !bookingIsForProgram)

  return (
    <tr>
      <td>
        <BookingContact booking={booking} />
      </td>
      <td>
          {bookingIsForProgram &&
          <a
            href={`/programs/${booking.user_or_program_detail.id}`}
            className="text-truncate" target="_blank"
            rel="noopener noreferrer"
          >
            {`${booking.user_or_program_detail.name}`}
          </a>
          }

          {bookingIsForProject &&
          <a
            href={`/projects/${booking.ticket.friendly_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${booking.ticket.project_name}`}
          </a>
          }

          {isGeneralBooking &&
          <div>{"N/A"}</div>
          }
      </td>

      <td className='text-nowrap'>
          {`${dateFns.format(booking.start, "YYYY-MM-DD")}`}
      </td>
      <td className='text-nowrap'>
          {`${dateFns.format(booking.end, "YYYY-MM-DD")}`}
      </td>

      <td className='text-nowrap'>
          {booking.organization_cut} / {booking.owner_split}
      </td>

      <td>
          {booking.invoice ?
            <a
              href={`/invoices/${booking.invoice.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {booking.invoice.reference}
            </a>
            :
            <p className="mb-0">N/A</p>
          }
      </td>

      <td>
        <div>
          {booking.invoice?.external_tracking_number ?
            <a href={`/invoices/${booking.invoice.id}`} target="_blank" rel="noopener noreferrer">
              {booking.invoice?.external_tracking_number}
            </a>
            :
            <p className="mb-0">N/A</p>
          }
        </div>
      </td>
    </tr>
  )
}

export default BookingDetails
