import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

const handleDateChangeRaw = (e) => {
  e.preventDefault();
}

const datePicker = props => (
  <div className="custom-datepicker">
    <DatePicker
      id={props.id}
      selected={props.selected}
      onChange={props.changed}
      required={props.required}
      style={props.style}
      className={`form-control ${props.className}`}
      dropdownMode="select"
      showMonthDropdown={props.showMonthDropdown}
      showYearDropdown={props.showYearDropdown}
      onChangeRaw={handleDateChangeRaw}
      dateFormat={props.dateFormat}
      disabled={props.disabled}
      showTimeInput={!!props.showTimeInput}
      showDisabledMonthNavigation={!!props.showDisabledMonthNavigation}
      {...props.maxDate && { maxDate: props.maxDate }}
      {...props.minDate && { minDate: props.minDate }}
      showTimeSelect={props.showTimeSelect}
      showTimeSelectOnly={props.showTimeSelectOnly}
      {...props.timeIntervals && { timeIntervals: props.timeIntervals }}
      {...props.timeFormat && { timeFormat: props.timeFormat }}
      readOnly={!!props.readOnly}
    />
  </div>
);

datePicker.defaultProps = {
  showMonthDropdown: true,
  showYearDropdown: true,
  showDisabledMonthNavigation: false,
  disabled: false,
  showTimeInput: false,
  readOnly: false,
  showTimeSelect: false,
  showTimeSelectOnly: false,
}

export default datePicker;
