import React, { Component } from "react";
import { toast } from "react-toastify";
import dateFns from "date-fns";

import Modal from "../UI/Modal";
import DatePicker from "../UI/datePicker";
import Button from "../UI/button/Button";
import ModalSpinner from "../UI/ModalSpinner";
import axios from "../../configAxios";

import "./Accreditations.css";

class Accreditations extends Component {
  signal = axios.CancelToken.source();
  state = {
    expiryDate: null,
    showAccreditationModal: false,
    accreditationsList: this.props.accreditationsList,
    loading: false
  }

  fileChangedHandler = (event) => {
    let fileSize = event.target.files[0] && event.target.files[0].size / 1024 / 1024;
    if (event.target.files[0])
      if (fileSize > 2) {
        toast.error('File size exceeds 2 MB');
        event.target.value = '';
      } else {
        let accreditationsList = this.state.accreditationsList.map(accr => {
          if (accr.cred_id === this.state.accreditationId) {
            accr["photo"] = event.target.files[0]
          }
          return accr
        })
        this.setState({ accreditationsList })
      }
  }

  dateChangeHandler = (expiryDate) => {
    let accreditationsList = this.state.accreditationsList.map(accr => {
      if (accr.cred_id === this.state.accreditationId) {
        accr["expiry_date"] = expiryDate ? dateFns.format(new Date(expiryDate), "YYYY-MM-DD") : null;
      }
      return accr
    })
    this.setState({ accreditationsList })
  }

  showAccreditationModalHandler = async id => {
    if (this.props.editUser) {
      let credDetails = this.state.accreditationsList.filter(accr => accr.cred_id === id)[0]
      if (credDetails && credDetails.id && this.props.selectedAccr.includes(id)) {
        const res = await axios.get(`/cred_users/${credDetails.id}`)
        this.setState({ expiryDate: res.data.json.data.expiry_date, loading: false })
      }
    }
    this.setState({ accreditationId: id, loading: false, showAccreditationModal: true })
  }

  createAccreditationList = event => {
    event.preventDefault();
    this.setState({ showAccreditationModal: false, expiryDate: null })
    this.props.createAccreditationList(this.state.accreditationsList)
  }

  checkboxHandler = (accreditationId, accreditationCredId) => {
    if (accreditationId && this.props.selectedAccr.includes(accreditationCredId)) {
      axios.delete(`/cred_users/${accreditationId}`);
      let accreditationsList = this.state.accreditationsList;
      accreditationsList = accreditationsList.map(accreditation => {
        if (accreditation.cred_id === accreditationCredId) {
          delete accreditation["file_attachment"];
          delete accreditation["expiry_date"];
          delete accreditation["user_id"];
          delete accreditation["id"];

        }
        return accreditation;
      })
      this.setState({ accreditationsList });
    }
    this.setState({ loading: true, accreditationId: accreditationCredId }, () => {
      this.dateChangeHandler(null);
    })
    !this.props.selectedAccr.includes(accreditationCredId) && this.showAccreditationModalHandler(accreditationCredId)
    this.props.selectAccrHandler(accreditationCredId)
  }

  render() {
    const { accreditations } = this.props;
    const accreditationModal = (
      <Modal
        title={this.props.editUser ? "Edit Accreditation Details" : "Add Accreditation Details"}
        show={this.state.showAccreditationModal}
        hide={() => this.setState({ showAccreditationModal: false, expiryDate: null })} >
        <div className="mt-2 accr-modal">
          {this.state.loading ? <ModalSpinner /> :
            <div>
              <div>
                <label>Expiry Date</label>
                <DatePicker
                  dateFormat="yyyy.MM.dd"
                  placeholder="Select Expiry Date"
                  className="mb-3"
                  selected={this.state.expiryDate}
                  changed={expiryDate => {
                    this.setState({ expiryDate })
                    this.dateChangeHandler(expiryDate)
                  }}
                />
              </div>
              <div>
                <label>File Upload</label>
                <input
                  type="file"
                  accept="image/*"
                  className="image-file-upload"
                  onChange={event => this.fileChangedHandler(event)}
                />
              </div>
              <div className="d-flex mt-3 justify-content-center">
                <Button
                  type="secondary"
                  className="mr-3"
                  clicked={() => this.setState({ showAccreditationModal: false, expiryDate: null })}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  clicked={event => this.createAccreditationList(event)}
                >
                  Save
                </Button>
              </div>
            </div>
          }
        </div>
      </Modal >
    );
    return (
      <div className="mb-2 accr">
        <button
          className="btn header-btn"
          onClick={() => this.props.tabHandler("Accreditations")}
          type="button"
          data-toggle="collapse"
          data-target=".Accreditations"
          aria-expanded="false"
          aria-controls="multiCollapseExample1 multiCollapseExample2"
        >
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Accreditations</p>
            <i
              className={
                this.props.selectedTabs.includes("Accreditations")
                  ? "fas fa-minus-circle fa-2x"
                  : "fas fa-plus-circle fa-2x"
              }
              style={{ backgroundColor: "#f5efef", color: "var(--color-primary)" }}
            />
          </div>
        </button>
        <div className="row">
          <div className="col-12">
            <div
              className="collapse Accreditations mx-2"
              id="multiCollapseExample1"
            >
              <div className="card">
                <div className="card-body">
                  <table className="w-100 table-responsive">
                    <thead>
                      <tr>
                        <th style={{ width: "40%" }} className="px-4">Accreditation Name</th>
                        <th style={{ width: "30%" }} className="px-1 text-center">Accreditation Level</th>
                        <th style={{ width: "20%" }} className="px-1 text-center">Expiry Date</th>
                        <th colSpan="2"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.keys(accreditations).map(key => {
                          return accreditations[key].map(accreditation => {
                            let accreditationData = this.props.accreditationsList
                              .filter(item => item.cred_id === accreditation.id)[0]
                            return (
                              <tr key={accreditation.id} className="border-top">
                                <td className="py-3 px-1">
                                  <div className="custom-checkbox row no-gutters" onClick={() => this.checkboxHandler(accreditationData.id, accreditation.id)}>
                                    <div
                                      className="box"
                                      style={{ backgroundColor: this.props.selectedAccr.includes(accreditation.id) ? "var(--color-primary)" : "white" }}
                                    >
                                      <i
                                        className="fas fa-check"
                                        style={{ color: "white", marginLeft: 2 }}
                                      />
                                    </div>
                                    <span>{accreditation.label}</span>
                                  </div>
                                </td>
                                <td className="text-capitalize py-3 px-1 text-center">{key}</td>
                                <td className="font-weight-bold py-3 px-1 text-center">
                                  {
                                    accreditationData.hasOwnProperty("expiry_date") && accreditationData.expiry_date
                                      ? dateFns.format(accreditationData.expiry_date, "YYYY.MM.DD") : "N/A"
                                  }
                                </td>
                                <td className="py-3 px-1" style={{ width: "9%" }}>
                                  <p className="cursor mb-0 text-center">
                                    {
                                      this.props.selectedAccr.includes(accreditation.id) &&
                                      <i className="fas fa-pen pl-3"
                                        onClick={() => {
                                          this.setState({ loading: true, showAccreditationModal: true })
                                          window.scrollTo(0, 0);
                                          this.showAccreditationModalHandler(accreditation.id)
                                          this.setState({ expiryDate: null })
                                        }}
                                      />
                                    }
                                  </p>
                                </td>
                                <td className="py-3 px-1" style={{ width: "6%" }}>
                                  <p className="cursor mb-0 pr-3 float-right">
                                    {
                                      accreditationData && accreditationData.file_attachment &&
                                      <a
                                        download
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={accreditationData.file_attachment.file.url}
                                      >
                                        <i className="fas fa-download text-muted" />
                                      </a>
                                    }
                                  </p>
                                </td>
                              </tr>
                            )
                          })
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {accreditationModal}
      </div>
    );
  }
}
export default Accreditations;
