import React, { Component } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

class RentalChart extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={this.props.data}
          margin={{
            top: 10,
            right: 30,
            left: 12,
            bottom: 0
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend
            align="left"
            wrapperStyle={{ marginLeft: "50px" }}
            payload={[
              {
                value: "Revenue",
                type: "square",
                color: "#FF6C60"
              },
              {
                value: "Rental Bookings",
                type: "square",
                color: "#7DB6ED"
              },
              {
                value: "Facility Bookings",
                type: "square",
                color: "#90D434"
              }
            ]}
          />
          <Area
            dataKey="revenue"
            stackId="1"
            stroke="#FF6C60"
            fill="#FFC4BF"
            dot={true}
          />
          <Area
            dataKey="equipment_item_bookings"
            stackId="1"
            stroke="#7DB6ED"
            fill="#DDF1FF"
            dot={true}
          />
          <Area
            dataKey="facility_bookings"
            stackId="1"
            stroke="#90D434"
            fill="#D2EDAF"
            dot={true}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default RentalChart;
