import React, { Component } from "react";
import { connect } from "react-redux";
import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import AuthContainer from "./AuthContainer";
import Logo from "./formComponents/Logo"
import { resetPassword } from "../../store/actions/index";

class ResetPassword extends Component {
  state = {
    controls: {
      username: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "username",
          required: true
        },
        label: "Email Address",
        value: ""
      }
    },
  };

  inputChangedHandler = event => {
    const updatecontrols = {
      ...this.state.controls,
      [event.target.name]: {
        ...this.state.controls[event.target.name],
        value: event.target.value
      }
    };
    this.setState({ controls: updatecontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    const userData = {
      username: this.state.controls.username.value
    };
    this.props.resetPassword(userData, this.props.history);
  };

  render() {
    const { loading } = this.props.response;
    let formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key]
      });
    }
    const form = formElementsArray.map(formElement => (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        label={formElement.config.label}
        value={formElement.config.value}
        changed={event => this.inputChangedHandler(event)}
      />
    ));
    return (
      <AuthContainer>
        <div className="card-body">
          <div className="card-text px-3 pb-3">
            <Logo />
            <form onSubmit={this.submitHandler} className="mt-4">
              {form}
              <FormButton block={true} disabled={loading}>
                Send Validation Token
              </FormButton>
            </form>
          </div>
        </div>
      </AuthContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    response: state.response
  };
};

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
