import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from "../../configAxios";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import Pagination from "../UI/Pagination";
import Spinner from "../UI/Spinner";
import langUtils from "../../utils/langUtils";

import OrganizationItem from "./OrganizationItem";

class OrganizationsList extends Component {
  constructor() {
    super();
    this.state = {
      type: {
        select: {
          elementType: "select",
          elementConfig: {
            name: "type",
            options: [
              { label: "5", value: 5 },
              { label: "10", value: 10 },
              { label: "20", value: 20 },
              { label: "30", value: 30 },
              { label: "50", value: 50 }
            ],
            defaultValue: { label: "50", value: 50 },
            isClearable: false
          }
        },
        text: {
          elementType: "input",
          elementConfig: {
            name: "declined",
            type: "text"
          },
          value: ""
        }
      }
    };
    this.textInput = React.createRef();
  }

  textHandler = () => {
    this.props.clearTextHandler();
    this.textInput.current.focus();
  };

  sortHandler = (event, key, sortOrder) => {
    this.setState({
      sortOrder, sortField: key,
    },
      () => this.props.inputChangeHandler(event, "sort", key, this.state.sortOrder))
  }
  getSortingOrder = () => {
    return this.state.sortOrder === "desc" ? "asc" : "desc"
  }

  setArrowVisibility = (key, order) => {
    if (this.state.sortField === key) {
      if (this.state.sortOrder === order) {
        return "hidden"
      }
    }
    return "initial"
  }

  organizationDeleteHandler = (id, name) => {
    let response = window.confirm(`Are you sure you want to delete ${name}?`);
    if (response) {
      let confirmationRes = window.confirm(`Are you sure you want to delete ${name}?`);
      if (confirmationRes) {
        axios.delete(`/organizations/${id}`)
          .then(res => {
            if (res.data.json.success) {
              toast.success(res.data.json.message)
              this.props.updateData();
            } else {
              if (res.data.json.hasOwnProperty("errors")) {
                Object.keys(res.data.json.errors).forEach(key => {
                  toast.error(`${title(key)} ${res.data.json.errors[key]}`);
                })
              }
              if (res.data.json.hasOwnProperty("error")) {
                toast.error(res.data.json.error);
              }
            }
          })
      }
    }
  }

  render() {
    let { totalResults, currentPage } = this.props.pagination;
    let perPage = this.props.filters.perPage;

    let from = perPage * (currentPage + 1);
    from -= perPage;
    from++;
    let to = perPage * (currentPage + 1);
    if (to > totalResults) {
      to = totalResults;
    }

    let showing = totalResults === 0 ? '' : `Showing ${from} to ${to} of ${totalResults} entries`;

    let removeIcon;
    if (this.props.filters.query !== "") {
      removeIcon = (
        <i
          className="fas fa-times position-absolute float-right remove-icon cursor"
          onClick={this.textHandler}
        />
      );
    }

    return (
      <div className="container organizations-list w-100">
        <div className="row mb-4">
          <div className="col-6 d-flex justify-content-start align-items-center">
            <h3>Organizations</h3>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center">
            <Link to="/organizations/new/">
              <Button type="success">{langUtils("txt_global_add_new", "Add New")}</Button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body p-4">
                <div className="card-text">
                  <div className="row overflow-hidden mx-0">
                    <div className="col-lg-4 pl-0">
                      <div className="font-weight-bold float-left mt-2 mr-3">
                        Show entries:
                      </div>
                      <Input
                        {...this.state.type.select}
                        selectedOption={{
                          label: this.props.filters.perPage,
                          value: this.props.filters.perPage
                        }}
                        changed={event =>
                          this.props.inputChangeHandler(event, "entries")
                        }
                        className="w-75"
                      />
                    </div>
                    <div className="col-lg-4" />
                    <div className="col-lg-1 font-weight-bold pt-2 px-0 text-lg-right text-left">
                      Search:
                    </div>
                    <div className="col-lg-3 pr-0">
                      <Input
                        {...this.state.type.text}
                        value={this.props.filters.query}
                        changed={event =>
                          this.props.inputChangeHandler(event, "search")
                        }
                        style={{ maxWidth: "100%", paddingRight: "45px" }}
                        forwardRef={this.textInput}
                      />
                      <i
                        className="fas fa-search position-absolute search-icon"
                      />
                      {removeIcon}
                    </div>
                  </div>
                </div>
                {this.props.innerLoading ? (
                  <div className="card-text">
                    <div className="row">
                      <div className="col-12">
                        <Spinner className="h-60vh" />
                      </div>
                    </div>
                  </div>
                ) : (
                    <div className="card-text">
                      <div className="row">
                        <div className="col-12">
                          {
                            this.props.organizations.length === 0 ? (
                              <h2 className="text-center my-5 py-5">
                                <i className="fas fa-lg fa-times my-3" /><br />
                                No Results
                            </h2>
                            ) : (
                                <div className="table-responsive">
                                  <table className="table table-bordered table-hover">
                                    <thead className="text-muted border-top">
                                      <tr>
                                        <th scope="col"
                                            className="justify-content-between cursor"
                                            onClick={(event) => {
                                              const order = this.getSortingOrder()
                                              this.sortHandler(event, "id", order)
                                            }}
                                        >
                                          ID
                                          <i
                                            className="fas fa-caret-up float-right"
                                            style={{
                                              top: 8,
                                              visibility: this.setArrowVisibility("id", "asc")
                                            }}
                                            onClick={event => this.sortHandler(event, "id", "asc")}
                                          />
                                          <i
                                            className="fas fa-caret-down cursor float-right position-relative"
                                            style={{
                                              top: 10,
                                              right: -9.8,
                                              visibility: this.setArrowVisibility("id", "desc")
                                            }}
                                            onClick={event => this.sortHandler(event, "id", "desc")}
                                          />
                                        </th>
                                        <th scope="col"
                                          className="justify-content-between cursor"
                                          onClick={(event) => {
                                            const order = this.getSortingOrder()
                                            this.sortHandler(event, "full_name", order)
                                          }}
                                        >
                                          Name
                                          <i
                                            className="fas fa-caret-up float-right"
                                            style={{
                                              top: 8,
                                              color: this.state.sortField === "full_name"
                                                ? this.state.sortOrder === "asc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "full_name", "asc")}
                                          />
                                          <i
                                            className="fas fa-caret-down cursor float-right position-relative"
                                            style={{
                                              top: 10,
                                              right: -9.8,
                                              color: this.state.sortField === "full_name"
                                                ? this.state.sortOrder === "desc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "full_name", "desc")}
                                          />
                                        </th>
                                        <th
                                          scope="col"
                                          className="w-20 justify-content-between cursor"
                                          onClick={(event) => {
                                            const order = this.getSortingOrder()
                                            this.sortHandler(event, "subdomain", order)
                                          }}
                                        >
                                          Sub-domain
                                          <i
                                            className="fas fa-caret-up float-right"
                                            style={{
                                              top: 8,
                                              color: this.state.sortField === "subdomain"
                                                ? this.state.sortOrder === "asc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "subdomain", "asc")}
                                          />
                                          <i
                                            className="fas fa-caret-down cursor position-relative float-right"
                                            style={{
                                              top: 10,
                                              right: -9.8,
                                              color: this.state.sortField === "subdomain"
                                                ? this.state.sortOrder === "desc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "subdomain", "desc")}
                                          />
                                        </th>
                                        <th
                                          scope="col"
                                          className="w-15 justify-content-between cursor"
                                          onClick={(event) => {
                                            const order = this.getSortingOrder()
                                            this.sortHandler(event, "installation_type", order)
                                          }}
                                        >
                                          Installation Type
                                          <i
                                            className="fas fa-caret-up float-right"
                                            style={{
                                              top: 8,
                                              color: this.state.sortField === "installation_type"
                                                ? this.state.sortOrder === "asc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "installation_type", "asc")}
                                          />
                                          <i
                                            className="fas fa-caret-down cursor position-relative float-right"
                                            style={{
                                              top: 10,
                                              right: -9.8,
                                              color: this.state.sortField === "installation_type"
                                                ? this.state.sortOrder === "desc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "installation_type", "desc")}
                                          />
                                        </th>
                                        <th
                                          scope="col"
                                          className="w-20 justify-content-between cursor"
                                          onClick={(event) => {
                                            const order = this.getSortingOrder()
                                            this.sortHandler(event, "ui_version", order)
                                          }}
                                        >
                                          UI Version
                                          <i
                                            className="fas fa-caret-up float-right"
                                            style={{
                                              top: 8,
                                              color: this.state.sortField === "ui_version"
                                                ? this.state.sortOrder === "asc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "ui_version", "asc")}
                                          />
                                          <i
                                            className="fas fa-caret-down cursor position-relative float-right"
                                            style={{
                                              top: 10,
                                              right: -9.8,
                                              color: this.state.sortField === "ui_version"
                                                ? this.state.sortOrder === "desc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "ui_version", "desc")}
                                          />
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-center w-15 justify-content-between cursor"
                                          onClick={(event) => {
                                            const order = this.getSortingOrder()
                                            this.sortHandler(event, "last_login", order)
                                          }}
                                        >
                                          Last Login
                                          <i
                                            className="fas fa-caret-up float-right"
                                            style={{
                                              top: 8,
                                              color: this.state.sortField === "last_login"
                                                ? this.state.sortOrder === "asc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "last_login", "asc")}
                                          />
                                          <i
                                            className="fas fa-caret-down cursor position-relative float-right"
                                            style={{
                                              top: 10,
                                              right: -9.8,
                                              color: this.state.sortField === "last_login"
                                                ? this.state.sortOrder === "desc"
                                                  ? "white"
                                                  : "#808080"
                                                : "#808080"
                                            }}
                                            onClick={event => this.sortHandler(event, "last_login", "desc")}
                                          />
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-center w-15"
                                        >
                                          Language
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-center action-column"
                                        >
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.props.organizations.map(organization => (
                                        <OrganizationItem
                                          key={organization.id}
                                          organization={organization}
                                          changeScreen={this.props.changeScreen}
                                          organizationDeleteHandler={this.organizationDeleteHandler}
                                        />
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              )
                          }
                        </div>
                        <div className="col-12">
                          <div className="row px-0">
                            <div className="col-lg-6 mt-5 pb-1">
                              <strong>
                                {showing}
                              </strong>
                            </div>
                            <div className="col-lg-6 px-0">
                              <Pagination
                                pageCount={
                                  this.props.pagination.totalResults /
                                  this.props.filters.perPage
                                }
                                currentPage={this.props.pagination.currentPage}
                                handlePageClick={this.props.paginationHandler}
                                style={{ marginRight: "-5px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationsList;
