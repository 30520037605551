import React from "react"

const GenericInput = ({ props }) => {
  return (
    <input
      className={
        props.elementConfig &&
        props.elementConfig.type &&
        props.elementConfig.type === "checkbox"
          ? ""
          : `form-control ${props.className}`
      }
      {...props.elementConfig}
      value={props.value}
      placeholder={
        props.elementConfig &&
        props.elementConfig.hasOwnProperty("placeholder")
          ? props.elementConfig.placeholder
          : ""
      }
      type={
        props.elementConfig &&
        props.elementConfig.type &&
        props.elementConfig.type
      }
      {...(props.elementConfig &&
        props.elementConfig.id && { id: props.elementConfig.id })}
      onChange={props.changed}
      ref={props.forwardRef}
      disabled={props.disabled}
      style={
        props.mediaFileStyle
          ? {
            borderRadius: 0,
            boxShadow: "none",
            paddingBottom: 30,
            paddingTop: 4,
            width: 220,
            position: "relative",
            left: -15,
            ...props.style,
          }
          : {
            borderRadius: 0,
            boxShadow: "none",
            paddingLeft: props.padding ? "3%" : "2%",
            ...props.style,
          }
      }
    />
  )
}

export default GenericInput
