import React, { Component } from "react";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Pagination from "../UI/Pagination";

import FilterForm from "./FilterForm";
import ProjectsList from "./ProjectsList";

import "./submissions.scss";
import errorStatusHandler from "../errorPages/UserNotAuthorized";
import onError from "../../utils/onError"

class Submissions extends Component {
  signal = axios.CancelToken.source();
  state = {
    filters: {
      showVideoProjects: true,
      orderBy: { label: "Date", value: "projects.created_at" }
    },
    pagination: {
      currentPage: 0,
      totalPages: 0
    },
    projects: [],
    orderBy: [],
    projectCues: [],
    loading: false,
    disabled: false,
    projectCueName: null,
    orderByName: { label: "Date", value: "projects.created_at" }
  };

  async componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({ ...this.state, loading: true });

    const formData = await axios.get("projects/formdata/", {
      cancelToken: this.signal.token
    });

    this.setState(
      {
        projectCues: formData.data.json.project_cues,
        orderBy: [
          { label: "Name", value: "projects.name" },
          { label: "Creator", value: "users.first_name" },
          { label: "Date", value: "projects.created_at" }
        ],
        filters: {
          ...this.state.filters,
          type: formData.data.json.project_cues[0][0]
        }
      },
      () => this.updateData()
    );
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  updateData = () => {
    let params = {
      consider_type: this.state.filters.type,
      order_by: this.state.filters.orderBy.value,
      order_direction: this.state.filters.orderBy.value === "projects.created_at" ? "DESC" : "ASC",
      query: this.state.filters.search,
      show_declined: this.state.filters.showDeclined,
      show_approved: this.state.filters.showApproved,
      has_video: this.state.filters.showVideoProjects,
      page: this.state.pagination.currentPage + 1
    };
    this.setState({ loading: true });
    axios
      .get("projects/submissions", { params, cancelToken: this.signal.token })
      .then(res => {
        if(res.status === 202) {
          errorStatusHandler(res);
          this.setState({ loading: false })
        } else {
          this.setState({
            ...this.state,
            pagination: {
              ...this.state.pagination,
              totalPages: res.data.meta.total_count
            },
            projects: res.data.projects,
            disabled: false,
            loading: false
          });
        }
      })
      .catch(err => {
        const { history } = this.props
        const { error: resError } = err.response.data.json

        onError(resError)

        if (err.response.status === 403) history.push("/dashboard")
      });
  };

  statusChangeHandler = (status, slug) => {
    this.setState({
      ...this.state,
      projects: this.state.projects.map(project => {
        if (project.slug === slug) {
          project.status.is_approved = status.approved;
          project.status.is_declined = status.declined;
        }
        return project;
      })
    });
  };

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState(
      {
        ...this.state,
        pagination: {
          ...this.state.pagination,
          currentPage: page.selected
        }
      },
      () => this.updateData()
    );
  };

  searchHandler = (params, projectCueName, orderByName) => {
    this.setState(
      {
        pagination: {
          currentPage: 0,
          totalPages: 0
        },
        filters: {
          ...this.state.filters,
          ...params
        },
        disabled: true,
        projectCueName: projectCueName,
        orderByName: orderByName
      },
      () => this.updateData()
    );
  };

  render() {
    return (
      <div className="container submissions w-100">
        <div className="row mb-4">
          <div className="col-12">
            <h3>Submission</h3>
          </div>
        </div>
        <div className="list-container">
          <div className="row">
            <FilterForm
              inputChangeHandler={this.inputChangeHandler}
              projectCues={this.state.projectCues}
              orderBy={this.state.orderBy}
              projectCue={this.state.filters.type}
              orderByName={this.state.orderByName}
              loading={this.state.loading}
              searchHandler={this.searchHandler}
              disabled={this.state.disabled}
            />
            <div className="col-lg-8 py-2">
              <div className="card">
                <div className="card-body">
                  <div className="card-text">
                    {
                      this.state.loading ? (
                        <Spinner />
                      ) : (
                          <ProjectsList
                            projects={this.state.projects}
                            projectCueName={this.state.projectCueName}
                            projectCue={this.state.filters.type}
                            statusChangeHandler={this.statusChangeHandler}
                          />
                        )
                    }
                    <Pagination
                      pageCount={this.state.pagination.totalPages / 10}
                      currentPage={this.state.pagination.currentPage}
                      handlePageClick={this.paginationHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Submissions;
