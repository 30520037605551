import React, { Component } from "react";
import { connect } from "react-redux";
import { title } from "change-case";
import _ from "lodash"
import { getProject } from "../../store/actions/index";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import axios from "../../configAxios";
import { toast } from "react-toastify";

class EditAttribute extends Component {
  signal = axios.CancelToken.source();
  state = {
    attributeName: {
      elementType: "input",
      elementConfig: {
        type: "text"
      },
      label: "Attribute",
      value: ""
    },
    attributeValue: {
      elementType: "asyncCreatableSelect",
      elementConfig: {
        name: "attributeValue",
        placeholder: "Search",
        inputLength: 1,
        components: {
          IndicatorSeparator: () => null
        },
        createOptionPosition: "first",
        formatCreateLabel: (userInput) => `Add "${userInput}"`,
        allowCreateWhileLoading: true,
        loadOptions: (inputValue, callback) => {
          if (inputValue.length > 0)
            this.searchUserHandler(inputValue, callback);
        },
        required: true
      },
      chart: "",
      selectedOption: null,
      label: "Value",
      value: null
    },
    duration: { hours: "", minutes: "", seconds: "" },
    disabled: true
  };

  componentDidMount() {
    this.setState({
      attributeName: {
        ...this.state.attributeName,
        value: this.props.attributesData.project_attribute_type_name
      },
    }, () => this.getattributeValue());

  }

  getattributeValue = () => {
    let updatedControls;
    const duration = { hours: "", minutes: "", seconds: "" }
    if (!this.props.longAttribute) {
      if (this.props.attributesData.project_attribute_type.ui_type === "duration_picker") {
        const durationArr = this.props.attributesData.value.split(':')
        duration.hours = durationArr[0];
        duration.minutes = durationArr[1];
        duration.seconds = durationArr[2];
      }
      updatedControls = {
        ...this.state.attributeValue,
        value: {
          label: this.props.attributesData.value,
          value: `${this.props.attributesData.value_2}`
        },
        selectedOption: {
          label: this.props.attributesData.value,
          value: `${this.props.attributesData.value_2}`
        }
      };
    } else {
      const { required, name } = this.state.attributeValue.elementConfig;
      const { label } = this.state.attributeValue
      updatedControls = {
        ...this.state.attributeValue,
        elementType: "textarea",
        label,
        value: this.props.attributesData.value,
        elementConfig: {
          name,
          required,
          type: "textarea",
          rows: 4
        }
      };
    }

    this.setState({ attributeValue: updatedControls, duration, disabled: false });
  };

  inputChangedHandler(event, key) {
    let inputValue = {};
    inputValue["value"] = this.props.longAttribute ? event.target.value : (_.isEmpty(event) ? null : event);
    if (!this.props.longAttribute)
      inputValue["selectedOption"] = _.isEmpty(event) ? null : event;

    const updatedControls = {
      ...this.state[key],
      ...inputValue
    };

    this.setState({ [key]: updatedControls });
  }

  durationInputChangedHandler = (event, key) => {
    this.setState({
      duration: {
        ...this.state.duration,
        [event.target.name]: event.target.value
      }
    }, () => {
      const inputValue = { value: {} }
      const { hours, minutes, seconds } = this.state.duration;
      inputValue.value["label"] = `${hours}:${minutes}:${seconds}`
      inputValue.value["value"] = null
      const updatedControls = {
        ...this.state[key],
        ...inputValue
      };

      this.setState({ [key]: updatedControls })
    })
  }

  updateAttributeHandler = event => {
    this.setState({ disabled: true })
    event.preventDefault();
    if (!this.props.longAttribute && _.isEmpty(this.state.attributeValue.value)) {
      toast.error("Value field is empty");
      this.setState({ disabled: false })
      return;
    }
    const { attributesData } = this.props;
    const attrData = {
      id: attributesData.id,
      project_id: attributesData.project_id,
      project_attribute_type_id: attributesData.project_attribute_type_id,
      value: this.props.longAttribute ? this.state.attributeValue.value : this.state.attributeValue.value.label
    };
    const id = this.props.projects.project.slug ? this.props.projects.project.slug : this.props.projects.project.id;
    if (this.state.updatedValue !== "") {
      axios
        .put(`/project_attributes/${attributesData.id}`, attrData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Updated Successfully");
            this.props.getProject(id);
            this.props.getAttributes();
            this.props.closeModal();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
            this.setState({ disabled: false })
          }
        })
        .catch(err => console.log(err));
    }
  };

  searchUserHandler = (inputValue, callback) => {
    const queryParams = {
      params: { query: inputValue },
      cancelToken: this.signal.token
    };
    axios.get("/users/autocomplete", queryParams).then(res => {
      callback(
        res.data.users.map(
          user => ({ label: user.full_name, value: `${user.id}` })
        )
      )
    })
  }

  renderDurationPicker = () => {
    const  { hours, minutes, seconds } = this.state.duration

    return (
      <>
        <label>Value</label>
        <div className="text-center mb-3 short-attr">
          <input
            className="duration-picker"
            type="number"
            min="0"
            required
            placeholder="hh"
            name="hours"
            value={hours}
            onChange={(event) => this.durationInputChangedHandler(event, "attributeValue")}
          />{" "}
          {" : "}
          <input
            className="duration-picker"
            type="number"
            max="59"
            min="0"
            required
            placeholder="mm"
            name="minutes"
            value={minutes}
            onChange={(event) => this.durationInputChangedHandler(event, "attributeValue")}
          />{" "}
          {" : "}
          <input
            className="duration-picker"
            type="number"
            max="59"
            min="0"
            required
            placeholder="ss"
            name="seconds"
            value={seconds}
            onChange={(event) => this.durationInputChangedHandler(event, "attributeValue")}
          />
        </div>
      </>
    )
  }

  renderInputField = () => {
    const { ui_type: uiType } = this.props.attributesData.project_attribute_type

    if (uiType === "duration_picker") return this.renderDurationPicker()

    return (
      <Input
        {...this.state.attributeValue}
        changed={event => this.inputChangedHandler(event, "attributeValue")}
      />
    )
  }

  render() {
    return (
      <form
        className="mt-3"
        onSubmit={this.updateAttributeHandler}
        key={this.state.attributeValue.elementConfig.type}
      >
        <Input {...this.state.attributeName} disabled={true} />
        {this.renderInputField()}
        <div className="text-center">
          <Button type="secondary" clicked={this.props.closeModal}>
            Cancel
          </Button>
          <FormButton className="ml-2" disabled={this.state.disabled}>Update</FormButton>
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProject: id => dispatch(getProject(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAttribute);
