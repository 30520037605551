import React, { Component } from "react";
import Spinner from "../../UI/Spinner";
import Button from "../../UI/button/Button";
import axios from "../../../configAxios";

class Votes extends Component {
  signal = axios.CancelToken.source();
  state = {
    votesList: [],
    loading: true
  };

  componentDidMount() {
    axios
      .get("/contests/votes", {
        params: {
          id: this.props.id
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({ votesList: res.data.contests, loading: false });
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  showContests = () => {
    this.props.showContests()
    this.props.tabChangeHandler("Contests")
  }

  render() {
    const votes = this.state.votesList;
    let votesList;
    if (this.state.loading) {
      votesList = <Spinner />;
    } else {
      votesList = votes.map((item, key) => (
        <li className="list-group-item rounded-0 border-top projectAttr" key={key}>
          <div className="row">
            <div className="col-lg-6">{item.name}</div>
            <div className="col-lg-6">{item.votes_count}</div>
          </div>
        </li>
      ));
    }
    return (
      <div className="project-contests-votes">
        <div className="contest-name mt-3 px-4">
          <h4>Votes of {this.props.name}</h4>
        </div>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="row">
              <div className="col-lg-6">
                <span className="font-weight-bold">Name</span>
              </div>
              <div className="col-lg-6">
                <span className="font-weight-bold">Votes count</span>
              </div>
            </div>
          </li>
          {votesList}
        </ul>
        <div className="row m-0 my-4 ml-4">
          <Button type="secondary" clicked={() => this.showContests()}>
            Back
          </Button>
        </div>
      </div>
    );
  }
}

export default Votes;
