import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import HTMLParser from "html-react-parser";

import Spinner from "../../../UI/ModalSpinner";

import axios from "../../../../configAxios";

class ViewEmail extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      loading: true,
      campaignInfo: null,
    }
  }

  componentDidMount() {
    axios.get(`/campaigns/${this.props.campaignId}`)
      .then(res => {
          if(res.data.json && res.data.json.success) {
            this.setState({ 
              campaignInfo: res.data.json.data,
              loading: false,
             })
          } else {
            toast.error("Something went wrong");
            this.props.closeEmail();
          }
      })  
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being cancelled");
  }

  render() {
    const { loading, campaignInfo } = this.state;
    
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            {
              loading
                ? <Spinner />
                : (
                  <div className="mt-4">
                    {HTMLParser(campaignInfo.body)}
                  </div>
                ) 
            }
          </div>
        </div>
      </div>
    )
  }
}

ViewEmail.propTypes = {
  campaignId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  closeEmail: PropTypes.func.isRequired,
};

export default ViewEmail;
