import React from "react"
import BookingDetails from "./BookingDetails";

const RentalDetails = ({ rental }) => {
  const columnHeight = rental.bookings.length + 1;
  return (
    <>
    <tr key={rental.id}>
      <th scope={"rowgroup"} rowSpan={columnHeight}>
        <a href={`/assets/${rental.id}`} className="text-truncate" target="_blank"
           rel="noopener noreferrer">
          {rental.name}
        </a>
      </th>
      <th scope={"rowgroup"} rowSpan={columnHeight}>
        {rental.owner && rental.owner.user_id &&
        <a href={`/users/${rental.owner.user_id}/summary`} className="text-truncate" target="_blank"
           rel="noopener noreferrer">
          {rental.owner_name}
        </a>
        }
        {rental.owner && !rental.owner.user_id &&
          rental.owner_name
        }
        { !rental.owner &&
          "N/A"
        }
      </th>
    </tr>
    {rental.bookings.map(booking => <BookingDetails key={booking.id} booking={booking} />)}
    </>
  )
}

export default RentalDetails
