import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "../../../configAxios";
import { title } from "change-case";
import { toast } from "react-toastify";

import Spinner from "../../UI/Spinner";

import ReservedAssets from "./ReservedAssets";
import BookAssets from "./BookAssets";

class EditReservation extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    tickets: null
  };

  componentDidMount() {
    this.getTickets(true);
  }

  getTickets = loading => {
    this.setState({ loading });
    axios
      .get("/tickets", {
        params: {
          program_id: this.props.match.params.id
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        if (res.data) {
          const { tickets } = res.data;
          this.setState({
            ticket: tickets,
            loading: false
          });
        } else {
          this.createTicket();
        }
      });
  };

  createTicket = () => {
    let formData = {};
    formData["type"] = "Program";
    formData["program_id"] = this.props.match.params.id;
    axios.post("/tickets", formData).then(res => {
      if (res.data.json.success) {
        this.setState({
          ticket: res.data.json.data,
          loading: false
        });
      } else {
        if ('errors' in res.data.json) {
          Object.keys(res.data.json.errors).forEach(key => {
            toast.error(title(key) + " " + res.data.json.errors[key])
          })
        }
        if ('error' in res.data.json) {
          toast.error(res.data.json.error)
        }
      }
    });
  };

  render() {
    return (
      <div className="container edit-reservation w-100">
        {
          this.state.loading ? (
            <Spinner />
          ) : (
              <React.Fragment>
                <div className="row mb-4">
                  <div className="col-9 mt-3">
                    <h4>{this.state.ticket.for_program_name}</h4>
                  </div>
                  <div className="col-3 text-right mt-3">
                    <span
                      className="cursor"
                      onClick={() => this.props.history.goBack()}
                    >
                      {"<< Back"}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-text">
                          <div className="row">
                            <div className="col-lg-6">
                              <ReservedAssets
                                getReservedAssets={this.getTickets}
                                programId={this.props.match.params.id}
                                getTickets={() => this.getTickets(false)}
                                ticket={this.state.ticket}
                              />
                            </div>
                            <div className="col-lg-6">
                              <BookAssets
                                programId={this.props.match.params.id}
                                ticketId={this.state.ticket.id}
                                getTicket={() => this.getTickets(false)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
        }
      </div>
    );
  }
}
export default withRouter(EditReservation);
