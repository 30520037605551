import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { title } from "change-case";

import ContactInformation from "./ContactInformation";
import FormFields from "./userFormFields";

import Spinner from "../UI/Spinner";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import axios from "../../configAxios";
import translateLocalTimezone from "../../utils/translateLocalTimezone";

class EditNonAdmin extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      loading: true,
      userInfo: null,
      roleLevel: "",
      disabled: false,
      allowUserEditContactInformation: false
    }
  }
  
  componentDidMount() {
    this.getUserData();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being canceled");
  }

  getUserData = () => {
    this.setState({ loading: true });
    axios.get(`/users/${this.props.match.params.id}`, { 
      cancelToken: this.signal.token 
    }).then(res => {
      if (!res.data.user) {
        toast.error("Something went wrong");
        this.props.history.push(`/users/${this.props.match.params.id}/summary`);
        return;
      } else {
        const { user } = res.data;
        this.loadUserInfo(user, {}).then(userInfo => {
          this.setState({ 
            userInfo: {
              ...userInfo,
              dateOfBirth: translateLocalTimezone(userInfo.dateOfBirth),
              twitter: user.data.twitter,
              instagram: user.data.instagram,
              facebook: user.data.facebook,
              linkedin: user.data.linkedin,
              youtube: user.data.youtube,
            }, 
            loading: false, 
            roleLevel: user.role.level,
            allowUserEditContactInformation: user.allow_users_to_update_contact_information
          });
        });
      }
    });
  }

  loadUserInfo = (userData, userInfo) => {
    return new Promise((resolve, reject) => {
      Object.keys(FormFields.textInput).forEach((key) => {
        if (userData[FormFields.textInput[key]]) {
          userInfo[key] = userData[FormFields.textInput[key]];
        }
      })
      return resolve(userInfo);
    })
  }

  inputChangeHandler = (event, type, key) => {
    if(type === "select") {
      this.setState({ [key]: event.value || "" });
    } else if(type === "date") {
      this.setState({ ...this.state, [key]: event });
    } else {
      this.setState({ [key]: event.target.value });
    }
  }

  userInfoUpdater = e => {
    e.preventDefault();
    this.setState({ disabled: true })
    const formKeyMap = {
      address1: "address1",
      address2: "address2",
      city: "city",
      province: "province",
      country: "country",
      postal_code: "postCode",
      home_phone: "phone",
      mobile_phone: "mobile",
      alternate_email: "alternateEmail",
      website: "website",
      job_position: "jobDescription",
      bio_link: "bio",
      twitter: "twitter",
      instagram: "instagram",
      facebook: "facebook",
      youtube: "youtube",
      linkedin: "linkedin",
      specializations: "specializations",
      pronouns: "pronouns",
      date_of_birth: "dateOfBirth"
    };

    const formData = new FormData();

    Object.keys(formKeyMap).forEach(key => {
      const stateVar = formKeyMap[key];
      if (this.state[stateVar] !== undefined) {
        formData.append(key, this.state[stateVar]);
      }
    });

    axios.put(`/users/${this.props.match.params.id}`, formData, {
      cancelToken: this.signal.token
    }).then(res => {
      if (res.data.json.success) {
        toast.success(`${
          this.state.roleLevel === "non_member"
            ? "Non Member"
            : "Member"
          } updated successfully`);
        this.props.history.push(`/users/${this.props.match.params.id}/summary`);
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(`${title(error)} ${res.data.json.errors[error]}`);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
        this.setState({ disabled: false });
      }
    }).catch(err => this.setState({ disabled: false }));
  } 

  render() {
    const { loading, userInfo } = this.state;
    return (
      <div className="container add-friend w-100">
        <div className="row">
          <div className="col-12">
            <div className="card">
              {
                loading
                  ? <Spinner />
                  : (
                    <div className="card-body p-0">
                      <div className="card-text p-4 pb-5">
                        <form autoComplete="off" onSubmit={this.userInfoUpdater}>
                          <ContactInformation 
                            allowUserEditContactInformation={this.state.allowUserEditContactInformation}
                            formData={userInfo}
                            editUser={true}
                            inputChangeHandler={this.inputChangeHandler}
                            />
                            <div className="row mt-3">
                              <div className="col-12 text-right">
                                <Button
                                  type="secondary"
                                  clicked={() => this.props.history.goBack()}
                                  className="float-none mr-1"
                                >
                                  Cancel
                                </Button>
                                <FormButton className="ml-1" disabled={this.state.disabled}>
                                  Update
                                </FormButton>
                              </div>
                            </div>
                        </form>
                      </div>
                    </div>
                  )
                }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EditNonAdmin);
