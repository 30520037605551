import Button from "../UI/button/Button"
import React from "react"

const AddElementButton = ({ createQuestion }) => {
  return (
    <div className="text-right mb-3">
      <Button clicked={() => createQuestion()} type="success">
        Add Form Element
        <a
          href="https://amsnetwork.atlassian.net/wiki/spaces/IDB/pages/877690887/Campaigns+Grant+Applications#Form-Elements"
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}>
          <i className="far fa-question-circle text-white pl-2"/>
        </a>
      </Button>
    </div>
  )
}

export default AddElementButton
