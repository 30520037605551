import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Item from "./Item";
import Pagination from "../UI/Pagination";
import { getForms } from "../../store/slices/formSlice";
import Spinner from "../UI/Spinner";
import CenteredContainer from "../UI/container/CenteredContainer";
import { selectTotalSubmissions } from "../../store/slices/submission";

class Items extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      checkFalse: !this.props.loading,
      isChecked: false,
      selectedReels: [],
    };
  }

  componentDidMount() {
    this.props.getForms(this.props.grantCampaign.id);
  }

  openModal = () => {
    window.scrollTo(0, 0);
    this.setState({ showModal: true });
  };

  paginationHandler = (page) => {
    window.scrollTo(0, 0);
    this.setState({ checkFalse: null });
    var searchParams = {};
    searchParams = this.props.forms.params;
    searchParams["page"] = page.selected + 1;
    this.props.getProjects(searchParams);
  };

  selectedReelsHandler = (projectId, isChecked) => {
    const selectedReels = [...this.state.selectedReels];
    if (isChecked) {
      selectedReels.push(projectId);
      this.setState({ selectedReels: selectedReels });
    } else {
      var index = selectedReels.indexOf(projectId);
      if (index !== -1) {
        selectedReels.splice(index, 1);
        this.setState({ selectedReels: selectedReels });
      }
    }
  };

  clearCheckboxHandler = () => {
    this.setState({ selectedReels: [] });
  };

  render() {
    const { forms, totalCount, loading } = this.props;
    let projectItems, forcePage;
    if (typeof this.props.forms.params != "undefined") {
      if (this.props.forms.params.page === "1") {
        forcePage = 0;
      }
    }
    if ( !forms || loading) {
      projectItems = <Spinner />;
    } else {
      if (forms.length > 0) {
        projectItems = forms.map((item) => {
          return (
            <Item
              key={item.id}
              grantCampaign={this.props.grantCampaign}
              item={item}
              isChecked={
                item.has_video
                  ? this.state.selectedReels.indexOf(item.id) === -1
                    ? false
                    : true
                  : false
              }
              setSelectedForm={this.props.setSelectedForm}
              clicked={this.selectedReelsHandler}
            />
          );
        });
      } else {
        projectItems = (
          <CenteredContainer style={ { minHeight: '15rem' } } >
            <h4>No Forms found...</h4>
          </CenteredContainer>
        );
      }
    }
    return (
      <div className="border-left">
        <div className="p-4">
          <div className="row">
            <div className="col-6 py-2">
              <span>
                Total Forms:{" "}
                <span className="font-weight-bold">{totalCount}</span>
              </span>
            </div>
          </div>
        </div>
        <ul className="list-group list-group-flush">{projectItems}</ul>

        <Pagination
          forcePage={forcePage}
          pageCount={totalCount / 10}
          handlePageClick={this.paginationHandler}
          checkFalse={this.state.checkFalse}
          range={5}
        />
      </div>
    );
  }
}

Items.propTypes = {
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  getForms: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    forms: state.forms.formsList,
    totalCount: state.forms.formsList.length,
    loading: state.forms.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForms: (campaign_id) => dispatch(getForms(campaign_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Items);
