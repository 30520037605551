import React, { Component } from "react"
import { toast } from "react-toastify"
import dateFns from "date-fns"
import { connect } from "react-redux"
import tagName from "../shared/tagName";
import { withRouter } from "react-router-dom"
import _ from "lodash"


import axios from "../../configAxios"
import errorHandler from "../errorPages/UserNotAuthorized"
import FormButton from "../UI/button/FormButton"
import Button from "../UI/button/Button"
import Spinner from "../UI/Spinner"

import Media from "./Media"

import NameAndOrganization from "../addFriend/personalInfo"
import ContactInformation from "../addFriend/ContactInformation"
import PrimaryEquipmentOperator from "../addFriend/PrimaryEquipmentOperator"
import Tags from "../addFriend/Tags"
import EducationAndAccreditations from "../addFriend/EducationAndAccreditations"
import Accreditations from "../addFriend/Accreditations"
import AboutFava from "../addFriend/AboutFava"
import AdditionalInfo from "./additionalInfo"
import CustomFields from "./CustomFields"
import FormFields from "./userFormFields"
import EditNonAdmin from "./EditNonAdmin"
import { getProjects } from "../../store/actions/index"

import translateLocalTimezone from "../../utils/translateLocalTimezone"

import "./AddFriend.css"

class AddFriend extends Component {
  signal = axios.CancelToken.source()
  timeout = 0
  defaultRedirectErrorMssg =
    "You are not allowed to update user profile contact information"

  state = {
    tags: {
      elementType: "asyncSelect",
      elementConfig: {
        name: "tags",
        placeholder: "Enter labels here",
        controlShouldRenderValue: false,
        inputLength: 1,
        loadOptions: (inputValue, callback) => {
          if (inputValue.length > 0)
            this.searchTagsbygName(inputValue, callback)
        },
        components: {
          DropdownIndicator: () => {
            return null
          },
          IndicatorSeparator: () => {
            return null
          },
          ClearIndicator: null,
        },
        styles: {
          multiValueLabel: (base) => ({
            ...base,
            backgroundColor: "#f8f9fa",
            color: "#666666",
          }),
          multiValueRemove: (base) => ({
            ...base,
            backgroundColor: "#f8f9fa",
            color: "#666666",
          }),
        },
      },
      selectedOption: [],
    },
    organization: "",
    user: "",
    firstName: "",
    lastName: "",
    createdAt: new Date(),
    dateOfBirth: null,
    selectedTabs: ["nameAndOrganization", "contactInformation"],
    selectedAboutFava: [],
    educationAndAccreditations: "",
    educationAndAccreditationsArray: [],
    selectedEduAndAcc: [],
    memberPermissions: {},
    expertiseObj: {},
    selectedValue: "",
    member_type: "",
    countries: [],
    tagged: [],
    oldTags: [],
    tagCharacters: "",
    attrData: "",
    photo: "",
    notes: "",
    uniqueName: "",
    roles: [],
    memberTypes: [],
    membershipPrice: {},
    eduLoading: true,
    selectedInfo: [],
    requiredFields: [],
    editUser: false,
    formData: {},
    loading: true,
    accreditations: [],
    selectedAccr: [],
    accreditationsList: [],
    expertiseNotes: "",
    tabs: ["Member", "Non Member"],
    customFields: {},
    userRoles: null,
    policyRes: null,
    disabled: false,
    notificationSettingId: null,
    userMedia: null,
    userMediaList: [],
  }

  componentDidMount() {
    const { match, location } = this.props
    if (
      match.path.includes("edit") &&
      location.state &&
      !location.state.isAdmin
    )
      return
    this.getUserFormData()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(
        {
          editUser: false,
          loading: true,
          notificationSettingId: null,
          updatedAccreditationsList: [],
          fileAttachments: [],
          userName: "",
          accreditationsList: [],
          eduLoading: true,
          disabled: false,
          editPolicyRes: null,
          selectedInfo: [],
          tagged: [],
          oldTags: [],
          userMedia: null,
          selectedEduAndAcc: [],
          selectedAccr: [],
          notes: "",
          uniqueName: "",
          expertiseNotes: "",
          createdAt: new Date(),
          dateOfBirth: null,
          roleLevel: null,
          organization: "",
          selectedTabs: ["nameAndOrganization", "contactInformation"],
        },
        () => {
          this.getUserFormData()
        }
      )
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getUserFormData = async () => {
    window.scrollTo(0, 0)
    const response = await axios.get("/custom_fields")
    if (response.data && response.data.json && !response.data.json.success) {
      toast.error(response.data.json.error || this.defaultRedirectErrorMssg)
      this.props.history.push(`/users/${this.props.auth.userId}/summary`)
      return
    }
    let customFieldsKey = []
    if (response.data.custom_fields) {
      customFieldsKey = response.data.custom_fields
        .filter((cf) => cf.klass_name === "User")
        .map((cf) => cf.column_name)
    }
    const customFields = {}
    customFieldsKey.forEach((fieldKey) => {
      customFields[fieldKey] = ""
    })
    const userRoles = response.data.custom_fields
      ? response.data.custom_fields.filter(
          (field) => field.klass_name === "Role"
        )
      : []
    this.setState({ customFields, userRoles })
    const policyRes = await axios.get("/users/policy", {
      params: { id: this.props.auth.userId },
    })
    this.setState({ policyRes: policyRes.data.json })

    const params = {}
    this.props.getProjects({ params })
    const expertiseObj = {}

    const formDataResponse = await axios.get("/users/formdata", {
      cancelToken: this.signal.token,
    })
    const {
      member_type: member_types,
      how_did_you_hear,
    } = formDataResponse.data.json
    let { role: roles } = formDataResponse.data.json

    const accreditations = {
      beginner: [],
      intermediate: [],
      advanced: [],
    }

    formDataResponse.data.json.cred.forEach((cred) => {
      let [label, type, id] = cred
      type = type.toLowerCase()
      accreditations[type] = [...accreditations[type], { label, id }]
    })
    const accreditationsList = []
    Object.keys(accreditations).forEach((accrType) => {
      accreditations[accrType].forEach((accr) => {
        const accrElement = {
          cred_id: accr.id,
        }
        accreditationsList.push(accrElement)
      })
    })

    const rolesSequence = {
      super_admin: 1,
      staff: 2,
      accounting: 3,
      member: 4,
      non_member: 5,
    }
    roles = roles.sort((role1, role2) => {
      return rolesSequence[role1.level] - rolesSequence[role2.level]
    })

    const membershipPrice = {}
    member_types.forEach((type) => {
      membershipPrice[type.id] = type.membership_price
    })

    const education_and_accreditations_response = await axios.get(
      "/education_and_associations",
      { cancelToken: this.signal.token }
    )
    this.setState({
      educationAndAccreditationsArray:
        education_and_accreditations_response.data.education_and_associations,
      expertiseObj,
      roles: roles.map((role) => {
        return {
          label: role.name ? role.name : _.capitalize(role.level),
          value: role.id,
          level: role.level,
        }
      }),
      memberTypes: member_types.map((type) => {
        return { label: type.name, value: type.id }
      }),
      membershipPrice: membershipPrice,
      eduLoading: false,
      accreditations,
      accreditationsList,
      aboutFava: how_did_you_hear,
    })

    if (this.props.location.pathname.includes("edit")) {
      this.getUserDetailsData()
    } else {
      this.setState({
        ...this.state,
        loading: false,
        selectedInfo: [...this.state.selectedInfo, "status"],
      })
    }
  }

  getUserDetailsData = async () => {
    const userDataResponse = await axios.get(
      `/users/${this.props.match.params.id}`,
      {
        cancelToken: this.signal.token,
      }
    )
    if (
      !userDataResponse.data.meta.is_admin &&
      !userDataResponse.data.user.allow_users_to_update_contact_information
    ) {
      toast.error(this.defaultRedirectErrorMssg)
      this.props.history.push(`/users/${this.props.match.params.id}/summary`)
      return
    }
    this.setState({
      editUser: true,
      notificationSettingId:
        userDataResponse.data.user.notification_setting &&
        userDataResponse.data.user.notification_setting.id,
      updatedAccreditationsList:
        userDataResponse.data.user.cred_users &&
        userDataResponse.data.user.cred_users,
      fileAttachments: userDataResponse.data.user.file_attachments,
      userName: userDataResponse.data.user.full_name,
    })

    const accreditationsListUpdated = this.state.accreditationsList
    this.state.updatedAccreditationsList &&
      this.state.updatedAccreditationsList.map((accr) => {
        this.state.accreditationsList.forEach((accr1) => {
          if (accr1.cred_id === accr.cred_id) {
            accr1.cred_id = accr.cred_id
            accr1.user_id = accr.user_id
            accr1.id = accr.id
            accr1.expiry_date = accr.expiry_date
            accr1.file_attachment = accr.file_attachment
          }
        })
      })
    this.setState({
      accreditationsList: accreditationsListUpdated.length
        ? accreditationsListUpdated
        : this.state.accreditationsList,
    })
    this.loadFormValues(userDataResponse.data.user)
    const userPolicyResponse = await axios.get(`/users/policy`, {
      params: { id: this.props.match.params.id },
      cancelToken: this.signal.token,
    })
    this.setState({
      editPolicyRes: userPolicyResponse.data.json,
      loading: false,
    })
  }

  loadFormValues = (userData) => {
    const params = {
      user_id: this.props.match.params.id,
    }
    axios
      .get(`tags/`, { params, cancelToken: this.signal.token })
      .then((res) => {
        if (res.data.tags[0] !== "") {
          this.setState({
            ...this.state,
            tagged: res.data.tags[0].split(","),
            oldTags: res.data.tags[0].split(","),
          })
        }
      })
      .catch((error) => error)

    const formControls = {}
    Object.keys(FormFields.textInput).forEach((key) => {
      if (userData[FormFields.textInput[key]]) {
        formControls[key] = userData[FormFields.textInput[key]]
      }
    })
    Object.keys(FormFields.checkboxes).forEach((key) => {
      if (userData[FormFields.checkboxes[key]]) {
        formControls[key] = userData[FormFields.checkboxes[key]]
      }
    })

    const personalInfoCheckbox = [
      "can_rent_equipment",
      "can_book_facilities",
      "can_register_in_programs",
      "gets_member_rates",
      "is_on_mailing_list",
      "gets_insurance_coverage",
      "allow_booking",
      "allow_registration",
      "jury_member",
    ]
    const createdAt = userData.created_at
      ? new Date(userData.created_at)
      : new Date()
    let dateOfBirth = userData.date_of_birth
    if (dateOfBirth) dateOfBirth = translateLocalTimezone(dateOfBirth)

    const customFields = { ...this.state.customFields }

    Object.keys(customFields).forEach((cf) => {
      customFields[cf] = userData.data[cf]
    })

    const expertiseObj = {}

    const formData = {
      ...this.state.formData,
      ...formControls,
      customFields,
      createdAt,
      dateOfBirth,
      twitter: userData.data.twitter,
      instagram: userData.data.instagram,
      facebook: userData.data.facebook,
      linkedin: userData.data.linkedin,
      youtube: userData.data.youtube,
      primaryEquipmentFirstName: userData.primary_operator_first_name,
      primaryEquipmentLastName: userData.primary_operator_last_name,
      primaryEquipmentContactInfo: userData.primary_operator_contact_info,
    }

    const personalInfoCheckboxUpdated = personalInfoCheckbox.filter((key) =>
      formControls.hasOwnProperty(key)
    )

    if (userData.status === 0) {
      personalInfoCheckboxUpdated.push("status")
    }

    if (
      userData.notification_setting &&
      userData.notification_setting.new_member_ticket
    ) {
      personalInfoCheckboxUpdated.push("notification_setting_attributes")
    }

    this.setState({
      ...this.state,
      customFields,
      organization: userData.user_organization_name,
      notes: userData.notes,
      uniqueName: userData.unique_name,
      formData: formData,
      expertiseObj,
      createdAt: createdAt,
      dateOfBirth: dateOfBirth,
      selectedInfo: personalInfoCheckboxUpdated,
      selectedEduAndAcc: userData.education_and_associations.map(
        (obj) => obj.id
      ),
      selectedAccr: userData.creds.map((obj) => obj.id),
    })
  }

  inputImageHandler = (event, key) => {
    const inputValue = {}
    if (key === "type") {
      this.setState({ selectedMedia: event })
      inputValue.selectedOption = event
    } else {
      inputValue.value = event.target.value
    }
    const updatedcontrols = {
      ...this.state.controls,
      [key]: {
        ...this.state.controls[key],
        ...inputValue,
      },
    }
    this.setState({ controls: updatedcontrols })
  }

  getTags = () => {
    let user_id
    if (this.state.editUser) user_id = this.props.match.params.id
    else user_id = this.props.project.user_id

    const { project_id } = this.state
    axios
      .get("/tags", {
        params: { project_id, user_id },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        if (res.data.tags[0] !== "") {
          this.setState({
            tagged: res.data.tags[0].split(","),
          })
        } else {
          this.setState({ tagged: [] })
        }
      })
  }

  searchTagsbygName = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue,
        type: "User",
      },
      cancelToken: this.signal.token,
    }
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.getTagsByName(queryParams, callback)
    }, 500)
  }

  getTagsByName = (queryParams, callback) => {
    axios.get("/tags/search", queryParams).then((res) => {
      const options = res.data.json.filter(
        (el) => !this.state.tagged.includes(el.name)
      )
      callback(
        options.map((tag) => {
          return {
            value: tag.value,
            label: tag.name,
          }
        })
      )
    })
  }

  inputTagsChangeHandler = (option) => {
    const user_id = this.props.match.params.id
    if (option && option.value) {
      if (this.state.editUser) {
        axios
          .post("/tags", { query: option.value, user_id: user_id })
          .then((res) => {
            if (res.data.json.success) {
              this.getTags()
            } else {
              errorHandler(res)
            }
          })
      } else {
        const tagged = this.state.tagged.slice(0)
        if (!this.state.tagged.includes(option.value)) {
          tagged.push(option.value)
        }
        this.setState({ tagged: tagged })
      }
    }
  }

  deleteTagHandler = (tagName, index) => {
    const user_id = this.props.match.params.id
    const response = window.confirm(
      "Are you sure you want to delete the label?"
    )
    if (response) {
      if (this.state.editUser) {
        axios
          .delete("/tags", {
            params: { query: tagName, user_id },
          })
          .then((res) => {
            this.getTags()
          })
      } else {
        const tagged = this.state.tagged.slice(0)
        tagged.splice(index, 1)
        this.setState({ tagged: tagged })
      }
    }
  }

  inputChangeHandler = (event, type, key) => {
    if (type === "text") {
      this.setState({ [key]: event.target.value })
    } else if (type === "select") {
      this.setState({ [key]: event ? event.value : "" })
      if (key === "role" && event) {
        this.setState({ roleLevel: event.level })
        if (event.level === "non_member") {
          this.setState({
            selectedInfo: this.props.location.pathname.includes("edit")
              ? [...this.state.selectedInfo]
              : ["status"],
            memberType: null,
            membershipDues: "",
            volunteerHours: "",
          })
        }
      }
    } else if (type === "expertiseSelect") {
      const obj = { ...this.state.expertiseObj }
      obj[key] = parseInt(event.target.value)
      this.setState({ expertiseObj: obj })
    } else if (type === "date") {
      if (key === "createdAt") {
        this.setState({ createdAt: event })
      } else if (key === "dateOfBirth") {
        this.setState({ dateOfBirth: event })
      }
    } else if (type === "customFields") {
      this.setState({
        customFields: {
          ...this.state.customFields,
          [key]: event.target.value,
        },
      })
    }
  }

  selectedEduAndAccHandler = (accrId) => {
    const { selectedEduAndAcc } = this.state
    if (selectedEduAndAcc.indexOf(accrId) === -1) {
      this.setState({ selectedEduAndAcc: [...selectedEduAndAcc, accrId] })
    } else {
      this.setState({
        selectedEduAndAcc: selectedEduAndAcc.filter((id) => id !== accrId),
      })
    }
  }

  handleChange = (selectorFile) => {
    this.setState({ photo: selectorFile })
  }

  createAccreditationOrUpdate = (id) => {
    this.setState({ loading: true })
    const promises = []
    this.state.accreditationsList.forEach((accr) => {
      const formData = new FormData()
      const accrdata = {
        user_id: accr.user_id ? accr.user_id : id,
        cred_id: accr.cred_id,
        id: accr.id,
        file_attachment: accr.photo,
        expiry_date: accr.expiry_date && accr.expiry_date,
      }
      for (const key in accrdata) {
        if (accrdata[key]) formData.append(key, accrdata[key])
      }
      if (accr.id) {
        if (this.state.selectedAccr.includes(accr.cred_id)) {
          promises.push(axios.put(`/cred_users/${accr.id}`, formData))
        } else {
          promises.push(axios.delete(`/cred_users/${accr.id}`))
        }
      } else {
        if (this.state.selectedAccr.includes(accr.cred_id)) {
          promises.push(axios.post("/cred_users", formData))
        }
      }
    })
    axios.all(promises).then(() => {
      if (this.state.editUser)
        toast.success(
          `${
            this.state.roleLevel === "non_member" ? "Non Member" : "Member"
          } updated successfully`
        )
      else
        toast.success(
          `${
            this.state.roleLevel === "non_member" ? "Non Member" : "Member"
          } created successfully`
        )
      this.setState({ loading: false })
      this.props.history.push(`/users/${id}/summary`)
    })
  }

  createOrUpdateTags = (id) => {
    if (this.state.editUser) {
      this.state.oldTags.forEach((tag) => {
        if (!this.state.tagged.includes(tag)) {
          const params = {
            query: tag,
            user_id: id,
          }
          axios.delete("/tags", { params })
        }
      })

      this.state.tagged.forEach((tag) => {
        if (!this.state.oldTags.includes(tag)) {
          axios.post("/tags", {
            query: tag,
            user_id: id,
          })
        }
      })
    } else {
      this.state.tagged.forEach((tag) => {
        axios.post("/tags", {
          query: tag,
          user_id: id,
        })
      })
    }
  }

  addMediaHandler = (fileAttachment) => {
    this.setState({ userMedia: fileAttachment })
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })

    const { selectedInfo } = this.state
    const formKeys = {
      organization_name: "organization",
      first_name: "firstName",
      last_name: "lastName",
      role_id: "role",
      member_type_id: "memberType",
      membership_dues: "membershipDues",
      volunteer_hours_required_per_year: "volunteerHours",
      address1: "address1",
      address2: "address2",
      city: "city",
      province: "province",
      country: "country",
      postal_code: "postCode",
      home_phone: "phone",
      mobile_phone: "mobile",
      email: "email",
      alternate_email: "alternateEmail",
      website: "website",
      job_position: "jobDescription",
      bio_link: "bio",
      twitter: "twitter",
      instagram: "instagram",
      facebook: "facebook",
      youtube: "youtube",
      linkedin: "linkedin",
      pronouns: "pronouns",
      notes: "notes",
      unique_name: "uniqueName",
      permissions: "memberPermissions",
      primary_operator_first_name: "primaryEquipmentFirstName",
      primary_operator_last_name: "primaryEquipmentLastName",
      primary_operator_contact_info: "primaryEquipmentContactInfo",
      education_and_association_ids: "selectedEduAndAcc",
      specializations: "specializations",
    }

    const can_be_empty_fields = [
      "organization",
      "volunteerHours",
      "membershipDues",
      "address1",
      "address2",
      "city",
      "province",
      "country",
      "postCode",
      "phone",
      "mobile",
      "alternateEmail",
      "website",
      "jobDescription",
      "bio",
      "twitter",
      "instagram",
      "notes",
      "uniqueName",
      "created_at",
      "date_of_birth",
      "pronouns",
      "facebook",
      "youtube",
      "linkedin",
      "specializations",
    ]

    const formData = {
      user_entity_type:
        this.state.roleLevel === "non_member" ? "Non Member" : "Member",
      date_of_birth: this.state.dateOfBirth
        ? dateFns.format(new Date(this.state.dateOfBirth), "DD-MM-YYYY")
        : {},
      permissions:
        this.state.memberPermissions === undefined ||
        this.state.memberPermissions == null
          ? {}
          : this.state.memberPermissions,
    }

    if (!this.state.editUser) {
      formData.how_did_you_hear_ids = `[${this.state.selectedAboutFava}]`
      formData.created_at = dateFns.format(
        new Date(this.state.createdAt),
        "DD-MM-YYYY"
      )
    }

    Object.keys(this.state.customFields).forEach((cf) => {
      const { customFields } = this.state
      if (typeof customFields[cf] !== "undefined" && customFields[cf] !== null)
        formData[cf] = customFields[cf]
    })

    Object.keys(formKeys).map((key) => {
      const stateKey = formKeys[key]
      const can_be_empty_field =
        can_be_empty_fields.indexOf(stateKey) > -1 &&
        typeof this.state[stateKey] !== "undefined"
      if (this.state[stateKey] || can_be_empty_field) {
        if (!Array.isArray(this.state[stateKey])) {
          formData[key] = this.state[stateKey]
        } else {
          formData[key] = `[${this.state[stateKey]}]`
        }
      }
      if (_.isBoolean(this.state[stateKey])) {
        formData[key] = this.state[stateKey]
      }
      return formData[key]
    })
    if (this.state.roleLevel !== "non_member") {
      selectedInfo.forEach((key) => {
        formData[key] = true
      })

      Object.keys(FormFields.checkboxes).forEach((key) => {
        formData[key] = selectedInfo.includes(key)
      })

      const personalInfoCheckbox = [
        "can_rent_equipment",
        "can_book_facilities",
        "can_register_in_programs",
        "gets_member_rates",
        "is_on_mailing_list",
        "gets_insurance_coverage",
        "allow_booking",
        "allow_registration",
        "jury_member",
      ]
      personalInfoCheckbox.forEach((key) => {
        if (!selectedInfo.includes(key)) {
          formData[key] = false
        }
      })
    } else {
      formData.is_on_mailing_list = selectedInfo.includes("is_on_mailing_list")
      formData.jury_member = selectedInfo.includes("jury_member")
    }

    formData.status = selectedInfo.includes("status") ? 0 : 1
    formData.notification_setting_attributes = selectedInfo.includes(
      "notification_setting_attributes"
    )
      ? JSON.stringify({
          new_member_ticket: 1,
          id: this.state.notificationSettingId,
        })
      : JSON.stringify({
          new_member_ticket: 0,
          id: this.state.notificationSettingId,
        })

    let data = {}
    const attrData = new FormData()
    for (var key in formData) {
      if (formData.hasOwnProperty(key)) {
        var val = formData[key]
        if (
          val !== "" &&
          val !== undefined &&
          !_.isEmpty(val) &&
          key !== "expertise_profile[expertise_area_ids]" &&
          key !== "permissions" &&
          key !== "selectedAboutFava"
        ) {
          attrData.append(key, val)
          const tempData = data
          tempData[key] = val
          data = tempData
        } else {
          if (typeof val === "object") {
            if (!_.isEmpty(val)) {
              attrData.append([key], JSON.stringify(val))
            }
          } else {
            attrData.append(key, val)
          }
        }
      }
    }

    if (this.state.photo !== "") {
      attrData.append("photo", this.state.photo[0])
    }

    if (this.state.userMedia) {
      attrData.append("file_attachment", this.state.userMedia)
    }

    if (this.state.editUser) {
      axios
        .put(`/users/${this.props.match.params.id}`, attrData)
        .then((res) => {
          if (res.data.json.success === true) {
            this.createAccreditationOrUpdate(res.data.json.data.id)
          } else {
            errorHandler(res)
            this.setState({ disabled: false })
          }
        })
        .catch(() => this.setState({ disabled: false }))
    } else {
      axios
        .post("/users", attrData)
        .then((res) => {
          if (res.data.json.success === true) {
            this.createOrUpdateTags(res.data.json.data.id)
            this.createAccreditationOrUpdate(res.data.json.data.id)
          } else {
            errorHandler(res)
            this.setState({ disabled: false })
          }
        })
        .catch(() => this.setState({ disabled: false }))
    }
  }

  tabHandler = (tab) => {
    const selectedTabs = this.state.selectedTabs
    if (selectedTabs.includes(tab)) {
      const index = selectedTabs.indexOf(tab)
      selectedTabs.splice(index, 1)
    } else {
      selectedTabs.push(tab)
    }
    this.setState({ selectedTabs: selectedTabs })
  }

  aboutFavaHandler = (aboutId) => {
    const { selectedAboutFava } = this.state
    if (selectedAboutFava.indexOf(aboutId) === -1) {
      this.setState({ selectedAboutFava: [...selectedAboutFava, aboutId] })
    } else {
      this.setState({
        selectedAboutFava: selectedAboutFava.filter((id) => id !== aboutId),
      })
    }
  }

  createAccreditationList = (accreditationsList) => {
    this.setState({ accreditationsList })
  }

  selectAccrHandler = (accrId) => {
    const { selectedAccr } = this.state
    if (selectedAccr.indexOf(accrId) === -1) {
      this.setState({ selectedAccr: [...selectedAccr, accrId] })
    } else {
      this.setState({
        selectedAccr: selectedAccr.filter((id) => id !== accrId),
      })
    }
  }

  checkedPersonalInfo = (key) => {
    const { selectedInfo } = this.state
    if (!selectedInfo.includes(key)) {
      this.setState({ selectedInfo: [...selectedInfo, key] })
    } else {
      this.setState({
        selectedInfo: selectedInfo.filter((id) => id !== key),
      })
    }
  }

  render() {
    const { roleLevel } = this.state
    const tags = this.state.tagged.map((tagValue, index) => (
      <span
        className="tag word-wrap-break mb-3"
        key={index}
        style={{ maxWidth: "100%" }}
      >
        <span className="tag-name">{tagName(tagValue)}</span>
        <button
          type="button"
          className="tag-delete"
          onClick={() => this.deleteTagHandler(tagValue, index)}
        >
          <i className="fas fa-sm fa-times mr-2" />
        </button>
      </span>
    ))

    const educationAndAccreditationsArray = this.state.educationAndAccreditationsArray.map(
      (educationAndAccreditations, index) => {
        const selectedEduAndAcc = this.state.selectedEduAndAcc.slice(0)
        return (
          <React.Fragment key={educationAndAccreditations.id}>
            <div className="col-md-4">
              <div className="custom-checkbox">
                <div
                  className={
                    selectedEduAndAcc.includes(educationAndAccreditations.id)
                      ? "box bg-primary"
                      : "box bg-white"
                  }
                  onClick={() =>
                    this.selectedEduAndAccHandler(educationAndAccreditations.id)
                  }
                >
                  <i
                    className="fas fa-check position-relative"
                    style={{
                      color: "white",
                      marginLeft: 2,
                    }}
                  />
                </div>
                <p
                  id={educationAndAccreditations.id}
                  key={educationAndAccreditations.id}
                  value={educationAndAccreditations.name}
                  style={{ cursor: "default" }}
                >
                  {educationAndAccreditations.name}
                </p>
              </div>
            </div>
          </React.Fragment>
        )
      }
    )

    const { location, match } = this.props
    if (
      match.path.includes("edit") &&
      location.state &&
      !location.state.isAdmin
    ) {
      return <EditNonAdmin />
    }

    return (
      <div className="container my-3 add-friend w-100">
        <div className="row">
          <div className="col-md-6 mb-3">
            {!this.state.loading && (
              <h4>{this.state.editUser ? "" : "New User"}</h4>
            )}
          </div>
          <div className="col-md-6">
            {/* <div
                className="text-right mt-3"
                onClick={() => this.props.history.goBack()}
              >
                {"<< Back"}
              </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              {this.state.loading ? (
                <Spinner />
              ) : (
                <div className="card-body p-0">
                  <div className="card-text p-4 pb-5">
                    <form autoComplete="off" onSubmit={this.submitHandler}>
                      <NameAndOrganization
                        inputChangeHandler={this.inputChangeHandler}
                        handleChange={this.handleChange}
                        selectedTabs={this.state.selectedTabs}
                        tabHandler={this.tabHandler}
                        roles={this.state.roles}
                        editUser={this.state.editUser}
                        formData={this.state.formData}
                        memberTypes={this.state.memberTypes}
                        selectedInfo={this.state.selectedInfo}
                        checkedPersonalInfo={this.checkedPersonalInfo}
                        policyRes={this.state.policyRes}
                        editPolicyRes={this.state.editPolicyRes}
                        customFields={this.state.userRoles}
                        membershipPrices={this.state.membershipPrice}
                      />
                      <ContactInformation
                        inputChangeHandler={this.inputChangeHandler}
                        editUser={this.state.editUser}
                        formData={this.state.formData}
                        selectedTabs={this.state.selectedTabs}
                        tabHandler={this.tabHandler}
                      />
                      <AdditionalInfo
                        inputChangeHandler={this.inputChangeHandler}
                        handleChange={this.handleChange}
                        selectedTabs={this.state.selectedTabs}
                        tabHandler={this.tabHandler}
                        editUser={this.state.editUser}
                        formData={this.state.formData}
                      />
                      {roleLevel === "non_member" && (
                        <PrimaryEquipmentOperator
                          inputChangeHandler={this.inputChangeHandler}
                          selectedTabs={this.state.selectedTabs}
                          tabHandler={this.tabHandler}
                          formData={this.state.formData}
                          editUser={this.state.editUser}
                        />
                      )}
                      {this.state.policyRes.can_add_or_delete_label && (
                        <Tags
                          inputTagsChangeHandler={this.inputTagsChangeHandler}
                          tags={this.state.tags}
                          selectedTabs={this.state.selectedTabs}
                          tabHandler={this.tabHandler}
                          tagCharacters={this.state.tagCharacters}
                          tagsList={tags}
                          onInputChange={(event) =>
                            this.setState({ tagCharacters: event })
                          }
                        />
                      )}
                      <EducationAndAccreditations
                        educationAndAccreditationsArray={
                          educationAndAccreditationsArray
                        }
                        loading={this.state.eduLoading}
                        inputChangeHandler={this.inputChangeHandler}
                        selectedTabs={this.state.selectedTabs}
                        tabHandler={this.tabHandler}
                        educationAndAccreditations={
                          this.state.educationAndAccreditations
                        }
                        selectedEdAndacc={this.state.selectedEdAndacc}
                      />
                      <Accreditations
                        inputChangeHandler={this.inputChangeHandler}
                        selectedTabs={this.state.selectedTabs}
                        tabHandler={this.tabHandler}
                        accreditations={this.state.accreditations}
                        accreditationsList={this.state.accreditationsList}
                        selectAccrHandler={this.selectAccrHandler}
                        selectedAccr={this.state.selectedAccr}
                        editUser={this.state.editUser}
                        createAccreditationList={this.createAccreditationList}
                      />

                      <CustomFields
                        tabHandler={this.tabHandler}
                        selectedTabs={this.state.selectedTabs}
                        inputChangeHandler={this.inputChangeHandler}
                        formData={this.state.formData}
                        editUser={this.state.editUser}
                      />
                      <Media
                        selectedTabs={this.state.selectedTabs}
                        tabHandler={this.tabHandler}
                        editUser={this.state.editUser}
                        userMediaList={this.state.userMediaList}
                        userMedia={this.state.userMedia}
                        getMediaAttributes={this.getMediaAttributes}
                        addMediaHandler={this.addMediaHandler}
                        deleteMediaAttributesList={
                          this.deleteMediaAttributesList
                        }
                        fileAttachments={this.state.fileAttachments}
                        userId={this.props.match.params.id}
                        username={this.state.userName}
                      />
                      {!this.state.editUser && (
                        <AboutFava
                          selectedAboutFava={this.state.selectedAboutFava}
                          tabHandler={this.tabHandler}
                          selectedTabs={this.state.selectedTabs}
                          aboutFava={this.state.aboutFava}
                          aboutFavaHandler={this.aboutFavaHandler}
                        />
                      )}
                      <div className="row mt-3">
                        <div className="col-12 text-right">
                          <Button
                            type="secondary"
                            clicked={() => this.props.history.goBack()}
                            className="float-none mr-1"
                          >
                            Cancel
                          </Button>
                          <FormButton
                            className="ml-1"
                            disabled={this.state.disabled}
                          >
                            {this.state.editUser ? "Update" : "Create"}
                          </FormButton>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    project: state,
    response: state.response,
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProjects: (params) => dispatch(getProjects(params)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddFriend)
)
