import React, { Component } from 'react'
import { toast } from "react-toastify"
import { title } from "change-case"

import axios from '../../../../configAxios'
import Button from '../../../UI/button/Button'
import Modal from "../../../UI/Modal";
import Spinner from "../../../UI/Spinner";
import Pagination from "../../../UI/Pagination";
import Input from "../../../UI/input/Input";
import SearchFilter from "../../../common/searchFilter/SearchFilter"
import langUtils from '../../../../utils/langUtils'

import TagForm from "../TagForm"
import MergeForm from "../MergeForm"
import DeleteIcon from "../DeleteIcon";

class Tags extends Component {
  constructor() {
    super();
    this.signal = axios.CancelToken.source();
    this.timeout = 0;
    this.state = {
      organizationTags: [],
      totalCount: 0,
      selectedPage: 1,
      forcePage: 0,
      showModal: false,
      edit: false,
      loading: true,
      organizationTagId: null,
      search: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "search",
          placeholder: "Search Label"
        },
        value: ""
      },
      perPage: 10
    }
    this.textInput = React.createRef();
  }

  componentDidMount() {
    this.getOrganizationTags();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getOrganizationTags = () => {
    axios.get('/organization_tags', {
      params: {
        tag_type: "Project",
        page: this.state.selectedPage,
        query: this.state.search.value,
        per_page: this.state.perPage
      },
      cancelToken: this.signal.token
    })
      .then(res => {
        this.setState({
          ...this.state,
          organizationTags: res.data.organization_tags,
          totalCount: res.data.meta.total_count,
          loading: false
        })
      })
  }

  deleteOrganizationTag = tagId => {
    let response = window.confirm("Are you sure you want to delete the label?");
    if (response) {
      axios.delete(`/organization_tags/${tagId}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Label is being deleted in the background.");
            setTimeout(() => window.location.reload(), 3000);
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      forcePage: page.selected,
      loading: true
    },
      () => this.getOrganizationTags()
    );
  };

  inputChangedHandler = event => {
    this.setState({
      search: {
        ...this.state.search,
        value: event.target.value
      },
      loading: true,
      selectedPage: 1,
      forcePage: 0
    })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getOrganizationTags();
    }, 300);
  }

  clearQueryString = () => {
    this.setState({
      search: {
        ...this.state.search,
        value: ""
      },
      forcePage: 0,
      selectedPage: 1,
      loading: true
    },
      () => this.getOrganizationTags()
    );
    this.textInput.current.focus();
  }

  perPageChangeHandler = perPage => {
    this.setState({
      perPage,
      loading: true,
      selectedPage: 1,
      forcePage: 0
    }, () => this.getOrganizationTags());
  }

  render() {
    const { forcePage, totalCount, perPage } = this.state;
    let removeIcon;
    if (this.state.search.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times float-right position-relative b-40 r-15 cursor"
          onClick={() => this.clearQueryString()}
        />
      );
    }

    let tagsList;
    if (this.state.loading) {
      tagsList = <Spinner />
    } else {
      if (this.state.organizationTags.length === 0) {
        tagsList = <div className="row">
          <div className="col-12">
            <div className="text-center p-5 m-5">
              <h5>No Labels</h5>
            </div>
          </div>
        </div>
      } else {
        tagsList = this.state.organizationTags.map(organizationTag => (
          <div className="list-group-item" key={organizationTag.id}>
            <div className="row">
              <div className="col-6">{organizationTag.name}</div>
              <div className="col-3 text-center">{organizationTag.count ? organizationTag.count : 0}</div>
              <div className="col-3 d-flex justify-content-around align-items-center">
                <button
                  className="btn btn-sm btn-outline-secondary"
                  title="Edit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    this.setState({ ...this.state, showMergeModal: true, organizationTagId: organizationTag.id })
                  }
                  }
                >
                  Merge
                </button>
                <i
                  className="fas fa-pencil-alt cursor"
                  title="Edit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    this.setState({ ...this.state, showModal: true, edit: true, organizationTagId: organizationTag.id })
                  }
                  }
                />
                <DeleteIcon tag={organizationTag} deleteTag={this.deleteOrganizationTag} />
              </div>
            </div>
          </div>
        ))
      }
    }

    let totalItems =
      this.state.totalCount < this.state.perPage
        ? this.state.totalCount
        : this.state.perPage;
    let to =
      this.state.totalCount < this.state.selectedPage * totalItems
        ? this.state.totalCount
        : this.state.selectedPage * totalItems;

    return (
      <div className="tag-manager">
        <div className="px-4 pt-4 pb-2">
        <div className="d-flex justify-content-between">
            <div className="flex-grow-1 pr-2">
              <Input {...this.state.search} forwardRef={this.textInput} changed={this.inputChangedHandler} />
              {removeIcon}
            </div>
            <div className="pl-2">
              <Button
                type="primary"
                clicked={() => {
                  window.scrollTo(0, 0);
                  this.setState({ ...this.state, showModal: true, edit: false })
                }
                }
              >
                {langUtils("txt_global_add_new", "Add New")}
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="offset-md-6 col-md-6">
            <SearchFilter
              perPage={perPage}
              inputChangeHandler={this.perPageChangeHandler}
            />
          </div>
        </div>

        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="row">
              <div className="col-6">
                <span className="font-weight-bold">Label</span>
              </div>
              <div className="col-3 text-center">
                <span className="font-weight-bold">Label Count</span>
              </div>
              <div className="col-3" />
            </div>
          </div>
          {tagsList}
        </div>

        <div className="d-flex">
          <div className="flex-fill d-flex justify-content-start align-items-center ml-2 pt-2">
            {`Showing ${(this.state.selectedPage - 1) *
              this.state.perPage +
              1} to ${to} of ${this.state.totalCount}`}
          </div>
          <div>
          <Pagination
            forcePage={forcePage}
            pageCount={totalCount / perPage}
            handlePageClick={this.paginationHandler} />
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          title={this.state.edit ? "Update Label" : "New Label"}
          hide={() => this.setState({ showModal: false })}
        >
          <TagForm
            organizationTagId={this.state.organizationTagId}
            edit={this.state.edit}
            tagType="Project"
            closeModal={() => this.setState({ showModal: false })}
            getOrganizationTags={this.getOrganizationTags}
          />
        </Modal>
        <Modal
          show={this.state.showMergeModal}
          title="Merge Label"
          hide={() => this.setState({ showMergeModal: false })}
        >
          <MergeForm
            organizationTagId={this.state.organizationTagId}
            edit={this.state.edit}
            tagType="Project"
            closeModal={() => this.setState({ showMergeModal: false })}
          />
        </Modal>
      </div>
    )
  }
}

export default Tags;
