import React, { useState, useEffect } from "react";
import ProgramSearch from "./ProgramSearch";
import ProgramItems from "./ProgramItems";
import axios from "../../configAxios";

const ListView = () => {
  const [disabled, setDisabled] = useState(true);
  const [programs, setPrograms] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [params, setParams] = useState({});

  const signal = axios.CancelToken.source();

  useEffect(() => {
    axios
      .get("/programs", { params, cancelToken: signal.token })
      .then(res => {
        setPrograms(res.data.programs);
        setTotalCount(res.data.meta.total_count);
        setDisabled(false);
      })
      .catch(err => {
      setTotalCount(0);
      setDisabled(false);
      });
  }, [params])

  return (
    <div className="row">
        <div className="col-lg-5">
          <ProgramSearch
            setParams={setParams}
            disabled={disabled}
          />
        </div>
        <div className="col-lg-7">
          <ProgramItems
            programs={programs}
            totalCount={totalCount}
            setParams={setParams}
          />
        </div>
      </div>
  )
};

export default ListView;
