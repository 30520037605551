import React from 'react';
import dateFns from "date-fns";
import { Link } from "react-router-dom";

const OrganizationItem = props => {
  return (
    <tr>
      <td>{props.organization.id}</td>
      <td>{props.organization.full_name}</td>
      <td>{props.organization.subdomain}</td>
      <td className="text-center">{props.organization.installation_type}</td>
      <td className="text-center">{props.organization.ui_version}</td>
      <td className="text-center">
        {props.organization.last_login
          ? dateFns.format(new Date(props.organization.last_login), "YYYY-MM-DD")
          : "---"}
      </td>
      <td className="text-center">{props.organization.base_language}</td>
      <td className="p-0 text-center action-column">
        <Link to={`/organizations/${props.organization.id}`}>
          <button title="show" className="btn btn-link m-0 p-2">
            <i className="fas fa-chart-area" />
          </button>
        </Link>
        <Link to={`/organizations/edit/${props.organization.id}`}>
          <button title="edit" className="btn btn-link m-0 p-2">
            <i className="fas fa-pen" />
          </button>
        </Link>
        <Link to={`/organizations/${props.organization.id}/super_admins`}>
          <button title="users" className="btn btn-link m-0 p-2">
            <i className="fas fa-users" />
          </button>
        </Link>
        <i className="fas fa-trash-alt cursor btn btn-link p-2"
          disabled={props.organization.installation_type &&
            props.organization.installation_type.toLowerCase() === "active" ? true : false}
          onClick={() => props.organization.installation_type &&
            props.organization.installation_type.toLowerCase() !== "active" && props.organizationDeleteHandler(props.organization.id, props.organization.full_name)}
          style={{
            cursor: props.organization.installation_type &&
              props.organization.installation_type.toLowerCase() !== "active" ? "pointer" : "no-drop",
            opacity: props.organization.installation_type &&
              props.organization.installation_type.toLowerCase() !== "active" ? 1 : 0.7,
            marginBottom:"1px"
          }}
        />
        <a href={`https://${props.organization.subdomain}.amsnetwork.ca/redirect/${localStorage.authToken}?userId=${localStorage.userId}&organizationId=${props.organization.id}&moreUserInfo=${localStorage.moreUserInfo}&expiresIn=${localStorage.expiresIn}&roleLevel=${localStorage.roleLevel}&name=${props.organization.name}&subdomain=${props.organization.subdomain}`}
          target="_blank" rel="noopener noreferrer">
          <button title="subdomain" className="btn btn-link m-0 p-2">
            <i class="fas fa-external-link-alt" />
          </button>
        </a>
      </td>
    </tr>
  )
}

export default OrganizationItem;
