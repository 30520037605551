import React, { Component } from "react"
import { NavLink } from "react-router-dom"

import axios from "../../../configAxios"
import Button from "../../UI/button/Button"
import Modal from "../../UI/Modal"

import CreateProject from "../CreateProject"
import langUtils from "../../../utils/langUtils"
import ProjectSearch from "../ProjectSearch"
import ProjectItems from "../ProjectItems"

import "./Projects.css"

class Projects extends Component {
  state = {
    showModal: false,
    refreshList: false,
    showReels: null,
    showCollections: null,
    canViewAddProjects: false,
  }

  componentDidMount() {
    this.getPolicy()
    if (this.props.location.pathname.includes("new")) {
      this.openModal()
    }
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios.get("/projects/policy").then((res) => {
      this.setState({
        canViewAddProjects: res.data.json.can_view_add_projects,
      })
    })
  }

  componentWillReceiveProps(props) {
    if (props.location.pathname.includes("new")) {
      this.openModal()
    }
  }

  openModal = () => {
    window.scrollTo(0, 0)
    this.setState({
      showModal: true,
      refreshList: false,
    })
  }

  getPolicy = () => {
    axios.get("/projects/policy").then((res) => {
      this.setState({
        showReels: res.data.json.reels,
        showCollections: res.data.json.collections,
      })
    })
  }

  render() {
    return (
      <div className="container projects w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Projects</h3>
          </div>
          <div className="col-6 text-right">
            <Button
              type="success"
              clicked={this.openModal}
              disabled={!this.state.canViewAddProjects}
              style={{
                cursor: this.state.canViewAddProjects ? "pointer" : "no-drop",
                opacity: this.state.canViewAddProjects ? 1 : 0.7,
              }}
            >
              {langUtils("txt_global_add_new", "Add New")}
            </Button>
          </div>
        </div>

        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs font-weight-bold">
                <li className="nav-item px-4 active-tab">
                  <NavLink className="decoration-none" to="/projects">
                    Projects
                  </NavLink>
                </li>
                {this.state.showReels && (
                  <li className="nav-item px-4 inactive-tab">
                    <NavLink className="text-dark decoration-none" to="/reels">
                      Reels
                    </NavLink>
                  </li>
                )}
                {this.state.showCollections && (
                  <li className="nav-item px-4 inactive-tab">
                    <NavLink
                      className="text-dark decoration-none"
                      to="/collections"
                    >
                      Collections
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body p-0">
            <div className="card-text">
              <div className="row">
                <div className="col-lg-5">
                  <ProjectSearch refreshList={this.state.refreshList} />
                </div>
                <div className="col-lg-7">
                  <ProjectItems
                    canViewAddProjects={this.state.canViewAddProjects}
                    showReels={this.state.showReels}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Create a Project"
          show={this.state.showModal}
          flag={true}
          hide={() => this.setState({ showModal: false })}
        >
          <CreateProject
            closeModal={(refreshList) =>
              this.setState({ showModal: false }, () => {
                this.props.history.push("/projects")
                this.setState({ refreshList: Boolean(refreshList) })
              })
            }
          />
        </Modal>
      </div>
    )
  }
}

export default Projects
