import React, { Component } from 'react';
import { toast } from "react-toastify";
import { title } from "change-case"
import axios from "../../../configAxios";
import Spinner from "../../UI/Spinner"
import Input from "../../UI/input/Input"
import FormButton from "../../UI/button/FormButton";
import ColorPicker from "../../common/colorPicker/ColorPicker";
import ToggleSwitch from "../../common/toggleSwitch"

class InvoiceMessage extends Component {
   signal = axios.CancelToken.source();
   state = {
      loading: true,
      disabled: false,
      top: {
         content: {
            elementType: "textarea",
            elementConfig: {
               name: "content",
               type: "text",
               rows: 5
            },
            label: "Content",
            value: "",
         },
         id: "",
         toggle: false,
         settingType: "invoice_top_message",
         borderColor: "",
         borderWidth: {
            elementType: "input",
            elementConfig: {
               name: "borderWidth",
               type: "number",
               min: 1
            },
            label: "Border/Frame Width",
            value: ""
         },
         updated: false
      },
      bottom: {
         content: {
            elementType: "textarea",
            elementConfig: {
               name: "content",
               type: "text",
               rows: 5
            },
            label: "Content",
            value: "",
         },
         id: "",
         toggle: false,
         settingType: "invoice_bottom_message",
         borderColor: "",
         borderWidth: {
            elementType: "input",
            elementConfig: {
               name: "borderWidth",
               type: "number",
               min: 1
            },
            label: "Border/Frame Width",
            value: ""
         },
         updated: false
      },
      organizationId: null,  
   }

   componentDidMount() {
      axios.get("/pdf_settings/invoice_message", { cancelToken: this.signal.token }).then(res => {
         const { pdf_settings: pdfSettings } = res.data;
         const top = pdfSettings.filter(pdfSetting => pdfSetting.setting_type === "invoice_top_message")[0]
         const bottom = pdfSettings.filter(pdfSetting => pdfSetting.setting_type === "invoice_bottom_message")[0]
         const { organization_id: organizationId } = bottom || top
         if (bottom) {
            const { content: bottomContent, show: bottomToggle, id, parameters: { border_width: bottomBorderWidth, border_color: bottomBorderColor } } = bottom
            this.setState({
               bottom: {
                  ...this.state.bottom,
                  content: {
                     ...this.state.bottom.content,
                     value: bottomContent
                  },
                  id,
                  toggle: bottomToggle,
                  borderColor: bottomBorderColor,
                  borderWidth: {
                     ...this.state.bottom.borderWidth,
                     value: bottomBorderWidth
                  }
               }
            })
         }
         if (top) {
            const { content: topContent, show: topToggle, id, parameters: { border_width: topBorderWidth, border_color: topBorderColor } } = top
            this.setState({
               top: {
                  ...this.state.top,
                  content: {
                     ...this.state.top.content,
                     value: topContent
                  },
                  id,
                  toggle: topToggle,
                  borderColor: topBorderColor,
                  borderWidth: {
                     ...this.state.top.borderWidth,
                     value: topBorderWidth
                  }
               }
            })
         }
         this.setState({ 
            organizationId,
            loading: false
         });
      })
   }

   componentWillUnmount() {
      this.signal.cancel("Request is being cancelled");
   }

   toggleChangeHandler = (event, key) => {
      this.setState({
         [key]: {
            ...this.state[key],
            toggle: event
         }
      })
   }

   inputChangeHandler = (event, key, field) => {
      this.setState({
         [key]: {
            ...this.state[key],
            [field]: {
               ...this.state[key][field],
               value: event.target.value
            }
         }
      })
   }

   colorChangeHandler = (color, key) => {
      this.setState({ 
         [key]: {
            ...this.state[key],
            borderColor: color
         }
      })
   }

   updateMessage = async (key) => {
      const { content, id, toggle, settingType, borderColor, borderWidth } = this.state[key]
      const { value } = content
      if(!value || value.replace(/^\s+|\s+$/g, '') === ""){
         toast.error(`${title(key)} content Field is empty`);
         return;
      }
      if(!this.colorValidator(borderColor)){
         toast.error(`Choose a valid ${title(key)} color`);
         return;
      }
      if(borderWidth.value < 0){
         toast.error(`${title(key)} frame width can't be negative`);
         return;
      }

      const attrData = {}
      attrData["id"] = id;
      attrData["setting_type"] = settingType;
      attrData["content"] = content.value.replace(/(\r\n|\n|\r)/gm," ");
      attrData['show'] = toggle;
      attrData["border_color"] = borderColor;
      attrData["border_width"] = borderWidth.value ? borderWidth.value.toString() : 1;

      let updated = false;
      await axios.put(`/pdf_settings/${id}`, attrData).then(res => {
         if(res.data.json.success)
            updated = true;
         else{
            if (res.data.json.hasOwnProperty("errors")) {
               Object.keys(res.data.json.errors).forEach(error => {
                 toast.error(title(error) + " " + res.data.json.errors[error]);
               });
             }
             if (res.data.json.hasOwnProperty("error")) {
               toast.error(res.data.json.error);
             }
         }
         this.setState({
            disabled: false,
            [key]: {
               ...this.state[key],
               updated
            }
         });
      });
   }
      
   submitHandler = async (event) => {
      event.preventDefault();
      this.setState({ 
         disabled: true,
         top: {...this.state.top, updated: false},
         bottom: {...this.state.bottom, updated: false}
      });
      await this.updateMessage("top")
      await this.updateMessage("bottom")
      if (this.state.top.updated && this.state.bottom.updated)
         toast.success(`Invoice message setting successfully updated`);
   }

   colorValidator = (color) => {
      if(/^[0-9A-F]{6}$/i.test(color)){
         return true;
      } else if(/^([0-9A-F]{3}){1,2}$/i.test(color)){
         return true;
      }
      return false;
   }

   renderHelper = () => {
      const { top, bottom } = this.state
      if(this.state.loading)
         return (
            <div className="card-text">
            <div className="row">
               <div className="col-12">
                  <Spinner className="h-60vh" />
               </div>
            </div>
         </div>
         )
      return (
         <form onSubmit={this.submitHandler}>
            <div className="row invoice-message">
               <div className="col-lg-6 col-md-8">
                  <div className="p-3">
                     <div className="mb-4">
                        <ToggleSwitch
                           checked={top.toggle}
                           onChange={(e) => this.toggleChangeHandler(e, "top")}
                        />
                        <label className="ml-2 my-0" >
                           Add message in the top ?
                        </label>
                     </div>
                     <ColorPicker onColorChange={(color) => this.colorChangeHandler(color, "top")} value={top.borderColor} label="Frame Colour: "/>
                     <Input {...top.borderWidth} changed={event => this.inputChangeHandler(event, "top", "borderWidth")} />
                     <Input {...top.content} changed={event => this.inputChangeHandler(event, "top", "content")} />
                     <FormButton className="float-left mb-3" children={"Save"} disabled={this.state.disabled}/>
                  </div>
               </div>
               <div className="col-lg-6 col-md-8">
                  <div className="p-3">
                     <div className="mb-4">
                        <ToggleSwitch
                           checked={bottom.toggle}
                           onChange={(e) => this.toggleChangeHandler(e, "bottom")}
                        />
                        <label className="ml-2 my-0" >
                           Add message in the bottom ? 
                        </label>
                     </div>
                     <ColorPicker onColorChange={(color) => this.colorChangeHandler(color, "bottom")} value={bottom.borderColor} label="Frame Colour: "/>
                     <Input {...bottom.borderWidth} changed={event => this.inputChangeHandler(event, "bottom", "borderWidth")} />
                     <Input {...bottom.content} changed={event => this.inputChangeHandler(event, "bottom", "content")} />
                  </div>
               </div>
            </div>
         </form>
      )
   }

   render() {
      return this.renderHelper()
   }
}

export default InvoiceMessage;