import React, { Component } from "react";
import Input from "../UI/input/Input";

class Tags extends Component {
  render() {
    return (
      <div className="mb-2">
        <button
          className="btn"
          onClick={() => this.props.tabHandler("tags")}
          type="button"
          data-toggle="collapse"
          data-target=".tags"
          aria-expanded="false"
          aria-controls="multiCollapseExample1 multiCollapseExample2"
          style={{
            width: "100%",
            backgroundColor: "#f5efef",
            outline: "none",
            boxShadow: "none"
          }}
        >
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Labels</p>
            <i
              className={
                this.props.selectedTabs.includes("tags")
                  ? "fas fa-minus-circle text-primary fa-2x"
                  : "fas fa-plus-circle text-primary fa-2x"
              }
            />
          </div>
        </button>
        <div className="row">
          <div className="col">
            <div className="collapse tags" id="multiCollapseExample1">
              <div className="mt-4">
                <div className="row">
                  <div className="col-12">
                    <Input
                      {...this.props.tags}
                      changed={this.props.inputTagsChangeHandler}
                      tags={true}
                      chart={this.props.tagCharacters}
                      onInputChange={this.props.onInputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {this.props.tagsList}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Tags;
