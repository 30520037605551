import React, { Component } from "react"
import axios from "../../../../configAxios"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"
import querystring from "query-string"
import HelcimForm from "./HelcimForm"
import Spinner from "../../../UI/Spinner"
import "./index.css"

class HelcimPayment extends Component {
  signal = axios.CancelToken.source();
  state = {
    invoice: null,
    loading: true,
    canModifyTotalAmount: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    const HELCIM_APPROVED_TRANSACTION_RESPONSE_CODE = "1";
    this.setState({ loading: true });
    if (!isEmpty(this.props.location.search)) {
      const paramsObj = querystring.parse(this.props.location.search);
      if (paramsObj["response"] === HELCIM_APPROVED_TRANSACTION_RESPONSE_CODE) {
        this.props.history.push({
          pathname: `/invoices/${this.props.match.params.id}`,
          state: {
            fromPaymentPage: true,
            paymentSuccess: true,
            responseMessage: paramsObj["responseMessage"],
            response: paramsObj["response"],
            transactionId: paramsObj["transactionId"],
            invoiceId: this.props.match.params.id
          }
        })
      } else {
        this.props.history.push({
          pathname: `/invoices/${this.props.match.params.id}`,
          state: {
            fromPaymentPage: true,
            paymentSuccess: false,
            responseMessage: paramsObj["responseMessage"],
            response: paramsObj["response"],
            transactionId: "",
            invoiceId: this.props.match.params.id
          }
        })
      }
    }
    axios
      .get(`/invoices/online_payment`, {
        params: {
          id: this.props.match.params.id
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        const invoice = res.data.json
        if (parseFloat(invoice.total_outstanding.split("$")[1]) > 0 && invoice.online_payment_enabled) {
          this.setState({
            invoice,
            loading: false
          });
        } else {
          toast.error("Online payment not supported");
          this.props.history.push(`/invoices/${this.props.match.params.id}`);
        }
      });
    this.getModifyPaymentAmount()
  }

  getModifyPaymentAmount = () => {
    axios
      .get("/invoices/can_modify_payment_amount", {
        params: { id: this.props.match.params.id},
      })
      .then((res) => {
        this.setState({
          canModifyTotalAmount: res.data.json.allow_users_to_modify_payment_amount,
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  render() {
    const { invoice } = this.state;
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (<div className="helcim-payment">
          <div className="container">
            <div className="row">
            </div>
            <div className="mt-5">
              <HelcimForm
                totalOutStanding={invoice.total_outstanding}
                helcimToken={invoice.organization.helcim_token}
                reference={invoice.reference}
                canModifyTotalAmount={this.state.canModifyTotalAmount}
              />
            </div>
          </div>
        </div>
          )}
      </div>
    );
  }
}

export default HelcimPayment;
