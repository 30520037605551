import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case"
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";

class TransferAmmount extends Component {
  state = {
    tranferBalance: {
      elementType: "input",
      elementConfig: {
        name: "tranferBalance",
        type: "number",
        required: true
      },
      value: ""
    },
    disabled: false
  };

  inputChangedHandler = event => {
    let tranferBalance = { ...this.state.tranferBalance };
    tranferBalance["value"] = event.target.value;
    this.setState({ tranferBalance: tranferBalance });
  };

  submitHandler = (e) => {
    e.preventDefault()

    this.setState({ disabled: true })
    const queryParams = {
      id: this.props.accountId,
      transfer_amount: parseInt(this.state.tranferBalance.value)
    };
    axios
      .post("/accounts/transfer_balance", queryParams)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Transfered Successfully");
          this.props.closeModal();
          this.props.getAccountDetails();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      })
      .catch(err => err);
  };

  render() {
    return (
      <div>
        <div>
          <div>
            <p className="lead mt-3">
              This is temporary functionality to tranfer an account balance from 
              the old system.Enter the full amount(tax included); the system
              automatically calculate the amount that should be attributed to
              GST.A General invoice will be created to contain the transfer
              information.
            </p>
          </div>
          <form onSubmit={this.submitHandler}>
            <div className="mt-3">
              <p className="font-weight-bold">Transfer Amount:</p>
              <Input
                {...this.state.tranferBalance}
                changed={event => this.inputChangedHandler(event)}
              />
            </div>
            <div className="d-flex justify-content-center">
              <Button
                type="secondary"
                className="mr-3"
                clicked={this.props.closeModal}
              >
                Cancel
              </Button>
              <FormButton disabled={this.state.disabled}>
                Submit
              </FormButton>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default TransferAmmount;
