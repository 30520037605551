import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import { clearResponse, createGrantCampaign } from "../../store/actions/index";
import DateInput from "./DateInput"
import StatusSelect from "./StatusSelect"
import ToggleSwitch from "../common/toggleSwitch";

class CreateCampaign extends Component {
  timeout = 0;
  state = {
    createCampaign: {
      campaignName: {
        name: "campaignName",
        elementType: "input",
        elementConfig: {
          name: "campaign_name",
          type: "text",
          required: true
        },
        value: "",
        label: "Campaign Name"
      },
      campaignDescription: {
        name: "campaignDescription",
        elementType: "textarea",
        elementConfig: {
          name: "campaign_description",
          type: "text",
          rows: 4,
          required: true
        },
        value: "",
        label: "Campaign Description"
      },
      campaignStatus: "Draft",
      campaignDeadline: {
        name: "campaignDeadline",
        value: "",
        label: "Application Deadline"
      },
      campaignMultipleApplications: false,
      onlyActiveMembers: false
    },
    showModal: true,
    enteredUserCharacters: "",
    disabled: false
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.response.hasOwnProperty("success") &&
      nextProps.response.success !== null && !this.props.response.loading
    ) {
      const { message, success } = nextProps.response;
      this.props.clearResponse();
      if (success) {
        this.props.closeModal(true);
        this.props.history.push("/campaigns");
        toast.success(message);
      } else {
        toast.error(message);
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  closeModal = () => {
    this.props.closeModal();
  };

  inputChangedHandler = (event, key, formName) => {
    event.preventDefault()
    let inputValue = {};
    inputValue.value = event.target.value;

    const updatedControls = {
      ...this.state[formName],
      [key]: {
        ...this.state[formName][key],
        ...inputValue
      }
    };
    this.setState({ [formName]: updatedControls });
  };

  setStatus = (status) => {
    this.setState({ createCampaign: { ...this.state.createCampaign, campaignStatus: status } })
  }

  toggleMultiSubmission() {
    this.setState({ createCampaign: { ...this.state.createCampaign, campaignMultipleApplications: !this.state.createCampaign.campaignMultipleApplications }})
  }

  toggleOnlyActiveMembers() {
    this.setState({ createCampaign: { ...this.state.createCampaign, onlyActiveMembers: !this.state.createCampaign.onlyActiveMembers }})
  }

  submitHandler = event => {
    const { createGrantCampaign, getGrantCampaigns, history } = this.props
    const {
      campaignName,
      campaignDescription,
      campaignDeadline,
      campaignStatus,
      campaignMultipleApplications,
      onlyActiveMembers
    } = this.state.createCampaign

    event.preventDefault();

    this.setState({ disabled: true });

    const campaignData = {
      name: campaignName.value,
      description: campaignDescription.value,
      deadline: campaignDeadline.value,
      status: campaignStatus,
      allow_multiple_applications: campaignMultipleApplications,
      only_active_members: onlyActiveMembers
    };

    createGrantCampaign(campaignData, getGrantCampaigns, history)
  };

  render() {
    const { campaignName, campaignDescription, campaignDeadline, campaignStatus, campaignMultipleApplications, onlyActiveMembers } = this.state.createCampaign;

    const createCampaignForm = (
      <>
        <Input
          key={campaignName.name}
          {...campaignName}
          changed={event =>
            this.inputChangedHandler(event, campaignName.name, "createCampaign")
          }
        />
        <Input
          key={campaignDescription.name}
          {...campaignDescription}
          changed={event =>
            this.inputChangedHandler(event, campaignDescription.name, "createCampaign")
          }
        />
        <StatusSelect status={campaignStatus} setStatus={this.setStatus} />
        <DateInput
          stateObject={campaignDeadline}
          onChangeFunction={e => this.inputChangedHandler(e, "campaignDeadline", "createCampaign")}
          invalidMessage="Please set a deadline"
        />
        <div className="my-3 d-flex align-items-center">
          <ToggleSwitch
            checked={campaignMultipleApplications}
            onChange={() => this.toggleMultiSubmission()}
            disabled={this.state.disabled}
          />
          <label className="ml-2 my-0">
            Allow users to submit multiple applications?
          </label>
        </div>
        <div className="my-3 d-flex align-items-center">
          <ToggleSwitch
            checked={onlyActiveMembers}
            onChange={() => this.toggleOnlyActiveMembers()}
            disabled={this.state.disabled}
          />
          <label className="ml-2 my-0">
            Only allow applications from active members?
          </label>
        </div>

      </>
    );

    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        {createCampaignForm}
        <div className="text-right mb-1">
          <span
            className="text-primary cursor"
            onClick={() =>
              this.setState({
                createCampaign: { ...this.state.createCampaign }
              })
            }
          />
        </div>
        <div className="text-center mt-3">
          <Button type="secondary" clicked={() => this.props.closeModal()}>
            Cancel
          </Button>
          <FormButton className="ml-2" disabled={this.state.disabled}>
            Create Campaign
          </FormButton>
        </div>
      </form>
    );
  }
}

CreateCampaign.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  createGrantCampaign: PropTypes.func.isRequired,
  clearResponse: PropTypes.func.isRequired,
  response: PropTypes.shape({
    loading: PropTypes.bool,
    message: PropTypes.string,
    success: PropTypes.bool
  }).isRequired
};

const mapStateToProps = state => {
  return {
    response: state.response,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createGrantCampaign: (projectData, callback, history) =>
      dispatch(createGrantCampaign(projectData, callback, history)),
    clearResponse: () => dispatch(clearResponse())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateCampaign));
