import React from "react"
import { flatten, sortBy } from "lodash"
import { orderedTagOptions } from "../shared/groupTagOptions"

const Tag = ({ setTags, tagOptions, setTagOptions, tagInfo}) => {
  const deleteTag = (e) => {
    e.preventDefault()

    const filteredOptions = flatten(tagOptions.map( o => o.options))
    const unformattedTagOptions = [...filteredOptions, tagInfo]

    setTags((prevState) => prevState.filter(tag => tag.value !== tagInfo.value))
    setTagOptions(orderedTagOptions(sortBy(unformattedTagOptions, 'label')))
  }

  return(
    <div className="tag word-wrap-break">
      <div className="tag-name">{tagInfo.label}</div>
      <button
        className="tag-delete"
        onClick={deleteTag}
      >
        x
      </button>
    </div>
  )
}

export default Tag
