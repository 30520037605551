import React, { Component } from 'react';
import { toast } from "react-toastify";
import { title } from "change-case";
import classNames from "classnames";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Pagination from "../UI/Pagination";

import RentalItem from './RentalItem';

import './RentalRequests.scss';

class RentalRequests extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: false,
    tickets: [],
    pagination: {
      currentPage: 0,
      totalCount: 0,
    },
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.updateData()
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.pagination.currentPage !== this.state.pagination.currentPage) {
      this.updateData();
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  updateData = () => {
    this.setState({ ...this.state, loading: true })
    let params = {
      page: this.state.pagination.currentPage + 1,
      full_parameters: true,
      include: {
        policy: true
      }
    };
    axios.get('/tickets', { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          pagination: {
            ...this.state.pagination,
            totalCount: res.data.meta.total_count
          },
          tickets: res.data.tickets.filter(ticket => ticket.is_pending_approval),
          loading: false
        })
      })
      .catch(error => error)
  }

  changeStatus = (id, status) => {
    let data = {
      id: id,
      status: status === 'approve' ? 1 : 2
      // status = 1 for approve, 2 for reject
    }
    axios.put('/tickets/update_status', data)
      .then(res => {
        if (res.data.json.success) {
          toast.success(`Rental Request ${status === 'approve' ? 'Approved' : 'Rejected'}`);
          this.setState({
            ...this.state,
            tickets: this.state.tickets.map(ticket => {
              if (ticket.id === id) {
                ticket = res.data.json.data
              }
              return ticket
            })
          })
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      })
      .catch(error => error)
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState({
      ...this.state,
      pagination: {
        ...this.state.pagination,
        currentPage: page.selected
      }
    })
  }

  render() {
    const { fromAssetsPage } = this.props;
    let { totalCount, currentPage } = this.state.pagination;
    let perPage = 10;

    let from = (perPage * (currentPage + 1));
    from -= perPage;
    from++;
    let to = perPage * (currentPage + 1);
    if (to > totalCount) {
      to = totalCount;
    }

    let showingContent = `Showing ${from} to ${to} of ${totalCount} entries`;
    
    return (
      <div className={classNames(
          "rental-requests", 
          { "w-100 container": !fromAssetsPage }
        )}
      >
          <div className="row mb-3">
            <div className="col-12">
              <h3 className={classNames({ 
                  "mx-3 mb-3 mt-4 pt-3": fromAssetsPage 
                })}
              >
                Rental Requests
              </h3>
            </div>
          </div>
        {
          this.state.loading ?
            <div className={classNames({"card col-12 text-center d-flex justify-content-center": !fromAssetsPage})}>
              <Spinner className="h-60vh" />
            </div>
            : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className={classNames({ "card-body p-0": !fromAssetsPage })}>
                      <div className={classNames({ 
                          "card-text": !fromAssetsPage,
                          "row": fromAssetsPage
                        })}
                      >
                        <div className={classNames( "col-12", { "py-0": !fromAssetsPage })}>
                          {
                            this.state.tickets.length === 0 ?
                              <div
                                className="text-muted text-center w-100 d-flex justify-content-center flex-column"
                                style={{
                                  minHeight: '40vh'
                                }}>
                                <h3>
                                  No Pending Rental Requests
                                  <h2>
                                    <i className="fas fa-times m-2 cursor"></i>
                                  </h2>
                                </h3>
                              </div> : ''
                          }
                          <div className="list-group list-group-flush">
                            {
                              this.state.tickets.map(ticket => (
                                <RentalItem key={ticket.id} ticket={ticket} changeStatus={this.changeStatus} />
                              ))
                            }
                          </div>

                          <div className="row align-items-center">
                            <div className="col-lg-6">
                              <p className={classNames({ 
                                  "my-3": fromAssetsPage,
                                  "mb-0": !fromAssetsPage
                                })}
                              >
                                <strong className="ml-3">
                                  {showingContent}
                                </strong>
                              </p>
                            </div>
                            <div className="col-lg-6">
                              <Pagination
                                pageCount={this.state.pagination.totalCount / 10}
                                currentPage={this.state.pagination.currentPage}
                                handlePageClick={this.paginationHandler} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
        }
      </div>
    )
  }
}

export default RentalRequests;