import React from 'react'
import { OverlayTrigger, Popover } from "react-bootstrap";
import classnames from "classnames";

function AttributeListItem({item, shouldShowId, editModal, deleteHandler}) {
  return (
      <div className="row">
        {
          shouldShowId()
            ?
            <>
              <div className="col-lg-1">
                <OverlayTrigger key={"top"} placement={"top"}
                  overlay={
                    <Popover>
                      <div>
                        {"Attribute Id: "}
                        <span className="font-weight-bold">{item.id}</span>
                      </div>
                    </Popover>
                  }
                >
                  <i
                    className="fa fa-info-circle text-muted cursor"
                    aria-hidden="true"
                  />
                </OverlayTrigger>
              </div>
              <div className="col-lg-5">{item.name}</div>
            </>
            :
            <>
              <div className="col-lg-6">{item.name}</div>
            </>
        }


        <div className="col-lg-2">
          <i
            className={classnames("fas fa-check-circle", {
              "text-info": item.visible,
              "text-secondary element-disabled": !item.visible
            })}
          />
        </div>
        <div className="offset-lg-2 col-lg-2">
          <div className="d-flex justify-content-around align-items-center px-2">
            <i
              className="fas fa-pencil-alt cursor mr-2"
              onClick={() => editModal(item.id)}
            />
            <i
              className={classnames(
                "fas fa-trash ml-3",
                {
                  "cursor": item.can_delete,
                  "cursor-not-allowed element-disabled": !item.can_delete
                }
              )}
              {...item.can_delete && {
                onClick: () => deleteHandler(item.id, item.name)
              }}
            />
          </div>
        </div>
      </div>
  )
}

export default AttributeListItem
