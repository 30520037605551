import React, { Component } from 'react'

import ShowCampaignInfo from "./ShowCampaignInfo";
import CampaignItem from "./CampaignItem"

import Modal from "../UI/Modal";

class CampaignList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCampaignModal: false,
      showCampaignDetails: null
    }
  }

  campaignInfoHandler = (campaignDetail) => {
    if (campaignDetail && Object.keys(campaignDetail).length > 0)
      this.setState({
        showCampaignDetails: campaignDetail,
        showCampaignModal: true
      });
  }

  render() {
    const { campaigns } = this.props;
    return (
      <>
        {campaigns && campaigns.length
          ? <>
            {campaigns.slice(0, 5).map(campaign => (
              <CampaignItem
                status={campaign.status}
                startTime={campaign.start_date}
                endTime={campaign.end_date}
                name={campaign.name}
                campaignInfoHandler={() => this.campaignInfoHandler(campaign)}
              />
            ))}
            <Modal
              closeButton
              show={this.state.showCampaignModal}
              hide={e => this.setState({ showCampaignModal: false })}
              closeModal={e => this.setState({ showCampaignModal: false })}
              title="Campaign Details"
            >
              {
                this.state.showCampaignDetails &&
                <ShowCampaignInfo showCampainDetails={this.state.showCampaignDetails} />
              }
            </Modal>
          </>
          : <div className="text-center my-4">No Campaigns Found</div>
        }
      </>
    )
  }
}

export default CampaignList
