import React, { Component } from "react"
import axios from "../../../configAxios"
import { formatDate } from "../../../utils/formatDate"
import { title } from "change-case"
import _ from "lodash"
import { toast } from "react-toastify"

import AssetDetailsModal from "./AssetDetailsModal"
import BookedAsset from "./BookedAsset"
import CreateProject from "../../projects/CreateProject"
import Button from "../../UI/button/Button"
import Input from "../../UI/input/Input"
import Modal from "../../UI/Modal"


class BookedAssets extends Component {
  signal = axios.CancelToken.source()
  state = {
    ticketNotes: {
      elementType: "textarea",
      elementConfig: {
        name: "ticketNotes",
        rows: "4",
      },
      value: "",
    },
    projectName: {
      elementType: "select",
      elementConfig: {
        name: "projectName",
        placeholder: "Select project",
        options: [],
        isLoading: true,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
    },
    programRental: {
      elementType: "select",
      elementConfig: {
        name: "programRental",
        options: [],
        isLoading: true,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
    },
    chargedLoanPeriod: {
      elementType: "input",
      elementConfig: {
        name: "quantity",
        type: "number",
      },
      value: "",
    },
    memberType: {
      elementType: "select",
      elementConfig: {
        name: "memberType",
        placeholder: "Select Option",
        required: true,
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
    },
    showEmailModal: false,
    selectedBookings: [],
    showChargedLoanModal: false,
    showCreateModal: false,
    chargedLoanPeriodButton: true,
    projectsCount: 0,
    policyRes: {},
    manageBookings: this.props.manageBookings,
    disableSave: this.props.disabled,
    openAssetModal: false,
    modalAssetForDisplay: null
  }

  componentDidMount() {
    const { programRental, memberType } = this.state
    const { bookedAssets } = this.props
    let programName

    axios
      .get("/registrations", {
        params: {
          user_id: this.props.userId,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const { registrations } = res.data
        let selectedOption
        if (bookedAssets.no_charge_enrolled_in_program_id) {
          programName = registrations.filter(
            (program) =>
              program.program_id ===
              bookedAssets.no_charge_enrolled_in_program_id
          )[0]
          selectedOption = {
            label: programName.program_name,
            value: programName.program_id,
          }
        }
        this.setState({
          programRental: {
            ...programRental,
            elementConfig: {
              ...programRental.elementConfig,
              options: registrations.map((program) => {
                return {
                  label: program.program_name,
                  value: program.program_id,
                }
              }),
              isLoading: false,
            },
            selectedOption,
          },
          ticketNotes: {
            ...this.state.ticketNotes,
            value: this.props.ticketNotes,
          },
        })
      })
      .catch((error) => error)
    axios
      .get("/bookings/formdata", { cancelToken: this.signal.token })
      .then((res) => {
        this.setState({
          memberType: {
            ...memberType,
            elementConfig: {
              ...memberType.elementConfig,
              options: res.data.json.asset_price_types.map((type) => {
                return { value: type[0], label: type[1] }
              }),
            },
          },
        })
      })
    this.loadUsersProjects()
    axios
      .get("/tickets/policy", {
        params: {
          id: this.props.ticketId,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({ policyRes: res.data.json })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ disableSave: nextProps.disabled })
  }

  loadUsersProjects = () => {
    axios
      .get("/users/projects", {
        params: {
          id: this.props.userId,
          type: "all",
          booking: true,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const { users } = res.data
        let selectedOption
        if (
          this.props.bookedAssets.project_name &&
          this.props.bookedAssets.project_name !== "No Project Name Given"
        ) {
          const project = users.filter(
            (project) => project.name === this.props.bookedAssets.project_name
          )[0]
          selectedOption = { label: project.name, value: project.id }
        } else {
          selectedOption = this.state.projectName.selectedOption
        }
        this.setState({
          projectName: {
            ...this.state.projectName,
            elementConfig: {
              ...this.state.projectName.elementConfig,
              options: users.map((project) => {
                return { label: project.name, value: project.id }
              }),
              isLoading: false,
            },
            selectedOption,
          },
          projectsCount: res.data.meta.total_count,
        })
      })
      .catch((err) => console.log(err))
  }

  updateTicketHandler = (sendEmail) => {
    const { projectName, programRental, ticketNotes } = this.state
    this.setState({ showEmailModal: false, disableSave: true })
    const formData = {
      send_email: sendEmail,
    }

    if (projectName.selectedOption) {
      formData.project_name = projectName.selectedOption.name
      formData.project_id = projectName.selectedOption.value
    }

    formData.notes = ticketNotes.value
    if (
      !_.isEmpty(programRental.selectedOption) &&
      programRental.selectedOption.value
    ) {
      formData.no_charge_enrolled_in_program_id =
        programRental.selectedOption.value
    } else {
      formData.no_charge_enrolled_in_program_id = null
    }

    axios
      .put(`/tickets/${this.props.ticketId}`, formData)
      .then((res) => {
        if (res.data.json.success) {
          this.setState({
            projectName: {
              ...this.state.projectName,
              value: "",
            },
            ticketNotes: {
              ...this.state.ticketNotes,
              value: "",
            },
            programRental: {
              ...this.state.programRental,
              selectedOption: null,
            },
          })
          toast.success("Booking has successfully updated")
          this.props.tabChangeHandler("Summary", null, this.props.userId)
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
      .catch(() => toast.error("Failed to update"))
  }

  deleteTicketHandler = () => {
    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      axios.delete(`/tickets/${this.props.ticketId}`).then((res) => {
        if (res.data.json.success) {
          toast.success("Ticket destroyed Successfully")
          this.props.tabChangeHandler("Summary", null, this.props.userId)
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
    }
  }

  checkedAssetHandler = (isChecked, assetId) => {
    const selectedBookings = [...this.state.selectedBookings]
    if (isChecked) {
      selectedBookings.push(assetId)
      const selectedAssets = [].concat(...selectedBookings)
      this.setState({ selectedBookings: selectedAssets })
    } else {
      const selectedAssets = selectedBookings.filter(
        (el) => !assetId.includes(el)
      )
      this.setState({ selectedBookings: selectedAssets })
    }
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {}
    if (
      key === "programRental" ||
      key === "projectName" ||
      key === "memberType"
    ) {
      inputValue.selectedOption = event
    } else {
      inputValue.value = event.target.value
    }
    const updateControls = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState(
      {
        [key]: updateControls,
      },
      () => {
        this.setState({
          chargedLoanPeriodButton: !(
            this.state.memberType.selectedOption ||
            this.state.chargedLoanPeriod.value !== ""
          ),
        })
      }
    )
  }

  selectBookingHandler = (select) => {
    const { bookings } = this.props.bookedAssets
    let selectedBookings = []
    if (select) {
      const selectedAssets = []
      bookings &&
        bookings.forEach((booking) => {
          selectedAssets.push(booking.booking_ids)
          selectedBookings = [].concat(...selectedAssets)
        })
    }
    this.setState({ selectedBookings })
  }

  updateChargedLoanPeriod = (event, update) => {
    this.setState({ chargedLoanPeriodButton: true })
    event.preventDefault()
    const { selectedBookings, chargedLoanPeriod } = this.state
    const formData = {
      ticket_id: this.props.ticketId,
      booking_ids: `[${selectedBookings}]`,
      new_charged_loan_period: update
        ? parseFloat(chargedLoanPeriod.value)
        : null,
      asset_price_type_id:
        this.state.memberType.selectedOption &&
        this.state.memberType.selectedOption.value,
    }
    axios
      .put("/bookings/update_charged_loan_periods_for", formData)
      .then((res) => {
        if (res.data.json.success) {
          toast.success("Charged loan period updated successfully")
          this.setState({
            showChargedLoanModal: false,
            chargedLoanPeriod: {
              ...this.state.chargedLoanPeriod,
              value: "",
            },
            memberType: {
              ...this.state.memberType,
              selectedOption: null,
            },
            selectedBookings: [],
            chargedLoanPeriodButton: true,
          })
          this.props.getTicket()
        } else {
          if ("errors" in res.data.json) {
            Object.keys(res.data.json.errors).forEach((key) => {
              toast.error(title(key) + " " + res.data.json.errors[key])
            })
          }
          if ("error" in res.data.json) {
            toast.error(res.data.json.error)
          }
          this.setState({ chargedLoanPeriodButton: false })
        }
      })
      .catch(() => toast.error("Something went wrong"))
  }

  destroyBookings = () => {
    const { selectedBookings } = this.state
    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      axios
        .delete("/tickets/destroy_bookings_for", {
          data: {
            id: this.props.ticketId,
            booking_ids: `[${selectedBookings}]`,
          },
        })
        .then((res) => {
          if (res.data.json.success) {
            toast.success(res.data.json.data)
            this.props.getTicket()
            this.setState({ selectedBookings: [] })
          }
        })
    }
  }

  setProjectName = (projectData, id) => {
    this.loadUsersProjects()
    const inputValue = {}
    if (id === parseInt(this.props.userId)) {
      inputValue.selectedOption = projectData
      this.setState({
        projectName: {
          ...this.state.projectName,
          ...inputValue,
        },
      })
    }
  }

  closeChargedLoanPeriodModal = () => {
    this.setState({
      showChargedLoanModal: false,
      chargedLoanPeriodButton: true,
      memberType: {
        ...this.state.memberType,
        selectedOption: null,
      },
      chargedLoanPeriod: {
        ...this.state.chargedLoanPeriod,
        value: "",
      },
    })
  }

  onModalToggle = () => {
    this.setState((prevState) => {
      return { openAssetModal: !prevState.openAssetModal }
    })
  }

  setDisplayAsset = (asset) => {
    this.setState({ modalAssetForDisplay: asset })
  }


  render() {
    const { selectedBookings, policyRes } = this.state
    const { bookedAssets } = this.props
    const formattedDate = `${formatDate(
      new Date(bookedAssets.created_at),
      "YYYY/MM/DD @ hh:mm A"
    )}`

    const emailModal = (
      <Modal
        show={this.state.showEmailModal}
        center
        closeModal={() => this.setState({ showEmailModal: false })}
        hide={() => this.setState({ showEmailModal: false })}
        closeButton
      >
        <div className="col-md-12 mt-4">
          <p className="text-center font-weight-bold word-wrap-break">
            Would you like to send confirmation email to{" "}
            {bookedAssets.full_name_cached}?
          </p>
          <div className="text-center mt-4">
            <Button
              type="success"
              className="mr-2"
              style={{ width: "80px" }}
              clicked={() => this.updateTicketHandler(true)}
            >
              Yes
            </Button>
            <Button
              type="delete"
              style={{ width: "80px" }}
              clicked={() => this.updateTicketHandler(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    )

    const ChargedLoanModal = (
      <Modal
        show={this.state.showChargedLoanModal}
        center
        title="Edit Charged Loan Period"
        hide={() => this.setState({ showChargedLoanModal: false })}
        closeModal={() => this.setState({ showChargedLoanModal: false })}
        closeButton
      >
        <div className="mt-3">
          <div>
            <label>Charged Loan Period</label>
            <Input
              {...this.state.chargedLoanPeriod}
              changed={(e) => this.inputChangedHandler(e, "chargedLoanPeriod")}
            />
          </div>
          <div>
            <label>Asset Price Type</label>
            <Input
              {...this.state.memberType}
              changed={(e) => this.inputChangedHandler(e, "memberType")}
            />
          </div>
          <div className="d-flex justify-content-center my-2 col-md-12">
            <Button
              type="primary"
              style={{ width: "80px" }}
              clicked={(event) => this.updateChargedLoanPeriod(event, true)}
              disabled={this.state.chargedLoanPeriodButton}
            >
              Save
            </Button>
            <Button
              type="delete"
              style={{ width: "80px", marginLeft: "10px" }}
              clicked={(event) => this.updateChargedLoanPeriod(event, false)}
            >
              Remove
            </Button>
            <Button
              type="secondary"
              style={{ width: "80px", marginLeft: "10px" }}
              clicked={() => this.closeChargedLoanPeriodModal()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    )

    let bookedAssetContent
    if (bookedAssets && bookedAssets.bookings && bookedAssets.bookings.length) {
      bookedAssetContent = bookedAssets.bookings.map((booking) => (
        <BookedAsset
          canEditBookings={policyRes.can_edit_bookings}
          key={booking.id}
          booking={booking}
          loanPeriod={booking.details}
          total={bookedAssets.total}
          getTicket={this.props.getTicket}
          ticketId={this.props.ticketId}
          checked={selectedBookings.indexOf(booking.id) !== -1}
          clicked={this.checkedAssetHandler}
          onModalToggle={this.onModalToggle}
          setDisplayAsset={this.setDisplayAsset}
        />
      ))
    }

    return (
      <div className="row">
        <div className="col-md-12 booking">
          <div className="mt-1">
            <div className="row no-gutters">
              <div className="col-lg-7">
                <Button
                  type="success"
                  className="mr-2"
                  style={{
                    width: "80px",
                    height: "35px",
                    cursor: this.state.manageBookings ? "pointer" : "no-drop",
                  }}
                  clicked={() => {
                    window.scrollTo(0, 0)
                    this.setState({ showEmailModal: true })
                  }}
                  disabled={
                    this.state.disableSave || !this.state.manageBookings
                  }
                >
                  Save
                </Button>
                {policyRes.can_delete && policyRes.is_persisted && (
                  <Button
                    disabled={!this.state.manageBookings}
                    type="delete"
                    style={{
                      width: "80px",
                      height: "35px",
                      cursor: this.state.manageBookings ? "pointer" : "no-drop",
                    }}
                    clicked={this.deleteTicketHandler}
                  >
                    Destroy
                  </Button>
                )}
              </div>
              <div className="col-lg-5 word-wrap-break text-lg-right text-center">
                <p className="total-text">
                  Total <span>{bookedAssets && bookedAssets.total}</span>
                </p>
              </div>
            </div>
            {/* {this.state.projectsCount > 0 && bookedAssets.ticketable && */}
            <>
              <div className="d-flex justify-content-between mt-3 mb-2">
                <div>Project Name:</div>
                <div
                  className="cursor font-weight-bold text-primary"
                  onClick={
                    this.state.manageBookings
                      ? () => {
                          window.scrollTo(0, 0)
                          this.setState({ showCreateModal: true })
                        }
                      : null
                  }
                  style={{
                    cursor: this.state.manageBookings ? "pointer" : "no-drop",
                  }}
                >
                  Create Project
                </div>
              </div>
              <div>
                <Input
                  {...this.state.projectName}
                  changed={(e) => this.inputChangedHandler(e, "projectName")}
                />
              </div>
              <div className="mt-3">
                <div className="mb-3">Program Rental</div>
                <Input
                  {...this.state.programRental}
                  changed={(e) => this.inputChangedHandler(e, "programRental")}
                />
              </div>
            </>
            {/* } */}
            <div className="mt-3">
              <div className="mb-3">Ticket Notes:</div>
              <Input
                {...this.state.ticketNotes}
                changed={(e) => this.inputChangedHandler(e, "ticketNotes")}
              />
            </div>

            <div className="d-flex justify-content-between">
              {policyRes.can_edit_bookings && (
                <div>
                  <p className="text-primary cursor">
                    <span
                      onClick={
                        this.state.manageBookings
                          ? () => this.selectBookingHandler(true)
                          : null
                      }
                    >
                      Select all
                    </span>
                    <span
                      style={{ fontSize: 24, position: "relative", top: 3 }}
                    >
                      {" "}
                      /{" "}
                    </span>
                    <span
                      onClick={
                        this.state.manageBookings
                          ? () => this.selectBookingHandler(false)
                          : null
                      }
                    >
                      Deselect all
                    </span>
                  </p>
                </div>
              )}
              <div className="mt-1">
                {policyRes.can_edit_bookings && (
                  <Button
                    className="mr-1 btn-link"
                    title="Remove selected bookings"
                    clicked={this.destroyBookings}
                    disabled={
                      !(
                        selectedBookings &&
                        selectedBookings.length &&
                        this.state.manageBookings
                      )
                    }
                  >
                    <i className="fas fa-trash cursor text-dark" />
                  </Button>
                )}
                {policyRes.can_remove_bookings && (
                  <Button
                    className="ml-1 btn-link"
                    title="Edit selected charge periods"
                    clicked={() => {
                      window.scrollTo(0, 0)
                      this.setState({ showChargedLoanModal: true })
                    }}
                    disabled={
                      !(
                        selectedBookings &&
                        selectedBookings.length &&
                        this.state.manageBookings
                      )
                    }
                  >
                    <i className="fas fa-pencil-alt text-dark" />
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div>
            <p>
              <span className="text-gray-300">Ticket #{this.props.ticketId} created on:</span>
              <br />
              <span>{formattedDate}</span>
            </p>
            <div>{bookedAssetContent}</div>
            {emailModal}
            {ChargedLoanModal}
          </div>
          <Modal
            title="Create a Project"
            show={this.state.showCreateModal}
            hide={() => this.setState({ showCreateModal: false })}
          >
            <CreateProject
              isUserBooking={true}
              userData={this.props.userData}
              setProjectName={this.setProjectName}
              closeModal={() => this.setState({ showCreateModal: false })}
            />
          </Modal>
          <AssetDetailsModal
            show={this.state.openAssetModal}
            hide={this.onModalToggle}
            asset={this.state.modalAssetForDisplay}
          />
        </div>
      </div>
    )
  }
}

export default BookedAssets
