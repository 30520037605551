import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { title, snakeCase } from "change-case"

import axios from "../../configAxios"
import langUtil from "../../utils/langUtils"

import Members from "../members"
import Experts from "../experts"

import "./friends.css"

class Friends extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab:
        typeof this.props.match.params.tab !== "undefined"
          ? title(this.props.match.params.tab)
          : "Members",
      canViewAddFriend: null,
      experts: null,
      isAdmin: null,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.handleTabFromProp()
    window.addEventListener("popstate", this.handleTabFromProp)
    this.getUserPolicy()
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.tab !== prevProps.match.params.tab) {
      const activeTab = title(this.props.match.params.tab)
      this.tabChangedHandler(activeTab)
    }
  }

  getUserPolicy = () => {
    axios.get("/users/policy").then((res) => {
      this.setState({
        canViewAddFriend: res.data.json.can_view_add_friend,
        experts: res.data.json.experts,
        isAdmin: res.data.json.is_admin,
      })
    })
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.tab !== "undefined"
          ? title(this.props.match.params.tab)
          : "Members",
    })
  }

  tabChangedHandler = (activeTab) => {
    this.setState({ activeTab })
    this.props.history.push(`/users/${snakeCase(activeTab)}`)
  }

  render() {
    return (
      <div className="container friends w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>{langUtil("txt_global_friends", "Friends")}</h3>
          </div>
          <div className="col-6 text-right">
            <Link
              to={this.state.canViewAddFriend ? "/users/member/new" : ""}
              className="btn new-friend-btn"
              onClick={
                !this.state.canViewAddFriend ? (e) => e.preventDefault() : null
              }
              style={{
                cursor: this.state.canViewAddFriend ? "pointer" : "no-drop",
              }}
            >
              {langUtil("txt_global_add_new", "Add New")}
            </Link>
          </div>
        </div>
        <div className="row">
          {this.state.activeTab === "Members" ? (
            <Members
              isAdmin={this.state.isAdmin}
              tabChangedHandler={this.tabChangedHandler}
              experts={this.state.experts}
            />
          ) : this.state.activeTab === "Experts" ? (
            <Experts tabChangedHandler={this.tabChangedHandler} />
          ) : (
            this.props.history.push("/error/404")
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(Friends)
