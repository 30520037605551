import React, { Component } from "react"
import { toast } from "react-toastify"
import { title } from "change-case"
import { formatDate } from "../../utils/formatDate"
import axios from "../../configAxios"
import Button from "../UI/button/Button"
import Modal from "../UI/Modal"
import Spinner from "../UI/Spinner"
import langUtils from "../../utils/langUtils"
import AddNewFlag from "./AddNewFlag"

import "./Flags.css"

class Flags extends Component {
  signal = axios.CancelToken.source();
  state = {
    showModal: false,
    showFlagDetailModal: false,
    totalCount: 0,
    flags: [],
    loading: true
  };

  componentDidMount() {
    this.getFlags();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getFlags() {
    const queryParams = {
      params: {
        user_id: parseInt(this.props.userId)
      },
      cancelToken: this.signal.token
    };
    axios
      .get("/flags", queryParams)
      .then(res => {
        this.setState({ flags: res.data.flags, totalCount: res.data.meta.total_count, loading: false });
      })
      .catch(error => error);
  }

  addNewFlagHandler = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true });
  };

  updateFlags = res => {
    this.getFlags();
    let flags = this.state.flags.slice(0);
    flags.splice(0, 0, res.data.json.data);
    this.setState({ flags: flags });
  };

  deleteHandler = index => {
    let flagId = this.state.flags[index].id;
    const queryParams = {
      params: {
        id: flagId
      }
    };
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      axios
        .delete("/flags", queryParams)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Flag deleted successfully");
            this.getFlags();
            if (this.state.flags === null) {
              this.setState({ flags: [] });
            }
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  };

  getDescription(str) {
    const str_array = str.split(/\s+/g);
    if (str_array.length >= 50) {
      return str_array.slice(0, 50).join(" ") + "...";
    }
    else {
      return str_array.slice(0, 50).join(" ");
    }
  }

  render() {
    const { policyRes } = this.props;
    const { flags, flagDetails } = this.state;

    const flagDetailModal = (
      <Modal show={this.state.showFlagDetailModal}
        title="Flag Details"
        closeModal={() => this.setState({ showFlagDetailModal: false })}
        hide={() => this.setState({ showFlagDetailModal: false })}
        closeButton
      >
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="row my-2">
              <div className="col-md-12">
                <span className="font-weight-bold">Name:</span>&nbsp;
                <span>{flagDetails && flagDetails.name}</span>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-12">
                <span className="font-weight-bold">Type:</span>&nbsp;
                <span>{flagDetails && flagDetails.flag_type}</span>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-12">
                <span className="font-weight-bold">Created:</span>&nbsp;
                <span>
                  {
                    flagDetails &&
                    formatDate(new Date(flagDetails.created_at), "YYYY/MM/DD")
                  }
                </span>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-12">
                <span className="font-weight-bold">Description:</span>&nbsp;
                <span>{flagDetails && flagDetails.description ? flagDetails.description : "--"}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )

    let tableData;
    if (this.state.loading) {
      tableData = (
        <Spinner className="h-20vh w-100" />
      );
    } else {
      tableData = (
        <table className="table">
          <thead style={{ tableLayout: "fixed" }}>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Created</th>
              <th style={{ width: "40%" }}>Description</th>
              <th className="table-delete-col" />
            </tr>
          </thead>
          <tbody>
            {flags &&
              flags.map((flag, index) => {
                const description = flag.description && this.getDescription(flag.description);
                return <tr key={index}>
                  <td>{flag.name && flag.name}</td>
                  <td>{flag.flag_type}</td>
                  <td>
                    {formatDate(new Date(flag.created_at), "YYYY/MM/DD")}
                  </td>
                  <td className="table-description">{flag.description && description}</td>
                  <td className="text-center table-delete-col">
                    <i class="fas fa-eye cursor mt-2"
                      onClick={() => { window.scrollTo(0, 0); this.setState({ showFlagDetailModal: true, flagDetails: flag }) }}
                    />
                  </td>
                  <td className="text-center table-delete-col">
                    {policyRes && policyRes.can_add_flag && flag.can_delete &&
                      <i
                        className="fas fa-trash cursor mt-2"
                        onClick={() => {
                          this.deleteHandler(index);
                        }}
                      />
                    }
                  </td>
                </tr>
              })}
          </tbody>
        </table>
      );
    }
    let showTable;
    if (!this.state.loading && flags.length === 0) {
      showTable = (
        <div className="col no-flag-container">
          <h4 className="no-flags-heading">No Flags</h4>
        </div>
      );
    } else {
      showTable = <>{tableData}</>;
    }
    return (
      <div className="summary-flag">
        <hr />
        <div className="row bg-light">
          <div className="col-6">
            <div className="pl-3 py-2">
              <h5 className="text-dark font-weight-bold m-0 mt-1">
                {window.locale ? window.locale.txt_global_flags || "Flags" : "Flags"} | <small className="font-weight-bold">Total {window.locale ? window.locale.txt_global_flags || "Flags" : "Flags"}: {this.state.totalCount}</small>
              </h5>
            </div>
          </div>
          <div className="col-6 text-right">
            {
              policyRes.can_add_flag &&
              <Button
                type="primary"
                clicked={this.addNewFlagHandler}
                className="m-1"
              >
                {langUtils("txt_global_add_new", "Add New")}
              </Button>
            }
          </div>
        </div>
        <hr />
        <div className="row flag-table list-container">{showTable}</div>
        {flagDetailModal}
        <Modal
          title="Create a Flag"
          show={this.state.showModal}
          hide={() => this.setState({ showModal: false })}
          flag={true}
          summary={true}
        >
          <AddNewFlag
            flagHandler={() => this.props.flagHandler()}
            updateFlags={this.updateFlags}
            userId={this.props.user}
            getFlags={this.getFlags}
            closeModal={() => this.setState({ showModal: false })}
          />
        </Modal>
      </div>
    );
  }
}

export default Flags;
