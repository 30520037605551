import React, { Component } from "react";
import dateFns from "date-fns";

import axios from "../../../configAxios";
import Spinner from "./../../UI/Spinner";

import ActivityDetail from "./ActivityDetail";
import "./index.css";

class EquipmentActivityDetail extends Component {
  signal = axios.CancelToken.source();
  state = {
    overdueAssets: null,
    assetsIn: null,
    assetsOut: null,
    loading: true,
  };

  componentDidMount() {
    this.setState({
      activeTab: this.props.activeTab
    }, () =>this.getAssets())
  }

  componentWillReceiveProps(nextProps) {
    const { activeTab: prevActiveTab, date: prevDate } = this.props
    const { activeTab, date } = nextProps
    if ((date !== prevDate) || (activeTab !== prevActiveTab)) {
      this.setState({
        activeTab
      }, () => this.getAssets())
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getAssets = async (loading = true) => {
    this.setState({ loading });

    const params = {
      asset_summary_day: dateFns.format(this.props.date, "YYYY-MM-DD"),
      type_used: this.state.activeTab
    };

    axios.get(`/assets/booking_activity`, { params, cancelToken: this.signal.token })
    .then(res => {
      const assetsIn = this.getUserAssests(res.data.json.in);
      const assetsOut = this.getUserAssests(res.data.json.out);
      const overdueAssets = this.getUserAssests(res.data.json.overdue);
  
      this.setState({
        overdueAssets,
        assetsIn,
        assetsOut,
        loading: false
      });
    })

  };

  getUserAssests = assetsArr => {
    let assetsObj = {};
    if (Array.isArray(assetsArr)) {
      assetsArr.forEach(data => {
        const key = `${data.user_or_program_detail.id}_${data.ticket.project_name}`;
        data["asset"] = {
          ...data.asset,
          showCheckbox: data.should_show_check_box,
          bookingId: data.id
        };
        if (assetsObj.hasOwnProperty(key)) {
          assetsObj[key]["assets"] = [...assetsObj[key]["assets"], data.asset];
        } else {
          assetsObj[key] = {
            info: data,
            assets: [data.asset]
          };
        }
      });
    }
    return assetsObj;
  };

  renderHeader = (title, text) => {
    return (
      <li className="list-group-item bg-light-maroon" style={{ zIndex: 0 }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 90 }}
        >
          <h3 className="font-weight-bold text-primary">{title}</h3>
          <span className="text-dark text-center">{text}</span>
        </div>
      </li>
    );
  };


  render() {
    const { loading, assetsIn, assetsOut, overdueAssets } = this.state;
    let assetInContent, assetOutContent, overdueAssetsContent;

    if (!loading) {
      assetInContent = Object.keys(assetsIn).map(id => {
        return (
          <ActivityDetail
            asset={assetsIn[id].info}
            assets={assetsIn[id].assets}
            type={"In"}
            getAssets={this.getAssets}
            key={id}
            downloadPackingInvoice={this.props.downloadPackingInvoice}
          />
        );
      });
      assetOutContent = Object.keys(assetsOut).map(id => {
        return (
          <ActivityDetail
            asset={assetsOut[id].info}
            assets={assetsOut[id].assets}
            getAssets={this.getAssets}
            type={"Out"}
            key={id}
            downloadPackingInvoice={this.props.downloadPackingInvoice}
          />
        );
      });
      overdueAssetsContent = Object.keys(overdueAssets).map(id => {
        return (
          <ActivityDetail
            asset={overdueAssets[id].info}
            assets={overdueAssets[id].assets}
            getAssets={this.getAssets}
            type={"In"}
            key={id}
            downloadPackingInvoice={this.props.downloadPackingInvoice}
          />
        );
      });
    }

    return (
      <div className="booking-activity p-3">
        {this.state.loading ? (
          <Spinner />
        ) : (
            <React.Fragment>
              <div className="row py-4">
                <div className="col-lg-6">
                  <div className="pl-3 pr-4 pr-lg-0">
                    <ul className="list-group">
                      {
                        this.renderHeader("IN", <span>Bookings that are due today before noon<br />(i.e End date was yesterday)</span>)
                      }
                      {assetInContent && assetInContent.length ? (
                        assetInContent
                      ) : (
                          <li className="list-group-item no-schedule-text" style={{ zIndex: 0 }}>
                            {"There's no scheduled rental yet"}
                          </li>
                        )}
                    </ul>
                    <ul className="list-group my-4">
                      {
                        this.renderHeader("OVERDUE", "Assets that are checked out, and were due back before today")
                      }
                      {overdueAssetsContent && overdueAssetsContent.length ? (
                        overdueAssetsContent
                      ) : (
                          <li className="list-group-item no-schedule-text">
                            {"There's no scheduled rental yet"}
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="pr-4 pl-3 pl-lg-0">
                    <ul className="list-group">
                      {
                        this.renderHeader("OUT", "Bookings that are going out today")
                      }
                      {assetOutContent && assetOutContent.length ? (
                        assetOutContent
                      ) : (
                          <li className="list-group-item no-schedule-text">
                            {"There's no scheduled rental yet"}
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
              </div>

            </React.Fragment>
          )}
      </div>
    );
  }
}

export default EquipmentActivityDetail;