import React, { Component } from "react";
import axios from "../../configAxios";
import dateFns from "date-fns";
import Spinner from "../UI/Spinner";
import FormButton from "../UI/button/FormButton";
import DatePicker from "../UI/datePicker/index";
import Pagination from "../UI/Pagination";
import TagDropdown from "../shared/programLabels/TagDropdown";
import tagName from "../shared/tagName"

class Program extends Component {
  constructor(props) {
    super(props);
    const currentMonthDate = new Date();
    this.signal = axios.CancelToken.source();
    this.state = {
      startDate: new Date().setMonth(currentMonthDate.getMonth() - 3, 1),
      endDate: new Date(),
      programs: [],
      forcePage: "",
      selectedPage: 1,
      reportOptions: null,
      downloading: false,
      totalCount: null,
      loading: true,
      disabled: true,
      tags: [],
    };
  }

  componentDidMount() {
    const queryParams = {
      id: "ProgramReport",
      page: this.state.selectedPage,
      per_page: 10
    };
    this.getReportData(queryParams);
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  getReportData(queryParams) {
    axios.get("/reports/program", { params: queryParams, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          programs: res.data["reports/program"],
          totalCount: res.data.meta.total_count,
          loading: false,
          disabled: false
        });
      });
  }

  submitHandler = event => {
    event.preventDefault();
    this.setState({ loading: true, selectedPage: 1, disabled: true });
    const { startDate, endDate, tags } = this.state;
    const report_options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD")
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD")
      },
      tags: tags
    };
    this.setState({ reportOptions: report_options });
    const queryParams = {
      id: "ProgramReport",
      report_options,
      page: 1,
      per_page: 10
    };
    this.getReportData(queryParams);
  };

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      forcePage: page.selected,
      loading: true
    });
    const queryParams = {
      id: "ProgramReport",
      report_options: this.state.reportOptions,
      page: page.selected + 1,
      per_page: 10
    };
    this.getReportData(queryParams);
  };

  updateTags = (updatedState) => {
    this.setState({ tags: updatedState })
  }

  renderProgramList = () => {
    return (
      <>
        {this.state.programs.map(program => (
          <tr key={program.id}>
            <td>
              {program.labels.map((label, index) => (
                <div key={index}>
                  {tagName(label)}
                </div>
              ))}
            </td>
            <td>{program.name}</td>
            <td>{program.number_of_enrolled}</td>
            <td>
              {program?.users?.map((user, index) => (
                <div key={index}>
                  {user.full_name}
                </div>
              ))}
            </td>
            <td>
              {program?.users?.map((user, index) => (
                <div key={index}>
                  {user.email}
                </div>
              ))}
            </td>
          </tr>
        ))}
      </>
    )
  }

  render() {
    return (
      <div className="container service-kind w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Program Report</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <form onSubmit={this.submitHandler}>
                    <div className="row">
                      <div className="col-3">
                        <label>Labels</label>
                        <TagDropdown tags={this.state.tags} setTags={this.updateTags} />
                      </div>
                      <div className="col-3">
                        <label>From</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.startDate}
                          changed={date => this.setState({ startDate: date })}
                        />
                      </div>
                      <div className="col-3">
                        <label>To</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.endDate}
                          changed={date => this.setState({ endDate: date })}
                        />
                      </div>
                      <div className="col-3">
                        <FormButton className="build-report" disabled={this.state.disabled}>Build Report</FormButton>
                      </div>
                    </div>
                  </form>
                  <div className="list mt-5">
                    <div className="table-responsive">
                      <table className="table table-bordered reports-table-layout">
                        <thead className="border-top">
                          <tr>
                            <th className="w-20">Program Labels</th>
                            <th className="w-30">Program Name</th>
                            <th className="w-20">Number of people enrolled</th>
                            <th className="w-20">Name</th>
                            <th className="w-30">Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!this.state.loading && this.renderProgramList()}
                        </tbody>
                      </table>
                    </div>
                    {this.state.loading && <Spinner className="h-30vh" />}
                    <Pagination
                      forcePage={this.state.selectedPage - 1}
                      pageCount={this.state.totalCount / 10}
                      handlePageClick={this.paginationHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Program;
