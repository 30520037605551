import React from "react"
import axios from "axios"
import { toast } from "react-toastify"

import onError from "../../../utils/onError"

const PromoItem = ({ programId, promo, setPromos }) => {

  const onDeleteHandler = (promoId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this promo?")

    if (!confirmDelete) return null

    axios
      .delete(`/programs/${programId}/promos/${promoId}`)
      .then((res) => {
        const { promos } = res.data.json
        setPromos(promos)

        toast.dismiss()
        toast.success('Promo successfully deleted')
      })
      .catch((err) => {
        const { error: resError } = err.response.data.json
        onError(resError)
      })
  }

  return (
    <li className="list-group-item" key={promo.id}>
      <div className="row">
        <div className="col-3">{promo.code}</div>
        <div className="col-2">{promo.discount_percent}%</div>
        <div className="col-5">{promo.name}</div>
        <div className="col-2 text-center">
          <i
            className="fas fa-trash cursor text-danger"
            onClick={() => onDeleteHandler(promo.id)}
          />
        </div>
      </div>
    </li>
  )
}

export default PromoItem
