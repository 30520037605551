import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PrivateRoute = props => {
  const { component: Component, auth, ...rest } = props;
  return (  
    <Route
      {...rest}
      render={componentProps =>
        auth.isAuthenticated === true ? (
          <Component {...componentProps} />
        ) : (
          <Redirect to={ 
            (props.location.state && props.location.state.fromPaymentPage && props.redirection) || 
              (props.location.state && props.location.state.fromLoginPage)
            ? {
              pathname: props.redirection,
              state: props.location.state 
            } : "/"} 
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
