import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { toast } from "react-toastify"
import { title } from "change-case"
import { formatDate} from "../../utils/formatDate"

import axios from "../../configAxios"
import Spinner from "../UI/Spinner"
import Button from "../UI/button/Button"
import FormButton from "../UI/button/FormButton"
import Modal from "../UI/Modal"
import Input from "../UI/input/Input"
import Pagination from "../UI/Pagination"
import langUtils from "../../utils/langUtils"

class ProjectTab extends Component {
  signal = axios.CancelToken.source()
  state = {
    loading: true,
    projects: null,
    type: "active",
    showModal: false,
    projectName: {
      elementType: "input",
      elementConfig: {
        name: "projectName",
        required: true,
      },
      value: "",
    },
    forcePage: 0,
    totalCount: 0,
    disabled: false,
    manageProjects: null,
  }

  componentDidMount() {
    this.getProjects(1)
    this.getUserPolicy()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getProjects = (page) => {
    this.setState({ loading: true })
    axios
      .get("/users/projects", {
        params: {
          id: this.props.userId,
          type: "all",
          page,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          projects: res.data.users,
          loading: false,
          totalCount: res.data.meta.total_count,
        })
      })
      .catch((err) => console.log(err))
  }

  inputChangedHandler = (event) => {
    const key = event.target.name
    this.setState({
      [key]: {
        ...this.state[key],
        value: event.target.value,
      },
    })
  }

  projectDeleteHandler = (e, projectId) => {
    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      this.setState({ loading: true })
      axios.delete(`/projects/${projectId}`).then((res) => {
        if (res.data.json.success) {
          toast.success("Project successfully deleted.")
          this.getProjects()
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
        }
      })
    }
  }

  getUserPolicy = () => {
    axios
      .get("/users/policy", {
        params: { id: this.props.userId },
      })
      .then((res) => {
        this.setState({
          manageProjects: res.data.json.manage_projects,
        })
      })
  }

  createProjectHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const { projectName } = this.state
    axios
      .post("/projects", {
        project_name: projectName.value,
        user_id: this.props.userId,
      })
      .then((res) => {
        const { success } = res.data.json
        if (success) {
          this.getProjects()
          toast.success("Project created successfully")
          this.setState(
            {
              showModal: false,
              projectName: {
                ...this.state.projectName,
                value: "",
              },
              forcePage: 0,
              disabled: false,
            },
            () => {
              this.getProjects(1)
              this.props.history.push(`/projects/${res.data.json.data.id}`)
            }
          )
        } else {
          if ("errors" in res.data.json) {
            Object.keys(res.data.json.errors).forEach((key) => {
              toast.error(title(key) + " " + res.data.json.errors[key])
            })
          }
          if ("error" in res.data.json) {
            toast.error(res.data.json.error)
          }
          this.setState({ showModal: false, disabled: false })
        }
      })
      .catch(() => toast.error("Failed to create project"))
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState({ forcePage: page.selected }, () => {
      this.getProjects(page.selected + 1)
    })
  }

  render() {
    const tooltip = (
      <OverlayTrigger
        placement={"left"}
        overlay={
          <Popover>
            <div className="font-weight-bold">
              Projects are Inactive by default until some content is added.
            </div>
          </Popover>
        }
      >
        <i className="fas fa-question-circle cursor" />
      </OverlayTrigger>
    )
    const { projects, loading } = this.state
    let projectContent
    if (projects === null || loading) {
      projectContent = <Spinner className="h-30vh" />
    } else {
      if (projects.length) {
        projectContent = projects.map((project) => (
          <li key={project.id} className="list-group-item rounded-0">
            <div className="row">
              <div className="col-3">
                {formatDate(new Date(project.created_at), "YYYY/MM/DD")}
              </div>
              <div className="col-5">
                <Link
                  to={`/projects/${project.slug}`}
                  onClick={
                    project.can_view_projects ? null : (e) => e.preventDefault()
                  }
                  className="text-info"
                  style={{
                    cursor: project.can_view_projects ? "pointer" : "no-drop",
                  }}
                >
                  {project.name}
                </Link>
              </div>
              <div className="col-2">
                {project.active ? "Active" : "Inactive"}
              </div>
              <div className="col-2">
                <div className="d-flex justify-content-around">
                  <Link
                    to={
                      this.state.manageProjects
                        ? `/projects/${project.slug}`
                        : ""
                    }
                    onClick={
                      this.state.manageProjects
                        ? null
                        : (e) => e.preventDefault()
                    }
                  >
                    <i
                      className="fas fa-pencil-alt cursor text-dark"
                      style={{
                        cursor:
                          !project.active || this.state.manageProjects
                            ? "pointer"
                            : "no-drop",
                        opacity:
                          !project.active || this.state.manageProjects
                            ? 1
                            : 0.7,
                      }}
                    />
                  </Link>
                  <i
                    className="fas fa-trash cursor text-dark"
                    onClick={
                      project.can_delete_projects
                        ? (e) =>
                            !project.active &&
                            this.projectDeleteHandler(e, project.slug)
                        : null
                    }
                    style={{
                      cursor:
                        !project.active && project.can_delete_projects
                          ? "pointer"
                          : "no-drop",
                      opacity:
                        !project.active && project.can_delete_projects ? 1 : 0.7,
                    }}
                  />
                </div>
              </div>
            </div>
          </li>
        ))
      } else {
        projectContent = (
          <div className="text-center my-5">
            <h4 className="center-block">No Projects</h4>
          </div>
        )
      }
    }

    const newProjectModal = (
      <Modal
        title="New Project"
        show={this.state.showModal}
        hide={() => this.setState({ showModal: false })}
      >
        <form onSubmit={this.createProjectHandler}>
          <label>Project Name</label>
          <Input
            {...this.state.projectName}
            changed={this.inputChangedHandler}
          />
          <div className="row d-flex justify-content-center">
            {" "}
            <Button
              className="mr-2"
              clicked={() => this.setState({ showModal: false })}
              type="secondary"
            >
              Cancel
            </Button>
            <FormButton disabled={this.state.disabled}>Submit</FormButton>
          </div>
        </form>
      </Modal>
    )

    return (
      <div className="pb-5 webkit-fill-available">
        <div className="text-lg-right mx-4 my-3">
          {this.state.manageProjects && 
            <Button
              type="success"
              clicked={() => {
                window.scrollTo(0, 0)
                this.setState({ showModal: true })
              }}
              style={{ cursor: "pointer" }}
            >
              {langUtils("txt_global_add_new", "Add New")}
            </Button>
          }
        </div>
        {loading ? (
          <Spinner className="h-30vh" />
        ) : (
          <ul className="list-group list-group-flush my-4">
            <li className="list-group-item rounded-0 border-top-0">
              <div className="row">
                <div className="col-3">
                  <span className="font-weight-bold">Date</span>
                </div>
                <div className="col-5">
                  <span className="font-weight-bold">Name</span>
                </div>
                <div className="col-2">
                  <span className="font-weight-bold">{tooltip} Status</span>
                </div>
                <div className="col-2">
                  <span className="font-weight-bold">Options</span>
                </div>
              </div>
            </li>
            {projectContent}
          </ul>
        )}
        {this.state.projects !== null && (
          <Pagination
            pageCount={this.state.totalCount / 10}
            forcePage={this.state.forcePage}
            handlePageClick={this.paginationHandler}
            currentPage={this.state.forcePage}
          />
        )}
        {newProjectModal}
      </div>
    )
  }
}

export default withRouter(ProjectTab)
