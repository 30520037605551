import React, { Component } from "react";
import dateFns from "date-fns";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import FormButton from "../UI/button/FormButton";
import Input from "../UI/input/Input"
import DatePicker from "../UI/datePicker/index";

class AssetRevenue extends Component {
  constructor() {
    super();
    const currentMonthDate = new Date();
    this.signal = axios.CancelToken.source();
    this.state = {
      startDate: new Date().setMonth(currentMonthDate.getMonth() - 1),
      endDate: new Date(),
      accountingCodes: [],
      categories: [],
      memberTypes: [],
      metaData: {},
      type: {
        elementType: "select",
        elementConfig: {
          name: "type",
          type: "select",
          options: [
            { label: "Invoice Dates", value: "invoice_date" },
            { label: "Booking Dates", value: "booking_date" }
          ],
          components: {
            IndicatorSeparator: () => null
          },
          isSearchable: false,
          isClearable: false
        },
        selectedOption: { label: "Invoice Dates", value: "invoice_date" }
      },
      subType: {
        elementType: "select",
        elementConfig: {
          name: "subType",
          type: "select",
          options: [
            { label: "All Invoice", value: "all" },
            { label: "Paid Invoice", value: "paid" }
          ],
          components: {
            IndicatorSeparator: () => null
          },
          isSearchable: false,
          isClearable: false
        },
        selectedOption: { label: "All Invoice", value: "all" }
      },
      loading: true,
      disabled: true
    };
  }

  componentDidMount() {
    const {
      type: { selectedOption: { value: typeValue } },
      subType: { selectedOption: { value: subTypeValue } }
    } = this.state;
    const queryParams = {
      id: "AssetRevenueReport",
      type: typeValue,
      subtype: subTypeValue
    };
    this.getAccountingCodes(queryParams);
    this.getCategories(queryParams);
    this.getMemberTypes(queryParams);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getAccountingCodes(queryParams) {
    axios
      .get("/reports/asset_revenue/by_accounting_code", {
        params: queryParams,
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({ accountingCodes: res.data.json.data });
      });
  }

  getCategories(queryParams) {
    axios
      .get("/reports/asset_revenue/by_category", {
        params: queryParams,
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          categories: res.data.json.data,
          metaData: res.data.json.meta,
          disabled: false
        });
      });
  }

  getMemberTypes(queryParams) {
    axios
      .get("/reports/asset_revenue/by_member_type", {
        params: queryParams,
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          memberTypes: res.data.json.data,
          loading: false,
          disabled: false
        });
      });
  }

  submitHandler = event => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const {
      startDate,
      endDate,
      type: { selectedOption: { value: typeValue } },
      subType: { selectedOption: { value: subTypeValue } }
    } = this.state;
    const report_options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD")
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD")
      }
    };
    const queryParams = {
      id: "AssetRevenueReport",
      report_options,
      type: typeValue
    };
    if (typeValue === "invoice_date")
      queryParams["subtype"] = subTypeValue;
    this.getAccountingCodes(queryParams);
    this.getCategories(queryParams);
    this.getMemberTypes(queryParams);
  };

  filterHandler = (e, key) => {
    this.setState({
      [key]: {
        ...this.state[key],
        selectedOption: e
      }
    });
  }

  render() {
    const {
      accountingCodes,
      categories,
      memberTypes,
      type: {
        selectedOption: { value: typeName }
      }
    } = this.state;

    const accountingCodesList = accountingCodes.map(code => (
      <tr key={code.accounting_code.id}>
        <td>{code.accounting_code.name}</td>
        <td>{code.bookings_size}</td>
        <td>{code.revenue}</td>
      </tr>
    ));

    const categoriesList = categories.map(data => (
      <tr key={data.category.id}>
        <td>{data.category.name}</td>
        <td>{data.bookings_size}</td>
        <td>{data.revenue}</td>
      </tr>
    ));

    const memberTypesList = memberTypes.map(data => (
      <tr key={data.member_type.id}>
        <td>{data.member_type.name}</td>
        <td>{data.bookings_size}</td>
        <td>{data.revenue}</td>
      </tr>
    ));

    return (
      <div className="container service-kind w-100">
        <div className="row mb-4">
          <div className="col-12">
            <h3>Asset Revenue Report</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <form onSubmit={this.submitHandler}>
                    <div className="form-row">
                      <div className="col">
                        <label>From</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.startDate}
                          changed={date => this.setState({ startDate: date })}
                        />
                      </div>
                      <div className="col">
                        <label>To</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.endDate}
                          changed={date => this.setState({ endDate: date })}
                        />
                      </div>
                    </div>
                    <div className="form-row mt-3">
                      <div className="col">
                        <label>Type</label>
                        <Input
                          {...this.state.type}
                          changed={e => this.filterHandler(e, "type")}
                        />
                      </div>
                      <div className="col">
                        {typeName === "invoice_date" ?
                          <>
                            <label>Sub Type</label>
                            <Input
                              {...this.state.subType}
                              changed={e => this.filterHandler(e, "subType")}
                            />
                          </>
                          : null}
                      </div>
                    </div>
                    <FormButton className="build-report" disabled={this.state.disabled}>Build Report</FormButton>
                  </form>
                  <div className="row mt-5">
                    <div className="col-12">
                      <h5 className="font-weight-bold">Statistics</h5>
                    </div>
                    {
                      this.state.loading ? (
                        <div className="col-12">
                          <Spinner className="h-40vh" />
                        </div>
                      ) : (
                          <React.Fragment>
                            <div className="col-3">
                              <p>Total Revenue for the Period:</p>
                            </div>
                            <div className="col-7">
                              <p>{this.state.loading ? 0 : this.state.metaData.total_revenue}</p>
                            </div>
                            <div className="col-3">
                              <p>Total Number of Bookings:</p>
                            </div>
                            <div className="col-7">
                              <p>{this.state.loading ? 0 : this.state.metaData.total_count}</p>
                            </div>
                          </React.Fragment>
                        )
                    }
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="list mt-5">
                        <h5 className="font-weight-bold">Total Revenue by Accounting Code</h5>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th className="w-40">Accounting Code</th>
                                <th className="w-20">Quantity</th>
                                <th className="w-20">Revenue</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!this.state.loading &&
                                accountingCodesList
                              }
                            </tbody>
                          </table>
                        </div>
                        {this.state.loading && <Spinner className="h-30vh" />}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <h5>
                      <b>Total Revenue by Category</b>
                    </h5>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th className="w-40">Category Name</th>
                            <th className="w-20">Quantity</th>
                            <th className="w-20">Revenue</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!this.state.loading && categoriesList}
                        </tbody>
                      </table>
                    </div>
                    {this.state.loading && <Spinner className="h-30vh" />}
                  </div>
                  <div className="mt-5">
                    <h5>
                      <b>Total Revenue by Member Type</b>
                    </h5>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th className="w-40">Member Type</th>
                            <th className="w-20">Quantity</th>
                            <th className="w-20">Revenue</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!this.state.loading && memberTypesList}
                        </tbody>
                      </table>
                    </div>
                    {this.state.loading && <Spinner className="h-30vh" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssetRevenue;
