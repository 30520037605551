import React, { Component } from "react"
import Button from "../../UI/button/Button"
import FormButton from "../../UI/button/FormButton"
import axios from "../../../configAxios"
import { toast } from "react-toastify"
import Input from "../../UI/input/Input"
import { title } from "change-case"
import ModalSpinner from "../../UI/ModalSpinner"

class NewAssetPriceType extends Component {
  signal = axios.CancelToken.source();
  state = {
    categoryNames: [],
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true
      },
      label: "Name",
      value: ""
    },
    priority: {
      elementType: "input",
      elementConfig: {
        name: "priority",
        type: "number",
        min: 0,
        max: 999999999,
        step: "any"
      },
      label: "Priority",
      value: ""
    },
    loading: false,
    disabled: false
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setState({ ...this.state, loading: true })
      axios
        .get("/asset_price_types/" + this.props.id, {
          cancelToken: this.signal.token
        })
        .then(res => {
          const { asset_price_type } = res.data;
          this.setState({
            name: {
              ...this.state.name,
              value: asset_price_type.name
            },
            priority: {
              ...this.state.priority,
              value: asset_price_type.level
            },
            loading: false
          });
        })
        .catch(err => console.log("Something went wrong"));
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;

    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let assetPriceData = {};
    let assetPriceDataEdit = {};

    const { name, priority } = this.state;
    assetPriceData["name"] = name.value;
    assetPriceData["level"] = priority.value;

    assetPriceDataEdit["id"] = this.props.id;
    assetPriceDataEdit["name"] = name.value;
    assetPriceDataEdit["level"] = priority.value;
    if (this.props.edit) {
      axios
        .put(`/asset_price_types/${assetPriceDataEdit["id"]}`, assetPriceDataEdit)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Asset price type successfully updated");
            this.props.closeModal();
            this.props.getAssetPriceTypes();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disabled: false })
        })
        .catch(err => toast.error(err));
    } else {
      axios
        .post("/asset_price_types", assetPriceData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Asset price type successfully created");
            this.props.closeModal();
            this.props.getAssetPriceTypes();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disabled: false })
        })
        .catch(err => toast.error(err));
    }
  };

  render() {
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        {
          this.state.loading ? <ModalSpinner /> : (
            <React.Fragment>
              <Input
                {...this.state.name}
                disabled={this.props.edit ? true : false}
                changed={event => this.inputChangedHandler(event, "name")}
              />
              <Input
                {...this.state.priority}
                changed={event => this.inputChangedHandler(event, "priority")}
              />
              <div className="text-center">
                <Button type="secondary" clicked={this.props.closeModal}>
                  Cancel
                </Button>
                <FormButton className="ml-2" disabled={this.state.disabled}>
                  {this.props.edit
                    ? "Edit Asset Price Type"
                    : "Create Asset Price Type"}
                </FormButton>
              </div>
            </React.Fragment>
          )
        }
      </form>
    );
  }
}

export default NewAssetPriceType;
