import React, { Component } from "react"
import "./Account.css"
import { formatDate } from "../../utils/formatDate"

class MoreInfo extends Component {
  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    let program = this.props.program;
    const formattedEarliestProgramDate = formatDate(
      new Date(program.earliest_scheduled_program_date)
        .toDateString()
        .substring(4),
      "YYYY/MM/DD")

    return (
      <div>
        <div>
          <div>{program.description}</div>
          <div className="mt-3">
            <p>
              <span className="grey-text">Instructors:</span>
              <br />
              <span className="text-dark">{program.instructors}</span>
            </p>
          </div>
          <div className="mt-3">
            <p>
              <span className="grey-text">Location:</span>
              <br />
              <span className="text-dark">{program.location}</span>
            </p>
          </div>
          <div className="mt-3">
            <p>
              <span className="grey-text">
                Scheduled Program Dates:
              </span>
              <br />
              <span className="text-dark">
                {program.earliest_scheduled_program_date != null
                  ? formattedEarliestProgramDate
                  : ""}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MoreInfo;
