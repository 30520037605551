import React, { Component } from 'react';
import { toast } from "react-toastify";
import { connect } from "react-redux";

import axios from "../../../configAxios";
import Input from "../../UI/input/Input";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import Spinner from "../../UI/ModalSpinner";
import CKEditorFull from "../../systemSettings/communication/CkEditorFull";
import Config from "../../systemSettings/communication/config";

export class EmailModal extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.emailEditorRef = React.createRef();
    this.editorTimer = null;
    this.state = {
      email: "",
      ccToOrganizationEmail: false,
      loading: true,
      mailBody: null,
      emailSubject: "",
      editorReady: false,
      disabled: false,
      isReadOnly: localStorage.getItem("roleLevel") === "member"
    };
  }

  componentDidMount() {
    this.setState({ 
      email: this.props.email,
      loading: true,
    });
    axios.get("/mail_formats", {
      cancelToken: this.signal.token
    })
      .then(res => {
        const { mail_formats } = res.data;
        if (Array.isArray(mail_formats)) {
          mail_formats.forEach(format => {
            if (format.tab_name === "Invoicing")
            this.setState({
              mailBody: format.data.body_email,
            }, this.getOrganizationInfo);
          })
        }
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Requests being cancelled");
    if (this.editorTimer) {
      clearInterval(this.editorTimer);
    }
  };

  getOrganizationInfo = () => {
    const { auth, invoice } = this.props;
    axios.get(`/organizations/${auth.organizationId}/basic`, {
      cancelToken: this.signal.token
    })
      .then(res => {
        const { json } = res.data;
        this.setState({
          emailSubject: `${json.organization.subdomain.toUpperCase()} Invoice #${invoice.reference}`,
          loading: false,
        }, this.editorCheckHandler);
      })
  }

  editorCheckHandler = () => {
    this.editorTimer = setInterval(
      () => {
        if (
          this.emailEditorRef.current &&
          this.emailEditorRef.current.editor &&
          this.emailEditorRef.current.editor.instanceReady
        ) {
          this.setState({ editorReady: true });
          clearInterval(this.editorTimer);
        }
      }, 500);
  }

  mailBodyHandler = event => {
    this.setState({
      mailBody: event.editor.getData()
    })
  }

  sendMail = event => {
    event.preventDefault();
    const params = {
      id: this.props.invoice.id,
      email_address: this.state.email,
      cc_to_organization_email: this.state.ccToOrganizationEmail,
      email_subject: this.state.emailSubject,
      email_body: this.state.mailBody
    };
    this.setState({ disabled: true });
    axios
      .get("/invoices/email_invoice", {
        params,
        cancelToken: this.signal.token
      })
      .then(res => {
        if (res.data.json.success) {
          toast.success("Email sent successfully.");
          this.props.closeEmailModal();
          this.setState({ ccToOrganizationEmail: false });
        } else {
          toast.error("Failed to send email");
          this.setState({ disabled: false });
        }
      });
  };

  toggleCheckbox = () => {
    if (this.state.isReadOnly)
      return
    this.setState({
      ccToOrganizationEmail: !this.state.ccToOrganizationEmail
    })
  }

  render() {
    const { invoice, closeEmailModal } = this.props;
    const { 
      email, 
      ccToOrganizationEmail,
      loading,
      mailBody,
      emailSubject,
      editorReady,
      isReadOnly,
      disabled
    } = this.state;

    return (
      <>
        {
          loading
            ? <Spinner />
            : (
              <>
                {!editorReady && <Spinner />}
                <form onSubmit={this.sendMail} className={`mt-3 ${
                  editorReady
                    ? ""
                    : "d-none"
                }`}>
                  <div className="mb-2 pb-2">
                    <Input
                      elementType={"input"}
                      elementConfig={{ required: true, type: "email", readOnly: isReadOnly }}
                      value={email}
                      label="To Address"
                      changed={event => this.setState({ email: event.target.value })}
                    />
                  </div>
                  <div className="mb-2 pb-1">
                    <Input 
                      elementType="input"
                      elementConfig={{
                        name: "mailSubject",
                        type: "text",
                        readOnly: isReadOnly,
                        required: true
                      }}
                      label="Subject"
                      changed={e => this.setState({ emailSubject: e.target.value })}
                      value={emailSubject}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-4 pb-2">
                        <label className="mb-2">Body Text</label>
                        <CKEditorFull
                          ref={this.emailEditorRef}
                          data={mailBody}
                          config={Config}
                          readOnly={isReadOnly}
                          onChange={this.mailBodyHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <Input
                    elementType={"input"}
                    elementConfig={{ name: "ccToOrganizationEmail", type: "checkbox", checked: ccToOrganizationEmail }}
                    value={ccToOrganizationEmail}
                    label={`BCC ${invoice.organization.name}`}
                    changed={this.toggleCheckbox}
                  />
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center">
                        <Button
                          type="secondary"
                          className="mr-3"
                          clicked={closeEmailModal}
                        >
                          Cancel
                        </Button>
                        <FormButton disabled={disabled || !editorReady} >
                          Send
                        </FormButton>
                      </div>
                    </div>
                  </div>
              </form>
              </>
            )
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(
  mapStateToProps
)(EmailModal);
