/* eslint-disable no-case-declarations */
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const items = [
  { id: 11, formId: 91, type: "Short Answer", name: "First Name" },
  { id: 12, formId: 91, type: "Short Answer", name: "Last Name" },
  { id: 13, formId: 92, type: "Short Answer", name: "Runtime (hrs)" },
  { id: 14, formId: 91, type: "Long Answer", name: "Address" },
  { id: 15, formId: 92, type: "Short Answer", name: "Year Completed" },
  { id: 16, formId: 91, type: "Text", name: "Notes" },
];

const initialState = {
  loading: true,
};

const formAttributesAdapter = createEntityAdapter({});

const slice = createSlice({
  name: "formAttribute",
  initialState: formAttributesAdapter.getInitialState({ loading: true }),
  reducers: {
    getFormAttributes(state, action) {
      formAttributesAdapter.addMany(state, items);
      state.loading = false;
    },
    addFormAttribute(state, action) {
      const { payload } = action;
      const id = Math.floor(Math.random() * Math.floor(100000000));
      const newAttribute = {
        id,
        formId: payload.formId,
        type: payload.type.label,
        name: payload.name,
      };
      formAttributesAdapter.addOne(state, newAttribute);
    },
    deleteFormAttribute(state, action) {
      state.items = state.items.filter((a) => a.id !== action.payload.id);
    },
  },
});
export const selectFormAttributesLoading = (state) =>
  state.formAttributes.loading;

export const {
  selectById: selectFormAttributesById,
  selectIds: selectFormAttributeIds,
  selectEntities: selectFormAttributeEntities,
  selectAll: selectAllFormAttributes,
  selectTotal: selectTotalFormAttributes,
} = formAttributesAdapter.getSelectors((state) => state.formAttributes);

export const selectFormAttributesByFormId = createSelector(
  [selectAllFormAttributes, (state, formId) => formId],
  (attrs, formId) => attrs.filter((attr) => attr.formId === formId)
);

const { actions, reducer } = slice;
export const {
  getFormAttributes,
  addFormAttribute,
  deleteFormAttribute,
} = actions;

export default reducer;
