import "./index.css"
import React from "react";

const AssetTable = ({ assetLineItems }) => {
  const assetList = assetLineItems.map((asset) => {
    return <tr>
        <td className="column-15 text-center">
          {asset.accounting_code || "N/A"}
        </td>
        <td className="column-70 text-center">
          {asset.asset_name}
        </td>
        <td className="column-15 text-center">
          {asset.quantity}
        </td>
        <td className="column-15 text-center">
          {asset.total_amount}
        </td>
    </tr>
  })

  return (
    <table className="table table-bordered">
      <thead className="thead-light">
        <tr>
          <th className="text-center">Code</th>
          <th className="text-center">
            Asset Name
          </th>
          <th className="text-center">
            Qty
          </th>
          <th className="text-center">Price</th>
        </tr>
      </thead>
      <tbody>
        {assetList}
      </tbody>
    </table>
  );
};

export default AssetTable;
