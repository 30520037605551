import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";

import Button from "../../UI/button/Button";
import Input from "../../UI/input/Input";
import Spinner from "../../UI/Spinner";
import ToggleSwitch from "../../common/toggleSwitch";
import axios from "../../../configAxios";

import Config from "./config";
import CKEditorFull from "./CkEditorFull";
import VerifiedEmailsSelect from "./VerifiedEmailsSelect"

import "./Invoicing.css";

class VolunteerHour extends Component {
  signal = axios.CancelToken.source();
  state = {
    volunteerHour: [],
    orgnId: null,
    id: null,
    fromEmail: {
      elementType: "input",
      elementConfig: {
        name: "fromEmail",
        type: "email",
        required: true
      },
      value: ""
    },
    bodyContent: "",
    checked: null,
    loading: true,
    disabled: false,
    disableToggle: true,
    verifiedEmails: [],
    useEmailDropdown: false,
    fromEmailSelected: "",
    defaultFromEmail: "",
  };

  componentDidMount() {
    const requests = [
      this.getOrgCommEmails(),
      this.getOrgFormData(),
      this.getFormData(),
    ];
    Promise.all(requests.map(request => (
      request
        .then(value => ({ status: "fulfilled", value }))
        .catch(reason => ({ status: "rejected", reason }))
    ))).then(() => this.setState({ loading: false }));
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getOrgCommEmails = () => {
    return axios.get("/organization_communication_emails", { 
      cancelToken: this.signal.token 
    }).then(res => {
      const { use_verified_email } = res.data.meta;
      let verifiedEmails = [];
      let useEmailDropdown = false;
      if ( use_verified_email ) {
        const { organization_communication_emails: orgEmails } = res.data;
        verifiedEmails =
          Array.isArray(orgEmails)
            ? orgEmails.filter(email => email.is_verified)
            : [];
        useEmailDropdown = verifiedEmails.length >= 1;
      }
      this.setState({ verifiedEmails, useEmailDropdown });
    })
  }

  getFormData = () => {
    return axios.get("/mail_formats", { cancelToken: this.signal.token }).then(res => {
      const volunteerHour = res.data.mail_formats.filter(data =>
        data.tab_name === "Volunteer Hour" ? true : false
      );
      this.setState({
        id: volunteerHour[0].id,
        orgnId: volunteerHour[0].organization_id,
        fromEmail: {
          ...this.state.fromEmail,
          value: volunteerHour[0].data.from_email
        },
        defaultFromEmail: volunteerHour[0].data.from_email,
        bodyContent: volunteerHour[0].data.body_email
      });
    });
  }

  getOrgFormData = () => {
    return axios.get("/organizations/formdata", {
      cancelToken: this.signal.token
    }).then(res => {
        const checked =
          res.data.json.current_organization
            .volunteer_hour_approval_email_required;
        this.setState({
          checked,
          disableToggle: false,
        });
      });
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  toggle = () => {
    this.setState({ checked: !this.state.checked, disableToggle: true }, () => {
      axios.put('organizations/set_volunteer_hour_approval_email_required', {
        id: this.state.orgnId,
        volunteer_hour_approval: this.state.checked
      })
        .then(res => {
          if (res.data.json.success) {
            toast.success("Preference Changed");
            this.setState({ disableToggle: false });
          } else {
            this.setState({
              ...this.state,
              checked: !this.state.checked,
              disableToggle: false,
            })
          }
        })
        .catch(err => {
          this.setState({ disableToggle: false });
          return err;
        })
    });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let attrData = {};
    let checked = {};
    const { 
      orgnId, 
      fromEmail, 
      bodyContent,
      fromEmailSelected,
      useEmailDropdown, 
    } = this.state;

    if (
      (useEmailDropdown && !fromEmailSelected) ||
      (!useEmailDropdown && !fromEmail.value) 
    ) {
      toast.error(`Please ${
        useEmailDropdown
          ? "select"
          : "enter"
      } a valid email address`);
      this.setState({ disabled: false });
      return;
    }

    attrData["id"] = this.state.id;
    attrData["from_email"] = fromEmail.value;
    attrData["body_email"] = bodyContent;
    checked["id"] = orgnId;
    checked["volunteer_hour_approval"] = this.state.checked;
    axios
      .put(`/mail_formats`, attrData).then(res => {
        if (res.data.json.success) {
          toast.success("Mail format successfully saved");
        } else {
          if ('errors' in res.data.json) {
            Object.keys(res.data.json.errors).forEach(key => {
              toast.error(`${title(key)} ${res.data.json.errors[key][0]}`)
            })
          }
          if ('error' in res.data.json) {
            toast.error(res.data.json.error)
          }
        }
        this.setState({ disabled: false })
      })
  };

  inputChangeBody = event => {
    this.setState({
      bodyContent: event.editor.getData()
    });
  };

  render() {
    const { 
      useEmailDropdown,
      verifiedEmails,
      defaultFromEmail,
    } = this.state;

    return (
      this.state.loading ? (
        <div className="card-text">
          <div className="row">
            <div className="col-12">
              <Spinner className="h-60vh" />
            </div>
          </div>
        </div>
      ) : (
          <div className="membership p-4">
            <div className="row">
              <div className="col-12">
                <h5 className="font-weight-bold pt-2">Automated Booking Confirmation Emailing</h5>
              </div>
            </div>

            <div className="my-4 d-sm-flex align-items-center">
              <div>
                <ToggleSwitch
                  onChange={this.toggle} 
                  checked={this.state.checked} 
                  disabled={this.state.disableToggle}
                />
              </div>
              <h6 className="mb-sm-0 mb-3 ml-2">Should volunteer hour approval email be sent to user?</h6>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3">
                <h6 className="text-dark">From Address</h6>
              </div>
              <div className="col-lg-6">
                {
                  useEmailDropdown
                    ? (
                      <VerifiedEmailsSelect
                        emailAddresses={verifiedEmails}
                        defaultEmail={defaultFromEmail}
                        emailChangeHandler={email => this.setState({
                          fromEmailSelected: email ? email.label : ""
                        })}
                      />
                    ) : (
                      <Input
                        {...this.state.fromEmail}
                        changed={event => this.inputChangedHandler(event, "fromEmail")}
                      />
                    )
                }
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-4">
                <h6 className="text-dark">Body Text</h6>
              </div>
            </div>

            <div className="row mt-3 body-text">
              <div className="col-12">
                <div id="toolbar-container" />
                <CKEditorFull
                  data={this.state.bodyContent}
                  config={Config}
                  onChange={this.inputChangeBody}
                  onBeforeLoad={(CKEDITOR) => ( CKEDITOR.disableAutoInline = true )}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-lg-3 d-flex justify-content-between">
                <Button
                  type="success"
                  className="px-3"
                  clicked={this.submitHandler}
                  disabled={this.state.disabled}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )
    );
  }
}

export default VolunteerHour;
