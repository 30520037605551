import React, {useEffect, useState} from 'react';
import Input from "../../../../UI/input/Input";
import _ from "lodash";

function ApplicantFieldsSelect({ applicantFields, setApplicantFields, isEditing }) {

  const initializeSelectedFields = () => {
    let applicantFieldData;

    if (applicantFields?.length) {
      applicantFieldData = applicantFields
    }

    if (applicantFields?.fields) {
      applicantFieldData = applicantFields.fields
    }

      return applicantFieldData?.map(field => {
        switch (field) {
        case "name":
          return { label: "Name", value: field }
        case "email":
          return {label: "Email Address", value: field }
        case "organization_name":
          return {label: "Organization Name", value: field }
          default:
            return null
        }
      }) || null
    }

  const dropdownOptions = [
      { label: "Name", value: "name" },
      { label: "Email Address", value: "email" },
      { label: "Organization Name", value: "organization_name" }
    ]

  const [selectedFields, setSelectedFields] = useState(initializeSelectedFields())

  useEffect(() => {
    if (selectedFields !== applicantFields) {
      let fields = selectedFields?.map(field => field.value) || null
      setApplicantFields(fields)
    }
  }, [selectedFields])

  return (
    <div className="mb-3">
      <Input
        id="applicant-data-question"
        elementType="select"
        selectedOption={selectedFields}
        elementConfig={{
          placeholder: "Select...",
          options: dropdownOptions,
          required: true,
          isMulti: true,
        }}
        changed={option => setSelectedFields(_.isEmpty(option) ? null : option)}
        customStyle={{
          container: provided => ({
            ...provided,
            width: 418
          })
        }}
        disabled={!isEditing}
      />
    </div>
  )
}

export default ApplicantFieldsSelect;
