import React from "react"
import isValidUrl from "../../../utils/isValidUrl"
import SocialMediaIcon from "./SocialMediaIcon"

const SocialMedia = (props) => {
  const socials = props.socials
  const socialsList = [
    socials?.facebook,
    socials?.instagram,
    socials?.linkedin,
    socials?.twitter,
    socials?.youtube,
  ]

  const activeSocialPanel = () => {
    return (
      isValidUrl(socials?.facebook) ||
      isValidUrl(socials?.instagram) ||
      isValidUrl(socials?.linkedin) ||
      isValidUrl(socials?.twitter) ||
      isValidUrl(socials?.youtube)
    )
  }

  const iconPicker = (website) => {
    if (website.includes("facebook")) return <i className="fab fa-facebook-f" />
    if (website.includes("instagram")) return <i className="fab fa-instagram" />
    if (website.includes("linkedin")) return <i className="fab fa-linkedin-in" />
    if (website.includes("twitter")) return <i className="fab fa-twitter" />
    if (website.includes("youtube")) return <i className="fab fa-youtube" />
    if (website.includes("vimeo")) return <i className="fab fa-vimeo-v" />
    return null
  }

  return (
    <>
      {activeSocialPanel() && (
        <div className="row no-gutters border-bottom py-2 px-2">
          {socialsList.map((social) => {
            if (isValidUrl(social)) {
              const socialUrl = social.trim()

              return (
                <SocialMediaIcon url={socialUrl}>
                  {iconPicker(socialUrl)}
                </SocialMediaIcon>
              )
            }
          })}
        </div>
      )}
    </>
  )
}

export default SocialMedia
