import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import _ from "lodash"
import { title } from "change-case"

import Input from "../../UI/input/Input"
import Button from "../../UI/button/Button"
import FormButton from "../../UI/button/FormButton"
import Spinner from "../../UI/Spinner"
import axios from "../../../configAxios"

import { timezone } from "./timezones"

import "./../index.css"

class BasicInformation extends Component {
  signal = axios.CancelToken.source()
  state = {
    fullName: {
      elementType: "input",
      elementConfig: {
        name: "fullName",
        type: "text",
      },
      label: "Full Organization Name",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    abbrName: {
      elementType: "input",
      elementConfig: {
        name: "abbrName",
        type: "text",
      },
      label: "Abbreviated Name",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    orgnWeb: {
      elementType: "input",
      elementConfig: {
        name: "orgnWeb",
        type: "text",
      },
      label: "Organization Website",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    phoneNumbers: {
      elementType: "textarea",
      elementConfig: {
        name: "phoneNumbers",
        type: "text",
      },
      label: "Phone Numbers",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    mailingAddress: {
      elementType: "textarea",
      elementConfig: {
        name: "mailingAddress",
        type: "text",
      },
      label: "Mailing Address",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    officeEmail: {
      elementType: "textarea",
      elementConfig: {
        name: "officeEmail",
        type: "email",
      },
      label: "Office E-Mail",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    gstNumber: {
      elementType: "input",
      elementConfig: {
        name: "gstNumber",
        type: "text",
      },
      label: "GST Number",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    gstRate: {
      elementType: "input",
      elementConfig: {
        name: "gstRate",
        type: "number",
        placeholder: "Eg. 0, 0.15, 0.5, 1",
        min: 0,
        max: 1,
        step: "any",
      },
      label: "GST Rate",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    pstNumber: {
      elementType: "input",
      elementConfig: {
        name: "pstNumber",
        type: "text",
      },
      label: "PST Number",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    pstRate: {
      elementType: "input",
      elementConfig: {
        name: "pstRate",
        type: "number",
        placeholder: "Eg. 0, 0.15, 0.5, 1",
        min: 0,
        max: 1,
        step: "any",
      },
      label: "PST Rate",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    hstNumber: {
      elementType: "input",
      elementConfig: {
        name: "hstNumber",
        type: "text",
      },
      label: "HST Number",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    hstRate: {
      elementType: "input",
      elementConfig: {
        name: "hstRate",
        type: "number",
        placeholder: "Eg. 0, 0.15, 0.5, 1",
        min: 0,
        max: 1,
        step: "any",
      },
      label: "HST Rate",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    creditName: {
      elementType: "input",
      elementConfig: {
        name: "creditName",
        type: "text",
      },
      label: "Credit name",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    communityDesc: {
      elementType: "input",
      elementConfig: {
        name: "communityDesc",
        type: "text",
      },
      label: "Community description",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    fullRateCategory: {
      elementType: "select",
      elementConfig: {
        name: "fullRateCategory",
        type: "text",
        options: [],
      },
      label: "Full rate category",
      selectedOption: null,
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    fullRateAccountCode: {
      elementType: "select",
      elementConfig: {
        name: "fullRateAccountCode",
        type: "text",
        options: [],
      },
      label: "Full rate account code",
      selectedOption: null,
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    communityAccountCode: {
      elementType: "select",
      elementConfig: {
        name: "communityAccountCode",
        type: "text",
        options: [],
      },
      label: "Community account code",
      selectedOption: null,
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    fullRateDesc: {
      elementType: "input",
      elementConfig: {
        name: "fullRateDesc",
        type: "text",
      },
      label: "Full rate description",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    favaAwarded: {
      elementType: "input",
      elementConfig: {
        name: "favaAwarded",
        type: "number",
      },
      label: "Organization Bucks awarded per Hour of Volunteer Work",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    dashWeb: {
      elementType: "input",
      elementConfig: {
        name: "dashWeb",
        type: "text",
      },
      label: "Dashboard Website",
      value: "",
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    timezone: {
      elementType: "select",
      elementConfig: {
        name: "timezone",
        type: "text",
        options: timezone,
        required: true,
      },
      label: "Timezone",
      value: "",
      selectedOption: {},
      style: { minWidth: "100%", maxWidth: "100%" },
    },
    logo: {
      elementType: "input",
      elementConfig: { name: "logo", type: "file" },
      label: "Logo",
    },
    login_logo: {
      elementType: "input",
      elementConfig: { name: "login_logo", type: "file" },
      label: "Login Logo",
    },
    logoPreview: "",
    login_logoPreview: "",
    id: null,
    gstCheckbox: false,
    pstCheckbox: false,
    hstCheckbox: false,
    billingForAsset: "Yes",
    billingForProgram: "Yes",
    loading: true,
    disabled: false,
  }

  componentDidMount() {
    axios
      .get(`/organizations/formdata/`, { cancelToken: this.signal.token })
      .then((res) => {
        const {
          current_organization,
          full_rate_category,
          full_rate_account_code,
          community_account_code,
        } = res.data.json
        let fullRateAccountCodeValue,
          fullRateAccountCodeLabel,
          fullRateCategoryLabel,
          fullRateCategoryValue,
          communityAccountCodeLabel,
          communityAccountCodeValue
        if (current_organization.full_rate_account_code_id) {
          const fullRateAccountCode =
            full_rate_account_code &&
            full_rate_account_code.filter(
              (data) =>
                data[0] === current_organization.full_rate_account_code_id
            )[0]
          fullRateAccountCodeValue = fullRateAccountCode[0]
          fullRateAccountCodeLabel =
            fullRateAccountCode[2] + " - " + fullRateAccountCode[1]
        }
        if (current_organization.full_rate_category) {
          const fullRateCategory = full_rate_category.filter(
            (data) => data[0] === current_organization.full_rate_category
          )[0]
          fullRateCategoryLabel = fullRateCategory[1]
          fullRateCategoryValue = fullRateCategory[0]
        }
        if (current_organization.community_account_code_id) {
          const communityAccountCode = community_account_code.filter(
            (data) => data[0] === current_organization.community_account_code_id
          )[0]
          communityAccountCodeLabel =
            communityAccountCode[2] + " - " + communityAccountCode[1]
          communityAccountCodeValue = communityAccountCode[0]
        }
        this.setState({
          id: current_organization.id,
          fullName: {
            ...this.state.fullName,
            value: current_organization.full_name,
          },
          abbrName: {
            ...this.state.abbrName,
            value: current_organization.name,
          },
          orgnWeb: {
            ...this.state.orgnWeb,
            value: current_organization.website,
          },
          phoneNumbers: {
            ...this.state.phoneNumbers,
            value: current_organization.phone_numbers,
          },
          mailingAddress: {
            ...this.state.mailingAddress,
            value: current_organization.mailing_address,
          },
          officeEmail: {
            ...this.state.officeEmail,
            value: current_organization.office_email,
          },
          gstNumber: {
            ...this.state.gstNumber,
            value: current_organization.gst_number,
          },
          gstRate: {
            ...this.state.gstRate,
            value: current_organization.gst_rate,
          },
          pstNumber: {
            ...this.state.pstNumber,
            value: current_organization.pst_number,
          },
          pstRate: {
            ...this.state.pstRate,
            value: current_organization.pst_rate,
          },
          hstNumber: {
            ...this.state.hstNumber,
            value: current_organization.hst_number,
          },
          hstRate: {
            ...this.state.hstRate,
            value: current_organization.hst_rate,
          },
          creditName: {
            ...this.state.creditName,
            value: current_organization.credit_name,
          },
          communityDesc: {
            ...this.state.communityDesc,
            value: current_organization.community_description,
          },
          dashWeb: {
            ...this.state.dashWeb,
            value: current_organization.dashboard_website,
          },
          fullRateCategory: {
            ...this.state.fullRateCategory,
            elementConfig: {
              ...this.state.fullRateCategory.elementConfig,
              options: full_rate_category.map((item) => {
                return { value: item[0], label: item[1] }
              }),
            },
            selectedOption: fullRateCategoryValue &&
              fullRateCategoryLabel && {
                value: fullRateCategoryValue,
                label: fullRateCategoryLabel,
              },
          },
          fullRateAccountCode: {
            ...this.state.fullRateAccountCode,
            elementConfig: {
              ...this.state.fullRateAccountCode.elementConfig,
              options: full_rate_account_code.map((type) => {
                return { value: type[0], label: type[2] + " - " + type[1] }
              }),
            },
            selectedOption: fullRateAccountCodeLabel &&
              fullRateAccountCodeValue && {
                value: fullRateAccountCodeValue,
                label: fullRateAccountCodeLabel,
              },
          },
          communityAccountCode: {
            ...this.state.communityAccountCode,
            elementConfig: {
              ...this.state.communityAccountCode.elementConfig,
              options: community_account_code.map((type) => {
                return { value: type[0], label: type[2] + " - " + type[1] }
              }),
            },
            selectedOption: communityAccountCodeLabel &&
              communityAccountCodeValue && {
                value: communityAccountCodeValue,
                label: communityAccountCodeLabel,
              },
          },
          fullRateDesc: {
            ...this.state.fullRateDesc,
            value: current_organization.full_rate_description,
          },
          favaAwarded: {
            ...this.state.favaAwarded,
            value: current_organization.hourly_bonus_for_volunteer_work,
          },
          timezone: {
            ...this.state.timezone,
            selectedOption: current_organization.timezone && {
              label: current_organization.timezone,
              value: current_organization.timezone,
            },
          },
          billingForAsset:
            current_organization.community_support_billing_for_asset === true
              ? "Yes"
              : "No",
          billingForProgram:
            current_organization.community_support_billing_for_program === true
              ? "Yes"
              : "No",
          gstCheckbox: current_organization.is_gst_used,
          hstCheckbox: current_organization.is_hst_used,
          pstCheckbox: current_organization.is_pst_used,
          loading: false,
          logoPreview: current_organization.logo.url,
          login_logoPreview: current_organization.login_logo.url,
        })
      })
      .catch((error) => error)
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  closeModal = () => {
    this.props.closeModal()
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {}
    if (key === "logo" || key === "login_logo") {
      inputValue.file = event.target.files[0]
    } else {
      inputValue.value = event.target.value
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState({
      [key]: updatedcontrols,
      logoPreview:
        key === "logo"
          ? URL.createObjectURL(event.target.files[0])
          : this.state.logoPreview,
    })
  }

  dropdownChangedHandler = (event, key) => {
    const inputValue = {}
    inputValue.selectedOption = event
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState({ [key]: updatedcontrols })
  }

  toggleHandler = (event, key) => {
    this.setState({
      [key]: {
        ...this.state[key],
        elementConfig: {
          ...this.state[key].elementConfig,
          checked: !this.state[key].elementConfig.checked,
        },
      },
    })
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({ disabled: true })
    const updatedData = {}
    const {
      fullName,
      abbrName,
      orgnWeb,
      phoneNumbers,
      mailingAddress,
      officeEmail,
      gstNumber,
      gstRate,
      pstNumber,
      pstRate,
      hstNumber,
      hstRate,
      creditName,
      communityDesc,
      fullRateCategory,
      fullRateAccountCode,
      communityAccountCode,
      fullRateDesc,
      favaAwarded,
      dashWeb,
      timezone,
      billingForAsset,
      billingForProgram,
      logo,
      login_logo
    } = this.state
    updatedData.full_name = fullName.value
    updatedData.name = abbrName.value
    updatedData.website = orgnWeb.value
    updatedData.phone_numbers = phoneNumbers.value
    updatedData.mailing_address = mailingAddress.value
    updatedData.office_email = officeEmail.value
    updatedData.gst_number = gstNumber.value
    updatedData.gst_rate = gstRate.value
    updatedData.pst_number = pstNumber.value
    updatedData.pst_rate = pstRate.value
    updatedData.hst_number = hstNumber.value
    updatedData.hst_rate = hstRate.value
    updatedData.credit_name = creditName.value
    updatedData.community_description = communityDesc.value
    updatedData.dashboard_website = dashWeb.value
    updatedData.full_rate_category = fullRateCategory.selectedOption
      ? fullRateCategory.selectedOption.value
      : null
    updatedData.full_rate_account_code_id = fullRateAccountCode.selectedOption
      ? fullRateAccountCode.selectedOption.value
      : null
    updatedData.community_account_code_id = communityAccountCode.selectedOption
      ? communityAccountCode.selectedOption.value
      : null
    updatedData.full_rate_description = fullRateDesc.value
    updatedData.hourly_bonus_for_volunteer_work = favaAwarded.value
    updatedData.timezone =
      timezone.selectedOption && timezone.selectedOption.value
    updatedData.community_support_billing_for_asset = billingForAsset === "Yes"
    updatedData.community_support_billing_for_program =
      billingForProgram === "Yes"
    updatedData.logo = logo.file ? logo.file : null
    updatedData.login_logo = login_logo.file ? login_logo.file : null
    const formData = new FormData()
    for (const key in updatedData) {
      if (updatedData[key]) {
        formData.append(key, updatedData[key])
      } else {
        formData.append(key, "")
      }
    }

    const { pstCheckbox, hstCheckbox, gstCheckbox } = this.state
    if (typeof pstCheckbox === "boolean")
      formData.append("is_pst_used", pstCheckbox)
    if (typeof hstCheckbox === "boolean")
      formData.append("is_hst_used", hstCheckbox)
    if (typeof gstCheckbox === "boolean")
      formData.append("is_gst_used", gstCheckbox)

    axios
      .put(`/organizations/${this.state.id}`, formData)
      .then((res) => {
        if (res.data.json.success) {
          localStorage.setItem("organizationTimezone", updatedData.timezone)
          toast.success("Your information has successfully updated")
          this.setState({ disabled: false })
          if (this.state.id === parseInt(localStorage.organizationId)) {
            localStorage.setItem("logoUrl", logo.file)
          }
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.json.errors[error])
            })
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
          this.setState({ disabled: false })
        }
      })
      .catch((err) =>
        toast.error(
          _.isEmpty(err.message) ? "Something Went Wrong" : err.message
        )
      )
  }

  render() {
    const {
      fullName,
      abbrName,
      orgnWeb,
      phoneNumbers,
      mailingAddress,
      officeEmail,
      gstNumber,
      gstRate,
      pstNumber,
      pstRate,
      hstNumber,
      hstRate,
      creditName,
      communityDesc,
      fullRateCategory,
      fullRateAccountCode,
      communityAccountCode,
      fullRateDesc,
      favaAwarded,
      dashWeb,
      timezone,
      billingForAsset,
      billingForProgram,
      logo,
      login_logo
    } = this.state
    return (
      <div className="basic-information p-3">
        <div className="row border-bottom">
          <div className="col-12">
            <h5 className="font-weight-bold">Basic Information</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form className="mt-3" onSubmit={this.submitHandler}>
              {this.state.loading ? (
                <Spinner />
              ) : (
                <div className="form-row pt-3">
                  <div className="col-lg-4">
                    <Input
                      {...fullName}
                      changed={(event) =>
                        this.inputChangedHandler(event, "fullName")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      {...abbrName}
                      changed={(event) =>
                        this.inputChangedHandler(event, "abbrName")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      {...orgnWeb}
                      changed={(event) =>
                        this.inputChangedHandler(event, "orgnWeb")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      {...phoneNumbers}
                      changed={(event) =>
                        this.inputChangedHandler(event, "phoneNumbers")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      {...mailingAddress}
                      changed={(event) =>
                        this.inputChangedHandler(event, "mailingAddress")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      {...officeEmail}
                      changed={(event) =>
                        this.inputChangedHandler(event, "officeEmail")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      {...gstNumber}
                      changed={(event) =>
                        this.inputChangedHandler(event, "gstNumber")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      {...pstNumber}
                      changed={(event) =>
                        this.inputChangedHandler(event, "pstNumber")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      {...hstNumber}
                      changed={(event) =>
                        this.inputChangedHandler(event, "hstNumber")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <div className="use-checkbox">
                      <input
                        type="checkbox"
                        className="cursor"
                        checked={this.state.gstCheckbox}
                        onClick={() =>
                          this.setState({
                            gstCheckbox: !this.state.gstCheckbox,
                          })
                        }
                        id="gst_rate_checkbox"
                      />{" "}
                      <label className="cursor" htmlFor="gst_rate_checkbox">
                        Use
                      </label>
                    </div>
                    <Input
                      {...gstRate}
                      changed={(event) =>
                        this.inputChangedHandler(event, "gstRate")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <div className="use-checkbox">
                      <input
                        type="checkbox"
                        className="cursor"
                        checked={this.state.pstCheckbox}
                        onClick={() =>
                          this.setState({
                            pstCheckbox: !this.state.pstCheckbox,
                          })
                        }
                        id="pst_rate_checkbox"
                      />{" "}
                      <label className="cursor" htmlFor="pst_rate_checkbox">
                        Use
                      </label>
                    </div>
                    <Input
                      {...pstRate}
                      changed={(event) =>
                        this.inputChangedHandler(event, "pstRate")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <div className="use-checkbox">
                      <input
                        type="checkbox"
                        className="cursor"
                        checked={this.state.hstCheckbox}
                        onClick={() =>
                          this.setState({
                            hstCheckbox: !this.state.hstCheckbox,
                          })
                        }
                        id="hst_rate_checkbox"
                      />{" "}
                      <label className="cursor" htmlFor="hst_rate_checkbox">
                        Use
                      </label>
                    </div>
                    <Input
                      {...hstRate}
                      changed={(event) =>
                        this.inputChangedHandler(event, "hstRate")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <div className="margin-top-22">
                      <Input
                        {...creditName}
                        changed={(event) =>
                          this.inputChangedHandler(event, "creditName")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <Input
                      {...favaAwarded}
                      changed={(event) =>
                        this.inputChangedHandler(event, "favaAwarded")
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <div className="margin-top-22">
                      <Input
                          {...dashWeb}
                          changed={(event) =>
                            this.inputChangedHandler(event, "dashWeb")
                          }
                        />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <Input
                      {...fullRateCategory}
                      changed={(event) =>
                        this.dropdownChangedHandler(event, "fullRateCategory")
                      }
                    />
                  </div>
                  <div className="col-lg-12">
                    <Input
                      {...fullRateAccountCode}
                      changed={(event) =>
                        this.dropdownChangedHandler(
                          event,
                          "fullRateAccountCode"
                        )
                      }
                    />
                  </div>
                  <div className="col-lg-12">
                    <Input
                      {...fullRateDesc}
                      changed={(event) =>
                        this.inputChangedHandler(event, "fullRateDesc")
                      }
                    />
                  </div>
                  <div className="col-lg-12">
                    <Input
                      {...communityAccountCode}
                      changed={(event) =>
                        this.dropdownChangedHandler(
                          event,
                          "communityAccountCode"
                        )
                      }
                    />
                  </div>

                  <div className="col-lg-12">
                    <Input
                      {...communityDesc}
                      changed={(event) =>
                        this.inputChangedHandler(event, "communityDesc")
                      }
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>Community Support Billing For Asset</label>
                    <div className="my-2 row col-md-12 ml-2">
                      <div
                        className="row"
                        onClick={() =>
                          this.setState({ billingForAsset: "Yes" })
                        }
                      >
                        <div className="radio-btn cursor">
                          <div
                            className={`${
                              billingForAsset === "Yes" && "selected"
                            }`}
                          />
                        </div>
                        <div className="ml-2 cursor">{"Yes"}</div>
                      </div>
                      <div
                        className="row ml-5"
                        onClick={() => this.setState({ billingForAsset: "No" })}
                      >
                        <div className="radio-btn cursor">
                          <div
                            className={`${
                              billingForAsset === "No" && "selected"
                            }`}
                          />
                        </div>
                        <div className="ml-2 cursor">No</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Community Support Billing For Program</label>
                    <div className="my-2 row col-md-12 ml-2">
                      <div
                        className="row"
                        onClick={() =>
                          this.setState({ billingForProgram: "Yes" })
                        }
                      >
                        <div className="radio-btn cursor">
                          <div
                            className={`${
                              billingForProgram === "Yes" && "selected"
                            }`}
                          />
                        </div>
                        <div className="ml-2 cursor">{"Yes"}</div>
                      </div>
                      <div
                        className="row ml-5"
                        onClick={() =>
                          this.setState({ billingForProgram: "No" })
                        }
                      >
                        <div className="radio-btn cursor">
                          <div
                            className={`${
                              billingForProgram === "No" && "selected"
                            }`}
                          />
                        </div>
                        <div className="ml-2 cursor">No</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-3">
                <Input
                  {...timezone}
                  changed={(event) =>
                    this.dropdownChangedHandler(event, "timezone")
                  }
                />
              </div>
              <div className="mt-3">
                <Input
                  {...logo}
                  className="logoinput"
                  changed={(event) => this.inputChangedHandler(event, "logo")}
                />
              </div>
              <div className="col-12">
                {this.state.logoPreview ? (
                  <div className="row">
                    <div className="col-12 mb-5">
                      <div className="mt-3 pl-5">
                        <img
                          className="card-img-top d-block mt-3 "
                          src={this.state.logoPreview}
                          alt="Logo"
                          style={{ width: "400px" }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-3">
                <Input
                  {...login_logo}
                  className="logoinput"
                  changed={(event) => this.inputChangedHandler(event, "login_logo")}
                />
                <label>Recommended Size: 400px by 184px</label>
              </div>
              <div className="col-12">
                {this.state.login_logoPreview &&
                  <div className="row">
                    <div className="col-12 mb-5">
                      <div className="mt-3 pl-5">
                        <img
                          className="card-img-top d-block mt-3 "
                          src={this.state.login_logoPreview}
                          alt="login_logo"
                          style={{ width: "400px" }}
                        />
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="col-lg-12 d-flex justify-content-center py-4">
                <Button
                  type="secondary"
                  className="float-left mr-3"
                  clicked={() => this.props.history.push("/dashboard")}
                >
                  Cancel
                </Button>
                <FormButton
                  className="float-right ml-2"
                  disabled={this.state.disabled}
                >
                  Save
                </FormButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(BasicInformation)
