import Button from "../UI/button/Button";
import React from "react";

const ReportSent = ({ history }) => {
  return (<>
    <h4 className="text-center">Issue Report Sent. Thank you!</h4>
    <div className="text-center mt-3">
      <Button
        type="primary"
        clicked={() => {
          history.push('/dashboard')
        }}
      >
        Back to Dashboard
      </Button>
    </div>
  </>)
}

export default ReportSent
