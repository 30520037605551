import React from "react"
import { isEmpty } from "lodash"
import { Draggable, Droppable } from 'react-beautiful-dnd'
import Label from "./Label"

const LabelCategoryGroup = ({ categoryOrder, labelCategories, labelCategoryName, labels, onUpdate }) => {
  return (
    <>
      <div className="list-group">
        <div className="col-12 list-group-item border-0">
          {labelCategoryName || 'Uncategorized'}
        </div>
        <Droppable droppableId={categoryOrder}>
          {provided => (
            <div className="pl-4" {...provided.droppableProps} ref={provided.innerRef}>
              {!isEmpty(labels) && labels?.map((label, index) => (
                <Draggable key={label.id} draggableId={`${label.id}`} index={index}>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Label label={label} onUpdate={onUpdate} labelCategories={labelCategories} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  )
}

export default LabelCategoryGroup
