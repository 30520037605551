import React, { useState } from "react";
import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import Button from "../UI/button/Button";

export default function CampaignSearch({ getGrantCampaigns, loading, clearSearch }) {
  const [searchParams, setSearchParams] = useState(null)

  return(
    <div className="card">
      <div className="mt-lg-4 ml-lg-4 m-lg-0 m-4" style={{ paddingLeft: "" }}>
        <h4 className="font-weight-bold text-dark">Search</h4>
        <form
          onSubmit={e => {
            e.preventDefault()
            getGrantCampaigns(searchParams)
          }}
        >
          <i
            className="fas fa-search"
            style={{
              position: "relative",
              float: "right",
              top: 24,
              color: "#b3b3b3",
              paddingRight: 10,
            }}
          />
          <div>
            <Input
              changed={e => setSearchParams(e.target.value)}
              needPadding={true}
              style={{"paddingRight": "55px" }}
              value={searchParams || ""}
              required
            />
          </div>
          <div className='d-flex flex-row justify-content-between'>
            <Button
              className="mt-4 w-25"
              type="warning"
              clicked={() => {
                setSearchParams(null)
                clearSearch()
              }}
            >
              Clear
            </Button>
            <FormButton
              block
              className="mt-4 w-50"
              disabled={loading}
            >
            Search
          </FormButton>
          </div>
        </form>
      </div>
    </div>
  )
}
