import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import Input from "../../UI/input/Input";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import axios from "../../../configAxios";
import Spinner from "../../UI/Spinner";
import errorStatusHandler from "../../errorPages/UserNotAuthorized";

class NewSuperAdmin extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      loading: true,
      disabled: false,
      firstname: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "firstname",
          id: "firstname",
          required: true,
        },
        label: "First Name",
        value: "",
      },
      lastname: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "lastname",
          id: "lastname",
          required: true,
        },
        label: "Last Name",
        value: "",
      },
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          name: "email",
          id: "email",
          autoComplete: "new-password",
          required: true,
        },
        label: "Email",
        value: "",
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          name: "password",
          id: "password",
          autoComplete: "new-password",
          required: true,
        },
        label: "Password",
        value: "",
      },
      passwordConfirm: {
        elementType: "input",
        elementConfig: {
          type: "password",
          name: "passwordConfirm",
          id: "passwordConfirm",
          autoComplete: "new-password",
          required: true,
        },
        label: "Password Confirmation",
        value: "",
      },
    }
  }

  componentDidMount() {
    const { edit, match } = this.props;
    if (edit) {
      axios.get(`/users/${match.params.id}`).then(
        res => {
          const { user } = res.data;
          this.setState({
            loading: false,
            firstname: {
              ...this.state.firstname,
              value: user.first_name,
            },
            lastname: {
              ...this.state.lastname,
              value: user.last_name,
            },
            email: {
              ...this.state.email,
              value: user.email,
            },
            password: {
              ...this.state.password,
              elementConfig: {
                ...this.state.password.elementConfig,
                required: false,
              }
            },
            passwordConfirm: {
              ...this.state.passwordConfirm,
              elementConfig: {
                ...this.state.passwordConfirm.elementConfig,
                required: false,
              }
            },
          })
        }).catch(err => {
          toast.error("Something went wrong");
          this.props.history.push("/ams_super_admins");
        })
    } else {
      this.setState({ loading: false });
    }
  }

  inputChangeHandler = (type, key, e) => {
    const { edit } = this.props;
    this.setState({
      [key]: {
        ...this.state[key],
        value: e.target.value,
      }
    }, () => {
      if (edit)
        this.passwordValidationHandler(key);
    });
  }

  passwordValidationHandler = (key) => {
    const fields = ["password", "passwordConfirm"];
    let  validation;
    if (fields.some(field => key === field)) {
      if(fields.some(field => !!this.state[field].value)) 
        validation = true; 
      else 
        validation = false;
      fields.forEach(field => {
        this.setState({
          [field]: {
            ...this.state[field],
            elementConfig: {
              ...this.state[field].elementConfig,
              required: validation,
            }
          }
        });
      });
    }
  }

  submitHandler = e => {
    e.preventDefault();
    const { 
      firstname, 
      lastname, 
      password, 
      passwordConfirm, 
      email,
    } = this.state;
    const { edit, match, auth } = this.props;
    const params = {
      first_name: firstname.value,
      last_name: lastname.value,
      password: password.value,
      password_confirmation: passwordConfirm.value,
      email: email.value,
      type: "site_admin",
      organization_id: auth.organizationId
    };
    if (edit && ( !password.value && !passwordConfirm.value)) {
      delete params.password;
      delete params.password_confirmation;
    }

    this.setState({ disabled: true });
    
    if (edit) {
      axios.put(`/users/update_admin/${match.params.id}`, params, {
        cancelToken: this.signal.token,
      }).then(res => {
        if (res.data.json.success) {
          this.submissionSuccessHandler();
        } else {
          errorStatusHandler(res);
          this.setState({ disabled: false });
        }
      })
      .catch(err => this.setState({ disabled: false }))
    } else {
      axios.post(`/users/create_admin`, params, {
        cancelToken: this.signal.token,
      }).then(res => {
        if (res.data.json.success) {
          this.submissionSuccessHandler();
        } else {
          errorStatusHandler(res);
          this.setState({ disabled: false });
        }
      })
      .catch(err => this.setState({ disabled: false }))
    }
  }

  submissionSuccessHandler = () => {
    const { edit } = this.props;
    toast.success(`AMS Super Admin ${
      edit 
        ? "updated" 
        : "created"
    } successfully`);
    this.props.history.push("/ams_super_admins");
  }

  render() {
    const { edit } = this.props;
    const { firstname, lastname, password, passwordConfirm, email, loading, disabled } = this.state;

    return (
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <h3>
              {`${edit ? "Edit" : "Create"} Super Admin`} 
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body p-4">
                <div className="card-text">
                  {loading 
                    ? <Spinner />
                    : (
                    <form onSubmit={this.submitHandler} autoComplete="new-password">
                      <h4>Basic</h4>
                      <hr/>
                      <div className="row mb-5">
                        <div className="col-lg-6">
                          <Input 
                            {...firstname} 
                            changed={e => this.inputChangeHandler("text", "firstname", e)} 
                          />
                        </div>
                        <div className="col-lg-6">
                          <Input 
                            {...lastname} 
                            changed={e => this.inputChangeHandler("text", "lastname", e)} 
                          />
                        </div>
                        <div className="col-lg-6">
                          <Input 
                            {...email} 
                            changed={e => this.inputChangeHandler("text", "email", e)} 
                          />
                        </div>
                        <div className="col-lg-6">
                          <Input 
                            {...password} 
                            changed={e => this.inputChangeHandler("text", "password", e)} 
                          />
                        </div>
                        <div className="col-lg-6">
                          <Input 
                            {...passwordConfirm} 
                            changed={e => this.inputChangeHandler("text", "passwordConfirm", e)} 
                          />
                        </div>
                      </div>
                      <div className="text-center m-4">
                        <Button
                          disabled={disabled}
                          className="mr-2" 
                          type="secondary"
                          clicked={() => this.props.history.push("/ams_super_admins")}
                        >
                          Cancel
                        </Button>
                        <FormButton disabled={disabled} >
                          {edit ? "Update" : "Create"}
                        </FormButton>
                      </div>
                    </form>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(
  mapStateToProps,
  null
)(NewSuperAdmin);
