import React, { Component } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { withRouter } from "react-router-dom"
import { title } from "change-case"
import _ from "lodash"
import classnames from "classnames"

import Input from "../UI/input/Input"
import axios from "../../configAxios"
import FormButton from "../UI/button/FormButton"
import Spinner from "../UI/Spinner"
import Modal from "../UI/Modal"

import EditAttribute from "./EditAttribute"

import "./ShortAttributes.css"

class Crew extends Component {
  signal = axios.CancelToken.source()
  state = {
    attrType: {
      elementType: "select",
      elementConfig: {
        name: "attrType",
        placeholder: "Select Option",
        options: [],
        required: true,
        isLoading: true,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
      label: null,
    },
    attrValue: {
      elementType: "asyncCreatableSelect",
      elementConfig: {
        name: "attrValue",
        placeholder: "Search",
        required: true,
        inputLength: 1,
        createOptionPosition: "first",
        formatCreateLabel: (userInput) => `Add "${userInput}"`,
        allowCreateWhileLoading: true,
        components: {
          IndicatorSeparator: () => null,
        },
        loadOptions: (inputValue, callback) => {
          if (inputValue.length > 0)
            this.searchUserHandler(inputValue, callback)
        },
        styles: {
          menu: (styles) => ({ ...styles, zIndex: "3" }),
        },
      },
      value: null,
      selectedOption: null,
      label: null,
    },
    userEnteredCharacter: "",
    attributesData: [],
    duration: { hours: "", minutes: "", seconds: "" },
    loading: true,
    showModal: false,
    updateAttributeData: {},
    disabled: true,
    manageAttributesForm: null,
  }

  componentDidMount() {
    const type = "Crew Role"
    axios
      .get("/project_attributes/formdata", {
        params: { type },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState(
          {
            attrType: {
              ...this.state.attrType,
              elementConfig: {
                ...this.state.attrType.elementConfig,
                options: res.data.project_attributes.map((attr) => {
                  return {
                    label: attr.name,
                    value: attr.id,
                  }
                }),
                isLoading: false,
              },
            },
          },
          () => this.getAttributes()
        )
      })
    this.getUserPolicy()
  }

  getUserPolicy = () => {
    axios
      .get("/projects/policy", {
        params: { id: this.props.projects.project.id },
      })
      .then((res) => {
        this.setState({
          manageAttributesForm: res.data.json.manage_attributes_form,
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getAttributes = () => {
    const type = "Crew Role"
    const project_id = this.props.match.params.id
    axios
      .get("/project_attributes", {
        params: { type, project_id },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          attributesData: res.data.project_attributes,
          loading: false,
          disabled: false,
        })
      })
  }

  inputChangedHandler(event, key) {
    const inputValue = {}
    if (key === "attrValue") {
      this.setState({
        attrValue: {
          ...this.state.attrValue,
          value: _.isEmpty(event) ? null : event,
          selectedOption: _.isEmpty(event) ? null : event,
        },
      })
    } else {
      inputValue.selectedOption = event
      const updatedControls = {
        ...this.state[key],
        ...inputValue,
      }

      this.setState({
        [key]: updatedControls,
      })
    }
  }

  addAttributeHandler(e) {
    e.preventDefault()
    this.setState({ disabled: true })
    const attrData = {
      project_id: this.props.projects.project.id,
      project_attribute_type_id: this.state.attrType.selectedOption.value,
    }
    if (_.isEmpty(this.state.attrValue.value)) {
      toast.error("Add Role field is Empty")
      this.setState({ disabled: false })
      return
    }
    attrData.value = this.state.attrValue.value.label
    axios.post("/project_attributes", attrData).then((res) => {
      if (res.data.json.success) {
        this.setState({
          attrType: {
            ...this.state.attrType,
            selectedOption: null,
          },
          attrValue: {
            ...this.state.attrValue,
            value: null,
            selectedOption: null,
          },
          duration: { hours: "", minutes: "", seconds: "" },
          disabled: false,
        })
        toast.success("Role created successfully.")
        this.getAttributes()
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach((error) => {
            toast.error(title(error) + " " + res.data.json.errors[error])
          })
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error)
        }
        this.setState({ disabled: false })
      }
    })
  }

  deleteAttributeHandler = (e, index) => {
    const params = {
      project_id: this.props.projects.project.slug,
      id: index,
    }
    const response = window.confirm("Are you sure you want to delete?")
    if (response) {
      axios
        .delete(`/project_attributes/${index}`, { data: params })
        .then((res) => {
          if (res.data.json.success) {
            toast.success("Attribute successfully deleted.")
            this.getAttributes()
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(title(error) + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
          }
        })
    }
  }

  openModal = (updateAttributeData) => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true, updateAttributeData })
  }

  clearCrewRole = () => {
    this.setState({ attrValue: "" })
  }

  searchUserHandler = (inputValue, callback) => {
    const queryParams = {
      params: { query: inputValue },
      cancelToken: this.signal.token,
    }
    axios.get("/users/autocomplete", queryParams).then((res) => {
      callback(
        res.data.users.map((user) => ({
          label: user.full_name,
          value: `${user.id}`,
        }))
      )
    })
  }

  render() {
    const { projects } = this.props
    let list = ""
    if (this.state.loading) {
      list = <Spinner />
    } else {
      list = (
        <ul className="list-group list-group-flush mt-3">
          <li className="list-group-item">
            <div className="row">
              <div className="col-md-5">
                <span className="font-weight-bold">Role</span>
              </div>
              <div className="col-md-5">
                <span className="font-weight-bold">Name</span>
              </div>
            </div>
          </li>
          {this.state.attributesData.map((data, index) => (
            <li className="list-group-item" key={index}>
              <div className="row">
                <div className="col-md-5">
                  {data.project_attribute_type_name}
                </div>
                <div className="col-md-5">{data.value}</div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-around">
                    <i
                      className="fas fa-pencil-alt cursor"
                      onClick={(event) => this.openModal(data)}
                    />
                    <i
                      className={classnames("fas fa-trash", {
                        cursor:
                          projects &&
                          projects.project &&
                          projects.project.can_delete,
                        "cursor-not-allowed element-disabled":
                          projects &&
                          projects.project &&
                          !projects.project.can_delete,
                      })}
                      onClick={(e) => {
                        if (
                          projects &&
                          projects.project &&
                          projects.project.can_delete
                        )
                          this.deleteAttributeHandler(e, data.id)
                      }}
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )
    }

    let showList
    if (!this.state.loading && this.state.attributesData.length === 0) {
      showList = (
        <div className="col no-crew-container my-5 py-5">
          <h5 className="no-crew-heading text-center font-weight-bold">
            No Contributors
          </h5>
        </div>
      )
    } else {
      showList = <>{list}</>
    }

    return (
      <div className="crew">
        <form onSubmit={(e) => this.addAttributeHandler(e)} className="mt-4 ">
          <div className="row m-0">
            <div className="col-md-5">
              <Input
                {...this.state.attrType}
                className="select-attr"
                changed={(event) => this.inputChangedHandler(event, "attrType")}
              />
            </div>
            <div className="col-md-5 short-attr">
              <Input
                {...this.state.attrValue}
                changed={(event) =>
                  this.inputChangedHandler(event, "attrValue")
                }
                chart={this.state.userEnteredCharacter}
                onInputChange={(event) =>
                  this.setState({ userEnteredCharacter: event })
                }
              />
            </div>
            <div className="col-md-2 no-label-attr-add-button">
              <FormButton
                disabled={
                  this.state.disabled || !this.state.manageAttributesForm
                }
                style={{
                  cursor: this.state.manageAttributesForm
                    ? "pointer"
                    : "no-drop",
                }}
              >
                Add
              </FormButton>
            </div>
          </div>
        </form>

        {showList}
        <Modal
          show={this.state.showModal}
          title="Edit Role"
          hide={() => this.setState({ showModal: false })}
        >
          <EditAttribute
            closeModal={() => this.setState({ showModal: false })}
            attributesData={this.state.updateAttributeData}
            getAttributes={this.getAttributes}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  }
}

export default withRouter(connect(mapStateToProps)(Crew))
