import React, { Component } from "react";
import axios from "../../configAxios";
import Button from "../UI/button/Button";
import AllPendingApproval from './AllPendingApproval';
import DateFilter from "./DateFilter"
import MineToApprove from './MineToApprove';
import History from './History';
import { CSVLink } from "react-csv";
import dateFns from "date-fns"
import "./index.css";


class VolunteerHours extends Component {
  csvLink = React.createRef();
  signal = axios.CancelToken.source();
  state = {
    showModel: false,
    activeTab: "All Pending Approval",
    value: "all",
    tabNames: [
      // "Mine to Approve", "All Pending Approval", "History"
      { label: "Mine to Approve", value: "mine" },
      { label: "All Pending Approval", value: "all" },
      { label: "History", value: "history" },
    ],
    exportData: "",
    formattedDates: {
      formattedStart: null,
      formattedEnd: null,
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  tabChangeHandler = tab => {
    this.setState({ activeTab: tab.label, value: tab.value });
  };

  exportVolunteerCSV = () => {
    axios.get("/volunteer_tickets/export_volunteer_csv", {
      params: {
        filter: this.state.value
      },
      cancelToken: this.signal.token
    })
      .then(res => {
        this.setState({ exportData: res.data.json.data }, () => {
          this.csvLink.current.link.click();
        });
      });
  }

  onDateSubmit = (e, selectedStart, selectedEnd) => {
    e.preventDefault()

    /* Ternary usage expects either a date object or null value.
    *  Null value allows the datepicker field to remain blank,
    *  otherwise dateFns will create a new date string and pass it through.
    *  If the argument is Null, we want it to stay null in state.  */
    const formattedStartDate = selectedStart
                             ? dateFns.format(selectedStart, 'YYYY-MM-DD')
                             : selectedStart
    const formattedEndDate   = selectedEnd
                             ? dateFns.format(selectedEnd, 'YYYY-MM-DD')
                             : selectedEnd

    if (selectedStart || selectedEnd) {
      this.setState({
        ...this.state,
        formattedDates: {
          ...this.state.formattedDates,
          formattedStart: formattedStartDate,
          formattedEnd: formattedEndDate
        }
      })
    }
  }

  onDateClear = () => {
    this.setState({
      formattedDates: {
        formattedStart: null,
        formattedEnd: null
      }
    })
  }

  render() {
    const tabs = this.state.tabNames.map((tab, index) => (
      <React.Fragment key={index}>
        <li
          onClick={() => this.tabChangeHandler(tab)}
          className={this.state.activeTab === tab.label ? 'list-item d-inline text-primary' : 'list-item d-inline text-muted'}
        >
          <span className="font-weight-bold cursor mx-3">{tab.label}</span>
        </li>
        {index !== this.state.tabNames.length - 1 ?
          <span className="font-weight-bold">|</span> : null}
      </React.Fragment>
    ));
    const { formattedDates } = this.state
    let activeTab;
    switch (this.state.activeTab) {
      case "Mine to Approve":
        activeTab = <MineToApprove formattedDates={formattedDates}/>;
        break;
      case "All Pending Approval":
        activeTab = <AllPendingApproval formattedDates={formattedDates}/>;
        break;
      case "History":
        activeTab = <History formattedDates={formattedDates}/>;
        break;
      default:
        activeTab = null;
    }
    return (
      <div className="container w-100 volunteer-hour">
        <div className="row mb-3">
          <div className="col-lg-6">
            <h3>Volunteer Hours</h3>
          </div>
          <div className="col-lg-6 text-right">
            <Button type="success" clicked={this.exportVolunteerCSV}>Export
              Volunteers</Button>
          </div>
        </div>

        <div className="card">
          <div className="card-body p-0">
            <div className="card-text">
              <div className="row">
                <div className="col-12">
                  <ul className="list-inline pt-3 px-2">
                    {tabs}
                  </ul>
                </div>
                <DateFilter
                  onDateClear={this.onDateClear}
                  onDateSubmit={this.onDateSubmit}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  {activeTab}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CSVLink
          data={this.state.exportData}
          filename={`${this.state.value}.csv`}
          className="hidden"
          ref={this.csvLink}
          target="_self"
        />
      </div>
    );
  }
}

export default VolunteerHours;
