import React, { Component } from 'react';
import { BarChart, ResponsiveContainer, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts"

class DonationGraph extends Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={this.props.data}
          layout="horizontal"
          barGap="40%"
          barCategoryGap="25%"
          margin={{
            top: 20, right: 30, left: 20, bottom: 50,
          }}
        >
          <CartesianGrid stroke="#b2b2b2" vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tickMargin={15}
            {...this.props.view === "weeks"
            && {
              angle: -45,
              tick: {
                dx: -20,
                dy: 20
              }
            }}
          />
          <YAxis
            axisLine={false}
            tickMargin={15}
            type="number"
            tickLine={false}
          />
          <Tooltip />
          <Bar dataKey="donations" fill="#3478b6" />
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

export default DonationGraph
