import React, { Component } from "react"
import { toast } from "react-toastify"
import dateFns from "date-fns"
import axios from "../../configAxios"
import Button from "../UI/button/Button"
import DatePicker from "../UI/datePicker/index"
import Input from "../UI/input/Input"
import FormButton from "../UI/button/FormButton"
import Pagination from "../UI/Pagination"
import Spinner from "../UI/Spinner"
import "./UserAccessingPrograms.css"

class UserAccessingPrograms extends Component {
  constructor() {
    super()
    const currentMonthDate = new Date()
    this.signal = axios.CancelToken.source()
    this.state = {
      ticket: {
        elementType: "select",
        elementConfig: {
          name: "ticket",
          options: [
            { label: "Name", value: "name" },
            { label: "Timeframe", value: "timeframe" },
            { label: "User Type", value: "user_type" },
          ],
          required: true,
          components: {
            IndicatorSeparator: () => {
              return null
            },
          },
        },
        selectedOption: { label: "Name", value: "name" },
        label: "Sort Ticket List By",
      },
      registrations: {
        elementType: "select",
        elementConfig: {
          name: "registrations",
          options: [
            { label: "Name", value: "name" },
            { label: "Program", value: "program" },
            { label: "User Type", value: "user_type" },
          ],
          required: true,
          components: {
            IndicatorSeparator: () => {
              return null
            },
          },
        },
        selectedOption: { label: "Name", value: "name" },
        label: "Sort Registrations List By",
      },
      startDate: new Date().setMonth(currentMonthDate.getMonth() - 1),
      endDate: new Date(),
      ticketPage: 1,
      registrationsPage: 1,
      forcePage: "",
      tickets: [],
      registrationsData: [],
      statistics: {},
      reportOptions: null,
      registrationsCount: null,
      ticketsCount: null,
      disabled: true,
      ticketLoader: true,
      registrationLoader: true,
      downloading: false,
      loading: true,
    }
  }

  componentDidMount() {
    const queryParams = {
      id: "UsersAccessingProgramsAndServicesReport",
      page: 1,
      per_page: 10,
    }

    const statisticsParams = {
      id: "UsersAccessingProgramsAndServicesReport",
      ticket_page: 1,
      registration_page: 1,
    }
    this.getTickets(queryParams)
    this.getRegistrationsReport(queryParams)
    this.getStatisticsData(statisticsParams)
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  getTickets = (queryParams) => {
    axios
      .get("/reports/users_accessing_programs_and_services/tickets", {
        params: queryParams,
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          tickets: res.data["reports/users_accessing_programs_and_services"],
          ticketsCount: res.data.meta.total_count,
          ticketLoader: false,
          disabled: false,
        })
      })
  }

  getRegistrationsReport = (queryParams) => {
    axios
      .get("/reports/users_accessing_programs_and_services/registrations", {
        params: queryParams,
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          registrationsData:
            res.data["reports/users_accessing_programs_and_services"],
          registrationsCount: res.data.meta.total_count,
          registrationLoader: false,
        })
      })
  }

  getStatisticsData = (queryParams) => {
    axios
      .get("/reports/users_accessing_programs_and_services/statistics", {
        params: queryParams,
        cancelToken: this.signal.token,
      })
      .then((res) => {
        this.setState({
          statistics: res.data.json.statistics,
          loading: false,
        })
      })
  }

  ticketPaginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState({
      ticketPage: page.selected + 1,
      forcePage: page.selected,
      ticketLoader: true,
    })
    const queryParams = {
      id: "UsersAccessingProgramsAndServicesReport",
      report_options: this.state.reportOptions,
      page: page.selected + 1,
    }

    const statisticsParams = {
      id: "UsersAccessingProgramsAndServicesReport",
      ticket_page: page.selected + 1,
      registration_page: this.state.registrationsPage,
    }
    this.getTickets(queryParams)
    this.getStatisticsData(statisticsParams)
  }

  registrationPaginationHandler = (page) => {
    this.setState({
      registrationsPage: page.selected + 1,
      forcePage: page.selected,
      registrationLoader: true,
    })
    const queryParams = {
      id: "UsersAccessingProgramsAndServicesReport",
      options: this.state.reportOptions,
      page: page.selected + 1,
    }

    const statisticsParams = {
      id: "UsersAccessingProgramsAndServicesReport",
      ticket_page: this.state.ticketPage,
      registration_page: page.selected + 1,
    }
    this.getRegistrationsReport(queryParams)
    this.getStatisticsData(statisticsParams)
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {}
    inputValue.selectedOption = event

    const updatedControls = {
      ...this.state[key],
      ...inputValue,
    }

    this.setState({ [key]: updatedControls })
  }

  submitHandler = (event) => {
    event.preventDefault()
    this.setState({
      registrationLoader: true,
      ticketLoader: true,
      disabled: true,
    })
    const { startDate, endDate } = this.state
    const options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD"),
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD"),
      },
      sort_order: {
        tickets: this.state.ticket.selectedOption.value,
        registrations: this.state.registrations.selectedOption.value,
      },
    }

    this.setState({
      ticketPage: 1,
      registrationsPage: 1,
      reportOptions: options,
    })
    const queryParams = {
      id: "UsersAccessingProgramsAndServicesReport",
      page: 1,
      report_options: options,
    }
    const statisticsParams = {
      id: "UsersAccessingProgramsAndServicesReport",
      ticket_page: 1,
      registration_page: 1,
      report_options: options,
    }
    this.getTickets(queryParams)
    this.getRegistrationsReport(queryParams)
    this.getStatisticsData(statisticsParams)
  }

  downloadReport = async () => {
    try {
      if (this.state.downloading) return
      this.setState({ downloading: true })
      const { startDate, endDate } = this.state
      const report_options = {
        start: {
          year: dateFns.format(startDate, "YYYY"),
          month: dateFns.format(startDate, "MM"),
          day: dateFns.format(startDate, "DD"),
        },
        end: {
          year: dateFns.format(endDate, "YYYY"),
          month: dateFns.format(endDate, "MM"),
          day: dateFns.format(endDate, "DD"),
        },
        "report_options[sort_order][tickets]": this.state.ticket.selectedOption
          .value,
        "report_options[sort_order][registrations]": this.state.registrations
          .value,
      }
      const report = await axios.get(
        "/reports/general_membership/download_csv",
        {
          params: {
            id: "UsersAccessingProgramsAndServicesReport",
            report_options,
          },
          cancelToken: this.signal.token,
        }
      )
      if (report.data.json.generating) {
        toast.success(
          <div>
            Generating Report.
            <br />
            File will be downloaded soon.
          </div>
        )
        const timerId = setInterval(
          () => this.checkDownloadProgress(report.data.json.id),
          5000
        )
        this.setState({ timerId })
      }
    } catch (err) {
      this.setState({ downloading: false })
      console.log(err)
    }
  }

  checkDownloadProgress = async (id) => {
    try {
      const progress = await axios.get(
        "/reports/users_accessing_programs_and_services/check_generating",
        {
          params: { id },
          cancelToken: this.signal.token,
        }
      )
      if (typeof progress.data.json === "string") {
        clearInterval(this.state.timerId)
        this.setState({ downloading: false })
        toast.success("File successfully downloaded")
        window.open(progress.data.json, "_blank")
        return
      }
    } catch (e) {
      toast.error("Downloading failed")
      clearInterval(this.state.timerId)
    }
  }

  render() {
    const { tickets, registrationsData, statistics } = this.state
    const ticketsList = tickets.map((ticket) => (
      <tr key={ticket.id}>
        <td>{ticket.full_name_cached}</td>
        <td>
          {ticket.bookings.length > 0 &&
            ticket.bookings[0].user_or_program_detail.email}
        </td>
        <td>
          {ticket.bookings.length > 0 &&
          ticket.bookings[0].user_or_program_detail.is_on_mailing_list
            ? "Yes"
            : "No"}
        </td>
        <td>
          {ticket.bookings
            .map((booking) => {
              return `${booking.asset.name}`
            })
            .join(", ")}
        </td>
        <td>{ticket.get_project_name}</td>
        <td>{dateFns.format(ticket.created_at, "YYYY-MM-DD")}</td>
        <td>{ticket.is_for_a_cached}</td>
        <td>{ticket.id}</td>
        <td>{ticket.total}</td>
        <td>
          {ticket.for_program === "Yes"
            ? ticket.for_program_name
            : ticket.for_program}
        </td>
      </tr>
    ))

    const registrationsList = registrationsData.map((registration) => (
      <tr key={registration.id}>
        <td>{registration.cache.full_name_cached}</td>
        <td>{registration.user.email}</td>
        <td>{registration.user.is_on_mailing_list ? "Yes" : "No"}</td>
        <td>{registration.program_name}</td>
        <td>{registration.cache.is_for_a_cached}</td>
      </tr>
    ))
    return (
      <div className="container user-accessing w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Users Accessing Programs and Services</h3>
          </div>
          <div className="col-6 text-right">
            <Button type="success" clicked={this.downloadReport}>
              Generate CSV
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <form onSubmit={this.submitHandler}>
                    <div className="row">
                      <div className="col-lg-2">
                        <label>From</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.startDate}
                          changed={(date) => this.setState({ startDate: date })}
                        />
                      </div>
                      <div className="col-lg-2">
                        <label>To</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.endDate}
                          changed={(date) => this.setState({ endDate: date })}
                        />
                      </div>
                      <div className="col-lg-3">
                        <Input
                          {...this.state.ticket}
                          changed={(event) =>
                            this.inputChangedHandler(event, "ticket")
                          }
                        />
                      </div>
                      <div className="col-lg-3">
                        <Input
                          {...this.state.registrations}
                          changed={(event) =>
                            this.inputChangedHandler(event, "registrations")
                          }
                        />
                      </div>
                      <div className="col-lg-2 text-center">
                        <FormButton
                          className="build-report"
                          disabled={this.state.disabled}
                        >
                          Build Report
                        </FormButton>
                      </div>
                    </div>
                  </form>

                  <div className="row mt-5">
                    <div className="col-12">
                      <h5 className="font-weight-bold">Statistics</h5>
                    </div>
                  </div>
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-12">
                        <Spinner className="h-30vh" />
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-6">
                        <p>Total Tickets</p>
                        <p>Total Program Registrations</p>
                      </div>
                      <div className="col-6">
                        <p>
                          {this.state.loading
                            ? 0
                            : `${statistics.total_tickets} / ${statistics.total_tickets_sum}`}
                        </p>
                        <p>
                          {this.state.loading
                            ? 0
                            : `${statistics.total_registrations} / ${statistics.total_total_registrations_sum}`}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12">
                      <h5 className="font-weight-bold">
                        Total Tickets and Registrations by User Type
                      </h5>
                    </div>
                  </div>
                  {this.state.loading ? (
                    <Spinner className="h-30vh" />
                  ) : (
                    <div className="row">
                      <div className="col-6">
                        <p>Associate Member</p>
                        <p>General Member</p>
                        <p>Producer Member</p>
                        <p>Lifetime Member</p>
                        <p>Mailing List Member</p>
                        <p>Partner Member</p>
                        <p>Non Member</p>
                        <p>Program Reservation</p>
                      </div>
                      <div className="col-6">
                        <p>
                          {this.state.loading ? 0 : statistics.associate_member}
                        </p>
                        <p>
                          {this.state.loading ? 0 : statistics.general_member}
                        </p>
                        <p>
                          {this.state.loading ? 0 : statistics.producer_member}
                        </p>
                        <p>
                          {this.state.loading ? 0 : statistics.lifetime_member}
                        </p>
                        <p>
                          {this.state.loading
                            ? 0
                            : statistics.mailing_list_member}
                        </p>
                        <p>
                          {this.state.loading ? 0 : statistics.partner_member}
                        </p>
                        <p>{this.state.loading ? 0 : statistics.non_member}</p>
                        <p>
                          {this.state.loading
                            ? 0
                            : statistics.program_reservation}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="list mt-3">
                    <h5 className="font-weight-bold">Tickets Listing</h5>
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        style={{
                          tableLayout: this.state.ticketLoader ? "fixed" : "",
                        }}
                      >
                        <thead className="border-top">
                          <tr>
                            <th className="w-20">Name</th>
                            <th className="w-20">Email</th>
                            <th className="w-20">Permission To Contact</th>
                            <th className="w-20">Assets</th>
                            <th className="w-10">Project Name</th>
                            <th className="w-10">Ticket Created</th>
                            <th className="w-10">User Type</th>
                            <th className="w-10">Ticket #</th>
                            <th className="w-10">Ticket Value</th>
                            <th className="w-10">Program Rental</th>
                          </tr>
                        </thead>
                        <tbody>{!this.state.ticketLoader && ticketsList}</tbody>
                      </table>
                    </div>
                    {this.state.ticketLoader ? (
                      <Spinner className="h-30vh" />
                    ) : (
                      <Pagination
                        forcePage={this.state.ticketPage - 1}
                        pageCount={this.state.ticketsCount / 10}
                        handlePageClick={this.ticketPaginationHandler}
                      />
                    )}
                  </div>
                  <div className="users">
                    <h5 className="font-weight-bold">Registrations Listing</h5>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="border-top">
                          <tr>
                            <th className="w-40">Name</th>
                            <th className="w-40">Email</th>
                            <th className="w-40">Permission To Contact</th>
                            <th className="w-30">Program</th>
                            <th className="w-30">User Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!this.state.loading && registrationsList}
                        </tbody>
                      </table>
                    </div>
                    {this.state.loading && <Spinner className="h-30vh" />}
                    <Pagination
                      forcePage={this.state.registrationsPage - 1}
                      pageCount={this.state.registrationsCount / 10}
                      handlePageClick={this.registrationPaginationHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserAccessingPrograms
