import React, { useState, useEffect } from 'react'
import ApplicantFieldsSelect from "./ApplicantFieldsSelect";
import Button from "../../../../UI/button/Button"
import { toast } from "react-toastify"

export default function ApplicantDataQuestion({ question, submitNewQuestion, updateQuestion, updateQuestionState }) {
  const editingCheck = () => (!question.applicant_fields)

  const [isEditing, setIsEditing] = useState(editingCheck())
  const [applicantFields, setApplicantFields] = useState(question.applicant_fields)

  useEffect(() => {
      setIsEditing(editingCheck())
    },
    [question])

  const disabledCheck = () => {
    return (!applicantFields)
  }

  const handleSubmit = function () {
    if (isEditing) {
      let payload = {
        applicant_fields: applicantFields,
        type: question.type,
        order: question.order,
        id: question.id || question.question_id || null
      }

      if (question.id || question.question_id) {
        payload["question_id"] = question.id
        setIsEditing(false)
        updateQuestionState(payload)
        updateQuestion(payload).then(() => {
          toast.success("Successfully Updated Question!")
        })
      } else {
        updateQuestionState(payload)
        submitNewQuestion(payload)
        setIsEditing(false)
      }
    }

    if (!isEditing) {
      setIsEditing(!isEditing)
    }
  }

  return (
    <div className="d-flex flex-column mt-3 w-100">
      <ApplicantFieldsSelect
        applicantFields={applicantFields}
        setApplicantFields={setApplicantFields}
        isEditing={isEditing}
      />

      <div className='d-flex flex-row justify-content-end'>
        <Button
          className="questionSaveButton"
          type="success"
          disabled={disabledCheck()}
          clicked={() => handleSubmit()}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </div>
    </div>
  )
}
