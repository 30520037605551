import React, { Component } from "react";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";

import OrganizationsList from "./OrganizationsList";

import "./organizations.scss";

class Organizations extends Component {
  signal = axios.CancelToken.source();
  timeout = 0;
  state = {
    filters: {
      order: {
        column_name: "",
        dir: false,
      },
      query: "",
      perPage: 50,
    },
    pagination: {
      currentPage: 0,
      totalResults: 0,
    },
    loading: false,
    innerLoading: false,
    organizations: [],

    page: "list",
    organization: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ ...this.state, loading: true });
    this.updateData();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filters !== this.state.filters) {
      this.setState({
        ...this.state,
        pagination: {
          ...this.state.pagination,
          currentPage: 0,
          totalResults: 0,
        },
        innerLoading: true,
      });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.updateData();
      }, 300);
    }
    if (
      prevState.pagination.currentPage !== this.state.pagination.currentPage
    ) {
      this.setState({ ...this.state, innerLoading: true });
      this.updateData();
    }
  }

  updateData = () => {
    let params = {
      order:
        this.state.filters.order.column_name.length === 0
          ? ""
          : `{"column_name": "${
              this.state.filters.order.column_name
            }", "dir": "${
              this.state.filters.order.dir === "asc" ? "asc" : "desc"
            }"}`,
      query: this.state.filters.query,
      per_page: this.state.filters.perPage,
      page: this.state.pagination.currentPage + 1,
    };
    axios
      .get("/organizations", { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          pagination: {
            ...this.state.pagination,
            totalResults: res.data.meta.total,
          },
          organizations: res.data.organizations,
          loading: false,
          innerLoading: false,
        });
      })
      .catch(error => console.error(error));
  };

  inputChangeHandler = (event, type, category, order) => {
    if (type === "entries") {
      this.setState({
        ...this.state,
        filters: {
          ...this.state.filters,
          perPage: event !== null ? event.value : 10,
        },
      });
    } else if (type === "search") {
      this.setState({
        ...this.state,
        filters: {
          ...this.state.filters,
          query: event.target.value,
        },
      });
    } else if (type === "sort") {
      this.setState({
        ...this.state,
        filters: {
          ...this.state.filters,
          order: {
            column_name: category,
            dir: order,
          },
        },
      });
    } else {
      console.log("Invalid Event");
    }
  };

  paginationHandler = page => {
    window.scrollTo(0, 0);
    this.setState({
      ...this.state,
      pagination: {
        ...this.state.pagination,
        currentPage: page.selected,
      },
    });
  };

  clearTextHandler = () => {
    const filters = { ...this.state.filters };
    filters.query = "";
    this.setState({ filters });
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    } else {
      return (
        <OrganizationsList
          innerLoading={this.state.innerLoading}
          filters={this.state.filters}
          organizations={this.state.organizations}
          inputChangeHandler={this.inputChangeHandler}
          pagination={this.state.pagination}
          paginationHandler={this.paginationHandler}
          updateData={this.updateData}
          clearTextHandler={this.clearTextHandler}
        />
      );
    }
  }
}

export default Organizations;
