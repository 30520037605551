import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";

import Pagination from "../UI/Pagination";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Modal from "../UI/Modal";
import CenteredContainer from "../UI/container/CenteredContainer";
import errorStatusHandler from "../errorPages/UserNotAuthorized";
import langUtils from "../../utils/langUtils";

import FlagListing from "./FlagListing";
import AddNewFlag from "./AddNewFlag";

import "./index.css";

class FlagListings extends Component {
  signal = axios.CancelToken.source();
  state = {
    type: {
      elementType: "select",
      elementConfig: {
        name: "type",
        options: [],
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    flagData: null,
    selectedFlag: {},
    setEntries: 15,
    total_count: "",
    forcePage: "",
    searchCondition: false,
    searchFlagData: [],
    searchParam: "",
    selectedPage: 1,
    showModal: false,
    disable: false,
    sortCondition: false,
    sortOrder: "desc",
    sortField: null,
    loading: false
  };

  componentDidMount() {
    let flagTypeId, flagTypeName;
    if (this.props.location.search.includes("flag_type_to_list")) {
      const values = queryString.parse(this.props.location.search);
      flagTypeId = values.flag_type_to_list;
      flagTypeName = values.flagTypeName;
      this.setState({
        selectedFlag: {
          id: flagTypeId
        }
      });
      const params = {
        flag_type_to_list: flagTypeId,
        page: 1,
        per_page: this.state.setEntries
      };
      this.getFlags(params);
    } else this.getFlags();
    axios
      .get("/flags/formdata", { cancelToken: this.signal.token })
      .then(res => {
        let flagTypes = res.data.json.map(temp => {
          return { value: temp.name, id: temp.id, label: temp.name };
        });
        flagTypes.unshift({ label: "All", value: "all" });
        this.setState({
          type: {
            ...this.state.type,
            elementConfig: {
              ...this.state.type.elementConfig,
              options: flagTypes
            },
            selectedOption: this.props.location.search.includes(
              "flag_type_to_list"
            )
              ? { value: flagTypeName, id: flagTypeId, label: flagTypeName }
              : flagTypes[0]
          }
        });
      })
      .catch(error => error);
    axios.get("/sidebar/system_settings", { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          policyRes: res.data.json
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getFlags = (params = {}) => {
    this.setState({
      loading: true
    });
    axios
      .get("/flags", { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          flagData: res.data.flags,
          total_count: res.data.meta.total_count,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ flagData: [], total_count: 0 });
      });
  };

  setFlagType = () => {
    this.setState({
      type: {
        ...this.state.type,
        selectedOption: {
          label: "All",
          value: "all"
        }
      }
    })
  }

  showEntriesHandler = event => {
    this.setState({
      setEntries: event.target.value,
      flagData: null,
      total_count: "",
      sortOrder: null,
      sortField: null,
      loading: true
    });
    if (!this.state.searchCondition) {
      const params = {
        flag_type_to_list: this.state.selectedFlag.id,
        page: 1,
        per_page: event.target.value
      };

      this.getFlags(params);
    } else {
      const params = {
        page: 1,
        per_page: event.target.value,
        query: this.state.searchParam,
        flag_type_to_list: this.state.selectedFlag.id,
      };

      this.getFlags(params);
    }
  };

  inputChangedHandler = event => {
    this.setState({
      flagData: null,
      sortCondition: false,
      selectedFlag: event,
      sortOrder: null,
      sortField: null,
      total_count: 0,
      loading: true
    });
    let inputValue = {};
    inputValue["selectedOption"] = event;
    this.setState({
      type: {
        ...this.state.type,
        selectedOption: event
      }
    });
    const params = {
      page: 1,
      per_page: this.state.setEntries,
      query: this.state.searchParam
    };

    if (inputValue.selectedOption)
      params["flag_type_to_list"] = inputValue.selectedOption.id;

    axios
      .get("/flags", { params })
      .then(res => {
        this.setState({
          flagData: res.data.flags,
          searchCondition: false,
          total_count: res.data.meta.total_count,
          forcePage: 0,
          loading: false
        });
      })
      .catch(err => err);
    if (inputValue.selectedOption && inputValue.selectedOption.id) {
      this.props.history.push(
        `/flags?flag_type_to_list=${inputValue.selectedOption.id}&flagTypeName=${
        inputValue.selectedOption.label
        }`
      );
    }
    else {
      this.props.history.push("/flags");
    }
  };

  searchHandler = searchParam => {
    let queryParams, params;
    if (this.state.type.selectedOption.value !== "all") {
      params = {
        flag_type_to_list: this.state.type.selectedOption.id
      }
    }
    this.setState(
      {
        searchCondition: true,
        searchParam,
        flagData: null,
        total_count: "",
        sortCondition: false,
        sortOrder: null,
        sortField: null,
        loading: true
      },
      () => {
        setTimeout(() => {
          queryParams = {
            params: {
              per_page: this.state.setEntries,
              query: this.state.searchParam,
              ...params
            },
            cancelToken: this.signal.token
          };
          axios
            .get("/flags", queryParams)
            .then(res => {
              this.setState({
                flagData: res.data.flags,
                total_count: res.data.meta.total_count,
                forcePage: 0,
                loading: false
              });
            })
            .catch(err => err);
        }, 3000);
      }
    );
  };

  getSortedData = queryParams => {
    axios
      .get("/flags", { ...queryParams, cancelToken: this.signal.token })
      .then(res => {
        if (res.status === 200) {
          this.setState({ flagData: res.data.flags, loading: false });
        }
      })
      .catch(err => err);
  };

  sortHandler = (sortOrder, sortField) => {
    if (this.state.flagData.length > 0) {
      if (!this.state.searchCondition) {
        this.setState({
          sortOrder: sortOrder,
          sortField: sortField,
          loading: true
        });
        const queryParams = {
          params: {
            flag_type_to_list: this.state.selectedFlag.id,
            page: this.state.selectedPage,
            per_page: this.state.setEntries,
            order: JSON.stringify({ "column": sortField, "dir": sortOrder })
          }
        };
        this.getSortedData(queryParams);
      } else {
        this.setState({
          sortOrder: sortOrder,
          sortField: sortField,
          loading: true
        });
        const queryParams = {
          params: {
            page: this.state.selectedPage,
            per_page: this.state.setEntries,
            order: JSON.stringify({ "column": sortField, "dir": sortOrder }),
            query: this.state.searchParam
          }
        };
        this.getSortedData(queryParams);
      }
    }
  };

  paginationHandler = page => {
    window.scrollTo(0, 0)
    const { sortField, sortOrder } = this.state;
    let params;
    this.setState({ selectedPage: page.selected + 1, loading: true });
    if (sortField) {
      params = {
        order: JSON.stringify({ "column": sortField, "dir": sortOrder }),
      }
    }
    if (!this.state.searchCondition) {
      const queryParams = {
        params: {
          flag_type_to_list: this.state.selectedFlag.id,
          page: page.selected + 1,
          per_page: this.state.setEntries,
          ...params
        }
      };
      axios
        .get("/flags", { ...queryParams, cancelToken: this.signal.token })
        .then(res => {
          this.setState({
            flagData: res.data.flags,
            total_count: res.data.meta.total_count,
            loading: false
          });
          this.render();
        });
    } else {
      const queryParams = {
        params: {
          flag_type_to_list: this.state.selectedFlag.id,
          per_page: this.state.setEntries,
          query: this.state.searchParam,
          ...params
        }
      };
      axios
        .get("/flags", { ...queryParams, cancelToken: this.signal.token })
        .then(res => {
          this.setState({
            flagData: res.data.flags,
            total_count: res.data.meta.total_count,
            loading: false
          });
          this.render();
        });
    }
  };

  deleteHandler = index => {
    this.setState({ disable: true, loading: true });
    const { sortField, sortOrder } = this.state;
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      if (!this.state.searchCondition) {
        const queryParams = {
          params: {
            id: index
          }
        };
        axios
          .delete("/flags", queryParams)
          .then(res => {
            if (res.data.json.success) {
              let params;
              if (sortField) {
                params = {
                  order: JSON.stringify({ "column": sortField, "dir": sortOrder }),
                }
              }
              const queryParams = {
                params: {
                  flag_type_to_list: this.state.selectedFlag.id,
                  page: this.state.selectedPage,
                  per_page: this.state.setEntries,
                  ...params
                }
              };
              axios
                .get("/flags", { ...queryParams, cancelToken: this.signal.token })
                .then(res => {
                  toast.success("Flag deleted successfully.");

                  if (!res.data.flags.length) {
                    this.setState({ flagData: null });
                    const queryParams = {
                      params: {
                        flag_type_to_list: this.state.selectedFlag.id,
                        page: 1,
                        per_page: this.state.setEntries,
                        ...params
                      }
                    };
                    axios
                      .get("/flags", {
                        ...queryParams,
                        cancelToken: this.signal.token
                      })
                      .then(res => {
                        this.setState({
                          flagData: res.data.flags,
                          total_count: res.data.meta.total_count,
                          disable: false,
                          loading: false,
                          forcePage: 0
                        });
                      })
                      .catch(err => {
                        this.setState({ disable: false, loading: false });
                      });
                  } else {
                    this.setState({ flagData: res.data.flags, disable: false, loading: false });
                  }
                });
            } else {
              errorStatusHandler(res);
            }
          })
          .catch(err => {
            this.setState({ disable: false, loading: false });
          });
      } else {
        const queryParams = {
          params: {
            id: index
          }
        };
        axios
          .delete("/flags", queryParams)
          .then(res => {
            const queryParams = {
              params: {
                sort_order: this.state.sortOrder,
                sort_field_number: this.state.sortField,
                page: this.state.selectedPage,
                per_page: this.state.setEntries,
                query: this.state.searchParam
              }
            };
            axios
              .get("/flags", { ...queryParams, cancelToken: this.signal.token })
              .then(res => {
                if (!res.data.flags.length) {
                  this.setState({ flagData: null });
                  const queryParams = {
                    params: {
                      sort_order: this.state.sortOrder,
                      sort_field_number: this.state.sortField,
                      page: 1,
                      per_page: this.state.setEntries,
                      query: this.state.searchParam
                    }
                  };
                  axios
                    .get("/flags", {
                      ...queryParams,
                      cancelToken: this.signal.token
                    })
                    .then(res => {
                      this.setState({
                        flagData: res.data.flags,
                        total_count: res.data.meta.total_count,
                        disable: false,
                        loading: false,
                        forcePage: 0
                      });
                    })
                    .catch(err => {
                      this.setState({ disable: false, loading: false });
                    });
                } else {
                  this.setState({ flagData: res.data.flags, disable: false, loading: false });
                }
              })
              .catch(err => err);
          })
          .catch(err => err);
      }
    }
    else {
      this.setState({ disable: false, loading: false });
    }
  };

  addNewFlagHandler = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true, sortOrder: null, sortField: null });
  };

  getSortOrder = () => {
    let order = "";
    if (this.state.sortOrder === "desc" && this.state.columnToBeSorted === this.state.sortField) {
      order = "asc";
    } else {
      order = "desc";
    }
    return order;
  }

  render() {
    let tableBody = null;
    let flagData = this.state.flagData;
    const { loading } = this.state;

    let spinner;
    if (loading) {
      spinner = <Spinner />;
    }

    if (flagData && flagData.length > 0) {
      tableBody = (
        <tbody>
          {
            this.state.flagData.map((data, Index) => (
              <FlagListing
                disable={this.state.disable}
                searchCondition={this.state.searchCondition}
                index={Index}
                key={Index}
                data={data}
                deleteHandler={this.deleteHandler}
              />
            ))
          }
        </tbody>
      )
    }
    let tableHeading = (
      <thead>
        <th scope="col" className="w-35 cursor"
          onClick={() => {
            this.setState({ columnToBeSorted: "flag_types.name" },
              () => {
                const order = this.state.sortOrder === "desc" ? "asc" : "desc"
                this.sortHandler(order, "flag_types.name");
              })
          }}>
          <div
            className="d-flex justify-content-between"
          >
            <div>Flag Type</div>
            <div className="position-relative text-muted" style={{ top: -2 }}>
              <i
                className="fas fa-caret-up"
                style={{
                  color:
                    this.state.sortField === "flag_types.name"
                      ? this.state.sortOrder === "asc"
                        ? "#808080"
                        : "white"
                      : "#808080"
                }}
              />
              <i
                className="fas fa-caret-down cursor position-relative"
                style={{
                  top: 10,
                  right: 8.7,
                  color:
                    this.state.sortField === "flag_types.name"
                      ? this.state.sortOrder === "desc"
                        ? "#808080"
                        : "white"
                      : "#808080"
                }}
              />
            </div>
          </div>
        </th>
        <th scope="col" className="w-35 cursor"
          onClick={() => {
            this.setState({ columnToBeSorted: "description" },
              () => {
                const order = this.state.sortOrder === "desc" ? "asc" : "desc"
                this.sortHandler(order, "description");
              })
          }}>
          <div
            className="d-flex justify-content-between "

          >
            <div>Flag description </div>
            <div className="position-relative text-muted" style={{ top: -2 }}>
              <i
                className="fas fa-caret-up"
                style={{
                  color:
                    this.state.sortField === "description"
                      ? this.state.sortOrder === "asc"
                        ? "#808080"
                        : "white"
                      : "#808080"
                }}
              />
              <i
                className="fas fa-caret-down cursor position-relative"
                style={{
                  top: 10,
                  right: 8.7,
                  color:
                    this.state.sortField === "description"
                      ? this.state.sortOrder === "desc"
                        ? "#808080"
                        : "white"
                      : "#808080"
                }}
              />
            </div>
          </div>
        </th>
        <th scope="col" className="w-20 cursor"
          onClick={() => {
            this.setState({ columnToBeSorted: "created_at" },
              () => {
                const order = this.state.sortOrder === "desc" ? "asc" : "desc"
                this.sortHandler(order, "created_at");
              })
          }}>
          <div
            className="d-flex justify-content-between"
          >
            <div>Created</div>
            <div className="position-relative text-muted" style={{ top: -2 }}>
              <i
                className="fas fa-caret-up"
                style={{
                  color:
                    this.state.sortField === "created_at"
                      ? this.state.sortOrder === "asc"
                        ? "#808080"
                        : "white"
                      : "#808080"
                }}
              />
              <i
                className="fas fa-caret-down cursor position-relative"
                style={{
                  top: 10,
                  right: 8.7,
                  color:
                    this.state.sortField === "created_at"
                      ? this.state.sortOrder === "desc"
                        ? "#808080"
                        : "white"
                      : "#808080"
                }}
              />
            </div>
          </div>
        </th>
        <th scope="col" className="w-10" />
      </thead>
    );
    let aboveTable = (
      <div className="row my-4">
        <div className="col-lg-6">
          {this.state.policyRes && this.state.policyRes.flag_types &&
            <Link to="/system_settings/flag_types" className="text-info">
              Manage Flag Types
            </Link>
          }
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-6 text-lg-right text-center">
              <p className="font-weight-bold mt-2">Flag Type to list:</p>
            </div>
            <div className="col-6">
              <Input
                key="type"
                {...this.state.type}
                changed={event => this.inputChangedHandler(event)}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <span className="font-weight-bold mt-4 mr-2 ml-4 ml-lg-0">
            Show entries:
          </span>
          <select onChange={event => { this.showEntriesHandler(event); }} className="mt-4 border">
            <option value={15}>15 </option>
            <option value={10}>10</option>
            <option value={5}>5</option>
          </select>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-6 text-lg-right text-center">
              <p className="font-weight-bold mt-4">Search:</p>
            </div>
            <div className="col-6">
              <i className="fas fa-search search-icon" />
              {
                this.state.searchParam !== "" && (
                  <i
                    className="fas fa-times remove-icon cursor"
                    onClick={() => this.searchHandler("")}
                  />
                )
              }
              <Input
                elementType={"input"}
                value={this.state.searchParam}
                changed={e => this.searchHandler(e.target.value)}
                style={{ paddingRight: "55px" }}
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div className="flag-listing container w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Flags</h3>
          </div>
          <div className="col-6 text-lg-right text-center">
            <Button type="success" clicked={this.addNewFlagHandler}>
            {langUtils("txt_global_add_new", "Add New")}
            </Button>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="card-text">
              <div className="row">
                <div className="col-12">
                  {aboveTable}
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-striped"
                      style={{ tableLayout: "fixed" }}
                    >
                      {tableHeading}
                      {!this.state.loading && tableBody}
                    </table>
                    {this.state.loading && spinner}
                    {this.state.total_count ? (
                      <Pagination
                        forcePage={this.state.forcePage}
                        pageCount={this.state.total_count / this.state.setEntries}
                        handlePageClick={this.paginationHandler}
                      />
                    ) : (
                        ""
                      )}
                    {flagData && flagData.length === 0 && (
                      <CenteredContainer>
                        <h3 className="text-lg-center">No Flags Found.</h3>
                      </CenteredContainer>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Add New Flag"
          show={this.state.showModal}
          hide={() => this.setState({ showModal: false })}
          flag={true}
        >
          <AddNewFlag
            closeModal={() => this.setState({ showModal: false })}
            getFlags={this.getFlags}
            setFlagType={this.setFlagType}
          />
        </Modal>
      </div>
    );
  }
}

export default FlagListings;
