import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "../../UI/Modal";
import Update from "./Update";
import Button from "../../UI/button/Button";

import "./index.css";
import "./Sidebar.css";

class Sidebar extends Component {
  timeout = 0;
  state = {
    showUpdateModal: false,
  };

  updatedName = (formData) => {
    this.setState({ projectName: formData.name });
  };

  campaignUpdateHandler = () => {
    window.scrollTo(0, 0);
    this.setState({ showUpdateModal: true });
  };

  render() {
    const { form } = this.props;

    return (
      <div className="project-sidebar">
        <div className="card">
          <div className="card-body">
            {/* <i
              className="fas fa-pencil-alt float-right cursor"
              onClick={this.projectUpdateHandler}
            /> */}
            <div className="row my-3">
              <div className="col-12 text-center text-primary font-weight-bold">
                {form.name}
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="buttons">
                  <Button
                    type="warning"
                    style={{ width: "100%" }}
                    clicked={this.campaignUpdateHandler}
                  >
                    Edit Form Name
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Form Update"
          show={this.state.showUpdateModal}
          hide={() => this.setState({ showUpdateModal: false })}
          style={{ top: "15%" }}
        >
          <Update
            closeModal={() => this.setState({ showUpdateModal: false })}
            form={form}
          />
        </Modal>
      </div>
    );
  }
}

Sidebar.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

export default Sidebar;
