import React, { Component } from "react";
import queryString from "query-string";

import axios from "../../../configAxios";
import Spinner from "../../UI/Spinner";

import InvoiceForm from "./InvoiceForm";

import "./index.css";

class CreateInvoice extends Component {
  signal = axios.CancelToken.source();
  state = {
    accountingCodes: null,
    loading: true,
    invoiceData: null,
    editInvoice: false,
    user: null,
    accountData: null,
    invoiceType: null
  };

  async componentDidMount() {
    window.scrollTo(0, 0)
    const formDataRes = await axios.get("/invoices/formdata", {
      cancelToken: this.signal.token
    });
    let invoiceData;
    let editInvoice = false;

    let { type: invoiceType, userId, accountId } = queryString.parse(
      this.props.location.search
    );

    let user, accountData;

    if (this.props.location.pathname.includes("edit")) {
      let invoiceDataRes = await axios.get(
        `/invoices/${this.props.match.params.id}`,
        { cancelToken: this.signal.token }
      );
      const { invoice } = invoiceDataRes.data;
      invoiceData = invoice;
      invoiceType = invoice.invoice_type;
      accountData = {
        credit_name: invoice.credit_name,
        available_credits: invoice.available_credits
      };
      editInvoice = true;
    }

    if (!editInvoice && invoiceType === "General") {
      const userRes = await axios.get(`/users/${userId}`, {
        cancelToken: this.signal.token
      });
      const accountRes = await axios.get(`accounts/${accountId}`, {
        cancelToken: this.signal.token
      });
      user = userRes.data.user;
      accountData = accountRes.data.account;
    }

    this.setState({
      invoiceData,
      editInvoice,
      user,
      accountData,
      invoiceType,
      accountingCodes: formDataRes.data.json.accounting_codes.map(code => {
        return { label: code[1], value: code[0] };
      }),
      organization: formDataRes.data.json.organization,
      loading: false
    });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  render() {
    return (
      <div className="container w-100 create-invoice">
        <div>
          <div className="row">
            <div className="col-lg-6">
              {this.state.editInvoice ? (
                <h4>{this.state.invoiceData.status_string}</h4>
              ) : (
                  <h4>{`New ${
                    this.state.invoiceType ? this.state.invoiceType : ""
                    } Invoice`}</h4>
                )}
            </div>
            <div className="col-lg-6 text-right">
              <span
                className="text-right mt-3 cursor"
                onClick={() =>
                  this.props.history.goBack()
                }
              >
                {"<< Back"}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body p-3">
                  <div className="card-text">
                    {this.state.loading ? (
                      <div style={{ paddingTop: "20%" }}>
                        <Spinner />
                      </div>
                    ) : (
                        <InvoiceForm
                          accountingCodes={this.state.accountingCodes}
                          editInvoice={this.state.editInvoice}
                          invoiceData={this.state.invoiceData}
                          user={this.state.user}
                          organization={this.state.organization}
                          accountData={this.state.accountData}
                          invoiceType={this.state.invoiceType}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateInvoice;
