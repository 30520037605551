import React, { useContext } from "react";
import EditGrantCampaign from './EditGrantCampaign'
import { RefreshGrantCampaignContext } from './index'
import "./index.css";

export default function Details({ grantCampaign, isAdmin, match }){
  const refreshGrantCampaign = useContext(RefreshGrantCampaignContext)

  return (
    <div className="mx-4 my-3">
      <div className="d-flex flex-row justify-content-between">
        <h3 className="mb-5">Campaign Details</h3>
        { isAdmin() && match.params.toptab === "campaign" &&
            <div className="">
              <EditGrantCampaign grantCampaign={grantCampaign} refreshCampaignData={refreshGrantCampaign}/>
            </div>
        }
      </div>
      <div className="d-flex flex-column justify-content-between pl-3">
        <>
          <h5 className="font-weight-bold text-muted">Name:</h5>
          <p className="pl-2 text-muted">{grantCampaign.name}</p>
        </>
        <>
          <h5 className="font-weight-bold text-muted">Description:</h5>
          <p className="pl-2 text-muted">{grantCampaign.description}</p>
        </>
        <>
          <h5 className="font-weight-bold text-muted">Status:</h5>
          <p className="pl-2 text-muted">{grantCampaign.status}</p>
        </>
        <>
          <h5 className="font-weight-bold text-muted">Allow Multple Applications:</h5>
          <p className="pl-2 text-muted">{grantCampaign.allow_multiple_applications ? "Yes" : "No"}</p>
        </>
        <>
          <h5 className="font-weight-bold text-muted">Deadline:</h5>
          <p className="pl-2 text-muted">{grantCampaign.deadline}</p>
        </>
      </div>
    </div>
  );
}

