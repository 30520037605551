import React, { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import TagModal from "./TagModal"

const Label = ({ label, labelCategories, onUpdate }) => {
  const [showModal, setShowModal] = useState(false)

  const deleteTag = (id) => {
    let response = window.confirm(
      "Are you sure you want to delete this label?"
    )
    if (response) {
      axios
        .delete(`/labels/${id}`)
        .then((res) => {
          toast.success(res.data.json.message)
          onUpdate()
        })
        .catch((err) => {
          toast.error(err)
        })
    }
  }

  const updateTag = (id, tagName, tagCategoryId, tagDef) => {
    axios
      .put(`/labels/${id}`, { name: tagName, label_category_id: tagCategoryId, definition: tagDef, })
      .then((res) => {
        toast.success(res.data.json.message)
        onUpdate()
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  return (<>
    <div className="list-group-item">
      <div className="row">
        <div className="col-6 d-flex">{label.name}</div>
        <div className="col-4 text-center">{label.count}</div>
        <div className="col-2 text-right justify-content-around d-flex my-auto">
          <i
            className="fas fa-trash cursor px-1"
            title="Delete"
            onClick={() => deleteTag(label.id)}
          />
          <i
            className="fas fa-pencil-alt cursor px-1"
            title="Edit"
            onClick={() => {
              setShowModal(true)
            }}
          />
        </div>
      </div>
    </div>
    <TagModal
      label={label}
      labelCategories={labelCategories}
      showModal={showModal}
      setShowModal={setShowModal}
      clicked={(tagName, tagCategoryId, tagDef) => {
        updateTag(label.id, tagName, tagCategoryId, tagDef)
      }}
      modalTitle={'Update'}
    />
  </>)
}

export default Label
