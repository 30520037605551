import React, { Component } from "react";
import Button from "../../UI/button/Button";
import isAfter from "date-fns/is_after";
import DatePicker from "react-datepicker";
import dateFns from "date-fns";

class DateRange extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date()
  };

  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;
    if (isAfter(startDate, endDate)) {
      endDate = startDate;
    }
    this.setState({
      startDate,
      endDate
    })
  };

  handleChangeStart = startDate => this.handleChange({ startDate });
  handleChangeEnd = endDate => this.handleChange({ endDate });

  handleSubmit = () => {
    const startDate = dateFns.format(this.state.startDate, "YYYY-MM-DD")
    const endDate = dateFns.format(this.state.endDate, "YYYY-MM-DD")
    this.props.dateRange(startDate, endDate)
  }

  render() {
    const { startDate, endDate } = this.state;
    return (
      <div className="row system-setting-accounting-codes">
        <div className="col-lg-12 my-4">
          <div className="mx-4">
            <div className="row">
              <div className="col-md">
                <div>
                  <label className="font-weight-bold">From</label>
                </div>
                <div>
                  <DatePicker
                    selected={startDate}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.handleChangeStart}
                  />
                </div>
              </div>
              <div className="col-md">
                <div>
                  <label className="font-weight-bold">To</label>
                </div>
                <div>
                  <DatePicker
                    selected={endDate}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.handleChangeEnd}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 text-center">
          <Button type="delete" clicked={() => this.props.closeModal()}>
            Cancel
          </Button>
          <Button
            type="success"
            className="ml-2"
            clicked={this.handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default DateRange;
