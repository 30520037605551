import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";

import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import axios from "../../../configAxios";
import CreatableSelect from 'react-select/lib/Creatable';
import ModalSpinner from "../../UI/ModalSpinner";

class EditLibrary extends Component {
  signal = axios.CancelToken.source();
  state = {
    formDetail: [],
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true
      },
      label: "Name",
      value: ""
    },
    options: [],
    loading: true,
    disabled: false
  };

  closeModal = () => {
    this.props.closeModal();
  };

  componentDidMount() {
    axios.get(`form_options/${this.props.id}`, { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          formDetail: res.data.form_option,
          loading: false
        })
        if (res.data.form_option.options) {
          this.setState({
            ...this.state,
            options: res.data.form_option.options.split(",").map(item => {
              return { label: item, value: item }
            })
          })
        }
      });
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  handleChange = (value, label) => {
    this.setState({ options: value })
  }

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const items = [];
    items.push(
      this.state.options.map(item => {
        return item.value
      })
    );
    const itemsList = items.join(",");
    let itemsData = {};
    itemsData["id"] = this.props.id;
    itemsData["options"] = itemsList;
    axios
      .put(`form_options`, itemsData)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Library has successfully edited");
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
        this.props.closeModal();
        this.props.getLibraryList();
        this.setState({ disabled: false })
      });

  }

  render() {
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        {
          this.state.loading ? <ModalSpinner /> : (
            <div className="p-3">
              <div className="row my-2">
                <div className="col-4">Form :</div>
                <div className="col-8">{this.state.formDetail.klass_name}</div>
              </div>
              <div className="row my-2">
                <div className="col-4">Field :</div>
                <div className="col-8">{this.state.formDetail.field_name}</div>
              </div>
              <div className="row my-2">
                <div className="col-4">Options</div>
                <div className="col-8" key={this.state.options}>
                  <CreatableSelect
                    isMulti
                    defaultValue={this.state.options}
                    onChange={this.handleChange}
                    options={this.state.options}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <Button type="secondary" clicked={this.props.closeModal}>
                    Cancel
                  </Button>
                  <FormButton className="ml-2" disabled={this.state.disabled}>Update Options</FormButton>
                </div>
              </div>
            </div>
          )
        }
      </form>
    );
  }
}

export default EditLibrary;
