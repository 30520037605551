import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { title, snakeCase } from "change-case";
import TabContent from "./TabContent";
import Input from "../UI/input/Input";
import axios from "../../configAxios";
import queryString from "query-string";
import Spinner from "../UI/Spinner";
import Pagination from "../UI/Pagination";
import "./SearchnBrowse.css";

const active = {
  color: "#007bff"
};

const inActive = {
  color: "#666666"
};

const tabNames = ["All Media", "Video", "Film", "Print", "Audio"];

class SearchnBrowse extends Component {
  signal = axios.CancelToken.source();
  timeout = 0;
  state = {
    search: {
      elementType: "input",
      elementConfig: {
        name: "search",
        type: "text"
      },
      value: ""
    },
    activeTab: "All Media",
    activeTabContent: null,
    searchResult: [],
    selectedPage: 1,
    totalCount: 0,
    startingItem: 0,
    endingItem: 10,
    setEntries: 10,
    searchCondition: false,
    loading: true,
    forcePage: "",
    showUpIcon: true,
    showDownIcon: false,
    sortColNumber: 1,
    sortOrder: "asc",
    counts: {},
    videoTypes: [],
    videoType: null
  };

  componentDidMount() {
    this.handleTabFromProp();
    window.addEventListener("popstate", this.handleTabFromProp);
    if (this.props.location.search.includes("?type")) {
      const values = queryString.parse(this.props.location.search);
      const type = values.type;
      if (type === "video") {
        this.setState({ videoType: "All" });
      }
      const tab = type.charAt(0).toUpperCase() + type.slice(1);
      this.setState({ activeTab: tab });
      this.props.tabChanged(tab, "");
      let params = {
        type: "Library",
        library_types: `["${tab}"]`,
        order: {
          column: this.state.sortColNumber,
          dir: this.state.sortOrder
        },
        page: 1,
        per_page: 10,
        is_index_for_booking_page: false
      };

      axios
        .get("/assets", { params, cancelToken: this.signal.token })
        .then(res => {
          this.setState({
            activeTabContent: res.data.assets,
            totalCount: res.data.meta.total_count,
            counts: res.data.meta,
            startingItem: res.data.meta.total_count === 0 ? 0 : 1,
            endingItem:
              this.state.setEntries < res.data.meta.total_count
                ? this.state.setEntries
                : res.data.meta.total_count,
            loading: false
          });
        });
    } else {
      axios
        .get("/assets", {
          params: {
            type: "Library",
            library_types:
              this.state.activeTab === "All Media"
                ? ""
                : `["${this.state.activeTab}"]`,
            order: {
              column: this.state.sortColNumber,
              dir: this.state.sortOrder
            },
            page: 1,
            per_page: 10,
            is_index_for_booking_page: false
          },
          cancelToken: this.signal.token
        })
        .then(res => {
          this.setState({
            activeTabContent: res.data.assets,
            totalCount: res.data.meta.total_count,
            startingItem: res.data.meta.total_count === 0 ? 0 : 1,
            endingItem:
              this.state.setEntries < res.data.meta.total_count
                ? this.state.setEntries
                : res.data.meta.total_count,
            counts: res.data.meta,
            loading: false
          });
        })
        .catch(err => {
          this.setState({
            activeTabContent: [],
            totalCount: 0,
            loading: false
          });
        });
    }

    axios
      .get("/assets/formdata", { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          videoTypes: res.data.json.video_type_list
        });
      });
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
    this.signal.cancel("Request is being Cancelled");
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : "All Media"
    });
  };

  tabChangeHandler = activeTab => {
    const tab = activeTab.toLowerCase();
    if (activeTab === "All Media") {
      this.props.history.push("/libraries/all_media");
    } else this.props.history.push(`/libraries/${snakeCase(tab)}?type=${tab}`);
    this.setState({
      activeTabContent: null,
      videoType: null,
      setEntries: 10,
      selectedPage: 1,
      sortColNumber: 1,
      showUpIcon: true,
      showDownIcon: false,
      activeTab,
      totalCount: 0,
      startingItem: 1,
      search: {
        ...this.state.search,
        value: ""
      },

      loading: true
    });
    this.props.tabChanged(activeTab, null);
    axios
      .get("/assets", {
        params: {
          type: "Library",
          library_types: activeTab === "All Media" ? "" : `["${activeTab}"]`,
          order: {
            column: this.state.sortColNumber,
            dir: this.state.sortOrder
          },
          page: 1,
          per_page: 10,
          is_index_for_booking_page: false
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          activeTabContent: res.data.assets,
          totalCount: res.data.meta.total_count,
          startingItem: res.data.meta.total_count === 0 ? 0 : 1,
          endingItem:
            this.state.setEntries < res.data.meta.total_count
              ? this.state.setEntries
              : res.data.meta.total_count,
          loading: false
        });
      });
  };

  videoTypeHandler = (type, activeTab) => {
    const tab = activeTab.toLowerCase();
    this.props.history.push(`/libraries/video?type=${tab}`);
    this.setState({
      activeTab,
      videoType: type,
      selectedPage: 1,
      setEntries: 10,
      sortColNumber: 1,
      showUpIcon: true,
      showDownIcon: false,
      startingItem: 1,
      totalCount: 0,
      show: !this.state.show,
      search: {
        ...this.state.search,
        value: ""
      },
      loading: true
    });
    this.props.tabChanged(activeTab, type);
    axios
      .get("/assets", {
        params: {
          type: "Library",
          library_types: `["${activeTab}"]`,
          order: {
            column: this.state.sortColNumber,
            dir: this.state.sortOrder
          },
          format_type: type === "All" ? "" : type,
          page: 1,
          per_page: this.state.setEntries,
          is_index_for_booking_page: false
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          activeTabContent: res.data.assets,
          totalCount: res.data.meta.total_count,
          startingItem: res.data.meta.total_count === 0 ? 0 : 1,
          endingItem:
            this.state.setEntries < res.data.meta.total_count
              ? this.state.setEntries
              : res.data.meta.total_count,
          loading: false
        });
      });
  };

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      forcePage: page.selected,
      loading: true
    });
    const queryParams = {
      params: {
        type: "Library",
        page: page.selected + 1,
        per_page: this.state.setEntries,
        order: {
          column: this.state.sortColNumber,
          dir: this.state.sortOrder
        },
        is_index_for_booking_page: false,
        query_string: this.state.search.value,
        library_types:
          this.state.activeTab === "All Media"
            ? ""
            : `["${this.state.activeTab}"]`,
        format_type: this.state.videoType === "All" ? "" : this.state.videoType
      }
    };
    axios
      .get("/assets", { ...queryParams, cancelToken: this.signal.token })
      .then(res => {
        const pageSelected = page.selected + 1;
        const count = this.state.setEntries * pageSelected;
        const endingItem =
          count < this.state.totalCount ? count : this.state.totalCount;
        const startingItem = count - this.state.setEntries + 1;
        this.setState({
          activeTabContent: res.data.assets,
          startingItem,
          endingItem,
          loading: false
        });
        this.render();
      });
  };

  showEntriesHandler = event => {
    const setEntries = event.target.value;
    this.setState({
      setEntries,
      selectedPage: 1,
      forcePage: "",
      loading: true
    });
    const queryParams = {
      params: {
        type: "Library",
        page: 1,
        per_page: event.target.value,
        order: {
          column: this.state.sortColNumber,
          dir: this.state.sortOrder
        },
        is_index_for_booking_page : false,
        query_string: this.state.search.value,
        library_types:
          this.state.activeTab === "All Media"
            ? ""
            : `["${this.state.activeTab}"]`,
        format_type: this.state.videoType === "All" ? "" : this.state.videoType
      }
    };
    axios
      .get("/assets", { ...queryParams, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          activeTabContent: res.data.assets,
          startingItem: res.data.meta.total_count === 0 ? 0 : 1,
          endingItem:
            this.state.setEntries < this.state.totalCount
              ? this.state.setEntries
              : this.state.totalCount,
          loading: false
        });
        this.render();
      });
  };

  sortingHandler = (column, order) => {
    this.setState({
      selectedPage: 1,
      startingItem: 1,
      sortColNumber: column,
      sortOrder: order,
      forcePage: ""
    });
    if (order === "asc") {
      this.setState({
        showDownIcon: false,
        showUpIcon: true,
        loading: true
      });
    } else {
      this.setState({
        showUpIcon: false,
        showDownIcon: true,
        loading: true
      });
    }

    const queryParams = {
      params: {
        type: "Library",
        page: 1,
        per_page: this.state.setEntries,
        query_string: this.state.search.value,
        order: {
          column: column,
          dir: order
        },
        is_index_for_booking_page : false,
        library_types:
          this.state.activeTab === "All Media"
            ? ""
            : `["${this.state.activeTab}"]`,
        format_type: this.state.videoType === "All" ? "" : this.state.videoType
      }
    };
    axios
      .get("/assets", { ...queryParams, cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          activeTabContent: res.data.assets,
          endingItem:
            this.state.setEntries < this.state.totalCount
              ? this.state.setEntries
              : this.state.totalCount,
          loading: false
        });
      });
  };

  clearQueryString = () => {
    let queryString = ""
    this.setState({
      search: {
        ...this.state.search,
        value: ""
      },
      loading: true
    }, () => {
      const queryParams = {
        params: {
          type: "Library",
          page: 1,
          per_page: this.state.setEntries,
          order: {
            column: this.state.sortColNumber,
            dir: this.state.sortOrder
          },
          is_index_for_booking_page: false,
          library_types:
            this.state.activeTab === "All Media"
              ? ""
              : `["${this.state.activeTab}"]`
        }
      };
      axios
        .get("/assets", { ...queryParams, cancelToken: this.signal.token })
        .then(res =>
          this.setState({
            activeTabContent: res.data.assets,
            totalCount: res.data.meta.total_count,
            startingItem: res.data.meta.total_count === 0 ? 0 : 1,
            endingItem:
              this.state.setEntries < res.data.meta.total_count
                ? this.state.setEntries
                : res.data.meta.total_count,
            loading: false
          })
        );
    });
    this.getLibraryList(queryString)
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    let queryString = event.target.value;
    inputValue["value"] = queryString;
    const updatedControls = {
      ...this.state[key],
      ...inputValue
    };

    this.setState({ [key]: updatedControls });
    this.getLibraryList(queryString)
  };

  getLibraryList = (queryString) => {
    this.setState({
      selectedPage: 1,
      startingItem: 1,
      loading: true,
      totalCount: 0
    });
    const queryParams = {
      params: {
        type: "Library",
        query_string: queryString === "" ? "" : queryString,
        page: 1,
        per_page: this.state.setEntries,
        order: {
          column: this.state.sortColNumber,
          dir: this.state.sortOrder
        },
        is_index_for_booking_page: false,
        library_types:
          this.state.activeTab === "All Media"
            ? ""
            : `["${this.state.activeTab}"]`
      }
    };

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      axios
        .get("/assets", { ...queryParams, cancelToken: this.signal.token })
        .then(res =>
          this.setState({
            activeTabContent: res.data.assets,
            totalCount: res.data.meta.total_count,
            startingItem: res.data.meta.total_count === 0 ? 0 : 1,
            endingItem:
              this.state.setEntries < res.data.meta.total_count
                ? this.state.setEntries
                : res.data.meta.total_count,
            loading: false
          })
        );
    }, 500);
  }

  render() {
    let removeIcon;
    if (this.state.search.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times search-browse-remove-icon cursor"
          onClick={() => this.clearQueryString()}
        />
      );
    }
    const { counts } = this.state;
    let tabContent;
    if (this.state.loading) {
      tabContent = <Spinner />;
    } else {
      if (this.state.activeTabContent.length === 0) {
        tabContent = (
          <div className="d-flex justify-content-center">No data found</div>
        );
      } else {
        tabContent = (
          <TabContent
            content={this.state.activeTabContent}
            counts={this.state.counts}
            sortingHandler={this.sortingHandler}
            showUpIcon={this.state.showUpIcon}
            showDownIcon={this.state.showDownIcon}
            sortColNumber={this.state.sortColNumber}
            activeTab={this.state.activeTab}
          />
        );
      }
    }

    const tabs = tabNames.map((tab, index) => {
      let count;
      if (tab === "All Media") {
        count = counts.libraries_count ? counts.libraries_count : 0;
      } else if (tab === "Video") {
        count = counts.videos_count ? counts.videos_count : 0;
      } else if (tab === "Audio") {
        count = counts.audios_count ? counts.audios_count : 0;
      } else if (tab === "Print") {
        count = counts.prints_count ? counts.prints_count : 0;
      } else {
        count = counts.films_count ? counts.films_count : 0;
      }

      let listItem;
      if (tab === "Video") {
        listItem = (
          <li
            className="nav-item-item dropdown"
            key={index}
            style={this.state.activeTab === tab ? active : inActive}
          >
            <p
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >{`${tab} (${count})`}</p>
            <div className="dropdown-menu">
              {this.state.videoTypes.map((type, index) => (
                <p
                  key={index}
                  onClick={() => this.videoTypeHandler(type, tab)}
                  style={this.state.videoType === type ? active : inActive}
                >
                  {type}
                </p>
              ))}
            </div>
          </li>
        );
      } else {
        listItem = (
          <li
            className={"nav-item-item"}
            key={index}
            style={this.state.activeTab === tab ? active : inActive}
          >
            <p
              className={
                this.state.activeTab === tab
                  ? "nav-link nav-link-item active"
                  : "nav-link nav-link-item"
              }
              onClick={() => this.tabChangeHandler(tab)}
            >{`${tab} (${count})`}</p>
          </li>
        );
      }
      return (
        <>
          {listItem}
          {tab === "Audio" ? "" : <span className="mt-2">/</span>}
        </>
      );
    });

    return (
      <div className="card-text search-browse">
        <div className="row">
          <div className="col-12">
            <ul className="nav mb-3 cursor">{tabs}</ul>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="list-count">
            <div className="mr-2 d-flex justify-content-between">
              {" "}
              <p className="mr-4 text-nowrap mt-2">Show entries: {" "}</p>
              <select
                style={{ borderColor: "rgb(221, 220, 220)" }}
                value={this.state.setEntries}
                onChange={event => {
                  this.showEntriesHandler(event);
                }}
                className="form-control"
                placeholder={"Select Option"}
              >
                <option>10 </option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
          </div>
          <div className="search search-browse">
            <div className="d-flex justify-content-between">
              <p className="mr-4">Search: </p>
              <i
                className="fas fa-search search-browse-search-icon"
              />
              {removeIcon}
              <Input
                {...this.state.search}
                style={{ padding: "2%", paddingRight: "55px" }}
                changed={event => this.inputChangedHandler(event, "search")}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {tabContent}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <p className="mt-3">
              Showing {this.state.startingItem} to {this.state.endingItem} of{" "}
              {this.state.totalCount} entries
            </p>
          </div>
          <div className="col-lg-6">
            {
              this.state.totalCount > 0 && (
                <Pagination
                  forcePage={this.state.selectedPage - 1}
                  pageCount={this.state.totalCount / this.state.setEntries}
                  handlePageClick={this.paginationHandler}
                />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchnBrowse);
