import React, { Component } from "react"

import Button from "../../UI/button/Button"
import AssetDetailsModal from "./AssetDetailsModal"
import "./AssetItems.css"

class AssetItem extends Component {
  timer = 0
  state = {
    openAssetModal: false,
    loadingModal: true,
    manageBookings: this.props.manageBookings,
  }

  modalCloseHandler = () => {
    this.setState(
      {
        openAssetModal: false,
      },
      () => {
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(
          () =>
            this.setState({
              loadingModal: true,
            }),
          500
        )
      }
    )
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer)
  }

  render() {
    const { asset } = this.props

    let priceTypes = ""
    asset.price_types &&
      asset.price_types.forEach((type, i) => {
        let price = i !== 0 ? "/" : ""
        price += type.length ? type[0].split("- ")[1] : ""
        priceTypes += price
      })
    const tooltip = (
      <i className="fa fa-info-circle text-muted cursor" aria-hidden="true" />
    )

    return (
      <li className="list-group-item asset-items">
        <div>
          <div className="row">
            <div className="col-md-7">
              <p>
                <span
                  className="text-primary font-weight-bold cursor"
                  onClick={() => {
                    window.scrollTo(0, 0)
                    this.setState({ openAssetModal: true })
                  }}
                >
                  {asset.name} {tooltip}
                </span>
                <br />
                <span>{asset.category_name} </span>
              </p>
            </div>
            <div className="col-md-5">
              {priceTypes !== "" && (
                <div className="text-right font-weight-bold">{priceTypes}</div>
              )}
              {asset.is_bulk_asset && (
                <div className="text-right">
                  {`Bulk: ${asset.bulk_asset.bulk_count_available}
                    of ${asset.bulk_asset.bulk_count_total} currently available`}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-9">
              <div className="d-flex justify-content-end">
                <div className="serviced-container">
                  {asset.status_text === "Being Serviced" && (
                    <div className="serviced">Being Serviced</div>
                  )}
                </div>
                {asset.is_available_to_book && (
                  <Button
                    type="success"
                    className="book-asset-button cursor"
                    disabled={
                      !(
                        asset.price_types.length > 0 &&
                        this.state.manageBookings
                      )
                    }
                    style={{
                      cursor:
                        asset.price_types.length > 0 &&
                        this.state.manageBookings
                          ? "pointer"
                          : "no-drop",
                    }}
                    clicked={() => {
                      window.scrollTo(0, 0)
                      this.props.bookAsset(true, asset)
                    }}
                  >
                    Book
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <AssetDetailsModal
          show={this.state.openAssetModal}
          hide={this.modalCloseHandler}
          asset={asset}
          loadingModal={this.state.loadingModal}
          onLoad={() => this.setState({ loadingModal: false })}
        />
      </li>
    )
  }
}

export default AssetItem
