import React, { Component } from 'react'
import { components } from "react-select";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Input from "../../UI/input/Input";

export class VerifiedEmailsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEmail: {
        elementType: "select",
        elementConfig: {
          name: "email",
          placeholder: "Select an Email",
          components: {
            IndicatorSeparator: () => null,
            Option: props => {
              const { data, innerProps, innerRef, getStyles } = props;
              return data.custom
                ? (
                  <div
                    ref={innerRef}
                    {...innerProps}
                    className="text-primary cursor text-underline-onhover"
                    style={{
                      ...getStyles("option", props),
                      cursor: "pointer"
                    }}
                    onClick={e => this.props.history.push("/system_settings/communication/email_adresses")}
                  >
                    Verify new email address...
                  </div>
                )
                : (
                  <components.Option {...props} />
                )
            }
          },
          isLoading: true,
          isSearchable: true,
          isClearable: true,
          required: true,
          options: [{ custom: true, isDisabled: true }],
        },
        customStyle: {
          menu: styles => ({ ...styles, zIndex: "3" })
        },
        selectedOption: null,
      }
    }
  }

  componentDidMount() {
    const { emailAddresses, defaultEmail } = this.props;
    const { options } = this.state.fromEmail.elementConfig;
    const emailOptions = Array.isArray(emailAddresses)
      ? emailAddresses.map(email => ({ label: email.email, value: email.id }))
      : [];
    const selectedEmail = 
      defaultEmail
        ? emailOptions.find(email => email.label === defaultEmail)
        : null;
    this.setState({
      fromEmail: {
        ...this.state.fromEmail,
        elementConfig: {
          ...this.state.fromEmail.elementConfig,
          options: [...emailOptions, ...options],
          isLoading: false,
        },
        selectedOption: selectedEmail,
      } 
    }, () => this.props.emailChangeHandler(this.state.fromEmail.selectedOption));
  }

  inputChangeHandler = e => {
    this.setState({
      fromEmail: {
        ...this.state.fromEmail,
        selectedOption: e,
      }
    }, () => this.props.emailChangeHandler(this.state.fromEmail.selectedOption));
  }

  render() {
    return (
      <Input 
        {...this.state.fromEmail}
        changed={this.inputChangeHandler}
      />
    )
  }
}

VerifiedEmailsSelect.propTypes = {
  emailChangeHandler: PropTypes.func.isRequired,
  emailAddresses: PropTypes.array.isRequired,
  defaultEmail: PropTypes.string.isRequired,
};

VerifiedEmailsSelect.defaultProps = {
  emailChangeHandler: () => null,
  emailAddresses: [],
  defaultEmail: "",
};

export default withRouter(VerifiedEmailsSelect);
