import React, { Component } from "react";
import dateFns from "date-fns";
import { toast } from "react-toastify";
import axios from "../../configAxios";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import DatePicker from "../UI/datePicker/index";
import Spinner from "../UI/Spinner";
import Pagination from "../UI/Pagination";

class Equipment extends Component {
  constructor() {
    super();
    this.signal = axios.CancelToken.source();
    this.state = {
      startDate: new Date(`01/01/${new Date().getFullYear()}`),
      endDate: new Date(),
      equipments: [],
      assetPriceTypes: [],
      forcePage: "",
      selectedPage: 1,
      reportOptions: null,
      downloading: false,
      totalCount: null,
      loading: true,
      disabled: true
    };
  }

  componentDidMount() {
    const queryParams = {
      id: "EquipmentReport",
      page: this.state.selectedPage,
      per_page: 10
    };
    this.getEquipmentReport(queryParams);
    axios
      .get("/reports/equipment/formdata", { cancelToken: this.signal.token })
      .then(res => {
        this.setState({ assetPriceTypes: res.data.json.asset_price_types });
      });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getEquipmentReport = queryParams => {
    axios
      .get("/reports/equipment", {
        params: queryParams,
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          equipments: res.data["reports/equipment"],
          totalCount: res.data.meta.total_count,
          loading: false,
          disabled: false
        });
      });
  };

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      forcePage: page.selected,
      loading: true
    });
    const queryParams = {
      id: "EquipmentReport",
      report_options: this.state.reportOptions,
      page: page.selected + 1,
      per_page: 10
    };
    this.getEquipmentReport(queryParams);
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ loading: true, selectedPage: 1, totalCount: 0, disabled: true });
    const { startDate, endDate } = this.state;
    const report_options = {
      start: {
        year: dateFns.format(startDate, "YYYY"),
        month: dateFns.format(startDate, "MM"),
        day: dateFns.format(startDate, "DD")
      },
      end: {
        year: dateFns.format(endDate, "YYYY"),
        month: dateFns.format(endDate, "MM"),
        day: dateFns.format(endDate, "DD")
      }
    };
    this.setState({ reportOptions: report_options });
    const queryParams = {
      id: "EquipmentReport",
      report_options,
      page: 1,
      per_page: 10
    };
    this.getEquipmentReport(queryParams);
  };

  downloadReport = async () => {
    try {
      if (this.state.downloading) return;
      this.setState({ downloading: true });
      const { startDate, endDate } = this.state;
      const report_options = {
        start: {
          year: dateFns.format(startDate, "YYYY"),
          month: dateFns.format(startDate, "MM"),
          day: dateFns.format(startDate, "DD")
        },
        end: {
          year: dateFns.format(endDate, "YYYY"),
          month: dateFns.format(endDate, "MM"),
          day: dateFns.format(endDate, "DD")
        }
      };
      let report = await axios.get("/reports/equipment/download_csv", {
        params: {
          id: "EquipmentReport",
          report_options
        },
        cancelToken: this.signal.token
      });
      if (report.data.json.generating) {
        toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
        let timerId = setInterval(
          () => this.checkDownloadProgress(report.data.json.id),
          5000
        );
        this.setState({ timerId });
      }
    } catch (err) {
      this.setState({ downloading: false });
      console.log(err);
    }
  };

  checkDownloadProgress = async id => {
    try {
      let progress = await axios.get("/reports/equipment/check_generating", {
        params: {
          id
        },
        cancelToken: this.signal.token
      });
      if (typeof progress.data.json === "string") {
        clearInterval(this.state.timerId);
        this.setState({ downloading: false });
        toast.success("File successfully downloaded");
        window.open(progress.data.json, "_blank");
        return;
      }
    } catch (e) {
      toast.error("Downloading failed");
      clearInterval(this.state.timerId);
    }
  };

  render() {
    const { equipments } = this.state;
    const equipmentList = equipments.map(equipment => {
      let valuesJson = {};
      this.state.assetPriceTypes.forEach(type => {
        equipment.price_types.forEach(price => {
          if (type[0] === price[1]) {
            const priceArray = price[0].split(" ");
            valuesJson[priceArray[0]] = priceArray[2];
          }
        });
      });

      const bulkItemCount = (equipment) => {
        if (equipment.bulk_asset == 1) return '/'

        return equipment.bulk_asset.bulk_count_total
      }

      return (
        <tr key={equipment.id}>
          <td>{equipment.name}</td>
          <td>{equipment.status_text}</td>
          <td style={{ width: 350 }}>{equipment.description}</td>
          <td>{equipment.included_accessories}</td>
          <td>{equipment.warranty_info}</td>
          <td>{`${equipment.barcode} ${
            equipment.serial_number ? equipment.serial_number : "--"
            }`}</td>
          <td>
            {equipment.insurance_value ? `$${equipment.insurance_value}` : ""}
          </td>
          <td>{equipment.category_name}</td>
          <td>{equipment.accounting_code && equipment.accounting_code.code}</td>
          <td>{bulkItemCount(equipment)}</td>
          <td>{dateFns.format(equipment.purchase_date, "YYYY-MM-DD")}</td>
          {this.state.assetPriceTypes.map(priceType => (
            <td>
              {valuesJson.hasOwnProperty(priceType[1])
                ? valuesJson[priceType[1]]
                : "--"}
            </td>
          ))}
        </tr>
      );
    });
    return (
      <div className="container service-kind w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>Equipment Report</h3>
          </div>
          <div className="col-6 text-right">
            <Button
              type="success"
              className="ml-3"
              clicked={this.downloadReport}
            >
              Generate CSV
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text overflow-reports-card">
                  <form onSubmit={this.submitHandler}>
                    <div className="row">
                      <div className="col-lg-3">
                        <label>From</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.startDate}
                          changed={date => this.setState({ startDate: date })}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label>To</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.endDate}
                          changed={date => this.setState({ endDate: date })}
                        />
                      </div>
                      <div className="col-lg-3">
                        <FormButton className="build-report" disabled={this.state.disabled}>Build Report</FormButton>
                      </div>
                    </div>
                  </form>
                  <div className="list mt-5 pt-3">
                    <table className="table table-bordered reports-table-layout">
                      <thead>
                        <tr>
                          <th style={{ width: 180 }}>Name</th>
                          <th style={{ width: 100 }}>Status</th>
                          <th style={{ width: 300 }}>Description</th>
                          <th style={{ width: 300 }}>Included Accessories</th>
                          <th style={{ width: 100 }}>Warranty Info</th>
                          <th style={{ width: 160 }}>Barcode/ISBN S/N</th>
                          <th style={{ width: 100 }}>Insurance Value</th>
                          <th style={{ width: 100 }}>Category</th>
                          <th style={{ width: 100 }}>Accounting Code</th>
                          <th style={{ width: 100 }}>Bulk Item Count</th>
                          <th style={{ width: 100 }}>Purchase Date</th>
                          {this.state.assetPriceTypes.map(priceType => (
                            <th style={{ width: 100 }} key={priceType[0]}>{priceType[1]}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>{!this.state.loading && equipmentList}</tbody>
                    </table>
                    {this.state.loading && <Spinner className="h-40vh" />}
                    {this.state.totalCount > 0 && (
                      <Pagination
                        forcePage={this.state.selectedPage - 1}
                        pageCount={this.state.totalCount / 10}
                        handlePageClick={this.paginationHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Equipment;
