import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.css";
import { withGetScreen } from "react-getscreen";

class pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagesDisplayed: "",
      currentSelectedPage: 1,
    };
  }
  componentWillMount() {
    if (this.props.isMobile()) {
      this.setState({ pagesDisplayed: 6 });
    } else if (this.props.isTablet()) {
      this.setState({ pagesDisplayed: 4 });
    } else {
      this.setState({ pagesDisplayed: 2 });
    }
  }
  render() {
    if (this.props.pageCount !== 0) {
      return (
        <div className={`d-flex justify-content-end w-100 custom-pagination  mt-3 ${this.props.className}`} style={this.props.style}>
          <ReactPaginate
            containerClassName={"pagination"}
            activeClassName="active"
            disabledClassName="disabled"

            breakLabel={".."}
            breakClassName={"page-item page-link text-muted"}
            breakLinkClassName={"page-link"}

            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}

            previousLabel={this.props.prevLabel ? this.props.prevLabel : "Previous"}
            previousClassName={`${(Math.ceil(this.props.pageCount) === 1) || (this.state.currentSelectedPage === 1) ? "page-item disabled" : ""}`}
            previousLinkClassName={"page-link"}

            nextLabel={this.props.nextLabel ? this.props.nextLabel : "Next"}
            nextClassName={`${(Math.ceil(this.props.pageCount) === 1) || (Math.ceil(this.props.pageCount) === this.state.currentSelectedPage) ? "page-item disabled " : ""}`}
            nextLinkClassName={"page-link"}

            disableInitialCallback={true}
            pageCount={this.props.pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={this.props.range ? this.props.range : window.screen.width < 577 ? 1 : 7}
            forcePage={this.props.forcePage}
            onPageChange={(page) => {
              this.props.handlePageClick(page);
              this.setState({currentSelectedPage: page.selected + 1});
            }}
            initialPage={
              typeof this.props.currentPage !== "undefined"
                ? this.props.currentPage
                : 0
            }
          />
        </div>
      )
    } else {
      return null;
    }
  }
}

export default withGetScreen(pagination);
