import React, { Component } from "react";

import Spinner from "./../UI/Spinner";
import Modal from "../UI/Modal";
import SearchFilter from "../common/searchFilter/SearchFilter"
import Pagination from "../UI/Pagination";
import axios from "../../configAxios";
import CreateorEditDonation from "./CreateorEditDonation";


class Donor extends Component {
  signal = axios.CancelToken.source();
  timeOut = 0;
  state = {
    loading: true,
    totalCount: 0,
    perPage: 10,
    selectedPage: 1,
    forcePage: 0,
    recognitionOptions: [],
    donationCampaigns: [],
    donations: [],
    donationCampaignOptions: [],
    userEnteredCharacter: "",
    donationId: null
  };


  componentDidMount() {
    this.getDonationCampaigns();
    this.getDonationsList();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getDonationsList = page => {
    const forPageChange = page && page.hasOwnProperty("selected");

    if (forPageChange)
      window.scrollTo(0, 0);

    this.setState({
      forcePage: forPageChange ? page.selected : 0,
      loading: true
    })

    axios
      .get("/donations", {
        params: {
          user_id: this.props.userId,
          per_page: this.state.perPage,
          ...forPageChange && { page: page.selected + 1 }
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          donations: res.data.donations,
          totalCount: res.data.meta.total,
          loading: false
        });
      });
  }

  getDonationCampaigns = () => {
    axios
      .get("/donations/formdata", {
        cancelToken: this.signal.token
      })
      .then(res => {
        let donationCampaigns = []
        res.data.json.donation_campaigns.forEach(type => {
          let { id, name } = type
          donationCampaigns[id] = name
        })

        this.setState({
          donationCampaignOptions: res.data.json.donation_campaigns.map(type => {
            return { label: type.name, value: type.id };
          }),
          donationCampaigns,
          recognitionOptions: res.data && res.data.json && res.data.json.recognition
            ? res.data.json.recognition.map(recongintion => ({ label: recongintion, value: recongintion }))
            : []
        });
      });
  }

  pageFilterHandler = perPage => {
    this.setState({
      perPage,
      loading: true
    }, () => this.getDonationsList())
  }

  render() {
    let donationList = this.state.donations && this.state.donations.map((donation, index) => {
      return (
        <li className="list-group-item" key={index}>
          <div className="row">
            <div className="col-md-10">
              <div>
                <p className="text-info font-weight-bold">
                  <span>
                    Campaign: {this.state.donationCampaigns
                      && this.state.donationCampaigns[donation.donation_campaign_id]}
                  </span>
                </p>
                <p>
                  <span>Donation Amount: {donation.amount ? `$${donation.amount}` : "---"}</span>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <i
                className="fas fa-pencil-alt cursor justify-content-start mt-2"
                onClick={() => { window.scrollTo(0, 0); this.setState({ donationId: donation.id, showEditModal: true }) }}
              />
            </div>
          </div>
        </li>
      );
    });
    return (
      <>
        {this.state.loading ? (
          <Spinner />
        ) : (
            <div>
              <CreateorEditDonation
                editDonation={false}
                userId={this.props.userId}
                donationCampaignOptions={this.state.donationCampaignOptions}
                recognitionOptions={this.state.recognitionOptions}
                getDonationsList={this.getDonationsList}
                closeModal={() => this.setState({ showEditModal: false })}
                setListLoading={() => this.setState({ loading: true })}
              />
              {this.state.donations && this.state.donations.length > 0 &&
                <div className="row">
                  <div className="offset-lg-8 col-lg-4">
                    <SearchFilter
                      perPage={this.state.perPage}
                      inputChangeHandler={this.pageFilterHandler}
                    />
                  </div>
                </div>}
              <div className="mt-5 list-group list-group-flush">
                {donationList}
              </div>
              <Pagination
                forcePage={this.state.forcePage}
                pageCount={this.state.totalCount / this.state.perPage}
                handlePageClick={this.getDonationsList}
              />
            </div>)}
        <Modal show={this.state.showEditModal}
          title="Edit Donation"
          closeModal={() => this.setState({ showEditModal: false })}
          hide={() => this.setState({ showEditModal: false })}
          closeButton
        >
          <CreateorEditDonation
            donorId={this.state.donationId}
            editDonation={true}
            userId={this.props.userId}
            donationCampaignOptions={this.state.donationCampaignOptions}
            getDonationsList={() => this.getDonationsList({ selected: this.state.forcePage })}
            recognitionOptions={this.state.recognitionOptions}
            closeModal={() => this.setState({ showEditModal: false })}
          />
        </Modal>
      </>
    )
  }
}

export default Donor;
