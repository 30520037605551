import React, { Component } from "react";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import axios from "../../../configAxios";
import { toast } from "react-toastify";
import { title } from "change-case";
import Input from "../../UI/input/Input";

class EditSetting extends Component {
  state = {
    key: {
      elementType: "select",
      elementConfig: {
        name: "settingKey",
        type: "text",
        options: [],
        required: true
      },
      label: "Key",
      selectedOption: null
    },
    settingValue: {
      elementType: "textarea",
      elementConfig: {
        name: "settingValue",
        rows: "7"
      },
      label: "Settings",
      value: ""
    },
    disabled: false
  };

  componentDidMount() {
    axios.get("/pdf_settings/formdata").then(res => {
      const { setting_types } = res.data.json;
      const options = [];
      setting_types.forEach(type => {
        Object.keys(type).forEach(key => {
          options.push({ label: type[key], value: key });
        });
      });
      this.setState({
        key: {
          ...this.state.key,
          elementConfig: {
            ...this.state.key.elementConfig,
            options
          }
        }
      });
    });
    if (this.props.isEdit) {
      this.setState({
        settingValue: {
          ...this.state.settingValue,
          value: this.props.setting_value
        },
        key: {
          ...this.state.key,
          selectedOption: {
            value: this.props.setting_key,
            label: this.props.uiValue
          }
        }
      });
    }
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    if (key === "settingValue") {
      inputValue["value"] = event.target.value;
    } else {
      inputValue["selectedOption"] = event;
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({disabled: true})
    let pdfSettingData = {};
    const { key, settingValue } = this.state;
    pdfSettingData["setting_type"] = key.selectedOption.value.toLowerCase();
    pdfSettingData["content"] = settingValue.value;

    if (this.props.isEdit) {
      axios
        .put(`/pdf_settings/${this.props.id}`, pdfSettingData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("PDF setting successfully updated");
            this.props.getPdfSettings();
            this.props.closeModal();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({disabled: false})
        })
        .catch(err => toast.error(err));
    } else {
      axios
        .post("/pdf_settings", pdfSettingData)
        .then(res => {
          if (res.data.json.success) {
            toast.success(" PDF setting has successfully created");
            this.props.closeModal();
            this.props.getPdfSettings();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({disabled: false})
        })
        .catch(err => toast.error(err));
    }
  };

  render() {
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        <div>
          <Input
            className="pdf-setting-key"
            {...this.state.key}
            disabled={this.props.isEdit ? true : false}
            changed={event => this.inputChangedHandler(event, "key")}
          />
          <Input
            {...this.state.settingValue}
            changed={event => this.inputChangedHandler(event, "settingValue")}
          />
          <FormButton className="float-right ml-2" disabled={this.state.disabled}>
            Save
          </FormButton>
          <Button type="secondary" clicked={this.props.closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}

export default EditSetting;
