import React from "react";
import { Link } from "react-router-dom";
import dateFns from "date-fns";

const InvoiceItem = props => {
  return (
    <li className="list-group-item px-3 pt-3">
      <div className="row">
        <div className="col-8">
          <Link to={`/invoices/${props.invoice.id}`} className="text-info">
            {props.invoice.name_for_reference}
          </Link>
          <p>{dateFns.format(new Date(props.invoice.invoice_date), "ddd, MMM DD, YYYY")}</p>
        </div>
        <div className="col-4 text-right d-flex flex-column justify-content-center">
          <p className={props.invoice.is_current ? 'text-muted' : 'text-success'}>
            <strong>
              {props.invoice.total_due_cached}
            </strong>
          </p>
        </div>
      </div>
    </li>
  );
};

export default InvoiceItem;