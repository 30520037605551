import React, { Component } from "react";
import { title } from "change-case"
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import axios from "../../configAxios";
import { toast } from "react-toastify";

class RegistrationModal extends Component {
  signal = axios.CancelToken.source();
  timeout = 0;
  state = {
    user: {
      elementType: "asyncSelect",
      elementConfig: {
        name: "user_id",
        placeholder: "Select User",
        required: true,
        loadOptions: (inputValue, callback) => {
          if (inputValue.length > 2) this.searchUsers(inputValue, callback);
        },
        components: {
          IndicatorSeparator: () => {
            return null;
          },
        },
      },
      label: "Assign User",
      selectedOption: null,
    },
    firstName: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "firstName",
        required: true
      },
      value: "",
      label: "First Name"
    },
    lastName: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "lastName",
        required: true
      },
      value: "",
      label: "Last Name"
    },
    contactInfo: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "contactInfo"
      },
      value: "",
      label: "Contact Info"
    },
    disabled: false,
    isExistingUser: true,
    enteredUserCharacters: ''
  };

  inputChangeHandler = (event, key) => {
    this.setState({
      [key]: {
        ...this.state[key],
        value: event.target.value
      }
    });
  };

  searchUsers = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue
      },
      cancelToken: this.signal.token
    };
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getUsers(queryParams, callback);
    }, 500);
  };

  getUsers = (queryParams, callback) => {
    axios.get("/users/autocomplete", queryParams)
      .then(res => {
        callback(
          res.data.users.map(user => {
            return {
              value: user.id,
              label: user.full_name
            };
          })
        );
      })
      .catch(err => err);
  }

  submitHandler = event => {
    event.preventDefault();
    let formData;
    if (this.state.isExistingUser) {
      formData = {
        user_id: this.state.user.selectedOption.value,
      };
    } else {
      formData = {
        floating_first_name: this.state.firstName.value,
        floating_last_name: this.state.lastName.value,
        floating_contact_info: this.state.contactInfo.value,
      };
    }
    this.setState({ disabled: true })
    axios
      .post("/registrations", {
        program_id: parseInt(this.props.programId),
        ...formData
      })
      .then(res => {
        if (res.data.json.success) {
          toast.success("Successfully Registered.");
          this.props.regUsers();
          this.props.closeModal();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
  };

  render() {
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        {this.state.isExistingUser ? (
          <Input
            {...this.state.user}
            changed={(event) => {
              this.setState({
                user: {
                  ...this.state.user,
                  selectedOption: event,
                },
              });
            }}
            onInputChange={(event) =>
              this.setState({ enteredUserCharacters: event })
            }
          />
        ) : (
          <>
            <Input
              {...this.state.firstName}
              changed={(event) => this.inputChangeHandler(event, "firstName")}
            />
            <Input
              {...this.state.lastName}
              changed={(event) => this.inputChangeHandler(event, "lastName")}
            />
            <Input
              {...this.state.contactInfo}
              changed={(event) => this.inputChangeHandler(event, "contactInfo")}
            />
          </>
        )}
        <div className="text-right my-2">
          <span
            className={
              this.state.isExistingUser ? "text-muted" : "text-primary cursor"
            }
            onClick={() => this.setState({ isExistingUser: true })}
          >
            Existing User
          </span>
          <span>&nbsp;/&nbsp;</span>
          <span
            className={
              !this.state.isExistingUser ? "text-muted" : "text-primary cursor"
            }
            onClick={() => this.setState({ isExistingUser: false })}
          >
            Non-Existent User
          </span>
        </div>
        <div className="text-center">
          <Button type="secondary" clicked={this.props.closeModal}>
            Cancel
          </Button>
          <FormButton className="ml-2" disabled={this.state.disabled}>Register</FormButton>
        </div>
      </form>
    );
  }
}

export default RegistrationModal;
