import React, { useState, useEffect } from 'react'
import ApplicationFormTable from "./ApplicationFormTable"
import axios from "../../../configAxios"
import Button from "../../UI/button/Button";
import { useDispatch } from 'react-redux'
import { deleteObject, updateObject } from "../../../store/actions/index"
import { getGrantApplications } from "../../../store/slices/grantApplicationSlice";

export default function GrantApplicationItem({ grantApplication }) {

  const [formsList, setFormsList] = useState([])

  const dispatch = useDispatch()

  const deleteGrantApplication = () => {
    const grantApplicationParams = {
      route: `/grant_applications/${grantApplication.id}`,
      data: {},
      callback: getGrantApplications,
      message: "Grant application deleted successfully"
      }

    window.confirm("Permanently delete this grant application?") && dispatch(deleteObject(grantApplicationParams)).then(() => {
      dispatch(getGrantApplications())
    })
  }

  const updateGrantApplication = (status) => {
    axios.put(`/grant_applications/${grantApplication.id}`, { status: status })
      //This dispatch getGrantApplications is a poor usage of Redux to solve this problem
      //More properly, a lot of these calls to getGrantApplications should optimistically update the store for a cleaner
      //user experience
      //This is a placeholder interim step to get more of the functionality wired into Redux.
    .then(() => dispatch(getGrantApplications()))
  }

  const submitGrantApplication = () => {
    const applicationParams = {
      route: `/grant_applications/${grantApplication.id}`,
      data: {
        status: "Submitted",
      },
      object: "grant_application",
      callback: getGrantApplications,
      message: "Grant application submitted successfully"
      }
      window.confirm("If you submit the application you will no longer be able to make any changes, would you like to proceed?") && dispatch(updateObject(applicationParams)).then(() =>{
        dispatch(getGrantApplications())
      })
  }

  const getForms = () => {
    axios.get(`/grant_campaigns/${grantApplication.grant_campaign_id}/forms`)
    .then(res => setFormsList(res.data.json.data))
  }

  useEffect(() => {
    formsList.length === 0 && getForms(grantApplication.grant_campaign_id)
  }, [grantApplication])


  const isReadyForSubmission = () => {
    const applicationForms = grantApplication.application_forms
    const status = grantApplication.status

    const formsComplete = applicationForms.find(form => form.status !== "Complete") ? false : true
    const formsExist = formsList.length > 0 && formsList.length === applicationForms.length
    const checkStatus = !["Ready for submission", "Awarded", "Submitted"].includes(status)
    return formsComplete && formsExist && checkStatus
  }

  useEffect(() => {
    isReadyForSubmission() && grantApplication.status === "Incomplete" && updateGrantApplication("Ready for submission")
  }, [formsList])

  const Actions = () => {
    return (
      <>
        {grantApplication.status == "Incomplete" && <span className="mr-1 fas fa-trash cursor" data-toggle="tooltip" data-placement="top" title="Delete" onClick={deleteGrantApplication} />}
      </>
    )
  }

  return (
    <>
      <h3 className="mt-3 pt-3">{grantApplication.grant_campaign_name}</h3>
      <div className="mb-3 text-center">
        <table className="w-100 mb-3">
          <colgroup>
            <col className="w-25" />
            <col className="w-25" />
            <col className="w-25" />
            <col className="w-25" />
          </colgroup>
          <thead>
            <tr className="">
              <th>Application Name</th>
              <th>Updated</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <thead className="border border-bottom-0">
            <tr>
              <td>{grantApplication.name}</td>
              <td>{grantApplication.updated_when}</td>
              <td>{grantApplication.status}</td>
              <td>
                <Actions />
              </td>
            </tr>
          </thead>
          <tbody className="border border-bottom-0">
            <tr>
              <td colSpan="4" className="px-0">
                <ApplicationFormTable key={grantApplication.id} forms={formsList} grantApplication={grantApplication}/>
              </td>
            </tr>
          </tbody>
          <tfoot className="border-bottom">
            <tf />
          </tfoot>
        </table>
        { grantApplication.status === "Ready for submission" &&
          <div className="d-flex flex-row justify-content-end">
            <Button type="success" clicked={submitGrantApplication}>Submit Application</Button>
          </div>
        }
      </div>
    </>
  )
}
