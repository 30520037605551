import { setResponse, clearResponse, setLoading } from "./index";
import axios from "../../configAxios";
import { capitalize } from 'lodash'

export const createQuestionResponses = (responseData, callback) => {
  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      dispatch(setLoading(true));
      const responseArray = await Promise.all(responseData.map(response => axios.post("/responses", response)))
      const response = responseArray.slice(-1)[0].data.json;
      if (response.success) {
        dispatch(setResponse(true, `Responses saved successfully`));
        callback && callback()
      } else {
        const errors = response.errors;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${capitalize(key)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};

export const updateQuestionResponses = (responseData, callback) => {
  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      dispatch(setLoading(true));
      const responseArray = await Promise.all(responseData.map(response => axios.put("/responses", response)))
      const response = responseArray.slice(-1)[0].data.json;
      if (response.success) {
        dispatch(setResponse(true, `Responses saved successfully`));
        callback && callback()
      } else {
        const errors = response.errors;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${capitalize(key)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};
