import React, { Component } from "react";

import NoImage from "../../assests/images/no-image.png";

const MediaCard = ({
  file,
  fileLink,
  editUser
}) => {
  const extension = editUser ? fileLink.split("?")[0].split(".").pop() : file[0].name.split(".").pop();
  const url = editUser && extension !== "pdf" ? fileLink 
    : file && file.length && file[0] && file[0].type !== "application/pdf" ? fileLink : NoImage;
  const filenameWithoutExt = !editUser && file[0].name && file[0].name.substring(0, file[0].name.lastIndexOf("."));
  const fileName = editUser ? file : filenameWithoutExt.substring(filenameWithoutExt.length -15, filenameWithoutExt.length);

  return (
    <div className="col-md-4 my-3">
      <div className="card border">
        <a href={fileLink} target="_blank" rel="noopener noreferrer">
          <div
            className="card-img-top bg-cover h-30vh"
            style={{
              backgroundImage:
                `url(${url})`
            }}
          />
        </a>
        <div className="card-body bg-light">
          <div className="card-text text-center text-primary font-weight-bold">
            <a href={fileLink} target="_blank" rel="noopener noreferrer">
              <span>{extension}</span>
              <p>{fileName}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaCard;
