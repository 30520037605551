import React from 'react'
import Button from "../../../UI/button/Button";
import DragDropWrapper from './DragDropWrapper'

export default function QuestionsList({ questions, setQuestions, submitNewQuestion, updateQuestion, updateQuestionState, deleteQuestion}) {

  return (
    <div>
      <DragDropWrapper questions={questions} setQuestions={setQuestions} submitNewQuestion={submitNewQuestion} updateQuestion={updateQuestion} updateQuestionState={updateQuestionState} deleteQuestion={deleteQuestion}/>
    </div>
  )
}
