import React, { Component } from 'react'
import { toast } from "react-toastify";
import axios from "../../configAxios";
import Spinner from '../UI/Spinner';
import Input from '../UI/input/Input';
import FormButton from '../UI/button/FormButton';
import Button from '../UI/button/Button';
import { title } from "change-case";
import _ from "lodash";

class AddSuperAdmin extends Component {
  signal = axios.CancelToken.source();
  state = {
    formData: {
      first_name: '',
      last_name: '',
      email: '',
      organization_id: '',
      password: '',
      password_confirmation: '',
      type: ''
    },
    input: {
      firstName: {
        elementType: "input",
        elementConfig: { name: "first_name", type: "text" },
        label: "First Name"
      },
      lastName: {
        elementType: "input",
        elementConfig: { name: "last_name", type: "text" },
        label: "Last Name"
      },
      email: {
        elementType: "input",
        elementConfig: { name: "email", type: "email", required: true },
        label: "Email"
      },
      password: {
        elementType: "input",
        elementConfig: {
          autoComplete: "new-password",
          name: "password",
          type: "password",
          required: !this.props.location.pathname.includes("edit")
        },
        label: "Password"
      },
      passwordConfirmation: {
        elementType: "input",
        elementConfig: {
          autoComplete: "new-password",
          name: "password_confirmation",
          type: "password",
          required: !this.props.location.pathname.includes("edit")
        },
        label: "Password Confirmation"
      }
    },
    type: {
      elementType: "select",
      elementConfig: {
        name: "type",
        placeholder: "Select Option",
        options: [
          { value: 'super_admin', label: 'Admin' },
          { value: 'staff', label: 'staff' }
        ],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        isClearable: true,
        required: true
      },
      selectedOption: null,
      label: "Profile"
    },
    organization: '',
    loading: true,
    organizationId: this.props.match.params.organizationid
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.props.location.pathname.includes("edit")) {
      axios
        .get(`/users/${this.props.match.params.id}`, {
          cancelToken: this.signal.token
        })
        .then(res => {
          this.setState({ role: res.data.user.role.name })
          let selectedType = [
            { value: 'super_admin', label: 'Admin' },
            { value: 'staff', label: 'staff' }
          ].filter(option => option.value === res.data.user.role.level)
            .map(option => ({ value: option.value, label: option.label }))[0]
          this.setState({
            ...this.state,
            type: {
              ...this.state.type,
              selectedOption: selectedType
            },
            formData: {
              ...this.state.formData,
              first_name: res.data.user.first_name,
              last_name: res.data.user.last_name,
              email: res.data.user.email,
              type: selectedType.value
            },
            loading: false
          })
        });
    }
    else {
      axios.get(`/organizations/${this.props.match.params.id}`, { cancelToken: this.signal.token })
        .then(res => {
          this.setState({
            ...this.state,
            formData: {
              ...this.state.formData,
              organization_id: this.props.match.params.id
            },
            loading: false
          })
        })
        .catch(error => error);
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ ...this.state, loading: true })
    if (this.props.location.pathname.includes("edit")) {
      let updatedUserData = new FormData();
      let userData = this.state.formData;
      userData["organization_id"] = this.state.organizationId;
      for (let key in userData) {
        if (userData[key])
          updatedUserData.append(key, userData[key]);
      }
      axios.put(`/users/update_admin/${this.props.match.params.id}`, updatedUserData)
        .then(res => {
          this.setState({ ...this.state, loading: false })
          if (res.data.json.success) {
            toast.success(`${title(this.state.formData.type)} updated successfully.`);
            this.props.history.push(`/organizations/${this.state.organizationId}/super_admins`);
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
        .catch(error => error)
    }
    else {
      axios.post(`/users/create_admin/`, this.state.formData)
        .then(res => {
          this.setState({ ...this.state, loading: false })
          if (res.data.json.success) {
            toast.success(`${title(this.state.formData.type)} created successfully.`);
            this.props.history.push(`/organizations/${this.props.match.params.id}/super_admins`);
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(error + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
        .catch(error => error)
    }
  }

  inputEventHandler = (event, field) => {
    let data = { ...this.state.formData }
    if (field === "type") {
      const type = {
        ...this.state[field],
        selectedOption: event
      };
      data[field] = type.selectedOption.value;
      this.setState({
        type, formData: data
      })
    }
    else {
      data[field] = event.target.value;
      this.setState({
        ...this.state,
        formData: data
      })
    }
  }

  render() {
    return (
      <div className="container w-100">
        {this.state.loading ? <Spinner className="h-60vh" /> :
          <div className="row mb-4">
            <div className="col-12">
              <h3>{this.props.location.pathname.includes("edit") ? `Edit ${this.state.role}` : "Create Admin"}</h3>
            </div>
          </div>}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body p-4">
                <div className="card-text">
                  {
                    this.state.loading ? <Spinner className="h-60vh" /> :
                      <div className="card-text">
                        <form autoComplete="off" action="" onSubmit={event => this.submitHandler(event)}>
                          <h4>Basic</h4>
                          <hr />
                          <div className="row mb-5">
                            <div className="col-lg-6">
                              <Input {...this.state.input.firstName} value={this.state.formData.first_name} changed={event => this.inputEventHandler(event, 'first_name')} />
                            </div>
                            <div className="col-lg-6">
                              <Input {...this.state.input.lastName} value={this.state.formData.last_name} changed={event => this.inputEventHandler(event, 'last_name')} />
                            </div>
                            <div className="col-lg-6">
                              <Input {...this.state.input.email} value={this.state.formData.email} changed={event => this.inputEventHandler(event, 'email')} />
                            </div>
                            <div className="col-lg-6">
                              <Input {...this.state.input.password} value={this.state.formData.password} changed={event => this.inputEventHandler(event, 'password')} />
                            </div>
                            <div className="col-lg-6">
                              <Input {...this.state.input.passwordConfirmation} value={this.state.formData.password_confirmation} changed={event => this.inputEventHandler(event, 'password_confirmation')} />
                            </div>
                            <div className="col-lg-6">
                              <Input {...this.state.type} changed={event => this.inputEventHandler(event, 'type')} />
                            </div>
                          </div>

                          <div className="text-center m-4">
                            <Button
                              type="danger"
                              className="mr-2"
                              clicked={() =>
                                this.props.history.push(this.props.location.pathname.includes("edit")
                                  ? `/organizations/${this.state.organizationId}/super_admins`
                                  : `/organizations/${this.props.match.params.id}/super_admins`)
                              }
                            >
                              Cancel
                             </Button>
                            <FormButton>
                              Submit
                            </FormButton>
                          </div>
                        </form>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddSuperAdmin;