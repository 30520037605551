import React, { useEffect, useState } from "react"

const BookingContact = ({ booking }) => {
  const bookingIsForUser = booking.user_or_program === "User"
  const { id, first_name, last_name } = booking.user_or_program_detail

  let bookingContactContent = <div>N/A</div>

  if (bookingIsForUser) {
    bookingContactContent = (
      <a
        href={`/users/${id}/summary`}
        className="text-truncate"
        target="_blank"
        rel="noopener noreferrer"
      >
        {`${first_name} ${last_name}`}
      </a>
    )
  }

  return (
    <>
      {bookingContactContent}
    </>
  )
}

export default BookingContact
