import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { title } from "change-case"
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";

class MergeProject extends Component {
  signal = axios.CancelToken.source();
  state = {
    projectName: {
      elementType: "select",
      elementConfig: {
        name: "projectName",
        placeholder: "Project Name",
        isSearchable: false,
        isClearable: false,
        required:true,
        isLoading: true,
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: {},
      clearAfterResponse: true
    },
    showModal: true,
    disabled: false
  };

  componentDidMount() {
    const queryParams = {
      params: {
        user_id: this.props.user_id,
        id: this.props.project_id
      },
      cancelToken: this.signal.token
    };

    axios.get('/projects/autocomplete', queryParams)
      .then(res => {
        let projects = [];
        for (let key in res.data.projects) {
          projects.push({ ...res.data.projects[key] });
        }

        const projectsField = projects.map(project => {
          let obj = {
            value: project.id,
            label: project.name
          };
          return obj;
        });

        this.setState({
          ...this.state,
          projectName: {
            ...this.state.projectName,
            elementConfig: {
              ...this.state.projectName.elementConfig,
              isLoading: false,
              options: projectsField
            }
          }
        });
      })
      .catch(err => err);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  closeModal = () => {
    this.props.closeModal();
  };

  inputChangedHandler = event => {
    let inputValue = {};
    inputValue["selectedOption"] = event;
    const updatedprojectName = {
      ...this.state.projectName,
      ...inputValue
    };
    this.setState({ projectName: updatedprojectName });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const queryParams = {
      user_id: this.props.user_id,
      id: this.props.project_id,
      project_merge_id: this.state.projectName.selectedOption.value
    };
    axios
      .post("/projects/merge", queryParams)
      .then(res => {
        if (res.data.json.success) {
          let message = "Merged Successfully";
          this.props.closeModal();
          this.props.getProjectName();
          toast.success(message);
          setTimeout(() => window.location.reload(), 3000);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      })
      .catch(err => {
        if (err.response.status !== 500) {
          if (err.response.data.json.hasOwnProperty("errors")) {
            Object.keys(err.response.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + err.response.data.json.errors[error]);
            });
          }
          if (err.response.data.json.hasOwnProperty("error")) {
            toast.error(err.response.data.json.error);
          }
        }
      });
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <p>Select the project you want to merge to current project</p>
        <Input
          {...this.state.projectName}
          changed={event => this.inputChangedHandler(event)}
          chart={this.state.enteredProjectChar}
        />
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            className="mr-2"
            clicked={this.props.closeModal}
          >
            Cancel
          </Button>
          <FormButton disabled={this.state.disabled}>Merge Project</FormButton>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects
  };
};

export default connect(mapStateToProps)(MergeProject);
