import React, { useEffect } from "react"
import axios from "axios"
import Input from "../UI/input/Input"

const SelectRentalReport = ({ selectedRentalReport, setRentalReport, setOwners }) => {

  const rentalOptions = [
    {label: "All", value: "all"}, 
    {label: "Organization Assets", value: "organization_rentals"}, 
    {label: "Third Party Assets", value: "third_party_rentals"}
  ]
  
  useEffect(() => {
    if (selectedRentalReport?.value === "third_party_rentals") {
      axios
        .get("/third_party_owners")
        .then(res => {
          const { third_party_owners } = res.data
          setOwners(third_party_owners)
        })
    }
  }, [selectedRentalReport])

  return (
    <Input
      name="selectRentalReport"
      elementType="select"
      selectedOption={selectedRentalReport}
      elementConfig={{
        placeholder: "Select rental report",
        required: true,
        options: rentalOptions,
      }}
      changed={setRentalReport}
      customStyle={{
        container: provided => ({
          ...provided,
          width: 250 //If used with flex boxes react-select collapses in width, there is supposed to be a quick fix, however this version of react-select appears to be too old and it didn't work. Thus the width was hard-coded.
        })
      }}
    />
  )
}

export default SelectRentalReport
