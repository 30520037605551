import React, { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import LabelCategoryModal from "./LabelCategoryModal"

const LabelCategory = ({ labelCategory, onUpdate }) => {
  const [showLabelCategoryModal, setShowLabelCategoryModal] = useState(false)
  const [labelCategoryName, setLabelCategoryName] = useState(labelCategory.name)

  const deleteLabelCategory = (id) => {
    let response = window.confirm(
      "Are you sure you want to delete this label category?"
    )
    if (response) {
      axios
        .delete(`/label_categories/${id}`)
        .then((res) => {
          toast.success(res.data.json.message)
          onUpdate()
          if (res.data.json.success === false) {
            toast.error(res.data.json.errors)
          }
        })
        .catch((err) => {
          toast.error(err)
        })
    }
  }

  const updateLabelCategory = (id) => {
    axios
      .put(`/label_categories/${id}`, { name: labelCategoryName })
      .then((res) => {
        toast.success(res.data.json.message)
        onUpdate()
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  return (
    <>
      <div className="row">
        <div className="col-5">{labelCategory.name}</div>
        <div className="col-5" />
        <div className="col-2 d-flex text-right justify-content-around">
          <i
            className="fas fa-trash cursor px-1"
            title="Delete"
            onClick={() => deleteLabelCategory(labelCategory.id)}
          />
          <i
            className="fas fa-pencil-alt cursor px-1"
            title="Edit"
            onClick={() => {
              setLabelCategoryName(labelCategory.name)
              setShowLabelCategoryModal(true)
            }}
          />
        </div>
      </div>
      <LabelCategoryModal
        labelCategoryName={labelCategoryName}
        setLabelCategoryName={setLabelCategoryName}
        showLabelCategoryModal={showLabelCategoryModal}
        setLabelCategoryModal={setShowLabelCategoryModal}
        clicked={() => updateLabelCategory(labelCategory.id)}
        modalTitle={'Update'}
      />
    </>
  )
}

export default LabelCategory
