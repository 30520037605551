import React, { Component } from "react";

import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import axios from "../../configAxios";
import ShowToggle from "./FilterForm/ShowToggle"

class FilterForm extends Component {
  signal = axios.CancelToken.source();
  state = {
    projectCueId: {
      elementType: "select",
      elementConfig: {
        name: "projectCueId",
        isSearchable: false,
        isClearable: false,
        isLoading: true
      },
      selectedOption: null,
      label: "Please consider the project for"
    },
    orderBy: {
      elementType: "select",
      elementConfig: {
        name: "orderBy",
        isSearchable: false,
        isClearable: false,
        isLoading: true
      },
      selectedOption: { label: "Date", value: "projects.created_at" },
      label: "Order by"
    },
    search: {
      elementType: "input",
      elementConfig: {
        name: "query_string",
        placeholder: "Enter keyword here",
        required: false
      },
      icon: "glyphicon-search",
      iconPosition: "left",
      label: "Search",
      value: ""
    },
    declined: false,
    approved: false,
    video: false,
    buttonDisabled: true,
    projectCueName: null,
    orderByName: { label: "Date", value: "projects.created_at" }
  };

  componentWillReceiveProps(props) {
    if (!props.loading) {
      let defaultValue = props.projectCues
        .map(data => {
          if (data[0] === props.projectCue) {
            return data;
          }
          return null;
        })
        .filter(item => item !== null)[0];
      const orderByData = props.orderBy
      this.setState({
        projectCueId: {
          ...this.state.projectCueId,
          elementConfig: {
            ...this.state.projectCueId.elementConfig,
            options: props.projectCues.map(data => ({
              value: data[0],
              label: data[1]
            })),
            isLoading: false
          },
          selectedOption: {
            value: defaultValue[0],
            label: defaultValue[1]
          }
        },
        orderBy: {
          ...this.state.orderBy,
          elementConfig: {
            ...this.state.orderBy.elementConfig,
            options: orderByData.map(data => ({
              value: data.value,
              label: data.label
            })),
            isLoading: false
          },
          selectedOption: {
            value: props.orderByName.value,
            label: props.orderByName.label
          }
        },
        buttonDisabled: false
      });
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {};
    if (key === "projectCueId") {
      inputValue["selectedOption"] = event;
      this.setState({projectCueName: event.label ? event.label : null})
    } else if (key === "orderBy") {
      inputValue["selectedOption"] = event;
      this.setState({ orderByName: event })
    } else if (key === "search") {
      inputValue["value"] = event.target.value
    }
    this.setState({
      [key]: {
        ...this.state[key],
        ...inputValue
      }
    });
  };

  toggleCheckbox = (boxType) => {
    this.setState({ ...this.state, [boxType]: !this.state[boxType] })
  }

  submitHandler = e => {
    e.preventDefault();
    const params = {
      type: this.state.projectCueId.selectedOption === null ? '' : this.state.projectCueId.selectedOption.value,
      orderBy: this.state.orderBy.selectedOption,
      search: this.state.search.value,
      showDeclined: this.state.declined,
      showApproved: this.state.approved,
      showVideoProjects: this.state.video
    };
    this.props.searchHandler(params, this.state.projectCueName, this.state.orderByName);
  };

  render() {
    return (
      <div
        className="col-lg-4 py-2"
        style={{ height: "fit-content" }}
      >
        <div className="card p-4">
          <form onSubmit={this.submitHandler}>
            <Input
              {...this.state.projectCueId}
              changed={event => this.inputChangedHandler(event, "projectCueId")}
            />
            <Input
              {...this.state.search}
              changed={event => this.inputChangedHandler(event, "search")}
            />
            <Input
              {...this.state.orderBy}
              changed={event => this.inputChangedHandler(event, "orderBy")}
            />

            <ShowToggle label={"Show Declined?"} checked={this.state.declined}
                        setChecked={() => this.toggleCheckbox("declined")}/>

            <ShowToggle label={"Show Approved?"} checked={this.state.approved}
                        setChecked={() => this.toggleCheckbox("approved")}/>

            <ShowToggle label={"Video Projects?"} checked={this.state.video}
                        setChecked={() => this.toggleCheckbox("video")} />

            <FormButton block disabled={this.state.buttonDisabled || this.props.disabled}>Submit</FormButton>
          </form>
        </div>
      </div>
    );
  }
}

export default FilterForm;
