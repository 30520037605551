import React, { Component } from "react";
import { toast } from "react-toastify";
import dateFns from "date-fns";
import _ from "lodash";

import axios from "../../configAxios";
import Button from "../UI/button/Button";
import DatePicker from "../UI/datePicker/index";
import Input from "../UI/input/Input";
import FormButton from "../UI/button/FormButton";
import Spinner from "../UI/Spinner";
import Pagination from "../UI/Pagination";
import SearchFilter from "../common/searchFilter/SearchFilter";

import "./GeneralMembershipReport.css";

class GeneralMembershipReport extends Component {
  csvLink = React.createRef();
  signal = axios.CancelToken.source();
  state = {
    memberTypes: [],
    totalMembers: 0,
    createdOnOrBefore: new Date(),
    pendingOnOrAfter: new Date(),
    sortMember: {
      elementType: "select",
      elementConfig: {
        name: "sortMember",
        options: [
          { label: "Member Name", value: "last_name" },
          { label: "Member Type", value: "member_type_id" },
          { label: "Expiration Date", value: "membership_expires" }
        ],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: { label: "Member Name", value: "last_name" },
    },
    reportData: [],
    forcePage: "",
    selectedPage: 1,
    reportOptions: null,
    downloading: false,
    loading: true,
    disabled: true,
    perPage: 10,
  };

  componentDidMount() {
    const queryParams = {
      id: "GeneralMembershipReport",
      page: this.state.selectedPage,
      per_page: this.state.perPage
    };
    this.getReportData(queryParams);
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  getReportData = queryParams => {
    axios.get("/reports/general_membership", { params: queryParams, cancelToken: this.signal.token })
      .then(res => {
        const reportData = res.data["reports/general_membership"];
        this.setState({
          memberTypes: res.data.meta.member_types,
          totalMembers: res.data.meta.total_count,
          reportData,
          loading: false,
          disabled: false
        });
      });
  };

  paginationHandler = (page, perPage)=> {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: _.isEmpty(page) ? 1 : page.selected + 1,
      forcePage: _.isEmpty(page) ? 1 : page.selected,
      perPage: perPage ? perPage : this.state.perPage,
      loading: true
    }, () => {
      const queryParams = {
        id: "GeneralMembershipReport",
        report_options: this.state.reportOptions,
        page:  this.state.selectedPage,
        per_page: this.state.perPage
      };
      this.getReportData(queryParams);
    });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ loading: true, disabled: true });
    const { createdOnOrBefore, pendingOnOrAfter } = this.state;
    const report_options = {
      as_of: {
        year: dateFns.format(createdOnOrBefore, "YYYY"),
        month: dateFns.format(createdOnOrBefore, "MM"),
        day: dateFns.format(createdOnOrBefore, "DD")
      },
      pending_as_of: {
        year: dateFns.format(pendingOnOrAfter, "YYYY"),
        month: dateFns.format(pendingOnOrAfter, "MM"),
        day: dateFns.format(pendingOnOrAfter, "DD")
      },
      sort_order: this.state.sortMember.selectedOption.value
    };

    this.setState({ selectedPage: 1, reportOptions: report_options });

    const queryParams = {
      id: "GeneralMembershipReport",
      report_options,
      page: 1,
      per_page: this.state.perPage
    };
    this.getReportData(queryParams);
  };

  downloadReport = async () => {
    try {
      if (this.state.downloading) return;
      this.setState({ downloading: true });
      const { createdOnOrBefore, pendingOnOrAfter } = this.state;
      const report_options = {
        as_of: {
          year: dateFns.format(createdOnOrBefore, "YYYY"),
          month: dateFns.format(createdOnOrBefore, "MM"),
          day: dateFns.format(createdOnOrBefore, "DD")
        },
        pending_as_of: {
          year: dateFns.format(pendingOnOrAfter, "YYYY"),
          month: dateFns.format(pendingOnOrAfter, "MM"),
          day: dateFns.format(pendingOnOrAfter, "DD")
        },
        sort_order: this.state.sortMember.selectedOption.value
      };
      let report = await axios.get("/reports/general_membership/download_csv", {
        params: {
          id: "GeneralMembershipReport",
          report_options
        },
        cancelToken: this.signal.token
      });
      if (report.data.json.generating) {
        toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
        let timerId = setInterval(
          () => this.checkDownloadProgress(report.data.json.id),
          5000
        );
        this.setState({ timerId });
      }
    } catch (err) {
      this.setState({ downloading: false });
      console.log(err);
    }
  };

  checkDownloadProgress = async id => {
    try {
      let progress = await axios.get("/reports/general_membership/check_generating", {
        params: {
          id
        },
        cancelToken: this.signal.token
      });
      if (typeof progress.data.json === "string") {
        clearInterval(this.state.timerId);
        this.setState({ downloading: false });
        toast.success("File successfully downloaded");
        window.open(progress.data.json, "_blank");
        return;
      }
    } catch (e) {
      toast.error("Downloading failed");
      clearInterval(this.state.timerId);
    }
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["selectedOption"] = event;

    const updatedControls = {
      ...this.state[key],
      ...inputValue
    };

    this.setState({ [key]: updatedControls });
  };

  render() {
    const { reportData } = this.state;
    let tableBody;
    tableBody = reportData.map(data => (
      <tr key={data.id}>
        <td>{data.name_last_first}</td>
        <td style={{ width: "320px" }}>
          {data.address1}
          <div>
            {data.city ? `${data.city} /` : ""}
            {data.province ? `${data.province} /` : ""} {data.postal_code}
          </div>
        </td>
        <td>
          {data.home_phone ? `Home: ${data.home_phone}` : ""}
          <div>{data.mobile_phone ? `Mobile: ${data.mobile_phone}` : ""}</div>
          <div>{data.email ? `Email: ${data.email}` : ""}</div>
          <div>{data.website ? `Web: ${data.website}` : ""}</div>
        </td>
        <td>{data.member_type ? data.member_type : "Unknown"}</td>
        <td>{data.account_balance}</td>
        <td>
          {`Current: ${data.volunteer_hours.current}`}
          <div>{`Total: ${data.volunteer_hours.total}`}</div>
        </td>
        <td>
        <div>
          {data.membership_expires
            ? dateFns.format(data.membership_expires, "YYYY.MM.DD")
            : ""}
        </div>
        </td>
      </tr>
    ));

    const totalItems =
      this.state.totalMembers < this.state.perPage
        ? this.state.totalMembers
        : this.state.perPage;
    const to =
      this.state.totalMembers < this.state.selectedPage * totalItems
        ? this.state.totalMembers
        : this.state.selectedPage * totalItems;

    return (
      <div className="container general-membership w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>General Membership Report</h3>
          </div>
          <div className="col-6 text-right">
            <Button
              type="success"
              className="ml-3"
              clicked={this.downloadReport}
            >
              Generate CSV
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body p-0">
                <div className="card-text">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item rounded-0">
                      <h5 className="font-weight-bold">
                        Statistics for generated report data
                      </h5>
                    </div>

                    <div className="list-group-item py-2 rounded-0">
                      <div className="row">
                        <div className="col-9">
                          <span>Total Members</span>
                        </div>
                        <div className="col-3 text-right">
                          <span className="font-weight-bold">
                            {this.state.totalMembers}
                          </span>
                        </div>
                      </div>
                    </div>

                    {
                      this.state.memberTypes.map((member, index) => (
                        <div className="list-group-item py-2 rounded-0" key={index}>
                          <div className="row">
                            <div className="col-9">
                              <span>{member.name}</span>
                            </div>
                            <div className="col-3 text-right">
                              <span className="font-weight-bold">{member.count}</span>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className="card-text">
                  <form className="mb-3" onSubmit={this.submitHandler}>
                    <div className="row">
                      <div className="col-12 py-2">
                        <label className="font-weight-normal">Created on or before</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.createdOnOrBefore}
                          changed={date => this.setState({ createdOnOrBefore: date })}
                          className="w-100"
                        />
                      </div>
                      <div className="col-12 py-2">
                        <label className="font-weight-normal">Pending on or after</label>
                        <DatePicker
                          dateFormat={"yyyy-MM-dd"}
                          selected={this.state.pendingOnOrAfter}
                          changed={date => this.setState({ pendingOnOrAfter: date })}
                        />
                      </div>
                      <div className="col-12 py-2">
                        <label className="font-weight-normal">Sort Members List by</label>
                        <Input
                          {...this.state.sortMember}
                          changed={event =>
                            this.inputChangedHandler(event, "sortMember")
                          }
                        />
                      </div>
                      <div className="col-12">
                        <FormButton block disabled={this.state.disabled}>
                          Build Report
                        </FormButton>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <div className="card hp-100">
              <div className="card-body">
                <div className="card-text">
                  <p className="text-justify detail">
                    The default date values are generally appropriate, and will
                    return all Active Members that were created on or before the
                    current date, and that either do not expire, or have membership
                    expiration dates sometime in the past year. In other words, the
                    default value for 'Pending on or after' does not include Members
                    whose memberships expired over a year ago.
                    <br />
                    <br /> The results can be refined in two major ways:
                    <br />
                    <br />
                    <b>'Created on or before'</b> allows you to choose the Date
                    before which the Member must have been added to the system. This
                    is the current date by default, but can be set to any Date in
                    the past in order to eliminate any Members that were added after
                    that date from the report.
                    <br />
                    <br />
                    <b>'Pending on or after'</b> allows you to specify a date on or
                    after which the Membership expires. This is the current date by
                    default, but can be set to any date in the past in order to
                    include Members whose memberships have expired sometime after
                    the selected date.
                    <br />
                    <br />
                    <b>Note:</b> 'Current Volunteer Hours' are calculated for the
                    period of one year from the 'Created on or before' date.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <div className="card">
              <div className="row mt-4">
                <div className="col-4 offset-8">
                  <SearchFilter 
                    perPage={this.state.perPage}
                    inputChangeHandler={(perPageRes) => this.paginationHandler(null, perPageRes)}
                  />
                </div>
              </div>
              <div className="card-body">
                <div className="card-text">
                  <div className="table-responsive">
                    <table className="table table-bordered reports-table-layout">
                      <thead className="border-top">
                        <tr>
                          <th className="w-20 text-center">Member Name</th>
                          <th className="w-20 text-center">Address</th>
                          <th className="w-20 text-center">Contact Info</th>
                          <th className="w-15 text-center">Member Type</th>
                          <th className="w-15 text-center">Account Balance</th>
                          <th className="w-15 text-center">Volunteer Hours</th>
                          <th className="w-15 text-center">Expiration Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!this.state.loading &&
                          tableBody}
                      </tbody>
                    </table>
                  </div>
                  {this.state.loading && <Spinner className="h-30vh" />}
                  <div className="row">
                    <div className="col-lg-3 d-flex align-items-center justify-content-start">
                      {`Showing ${(this.state.selectedPage - 1) *
                        this.state.perPage +
                        1} to ${to} of ${this.state.totalMembers}`}
                    </div>
                    <div className="col-lg-9 d-flex align-items-center justify-content-end">
                      <Pagination
                        forcePage={this.state.selectedPage - 1}
                        pageCount={this.state.totalMembers / this.state.perPage}
                        handlePageClick={this.paginationHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralMembershipReport;
