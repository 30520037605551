import React, { useEffect, useState } from "react"
import Input from "../../../UI/input/Input"

const MembershipCheckbox = ({ mailList, memberType, onCheckboxToggle }) => {
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [memberTypeData, setMemberTypeData] = useState({
    memberTypeId: 0,
    memberTypeName: '',
  })

  useEffect(() => {
    setMemberTypeData((prevState) => (
      {
        ...prevState,
        memberTypeId: memberType[0],
        memberTypeName: memberType[1],
      }
    ))
  }, [memberType])
  
  const mailListHandler = (mailList) => {
    const newMailList = [...mailList]
    if (!newMailList.includes(memberTypeId)) {
      return [...newMailList, memberTypeId]
    }
    return newMailList.filter((id) => id !== memberTypeId)
  }

  const onClickHandler = () => {
    setCheckboxValue((prevState) => !prevState)
    onCheckboxToggle(mailListHandler(mailList))
  }

  const { memberTypeId, memberTypeName } = memberTypeData
  return (
    <div key={memberTypeId} className="col-lg-4">
      <Input
        elementType={'input'}
        elementConfig={{
          id: memberTypeId,
          name: memberTypeName,
          type: 'checkbox',
          checked: checkboxValue
        }}
        style={{cursor: 'pointer'}}
        changed={() => onClickHandler()}
        label={memberTypeName}
        value={checkboxValue}
      />
    </div>
  )
}

MembershipCheckbox.defaultProps = {
  mailList: [],
  memberType: [],
  onCheckboxToggle: () => {},
}

export default MembershipCheckbox
