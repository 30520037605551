import React, { Component } from "react";
import Spinner from "../../../UI/Spinner";
import Modal from "../../../UI/Modal";
import axios from "../../../../configAxios";
import EditCustomFields from "./EditCustomFields";
import { withRouter } from "react-router-dom";

class CustomFields extends Component {
  signal = axios.CancelToken.source();
  state = {
    customFields: [],
    edit: false,
    editModal: false,
    id: null,
    loading: true
  };

  componentDidMount() {
    this.getCustomFieldsList();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getCustomFieldsList = () => {
    axios
      .get("/custom_fields", { cancelToken: this.signal.token })
      .then(res => {
        this.setState({ 
          customFields: 
            Array.isArray(res.data.custom_fields)
              ? res.data.custom_fields
              : [],
          loading: false });
      });
  };

  render() {
    const fields = this.state.customFields;
    let fieldsList;
    if (this.state.loading) {
      fieldsList = <Spinner />;
    } else {
      fieldsList = fields.map(item => (
        <li key={item.id} className="list-group-item rounded-0 border-top">
          <div className="row">
            <div className="col-lg-3">{item.klass_name}</div>
            <div className="col-lg-4">{item.column_name}</div>
            <div className="col-lg-4">{item.label}</div>
            <div className="col-lg-1 d-flex justify-content-between">
              <div>
                <i
                  className="fas fa-pencil-alt text-muted cursor"
                  onClick={() => { window.scrollTo(0, 0); this.setState({ id: item.id, editModal: true }) }}
                />
              </div>
            </div>
          </div>
        </li>
      ));
    }
    return (
      <div>
        <div className="p-3">
          <div className="row">
            <div className="col-12">
              <h5 className="font-weight-bold">Custom Fields</h5>
            </div>
          </div>
        </div>

        <ul className="list-group list-group-flush border-top-0 mt-3">
          <li className="list-group-item">
            <div className="row">
              <div className="col-3">
                <span className="font-weight-bold">
                  Type/Section
                  </span>
              </div>
              <div className="col-4">
                <span className="font-weight-bold">
                  Field
                  </span>
              </div>
              <div className="col-4">
                <span className="font-weight-bold">
                  Label
                  </span>
              </div>
              <div className="col-1" />
            </div>
          </li>
          {fieldsList}
        </ul>

        <Modal
          show={this.state.editModal}
          title="Edit Label"
          hide={() => this.setState({ editModal: false })}
        >
          <EditCustomFields
            closeModal={() => this.setState({ editModal: false })}
            getCustomFieldsList={this.getCustomFieldsList}
            id={this.state.id}
          />
        </Modal>
      </div>
    );
  }
}

export default withRouter(CustomFields);
