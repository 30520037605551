import React, { Component } from "react";
import "./../../App.css";

class AboutFava extends Component {
  render() {
    return (
      <div className="mb-2">
        <button
          className="btn w-100"
          onClick={() => this.props.tabHandler("aboutFava")}
          type="button"
          data-toggle="collapse"
          data-target=".aboutFava"
          aria-expanded="false"
          aria-controls="multiCollapseExample1 multiCollapseExample2"
          style={{
            backgroundColor: "#f5efef",
            outline: "none",
            boxShadow: "none"
          }}
        >
          <div className="d-flex justify-content-between">
            <p className="font-12 font-weight-bold">
              How did you hear about {localStorage.organizationName} ?
            </p>
            <i
              className={
                this.props.selectedTabs.includes("aboutFava")
                  ? "fas fa-minus-circle text-primary fa-2x"
                  : "fas fa-plus-circle text-primary fa-2x"
              }
            />
          </div>
        </button>
        <div className="row">
          <div className="col">
            <div className="collapse aboutFava" id="multiCollapseExample1">
              <div className="card p-3">
                <div className="row">
                  {this.props.aboutFava.map(data => {
                    const [value, label] = data
                    return (
                      <div key={value} className="col-3">
                        <div
                          onClick={() => this.props.aboutFavaHandler(value)}
                          className="custom-checkbox"
                        >
                          <div className={this.props.selectedAboutFava.includes(value) ? "box bg-primary" : "box bg-white"}>
                            <i className="fas fa-check text-white" style={{ marginLeft: 2 }} />
                          </div>
                          <p className="ml-2">{label}</p>
                        </div>
                      </div>)
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AboutFava;
