import React, { Component } from "react"
import { connect } from "react-redux"
import ProjectItem from "./Projects/ProjectItem"
import Pagination from "../UI/Pagination"
import { getProjects } from "../../store/actions"
import Spinner from "../UI/Spinner"
import Button from "../UI/button/Button"
import Modal from "../UI/Modal"
import CenteredContainer from "../UI/container/CenteredContainer"
import AddToReel from "./AddToReel/AddToReel"

class ProjectItems extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      checkFalse: !this.props.projects.loading,
      isChecked: false,
      selectedReels: [],
    }
  }

  componentDidMount() {
    this.props.getProjects()
  }

  openModal = () => {
    window.scrollTo(0, 0)
    this.setState({ showModal: true })
  }

  paginationHandler = (page) => {
    window.scrollTo(0, 0)
    this.setState({ checkFalse: null })
    const searchParams = { page: page.selected + 1 }
    this.props.getProjects(searchParams)
  }

  selectedReelsHandler = (projectId, isChecked) => {
    const selectedReels = [...this.state.selectedReels]
    if (isChecked) {
      selectedReels.push(projectId)
      this.setState({ selectedReels: selectedReels })
    } else {
      var index = selectedReels.indexOf(projectId)
      if (index !== -1) {
        selectedReels.splice(index, 1)
        this.setState({ selectedReels: selectedReels })
      }
    }
  }

  clearCheckboxHandler = () => {
    this.setState({ selectedReels: [] })
  }

  render() {
    const { projects, totalCount } = this.props.projects
    const { loading } = this.props.response
    let projectItems, forcePage
    if (typeof this.props.projects.params !== "undefined") {
      if (this.props.projects.params.page === "1") {
        forcePage = 0
      }
    }
    if (projects === null || loading) {
      projectItems = <Spinner />
    } else {
      if (projects.length > 0) {
        projectItems = projects.map((project) => {
          return (
            <ProjectItem
              key={project.id}
              project={project}
              isChecked={
                project.has_video
                  ? this.state.selectedReels.indexOf(project.id) !== -1
                  : false
              }
              clicked={this.selectedReelsHandler}
              showReels={this.props.showReels}
            />
          )
        })
      } else {
        projectItems = (
          <CenteredContainer>
            <h4 className="center-block">No projects found...</h4>
          </CenteredContainer>
        )
      }
    }
    return (
      <div className="border-left">
        <div className="p-4">
          <div className="row">
            <div className="col-6 py-2">
              <span>
                Total Projects:{" "}
                <span className="font-weight-bold">{totalCount}</span>
              </span>
            </div>
            {this.props.showReels ? (
              <div className="col-6 text-right">
                <Button
                  type="success"
                  clicked={
                    this.props.canViewAddProjects ? this.openModal : null
                  }
                  disabled={
                    !(
                      this.state.selectedReels.length ||
                      !this.props.canViewAddProjects
                    )
                  }
                  style={{
                    cursor: this.props.canViewAddProjects
                      ? "pointer"
                      : "no-drop",
                    opacity: this.props.canViewAddProjects ? 1 : 0.7,
                  }}
                >
                  Add to Reel
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        <ul className="list-group list-group-flush">{projectItems}</ul>

        <Pagination
          forcePage={forcePage}
          pageCount={totalCount / 10}
          handlePageClick={this.paginationHandler}
          checkFalse={this.state.checkFalse}
          range={5}
        />

        <Modal
          title="Add to Reel"
          show={this.state.showModal}
          hide={() => this.setState({ showModal: false })}
          style={{ top: "15%" }}
          flag={true}
        >
          <AddToReel
            closeModal={() => this.setState({ showModal: false })}
            selectedReels={this.state.selectedReels}
            clearCheckbox={this.clearCheckboxHandler}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
    response: state.response,
  }
}

export default connect(mapStateToProps, { getProjects })(ProjectItems)
