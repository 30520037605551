import React from 'react'
import Input from "../../../../UI/input/Input";

export const inputTypeOptions = [
  {label: "Short Answer", value: "ShortAnswerQuestion"},
  {label: "Long Answer", value: "LongAnswerQuestion"},
  {label: "Dropdown", value: "DropdownQuestion"},
  {label: "Checkbox", value: "CheckboxQuestion"},
  {label: "Multiple Choice", value: "MultipleChoiceQuestion"},
  {label: "File Upload", value: "FileUploadQuestion"},
  {label: "File Download", value: "FileDownloadQuestion"},
  {label: "Text Block", value: "TextQuestion"},
  {label: "AMS Project Connection", value: "ProjectLinkQuestion"},
  {label: "Date Select", value: "DateSelectQuestion"},
  {label: "Currency Value", value: "CurrencyQuestion"},
  {label: "Applicant Data", value: "ApplicantDataQuestion"}
]

function SelectType({ updateQuestionType }) {

  return (
    <div className="w-100">
      <Input
        name="formInputs[type]"
        elementType="select"
        elementConfig={{
          placeholder: "Select form element",
          options: inputTypeOptions,
          selectedOption: null,
          required: true
        }}
        changed={updateQuestionType}
      />
    </div>
  )
}

export default SelectType
