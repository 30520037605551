import React, { Component } from "react";
import { title, snakeCase } from "change-case";
import { Link } from "react-router-dom";

import axios from "../../configAxios";

import Assets from "./Assets";
import BookingActivity from "./bookingActivity";
import CheckedOut from "./currentlyOut//checkedOut";
import RentalRequests from "../rentalRequests";
import langUtil from "../../utils/langUtils"

class Tabs extends Component {
  state = {
    tabs: [],
    activeTab: "Search",
    canCreate: null
  };

  componentDidMount() {
    const tabsPolicy = {
      can_search: "Search",
      can_view_booking_activity: "Booking Activity",
      can_view_checked_out: "Checked Out",
      can_view_rental_requests: "Rental Requests"
    }
    this.handleTabFromProp();
    axios
      .get("/assets/policy")
      .then(res => {
        const tabs = []
        Object.keys(tabsPolicy).map((policy, tab) => {
          if (res.data.json[policy]) {
            tabs.push(tabsPolicy[policy])
          }
        });
      this.setState({
        canCreate: res.data.json["can_create"],
        tabs: tabs
      })
    });
    window.addEventListener("popstate", this.handleTabFromProp);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp);
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.tab !== "undefined" ? title(this.props.tab) : "Search"
    });
  };

  tabChangeHandler = (activeTab, ticketId) => {
    this.setState({ activeTab, ticketId });
    if (activeTab === "Search") {
      this.props.history.push(`/assets`);
      return;
    }
    this.props.history.push(`/assets/${snakeCase(activeTab)}`);
  };

  render() {
    const { tabs: tabNames } = this.state;

    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${this.state.activeTab === tab ? "active-tab " : "inactive-tab"}`}
      >
        {tab}
      </li>
    ));
    let activeTab;
    switch (this.state.activeTab) {
      case "Search":
        activeTab = <Assets />;
        break;
      case "Booking Activity":
        activeTab = <BookingActivity />;
        break;
      case "Checked Out":
        activeTab = <CheckedOut />
        break;
      case "Rental Requests":
        activeTab = <RentalRequests fromAssetsPage />;
        break;
      default:
        activeTab = <Assets />;
    }
    return (
      <div className="assets container w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>{langUtil("txt_global_stuff", "Our Stuff")}</h3>
          </div>
          { this.state.canCreate ? (<div className="col-6 text-right">
            <Link to="/assets/new" className="btn new-asset-btn">
              Add New Asset
            </Link>
          </div>) : ""}
        </div>
        <div className="card">
          {tabNames.length > 1 && <ul className="nav nav-tabs ml-0">{tabs}</ul>}
          {activeTab}
        </div>
      </div>
    );
  }
}
export default Tabs;
