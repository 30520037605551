import React, { useEffect, useState } from "react"
import Input from "../UI/input/Input"

const SelectRentalReport = ({ owners, selectedOwner, setSelectedOwner }) => {
  const [ownersOptions, setOwnersOptions] = useState([])

  useEffect(() => {
    if (owners.length > 0) {
      let formattedOptions = owners.map((owner) => {
        const fullName = owner.first_name && owner.last_name ? `${owner.first_name} ${owner.last_name}` : `${owner.email_address}`
        const valueName = owner.first_name && owner.last_name ? `${owner.first_name}_${owner.last_name}` : `${owner.email_address}`
        return { label: fullName, value: valueName, thirdPartyOwnerId: owner.id, userId: owner.user_id }
      })
      setOwnersOptions(formattedOptions)
    }
  }, [owners])

  return (
    <Input
      name="selectThirdPartyOwner"
      elementType="select"
      selectedOption={selectedOwner}
      elementConfig={{
        placeholder: "Select third party owner",
        options: ownersOptions,
      }}
      changed={setSelectedOwner}
      customStyle={{
        container: provided => ({
          ...provided,
          width: 250
        })
      }}
    />
  )
}

export default SelectRentalReport
