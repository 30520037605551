import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { title } from "change-case";
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import FormButton from "../UI/button/FormButton";
import { getProject } from "../../store/actions/index";

class ProjectUpdate extends Component {
  state = {
    updateProjectName: {
      elementType: "input",
      elementConfig: {
        name: "updateProjectName",
        required: true
      }
    },
    projectName: this.props.projectName,
    disabled: false
  }
  inputChangedHandler = (event) => {
    this.setState({ projectName: event.target.value })
  };
  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const id = this.props.projects.project.slug ? this.props.projects.project.slug : this.props.projects.project.id;
    const formData = {
      name: this.state.projectName,
      share: this.props.projects.project.share
    };
    axios
      .put(`/projects/${id}`, formData)
      .then(res => {
        if (res.data.json.success) {
          let message = "Project name successfully updated."
          this.props.closeModal();
          toast.success(message);
          this.props.updatedName(formData);
        } else {
          if ('errors' in res.data.json) {
            Object.keys(res.data.json.errors).forEach(key => {
              toast.error(title(key) + " " + res.data.json.errors[key])
            })
          }
          if ('error' in res.data.json) {
            toast.error(res.data.json.error)
          }
          this.setState({ disabled: false })
        }
      })
      .catch(err => err);
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        <p>Edit Project Name</p>
        <Input  {...this.state.updateProjectName} changed={(event) => this.inputChangedHandler(event)}
                value={this.state.projectName} />
        <div className="d-flex justify-content-center">
          <Button type="secondary" className="mr-2" clicked={this.props.closeModal}>
            Cancel
          </Button>
          <FormButton disabled={this.state.disabled}>Update</FormButton>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projects
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getProject: id => dispatch(getProject(id))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectUpdate);
