import React, { useState } from "react"
import LabelsTab from "./LabelsTab"
import LabelCategoriesTab from "./LabelCategoriesTab"

const Labels = () => {
  const [currentTab, setCurrentTab] = useState('labels')

  return (
    <div className="container system-settings w-100">
      <div className="row system-title mb-4">
        <div className="col-12">
          <h3>Standard Labels</h3>
        </div>
      </div>
      <div className="col-md-9">
        <div className="card">
          <div className="card-body p-0">
            <div className="card-text">
              <div className="row">
                <div className="col-12">
                  <div className="card email-campaign">
                    <ul className="nav nav-tabs font-weight-bold ml-0">
                      <li
                        className={`nav-item cursor font-weight-bold px-4 ${(currentTab === 'labels') ? 'active-tab' : 'inactive-tab'}`}
                        onClick={() => setCurrentTab('labels')}
                      >
                        Program Labels
                      </li>
                      <li
                        className={`nav-item cursor font-weight-bold px-4 ${(currentTab === 'label_categories') ? 'active-tab' : 'inactive-tab'}`}
                        onClick={() => setCurrentTab('label_categories')}
                      >
                        Label Categories
                      </li>
                    </ul>
                    <div className="tag-manager">
                      { currentTab === 'labels' ? <LabelsTab /> : <LabelCategoriesTab />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Labels
