import React from "react"
import isValidUrl from "../../../utils/isValidUrl"
import UserInfoPanel from "./UserInfoPanel"

const Website = (props) => {
  const user = props.user
  return (
    <>
      {user.website && (
        <UserInfoPanel>
          {isValidUrl(user.website) ? (
            <a href={user.website} target="_blank" rel="noopener noreferrer" className="text-primary">
              {user.website}
            </a>
          ) : (
            <span>{user.website}</span>
          )}
        </UserInfoPanel>
      )}
    </>
  )
}

export default Website
