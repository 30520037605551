import React, { useState, useEffect } from 'react'
import axios from "../../../../configAxios"
import Input from "../../../UI/input/Input";
import Spinner from "../../../campaigns/Spinner"

export default function ProjectLinkQuestion({ question, response, setResponseData, completed }) {
  const [selectedOption, setSelectedOption] = useState(null)
  const [userProjects, setUserProjects] = useState(null)
  const [dropdownOptions, setDropdownOptions] = useState(null)

  const defaultOption = {label: "Not Applicable", value: 0}

  const getProjectLink = () => {
    if (response?.project_id === 0) {
      return defaultOption
    }

    if (response?.project_id) {
      let projectLinkItem = userProjects.find(project => project.id === response.project.id)
      return { label: projectLinkItem.name, value: projectLinkItem.id }
    } else {
      return null
    }
  }
  
  const createOptions = () => {
     return (
       [defaultOption].concat(userProjects.map(project => ({ label: project.name, value: project.id })))
     )
  }

  const clearValidationError = () => {
    const element = document.getElementById("project-link-question")
    element && element.validity.customError && !element.validity.valueMissing && element.setCustomValidity('')
  }

  const getUserProjectData = () => {
    axios.get("users/projects_list")
      .then(res => setUserProjects(res.data.json.data))
  }

  useEffect(() => {
    selectedOption && clearValidationError()
  }, [selectedOption])

  useEffect(() => {
    selectedOption && setResponseData(question.id, { project_id: selectedOption.value })
  }, [selectedOption])

  useEffect(() => {
    completed || getUserProjectData()
  },  [])

  useEffect(() => {
   if (userProjects && !dropdownOptions) {
     setSelectedOption(getProjectLink())
     setDropdownOptions(createOptions())
   }
  }, [userProjects])

  const ProjectLink = () => {
    return (
      <>
        {
          response.project_id === 0
          ?
            <p>Applicant did not select a project</p>
          :
            <a href={`/projects/${response.project.slug}`} target="_blank"
               rel="noopener noreferrer">{response.project.name}</a>
        }
      </>
    )
  }

  const Contents = () => {
    return (
      <>
        <p className="mb-2 font-weight-bold">{question.question_text}</p>
        { completed
          ?
          <ProjectLink />
          :
          <div className="mx-3">
            <Input
              id="project-link-question"
              name={question.id}
              elementType="select"
              selectedOption={selectedOption}
              elementConfig={{
                placeholder: "Select...",
                options: dropdownOptions,
                required: true,
                onInvalid: e => e.target.setCustomValidity("Please select an option")
              }}
              changed={option => setSelectedOption(option || null)}
              customStyle={{
                container: provided => ({
                  ...provided,
                  width: "100%"
                })
              }}
              disabled={completed}
            />
          </div>
        }
      </>
    )
  }
  return (
    <div className="mb-4">
      { dropdownOptions || completed
        ?
        <Contents />
        :
        <Spinner />
      }
    </div>
  )
}
