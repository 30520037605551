import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import Modal from "../UI/Modal";
import axios from "../../configAxios";

import NoUser from "../../assests/images/user-icon.jpg";
import "./members.css";

class MemberItem extends Component {
  signal = axios.CancelToken.source();
  state = {
    notesModal: false,
    bioModal: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  notesHandler = () => {
    window.scrollTo(0, 0);
    this.setState({ notesModal: true });
  };

  bioHandler = () => {
    window.scrollTo(0, 0);
    this.setState({ bioModal: true });
  };

  countWords = (str) => {
    str = str.replace(/(^\s*)|(\s*$)/gi, "");
    str = str.replace(/[ ]{2,}/gi, " ");
    str = str.replace(/\n /, "\n");
    return str.split(' ').length;
  }

  truncateString = (str, limit) => {
    const brExp = /<\/?br\s*\/?>/;
    let limitStr = (str.split(/\s+/).slice(0, limit).join(" "));
    
    return limitStr.split(brExp).map((word, index, arr) => {
      if (arr.length - 1 === index) return (<span>{word}</span>)
      return <span>{word}<br /></span>
    });
  }

  getModalContent = (text) => {
    let brExp = /<\/?br\s*\/?>/;
    return (
      <div>
        {text.split(brExp).map((str, index, arr) => {
          if (arr.length - 1 === index) return <span>{str}</span>
          return <span>{str}<br /><br /></span>
        })}
      </div>
    );
  }

  isAdmin = () => {
    const roleLevel =  localStorage.getItem('roleLevel')
    return ["super_admin", "site_admin", "staff"].includes(roleLevel)
  }

  render() {
    const { member } = this.props;
    const image = member.photo ? member.photo : NoUser;
    let bio, notes, modalBio, modalNotes, bioLength, notesLength, educationAndAssociationNames;

    if(member.address === ' ') {
      member.address = ''
    }
    if (!_.isEmpty(member.bio_link)) {
      bioLength = this.countWords(member.bio_link)
      bio = this.truncateString(member.bio_link, 50)
      modalBio = this.getModalContent(member.bio_link)
    }

    if (this.isAdmin() && !_.isEmpty(member.notes)) {
      notesLength = this.countWords(member.notes)
      notes = this.truncateString(member.notes, 50)
      modalNotes = this.getModalContent(member.notes)
    }

    educationAndAssociationNames = member.education_and_associations.map((education_and_association) => {
      return education_and_association.name.trim()
    }).join(", ");

    const { organization_name, full_name, email} = member;
    const isNamePresent = full_name && full_name.trim() !== "";
    let name = "";
    if(organization_name){
      name = `${organization_name}${isNamePresent ? ` (${full_name.trim()})` : ""}`
    } else if(isNamePresent) {
      name = full_name.trim();
    } else if(email) {
      name = email.split('@')[0];
    } else {
      name = `/users/${member.id}/summary`
    }

    return (
      <div
        className="list-group-item members border-bottom"
      >
        <div className="row no-gutters">
          <div className="col-lg-3 pr-2">
            <div className="rounded-circle bg-cover" style={{
              width: "90%",
              paddingTop: "90%",
              backgroundImage: `url(${image})`
            }} />
          </div>
          <div className="col-lg-9 pt-2">
            <Link
              to={`/users/${member.id}/summary`}
              className="text-info username"
            >
              <span>{name}</span>
            </Link>
            { notes && (<div className="mt-2">
                <span className="font-weight-bold text-muted">Notes: </span>
                <span>{notes}{notesLength > 50 && <span className="cursor font-weight-bold text-muted" onClick={this.notesHandler}> ... (see more)</span>}</span>
              </div>)
            }
            { bio && (
              <div className="mt-2 pb-2">
                <span className="font-weight-bold text-muted">Bio: </span>
                <span>{bio}{bioLength > 50 && <span className="cursor font-weight-bold text-muted" onClick={this.bioHandler}> ... (see more)</span>}</span>
              </div>)
            }
            { educationAndAssociationNames && (
              <div className="mt-2">
                <span className="font-weight-bold text-muted">Associations & Educations: </span>
                <span>{educationAndAssociationNames}</span>
              </div>)
            }

            <div className="row mt-2 text-muted">
              { member.address && (
                <div className="col-md-12">
                  <p className="mb-1">
                    <span className="font-weight-bold">Address: </span>
                    <span>{member.address}</span>{" "}
                  </p>
                </div>
              )}
              <div className="col-md-12">
                { member.mobile_phone && (
                  <p className="mb-1">
                    <span className="font-weight-bold">Mobile: </span>
                    <span>
                      {member.mobile_phone ? member.mobile_phone : member.home_phone ? member.home_phone : ""}
                    </span>
                  </p>
                )}
                { member.email && (
                  <p className="mb-1">
                    <span className="font-weight-bold">Email: </span>
                    <span>
                      {member.email}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal title="Notes"
          show={this.state.notesModal}
          closeModal={() => this.setState({ notesModal: false })}
          flag={true}
          hide={() => this.setState({ notesModal: false })}
          closeButton>
          <div className="mt-4 text-left" style={{ overflowY: "scroll", maxHeight: "250px", scrollbarWidth: "thin" }}>
            <span>{modalNotes}</span>
          </div>
        </Modal>
        <Modal title="Bio"
          show={this.state.bioModal}
          closeModal={() => this.setState({ bioModal: false })}
          flag={true}
          hide={() => this.setState({ bioModal: false })}
          closeButton>
          <div className="mt-4 text-left" style={{ overflowY: "scroll", maxHeight: "250px", scrollbarWidth: "thin" }}>
            <span>{modalBio}</span>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MemberItem;
