import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"

import axios from "../../configAxios"
import Spinner from "../UI/Spinner"
import Input from "../UI/input/Input"
import FormButton from "../UI/button/FormButton"
import { timezone } from "../systemSettings/basicInformation/timezones"

class OrganizationForm extends Component {
  signal = axios.CancelToken.source()
  state = {
    formData: {
      full_name: "",
      name: "",
      subdomain: "",
      mailing_address: "",
      phone_numbers: "",
      office_email: "",
      website: "",
      gst_rate: null,
      pst_rate: null,
      hst_rate: null,
      gst_number: "",
      pst_number: "",
      hst_number: "",
      hourly_bonus_for_volunteer_work: "",
      timezone: "",
      staff_limit: "",
      member_limit: "",
      storage_limit: "",
      invoice_email_sender: "",
      invoice_email_body: "",
      is_enabled_for_favatv: false,
      use_shared_ams_media_encoder_pipeline: true,
      is_enabled_for_artsevents: false,
      ui_version: "",
      installation_type: "",
    },
    logoPreview: "",
    input: {
      fullName: {
        elementType: "input",
        elementConfig: { name: "full_name", type: "text", required: true },
        label: "Full Organization Name",
      },
      name: {
        elementType: "input",
        elementConfig: { name: "name", type: "text", required: true },
        label: "Abbreviated Organization Name",
      },
      logo: {
        elementType: "input",
        elementConfig: { name: "logo", type: "file" },
        label: "Logo",
      },
      subDomain: {
        elementType: "input",
        elementConfig: { name: "subdomain", type: "text", required: true },
        label: "Sub-domain",
      },
      mailingAddress: {
        elementType: "textarea",
        elementConfig: { name: "mailing_address", rows: "5" },
        label: "Mailing Address",
      },
      phoneNumbers: {
        elementType: "input",
        elementConfig: { name: "phone_numbers", type: "text" },
        label: "Phone Numbers",
      },
      officeEmail: {
        elementType: "input",
        elementConfig: { name: "office_email", type: "text" },
        label: "Office E-mail",
      },
      organizationWebsite: {
        elementType: "input",
        elementConfig: { name: "website", type: "text" },
        label: "Organization Website",
      },
      gstRate: {
        elementType: "input",
        elementConfig: { name: "gst_rate", type: "number" },
        label: "GST Rate",
      },
      pstRate: {
        elementType: "input",
        elementConfig: { name: "pst_rate", type: "number" },
        label: "PST Rate",
      },
      hstRate: {
        elementType: "input",
        elementConfig: { name: "hst_rate", type: "number" },
        label: "HST Rate",
      },
      gstNumber: {
        elementType: "input",
        elementConfig: { name: "gst_number", type: "text" },
        label: "GST Number",
      },
      pstNumber: {
        elementType: "input",
        elementConfig: { name: "pst_number", type: "text" },
        label: "PST Number",
      },
      hstNumber: {
        elementType: "input",
        elementConfig: { name: "hst_number", type: "text" },
        label: "HST Number",
      },
      bonus: {
        elementType: "input",
        elementConfig: {
          name: "hourly_bonus_for_volunteer_work",
          type: "number",
        },
        label: "Organization Bucks awarded per Hour of Volunteer Work",
      },
      timeZone: {
        elementType: "select",
        elementConfig: {
          name: "timezone",
          isClearable: false,
          options: timezone,
          defaultValue: null,
        },
        label: "Time Zone",
      },
      installationType: {
        elementType: "select",
        elementConfig: {
          name: "installation_type",
          isClearable: false,
          options: [
            { label: "SandBox", value: "SandBox" },
            { label: "Active", value: "Active" },
          ],
          defaultValue: null,
        },
        label: "Installation Type",
      },
      uiVersion: {
        elementType: "select",
        elementConfig: {
          name: "ui_version",
          isClearable: false,
          options: [
            { label: "User Interface 1.0", value: "User Interface 1.0" },
            { label: "User Interface 2.0", value: "User Interface 2.0" },
          ],
          defaultValue: null,
        },
        label: "UI Version",
      },
      staffLimit: {
        elementType: "input",
        elementConfig: { name: "staff_limit", type: "number" },
        label: "Staff Limit",
      },
      memberLimit: {
        elementType: "input",
        elementConfig: { name: "member_limit", type: "number" },
        label: "Member Limit",
      },
      storageLimit: {
        elementType: "input",
        elementConfig: { name: "storage_limit", type: "number" },
        label: "Storage Limit in GB",
      },
      invoiceEmailSender: {
        elementType: "input",
        elementConfig: { name: "invoice_email_sender", type: "text" },
        label: "From Address",
      },
      invoiceEmailBody: {
        elementType: "textarea",
        elementConfig: { name: "invoice_email_body", rows: "4" },
        label: "Email Body",
      },
      isEnabledForFavaTv: {
        elementType: "input",
        elementConfig: { name: "is_enabled_for_favatv", type: "checkbox" },
        label: "FavaTV Enabled?",
      },
      useSharedAmsMediaEncoderPipeline: {
        elementType: "input",
        elementConfig: { name: "use_shared_ams_media_encoder_pipeline", type: "checkbox" },
        label: "Use shared AMS Media Encoder Pipeline?",
      },
      isEnabledForArtsEventsCa: {
        elementType: "input",
        elementConfig: { name: "is_enabled_for_artsevents", type: "checkbox" },
        label: "ArtsEvents.ca Enabled?",
      },
    },
    organization: "",
    loading: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.props.type === "edit") {
      this.setState({ ...this.state, loading: true })
      axios
        .get(`/organizations/${this.props.match.params.id}`, {
          cancelToken: this.signal.token,
        })
        .then((res) => {
          this.setState({
            ...this.state,
            organization: res.data.organization,
            formData: {
              ...this.state.formData,
              full_name: res.data.organization.full_name,
              name: res.data.organization.name,
              subdomain: res.data.organization.subdomain,
              mailing_address: res.data.organization.mailing_address,
              phone_numbers: res.data.organization.phone_numbers,
              office_email: res.data.organization.office_email,
              website: res.data.organization.website,
              gst_rate: res.data.organization.gst_rate,
              pst_rate: res.data.organization.pst_rate,
              hst_rate: res.data.organization.hst_rate,
              gst_number: res.data.organization.gst_number,
              pst_number: res.data.organization.pst_number,
              hst_number: res.data.organization.hst_number,
              hourly_bonus_for_volunteer_work:
                res.data.organization.hourly_bonus_for_volunteer_work,
              timezone: {
                label: res.data.organization.timezone,
                value: res.data.organization.timezone,
              },
              ui_version: {
                label: res.data.organization.ui_version
                  ? res.data.organization.ui_version
                  : "User Interface 2.0",
                value: res.data.organization.ui_version
                  ? res.data.organization.ui_version
                  : "User Interface 2.0",
              },
              installation_type: {
                label: res.data.organization.installation_type
                  ? res.data.organization.installation_type
                  : "SandBox",
                value: res.data.organization.installation_type
                  ? res.data.organization.installation_type
                  : "SandBox",
              },
              staff_limit: res.data.organization.staff_limit,
              member_limit: res.data.organization.member_limit,
              storage_limit: res.data.organization.storage_limit,
              invoice_email_sender: res.data.organization.invoice_email_sender,
              invoice_email_body: res.data.organization.invoice_email_body,
              is_enabled_for_favatv:
                res.data.organization.is_enabled_for_favatv,
              use_shared_ams_media_encoder_pipeline:
                res.data.organization.use_shared_ams_media_encoder_pipeline,
              is_enabled_for_artsevents:
                res.data.organization.is_enabled_for_artsevents,
            },
            loading: false,
          })
        })
        .catch((error) => error)
    } else {
      this.setState({
        ...this.state,
        formData: {
          ...this.state.formData,
          ui_version: {
            label: "User Interface 2.0",
            value: "User Interface 2.0",
          },
          installation_type: { label: "SandBox", value: "SandBox" },
        },
      })
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  submitHandler = (event) => {
    event.preventDefault()
    if (this.state.formData.subdomain.split(" ").length > 1) {
      toast.error("Subdomain can't have more than one word")
      return
    }

    const formDataJSON = this.state.formData
    formDataJSON.timezone =
      formDataJSON.timezone !== "" ? formDataJSON.timezone.value : ""
    formDataJSON.installation_type =
      formDataJSON.installation_type.value !== ""
        ? formDataJSON.installation_type.value
        : ""
    formDataJSON.ui_version =
      formDataJSON.ui_version.value !== "" ? formDataJSON.ui_version.value : ""

    const formData = new FormData()

    for (const key in formDataJSON) {
      formData.append(key, formDataJSON[key] ? formDataJSON[key] : "")
    }

    formDataJSON.logo && formData.append("logo", formDataJSON.logo)

    if (this.props.type === "edit") {
      this.setState({ ...this.state, loading: true })
      axios
        .put(`/organizations/${this.state.organization.id}`, formData)
        .then((res) => {
          if (res.data.json.success) {
            toast.success("Organization updated successfully.")
            this.setState({ ...this.state, loading: false })
            if (
              res.data.json.data.id === parseInt(localStorage.organizationId)
            ) {
              localStorage.setItem("logoUrl", res.data.json.data.logo)
            }
            this.props.history.push(`/organizations/`)
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(error + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
            this.setState({ ...this.state, loading: false })
          }
        })
        .catch((error) => error)
    } else if (this.props.type === "add") {
      this.setState({ ...this.state, loading: true })
      axios
        .post(`/organizations`, formData)
        .then((res) => {
          if (res.data.json.success) {
            toast.success("Organization created successfully.")
            this.setState({ ...this.state, loading: false })
            this.props.history.push(`/organizations`)
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach((error) => {
                toast.error(error + " " + res.data.json.errors[error])
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error)
            }
            this.setState({ ...this.state, loading: false })
          }
        })
        .catch((error) => error)
    }
  }

  inputEventHandler = (event, field) => {
    const data = { ...this.state.formData }

    if (["timezone", "installation_type", "ui_version"].includes(field)) {
      data[field] = event === null ? "" : event
    } else if (field === "logo") {
      data[field] = event.target.files[0]
    } else {
      data[field] = event.target.value
    }

    this.setState({
      ...this.state,
      formData: data,
      logoPreview:
        field === "logo"
          ? URL.createObjectURL(event.target.files[0])
          : this.state.logoPreview,
    })
  }

  render() {
    const { organization } = this.state
    return (
      <div className="container w-100">
        <div className="row mb-4">
          <div className="col-12">
            <h3>
              {this.props.type === "add"
                ? "Add Organization"
                : "Edit Organization"}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body p-4">
                {this.state.loading ? (
                  <Spinner className="h-60vh" />
                ) : (
                  <div className="card-text">
                    <form
                      action=""
                      onSubmit={(event) => this.submitHandler(event)}
                    >
                      <h5>Basic</h5>
                      <hr />
                      <div className="row">
                        <div className="col-lg-6">
                          <Input
                            {...this.state.input.fullName}
                            value={this.state.formData.full_name}
                            changed={(event) =>
                              this.inputEventHandler(event, "full_name")
                            }
                          />
                        </div>
                        <div className="col-lg-6">
                          <Input
                            {...this.state.input.name}
                            value={this.state.formData.name}
                            changed={(event) =>
                              this.inputEventHandler(event, "name")
                            }
                          />
                        </div>
                        <div className="col-12">
                          <Input
                            {...this.state.input.logo}
                            className="logoinput"
                            changed={(event) =>
                              this.inputEventHandler(event, "logo")
                            }
                          />
                        </div>
                        {organization.logo || this.state.logoPreview ? (
                          <div className="col-12 mb-5">
                            <img
                              className="card-img-top d-block"
                              src={this.state.logoPreview || organization.logo}
                              alt="Logo"
                              style={{ width: "400px" }}
                              onError={(e) => {
                                e.target.className = "d-none"
                              }}
                              onLoad={(e) => {
                                e.target.className = "card-img-top d-block"
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.gstRate}
                            value={this.state.formData.gst_rate}
                            changed={(event) =>
                              this.inputEventHandler(event, "gst_rate")
                            }
                            elementConfig={{ type: "number" }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.pstRate}
                            value={this.state.formData.pst_rate}
                            changed={(event) =>
                              this.inputEventHandler(event, "pst_rate")
                            }
                            elementConfig={{ type: "number" }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.hstRate}
                            value={this.state.formData.hst_rate}
                            changed={(event) =>
                              this.inputEventHandler(event, "hst_rate")
                            }
                            elementConfig={{ type: "number" }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.gstNumber}
                            value={this.state.formData.gst_number}
                            changed={(event) =>
                              this.inputEventHandler(event, "gst_number")
                            }
                          />
                        </div>
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.pstNumber}
                            value={this.state.formData.pst_number}
                            changed={(event) =>
                              this.inputEventHandler(event, "pst_number")
                            }
                          />
                        </div>
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.hstNumber}
                            value={this.state.formData.hst_number}
                            changed={(event) =>
                              this.inputEventHandler(event, "hst_number")
                            }
                          />
                        </div>
                      </div>
                      <br />
                      <br />

                      <h5>Advanced</h5>
                      <hr />
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-lg-6">
                              <Input
                                {...this.state.input.subDomain}
                                value={this.state.formData.subdomain}
                                changed={(event) =>
                                  this.inputEventHandler(event, "subdomain")
                                }
                              />
                            </div>
                            <div className="col-lg-6">
                              <Input
                                {...this.state.input.officeEmail}
                                value={this.state.formData.office_email}
                                changed={(event) =>
                                  this.inputEventHandler(event, "office_email")
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <Input
                                {...this.state.input.phoneNumbers}
                                value={this.state.formData.phone_numbers}
                                changed={(event) =>
                                  this.inputEventHandler(event, "phone_numbers")
                                }
                              />
                            </div>
                            <div className="col-lg-6">
                              <Input
                                {...this.state.input.organizationWebsite}
                                value={this.state.formData.website}
                                changed={(event) =>
                                  this.inputEventHandler(event, "website")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.mailingAddress}
                            value={this.state.formData.mailing_address}
                            changed={(event) =>
                              this.inputEventHandler(event, "mailing_address")
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-8">
                          <Input
                            {...this.state.input.timeZone}
                            selectedOption={this.state.formData.timezone}
                            changed={(event) =>
                              this.inputEventHandler(event, "timezone")
                            }
                          />
                        </div>
                        <div className="col-lg-12">
                          <Input
                            {...this.state.input.bonus}
                            value={
                              this.state.formData
                                .hourly_bonus_for_volunteer_work
                            }
                            changed={(event) =>
                              this.inputEventHandler(
                                event,
                                "hourly_bonus_for_volunteer_work"
                              )
                            }
                            elementConfig={{ type: "number" }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <Input
                            {...this.state.input.installationType}
                            selectedOption={
                              this.state.formData.installation_type
                            }
                            changed={(event) =>
                              this.inputEventHandler(event, "installation_type")
                            }
                          />
                        </div>
                        <div className="col-lg-6">
                          <Input
                            {...this.state.input.uiVersion}
                            selectedOption={this.state.formData.ui_version}
                            changed={(event) =>
                              this.inputEventHandler(event, "ui_version")
                            }
                          />
                        </div>
                      </div>
                      <br />
                      <br />

                      <h5>Limits</h5>
                      <hr />
                      <div className="row">
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.staffLimit}
                            value={this.state.formData.staff_limit}
                            changed={(event) =>
                              this.inputEventHandler(event, "staff_limit")
                            }
                          />
                        </div>
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.memberLimit}
                            value={this.state.formData.member_limit}
                            changed={(event) =>
                              this.inputEventHandler(event, "member_limit")
                            }
                          />
                        </div>
                        <div className="col-lg-4">
                          <Input
                            {...this.state.input.storageLimit}
                            value={this.state.formData.storage_limit}
                            changed={(event) =>
                              this.inputEventHandler(event, "storage_limit")
                            }
                          />
                        </div>
                      </div>
                      <br />
                      <br />

                      <h5>Automated Invoice Emailing</h5>
                      <hr />
                      <div className="row">
                        <div className="col-lg-12">
                          <Input
                            {...this.state.input.invoiceEmailSender}
                            value={this.state.formData.invoice_email_sender}
                            changed={(event) =>
                              this.inputEventHandler(
                                event,
                                "invoice_email_sender"
                              )
                            }
                          />
                        </div>
                        <div className="col-lg-12">
                          <Input
                            {...this.state.input.invoiceEmailBody}
                            value={this.state.formData.invoice_email_body}
                            changed={(event) =>
                              this.inputEventHandler(
                                event,
                                "invoice_email_body"
                              )
                            }
                          />
                        </div>
                      </div>
                      <br />
                      <br />
                      <h5>Additional Services</h5>
                      <hr />
                      <div className="row">
                        <div className="col-lg-12 text-left">
                          <Input
                            {...this.state.input.isEnabledForFavaTv}
                            elementConfig={{
                              checked: this.state.formData
                                .is_enabled_for_favatv,
                              type: "checkbox",
                            }}
                            changed={(event) =>
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  is_enabled_for_favatv: !this.state.formData
                                    .is_enabled_for_favatv,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-left">
                          <Input
                            {...this.state.input.useSharedAmsMediaEncoderPipeline}
                            elementConfig={{
                              checked: this.state.formData
                                .use_shared_ams_media_encoder_pipeline,
                              type: "checkbox",
                            }}
                            changed={(event) =>
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  use_shared_ams_media_encoder_pipeline: !this.state.formData
                                    .use_shared_ams_media_encoder_pipeline,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-left">
                          <Input
                            {...this.state.input.isEnabledForArtsEventsCa}
                            elementConfig={{
                              checked: this.state.formData
                                .is_enabled_for_artsevents,
                              type: "checkbox",
                            }}
                            changed={(event) =>
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  is_enabled_for_artsevents: !this.state
                                    .formData.is_enabled_for_artsevents,
                                },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="text-center m-4">
                        <FormButton>Submit</FormButton>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(OrganizationForm)
