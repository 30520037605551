/**
 * Returns a "Contributor" string or itself based on the input string.
 * @param {string} moduleName - The name to be checked for translation.
 * @returns {string} - The string output after valid translation.
 */

const translateModuleName = (moduleName) => {
  switch (moduleName) {
    case 'Crew Roles':
      return 'Contributors'
    case 'Crew Role':
      return 'Contributor'
    case 'EquipmentItem':
      return 'Equipment'
    default:
      return moduleName
  }
}

export default translateModuleName
