import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { title } from "change-case";

import Input from "../../UI/input/Input";
import Button from "../../UI/button/Button";
import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";

import CKEditorFull from "./CkEditorFull";
import Config from "./config";
import VerifiedEmailSelect from "./VerifiedEmailsSelect";

import "./Invoicing.css";

class Invoicing extends Component {
  signal = axios.CancelToken.source();
  state = {
    formAddress: "",
    emailBody: null,
    id: null,
    loading: true,
    disabled: false,
    verifiedEmails: [],
    useEmailDropdown: false,
    defaultFromEmail: "",
  };

  componentDidMount() {
    axios.get("/organization_communication_emails", { 
      cancelToken: this.signal.token 
    }).then(res => {
      const { use_verified_email } = res.data.meta;
      let verifiedEmails = [];
      let useEmailDropdown = false;
      if ( use_verified_email ) {
        const { organization_communication_emails: orgEmails } = res.data;
        verifiedEmails =
          Array.isArray(orgEmails)
            ? orgEmails.filter(email => email.is_verified)
            : [];
        useEmailDropdown = verifiedEmails.length >= 1;
      }
      this.setState({ verifiedEmails, useEmailDropdown }, this.getFormData);
    })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getFormData = () => {
    axios
      .get(`/mail_formats`, {
        cancelToken: this.signal.token
      })
      .then(res => {
        res.data.mail_formats.forEach(format => {
          if (format.tab_name === "Invoicing") {
            this.setState({
              id: format.id,
              emailBody: format.data.body_email,
              formAddress: format.data.from_email,
              defaultFromEmail: format.data.from_email,
              loading: false
            });
          }
        });
      });
  }

  updateHandler = () => {
    this.setState({ disabled: true })
    const invoiceData = {
      id: this.state.id,
      from_email: this.state.formAddress,
      body_email: this.state.emailBody
    };

    axios.put("/mail_formats", invoiceData).then(res => {
      if (res.data.json.success) {
        toast.success("Invoice Mail Format successfully updated");
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(title(error) + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
      }
      this.setState({ disabled: false })
    });
  };

  inputChange = event => {
    this.setState({
      emailBody: event.editor.getData()
    });
  };

  render() {
    const { 
      useEmailDropdown, 
      verifiedEmails,
      defaultFromEmail, 
    } = this.state;

    return (
      this.state.loading ? (
        <div className="row">
          <div className="col-12">
            <Spinner className="h-60vh" />
          </div>
        </div>
      ) : (
          <div className="p-4">
            <div className="row">
              <div className="col-12">
                <h5 className="font-weight-bold pt-2">Automated Invoice Emailing</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-3">
                <label className="font-weight-normal">From Address</label>
                {
                  useEmailDropdown
                    ? (
                      <VerifiedEmailSelect
                        emailAddresses={verifiedEmails}
                        defaultEmail={defaultFromEmail}
                        emailChangeHandler={email => this.setState({
                          formAddress: email ? email.label : "",
                        })}
                      />
                    ) : (
                      <Input
                        elementType="input"
                        elementConfig={{ type: "email" }}
                        value={this.state.formAddress}
                        changed={event =>
                          this.setState({ formAddress: event.target.value })
                        }
                      />
                   )
                }
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-editor">
                <label>Body Text</label>
                <div className="bd-highlight">
                  <CKEditorFull
                    data={this.state.emailBody}
                    config={Config}
                    onChange={this.inputChange}
                  />
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-12 text-center">
                <Button type="success" clicked={this.updateHandler} disabled={this.state.disabled}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        )
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(Invoicing);
