import React, { Component } from "react";
import { connect } from "react-redux";
import { getGrantApplications } from '../../store/slices/grantApplicationSlice'
import { clearResponse } from '../../store/actions/index'
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { isEmpty } from "lodash";
import Button from "../UI/button/Button";
import "./index.css";
import SelectGrantCampaign from "./SelectGrantCampaign";
import Modal from "../UI/Modal";
import spinner from "../../assests/images/spinner-gif.gif";
import GrantApplicationsTable from "./grantApplicationsTable/GrantApplicationsTable"
import { getActiveGrantCampaigns } from "../../store/slices/grantCampaign";

class GrantApplicationsIndex extends Component {
  state = {
    showModal: false,
    refreshList: false,
  };

  componentDidMount() {
    this.props.getGrantApplications()
    this.props.getActiveGrantCampaigns()
    this.newApplicationRouteValidation() && this.openModal()
  }

  componentDidUpdate() {
    if (!isEmpty(this.props.response) && this.props.response.success !== null) {
      const { message, success } = this.props.response;
      this.props.clearResponse()
        
      success ? toast.success(message) : toast.error(message);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.newApplicationRouteValidation() && this.openModal()
    }
  }

  openModal = () => {
    window.scrollTo(0, 0);
    this.setState({
      showModal: true,
      refreshList: false,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false
    }, () => {
        this.newApplicationRouteValidation() && this.props.history.push('/grant_applications')
    })
  }

  newApplicationRouteValidation() {
    if (this.props.location.pathname.includes("new")) return true
  }

  render() {
    const grantApplications = this.props.grantApplications

    const Spinner = () => {
      return (
        <img
          src={spinner}
          style={{ width: "200px", margin: "auto", display: "block" }}
          alt="Loading..."
        />
      )
    }

    return (
      <div className="container projects w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>My Applications</h3>
          </div>
          <div className="col-6 text-right">
            <Button type="success" clicked={this.openModal}>
              New Application
            </Button>
          </div>
        </div>

        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs font-weight-bold">
                <li className="nav-item px-4 active-tab">
                  <NavLink className="decoration-none" to="/grant_applications">
                    Applications
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="w-100 card d-flex flex-row justify-content-center">
          { grantApplications && grantApplications.loading ?
            <Spinner />
            :
              <GrantApplicationsTable grantApplications={grantApplications.grantApplicationsList}/>
          }
        </div>

        <Modal
          title="Select a Campaign"
          show={this.state.showModal}
          flag={true}
          hide={() => this.closeModal()}
          closeModal={() => this.closeModal()}
          closeButton
        >
          <SelectGrantCampaign
            closeModal={() =>
              this.setState({ showModal: false }, () => {
                this.props.getGrantApplications()
              })
            }
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    grantApplications: state.grantApplications,
    grantCampaigns: state.grantCampaigns,
    response: state.response,
  };
};

export default connect(mapStateToProps, {
  clearResponse,
  getActiveGrantCampaigns,
  getGrantApplications
})(GrantApplicationsIndex);
