import { setResponse, clearResponse, setLoading } from "./index";
import axios from "../../configAxios";
import { grantCampaignUpdate } from "../slices/grantCampaign"

export const createGrantCampaign = (campaignData, callback, history) => {
  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      const res = await axios.post("/grant_campaigns", campaignData);
      const { success } = res.data.json;
      if (success) {
        dispatch(setResponse(true, "Campaign Created Successfully"));
        callback && callback()
      } else {
        const { errors } = res.data.json;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${key.charAt(0).toUpperCase() + key.slice(1)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};

export const updateGrantCampaign = (campaignData, history) => {
  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      dispatch(setLoading(true));
      const res = await axios.put(
        `/grant_campaigns/${campaignData.id}`,
        campaignData
      );
      const { success } = res.data.json;
      if (success) {
        dispatch(setResponse(true, "Campaign Updated Successfully"));
        dispatch(
          grantCampaignUpdate({
            id: campaignData.id,
            changes: { ...campaignData }
          })
        )
      } else {
        const { errors } = res.data.json;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${key.charAt(0).toUpperCase() + key.slice(1)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};

export const duplicateGrantCampaign = (campaignData, callback) => {
  return async (dispatch) => {
    dispatch(clearResponse())

    try {
      const res = await axios.post(
        `/grant_campaigns/${campaignData.id}/duplicate`,
        campaignData
      )

      const { success } = res.data.json;

      if (success) {
        dispatch(setResponse(true, "Campaign Duplicated Successfully"));
        callback && callback()
      } else {
        const { errors } = res.data.json;

        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${key.charAt(0).toUpperCase() + key.slice(1)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
}
