import React from "react"
import axios from "../../../configAxios";
import { toast } from "react-toastify";

const AccountInfo = ({ user }) => {
  const { id: userID, organization_id: orgID } = user

  const generatePdf = () => {
    toast.success('Please wait, your membership card is being generated')
    axios.get(`/users/${user.id}/membership_pdf`, {
      params: {
        id: user.id
      }
    })
      .then(res => {
        window.open(res.data.json.file_path, "_blank");
      })
      .catch(err => toast.error("Failed to download"));
  };

  return (

    <div className="card d-flex flex-row align-items-center align-content-center justify-content-between mt-3 col-12 py-2">
      <div className="font-weight-bold">
        Account #
      </div>
      <div>{orgID}-{userID}</div>
      <i
        className="fas fa-address-card cursor"
        onClick={() => generatePdf()}
      />
    </div>
  )
}

export default AccountInfo
