import React from "react";

const ViewAssetPriceType = props => (
  <div>
    <div className="mt-3" >
      <label>Type:</label>
      <p>{props.assetPrice.name}</p>
    </div >
    <div className="mt-3">
      <label>Priority:</label>
      <p>{props.assetPrice.level}</p>
    </div>
  </div>
);

export default ViewAssetPriceType;
