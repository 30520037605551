import React, { useState, useEffect } from 'react'
import Input from "../../../../UI/input/Input";
import Button from "../../../../UI/button/Button";
import MultiQuestionOption from './MultiQuestionOption'
import MandatoryCheckbox from "./MandatoryCheckbox";
import { toast } from "react-toastify"

export default function MultiQuestion({question, submitNewQuestion, updateQuestion, updateQuestionState}) {
  const [questionOptions, setQuestionOptions] = useState(question.question_options)
  const [questionText, setQuestionText] = useState(question.question_text)
  const [isEditing, setIsEditing] = useState(!question.question_text)
  const [isMandatory, setIsMandatory] = useState(question.mandatory)

  const createOption = () => {
    setQuestionOptions(prev => [...prev, {option_text: "", option_order: prev.length}])
  }

  useEffect(() => {
    setIsEditing(!(question.question_options.length >= 2 && question.question_text))
  }, [question])

  const checkContent = () => {
    if (!questionText) return true
    if (questionOptions.length < 2) return true
    for (let option of questionOptions) {
      if(option.option_text === "") return true
    }
    return false
  }

  const removeOption = (removedOption) => {
    return questionOptions.filter(option => option.option_order!== removedOption.option_order)
  }

  const setOptionText = (option, text) => {
    setQuestionOptions(prev => (
      prev.map(arrOption=> {
        return arrOption.option_order === option.option_order ? {...arrOption, option_text: text} : arrOption
      })
    ))
  }

  const resetOrder = (options) => {
    return options.map((option, index) => {
        return option.option_order === index ? option : {...option, option_order: index}
      })
  }

  const handleSubmit = function() {
    if (isEditing) {
      let payload = {
        question_text: questionText,
        mandatory: isMandatory,
        type: question.type,
        order: question.order,
        question_options: questionOptions,
        id: question.id || question.question_id || null
      }

      if (question.id || question.question_id) {
        payload["question_id"] = question.id
        updateQuestionState(payload)
        setIsEditing(false)
        updateQuestion(payload).then(() => {
          toast.success("Successfully Updated Question!")
        })
      } else {
        submitNewQuestion(payload)
        updateQuestionState(payload)
        setIsEditing(false)
      }
    }

    if (!isEditing) {
      setIsEditing(!isEditing)
    }
  }

  return (
    <div className="w-100 d-flex flex-column mt-3">
      <MandatoryCheckbox
        isMandatory={isMandatory}
        isEditing={isEditing}
        setIsMandatory={() => setIsMandatory(prev => !prev)}
      />
      <Input
        elementType="textarea"
        elementConfig={{
          name: "question_text",
          required: true,
          rows: 3,
          readOnly: !isEditing,
        }}
        changed={event => setQuestionText(event.target.value)}
        value={questionText}
        label="Question Text"
      />
      <div className="text-right my-3">
        <Button clicked={() => createOption()} type="success" disabled={!isEditing}>
          Add Option
        </Button>
      </div>
      <div className="d-flex flex-column list-group">
        { questionOptions.length > 0 && (
            questionOptions.map((option, index) => {
              return ( 
                <MultiQuestionOption 
                  key={index}
                  option={option}
                  setOptionText={setOptionText}
                  removeOption={(option) => {
                    setIsEditing(true)
                    setQuestionOptions(resetOrder(removeOption(option)))}}
                  isEditing={isEditing}/>
              )})
          )
        }
      </div>
      <div className='d-flex flex-row justify-content-end'> 
        <Button
          className="questionSaveButton"
          type="success"
          disabled={checkContent()}
          clicked={() => {handleSubmit()}}
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </div>
    </div>
  )
}
