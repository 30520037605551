import React from 'react'
import ShortAnswerQuestion from './questions/ShortAnswerQuestion'
import LongAnswerQuestion from './questions/LongAnswerQuestion'
import CheckboxQuestion from './questions/CheckboxQuestion'
import MultipleChoiceQuestion from './questions/MultipleChoiceQuestion'
import DropdownQuestion from './questions/DropdownQuestion'
import FileUploadQuestion from './questions/FileUploadQuestion'
import TextContent from './questions/TextContent'
import FileDownloadQuestion from './questions/FileDownloadQuestion'
import ProjectLinkQuestion from "./questions/ProjectLinkQuestion";
import DateSelectQuestion from "./questions/DateSelectQuestion"
import CurrencyQuestion from "./questions/CurrencyQuestion"
import ApplicantData from "../../campaigns/campaign/grantApplications/applicationOverview/ApplicantData"

export default function FormQuestions({form, applicantData, responses, completed, setResponses}) {

  const setResponseData = (id, data) => {
    setResponses(prev => {
        return prev.map(response => response.question_id === id ? {...response, ...data} : response)
      })
  }

  const selectResponse = (question_id) => {
    return responses.find(response => response.question_id === question_id)
  }

  const mapQuestionsToInputs = (question, index) => {
    switch(question.type) {
      case "ShortAnswerQuestion": 
        return <ShortAnswerQuestion key={index} question={question} completed={completed} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "CurrencyQuestion": 
        return <CurrencyQuestion key={index} question={question} completed={completed} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "LongAnswerQuestion":
        return <LongAnswerQuestion key={index} question={question} completed={completed} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "CheckboxQuestion":
        return <CheckboxQuestion key={index} question={question} completed={completed} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "MultipleChoiceQuestion":
        return <MultipleChoiceQuestion key={index} question={question} completed={completed} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "DropdownQuestion":
        return <DropdownQuestion key={index} question={question} completed={completed} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "FileUploadQuestion":
        return <FileUploadQuestion key={index} question={question} completed={completed} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "FileDownloadQuestion":
        return <FileDownloadQuestion key={index} question={question} />
      case "TextQuestion":
        return <TextContent key={index} question={question} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "ProjectLinkQuestion":
        return <ProjectLinkQuestion key={index} question={question} completed={completed} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "DateSelectQuestion":
        return <DateSelectQuestion key={index} question={question} completed={completed} response={selectResponse(question.id) || null} setResponseData={(id, data) => setResponseData(id, data)}/>
      case "ApplicantDataQuestion":
        return <ApplicantData applicantData={applicantData}/>
    }
  }
  return (
    form.questions.map((question, index) => mapQuestionsToInputs(question, index))
  )
}
