import React, { Component } from "react";

import CenteredContainer from "../UI/container/CenteredContainer";
import Spinner from "../UI/Spinner";
import Pagination from "../UI/Pagination";

import ExpertsItem from "./ExpertsItem";

class ExpertsItems extends Component {
  render() {
    const { experts, totalCount, forcePage, perPage } = this.props;
    let expertsItems;
    if (this.props.loading) {
      expertsItems = <Spinner />;
    } else {
      if (experts.length > 0) {
        expertsItems = experts.map((expert, index) => (
          <ExpertsItem key={expert.id} index={index} expert={expert} />
        ));
      } else {
        expertsItems = (
          <CenteredContainer>
            <h4 className="center-block">No experts found...</h4>
          </CenteredContainer>
        );
      }
    }

    return (
      <div className="card webkit-fill-available">
        <div className="webkit-fill-available">
          <ul className="list-group list-group-flush">{expertsItems}</ul>
        </div>
        <div className="mt-3">
          {totalCount > 0 && (
            <Pagination
              forcePage={forcePage}
              pageCount={totalCount / 10}
              handlePageClick={this.props.paginationHandler}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ExpertsItems;
