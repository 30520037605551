const tagName = (tagValue) => {
  if (tagValue && tagValue.includes(":")) {
    let tagNames = tagValue.split(":")
    return tagNames.slice(1, tagNames.length).join(":")
  }

  return tagValue
}

export default tagName
