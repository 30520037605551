import React, { Component } from "react"

import ModalSpinner from "../../UI/ModalSpinner"

import CreateThirdPartyOwner from "./CreateThirdPartyOwner"

class AddNewThirdPartyOwner extends Component {
  closeModal = () => {
    this.props.closeModal()
  }

  render() {
    return (
      <div>
        {this.props.loading ? (
          <ModalSpinner />
        ) : (
          <div>
            <CreateThirdPartyOwner
              thirdPartyDetails={this.props.thirdPartyDetails}
              closeModal={this.closeModal}
              getThirdPartyOwners={this.props.getThirdPartyOwners}
              editOrMigrate={this.props.edit}
              migrate={this.props.migrate}
            />
          </div>
        )}
      </div>
    )
  }
}

export default AddNewThirdPartyOwner
