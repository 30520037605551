import React, { Component } from "react";
import Item from "./Item";
import Spinner from "../UI/Spinner";
import CenteredContainer from "../UI/container/CenteredContainer";
import CampaignSearch from "./CampaignSearch";
import _ from "lodash";

class CampaignItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: false,
      selectedReels: [],
    };
  }

  selectedReelsHandler = (projectId, isChecked) => {
    const selectedReels = [...this.state.selectedReels];
    if (isChecked) {
      selectedReels.push(projectId);
      this.setState({ selectedReels: selectedReels });
    } else {
      var index = selectedReels.indexOf(projectId);
      if (index !== -1) {
        selectedReels.splice(index, 1);
        this.setState({ selectedReels: selectedReels });
      }
    }
  };


  componentDidMount() {
    this.props.getGrantCampaigns()
  }

  render() {
    const totalCount = _.isEmpty(this.props.campaigns) ? 0 : this.props.campaigns.length

    const GrantCampaignItems = () => {
      if (_.isEmpty(this.props.campaigns)) {
        return (
          <CenteredContainer>
            <h4 className="center-block">No Campaigns found...</h4>
          </CenteredContainer>
        )
      } else {
         return (
          this.props.campaigns.map(campaign => (
            <Item
              key={campaign.id}
              campaign={campaign}
              isChecked={campaign.has_video ? this.state.selectedReels.indexOf(campaign.id) !== -1 : false}
              clicked={this.selectedReelsHandler}
              getGrantCampaigns={this.props.getGrantCampaigns}
              isAdmin={() => this.props.isAdmin()}
              isProgramOfficer={this.props.userId === campaign.program_officer.id}
            />
          )
        )
      )
      }
    }
    return (
      <div className="card-body p-0" style={{ minHeight: "14rem" }}>
        <div className="card-text">
          <div className="row">
            <div className="col-lg-5">
              <CampaignSearch
                clearSearch={() => this.props.getGrantCampaigns()}
                getGrantCampaigns={search_params => this.props.getGrantCampaigns(search_params)}
                loading={this.props.campaignLoading}
              />
            </div>
            <div className="col-lg-7">
              <div className="border-left">
                <div className="p-4">
                  <div className="row">
                    <div className="col-6 py-2">
                      <span>
                        Total Campaigns:{" "}
                        <span className="font-weight-bold">{totalCount}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <ul className="list-group list-group-flush">
                  {!this.props.campaigns || this.props.campaignLoading
                    ?
                    <Spinner />
                    :
                    <GrantCampaignItems />
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CampaignItems
