import React from "react"

const DeleteIcon = ({tag, deleteTag}) => {
  const deleteIconStyle = tag.count ? {cursor: "no-drop", opacity: 0.5} : {cursor: "pointer"};

  const handleClick = () => {
    if (!tag.count) {
      deleteTag(tag.id);
    }
  }

  return (
    <i
      className="fas fa-trash cursor"
      title="Delete"
      style={deleteIconStyle}
      onClick={handleClick}
    />
  )
};

export default DeleteIcon
