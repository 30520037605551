import React from "react"
import langUtil from "../../../utils/langUtils"
import Input from "../../UI/input/Input"

const InactiveFriendsCheckbox = ({ value, changed }) => {
  const label = `Include Inactive ${langUtil("txt_global_friends", "Friends")} in search?`
  return (
    <Input
      key="inActive"
      elementType="input"
      elementConfig={{
        name: "inactive",
        type: "checkbox",
        checked: value
      }}
      label={label}
      changed={changed}
    />
  )
}

export default InactiveFriendsCheckbox
