import React from "react"
import Select from "react-select";

const StatusSelect = ({status, setStatus}) => {
  const options = [{ value: 'Draft', label: 'Draft' }, { value: 'Active', label: 'Active' }]
  const selectDefault = options.filter(option => option.value === status)
  return (
    <div className="pb-3">
      <label> Campaign Status </label>
      <Select
        options={options}
        defaultValue={selectDefault}
        onChange={(selected) => setStatus(selected.value)}
      />
    </div>
  )
}

export default StatusSelect
