import React from 'react'

export default function ApplicationFormItem({form, applicationFormItem, grantApplication}) {
  return (
    <tr>
      <td>{form.name}</td>
      <td>{applicationFormItem && applicationFormItem.updated_when || "N/A"}</td>
      <td>{applicationFormItem && applicationFormItem.status || "Not Started"}</td>
    </tr>
  )
}
