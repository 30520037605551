/**
* Returns a boolean true if protocol exists and is either "http://" or "https://", otherwise returns false.
* Second param for "new URL(1st, 2nd)" is optional and exists to maintain Safari compatibility.
* @param {string} urlString - The name to be checked for translation.
* @returns {boolean} - The string output after valid translation.
*/

const isValidUrl = (urlString) => {
  let url
  try {
    url = new URL(urlString, urlString)
  }
  catch (_) {
    return false
  }
  return url.protocol === "http:" || url.protocol === "https:"
}

export default isValidUrl
