import React from "react";
import { Link } from "react-router-dom";
import dateFns from "date-fns";

const bookingActivity = props => {
  let title;
  if (props.booking.user_or_program === "User") {
    title =
      props.booking.user_or_program_detail.organization_name ||
      `${props.booking.user_or_program_detail.first_name} ${
      props.booking.user_or_program_detail.last_name
      }`;
  } else {
    title = props.booking.user_or_program_detail.name;
  }
  return (
    <li className="list-group-item px-3 pt-3">
      <div className="row">
        <div className="col-12">
          {props.activeTab && props.activeTab === "EquipmentItem"
            ? props.booking.user_or_program === "User"
              ? <Link
                to={`/users/${props.booking.user_or_program_detail.id}/summary`}
                className="text-info"
              >
                <div className="text-primary">
                  {`${title} - ${props.booking.ticket.project_name ? props.booking.ticket.project_name : "No Project Name"}`}
                </div>
              </Link>
              : <Link
                to={`/programs/${props.booking.user_or_program_detail.id}`}
                className="text-info"
              >
                <div className="text-primary">
                  {`${title} - ${props.booking.ticket.project_name ? props.booking.ticket.project_name : "No Project Name"}`}
                </div>
              </Link>
            : props.booking.user_or_program === "User"
              ? <div className="text-primary">
                {`${title} - ${props.booking.ticket.project_name ? props.booking.ticket.project_name : "No Project Name"}`}
              </div>
              : <div className="text-primary">
                {`${title} - ${props.booking.ticket.project_name ? props.booking.ticket.project_name : "No Project Name"}`}
              </div>
          }
          <div className="mb-1">{`Ticket Created: ${dateFns.format(new Date(props.booking.created_at), "ddd, MMM DD, YYYY")}`}</div>
        </div>
      </div>
    </li>
  );
};

export default bookingActivity;