import React, { Component } from "react";
import "./TabContent.css";
import ListItem from "./ListItem";

class TabContent extends Component {
  state = {
    content: [],
    activeTab: "",
    allMediaHeadings: {
      2: "Director",
      3: "Contributor",
      4: "Type",
      5: "Year Released",
      6: "# of Copies"
    },
    videoHeadings: {
      2: "Director",
      3: "Type",
      4: "Year",
      5: "# of Copies",
      6: "Runtime",
      7: "Feature Type",
      8: "Genre",
      9: "Edition"
    },
    audioHeadings: {
      2: "Contributer",
      3: "Type",
      4: "Year Released",
      5: "# of Copies"
    },
    printHeadings: {
      2: "Author",
      3: "Print type",
      4: "#Copies",
      5: "Pages",
      6: "Publisher",
      7: "Year"
    },
    width: window.innerWidth
  };

  componentDidMount() {
    this.setState({
      content: this.props.content,
      activeTab: this.props.activeTab
    });
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillReceiveProps(props) {
    this.setState({ content: props.content, activeTab: props.activeTab });
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 768;
    const tableBody = this.state.content.map(item => (
      <ListItem key={item.id} listItem={item} type={this.state.activeTab} />
    ));
    let tableHeadings;
    switch (this.state.activeTab) {
      case "All Media":
        tableHeadings = Object.keys(this.state.allMediaHeadings).map(key => {
          return (
            <th
              key={key}
              onClick={
                key === "2" || key === "3"
                  ? ""
                  : () =>
                    this.props.sortingHandler(
                      parseInt(key),
                      this.props.sortColNumber === parseInt(key)
                        ? this.props.showUpIcon
                          ? "desc"
                          : "asc"
                        : "asc"
                    )
              }
              style={{
                cursor: key === "2" || key === "3" ? "" : "pointer",
                width: 150
              }}
            >
              {this.state.allMediaHeadings[key]}
              {parseInt(key) === 2 || parseInt(key) === 3 ? (
                ""
              ) : (
                  <>
                    <i
                      className={
                        this.props.sortColNumber === parseInt(key)
                          ? this.props.showUpIcon
                            ? "fas fa-sort-up sort-up"
                            : "fas fa-sort-up hide"
                          : "fas fa-sort-up sort-up"
                      }
                      onClick={() =>
                        this.props.sortingHandler(parseInt(key), "desc")
                      }
                    />
                    <i
                      className={
                        this.props.sortColNumber === parseInt(key)
                          ? this.props.showDownIcon
                            ? "fas fa-sort-down sort-down"
                            : "fas fa-sort-down hide"
                          : "fas fa-sort-down sort-down"
                      }
                      onClick={() =>
                        this.props.sortingHandler(parseInt(key), "asc")
                      }
                    />
                  </>
                )}
            </th>
          );
        });
        break;
      case "Video":
        tableHeadings = Object.keys(this.state.videoHeadings).map(key => (
          <th
            key={key}
            onClick={
              key === "2"
                ? ""
                : () =>
                  this.props.sortingHandler(
                    parseInt(key),
                    this.props.sortColNumber === parseInt(key)
                      ? this.props.showUpIcon
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
            }
            style={{
              cursor: key === "2" ? "" : "pointer",
              width: 140
            }}
          >
            {this.state.videoHeadings[key]}
            {parseInt(key) === 2 ? (
              ""
            ) : (
                <>
                  <i
                    className={
                      this.props.sortColNumber === parseInt(key)
                        ? this.props.showUpIcon
                          ? "fas fa-sort-up sort-up"
                          : "fas fa-sort-up hide"
                        : "fas fa-sort-up sort-up"
                    }
                    onClick={() =>
                      this.props.sortingHandler(parseInt(key), "desc")
                    }
                  />
                  <i
                    className={
                      this.props.sortColNumber === parseInt(key)
                        ? this.props.showDownIcon
                          ? "fas fa-sort-down sort-down"
                          : "fas fa-sort-down hide"
                        : "fas fa-sort-down sort-down"
                    }
                    onClick={() =>
                      this.props.sortingHandler(parseInt(key), "asc")
                    }
                  />
                </>
              )}
          </th>
        ));
        break;
      case "Audio":
      case "Film":
        tableHeadings = Object.keys(this.state.audioHeadings).map(key => (
          <th
            key={key}
            onClick={
              key === "2"
                ? ""
                : () =>
                  this.props.sortingHandler(
                    parseInt(key),
                    this.props.sortColNumber === parseInt(key)
                      ? this.props.showUpIcon
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
            }
            style={{
              cursor: key === "2" ? "" : "pointer",
              width: 170
            }}
          >
            {this.state.audioHeadings[key]}
            {parseInt(key) === 2 ? (
              ""
            ) : (
                <>
                  <i
                    className={
                      this.props.sortColNumber === parseInt(key)
                        ? this.props.showUpIcon
                          ? "fas fa-sort-up sort-up"
                          : "fas fa-sort-up hide"
                        : "fas fa-sort-up sort-up"
                    }
                    onClick={() =>
                      this.props.sortingHandler(parseInt(key), "desc")
                    }
                  />
                  <i
                    className={
                      this.props.sortColNumber === parseInt(key)
                        ? this.props.showDownIcon
                          ? "fas fa-sort-down sort-down"
                          : "fas fa-sort-down hide"
                        : "fas fa-sort-down sort-down"
                    }
                    onClick={() =>
                      this.props.sortingHandler(parseInt(key), "asc")
                    }
                  />
                </>
              )}
          </th>
        ));
        break;
      case "Print":
        tableHeadings = Object.keys(this.state.printHeadings).map(key => (
          <th
            key={key}
            onClick={() =>
              this.props.sortingHandler(
                parseInt(key),
                this.props.sortColNumber === parseInt(key)
                  ? this.props.showUpIcon
                    ? "desc"
                    : "asc"
                  : "asc"
              )
            }
            className="cursor"
            style={{ width: 120 }}
          >
            {this.state.printHeadings[key]}
            <>
              <i
                className={
                  this.props.sortColNumber === parseInt(key)
                    ? this.props.showUpIcon
                      ? "fas fa-sort-up sort-up"
                      : "fas fa-sort-up hide"
                    : "fas fa-sort-up sort-up"
                }
                onClick={() => this.props.sortingHandler(parseInt(key), "desc")}
              />
              <i
                className={
                  this.props.sortColNumber === parseInt(key)
                    ? this.props.showDownIcon
                      ? "fas fa-sort-down sort-down"
                      : "fas fa-sort-down hide"
                    : "fas fa-sort-down sort-down"
                }
                onClick={() => this.props.sortingHandler(parseInt(key), "asc")}
              />
            </>
          </th>
        ));
        break;
      default:
        tableHeadings = null;
    }
    return (
      <div className="library-tab-content">
        <div className="table-responsive">
          <table
            className="table table-bordered tab-content-table"
            style={{ tableLayout: "fixed" }}
          >
            <thead className="text-muted border-top">
              <tr>
                <th
                  onClick={() =>
                    this.props.sortingHandler(
                      0,
                      this.props.sortColNumber === 0
                        ? this.props.showUpIcon
                          ? "desc"
                          : "asc"
                        : "asc"
                    )
                  }
                  className="cursor"
                  style={{ width: 100 }}
                >
                  Call #
                <>
                    <i
                      className={
                        this.props.sortColNumber === 0
                          ? this.props.showUpIcon
                            ? "fas fa-sort-up sort-up"
                            : "fas fa-sort-up hide"
                          : "fas fa-sort-up sort-up"
                      }
                      onClick={() => this.props.sortingHandler(0, "desc")}
                    />
                    <i
                      className={
                        this.props.sortColNumber === 0
                          ? this.props.showDownIcon
                            ? "fas fa-sort-down sort-down"
                            : "fas fa-sort-down hide"
                          : "fas fa-sort-down sort-down"
                      }
                      onClick={() => this.props.sortingHandler(0, "asc")}
                    />
                  </>
                </th>
                <th
                  onClick={() =>
                    this.props.sortingHandler(
                      1,
                      this.props.sortColNumber === 1
                        ? this.props.showUpIcon
                          ? "desc"
                          : "asc"
                        : "asc"
                    )
                  }
                  className="cursor"
                  style={{ width: 150 }}
                >
                  Title
                <i
                    className={
                      this.props.sortColNumber === 1
                        ? this.props.showUpIcon
                          ? "fas fa-sort-up sort-up"
                          : "fas fa-sort-up hide"
                        : "fas fa-sort-up sort-up"
                    }
                    onClick={() => this.props.sortingHandler(1, "desc")}
                  />
                  <i
                    className={
                      this.props.sortColNumber === 1
                        ? this.props.showDownIcon
                          ? "fas fa-sort-down sort-down"
                          : "fas fa-sort-down hide"
                        : "fas fa-sort-down sort-down"
                    }
                    onClick={() => this.props.sortingHandler(1, "asc")}
                  />
                </th>
                {isMobile ? "" : tableHeadings}
                <th style={{ width: 100 }}>Available?</th>
                <th style={{ width: 60 }}>Edit</th>
              </tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TabContent;
