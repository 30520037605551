import React, { useState, useEffect } from 'react'
import Input from "../../../UI/input/Input";

export default function DropdownQuestion({ question, response, setResponseData, completed }) {

  const getQuestionOption = () => {
    if (response && response.question_option_id) {
      let optionDetails = question.question_options.find(option => option.id === response.question_option_id)
      return { label: optionDetails.option_text, value: optionDetails.id }
    } else {
      return null
    }
  }

  const [selectedOption, setSelectedOption] = useState(getQuestionOption())

  const dropdownOptions = question.question_options.map((option) => ({ label: option.option_text, value: option.id }))

  useEffect(() => {
    selectedOption && setResponseData(question.id, {question_option_id: selectedOption.value})
  }, [selectedOption])

  const clearValidationError = () => {
    const element = document.getElementById("dropdown-question")
    element.validity.customError && !element.validity.valueMissing && element.setCustomValidity('')
  }

  useEffect(() => {
    selectedOption && clearValidationError()
  }, [selectedOption])

  return (
    <div className="mb-4">
      <p className="mb-2 font-weight-bold">{question.question_text}</p>
      <div className="mx-3">
        <Input
          id="dropdown-question"
          name={question.id}
          elementType="select"
          selectedOption={selectedOption}
          elementConfig={{
            placeholder: "Select...",
            options: dropdownOptions,
            required: question.mandatory,
            onInvalid: e => e.target.setCustomValidity("Please select an option")
          }}
          changed={option => setSelectedOption(option || null)}
          customStyle={{
            container: provided => ({
              ...provided,
              width: "100%"
            })
          }}
          disabled={completed}
        />
      </div>
    </div>
  )
}
