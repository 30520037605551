import React, { useState, useEffect } from "react"
import axios from "axios";
import GrantApplicationItem from "./grantApplicationItem";
import langUtil from "../../../utils/langUtils";
import Button from "../../UI/button/Button";

const GrantApplication = ({ user }) => {
  const [grantApplications, setGrantApplications] = useState([])
  const [isSameUser, setIsSameUser] = useState(false)

  useEffect(() => {
    axios.get(`/grant_applications/for_user/${user.id}`)
      .then(res => { setGrantApplications(res.data.json.data) })
      .catch(err => console.log(err))
    axios.get(`/users/${user.id}/is_current`)
      .then(res => { setIsSameUser(res.data) })
      .catch(err => console.log(err))
  }, [])

  return <>
    <div className="row bg-light">
      <div className="col-6">
        <div className="pl-3 py-2">
          <h5 className="text-dark font-weight-bold m-0">
            {langUtil("applications", "Applications")}
          </h5>
        </div>
      </div>
      <div className="col-6 text-right">
        <div>
          {isSameUser && (
            <Button
              type="primary"
              clicked={() => window.location.href='/grant_applications'}
              className="m-1"
            >
              Manage My Applications
            </Button>
          )}
        </div>
      </div>
    </div>
    {grantApplications.length > 0 ?
      <div className="w-100 pb-3 px-4">
        {grantApplications.map((grantApplication,index) => {
          return <GrantApplicationItem key={index} grantApplication={grantApplication} />
        })}
      </div>
      :
      <div className="d-flex justify-content-center align-items-center list-container">
        <h4 className="text-dark">No {langUtil("applications", "Applications")}</h4>
      </div>
    }
  </>
}

export default GrantApplication
