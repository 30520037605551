import React, { useEffect } from "react"
import axios from "../../../configAxios"
import { toast } from "react-toastify"
import tagName from "../../shared/tagName"

const LabelList = ({ tags, setTags, setCategoryId, programId }) => {

  useEffect(() => {
    if (programId) {
      axios.get("/tags", {
        params: { program_id: programId }
      })
        .then(res => {
          // 2023-05-8: endpoint should return array of individual tags instead of an array of one item containing the tags string, split(", ") is sketchy because tags could also contain commas
          const formattedTags = res.data.tags[0].split(",").map(tag => {
            return { value: tag, label: tagName(tag) }
          })
          setTags(formattedTags)
        })
    }
  }, [programId])

  const deleteTag = (tag) => {
    const response = window.confirm("Are you sure you want to delete the label from this program?")
    if (response) {
      axios.delete("/tags", {
        params: { query: tag.value, program_id: programId }
      })
        .then((res) => {
          if (res.data.json.success) {
            setTags(prevState => prevState.filter(existingTag => existingTag.value !== tag.value))
            toast.success("Successfully deleted.")
            setCategoryId(null)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <div className='row mt-2 pl-3'>
      {tags.map((tag, index) => {
        return <>
          <div className="tag word-wrap-break" key={index}>
            <div className="tag-name">{tag.label}</div>
            <button
              className="tag-delete"
              onClick={() => deleteTag(tag)}
            >
              x
            </button>
          </div>
        </>
      })}
    </div>
  )
}

export default LabelList
