import React, { Component } from "react";
import PropTypes from "prop-types";

import "./index.css";

class Details extends Component {
  render() {
    const { name, description } = this.props;

    return (
      <div className="row m-2">
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <h6 className="font-weight-bold text-muted">Name:</h6>
              <p className="text-dark">{name}</p>
            </div>
            <div className="col-12">
              <h6 className="font-weight-bold text-muted">Description:</h6>
              <p className="text-dark">{description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Details.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default Details;
