import React, { Component } from "react";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import axios from "../../../configAxios";
import { toast } from "react-toastify";
import Input from "../../UI/input/Input";
import { title } from "change-case";
import ModalSpinner from "../../UI/ModalSpinner";

class NewFlagType extends Component {
  signal = axios.CancelToken.source();
  state = {
    categoryNames: [],
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true
      },
      label: "Name",
      value: ""
    },
    visible: {
      elementType: "input",
      elementConfig: {
        name: "visible",
        type: "checkbox",
        checked: null
      },
      label: "Visible",
      value: ""
    },
    isChecked: false,
    loading: false,
    disabled: false
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setState({ ...this.state, loading: true })
      axios
        .get("/flag_types/" + this.props.id, { cancelToken: this.signal.token })
        .then(res => {
          this.setState({
            name: {
              ...this.state.name,
              value: res.data.flag_type.name,
              isChecked: res.data.flag_type.visible
            },
            visible: {
              ...this.state.visible,
              elementConfig: {
                ...this.state.visible.elementConfig,
                checked: res.data.flag_type.visible
              }},
            loading: false
          });
        });
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  closeModal = () => {
    this.props.closeModal();
  };
  
  toggleCheckbox = (event, key) => {
    this.setState({
      [key]: {
        ...this.state[key],
        elementConfig: {
          ...this.state[key].elementConfig,
          checked: !this.state[key].elementConfig.checked
        }
      }
    });
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    if (key === "name") {
      inputValue["value"] = event.target.value;
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({disabled: true})
    let flagData = {};
    const { name, visible } = this.state;
    flagData["name"] = name.value;
    flagData["visible"] = visible.elementConfig.checked;
    if (this.props.edit) {
      axios.put("/flag_types/" + this.props.id, flagData).then(res => {
        if (res.data.json.success) {
          toast.success("Flag type successfully updated");
          this.props.closeModal();
          this.props.getFlagTypes();
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
        this.setState({disabled: false})
      });
    } else {
      axios
        .post("/flag_types", flagData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Successfully created");
            this.props.closeModal();
            this.props.getFlagTypes();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({disabled: false})
        })
        .catch(err => toast.error(err));
    }
  };

  render() {
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        {
          this.state.loading ? <ModalSpinner /> : (
            <React.Fragment>
              <Input
                {...this.state.name}
                changed={event => this.inputChangedHandler(event, "name")}
              />
              <Input
                {...this.state.visible}
                changed={event => this.toggleCheckbox(event, "visible")}
              />
              <div className="d-flex justify-content-center">
                <Button type="secondary" className="mr-2" clicked={this.props.closeModal}>
                  Cancel
                </Button>
                <FormButton disabled={this.state.disabled} >
                  {this.props.edit ? "Edit Flag Type" : "Create Flag Type"}
                </FormButton>
              </div>
            </React.Fragment>
          )
        }
      </form>
    );
  }
}

export default NewFlagType;
