import React, { Component } from 'react'
import { toast } from "react-toastify"
import { title } from "change-case"

import axios from '../../../../configAxios'
import Button from '../../../UI/button/Button'
import Modal from "../../../UI/Modal";
import Spinner from "../../../UI/Spinner";
import Pagination from "../../../UI/Pagination";
import Input from "../../../UI/input/Input";
import SearchFilter from "../../../common/searchFilter/SearchFilter";
import langUtils from '../../../../utils/langUtils';
import { responseErrorHandler } from '../../../../utils/errorHandler'

import AddAndEditAssociationsAndEducationForm from '../AddAndEditAssociationsAndEducationForm';
import MergeAssociationsAndEducationForm from '../MergeAssociationsAndEducationForm';

import "../AssociationsAndEducationStyles.css"

class AssociationsAndEducation extends Component {
  constructor() {
    super();
    this.signal = axios.CancelToken.source();
    this.timeout = 0;
    this.state = {
      associationsAndEducations: [],
      totalCount: 0,
      selectedPage: 1,
      forcePage: 0,
      showModal: false,
      edit: false,
      loading: true,
      associationsAndEducationId: null,
      search: {
        elementType: "input",
        elementConfig: {
          type: "text",
          name: "search",
          placeholder: "Search Associations and Education"
        },
        value: ""
      },
      perPage: 10
    }
    this.textInput = React.createRef();
  }

  componentDidMount() {
    this.getAssociationsAndEducations();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getAssociationsAndEducations = () => {
    axios.get('/education_and_associations', {
      params: {
        page: this.state.selectedPage,
        query: this.state.search.value,
        per_page: this.state.perPage
      },
      cancelToken: this.signal.token
    })
      .then(res => {
        this.setState({
          ...this.state,
          associationsAndEducations: res.data.education_and_associations,
          totalCount: res.data.meta.total,
          loading: false
        })
      })
  }

  deleteAssociationsAndEducation = associationsAndEducationId => {
    let response = window.confirm("Are you sure you want to delete the Association and Education?");
    if (response) {
      axios.delete(`/education_and_associations/${associationsAndEducationId}`)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Association/Education is being deleted in the background.");
            setTimeout(() => window.location.reload(), 3000);
          } else {
            responseErrorHandler(res);
          }
        })
    }
  }

  paginationHandler = page => {
    window.scrollTo(0, 0)
    this.setState({
      selectedPage: page.selected + 1,
      forcePage: page.selected,
      loading: true
    },
      () => this.getAssociationsAndEducations()
    );
  };

  inputChangedHandler = event => {
    this.setState({
      search: {
        ...this.state.search,
        value: event.target.value
      },
      loading: true,
      selectedPage: 1,
      forcePage: 0
    })
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getAssociationsAndEducations();
    }, 300);
  }

  clearQueryString = () => {
    this.setState({
      search: {
        ...this.state.search,
        value: ""
      },
      forcePage: 0,
      selectedPage: 1,
      loading: true
    },
      () => this.getAssociationsAndEducations()
    );
    this.textInput.current.focus();
  }

  perPageChangeHandler = perPage => {
    this.setState({
      perPage,
      loading: true,
      selectedPage: 1,
      forcePage: 0
    }, () => this.getAssociationsAndEducations());
  }

  render() {
    const { forcePage, totalCount, perPage } = this.state;
    let removeIcon;
    if (this.state.search.value !== "") {
      removeIcon = (
        <i
          className="fas fa-times float-right position-relative b-40 r-15 cursor"
          onClick={() => this.clearQueryString()}
        />
      );
    }

    let associationsAndEducationList;
    if (this.state.loading) {
      associationsAndEducationList = <Spinner />
    } else {
      if (this.state.associationsAndEducations.length === 0) {
        associationsAndEducationList = <div className="row">
          <div className="col-12">
            <div className="text-center p-5 m-5">
              <h5>No Associations and Education</h5>
            </div>
          </div>
        </div>
      } else {
        associationsAndEducationList = this.state.associationsAndEducations.map(associationsAndEducation => (
          <div className="list-group-item" key={associationsAndEducation.id}>
            <div className="row">
              <div className="col-6">{associationsAndEducation.name}</div>
              <div className="col-3 text-center">{associationsAndEducation.count}</div>
              <div className="col-3 d-flex justify-content-around align-items-center">
                <button
                  className="btn btn-sm btn-outline-secondary"
                  title="Edit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    this.setState({ ...this.state, showMergeModal: true, associationsAndEducationId: associationsAndEducation.id })
                  }
                  }
                >
                  Merge
                </button>
                <i
                  className="fas fa-pencil-alt cursor"
                  title="Edit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    this.setState({ ...this.state, showModal: true, edit: true, associationsAndEducationId: associationsAndEducation.id })
                  }
                  }
                />
                <i
                  className="fas fa-trash cursor"
                  style={{ cursor: associationsAndEducation.can_destroy ? "pointer" : "no-drop", opacity: associationsAndEducation.can_destroy ? 1 : 0.5 }}
                  title="Delete"
                  onClick={() => associationsAndEducation.can_destroy && this.deleteAssociationsAndEducation(associationsAndEducation.id)}
                />
              </div>
            </div>
          </div>
        ))
      }
    }

    let totalItems =
      this.state.totalCount < this.state.perPage
        ? this.state.totalCount
        : this.state.perPage;
    let to =
      this.state.totalCount < this.state.selectedPage * totalItems
        ? this.state.totalCount
        : this.state.selectedPage * totalItems;

    return (
      <div className="tag-manager associations-and-education-manager">
        <div className="p-4">
          <div className="d-flex">
            <div className="flex-fill pr-2">
              <Input {...this.state.search} forwardRef={this.textInput} changed={this.inputChangedHandler} />
              {removeIcon}
            </div>
            <div className="pl-2">
              <Button
                type="primary"
                clicked={() => {
                  window.scrollTo(0, 0);
                  this.setState({ ...this.state, showModal: true, edit: false })
                }
                }
              >
                {langUtils("txt_global_add_new", "Add New")}
                </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="offset-md-6 col-md-6">
            <SearchFilter
              perPage={perPage}
              inputChangeHandler={this.perPageChangeHandler}
            />
          </div>
        </div>

        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="row">
              <div className="col-6">
                <span className="font-weight-bold">Name</span>
              </div>
              <div className="col-3 text-center">
                <span className="font-weight-bold">Count</span>
              </div>
            </div>
          </div>
          {associationsAndEducationList}
        </div>

        <div className="d-flex">
          <div className="flex-fill d-flex justify-content-start align-items-center ml-2 pt-2">
            {`Showing ${(this.state.selectedPage - 1) *
              this.state.perPage +
              1} to ${to} of ${this.state.totalCount}`}
          </div>
          <div>
            <Pagination
              forcePage={forcePage}
              pageCount={totalCount / perPage}
              handlePageClick={this.paginationHandler} />
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          title={this.state.edit ? "Update Associations and Education" : "New Associations and Education"}
          hide={() => this.setState({ showModal: false })}
        >
          <AddAndEditAssociationsAndEducationForm
            associationsAndEducationId={this.state.associationsAndEducationId}
            edit={this.state.edit}
            closeModal={() => this.setState({ showModal: false })}
            getAssociations={this.getAssociationsAndEducations}
          />
        </Modal>
        <Modal
          show={this.state.showMergeModal}
          title="Merge Associations and Education"
          hide={() => this.setState({ showMergeModal: false })}
        >
          <MergeAssociationsAndEducationForm
            associationsAndEducationId={this.state.associationsAndEducationId}
            edit={this.state.edit}
            closeModal={() => this.setState({ showMergeModal: false })}
            getAssociations={this.getAssociationsAndEducations}
          />
        </Modal>
      </div>
    )
  }
}

export default AssociationsAndEducation;
