import React, { Component } from "react";
import Modal from "../../UI/Modal";
import Update from "./Update";
import Button from "../../UI/button/Button";
import "./index.css";
import "./EditGrantCampaign.css";

class EditGrantCampaign extends Component {
  timeout = 0;
  state = {
    showUpdateModal: false,
  };

  campaignUpdateHandler = () => {
    window.scrollTo(0, 0);
    this.setState({ showUpdateModal: true });
  };

  render() {
    const { grantCampaign } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="buttons">
              <Button
                type="warning"
                style={{ width: "100%" }}
                clicked={this.campaignUpdateHandler}
              >
                Edit Campaign
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title="Update Campaign"
          show={this.state.showUpdateModal}
          hide={() => this.setState({ showUpdateModal: false })}
          style={{ top: "15%" }}
        >
          <Update
            {...grantCampaign}
            closeModal={() => {
              this.props.refreshCampaignData()
              this.setState({ showUpdateModal: false })
            }}
          />
        </Modal>
      </div>  
    )
  }
}

export default EditGrantCampaign;
