import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import dateFns from "date-fns";
import queryString from "query-string";
import { toast } from "react-toastify";
import { title } from "change-case";
import { isEmpty, isNull, isArray } from "lodash"
import { v4 as uuidv4 } from "uuid";

import DatePicker from "../../UI/datePicker";
import Input from "../../UI/input/Input";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import axios from "../../../configAxios";

import PersonalInfo from "../detail/PersonalInfo";
import RegistrationTable from "../detail/RegistrationTable";
import BookingsTable from "../detail/BookingsTable";
import InvoiceTable from "../detail/InvoiceTable";

import InvoiceItem from "./InvoiceItemRow";
import "./index.css";
import { displayTax } from "../helpers";

class InvoiceItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      contactInfo: "",
      invoiceDate: new Date(),
      invoiceNotes: "",
      invoiceItemsData: [],
      insurancePremium: "",
      serviceInKind: "",
      subtotal: 0.0,
      total: 0.0,
      creditPaymentAmount: "",
      accountingCodes: this.props.accountingCodes,
      chargeTax: false,
      invoiceType: this.props.invoiceType,
      isGstUsed: null,
      isPstUsed: null,
      isHstUsed: null,
      selectedInvoices: [],
      selectedInvoiceIdToEdit: [],
      selectedInvoicesUpdate: [],
      showFavaBucksPayment: this.props.invoiceType !== "Floating" ? true : false,
      disabled: false,
      externalTrackingNumber: null
    };
  }

  componentDidMount() {
    const { organization } = this.props;
    if (this.props.editInvoice) {
      const { invoiceData } = this.props;
      this.setState({
        invoiceNotes: invoiceData.notes ? invoiceData.notes : "",
        invoiceDate: new Date(invoiceData.invoice_date),
        chargeTax: invoiceData.charge_tax,
        subtotal:
          invoiceData.subtotal_due && invoiceData.subtotal_due.replace("$", ""),
        insurancePremium:
          invoiceData.insurance_premium &&
          this.convertToFloat(invoiceData.insurance_premium),
        serviceInKind:
          invoiceData.credit_amount &&
          this.convertToFloat(invoiceData.credit_amount),
        creditPaymentAmount:
          invoiceData.credit_payment_amount &&
          this.convertToFloat(invoiceData.credit_payment_amount),
        total: invoiceData.total && invoiceData.total.replace("$", ""),
        isGstUsed: invoiceData.is_gst_used,
        isPstUsed: invoiceData.is_pst_used,
        isHstUsed: invoiceData.is_hst_used,
        showFavaBucksPayment: invoiceData.account_id ? true : false,
        externalTrackingNumber: invoiceData.external_tracking_number
      });
    } else {
      this.setState({
        isGstUsed: true,
        isPstUsed: organization.is_pst_used,
        isHstUsed: organization.is_hst_used,
      });
    }
  }

  convertToFloat = amount => {
    if (amount) {
      amount = amount.replace("$", "").replace(",", "");
      amount = parseFloat(amount);
      amount = amount.toFixed(2);
    }
    return amount;
  };

  renderPriceInput = key => {
    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">$</span>
        </div>
        <input
          className="form-control"
          type="number"
          step="0.01"
          value={this.state[key]}
          onChange={e =>
            this.setState({
              [key]: e.target.value
            })
          }
        />
      </div>
    );
  };

  addRowHandler = () => {
    const { invoiceItemsData } = this.state;
    const newRow = {
      key: uuidv4(),
      accountingCode: null,
      description: null,
      amount: 0.0,
      invalidContent: false,
    };
    this.setState({
      invoiceItemsData: [...invoiceItemsData, newRow]
    }, this.validateRow);
  };

  updateRowHandler = (index, data) => {
    let { invoiceItemsData, subtotal } = this.state;
    invoiceItemsData[index] = data;
    this.setState({
      invoiceItemsData,
      subtotal
    });
  };

  deleteRowHandler = index => {
    let { invoiceItemsData } = this.state;
    invoiceItemsData.splice(index, 1);
    this.setState({ invoiceItemsData });
  };

  validateRow = (submitting) => {
    const { invoiceItemsData } = this.state;
    const lastIndex = invoiceItemsData.length - 1;
    const newInvoiceItemsData = invoiceItemsData.map(
      (data, index) => {
        const {accountingCode } = data;
        if (
          (accountingCode && accountingCode.selectedOption) ||
          (!submitting && index === lastIndex)
        )
          return { ...data, invalidContent: false };
        return { ...data, invalidContent: true };
      }
    );
    this.setState({
      invoiceItemsData: newInvoiceItemsData
    });
  }

  updateRowValidation = (rowIndex, invalidContent) => {
    const { invoiceItemsData } = this.state;
    const newInvoiceItemsData = invoiceItemsData.map(
      (data, index) => {
        if (index === rowIndex)
          return { ...data, invalidContent };
        return { ...data };
      }
    );
    this.setState({
      invoiceItemsData: newInvoiceItemsData
    })
  };

  createInvoiceHandler = e => {
    e.preventDefault();
    this.setState({ disabled: true })
    const { invoiceDate } = this.state;
    const { organization } = this.props;
    const invoiceData = new FormData();
    invoiceData.append("type", this.state.invoiceType);
    this.state.firstName !== "" &&
      invoiceData.append("floating_first_name", this.state.firstName);
    this.state.lastName !== "" &&
      invoiceData.append("floating_last_name", this.state.lastName);
    this.state.contactInfo !== "" &&
      invoiceData.append("floating_contact_info", this.state.contactInfo);
    this.state.invoiceNotes !== "" &&
      invoiceData.append("notes", this.state.invoiceNotes);

    this.state.insurancePremium !== "" &&
      invoiceData.append("insurance_premium", this.state.insurancePremium);
    this.state.serviceInKind !== "" &&
      invoiceData.append("credit_amount", this.state.serviceInKind);

    organization.is_gst_used &&
      invoiceData.append("is_gst_used", this.state.isGstUsed);
    organization.is_pst_used &&
      invoiceData.append("is_pst_used", this.state.isPstUsed);
    organization.is_hst_used &&
      invoiceData.append("is_hst_used", this.state.isHstUsed);

    invoiceData.append("invoice_date[day]", dateFns.getDate(invoiceDate));
    invoiceData.append(
      "invoice_date[month]",
      dateFns.getMonth(invoiceDate) + 1
    );
    invoiceData.append("invoice_date[year]", dateFns.getYear(invoiceDate));

    if (this.state.invoiceType !== "Floating") {
      !this.props.editInvoice &&
        invoiceData.append(
          "account_id",
          queryString.parse(this.props.location.search)["accountId"]
        );
      this.state.creditPaymentAmount !== "" &&
        invoiceData.append(
          "credit_payment_amount",
          this.state.creditPaymentAmount
        );
    }

    let [invoiceItems, missingInvoiceItem] = this.getInvoiceItems();
    if (invoiceItems === null) {
      this.missingAccountingCodeWarning(missingInvoiceItem);
    } else {
      invoiceItems &&
        invoiceData.append("invoice_items", `${JSON.stringify(invoiceItems)}`);

      axios.post("/invoices", invoiceData).then(res => {
        if (res.data.json.success) {
          toast.success("Invoice created successfully.");
          this.props.history.push(`/invoices/${res.data.json.data.id}`);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    }
  };

  getInvoiceItems = () => {
    let invoiceItems;
    const { invoiceItemsData } = this.state;
    let missingAccCode = false;
    let missingItem = false;
    if (invoiceItemsData.length) {
      invoiceItems = invoiceItemsData.map(data => {
        if (data.accountingCode === null || isEmpty(data.accountingCode.selectedOption)) {
          missingAccCode = true;
          if (
            !data.description &&
            (parseFloat(data.amount) === 0)
          ) {
            missingItem = true;
          }
        }
        return {
          accounting_code_id: data.accountingCode !== null && !isEmpty(data.accountingCode.selectedOption)
            ? data.accountingCode.selectedOption.value
            : null,
          description: data.description,
          amount: data.amount
        };
      });
    }
    if (missingAccCode) {
      return [null, missingItem];
    } else {
      return [invoiceItems, missingItem];
    }
  };

  getUpdatedInvoiceItems = () => {
    let invoiceItems;
    let check = false;
    const { selectedInvoicesUpdate } = this.state;
    invoiceItems = selectedInvoicesUpdate.map(invoice => {
      if (isNull(invoice.accounting_code_id)) {
        check = true;
      }
      if (isNull(invoice.accounting_code)) {
        return { ...invoice, accounting_code: null }
      }
      return invoice;
    }).filter(invoice => this.state.selectedInvoices.every(id => id !== invoice.id));
    if (check) {
      return null
    }
    return invoiceItems;
  }

  updateInvoiceHandler = e => {
    e.preventDefault();
    this.setState({ disabled: true })
    const { invoiceData: invoice } = this.props;
    const invoiceData = new FormData();
    const { invoiceDate } = this.state;
    const paid = parseInt(this.props.invoiceData.total.replace("$", ""));
    invoiceData.append("status", paid === 0 ? 1 : 0);
    invoiceData.append("invoice_date", dateFns.format(invoiceDate, "YYYY-M-D"));

    invoice.charge_tax !== this.state.chargeTax &&
      invoiceData.append("charge_tax", this.state.chargeTax);

    invoiceData.append("notes", this.state.invoiceNotes);
    invoiceData.append("external_tracking_number", this.state.externalTrackingNumber);

    this.state.insurancePremium !==
      this.convertToFloat(invoice.insurance_premium) &&
      invoiceData.append("insurance_premium", this.state.insurancePremium);

    this.state.serviceInKind !== this.convertToFloat(invoice.credit_amount) &&
      invoiceData.append("credit_amount", this.state.serviceInKind);

    this.state.creditPaymentAmount !==
      this.convertToFloat(invoice.credit_payment_amount) &&
      invoiceData.append(
        "credit_payment_amount",
        this.state.creditPaymentAmount
      );

    invoice.organization.is_gst_used &&
      invoiceData.append("is_gst_used", this.state.isGstUsed);

    invoice.organization.is_pst_used &&
      invoiceData.append("is_pst_used", this.state.isPstUsed);

    invoice.organization.is_hst_used &&
      invoiceData.append("is_hst_used", this.state.isHstUsed);

    let [invoiceItems, missingInvoiceItem] = this.getInvoiceItems();
    const updatedItems = this.getUpdatedInvoiceItems();
    if (!isEmpty(this.state.selectedInvoiceIdToEdit) && isNull(updatedItems)) {
      toast.error("Edited invoice item does not exist");
      this.setState({ disabled: false });
      return;
    }

    if (invoiceItems === null) {
      this.missingAccountingCodeWarning(missingInvoiceItem);
    } else {
      const setInvoiceItems = [...(isArray(invoiceItems) ? invoiceItems : []), ...(isArray(updatedItems) ? updatedItems : [])];
      setInvoiceItems &&
        invoiceData.append("invoice_items", `${JSON.stringify(setInvoiceItems)}`);

      this.state.selectedInvoices.length &&
        invoiceData.append(
          "delete_invoice_items",
          JSON.stringify(this.state.selectedInvoices)
        );

      axios.put(`/invoices/${invoice.id}`, invoiceData).then(res => {
        if (res.data.json.success) {
          toast.success("Invoice updated successfully.");
          this.props.history.push(`/invoices/${invoice.id}`);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    }
  };

  missingAccountingCodeWarning = (missingInvoiceItem) => {
    const errorMssg =
      missingInvoiceItem
        ? "Please add an invoice item"
        : "Missing Item Accounting Codes";
    toast.error(errorMssg);
    this.validateRow(true);
    this.setState({ disabled: false })
  }

  renderUserInfo = () => {
    const { user } = this.props;
    return (
      <div>
        <h5 className="text-primary font-weight-bold">
          {`${user.first_name ? user.first_name : ""} ${
            user.last_name ? user.last_name : ""
            }`}
        </h5>
        <div className="text-dark text-justify mt-4">{user.address1}</div>
        <div className="text-dark text-justify">{user.address2}</div>
        <div className="mt-4">
          <span className="text-muted">Home: </span>
          <span className="text-dark">
            {user.home_phone ? user.home_phone : "(none)"}
          </span>
        </div>
        <div className="mt-1">
          <span className="text-muted">Mobile: </span>
          <span className="text-dark">
            {user.mobile_phone ? user.mobile_phone : "(none)"}
          </span>
        </div>
        <div className="mt-1 mb-3">
          <span className="text-muted">Email: </span>
          <span className="text-dark">
            {user.email ? user.email : "(none)"}
          </span>
        </div>
      </div>
    );
  };

  selectedInvoiceHandler = id => {
    let { selectedInvoices } = this.state;
    if (selectedInvoices.includes(id)) {
      let index = selectedInvoices.indexOf(id);
      selectedInvoices.splice(index, 1);
    } else {
      selectedInvoices = [...selectedInvoices, id];
    }
    this.setState({ selectedInvoices });
  };

  selectedToEditHandler = (id, itemData) => {
    const { description, codeId: accounting_code_id, amount } = itemData;
    const { selectedInvoiceIdToEdit, selectedInvoicesUpdate } = this.state;
    if (!selectedInvoiceIdToEdit.includes(id)) {
      let invoicePrice = '0.00';
      if (typeof amount === "string") {
        invoicePrice = amount.includes('$') ? amount.substr(1) : amount;
      }
      this.setState({
        selectedInvoiceIdToEdit: [...selectedInvoiceIdToEdit, id],
        selectedInvoicesUpdate: [
          ...selectedInvoicesUpdate,
          {
            description,
            accounting_code_id,
            amount: invoicePrice,
            id
          }
        ]
      })
    }
  }

  editInvoiceInfoHandler = (id, detail, attribute) => {
    const { selectedInvoicesUpdate: invoices } = this.state;
    const newInvoiceList = invoices.map(invoice => {
      if (invoice.id === id) {
        let newInvoice = invoice;
        newInvoice[attribute] = detail
        return newInvoice;
      }
      return invoice
    });
    this.setState({ selectedInvoicesUpdate: newInvoiceList });
  }

  renderTax = (frenchLabel, label, key, stateKey, amount) => {
    const { organization, editInvoice, invoiceData } = this.props;
    let invoiceRate = "0.0";
    if (editInvoice) {
      if (
        invoiceData.hasOwnProperty(`frozen_${key}`) &&
        invoiceData[`frozen_${key}`]
      ) {
        invoiceRate = invoiceData[`frozen_${key}`];
      } else if (invoiceData.organization && invoiceData.organization[key]) {
        invoiceRate = invoiceData.organization[key];
      }
    } else {
      if (organization && organization[key]) {
        invoiceRate = organization[key];
      }
    }

    return (
      <div className="d-flex justify-content-between pr-5">
        <div className="text-right total-text ml-4 row">
          <div>
            {`${frenchLabel} / ${label} (${displayTax(invoiceRate)})`}
          </div>
          <div className="ml-2">
            <Input
              elementType={"input"}
              elementConfig={{
                type: "checkbox",
                checked: this.state[stateKey]
              }}
              label={"Use"}
              changed={e =>
                this.setState({
                  [stateKey]: !this.state[stateKey]
                })
              }
            />
          </div>
        </div>
        <div className="text-right total-text mr-4">{`$ ${
          editInvoice && this.state[stateKey]
            ? parseFloat(invoiceData[amount]).toFixed(2)
            : "0.00"
          }`}</div>
      </div>
    );
  };

  render() {
    const { invoiceItemsData, invoiceType } = this.state;
    const { invoiceData, editInvoice, accountData, organization } = this.props;

    return (
      <div>
        <form
          onSubmit={e =>
            editInvoice
              ? this.updateInvoiceHandler(e)
              : this.createInvoiceHandler(e)
          }
        >
          <div className="row">
            <div className="col-lg-6">
              {editInvoice ? (
                <PersonalInfo invoice={invoiceData} />
              ) : (
                  <div>
                    {this.state.invoiceType !== "Floating" && !editInvoice ? (
                      <div>{this.renderUserInfo()}</div>
                    ) : (
                        <div>
                          {!editInvoice && (
                            <div className="mt-3">
                              <p>Invoice Date</p>
                              <div className="ml-3">
                                <DatePicker
                                  selected={this.state.invoiceDate}
                                  changed={invoiceDate =>
                                    this.setState({ invoiceDate })
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className="mt-3">
                            <p>First Name</p>
                            <Input
                              elementType={"input"}
                              elementConfig={{ required: true }}
                              value={this.state.firstName}
                              changed={e =>
                                this.setState({ firstName: e.target.value })
                              }
                            />
                          </div>
                          <div className="mt-3">
                            <p>Last Name</p>
                            <Input
                              elementType={"input"}
                              elementConfig={{ required: true }}
                              value={this.state.lastName}
                              changed={e =>
                                this.setState({ lastName: e.target.value })
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>
                )}
            </div>
            <div className="col-lg-6">
              {!editInvoice && invoiceType !== "General" && (
                <div className="mt-3">
                  <p>Contact Info</p>
                  <Input
                    elementType={"input"}
                    elementConfig={{ required: true }}
                    value={this.state.contactInfo}
                    changed={e => this.setState({ contactInfo: e.target.value })}
                  />
                </div>
              )}
              {editInvoice && (
                <div className="mt-3">
                  <p>Invoice Date</p>
                  <div className="ml-3">
                    <DatePicker
                      selected={this.state.invoiceDate}
                      changed={invoiceDate => this.setState({ invoiceDate })}
                    />
                  </div>
                </div>
              )}
              {editInvoice && (
                <div className="mt-3">
                  <Input
                    elementType={"input"}
                    elementConfig={{
                      type: "checkbox",
                      checked: this.state.chargeTax
                    }}
                    label={"Charge Tax?"}
                    changed={e =>
                      this.setState({ chargeTax: !this.state.chargeTax })
                    }
                  />
                </div>
              )}
              <div className="mt-3">
                <p>Invoice Notes</p>
                <Input
                  elementType="textarea"
                  value={this.state.invoiceNotes}
                  changed={e => this.setState({ invoiceNotes: e.target.value })}
                />
              </div>
              <div className="mt-3">
                <p>External Tracking Number</p>
                <Input
                  elementType={"input"}
                  changed={e => this.setState({ externalTrackingNumber: e.target.value })}
                  value={this.state.externalTrackingNumber}
                />
              </div>
            </div>
          </div>
          <div>
            {editInvoice && (
              <div>
                {invoiceData.registration && (
                  <div className="mt-2">
                    <RegistrationTable
                      registration={invoiceData.registration}
                    />
                  </div>
                )}
                {invoiceData.bookings && (
                  <div className="mt-2">
                    <BookingsTable
                      bookings={invoiceData.bookings}
                      organization={invoiceData.organization}
                    />
                  </div>
                )}
              </div>
            )}
            <Button
              type="success"
              style={{ marginTop: "35px", marginBottom: "35px" }}
              clicked={this.addRowHandler}
            >
              Add Invoice Item
            </Button>
            <table className="table table-bordered">
              <thead className="bg-light">
                <tr>
                  <th style={{ width: "42%" }} className="text-center">
                    Code
                  </th>
                  <th className="text-center">Description</th>
                  <th style={{ width: "20%" }} className="text-center">
                    Price
                  </th>
                  <th style={{ width: "7%" }} className="text-center">
                    Delete
                  </th>
                  {invoiceData && !isEmpty(invoiceData.invoice_items) && <th style={{ width: "7%" }} className="text-center">
                    Edit
                  </th>}
                </tr>
              </thead>
              <tbody>
                {editInvoice && (
                  <InvoiceTable
                    invoice={invoiceData}
                    selectedInvoiceHandler={this.selectedInvoiceHandler}
                    selectedInvoices={this.state.selectedInvoices}
                    selectedToEdit={this.state.selectedInvoiceIdToEdit}
                    selectedToEditInvoiceDetails={this.state.selectedInvoicesUpdate}
                    selectToEditHandler={this.selectedToEditHandler}
                    editInvoiceDetailHandler={this.editInvoiceInfoHandler}
                    editInvoice={editInvoice}
                  />
                )}
                {invoiceItemsData.map((data, index) => (
                  <InvoiceItem
                    key={data.key}
                    invalidContent={data.invalidContent}
                    invoiceRowData={data}
                    index={index}
                    updateRowValidation={this.updateRowValidation}
                    {...invoiceData && { rowCount: invoiceData.invoice_items.length }}
                    accountingCodes={this.state.accountingCodes}
                    updateRow={this.updateRowHandler}
                    deleteRow={this.deleteRowHandler}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={invoiceData && !isEmpty(invoiceData.invoice_items) ? "5" : "4"}>
                    <div className="d-flex justify-content-between pr-5">
                      <div className="text-right total-text ml-4">Sous-total / Subtotal</div>
                      <div className="text-right total-text mr-4">{`$ ${this.state.subtotal}`}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={invoiceData && !isEmpty(invoiceData.invoice_items) ? "5" : "4"}
                    className="bg-light"
                  >
                    <div className="d-flex justify-content-between pr-5">
                      <div className="text-right total-text ml-4">
                        Insurance Premium
                      </div>
                      <div className="mr-4">
                        {this.renderPriceInput("insurancePremium")}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={invoiceData && !isEmpty(invoiceData.invoice_items) ? "5" : "4"}
                    className="bg-light"
                  >
                    <div className="d-flex justify-content-between pr-5">
                      <div className="text-right total-text ml-4">
                        Service In Kind
                      </div>
                      <div className="mr-4">
                        {this.renderPriceInput("serviceInKind")}
                      </div>
                    </div>
                  </td>
                </tr>
                {this.state.showFavaBucksPayment && (
                  <tr>
                    <td
                      colSpan={invoiceData && !isEmpty(invoiceData.invoice_items) ? "5" : "4"}
                      className="bg-light"
                    >
                      <div className="d-flex justify-content-between pr-5">
                        <div className="total-text ml-4">
                          <div>{`${organization.credit_name} Payment`}</div>
                          <div className="mt-1">
                            {`(Available: $ ${accountData.available_credits ? accountData.available_credits.replace("$", "") : "$0.00"})`}
                          </div>
                        </div>
                        <div className="mr-4">
                          {this.renderPriceInput("creditPaymentAmount")}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {(editInvoice ? invoiceData.organization.is_gst_used : organization.is_gst_used) && (
                  <tr>
                    <td colSpan={invoiceData && !isEmpty(invoiceData.invoice_items) ? "5" : "4"}>
                      {this.renderTax(
                        "TPS",
                        "GST",
                        "gst_rate",
                        "isGstUsed",
                        "gst_amount"
                      )}
                    </td>
                  </tr>
                )}
                {(editInvoice ? invoiceData.organization.is_pst_used : organization.is_pst_used) && (
                  <tr>
                    <td colSpan={invoiceData && !isEmpty(invoiceData.invoice_items) ? "5" : "4"}>
                      {this.renderTax(
                        "TVQ",
                        "PST",
                        "pst_rate",
                        "isPstUsed",
                        "pst_amount"
                      )}
                    </td>
                  </tr>
                )}
                {(editInvoice ? invoiceData.organization.is_hst_used : organization.is_hst_used) && (
                  <tr>
                    <td colSpan={invoiceData && !isEmpty(invoiceData.invoice_items) ? "5" : "4"}>
                      {this.renderTax(
                        "TVH",
                        "HST",
                        "hst_rate",
                        "isHstUsed",
                        "hst_amount"
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={invoiceData && !isEmpty(invoiceData.invoice_items) ? "5" : "4"}>
                    <div className="d-flex justify-content-between pr-5">
                      <div className="text-right total-text ml-4">
                        {" "}
                        {(editInvoice &&
                          parseFloat(invoiceData.special_credits)) > 0
                          ? "TOTAL DÛ / TOTAL DUE AFTER CREDITS"
                          : "TOTAL DÛ / TOTAL DUE"}
                      </div>
                      <div className="text-right total-text mr-4">{`$ ${this.state.total}`}</div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
            {
              this.props.editInvoice ? (
                <React.Fragment>
                  <FormButton className="float-right" disabled={this.state.disabled}>
                    Update Invoice
                  </FormButton>
                  <Link to={`/invoices/${this.props.invoiceData.id}`}>
                    <Button type="secondary" className="mr-2 float-right">
                      Cancel
                    </Button>
                  </Link>
                </React.Fragment>
              ) : (
                  <FormButton className="float-right" disabled={this.state.disabled}>
                    Save Invoice
                  </FormButton>
                )
            }
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(InvoiceItems);
