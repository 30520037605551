import React, { Component } from "react";
import { title } from "change-case";
import { toast } from "react-toastify";

import Spinner from "../../../UI/Spinner";

import axios from "../../../../configAxios";
import ToggleSwitch from "../../../common/toggleSwitch"

class Alert extends Component {
  signal = axios.CancelToken.source();

  state = {
    warningMessage: [],
    loading: true,
    disabled: false
  }

  componentDidMount() {
    this.getWarningMessage()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getWarningMessage = () => {
    axios.get("/alerts", { cancelToken: this.signal.token })
      .then(res => {
        this.setState({ warningMessage: res.data.alerts, loading: false, disabled: false })
      })
      .catch(error => error);
  }

  alertChangeHandler = (message, index) => {
    axios.post("alerts", {
      name: message.name,
      value: !message.value
    }).then(res => {
      if (res.data.json.success) {
        this.setState({ index, disabled: true })
        this.getWarningMessage();
        toast.success("Alert successfully updated.")
      }
    })
  };

  render() {
    const alerts = this.state.warningMessage && this.state.warningMessage.map((message, index) => {
      return (
        <div className="row" key={index}>
          <div className="col-12">
            <div className="my-2 d-flex align-items-center">
              <ToggleSwitch 
                checked={message.value}
                onChange={() => !this.state.disabled && this.alertChangeHandler(message, index)}
                disabled={index === this.state.index && this.state.disabled}
              />
              <label className="ml-3">
                {`${title(message.name)}?`}
              </label>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="my-3 mx-4">
        {this.state.loading ?
          <Spinner />
          :
          <>
            <div className="my-4">
              If enabled, the following alerts will be displayed under each user profile
            </div>
            <div>{alerts}</div>
          </>
        }
      </div>
    )
  }

}

export default Alert;