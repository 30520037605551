import React from "react";
import { Link } from "react-router-dom";
import dateFns from "date-fns";

const FlagItem = props => {
  let link = '';
  if (typeof props.flag.user_or_asset !== 'string') {
    if ('asset' in props.flag.user_or_asset) {
      link = (
        <Link to={`/assets/${props.flag.user_or_asset.asset.id}`} className="text-info mb-1">
          {props.flag.name}
        </Link>
      );
    } else if ('user' in props.flag.user_or_asset) {
      link = (
        <Link to={`/users/${props.flag.user_or_asset.user.id}/summary`} className="text-info mb-1">
          {props.flag.name}
        </Link>
      );
    }
  } else {
    link = <span className="text-primary mb-1">{props.flag.name}</span>
  }

  return (
    <li className="list-group-item p-3">
      <div className="row">
        <div className="col-9">
          {link}
          <br />
          {props.flag.created_by ? (
            <React.Fragment>
              by <strong>{props.flag.created_by}</strong> {" "}
            </React.Fragment>
          ) : null}
          {dateFns.format(new Date(props.flag.created_at), "ddd MMM DD YYYY")}
        </div>
        <div className="col-3 d-flex flex-column justify-content-center">
          <button className="btn btn-link" onClick={e => props.deleteFlag(props.flag.id)}>
            <i className="fas fa-trash cursor text-danger" />
          </button>
        </div>
      </div>
    </li>
  );
};

export default FlagItem;