import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { title } from "change-case";
import { Link } from "react-router-dom";

import Button from "../../UI/button/Button";
import axios from "../../../configAxios";
import Spinner from "../../UI/Spinner";
import Modal from "../../UI/Modal";
import ToggleSwitch from "../../common/toggleSwitch";
import errorStatusHandler from "../../errorPages/UserNotAuthorized";
import langUtils from "../../../utils/langUtils";

import AddUsers from "./AddUser";

class Settings extends Component {
  signal = axios.CancelToken.source();
  state = {
    canMemberModifyTicket: null,
    canClearTicketBuilder: null,
    loading: true,
    newModal: false,
    flag: true,
    usersList: {},
    disableToggle: false,
    disableTicketBuilder: false,
  }

  componentDidMount() {
    axios.get(`/organizations/${this.props.auth.organizationId}`, { cancelToken: this.signal.token })
    .then(res => {
      if(res.status === 202) {
        errorStatusHandler(res);
        this.props.tabChangeHandler("Categories");
      } else {
        const { organization } = res.data;
        this.setState({ 
          canMemberModifyTicket: organization && organization.can_member_modify_ticket,
          canClearTicketBuilder: 
            organization && 
            organization.data && 
            organization.data.clear_ticket_builder_entries,
         })
         this.getUserList();
      }
    })
  }

  componentWillUnmount() {
    this.signal.cancel("Requests being cancelled");
  }

  getUserList = () => {
    axios.get('users/rental_ticket_alert', { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          usersList: res.data.users,
          loading: false
        })
      })
  }

  toggle = checked => {
    this.setState({ disableToggle: true });
    axios.put(`/organizations/set_can_member_modify_ticket`, {
      id: this.props.auth.organizationId,
      can_member_modify_ticket: checked
    }, { cancelToken: this.signal.token })
      .then(res => {
        if (res.data.json.success) {
          toast.success("Preference Changed");
          this.setState({ 
            canMemberModifyTicket: checked, 
            disableToggle: false 
          });
        } else {
          errorStatusHandler(res);
          this.setState({ disableToggle: false });
        }
      })
      .catch(err => {
        this.setState({ disableToggle: false });
      })
  };

  toggleClearTicketBuilder = checked => {
    const { canClearTicketBuilder } = this.state;
    this.setState({ 
      canClearTicketBuilder: !canClearTicketBuilder, 
      disableTicketBuilder: true,
    });
    axios.put(`/organizations/${this.props.auth.organizationId}`, {
      clear_ticket_builder_entries: !canClearTicketBuilder
    }, { cancelToken: this.signal.token })
      .then(res => {
        if (res.data.json.success) {
          toast.success("Preference Changed");
          this.setState({ disableTicketBuilder: false });
        } else {
          errorStatusHandler(res);
          this.setState({ 
            disableTicketBuilder: false,
            canClearTicketBuilder, 
          });
        }
      }).catch(() => this.setState({
        disableTicketBuilder: false,
        canClearTicketBuilder, 
      }));
  }

  deleteUserHandler = id => {
    let response = window.confirm("Are you sure you want to delete?");
    this.setState({ loading: true });
    let user = this.state.usersList.filter(user => user.id === id)
    if (response) {
      let data = {
        id: id,
        notification_setting_attributes: JSON.stringify({ "new_member_ticket": 0, "id": user[0].notification_setting_id })
      }
      axios.put(`/users/${id}`, data)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Deleted from rental ticket maillist successfully")
            this.getUserList();
          } else {
            errorStatusHandler(res);
          }
        })
    }
  }

  render() {
    const users = this.state.usersList;
    let usersList;
    if (this.state.loading) {
      return <Spinner />
    } else {
      const sortedUsers = users.sort((user1, user2) => (user1.full_name > user2.full_name) ? 1 : -1)
      usersList = sortedUsers.map(item => (
        <li className="list-group-item rounded-0 border-top" key={item.id}>
          <div className="row">
            <div className="col-10">
              <Link
                to={`/users/${item.id}/summary`}
                className="text-info font-weight-normal decoration-none"
              >{item.full_name}</Link>
            </div>
            <div className="col-2">
              <i class="fas fa-trash cursor"
                onClick={() => this.deleteUserHandler(item.id)}
              />
            </div>
          </div>
        </li>
      ));
      return (
        <div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="mx-4 my-4 d-sm-flex align-items-center">
                    <ToggleSwitch 
                      onChange={this.toggle} 
                      checked={this.state.canMemberModifyTicket} 
                      disabled={this.state.disableToggle}
                    />
                    <p className="ml-2 my-sm-0">Allow members to modify Tickets?</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mx-4 mb-4 d-sm-flex align-items-center">
                    <ToggleSwitch 
                      onChange={this.toggleClearTicketBuilder} 
                      checked={this.state.canClearTicketBuilder} 
                      disabled={this.state.disableTicketBuilder}
                    />
                    <p className="ml-2 my-sm-0">Clear Ticket Builder from entries older than 30 days?</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-9">
                <p class="font-weight-bold ml-4">Send a copy of the ticket request to:</p></div>
                <div className="col-sm-3 pl-5">
                  <Button
                    type="success"
                    clicked={() => { window.scrollTo(0, 0); this.setState({ newModal: true }) }}
                  >
                    {langUtils("txt_global_add_new", "Add New")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-group list-group-flush mt-3 ml-1">
            <li className="list-group-item">
              <div className="row">
                <div className="col-lg-12">
                  <span className="font-weight-bold">User Name</span>
                </div>
              </div>
            </li>
            {usersList}
          </ul>
          <Modal
            show={this.state.newModal}
            flag={this.state.flag}
            title={"Add User"}
            hide={() => this.setState({ newModal: false })}
          >
            <AddUsers
              closeModal={() => this.setState({ newModal: false })}
              usersList={this.state.usersList}
              getUserList={this.getUserList}
            />
          </Modal>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(Settings);