import React, { Component } from 'react'
import { toast } from "react-toastify";
import { title } from "change-case";
import classNames from "classnames";

import axios from '../../../configAxios'
import Button from '../../UI/button/Button'
import ModalSpinner from '../../UI/ModalSpinner';
import FormButton from '../../UI/button/FormButton';

import TagInput from "./TagInput";

class TagForm extends Component {
  signal = axios.CancelToken.source();
  timeout = null;
  formRef = React.createRef();
  state = {
    loading: this.props.edit,
    disableSubmit: false,
    name: "",
    intialInputValue: "",
    tagList: [],
    inputIsLoading: false,
  }

  componentDidMount() {
    if (this.props.edit) {
      axios.get(`/organization_tags/${this.props.organizationTagId}`, 
        { cancelToken: this.signal.token }
      ).then(res => {
          const { name: tagName } = res.data.organization_tag;
          this.setState({
            ...this.state,
            name: tagName,
            intialInputValue: tagName,
            loading: false
          })
        })
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Requests are being cancelled.");
  }

  inputChangedHandler = (event, key) => {
    let formFields = this.state.formFields
    formFields[key].value = event.target.value

    this.setState({ 
      ...this.state, 
      formFields, 
      disableSubmit: false 
    }, () => this.getTagsByName(formFields[key].value));
  }

  submitHandler = e => {
    e.preventDefault();
    this.setState({ ...this.state, disableSubmit: true })
    let data = {
      name: this.state.name,
      tag_type: this.props.tagType
    }
    if (this.props.edit) {
      axios.put(`/organization_tags/${this.props.organizationTagId}`, data)
        .then(res => {
          if (res.data.json.success) {
            toast.success(`Label Updated Successfully`)
            this.props.closeModal()
            this.props.getOrganizationTags();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disableSubmit: false });
        })
    } else {
      axios.post('/organization_tags', data)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Label Created Successfully");
            this.props.closeModal()
            this.props.getOrganizationTags();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disableSubmit: false });
        })
    }
  }

  render() {
    const submitBtnDisabled = (
      this.state.disableSubmit ||
      this.state.inputIsLoading
    );

    if (this.state.loading) {
      return (
        <ModalSpinner />
      )
    }
    return (
      <div className="tag-form mt-3">
        <form 
          autoComplete="new-password"
          onSubmit={this.submitHandler}
          ref={this.formRef}
        >
          <TagInput
            tagNameChanged={tagName => this.setState({ name: tagName })}
            tagName={this.state.name}
            edit={this.props.edit}
            tagType={this.props.tagType}
            getTagList={tagList => this.setState({ tagList })}
            setInputLoading={inputIsLoading => this.setState({ inputIsLoading })}
            formRef={this.formRef}
          />
          <div className="text-center">
            <Button
              type="secondary"
              clicked={this.props.closeModal}
              className="mr-2"
            >
              Cancel
            </Button>
            <FormButton
              disabled={submitBtnDisabled}
              className={classNames({ "cursor-not-allowed": submitBtnDisabled })}
            >
              {this.props.edit ? "Update" : "Create"}
            </FormButton>
          </div>
        </form>
      </div>
    )
  }
}

export default TagForm;