/* eslint-disable */

const _localizeSettings = () => {
  !function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);
}

export const localizeWidget = () => {
  const localizeWidget = document.getElementById("localize-widget")

  if (localizeWidget) {
    localizeWidget.style.height = "auto";
    localizeWidget.style.width = "auto";
  } else {
    const localizeScript = document.createElement('script');

    localizeScript.setAttribute("id", "localizeScript");

    localizeScript.addEventListener('load', (event) => { Localize.initialize({ key: 'sC8yUGknkTT4c', rememberLanguage: true });
    })

    localizeScript.src = "https://global.localizecdn.com/localize.js"
    document.body.appendChild(localizeScript);

    const localizeFunction = document.createElement('script');

    localizeFunction.setAttribute("id", "localizeFunction");

    localizeFunction.addEventListener('load', (event) => {_localizeSettings()})
  }

}
/* eslint-enable */

export const removeLocalizeWidget = () => {
  const localizeWidget = document.getElementById("localize-widget")

  if (localizeWidget) {
    localizeWidget.style.height = "0";
    localizeWidget.style.width = "0";
  }
}
