import React from "react"

const CheckboxInput = ({ props }) => {
  return (
    <div className="d-flex flex-row align-items-baseline">
      <input
        type="checkbox"
        className={props.className}
        {...props.elementConfig}
        value={props.value}
        onClick={props.changed}
        disabled={props.disabled}
      />
      <label
        className="ml-2 mb-0"
        htmlFor={props.elementConfig.id}
        onClick={props.changed}
      >
        {props.questionText}
      </label>
    </div>
  )
}

export default CheckboxInput
