import React from "react"
import Select from "react-select";

const ApplicationStatusSelect = ({status, setStatus}) => {
  const options = [
    { value: 'Incomplete', label: 'Incomplete' },
    { value: 'Received', label: 'Received' },
    { value: 'Under Jury Review', label: 'Under Jury Review' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Denied', label: 'Denied'}
  ]
  const defaultStatus = status ? { label: `${status}` } : null
  return (
    <div className="pb-3" >
      <Select
        options={options}
        defaultValue={defaultStatus}
        onChange={(selected) => setStatus(selected.value)}
        menuPortalTarget={document.querySelector("body")} //allows the dropdown to 'stick' to the body, preventing the table overflow from forcing a scroll
      />
    </div>
  )
}

export default ApplicationStatusSelect
