export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const SET_RESPONSE = "SET_RESPONSE";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
export const RESET_RESPONSE = "RESET_RESPONSE";
export const LOADING = "LOADING";
export const GET_SIDE_BAR = "GET_SIDE_BAR";
export const GET_INVOICES_POLICY = "GET_INVOICES_POLICY";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const GET_PROJECT_ATTRIBUTE_TYPES = "GET_PROJECT_ATTRIBUTE_TYPES";
export const GET_PROJECT_ATTRIBUTES = "GET_PROJECT_ATTRIBUTES";
export const ADD_PROJECT_ATTRIBUTE = "GET_PROJECT_ATTRIBUTES";
export const UPDATE_PROJECT_ATTRIBUTE = "UPDATE_PROJECT_ATTRIBUTE";
export const CREATE_PROJECT_ATTRIBUTE = "CREATE_PROJECT_ATTRIBUTE";
export const DELETE_PROJECT_ATTRIBUTE = "DELETE_PROJECT_ATTRIBUTE";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const GET_MEMBERS = "GET_MEMBERS";
export const SEARCH_MEMBERS = "SEARCH_MEMBERS";
export const MEMBERS_LOADING = "MEMBERS_LOADING";
export const GET_GRANT_CAMPAIGNS = "GET_GRANT_CAMPAIGNS";
export const GET_GRANT_CAMPAIGN = "GET_GRANT_CAMPAIGN";
export const GET_SUBMISSIONS = "GET_SUBMISSIONS";
export const UPDATE_SUBMISSION_ANSWERS = "UPDATE_SUBMISSION_ANSWERS";
export const GET_FORMS = "GET_FORMS";
export const GET_FORM = "GET_FORM";
export const CREATE_FORM = "CREATE_FORM";
export const UPDATE_FORM = "UPDATE_FORM";
export const ADD_FORM_ATTRIBUTE = "ADD_FORM_ATTRIBUTE";
export const DELETE_FORM_ATTRIBUTE = "DELETE_FORM_ATTRIBUTE";
export const GET_LOGIN_ASSETS = "GET_LOGIN_ASSETS";
export const SET_LOGIN_URL = "SET_LOGIN_URL";
export const TOGGLE_LOGIN_BUTTON = "TOGGLE_LOGIN_BUTTON";
export const IS_ADMIN = "IS_ADMIN";
export const RESET_ADMIN_STATUS = "RESET_ADMIN_STATUS";
export const SET_ASSET_LOADING = "SET_ASSET_LOADING";
export const UPDATE_ORGANIZATION_SETTINGS = "UPDATE_ORGANIZATION_SETTINGS";
export const GET_ORGANIZATION_SETTINGS = "GET_ORGANIZATION_SETTINGS";
