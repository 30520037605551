import React, { Component } from "react"
import { toast } from "react-toastify"
import { CSVLink } from "react-csv"

import Button from "../../UI/button/Button"
import axios from "../../../configAxios"
import Spinner from "../../UI/Spinner"
import Input from "../../UI/input/Input"

class ExportData extends Component {
  csvLink = React.createRef()
  signal = axios.CancelToken.source()
  state = {
    addressList: [],
    models: {
      elementType: "select",
      elementConfig: {
        name: "models",
        type: "text",
        placeholder: "Select Data Type",
        options: [],
      },
      label: "From Address",
      selectedOption: null,
    },
    organizations: {
      elementType: "select",
      elementConfig: {
        name: "organizations",
        type: "text",
        placeholder: "Select Organization",
        options: [],
      },
      label: "Organization",
      selectedOption: null,
    },
    orgnId: null,
    csvData: [],
    loading: false,
    is_admin: "",
    disabled: false,
  }

  componentDidMount() {
    axios
      .get("/organizations/formdata", { cancelToken: this.signal.token })
      .then((res) => {
        const { models } = res.data.json
        const { organizations } = res.data.json
        const { current_organization } = res.data.json
        const { is_admin } = res.data.json
        this.setState({
          ...this.state,
          models: {
            ...this.state.models,
            elementConfig: {
              ...this.state.models.elementConfig,
              options: models.map((type) => {
                return { value: type, label: type }
              }),
            },
          },
          organizations: {
            ...this.state.organizations,
            elementConfig: {
              ...this.state.organizations.elementConfig,
              options: organizations.map((organization) => {
                return { value: organization[0], label: organization[1] }
              }),
            },
          },
          orgnId: current_organization.id,
          is_admin,
        })
      })
  }

  inputChangedHandler = (event, key) => {
    const inputValue = {}
    inputValue.selectedOption = event
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState({ [key]: updatedcontrols })
  }

  exportCsv = () => {
    if (
      this.state.models.selectedOption &&
      (this.state.is_admin ? this.state.organizations.selectedOption : true)
    ) {
      this.setState({ ...this.state, loading: true, disabled: true })
      axios
        .get("/organizations/export_csv_data", {
          params: {
            option: this.state.models.selectedOption.value,
            organization: this.state.is_admin
              ? this.state.organizations.selectedOption.value
              : this.state.orgnId,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          if (res.data.json.success) {
            this.setState(
              {
                csvData: res.data.json.csv_data,
                filename: `${this.state.models.selectedOption.value} Model Data.csv`,
                loading: false,
                disabled: false,
              },
              () => {
                this.csvLink.current.link.click()
              }
            )
          } else {
            toast.error("No Data Present")
            this.setState({
              loading: false,
              disabled: false,
            })
          }
        })
    } else {
      if (this.state.models.selectedOption === null) {
        toast.error("Please Select Model")
      }
      if (
        this.state.is_admin &&
        this.state.organizations.selectedOption === null
      ) {
        toast.error("Please Select Organization")
      }
    }
  }

  render() {
    return (
      <div className="w-100 h-70vh p-4">
        <div className="row">
          <div className="col-12">
            <h5 className="font-weight-bold">Export Data</h5>
          </div>
        </div>
        {this.state.loading ? <Spinner className="h-60vh" /> : ""}
        <form
          className="row mt-3"
          style={{ display: this.state.loading ? "none" : "block" }}
        >
          <div className="col-lg-4">
            <Input
              {...this.state.models}
              changed={(event) => this.inputChangedHandler(event, "models")}
            />
            {this.state.is_admin ? (
              <Input
                {...this.state.organizations}
                changed={(event) =>
                  this.inputChangedHandler(event, "organizations")
                }
              />
            ) : (
              ""
            )}
          </div>
          <div className="col-12 d-flex justify-content-start">
            <Button
              clicked={this.exportCsv}
              type="primary"
              disabled={this.state.disabled}
            >
              Submit
            </Button>
            <CSVLink
              data={this.state.csvData}
              filename={this.state.filename}
              className="hidden"
              ref={this.csvLink}
              target="_self"
            />
          </div>
        </form>
      </div>
    )
  }
}

export default ExportData
