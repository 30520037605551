import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

import CenteredContainer from "../UI/container/CenteredContainer";
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    return <>
      { this.state.error ? <ErrorPage props={this.state.props} /> : this.props.children }
    </>
  }
}

export default ErrorBoundary;
