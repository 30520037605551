import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const items = [
  {
    id: 21,
    formId: 91,
    creator: "Joel Duhaime",
    grant_campaign_id: 2,
  },
  {
    id: 22,
    formId: 91,
    creator: "John Hazlett",
    grant_campaign_id: 2,
  },
  {
    id: 23,
    formId: 91,
    creator: "Hattie Hardt",
    grant_campaign_id: 2,
  },
  {
    id: 24,
    formId: 92,
    creator: "Hattie Hardt",
    grant_campaign_id: 2,
  },
];

const submissionsAdapter = createEntityAdapter({});

const slice = createSlice({
  name: "submission",
  initialState: submissionsAdapter.getInitialState(initialState),
  reducers: {
    getSubmissions(state, action) {
      state.loading = false;
      return submissionsAdapter.addMany(state, items);
    },
    createSubmission(state, action) {
      const id = Math.floor(Math.random() * Math.floor(100000000));
      const { formId, creator } = action.payload;
      return submissionsAdapter.addOne(state, {
        id,
        formId,
        creator,
        grant_campaign_id: 2,
      });
    },
  },
});

export const selectSubmissionsLoading = (state) => state.submissions.loading;

export const {
  selectById: selectSubmissionById,
  selectIds: selectSubmissionIds,
  selectEntities: selectSubmissionEntities,
  selectAll: selectAllSubmissions,
  selectTotal: selectTotalSubmissions,
} = submissionsAdapter.getSelectors((state) => state.submissions);

const { actions, reducer } = slice;
export const { getSubmissions, createSubmission } = actions;

export default reducer;
