export const timezone = [
  { label: "American Samoa", value: "American Samoa" },
  {
    label: "International Date Line West",
    value: "International Date Line West",
  },
  { label: "Midway Island", value: "Midway Island" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Alaska", value: "Alaska" },
  { label: "Pacific Time (US & Canada)", value: "Pacific Time (US & Canada)" },
  { label: "Tijuana", value: "Tijuana" },
  { label: "Arizona", value: "Arizona" },
  { label: "Chihuahua", value: "Chihuahua" },
  { label: "MST", value: "MST" },
  { label: "Mazatlan", value: "Mazatlan" },
  {
    label: "Mountain Time (US & Canada)",
    value: "Mountain Time (US & Canada)",
  },
  { label: "Central America", value: "Central America" },
  { label: "Central Time (US & Canada)", value: "Central Time (US & Canada)" },
  { label: "Guadalajara", value: "Guadalajara" },
  { label: "Mexico City", value: "Mexico City" },
  { label: "Monterrey", value: "Monterrey" },
  { label: "Saskatchewan", value: "Saskatchewan" },
  { label: "Bogota", value: "Bogota" },
  { label: "Eastern Time (US & Canada)", value: "Eastern Time (US & Canada)" },
  { label: "Indiana (East)", value: "Indiana (East)" },
  { label: "Lima", value: "Lima" },
  { label: "Quito", value: "Quito" },
  { label: "Atlantic Time (Canada)", value: "Atlantic Time (Canada)" },
  { label: "Caracas", value: "Caracas" },
  { label: "Georgetown", value: "Georgetown" },
  { label: "La Paz", value: "La Paz" },
  { label: "Santiago", value: "Santiago" },
  { label: "Newfoundland", value: "Newfoundland" },
  { label: "Brasilia", value: "Brasilia" },
  { label: "Buenos Aires", value: "Buenos Aires" },
  { label: "Greenland", value: "Greenland" },
  { label: "Montevideo", value: "Montevideo" },
  { label: "Mid-Atlantic", value: "Mid-Atlantic" },
  { label: "Azores", value: "Azores" },
  { label: "Cape Verde Is.", value: "Cape Verde Is." },
  { label: "Edinburgh", value: "Edinburgh" },
  { label: "Lisbon", value: "Lisbon" },
  { label: "London", value: "London" },
  { label: "Monrovia", value: "Monrovia" },
  { label: "UTC", value: "UTC" },
  { label: "Amsterdam", value: "Amsterdam" },
  { label: "Belgrade", value: "Belgrade" },
  { label: "Berlin", value: "Berlin" },
  { label: "Bern", value: "Bern" },
  { label: "Bratislava", value: "Bratislava" },
  { label: "Brussels", value: "Brussels" },
  { label: "Budapest", value: "Budapest" },
  { label: "Casablanca", value: "Casablanca" },
  { label: "Copenhagen", value: "Copenhagen" },
  { label: "Dublin", value: "Dublin" },
  { label: "Ljubljana", value: "Ljubljana" },
  { label: "Madrid", value: "Madrid" },
  { label: "Paris", value: "Paris" },
  { label: "Prague", value: "Prague" },
  { label: "Rome", value: "Rome" },
  { label: "Sarajevo", value: "Sarajevo" },
  { label: "Skopje", value: "Skopje" },
  { label: "Stockholm", value: "Stockholm" },
  { label: "Vienna", value: "Vienna" },
  { label: "Warsaw", value: "Warsaw" },
  { label: "West Central Africa", value: "West Central Africa" },
  { label: "Zagreb", value: "Zagreb" },
  { label: "Athens", value: "Athens" },
  { label: "Bucharest", value: "Bucharest" },
  { label: "Cairo", value: "Cairo" },
  { label: "Harare", value: "Harare" },
  { label: "Helsinki", value: "Helsinki" },
  { label: "Jerusalem", value: "Jerusalem" },
  { label: "Kaliningrad", value: "Kaliningrad" },
  { label: "Kyiv", value: "Kyiv" },
  { label: "Pretoria", value: "Pretoria" },
  { label: "Riga", value: "Riga" },
  { label: "Sofia", value: "Sofia" },
  { label: "Tallinn", value: "Tallinn" },
  { label: "Vilnius", value: "Vilnius" },
  { label: "Baghdad", value: "Baghdad" },
  { label: "Istanbul", value: "Istanbul" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Minsk", value: "Minsk" },
  { label: "Moscow", value: "Moscow" },
  { label: "Nairobi", value: "Nairobi" },
  { label: "Riyadh", value: "Riyadh" },
  { label: "St. Petersburg", value: "St. Petersburg" },
  { label: "Tehran", value: "Tehran" },
  { label: "Abu Dhabi", value: "Abu Dhabi" },
  { label: "Baku", value: "Baku" },
  { label: "Muscat", value: "Muscat" },
  { label: "Samara", value: "Samara" },
  { label: "Tbilisi", value: "Tbilisi" },
  { label: "Volgograd", value: "Volgograd" },
  { label: "Yerevan", value: "Yerevan" },
  { label: " Kabul", value: "Kabul" },
  { label: "Ekaterinburg", value: "Ekaterinburg" },
  { label: "Islamabad", value: "Islamabad" },
  { label: "Karachi", value: "Karachi" },
  { label: "Tashkent", value: "Tashkent" },
  { label: "Asia/Kolkata", value: "Asia/Kolkata" },
  { label: "Chennai", value: "Chennai" },
  { label: "Kolkata", value: "Kolkata" },
  { label: "Mumbai", value: "Mumbai" },
  { label: "New Delhi", value: "New Delhi" },
  { label: "Sri Jayawardenepura", value: "Sri Jayawardenepura" },
  { label: "Kathmandu", value: "Kathmandu" },
  { label: "Almaty", value: "Almaty" },
  { label: "Astana", value: "Astana" },
  { label: "Dhaka", value: "Dhaka" },
  { label: "Urumqi", value: "Urumqi" },
  { label: "Rangoon", value: "Rangoon" },
  { label: "Bangkok", value: "Bangkok" },
  { label: "Hanoi", value: "Hanoi" },
  { label: "Jakarta", value: "Jakarta" },
  { label: "Krasnoyarsk", value: "Krasnoyarsk" },
  { label: "Novosibirsk", value: "Novosibirsk" },
  { label: "Beijing", value: "Beijing" },
  { label: "Chongqing", value: "Chongqing" },
  { label: "Hong Kong", value: "Hong Kong" },
  { label: "Irkutsk", value: "Irkutsk" },
  { label: "Kuala Lumpur", value: "Kuala Lumpur" },
  { label: "Perth", value: "Perth" },
  { label: "Singapore", value: "Singapore" },
  { label: "Taipei", value: "Taipei" },
  { label: "Ulaanbaatar", value: "Ulaanbaatar" },
  { label: "Osaka", value: "Osaka" },
  { label: "Sapporo", value: "Sapporo" },
  { label: "Seoul", value: "Seoul" },
  { label: "Tokyo", value: "Tokyo" },
  { label: "Yakutsk", value: "Yakutsk" },
  { label: "Adelaide", value: "Adelaide" },
  { label: "Darwin", value: "Darwin" },
  { label: "Brisbane", value: "Brisbane" },
  { label: "Canberra", value: "Canberra" },
  { label: "Guam", value: "Guam" },
  { label: "Hobart", value: "Hobart" },
  { label: "Melbourne", value: "Melbourne" },
  { label: "Port Moresby", value: "Port Moresby" },
  { label: "Sydney", value: "Sydney" },
  { label: "Vladivostok", value: "Vladivostok" },
  { label: "Magadan", value: "Magadan" },
  { label: "New Caledonia", value: "New Caledonia" },
  { label: "Solomon Is.", value: "Solomon Is." },
  { label: "Srednekolymsk", value: "Srednekolymsk" },
  { label: "Auckland", value: "Auckland" },
  { label: "Fiji", value: "Fiji" },
  { label: "Kamchatka", value: "Kamchatka" },
  { label: "Marshall Is.", value: "Marshall Is." },
  { label: "Wellington", value: "Wellington" },
  { label: "Chatham Is.", value: "Chatham Is." },
  { label: "Nuku'alofa", value: "Nuku'alofa" },
  { label: "Samoa", value: "Samoa" },
  { label: "Tokelau Is.", value: "Tokelau Is." },
]

export default {
  timezone,
}
