import React, { Component } from 'react'
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from '../../../configAxios'
import Button from '../../UI/button/Button'
import Input from '../../UI/input/Input'
import ModalSpinner from '../../UI/ModalSpinner';
import FormButton from '../../UI/button/FormButton';
import { responseErrorHandler } from '../../../utils/errorHandler';

class MergeForm extends Component {
  signal = axios.CancelToken.source();
  timeout = 0;
  state = {
    loading: true,
    disableSubmit: false,
    formFields: {
      name: {
        elementType: "input",
        elementConfig: {
          name: "name",
          type: "text",
          required: true
        },
        label: "Label",
        value: ""
      },
      tag: {
        elementType: "asyncSelect",
        elementConfig: {
          name: "tag",
          placeholder: "Search here",
          inputLength: 1,
          loadOptions: (inputValue, callback) => {
            if (inputValue.length > 0) this.searchTagsByName(inputValue, callback);
          },
          components: {
            DropdownIndicator: () => {
              return null;
            },
            IndicatorSeparator: () => {
              return null;
            }
          },
          styles: {
            multiValueLabel: base => ({
              ...base,
              backgroundColor: "#f8f9fa",
              color: "#666666"
            }),
            multiValueRemove: base => ({
              ...base,
              backgroundColor: "#f8f9fa",
              color: "#666666"
            })
          }
        },
        label: "Merge To",
        selectedOption: null
      },
      tagCharacters: ""
    }
  }

  searchTagsByName = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue,
        type: this.props.tagType
      },
      cancelToken: this.signal.token
    };
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getTagsByName(queryParams, callback);
    }, 300);
  };

  getTagsByName = (queryParams, callback) => {
    axios
      .get("/tags/search", queryParams)
      .then(res => {
        const options = res.data.json.filter(tag => tag.name !== this.state.formFields.name.value);
        callback(
          options.map(tag => ({ value: tag.name, label: tag.name }))
        );
      })
  }

  componentDidMount() {
    axios.get(`/organization_tags/${this.props.organizationTagId}`, { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          formFields: {
            ...this.state.formFields,
            name: {
              ...this.state.formFields.name,
              value: res.data.organization_tag.name
            }
          },
          loading: false
        })
      })
  }

  inputChangedHandler = (event, key) => {
    if (key === "tag") {
      let formFields = this.state.formFields
      formFields[key].selectedOption = event

      this.setState({ ...this.state, formFields });
    } else {
      let formFields = this.state.formFields
      formFields[key].value = event.target.value

      this.setState({ ...this.state, formFields });
    }
  }

  submitHandler = e => {
    e.preventDefault()

    if (!this.state.formFields.tag.selectedOption) {
      toast.error(`Please select a Label to merge`);
      return
    }

    this.setState({ ...this.state, disableSubmit: true })
    let data = {
      old_tag: this.state.formFields.name.value,
      new_tag: this.state.formFields.tag.selectedOption.value,
      tag_type: this.props.tagType
    }
    axios.post(`/organization_tags/merge`, data)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Labels are being merged in the background.")
          this.props.closeModal()
        } else {
          responseErrorHandler(res);
        }
      })
  }

  render() {
    if (this.state.loading) {
      return (
        <ModalSpinner />
      )
    }
    return (
      <div className="mt-3">
        <form onSubmit={this.submitHandler}>
          <Input
            {...this.state.formFields.name}
          />
          <Input
            {...this.state.formFields.tag}
            changed={event => this.inputChangedHandler(event, "tag")}
            chart={this.state.tagCharacters}
            onInputChange={event => this.setState({ tagCharacters: event })}
          />
          <div className="text-center">
            <Button
              type="secondary"
              clicked={this.props.closeModal}
              className="mr-2"
            >
              Cancel
            </Button>
            <FormButton disabled={this.state.disableSubmit}>Merge</FormButton>
          </div>
        </form>
      </div>
    )
  }
}

export default MergeForm;
