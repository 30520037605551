import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { upperCase } from "change-case";

import Spinner from "./components/UI/Spinner";

import "react-toastify/dist/ReactToastify.min.css";

import "./App.css";

import Navbar from "./components/layout/Navbar";
import Login from "./components/auth/Login";
import SignUpEmail from "./components/auth/SignUpEmail";
import Unsubscribe from "./components/auth/Unsubscribe";
import RedirectToOrganization from "./components/auth/RedirectToOrganization";

import Labels from "./components/labels"
import Languages from "./components/languages";
import LanguageForm from "./components/languages/LanguageForm";


import Organizations from "./components/organizations";
import OrganizationForm from "./components/organizations/OrganizationForm";
import OrganizationDetails from "./components/organizations/OrganizationDetails";
import SuperAdminList from "./components/organizations/SuperAdminList";
import AddSuperAdmin from "./components/organizations/AddSuperAdmin";
import AddFileForm from "./components/organizations/AddFileForm";

import Submissions from "./components/submissions";

import ResetPassword from "./components/auth/ResetPassword";
import UpdatePassword from "./components/auth/UpdatePassword";
import ConfirmPasswordUpdate from "./components/auth/ConfirmPasswordUpdate";
import PrivateRoute from "./components/common/PrivateRoute";
import Projects from "./components/projects/Projects/Projects";
import Project from "./components/project/Project";
import Campaigns from "./components/campaigns";
import Campaign from "./components/campaigns/campaign";
import CampaignSubmission from "./components/campaigns/submission";
import CampaignSubmissionEdit from "./components/campaigns/submission/Edit";
import Forms from "./components/forms";
import Form from "./components/forms/form";
import FlagListings from "./components/Flag/FlagListings";
import Programs from "./components/programs/index";
import GrantApplicationsIndex from "./components/grantApplications/GrantApplicationsIndex"
import ApplicationForm from './components/grantApplications/applicationForms/ApplicationForm'
import CompletedApplicationForm from './components/campaigns/campaign/grantApplications/applicationOverview/CompletedApplicationForm'
import Create from "./components/forms/Create"

import Reels from "./components/Reel/Reels/Reels";
import Friends from "./components/friends/Friends";
import EditReelItem from "./components/Reel/Reels/ReelsItem/EditReelItem";

import RentalRequests from "./components/rentalRequests";

//user
import AddFriend from "./components/addFriend/AddFriend";
import SignUp from "./components/addFriend/SignUp";
import User from "./components/users";

// import SystemSettings from "./components/systemSettings/index";
// import Program from "./components/Programs/Program";
import Program from "./components/programs/Program";
import EditReservation from "./components/programs/editReservation";
import Collections from "./components/Collections/Collections";
import Library from "./components/library/index";
import NewOrEditProgram from "./components/programs/NewOrEditProgram";
//Our Stuff
import Assets from "./components/assets";

import CreateAsset from "./components/assets/createAsset";
import EditAsset from "./components/assets/editAsset";
import AssetReport from "./components/assets/report";
import AssetDetail from "./components/assets/assetDetail";
import CreateOrEditLibrary from "./components/library/CreateOrEditLibrary";

import SystemSettings from "./components/systemSettings";
//volunteer hours
import VolunteerHours from "./components/volunteerHours";

//billing and payments
import Billing from "./components/billing";
import BillingDetail from "./components/billing/detail";
import ThirdPartyPayment from "./components/billing/ThirdPartyPayments";
import CreateInvoice from "./components/billing/create";
import HelcimPayment from "./components/billing/detail/helcimPayment";
import UnauthPayStatus from "./components/billing/unauthPayStatus";

//Report
import Reports from "./components/reports/index";
import GeneralMembershipReport from "./components/reports/GeneralMembershipReport";
import PartnershipOrganizations from "./components/reports/PartnershipOrganizations";
import ServicesInKind from "./components/reports/ServicesInKind";
import GrantApplication from "./components/reports/GrantApplication";
import GeneralRevenue from "./components/reports/GeneralRevenue";
import AssetServicing from "./components/reports/AssetServicing";
import AssetRevenue from "./components/reports/AssetRevenue";
import Rentals from "./components/reports/Rentals";
import AccountReport from "./components/reports/Account";
import ProgramReport from "./components/reports/Program";
import ProjectReport from "./components/reports/Project";
import EquipmentReport from "./components/reports/Equipment";
import UsersAccessingProgramsAndServices from "./components/reports/UserAccessingPrograms";
import EducationAndAssociation from "./components/reports/EducationAssociation";
import FavaStatsReport from "./components/reports/FavaStatsReport";

//Dashboard
import Dashboard from "./components/dashboard";

//Public Calendar
import PublicCalendar from "./components/publicSection/publicCalendar";

//Donations
import Donations from "./components/donations";
import DonationCampaigns from "./components/donations/DonationsCampaigns";
import Donors from "./components/donations/Donors";

//Error
import ErrorPage from "./components/errorPages/ErrorPage";
import axios from "./configAxios";
// import NotFound from "./components/errorPages/NotFoundPage";
// import NotFoundPage from "./components/errorPages/NotFoundPage";

// Swagger
import Swagger from "./components/swagger/index";

// email verifications
import EmailVerfication from "./components/emailVerfication";

// AMS Super Admins
import List from "./components/amsSuperAdmin/list";
import NewSuperAdmin from "./components/amsSuperAdmin/list/NewSuperAdmin";
import { connect } from "react-redux";
import { getTranslationStatus } from "./store/actions";
import { localizeWidget, removeLocalizeWidget } from "./utils/localizeWidget";


const mql = window.matchMedia(`(max-width: 1100px)`);


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      show: true,
      loading: true
    };
  }

  componentDidMount() {
    const { getTranslationStatus } = this.props
    mql.addListener(this.mediaQueryChanged);
    if (localStorage.getItem("sidebar")) {
      this.setState({
        show: localStorage.getItem("sidebar") === "true" ? true : false,
      });
    }
    if (window.innerWidth < 1100) {
      this.setState(
        {
          ...this.state,
          show: false,
        },
        () => localStorage.setItem("sidebar", this.state.show)
      );
    }

    if (localStorage.authToken) {
      this.getLocaleFromSessionStorage();
    }
    else{
      this.setState({loading: false});
    }

    if (localStorage.organizationId) {
      getTranslationStatus(localStorage.organizationId)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.localize !== this.props.localize) {
      if (this.props.localize) {
        localizeWidget()
      } else {
        removeLocalizeWidget()
      }
    }
  }

  getLocaleFromSessionStorage = () => {
    if (sessionStorage.getItem('locale')) {
      this.setState({loading: false});
      window.locale = JSON.parse(sessionStorage.getItem('locale'))
    } else {
      axios.get('languages/show')
        .then(res => {
          window.locale = res.data.language.locale
          sessionStorage.setItem('locale', JSON.stringify(res.data.language.locale))
          this.setState({loading: false});
        })
    }
  }

  componentWillUnmount() {
    if (typeof this.state.mql !== "undefined") {
      this.state.mql.removeListener(this.mediaQueryChanged);
    }
  }

  toggleSidebar = () => {
    this.setState({ show: !this.state.show }, () =>
      localStorage.setItem("sidebar", this.state.show)
    );
  };

  changeTab = () => {
    if (window.innerWidth < 1100) {
      this.toggleSidebar();
    }
  };

  mediaQueryChanged = () => {
    this.setState({ sidebarDocked: mql.matches, show: false }, () =>
      localStorage.setItem("sidebar", this.state.show)
    );
  };

  render() {
    let showNavbar = this.props.location.pathname.startsWith("/error") ||
      this.props.location.pathname.startsWith("/unsubscribe") || this.props.location.pathname.startsWith("/redirect");

    if(!sessionStorage.getItem('locale') && localStorage.authToken){
      this.getLocaleFromSessionStorage();
    }

    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {localStorage.organizationSubDomain !== null &&
            localStorage.organizationSubDomain !== "" &&
            typeof localStorage.organizationSubDomain !== "undefined"
              ? upperCase(localStorage.organizationSubDomain)
              : upperCase(window.location.hostname.split(".")[0])}
          </title>
          {localStorage.getItem("logoUrl") && (
            <link
              rel="shortcut icon"
              href={localStorage.getItem("logoUrl")}
              sizes="16x16"
            />
          )}
        </Helmet>
        {!showNavbar && (
          <Navbar
            toggleSidebar={this.toggleSidebar}
            changeTab={this.changeTab}
            show={this.state.show}
          />
        )}
        <ToastContainer />

        <Route exact path="/swagger" render={() => <Swagger />} />
        <Route exact path="/users/signup" component={SignUp} />
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/unsubscribe/:token" component={Unsubscribe} />
        <Route exact path="/redirect/:token" component={RedirectToOrganization} />
        <Route exact path="/reset_password" component={ResetPassword} />
        <Route exact path="/signup" component={SignUpEmail} />
        <Route exact path="/passwords/edit" component={UpdatePassword} />
        <Route exact path="/confirm_password_update" component={ConfirmPasswordUpdate} />
        <Route exact path="/error/:code" component={ErrorPage} />
        <div
          style={{
            marginLeft: `${
              !showNavbar &&
              this.state.show &&
              localStorage.getItem("authToken")
                ? "300px"
                : "0px"
            }`,
          }}
          className="content-container mt-5"
        >
          <Route
            exact
            path="/invoices/:id/online_payment"
            component={HelcimPayment}
          />
          <Route exact path="/ses/:status" component={EmailVerfication} />
          <Route
            exact
            path="/invoices/payment/status"
            component={UnauthPayStatus}
          />
        </div>
        <Route
          exact
          path="/public/assets/booking_activity"
          component={PublicCalendar}
        />

        <div
          style={{
            marginLeft: `${!showNavbar && this.state.show ? "300px" : "0px"}`,
          }}
          className="content-container mt-5"
        >
        <div id="draggable" style={{"list-style": "none"}}></div>
          {this.state.loading ? (<Spinner/>) : (
          <Switch>
            {/* dashboard */}
            <PrivateRoute exact path="/dashboard" component={Dashboard} />

            {/*users*/}
            <PrivateRoute
              exact
              path="/users/member/new"
              component={AddFriend}
            />
            <PrivateRoute exact path="/users/edit/:id" component={AddFriend} />
            <PrivateRoute exact path="/users/:id/:tab" component={User} />
            <PrivateRoute exact path="/users/:tab" component={Friends} />

            {/*Project*/}
            <PrivateRoute exact path="/submissions" component={Submissions} />
            <PrivateRoute exact path="/projects" component={Projects} />
            <PrivateRoute exact path="/projects/new" component={Projects} />
            <PrivateRoute exact path="/projects/:id" component={Project} />
            <PrivateRoute
              exact
              path="/projects/:id/:toptab"
              component={Project}
            />
            
            <PrivateRoute exact path="/campaigns" component={Campaigns} />
            <PrivateRoute exact path="/campaigns/new" component={Campaigns} />
            <PrivateRoute
              exact
              path="/campaigns/:campaignId/submissions/:id"
              component={CampaignSubmission}
            />
            <PrivateRoute
              exact
              path="/campaigns/:campaignId/submissions/:id/edit"
              component={CampaignSubmissionEdit}
            />
            <PrivateRoute
              exact
              path="/campaigns/:id/applications/show/:application_id/application_forms/:application_form_id"
              component={CompletedApplicationForm}
            />
            <PrivateRoute exact path="/campaigns/:campaign_id/forms/new" component={Create} />
            <PrivateRoute exact path="/campaigns/:campaign_id/forms/:form_id/edit" component={Create} />
            <PrivateRoute
              path="/campaigns/:id/:toptab"
              component={Campaign}
            />
            <PrivateRoute exact path="/grant_applications" component={GrantApplicationsIndex} />
            <PrivateRoute exact path="/grant_applications/new" component={GrantApplicationsIndex} />
            <PrivateRoute exact path="/grant_applications/:id/form/:form_id/application/:application_id/apply" component={ApplicationForm} />
            <PrivateRoute exact path="/grant_applications/:id/application_form/:application_form_id/show" component={CompletedApplicationForm} />

            {/* This will need to be removed once forms are properly encapsulated within grant-campaigns */}
            <PrivateRoute exact path="/forms/:id" component={Form} />
            <PrivateRoute exact path="/forms/:id/:toptab" component={Form} />
            <PrivateRoute exact path="/forms" component={Forms} />

            <PrivateRoute exact path="/reels" component={Reels} />
            <PrivateRoute exact path="/reels/:id" component={EditReelItem} />
            <PrivateRoute exact path="/collections" component={Collections} />

            {/* Assets */}
            <PrivateRoute
              exact
              path="/assets"
              component={(props) => <Assets tab="Search" {...props} />}
            />
            <PrivateRoute
              exact
              path="/assets/booking_activity"
              component={(props) => (
                <Assets tab="Booking Activity" {...props} />
              )}
            />
            <PrivateRoute
              exact
              path="/assets/booking_activity/:innertab"
              component={(props) => (
                <Assets tab="Booking Activity" {...props} />
              )}
            />
            <PrivateRoute
              exact
              path="/assets/checked_out"
              component={(props) => <Assets tab="Checked Out" {...props} />}
            />
            <PrivateRoute
              exact
              path="/assets/rental_requests"
              component={props => <Assets tab="Rental Requests" {...props} />}
            />
            <PrivateRoute exact path="/assets/new" component={CreateAsset} />
            <PrivateRoute exact path="/assets/:id" component={AssetDetail} />
            <PrivateRoute exact path="/assets/edit/:id" component={EditAsset} />
            <PrivateRoute
              exact
              path="/assets/:id/report"
              component={AssetReport}
            />

            {/* Library */}
            <PrivateRoute exact path="/libraries" component={Library} />
            <PrivateRoute exact path="/libraries/:toptab" component={Library} />
            <PrivateRoute
              exact
              path="/libraries/:toptab/new"
              component={CreateOrEditLibrary}
            />
            <PrivateRoute
              exact
              path="/libraries/:toptab/:id/edit"
              component={CreateOrEditLibrary}
            />

            {/*programs*/}
            <PrivateRoute exact path="/programs" component={Programs} />
            <PrivateRoute
              exact
              path="/programs/calendar_view"
              component={(props) => <Programs tab="Calendar View" {...props} />}
            />
            <PrivateRoute
              path="/programs/list_view/:innertab"
              component={(props) => <Programs tab="List View" {...props} />}
            />
            <PrivateRoute
              path="/programs/list_view"
              component={(props) => <Programs tab="List View" {...props} />}
            />
            <PrivateRoute
              exact
              path="/programs/drafts"
              component={(props) => <Programs tab="Drafts" {...props} />}
            />
            <PrivateRoute
              exact
              path="/programs/new"
              component={NewOrEditProgram}
            />
            <PrivateRoute
              exact
              path="/programs/:id/edit"
              component={NewOrEditProgram}
            />
            <PrivateRoute exact path="/programs/:id" component={Program} />
            <PrivateRoute
              exact
              path="/programs/:id/bookings"
              component={EditReservation}
            />

            {/* billing */}
            <PrivateRoute exact path="/invoices" component={Billing} />
            <PrivateRoute
              exact
              path="/invoices/new"
              component={CreateInvoice}
            />
            <PrivateRoute
              exact
              path="/invoices/:id"
              redirection="/invoices/payment/status"
              component={BillingDetail}
            />
            <PrivateRoute
              exact
              path="/invoices/third_party_payments/:tab"
              component={ThirdPartyPayment}
            />

            <PrivateRoute
              exact
              path="/invoices/edit/:id"
              component={CreateInvoice}
            />

            {/* Reports */}
            <PrivateRoute exact path="/reports" component={Reports} />
            <PrivateRoute
              exact
              path="/reports/general_membership_report"
              component={GeneralMembershipReport}
            />
            <PrivateRoute
              exact
              path="/reports/partnership_organizations_report"
              component={PartnershipOrganizations}
            />
            <PrivateRoute
              exact
              path="/reports/asset_revenue_report"
              component={AssetRevenue}
            />
            <PrivateRoute
              exact
              path="/reports/general_revenue_report"
              component={GeneralRevenue}
            />
            <PrivateRoute
              exact
              path="/reports/rentals_report"
              component={Rentals}
            />
            <PrivateRoute
              exact
              path="/reports/asset_servicing_report"
              component={AssetServicing}
            />
            <PrivateRoute
              exact
              path="/reports/grant_application_report"
              component={GrantApplication}
            />
            <PrivateRoute
              exact
              path="/reports/account_report"
              component={AccountReport}
            />
            <PrivateRoute
              exact
              path="/reports/program_report"
              component={ProgramReport}
            />
            <PrivateRoute
              exact
              path="/reports/project_report"
              component={ProjectReport}
            />
            <PrivateRoute
              exact
              path="/reports/equipment_report"
              component={EquipmentReport}
            />
            <PrivateRoute
              exact
              path="/reports/users_accessing_programs_and_services_report"
              component={UsersAccessingProgramsAndServices}
            />
            <PrivateRoute
              exact
              path="/reports/services_in_kind_report"
              component={ServicesInKind}
            />
            <PrivateRoute
              exact
              path="/reports/education_and_association_report"
              component={EducationAndAssociation}
            />
            <PrivateRoute
              exact
              path="/reports/fava_stats_report"
              component={FavaStatsReport}
            />

            {/* system settings */}
            <PrivateRoute
              exact
              path="/system_settings"
              component={SystemSettings}
            />
            <PrivateRoute
              exact
              path="/system_settings/:sidetab"
              component={SystemSettings}
            />
            <PrivateRoute
              exact
              path="/system_settings/:sidetab/:toptab"
              component={SystemSettings}
            />
            <PrivateRoute
              exact
              path="/system_settings/:sidetab/:toptab/:innertab"
              component={SystemSettings}
            />

            {/* AMS Super Admin */}
            {/* Language */}
            <PrivateRoute exact path="/languages" component={Languages} />
            <PrivateRoute
              exact
              path="/languages/new"
              component={LanguageForm}
            />
            <PrivateRoute exact path="/languages/:tab" component={Languages} />
            <PrivateRoute
              exact
              path="/languages/:id/edit"
              component={LanguageForm}
            />

            {/* organizations */}
            <PrivateRoute
              exact
              path="/organizations"
              component={Organizations}
            />
            <PrivateRoute
              exact
              path="/organizations/new"
              component={() => <OrganizationForm type="add" />}
            />
            <PrivateRoute
              exact
              path="/organizations/:id"
              component={OrganizationDetails}
            />
            <PrivateRoute
              exact
              path="/organizations/edit/:id"
              component={(props) => <OrganizationForm type="edit" {...props} />}
            />
            <PrivateRoute
              exact
              path="/organizations/:id/super_admins"
              component={SuperAdminList}
            />
            <PrivateRoute
              exact
              path="/organizations/:id/super_admins/new"
              component={AddSuperAdmin}
            />
            <PrivateRoute
              exact
              path="/organizations/:organizationid/super_admins/:id/edit"
              component={AddSuperAdmin}
            />
            <PrivateRoute
              exact
              path="/organizations/:id/members/new"
              component={(props) => <AddFileForm type="member" {...props} />}
            />
            <PrivateRoute
              exact
              path="/organizations/:id/equipment_items/new"
              component={(props) => (
                <AddFileForm type="equipment_item" {...props} />
              )}
            />
            <PrivateRoute
              exact
              path="/organizations/:id/libraries/new"
              component={(props) => <AddFileForm type="library" {...props} />}
            />

            {/* Super Admin List */}
            <PrivateRoute 
              exact 
              path="/ams_super_admins" 
              component={List} 
            />
            <PrivateRoute 
              exact 
              path="/ams_super_admins/new" 
              component={NewSuperAdmin} 
            />
            <PrivateRoute 
              exact 
              path="/ams_super_admins/:id/edit"
              component={(props) => <NewSuperAdmin edit {...props} />} 
            />

            {/* Labels */}
            <PrivateRoute exact path="/labels" component={Labels} />

            {/* Rental Requests */}
            <PrivateRoute exact path="/tickets" component={RentalRequests} />

            {/* VolunteerHours */}
            <PrivateRoute
              exact
              path="/volunteer_tickets"
              component={VolunteerHours}
            />

            {/*flags*/}
            <PrivateRoute exact path="/flags" component={FlagListings} />

            {/* Donations */}
            <PrivateRoute exact path="/donations" component={Donations} />
            <PrivateRoute
              exact
              path="/donations/campaigns"
              component={DonationCampaigns}
            />
            <PrivateRoute exact path="/donations/donors" component={Donors} />
          </Switch>
          )}
          {
            this.props.location.pathname.startsWith("/error") ||
              this.props.location.pathname.startsWith("/login") ||
              this.props.location.pathname.includes("password") ||
              this.props.location.pathname.startsWith("/signup") ||
              this.props.location.pathname.startsWith("/ses") ||
              this.props.location.pathname === "/users/signup" ||
              this.props.location.pathname === "/invoices/payment/status" ||
              this.props.location.pathname.startsWith("/public") ? '' : (
                <div className="container w-100">
                  <div className="row">
                    <div className="col-12 text-center my-5 py-5">
                      {`Powered By AMS Network © ${new Date().getFullYear()}`}
                    </div>
                  </div>
                </div>
              )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    localize: state.organization.localize,
  }
}

export default withRouter(connect(mapStateToProps, { getTranslationStatus })(App))
