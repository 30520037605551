import React, { Component } from "react";
import dateFns from "date-fns";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Modal from "../UI/Modal";
import Button from "../UI/button/Button";
import axios from "../../configAxios";

class ProjectItem extends Component {
  state = {
    showEmailModal: false
  };

  statusHandler = (status, email) => {
    let params = {
      id: this.props.project.slug,
      consider_type: this.props.projectCue || this.props.project.consider_type_id,
      status
    };

    if (status === "approve") {
      params["email"] = email;
    }

    axios
      .post("projects/submission_approve/", params)
      .then(res => {
        const statusObj = {
          approved: res.data.project.status.is_approved,
          declined: res.data.project.status.is_declined
        };
        toast.success(`Project ${status}d successfully`);
        this.setState({
          showEmailModal: false
        });
        this.props.statusChangeHandler(statusObj, this.props.project.slug);
      })
      .catch(error => error);
  };

  disapproveHandler = () => {
    let response = window.confirm("Decline Approve Submission?");
    if (response) {
      this.statusHandler("decline");
    }
  };

  render() {
    const emailModal = (
      <Modal
        show={this.state.showEmailModal}
        hide={() => this.setState({ showEmailModal: false })}
        center
        closeModal={() => this.setState({ showEmailModal: false })}
        closeButton
      >
        <div className="col-md-12 mt-2">
          <div className="d-flex justify-content-center font-weight-bold">
            Would you like to notify the member about the project being approved
            ?
          </div>
          <div className="d-flex justify-content-center my-2">
            <Button
              type="success"
              className="mr-2"
              style={{ width: "80px" }}
              clicked={() => this.statusHandler("approve", "true")}
            >
              Yes
            </Button>
            <Button
              type="delete"
              style={{ width: "80px" }}
              clicked={() => this.statusHandler("approve", "false")}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    );

    let actionButtons = '';
    if (this.props.project.status.is_approved && this.props.project.can_approve) {
      actionButtons = (
        <Button
          className="btn rounded-circle"
          style={{ background: "#F66C61", color: "white" }}
          clicked={this.disapproveHandler}
        >
          <i className="fas fa-times" />
        </Button>
      )
    } else if (this.props.project.status.is_declined) {
      actionButtons = (
        <p className="text-danger">
          Declined
        </p>
      )
    } else {
      actionButtons = (
        this.props.project.can_approve &&
        <React.Fragment>
          <Button
            type="primary"
            className="rounded-circle"
            clicked={() => { window.scrollTo(0, 0); this.setState({ showEmailModal: true }) }}
          >
            <i className="fas fa-check" />
          </Button>
          &nbsp; &nbsp;
            <Button
            type="danger"
            className="rounded-circle"
            clicked={this.disapproveHandler}
          >
            <i className="fas fa-times" />
          </Button>
        </React.Fragment>
      )
    }

    return (
      <div className={`submission project-item row border-bottom ${this.props.index === 0 ? '' : 'pt-3'}`}>
        <div className="col-lg-10 col-8">
          <div style={{ marginBottom: "5px" }}>
            <Link
              to={`/projects/${this.props.project.slug ? this.props.project.slug : this.props.project.id}`}
              className="text-info font-weight-bold li-link"
            >
              <h5 style={{ marginBottom: "5px" }}>
                {this.props.project.name ? this.props.project.name
                  : this.props.project.slug ? `/projects/${this.props.project.slug}`
                    : `/projects/${this.props.project.id}`}
              </h5>
            </Link>
          </div>
          <div style={{ marginBottom: "5px" }}>
            by: {this.props.project.creator} |{" "}
            {dateFns.format(
              new Date(this.props.project.created_at),
              "MMM DD, YYYY"
            )}
          </div>
          <div>
            <div className="row">
              <div className="col-lg-8">
                <p>
                  {this.props.project.consider_type_name}
                  {this.props.project.consider_type_name && ' - '}
                  {this.props.project.status.is_approved ? (
                    <span className="text-primary">Approved</span>
                  ) : this.props.project.status.is_declined ? (
                    <span className="text-primary">Approval Declined</span>
                  ) : (
                        <span className="text-primary">Pending Approval</span>
                  )}
                </p>
              </div>
              <div className="col-lg-4">
                Video Added - {this.props.project.has_video ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-4 action-buttons pb-3">
          {actionButtons}
        </div>
        {emailModal}
      </div>
    );
  }
}

export default ProjectItem;
