import { toast } from "react-toastify"
import {
  AUTHENTICATE_USER,
  GET_INVOICES_POLICY,
  GET_SIDE_BAR,
  IS_ADMIN,
  RESET_ADMIN_STATUS,
} from "./actionTypes"
import axios, { setAuthToken } from "../../configAxios"
import { title } from "change-case"
import { setLoading } from "./index"

export const authenticateUser = (isAuthenticated, userId, organizationId, roleLevel) => {
  return {
    type: AUTHENTICATE_USER,
    isAuthenticated,
    organizationId,
    userId,
    roleLevel,
  }
}

export const loginUser = (userData) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    axios
      .post("/oauth/token", userData)
      .then((res) => {
        if (res.data.status === "invalid") {
          if (res.data.hasOwnProperty("errors")) {
            Object.keys(res.data.errors).forEach((error) => {
              toast.error(title(error) + " " + res.data.errors[error])
            })
          }
          let count = parseInt(localStorage.getItem("wrongCredentialsCount"))
          if (count) {
            count += 1
            localStorage.setItem("wrongCredentialsCount", count)
          } else {
            localStorage.setItem("wrongCredentialsCount", 1)
          }
          dispatch(setLoading(false))
        } else {
          dispatch(setLoading(false))
          const userId = res.data.user_id
          const organizationId = res.data.organization_id
          const roleLevel = res.data.role_level
          const moreUserInfo = res.data.social_data

          localStorage.removeItem("wrongCredentialsCount")
          localStorage.setItem("authToken", res.data.access_token)
          localStorage.setItem("expiresIn", res.data.expires_in)
          localStorage.setItem("userId", userId)
          localStorage.setItem("organizationId", organizationId)
          localStorage.setItem("organizationName", res.data.organization_name)
          localStorage.setItem(
            "organizationTimezone",
            res.data.organization_timezone
          )
          localStorage.setItem(
            "organizationSubDomain",
            res.data.current_organization_subdomain
          )
          localStorage.setItem(
            "logoUrl",
            res.data.current_organization_logo.logo.url
          )
          localStorage.setItem("sidebar", "true")
          localStorage.setItem("moreUserInfo", JSON.stringify(moreUserInfo))
          localStorage.setItem("roleLevel", roleLevel)
          localStorage.setItem("email", userData.username)
          localStorage.setItem("landingPage", res.data.default_landing_page.replace("user_id", userId) || "/dashboard")

          setAuthToken(res.data.access_token)
          dispatch(authenticateUser(true, userId, organizationId, roleLevel))
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.hasOwnProperty("error")) {
            toast.error(err.response.data.error)
          }
          if (err.response.data.hasOwnProperty("errors")) {
            err.response.data.errors.forEach((error) => {
              toast.error(`${title(error)} ${err.response.data.errors[error]}`)
            })
          }
        }
        dispatch(setLoading(false))
      })
  }
}

export const resetPassword = (userData, history) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    axios
      .post("/passwords", userData)
      .then((res) => {
        dispatch(setLoading(false))
        if (res.data.json.success) {
          toast.success(
            "We have sent an email to you with link to reset your password."
          )
          history.push("/passwords/edit")
        } else {
          toast.error("User not found")
        }
      })
      .catch((err) => {
        dispatch(setLoading(false))
        if (err.response && err.response.data) {
          if (err.response.data.hasOwnProperty("error")) {
            toast.error(err.response.data.error)
          }
          if (err.response.data.hasOwnProperty("errors")) {
            err.response.data.errors.forEach((error) => {
              toast.error(`${title(error)} ${err.response.data.errors[error]}`)
            })
          }
        }
      })
  }
}

export const logoutUser = () => {
  return (dispatch) => {
    try {
      axios.delete("/oauth/token", {
        params: {
          token: localStorage.authToken,
        },
      })
    } catch (e) {
      console.log(e.name)
    } finally {
      localStorage.removeItem("authToken")
      localStorage.removeItem("expiresIn")
      localStorage.removeItem("userId")
      localStorage.removeItem("organizationId")
      localStorage.removeItem("logoUrl")
      localStorage.removeItem("sidebar")
      localStorage.removeItem("lastVisitedTab")
      localStorage.removeItem("roleLevel")
      localStorage.clear()
      sessionStorage.clear()
      dispatch(authenticateUser(false, null, null))
      dispatch(resetAdminStatus())
    }
  }
}

export const getSideBar = () => {
  return (dispatch) => {
    axios.get("/sidebar").then((res) => {
      dispatch({
        type: GET_SIDE_BAR,
        sideBar: res.data.json,
      })
    })
  }
}

export const getInvoicesPolicy = () => {
  return (dispatch) => {
    axios.get("invoices/policy").then((res) => {
      dispatch({
        type: GET_INVOICES_POLICY,
        policyRes: res.data.json,
      })
    })
  }
}

export const getAdminStatus = (id) => async (dispatch) => {
  try {
    const res = await axios.get('/users/policy', { params: { id } })
    const { is_admin: isAdmin } = res.data.json

    dispatch({ type: IS_ADMIN, isAdmin })
  }
  catch (e) {
    console.log(e)
    toast.error(`Error: ${e.message}`)
  }
}

export const resetAdminStatus = () => ({ type: RESET_ADMIN_STATUS })
