import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { getAdminStatus } from "../../../store/actions"
import dateFns from "date-fns"
import "./BookedAsset.css"
import EditLoanPeriod from "./EditLoanPeriod"
import Modal from "../../UI/Modal"



class BookedAsset extends Component {
  state = {
    showLoanModal: false,
    openAssetModal: false,
    pickUpDate: null,
    dropOffDate: null,
    count: {
      hours: null,
      minutes: null,
      days: null
    },
  };

  componentDidMount() {
    const booking = this.props;
    this.getBookedAsset(booking, this.props);
    this.props.getAdminStatus(this.props.userId)
  }

  componentWillReceiveProps(props) {
    const { booking } = props;
    this.getBookedAsset(booking, props);
  }

  getBookedAsset = (booking, props) => {
    if (booking) {
      const { loanPeriod, total } = props;
      const startDate = new Date(loanPeriod.start_time);
      const endDate = new Date(loanPeriod.end_time);
      const res = Math.abs(endDate - startDate) / 1000;
      const days = Math.floor(res / 86400);
      const hours = Math.floor(res / 3600) % 24;
      const minutes = Math.floor(res / 60) % 60;
      const mins = (minutes * 10 / 60).toFixed(1) * 10;
      this.setState({
        pickUpDate: new Date(new Date(loanPeriod.start_time)),
        dropOffDate: new Date(new Date(loanPeriod.end_time)),
        count: {
          ...this.state.count,
          hours,
          minutes: mins,
          days
        },
        total: total
      });
    }
  };

  checkboxHandler = (event, modal) => {
    event.stopPropagation();
    if (this.state.showLoanModal) return;
    if (!modal) {
      let checked = !this.props.checked;
      this.setState({ checked });
      this.props.clicked(checked, this.props.booking.booking_ids);
    } else {
      if (this.props.canEditBookings) this.setState({ showLoanModal: true })
    }
  };

  closeModal = event => {
    event.stopPropagation();
    this.setState({ showLoanModal: false });
  };

  modalDisplayHandler = () => {
    const { booking, onModalToggle, setDisplayAsset } = this.props

    onModalToggle()
    setDisplayAsset(booking.asset)
  }

  render() {
    const { booking, checked: isChecked, isAdmin } = this.props;

    const type = booking && booking.asset.type;
    const chargedLoanPeriod = booking && booking.charged_loan_period;
    const { pickUpDate, dropOffDate, count } = this.state;
    const time = type === "Facility" ? `${count.hours}.${count.minutes} hours` : `${count.days + 1} days`;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const loanModal = (
      <Modal
        show={this.state.showLoanModal}
        hide={() => this.setState({ showLoanModal: false })}
        title="Edit Loan Period"
        center
        closeModal={event => this.setState({ showLoanModal: false })}
        closeButton
      >
        <EditLoanPeriod
          closeModal={() => this.setState({ showLoanModal: false })}
          type={type}
          pickUpDate={this.state.pickUpDate}
          dropOffDate={this.state.dropOffDate}
          bookingId={this.props.booking.booking_ids}
          ticketId={this.props.ticketId}
          getTicket={this.props.getTicket}
        />
      </Modal>
    );
    return (
      <li
        className="list-group-item cursor"
        onClick={event => this.checkboxHandler(event, false)}
      >
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-1">
                    <input type="checkbox" checked={isChecked} />
                  </div>
                  {isAdmin &&
                    <Link
                      to={`/assets/${booking.asset.id}`}
                      className="text-info font-weight-bold"
                    >
                      <div className="col-10">
                        <span className="font-weight-bold">{`(${booking.quantity}) `}</span>
                        {booking.asset.name}
                        <br/>
                      </div>
                    </Link>
                  }
                  {!isAdmin &&
                    <div
                      className="col-10 text-primary font-weight-bold cursor"
                      onClick={this.modalDisplayHandler}
                    >
                      <span className="font-weight-bold">{`(${booking.quantity}) `}</span>
                      {booking.asset.name}
                      <br/>
                    </div>
                  }
                </div>
              </div>
              <div className="col-3 text-right">
                <span className="font-weight-bold cursor">
                  {booking.price}
                </span>
              </div>
            </div>
            <div className="row" onClick={event => this.checkboxHandler(event, true)}>
              {pickUpDate && (
                <div>
                  <div className="col ml-5 btn-link p-0">
                    <span>
                      {
                        type === "Facility" ?
                          `${months[pickUpDate.getMonth()]} 
                          ${pickUpDate.getDate()}: 
                          ${dateFns.format(pickUpDate, "hh:mmA ")} - 
                          ${dateFns.format(dropOffDate, "hh:mmA ")}`
                          :
                          `${months[pickUpDate.getMonth()]} 
                          ${pickUpDate.getDate()}- 
                          ${months[dropOffDate.getMonth()]} 
                          ${dropOffDate.getDate()}`
                      }
                    </span>
                  </div>
                  <div className="col ml-5 btn-link p-0">
                    <span>
                      {` (${time}`}
                    </span>
                    <span>
                      {chargedLoanPeriod !== null &&
                        `, ${parseFloat(chargedLoanPeriod)} charged`}
                      {")"}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {loanModal}
          </div>
        </div>
      </li>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    userId: state.auth.userId,
  }
}

export default connect(mapStateToProps, { getAdminStatus })(BookedAsset)
