import React from 'react'
import _ from "lodash";

import DonorItem from "./DonorItem"

const DonorList = ({ donors }) => {
  return (
    <>
      {_.isEmpty(donors)
        ? <div className="text-center my-4">No Donors Found</div>
        : <>
          {donors.slice(0, 5).map(donor => (
            <DonorItem
              image={donor.donor &&
                donor.donor.photo &&
                donor.donor.photo.url
                ? donor.donor.photo.url
                : null}
              lastContact={
                donor.donor && donor.donor.last_contact
                  ? donor.donor.last_contact
                  : null
              }
              lastGift={
                donor.donor && donor.donor.last_gift
                  ? donor.donor.last_gift
                  : null
              }
              donorName={
                `${donor.donor.first_name ? donor.donor.first_name : ""} ${donor.donor.last_name ? donor.donor.last_name : ""}`
              }
              donorId={donor.donor.id}
              thanked={donor.has_thanked}
            />
          ))}
        </>
      }
    </>
  )
}

export default DonorList
