import React from "react"


const JuryMemberItem = ({ juryMember }) => {
  return (
    <div className="d-flex flex-row px-2 ml-2 justify-content-between border-top border-left border-right">
      <p className="mb-0">{juryMember.jury_member.full_name}</p>
      <a className="mb-0" href={`mailto: ${juryMember.jury_member.email}`}>{juryMember.jury_member.email}</a>
    </div>
  )
}


const JuryMemberDisplay = ({ juryMembers }) => {
  return(
    <>
      { juryMembers && juryMembers.length > 0 ?
        <>
          <div className="d-flex flex-row justify-content-between px-2 ml-2">
            <h5 className="font-weight-bold">Name</h5>
            <h5 className="font-weight-bold">Email</h5>
          </div>
          {juryMembers.map((juryMember, index) => {
            return <JuryMemberItem key={index} juryMember={juryMember} />
          })}
          <div className="ml-2 border-bottom" />
        </>
        :
        <p>A jury hasn't been selected yet</p>
      }
    </>
  )
}

export default JuryMemberDisplay
