import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

import storeConfig from "./store/configStore";
import { authenticateUser } from "./store/actions/index";

import App from "./App";
import ScrollToTop from "./ScrollToTop";
import { setAuthToken } from "./configAxios";
import ErrorBoundary from "./components/errorPages/ErrorHandler";

import "./index.scss";

import history from "./history";

const store = storeConfig();
if (localStorage.authToken) {
  setAuthToken(localStorage.authToken);
  store.dispatch(
    authenticateUser(true, localStorage.userId, localStorage.organizationId, localStorage.roleLevel)
  );
}

if (process.env.NODE_ENV === "production") {
  if (window.location.hostname.includes("filmreelui")) {
    Sentry.init({
      dsn: "https://f05c3d8bfbec48efab3e319369f431e7@sentry.io/1435529"
    });
  } else {
    ReactGA.initialize('UA-49083054-3');
    ReactGA.pageview(window.location.pathname + window.location.search);
    const script = document.createElement("script");
    script.src = "//rum-static.pingdom.net/pa-5dd2ef9b05a241000800086f.js";
    script.async = true;
    document.head.appendChild(script);
    Sentry.init({
      dsn: "https://3ff8fe0af3e54a8cbcf887bcf280c55e@sentry.io/1481884"
    });
  }
}

const acceptedRoles = ["super_admin", "staff", "site_admin"]

if (acceptedRoles.includes(localStorage.roleLevel)) {
  const jiraHelpdesk = (callback) => {
    const jhdScript = document.createElement("script")
    jhdScript.type = "text/javascript"
    jhdScript.setAttribute("data-jsd-embedded", null)
    jhdScript.setAttribute("data-key", "81a092dd-6a43-489d-9ed7-b4a9feecc9db")
    jhdScript.setAttribute("data-base-url", "https://jsd-widget.atlassian.com")
    jhdScript.src = "https://jsd-widget.atlassian.com/assets/embed.js"
    jhdScript.onload = () => {
      callback()
    }
    document.getElementsByTagName("head")[0].appendChild(jhdScript)
  }

  jiraHelpdesk( () => {
    const DOMContentLoaded_event = document.createEvent("Event")
    DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true)
    window.document.dispatchEvent(DOMContentLoaded_event)
  })
}

const app = (
  <ErrorBoundary>
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Router>
    </Provider>
  </ErrorBoundary>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
