import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { title } from "change-case";
import queryString from "query-string";

import axios, { setAuthToken } from "../../configAxios";
import { authenticateUser } from "../../store/actions/index";
import spinner from "../../assests/images/spinner-gif.gif";

class RedirectToOrganization extends Component {
  signal = axios.CancelToken.source();

  componentDidMount() {
    const { token } = this.props.match.params;
    const values = queryString.parse(this.props.location.search);
    localStorage.setItem("authToken", token);
    localStorage.setItem("expiresIn", values.expiresIn);
    localStorage.setItem("userId", values.userId);
    localStorage.setItem("organizationId", values.organizationId);
    localStorage.setItem("sidebar", "true");
    localStorage.setItem("moreUserInfo", values.moreUserInfo);
    localStorage.setItem("organizationName", values.name);
    localStorage.setItem("organizationSubDomain", values.subdomain);
    localStorage.setItem("roleLevel", values.roleLevel);
    this.props.authenticateUser(true, values.userId, values.organizationId, values.roleLevel);
    setAuthToken(token);
    axios.get(`/organizations/${values.organizationId}`, { cancelToken: this.signal.token })
      .then(res => {
        const organization = res.data.organization;
        localStorage.setItem("logoUrl", organization.logo);
        this.props.history.push("/dashboard");
      });
  }

  render() {
    return (
      <div className="text-center mt-4">
        <h4>Redirecting to {title(localStorage.organizationSubDomain)}</h4>
        <img src={spinner} alt="Spinner" />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authenticateUser: () => dispatch(authenticateUser(true, localStorage.userId, localStorage.organizationId, localStorage.roleLevel))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(RedirectToOrganization));
