import React from "react";
import { Link } from "react-router-dom";
import NoUser from "../../../assests/images/user-icon.jpg";

const MemberItem = props => {
  const { member } = props;
  const image = member.photo ? member.photo : NoUser;
  return (
    <li className="list-group-item p-3">
      <div className="row">
        <div className="col-3">
          <div className="rounded-circle bg-cover" style={{
            width: 60,
            height: 60,
            backgroundImage: `url(${image})`
          }} />
        </div>
        <div className="col-9">
          <div className="ml-4">
            <Link to={`users/${member.id}/summary`} className="text-info">
              {member.full_name}
            </Link>
            <p>{member.email}</p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default MemberItem;
