import React from "react"
import CreateLabel from "./CreateLabel"

const SearchAndCreate = ({
                           searchTerm,
                           setSearchTerm,
                           onLabelUpdate,
                           onCategoryUpdate,
                           labelCategories
                         }) => {
  return (
    <div className="p-4">
      <div className="d-flex justify-content-between">
        <div className="flex-grow-1 pr-2">
          <input
            id="search-entry-filter"
            placeholder="Search Labels"
            className="form-control"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          {searchTerm.length > 0 && (
            <i
              className="fas fa-times float-right position-relative b-25 r-15 cursor"
              onClick={() => setSearchTerm("")}
            />
          )}
        </div>
        <div className="pl-2">
          <CreateLabel labelCategories={labelCategories} onComplete={onLabelUpdate} />
        </div>
      </div>
    </div>
  )
}
export default SearchAndCreate
