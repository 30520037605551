import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { serialize } from 'object-to-formdata'
import { toast } from "react-toastify";
import { connect } from "react-redux"
import { grantCampaignUpdate } from "../../../../store/slices/grantCampaign"
import Button from "../../../UI/button/Button"

const JuryPackageUpload = ({ grantCampaign, updateGrantCampaignStore }) => {
  const [uploadedPackage, setUploadedPackage] = useState(grantCampaign.jury_package)

  useEffect(() => {
    setUploadedPackage(grantCampaign.jury_package)
  },[grantCampaign])

  const uploadPackageHandler = (event) => {
    const juryPackage = event.target.files[0]

    if (juryPackage) {
      const formData = serialize({ ...grantCampaign, jury_package: juryPackage })

      axios.put(`/grant_campaigns/${grantCampaign.id}`, formData).then((res) => {
        toast.success("Jury Package Uploaded")
        const updatedCampaign = res.data.json.data
        updateGrantCampaignStore(updatedCampaign)
      })
    }
  }

  const removeFileUpload = () => {
    axios.delete(`/grant_campaigns/${grantCampaign.id}/jury_package`).then(() => {
      toast.success("Jury Package Removed")
      updateGrantCampaignStore({ ...grantCampaign, jury_package: null })
      setUploadedPackage(null)
    })
  }

  return (
    <div className="d-flex flex-row justify-content-between align-items-center w-100 py-2">
      {!uploadedPackage &&
      <>
        <label htmlFor="jury_package_upload" className="btn btn-primary w-75"
               style={{ backgroundColor: "var(--color-success)" }}>Upload Jury Package</label>
        {/* File upload input elements cannot be directly styled, so I'm using the label as a way of redirecting click events to the input */}
        <input
          className="d-none"
          id="jury_package_upload"
          type="file"
          onChange={e => uploadPackageHandler(e)}
        />
      </>
      }
      {uploadedPackage &&
      <>
        <a target="_blank" rel="noopener noreferrer" href={uploadedPackage} className="w-75">
          <Button type="primary" className="w-100">
            <div>View Jury Package</div>
          </Button>
        </a>
        <span
          hidden={!uploadedPackage}
          className="cursor fas fa-sm fa-trash text-danger pr-5"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove File"
          onClick={() => {
            removeFileUpload()
          }}
        />
      </>
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGrantCampaignStore: (campaignData) => dispatch(grantCampaignUpdate({
        id: campaignData.id,
        changes: { ...campaignData }
      })
    )
  }
}
// I would have preferred to drive the upload with local state only, but the whole Tabs component is tightly
// coupled to the Campaign slice in Redux. I've extended this component to interact with the store so users can navigate
// around the tabs cleanly.
export default connect(null, mapDispatchToProps)(JuryPackageUpload)
