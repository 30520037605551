import React from "react";
import { Modal } from "react-bootstrap";
import "./CalendarModal.css";

const calendarModal = props => {
  let modalStyle;

  if (props.center) {
    modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      ...props.style
    };
  } else {
    modalStyle = {
      position: "absolute",
      width: "100%",
      height: "100%",
      ...props.style
    };
  }

  let closeButton = (
    <button type="button" className="close" onClick={() => props.closeModal()}>
      &times;
    </button>
  );

  return (
    <Modal
      backdrop={true}
      show={props.show}
      style={modalStyle}
      className="calendar-modal"
      {...props.hide && { onHide: props.hide }}
    >
      {props.closeButton && closeButton}
      {props.title && (
        <Modal.Header
          style={{
            backgroundColor: props.flag ? "#f2f2f2" : "white",
            color: props.program ? "brown" : "",
            borderBottom: props.program ? "1px solid white" : ""
          }}
        >
          <Modal.Title style={{ fontWeight: props.flag ? "bold" : "normal" }}>
            {props.title}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default calendarModal;
