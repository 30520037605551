import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";
import DatePicker from "../../UI/datePicker";
import axios from "../../../configAxios";
import dateFns from "date-fns";
import Button from "../../UI/button/Button";
import Input from "../../UI/input/Input";

class EditLoanPeriod extends Component {
  state = {
    startTimeHour: {
      elementType: "select",
      elementConfig: {
        name: "startTimeHour",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        isClearable: false
      },
      selectedOption: null
    },
    startTimeMinute: {
      elementType: "select",
      elementConfig: {
        name: "startTimeMinute",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        isClearable: false
      },
      selectedOption: null
    },
    endTimeHour: {
      elementType: "select",
      elementConfig: {
        name: "endTimeHour",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        isClearable: false
      },
      selectedOption: null
    },
    endTimeMinute: {
      elementType: "select",
      elementConfig: {
        name: "endTimeMinute",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        },
        isClearable: false
      },
      selectedOption: null
    },
    pickUpDate: null,
    dropOffDate: null,
    bookingId: null,
    isFacility: false,
    disabled: false
  };

  componentDidMount() {
    const { pickUpDate, dropOffDate, bookingId } = this.props;
    if (this.props.type === "Facility") {
      const startTimeHour = dateFns.format(pickUpDate, "HH");
      const startTimeMinute = dateFns.format(pickUpDate, "mm");
      const endTimeHour = dateFns.format(dropOffDate, "HH");
      const endTimeMinute = dateFns.format(dropOffDate, "mm");
      let hours = [];
      let minutes = [];
      for (let i = 0; i < 24; i++) {
        hours.push({ label: i, value: i });
      }
      for (let i = 0; i < 60; i++) {
        minutes.push({ label: i, value: i });
      }
      this.setState({
        startTimeHour: {
          ...this.state.startTimeHour,
          elementConfig: {
            ...this.state.startTimeHour.elementConfig,
            options: hours
          },
          selectedOption: { label: startTimeHour, value: startTimeHour }
        },
        startTimeMinute: {
          ...this.state.startTimeMinute,
          elementConfig: {
            ...this.state.startTimeMinute.elementConfig,
            options: minutes
          },
          selectedOption: { label: startTimeMinute, value: startTimeMinute }
        },
        endTimeHour: {
          ...this.state.endTimeHour,
          elementConfig: {
            ...this.state.endTimeHour.elementConfig,
            options: hours
          },
          selectedOption: { label: endTimeHour, value: endTimeHour }
        },
        endTimeMinute: {
          ...this.state.endTimeMinute,
          elementConfig: {
            ...this.state.endTimeMinute.elementConfig,
            options: minutes
          },
          selectedOption: {
            label: endTimeMinute,
            value: endTimeMinute
          }
        },
        isFacility: true
      });
    }

    this.setState({ pickUpDate, dropOffDate, bookingId });
  }

  updateLoanPeriod = e => {
    this.setState({ disabled: true })
    e.preventDefault();
    e.stopPropagation();
    const { pickUpDate, dropOffDate, startTimeHour, endTimeHour, startTimeMinute, endTimeMinute } = this.state;
    let formData = {
      ticket_id: this.props.ticketId,
      booking_ids: `[${this.state.bookingId}]`
    };

    const year = pickUpDate.getFullYear();
    const month = pickUpDate.getMonth() + 1;
    const day = pickUpDate.getDate();

    if (this.state.isFacility) {
      formData = {
        ...formData,
        date: {
          year, 
          month,  
          day 
        },  
        start_time: { 
          year, 
          month,  
          day,  
          hour: startTimeHour.selectedOption.value, 
          minute: startTimeMinute.selectedOption.value  
        },  
        end_time: { 
          year, 
          month,  
          day,  
          hour: endTimeHour.selectedOption.value, 
          minute: endTimeMinute.selectedOption.value  
        } 
      };  
    } else {
      formData = {
        ...formData,  
        start_date: { 
          year, 
          month,  
          day 
        },  
        end_date: { 
          year: dropOffDate.getFullYear(),  
          month: dropOffDate.getMonth() + 1,  
          day: dropOffDate.getDate()  
        } 
      };  
    }

    let startTime, endTime;
    if (this.state.isFacility) {
      startTime = new Date(month + "/" + day + "/" + year + " " + startTimeHour.selectedOption.value + ":" + startTimeMinute.selectedOption.value);
      endTime = new Date(month + "/" + day + "/" + year + " " + endTimeHour.selectedOption.value + ":" + endTimeMinute.selectedOption.value);
    }

    if (this.state.isFacility && (startTime > endTime)) {
      toast.error("The Booking Start date/time must be before the Booking End date/time");
      this.setState({ disabled: false });
    } else {
      axios
        .put("/bookings/update_loan_period_for", formData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Loan period updated Successfully");
            this.props.closeModal();
            this.props.getTicket();
          } else {
            if ('errors' in res.data.json) {
              Object.keys(res.data.json.errors).forEach(key => {
                toast.error(title(key) + " " + res.data.json.errors[key]);
              })
            }
            if ('error' in res.data.json) {
              toast.error(res.data.json.error);
            }
            this.setState({ disabled: false });
          }
        })
        .catch(err => console.log(err));
    }
  };

  inputChangedHandler = (event, key) => {
    this.setState({
      [key]: {
        ...this.state[key],
        selectedOption: event
      }
    });
  };

  render() {
    return (
      <div className="mt-3 booked-asset">
        {this.props.type !== "Facility" && <label>Pick Up</label>}
        <DatePicker
          selected={this.state.pickUpDate}
          changed={date => this.setState({ pickUpDate: date })}
        />
        {this.props.type !== "Facility" && (
          <div className="mt-3">
            <label>DropOff</label>
            <DatePicker
              selected={this.state.dropOffDate}
              changed={date => this.setState({ dropOffDate: date })}
            />
          </div>
        )}
        {this.props.type === "Facility" && (
          <>
            <div className="row mt-4">
              <div className="col-1">
                <label>From</label>
              </div>

              <div className="col">
                <Input
                  {...this.state.startTimeHour}
                  changed={event =>
                    this.inputChangedHandler(event, "startTimeHour")
                  }
                />
              </div>

              <div className="col">
                <Input
                  {...this.state.startTimeMinute}
                  changed={event =>
                    this.inputChangedHandler(event, "startTimeMinute")
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <label>To</label>
              </div>
              <div className="col">
                <Input
                  {...this.state.endTimeHour}
                  changed={event =>
                    this.inputChangedHandler(event, "endTimeHour")
                  }
                />
              </div>

              <div className="col">
                <Input
                  {...this.state.endTimeMinute}
                  changed={event =>
                    this.inputChangedHandler(event, "endTimeMinute")
                  }
                />
              </div>
            </div>
          </>
        )}
        <div className="d-flex justify-content-center my-4 mb-3">
          <Button
            type="success"
            className="mr-2"
            style={{ width: "80px" }}
            clicked={e => this.updateLoanPeriod(e)}
            disabled={this.state.disabled}
          >
            Save
          </Button>
          <Button
            type="delete"
            style={{ width: "80px" }}
            clicked={() => this.props.closeModal()}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default EditLoanPeriod;
