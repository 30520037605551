import React, { useEffect, useState } from "react"
import axios from "axios"
import { CSVLink } from "react-csv"

const CsvExport = ({ formId, goToForm, viewCompletedForm }) => {
  const [exportData, setExportData] = useState('')

  useEffect(() => {
    axios
      .get(`/application_forms/${formId}/export_csv`)
      .then((res) => {
        const { data } = res.data.json

        setExportData(data)
      })
      .catch((error) => {
        console.log(error);
      })
  }, [formId])

  return (
    <>
      <span
        className="mr-1 fas fa-eye cursor"
        data-toggle="tooltip"
        data-placement="top"
        title="View"
        onClick={() => goToForm(viewCompletedForm)}
      />
      {" "}
      <CSVLink
        data={exportData}
        filename={`form_responses.csv`}
      >
        <span
          className="ml-1 fas fa-file-download cursor"
          style={{ color: "DimGray" }}
          data-toggle="tooltip"
          data-placement="top"
          title="Export XLS"
        />
      </CSVLink>
    </>
  )
}

export default CsvExport
