import React from "react"

const DetailTableList = ({ children, title }) => {
  return (
    <div className="row mt-4">
      <div className="col-12 schedule-title">
        <h5>{title}</h5>
      </div>
      <div className="col-12 schedule-list">
        <ul className="list-group">
          {children}
        </ul>
      </div>
    </div>
  )
}

export default DetailTableList
