import React, { Component } from 'react'
import _ from "lodash"
import { components } from "react-select";

import Input from "../../UI/input/Input";
import PoweredByGoogleIcon from "../../../assests/images/powered_by_google_desktop.png"

class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.autocompleteStatusOK = null;
    this.autocompleteService = null;
    this.checkScriptTimeout = 0;
    this.state = {
      scriptLoaded: false,
      autocompleteSearch: {
        elementType: "asyncSelect",
        elementConfig: {
          name: "autocompleteSearch",
          placeholder: "",
          inputLength: 1,
          required: true,
          components: {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            MenuList: props => (
              <>
                <components.MenuList {...props}>{props.children}</components.MenuList>
                <div className="d-flex justify-content-end pr-1 pb-1"><img src={PoweredByGoogleIcon} alt="Powered By Google" /></div>
              </>
            ),
            Option: props => (
              <>
                <components.Option {...props}>
                  <div className="d-flex justify-content-start align-items-center">
                    <i className="fas fa-map-marker-alt mr-2" />
                    {props.children}
                  </div>
                </components.Option>
              </>
            )
          },
          loadOptions: (inputValue, callback) => {
            if (inputValue.length > 0)
              this.searchLocationHandler(inputValue, callback);
          },
        },
        value: null,
        selectedOption: null,
        label: this.props.label || "Location"
      }
    }
  }

  componentDidMount() {
    if (!window.google) {
      this.loadGoogleScript();
      this.updateScriptVar();
      this.checkScriptTimeout = setTimeout(this.updateScriptVar, 500);
    } else {
      this.updateScriptVar();
    }
    if (this.props.editMode && this.props.locationValue) {
      const { locationValue } = this.props;
      this.setState({
        autocompleteSearch: {
          ...this.state.autocompleteSearch,
          selectedValue: { label: locationValue, value: locationValue },
          value: { label: locationValue, value: locationValue }
        }
      })
    }
  }

  componentWillUnmount() {
    this.clearGoogleScriptTimeout();
  }

  loadGoogleScript = () => {
    if (!this.state.scriptLoaded) {
      const script = document.createElement("script");
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDedPXmZakbM9uMqAY75lHUdV-0DIM-4sQ&libraries=places";
      document.head.appendChild(script);
    }
  }

  clearGoogleScriptTimeout = () => {
    if (this.checkScriptTimeout) {
      clearTimeout(this.checkScriptTimeout);
    }
  }

  updateScriptVar = () => {
    if (window.google) {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.autocompleteStatusOK = window.google.maps.places.PlacesServiceStatus.OK;
      this.setState({ scriptLoaded: true },this.clearGoogleScriptTimeout);
    }
  }

  searchLocationHandler = (inputValue, callback) => {
    if (!this.autocompleteService || !this.state.scriptLoaded) {
      return [];
    }
    this.autocompleteService.getPlacePredictions({
      input: inputValue
    },
      (predictions, status) => {
        if (status !== this.autocompleteStatusOK) {
          callback([]);
        }
        else {
          callback(
            predictions.map(
              prediction => ({ label: prediction.description, value: prediction.place_id })
            )
          )
        }
      }
    )
  }

  optionChangeHandler = e => {
    this.setState({
      autocompleteSearch: {
        ...this.state.autocompleteSearch,
        value: !_.isEmpty(e) ? e : null,
        selectedOption: !_.isEmpty(e) ? e : null
      }
    }, () => this.props.changeLocation(e ? e.label : ""))
  }

  render() {
    return (
      <Input
        {...this.state.autocompleteSearch}
        chart={this.props.locationValue}
        changed={this.optionChangeHandler}
      />
    )
  }
}

export default LocationSearch
