import React from 'react';

export default function ApplicantData({ applicantData }) {
  const { name, email, organization_name } = applicantData

  return (
    <div className="mx-3 mb-4">
      <div className="d-flex flex-row  text-center w-100 border">
        {name &&
          <div className="my-3 flex-fill">
            <h5 className="mr-1">Name: </h5>
            <p className="">{name}</p>
          </div>
        }

        {organization_name &&
          <div className="my-3 flex-fill">
            <h5 className="mr-1">Organization Name: </h5>
            <p className="">{organization_name}</p>
          </div>
        }

        {email &&
          <div className="my-3 flex-fill">
            <h5 className="mr-1">Email: </h5>
              <a href={`mailto:${email}`}>{email}</a>
          </div>
        }
      </div>
    </div>
  )
}
