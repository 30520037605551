import React, { Component } from "react"
import { toast } from "react-toastify"
import { withRouter } from "react-router-dom"
import { title } from "change-case"
import { first, last, omit } from "lodash"


import FormButton from "../../UI/button/FormButton"
import Button from "../../UI/button/Button"
import axios from "../../../configAxios"
import Input from "../../UI/input/Input"

import attrFields from "../createAsset/attrFormFields"
import onError from "../../../utils/onError";

class Financial extends Component {
  constructor(props) {
    super(props);
    const { asset, accountingCodes } = this.props;

    let accountCodeDefault = {
      label: accountingCodes[0][1],
      value: accountingCodes[0][0]
    };
    if (asset.category_id) {
      accountCodeDefault = accountingCodes
        .map(([accountingCodeId, name, code]) => {
          if (accountingCodeId === this.props.asset.accounting_code_id)
            return { label: `${code} - ${name}`, value: accountingCodeId };
          return null;
        })
        .filter(item => item !== null)[0];
    }

    let assetPriceData = {};
    asset.price_types.forEach(element => {
      assetPriceData[element[1]] = {
        value: element[0].split("$")[1],
        id: element[2]
      };
    });
    this.state = {
      assetType: asset.type,
      assetName: asset.name,
      purchaseAmout: asset.purchase_amount,
      insuranceValue: asset.insurance_value,
      assetPriceData,
      gst: asset.is_gst_used || null,
      pst: asset.is_pst_used || null,
      hst: asset.is_hst_used || null,
      accountingCode: {
        elementType: "select",
        elementConfig: {
          name: "accountingCode",
          isClearable: false,
          options: accountingCodes.map(([accountingCodeId, name, code]) => {
            return { label: `${code} - ${name}`, value: accountingCodeId };
          }),
          defaultValue: accountCodeDefault,
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          }
        },
        selectedOption: accountCodeDefault
      },
      disabled: false
    };
  }

  inputChangeHandler = (event, key, type) => {
    if (type === "select") {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event
        }
      });
    } else if (type === "checkbox") {
      this.setState({ [key]: event });
    } else {
      this.setState({ [key]: event.target.value });
    }
  };

  assetPriceHandler = (event, key) => {
    this.setState({
      assetPriceData: {
        ...this.state.assetPriceData,
        [key]: {
          ...this.state.assetPriceData[key],
          value: event.target.value
        }
      }
    });
  };

  deletePriceTypeHandler = (assetPriceTypeId) => {
    const assetPrice = this.state.assetPriceData[assetPriceTypeId]

    axios
      .delete(`/asset_prices/${assetPrice.id}`)
      .then(() => {
        toast.success("Asset Price deleted successfully.")
        this.setState((prevState) => (
          { assetPriceData: omit(prevState.assetPriceData, assetPriceTypeId) }
        ))
      })
      .catch(err => {
        onError(err)
      })
  }


  submitConfirmedKey = (key, asset, assetData) => {
    const stateKey = attrFields.checkbox[key];
    if ((this.state[stateKey] !== null) && (this.state[stateKey] !== undefined) && (this.state[stateKey] !== asset[key])) {
      assetData.append(key, this.state[stateKey]);
    }
  }

  updateAssetHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const { asset } = this.props;

    let assetData = new FormData();

    assetData.append("name", this.state.assetName);

    assetData.append("type_used", this.state.assetType);

    this.state.purchaseAmout !== asset.purchase_amount &&
      assetData.append("purchase_amount", this.state.purchaseAmout);

    this.state.insuranceValue !== asset.insurance_value &&
      assetData.append("insurance_value", this.state.insuranceValue);

    this.state.accountingCode.selectedOption &&
      this.state.accountingCode.selectedOption.value !==
      asset.accounting_code_id &&
      assetData.append(
        "accounting_code_id",
        this.state.accountingCode.selectedOption.value
      );

    Object.keys(attrFields.checkbox).forEach(key => {
      this.submitConfirmedKey(key, asset, assetData)
    });

    let assetPrices = Object.keys(this.state.assetPriceData).map(key => {
      var obj = {
        asset_price_type_id: key,
        value: this.state.assetPriceData[key].value
      };
      if (this.state.assetPriceData[key])
        obj["id"] = this.state.assetPriceData[key].id;
      return obj;
    });

    assetPrices.length &&
      assetData.append(
        "asset_prices_attributes",
        `${JSON.stringify(assetPrices)}`
      );

    axios.put(`/assets/${asset.id}`, assetData).then(res => {
      if (res.data.json.success) {
        toast.success("Asset updated successfully.");
        this.props.history.push(`/assets/${asset.id}`);
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(title(error) + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
        this.setState({ disabled: false })
      }
    });
  };

  renderRow1 = () => {
    const rowObj = {
      purchaseAmout: "Purchase Amount",
      insuranceValue: "Insurance Value",
      accountingCode: "Accounting Code"
    };
    const form = Object.keys(rowObj).map(key => (
      <div key={key} className="col-md-4">
        <p>{rowObj[key]}</p>
        {key !== "accountingCode" ? (
          <Input
            elementType={"input"}
            elementConfig={{ type: "number", min: 0, max: 999999999, step: 0.01 }}
            value={this.state[key]}
            changed={event => this.inputChangeHandler(event, key, "text")}
          />
        ) : (
            <Input
              {...this.state[key]}
              changed={event =>
                this.inputChangeHandler(event, "accountingCode", "select")
              }
            />
          )}
      </div>
    ));
    return form;
  };

  renderAssetPriceTypes = () => {
    const { assetPriceData, assetType } = this.state

    return (
      this.props.assetPriceTypes?.map((type, index) => (
        <div className="col-md-4" key={index}>
          <p>{assetType === "Facility" ? `${last(type)} (per hour)` : `${last(type)} (per day)`}</p>
          <Input
            elementType={"input"}
            elementConfig={{ type: "number", min: 0, max: 999999999, step: 0.01 }}
            /* Input.js component has issues refreshing state when value is null or undefined.
             Because this in a numeric input field, we can use a band-aid solution to not display
             any values by setting the value to a string other than a parseable number. */
            value={assetPriceData[first(type)]?.value ?? 'null'}
            changed={event => this.assetPriceHandler(event, first(type))}
          />
          {assetPriceData[first(type)] && (
            <i
              className="fas fa-times float-right position-relative b-40 r-35 cursor"
              onClick={() => this.deletePriceTypeHandler(first(type))}
            />
          )}
        </div>
      ))
    );
  };

  renderCheckBoxes = () => {
    const checkboxes = ["GST", "PST", "HST"];
    return checkboxes.map(key => (
      <div className="col-md-1" key={key}>
        <div className="row">
          <div
            onClick={() =>
              this.inputChangeHandler(
                !this.state[key.toLowerCase()],
                key.toLowerCase(),
                "checkbox"
              )
            }
            className="custom-checkbox"
          >
            <div
              className="box"
              style={{
                backgroundColor: this.state[key.toLowerCase()]
                  ? "rgb(117,0,0)"
                  : "white"
              }}
            >
              <i className="fas fa-check check-icon" />
            </div>
            <p className="ml-3">{key}</p>
          </div>
        </div>
      </div>
    ));
  };

  render() {
    return (
      <div>
        {" "}
        <form onSubmit={this.updateAssetHandler}>
          <div className="row">{this.renderRow1()}</div>
          <div className="row">{this.renderAssetPriceTypes()}</div>
          <p>Taxes</p>
          <div className="row ml-2">{this.renderCheckBoxes()}</div>

          <div> </div>
          <div className="text-center mt-5 mb-4">
            <Button
              type="secondary"
              clicked={() =>
                this.props.history.push(`/assets/${this.props.asset.id}`)
              }
            >
              Cancel
            </Button>
            <FormButton
              className="ml-2"
              disabled={this.state.disabled}
            >
              Save
            </FormButton>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(Financial);
