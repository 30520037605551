import React from "react";
import { Link, withRouter } from "react-router-dom";

const PersonalInfo = props => {
  const { invoice } = props;
  let isForFloatingorRegistration = invoice.is_floating_invoice || invoice.is_for_drop_in_registration
  let name;
  let contact_name = null;
  if (invoice.is_for_user) {
    const { first_name, last_name } = invoice.user;
    const full_name = `${first_name ? first_name : ""} ${last_name ? last_name : ""}`;
    contact_name = invoice.organization.name && full_name
    name = invoice.user.organization_name ? invoice.user.organization_name : 
      full_name
  } else if (isForFloatingorRegistration) {
    const { floating_first_name, floating_last_name } = invoice;
    name = `${floating_first_name ? floating_first_name : ""} ${
      floating_last_name ? floating_last_name : ""
      }`;
  }
  return (
    <div className="invoice-detail">
      <div className="mt-3">
        {invoice.user ?
          <Link to={`/users/${invoice.user.id}/summary`} style={{ textDecoration: "none" }}>
            <h5 className="text-primary font-weight-bold cursor">{name}</h5>
          </Link>
          : <h5 className="text-primary font-weight-bold cursor">{name}</h5>
        }
        {contact_name && <div className="mt-2">
          <span className="text-dark">{contact_name}</span>
        </div>}
        {invoice.ticket && invoice.ticket.ticketable && invoice.ticket.ticketable.type === "Program"
          &&
          (<div>
            <Link to={`/programs/${invoice.ticket.ticketable.details.id}`} style={{ textDecoration: "none" }}>
              <h5 className="text-primary font-weight-bold cursor">{invoice.ticket.ticketable.details.name}</h5>
            </Link>
            <div className="cursor">
              <span className="text-muted">Program#: </span>
              <span className="text-dark">
                {invoice.ticket.ticketable.details.id}
              </span>
            </div>
          </div>)
        }
        {isForFloatingorRegistration && (
          <div>
            <span className="text-muted">Contact: </span>
            <span className="text-dark">
              {invoice.floating_contact_info
                ? invoice.floating_contact_info
                : "---"}
            </span>
          </div>
        )}
        {invoice.user && (
          <div>
            <div className="text-dark mt-2">{invoice.user.address1}</div>
            <div className="text-dark">{invoice.user.address2}</div>
            {invoice.user.city && <div className="mt-2">
              <span className="text-muted">City: </span>
              <span className="text-dark">
                {invoice.user.city}
              </span>
            </div>}
            {invoice.user.province && <div>
              <span className="text-muted">Province: </span>
              <span className="text-dark">
                {invoice.user.province ? invoice.user.province : "(none)"}
              </span>
            </div>}
            {invoice.user.postal_code && <div>
              <span className="text-muted">Postal Code: </span>
              <span className="text-dark">
                {invoice.user.postal_code}
              </span>
            </div>}
            {invoice.user.home_phone && <div>
              <span className="text-muted">Home: </span>
              <span className="text-dark">
                {invoice.user.home_phone}
              </span>
            </div>}
            {invoice.user.mobile_phone && <div>
              <span className="text-muted">Mobile: </span>
              <span className="text-dark">
                {invoice.user.mobile_phone}
              </span>
            </div>}
            {props.email && <div>
              <span className="text-muted">Email: </span>
              <span className="text-dark">
                {props.email}
              </span>
            </div>}
          </div>
        )}
        {invoice.notes && <div className="mt-2">
          <span className="text-muted">Notes: </span>
          <span className="text-dark">
            {invoice.notes}
          </span>
        </div>}
        {invoice.ticket && (
          <div>
            {invoice.ticket.get_project_name &&
              <div className="mt-2">
                <span className="text-muted">Project Name: </span>
                <span className="text-dark">
                  {invoice.ticket.get_project_name}
                </span>
              </div>}
            {invoice.ticket.notes && <div className="mt-2">
              <span className="text-muted">Ticket Notes: </span>
              <span className="text-dark">
                {invoice.ticket.notes}
              </span>
            </div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(PersonalInfo);