import React, { Component } from "react";
import { toast } from "react-toastify";
import dateFns from "date-fns";
import { withRouter } from "react-router-dom";
import { title } from "change-case"

import FormButton from "../../UI/button/FormButton";
import Button from "../../UI/button/Button";
import axios from "../../../configAxios";
import Input from "../../UI/input/Input";
import DatePicker from "../../UI/datePicker";

class Details extends Component {
  constructor(props) {
    super(props);
    const { asset } = this.props;
    let purchaseDate = 
      asset.purchase_date 
        ? new Date(asset.purchase_date)  
        : new Date();
    this.state = {
      assetType: asset.type,
      assetName: asset.name,
      purchaseDate,
      barcode: asset.barcode,
      bulkCount: asset.is_bulk_asset ? asset.bulk_asset.bulk_count_total : "",
      description: asset.description,
      includedAccessories: asset.included_accessories,
      warrantyInfo: asset.warranty_info,
      externalUrl: asset.external_url_resources,
      disabled: false
    };
  }

  inputChangeHandler = (event, key, type) => {
      this.setState({ [key]: event.target.value });
  };

  updateAssetHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const { asset } = this.props;

    const {
      purchaseDate,
      barcode,
      description,
      includedAccessories,
      warrantyInfo,
      assetName,
      assetType,
      externalUrl,
      bulkCount
    } = this.state;

    let assetData = new FormData();

    assetData.append("name", assetName);

    assetData.append("type_used", assetType);

    let currentDate = dateFns.format(purchaseDate, "DD-MM-YYYY");
    let prevDate = dateFns.format(asset.purchase_date, "DD-MM-YYYY");

    currentDate !== prevDate && assetData.append("purchase_date", currentDate);

    if(asset.type !== "Facility") {
      if(bulkCount > 1){
        bulkCount !== asset.bulk_asset.bulk_count_total && assetData.append("bulk_count_total", bulkCount);
        console.log("bulkCount", bulkCount)
      }
      if (bulkCount <= 1){
        assetData.append("bulk_count_total", "");
        barcode !== asset.barcode && assetData.append("barcode", barcode);
        console.log("barcode", barcode);
      }
    }
    
    externalUrl !== asset.external_url_resources &&
    assetData.append("external_url_resources", externalUrl);
    
    description !== asset.description &&
      assetData.append("description", description);

    includedAccessories !== asset.included_accessories &&
      assetData.append("included_accessories", includedAccessories);

    warrantyInfo !== asset.warranty_info &&
      assetData.append("warranty_info", warrantyInfo);

    axios.put(`/assets/${asset.id}`, assetData).then(res => {
      if (res.data.json.success) {
        toast.success("Asset updated successfully.");
        this.props.history.push(`/assets/${asset.id}`);
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(title(error) + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
        this.setState({ disabled: false })
      }
    });
  };

  renderTextAreas = () => {
    const rowObj = {
      description: "Description",
      includedAccessories: "Included Accessories",
      warrantyInfo: "Warranty Information"
    };
    const form = Object.keys(rowObj).map(key => (
      <div key={key}>
        <p>{rowObj[key]}</p>
        <Input
          elementType={"textarea"}
          elementConfig={{ rows: "4" }}
          value={this.state[key]}
          changed={event => this.inputChangeHandler(event, key, "text")}
        />
      </div>
    ));
    return form;
  };

  render() {
    const { asset } = this.props;
    return (
      <div>
        {" "}
        <form onSubmit={this.updateAssetHandler}>
          <div className="row">
            <div className="col-md">
              <p>Purchase Date</p>
              <DatePicker
                selected={this.state.purchaseDate}
                changed={purchaseDate => this.setState({ purchaseDate })}
              />
            </div>
            { asset.type !== "Facility" && 
            <>
              <div className="col-md">
                <p>Bulk Asset (# of Units)</p>
                <Input
                  elementType={"input"}
                  value={this.state.bulkCount}
                  changed={event =>
                  this.inputChangeHandler(event, "bulkCount", "text")
                  }
                />
              </div>
              
                <div className="col-md">
                <p>Barcode/ISBN</p>
                <Input
                  elementType={"input"}
                  value={this.state.barcode}
                  changed={event =>
                    this.inputChangeHandler(event, "barcode", "text")
                  }
                  disabled = {this.state.bulkCount > 1}
                  style={{ 
                    cursor: this.state.bulkCount > 1 
                    ? "no-drop" : ""   
                  }}
                />
                </div>
              
            </>
            }
            <div className="col-md">
              <p>External Url Resources</p>
              <Input
                elementType={"input"}
                value={this.state.externalUrl}
                changed={event =>
                  this.inputChangeHandler(event, "externalUrl", "text")
                }
              />
            </div>
          </div>
          <div>{this.renderTextAreas()}</div>
          <div className="text-center mt-5 mb-4">
            <Button
              type="secondary"
              clicked={() =>
                this.props.history.push(`/assets/${this.props.asset.id}`)
              }
            >
              Cancel
            </Button>
            <FormButton
              className="ml-2"
              disabled={this.state.disabled}
            >
              Save
            </FormButton>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(Details);
