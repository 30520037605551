import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"

import Spinner from "../../UI/Spinner";
import Button from "../../UI/button/Button"
import axios from "../../../configAxios"

class UnauthPayStatus extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source()
    this.state = {
      loading: true,
      paymentMessage: "",
      helcimMessage: "",
      paymentStatus: null
    }
  }

  componentDidMount() {
    const { location } = this.props;
    const paymentMssgError = "Your payment could not be completed, if you were charged please wait for 12-24 hours for us to check with our payment provider. else please try again";
    const paymentMssgSuccess = "Your payment was completed successfully"
    if (!(location.state && location.state.fromPaymentPage)) {
      window.location.href = "/error/404"
    } else {
      const { paymentSuccess, response, responseMessage, transactionId, invoiceId } = location.state;
      if (paymentSuccess) {
        let data = {
          responseMessage,
          response,
          transactionId,
          id: invoiceId
        };
        axios.post("/invoices/verify_online_payment", data , {
          cancelToken: this.signal.token
        }).then(res => {
          let paymentMessage = "", paymentStatus = false;
          if (res.data.json.success) {
            paymentMessage = paymentMssgSuccess;
            paymentStatus = true
          } else {
            toast.error(res.data.json.message);
            paymentMessage = paymentMssgError;
          }
          this.setState({
            paymentMessage,
            paymentStatus,
            loading: false
          })
        });
      } else {
        this.setState({
          paymentMessage: paymentMssgError,
          helcimMessage: responseMessage,
          paymentStatus: false,
          loading: false
        })
      }
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being cancelled");
  }

  redirectToHomePage = () => {
    this.props.history.push({
      pathname: "/",
      state: {}
    })
  }

  redirectToInvoicePage = () => {
    this.props.history.push({
      pathname: `/invoices/${this.props.location.state.invoiceId}/online_payment`,
      state: {}
    })
  }

  render() {
    return (
      <>
        {this.state.loading 
          ? <Spinner /> : 
          (<>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div 
                    style={{ 
                      boxShadow: `inset 0 0 4px ${
                        this.state.paymentStatus
                          ? "green"
                          : "red"
                      }` 
                    }} 
                    className="jumbotron text-center"
                  >
                    <h3>
                      Payment {this.state.paymentStatus ? " Success" : " Error" }
                    </h3>
                    {
                      this.state.helcimMessage
                        ? <p className="font-weight-bold">{this.state.helcimMessage}</p>
                        : null
                    }
                    <p>{this.state.paymentMessage}</p>
                    {!this.state.paymentStatus &&
                    <Button type="secondary" clicked={this.redirectToInvoicePage} className="ml-3">
                      Try Again
                    </Button>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="container w-100">
              <div className="row">
                <div className="col-12 text-center my-5 py-5">
                  {`Powered By AMS Network © ${new Date().getFullYear()}`}
                </div>
              </div>
            </div>
          </>)
        }
      </>
    )
  }
}

export default withRouter(UnauthPayStatus)
