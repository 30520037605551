import React from 'react'
import SelectType, { inputTypeOptions } from './SelectType'
import SingleQuestion from './SingleQuestion'
import MultiQuestion from './MultiQuestion'
import ApplicantDataQuestion from "./ApplicantDataQuestion"

function ListItem({
                    question,
                    dragging,
                    submitNewQuestion,
                    updateQuestion,
                    updateQuestionState,
                    deleteQuestion
                  }) {

  const updateQuestionField = (questionData) => {
    if (!dragging) {
      updateQuestionState({ ...question, ...questionData })
    }
  }

  const QuestionContent = () => {

    const isMultiQuestion = (question.type === "MultipleChoiceQuestion" || question.type === "DropdownQuestion")
    const isApplicantData = question.type === "ApplicantDataQuestion"
    const isSingleQuestion = !isMultiQuestion && !isApplicantData

    return (
      <div>
        {isSingleQuestion &&
        <SingleQuestion question={question} submitNewQuestion={submitNewQuestion} updateQuestion={updateQuestion}
                        updateQuestionState={updateQuestionState} dragging={dragging}/>
        }

        {isApplicantData &&
        <ApplicantDataQuestion question={question} submitNewQuestion={submitNewQuestion} updateQuestion={updateQuestion}
                               updateQuestionState={updateQuestionState}/>
        }

        {isMultiQuestion &&
        <MultiQuestion question={question} submitNewQuestion={submitNewQuestion} updateQuestion={updateQuestion}
                       updateQuestionState={updateQuestionState}/>
        }
      </div>
    )
  }

  const questionLabel = inputTypeOptions.find((o) => o.value === question.type)
  
  return (
    <div className="d-flex flex-column justify-content-between list-group-item mb-3">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <span>{question.type ? questionLabel.label : ""}</span>
        <span className="cursor fas fa-sm fa-times mb-1" onClick={() => deleteQuestion(question)}/>
      </div>
      <div>
        {question.type ?
          <QuestionContent/>
          :
          <SelectType
            updateQuestionType={(selectedType) => updateQuestionField({ type: selectedType.value })}
          />
        }
      </div>
    </div>
  )
}

export default ListItem
