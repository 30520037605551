import React, { Component } from "react";
import Input from "../UI/input/Input";
import DatePicker from "../UI/datePicker/index";
import Button from "../UI/button/Button";
import axios from "../../configAxios";
import { title } from "change-case";
import { Link, withRouter } from "react-router-dom";
import FormButton from "../UI/button/FormButton";
import dateFns from "date-fns";
import "./NewOrEdit.css";
import { toast } from "react-toastify";
import Spinner from "../UI/Spinner";

class AddOrEditPrint extends Component {
  signal = axios.CancelToken.source();
  state = {
    call: null,
    title: null,
    authors: null,
    bulkCount: null,
    isbn: null,
    barcode: null,
    pages: null,
    volume: null,
    publisher: null,
    city: null,
    yearPublished: null,
    edition: null,
    series: null,
    genre: null,
    description: null,
    tags: null,
    rating: null,
    myRating: null,
    languages: null,
    contributersName: {
      elementType: "input",
      elementConfig: {
        name: "contributersName",
        type: "text",
        placeholder: "Contributor Name"
      }
    },
    type: {
      elementType: "select",
      elementConfig: {
        name: "type",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Type of print"
    },
    condition: {
      elementType: "select",
      elementConfig: {
        name: "condition",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    oversize: {
      elementType: "select",
      elementConfig: {
        name: "oversize",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Oversize"
    },
    anthology: {
      elementType: "select",
      elementConfig: {
        name: "anthology",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Anthology"
    },
    bindingType: {
      elementType: "select",
      elementConfig: {
        name: "bindingType",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null,
      label: "Binding type"
    },
    QASMember: {
      elementType: "select",
      elementConfig: {
        name: "QASMember",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    reference: {
      elementType: "select",
      elementConfig: {
        name: "reference",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    matureContent: {
      elementType: "select",
      elementConfig: {
        name: "matureContent",
        options: [],
        required: true,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: null
    },
    contributersType: {
      elementType: "select",
      elementConfig: {
        name: "contributersType",
        options: [],
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      label: "Contributers"
    },
    lendingDueDate: null,
    artwork: null,
    contributers: [{ contributerType: null, name: "", id: "" }],
    loading: true,
    disabled: false
  };

  async componentDidMount() {
    const {
      type,
      condition,
      bindingType,
      contributersType,
      oversize,
      anthology,
      QASMember,
      reference,
      matureContent
    } = this.state;
    const res = await axios.get("/assets/formdata", { cancelToken: this.signal.token });
    const resData = res.data.json;
    const printTypes = resData.print_types;
    const printCondition = resData.print_conditions;
    const bindingTypes = resData.binding_type;
    const contributerTypes = resData.contributer_types;
    const yesNoArray = ["Yes", "No"];
    this.setState({
      type: {
        ...type,
        elementConfig: {
          ...type.elementConfig,
          options: printTypes.map((type, index) => {
            return {
              value: index,
              label: type
            };
          })
        }
      },
      condition: {
        ...condition,
        elementConfig: {
          ...condition.elementConfig,
          options: printCondition.map((condition, index) => {
            return { value: index, label: condition };
          })
        }
      },
      oversize: {
        ...oversize,
        elementConfig: {
          ...oversize.elementConfig,
          options: yesNoArray.map((value, index) => {
            return { value: index, label: value };
          })
        }
      },
      anthology: {
        ...anthology,
        elementConfig: {
          ...anthology.elementConfig,
          options: yesNoArray.map((value, index) => {
            return { value: index, label: value };
          })
        }
      },
      bindingType: {
        ...bindingType,
        elementConfig: {
          ...bindingType.elementConfig,
          options: bindingTypes.map((type, index) => {
            return { value: index, label: type };
          })
        }
      },
      QASMember: {
        ...QASMember,
        elementConfig: {
          ...QASMember.elementConfig,
          options: yesNoArray.map((value, index) => {
            return { value: index, label: value };
          })
        }
      },
      reference: {
        ...reference,
        elementConfig: {
          ...reference.elementConfig,
          options: yesNoArray.map((value, index) => {
            return { value: index, label: value };
          })
        }
      },
      matureContent: {
        ...matureContent,
        elementConfig: {
          ...matureContent.elementConfig,
          options: yesNoArray.map((value, index) => {
            return { value: index, label: value };
          })
        }
      },
      contributersType: {
        ...contributersType,
        elementConfig: {
          ...contributersType.elementConfig,
          options: contributerTypes.map((type, index) => {
            return { value: index, label: type };
          })
        }
      },
      loading: this.props.location.pathname.includes("edit") ? true : false
    });

    if (this.props.location.pathname.includes("edit")) {
      const id = this.props.match.params.id;
      const res = await axios.get(`/assets/${id}`, { cancelToken: this.signal.token })
      const asset = res.data.asset_detail;
      let contributers = [{ contributerType: null, name: "" }];
      if (asset.asset_contributers.length) {
        contributers = asset.asset_contributers.map(contributer => {
          return {
            contributerType: {
              label: contributer.contributer_type,
              value: contributer.contributer_type
            },
            name: contributer.name,
            id: contributer.id
          };
        });
      }
      this.setState({
        call: asset.parameters.call_number,
        title: asset.name,
        authors: asset.parameters.authors,
        type: {
          ...this.state.type,
          selectedOption: asset.parameters.type_of_print && {
            label: asset.parameters.type_of_print,
            value: asset.parameters.type_of_print
          }
        },
        bulkCount: asset.bulk_asset.bulk_count_total,
        condition: {
          ...this.state.condition,
          selectedOption: {
            label: asset.parameters.condition,
            value: asset.parameters.condition
          }
        },
        isbn: asset.parameters.isbn_number,
        barcode: asset.barcode,
        oversize: {
          ...this.state.oversize,
          selectedOption: asset.parameters.oversize && {
            label: asset.parameters.oversize,
            value: asset.parameters.oversize
          }
        },
        anthology: {
          ...this.state.anthology,
          selectedOption: asset.parameters.anthology && {
            label: asset.parameters.anthology,
            value: asset.parameters.anthology
          }
        },
        pages: asset.parameters.pages,
        volume: asset.parameters.volume,
        publisher: asset.parameters.publisher,
        city: asset.parameters.city_published,
        yearPublished: asset.parameters.year_of_publishing,
        edition: asset.parameters.edition,
        bindingType: {
          ...this.state.bindingType,
          selectedOption: asset.parameters.binding_type && {
            label: asset.parameters.binding_type,
            value: asset.parameters.binding_type
          }
        },
        series: asset.parameters.series,
        genre: asset.parameters.genre,
        description: asset.description,
        tags: asset.parameters.tags,
        rating: asset.parameters.rating,
        myRating: asset.parameters.my_rating,
        languages: asset.parameters.languages,
        lendingDueDate: asset.parameters.lending_due_date ?
          new Date(asset.parameters.lending_due_date) : asset.parameters.lending_due_date,
        QASMember: {
          ...this.state.QASMember,
          selectedOption: asset.parameters.qas_member && {
            label: asset.parameters.qas_member,
            value: asset.parameters.qas_member
          }
        },
        reference: {
          ...this.state.reference,
          selectedOption: {
            label: asset.parameters.reference,
            value: asset.parameters.reference
          }
        },
        matureContent: {
          ...this.state.matureContent,
          selectedOption: {
            label: asset.parameters.mature_content,
            value: asset.parameters.mature_content
          }
        },
        contributers,
        loading: false
      });
    }
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  addContributerFields = () => {
    this.setState(prevState => ({
      contributers: [
        ...prevState.contributers,
        { contributerType: null, name: "" }
      ]
    }));
  };

  deleteContributerFields = (index, id) => {
    let response = window.confirm("Are you sure you want to delete?");
    if (response) {
      const { contributers } = { ...this.state };
      if (id) {
        axios
          .delete("/asset_contributers", {
            params: {
              id: id
            }
          })
          .then(res => {
            if (res.data.json.success) {
              toast.success("Contributor deleted successfully.");
            } else {
              if (res.data.json.hasOwnProperty("errors")) {
                Object.keys(res.data.json.errors).forEach(error => {
                  toast.error(title(error) + " " + res.data.json.errors[error]);
                });
              }
              if (res.data.json.hasOwnProperty("error")) {
                toast.error(res.data.json.error);
              }
            }
          });
      }
      contributers.splice(index, 1);
      this.setState({ contributers });
    }
  };

  changeHandler = (event, i, key) => {
    let inputValue = {};
    let { contributers } = this.state;
    if (key === "contributersType") {
      inputValue["selectedOption"] = event;
      contributers[i].contributerType = event;
    } else {
      inputValue["value"] = event.target.value;
      contributers[i].name = event.target.value;
    }
    const updatedControls = {
      ...this.state[key],
      ...inputValue
    };

    this.setState({ [key]: updatedControls, contributers });
  };

  inputChangeHandler = (event, key, type) => {
    if (type === "file") {
      this.setState({ artwork: event.target.files[0] });
    } else if (type === "select") {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event
        }
      });
    } else if (type === "creatableSelect") {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event
        }
      });
    }
  };

  renderTextbox = (label, name, placeholder) => {
    return (
      <>
        <p
          className={
            name === "title" ? "new-type-label required" : "new-type-label"
          }
        >
          <b>{label} </b>
        </p>
        <Input
          elementType={"input"}
          elementConfig={{
            required: name === "title" ? true : false,
            type:
              name === "bulkCount" ||
                name === "yearPublished" ||
                name === "pages"
                ? "number"
                : "text",
            placeholder: placeholder
          }}
          value={this.state[name]}
          changed={event => this.setState({ [name]: event.target.value })}
        />
      </>
    );
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let formData = new FormData();
    const { contributers } = this.state;
    const lendingDueDate = this.state.lendingDueDate
      ? dateFns.format(new Date(this.state.lendingDueDate), "YYYY-MM-DD")
      : "";

    const asset_contributers_attributes = contributers
      .map(contributer => {
        if (contributer.contributerType !== null && contributer.name !== "") {
          const contributerData = {
            contributer_type:
              (contributer.contributerType !== null ||
                contributer.contributerType !== "") &&
                contributer.name !== ""
                ? contributer.contributerType.label
                : "",
            name:
              contributer.contributerType === null ||
                contributer.contributerType === ""
                ? ""
                : contributer.name
          };
          if (contributer.id !== "") {
            contributerData["id"] = contributer.id;
          }
          return JSON.stringify(contributerData);
        } else {
          return null;
        }
      })
      .filter(item => item !== null);
    formData.append(["status"], 0);
    formData.append(["name"], this.state.title);
    this.state.call && formData.append(["call_number"], this.state.call);
    this.state.authors && formData.append(["authors"], this.state.authors);
    formData.append(
      ["bulk_count_total"],
      this.state.bulkCount ? parseInt(this.state.bulkCount) : ""
    );
    this.state.type.selectedOption &&
      formData.append(["type_of_print"], this.state.type.selectedOption.label);
    this.state.condition.selectedOption &&
      formData.append(["condition"], this.state.condition.selectedOption.label);
    this.state.isbn && formData.append(["isbn_number"], this.state.isbn);
    this.state.barcode && formData.append(["barcode"], this.state.barcode);
    this.state.oversize.selectedOption &&
      formData.append(["oversize"], this.state.oversize.selectedOption.label);
    this.state.anthology.selectedOption &&
      formData.append(["anthology"], this.state.anthology.selectedOption.label);
    this.state.pages && formData.append(["pages"], this.state.pages);
    this.state.volume && formData.append(["volume"], this.state.volume);
    this.state.publisher &&
      formData.append(["publisher"], this.state.publisher);
    this.state.city && formData.append(["city_published"], this.state.city);
    this.state.yearPublished &&
      formData.append(["year_of_publishing"], this.state.yearPublished);
    this.state.edition && formData.append(["edition"], this.state.edition);
    this.state.bindingType.selectedOption &&
      formData.append(
        ["binding_type"],
        this.state.bindingType.selectedOption.label
      );
    this.state.artwork && formData.append(["photo"], this.state.artwork);
    this.state.series && formData.append(["series"], this.state.series);
    this.state.genre && formData.append(["genre"], this.state.genre);
    this.state.description &&
      formData.append(["description"], this.state.description);
    this.state.tags && formData.append(["tags"], this.state.tags);
    this.state.rating && formData.append(["rating"], this.state.rating);
    this.state.myRating && formData.append(["my_rating"], this.state.myRating);
    this.state.languages &&
      formData.append(["languages"], this.state.languages);
    formData.append(["lending_due_date"], lendingDueDate);
    this.state.QASMember.selectedOption &&
      formData.append(
        ["qas_member"],
        this.state.QASMember.selectedOption.label
      );
    this.state.reference.selectedOption &&
      formData.append(["reference"], this.state.reference.selectedOption.label);
    this.state.matureContent.selectedOption &&
      formData.append(
        ["mature_content"],

        this.state.matureContent.selectedOption.label
      );
    asset_contributers_attributes.length &&
      formData.append(
        ["asset_contributers_attributes"],
        `[${asset_contributers_attributes}]`
      );
    formData.append(["type_used"], "Print");

    if (this.props.location.pathname.includes("edit")) {
      axios.put(`/assets/${this.props.match.params.id}`, formData).then(res => {
        if (res.data.json.success) {
          toast.success("Print successfully updated");
          this.props.history.push(`/assets/${this.props.match.params.id}`);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    } else {
      axios.post("/assets", formData).then(res => {
        if (res.data.json.success) {
          toast.success("Print successfully created");
          this.props.history.push(`/libraries/all_media`);
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(title(error) + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
          this.setState({ disabled: false })
        }
      });
    }
  };

  render() {
    return (
      <div className="card mt-4 mb-4 new-library-type">
        {this.state.loading ? (
          <Spinner />
        ) : (
            <form onSubmit={this.submitHandler}>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Call #", "call")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Title", "title")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Author(s)", "authors")}
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.type}
                    changed={event =>
                      this.inputChangeHandler(event, "type", "select")
                    }
                  />
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between text-nowrap">
                <div className="col-md input-fields">
                  {this.renderTextbox("# of Copies (if more than one)", "bulkCount")}
                </div>
                <div className="col-md input-fields condition">
                  <label className="required">Condition </label>
                  <Input
                    {...this.state.condition}
                    changed={event =>
                      this.inputChangeHandler(event, "condition", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("ISBN #", "isbn")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Barcode", "barcode")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <Input
                    {...this.state.oversize}
                    changed={event =>
                      this.inputChangeHandler(event, "oversize", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <Input
                    {...this.state.anthology}
                    changed={event =>
                      this.inputChangeHandler(event, "anthology", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Pages", "pages")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Volume", "volume")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Publisher", "publisher")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("City", "city")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Year Published", "yearPublished")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Edition", "edition")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  <Input
                    {...this.state.bindingType}
                    changed={event =>
                      this.inputChangeHandler(event, "bindingType", "select")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  <label>Upload a Thumbnail/Artwork</label>
                  <Input
                    elementType={"file"}
                    value={this.state.artwork}
                    changed={event =>
                      this.inputChangeHandler(event, "artwork", "file")
                    }
                  />
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Series", "series")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Genre", "genre")}
                </div>
              </div>
              <div className="row m-0 mt-4 justify-content-between">
                <div className="col-md input-fields">
                  {this.renderTextbox("Description/Notes", "description")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Tags", "tags")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("Rating", "rating")}
                </div>
                <div className="col-md input-fields">
                  {this.renderTextbox("My rating", "myRating")}
                </div>
              </div>
              <div className="row m-0 mt-4">
                <div className="col-md-3 input-fields">
                  {this.renderTextbox("Languages", "languages")}
                </div>
                <div className="col-md-3 input-fields">
                  <p className="mb-2">
                    <b>Lending due date</b>
                  </p>
                  <DatePicker
                    selected={this.state.lendingDueDate}
                    changed={date =>
                      this.setState({
                        lendingDueDate: date
                      })
                    }
                  />
                </div>
              </div>
              <div className="row m-0 mt-4">
                <div className="col-md-3 input-fields">
                  <label>QAS Member? </label>
                  <Input
                    {...this.state.QASMember}
                    changed={event =>
                      this.inputChangeHandler(event, "QASMember", "select")
                    }
                  />
                </div>
                <div className="col-md-3 input-fields">
                  <label className="required">Reference? </label>
                  <Input
                    {...this.state.reference}
                    changed={event =>
                      this.inputChangeHandler(event, "reference", "select")
                    }
                  />
                </div>
                <div className="col-md-3 input-fields">
                  <label className="required">Mature Content? </label>
                  <Input
                    {...this.state.matureContent}
                    changed={event =>
                      this.inputChangeHandler(event, "matureContent", "select")
                    }
                  />
                </div>
              </div>
              <div className="contributers  mt-4" id="contributer">
                {this.state.contributers.map((val, index) => {
                  return (
                    <li className="row m-0" key={index}>
                      <div className="col-md-3 input-fields">
                        <Input
                          {...this.state.contributersType}
                          selectedOption={val.contributerType || null}
                          changed={event =>
                            this.changeHandler(event, index, "contributersType")
                          }
                        />
                      </div>
                      <div className="col-md-3 input-fields contributer-name">
                        <Input
                          {...this.state.contributersName}
                          value={val.name || ""}
                          changed={event =>
                            this.changeHandler(event, index, "contributersName")
                          }
                        />
                      </div>
                      <input type="hidden" name="contributerId" value={val.id} />
                      <i
                        className="fas fa-trash-alt library-delete"
                        onClick={() =>
                          this.deleteContributerFields(index, val.id)
                        }
                      />
                    </li>
                  );
                })}
              </div>
              <div className="row m-0 mt-4 ml-4 mb-3 add-button">
                <Button type="success" clicked={this.addContributerFields}>
                  Add
              </Button>
              </div>
              <div className="row m-0 mt-0 mb-5 justify-content-center">
                <Link to={`/libraries/${this.props.type}?type=${this.props.type}`}>
                  <Button type="secondary" className="mr-3">
                    Cancel
                </Button>
                </Link>
                <FormButton className="submit" disabled={this.state.disabled}>Submit</FormButton>
              </div>
            </form>
          )}
      </div>
    );
  }
}

export default withRouter(AddOrEditPrint);
