import React, { Component } from 'react'
import { Link, withRouter } from "react-router-dom"
import is from "is_js"

import Pagination from "../UI/Pagination";
import SearchFilter from "../common/searchFilter/SearchFilter"
import Modal from "../UI/Modal"
import Spinner from "../UI/Spinner"
import axios from "../../configAxios"

import ShowDonationInfo from "./ShowDonationInfo";
import ShowCampaignInfo from "./ShowCampaignInfo"

class Donors extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      loading: true,
      donations: [],
      forcePage: 0,
      totalCount: 0,
      perPage: 10,
      showDonation: false,
      donationDetails: null,
      showCampaignInfo: false,
      campaignInfo: null
    }
  }

  componentDidMount = () => {
    this.getDonationDetails();
  }

  componentWillUnmount = () => {
    this.signal.cancel("Request is being canceled")
  }

  getDonationDetails = page => {
    const forPageChange = page && page.hasOwnProperty("selected");

    if (forPageChange)
      window.scrollTo(0, 0);

    this.setState({
      forcePage: forPageChange ? page.selected : 0,
      donations: [],
      loading: true
    })

    const attrValue = {
      params: {
        per_page: this.state.perPage,
        ...forPageChange && { page: page.selected + 1 }
      },
      cancelToken: this.signal.token
    };

    axios.get("/donations", attrValue)
      .then(res => {
        this.setState({
          loading: false,
          donations: res.data && res.data.donations
            ? res.data.donations
            : [],
          totalCount: res.data && res.data.meta && res.data.meta.total
            ? res.data.meta.total
            : 0
        })
      })
      .catch(err => err)
  }

  pageFilterHandler = perPage => this.setState({ perPage }, () => this.getDonationDetails())

  render() {
    const {
      donations,
      donationDetails,
      loading,
      forcePage,
      totalCount,
      perPage
    } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="h3">
              Donations
            </div>
          </div>
          <div className="col-6 text-right">
            <span
              className="cursor"
              onClick={e => {
                if (is.firefox())
                  this.props.history.push("/donations")
                else
                  this.props.history.goBack()
              }}
            >
              {"<< Back"}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="bg-white pt-3">
              <div className="row">
                <div className="offset-sm-8 col-sm-4">
                  <SearchFilter
                    perPage={perPage}
                    inputChangeHandler={this.pageFilterHandler}
                  />
                </div>
              </div>
              {loading
                ? <Spinner /> :
                (donations && donations.length > 0 ?
                  <>
                    <div className="table-responsive-lg p-0">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="border-top-0" scope="col">Donor</th>
                            <th className="border-top-0 text-center" scope="col">Campaign</th>
                            <th className="border-top-0 text-center" scope="col">Amount</th>
                            <th className="border-top-0 text-center" scope="col">Thanked</th>
                            <th className="border-top-0 text-center" scope="col">Info</th>
                          </tr>
                        </thead>
                        <tbody>
                          {donations.map(donation => (
                            <tr key={donation.id}>
                              <td className="py-3">
                                {
                                  donation.donor ?
                                    <Link to={`/users/${donation.donor.id}/summary`} className="text-secondary">
                                      <span>
                                        {donation.donor.first_name ? donation.donor.first_name : ""}
                                      </span>
                                      {" "}
                                      <span>
                                        {donation.donor.last_name ? donation.donor.last_name : ""}
                                      </span>
                                    </Link>
                                    : "---"}
                              </td>
                              <td className="py-3 text-center">
                                <span
                                  className="cursor text-underline-onhover"
                                  onClick={() => this.setState({
                                    showCampaignInfo: true,
                                    campaignInfo: donation.donation_campaign
                                  })}
                                >
                                  {donation.donation_campaign.name}
                                </span>
                              </td>
                              <td className="py-3 text-center">
                                ${donation.amount ? donation.amount : "0.00"}
                              </td>
                              <td className="py-3 text-center">{donation.has_thanked ? "Yes" : "No"}</td>
                              <td className="py-3 text-center">
                                <i
                                  className="fas fa-info-circle cursor"
                                  onClick={e => this.setState({
                                    showDonation: true,
                                    donationDetails: donation
                                  })}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      forcePage={forcePage}
                      pageCount={totalCount / perPage}
                      handlePageClick={this.getDonationDetails}
                    />
                  </>
                  : <div className="p-5 text-center">No Donations</div>)
              }
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showDonation}
          closeButton
          closeModal={e => this.setState({ showDonation: false })}
          hide={() => this.setState({ showDonation: false })}
          title="Donation Details"
        >
          <ShowDonationInfo
            donationDetails={donationDetails}
            closeModalHandler={() => this.setState({ showDonation: false })}
          />
        </Modal>
        <Modal
          show={this.state.showCampaignInfo}
          closeButton
          closeModal={e => this.setState({ showCampaignInfo: false })}
          hide={() => this.setState({ showCampaignInfo: false })}
          title="Campaign Details"
        >
          <ShowCampaignInfo showCampainDetails={this.state.campaignInfo} />
        </Modal>
      </div>
    )
  }
}

export default withRouter(Donors)
