import React, { Component } from "react"
import { connect } from "react-redux"
import { setLoginUrl, toggleLoginButton } from "../../../../store/actions"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"

import axios from "../../../../configAxios"
import errorHandler from "../../../errorPages/UserNotAuthorized"
import Input from "../../../UI/input/Input"
import FormButton from "../../../UI/button/FormButton"
import Spinner from "../../../UI/Spinner"
import ToggleSwitch from "../../../common/toggleSwitch"
import isValidUrl from "../../../../utils/isValidUrl"

class Settings extends Component {
  constructor(props) {
    super(props)
    this.signal = axios.CancelToken.source()
    this.timeout = 0
    this.state = {
      hideMemberType: false,
      allowUserEditContactInformation: false,
      allowProjectAccessToMembers: false,
      allowProfileAccessToMembers: false,
      allowFrontEndUser: false,
      disabled: false,
      loading: true,
      defaultMemberTypes: {
        elementType: "select",
        elementConfig: {
          name: "defaultMemberTypes",
          type: "text",
          options: [],
        },
        label: "Member Types",
        selectedOption: null,
        style: { minWidth: "100%", maxWidth: "100%" },
      },
      defaultRoles: {
        elementType: "select",
        elementConfig: {
          name: "defaultRoles",
          type: "text",
          options: [],
          required: true,
        },
        label: "Role",
        selectedOption: null,
        style: { minWidth: "100%", maxWidth: "100%" },
      },
      defaultCommunicationEmailId: {
        elementType: "select",
        elementConfig: {
          name: "defaultCommunicationEmailId",
          type: "text",
          options: [],
        },
        label: "Send New User Registration to:",
        selectedOption: null,
        style: { minWidth: "100%", maxWidth: "100%" },
      },
      loginUrlOverride: {
        elementType: "text",
        elementConfig: {
          name: "loginUrlOverride",
          type: "text",
          required: false,
        },
        label: "Login URL Override",
        value: null,
        style: { minWidth: "100%", maxWidth: "100%" },
      },
      defaultLandingPage: {
        elementType: "select",
        elementConfig: {
          name: "defaultLandingPage",
          type: "text",
          options: [],
        },
        label: "Default Landing Page",
        selectedOption: null,
        style: { minWidth: "100%", maxWidth: "100%" },
      },
    }
  }

  componentDidMount() {
    this.getOrganizationInfo()
    this.setDefaults()
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being cancelled")
  }

  getOrganizationInfo = () => {
    this.setState({ loading: true })
    axios
      .get(`/organizations/${this.props.auth.organizationId}`, {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        if (res.data.organization) {
          const {
            allow_users_to_update_contact_information,
            allow_sign_up,
            allow_project_access_to_members,
            allow_profile_access_to_members,
            login_url_override,
            default_landing_page,
            landing_page_options,
          } = res.data.organization
          const formattedOverride = login_url_override ? login_url_override.trim() : null
          const selectedLandingPage = landing_page_options.filter((option) => option.value == default_landing_page)[0] || landing_page_options[0]
          this.setState({
            allowUserEditContactInformation: !!allow_users_to_update_contact_information,
            allowFrontEndUser: !!allow_sign_up,
            allowProjectAccessToMembers: !!allow_project_access_to_members,
            allowProfileAccessToMembers: !!allow_profile_access_to_members,
            loginUrlOverride: {
              ...this.state.loginUrlOverride,
              value: formattedOverride
            },
            loading: false,
            defaultLandingPage: {
              ...this.state.defaultLandingPage,
              selectedOption: selectedLandingPage,
              elementConfig: {
                ...this.state.defaultLandingPage.elementConfig,
                options: landing_page_options,
              },
            },
          })
        }
      })
  }

  selectEmailOptions(organization_communication_emails) {
    const verifiedEmails = []
    organization_communication_emails.forEach(email => {
        if (email.is_verified) {
          verifiedEmails.push({ value: email.id, label: email.email })
        }
      }
    );
    return verifiedEmails
  }

  setDefaults = () => {
    axios
      .get("/organizations/formdata", {
        cancelToken: this.signal.token,
      })
      .then((res) => {
        let defaultMemberTypeValue,
          defaultMemberTypeLabel,
          defaultRoleLabel,
          defaultRoleValue,
          defaultCommunicationEmailIdLabel,
          defaultCommunicationEmailIdValue,
          hideMemberType
        if (res.data.meta.default_role) {
          const default_role = res.data.meta.default_role
          defaultRoleLabel = default_role.name
          defaultRoleValue = default_role.id
          if (defaultRoleLabel === "Non Member" && defaultRoleValue === 4) {
            hideMemberType = true
          }
        }
        if (res.data.meta.default_membership_type) {
          const default_membership_type = res.data.meta.default_membership_type
          defaultMemberTypeLabel = default_membership_type.name
          defaultMemberTypeValue = default_membership_type.id
        }
        if (res.data.meta.default_organization_communication_email.length) {
          defaultCommunicationEmailIdLabel =
            res.data.meta.default_organization_communication_email[0].email
          defaultCommunicationEmailIdValue =
            res.data.meta.default_organization_communication_email[0].id
        }
        axios
          .get("/organization_communication_emails", {
            cancelToken: this.signal.token,
          })
          .then((res) => {
            this.setState({
              loading: false,
              defaultCommunicationEmailId: {
                ...this.state.defaultCommunicationEmailId,
                elementConfig: {
                  ...this.state.defaultCommunicationEmailId.elementConfig,
                  options: this.selectEmailOptions(res.data.organization_communication_emails),
                },
              },
            })
          })

        const member_type_options = res.data.json.member_types?.map((type) => {
          return { value: type.id, label: type.name }
        });

        const role_options = res.data.json.role?.map((type) => {
          return { value: type.id, label: type.name }
        });

        const selectedMemberType = defaultMemberTypeLabel && defaultMemberTypeValue
          ? {
            value: defaultMemberTypeValue,
            label: defaultMemberTypeLabel,
          }
          : null;

        const selectedRole = defaultRoleLabel &&
          defaultRoleValue && {
            value: defaultRoleValue,
            label: defaultRoleLabel,
          };

        const selectedCommunicationEmail = defaultCommunicationEmailIdLabel &&
        defaultCommunicationEmailIdValue
          ? {
            value: defaultCommunicationEmailIdValue,
            label: defaultCommunicationEmailIdLabel,
          }
          : null;

        this.setState(
          {
            hideMemberType: hideMemberType,
            defaultMemberTypes: {
              ...this.state.defaultMemberTypes,
              elementConfig: {
                ...this.state.defaultMemberTypes.elementConfig,
                options: member_type_options,
              },
              selectedOption: selectedMemberType,
            },
            defaultRoles: {
              ...this.state.defaultRoles,
              elementConfig: {
                ...this.state.defaultRoles.elementConfig,
                options: role_options,
              },
              selectedOption: selectedRole,
            },
            defaultCommunicationEmailId: {
              ...this.state.defaultCommunicationEmailId,
              selectedOption:
                selectedCommunicationEmail,
            },
          }
        )
      })
  }

  submitHandler = (event) => {
    event.preventDefault()
    const { setLoginUrl } = this.props
    const {
      allowFrontEndUser,
      defaultMemberTypes,
      defaultRoles,
      defaultCommunicationEmailId,
      loginUrlOverride,
    } = this.state
    const formattedOverride = loginUrlOverride?.value?.trim()
    const params = {
      allow_sign_up: allowFrontEndUser,
      role_id: defaultRoles.selectedOption?.value,
      organization_communication_email_id: defaultCommunicationEmailId.selectedOption?.value,
      login_url_override: formattedOverride,
      default_landing_page: this.state.defaultLandingPage.selectedOption.value,
    }

    if (!this.state.hideMemberType) {
      params.member_type_id = defaultMemberTypes.selectedOption?.value
    }

    if (formattedOverride && !isValidUrl(formattedOverride)) {
      return toast.error("Valid URL must contain http:// or https://")
    }

    this.setState({ disabled: true })
    
    axios
      .put("/organizations/set_default_sign_up_settings", params)
      .then((res) => {
        const { success } = res.data.json
        if (success) {
          const { login_url_override } = res.data.json.data

          /* setLoginUrl updates loginAssets['loginUrlOverride'] redux store state
          * to keep the loginAssets state from becoming stale and out of date. */
          setLoginUrl(login_url_override)

          this.setState({
            disabled: false,
          })
          toast.success("Default sign-up settings have been successfully updated")
          this.getOrganizationInfo()
          this.setDefaults()
        } else {
          errorHandler(res)
        }
      })
      .catch((err) =>
        toast.error(
          isEmpty(err.message) ? "Something Went Wrong" : err.message
        )
      )
  }

  dropdownChangedHandler = (event, key) => {
    const inputValue = {}
    let hideMemberType = false
    if (event && event.label === "Non Member") {
      hideMemberType = true
    }
    inputValue.selectedOption = event
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue,
    }
    this.setState({ [key]: updatedcontrols, hideMemberType: hideMemberType })
  }

  updateUserSettings = () => {
    this.setState({ disabled: true })
    const {
      allowUserEditContactInformation,
      allowFrontEndUser,
      allowProjectAccessToMembers,
      allowProfileAccessToMembers,
    } = this.state
    axios
      .put(
        `/organizations/${this.props.auth.organizationId}`,
        {
          allow_users_to_update_contact_information: allowUserEditContactInformation,
          allow_sign_up: allowFrontEndUser,
          allow_project_access_to_members: allowProjectAccessToMembers,
          allow_profile_access_to_members: allowProfileAccessToMembers,
        },
        { cancelToken: this.signal.organization }
      )
      .then((res) => {
        if (res.data.json.success) {
          this.setState({
            disabled: false,
          })
          toast.success("User settings updated successfully")
        } else {
          errorHandler(res)
          this.setState({ disabled: false }, () => this.getOrganizationInfo())
        }
      })
  }

  allowUserEditContactInformationChangeHandler = () => {
    const { allowUserEditContactInformation } = this.state
    this.setState(
      {
        allowUserEditContactInformation: !allowUserEditContactInformation,
      },
      this.updateUserSettings
    )
  }

  allowProjectAccessToMembersChangeHandler = () => {
    const { allowProjectAccessToMembers } = this.state
    this.setState(
      {
        allowProjectAccessToMembers: !allowProjectAccessToMembers,
      },
      this.updateUserSettings
    )
  }

  allowProfileAccessToMembersChangeHandler = () => {
    const { allowProfileAccessToMembers } = this.state
    this.setState(
      {
        allowProfileAccessToMembers: !allowProfileAccessToMembers,
      },
      this.updateUserSettings
    )
  }

  allowFrontEndUserChangeHandler = () => {
    const { allowFrontEndUser } = this.state
    this.setState(
      {
        allowFrontEndUser: !allowFrontEndUser,
      },
      this.updateUserSettings
    )
  }

  render() {
    const {
      auth,
      loginButtonStatus,
      toggleLoginButton
    } = this.props
    const {
      allowUserEditContactInformation,
      allowProjectAccessToMembers,
      allowProfileAccessToMembers,
      allowFrontEndUser,
      disabled,
      loading,
      defaultMemberTypes,
      defaultRoles,
      defaultCommunicationEmailId,
      loginUrlOverride,
      defaultLandingPage
    } = this.state

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            {loading ? (
              <Spinner />
            ) : (
              <div className="pb-3">
                <div className="my-3 d-flex align-items-center">
                  <ToggleSwitch
                    checked={allowUserEditContactInformation}
                    onChange={this.allowUserEditContactInformationChangeHandler}
                    disabled={disabled}
                  />
                  <label className="ml-2 my-0">
                    Allow users to update Contact Information?
                  </label>
                </div>
                <div className="my-3 d-flex align-items-center">
                  <ToggleSwitch
                    checked={allowProjectAccessToMembers}
                    onChange={this.allowProjectAccessToMembersChangeHandler}
                    disabled={disabled}
                  />
                  <label className="ml-2 my-0">
                    Allow users to see each others projects?
                  </label>
                </div>
                <div className="my-3 d-flex align-items-center">
                  <ToggleSwitch
                    checked={allowProfileAccessToMembers}
                    onChange={this.allowProfileAccessToMembersChangeHandler}
                    disabled={disabled}
                  />
                  <label className="ml-2 my-0">
                    Allow users to see each others profiles?
                  </label>
                </div>
                <div className="my-3 d-flex align-items-center">
                  <ToggleSwitch
                    checked={allowFrontEndUser}
                    onChange={this.allowFrontEndUserChangeHandler}
                    disabled={disabled}
                  />
                  <label className="ml-2 my-0">
                    Allow front end user registration?
                  </label>
                </div>
                <div className="my-3 d-flex align-items-center">
                  <ToggleSwitch
                    checked={loginButtonStatus}
                    onChange={() => toggleLoginButton(auth.organizationId, loginButtonStatus)}
                    disabled={disabled}
                  />
                  <label className="ml-2 my-0">
                    Enable public registration login button?
                  </label>
                </div>
                <div className="card border">
                  <div className="card-body">
                    <div className="my-3 row">
                      <form className="col" onSubmit={this.submitHandler}>
                        <div className="row">
                          <div className="col-6">
                            <Input
                              {...defaultRoles}
                              selectedOption={
                                this.state.defaultRoles.selectedOption
                              }
                              changed={(event) =>
                                this.dropdownChangedHandler(
                                  event,
                                  "defaultRoles"
                                )
                              }
                            />
                          </div>
                          <div className="col-6">
                            <Input
                              {...defaultMemberTypes}
                              disabled={this.state.hideMemberType}
                              selectedOption={
                                this.state.defaultMemberTypes.selectedOption
                              }
                              changed={(event) =>
                                this.dropdownChangedHandler(
                                  event,
                                  "defaultMemberTypes"
                                )
                              }
                            />
                          </div>
                          <div className="col-6">
                            <Input
                              {...defaultCommunicationEmailId}
                              selectedOption={
                                this.state.defaultCommunicationEmailId
                                  .selectedOption
                              }
                              changed={(event) =>
                                this.dropdownChangedHandler(
                                  event,
                                  "defaultCommunicationEmailId"
                                )
                              }
                            />
                          </div>
                          <div className="col-6">
                            <Input
                              {...loginUrlOverride}
                              value={loginUrlOverride.value}
                              changed={(e) => this.setState({
                                loginUrlOverride: {
                                  ...this.state.loginUrlOverride,
                                  value: e.target.value
                                }
                              })}
                            />
                          </div>
                          <div className="col-6">
                            <Input
                              {...defaultLandingPage}
                              selectedOption={
                                this.state.defaultLandingPage.selectedOption
                              }
                              changed={(event) =>
                                this.dropdownChangedHandler(
                                  event,
                                  "defaultLandingPage"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="my-3">
                          <FormButton
                            className="float-left"
                            disabled={this.state.disabled}
                          >
                            Save
                          </FormButton>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  loginButtonStatus: state.loginAssets.loginButtonStatus
})

export default connect(mapStateToProps, { setLoginUrl, toggleLoginButton })(Settings)
