import axios from "axios";

export const mailBody = (organizationInfo) => {
  const email = organizationInfo.email != 'no-reply@amsnetwork.ca' ? organizationInfo.email : ''
  return(
    `<p>Hello,</p>
     <br />
     <p>We want to inform you that there has been activity on your application.</p>
     <p>Please log in to see the updated status.</p>
     <br />
     <p>This is an automated messaged</p>
     <br />
     <p>${organizationInfo.full_name}</p>
     <p>${organizationInfo.website}</p>
     <p>${email}</p>
     <p>${organizationInfo.mailing_address}</p>`
  )
}

export function updateStatusApi(applicationItem, status) {
  return axios.put(`/grant_applications/${applicationItem.id}/update_status`, { status: status });
}

export function sendUpdateEmailApi(applicationItem, email, subject, bodyText, bcc) {
  return axios.put(`/grant_applications/${applicationItem.id}/send_status_update_email`, {
    email: email,
    subject: subject,
    body: bodyText,
    bcc: bcc
  });
}
