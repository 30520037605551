import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";

import Button from "../../UI/button/Button";
import Input from "../../UI/input/Input";
import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";

import Config from "./config";
import CKEditorFull from "./CkEditorFull";
import VerifiedEmailSelect from "./VerifiedEmailsSelect";

import "./Invoicing.css";

class Rentals extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    rentals: [],
    orgnId: null,
    id: null,
    memberTypes: [],
    fromEmail: {
      elementType: "input",
      elementConfig: {
        name: "fromEmail",
        type: "email",
        required: true
      },
      value: ""
    },
    pendingApprovalContent: null,
    approvedContent: null,
    disabled: false,
    verifiedEmails: [],
    useEmailDropdown: false,
    fromEmailSelected: "",
    defaultFromEmail: "",
  };

  componentDidMount() {
    axios.get("/organization_communication_emails", { 
      cancelToken: this.signal.token 
    }).then(res => {
      const { use_verified_email } = res.data.meta;
      let verifiedEmails = [];
      let useEmailDropdown = false;
      if ( use_verified_email ) {
        const { organization_communication_emails: orgEmails } = res.data;
        verifiedEmails =
          Array.isArray(orgEmails)
            ? orgEmails.filter(email => email.is_verified)
            : [];
        useEmailDropdown = verifiedEmails.length >= 1;
      }
      this.setState({ verifiedEmails, useEmailDropdown }, this.getFormData);
    })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getFormData = () => {
    axios.get("/mail_formats", { cancelToken: this.signal.token }).then(res => {
      const rentals = res.data.mail_formats.filter(
        data => data.tab_name === "Rentals"
      );
      this.setState({
        loading: false,
        id: rentals[0].id,
        orgnId: rentals[0].organization_id,
        fromEmail: {
          ...this.state.fromEmail,
          value: rentals[0].data.from_email
        },
        defaultFromEmail: rentals[0].data.from_email,
        pendingApprovalContent: rentals[0].data.pending_approval,
        approvedContent: rentals[0].data.approved
      });
    });
  }

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let attrData = {};
    const { 
      fromEmail,
      pendingApprovalContent,
      approvedContent,
      useEmailDropdown,
      fromEmailSelected
    } = this.state;

    if (
      (useEmailDropdown && !fromEmailSelected) ||
      (!useEmailDropdown && !fromEmail.value)
    ) {
      toast.error(`Please ${
        useEmailDropdown
          ? "Select"
          : "Enter"
      } a valid email`);
      this.setState({ disabled: false });
      return;
    }

    attrData["id"] = this.state.id;
    attrData["from_email"] = 
      useEmailDropdown
        ? fromEmailSelected
        : fromEmail.value;
    attrData["pending_approval"] = pendingApprovalContent;
    attrData["approved"] = approvedContent;
    axios.put(`/mail_formats`, attrData).then(res => {
      if (res.data.json.success) {
        toast.success("Mail format successfully saved");
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(title(error) + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
      }
      this.setState({ disabled: false })
    });
  };

  inputChangePending = event => {
    this.setState({
      pendingApprovalContent: event.editor.getData()
    });
  };

  inputChangeApproved = event => {
    this.setState({
      approvedContent: event.editor.getData()
    });
  };

  render() {
    const { 
      useEmailDropdown, 
      verifiedEmails, 
      defaultFromEmail, 
    } = this.state;

    return this.state.loading ? (
      <div className="card-text">
        <div className="row">
          <div className="col-12">
            <Spinner className="h-60vh" />
          </div>
        </div>
      </div>
    ) : (
        <div className="membership p-4">
          <div className="row">
            <div className="col-12">
              <h5 className="font-weight-bold pt-2">Automated Booking Confirmation Emailing</h5>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-3">
              <h6 className="font-weight-normal">From Address</h6>
            </div>
            <div className="col-lg-6">
              {
                useEmailDropdown
                  ? (
                    <VerifiedEmailSelect 
                      defaultEmail={defaultFromEmail}
                      emailAddresses={verifiedEmails}
                      emailChangeHandler={email => this.setState({
                        fromEmailSelected: email ? email.label : "", 
                      })}
                    />
                  ) : (
                    <Input
                      {...this.state.fromEmail}
                      changed={event => this.inputChangedHandler(event, "fromEmail")}
                    />
                  )
              }
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-4">
              <h6 className="font-weight-normal">Pending Approval Body</h6>
            </div>
          </div>

          <div className="row mt-3 body-text">
            <div className="col-12">
              <div id="toolbar-container" />
              <CKEditorFull
                data={this.state.pendingApprovalContent}
                config={Config}
                onChange={this.inputChangePending}
                onBeforeLoad={(CKEDITOR) => ( CKEDITOR.disableAutoInline = true )}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-4">
              <h6>Approved Body</h6>
            </div>
          </div>

          <div className="row body-text">
            <div className="col-12">
              <div id="toolbar-container-footer" />
              <CKEditorFull
                data={this.state.approvedContent}
                config={Config}
                onChange={this.inputChangeApproved}
                onBeforeLoad={(CKEDITOR) => ( CKEDITOR.disableAutoInline = true )}
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-3 d-flex justify-content-between">
              <Button
                type="success"
                className="px-3"
                clicked={this.submitHandler}
                disabled={this.state.disabled}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      );
  }
}

export default Rentals;
