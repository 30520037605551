import { setResponse, clearResponse } from "./index";
import axios from "../../configAxios";
import { capitalize } from 'lodash'

export const deleteForm = (formData) => {
  return async (dispatch) => {
    dispatch(clearResponse());
    try {
      const res = await axios.delete(`/forms/${formData.form_id}`, { params: formData.content });
      const { success } = res.data.json;
      if (success) {
        dispatch(setResponse(true, "Form deleted Successfully"));
      } else {
        const { errors } = res.data.json;
        const errorMessage = Object.keys(errors)
          .map(
            (key) =>
              `${capitalize(key)} ${errors[key]}`
          )
          .join(" ");
        dispatch(setResponse(false, errorMessage));
      }
    } catch (err) {
      console.log(err);
      dispatch(setResponse(false, "Something went wrong"));
    }
  };
};
