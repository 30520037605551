import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import axios from "../../configAxios"
import Input from "../UI/input/Input"
import { getProjects } from "../../store/actions/index"
import "./ProgramSearch.css"
import FormButton from "../UI/button/FormButton"
import TagDropdown from "./TagDropdown"
import onError from "../../utils/onError"

const ProgramSearch = ({ disabled, setParams }) => {
  const signal = axios.CancelToken.source();

  const [queryString, setQueryString] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [tags, setTags] = useState([]);

  const textInput = React.createRef();

  useEffect(() => {
    axios.get("/programs/filters", { cancelToken: signal.token })
    .then(res => {
      const { locations, status } = res.data.json

      setLocations(locations);
      setStatus(status);

      const resStatus = status.find(el => el.value === "Active")
      const initialStatus = { ...resStatus, label: resStatus.value }

      setSelectedStatus(initialStatus)
    })
    .catch(error => onError(error));
  }, [])

  const stringInput = {
    elementType: "input",
    elementConfig: {
      name: "query_string",
      placeholder: "Enter keyword here"
    },
    iconPosition: "left",
    icon: "glyphicon-search",
    value: queryString
  };

  const locationOptions = locations.map(location => ({value: location, label: location}));

  const locationInput = {
    elementType: "select",
    elementConfig: {
      name: "location",
      placeholder: "Location",
      options: locationOptions,
      components: {
        IndicatorSeparator: () => {
          return null;
        }
      }
    },
    selectedOption: selectedLocation,
    clearAfterResponse: true
  };
  
  const statusOptions = status.map(s => ({
    value: s.value, 
    label: s.value, 
    key: s.key
  }));

  const statusInput = {
    elementType: "select",
      elementConfig: {
        name: "status",
        placeholder: "Status",
        options: statusOptions,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      selectedOption: selectedStatus,
  };

  const submitHandler = event => {
    event.preventDefault();
    
    const params = {
      page: 1,
      query: queryString,
      location: selectedLocation?.value,
      status: selectedStatus?.key,
      tags: JSON.stringify(tags.map(tag => tag.value)),
      type: "All"
    };
    setParams({...params})
  }

  const clearTextHandler = () => {
    setQueryString("")
    textInput.current.focus();
  };

  return (
      <div className="card">
        <div className="card-body">
          <div className="card-text">
            <h5 className="font-weight-bold">Search</h5>
            <form onSubmit={submitHandler}>
              <div className="row program-search">
                <div className="col-md-12">
                  <i className="fas fa-search search" />
                  {queryString && 
                  <i className="fas fa-times remove cursor" onClick={clearTextHandler} /> }
                  <Input
                    key="queryString"
                    {...stringInput}
                    changed={event => setQueryString(event.target.value)}
                    padding={true}
                    forwardRef={textInput}
                    style={{ paddingRight: "55px" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    key="location"
                    {...locationInput}
                    changed={event => setSelectedLocation(event)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <TagDropdown tags={tags} setTags={setTags} />
                </div>
                <div className="col-md-6">
                  <Input
                    key="status"
                    {...statusInput}
                    changed={event => setSelectedStatus(event)}
                  />
                </div>
              </div>
              <FormButton block disabled={disabled}>
                Search
              </FormButton>
            </form>
          </div>
        </div>
      </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getProjects: searchParams => dispatch(getProjects(searchParams))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ProgramSearch);
