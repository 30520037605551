import React, { Component } from 'react'
import { format } from "date-fns"
import _ from "lodash"
import { toast } from "react-toastify"

import Button from "../UI/button/Button";

const TextLabel = ({ children }) => {
  return (
    <div className="font-weight-bold text-secondary">{children}</div>
  )
}

class ProgramConfirmation extends Component {

  state = {
    file: null
  }

  onFileUpload = e => {
    if (!_.isEmpty(e.target.files)) {
      if (e.target.files[0].type === "application/pdf")
        this.setState({ file: e.target.files[0] })
      else {
        toast.error(`${e.target.files[0].type} is not allowed. Only PDF format files are allowed`)
        this.setState({ file: null })
      }
    } else {
      this.setState({ file: null })
    }
  }

  render() {
    const { program } = this.props;
    return (
      <>
        <TextLabel className="mt-3">Please confirm details</TextLabel>
        <div className="my-3">
          <TextLabel>Dates or Date Ranges</TextLabel>
          <div className="text-dark font-weight-bold">
            <span>{program.earlybird_cutoff ? format(program.earlybird_cutoff, "MMM DD, YYYY") : "--"}</span>
            <span>
              {program.drop_cutoff ? ` to ${format(program.drop_cutoff, "MMM DD, YYYY")}` : "--"}</span>
          </div>
        </div>
        <div className="my-3">
          <TextLabel>Cost</TextLabel>
          <div className="text-dark font-weight-bold">{program.enrollment_price ? `$${program.enrollment_price}` : "--"}</div>
        </div>
        <div className="my-3">
          <TextLabel>Contact Name</TextLabel>
          <div className="text-dark font-weight-bold">{program.instructors ? program.instructors : "--"}</div>
        </div>
        {program.allow_media_upload
          ? (<div className="my-3">
            <TextLabel>Upload File</TextLabel>
            <div className="p-2 border">
              <input placeholder="Hello" type="file" onChange={this.onFileUpload} className="cursor" id="fileUpload" accept="application/pdf" />
            </div>
          </div>)
          : null
        }
        <div className="text-center">
          <Button className="mr-2" type="secondary" clicked={e => this.props.closeModal()}>Cancel</Button>
          <Button disabled={this.props.disabled} type="primary" clicked={e => this.props.registerHandler(this.state.file)}>Submit</Button>
        </div>
      </>
    )
  }
}

export default ProgramConfirmation
