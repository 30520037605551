export const customStylesFlag = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #d9d9d9",
    color: state.isSelected ? "white" : "black",
  }),
  control: () => ({
    border: "1px solid #d9d9d9",
    display: "flex",
    fontSize: 13,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
}
