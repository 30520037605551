import React, { Component } from "react";
import { toast } from "react-toastify";
import { title } from "change-case";
import classnames from "classnames";

import axios from "../../../configAxios";

class projectCueItem extends Component {
  state = {
    visible: this.props.cue.visible,
    cue: this.props.cue
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ cue: nextProps.cue, visible: nextProps.cue.visible })
  }

  visibilityHandler = () => {
    let response = window.confirm("Are you sure you want to change the submission visibility?")
    if (response) {
    let attrData = {};
    attrData["name"] = this.props.cue.name;
    attrData["category"] = "Cues";
    attrData["visible"] = !this.state.visible;
    axios
      .put(`/project_attribute_types/${this.props.cue.id}`, attrData)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Submission visibility successfully updated");
          this.props.getProjectCuesList();
          this.setState({ visible: res.data.json.data.visible });
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(error + " " + title(res.data.json.errors[error]));
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
        this.setState({ disabled: false })
      });
    }
  }

  render() {
    const { cue } = this.props;
    return (
      <div className="row">
        <div className="col-lg-7">{cue.name}</div>
        <div className="col-lg-3">
          <i className={classnames(
              "fas fa-check-circle cursor",
              {
                "text-info": this.state.visible,
                "text-secondary element-disabled": !this.state.visible
              }
            )}
            onClick={this.visibilityHandler}
          />
        </div>
        <div className="col-lg-2">
          <div className="d-flex justify-content-around">
            <div>
              <i
                className="fas fa-pencil-alt projectAttr-edit cursor"
                onClick={() => { window.scrollTo(0, 0); this.props.editModal(cue.id) }}
              />
            </div>
            <div>
              <i class="fas fa-trash cursor"
                style={{ cursor: cue.can_delete ? "pointer" : "no-drop", opacity: cue.can_delete ? 1 : 0.7 }}
                onClick={() => cue.can_delete && this.props.deleteCueHandler(cue.id)}
              />
            </div>
            {/* <div>
                  {cue.archive ? (
                    <button className="btn-danger rounded cursor" onClick={() => this.unarchiveCue(cue.id)}>
                      Unarchive
                  </button>
                  ) : (
                      <button className="btn-success rounded cursor px-3" onClick={() => this.archiveCue(cue.id)}>Archive</button>
                    )}
                </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default projectCueItem;