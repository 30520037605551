import React, { Component } from "react";
import { title } from "change-case";
import { toast } from "react-toastify";
import axios from "../../configAxios";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";

class Adjustment extends Component {
  state = {
    amount: {
      elementType: "input",
      elementConfig: {
        name: "amount",
        type: "number",
        placeholder: "",
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      value: ""
    },
    notes: {
      elementType: "textarea",
      elementConfig: {
        name: "notes",
        rows: "6"
      },
      value: ""
    },
    disabled: false
  };
  closeModal = () => {
    this.props.closeModal();
  };
  inputChangedHandler = (event, key) => {
    if (key === "amount") {
      let amount = { ...this.state.amount };
      amount["value"] = event.target.value;
      this.setState({ amount: amount });
    } else {
      let notes = { ...this.state.notes };
      notes["value"] = event.target.value;
      this.setState({ notes: notes });
    }
  };
  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    const queryParams = {
      account_id: this.props.accountId,
      adjustment_amount: this.state.amount.value,
      notes: this.state.notes.value
    };
    axios
      .post("/adjustments", queryParams)
      .then(res => {
        if (res.data.json.success) {
          this.props.closeModal();
          this.props.getAccountDetails();
          toast.success("Adjustment made Successfully");
        } else {
          if ('errors' in res.data.json) {
            Object.keys(res.data.json.errors).forEach(key => {
              toast.error(title(key) + " " + res.data.json.errors[key])
            })
          }
          if ('error' in res.data.json) {
            toast.error(res.data.json.error)
          }
          this.setState({ disabled: false })
        }
      })
      .catch(err => err);
  };

  render() {
    return (
      <div className="mt-4">
        <p>Adjustment Amount</p>
        <Input
          {...this.state.amount}
          changed={event => this.inputChangedHandler(event, "amount")}
        />
        <p>Notes</p>
        <Input
          {...this.state.notes}
          changed={event => this.inputChangedHandler(event, "notes")}
        />
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            className="float-left mr-3"
            clicked={this.closeModal}
          >
            Cancel
          </Button>
          <Button
            type="warning"
            className="float-left mr-3"
            clicked={this.submitHandler}
            disabled={this.state.disabled}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}
export default Adjustment;
