import React from 'react'
import { Link } from 'react-router-dom';
import dateFns from "date-fns";

import Button from "../UI/button/Button";

const RentalItem = (props) => {
  return (
    <div className="list-group-item pt-3">
      <div className="row">
        <div className="col-lg-3">
          <h5 className="text-primary font-weight-bold">{props.ticket.project_name}</h5>
          {
            props.ticket.full_name_cached ?
              <span>
                by: <Link to={`/users/${props.ticket.ticketable.details.id}/summary`} className="text-primary">
                  <span className="font-weight-bold">{props.ticket.full_name_cached}</span>
                </Link>
              </span>
              : null
          }
          <p>
            on: <span className="font-weight-bold">{dateFns.format(new Date(props.ticket.created_at), "ddd MMM DD YYYY")}</span>
          </p>
        </div>
        <div className="col-lg-7 d-flex justify-content-center align-items-start flex-column">
          {
            props.ticket.status_string === 'Pending Approval' ?
              <span className="text-danger font-weight-bold">{props.ticket.status_string}</span> :
              <span className="text-success font-weight-bold">{props.ticket.status_string}</span>
          }
          {
            props.ticket.bookings ? props.ticket.bookings.map(booking => (
              <div className="w-100" key={booking.id}>
                <div className="row">
                  <div className="col-12">
                    <strong className="mr-3">{booking.asset.name}</strong>
                  </div>
                </div>
                {
                  booking.asset.object.is_bookable_in_days || booking.asset.object.is_bookable_in_hours ? (
                    <div className="row">
                      <div className="col-9">
                        {dateFns.format(new Date(booking.details.start_time), "ddd MMM DD YYYY")}
                        {" - "}
                        {dateFns.format(new Date(booking.details.end_time), "ddd MMM DD YYYY")}
                        {` (${booking.loan_period} x ${booking.price_to_charge} per day)`}
                      </div>
                      <div className="col-1">
                        <span className="font-weight-bold">
                          {booking.quantity}
                        </span>
                      </div>
                      <div className="col-2">
                        <span className="font-weight-bold">
                          {booking.price}
                        </span>
                      </div>
                    </div>
                  ) : null
                }
              </div>
            )) : null
          }
        </div>
        <div className="col-lg-2 action-buttons text-center py-3">
          {
            props.ticket.edit_link ? (
              <Link
                to={`/users/${props.ticket.ticketable.details.id}/bookings?ticketId=${props.ticket.id}`}
                title="Edit Ticket">
                <Button className="btn btn-secondary rounded-circle mr-1" title="Edit">
                  <i className="fas fa-edit"></i>
                </Button>
              </Link>
            ) : ''
          }
          {
            props.ticket.edit_link ? (
              <React.Fragment>
                <Button className="btn btn-success rounded-circle mx-1" title="Approve" clicked={event => props.changeStatus(props.ticket.id, 'approve')}>
                  <i className="fas fa-check"></i>
                </Button>
                <Button className="btn btn-danger rounded-circle ml-1" title="Reject" clicked={event => props.changeStatus(props.ticket.id, 'reject')}>
                  <i className="fas fa-times"></i>
                </Button>
              </React.Fragment>
            ) : null
          }
        </div>
      </div>
    </div>
  )
}

export default RentalItem;
