import axios from "../../configAxios"
import { toast } from "react-toastify"
import { GET_ORGANIZATION_SETTINGS, UPDATE_ORGANIZATION_SETTINGS } from "./actionTypes"

export const getTranslationStatus = (orgId) => async dispatch => {
  try {
    const res = await axios.get(`/organizations/${orgId}/enable_language_translation`)
    const { enable_language_translation: localize } = res.data.json

    dispatch({ type: GET_ORGANIZATION_SETTINGS, localize, })
  }
  catch (e) {
    console.error(e)
  }
}

export const updateTranslationStatus = (localizationStatus, orgId) => async dispatch => {
  try {
    const params = { enable_language_translation: localizationStatus }
    const res = await axios.put(`/organizations/${orgId}/enable_language_translation`, params)
    const { enable_language_translation: localize } = res.data.json
    const toastMessage = localize ? 'enabled' : 'disabled'

    toast.success(`Translation successfully ${toastMessage}`)

    dispatch({ type: UPDATE_ORGANIZATION_SETTINGS, localize, })
  }
  catch (e) {
    console.error(e)
  }
}
