import Select from "react-select"
import { customStylesFlag } from "./customStyles"
import React from "react"

const SelectDropdown = ({ props }) => {
  const setSelectValue = (props) => {
    if (props.elementConfig.isMulti) return props.selectedOption
    else {
      return props.selectedOption
        ? props.selectedOption.value
        : props.value || ""
    }
  }
  return (
    <div className="select-dropdown">
      <Select
        {...props.elementConfig}
        value={props.clearAfterResponse ? props.value : props.selectedOption}
        onChange={props.changed}
        isClearable={
          props.elementConfig.isClearable === undefined
            ? true
            : props.elementConfig.isClearable
        }
        isSearchable={props.elementConfig.isSearchable}
        isLoading={props.elementConfig.isLoading}
        isMulti={props.elementConfig.isMulti}
        isDisabled={props.disabled}
        ref={props.forwardRef}
        styles={{ ...customStylesFlag, ...props.customStyle }}
        className={props.className}
        placeholder={
          props.elementConfig &&
          props.elementConfig.hasOwnProperty("placeholder")
            ? props.elementConfig.placeholder
            : "Select Option"
        }
      />
      <input
        id={props.id}
        value={setSelectValue(props)}
        required={props.elementConfig.required}
        onInvalid={props.elementConfig.onInvalid}
        style={{
          opacity: 0,
          height: 0,
          position: "absolute",
        }}
      />
    </div>
  )
}

export default SelectDropdown
