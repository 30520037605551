import React from 'react'
import Editor from "rich-markdown-editor"

export default function LongAnswerQuestion({ question, response, setResponseData, completed }) {

  const editingTheme = () => {
    if (completed) {
      return { background: "#e9ecef", color: "#6c757d" }
    }
    return {zIndex: 100, blockToolbarBackground: "#ffffff", color: "#6c757d"}
  }

  const displayStyle = () => {
    if (completed) {
      return { background: "#e9ecef", border: "1px solid #d9d9d9" }
    }
    return { "min-height": "150px", border: "1px solid #d9d9d9"}
  }

  return (
    <div className="mb-4">
      <p className="mb-2 font-weight-bold">{question.question_text}</p>
      <input //This is being used to add validation to the contents of the ckeditor
        style={{ width: "1px", height: "1px", opacity: 0, padding: 0, margin: 0, border: 0, position: "absolute", left: "60px" }}
        required={question.mandatory}
        value={response?.response_text}
      />
      <div className="mx-3 p-4" style={displayStyle()}>
        <Editor
          readOnly={completed}
          theme={editingTheme()}
          defaultValue={response?.response_text || ""}
          placeholder="Please enter your response here"
          onChange={(getValue) => {
            const data = getValue()
            setResponseData(question.id, { response_text: data })
          }}
        />
      </div>
    </div>
  )
}
