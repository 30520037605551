import React, { Component } from "react";
import Input from "../../UI/input/Input";
import Button from "../../UI/button/Button";
import axios from "../../../configAxios";
import FormButton from "../../UI/button/FormButton";

class EditNewThirdPartyOwner extends Component {
  signal = axios.CancelToken.source();
  state = {
    firstName: {
      elementType: "input",
      elementConfig: {
        name: "firstName",
        type: "text",
        required: true
      },
      label: "First name",
      value: ""
    },
    lastName: {
      elementType: "input",
      elementConfig: {
        name: "lastName",
        type: "text",
        required: true
      },
      label: "Last name",
      value: ""
    },
    address1: {
      elementType: "input",
      elementConfig: {
        name: "address1",
        type: "text",
        required: true
      },
      label: "Address",
      value: ""
    },
    address2: {
      elementType: "input",
      elementConfig: {
        name: "address2",
        type: "text",
        required: true
      },
      value: ""
    },
    city: {
      elementType: "input",
      elementConfig: {
        name: "city",
        type: "text",
        required: true
      },
      label: "City",
      value: ""
    },
    province: {
      elementType: "input",
      elementConfig: {
        name: "province",
        type: "text",
        required: true
      },
      label: "Province",
      value: ""
    },
    postalCode: {
      elementType: "input",
      elementConfig: {
        name: "postalCode",
        type: "text",
        required: true
      },
      label: "Postal Code",
      value: ""
    },
    phoneNumber: {
      elementType: "input",
      elementConfig: {
        name: "phoneNumber",
        type: "text",
        required: true
      },
      label: "Phone Number",
      value: ""
    },
    emailAddress: {
      elementType: "input",
      elementConfig: {
        name: "emailAddress",
        type: "text",
        required: true
      },
      label: "Email Address",
      value: ""
    },
    thirdPartyDetails: {},
    disabled: false
  };
  componentDidMount() {
    axios.get(`/third_party_owners/${this.thirdPartyOwner.id}`, { cancelToken: this.signal.token })
      .then(res => {
      })
      .catch(error => error);
  };

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  closeModal = () => {
    this.props.closeModal();
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    inputValue["value"] = event.target.value;

    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  render() {
    const { firstName, lastName, address1, address2, city, province, postalCode, phoneNumber, emailAddress } = this.state;
    return (
      <div>
        <form onSubmit={this.props.editThirdPartyHandler}>
          <Input
            {...firstName}
            changed={event => this.inputChangedHandler(event, "firstName")}
          />
          <Input
            {...lastName}
            changed={event => this.inputChangedHandler(event, "lastName")}
          />
          <Input
            {...address1}
            changed={event => this.inputChangedHandler(event, "address1")}
          />
          <Input
            {...address2}
            changed={event => this.inputChangedHandler(event, "address2")}
          />
          <Input
            {...city}
            changed={event => this.inputChangedHandler(event, "city")}
          />
          <Input
            {...province}
            changed={event => this.inputChangedHandler(event, "province")}
          />
          <Input
            {...postalCode}
            changed={event => this.inputChangedHandler(event, "postalCode")}
          />
          <Input
            {...phoneNumber}
            changed={event => this.inputChangedHandler(event, "phoneNumber")}
          />
          <Input
            {...emailAddress}
            changed={event => this.inputChangedHandler(event, "emailAddress")}
          />
          <div className="text-center">
            <Button type="secondary" clicked={this.closeModal}>
              Cancel
            </Button>
            <FormButton className="ml-2">{this.props.editThirdPartyOwner ? 'Edit Third Party Details' : 'Create New Third Party'}</FormButton>
          </div>
        </form>
      </div>

    )
  }
}

export default EditNewThirdPartyOwner;