import React from "react"

const NoPromos = () => {
  return (
    <li className="list-group-item" key="empty-promos">
      <div className="row">
        <div className="col-12">
          No promos available
        </div>
      </div>
    </li>
  )
}

export default NoPromos
