import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";
import Button from "../UI/button/Button";
class OrganizationDetails extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: false,
    organization: ""
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getOrganization();
  }

  getOrganization = () => {
    this.setState({ ...this.state, loading: true });
    axios
      .get(`/organizations/${this.props.match.params.id}`, {
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          ...this.state,
          organization: res.data.organization,
          loading: false
        });
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  addDemoData = event => {
    let data = {
      id: this.state.organization.id
    };
    axios
      .post(`/organizations/setup_demo_data`, data)
      .then(res => {
        this.setState({ ...this.state, loading: false });
        if (res.data.json.success) {
          toast.success("Demo data import added to background.");
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            Object.keys(res.data.json.errors).forEach(error => {
              toast.error(error + " " + res.data.json.errors[error]);
            });
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error);
          }
        }
      })
      .catch(error => error);
  };

  deleteMemberHandler = () => {
    const { organization } = this.state;
    let response = window.confirm(`Are you sure you want to delete ${organization.non_admin_members_count} member(s) from ${organization.full_name}?`);
    if (response) {
      axios.delete("/organizations/non_admin_members", {
        params: {
          id: parseInt(organization.id)
        }
      })
        .then(res => {
          if (res.data.json.success) {
            toast.success(res.data.json.message);
            this.getOrganization();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(key => {
                toast.error(`${title(key)} ${res.data.json.errors[key]}`);
              })
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  render() {
    return (
      <div className="container w-100">
        <div className="row mb-4">
          <div className="col-lg-4">
            <h3 className="word-wrap-break">{this.state.organization.full_name}</h3>
          </div>
          <div className="col-lg-8 text-lg-right text-center">
            <span>
              <Button
                type="success"
                className="mr-1 my-1"
                clicked={event => this.addDemoData(event)}
              >
                Add Demo Data
              </Button>
            </span>
            <Link to={`/organizations/${this.state.organization.id}/libraries/new`}>
              <Button type="success" className="mr-1 my-1">
                Add Libraries
              </Button>
            </Link>
            <Link to={`/organizations/${this.state.organization.id}/equipment_items/new`}>
              <Button type="success" className="mr-1 my-1">
                Add Equipments
              </Button>
            </Link>
            <Link to={`/organizations/${this.state.organization.id}/members/new`}>
              <Button type="success" className="mr-1 my-1">
                Add Members
              </Button>
            </Link>
            <span>
              <Button
                type="danger"
                className="mr-1 my-1"
                clicked={this.deleteMemberHandler}
              >
                Delete Member
              </Button>
            </span>
          </div>
        </div>

        {this.state.loading ? (
          <div className="row card w-100">
            <Spinner className="h-60vh" />
          </div>
        ) : (
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body p-3 text-center">
                    <div className="card-text">
                      <h5>Active Projects</h5>
                      <h5 className="text-danger">
                        {this.state.organization.active_projects_count}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body p-3 text-center">
                    <div className="card-text">
                      <h5>Bucket Size</h5>
                      <h5 className="text-danger">
                        {this.state.organization.bucket_size}{" "}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body p-3 text-center">
                    <div className="card-text">
                      <h5>Assets</h5>
                      <h5 className="text-danger">
                        {this.state.organization.assets_count}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card" style={{ minHeight: "calc(100% - 1.5rem)" }}>
                  <div className="card-body p-4">
                    <div className="card-text">
                      <p className="text-muted">
                        Staff: {this.state.organization.staffs_count}&nbsp;/&nbsp;
                        {this.state.organization.staff_limit}
                      </p>
                      <div className="progress border-radius-20">
                        <div
                          className="progress-bar bg-danger border-radius-20"
                          role="progressbar"
                          style={{
                            width: `${(parseFloat(
                              this.state.organization.staffs_count
                            ) /
                              parseFloat(this.state.organization.staff_limit)) *
                              100}%`
                          }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <p className="text-muted">
                        Members: {this.state.organization.members_count}&nbsp;/&nbsp;
                    {this.state.organization.member_limit}
                      </p>
                      <div className="progress border-radius-20">
                        <div
                          className="progress-bar bg-danger border-radius-20"
                          role="progressbar"
                          style={{
                            width: `${(parseFloat(
                              this.state.organization.members_count
                            ) /
                              parseFloat(this.state.organization.member_limit)) *
                              100}%`
                          }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <p className="text-muted">
                        Storage: {this.state.organization.storage_usage_in_gb}&nbsp;/&nbsp;
                    {this.state.organization.storage_limit}
                      </p>
                      <div className="progress border-radius-20">
                        <div
                          className="progress-bar bg-danger border-radius-20"
                          role="progressbar"
                          style={{
                            width: `${(parseFloat(
                              this.state.organization.storage_usage_in_gb
                            ) /
                              parseFloat(this.state.organization.storage_limit)) *
                              100}%`
                          }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default OrganizationDetails;
