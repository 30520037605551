import React, { Component } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { title } from "change-case";
import _ from "lodash"
import FormButton from "../../UI/button/FormButton";
import Button from "../../UI/button/Button";
import axios from "../../../configAxios";
import Input from "../../UI/input/Input";
import Modal from "../../UI/Modal";
import RentalAgreementModal from "../createAsset/RentalAgreementModal";
import NoImage from "../../../assests/images/no-image.png";

class General extends Component {
  constructor(props) {
    super(props);

    const { categories, locations, creds, status } = this.props.formData;
    const { asset } = this.props;

    const statusText = {
      Active: status["ACTIVE"],
      "Being Serviced": status["SERVICE"],
      Decomissioned: status["DECOMISSIONED"]
      // InActive: status["INACTIVE"]
    };

    let categoryDefaultValue;
    if (categories.length > 0) {
      categoryDefaultValue = {
        label: categories[0][1],
        value: categories[0][0]
      };
    }

    if (asset.category_id) {
      categoryDefaultValue = categories
        .map(type => {
          if (type[0] === this.props.asset.category_id)
            return { label: type[1], value: type[0] };
          return null;
        })
        .filter(item => item !== null)[0];
    }

    let locationDefaultValue;
    if (locations.length > 0) {
      locationDefaultValue = {
        label: locations[0][1],
        value: locations[0][0]
      }
    };

    if (asset.location_id) {
      locationDefaultValue = locations
        .map(type => {
          if (type[0] === this.props.asset.location_id)
            return { label: type[1], value: type[0] };
          return null;
        })
        .filter(item => item !== null)[0];
    }

    let credDefaultValue;
    if (creds.length > 0) {
      credDefaultValue = {
        label: creds[0][1],
        value: creds[0][0]
      }
    };

    if (asset.cred_id) {
      credDefaultValue = creds
        .map(type => {
          if (type[0] === this.props.asset.cred_id)
            return { label: type[1], value: type[0] };
          return null;
        })
        .filter(item => item !== null)[0];
    }

    let thirdPartyData = asset.third_party_rental_agreement && {
      thirdPartyOwner: asset.third_party_rental_agreement.third_party_owner_id,
      favaPercentage: asset.third_party_rental_agreement.middleman_percentage,
      rentalAgreementNotes: asset.third_party_rental_agreement.notes
    }

    this.state = {
      assetType: asset.type,
      assetName: asset.name,
      uniqueName: asset.unique_name,
      assetPhoto: asset.photo,
      assetForSale: asset.asset_for_sale,
      newPhoto: null,
      serialNumber: asset.serial_number,
      accreditation: !_.isNull(asset.cred_id),
      thirdPartyAgreementData: thirdPartyData,
      intialThirdPartyAgreementData: thirdPartyData,
      thirdPartyAgreement: !_.isEmpty(asset.third_party_rental_agreement),
      intialThirdPartyAgreement: !_.isEmpty(asset.third_party_rental_agreement),
      status: statusText[asset.status_text],
      category: {
        elementType: "select",
        elementConfig: {
          name: "category",
          placeholder: "Select Category",
          isClearable: false,
          options: categories.map(type => {
            return { label: type[1], value: type[0] };
          }),
          defaultValue: categoryDefaultValue,
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          }
        },
        selectedOption: categoryDefaultValue
      },
      location: {
        elementType: "select",
        elementConfig: {
          name: "location",
          placeholder: "Select Location",
          isClearable: false,
          options: this.props.formData.locations.map(type => {
            return { label: type[1], value: type[0] };
          }),
          defaultValue: locationDefaultValue,
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          }
        },
        selectedOption: locationDefaultValue
      },
      credName: {
        elementType: "select",
        elementConfig: {
          name: "credName",
          options: this.props.formData.creds.map(type => {
            return { label: type[1], value: type[0] };
          }),
          placeholder: "Select Option",
          isClearable: false,
          components: {
            IndicatorSeparator: () => {
              return null;
            }
          }
        },
        selectedOption: credDefaultValue
      },
      showStatusNotes: false,
      statusNotes: asset.change_notes,
      isOpenRentalAgreementModal: false,
      disabled: false
    };
  }

  updateCredOptions = () => {
    axios.get("/assets/formdata").then(res => {
      const { creds } = res.data.json;
      this.setState({
        credName: {
          ...this.state.credName,
          elementConfig: {
            ...this.state.credName.elementConfig,
            options: creds.map(type => {
              return { label: type[1], value: type[0] };
            })
          }
        }
      });
    });
  };

  inputChangeHandler = (event, key, type) => {
    if (type === "file") {
      this.setState({ newPhoto: event.target.files[0] });
    } else if (type === "select") {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event
        }
      });
    } else if (type === "checkbox" || type === "radio") {
      (key === "status" || key === "thirdPartyAgreement") && window.scrollTo(0, 0);
      this.setState({
        ...key !== "thirdPartyAgreement" && { [key]: event },
        isOpenRentalAgreementModal: key === "thirdPartyAgreement" && event && true,
        showStatusNotes: key === "status" && true
      });
    } else {
      this.setState({ [key]: event.target.value });
    }
  };

  renderRadioButtons = () => {
    const { status: statusObj } = this.props.formData;
    const statusText = {
      Active: statusObj["ACTIVE"],
      "Being Serviced": statusObj["SERVICE"],
      Decommissioned: statusObj["DECOMISSIONED"]
      // InActive: statusObj["INACTIVE"]
    };
    return Object.keys(statusText).map(key => (
      <p
        key={key}
        onClick={() =>
          this.inputChangeHandler(statusText[key], "status", "radio")
        }
        className="cursor"
      >
        <input
          type="radio"
          name="status"
          checked={this.state.status === statusText[key] ? true : false}
        />
        <span className="mx-3">{key}</span>
      </p>
    ));
  };

  saveRentalAgreement = data => {
    this.setState({
      thirdPartyAgreementData: data,
      isOpenRentalAgreementModal: false,
      thirdPartyAgreement: true
    })
  }

  thirdPartyAgreementChangeHandler = () => {
    if (!this.state.thirdPartyAgreement) {
      this.inputChangeHandler(
        !this.state.thirdPartyAgreement,
        "thirdPartyAgreement",
        "checkbox"
      )
    } else {
      this.setState({ thirdPartyAgreement: false });
    }
  }

  updateAssetHandler = (event, status) => {
    //check for accrediation restrictions

    event.preventDefault();
    this.setState({ disabled: true })
    const { asset } = this.props;
    const { category, location } = this.state;
    let assetData = new FormData();

    assetData.append("name", this.state.assetName);

    assetData.append("type_used", this.state.assetType);

    this.state.newPhoto && assetData.append("photo", this.state.newPhoto);

    assetData.append("asset_for_sale", this.state.assetForSale);

    if (status) {
      assetData.append("change_notes", this.state.statusNotes);
      assetData.append("status", this.state.status);
    } else {
      assetData.append("status", this.state.status);
      category.selectedOption && category.selectedOption.value !== asset.category_id &&
        assetData.append("category_id", category.selectedOption.value);

      location.selectedOption && location.selectedOption.value !== asset.location_id &&
        assetData.append("location_id", location.selectedOption.value);

      this.state.serialNumber !== asset.serial_number &&
        assetData.append("serial_number", this.state.serialNumber);

      this.state.uniqueName !== asset.unique_name &&
        assetData.append("unique_name", this.state.uniqueName);

      if (this.state.accreditation) {
        if (this.state.credName.selectedOption &&
          this.state.credName.selectedOption.value !== asset.cred_id) {
          assetData.append("cred_id", this.state.credName.selectedOption.value);
        }
      } else {
        assetData.append("delete_cred", true);
      }
      if (this.state.thirdPartyAgreement && this.state.thirdPartyAgreementData.thirdPartyOwner) {
        const { thirdPartyAgreementData, intialThirdPartyAgreementData } = this.state;
        let flag = true;
        if (!_.isNull(intialThirdPartyAgreementData)) {
          flag = Object.keys(intialThirdPartyAgreementData).some(key => intialThirdPartyAgreementData[key] !== thirdPartyAgreementData[key]);
        }
        const data =
        {
          third_party_owner_id: this.state.thirdPartyAgreementData.thirdPartyOwner,
          middleman_percentage: this.state.thirdPartyAgreementData.favaPercentage,
          notes: this.state.thirdPartyAgreementData.rentalAgreementNotes
        };

        if (flag)
          assetData.append(
            "third_party_rental_agreement_attributes", JSON.stringify(data));
      } else {
        if (this.state.intialThirdPartyAgreement)
          assetData.append("delete_third_party_rental_agreement", true);
      }
    }

    axios.put(`/assets/${asset.id}`, assetData).then(res => {
      if (res.data.json.success) {
        this.setState({
          assetPhoto: res.data.json.data.photo
        });
        toast.success("Asset updated successfully.");
        if (status) {
          this.setState({
            showStatusNotes: false
          });
          this.props.history.push(`/assets/${asset.id}`);
        } else {
          this.props.history.push(`/assets/${asset.id}`);
        }
      } else {
        if (res.data.json.hasOwnProperty("errors")) {
          Object.keys(res.data.json.errors).forEach(error => {
            toast.error(title(error) + " " + res.data.json.errors[error]);
          });
        }
        if (res.data.json.hasOwnProperty("error")) {
          toast.error(res.data.json.error);
        }
        this.setState({ disabled: false })
      }
    }).catch(err => {
      this.setState({ disabled: false })
    });
  };

  render() {
    const statusNotesModal = (
      <Modal show={this.state.showStatusNotes} hide={() => this.setState({ showStatusNotes: false })} >
        <div className="mt-4">
          <label>Status Change Notes</label>
          <Input
            elementType={"textarea"}
            elementConfig={{ rows: "4" }}
            value={this.state.statusNotes}
            changed={event =>
              this.inputChangeHandler(event, "statusNotes", "text")
            }
          />
        </div>
        <div className="d-flex justify-content-center">
          <Button
            type="secondary"
            style={{ marginRight: "10px" }}
            clicked={() => this.setState({ showStatusNotes: false })}
          >
            Cancel
          </Button>
          <Button
            type="warning"
            clicked={event => this.updateAssetHandler(event, true)}
            disabled={this.state.disabled}
          >
            Submit
          </Button>
        </div>
      </Modal>
    );

    const image = this.state.assetPhoto ? this.state.assetPhoto : NoImage;
    return (
      <div className="edit-asset-general">
        {statusNotesModal}
        <RentalAgreementModal
          rentalAgreementModal={this.state.isOpenRentalAgreementModal}
          thirdPartyOwners={this.props.thirdPartyOwners}
          saveRentalAgreement={this.saveRentalAgreement}
          edit={this.state.thirdPartyAgreement}
          rentalAgreementData={this.state.thirdPartyAgreementData}
          closeModal={() => this.setState({ isOpenRentalAgreementModal: false })}
        />
        <form onSubmit={event => this.updateAssetHandler(event, false)}>
          <div>
            <div className="row">
              <div className="col-md-8">
                <div>Status</div>
                <div className="row mt-2 ml-2">{this.renderRadioButtons()}</div>
                <div className="row">
                  <div className="col-md-6">
                    <p>Asset Name</p>
                    <Input
                      elementType={"input"}
                      elementConfig={{ required: true }}
                      value={this.state.assetName}
                      changed={event =>
                        this.inputChangeHandler(event, "assetName", "text")
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <p>Photo <small>(Recommended size 800px By 800px)</small></p>
                    <Input
                      elementType={"file"}
                      value={this.state.photo}
                      changed={event =>
                        this.inputChangeHandler(event, "photo", "file")
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4 d-flex justify-content-center">
                {" "}
                <div className="thumbnail d-none d-md-block bg-cover" style={{
                  width: 170,
                  height: 170,
                  backgroundImage: `url(${image})`,
                  border: "none"
                }} />
              </div>
            </div>
            <div className="row">
              {" "}
              <div className="col-md-4">
                <p>Category</p>
                <Input
                  {...this.state.category}
                  changed={event =>
                    this.inputChangeHandler(event, "category", "select")
                  }
                />
              </div>
              <div className="col-md-4">
                <p>Location</p>
                <Input
                  {...this.state.location}
                  changed={event =>
                    this.inputChangeHandler(event, "location", "select")
                  }
                />
              </div>
              <div className="col-md-4">
                <p>Unique/Quickbook Name</p>
                <Input
                  elementType={"input"}
                  value={this.state.uniqueName}
                  style={{ height: '38px' }}
                  changed={event =>
                    this.inputChangeHandler(event, "uniqueName", "text")
                  }
                />
              </div>
            </div>
            <div className="row">
              {this.state.assetType === "EquipmentItem" && (
                <div className="col-md-4">
                  <p>Serial Number</p>
                  <Input
                    elementType={"input"}
                    value={this.state.serialNumber}
                    style={{ height: '38px' }}
                    changed={event =>
                      this.inputChangeHandler(event, "serialNumber", "text")
                    }
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="w-100">
                <label className="mb-4 ml-3">For Sale</label>
                <div className="row m-0 mb-4">
                  <div
                    onClick={() =>
                      this.inputChangeHandler(
                        !this.state["assetForSale"],
                        "assetForSale",
                        "checkbox"
                      )
                    }
                    className="custom-checkbox col-12"
                  >
                    <div
                      className="box"
                      style={{
                        backgroundColor: this.state["assetForSale"]
                          ? "rgb(117,0,0)"
                          : "white"
                      }}
                    >
                      <i className="fas fa-check check-icon" />
                    </div>
                    <p className="ml-3">This Asset is for sale?</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="w-100">
                <label className="mb-4 ml-3">Accreditation Restrictions</label>
                <div className="row m-0 mb-4">
                  <div
                    onClick={() =>
                      this.inputChangeHandler(
                        !this.state["accreditation"],
                        "accreditation",
                        "checkbox"
                      )
                    }
                    className="custom-checkbox col-12"
                  >
                    <div
                      className="box"
                      style={{
                        backgroundColor: this.state["accreditation"]
                          ? "rgb(117,0,0)"
                          : "white"
                      }}
                    >
                      <i className="fas fa-check check-icon" />
                    </div>
                    <p className="ml-3">This Asset requires a accreditation</p>
                  </div>
                </div>
              </div>
            </div>
            {this.state.accreditation && (
              <div className="row">
                {" "}
                <div className="col-md-4">
                  {" "}
                  <Input
                    {...this.state.credName}
                    changed={event =>
                      this.inputChangeHandler(event, "credName", "select")
                    }
                  />
                </div>
                <div
                  className="col text-primary cursor mt-2"
                  onClick={() => this.props.history.push("/system_settings/users/accreditations")}
                >
                  <u>Create New</u>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <label className="mb-4">Third Party Rental Agreement</label>
                <div className="row">
                  <div className="col-12">
                    <div className="custom-checkbox" style={{ cursor: "default" }}>
                      <div
                        onClick={this.thirdPartyAgreementChangeHandler}
                        className="box"
                        style={{
                          backgroundColor: this.state["thirdPartyAgreement"]
                            ? "rgb(117,0,0)"
                            : "white"
                        }}
                      >
                        <i className="fas fa-check check-icon" />
                      </div>
                      <p className="ml-3">
                        <span className="cursor" onClick={this.thirdPartyAgreementChangeHandler}>
                          This Asset is owned by a Third Party
                        </span>
                        {this.state.thirdPartyAgreement &&
                          <span className="cursor ml-2" onClick={() => { window.scrollTo(0, 0); this.setState({ isOpenRentalAgreementModal: true }) }}>
                            {"|"} <i className="fas fa-pen pl-1"></i>
                          </span>
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center my-4">
            <Button
              type="secondary"
              clicked={() =>
                this.props.history.push(`/assets/${this.props.asset.id}`)
              }
            >
              Cancel
            </Button>
            <FormButton
              className="ml-2"
              disabled={this.state.disabled}
            >
              Save
            </FormButton>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(General);
