import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SwaggerUI from "swagger-ui-react"

import Spinner from "../UI/Spinner";
import axios from "../../configAxios";

import "swagger-ui-react/swagger-ui.css"

class Swagger extends Component {
  constructor(props) {
    super(props);
    this.signal = axios.CancelToken.source();
    this.state = {
      swaggerJSON: {},
      loading: true
    }
  }

  componentDidMount() {
    const subdomain = window.location.hostname.split('.')[0];
    const url = `https://${subdomain}.amsnetwork.ca/api/v3/${subdomain}_doc`;
    axios.get(url, { cancelToken: this.signal.token })
      .then(res => {
        this.modifyJSON({ ...res.data }).then(modifiedConfig => {
          this.setState({
            loading: false,
            swaggerJSON: modifiedConfig
          })
        })
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request being cancelToken")
  }

  modifyJSON = config => {
    return new Promise((resolve, reject) => {
      Object.keys(config.paths).forEach(path => {
        Object.keys(config.paths[path]).forEach(method => {
          if (Object.keys(config.paths[path][method]).includes("consumes")) {
            if (config.paths[path][method].parameters &&
              config.paths[path][method].parameters.some(param => param.in === "formData"))
              config.paths[path][method].consumes = ["application/x-www-form-urlencoded"];
          }
        })
      })
      config.security = [{
        api_key: []
      }]
      return resolve(config);
    })
  }

  requestInterceptor = req => {
    if (req.headers.Authorization)
      req.headers.Authorization = "Bearer " + req.headers.Authorization;
    return req;
  }

  render() {
    return (
      <>
        {this.state.loading
          ? <Spinner />
          : <SwaggerUI spec={this.state.swaggerJSON} requestInterceptor={this.requestInterceptor} />
        }
      </>
    );
  }
}

export default withRouter(Swagger);
