import React from "react";
import Button from "./Button"

const BackLink = ({ history, backUrl, children }) => {
  return (
    <div className="d-flex flex-row justify-content-start">
      <Button
        type="success"
        clicked={() => {
          history.push(backUrl)
        }}
      >
        {children}
      </Button>
    </div>
  )
}

export default BackLink
