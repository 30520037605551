import React, { useEffect, useState } from "react"
import axios from "axios"
import { isEmpty } from "lodash"
import { withRouter } from "react-router-dom"

import CreatePromoForm from "./CreatePromoForm"
import NoPromos from "./NoPromos"
import PromoItem from "./PromoItem"
import onError from "../../../utils/onError"

const PromoDetails = ({ match }) => {
  const { id: programId } = match.params
  const [promos, setPromos] = useState([])

  useEffect(() => getProgramPromos(), [])

  const getProgramPromos = () => {
    axios
      .get(`/programs/${programId}/promos`)
      .then((res) => {
        const { data: allPromos } = res.data.json
        setPromos(allPromos)
      })
      .catch((err) => {
        const { error: resError } = err.response.data.json
        onError(resError)
      })
  }
  return (
    <>
      {isEmpty(promos) && <NoPromos />}
      {promos?.map((promo) => (
        <PromoItem
          programId={programId}
          promo={promo}
          setPromos={setPromos}
        />
      ))}
      <CreatePromoForm
        programId={programId}
        setPromos={setPromos}
      />
    </>
  )
}

export default withRouter(PromoDetails)
