import React, { Component } from "react";

import Spinner from "../../UI/Spinner";
import axios from "../../../configAxios";

import TicketItem from "./TicketItem";

class TicketItems extends Component {
  signal = axios.CancelToken.source();
  state = {
    tickets: [],
    loading: true
  };

  async componentDidMount() {
    const policyRes = await axios.get("/assets/policy", { cancelToken: this.signal.token });
    if (policyRes.data.json.ticket_builder) {
      this.getTickets(true);
    }
  }

  getTickets = async loading => {
    this.setState({ loading });
    const ticketBuilderRes = await axios.get("/assets/ticket_builder", { cancelToken: this.signal.token });
    this.setState({ tickets: ticketBuilderRes.data.assets });
    this.setState({ loading: false });
  };

  render() {
    const { tickets } = this.state;

    let TicketItems = tickets.map(ticket => (
      <TicketItem
        key={ticket.id}
        ticket={ticket}
        getTickets={this.getTickets}
      />
    ));

    if (this.state.loading) {
      return (
        <Spinner className="h-40vh" />
      )
    } else {
      return (
        <ul className="list-group list-group-flush border-top">{TicketItems}</ul>
      )
    }
  }
}

export default TicketItems;
