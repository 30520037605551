import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { title, snakeCase } from "change-case"
import axios from "../../../configAxios"

import ProjectAttributes from "./ProjectAttributes"
import ProjectCues from "./ProjectCues"
import Contests from "./Contests"
import Votes from "./Votes"
import Setting from "./Setting"

import "./Projects.css"

class Projects extends Component {
  signal = axios.CancelToken.source()
  state = {
    activeTab: "Project Attributes",
    newForm: false,
    edit: false,
    details: false,
    id: null,
    name: null,
  }

  componentDidMount() {
    this.handleTabFromProp()
    window.addEventListener("popstate", this.handleTabFromProp)
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleTabFromProp)
    this.signal.cancel("Request is being Cancelled")
  }

  handleTabFromProp = () => {
    this.setState({
      ...this.state,
      activeTab:
        typeof this.props.match.params.toptab !== "undefined"
          ? title(this.props.match.params.toptab)
          : "Project Attributes",
    })
  }

  tabChangeHandler = (activeTab, action, id, name) => {
    this.setState({
      ...this.state,
      activeTab,
      edit: action === "edit",
      newForm: false,
      edit: false,
      details: false,
      id,
      name,
    })
    this.props.history.push(`/system_settings/projects/${snakeCase(activeTab)}`)
  }

  showCreateContestForm = () => {
    this.setState({ newForm: true, edit: false, details: false })
  }

  showEditContestForm = (id) => {
    this.setState({ newForm: false, edit: true, details: false, id })
  }

  showContestDetails = (id) => {
    this.setState({ newForm: false, edit: false, details: true, id })
  }

  showContests = () => {
    this.setState({ newForm: false, edit: false, details: false })
  }

  render() {
    const tabNames = [
      "Project Attributes",
      "Project Submissions",
      "Contests",
      "Setting",
    ]
    const tabs = tabNames.map((tab, index) => (
      <li
        key={index}
        onClick={() => this.tabChangeHandler(tab)}
        className={`nav-item font-weight-bold px-4 ${
          this.state.activeTab === tab ? "active-tab " : "inactive-tab"
        }`}
      >
        {tab}
      </li>
    ))
    let activeTab
    switch (this.state.activeTab) {
      case "Project Attributes":
        activeTab = <ProjectAttributes />
        break
      case "Project Submissions":
        activeTab = <ProjectCues />
        break
      case "Contests":
        activeTab = (
          <Contests
            createForm={this.createForm}
            tabChangeHandler={this.tabChangeHandler}
            showCreateContestForm={this.showCreateContestForm}
            showEditContestForm={this.showEditContestForm}
            showContests={this.showContests}
            showContestDetails={this.showContestDetails}
            newForm={this.state.newForm}
            edit={this.state.edit}
            details={this.state.details}
            id={this.state.id}
          />
        )
        break
      case "Votes":
        activeTab = (
          <Votes
            id={this.state.id}
            name={this.state.name}
            showContests={this.showContests}
            tabChangeHandler={this.tabChangeHandler}
          />
        )
        break
      case "Setting":
        activeTab = <Setting />
        break
      default:
        activeTab = null
    }
    return (
      <div className="card rentals">
        <ul className="nav nav-tabs ml-0">{tabs}</ul>

        {activeTab}
      </div>
    )
  }
}

export default withRouter(Projects)
