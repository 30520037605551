import React from "react"
import CreateLabelCategory from "./CreateLabelCategory"

const LabelHeader = ({ onCategoryUpdate }) => {
  return (
    <div className="list-group-item">
      <div className="row">
        <div className="col-5 my-auto">
          <span className="font-weight-bold">Label Category</span>
        </div>
        <div className="col-3 text-center">

        </div>
        <div className="col-4 text-right">
          <CreateLabelCategory onComplete={onCategoryUpdate} />
        </div>
      </div>
    </div>
  )
}

export default LabelHeader
