import React, { Component } from 'react';
import { toast } from "react-toastify";
import { title } from "change-case";
import { withRouter } from "react-router-dom"
import { components } from "react-select"

import axios from '../../../../configAxios'
import Button from '../../../UI/button/Button'
import Input from '../../../UI/input/Input'
import Spinner from "../../../UI/Spinner"
import FormButton from '../../../UI/button/FormButton'

import Config from "../../communication/config";
import CKEditorFull from "../../communication/CkEditorFull"

import "./CampaignForm.scss"

class CampaignForm extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: this.props.edit,
    disableSubmit: false,
    usingTemplate: false,

    body: "",
    formFields: {
      subject: {
        elementType: "input",
        elementConfig: {
          name: "subject",
          type: "text",
          required: true
        },
        label: "Subject",
        value: ""
      },
      mailingLists: {
        elementType: "select",
        elementConfig: {
          name: "mailing_lists",
          placeholder: "Select Mailing Lists",
          components: {
            IndicatorSeparator: () => {
              return null;
            },
            DropdownIndicator: () => {
              return null;
            }
          },
          isSearchable: true,
          isClearable: true,
          isLoading: true,
          isMulti: true,
          closeMenuOnSelect: false
        },
        selectedOption: [],
        label: "Mailing Lists"
      },
      email: {
        elementType: "select",
        elementConfig: {
          name: "email",
          placeholder: "Select an Email",
          components: {
            IndicatorSeparator: () => null,
            Option: props => {
              const { data, innerProps, innerRef, getStyles } = props;
              return data.custom
                ? (
                  <div
                    ref={innerRef}
                    {...innerProps}
                    className="text-primary cursor text-underline-onhover"
                    style={{
                      ...getStyles("option", props),
                      cursor: "pointer"
                    }}
                    onClick={e => this.props.history.push("/system_settings/communication/email_adresses")}
                  >
                    Verify new email address...
                  </div>
                )
                : (
                  <components.Option {...props} />
                )
            }
          },
          isSearchable: true,
          isClearable: true,
          isLoading: true,
          required: true,
          options: [{ custom: true, isDisabled: true }],
        },
        customStyle: {
          menu: styles => ({ ...styles, zIndex: "3" })
        },
        selectedOption: null,
      }
    },
    template_data: {}
  }

  componentDidMount() {
    this.clearLocalStorageForTemplates()
    Promise.allSettled([
      axios.get("/organization_communication_emails", { cancelToken: this.signal.token }),
      axios.get(`/campaigns/formdata`, { cancelToken: this.signal.token })
    ]).then(([emailData, formDataResults]) => {
      let updateState = {}
      let emailOption = []
      if (emailData.value.data) {
        const { data: emails } = emailData.value;
        if (Array.isArray(emails.organization_communication_emails)) {
          emailOption = emails.organization_communication_emails
            .filter(email => (email.is_verified))
            .map(email => ({ label: email.email, value: email.id }))
        }

        emailOption.push(...this.state.formFields.email.elementConfig.options);

        updateState.email = {
          ...this.state.formFields.email,
          elementConfig: {
            ...this.state.formFields.email.elementConfig,
            isLoading: false,
            options: emailOption
          }
        }
      }

      if (formDataResults.value.data) {
        const { data: formDataValues } = formDataResults.value;
        updateState.mailingLists = {
          ...this.state.formFields.mailingLists,
          elementConfig: {
            ...this.state.formFields.mailingLists.elementConfig,
            options: formDataValues.json.mailing_lists.map(mailingList => ({
              value: mailingList.id,
              label: mailingList.name
            })),
            isLoading: false
          }
        }
      }

      this.setState({
        formFields: {
          ...this.state.formFields,
          ...updateState
        }
      }, () => {
        if (this.props.edit)
          this.getEditFormData();
      })

    })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
    this.clearLocalStorageForTemplates();
  }

  getEditFormData = () => {
    axios.get(`/campaigns/${this.props.campaignId}`, { cancelToken: this.signal.token })
      .then(res => {
        const { options } = this.state.formFields.email.elementConfig;
        const selectedEmail = options.find(email => (
          res.data.json.data.email && email.label === res.data.json.data.email
        ))
        this.setState({
          ...this.state,
          formFields: {
            ...this.state.formFields,
            subject: {
              ...this.state.formFields.subject,
              value: res.data.json.data.subject
            },
            mailingLists: {
              ...this.state.formFields.mailingLists,
              selectedOption: res.data.json.data.mailing_lists.map(mailing_list => ({ label: mailing_list.name, value: mailing_list.id }))
            },
            email: {
              ...this.state.formFields.email,
              selectedOption: selectedEmail
                ? selectedEmail
                : null
            }
          },
          body: res.data.json.data.body,
          usingTemplate: res.data.json.data.format === "html",
          loading: false
        })
        if (res.data.json.data.format === "html") {
          this.setState({
            ...this.state,
            template_data: {
              "gjs-assets": res.data.json.data.data["gjs-assets"],
              "gjs-css": res.data.json.data.data["gjs-css"],
              "gjs-styles": res.data.json.data.data["gjs-styles"],
              "gjs-html": res.data.json.data.data["gjs-html"],
              "gjs-components": res.data.json.data.data["gjs-components"]
            }
          }, () => {
            localStorage.setItem("gjs-assets", res.data.json.data.data["gjs-assets"])
            localStorage.setItem("gjs-css", res.data.json.data.data["gjs-css"])
            localStorage.setItem("gjs-styles", res.data.json.data.data["gjs-styles"])
            localStorage.setItem("gjs-html", res.data.json.data.data["gjs-html"])
            localStorage.setItem("gjs-components", res.data.json.data.data["gjs-components"])
          })
        }
      })
  }

  closeModal = () => {
    localStorage.setItem("gjs-assets", this.state.template_data["gjs-assets"])
    localStorage.setItem("gjs-css", this.state.template_data["gjs-css"])
    localStorage.setItem("gjs-styles", this.state.template_data["gjs-styles"])
    localStorage.setItem("gjs-html", this.state.template_data["gjs-html"])
    localStorage.setItem("gjs-components", this.state.template_data["gjs-components"])
  }

  clearLocalStorageForTemplates = () => {
    localStorage.removeItem('gjs-styles')
    localStorage.removeItem('gjs-css')
    localStorage.removeItem('gjs-components')
    localStorage.removeItem('gjs-html')
    localStorage.removeItem('gjs-assets')
  }

  inputChangedHandler = (event, key) => {
    if (key === "mailingLists" || key === "email") {
      this.setState({
        ...this.state,
        formFields: {
          ...this.state.formFields,
          [key]: {
            ...this.state.formFields[key],
            selectedOption: event
          }
        }
      })
    } else if (key === "body") {
      this.setState({
        ...this.state,
        body: event.editor.getData()
      })
    } else {
      let formFields = this.state.formFields
      formFields[key].value = event.target.value

      this.setState({ ...this.state, formFields });
    }
  }

  setHTMLForEmail = () => {
    return new Promise((resolve, reject) => {
      return resolve({
        email: this.state.formFields.email.selectedOption.label,
        subject: this.state.formFields.subject.value,
        mailing_list_ids: this.state.formFields.mailingLists.selectedOption.map(mailingList => mailingList.value).join(),
        body: this.state.body,
        format: "html",
        data: this.state.template_data
      })
    })
  }
  submitHandler = async e => {
    e.preventDefault()

    if (this.state.formFields.mailingLists.selectedOption.length === 0) {
      toast.error("You must select Mailing List for this Campaigns")
      return
    }

    this.setState({ ...this.state, disableSubmit: true })

    let data;

    if (this.state.usingTemplate) {
      data = await this.setHTMLForEmail();
      this.clearLocalStorageForTemplates();
    } else {
      data = {
        email: this.state.formFields.email.selectedOption.label,
        subject: this.state.formFields.subject.value,
        mailing_list_ids: this.state.formFields.mailingLists.selectedOption.map(mailingList => mailingList.value).join(),
        body: this.state.body,
        format: "text",
      }
    }

    if (this.props.edit && !this.props.duplicate) {
      axios.put(`/campaigns/${this.props.campaignId}`, data)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Campaign Updated Successfully")
            this.props.closeForm()
            this.props.getCampaigns();
          } else {
            this.setState({ ...this.state, disableSubmit: false })
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    } else {
      axios.post('/campaigns', data)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Campaign Created Successfully")
            this.props.closeForm()
            this.props.getCampaigns();
          } else {
            this.setState({ ...this.state, disableSubmit: false })
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
        })
    }
  }

  render() {
    return (
      <>
        {this.state.loading && <Spinner />}
        <div className="p-4">
          <div className="row">
            <div className="col-12">
              <h5 className="font-weight-bold">
                {
                  this.props.edit && !this.props.duplicate ? "Update Campaign" :
                    this.props.duplicate ? "Duplicate Campaign" : "New Campaign"
                }
              </h5>
            </div>
          </div>

          <form onSubmit={this.submitHandler} className="mt-3">
            <div className="row">
              <div className="col-12">
                <Input
                  {...this.state.formFields.email}
                  changed={event => this.inputChangedHandler(event, "email")}
                />
                <Input
                  {...this.state.formFields.subject}
                  changed={event => this.inputChangedHandler(event, "subject")}
                />
                <Input
                  {...this.state.formFields.mailingLists}
                  changed={event => this.inputChangedHandler(event, "mailingLists")}
                />

                <div className="row mb-2">
                  <div className="col-6">
                    <label>Email Body</label>
                  </div>
                </div>

                {this.state.usingTemplate ? (
                  <div className="card border rounded text-center">
                    <div className="row">
                      <div className="col-lg-10 offset-lg-1 p-5">
                        <h4 className="font-weight-bold mb-3">Please Use Text Editor</h4>
                        <button
                          type="button"
                          onClick={() => this.setState({ ...this.state, body: "", usingTemplate: false })}
                          className="btn btn-sm btn-outline-secondary"
                        >
                          <i className="fas fa-keyboard mr-1" />
                          Use Text Editor
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="body-text">
                    <div id="toolbar-container" />
                    <CKEditorFull
                      data={this.state.body}
                      config={Config}
                      onChange={event => this.inputChangedHandler(event, "body")}
                      onBeforeLoad={(CKEDITOR) => ( CKEDITOR.disableAutoInline = true )}
                    />
                  </div>
                )}

                <div className="text-center p-3">
                  <Button
                    type="secondary"
                    clicked={() => { this.clearLocalStorageForTemplates(); this.props.closeForm(); }}
                    className="mr-2"
                  >
                    Cancel
                  </Button>
                  <FormButton disabled={this.state.disableSubmit}>
                    {
                      this.props.edit && !this.props.duplicate ? "Update" :
                        this.props.duplicate ? "Duplicate" : "Create"
                    }
                  </FormButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }
}

export default withRouter(CampaignForm);
