import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import  { connect } from "react-redux"
import { getAdminStatus } from "../../../store/actions"
import { toast } from "react-toastify"
import { title } from "change-case"
import Calendar from "react-calendar"
import dateFns, { eachDay } from "date-fns"
import { parseFromTimeZone } from "date-fns-timezone"
import _ from "lodash"

import Input from "../..//UI/input/Input"
import Button from "../..//UI/button/Button"
import axios from "../../../configAxios"
import Spinner from "../../UI/Spinner"

import "./BookAsset.css"

class BookAsset extends Component {
  signal = axios.CancelToken.source()
  state = {
    memberType: {
      elementType: "select",
      elementConfig: {
        name: "type",
        placeholder: "Type",
        options: [],
        defaultValue: {},
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
    },
    quantity: {
      elementType: "input",
      elementConfig: {
        name: "quantity",
        type: "number",
        required: true,
      },
      value: "1",
    },
    startTimePicker: {
      elementType: "select",
      elementConfig: {
        name: "startTimePicker",
        placeholder: "Start Time",
        options: [],
        defaultValue: {},
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
    },
    endTimePicker: {
      elementType: "select",
      elementConfig: {
        name: "endTimePicker",
        placeholder: "End Time",
        options: [],
        defaultValue: {},
        components: {
          IndicatorSeparator: () => {
            return null
          },
        },
      },
      selectedOption: null,
    },
    bookingDateRange: [],
    bookingDates: [],
    isRangeSelect: true,
    monthRange: {
      startOfMonth: dateFns.startOfMonth(new Date()),
      endOfMonth: dateFns.endOfMonth(new Date()),
    },
    selectedAsset: this.props.selectedAsset,
    ticketId: this.props.ticketId,
    disabled: true,
    bookedDates: {},
    facilityBookedDates: {},
    isProgram: true,
    loading: false,
    bulkCount: [],
    currentDate: new Date(),
  }

  componentDidMount() {
    const { getAdminStatus, userID } = this.props
    getAdminStatus(userID)

    const { selectedAsset } = this.state
    const defaultOption = {
      label: selectedAsset["price_types"][0][0],
      value: selectedAsset["price_types"][0][1],
    }
    const options = []
    for (let i = 0; i < 24; i++) {
      options.push({ label: `${i}:00`, value: `${i}:00` })
      options.push({ label: `${i}:15`, value: `${i}:15` })
      options.push({ label: `${i}:30`, value: `${i}:30` })
      options.push({ label: `${i}:45`, value: `${i}:45` })
    }
    this.setState({
      startTimePicker: {
        ...this.state.startTimePicker,
        elementConfig: {
          ...this.state.startTimePicker.elementConfig,
          options: options,
        },
      },
      endTimePicker: {
        ...this.state.endTimePicker,
        elementConfig: {
          ...this.state.endTimePicker.elementConfig,
          options: options,
        },
      },
      memberType: {
        ...this.state.memberType,
        elementConfig: {
          ...this.state.memberType.elementConfig,
          defaultValue: defaultOption,
          options: selectedAsset["price_types"].map((type) => {
            return { label: type[0], value: type[1] }
          }),
        },
        selectedOption: defaultOption,
      },
    })
    const startOfMonth = dateFns.addDays(dateFns.startOfMonth(new Date()), 1)
    this.monthChangeHandler({ activeStartDate: startOfMonth })
  }

  componentWillReceiveProps(props) {
    this.setState({ ticketId: props.ticketId })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  dateChangeHandler = (newDate) => {
    const { bookingDateRange, bookingDates, isRangeSelect } = this.state
    const dateFormatString = "YYYY-MM-DD"
    const date = dateFns.format(newDate, dateFormatString)
    if (isRangeSelect) {
      let newDateRange = [...bookingDateRange]
      if (newDateRange.length >= 2) {
        newDateRange = [date]
      } else {
        newDateRange.push(date)
        newDateRange.sort(dateFns.compareAsc)
      }
      const finalDates = eachDay(newDateRange[0], newDateRange[1])
      this.setState({
        bookingDateRange: [...newDateRange],
        bookingDates: finalDates,
        disabled: finalDates.length < 1,
      })
    } else {
      let newDates
      const exists = this.isExistingDate(bookingDates, date)
      if (!exists) {
        newDates = [...bookingDates]
        newDates.push(date)
      } else {
        newDates = bookingDates.filter((newDate) => newDate !== date)
      }
      this.setState({
        bookingDates: [...newDates],
        disabled: newDates.length < 1,
      })
    }
  }

  isExistingDate = (dateArray, date) => {
    const dateFormatString = "YYYY-MM-DD"
    let contains = false
    dateArray.forEach((element) => {
      if (dateFns.format(date, dateFormatString) === dateFns.format(element, dateFormatString))
      {
        contains = true
        return
      }
    })
    return contains
  }

  inputChangeHandler = (event, key) => {
    if (key === "quantity") {
      this.setState({
        quantity: {
          ...this.state.quantity,
          value: event.target.value,
        },
      })
    } else {
      this.setState({
        [key]: {
          ...this.state[key],
          selectedOption: event,
        },
      })
    }
  }

  monthChangeHandler = () => {
    this.setState({ loading: true, disabled: true })
    let startOfMonth = dateFns.startOfMonth(this.state.currentDate)
    let endOfMonth = dateFns.endOfMonth(this.state.currentDate)
    this.setState(
      {
        monthRange: {
          startOfMonth,
          endOfMonth,
        },
      },
      () => {
        this.getBookedDates()
      }
    )
  }

  getBookedDates = () => {
    const { selectedAsset, monthRange } = this.state
    let bulkCount = []
    if (selectedAsset.is_bulk_asset) {
      axios
        .get("/assets/get_bulk_count", {
          params: {
            id: selectedAsset.id,
            year: dateFns.getYear(monthRange.startOfMonth),
            month: dateFns.getMonth(monthRange.startOfMonth) + 1,
            calendar_type: selectedAsset.type,
          },
          cancelToken: this.signal.token,
        })
        .then((res) => {
          bulkCount = res.data.json.bulk_count_total
          this.setState({
            bulkCount,
          })
        })
    }
    axios
      .get("/assets/calendar", {
        params: {
          id: selectedAsset.id,
          year: dateFns.getYear(monthRange.startOfMonth),
          month: dateFns.getMonth(monthRange.startOfMonth) + 1,
        },
        cancelToken: this.signal.token,
      })
      .then((res) => {
        const { assets } = res.data
        const bookedDates = {}
        const facilityBookedDates = {}
        assets.map((asset) => {
          let title
          if (asset.booking.user_or_program === "User") {
            title =
              asset?.booking?.user_or_program_detail?.organization_name ||
              `${asset?.booking?.user_or_program_detail?.first_name} ${asset?.booking?.user_or_program_detail?.last_name}`
          } else {
            title = asset?.booking?.user_or_program_detail?.name
          }
          const userOrProgramId = asset?.booking?.user_or_program_detail?.id
          const userOrProgram = asset.booking.user_or_program
          if (selectedAsset.type !== "Facility") {
            if (!selectedAsset.is_bulk_asset) {
              let startDate = new Date(asset.booking.details.start_time) //YYYY-MM-DD
              let endDate = new Date(asset.booking.details.end_time) //YYYY-MM-DD
              let currentMonth = dateFns.getMonth(monthRange.startOfMonth)
              let date = new Date(startDate)
              while (date <= endDate) {
                const day = dateFns.getDate(date)
                if (dateFns.getMonth(date) === currentMonth) {
                  bookedDates[day] = { userOrProgramId, title, userOrProgram }
                }
                date.setDate(date.getDate() + 1)
              }
            }
          } else {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            let parsedStartDate = parseFromTimeZone(asset.booking.details.start_time, { timeZone, })
            let parsedEndDate = parseFromTimeZone(asset.booking.details.end_time, { timeZone, })
            let startTime = dateFns.format(parsedStartDate, "hh:mm a")
            let endTime = dateFns.format(parsedEndDate, "hh:mm a")
            let date = new Date(parsedStartDate)
            const endDate = new Date(parsedEndDate)
            const currentMonth = dateFns.getMonth(monthRange.startOfMonth)
            while (date <= endDate) {
              if (dateFns.getMonth(date) === currentMonth) {
                const day = dateFns.getDate(date)
                facilityBookedDates[day] = {
                  ...facilityBookedDates[day],
                  [`${startTime}-${endTime}`]: {
                    userOrProgramId,
                    title,
                    userOrProgram,
                  },
                }
              }
              date.setDate(date.getDate() + 1)
            }
          }
          return facilityBookedDates
        })
        this.setState({ bookedDates, facilityBookedDates, loading: false })
      })
  }

  bookingHandler = () => {
    this.setState({ disabled: true })
    const {
      memberType,
      bookingDates,
      selectedAsset,
      ticketId,
      quantity,
      startTimePicker,
      endTimePicker,
    } = this.state
    const formData = {
      asset_id: selectedAsset.id,
      ticketable_type: this.props.userId ? "User" : "Program",
      ticketable_id: this.props.userId ? this.props.userId : this.props.programId,
      price_type: memberType.selectedOption.value,
      ticket_id: ticketId,
      calendar_type: selectedAsset.type,
    }
    if (selectedAsset.is_bulk_asset) {
      formData["quantity"] = parseInt(quantity.value)
    }
    const dateFormatString = `YYYY-MM-DD`
    formData["start"] = startTimePicker.selectedOption ? startTimePicker.selectedOption.value : null
    formData["end"] = endTimePicker.selectedOption ? endTimePicker.selectedOption.value : null
    const bookingDatesArr = bookingDates.map((date) => dateFns.format(date, dateFormatString))
    formData["booking_dates"] = bookingDatesArr.join(", ")
    axios
      .post("/bookings", formData)
      .then((res) => {
        const { success } = res.data.json
        if (success) {
          toast.success("Asset booked successfully.")
          this.props.closeModal()
          this.props.getTicket()
        } else {
          if (res.data.json.hasOwnProperty("errors")) {
            const errors = res.data.json.errors
            if (errors.booking.length) {
              errors.booking.forEach((error) => {
                if (typeof error[0] === "string") toast.error(error[0])
              })
            }
            if (errors.insufficient_quantity) {
              toast.error(errors.insufficient_quantity[0])
            }
            if (errors.ticket) {
              Object.keys(errors.ticket).forEach((error) => {
                toast.error(`${title(error)} ${errors.ticket[error][0]}`)
              })
            }
          }
          if (res.data.json.hasOwnProperty("error")) {
            toast.error(res.data.json.error)
          }
          this.setState({ disabled: false })
        }
      })
      .catch((err) => console.log(err))
  }

  navigateToUserDetails = (userOrProgramId, userOrProgram) => {
    if (userOrProgram === "User") {
      if (
        this.props.userId &&
        parseInt(this.props.userId) === userOrProgramId
      ) {
        this.props.tabChangeHandler("Summary", null, userOrProgramId)
      } else {
        this.props.history.push(`/users/${userOrProgramId}/summary`)
      }
    } else {
      this.props.history.push(`/programs/${userOrProgramId}`)
    }
  }
  adminOrOwner = (userOrProgramId, userOrProgram) => {
    const equalUserIds = (userOrProgram === 'User') && (userOrProgramId === Number(this.props.userId))
    if (equalUserIds || this.props.isAdmin ) return true
    return false
  }

  tileDisabled = ({ date }) => {
    let {
      bookedDates,
      monthRange,
      selectedAsset,
      facilityBookedDates,
    } = this.state
    bookedDates = selectedAsset.type !== "Facility" ? bookedDates : facilityBookedDates
    let disabled = false
    if (dateFns.getMonth(date) === dateFns.getMonth(monthRange.startOfMonth) - 1)
    {
      return true
    }

    Object.keys(bookedDates).map((bookedDate) => {
      if (
        dateFns.getMonth(monthRange.startOfMonth) === dateFns.getMonth(date) &&
        parseInt(bookedDate) === dateFns.getDate(date)
      )
      {
        disabled = dateFns.getDate(date) === parseInt(bookedDate)
      }
      return disabled
    })
  }

  rangeToggleChange = (value) => {
    this.setState({
      isRangeSelect: value,
      bookingDateRange: [],
      bookingDates: [],
      disabled: true,
      startTimePicker: {
        ...this.state.startTimePicker,
        selectedOption: null,
      },
      endTimePicker: {
        ...this.state.endTimePicker,
        selectedOption: null,
      },
    })
  }

  render() {
    const { defaultValue } = this.state.memberType.elementConfig
    const {
      selectedAsset,
      bookedDates,
      monthRange,
      bulkCount,
      facilityBookedDates,
      bookingDateRange,
      loading,
      disabled,
      isRangeSelect,
      startTimePicker,
      endTimePicker,
    } = this.state
    let [startDate, endDate] = bookingDateRange
    const { isAdmin, userID } = this.props

    const tileContent = ({ date, view }) => {
      let content
      bulkCount &&
      bulkCount.map((count, index) => {
          if (view === "month" && dateFns.getMonth(monthRange.startOfMonth) ===
              dateFns.getMonth(date) && this.state.selectedAsset.is_bulk_asset &&
            parseInt(count.date) === dateFns.getDate(date))
          {
            content = <div key={index}>{`${count.bulk_count_left} left`}</div>
          }
          return content
        })
      bookedDates &&
        Object.keys(bookedDates).forEach((bookedDate, index) => {
          if (view === "month" && dateFns.getMonth(monthRange.startOfMonth) ===
              dateFns.getMonth(date) && parseInt(bookedDate) === dateFns.getDate(date))
          {
            const { userOrProgramId, userOrProgram, title: entityTitle } = bookedDates[bookedDate]
            content = (
              <div
                key={`${userOrProgram}-${index}`}
                onClick={() => {
                  if (!this.adminOrOwner(userOrProgramId, userOrProgram)) return null
                  this.navigateToUserDetails(userOrProgramId, userOrProgram)
                }}
                disabled={!this.adminOrOwner(userOrProgramId, userOrProgram)}
                className="booked-text cursor"
              >
                <div className="mt-1" />
                <div style={{ wordBreak: "break-word" }}>
                  {this.adminOrOwner(userOrProgramId, userOrProgram) ? entityTitle : 'Unavailable'}
                </div>
              </div>
            )
          }
        })
      return content
    }

    const facilityTileContent = ({ date, view }) => {
      let content
      if ( view === "month" && dateFns.getMonth(monthRange.startOfMonth) === dateFns.getMonth(date))
      {
        facilityBookedDates &&
          Object.keys(facilityBookedDates).map((bookedDate) => {
            if (parseInt(bookedDate) === dateFns.getDate(date)) {
              let timeContent = []
              Object.keys(facilityBookedDates[bookedDate]).map((time, index) => {
                const { title: entityTitle, userOrProgramId, userOrProgram, } = facilityBookedDates[bookedDate][time]
                timeContent.push(
                  <div key={`${userOrProgram}-${index}`}>
                    <div>{this.adminOrOwner(userOrProgramId, userOrProgram) ? entityTitle : 'Unavailable'}</div>
                    <div
                      onClick={() => {
                        if (!this.adminOrOwner(userOrProgramId, userOrProgram)) return null
                        this.navigateToUserDetails(userOrProgramId, userOrProgram)
                      }}
                      disabled={!this.adminOrOwner(userOrProgramId, userOrProgram)}
                      className="booked-text cursor text-decoration-underline"
                    >
                      <u>{time}</u>
                    </div>
                  </div>
                )
                return timeContent
              })
              content = <div className="time-link">{timeContent}</div>
            }
            return content
          })
      }
      return content
    }

    const tileClassName = ({ date, view }) => {
      let {
        bookedDates,
        monthRange,
        bookingDates,
        facilityBookedDates,
        selectedAsset,
      } = this.state
      let className
      let startOfToday = dateFns.startOfDay(new Date())
      if (date < startOfToday) {
        className = "previous-dates"
      } else {
        if (view === "month" && (dateFns.getDay(date) === 0 || dateFns.getDay(date) === 6))
        {
          className = "weekend"
        }
      }
      bookedDates = selectedAsset.type !== "Facility" ? bookedDates : facilityBookedDates
      Object.keys(bookedDates).map((bookedDate) => {
        if (view === "month" && dateFns.getMonth(monthRange.startOfMonth) ===
            dateFns.getMonth(date) && parseInt(bookedDate) === dateFns.getDate(date))
        {
          className = "booked-color"
        }
        return className
      })
      if (this.isExistingDate(bookingDates, date)) {
        className = "currently-selected"
      }
      return className
    }

    return (
      <div className="book-asset py-3">
        <div className="col-md-12">
          <label>Member Type</label>
          {!_.isEmpty(defaultValue) && (
            <Input
              {...this.state.memberType}
              changed={(event) => this.inputChangeHandler(event, "memberType")}
            />
          )}
        </div>
        {selectedAsset && selectedAsset.is_bulk_asset && (
          <div className="col-md-12">
            <label>Quantity </label>
            <Input
              {...this.state.quantity}
              changed={(event) => this.inputChangeHandler(event, "quantity")}
            />
          </div>
        )}
        {selectedAsset && selectedAsset.type !== "Facility" ? (
          <div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <label>From</label>
                  <Input
                    elementConfig={{ readOnly: true }}
                    key={startDate}
                    value={
                      startDate &&
                      `${dateFns.getYear(startDate)}-${
                        dateFns.getMonth(startDate) + 1
                      }-${dateFns.getDate(startDate)}`
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label>To</label>
                  <Input
                    elementConfig={{ readOnly: true }}
                    key={endDate}
                    value={
                      endDate &&
                      `${dateFns.getYear(endDate)}-${
                        dateFns.getMonth(endDate) + 1
                      }-${dateFns.getDate(endDate)}`
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <Input
                  {...startTimePicker}
                  changed={(event) =>
                    this.inputChangeHandler(event, "startTimePicker")
                  }
                />
              </div>
              <div className="col-md-6">
                <Input
                  {...endTimePicker}
                  changed={(event) =>
                    this.inputChangeHandler(event, "endTimePicker")
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className="col-md-12 d-flex justify-content-center">
          <Button
            type="primary"
            clicked={this.bookingHandler}
            disabled={disabled}
            style={{ cursor: disabled ? "no-drop" : "pointer" }}
          >
            Book
          </Button>
          <div className="pl-3">
            <Button type="secondary" clicked={this.props.closeModal}>
              Cancel
            </Button>
          </div>
        </div>
        <div className="col-md-12 my-3 text-center">
          {loading && <Spinner />}
          <div style={{ display: !loading ? "block" : "none" }}>
            <div className="d-flex justify-content-between align-items-center px-3 py-4 calendar-navigation-border">
              <div className="ml-4">
                <i
                  className="fa fa-angle-left cursor"
                  style={{ fontSize: "20px" }}
                  onClick={() =>
                    this.setState(
                      {
                        currentDate: dateFns.subMonths(
                          this.state.currentDate,
                          1
                        ),
                      },
                      () => this.monthChangeHandler()
                    )
                  }
                />
              </div>
              <div className="calendar-navigation-label">
                {dateFns.format(this.state.currentDate, "MMMM YYYY")}
              </div>
              <div className="mr-4">
                <i
                  className="fa fa-angle-right cursor"
                  style={{ fontSize: "20px" }}
                  onClick={() =>
                    this.setState(
                      {
                        currentDate: dateFns.addMonths(
                          this.state.currentDate,
                          1
                        ),
                      },
                      () => this.monthChangeHandler()
                    )
                  }
                />
              </div>
            </div>
            <Calendar
              calendarType="US"
              onClickDay={this.dateChangeHandler}
              tileContent={
                selectedAsset && selectedAsset.type !== "Facility"
                  ? tileContent : facilityTileContent
              }
              tileDisabled={this.tileDisabled}
              tileClassName={tileClassName}
              showNavigation={false}
              activeStartDate={this.state.currentDate}
            />
            {selectedAsset && selectedAsset.type === "Facility" && (
              <div className="row">
                <div className="mt-4 mx-3">
                  <div className="d-flex justify-content-start align-item-center py-3">
                    <div>
                      <input
                        className="cursor"
                        type="radio"
                        checked={isRangeSelect}
                        name="radio"
                        onClick={(e) => this.rangeToggleChange(true)}
                      />
                      <label
                        className="ml-2 cursor"
                        onClick={(e) => this.rangeToggleChange(true)}
                      >
                        Select date range
                      </label>
                    </div>
                    <div>
                      <input
                        className="cursor ml-2"
                        type="radio"
                        checked={!isRangeSelect}
                        name="radio"
                        onClick={(e) => this.rangeToggleChange(false)}
                      />
                      <label
                        onClick={(e) => this.rangeToggleChange(false)}
                        className="ml-2 cursor"
                      >
                        Select a specific date(s)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAdmin: state.auth.isAdmin,
    userID: state.auth.userId,
  }
}

export default connect(
  mapStateToProps,
  { getAdminStatus },
)(withRouter(BookAsset))
