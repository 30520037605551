import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash"
import axios from "../../../configAxios";

import CardHeader from "../CardHeader";
import MemberItem from "./MemberItem";

import Spinner from "../../../assests/images/spinner.gif";
import "../index.scss";

class NewMembers extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    members: []
  };

  componentDidMount() {
    const params = {
      type: "search_and_browse",
      order: {
        column: "created_at",
        dir: "desc"
      },
      per_page: 5
    };
    axios
      .get("/users", { params, cancelToken: this.signal.token })
      .then(res => {
        this.setState({ members: res.data.users, loading: false });
        this.props.updateGrid();
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  render() {
    const spinner = (
      <img
        src={Spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading..."
      />
    );
    return (
      <div className="dashboard">
        <div className="card">
          {
            this.state.loading ? (
              <div className="card-body p-0">
                <div className="card-text">
                  <div className="spinner">{spinner}</div>
                </div>
              </div>
            ) : (
                <div>
                  <CardHeader
                    title="New Members"
                    icon="fa-users"
                    collapse="newmembers"
                    updateGrid={this.props.updateGrid}
                    removeModule={this.props.removeModule}
                    name={this.props.name}
                  />
                  <div className="dashboard-card collapse show" id="newmembers">
                    <div className="card-body p-0">
                      <ul className="list-group">
                        {
                          !_.isEmpty(this.state.members) && this.state.members.map(member => (
                            <MemberItem key={member.id} member={member} />
                          ))
                        }
                      </ul>
                      <div className="p-2 text-center cursor card-text" style={{ height: "40px" }}>
                        <Link to="/users/members" className="text-info">View All</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
          }
        </div>
      </div>
    );
  }
}

export default withRouter(NewMembers);
