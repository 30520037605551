import React, { Component } from "react"
import { toast } from "react-toastify"

import axios from "../../../configAxios"
import errorHandler from "../../errorPages/UserNotAuthorized"
import Input from "../../UI/input/Input"
import Button from "../../UI/button/Button"
import FormButton from "../../UI/button/FormButton"

class CreateThirdPartyOwner extends Component {
  signal = axios.CancelToken.source()
  timeout = 0
  state = {
    createNewThirdPartyOwner: {
      user: {
        elementType: "asyncSelect",
        elementConfig: {
          name: "user_id",
          placeholder: "Select User",
          required: true,
          defaultValue: this.props.editOrMigrate &&
            !this.props.migrate && {
              value: this.props.thirdPartyDetails.user_id,
              label:
                this.props.thirdPartyDetails.first_name +
                " " +
                this.props.thirdPartyDetails.last_name,
            },
          loadOptions: (inputValue, callback) => {
            if (inputValue.length > 2) this.searchUsers(inputValue, callback)
          },
          hidden: true,
          components: {
            IndicatorSeparator: () => {
              return null
            },
          },
        },
        label: "Assign User",
        hide: false,
      },
    },
    enteredUserCharacters: "",
    disabled: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled")
  }

  closeModal = () => {
    this.props.closeModal()
  }

  searchUsers = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue,
      },
      cancelToken: this.signal.token,
    }
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.getUsers(queryParams, callback)
    }, 500)
  }

  getUsers = (queryParams, callback) => {
    axios
      .get("/users/autocomplete", queryParams)
      .then((res) => {
        callback(
          res.data.users.map((user) => {
            return {
              value: user.id,
              label: user.full_name,
            }
          })
        )
      })
      .catch((err) => err)
  }

  inputChangedHandler = (event, key, formName) => {
    const inputValue = {}
    if (key !== "user") {
      inputValue.value = event.target.value
    } else {
      inputValue.selectedOption = event
    }

    const updatedcontrols = {
      ...this.state[formName],
      [key]: {
        ...this.state[formName][key],
        ...inputValue,
      },
    }
    this.setState({ [formName]: updatedcontrols })
  }

  submitHandler = (event) => {
    event.preventDefault()
    const { createNewThirdPartyOwner } = this.state
    const thirdPartyOwnerData = {}
    thirdPartyOwnerData.user_id =
      createNewThirdPartyOwner.user.selectedOption.value
    if (!this.props.editOrMigrate) {
      axios.post("/third_party_owners", thirdPartyOwnerData).then((res) => {
        if (res.data.json.success) {
          toast.success("Successfully created")
          this.props.closeModal()
          this.props.getThirdPartyOwners()
        } else {
          errorHandler(res)
        }
        this.setState({ disabled: false })
      })
    } else {
      thirdPartyOwnerData.id = this.props.thirdPartyDetails.id
      axios
        .put(
          `/third_party_owners/${thirdPartyOwnerData.id}`,
          thirdPartyOwnerData
        )
        .then((res) => {
          if (res.data.json.success) {
            if (this.props.migrate) {
              toast.success("Successfully Migrated")
            } else {
              toast.success("Third party owner has successfully edited")
            }
            this.props.closeModal()
            this.props.getThirdPartyOwners()
          } else {
            errorHandler(res)
          }
          this.setState({ disabled: false })
        })
      console.log("id=" + this.props.thirdPartyDetails.id)
    }
  }

  render() {
    const createNewThirdPartyOwnerFormElements = []

    for (const key in this.state.createNewThirdPartyOwner) {
      createNewThirdPartyOwnerFormElements.push({
        index: 1,
        id: key,
        config: this.state.createNewThirdPartyOwner[key],
      })
    }

    const createNewThirdPartyOwnerForm = createNewThirdPartyOwnerFormElements.map(
      (formElement) => (
        <Input
          key={formElement.id}
          {...formElement.config}
          changed={(event) =>
            this.inputChangedHandler(
              event,
              formElement.id,
              "createNewThirdPartyOwner"
            )
          }
          onInputChange={(event) =>
            this.setState({ enteredUserCharacters: event })
          }
        />
      )
    )

    return (
      <form onSubmit={this.submitHandler} className="mt-4">
        {createNewThirdPartyOwnerForm}
        <div className="text-center mt-3">
          <Button type="secondary" clicked={() => this.props.closeModal()}>
            Cancel
          </Button>
          <FormButton className="ml-2" disabled={this.state.disabled}>
            {!this.props.editOrMigrate
              ? "Create Third Party Owner"
              : this.props.migrate
              ? "Migrate"
              : "Edit Third Party Owner"}
          </FormButton>
        </div>
      </form>
    )
  }
}

export default CreateThirdPartyOwner
