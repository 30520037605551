import React, { useState } from "react";

const CardHeader = props => {
  const [btnDisable, setBtnDisable] = useState(false);
  return (
    <div
      className="card-header p-2 bg-white border-bottom"
      style={{ height: "40px" }}
    >
      <div className="row">
        <div className="col-8">
          <div className="card-heading">
            <i className={`fas ${props.icon} fa-lg mt-1 ml-2`} />
            <span className="ml-3">{props.title}</span>
          </div>
        </div>
        <div className="col-4 text-right">
          <button
            className="btn btn-link text-dark p-0"
            data-toggle="collapse"
            data-target={`#${props.collapse}`}
            aria-expanded="true"
            aria-controls={props.collapse}
            onClick={e => props.updateGrid()}>
            <i className="fa fa-angle-up fa-lg mr-4 cursor text-dark" />
          </button>
          {/* onClick={e => props.updateGrid() } */}
          <button className="btn btn-link text-dark p-0"
            disabled={btnDisable}
            onClick={() => {
              setBtnDisable(true)
              props.removeModule(props.name)
            }}>
            <i className="fa fa-times cursor mr-1 text-dark" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardHeader;
