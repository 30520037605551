import React, { Component } from 'react'
import { toast } from "react-toastify";
import { title } from "change-case";

import axios from '../../../configAxios'
import Button from '../../UI/button/Button'
import Input from '../../UI/input/Input'
import ModalSpinner from '../../UI/ModalSpinner';
import FormButton from '../../UI/button/FormButton';
import { responseErrorHandler } from '../../../utils/errorHandler';

class MergeAssociationsAndEducationForm extends Component {
  signal = axios.CancelToken.source();
  timeout = 0;
  state = {
    loading: true,
    disableSubmit: false,
    formFields: {
      name: {
        elementType: "input",
        elementConfig: {
          name: "name",
          type: "text",
          required: true
        },
        label: "Association",
        value: ""
      },
      association: {
        elementType: "asyncSelect",
        elementConfig: {
          name: "association",
          placeholder: "Search here",
          inputLength: 1,
          loadOptions: (inputValue, callback) => {
            if (inputValue.length > 0) this.searchAssociationsByName(inputValue, callback);
          },
          components: {
            DropdownIndicator: () => {
              return null;
            },
            IndicatorSeparator: () => {
              return null;
            }
          },
          styles: {
            multiValueLabel: base => ({
              ...base,
              backgroundColor: "#f8f9fa",
              color: "#666666"
            }),
            multiValueRemove: base => ({
              ...base,
              backgroundColor: "#f8f9fa",
              color: "#666666"
            })
          }
        },
        label: "Merge To",
        selectedOption: null
      },
      associationCharacters: ""
    }
  }

  searchAssociationsByName = (inputValue, callback) => {
    const queryParams = {
      params: {
        query: inputValue,
      },
      cancelToken: this.signal.token
    };
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getAssociationsByName(queryParams, callback);
    }, 300);
  };

  getAssociationsByName = (queryParams, callback) => {
    axios
      .get("/education_and_associations", queryParams)
      .then(res => {
        const options = res.data.education_and_associations.filter(association => association.name !== this.state.formFields.name.value);
        callback(
          options.map(association => ({ value: association.name, label: association.name, id: association.id }))
        );
      })
  }

  componentDidMount() {
    axios.get(`/education_and_associations/${this.props.associationsAndEducationId}`, { cancelToken: this.signal.token })
      .then(res => {
        this.setState({
          ...this.state,
          formFields: {
            ...this.state.formFields,
            name: {
              ...this.state.formFields.name,
              value: res.data.education_and_association.name
            }
          },
          loading: false
        })
      })
  }

  inputChangedHandler = (event, key) => {
    if (key === "association") {
      let formFields = this.state.formFields
      formFields.association.selectedOption = event

      this.setState({ ...this.state, formFields });
    } else {
      let formFields = this.state.formFields
      formFields[key].value = event.target.value

      this.setState({ ...this.state, formFields });
    }
  }

  submitHandler = e => {
    e.preventDefault()

    if (!this.state.formFields.association.selectedOption) {
      toast.error(`Please select an Association to merge`);
      return
    }

    this.setState({ ...this.state, disableSubmit: true })
    let data = {
        old_education_and_association_id: this.props.associationsAndEducationId,
        new_education_and_association_id: this.state.formFields.association.selectedOption.id,
    }
    axios.post(`/education_and_associations/merge`, data)
      .then(res => {
        if (res.status === 201 || res.data.json.success) {
          toast.success(`Associations are being merged in the background.`)
          this.props.closeModal()
          this.props.getAssociations();
        } else {
          responseErrorHandler(res);
        }
      })
  }

  render() {
    if (this.state.loading) {
      return (
        <ModalSpinner />
      )
    }
    return (
      <div className="mt-3">
        <form onSubmit={this.submitHandler}>
          <Input
            {...this.state.formFields.name}
          />
          <Input
            {...this.state.formFields.association}
            changed={event => this.inputChangedHandler(event, "association")}
            chart={this.state.associationCharacters}
            onInputChange={event => this.setState({ associationCharacters: event })}
          />
          <div className="text-center">
            <Button
              type="secondary"
              clicked={this.props.closeModal}
              className="mr-2"
            >
              Cancel
            </Button>
            <FormButton disabled={this.state.disableSubmit}>Merge</FormButton>
          </div>
        </form>
      </div>
    )
  }
}

export default MergeAssociationsAndEducationForm;
