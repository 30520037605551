import React, { Component } from "react";
import axios from "../../configAxios";
import ModalSpinner from "../UI/ModalSpinner";

class PastRegistration extends Component {
  signal = axios.CancelToken.source();
  state = {
    registrations: null,
    loader: true
  };

  componentDidMount() {
    axios.get("/registrations/past_registrations", {
      params: {
        id: this.props.userId
      },
      cancelToken: this.signal.token
    })
      .then(res => {
        this.setState({ registrations: res.data.registrations, loader: false });
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.signal.cancel('Request is being Cancelled');
  }

  render() {
    let registrationList = null;
    if (this.state.loader) {
      return <ModalSpinner />
    } else {
      if (this.state.registrations !== null) {
        if (this.state.registrations.length !== 0) {
          registrationList = this.state.registrations.map(
            (registration, index) => {
              return <p key={index}>{registration.program_name}</p>;
            }
          );
        } else {
          registrationList = (
            <p
              className="text-center font-weight-bold text-dark"
              style={{ color: "black" }}
            >
              No past registration.
            </p>
          );
        }
      }
    }
    return (
      <div className="pl-3">
        <div className="mt-4">{registrationList}</div>
      </div>
    );
  }
}
export default PastRegistration;
