import React, { Component } from "react";
import dateFns from "date-fns";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

import Button from "../UI/button/Button";
import axios from "../../configAxios";
import Spinner from "../UI/Spinner";

class PartnershipOrganizations extends Component {
  csvLink = React.createRef();
  signal = axios.CancelToken.source();
  state = {
    organizationId: null,
    partnerOrganizations: [],
    reportData: "",
    statistics: {},
    loading: true
  };

  componentDidMount() {
    axios
      .get("/reports/partnership_organization", {
        params: {
          organization_id: this.props.auth.organizationId
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        this.setState({
          partnerOrganizations: res.data["reports/partnership_organization"],
          statistics: res.data.meta,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  reportDownloadHandler = () => {
    axios
      .get("/reports/partnership_organization", {
        params: {
          organization_id: this.props.auth.organizationId,
          generate_csv: true
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        toast.success(<div>Generating Report.<br />File will be downloaded soon.</div>);
        this.setState({ reportData: res.data.json.data }, () => {
          this.csvLink.current.link.click();
        });
      });
  };

  render() {
    const { partnerOrganizations } = this.state;
    const partnersList = partnerOrganizations.map(organization => (
      <tr key={organization.id}>
        <td>{organization.name}</td>
        <td>{organization.description}</td>
        <td>
          {organization.address1}
          <div>{organization.email}</div>
          <div>{organization.phone}</div>
        </td>
        <td>{organization.users_count}</td>
      </tr>
    ));

    const usersList = partnerOrganizations.map(organization => (
      <tr key={organization.id}>
        <td>
          <b>{organization.name}</b>
        </td>
        <td>
          {organization.users.map(user => (
            <div key={user.id}>{user.organization_or_name}</div>
          ))}
        </td>
        <td>
          {organization.users.map(user => (
            <div key={user.id}>
              {dateFns.format(user.created_at, "YYYY-MM-DD")}
            </div>
          ))}
        </td>
      </tr>
    ));

    return (
      <div className="container general-membership w-100">
        <div className="row mb-4">
          <div className="col-6">
            <h3>
              Partner Organizations
            </h3>
          </div>
          <div className="col-6 text-right">
            <Button
              type="success"
              clicked={this.reportDownloadHandler}
            >
              Generate CSV
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="card-text">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="font-weight-bold">
                        Statistics
                      </h5>
                    </div>
                  </div>
                  {
                    this.state.loading ? (
                      <div className="row">
                        <div className="col-12">
                          <Spinner className="h-30vh" />
                        </div>
                      </div>
                    ) : (
                        <div className="row">
                          <div className="col-4">
                            <p>Total Num. of Partner Organizations:</p>
                            <p>Total Num. of Partner Members:</p>
                          </div>
                          <div className="col-3">
                            <p>
                              {this.state.loading
                                ? 0
                                : this.state.statistics.total_partner_organization}
                            </p>
                            <p>
                              {this.state.loading
                                ? 0
                                : this.state.statistics.total_partner_members}
                            </p>
                          </div>
                        </div>
                      )
                  }

                  <div className="list mt-5">
                    <h5>
                      <b>Partner Organizations List</b>
                    </h5>
                    <div className="table-responsive">
                      <table className="table table-bordered reports-table-layout">
                        <thead>
                          <tr className="border-top">
                            <th className="w-30">Name</th>
                            <th className="w-20">Description</th>
                            <th className="w-20">Contact</th>
                            <th className="w-10">Num. Users</th>
                          </tr>
                        </thead>
                        <tbody>{!this.state.loading && partnersList}</tbody>
                      </table>
                    </div>
                    {this.state.loading && <Spinner className="h-30vh" />}
                  </div>

                  <div className="users mt-5">
                    <h5 className="font-weight-bold">
                      Users from Partner Organizations
                    </h5>
                    <table className="table table-bordered reports-table-layout">
                      <thead>
                        <tr>
                          <th className="w-30">Partner Organization</th>
                          <th className="w-40">Friend Name</th>
                          <th className="w-30">Associated Since</th>
                        </tr>
                      </thead>
                      <tbody>{!this.state.loading && usersList}</tbody>
                    </table>
                    {this.state.loading && <Spinner className="h-30vh" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CSVLink
          data={this.state.reportData}
          filename={`partner_organizations_report.csv`}
          className="hidden"
          ref={this.csvLink}
          target="_self"
        />
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(PartnershipOrganizations);
