import React, { Component } from "react";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import axios from "../../../configAxios";
import { toast } from "react-toastify";
import Input from "../../UI/input/Input";
import { title } from "change-case";
import ModalSpinner from "../../UI/ModalSpinner";

class NewAccountingCode extends Component {
  signal = axios.CancelToken.source();
  state = {
    categoryNames: [],
    name: {
      elementType: "input",
      elementConfig: {
        name: "name",
        type: "text",
        required: true
      },
      label: "Name",
      value: ""
    },
    code: {
      elementType: "input",
      elementConfig: {
        name: "code",
        type: "text",
        required: true,
        maxlength: "10"
      },
      label: "Accounting Code",
      value: ""
    },
    taxCode: {
      elementType: "input",
      elementConfig: {
        name: "taxCode",
        type: "text",
        maxlength: "3"
      },
      label: "Tax Code",
      value: ""
    },
    type: {
      elementType: "select",
      elementConfig: {
        name: "type",
        type: "text",
        options: [{ label: "tax code", value: "tax_code" }],
        defaultValue: null
      },
      label: "Type",
      selectedOption: null
    },
    linkAccountingCode: {
      elementType: "input",
      elementConfig: {
        name: "linkAccountingCode",
        type: "checkbox",
        checked: false
      },
      label: "Link Accounting Code"
    },
    chargeTax: {
      elementType: "input",
      elementConfig: {
        name: "chargeTax",
        type: "checkbox",
        checked: true
      },
      label: "Apply Tax"
    },
    isEditable: true,
    loading: false,
    disabled: false
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setState({ ...this.state, loading: true })
      axios
        .get("/accounting_codes/" + this.props.id, {
          cancelToken: this.signal.token
        })
        .then(res => {
          const { name, account_number, code_type, code, is_editable, charge_tax } = res.data.accounting_code
          this.setState({ isEditable: is_editable })
          const updateData = {};
          updateData["name"] = {
            ...this.state.name,
            value: name
          }
          updateData["code"] = {
            ...this.state.code,
            value: code
          }
          updateData["taxCode"] = {
            ...this.state.taxCode,
            value: account_number
          }
          updateData["isEditable"] = is_editable
          if (code_type !== "accounting_code" || account_number)
            updateData["linkAccountingCode"] = {
              ...this.state.linkAccountingCode,
              elementConfig: {
                ...this.state.linkAccountingCode.elementConfig,
                checked: true
              }
            }
          updateData["type"] = {
            ...this.state.type,
            elementConfig: {
              ...this.state.type.elementConfig,
              defaultValue: { value: account_number ? "tax_code" : code_type, label: account_number ? "tax code" : code_type.replace("_", " ") }
            },
            selectedOption: { value: account_number ? "tax_code" : code_type, label: account_number ? "tax code" : code_type.replace("_", " ") }
          }
          updateData["chargeTax"] = {
            ...this.state.chargeTax,
            elementConfig: {
              ...this.state.chargeTax.elementConfig,
              checked: charge_tax
            }
          }
          this.setState({ ...updateData }, () => this.getFormData());
        });
    } else {
      this.getFormData();
    }
  }

  getFormData = () => {
    axios
      .get("/accounting_codes/formdata", { cancelToken: this.signal.token })
      .then(res => {
        const { code_types } = res.data.json;
        const { options } = this.state.type.elementConfig;
        const { selectedOption } = this.state.type
        const dropDownOptions = code_types.map(types => {
          return { label: types.replace("_", " "), value: types };
        });
        const defaultOption = {
          label: code_types[0].replace("_", " "),
          value: code_types[0]
        }
        this.setState({
          type: {
            ...this.state.type,
            elementConfig: {
              ...this.state.type.elementConfig,
              defaultValue: { label: code_types[0].replace("_", " "), value: code_types[0] },
              options: [...dropDownOptions, ...options]
            },
            selectedOption: this.props.edit ? selectedOption : defaultOption
          },
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  closeModal = () => {
    this.props.closeModal();
  };

  inputChangedHandler = (event, key) => {
    let inputValue = {};
    if (key === "name" || key === "code" || key === "taxCode") {
      inputValue["value"] = event.target.value;
    } else {
      inputValue["selectedOption"] = event;
    }
    const updatedcontrols = {
      ...this.state[key],
      ...inputValue
    };
    this.setState({ [key]: updatedcontrols });
  };

  submitHandler = event => {
    event.preventDefault();
    this.setState({ disabled: true })
    let accountingCodeData = {};
    const { name, code, type, taxCode, chargeTax } = this.state;
    accountingCodeData["name"] = name.value;
    accountingCodeData["code"] = code.value;
    accountingCodeData["account_number"] = type.selectedOption && type.selectedOption.value === "tax_code" ? taxCode.value : "";
    if (type.selectedOption) {
      if (type.selectedOption.value === "tax_code")
        accountingCodeData["code_type"] = "accounting_code";
      else
        accountingCodeData["code_type"] = type.selectedOption.value;
    }
    accountingCodeData["charge_tax"] = chargeTax.elementConfig.checked
    if (this.props.edit) {
      axios
        .put("/accounting_codes/" + this.props.id, accountingCodeData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Accounting code successfully updated.");
            this.props.closeModal();
            this.props.getAccountingCodes();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disabled: false })
        });
    }
    else {
      axios
        .post("/accounting_codes", accountingCodeData)
        .then(res => {
          if (res.data.json.success) {
            toast.success("Accounting code successfully created.");
            this.props.closeModal();
            this.props.getAccountingCodes();
          } else {
            if (res.data.json.hasOwnProperty("errors")) {
              Object.keys(res.data.json.errors).forEach(error => {
                toast.error(title(error) + " " + res.data.json.errors[error]);
              });
            }
            if (res.data.json.hasOwnProperty("error")) {
              toast.error(res.data.json.error);
            }
          }
          this.setState({ disabled: false })
        })
        .catch(err => toast.error(err));
    }
  }

  checkboxHandler = () => {
    const { elementConfig } = this.state.linkAccountingCode;
    this.setState({
      linkAccountingCode: {
        ...this.state.linkAccountingCode,
        elementConfig: {
          ...elementConfig,
          checked: !elementConfig.checked
        }
      }
    }, () => {
      if (!this.state.linkAccountingCode.elementConfig.checked)
        this.setState({
          taxCode: {
            ...this.state.taxCode,
            value: ""
          },
          type: {
            ...this.state.type,
            selectedOption: this.state.type.elementConfig.defaultValue
          }
        });
    })
  }

  taxCheckboxHandler = () => {
    this.setState(prevState => ({
      chargeTax: {
        ...prevState.chargeTax,
        elementConfig: {
          ...prevState.chargeTax.elementConfig,
          checked: !prevState.chargeTax.elementConfig.checked
        }
      }
    }))
  }

  render() {
    const { checked } = this.state.linkAccountingCode.elementConfig;
    const disabled = !this.state.isEditable
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        {
          this.state.loading ? <ModalSpinner /> : (
            <React.Fragment>
              <Input
                {...this.state.name}
                disabled={disabled}
                changed={event => this.inputChangedHandler(event, "name")}
              />
              <Input
                {...this.state.code}
                disabled={disabled}
                changed={event => this.inputChangedHandler(event, "code")}
              />
              <div className="d-flex">
                <Input {...this.state.linkAccountingCode} changed={event => this.checkboxHandler()} />
                <div className='ml-3'>
                  <Input {...this.state.chargeTax} changed={this.taxCheckboxHandler} />
                </div>
              </div>
              {checked && (
                <Input
                  {...this.state.type}
                  changed={event => this.inputChangedHandler(event, "type")}
                />
              )}
              {this.state.type.selectedOption && this.state.type.selectedOption.value === "tax_code" &&
                <Input
                  {...this.state.taxCode}
                  changed={event => this.inputChangedHandler(event, "taxCode")}
                />}
              <div className="text-center">
                <Button type="secondary" clicked={this.props.closeModal}>
                  Cancel
                </Button>
                <FormButton className="ml-2">
                  {this.props.edit
                    ? "Edit Accounting Code"
                    : "Create Accounting Code"
                  }
                </FormButton>
              </div>
            </React.Fragment>
          )
        }
      </form>
    );
  }
}

export default NewAccountingCode;
