import React, { Component } from "react";
import queryString from "query-string";
import AddNewItem from "./AddNewItem";

class CreateOrEditLibrary extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  render() {
    const values = queryString.parse(this.props.location.search);
    const type = values.type;
    const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);

    const activeTab = <AddNewItem type={type} />;

    return (
      <div className="container library mb-3 w-100">
        <div className="d-flex justify-content-between">
          <h4>
            {this.props.location.pathname.includes("edit") ? "Edit" : "New"}{" "}
            {typeCapitalized} Media
          </h4>
        </div>
        {activeTab}
      </div>
    );
  }
}
export default CreateOrEditLibrary;
