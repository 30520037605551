import React, { Component } from "react";
import Button from "../../UI/button/Button";
import FormButton from "../../UI/button/FormButton";
import axios from "../../../configAxios";
import { toast } from "react-toastify";
import Input from "../../UI/input/Input";
import errorStatusHandler from "../../errorPages/UserNotAuthorized";

class AddUsers extends Component {
  signal = axios.CancelToken.source();
  state = {
    user: {
      elementType: "select",
      elementConfig: {
        name: "User",
        placeholder: "Select User",
        options: [],
        isMulti: false,
        isLoading: true,
        isClearable: false,
        components: {
          IndicatorSeparator: () => {
            return null;
          }
        }
      },
      label: "User",
      selectedOption: null
    },
    usersList: {},
    disabled: true,
    userId: null
  };

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails = () => {
    axios.get('users/admins')
      .then(res => {
        let usersList = res.data.users
        const sortedUsersList = usersList.sort((user1, user2) => (user1.full_name > user2.full_name) ? 1 : -1);
        const users = usersList.filter(el => {
          return !this.props.usersList.some(users => {
            return el.id === users.id
          })
        })

        this.setState({
          user: {
            ...this.state.user,
            elementConfig: {
              ...this.state.user.elementConfig,
              options: users.map(element => {
                const value = element.id;
                const label = element.full_name;
                return { label, value };
              }),
              isLoading: false
            }
          },
          usersList: sortedUsersList
        });
      })
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  inputChangedHandler = (event) => {
    const user = {
      ...this.state.user,
      selectedOption: event
    };
    this.setState({ user, disabled: false, userId: event.value });
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ disabled: true });
    const id = this.state.userId
    let user = this.state.usersList.filter(user => user.id === id)
    let data = {
      id: id,
      notification_setting_attributes: JSON.stringify({ "new_member_ticket": 1, "id": user[0].notification_setting_id })
    }
    axios.put(`/users/${id}`, data)
      .then(res => {
        if (res.data.json.success) {
          toast.success("Added to rental ticket maillist successfully")
          this.props.getUserList();
          this.props.closeModal();
        } else {
          errorStatusHandler(res);
          this.props.closeModal();
        }
      })
  }

  render() {
    return (
      <form className="mt-3" onSubmit={this.submitHandler}>
        {
          <React.Fragment>
            <Input
              {...this.state.user}
              changed={event => this.inputChangedHandler(event)}
            />
            <div className="text-center">
              <Button type="secondary" clicked={this.props.closeModal}>Cancel</Button>
              <FormButton className="ml-2" disabled={this.state.disabled}
                style={{ cursor: this.state.disabled ? "no-drop" : "pointer"}}
              >
                Save
              </FormButton>
            </div>
          </React.Fragment>
        }
      </form>
    );
  }
}

export default AddUsers;