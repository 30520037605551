import React from "react"
import UserInfoPanel from "./UserInfoPanel"

const Pronouns = (props) => {
  const user = props.user
  return (
    <>
      {user.organization_or_name !== user.full_name && user.pronouns && (
        <UserInfoPanel>{user.pronouns}</UserInfoPanel>
      )}
    </>
  )
}

export default Pronouns
