import React, { Component } from 'react'
import _ from "lodash";
import dateFns from "date-fns";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import CampaignList from "./CampaignList";
import DonorList from "./DonorList"
import DonationGraph from "./DonationGraph"
import AddorEditDonationCampaign from "./AddorEditDonationCampaign"
import CreateorEditDonation from "../users/CreateorEditDonation"

import Modal from "../UI/Modal"
import Button from "../UI/button/Button"
import Spinner from "../UI/Spinner";
import Input from "../UI/input/Input"
import axios from '../../configAxios';
import SpinnerImg from "../../assests/images/spinner.gif";
import langUtils from '../../utils/langUtils';

const MiniSpinner = () => (
  <div className="d-flex my-5 justify-content-center">
    <img
      src={SpinnerImg}
      alt="Loading..."
      className="my-5"
      style={{ width: "20%", maxWidth: "150px", minWidth: "100px" }}
    />
  </div>
)

class Donations extends Component {
  signal = axios.CancelToken.source();
  state = {
    loading: true,
    loadingGraph: true,
    campaigns: [],
    donors: [],
    donationData: [
      { name: "Jan", donations: 0 },
      { name: "Feb", donations: 0 },
      { name: "Mar", donations: 0 },
      { name: "Apr", donations: 0 },
      { name: "May", donations: 0 },
      { name: "Jun", donations: 0 },
      { name: "Jul", donations: 0 },
      { name: "Aug", donations: 0 },
      { name: "Sep", donations: 0 },
      { name: "Oct", donations: 0 },
      { name: "Nov", donations: 0 },
      { name: "Dec", donations: 0 },
    ],
    filter: {
      elementType: "select",
      elementConfig: {
        name: "filter",
        options: [
          { label: "Months", value: "months" },
          { label: "Years", value: "years" },
          { label: "Weeks", value: "weeks" }
        ],
        components: {
          IndicatorSeparator: () => null
        },
        isSearchable: false,
        defaultValue: { label: "Months", value: "months" },
        isClearable: false
      },
      selectedOption: { label: "Months", value: "months" }
    },
    showNewCampaignModal: false,
    showDonationModal: false,
    donationCampaignOptions: [],
    recognitionOptions: []
  }

  componentDidMount = () => {
    Promise.allSettled([
      this.getDonationFormData(),
      this.getGraphData(),
      this.getDonorsInfo(),
      this.getDonationCampaigns()
    ]).then(res => {
      this.setState({ loading: false })
    })
  }

  componentWillUnmount = () => {
    this.signal.cancel("Request is being cancelled");
  }

  filterChangeHandler = event => {
    this.setState({
      filter: {
        ...this.state.filter,
        selectedOption: event
      }
    }, () => {
      try {
        this.getGraphData()
      } catch (err) { }
    });
  }

  weekIncrementor = (date) => (date.setDate(date.getDate() - 7))

  showWeekLastDate = (date) => (new Date(date).setDate(new Date(date).getDate() + 6));

  getGraphData = () => {
    const {
      filter:
      { selectedOption: { value: view } }
    } = this.state;
    let attrValue = {
      params: {
        type: `${view.slice(0, -1)}ly`
      },
      cancelToken: this.signal.token
    }
    this.setState({ loadingGraph: true })
    return axios.get("/donations/stats", attrValue)
      .then(res => {
        const newData = [...this.state.donationData]
        const { data: graphData } = res.data.json;
        const today = new Date();
        const todayDay = today.getDay()
        const diff = today.getDate() - todayDay + (todayDay === 0 ? -6 : 1);
        let dateIterator = new Date(today.setDate(diff))
        this.setState({
          donationData:
            graphData ?
              newData.map((donationData, index) => {
                donationData.name = view !== "weeks"
                  ? graphData[index].month
                  : `${dateFns.format(dateIterator, "DD/MM")}\n-\n${dateFns.format(this.showWeekLastDate(dateIterator), "DD/MM")}`;
                donationData.donations = graphData[index].total_value;
                this.weekIncrementor(dateIterator);
                return donationData;
              }).reverse()
              : [],
          loadingGraph: false
        })
      })
      .catch(err => this.setState({ donationData: [], loadingGraph: false }))
  }

  getDonorsInfo = () => {
    const attrValue = {
      params: {
        page: 1,
        per_page: 5
      },
      cancelToken: this.signal.token
    }
    return axios.get("/donations", attrValue)
      .then(res => {
        if (res.data.donations)
          this.setState({
            donors: res.data.donations
          })
      })
  }

  getDonationCampaigns = () => {
    return axios.get("/donation_campaigns")
      .then(res => {
        if (res.data.donation_campaigns) {
          this.setState({ campaigns: res.data.donation_campaigns });
        }
      })
  }

  getDonationFormData = () => {
    return axios.get("/donations/formdata", {
      cancelToken: this.signal.token
    })
      .then(res => {
        this.setState({
          donationCampaignOptions: res.data.json.donation_campaigns.map(campaign => ({
            label: campaign.name,
            value: campaign.id
          })),
          recognitionOptions: res.data && res.data.json && res.data.json.recognition
            ? res.data.json.recognition.map(recongintion => ({ label: recongintion, value: recongintion }))
            : []
        })
      })
  }

  render() {
    const {
      campaigns,
      donors,
      donationData,
      loadingGraph,
      loading } = this.state;
    return (
      <>
        {
          loading
            ? <Spinner /> :
            <>
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="mb-4">Donations</h4>
                  </div>
                  <div className="col-md-4 text-md-right">
                    <Button
                      title="addDonation"
                      type="primary"
                      className="mb-2"
                      clicked={() => this.setState({ showDonationModal: true })}
                    >
                      {langUtils("txt_global_add_new", "Add New")}
                    </Button>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-text">
                          <div className="row">
                            <div className="col-md-10 h4">
                              <span className="text-dark font-weight-bold pl-4" >Total Donations in last 12 {this.state.filter.selectedOption.value}</span>
                            </div>
                            <div className="col-md-2">
                              <div className="pr-4">
                                <Input changed={this.filterChangeHandler} {...this.state.filter} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12" style={{ height: "400px" }}>
                            {loadingGraph
                              ? <MiniSpinner />
                              : donationData && donationData.length ?
                                <DonationGraph
                                  data={this.state.donationData}
                                  view={this.state.filter.selectedOption.value}
                                /> :
                                <div className="text-center my-5">
                                  No Data Available
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-text">
                          <div className="row border-bottom pb-2">
                            <div className="col-lg-8 font-weight-bold"> <i className="fas fa-bullhorn mr-1" /> Campaigns</div>
                            <div className="col-lg-4 text-right">
                              <span
                                className="cursor text-primary"
                                onClick={e => this.setState({ showNewCampaignModal: true })}
                              >
                                New Campaign
                              </span>
                            </div>
                          </div>
                          <CampaignList campaigns={campaigns} />
                          {campaigns && campaigns.length ?
                            <div className="row">
                              <div className="col-12 text-center">
                                <div className="mt-3">
                                  <Link
                                    to="/donations/campaigns"
                                    className="text-primary cursor"
                                  >
                                    View All
                                  </Link>
                                </div>
                              </div>
                            </div> : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-text">
                          <div className="row border-bottom font-weight-bold pb-2">
                            <div className="col-12"><i className="fas fa-hand-holding-usd mr-1" />Donations</div>
                          </div>
                          <DonorList donors={donors} />
                          {donors && donors.length > 0 ?
                            (<div className="row">
                              <div className="col-12 text-center">
                                <div className="mt-3">
                                  <Link
                                    to="/donations/donors"
                                    className="text-primary cursor"
                                  >
                                    View All
                                  </Link>
                                </div>
                              </div>
                            </div>)
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                closeButton
                closeModal={() => this.setState({ showNewCampaignModal: false })}
                hide={() => this.setState({ showNewCampaignModal: false })}
                show={this.state.showNewCampaignModal}
                title="New Campaign"
              >
                <AddorEditDonationCampaign
                  getUpdatedCampaigns={this.getDonationCampaigns}
                  modalCloseHandler={e => this.setState({ showNewCampaignModal: false })} />
              </Modal>
              <Modal
                title="Add Donation"
                closeButton
                closeModal={() => this.setState({ showDonationModal: false })}
                hide={() => this.setState({ showDonationModal: false })}
                show={this.state.showDonationModal}
              >
                <CreateorEditDonation
                  editDonation={false}
                  userId={this.props.auth.userId}
                  donationCampaignOptions={this.state.donationCampaignOptions}
                  recognitionOptions={this.state.recognitionOptions}
                  getDonationsList={() => {
                    this.getGraphData();
                    this.getDonorsInfo();
                  }}
                  fromDonationPage={true}
                  closeModal={() => this.setState({
                    showDonationModal: false
                  })}
                />
              </Modal>
            </>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(
  mapStateToProps,
  null
)(Donations)
