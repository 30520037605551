import React, { Component } from "react";
import Calendar from "react-calendar";
import dateFns, { addDays, startOfMonth, endOfMonth } from "date-fns";
import { Link } from "react-router-dom";

import axios from "../../configAxios";
import Spinner from "../UI/Spinner";

import "./CalendarView.css";

class CalendarView extends Component {
  signal = axios.CancelToken.source();
  state = {
    startOfMonth: startOfMonth(addDays(new Date(), 1)),
    endOfMonth: endOfMonth(new Date()),
    bookedDates: {},
    loading: true
  };

  componentDidMount() {
    this.getProgramDate();
  }

  componentWillUnmount() {
    this.signal.cancel("Request is being Cancelled");
  }

  getProgramDate = () => {
    const month = this.state.startOfMonth.getMonth() + 1;
    const year = this.state.startOfMonth.getFullYear();
    const firstDay = this.state.startOfMonth.getDate();
    const lastDay = this.state.endOfMonth.getDate();
    const start = {
      date: firstDay,
      month: month,
      year: year
    };
    const end = {
      date: lastDay,
      month: month,
      year: year
    };
    axios
      .get("/programs/calendar", {
        params: {
          type: "program",
          start: start,
          end: end
        },
        cancelToken: this.signal.token
      })
      .then(res => {
        const bookedDates = {};
        res.data.programs.forEach(program => {
          const title = program.program.name;
          let day = dateFns.getDate(program.start);
          let details = {};
          const startTime = dateFns.format(program.start, "hh:mmA");
          const endTime = dateFns.format(program.end, "hh:mmA");
          details["id"] = program.id;
          details["programId"] = program.program.id;
          details["title"] = title;
          details["time"] = `${startTime} - ${endTime}`;
          details["is_finished"] = program.program.is_finished;
          if (bookedDates[day] && bookedDates[day].length) {
            bookedDates[day].push(details);
          } else {
            bookedDates[day] = [details];
          }
        });
        this.setState({ bookedDates, loading: false });
      });
  };

  monthChangeHandler = date => {
    this.setState(
      {
        loading: true,
        startOfMonth: startOfMonth(addDays(date, 1)),
        endOfMonth: endOfMonth(date)
      },
      () => this.getProgramDate()
    );
  };

  renderTooltip = (bookedDates, date) => {
    return (
      <div className={date.getDay() === 6 ? "custom-tooltip tooltip-left" : "custom-tooltip tooltip-right"}>
        {bookedDates.map((obj, index) => {
          if (index === 0) {
            return null;
          } else {
            return (
              <div className="row font-15 font-weight-bold" key={`${obj.id}`}>
                {" "}
                <div className="col-md-12 mb-2" style={{ whiteSpace: "pre-line" }}>
                  <Link to={"/programs/" + obj.programId} className="text-info">
                    {obj.title}
                  </Link>
                </div>{" "}
                <div className="col-md-12 mb-2">{obj.time}</div>
              </div>
            );
          }
        })}
      </div>
    )
  }

  renderTileContent = (bookedDate, date) => {
    return (
      <div className="row text-container">
        {" "}
        <div
          className={`${
            bookedDate.length > 1
              ? "col-md-9"
              : "col-md-12"
            }`}
        >
          <Link
            to={"/programs/" + bookedDate[0].programId}
            key={bookedDate[0].id}
            className="tile-content-link"
          >
            <p className="tile-content-text mt-2">{bookedDate[0].title}</p>
            <p className="tile-content-text">{bookedDate[0].time}</p>
          </Link>
        </div>
        {bookedDate.length > 1 && (
          <div className="col-md-2">
            <div className={`mt-3 badge ${bookedDate[0].is_finished ? "grey-background" : "blue-background"}`} style={{ marginLeft: "-20px" }}>
              {`${
                bookedDate.length > 1
                  ? `${bookedDate.length - 1}+`
                  : ""
                }`}
              {this.renderTooltip(bookedDate, date)}
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { bookedDates, startOfMonth } = this.state;
    let tileContent = ({ date, view }) =>
      Object.keys(bookedDates).map(bookedDate => {
        let tileContent;
        if (
          view === "month" &&
          date.getMonth() === dateFns.getMonth(startOfMonth) &&
          date.getDate() === parseInt(bookedDate)
        ) {
          const obj = bookedDates[bookedDate];
          tileContent = (
            <div
              className={`tile-content-container justify-content-center tile-content ${obj[0].is_finished ? "grey-background" : "blue-background"}`}
              key={obj[0].id}
            >
              {this.renderTileContent(bookedDates[bookedDate], date)}
            </div>
          );
        }
        return tileContent;
      });
    return (
      <div className="row calender-view">
        {this.state.loading && <Spinner />}
        <div
          className="col-md-12 show-calendar"
          style={{
            display: !this.state.loading ? "block" : "none",
            width: "100%"
          }}
        >
          <Calendar
            calendarType="US"
            tileContent={tileContent}
            onActiveDateChange={payload =>
              this.monthChangeHandler(payload.activeStartDate)
            }
            onClickMonth={date => this.monthChangeHandler(date)}
          />
        </div>
      </div>
    );
  }
}

export default CalendarView;
