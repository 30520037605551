import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import _ from "lodash"
import { toast } from "react-toastify";
import Button from "../../../UI/button/Button";
import Modal from "../../../UI/Modal";
import EditJuryMembers from "./EditJuryMembers"
import { clearResponse } from "../../../../store/actions";
import JuryPackageUpload from "./JuryPackageUpload"
import JuryMemberDisplay from "./juryMemberDisplay"

export default function JuryTab({ grantCampaign }) {

  const juryMembers = grantCampaign.grant_campaigns_jury_members
  const [showModal, setShowModal] = useState(false)
  
  const organizationId = useSelector((state) => state.auth.organizationId)
  const response = useSelector(state => state.response)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!_.isEmpty(response) && response.success !== null) {
      const { message, success } = response;
      dispatch(clearResponse())
        
      success ? toast.success(message) : toast.error(message);
    }
  },[response])

  return (
    <div className="container pt-0 pr-0 pl-4 pb-3 mt-3 mb-3 d-flex flex-row">
      <div className="w-100 d-flex flex-row justify-content-between">
        <div className="w-50">
          <h3 className="mb-5">Jury Members</h3>
          <JuryMemberDisplay juryMembers={juryMembers} />
        </div>
        <div className="d-flex flex-column w-25 pl-3">
          <Button
            type="warning"
            className="w-75"
            clicked={() => setShowModal(true)}
          >
            Edit Jury Members
          </Button>
          <JuryPackageUpload grantCampaign={grantCampaign}/>
        </div>
      </div>
        <Modal
          title="Edit Jury"
          show={showModal}
          hide={() => setShowModal(false)}
        >
          <EditJuryMembers
            grantCampaignId={grantCampaign.id}
            organizationId={organizationId}
            existingJury={juryMembers}
            closeModal={() => {
              setShowModal(false)
              window.location.reload()
            }}
          />
        </Modal>
   </div>
  );
}
